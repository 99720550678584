import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionBodyFreeText } from '../TryInFeedbackOptionBodyFreeText';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

const TryInFeedbackClaspPlacementOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial>> = ({
    orderItem,
    newItem,
    updateNewItem,
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    const unselect = () => {
        // on unselect, reset clasp placement item changes
        updateNewItem({
            name: 'partial_production_metadata',
            payload: { clasp_notes: orderItem.partial_production_metadata?.clasp_notes ?? null },
        });
        onUnselected();
    };

    const itemClaspNotes = newItem.partial_production_metadata?.clasp_notes ?? null;

    // auto-set description to clasp notes if already set
    React.useEffect(() => {
        if (isSelected && itemClaspNotes !== description) {
            onDescriptionChanged(itemClaspNotes ?? undefined);
        }
    }, [isSelected, itemClaspNotes, description, onDescriptionChanged]);

    return (
        <TryInFeedbackOptionWrapper
            title={'Clasp placement'}
            subtitle={'The clasp placement needs adjustment'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={unselect}
            hasError={isSelected && showError && !description}
        >
            <TryInFeedbackOptionBodyFreeText
                label={'Where should the clasp(s) be placed? (teeth numbers, rest locations)'}
                value={itemClaspNotes ?? undefined}
                onChange={val =>
                    updateNewItem({ name: 'partial_production_metadata', payload: { clasp_notes: val ?? null } })
                }
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackClaspPlacementOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Partial> = {
    sku: LabOrderItemSKUType.Partial,
    isArchSpecific: false,
    category: 'Clasp placement',
    isEnabled: item => ['Chrome-Cobalt', 'Titanium'].includes(item.unit.material),
    Component: TryInFeedbackClaspPlacementOption,
};
