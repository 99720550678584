import type { ContactInfoProps } from './ContactInfo';
import { contactInfoForDoctor, cleanedContactInfoResults, ContactInfo } from './ContactInfo';
import type { NotificationSettingsProps } from './NotificationSettings';
import { notificationSettingsForDoctor, NotificationSettings } from './NotificationSettings';
import type { NotificationPreferencesSectionProps } from './utils';
import { useCollectNotificationPreferencesMutation } from '@orthly/graphql-react';
import { makeLazyDialog, useChangeSubmissionFn, RootActionDialog } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

interface PrevNextButtonsProps {
    prevLabel: string;
    onPrev: () => void;
    prevDisabled: boolean;
    nextLabel: string;
    nextDisabled: boolean;
    onNext: () => void;
}

const PrevNextButtons: React.FC<PrevNextButtonsProps> = props => {
    const { prevLabel, prevDisabled, onPrev, nextLabel, nextDisabled, onNext } = props;
    return (
        <Grid container style={{ justifyContent: 'flex-end', gap: 8, paddingTop: 40 }}>
            <Button variant={'secondary'} disabled={prevDisabled} onClick={onPrev}>
                {prevLabel}
            </Button>
            <Button variant={'primary'} disabled={nextDisabled} onClick={onNext}>
                {nextLabel}
            </Button>
        </Grid>
    );
};
export const useEditNotificationsDialog = makeLazyDialog<NotificationPreferencesSectionProps>(props => {
    const { open, setOpen, doctor } = props;
    const [step, setStep] = React.useState<number>(0);

    const [contactInfo, setContactInfo] = React.useState<Partial<ContactInfoProps>>(contactInfoForDoctor(doctor));
    const [notifSettings, setNotifSettings] = React.useState<Partial<NotificationSettingsProps>>(
        notificationSettingsForDoctor(doctor),
    );

    const [submitMtn] = useCollectNotificationPreferencesMutation();
    const mtnSubmitter = async (
        contactInfoValues: ContactInfoProps,
        notifSettingsValues: NotificationSettingsProps,
    ) => {
        await submitMtn({
            variables: {
                data: {
                    doctor_id: doctor.id,
                    ...cleanedContactInfoResults(contactInfoValues),
                    ...notifSettingsValues,
                },
            },
        });
    };
    const { submit, submitting } = useChangeSubmissionFn<any, [ContactInfoProps, NotificationSettingsProps]>(
        mtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => ['Notification preferences updated!', {}],
            onSuccess: () => {
                setOpen(false);
            },
        },
    );

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            loading={submitting}
            CustomButton={() => null}
            title={'Notification preferences'}
            titleAction={
                <Text variant={'body2'} color={'DARK_GRAY'} style={{ alignSelf: 'center', userSelect: 'none' }}>
                    Step {step + 1} of 2
                </Text>
            }
            content={
                step === 0 ? (
                    <ContactInfo
                        doctor={doctor}
                        formState={contactInfo}
                        setFormState={setContactInfo}
                        customSubmit={({ disabled, triggerSubmit }) => {
                            return (
                                <PrevNextButtons
                                    prevLabel={'Cancel'}
                                    onPrev={() => setOpen(false)}
                                    prevDisabled={false}
                                    nextLabel={'Next'}
                                    onNext={triggerSubmit}
                                    nextDisabled={disabled}
                                />
                            );
                        }}
                        onSubmit={values => {
                            setContactInfo(values);
                            setStep(1);
                        }}
                    />
                ) : (
                    <NotificationSettings
                        formState={notifSettings}
                        setFormState={setNotifSettings}
                        customSubmit={({ disabled, triggerSubmit }) => {
                            return (
                                <PrevNextButtons
                                    prevLabel={'Back'}
                                    onPrev={() => setStep(0)}
                                    prevDisabled={false}
                                    nextLabel={'Save & Finish'}
                                    onNext={triggerSubmit}
                                    nextDisabled={disabled}
                                />
                            );
                        }}
                        onSubmit={async notifSettingsResult => {
                            await submit(contactInfo as ContactInfoProps, notifSettingsResult);
                        }}
                    />
                )
            }
        />
    );
});
