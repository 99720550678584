import { getPartnerId } from '../../../../utils/authorization';
import { SettingsBaseModal } from '../../components/SettingsBaseModal';
import { AddCreditCardForm } from './AddCreditCardForm.graphql';
import { useSession } from '@orthly/session-client';
import React from 'react';

interface AddCreditCardModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    createCard: (props: { partnerId: string; cardToken: string }) => Promise<void>;
    onOpenBankAccountModal: () => void;
    setNewlyCreatedPaymentMethodId?: (pm: string) => void;
    onClosePaymentAction?: () => void;
}

export const AddCreditCardModal: React.FC<AddCreditCardModalProps> = ({
    open,
    setOpen,
    createCard,
    onOpenBankAccountModal,
    setNewlyCreatedPaymentMethodId,
    onClosePaymentAction,
}) => {
    const session = useSession();
    const userId = session?.user_id;
    const partnerId = getPartnerId(session);

    if (!userId || !partnerId) {
        return null;
    }

    return (
        <SettingsBaseModal
            content={
                <AddCreditCardForm
                    createCard={createCard}
                    setOpen={setOpen}
                    onOpenBankAccountModal={onOpenBankAccountModal}
                    onClosePaymentAction={onClosePaymentAction}
                    setNewlyCreatedPaymentMethodId={setNewlyCreatedPaymentMethodId}
                />
            }
            loading={false}
            title={'Add your credit card'}
            open={open}
            setOpen={(open: boolean) => {
                setOpen(open);
                if (!open) {
                    onClosePaymentAction?.();
                }
            }}
            CustomButton={() => null}
        />
    );
};
