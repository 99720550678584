import { useCheckoutScrollToControl } from '../../Checkout.util';
import { ChevronDown, ChevronUp } from '@orthly/ui';
import { FlossPalette, FlossPaletteUtils, makeStyles, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

const use_styles = makeStyles({
    next: {
        backgroundColor: FlossPalette.STAR_GRASS,
        color: `#fff`,
        overflow: `hidden`,
        height: 48,
        cursor: `pointer`,
        position: `relative`,
        [`& .sliding`]: {
            position: `absolute`,
            top: 0,
            left: 0,
            right: 0,
            margin: `12px 0`,
            lineHeight: `normal`,
        },
        [`& .number`]: { top: 0 },
        [`& .scroll`]: { top: 48 },
        [`&:hover`]: {
            backgroundColor: FlossPalette.STAR_GRASS,
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))`,
        },
        [`&:focus`]: {
            borderColor: FlossPaletteUtils.toRgba(`BLACK`, 0.2),
        },
        [`&[disabled]`]: {
            backgroundColor: FlossPalette.STAR_GRASS,
            cursor: `default`,
            pointerEvents: `none`,
            opacity: 0.5,
        },
        [`&.warning`]: {
            backgroundColor: FlossPalette.ATTENTION,
        },
        [`&.scroll_for_more`]: {
            animation: `4000ms $scroll_for_more 0ms infinite ease-in-out`,
            [`& .number`]: { animation: `4000ms $number 0ms infinite ease-in-out` },
            [`& .scroll`]: { animation: `4000ms $scroll 0ms infinite ease-in-out` },
        },
        [`& .arrow`]: { position: `relative`, marginRight: 4, animation: `1000ms $arrow 0ms infinite ease-in-out` },
    },
    [`@keyframes scroll_for_more`]: {
        [`0%, 45%, 100%`]: { backgroundColor: FlossPalette.STAR_GRASS },
        [`50%, 95%`]: { backgroundColor: FlossPalette.GRAY },
    },
    [`@keyframes number`]: {
        [`0%, 45%, 100%`]: { top: 0 },
        [`50%, 95%`]: { top: 48 },
    },
    [`@keyframes scroll`]: {
        [`0%, 45%, 100%`]: { top: 48 },
        [`50%, 95%`]: { top: 0 },
    },
    [`@keyframes arrow`]: {
        [`0%, 100%`]: { top: -1 },
        [`50%`]: { top: 3 },
    },
});

interface CheckoutNextButtonProps {
    remaining: number;
    text: string;
    warning: boolean;
    next_disabled: boolean;
    next: () => unknown;
}

export const CheckoutNextButton: React.VFC<CheckoutNextButtonProps> = props => {
    const { remaining, text, warning, next_disabled, next } = props;
    const classes = use_styles();
    const { at_bottom, at_top, go_to_top, go_down } = useCheckoutScrollToControl();
    const click = React.useCallback(() => {
        if (next_disabled) {
            at_bottom ? go_to_top() : go_down();
        } else {
            next();
        }
    }, [next, next_disabled, at_bottom, go_to_top, go_down]);
    const can_scroll = !at_top || !at_bottom;
    const disabled = next_disabled && (remaining === 0 || !can_scroll);
    return (
        <Button
            variant={'primary'}
            onClick={click}
            fullWidth={true}
            className={`${classes.next}${can_scroll && remaining ? ` scroll_for_more` : ``}${
                warning ? ` warning` : ``
            }`}
            disabled={disabled}
        >
            <Grid container className={`sliding number`} justifyContent={'center'}>
                {remaining ? `${remaining} ${remaining === 1 ? `field` : `fields`} remaining` : text}
            </Grid>
            <Grid container className={`sliding scroll`} justifyContent={'center'}>
                {at_bottom ? <ChevronUp className={`arrow`} /> : <ChevronDown className={`arrow`} />}
                <span>Scroll for more</span>
            </Grid>
        </Button>
    );
};
