import { useRootStyles } from '../../../components/Containers';
import { formatDataTestTitle } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grid, Text } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            zIndex: 1,
        },
        titleContainer: {
            padding: '36px 40px 16px',
            [theme.breakpoints.down('md')]: {
                padding: '36px 16px 0 16px',
            },
        },
    }),
);

interface InboxBodySectionProps {
    title?: string;
    titleSecondary?: React.ReactNode;
    style?: React.CSSProperties;
    hideOnMobile?: boolean;
}

export const InboxBodySection: React.FC<InboxBodySectionProps> = props => {
    const classes = useStyles();
    const rootClasses = useRootStyles();
    return (
        <Grid
            container
            className={cx(classes.root, props.hideOnMobile && rootClasses.noMobileDisplay)}
            style={props.style}
        >
            {props.title && (
                <Grid container className={classes.titleContainer} justifyContent={'space-between'}>
                    <Text data-test={`inbox-body-section-${formatDataTestTitle(props.title)}`} variant={'h5'}>
                        {props.title}
                    </Text>
                    {props.titleSecondary}
                </Grid>
            )}
            <Grid container>{props.children}</Grid>
        </Grid>
    );
};
