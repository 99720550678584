import { useOrderDetailSection } from '../../OrderDetailSectionProvider';
import { DOUBLE_TOOLBAR_TABLET_HEIGHT, TOOLBAR_TABLET_DOWN_HEIGHT, useScreenIsMobile } from '@orthly/ui';
import { FlossPalette, Grid, styled, Text, useScreenIsMd, useScreenIsSm } from '@orthly/ui-primitives';
import React from 'react';

const SectionHeader = styled('div')({
    width: '100%',
    background: FlossPalette.DARK_TAN,
    padding: '8px 8px 8px 16px',
    position: 'sticky',
    zIndex: 1,
});

const useToolbarScrollMargin = () => {
    const screenIsMd = useScreenIsMd();
    const screenIsSm = useScreenIsSm();
    const screenIsMobile = useScreenIsMobile();

    if (screenIsMobile) {
        return TOOLBAR_TABLET_DOWN_HEIGHT;
    }

    if (screenIsSm) {
        return DOUBLE_TOOLBAR_TABLET_HEIGHT;
    }

    if (screenIsMd) {
        return TOOLBAR_TABLET_DOWN_HEIGHT;
    }

    return 0;
};

export const OrderDetailSection = React.forwardRef<
    HTMLDivElement,
    { title?: string; additionalScrollMargin?: number; children: React.ReactNode }
>(({ title, additionalScrollMargin = 0, children }, ref) => {
    const { topBarHeight } = useOrderDetailSection();
    const toolbarScrollMargin = useToolbarScrollMargin();

    return (
        <Grid
            container
            ref={ref}
            style={{
                scrollMargin: topBarHeight + additionalScrollMargin + toolbarScrollMargin,
            }}
        >
            {title && (
                <SectionHeader style={{ top: topBarHeight }}>
                    <Text variant={'body2'} medium style={{ marginLeft: 16 }} color={'DARK_GRAY'}>
                        {title}
                    </Text>
                </SectionHeader>
            )}
            <Grid container style={{ padding: '16px 12px' }}>
                {children}
            </Grid>
        </Grid>
    );
});
