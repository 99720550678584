import { DenturesProductionType, OrderItemPartialDentureProductionType } from '@orthly/items';

export const TRY_IN_FEEDBACK_NEXT_STEPS_PARTIAL_LABELS: Record<OrderItemPartialDentureProductionType, string> = {
    [OrderItemPartialDentureProductionType.Final]: 'Proceed to final fabrication',
    [OrderItemPartialDentureProductionType.TryInTeethSetup]: 'Request a new try-in with teeth setup',
    [OrderItemPartialDentureProductionType.TryInFrameworkOnly]: 'Request a new try-in with framework only',
    // should never be used
    [OrderItemPartialDentureProductionType.WaxRim]: 'Request a new bite rim',
};

export const TRY_IN_FEEDBACK_NEXT_STEPS_DENTURE_LABELS: Record<DenturesProductionType, string> = {
    [DenturesProductionType.Final]: 'Proceed to final fabrication',
    [DenturesProductionType.TryIn]: 'Request a new try-in',
    // should never be used
    [DenturesProductionType.WaxRim]: 'Request a new bite rim',
};
