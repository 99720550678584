import { ItemsSectionDisplayClasses } from './util/displayClasses';
import { getShadesAndStain } from './util/getItemDisplay';
import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import type { IOrderItemV2DTO } from '@orthly/items';
import { Medium } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const ShadeOcclusalDetails: React.FC<{ item: IOrderItemV2DTO }> = ({ item }) => {
    const { occlusalStain } = getShadesAndStain(item);
    const { fontComponents } = useOrderSummaryComponents();
    const { Text } = fontComponents;
    return (
        <Text
            className={ItemsSectionDisplayClasses.SHADE_OCCLUSAL_DETAILS}
            style={{ flex: 30, textAlign: 'right', fontSize: '12pt' }}
        >
            {item.shades?.length ? <Medium>See below</Medium> : <Text style={{ color: FlossPalette.GRAY }}>N/A</Text>}
            {`${!!occlusalStain ? ' /' : ''} ${occlusalStain}`}
        </Text>
    );
};
