import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionBodyFreeText } from '../TryInFeedbackOptionBodyFreeText';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { BigRadioSelect } from '@orthly/dentin';
import { LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

const CORRECT_OCCLUSAL_SCHEME_TEXT = 'Correct occlusal scheme to class I';

type BiteOption = 'correct_occlusal_scheme' | 'other';

const TryInFeedbackDentureBiteOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Denture>> = ({
    orderItem,
    newItem,
    updateNewItem,
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    const [selectedOption, setSelectedOption] = React.useState<BiteOption | null>(null);

    React.useEffect(() => {
        const correctOcclusalScheme = newItem.denture_production_metadata?.correct_occlusal_scheme;
        if (correctOcclusalScheme && selectedOption !== 'correct_occlusal_scheme') {
            setSelectedOption('correct_occlusal_scheme');
        } else if (description && description !== CORRECT_OCCLUSAL_SCHEME_TEXT && selectedOption !== 'other') {
            setSelectedOption('other');
        }
        if (correctOcclusalScheme && description !== CORRECT_OCCLUSAL_SCHEME_TEXT) {
            onDescriptionChanged(CORRECT_OCCLUSAL_SCHEME_TEXT);
        }
    }, [
        newItem.denture_production_metadata?.correct_occlusal_scheme,
        description,
        selectedOption,
        onDescriptionChanged,
        setSelectedOption,
    ]);

    const unselect = () => {
        updateNewItem({
            name: 'denture_production_metadata',
            payload: {
                correct_occlusal_scheme: orderItem.denture_production_metadata?.correct_occlusal_scheme ?? null,
            },
        });
        setSelectedOption(null);
        onUnselected();
    };

    return (
        <TryInFeedbackOptionWrapper
            title={'Bite'}
            subtitle={`Denture doesn't occlude properly`}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={unselect}
            hasError={isSelected && showError && !description}
        >
            <BigRadioSelect<BiteOption>
                value={selectedOption ?? undefined}
                onChange={val => {
                    if (val === selectedOption) {
                        return;
                    }
                    updateNewItem({
                        name: 'denture_production_metadata',
                        payload: { correct_occlusal_scheme: val === 'correct_occlusal_scheme' },
                    });
                    if (val === 'other') {
                        setSelectedOption('other');
                        onDescriptionChanged(undefined);
                    }
                }}
                options={[
                    { label: CORRECT_OCCLUSAL_SCHEME_TEXT, value: 'correct_occlusal_scheme' },
                    { label: 'Other', value: 'other' },
                ]}
            />
            {selectedOption === 'other' && (
                <div style={{ marginTop: 8 }}>
                    <TryInFeedbackOptionBodyFreeText
                        label={'How would you like us to adjust the bite?'}
                        value={description ?? undefined}
                        onChange={onDescriptionChanged}
                    />
                </div>
            )}
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackDentureBiteOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Denture> = {
    sku: LabOrderItemSKUType.Denture,
    isArchSpecific: false,
    category: 'Bite',
    isEnabled: () => true,
    Component: TryInFeedbackDentureBiteOption,
};
