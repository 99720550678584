import Clock from '../../assets/icons/Clock.png';
import type { StarRatingType } from '../inbox/components/tracker/OrderSummaryStepper';
import { StarRatingInput } from '../inbox/components/tracker/OrderSummaryStepper';
import { useFeedbackReasons, useGetStarRating } from './Feedback.util';
import { FeedbackReasonCodeCategoryCell } from './FeedbackItemProperties';
import { FeedbackActions } from './state/feedback.actions';
import type { FeedbackItemPropsScreen } from './state/feedback.reducer';
import { useFeedbackSelector } from './state/feedback.selectors';
import { useSubmitFeedbackV3 } from './state/useSubmitFeedback';
import { FeedbackAndRefabBodyContainer } from '@orthly/dentin';
import type { LabsGqlReasonCodeOptionFragment } from '@orthly/graphql-operations';
import { LabsGqlReasonCodeGroup } from '@orthly/graphql-schema';
import { isArrayMin2 } from '@orthly/runtime-utils';
import { SimpleInput } from '@orthly/ui';
import {
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    InputAdornment,
    Blue,
    Text,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = stylesFactory(theme => ({
    root: {
        maxHeight: `calc(100vh - 72px)`,
        // 424px is width of the FeedbackSidebar for the desktop feedback flow
        maxWidth: `calc(100vw - 424px)`,
        marginLeft: `5px`,
        [theme.breakpoints.down('lg')]: {
            marginLeft: `0px`,
            maxWidth: 'none',
        },
    },
    categoryTitle: {
        marginBottom: `16px`,
        [theme.breakpoints.down('sm')]: {
            marginLeft: `4px`,
        },
    },
}));

export const ChairsideAdjustmentMinutesInputSection: React.VFC<{
    itemId: string;
}> = ({ itemId }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const savedMinutes = useFeedbackSelector(state => String(state.items[itemId]?.chairside_adjustment_minutes ?? ''));
    const [liveMinutes, setLiveMinutes] = React.useState(savedMinutes); // can take invalid (NaN or negative) values
    const checkInvalid = React.useCallback((val: string) => (val !== '' && Number.isNaN(+val)) || +val < 0, []);
    const isInvalid = checkInvalid(liveMinutes);
    const dispatch = useDispatch();
    const updateChairsideAdjustmentMinutes = React.useCallback(
        (val?: string) => {
            const newLiveMinutes = val ?? '';
            setLiveMinutes(newLiveMinutes);
            if (!checkInvalid(newLiveMinutes)) {
                dispatch(
                    FeedbackActions.SET_ITEM_CHAIRSIDE_ADJUSTMENT_MINUTES({
                        itemId,
                        chairsideAdjustmentMinutes: newLiveMinutes === '' ? null : +newLiveMinutes,
                    }),
                );
            }
        },
        [checkInvalid, setLiveMinutes, dispatch, itemId],
    );
    return (
        <>
            <Text variant={isMobile ? `h6` : `h5`} style={{ marginBottom: 16 }}>
                How long did you spend making adjustments?
            </Text>
            <SimpleInput
                label={`Minutes`}
                variant={`standard`}
                onChange={updateChairsideAdjustmentMinutes}
                value={liveMinutes}
                data-test={`feedback-chairside-adjustment-minutes-input`}
                TextFieldProps={{
                    InputProps: {
                        endAdornment: (
                            <InputAdornment position={`end`} style={{ height: 30, paddingBottom: 4 }}>
                                <img src={Clock} style={{ marginRight: 18, height: 18, width: 18 }} alt={``} />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                        type: `tel`,
                        style: { borderRadius: `8px` },
                    },
                    error: isInvalid,
                    helperText: isInvalid ? `Please enter a valid non-negative number` : ``,
                    style: { width: `100%` },
                }}
            />
        </>
    );
};

export const ItemNotesInputSection: React.VFC<{
    itemId: string;
}> = ({ itemId }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const itemNotes = useFeedbackSelector(state => state.items[itemId]?.notes ?? '');
    const dispatch = useDispatch();
    const updateItemNotes = React.useCallback(
        (itemNotes?: string) => dispatch(FeedbackActions.SET_ITEM_NOTES({ itemId, notes: itemNotes ?? '' })),
        [itemId, dispatch],
    );

    return (
        <>
            <Text variant={isMobile ? `h6` : `h5`} style={{ marginBottom: 16 }}>
                Anything else we should know?
            </Text>
            <SimpleInput
                label={'Add a comment…'}
                onChange={updateItemNotes}
                value={itemNotes}
                data-test={'feedback-item-additional-notes'}
                TextFieldProps={{
                    style: { width: `100%`, justifyContent: 'flex-start' },
                    multiline: true,
                    rows: isMobile ? 8 : 2,
                    InputProps: {
                        type: 'text',
                    },
                }}
            />
        </>
    );
};

interface FeedbackItemProps {
    itemId: string;
    reasonCodes?: LabsGqlReasonCodeOptionFragment[];
    starRating: StarRatingType;
}

const FeedbackItemProperties: React.VFC<FeedbackItemProps> = ({ itemId, reasonCodes, starRating }) => {
    const tags = useFeedbackSelector(state => state.items[itemId]?.tags) ?? {};
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const fitGroupCodes = reasonCodes?.filter(reasonCode => reasonCode.group === LabsGqlReasonCodeGroup.Fit);
    const fitGroupCategories = _.uniq(fitGroupCodes?.map(reasonCode => reasonCode.category));
    const aestheticGroupCodes = reasonCodes?.filter(rc => rc.group === LabsGqlReasonCodeGroup.Aesthetic);
    const aestheticGroupCategories = _.uniq(aestheticGroupCodes?.map(reasonCode => reasonCode.category));
    const showNotes = Object.values(tags).some(reason_code => reason_code.title !== 'Other');

    return (
        <Grid container className={classes.root} spacing={2}>
            <Grid item>
                <div style={{ paddingBottom: 10 }}>
                    <StarRatingInput rating={starRating} readonly={true} />
                </div>
            </Grid>
            {isMobile && (
                <Grid xs={12} item>
                    <div style={{ paddingBottom: 10 }}>
                        <ChairsideAdjustmentMinutesInputSection itemId={itemId} />
                    </div>
                </Grid>
            )}
            <Grid container spacing={isMobile ? 1 : 2} style={{ paddingBottom: 10, marginLeft: 0, paddingRight: 12 }}>
                {!!aestheticGroupCodes?.length && (
                    <Grid item xs={12} lg={6}>
                        <Text variant={isMobile ? 'h6' : `h5`} medium className={classes.categoryTitle}>
                            Product Aesthetic
                        </Text>
                        {aestheticGroupCategories.map(category => (
                            <FeedbackReasonCodeCategoryCell
                                key={category}
                                itemId={itemId}
                                reasonCodes={aestheticGroupCodes}
                                category={category}
                            />
                        ))}
                    </Grid>
                )}
                {!!fitGroupCodes?.length && (
                    <Grid item xs={12} lg={6}>
                        <Text variant={isMobile ? 'h6' : `h5`} medium className={classes.categoryTitle}>
                            Product Fit
                        </Text>
                        {fitGroupCategories.map(category => (
                            <FeedbackReasonCodeCategoryCell
                                key={category}
                                itemId={itemId}
                                reasonCodes={fitGroupCodes}
                                category={category}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
            {!isMobile && (
                <Grid item xs={6}>
                    <ChairsideAdjustmentMinutesInputSection itemId={itemId} />
                </Grid>
            )}
            {showNotes && (
                <Grid item xs={isMobile ? 12 : 6} style={{ paddingBottom: isMobile ? 25 : 0 }}>
                    <ItemNotesInputSection itemId={itemId} />
                </Grid>
            )}
        </Grid>
    );
};

export const FeedbackItemPropertiesScreen: React.VFC<{
    screen: FeedbackItemPropsScreen;
}> = ({ screen }) => {
    const { items, item, previous_screen } = screen;
    const dispatch = useDispatch();
    const tags = useFeedbackSelector(state => state.items[item.id]?.tags) ?? {};
    const { submit } = useSubmitFeedbackV3();
    const starRating = useGetStarRating(item);
    const {
        feedbackReasons,
        isCompleted: areReasonCodesFetched,
        isLoading: areReasonCodesLoading,
    } = useFeedbackReasons(item);
    // Sort reason codes so that 'Other' always appears at the end of the list
    const sortedReasonCodes = _.sortBy(feedbackReasons, rc => rc.title.toLowerCase() === 'other');

    React.useEffect(() => {
        dispatch(FeedbackActions.SET_ITEM_STAR_RATING({ itemId: item.id, starRating }));
    }, [item, starRating, dispatch]);

    const hideBack = screen.previous_screen === undefined || items.length === 1;

    const onNext = () => {
        if (isArrayMin2(items)) {
            dispatch(FeedbackActions.GO_TO({ items, id: 'item_select', previous_screen: screen }));
        } else {
            submit();
        }
    };

    React.useEffect(() => {
        // If there are no valid reason to select, we go straight to asking for notes
        if (areReasonCodesFetched && areReasonCodesLoading === false && feedbackReasons.length === 0) {
            // Since we're skipping this screen, we keep previous_screen intact, so if user goes back, they go back to item selection
            dispatch(FeedbackActions.GO_TO({ items, item, id: 'item_notes', previous_screen }));
        }
    }, [areReasonCodesFetched, areReasonCodesLoading, feedbackReasons, dispatch, items, item, previous_screen]);

    // If we have 0 reason, the hook above will trigger to move us to the item_notes screen
    // We return null so the UI doesn't even try to render this component while we're switching
    if (feedbackReasons.length === 0) {
        return null;
    }

    return (
        <FeedbackAndRefabBodyContainer
            title={
                <>
                    What could we have done better in {item.fullDisplayName}
                    <Blue>?</Blue>
                </>
            }
            onBack={hideBack ? undefined : () => dispatch(FeedbackActions.GO_BACK(screen))}
            onNext={onNext}
            nextTitle={items[1] ? 'Next' : 'Submit feedback'}
            disableNext={_.isEmpty(tags)}
        >
            <>
                <FeedbackItemProperties itemId={item.id} reasonCodes={sortedReasonCodes} starRating={starRating} />
            </>
        </FeedbackAndRefabBodyContainer>
    );
};
