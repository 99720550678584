import { useOrderDetailSection } from '../../OrderDetailSectionProvider';
import { OrderDetailSection } from './OrderDetailSection';
import type { OrderDetailSharedProps } from './OrderDetailSection.types';
import { ScanExportModelViewerBlock } from '@orthly/veneer';
import React from 'react';

export const OrderDetailScansSection: React.FC<OrderDetailSharedProps> = ({ order }) => {
    const { setScansRefs } = useOrderDetailSection();

    return (
        <OrderDetailSection title={'Scans'} ref={setScansRefs}>
            <ScanExportModelViewerBlock order={order} />
        </OrderDetailSection>
    );
};
