import { TrainingDialogStage } from '../TrainingDataIntake.types';
import { useCreateTrainingIntakeSurveyResponse } from './useCreateTrainingIntakeSurveyResponse.graphql';
import { useTrainees } from './useTrainees';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';

export const useCanSubmitTrainingData = (stage: TrainingDialogStage) => {
    const { dentistsAreValid } = useTrainees();

    if (stage === TrainingDialogStage.FIRST) {
        return true;
    }

    return dentistsAreValid;
};

export const useOnSubmitTrainingDataAction = ({
    stage,
    setStage,
    refetch,
    onError,
    onSuccess,
}: {
    stage: TrainingDialogStage;
    setStage: (stage: TrainingDialogStage) => void;
    refetch?: () => void;
    onError?: () => void;
    onSuccess?: () => void;
}) => {
    const { dentists, trainees, traineesAreValid, setTrainees } = useTrainees();
    const { submit } = useCreateTrainingIntakeSurveyResponse();

    return async () => {
        setTrainees(oldValue => oldValue.map(trainee => ({ ...trainee, showInvalidFields: true })));

        if (!traineesAreValid) {
            return;
        }

        if (stage === TrainingDialogStage.FIRST && dentists.length > 0) {
            setStage(TrainingDialogStage.SECOND);
            return;
        }

        if ((stage === TrainingDialogStage.FIRST && dentists.length === 0) || stage === TrainingDialogStage.SECOND) {
            BrowserAnalyticsClientFactory?.Instance?.track('Practice - Onboarding - Training Data Intake Submitted', {
                hasDentist: dentists.length > 0,
            });
            await submit(
                trainees.map(trainee => ({
                    name: trainee.name ?? '',
                    role: trainee.role ?? '',
                    experience: trainee.experience,
                    scanner: trainee.scanner,
                    material: trainee.material,
                    retractions: trainee.retractions,
                    prepStyles: trainee.prepStyles,
                    willAdapt: trainee.willAdapt,
                })),
            )
                .then(onSuccess)
                .catch(onError);

            refetch?.();
        }
    };
};
