import { FlossPalette, Backdrop } from '@orthly/ui-primitives';
import React from 'react';

interface DandyBackdropProps {
    open: boolean;
    navbarHeight: number;
}

export const DandyBackdrop: React.FC<DandyBackdropProps> = ({ open, navbarHeight }) => {
    return (
        <Backdrop
            open={open}
            data-test={'backdrop'}
            style={{
                height: '100vh',
                zIndex: 1499,
                top: navbarHeight,
                background: FlossPalette.STROKE_LIGHT,
            }}
        />
    );
};
