import type { OrderTimelineV2Item } from '../../../../../PracticeOrderChatV2';
import { OrderTimelineAvatar } from './OrderTimelineAvatar';
import { stylesFactory, Text } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    title: {
        display: 'flex',
        gap: '0.5ch',
        alignItems: 'baseline',
    },
}));

export const OrderTimelineTitle: React.FC<{
    actor: OrderTimelineV2Item['actor'];
    className?: string;
    showAvatar: boolean | null;
}> = ({ actor, children, className, showAvatar }) => {
    const classes = useStyles();

    return (
        <Text variant={'caption'} className={classNames(classes.title, className)}>
            {showAvatar && <OrderTimelineAvatar actor={actor} />}
            {children}
        </Text>
    );
};
