import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { ImageTypeUploadOrder, ImageTypeUploadRequired } from '../state/aligners-checkout.selectors';
import { useMobileCheckoutStyles } from './MobileCheckout-styles';
import type { UploadedImageSourceMap } from './MobileCheckout.util';
import { ImageTypeToTitleMap } from './MobileCheckout.util';
import type { LabsGqlAlignerCasePhotoType as AlignerPhotoType } from '@orthly/graphql-schema';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, OrthlyFrontendApps, XIcon } from '@orthly/ui';
import { FlossPalette, Grid, IconButton, Slide, Text } from '@orthly/ui-primitives';
import { useFirebasePreview, ImageUploader } from '@orthly/veneer';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import Carousel from 'react-material-ui-carousel';

interface MobileCheckoutUploadPopupProps {
    setUploadOpen: (changed: boolean) => void;
    setUploadState: Dispatch<SetStateAction<AlignerPhotoType>>;
    setImgSrcMap: Dispatch<SetStateAction<UploadedImageSourceMap>>;
    setSkippedImages: Dispatch<SetStateAction<{ [K in AlignerPhotoType]?: boolean }>>;
    skippedImages: { [K in AlignerPhotoType]?: boolean };
    imgSrcMap: UploadedImageSourceMap;
    scanId: string;
    numOpens: number;
}

interface MobileCheckoutCarouselItemProps extends MobileCheckoutUploadPopupProps {
    type: AlignerPhotoType;
}

interface MobileCheckoutUploadPopupRootProps extends MobileCheckoutUploadPopupProps {
    uploadState: AlignerPhotoType;
    uploadOpen: boolean;
}

const MobileCheckoutCarouselItem: React.FC<MobileCheckoutCarouselItemProps> = props => {
    const classes = useMobileCheckoutStyles();
    const {
        type,
        scanId,
        setImgSrcMap,
        imgSrcMap,
        numOpens,
        setUploadState,
        setUploadOpen,
        skippedImages,
        setSkippedImages,
    } = props;
    const examplePhoto = `/checkout/examples/${type}-example.png`;
    const config = new OrthlyBrowserConfig(OrthlyFrontendApps.practice);
    const uploadedGCSPath = imgSrcMap[type] ?? undefined;
    const { execute } = useFirebasePreview();
    const [showExampleDisabled, setShowExampleDisabled] = React.useState(false);
    const setNextIndex = React.useCallback(
        (newImgSrcMap: UploadedImageSourceMap) => {
            setTimeout(() => {
                const nextImage = ImageTypeUploadOrder.find(val => !skippedImages[val] && !newImgSrcMap[val]);
                if (nextImage) {
                    setUploadState(nextImage);
                    return;
                }
                setShowExampleDisabled(false);
                setUploadOpen(false);
            }, 3000);
        },
        [setUploadState, setUploadOpen, setShowExampleDisabled, skippedImages],
    );
    const setPhotoSkipped = React.useCallback(() => {
        setSkippedImages(state => ({ ...state, [type]: true }));
        const nextImage = ImageTypeUploadOrder.find(val => val !== type && !skippedImages[val] && !imgSrcMap[val]);
        if (nextImage) {
            setUploadState(nextImage);
            return;
        }
        setShowExampleDisabled(false);
        setUploadOpen(false);
    }, [setUploadState, setUploadOpen, setShowExampleDisabled, imgSrcMap, skippedImages, setSkippedImages, type]);

    const storagePathConfig = getFullStoragePath(config.env, OrderingStorageConfigs.aligners, scanId);
    return (
        <Grid container className={classes.carouselItemWrapper}>
            <Grid container className={classes.carouselItem}>
                <ImageUploader
                    key={`${numOpens}`}
                    storagePathConfig={storagePathConfig}
                    autoSubmit
                    deleteOnReset
                    replaceExisting
                    overrideFileName={type}
                    processImages
                    showExampleDisabled={showExampleDisabled}
                    examplePhoto={examplePhoto}
                    initialSrc={uploadedGCSPath}
                    exampleStyle={{
                        borderRadius: 8,
                        height: 264,
                        overflow: 'hidden',
                        alignContent: 'center',
                        justifyContent: 'center',
                        position: 'relative',
                    }}
                    ImageTag={
                        <div className={classes.imageTag}>
                            <Text variant={'body2'} color={'WHITE'} style={{ fontWeight: 500 }}>
                                Example
                            </Text>
                        </div>
                    }
                    onReset={() => setImgSrcMap && setImgSrcMap(state => ({ ...state, [type]: null }))}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onComplete={async results => {
                        // Safety check
                        if (!results[0]) {
                            window.alert('Could not upload image');
                            return;
                        }
                        const url = await execute(results[0]?.uploadedPath);
                        const newImgSrcMap = { ...imgSrcMap, [type]: url };
                        setImgSrcMap && setImgSrcMap(newImgSrcMap);
                        setShowExampleDisabled(true);
                        setNextIndex(newImgSrcMap);

                        AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                            productName: 'aligners',
                            isMobile: true,
                        });
                    }}
                    onSkipImage={
                        !ImageTypeUploadRequired[type] && !imgSrcMap[type] ? () => setPhotoSkipped() : undefined
                    }
                    style={{ width: '100%', marginTop: 32 }}
                />
            </Grid>
        </Grid>
    );
};

export const MobileCheckoutUploadPopup: React.FC<MobileCheckoutUploadPopupRootProps> = props => {
    const {
        setUploadState,
        setUploadOpen,
        uploadState,
        uploadOpen,
        setImgSrcMap,
        imgSrcMap,
        scanId,
        numOpens,
        skippedImages,
        setSkippedImages,
    } = props;
    const currentIndex = ImageTypeUploadOrder.findIndex(type => type === uploadState);
    const nextIndex = currentIndex >= ImageTypeUploadOrder.length - 1 ? 0 : currentIndex + 1;
    const prevIndex = currentIndex === 0 ? ImageTypeUploadOrder.length - 1 : currentIndex - 1;
    const classes = useMobileCheckoutStyles();

    return (
        <Slide
            direction={'up'}
            in={uploadOpen}
            style={{
                position: 'absolute',
                top: 0,
                zIndex: 1203,
                width: '100%',
                backgroundColor: FlossPalette.WHITE,
                height: '100%',
            }}
        >
            <Grid container direction={'column'}>
                <Grid container justifyContent={'flex-end'}>
                    <IconButton onClick={() => setUploadOpen(false)}>
                        <XIcon />
                    </IconButton>
                </Grid>
                <Text variant={'h6'} style={{ width: '100%' }} align={'center'}>
                    Take a photo of the
                </Text>
                <Text variant={'h4'} color={'BURGUNDY'} style={{ width: '100%' }} align={'center'}>
                    {ImageTypeToTitleMap[uploadState]}
                </Text>
                {!ImageTypeUploadRequired[uploadState] && (
                    <Grid container justifyContent={'center'}>
                        <Text
                            variant={'caption'}
                            color={'WHITE'}
                            style={{
                                width: 'max-content',
                                backgroundColor: FlossPalette.GRAY,
                                paddingTop: 4,
                                paddingBottom: 4,
                                paddingLeft: 8,
                                paddingRight: 8,
                            }}
                            align={'center'}
                        >
                            This photo is optional
                        </Text>
                    </Grid>
                )}
                <Carousel
                    animation={'slide'}
                    autoPlay={false}
                    next={() => {
                        const nextType = ImageTypeUploadOrder[nextIndex];
                        nextType && setUploadState(nextType);
                    }}
                    prev={() => {
                        const prevType = ImageTypeUploadOrder[prevIndex];
                        prevType && setUploadState(prevType);
                    }}
                    index={currentIndex}
                    activeIndicatorIconButtonProps={{ style: { color: FlossPalette.STAR_GRASS } }}
                    indicatorIconButtonProps={{ style: { color: FlossPalette.DARK_TAN } }}
                    navButtonsAlwaysInvisible={true}
                    className={classes.carousel}
                >
                    {ImageTypeUploadOrder.map(type => (
                        <MobileCheckoutCarouselItem
                            type={type}
                            key={type}
                            scanId={scanId}
                            setImgSrcMap={setImgSrcMap}
                            imgSrcMap={imgSrcMap}
                            numOpens={numOpens}
                            setUploadState={setUploadState}
                            setUploadOpen={setUploadOpen}
                            skippedImages={skippedImages}
                            setSkippedImages={setSkippedImages}
                        />
                    ))}
                </Carousel>
            </Grid>
        </Slide>
    );
};
