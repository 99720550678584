import type {
    LabsGqlDoctorDeliveryAddressFragment,
    LabsGqlDoctorPreferencesFragment,
    LabsGqlPartnerNotificationPreferencesFragment,
} from '@orthly/graphql-operations';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'onboarding';

export interface CalculateOnboardingStateArgs {
    addresses: LabsGqlDoctorDeliveryAddressFragment[];
    addressesLoading: boolean;
    preferences: LabsGqlDoctorPreferencesFragment[];
    preferencesLoading: boolean;
    notifications?: LabsGqlPartnerNotificationPreferencesFragment | null;
    notificationsLoading: boolean;
}

export const OnboardingActions = {
    INITIALIZE_STATE: createSyncAction<CalculateOnboardingStateArgs, [CalculateOnboardingStateArgs]>(
        `${PREFIX}/INITIALIZE_STATE`,
    ),
    BACK: createSyncAction<undefined, []>(`${PREFIX}/BACK`),
    NEXT: createSyncAction<undefined, []>(`${PREFIX}/NEXT`),
};

export const useOnboardingAction = generateUseActionHook<typeof OnboardingActions>(OnboardingActions);
