import { WebcamIcon } from '@orthly/ui';
import { Button, FlossPalette, stylesFactory, Text, useScreenIsMd, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';
import { useDropzone } from 'react-dropzone';

const useStyles = stylesFactory(theme => ({
    flex: {
        flexWrap: 'nowrap',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'stretch',
        flexDirection: 'row',
        flexGrow: 1,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            flexGrow: 'unset',
        },
        '&>*:first-child': {
            zIndex: 1,
        },
    },
    cameraOverlay: {
        alignSelf: 'stretch',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cameraEmpty: {
        borderRadius: 16,
        backgroundColor: '#F4F3F0',
        border: `8px solid ${FlossPalette.WHITE}`,
        padding: 24,
    },
}));

export const WebcamCaptureInstruction: React.VFC<{
    onNext: () => void;
    onUpload: (blob: Blob) => void;
    existingPreview?: React.ReactNode;
}> = ({ onNext, onUpload, existingPreview }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMd();
    const {
        getRootProps,
        getInputProps,
        open: openUpload,
    } = useDropzone({
        noClick: true,
        onDropAccepted: ([file]) => {
            if (file) {
                onUpload(file);
            }
        },
    });
    return (
        <Grid container className={clsx(classes.cameraOverlay, classes.cameraEmpty)} {...getRootProps()}>
            <input {...getInputProps()} />
            {existingPreview}
            <WebcamIcon />
            <Text variant={'h6'} medium>
                {existingPreview ? 'Want a new photo?' : 'Take a photo'}
            </Text>
            <Text variant={'body2'}>Use the webcam on your {isMobile ? 'device' : 'laptop'} to take a photo</Text>
            <Button variant={'primary'} onClick={onNext} style={{ marginTop: 16 }}>
                Use your webcam
            </Button>
            <Button variant={'secondary'} style={{ backgroundColor: 'unset' }} onClick={openUpload}>
                Upload a photo
            </Button>
        </Grid>
    );
};
