import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { useCheckoutSelector } from '../../../../redux';
import { useDentureCheckoutStepInfo } from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { denturesPhotosBannerConfig } from './DenturePhotoUploadChoiceScreen';
import { useDentureUploadedPhotos } from './dentures-upload-shared';
import { CheckoutBodyWrapper, CheckoutBanner } from '@orthly/dentin';
import { Grid } from '@orthly/ui-primitives';
import { PhotoUpload, denturePhotoUploadConfig } from '@orthly/veneer';
import React from 'react';

const InitialDenturePhotoUploadReviewBody: React.VFC = () => {
    const scan = useCheckoutSelector(s => s.scan);
    const uploadState = useDentureUploadedPhotos(scan?.id);

    return (
        <PhotoUpload
            uploadConfig={denturePhotoUploadConfig}
            uploadState={uploadState}
            viewInstructionsOptions={{
                showButton: false,
                onClick: () => {},
            }}
            scanID={scan?.id}
            isDesktop={true}
            hideTitle={true}
            onUpload={() => {
                AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                    productName: denturePhotoUploadConfig.firestoreProductName,
                    isMobile: false,
                });
            }}
        />
    );
};

export const InitialDenturePhotoUploadReviewScreen: React.VFC = () => {
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(
        DentureCheckoutStep.InitialNonWaxRimOrderPhotosReviewStep,
    );
    if (!isActiveStep) {
        return null;
    }
    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <Grid container alignContent={'flex-start'}>
                <Grid item xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
                    <CheckoutBanner config={denturesPhotosBannerConfig} />
                </Grid>
                <InitialDenturePhotoUploadReviewBody />
            </Grid>
        </CheckoutBodyWrapper>
    );
};
