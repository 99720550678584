import { useCheckoutAction } from '../../state/checkout.actions';
import type { ImplantScreenInterface } from '../../state/reducers/implantScreens';
import {
    getSelectedAbutmentMaterialValue,
    getSelectedCrownMaterialValue,
    getSelectedRetention,
} from './implantSelectionGetters';
import { CheckoutRadioIcon } from '@orthly/dentin';
import { OrderItemLinkRelationship as RetentionType } from '@orthly/items';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    materialOptionCard: {
        backgroundColor: FlossPalette.TAN,
        border: `2px solid ${FlossPalette.TAN}`,
        marginBottom: 16,
        padding: 18,
        cursor: 'pointer',
        borderRadius: 4,
    },
    checkedCard: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        border: `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
    },
}));

interface AbutmentMaterialOption {
    value: string;
    label?: string;
    subtitle: string;
    hidden?: (retentionType?: RetentionType, crownMaterial?: string) => boolean;
}
const abutmentMaterialOptions: AbutmentMaterialOption[] = [
    {
        value: 'Dandy Recommended',
        subtitle:
            'Most popular option. Allows Dandy to select optimal abutment material for a successful final restoration.',
    },
    {
        // Remove this option for Emax
        value: 'Custom Titanium Abutment',
        subtitle: 'Allows us to create an abutment with crown and tissue support.',
    },
    {
        value: 'Ti-Base',
        subtitle: 'A stock titanium component. Creates an interface between the crown and the implant.',
        hidden: (retentionType, crownMaterial) => {
            const isScrewRetained = retentionType === RetentionType.ScrewRetained;
            const isEmax = ['Emax', 'Emax Full Contour', 'Emax Layered Porcelain'].includes(crownMaterial ?? '');
            const visible = isScrewRetained && !isEmax;
            return !visible;
        },
    },
    {
        value: 'Custom Zirconia Hybrid Abutment',
        subtitle: 'Zirconia and titanium custom milled abutment. Eliminates any gray or gold tones from the titanium. ',
    },
];

interface AbutmentMaterialCardProps {
    materialOption: AbutmentMaterialOption;
    selectedMaterialValue?: string;
    item_index: number;
    retentionType?: RetentionType;
    crownMaterial?: string;
}
const AbutmentMaterialCard: React.VFC<AbutmentMaterialCardProps> = props => {
    const { materialOption, selectedMaterialValue, item_index, retentionType, crownMaterial } = props;
    const classes = useStyles();
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');
    const checked = materialOption.value === selectedMaterialValue;
    const onClick = React.useCallback(
        () =>
            !checked &&
            updateItem({ item_index, change: { name: 'abutment_material', payload: materialOption.value } }),
        [checked, materialOption.value, updateItem, item_index],
    );
    const hidden = materialOption.hidden?.(retentionType, crownMaterial);
    React.useEffect(() => {
        if (!selectedMaterialValue || (hidden && checked)) {
            // 'Dandy Recommended' by default, and also if chosen opt is hidden (due to crown/retention changes).
            updateItem({ item_index, change: { name: 'abutment_material', payload: 'Dandy Recommended' } });
        }
    }, [selectedMaterialValue, hidden, checked, updateItem, item_index]);
    if (hidden) {
        return null;
    }
    return (
        <div className={cx(classes.materialOptionCard, checked ? classes.checkedCard : '')} onClick={onClick}>
            <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={1}>
                    <CheckoutRadioIcon checked={checked} />
                </Grid>
                <Grid item xs={11}>
                    <Text variant={'body2'} medium style={{ marginBottom: 4 }}>
                        {materialOption.label ?? materialOption.value}
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ maxWidth: 540 }}>
                        {materialOption.subtitle}
                    </Text>
                </Grid>
            </Grid>
        </div>
    );
};

export const AbutmentMaterialScreen: ImplantScreenInterface = {
    title: 'Select the abutment material',
    Component: ({ item }) => {
        const selectedRetention = getSelectedRetention(item);
        const selectedCrownMaterialValue = getSelectedCrownMaterialValue(item);
        const selectedAbutmentMaterialValue = getSelectedAbutmentMaterialValue(item);
        return (
            <Grid container direction={'row'} style={{ marginTop: 24 }}>
                {abutmentMaterialOptions.map(materialOption => (
                    <Grid item xs={8} key={materialOption.value}>
                        <AbutmentMaterialCard
                            materialOption={materialOption}
                            selectedMaterialValue={selectedAbutmentMaterialValue}
                            item_index={item.item_index}
                            crownMaterial={selectedCrownMaterialValue}
                            retentionType={selectedRetention}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    },
    enableNext: ({ item }) => !!getSelectedAbutmentMaterialValue(item),
};
