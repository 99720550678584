import { useOpenOrderDetailPage } from '../../LabsUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { OrderDetailRefabBlock } from '@orthly/veneer';
import React from 'react';

interface OrderDetailsRefabsProps {
    order: LabsGqlOrder;
}

export const OrderDetailsRefabs: React.FC<OrderDetailsRefabsProps> = props => {
    const { order } = props;
    const openOrder = useOpenOrderDetailPage();

    return <OrderDetailRefabBlock order={order} openOrder={openOrder} />;
};
