import { useCurrentProfileIsDoctor, useCurrentSessionIsAdmin } from '../../../../../../redux';
import { useStaffForm } from '../useStaffForm';
import { CheckboxLabel } from './components/CheckboxLabel';
import { Section } from './components/Section';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { CheckboxesGroup, FormHelperText, styled } from '@orthly/ui-primitives';

const RolesContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
});

const roleOptions = [
    {
        id: LabsGqlStaffRolePractice.Admin,
        label: 'Admin',
        helpText: 'Give permissions to manage settings for this practice',
    },
    {
        id: LabsGqlStaffRolePractice.PracticeBilling,
        label: 'Billing Manager',
        helpText: 'By setting a billing manager, you allow Dandy to contact this person for any billing related issues',
    },
    {
        id: LabsGqlStaffRolePractice.PracticeAssistant,
        label: 'Dental Assistant',
    },
    {
        id: LabsGqlStaffRolePractice.PracticeDoctor,
        label: 'Dentist',
    },
    {
        id: LabsGqlStaffRolePractice.PracticeOfficeManager,
        label: 'Office Manager',
    },
    {
        id: LabsGqlStaffRolePractice.PracticeReceptionist,
        label: 'Receptionist',
    },
] satisfies { id: LabsGqlStaffRolePractice; label: string; helpText?: string }[];

export const RolesSection: React.FC = () => {
    const session = useSession();
    const { handleRoleChange, roles, rolesError, setShowRolesError, staffUser } = useStaffForm();
    const isAdminSession = useCurrentSessionIsAdmin() ?? false;
    const isDoctorSession = useCurrentProfileIsDoctor() ?? false;

    return (
        <Section title={'Role(s)'} subtitle={'Personalizes the practice portal'} isRequired>
            <RolesContainer>
                <CheckboxesGroup
                    disabled={!isAdminSession && !isDoctorSession}
                    options={roleOptions.map(role => ({
                        id: role.id,
                        label: <CheckboxLabel helpText={role.helpText}>{role.label}</CheckboxLabel>,
                        disabled:
                            // You may not set the admin role if you are not an admin yourself
                            // and you may not remove the admin role from yourself
                            role.id === LabsGqlStaffRolePractice.Admin &&
                            (!isAdminSession || staffUser?.staff_member_id === session?.id),
                    }))}
                    handleChange={(...args) => {
                        setShowRolesError(true);
                        handleRoleChange(...args);
                    }}
                    values={Object.fromEntries(roleOptions.map(role => [role.id, roles.includes(role.id)]))}
                    hasColumns
                    columnWidth={'160px'}
                    fullWidth
                    variant={'compact'}
                />
                {rolesError && <FormHelperText error>{rolesError}</FormHelperText>}
            </RolesContainer>
        </Section>
    );
};
