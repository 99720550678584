import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import { Grid, Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    textSecondary: {
        color: theme.palette.text.secondary,
    },
}));

export const PartialDenturesAddOnsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const classes = useStyles();
    const rows = React.useMemo(
        () => [
            {
                id: 'try-in',
                optionRaw: 'Each Try-In/Setup on Any Order',
                option: (
                    <Grid container direction={'column'}>
                        <Grid item>Each Try-In/Setup on Any Order</Grid>
                        <Grid item>
                            <Text variant={'caption'} className={classes.textSecondary}>
                                (including bite registration if necessary)
                            </Text>
                        </Grid>
                    </Grid>
                ),
                price: getPrice('Additional Try-In/Setup'),
                formattedPrice: formatPrice(
                    getPrice('Additional Try-In/Setup'),
                    formattedPrice => `Add ${formattedPrice} to total price`,
                ),
            },
            {
                id: 'unilateral-flexible',
                option: 'For Unilateral Flexible',
                price: -8000,
                formattedPrice: 'Subtract $80 from total price',
            },
            {
                id: 'unilateral-cast',
                option: 'For Unilateral Cast (Metal or Hybrid)',
                price: -12000,
                formattedPrice: 'Subtract $120 from total price',
            },
        ],
        [classes.textSecondary, getPrice, formatPrice],
    );

    return (
        <PricingGuideTable
            id={'partial-dentures-add-ons'}
            title={'Additional options'}
            columns={[
                {
                    id: 'option',
                    title: 'Option',
                    sortBy: ({ optionRaw, option }) => optionRaw ?? option,
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ formattedPrice }) => formattedPrice,
                },
            ]}
            rows={rows}
        />
    );
};
