import { FlossPalette, Icon, Link, Text, Tooltip, styled } from '@orthly/ui-primitives';
import React from 'react';

const InfoIcon = styled(Icon)({
    color: FlossPalette.GRAY,
});

const LEARN_MORE_URL =
    'https://help.meetdandy.com/hc/en-us/articles/18084888439565-Secure-Login-Methods-for-Protecting-Patient-Health-Data#secure-login-methods-for-protecting-patient-health-data-0';

const TooltipTitle: React.FC = () => (
    <>
        {`If you are using mobile phone number, we'll text you an authorization code. Using email you can use your password to log in. `}
        <Link href={LEARN_MORE_URL} style={{ color: 'white' }} target={'_blank'}>
            <Text bold color={'WHITE'} component={'span'} variant={'caption'}>
                Learn more.
            </Text>
        </Link>
    </>
);

export const LoginTooltip: React.FC = () => (
    <Tooltip placement={'right'} arrow title={<TooltipTitle />}>
        <InfoIcon icon={'InfoOutlinedIcon'} />
    </Tooltip>
);
