import type { StarRatingType } from '../inbox/components/tracker/OrderSummaryStepper';
import { StarRatingInput } from '../inbox/components/tracker/OrderSummaryStepper';
import { FeedbackActions } from './state/feedback.actions';
import type { FeedbackItemSelectScreen, FeedbackScreen } from './state/feedback.reducer';
import { useFeedbackSelector } from './state/feedback.selectors';
import { useSubmitFeedbackV3 } from './state/useSubmitFeedback';
import { firstScreenForItem } from './utils';
import type { FeedbackAndRefabItem } from '@orthly/dentin';
import { FeedbackAndRefabBodyContainer, ProductUnitTypeImages } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import { ActionCard, ChevronRight, PencilOutlinedIcon } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    InfoIcon,
    Text,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = stylesFactory(() => ({
    buttonBase: {
        backgroundColor: FlossPalette.TAN,
        width: '100%',
        borderRadius: 16,
        borderWidth: 2,
        borderColor: FlossPalette.TAN,
        borderStyle: 'solid',
        cursor: 'default',
        '&:hover': { borderColor: FlossPalette.DARK_TAN },
    },
    buttonDesktop: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'stretch',
        padding: 32,
    },
    buttonTopRow: {
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'center',
        gap: '8px',
    },
    buttonMobile: {
        padding: 16,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        display: 'flex',
        flexFlow: 'row',
        alignItems: 'stretch',
    },
}));

interface ItemButtonProps {
    item: FeedbackAndRefabItem;
    isComplete: boolean;
    onChange: (starRating: StarRatingType, item: FeedbackAndRefabItem) => void;
}

const ItemButtonDesktop: React.VFC<ItemButtonProps> = ({ item, isComplete, onChange }) => {
    const styles = useStyles();
    const starRating = useFeedbackSelector(state => state.items[item.id]?.star_rating || null) as StarRatingType;
    return (
        <div className={cx(styles.buttonBase, styles.buttonDesktop)}>
            <div className={styles.buttonTopRow}>
                <Text variant={'h6'} medium>
                    {item.name}
                </Text>
                <div style={{ flexGrow: 1 }} />
                <StarRatingInput
                    rating={starRating}
                    onChange={newRating => onChange(newRating, item)}
                    disabled={isComplete}
                />
            </div>
            <Text variant={'body2'} color={isComplete ? 'BLACK' : 'GRAY'}>
                {isComplete ? 'Feedback Received' : 'Pending Rating'}
            </Text>
            <img
                src={ProductUnitTypeImages.Large[CartItemV2Utils.getProductUnitType(item.labOrderItem)]}
                alt={''}
                style={{ height: 176, alignSelf: 'center', marginTop: 16 }}
            />
        </div>
    );
};
const ItemButtonMobile: React.VFC<ItemButtonProps> = ({ item, isComplete, onChange }) => {
    const styles = useStyles();
    const imgSrc = ProductUnitTypeImages.Large[CartItemV2Utils.getProductUnitType(item.labOrderItem)];
    const RightIcon = isComplete ? PencilOutlinedIcon : ChevronRight;
    const starRating = useFeedbackSelector(state => state.items[item.id]?.star_rating || null) as StarRatingType;
    return (
        <Grid container className={cx(styles.buttonBase, styles.buttonMobile)} alignItems={'center'} direction={'row'}>
            <Grid item>
                <img src={imgSrc} alt={''} style={{ height: 30 }} />
            </Grid>
            <Grid item container direction={'column'}>
                <Grid item style={{ flexGrow: 1 }}>
                    <Text variant={'body2'} medium style={{ paddingLeft: 12 }}>
                        {item.name}
                    </Text>
                </Grid>
                <Grid item>
                    <Text variant={'body2'} medium color={isComplete ? 'BLACK' : 'GRAY'}>
                        {isComplete ? 'Feedback Received' : 'Pending Rating'}
                    </Text>
                </Grid>
                <Grid item>
                    <StarRatingInput
                        rating={starRating}
                        onChange={newRating => onChange(newRating, item)}
                        disabled={isComplete}
                    />
                </Grid>
            </Grid>
            {!isComplete && (
                <Grid item>
                    <RightIcon style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                </Grid>
            )}
        </Grid>
    );
};

// If item === undefined, returns true if *any* item is in history.
const itemInScreenHistory = (screen: FeedbackScreen, item?: FeedbackAndRefabItem): boolean => {
    if ('item' in screen && (!item || screen.item === item)) {
        return true;
    }
    if ('previous_screen' in screen && screen.previous_screen) {
        return itemInScreenHistory(screen.previous_screen, item);
    }
    return false;
};

export const FeedbackItemSelectScreenComponent: React.VFC<{ screen: FeedbackItemSelectScreen }> = ({ screen }) => {
    const { items } = screen;
    const dispatch = useDispatch();
    const { submit } = useSubmitFeedbackV3();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const statefulItems = useFeedbackSelector(state => state.items);
    const completedItems = items.filter(item => {
        return statefulItems[item.id]?.star_rating === 5 || itemInScreenHistory(screen, item);
    });
    // we only want to show the warning banner if the doctor hasn't left feedback for all the items in the order
    // so if there is at least one completed review but not as many reviews as there are items in the order, show the banner
    const showActionCard = !!completedItems.length && completedItems.length < items.length;

    // as long as there is at least one completed review of an item in the order, allow for submission of feedback
    const onNext = completedItems[0] && submit;
    // should only be able to go back if we reached item select screen bc of multi-item order and we've visited the
    // next screen in the flow
    const onBack = screen.previous_screen && (() => dispatch(FeedbackActions.GO_BACK(screen)));

    const onChange = (newRating: StarRatingType, item: FeedbackAndRefabItem) => {
        dispatch(FeedbackActions.SET_ITEM_STAR_RATING({ itemId: item.id, starRating: newRating }));
        dispatch(
            FeedbackActions.GO_TO(
                firstScreenForItem({ screen, item, fiveStarShortcutUsed: false, starRating: newRating }),
            ),
        );
    };

    return (
        <FeedbackAndRefabBodyContainer
            title={
                itemInScreenHistory(screen)
                    ? `Do you want to leave feedback on anything else?`
                    : `What product do you want to leave feedback on?`
            }
            onBack={onBack}
            onNext={onNext}
            nextTitle={`Submit feedback`}
        >
            {items.map(item => (
                <Grid item xs={isMobile ? 12 : 6} key={item.index}>
                    {isMobile ? (
                        <ItemButtonMobile
                            key={item.index}
                            item={item}
                            isComplete={itemInScreenHistory(screen, item)}
                            onChange={onChange}
                        />
                    ) : (
                        <ItemButtonDesktop
                            key={item.index}
                            item={item}
                            isComplete={itemInScreenHistory(screen, item)}
                            onChange={onChange}
                        />
                    )}
                </Grid>
            ))}
            {showActionCard && (
                <Grid item style={{ marginTop: 16, width: '100%' }}>
                    <ActionCard
                        title={`Once you click 'Submit feedback' you will no longer be able to review other items related to this order.`}
                        subtitle={''}
                        variant={'alert'}
                        IconComponent={InfoIcon}
                    />
                </Grid>
            )}
        </FeedbackAndRefabBodyContainer>
    );
};
