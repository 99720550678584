import { SelectStaffContent, SelectStaffFloatButton } from '../components';
import { useSelectStaffAction } from '../state/select-staff.actions';
import { CreateStaffStep } from '../state/select-staff.progression';
import { ResponsibilityOption } from '../state/select-staff.reducer';
import { selectCreateStaffProgression, useSelectStaffSelector } from '../state/select-staff.selectors';
import { objectEntries } from '@orthly/runtime-utils';
import { SimpleCheckbox, SimpleInput } from '@orthly/ui';
import { FlossPalette, stylesFactory, Collapse, Grid, Button, Text } from '@orthly/ui-primitives';
import * as _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    item: {
        padding: 16,
        backgroundColor: FlossPalette.WHITE,
        borderRadius: 16,
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 328px), 1fr))',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        padding: '20px 0',
        gap: '24px',
        [theme.breakpoints.down('lg')]: {
            gap: '16px',
            flexGrow: 1,
            flexShrink: 1,
            overflowY: 'auto',
        },
    },
    nextButton: {
        marginTop: 20,
        alignSelf: 'flex-start',
        [theme.breakpoints.down('lg')]: {
            marginTop: 12,
            alignSelf: 'stretch',
        },
    },
}));

const OptionCheckbox: React.FC<{ value: boolean; onChange: (value: boolean) => void }> = props => {
    const { value, onChange, children } = props;
    const classes = useStyles();
    return (
        <Grid container className={classes.item}>
            <SimpleCheckbox checked={value} setChecked={onChange} label={children} />
        </Grid>
    );
};

const OtherInput: React.FC<{ value?: string; onChange: (value?: string) => void }> = props => {
    const { value, onChange, children } = props;
    const classes = useStyles();
    const expanded = value !== undefined;
    return (
        <Grid container className={classes.item} direction={'column'} wrap={'nowrap'}>
            <SimpleCheckbox
                checked={expanded}
                setChecked={checked => onChange(checked ? '' : undefined)}
                label={children}
            />
            <Collapse in={expanded} style={{ marginLeft: 11 }}>
                <SimpleInput
                    value={value}
                    onChange={onChange}
                    placeholder={'Please provide your responsibilities here'}
                    fullWidth
                    label={''}
                    flossInputConfig={{ backgroundColor: 'gray' }}
                />
            </Collapse>
        </Grid>
    );
};

const ResponsibilityOptionsDisplay: { [k in ResponsibilityOption]: string } = {
    [ResponsibilityOption.CreateNewOrders]: 'Create new orders',
    [ResponsibilityOption.ManageNotifications]: 'Manage notifications',
    [ResponsibilityOption.ChatWithDandy]: 'Chat with Dandy',
    [ResponsibilityOption.ManageBilling]: 'Manage billing and invoices',
    [ResponsibilityOption.TrackOrders]: 'Track and review orders',
};

export const SelectStaffChooseResponsibilities: React.VFC = () => {
    const classes = useStyles();
    const addCreationField = useSelectStaffAction('ADD_STAFF_CREATION_FIELD');
    const prevStep = useSelectStaffAction('PREV_SELECT_STAFF_STEP');
    const nextStep = useSelectStaffAction('NEXT_SELECT_STAFF_STEP');
    const { error } = useSelectStaffSelector(selectCreateStaffProgression);
    const { responsibilities, responsibility_notes } = useSelectStaffSelector(
        ({ profileCreationState }) => profileCreationState,
    );
    const toggleResponsibility = React.useCallback(
        (option: ResponsibilityOption, value: boolean) => {
            if (value) {
                addCreationField({ responsibilities: [...responsibilities, option] });
            } else {
                addCreationField({ responsibilities: _.without(responsibilities, option) });
            }
        },
        [addCreationField, responsibilities],
    );
    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.Responsibilities);
    return (
        <SelectStaffContent show={show}>
            <SelectStaffFloatButton onClick={prevStep}>Return to occupation</SelectStaffFloatButton>
            <Text variant={'h4'} medium>
                What are your responsibilities?
            </Text>
            <Text variant={'body1'} color={'DARK_GRAY'}>
                This information is used to improve and personalize your Dandy experience, as well as improve our
                services.
            </Text>
            <Grid className={classes.grid} style={{ marginTop: 12 }}>
                {objectEntries(ResponsibilityOptionsDisplay).map(([responsibility, display]) => (
                    <OptionCheckbox
                        value={responsibilities.includes(responsibility)}
                        onChange={value => toggleResponsibility(responsibility, value)}
                        key={display}
                    >
                        {display}
                    </OptionCheckbox>
                ))}
                <OtherInput
                    value={responsibility_notes}
                    onChange={responsibility_notes => addCreationField({ responsibility_notes })}
                >
                    Other responsibilities
                </OtherInput>
            </Grid>
            <Button variant={'primary'} onClick={nextStep} className={classes.nextButton} disabled={!!error}>
                Continue
            </Button>
        </SelectStaffContent>
    );
};
