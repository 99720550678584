import { Spotlight } from '@orthly/ui';
import { Button, FlossPalette, stylesFactory, Text } from '@orthly/ui-primitives';
import React from 'react';
import type { UseAsyncReturn } from 'react-async-hook';

const useStyles = stylesFactory(theme => ({
    flex: {
        flexWrap: 'nowrap',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'stretch',
        flexDirection: 'row',
        flexGrow: 1,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            flexGrow: 'unset',
        },
        '&>*:first-child': {
            zIndex: 1,
        },
    },
    cameraOverlay: {
        alignSelf: 'stretch',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cameraEmpty: {
        borderRadius: 16,
        backgroundColor: '#F4F3F0',
        border: `8px solid ${FlossPalette.WHITE}`,
        padding: 24,
    },
}));

export const WebcamCapturePermission: React.VFC<{
    onCancel: () => void;
    mediaStreamRequest: UseAsyncReturn<MediaStream>;
}> = ({ onCancel, mediaStreamRequest }) => {
    const classes = useStyles();
    const execute = React.useCallback(() => {
        void mediaStreamRequest.execute();
    }, [mediaStreamRequest]);
    return (
        <Spotlight container className={classes.cameraOverlay}>
            {mediaStreamRequest.loading ? (
                <Text variant={'h6'} color={'WHITE'} medium>
                    Starting up your camera...
                </Text>
            ) : (
                <>
                    <Text variant={'h6'} color={'WHITE'} medium>
                        Please allow access to your camera
                    </Text>
                    <Button variant={'primary'} onClick={execute} style={{ marginTop: 16 }}>
                        Retry
                    </Button>
                    <Button variant={'secondary'} style={{ backgroundColor: 'unset' }} onClick={onCancel}>
                        Cancel
                    </Button>
                </>
            )}
            {mediaStreamRequest.error && (
                <Text variant={'h6'} color={'ATTENTION_FOREGROUND'} medium>
                    Unable to access your webcam: {mediaStreamRequest.error.message}
                </Text>
            )}
        </Spotlight>
    );
};
