import React from 'react';
import { z } from 'zod';

const createLoginCredentialsSchema = z
    .object({
        password: z.string().min(7, { message: 'Password must be at least 7 characters' }),
        confirmedPassword: z.string(),
        email: z.string().email({ message: 'Invalid email address' }),
    })
    .superRefine(({ password, confirmedPassword }, ctx) => {
        if (password !== confirmedPassword) {
            ctx.addIssue({
                code: 'custom',
                message: 'Passwords do not match',
                path: ['confirmedPassword'],
            });
        }
    });

export const useCreateLoginCredentialsValidation = ({
    password,
    confirmedPassword,
    email,
}: {
    password: string;
    confirmedPassword: string;
    email: string;
}) => {
    const [showPasswordError, setShowPasswordError] = React.useState(false);
    const [showConfirmedPasswordError, setShowConfirmedPasswordError] = React.useState(false);
    const [showEmailError, setShowEmailError] = React.useState(false);

    const validation = React.useMemo(
        () => createLoginCredentialsSchema.safeParse({ password, confirmedPassword, email }),
        [password, confirmedPassword, email],
    );

    if (validation.success) {
        return { isFormValid: true, setShowConfirmedPasswordError, setShowPasswordError, setShowEmailError };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: false,
        setShowConfirmedPasswordError,
        setShowPasswordError,
        setShowEmailError,
        passwordError: showPasswordError && fieldErrors.password?.[0],
        confirmedPasswordError: showConfirmedPasswordError && fieldErrors.confirmedPassword?.[0],
        emailError: showEmailError && fieldErrors.email?.[0],
    };
};
