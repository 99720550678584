import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import type { ValidShadeType, IOrderItemV2DTO } from '@orthly/items';
import { FlossPalette } from '@orthly/ui-primitives';
import capitalize from 'lodash/capitalize';
import React from 'react';

export const ExtraShadesSummary: React.FC<{ item: IOrderItemV2DTO; visibleShades?: ValidShadeType[] }> = props => {
    const { item } = props;
    const {
        ContainerComponent,
        fontComponents: { Text },
    } = useOrderSummaryComponents();

    if (!item.shades?.length) {
        return null;
    }

    const shadeOrder: ValidShadeType[] = props.visibleShades ?? ['gingival', 'base', 'incisal', 'stump'];

    return (
        <ContainerComponent style={{ display: 'flex', flexDirection: 'row', fontSize: '12px' }}>
            {shadeOrder.map(shadeName => {
                const shade = item.shades?.find(shade => shade.name === shadeName);
                const color = shade?.value && shadeName !== 'stump' ? FlossPalette.BLACK : FlossPalette.GRAY;

                if (shadeName === 'stump' && !shade?.value) {
                    return null;
                }

                return (
                    <React.Fragment key={shadeName}>
                        {shadeName === 'stump' && <Text style={{ color }}>{`(`}</Text>}
                        <Text style={{ color, marginRight: '6pt' }}>{capitalize(shadeName)}</Text>
                        <Text
                            style={{
                                color,
                                fontWeight: shade?.value ? 'bold' : undefined,
                                marginRight: shadeName === 'stump' ? undefined : '12pt',
                            }}
                        >
                            {shade?.value ?? `N/A`}
                        </Text>
                        {shadeName === 'stump' && <Text style={{ color, marginRight: '12pt' }}>{`)`}</Text>}
                    </React.Fragment>
                );
            })}
        </ContainerComponent>
    );
};
