import type { SearchAutocompleteOrder } from '../../hooks';
import type { DandySearchOptionProps, DandyRecentSearchOptionProps } from './DandySearchOption';
import { DandySearchOption } from './DandySearchOption';
import { DANDY_SEARCH_WIDTH } from './DandySearchPresenter.constants';
import type { AddSaveCommandArgs } from './DandySearchPresenter.hooks';
import type { SavedSearchItem } from './DandySearchPresenter.types';
import { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { Text, useScreenIsMd, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DandySearchOptionsHeaderProps {
    title: string;
    icon?: React.ReactNode;
}

const DandySearchOptionsHeader: React.VFC<DandySearchOptionsHeaderProps> = props => {
    const { title, icon } = props;

    return (
        <Grid item container direction={'row'} style={{ padding: '8px 16px' }}>
            {icon}
            <Text
                data-test={'search-options-header'}
                variant={'body2'}
                medium
                color={'GRAY'}
                style={{ marginLeft: !!icon ? 8 : 0 }}
            >
                {title}
            </Text>
        </Grid>
    );
};

interface DandySearchOptionsProps {
    search: string;
    loading: boolean;
    orders: SearchAutocompleteOrder[];
    savedSearches: SavedSearchItem[];
    onNavigationAction: (orderId: string | number) => void;
    width?: number;
    saveSearchAction: (saveCommandArgs: AddSaveCommandArgs) => Promise<void>;
}

export const DandySearchOptions: React.VFC<DandySearchOptionsProps> = props => {
    const { search, loading, orders, savedSearches, width, onNavigationAction, saveSearchAction } = props;
    const isMobile = useScreenIsMd();

    const orderOptions = React.useMemo(() => {
        return orders.map<Omit<DandySearchOptionProps, 'refetch' | 'onNavigationAction' | 'saveSearchAction'>>(
            order => ({
                value: order.id,
                label: `${order.patient.first_name} ${order.patient.last_name}`,
                status: order.status,
                sku: order.items_v2[0]?.sku ?? LabsGqlOrderItemSkuType.Other,
                patient_gender: order.patient.gender,
                patient_birthday: order.patient.birthday,
                search_term: search,
            }),
        );
    }, [orders, search]);

    const savedSearchOptions = React.useMemo(() => {
        return savedSearches.map<DandyRecentSearchOptionProps>(search => ({
            value: (search.search?.search as string) ?? '',
            label: search.name,
            patient_gender: search.search?.order_criteria?.find(c => c.filter_id === 'patient_gender')
                ?.comparison_value as string,
            patient_birthday: search.search?.order_criteria?.find(c => c.filter_id === 'patient_birthday')
                ?.comparison_value as string,
        }));
    }, [savedSearches]);

    return (
        <Grid
            container
            direction={'column'}
            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
            // eslint-disable-next-line no-nested-ternary
            style={{ width: width ? `${width}px` : isMobile ? '100%' : DANDY_SEARCH_WIDTH }}
            data-test={'search-options'}
        >
            <Grid item style={{ padding: '8px 0' }}>
                {orderOptions.length > 0 && (
                    <>
                        {orderOptions.map(
                            (option: Omit<DandySearchOptionProps, 'onNavigationAction' | 'saveSearchAction'>) => (
                                <DandySearchOption
                                    key={option.value}
                                    onNavigationAction={onNavigationAction}
                                    saveSearchAction={saveSearchAction}
                                    {...option}
                                />
                            ),
                        )}
                    </>
                )}

                {orderOptions.length === 0 && savedSearchOptions.length > 0 && search.length < 2 && (
                    <>
                        <DandySearchOptionsHeader title={'Recent searches'} />
                        {savedSearchOptions.map((search: DandyRecentSearchOptionProps) => (
                            <DandySearchOption
                                key={search.value}
                                saveSearchAction={saveSearchAction}
                                onNavigationAction={onNavigationAction}
                                {...search}
                            />
                        ))}
                    </>
                )}

                {orderOptions.length === 0 && search.length >= 2 && (
                    <DandySearchOptionsHeader
                        title={loading ? 'Loading...' : `No results for “${search}”... Try looking for something else`}
                    />
                )}
            </Grid>
        </Grid>
    );
};
