import { useCheckoutPropSelector } from '../../../redux';
import type { PartnerAppState } from '../../../redux/types';
import type { CheckoutStepCompletionState } from './checkout.selectors';
import { denturesStepProgression } from './reducers/dentures-checkout.progression';
import type { DentureCheckoutStep, DentureCheckoutState } from './reducers/dentures-checkout.types';
import { CartItemV2Utils, LabOrderItemSKUType, isTeethShade } from '@orthly/items';
import type { TeethShade, ValidShadeType } from '@orthly/items';
import { generateUseStatePropSelector } from '@orthly/redux-async-actions';
import _ from 'lodash';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';

export const useDentureCheckoutSelector: TypedUseSelectorHook<DentureCheckoutState> = <TSelected>(
    selector: (state: DentureCheckoutState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
    return useSelector<PartnerAppState, TSelected>(s => selector(s.dentureCheckout), equalityFn);
};

export const useDentureCheckoutPropSelector = generateUseStatePropSelector(
    useDentureCheckoutSelector,
    'dentureCheckout',
);

export function denturesCheckoutCurrentStep(state: DentureCheckoutState): DentureCheckoutStep {
    return state.currentStep;
}

export function dentureCheckoutStepCompletionState(
    state: DentureCheckoutState,
): CheckoutStepCompletionState & { fieldsRemaining: undefined } {
    const transition = denturesStepProgression(state);

    switch (transition.type) {
        case 'CHANGE_STEP':
            return {
                complete: true,
                fieldsRemaining: undefined,
            };
        case 'NOT_COMPLETE':
            return {
                complete: false,
                reason: transition.reason,
                fieldsRemaining: undefined,
            };
    }
}

export function useScanIsDenture(): boolean {
    const { items } = useCheckoutPropSelector(['items']);
    return items.some(item => CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture));
}

interface DentureCheckoutStepInfo {
    isActiveStep: boolean;
    isComplete: boolean;
}

export function useDentureCheckoutStepInfo(step: DentureCheckoutStep): DentureCheckoutStepInfo {
    const { currentStep, history } = useDentureCheckoutPropSelector(['currentStep', 'history']);

    return {
        isActiveStep: currentStep === step,
        isComplete: history.includes(step),
    };
}

const getDentureCheckoutShade = (state: DentureCheckoutState, shadeName: ValidShadeType): TeethShade | undefined => {
    const shades = state.fields.dentureOptions?.shades ?? null;
    const checkoutItemShade = shades && _.find(shades, shade => shade.name === shadeName);
    const shade = checkoutItemShade?.value;
    return isTeethShade(shade) ? shade : undefined;
};
export const useDentureCheckoutBaseShade = () => useDentureCheckoutSelector(s => getDentureCheckoutShade(s, 'base'));
export const useDentureCheckoutTissueShade = () =>
    useDentureCheckoutSelector(s => getDentureCheckoutShade(s, 'tissue'));
