import { PracticeScreen } from '../../PracticeScreen';
import type { NavMenuButtonProps } from './NavMenu';
import { NavMenu, NavMenuSection } from './NavMenu';
import { SKUScanbodyIcon } from '@orthly/ui';
import { Text, Divider, Grid, Icon } from '@orthly/ui-primitives';
import React from 'react';

const menuButtonConfig: NavMenuButtonProps[] = [{ title: 'Implant Readiness', screen: PracticeScreen.scanbodies }];

const shopMenuButtonConfig = [
    {
        title: 'Accessories',
        subtitle: 'Shade guides, scanner parts, and more',
        externalLink: 'chairside-dandy-accessories',
    },
    {
        title: 'Tech & Setup',
        subtitle: 'Laptop carts and more',
        externalLink: 'chairside-dandy-tech',
    },
    {
        title: 'Marketing Materials',
        subtitle: 'Brochures, stickers, and more',
        externalLink: 'chairside-dandy-marketing',
    },
];

export const HorizontalNavSupplies: React.VFC<{
    enableDandyShopLinks: boolean;
    openExternalLinkAction?: (externalLink: string) => void;
}> = ({ enableDandyShopLinks, openExternalLinkAction }) => {
    const [open, setOpen] = React.useState(false);

    return enableDandyShopLinks ? (
        <NavMenu open={open} setOpen={setOpen} title={'Supplies'} description={'Order supplies for your practice'}>
            <Grid container style={{ padding: '16px 0', width: 480, flexWrap: 'nowrap' }}>
                <NavMenuSection
                    title={'Implant Essentials'}
                    Icon={SKUScanbodyIcon}
                    menuButtons={menuButtonConfig}
                    navigationAction={() => setOpen(false)}
                />
                <Grid item>
                    <Divider orientation={'vertical'} light />
                </Grid>
                <NavMenuSection
                    title={'Dandy Shop'}
                    titleLabel={'New'}
                    Icon={props => <Icon icon={'ShoppingCartOutlined'} {...props} />}
                    menuButtons={shopMenuButtonConfig}
                    navigationAction={() => setOpen(false)}
                    openExternalLinkAction={openExternalLinkAction}
                />
            </Grid>
        </NavMenu>
    ) : (
        <NavMenu
            open={open}
            setOpen={setOpen}
            title={'Supplies'}
            description={'Order supplies for your practice'}
            menuButtons={menuButtonConfig}
        >
            <Grid container style={{ padding: '8px 16px' }}>
                <Text variant={'body2'} color={'GRAY'}>
                    Store coming soon...
                </Text>
            </Grid>
        </NavMenu>
    );
};
