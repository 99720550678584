import { useCurrentProfile } from '../../../../redux';
import { useShowMobileLayout } from '../../../../utils/LayoutUtils';
import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { SkuSelectionCard } from './SkuSelectionCard';
import { WhatsDandyRecomendedBlurb } from './WhatsDandyRecomendedBlurb';
import { ORDER_PREFERENCES_SKU_DEFINITIONS } from '@orthly/dentin';
import { useScreenIsMobileOrVerticalTablet, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const ProfilePreferencesHomeInner: React.VFC = () => {
    const isMobileLayout = useShowMobileLayout();

    return (
        <div style={isMobileLayout ? { padding: '8px 24px 24px 24px' } : { padding: 24 }}>
            <div style={{ paddingTop: 12, paddingBottom: 24 }}>
                <Text variant={'h6'} medium>
                    What products are you interested in?
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    Preference selections will be pre-populated in the ordering experience. You can always make changes
                    on a per-case basis.
                </Text>
            </div>
            <Grid container spacing={2}>
                {ORDER_PREFERENCES_SKU_DEFINITIONS.map(agg => {
                    return (
                        <Grid item xs={4} key={agg.label}>
                            <SkuSelectionCard skuLabel={agg.label} />
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

export const ProfilePreferencesHome: React.VFC = () => {
    const profile = useCurrentProfile();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    if (!profile?.id || profile.__typename !== 'DoctorPreferences') {
        return null;
    }
    return (
        <PracticeSettingsBase
            title={'Clinical Preferences'}
            Content={<ProfilePreferencesHomeInner />}
            rightContent={
                !isMobile && (
                    <div style={{ marginTop: 140 }}>
                        <WhatsDandyRecomendedBlurb />
                    </div>
                )
            }
        />
    );
};
