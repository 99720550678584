/* eslint-disable max-lines */
import { stylesFactory } from '../util';
import { Stack } from './Stack';
import { SwitchFade, SwitchFadeBinary } from './SwitchFade';
import type { SvgIconProps } from '@orthly/ui-primitives';
import {
    ClickAwayListener,
    Tooltip,
    FlossPalette,
    useScreenIsMobile,
    MinimizeIcon,
    PopOutIcon,
    Icon,
    Text,
    Button,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import cx from 'clsx';
import React from 'react';
import { DraggableCore } from 'react-draggable';
import mergeRefs from 'react-merge-refs';
import { useHistory } from 'react-router-dom';

export enum MiniWindowVariant {
    Maximized = 'Maximized',
    Restored = 'Restored',
    Closed = 'Closed',
}

export const MiniWindowVariantCSSClass: { [variant in MiniWindowVariant]: string } = {
    [MiniWindowVariant.Maximized]: 'MiniWindow-Maximized',
    [MiniWindowVariant.Restored]: 'MiniWindow-Restored',
    [MiniWindowVariant.Closed]: 'MiniWindow-Closed',
};

const WINDOW_TRANSITION_DURATION = 200;
const useStyles = stylesFactory(theme => ({
    window: {
        display: 'flex',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        background: 'white',
        width: 500,
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Restored]}`]: {
            minHeight: 64,
            boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.25)',
        },
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Maximized]}`]: {
            height: '100%',
            boxShadow: '-20px 100px 500px 150px rgba(0, 0, 0, 0.25)',
        },
        '& > *:first-child': {
            borderTopLeftRadius: 'inherit',
            borderTopRightRadius: 'inherit',
        },
        '& > *:last-child': {
            borderBottomLeftRadius: 'inherit',
            borderBottomRightRadius: 'inherit',
        },
        [theme.breakpoints.down('sm')]: {
            left: '-100vw !important',
        },
    },
    windowTransition: {
        transition: `fade ${WINDOW_TRANSITION_DURATION}ms ease-in-out`,
    },
    title: {
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Restored]}`]: {
            padding: '8px 12px',
            backgroundColor: FlossPalette.DARK_TAN,
            height: 40,
        },
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Maximized]}`]: {
            padding: '16px 24px 0',
            backgroundColor: FlossPalette.WHITE,
        },
    },
    nativeTitle: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    backButton: {
        padding: 'initial',
        minWidth: 'initial',
        aspectRatio: '1',
        border: 'initial',
        overflow: 'hidden',
        flexShrink: 0,
        transition: 'all 200ms ease-in-out',
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Restored]}`]: {
            width: 24,
            marginRight: 8,
            backgroundColor: 'transparent',
        },
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Maximized]}`]: {
            width: 48,
            marginRight: 24,
        },
    },
    nativeBackButton: {
        background: 'transparent',
        height: 40,
        borderRadius: 8,
        width: 56,
    },
    handleTitle: {
        flexGrow: 1,
        overflowX: 'hidden',
    },
    handle: {
        cursor: 'move',
        display: 'flex',
        alignItems: 'center',
    },
    handleText: {
        lineHeight: 'inherit !important',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginRight: 56,
    },
    nativeHandleText: {
        fontSize: '24px !important',
        fontWeight: 400,
        fontFamily: 'Bagoss Standard, Verdana, sans-serif',
        marginLeft: 16,
    },
    action: {
        color: FlossPalette.GRAY,
        cursor: 'pointer',
    },
    disabled: {
        color: FlossPalette.DARK_TAN,
    },
    actionContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        '& > *:not(:first-child)': { marginLeft: 8 },
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Restored]}`]: {
            margin: '8px 12px',
        },
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Maximized]}`]: {
            margin: '16px 24px',
        },
    },
    noMobileDisplay: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    backButtonIcon: {
        color: FlossPalette.DARK_GRAY,
        [theme.breakpoints.down('sm')]: {
            color: FlossPalette.STAR_GRASS,
        },
    },
}));

export type MiniWindowPendingAnimation = 'move' | 'ongoing' | 'none';

export interface MiniWindowCommonStates {
    variant: MiniWindowVariant;
    setVariant: (variant: MiniWindowVariant, pendingAnimation?: MiniWindowPendingAnimation) => void;
    pendingAnimation: MiniWindowPendingAnimation;
    setPendingAnimation: (pendingAnimation: MiniWindowPendingAnimation) => void;
}

export function useMiniWindowStates(initialVariant?: MiniWindowVariant): MiniWindowCommonStates {
    const [variant, setVariantInner] = React.useState(initialVariant ?? MiniWindowVariant.Restored);
    /**
     * should we move the window after this rerender?
     * 'move' = onmount move to default position
     */
    const [pendingAnimation, setPendingAnimation] = React.useState<MiniWindowPendingAnimation>('move');
    const setVariant = React.useCallback(
        (
            variant: Parameters<typeof setVariantInner>[0],
            pendingAnimation: Parameters<typeof setPendingAnimation>[0] = 'none',
        ) => {
            setPendingAnimation(pendingAnimation);
            setVariantInner(variant);
        },
        [],
    );
    return React.useMemo<MiniWindowCommonStates>(
        () => ({ variant, setVariant, pendingAnimation, setPendingAnimation }),
        [variant, setVariant, pendingAnimation, setPendingAnimation],
    );
}

interface MiniWindowTitleProps extends Pick<MiniWindowCommonStates, 'variant' | 'setVariant'> {
    /** will display a back button if set */
    onBack?: () => void;
    /** when to trigger fade in/out animation */
    childrenSwitchKey?: React.Key;
    /** will be wrapped with a default style if string, or wrapped inside a boundary otherwise */
    children?: React.ReactNode;
    /** hide the window control buttons */
    hideControls?: boolean;
    /** disable the back/close buttons once a call to create a conversation has been made */
    disableBackAndClose?: boolean;
    /** disable the button to move the window */
    disableMovableWindow?: boolean;
    style?: React.CSSProperties;
    backButtonClasses?: string;
    closeIconStyles?: React.CSSProperties;
}

interface MiniWindowControlButtonProps {
    onClick: () => void;
    text: string;
    Icon: React.ComponentType<SvgIconProps>;
    disabled?: boolean;
    hideOnMobile?: boolean;
    iconStyles?: React.CSSProperties;
}

export const MiniWindowControlButton: React.VFC<MiniWindowControlButtonProps> = props => {
    const classes = useStyles();

    const { onClick, text, Icon, disabled, hideOnMobile, iconStyles } = props;
    return (
        <Tooltip title={text} arrow placement={'left'}>
            <Icon
                className={cx(classes.action, disabled && classes.disabled, hideOnMobile && classes.noMobileDisplay)}
                onClick={disabled ? undefined : onClick}
                style={iconStyles}
            />
        </Tooltip>
    );
};
export const MiniWindowTitle: React.VFC<MiniWindowTitleProps> = props => {
    const {
        variant,
        setVariant,
        onBack,
        childrenSwitchKey,
        children,
        hideControls,
        disableBackAndClose,
        disableMovableWindow,
        style,
        closeIconStyles,
        backButtonClasses,
    } = props;
    const classes = useStyles();
    const classVariant = MiniWindowVariantCSSClass[variant];

    const nodeRef = React.useRef<HTMLDivElement>(null);
    const isMobile = useScreenIsMobile();
    const history = useHistory();

    const handleClose = () => {
        if (isMobile) {
            history.goBack();
            setTimeout(() => {
                setVariant(MiniWindowVariant.Closed, 'none');
            }, 100);
        } else {
            setVariant(MiniWindowVariant.Closed, 'move');
        }
    };

    return (
        <Stack className={isMobile ? classes.nativeTitle : clsx(classes.title, classVariant)} style={style}>
            <Button
                variant={'secondary'}
                className={
                    isMobile
                        ? clsx(classes.backButton, classes.nativeBackButton)
                        : clsx(classes.backButton, classVariant, backButtonClasses)
                }
                disabled={disableBackAndClose}
                style={
                    onBack
                        ? undefined
                        : { display: isMobile ? 'none' : undefined, width: 0, marginRight: 0, border: 'none' }
                }
                onClick={onBack}
            >
                <Icon icon={'ChevronLeft'} className={classes.backButtonIcon} />
            </Button>
            <SwitchFade nodeRef={nodeRef} switchKey={childrenSwitchKey ?? String(children)}>
                <div ref={nodeRef} className={clsx(classes.handleTitle, !hideControls && classes.handle)}>
                    {typeof children === 'string' ? (
                        <Text
                            variant={variant === MiniWindowVariant.Restored || isMobile ? 'body2' : 'h5'}
                            className={isMobile ? classes.nativeHandleText : classes.handleText}
                        >
                            {children}
                        </Text>
                    ) : (
                        children
                    )}
                </div>
            </SwitchFade>
            {!hideControls && (
                <Stack className={clsx(classes.actionContainer, classVariant)}>
                    {variant === MiniWindowVariant.Restored && (
                        <MiniWindowControlButton
                            hideOnMobile
                            text={'Make chat larger'}
                            Icon={PopOutIcon}
                            onClick={() => setVariant(MiniWindowVariant.Maximized, 'move')}
                        />
                    )}
                    {variant === MiniWindowVariant.Maximized && (
                        <MiniWindowControlButton
                            hideOnMobile
                            text={'Make chat smaller'}
                            Icon={MinimizeIcon}
                            onClick={() => setVariant(MiniWindowVariant.Restored, 'move')}
                        />
                    )}
                    <MiniWindowControlButton
                        text={disableBackAndClose ? 'Disabled while chat is being created' : 'Close window'}
                        Icon={props => <Icon icon={'CloseIcon'} {...props} />}
                        disabled={disableBackAndClose}
                        onClick={handleClose}
                    />
                </Stack>
            )}
            {hideControls && disableMovableWindow && (
                <MiniWindowControlButton
                    text={''}
                    Icon={props => <Icon icon={'CloseIcon'} {...props} />}
                    disabled={disableBackAndClose}
                    onClick={handleClose}
                    iconStyles={closeIconStyles}
                />
            )}
        </Stack>
    );
};

interface MiniWindowCallbackParams {
    node: HTMLDivElement;
    variant: MiniWindowVariant;
    setLeft: React.Dispatch<React.SetStateAction<number>>;
    setTop: React.Dispatch<React.SetStateAction<number>>;
}

interface MiniWindowProps extends MiniWindowCommonStates, React.ComponentProps<'div'> {
    /** set window move animation definition (default: `moveToBottomRight`) */
    onShouldMove?: (params: MiniWindowCallbackParams) => void;
}

const moveToBottomRight = ({ node, variant, setLeft, setTop }: MiniWindowCallbackParams) => {
    if (variant === MiniWindowVariant.Maximized) {
        const { width } = node.getBoundingClientRect();
        setLeft(-width);
        setTop(0);
    } else if (variant === MiniWindowVariant.Restored) {
        const maxHeight = node.offsetParent?.getBoundingClientRect().height ?? 0;
        const { width, height } = node.getBoundingClientRect();
        setLeft(-1 * width);
        setTop(maxHeight - height);
    } else if (variant === MiniWindowVariant.Closed) {
        setTop(top => top + 100);
    }
};

export const MiniWindowCore = React.forwardRef<HTMLDivElement, MiniWindowProps>(
    ({ variant, setVariant, pendingAnimation, setPendingAnimation, onShouldMove, children, ...props }, outerRef) => {
        const classes = useStyles();
        const [left, setLeft] = React.useState(0);
        const [top, setTop] = React.useState(0);
        const ref = React.useRef<HTMLDivElement>(null);
        const classVariant = MiniWindowVariantCSSClass[variant];
        const isMobile = useScreenIsMobile();

        const onShouldMoveRef = React.useRef<typeof onShouldMove>();
        onShouldMoveRef.current = onShouldMove ?? moveToBottomRight;
        React.useLayoutEffect(() => {
            if (pendingAnimation === 'move' && ref.current) {
                onShouldMoveRef.current?.({ variant, setLeft, setTop, node: ref.current });
                setPendingAnimation('ongoing');
                setTimeout(() => setPendingAnimation('none'), WINDOW_TRANSITION_DURATION);
            }
        }, [variant, pendingAnimation, setPendingAnimation]);

        const onDrag = React.useCallback<NonNullable<React.ComponentPropsWithoutRef<typeof DraggableCore>['onDrag']>>(
            /**
             * On drag update the position of MiniWindow
             * deltaX/Y are the difference between the current mouse position and the previous mouse position
             * x is the current absolute position of the mouse
             */
            (_, { deltaX, deltaY, x }) => {
                if (!isMobile) {
                    if (x <= 0 || x + 1 >= document.documentElement.clientWidth) {
                        setVariant(MiniWindowVariant.Maximized, 'move');
                    } else {
                        // this if should be unnecessary but if `setVariant` is a redux action the performance can be really bad
                        if (variant !== MiniWindowVariant.Restored) {
                            setVariant(MiniWindowVariant.Restored, 'none');
                        }
                        setLeft(left => left + deltaX);
                        setTop(top => top + deltaY);
                    }
                }
            },
            [isMobile, setVariant, variant],
        );

        const onClickAway = (event: MouseEvent | TouchEvent) => {
            // check if we're clicking in the emoji picker before we close the window due to the Popover being rendered in a separate part of the DOM:
            // https://stackoverflow.com/questions/55143914/material-ui-clickawaylistener-close-when-clicking-itself
            const { target } = event;
            const className = target instanceof HTMLElement ? target.className : '';
            const childClass = target instanceof HTMLElement ? target.firstElementChild?.className ?? '' : '';
            const clickedInEmojiPicker = () => {
                return (
                    className.includes('emoji') ||
                    className.includes('icn') ||
                    className.includes('native') ||
                    childClass.includes?.('emoji') ||
                    childClass.includes?.('icn') ||
                    childClass.includes?.('native')
                );
            };
            if (variant === MiniWindowVariant.Maximized && pendingAnimation === 'none' && !clickedInEmojiPicker()) {
                setVariant(MiniWindowVariant.Closed);
            }
        };

        return (
            <SwitchFadeBinary nodeRef={ref}>
                {variant !== MiniWindowVariant.Closed && (
                    <ClickAwayListener onClickAway={onClickAway}>
                        <DraggableCore
                            handle={`.${classes.handle}`}
                            offsetParent={document.body}
                            onDrag={onDrag}
                            nodeRef={ref}
                        >
                            <div
                                {...props}
                                className={clsx(
                                    props.className,
                                    classes.window,
                                    classVariant,
                                    pendingAnimation === 'ongoing' && classes.windowTransition,
                                )}
                                style={{
                                    top,
                                    left,
                                    ...props.style,
                                }}
                                ref={mergeRefs([ref, outerRef])}
                            >
                                {children}
                            </div>
                        </DraggableCore>
                    </ClickAwayListener>
                )}
            </SwitchFadeBinary>
        );
    },
);

export const MiniWindow: React.FC<{ initialVariant?: MiniWindowVariant }> = ({ initialVariant, children }) => (
    <MiniWindowCore {...useMiniWindowStates(initialVariant)}>{children}</MiniWindowCore>
);
