import { ProductImageWrapper } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { FlossPalette, Grid, stylesFactory, Text } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    card: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        boxShadow: theme.shadows[2],
        padding: 24,
        backgroundImage: `
            radial-gradient(
                circle at 100% 130%,
                ${FlossPalette.PRIMARY_FOREGROUND}55 15%,
                ${FlossPalette.PRIMARY_FOREGROUND}00 45%
            ),
            radial-gradient(
                circle at 100% 130%,
                ${FlossPalette.PRIMARY_FOREGROUND}55 15%,
                ${FlossPalette.PRIMARY_FOREGROUND}00 35%
            )`,
    },
}));

const product_unit_type_for_lab_order = (lab_order: LabsGqlLabOrderFragment) => {
    const items = OrderItemV2Utils.parseItems(lab_order.items_v2);
    return items[0] ? CartItemV2Utils.getProductUnitType(items[0]) : `Unknown`;
};

const format_date = (date: string) => moment(date).format('MMM D');

export const LabOrderCard: React.VFC<{
    lab_order: LabsGqlLabOrderFragment;
    active: boolean;
    actions: React.ReactNode;
}> = ({ lab_order, active, actions }) => (
    <Grid container className={useStyles().card} style={active ? { backgroundColor: FlossPalette.WHITE } : undefined}>
        <Grid item container alignItems={`center`}>
            <ProductImageWrapper product={product_unit_type_for_lab_order(lab_order)} />
            <Text variant={`body1`} style={{ margin: `0 16px` }}>
                {lab_order.patient.first_name} {lab_order.patient.last_name}
            </Text>
        </Grid>
        <Grid item container style={{ margin: `16px 0` }}>
            <Grid item container direction={`column`} xs={6}>
                <Text variant={`caption`}>Order placed</Text>
                <Text variant={`body2`}>{format_date(lab_order.created_at)}</Text>
            </Grid>
            {lab_order.delivery_date && (
                <Grid item container direction={`column`} xs={6}>
                    <Text variant={`caption`}>Delivered</Text>
                    <Text variant={`body2`}>{format_date(lab_order.delivery_date)}</Text>
                </Grid>
            )}
        </Grid>
        {actions && (
            <Grid item container>
                {actions}
            </Grid>
        )}
    </Grid>
);
