import type { FlossColor } from '@orthly/ui';
import { stylesFactory, FlossPalette, Badge } from '@orthly/ui-primitives';
import React from 'react';

interface BadgeColorProps {
    background?: FlossColor;
    textColor?: FlossColor;
}

const useStyles = stylesFactory<BadgeColorProps>(() => ({
    badge: props => ({
        backgroundColor: FlossPalette[props.background ?? 'BURGUNDY'],
        color: FlossPalette[props.textColor ?? 'WHITE'],
        borderRadius: 2,
        minWidth: 'unset',
        height: 16,
        fontSize: 12,
        lineHeight: 16,
        padding: '0 8px',
    }),
}));

interface BadgeStyledProps extends BadgeColorProps {
    count: number;
    children: React.ReactNode;
}

export const BadgeStyled: React.FC<BadgeStyledProps> = props => {
    const { count, background, textColor } = props;
    const classes = useStyles({ background, textColor });
    if (count <= 0) {
        return <>{props.children}</>;
    }
    return (
        <Badge
            badgeContent={count}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{ badge: classes.badge }}
            style={{ marginBottom: 0 }}
            overlap={'rectangular'}
        >
            {props.children}
        </Badge>
    );
};
