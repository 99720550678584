import { useMutation } from '@apollo/client';
import type { CreateTrainingIntakeSurveyResponsesMutationVariables } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import constate from 'constate';
import { atom, useAtom } from 'jotai';

const CreateTrainingIntakeSurveyResponses_Mutation = graphql(`
    mutation createTrainingIntakeSurveyResponses($data: [TrainingIntakeResponseInput!]!) {
        createTrainingIntakeResponse(data: $data)
    }
`);

type Vars = CreateTrainingIntakeSurveyResponsesMutationVariables['data'];

const successToastOpenAtom = atom(false);
const errorToastOpenAtom = atom(false);

const useCreateTrainingIntake = () => {
    const [successToastOpen, setSuccessToastOpen] = useAtom(successToastOpenAtom);
    const [errorToastOpen, setErrorToastOpen] = useAtom(errorToastOpenAtom);

    const [mtn] = useMutation(CreateTrainingIntakeSurveyResponses_Mutation);
    const mtnSubmitter = (data: Vars) => mtn({ variables: { data } });
    const result = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        onError: () => setErrorToastOpen(true),
        onSuccess: () => setSuccessToastOpen(true),
    });

    return {
        ...result,
        successToastOpen,
        errorToastOpen,
        setSuccessToastOpen,
        setErrorToastOpen,
    };
};

// The rule does not understand this format
// eslint-disable-next-line import/no-unused-modules
export const [CreateTrainingIntakeSurveyProvider, useCreateTrainingIntakeSurveyResponse] =
    constate(useCreateTrainingIntake);
