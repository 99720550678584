import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const CreateDoctorPreferences_Mutation = graphql(`
    mutation CreateDoctorPreferences($data: CreateDoctorPreferencesCommand!) {
        createDoctorPreferences(data: $data) {
            id
        }
    }
`);

export const useCreateDoctorPreferences = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (message?: string) => void;
}) => {
    const session = useSession();
    const [submitMtn, { error }] = useMutation(CreateDoctorPreferences_Mutation);
    const { submit: createDoctorPreferences, ...rest } = useChangeSubmissionFn(submitMtn, {
        disableDefaultErrorMessage: true,
        onSuccess: () => {
            onSuccess?.();
        },
        onError: e => {
            onError?.(e.message);
        },
    });

    const submit = React.useCallback(
        async ({
            firstName,
            lastName,
            contactEmail,
            contactPhone,
            roles,
            force = false,
        }: {
            firstName: string;
            lastName: string;
            contactEmail: string;
            contactPhone?: string;
            roles: `${LabsGqlStaffRolePractice}`[];
            force?: boolean;
        }) => {
            if (session?.organization_id) {
                return createDoctorPreferences({
                    variables: {
                        data: {
                            partner_id: session.organization_id,
                            name: `${firstName} ${lastName}`,
                            contact_email: contactEmail,
                            contact_phone: contactPhone,
                            roles: roles as LabsGqlStaffRolePractice[],
                            force: force,
                        },
                    },
                });
            }
        },
        [createDoctorPreferences, session?.organization_id],
    );

    return {
        error: error?.message,
        submit,
        ...rest,
    };
};
