import { AttachScansMobile } from '../../order-detail-v2/components/AttachScansMobile';
import { OrderAction, OrderActionTrackerIdMap } from '../OrderActionsUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { styled, Drawer, Button, ScanIcon } from '@orthly/ui-primitives';
import React from 'react';

const ButtonContainer = styled('div')({
    display: 'flex',
});
const StyledPaper = styled('div')({
    height: '92%',
    borderRadius: 16,
});
const StyledButton = styled(Button)({
    height: 'unset',
    minWidth: 'unset',
});

interface UploadOrTakePhotoActionProps {
    order: LabsGqlOrder;
    setOpen: (value: boolean) => void;
    isIconOnly?: boolean;
}

// Keeping these components together as they are related.

export const AddExistingScanMobileDialog: React.FC<{
    order: LabsGqlOrder;
    open: boolean;
    setOpen: (value: boolean) => void;
}> = ({ order, open, setOpen }) => {
    return (
        <Drawer
            open={open}
            onClose={() => setOpen(false)}
            variant={'temporary'}
            anchor={'bottom'}
            PaperProps={{
                component: StyledPaper,
            }}
        >
            <AttachScansMobile order={order} backAction={() => setOpen(false)} setOpen={setOpen} />
        </Drawer>
    );
};

export const AttachExistingScanMobileButton: React.FC<UploadOrTakePhotoActionProps> = ({
    order,
    isIconOnly,
    setOpen,
}) => {
    return (
        <>
            <ButtonContainer>
                <StyledButton
                    variant={'ghost'}
                    size={'large'}
                    data-test={'open-button'}
                    disabled={!order.can_attach_scans}
                    data-tracker-id={OrderActionTrackerIdMap[OrderAction.AttachExistingScan]}
                    startIcon={!isIconOnly ? 'ScanIcon' : undefined}
                    onClick={() => setOpen(true)}
                >
                    {isIconOnly ? <ScanIcon /> : 'Attach existing scan'}
                </StyledButton>
            </ButtonContainer>
        </>
    );
};
