import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { SimpleCheckbox } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import { PersonCardOccupationOptionsDisplay } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    checkboxLabel: {
        marginLeft: 8,
        marginTop: 8,
    },
}));

interface EmployeeCheckListProps {
    sendersOrReceivers: LabsGqlDoctorPreferencesFragment[];
    senderOrReceiverIds: string[];
    setSenderOrReceiverIds: (senderOrReceiverIds: string[]) => void;
}

export const EmployeeCheckList: React.FC<EmployeeCheckListProps> = props => {
    const { sendersOrReceivers, senderOrReceiverIds, setSenderOrReceiverIds } = props;
    const classes = useStyles();
    return (
        <>
            {sendersOrReceivers.map(senderOrReceiver => (
                <Grid key={senderOrReceiver.id} container>
                    <SimpleCheckbox
                        checked={senderOrReceiverIds.includes(senderOrReceiver.id)}
                        setChecked={checked => {
                            setSenderOrReceiverIds(
                                checked
                                    ? [...senderOrReceiverIds, senderOrReceiver.id]
                                    : senderOrReceiverIds.filter(
                                          senderOrReceiverId => senderOrReceiverId !== senderOrReceiver.id,
                                      ),
                            );
                        }}
                        label={
                            <Text variant={'body2'}>
                                {senderOrReceiver.formatted_name}{' '}
                                <span style={{ color: FlossPalette.LIGHT_GRAY }}>
                                    {senderOrReceiver.roles
                                        ?.map(role => PersonCardOccupationOptionsDisplay[role])
                                        .join(', ')}
                                </span>
                            </Text>
                        }
                        FormControlLabelProps={{ classes: { label: classes.checkboxLabel } }}
                        CheckboxProps={{ style: { margin: '8px 0px 0px' }, color: 'secondary' }}
                    />
                </Grid>
            ))}
        </>
    );
};
