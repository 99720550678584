import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import { CROWN_BRIDGE_MATERIALS } from './CrownBridgeMaterials';
import React from 'react';

export const CrownBridgeMaterialsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const materials = CROWN_BRIDGE_MATERIALS;
    const rows = React.useMemo(
        () =>
            materials.map(({ label, field, isGold }) => {
                const price = getPrice(field);

                return {
                    price,
                    id: label,
                    material: label,
                    formattedPrice: formatPrice(price, formattedPrice =>
                        isGold ? `${formattedPrice} + Alloy` : formattedPrice,
                    ),
                };
            }),
        [getPrice, formatPrice, materials],
    );

    return (
        <PricingGuideTable
            id={'crown-bridge-materials'}
            title={'Materials'}
            columns={[
                {
                    id: 'material',
                    title: 'Material',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ formattedPrice }) => formattedPrice,
                },
            ]}
            rows={rows}
            footnotes={[
                '*Listed price does not include the alloy price of gold. Final price depends on the weight and market value of gold, which will be added to the listed price.',
            ]}
        />
    );
};
