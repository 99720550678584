import { getFlagVariantValueChecker } from '../../../../utils/utils';
import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import ClearAlignersImage from '@orthly/dentin/assets/images/hd-skus/clear_aligners.png';

export const ClearAlignersUpsellConfig: InboxUpsellItemConfigProps = {
    assetName: '2023-12 Aligners Campaign',
    title: '25% off Clear Aligner Treatment Plans',
    subtitle:
        'Valid on clear aligners orders submitted through January 31, 2024 for eligible practices only. Discount will be applied on monthly invoice.',
    ctaText: 'Learn More',
    destination: {
        url: 'https://www.meetdandy.com/labs/clear-aligners/?utm_source=portal&utm_medium=banner&utm_campaign=2023-12-aligners-promo',
    },
    imgSrc: ClearAlignersImage,
    imgStyle: { maxHeight: 80 },
    skuType: 'clear-aligners',
    filterCriteria: null,
    featureFlagKey: 'upsellClearAligners2023',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_a'),
};
