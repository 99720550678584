import { ATTACH_SURGICAL_REPORT_PATH } from '../../ScanbodiesPaths';
import type { FirebaseStorageWatchStage } from '../../hooks/useFirebaseStorageSearchWatch';
import { useFirebaseStorageSearchWatch } from '../../hooks/useFirebaseStorageSearchWatch';
import { useScanbodyRequestSelector } from '../../state/ScanbodyRequest.selectors';
import { ScanbodySurgicalReportUploaderFileSelection } from './ScanbodySurgicalReportUploaderFileSelection';
import { ScanbodySurgicalReportUploaderMethodSelection } from './ScanbodySurgicalReportUploaderMethodSelection';
import { PracticeScreen } from '@orthly/dentin';
import { useFetchDoctorsQuery, useGetOwnImpersonationTokenQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { Collapse } from '@orthly/ui-primitives';
import type firebase from 'firebase/compat';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useScanbodyRequestSurgicalReportBucket = (sessionId: string) => {
    const practiceId = useSession()?.organization_id;
    return getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.scanbodies, practiceId ?? '', sessionId);
};

export const SURGICAL_REPORT_OVERRIDE_FILENAME = 'SurgicalReport';

export const useScanbodySurgicalReportUploaderSession = (): ScanbodySurgicalReportUploaderSession => {
    // This sessionId links mobile and laptop
    // const for component lifetime
    const [sessionId] = React.useState<string>(() => uuidv4());

    // generate storage path
    const storagePathConfig = useScanbodyRequestSurgicalReportBucket(sessionId);
    const overrideFileName = SURGICAL_REPORT_OVERRIDE_FILENAME;

    // generate mobile URL
    const request = useScanbodyRequestSelector(s => s.request);
    const { data: impersonateTokenData } = useGetOwnImpersonationTokenQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const mobileURL = React.useMemo(() => {
        const mobileURL = new URL(
            `/${PracticeScreen.scanbodies}/${ATTACH_SURGICAL_REPORT_PATH}/${sessionId}`,
            window.location.origin,
        );
        if (impersonateTokenData) {
            mobileURL.searchParams.set('impersonateToken', impersonateTokenData?.getOwnImpersonationToken.token);
        }
        if (request.manufacturer) {
            mobileURL.searchParams.set('manufacturer', request.manufacturer);
        }
        if (request.system) {
            mobileURL.searchParams.set('system', request.system);
        }
        if (request.connection) {
            mobileURL.searchParams.set('connection', request.connection);
        }
        return mobileURL.toString();
    }, [sessionId, impersonateTokenData, request.manufacturer, request.system, request.connection]);

    // watch firebase changes
    const { stage, refresh, metadata, downloadURL, blob } = useFirebaseStorageSearchWatch(
        storagePathConfig,
        overrideFileName,
    );

    return {
        mobileURL,
        stage,
        refresh,
        metadata,
        downloadURL,
        blob,
        overrideFileName,
        storagePathConfig,
    };
};

interface ScanbodySurgicalReportUploaderSession {
    mobileURL: string;
    stage: FirebaseStorageWatchStage;
    refresh: () => Promise<void>;
    metadata?: firebase.storage.FullMetadata;
    downloadURL?: string;
    blob?: Blob;
    storagePathConfig: BucketStoragePathConfig;
    overrideFileName: string;
}

enum ScanbodySurgicalReportUploaderStage {
    METHOD_SELECTION = 'METHOD_SELECTION',
    FILE_SELECTION = 'FILE_SELECTION',
}

interface ScanbodySurgicalReportUploaderProps {
    session: ScanbodySurgicalReportUploaderSession;
}

/**
 * This is a mobile to desktop file uploader. The binding is achieved with a session.
 * A session uses a firebase reference as the single source of truth.
 *
 * Data flow:
 * * session > firebase storage path
 * * desktop > upload > firebase storage path
 * * desktop > qrcode (firebase storage path as search params) > mobile
 * * mobile > upload > firebase storage path
 * * firebase storage path > watch (poll, 5s) > desktop
 */
export const ScanbodySurgicalReportUploader: React.FC<ScanbodySurgicalReportUploaderProps> = props => {
    const { session } = props;
    const [stage, setStage] = React.useState<ScanbodySurgicalReportUploaderStage>(
        ScanbodySurgicalReportUploaderStage.METHOD_SELECTION,
    );

    // proceed to next stage if mobile uploads something
    const autoNextTriggered = React.useRef<boolean>(false);
    React.useEffect(() => {
        if (!autoNextTriggered.current && session.blob) {
            autoNextTriggered.current = true;
            setStage(ScanbodySurgicalReportUploaderStage.FILE_SELECTION);
        }
    }, [session.blob]);

    // find doctorName from form
    const { requesting_doctor_id } = useScanbodyRequestSelector(s => s.request);
    const [doctorName, setDoctorName] = React.useState<string | undefined>(undefined);
    useFetchDoctorsQuery({
        skip: !requesting_doctor_id,
        onCompleted: ({ fetchDoctors }) => {
            setDoctorName(fetchDoctors.find(({ id }) => id === requesting_doctor_id)?.name);
        },
    });

    return (
        <>
            <Collapse in={stage === ScanbodySurgicalReportUploaderStage.METHOD_SELECTION}>
                <ScanbodySurgicalReportUploaderMethodSelection
                    {...session}
                    onDesktopUpload={() => setStage(ScanbodySurgicalReportUploaderStage.FILE_SELECTION)}
                    doctorName={doctorName}
                />
            </Collapse>
            <Collapse in={stage === ScanbodySurgicalReportUploaderStage.FILE_SELECTION}>
                <ScanbodySurgicalReportUploaderFileSelection
                    onBackToMethodSelection={() => setStage(ScanbodySurgicalReportUploaderStage.METHOD_SELECTION)}
                    {...session}
                />
            </Collapse>
        </>
    );
};
