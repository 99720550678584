import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const verifyLoginPhoneNumber_Mutation = graphql(`
    mutation verifyLoginPhoneNumber($code: String!, $mobile_phone_number: String!, $user_id: String!) {
        verifyLoginPhoneNumber(code: $code, mobile_phone_number: $mobile_phone_number, user_id: $user_id)
    }
`);

export function useVerifyLoginPhoneNumber({ onSuccess }: { onSuccess?: () => void }) {
    const [error, setError] = React.useState<string>();
    const [submitMtn] = useMutation(verifyLoginPhoneNumber_Mutation);
    const { submit: verifyPhoneNumber, ...rest } = useChangeSubmissionFn<any, any>(
        variables => submitMtn({ variables }),
        {
            disableDefaultErrorMessage: true,
            onSuccess: data => {
                if (data) {
                    setError(undefined);
                    onSuccess?.();
                } else {
                    setError('Something went wrong.  Please try again.');
                }
            },
            onError: e => {
                setError(e?.message);
            },
        },
    );

    const submit = React.useCallback(
        async (code: string, mobile_phone_number: string, user_id: string) => {
            if (mobile_phone_number && code && user_id) {
                return verifyPhoneNumber({ code, mobile_phone_number, user_id });
            }
        },
        [verifyPhoneNumber],
    );

    return {
        error,
        submit,
        ...rest,
    };
}
