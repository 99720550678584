import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import { useDentureCheckoutSelector, useDentureCheckoutStepInfo } from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { CheckoutBodyWrapper, GallerySelect } from '@orthly/dentin';
import HeavyFestooning from '@orthly/dentin/assets/images/festooning-level/HeavyFestooning.svg';
import MediumFestooning from '@orthly/dentin/assets/images/festooning-level/MediumFestooning.svg';
import SoftFestooning from '@orthly/dentin/assets/images/festooning-level/SoftFestooning.svg';
import { LabsGqlDenturesFabricationMethod, LabsGqlDenturesFestooningLevel } from '@orthly/graphql-schema';
import React from 'react';

export const DentureFestooningScreen: React.FC = () => {
    const selectedFabrication = useDentureCheckoutSelector(s => s.fields.dentureOptions?.fabrication);
    const selectedFestooning = useDentureCheckoutSelector(s => s.fields.festooningLevel);
    const setFestooningLevel = useDenturesCheckoutAction('CHOOSE_DENTURE_FESTOONING_LEVEL');
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(DentureCheckoutStep.DentureFestooningStep);
    if (!isActiveStep) {
        return null;
    }

    // for the "aesthetic" option, we want to recommend heavy, otherwise we recommend medium
    const recommendation: LabsGqlDenturesFestooningLevel =
        selectedFabrication === LabsGqlDenturesFabricationMethod.Milled
            ? LabsGqlDenturesFestooningLevel.Heavy
            : LabsGqlDenturesFestooningLevel.Medium;

    const value = selectedFestooning ?? undefined;

    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <div style={{ marginTop: 24 }}>
                <GallerySelect<LabsGqlDenturesFestooningLevel>
                    updateSelectedValue={val => {
                        if (val !== value) {
                            setFestooningLevel(val);
                        }
                    }}
                    options={[
                        {
                            title: 'Medium',
                            image: MediumFestooning,
                            value: LabsGqlDenturesFestooningLevel.Medium,
                        },
                        {
                            title: 'Soft',
                            image: SoftFestooning,
                            value: LabsGqlDenturesFestooningLevel.Soft,
                        },
                        {
                            title: 'Heavy',
                            image: HeavyFestooning,
                            value: LabsGqlDenturesFestooningLevel.Heavy,
                        },
                    ]}
                    recommendedValue={recommendation}
                    selectedValue={value}
                />
            </div>
        </CheckoutBodyWrapper>
    );
};
