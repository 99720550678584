import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlSetDoctorPreferencesCommand } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const UpdateDoctorPreferences_Mutation = graphql(`
    mutation UpdateDoctorPreferences($data: SetDoctorPreferencesCommand!) {
        setDoctorPreferences(data: $data) {
            id
        }
    }
`);

export const useUpdateDoctorPreferences = ({
    onSuccess,
    onFailure,
}: {
    onSuccess?: () => void;
    onFailure?: (message?: string) => void;
}) => {
    const [submitMtn, { error }] = useMutation(UpdateDoctorPreferences_Mutation);
    const { submit: updateDoctorPreferences, ...rest } = useChangeSubmissionFn<any, any>(
        variables => submitMtn({ variables }),
        {
            disableDefaultErrorMessage: true,
            onSuccess: () => {
                onSuccess?.();
            },
            onError: e => {
                onFailure?.(e?.message);
            },
        },
    );

    const submit = React.useCallback(
        async (data: LabsGqlSetDoctorPreferencesCommand) => updateDoctorPreferences({ data }),
        [updateDoctorPreferences],
    );

    return {
        error: error?.message,
        submit,
        ...rest,
    };
};
