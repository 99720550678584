import type { CheckboxPrimitiveProps, FormControlLabelProps } from '@orthly/ui-primitives';
import { CheckboxPrimitive as Checkbox, FormControlLabel } from '@orthly/ui-primitives';
import React from 'react';

interface SimpleCheckboxProps {
    checked: boolean;
    setChecked: (checked: boolean) => void;
    label: string | React.ReactNode;
    FormControlLabelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
    CheckboxProps?: CheckboxPrimitiveProps;
    // If true, the input element will blur itself after it is clicked, in order to give up the focus.
    blur?: boolean;
}

export const SimpleCheckbox: React.FC<SimpleCheckboxProps> = props => {
    const ref = React.useRef<HTMLInputElement>(null);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setChecked(event.target.checked);
        if (props.blur) {
            ref.current?.blur();
        }
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...props.CheckboxProps}
                    checked={props.checked}
                    color={'secondary'}
                    onChange={onChange}
                    inputRef={ref}
                />
            }
            label={props.label}
            {...props.FormControlLabelProps}
        />
    );
};
