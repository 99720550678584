import { useCalculatePaddingTop } from '../utils/LayoutUtils';
import { useIsChairside } from '../utils/chairside.hooks';
import { useStyles } from './Containers.styles';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import cx from 'classnames';
import React from 'react';

export const useRootStyles = stylesFactory(theme => ({
    noMobileDisplay: {
        [theme.breakpoints.down('sm')]: {
            display: 'none !important',
        },
    },
    noSmallScreenDisplay: {
        [theme.breakpoints.down('md')]: {
            display: 'none !important',
        },
    },
}));

export const LoggedInContainerPractice: React.FC = props => {
    const classes = useStyles();
    const chairside = useIsChairside();
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');

    const contentPaddingTop = useCalculatePaddingTop({
        chairside,
        maintenanceBannerPractice: !!maintenanceBannerPractice?.length,
    });

    return (
        <Grid style={{ paddingTop: contentPaddingTop }} container className={cx(classes.root, classes.practice)}>
            {props.children}
        </Grid>
    );
};
