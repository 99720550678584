import { AnalyticsClient } from '../../../../../analytics/analyticsClient';
import { OrderCancelReasonFormComponent, isOrderPlacedOnHoldDuringStatus } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { LabsGqlExternalUserCancelOrderMutationVariables } from '@orthly/graphql-operations';
import { useExternalUserCancelOrderMutation, useScans } from '@orthly/graphql-react';
import { LabsGqlCancellationReasonCode, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { type CancellationReasonCode } from '@orthly/shared-types';
import { useChangeSubmissionFn, OrthlyErrorBoundary } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Icon,
    makeStyles,
    createStyles,
    Button,
    useScreenIsMobile,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

type Vars = LabsGqlExternalUserCancelOrderMutationVariables['data'];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subtitle: {
            fontSize: '1rem',
            color: theme.palette.text.primary,
            marginTop: theme.spacing(1),
        },
        orderInProgress: {
            fontWeight: 'bold',
            marginBottom: theme.spacing(1),
        },
        title: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    }),
);

export const CancelOrderDialog: React.VFC<{
    order: LabsGqlOrder;
    open: boolean;
    setOpen: (open: boolean) => void;
    refetch?: () => void;
}> = props => {
    const { order, open, refetch, setOpen } = props;
    const { refetch: refetchScans } = useScans({ fetchPolicy: 'cache-first' });
    const [submitMtn] = useExternalUserCancelOrderMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const classes = useStyles();
    const isMobile = useScreenIsMobile();

    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Order canceled', {}],
        onSuccess: async () => {
            await refetchScans();
            refetch && refetch();
        },
    });

    const [cancellationReasonCode, setCancellationReasonCode] = React.useState<Vars['cancellation_reason_code'] | null>(
        null,
    );
    const [cancellationReason, setCancellationReason] = React.useState<Vars['cancellation_reason']>(null);

    const submitIsDisabled = React.useMemo(() => {
        return !cancellationReasonCode || submitting;
    }, [cancellationReasonCode, submitting]);

    const onSubmit = React.useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (!cancellationReasonCode) {
                return;
            }
            const cancellationReasonForReasonCode = [
                LabsGqlCancellationReasonCode.AnotherLabOther,
                LabsGqlCancellationReasonCode.Other,
            ].includes(cancellationReasonCode)
                ? cancellationReason
                : null;

            await submit({
                cancellation_reason_code: cancellationReasonCode,
                cancellation_reason: cancellationReasonForReasonCode,
                delete_scan: false,
                orderId: props.order.id,
            });

            AnalyticsClient.track('All - Cancel Order - Submitted', {
                $groups: { order: order.id },
                cancellationReasonCode: cancellationReasonCode as unknown as CancellationReasonCode,
                cancellationReason: cancellationReasonForReasonCode ?? undefined,
            });
        },
        [cancellationReasonCode, cancellationReason, submit, props.order.id, order.id],
    );

    const isOrderInProgress = [LabsGqlLabOrderStatus.NeedsReview, LabsGqlLabOrderStatus.Fabrication].every(
        status => order.status === status || isOrderPlacedOnHoldDuringStatus(order, status),
    );

    if (!order.can_cancel) {
        return null;
    }

    return (
        <Dialog open={open} PaperProps={{ style: { minWidth: 350, borderRadius: 8 } }}>
            <>
                <DialogTitle>
                    <div className={classes.title}>
                        <Text variant={isMobile ? 'h5' : 'h4'} style={{ paddingBottom: 0 }}>
                            Cancel Order
                        </Text>
                        <IconButton
                            onClick={() => {
                                setOpen(false);
                            }}
                            size={'small'}
                        >
                            <Icon icon={'CloseIcon'} color={'primary'} />
                        </IconButton>
                    </div>
                    {!isOrderInProgress ? (
                        <Text variant={'body1'} className={classes.subtitle} data-test={'cancel-order-subtitle'}>
                            <div className={classes.orderInProgress}>
                                Your order is already in progress. Are you sure you want to cancel?
                            </div>
                            If so, please select a reason for canceling the order:
                        </Text>
                    ) : (
                        <div className={classes.subtitle} data-test={'cancel-order-subtitle'}>
                            Please select a reason for canceling the order:
                        </div>
                    )}
                </DialogTitle>
                <DialogContent
                    style={{ padding: isMobile ? '0 24px 24px 24px' : '0 40px 40px 40px', borderTop: 'none' }}
                    dividers
                >
                    <OrthlyErrorBoundary>
                        <form onSubmit={onSubmit}>
                            <OrderCancelReasonFormComponent
                                cancellationReasonCode={cancellationReasonCode}
                                setCancellationReasonCode={setCancellationReasonCode}
                                cancellationReason={cancellationReason}
                                setCancellationReason={setCancellationReason}
                            />
                            <Button
                                type={'submit'}
                                disabled={submitIsDisabled}
                                fullWidth
                                variant={'primary'}
                                style={{ marginTop: 10 }}
                            >
                                Submit Cancellation
                            </Button>
                        </form>
                    </OrthlyErrorBoundary>
                </DialogContent>
            </>
        </Dialog>
    );
};
