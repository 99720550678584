import { useOrderDetailSection } from '../OrderDetailSectionProvider';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderWaxupReviewStatus, LabsGqlWorkflowStateEnum } from '@orthly/graphql-schema';
import { FlossPalette, styled, stylesFactory, Tab, Tabs, Text, Divider, useScreenIsSm } from '@orthly/ui-primitives';
import { shouldShowWaxup, useOrderDetailImages } from '@orthly/veneer';
import React from 'react';

const TabContainer = styled('div')({
    width: '100%',
    background: FlossPalette.WHITE,
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});
const RequiresAttentionLabel = styled(Text)({
    background: FlossPalette.ATTENTION,
    padding: '0 6px',
    borderRadius: 2,
});

const useTabStyles = stylesFactory(theme => ({
    flexContainer: {
        margin: '0 24px',
        [theme.breakpoints.down('md')]: {
            margin: 0,
        },
    },
}));

interface OrderDetailTabBarProps {
    order: LabsGqlSingleLabOrderFragment;
}

export const OrderDetailTabBar: React.VFC<OrderDetailTabBarProps> = ({ order }) => {
    const {
        tabValue,
        setTabValue,
        setPreviousTabValue,
        overviewInViewObj,
        detailsInViewObj,
        designInViewObj,
        scansInViewObj,
        photosInViewObj,
        scrollToSection,
    } = useOrderDetailSection();

    const tabClasses = useTabStyles();
    const imageSources = useOrderDetailImages(order, false);
    const showDesign = shouldShowWaxup(order);
    const showPhotos = imageSources.length > 0 || (order?.scan_export.image_urls ?? []).length > 0;
    const showTimeline = useScreenIsSm();

    const isOnHold = order.workflow_state.state === LabsGqlWorkflowStateEnum.OnHold && !order.hold_in_review;
    const waxupReadyForReview = order.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview;
    const needsAlignerApproval = order.fulfillment_workflow.active_task?.type === 'ApproveAlignerFabrication';
    const requiresAttention = isOnHold || waxupReadyForReview || needsAlignerApproval;

    // State to track the current tab
    const [currentTab, setCurrentTab] = React.useState<string>(tabValue);

    // State to track the previous tab

    // Ref to track if a tab was clicked
    const tabClickedRef = React.useRef(false);

    // Handle tab click
    const handleTabClick = (value: string) => {
        setPreviousTabValue(tabValue); // Set the previous tab to the current tab
        setCurrentTab(value); // Set the current tab to the clicked tab
        tabClickedRef.current = true; // Mark that a tab was clicked

        // Scroll to the corresponding section
        scrollToSection(value);

        // Reset tabClickedRef after a delay
        setTimeout(() => {
            tabClickedRef.current = false; // Reset the tab click state after scrolling
        }, 800); // Adjust this timeout based on the scroll duration
    };

    // Update the tab based on visibility
    React.useEffect(() => {
        if (tabClickedRef.current) {
            return;
        } // Don't update if a tab was clicked

        // Update the currentTab based on which section is in view
        if (overviewInViewObj?.inView) {
            setCurrentTab('overview');
        } else if (designInViewObj?.inView) {
            setCurrentTab('design');
        } else if (detailsInViewObj?.inView) {
            setCurrentTab('details');
        } else if (scansInViewObj?.inView) {
            setCurrentTab('scans');
        } else if (photosInViewObj?.inView) {
            setCurrentTab('photos');
        }
    }, [
        overviewInViewObj?.inView,
        detailsInViewObj?.inView,
        designInViewObj?.inView,
        scansInViewObj?.inView,
        photosInViewObj?.inView,
    ]);

    // Sync tabValue with currentTab
    React.useEffect(() => {
        setTabValue?.(currentTab);
    }, [currentTab, setTabValue]);

    return (
        <TabContainer>
            <Tabs
                classes={tabClasses}
                variant={'scrollable'}
                scrollButtons={false}
                value={tabValue}
                indicatorColor={'secondary'}
                textColor={'inherit'}
                onChange={(_, value) => handleTabClick(value)}
            >
                {showTimeline && <Tab label={'Timeline'} value={'timeline'} />}
                {showTimeline && (
                    <Tab
                        label={''}
                        icon={<Divider orientation={'vertical'} />}
                        disabled
                        style={{ minWidth: 'unset', padding: '12px 0' }}
                    />
                )}
                <Tab
                    label={'Overview'}
                    value={'overview'}
                    style={{ minHeight: 'unset' }}
                    icon={
                        requiresAttention ? (
                            <RequiresAttentionLabel variant={'caption'} medium color={'WHITE'}>
                                Requires attention
                            </RequiresAttentionLabel>
                        ) : undefined
                    }
                    iconPosition={'end'}
                />
                {showDesign && <Tab label={'Design'} value={'design'} />}
                <Tab label={'Details'} value={'details'} />
                <Tab label={'Scans'} value={'scans'} />
                {showPhotos && <Tab label={'Photos'} value={'photos'} />}
            </Tabs>
        </TabContainer>
    );
};
