import { useCheckoutSelector } from '../../../../redux';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import {
    useDentureCheckoutPropSelector,
    useDentureCheckoutSelector,
    useDentureCheckoutStepInfo,
} from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { CheckoutBodyWrapper, CheckoutTextField } from '@orthly/dentin';
import { DentureType, OrderItemArch } from '@orthly/items';
import {
    FlossPalette,
    stylesFactory,
    Grid,
    Popover,
    HelpOutlineIcon,
    KeyboardArrowUpIcon,
    Icon,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    radioButton: {
        width: '100%',
        padding: 16,
        borderRadius: 4,
        cursor: 'pointer',
    },
    hoverRadio: { '&:hover': { color: FlossPalette.GRAY }, color: FlossPalette.LIGHT_GRAY },
    selectedRadio: { color: FlossPalette.PRIMARY_FOREGROUND },
}));

type NewOrImmediateType = 'New' | 'Immediate' | null;

interface RadioButtonProps {
    label: string;
    isSelected: boolean;
    onSelect: () => void;
    description?: string;
}
export const RadioButton: React.VFC<RadioButtonProps> = props => {
    const { label, isSelected, onSelect, description } = props;
    const classes = useStyles();
    const backgroundColor = FlossPalette[isSelected ? 'PRIMARY_BACKGROUND' : 'TAN'];
    const border = `2px solid ${FlossPalette[isSelected ? 'PRIMARY_FOREGROUND' : 'DARK_TAN']}`;
    return (
        <div className={classes.radioButton} style={{ backgroundColor, border }} onClick={onSelect}>
            <Grid container wrap={'nowrap'}>
                <Grid item>
                    <Icon
                        icon={isSelected ? 'RadioButtonCheckedIcon' : 'RadioButtonUnCheckedIcon'}
                        className={isSelected ? classes.selectedRadio : classes.hoverRadio}
                        style={{ marginBottom: -8, marginRight: 16 }}
                    />
                </Grid>
                <Grid item>
                    <Text variant={'body2'} medium>
                        {label}
                    </Text>
                    {description && (
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {description}
                        </Text>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};

const ArchSelectionGridItems: React.VFC = () => {
    const selectedArchType = useDentureCheckoutSelector(state => state.fields.dentureArch);
    const setArchType = useDenturesCheckoutAction('CHOOSE_DENTURE_ARCH');
    return (
        <>
            <Grid item xs={12}>
                <Text variant={'h6'} medium>
                    Select the patient's arches
                </Text>
            </Grid>
            {Object.values(OrderItemArch).map(archType => (
                <Grid item xs={4} key={archType}>
                    <RadioButton
                        label={`${archType} Arch`}
                        isSelected={archType === selectedArchType}
                        onSelect={() => setArchType(archType)}
                    />
                </Grid>
            ))}
        </>
    );
};

const AboutBiteRim: React.VFC = () => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    // The exact (negative) margins below help reproduce the notch from figma.
    return (
        <div style={{ marginTop: -20 }}>
            <KeyboardArrowUpIcon
                fontSize={'large'}
                style={{
                    color: FlossPalette.DARK_TAN,
                    marginBottom: -20,
                    marginLeft: 12,
                    borderBottom: '1px solid white',
                    zIndex: 1,
                }}
            />
            <div style={{ padding: 16, border: `2px solid ${FlossPalette.DARK_TAN}`, borderRadius: 4 }}>
                <div
                    style={{ width: 15, borderTop: '2px solid white', marginTop: -18, marginLeft: 4, marginBottom: 18 }}
                />
                <Grid container alignItems={'center'}>
                    <Grid item xs={4} style={{ textAlign: 'center' }}>
                        <img src={'/checkout/denture/biterim.svg'} alt={'Bite Rim'} style={{ maxWidth: '100%' }} />
                    </Grid>
                    <Grid item xs={8}>
                        <Text variant={'body2'} medium style={{ marginBottom: 8 }}>
                            We will mail you a wax bite rim
                        </Text>
                        <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginBottom: 8 }}>
                            This is used to establish bite and teeth relationship
                        </Text>
                        <Text
                            variant={'body2'}
                            medium
                            color={'PRIMARY_FOREGROUND'}
                            style={{ marginBottom: 8, cursor: 'pointer' }}
                            onClick={event => setAnchorEl(anchorEl ? null : event.currentTarget)}
                        >
                            Why we use wax bite rims?
                            <HelpOutlineIcon style={{ marginBottom: -6, marginLeft: 10 }} />
                        </Text>
                        <Popover
                            open={!!anchorEl}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                            PaperProps={{ style: { maxWidth: 388, padding: 16, borderRadius: 16 } }}
                        >
                            <Text variant={'h6'} medium color={'DARK_GRAY'} style={{ marginBottom: 8 }}>
                                <HelpOutlineIcon style={{ marginBottom: -4, marginRight: 10 }} />
                                Why we use wax bite rims?
                            </Text>
                            <Text variant={'body2'}>
                                Dandy uses wax bite rims for patients who are (mostly) edentulous and do not have an
                                existing appliance or dentition to establish bite and / or vertical dimension. Applying
                                a light body wash to a wax bite rim also captures more information for the final
                                denture.
                            </Text>
                        </Popover>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

const ExactCopyChanges: React.VFC = () => {
    const exactCopyNotes = useDentureCheckoutSelector(state => state.fields.exactCopyNotes);
    const addExactCopyNotes = useDenturesCheckoutAction('ADD_EXACT_COPY_NOTES');
    return (
        <>
            <Grid item>
                <Text variant={'body1'}>What would you like us to change?</Text>
            </Grid>
            <Grid item>
                <CheckoutTextField
                    onChange={val => addExactCopyNotes(val)}
                    label={''}
                    required={false}
                    value={exactCopyNotes}
                    TextFieldProps={{
                        autoFocus: true,
                        InputLabelProps: {
                            shrink: true,
                        },
                        minRows: 2,
                        maxRows: 10,
                        multiline: true,
                    }}
                />
            </Grid>
        </>
    );
};

const CopyVsWaxRimSelectionGridItems: React.VFC = () => {
    const patient_first_name = useCheckoutSelector(state => state.patient_first_name);
    const selectedDentureType = useDentureCheckoutSelector(state => state.fields.dentureType);
    const isExactCopy = useDentureCheckoutSelector(state => state.fields.isExactCopy);
    const setSelectedDentureType = useDenturesCheckoutAction('CHOOSE_DENTURE_TYPE');
    const setIsExactCopy = useDenturesCheckoutAction('CHOOSE_IS_EXACT_COPY');
    const addExactCopyNotes = useDenturesCheckoutAction('ADD_EXACT_COPY_NOTES');
    return (
        <>
            <Grid item xs={12}>
                <Text variant={'h6'} medium>
                    Does {patient_first_name} have an existing denture?
                </Text>
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    label={'Yes'}
                    isSelected={selectedDentureType === DentureType.Copy}
                    onSelect={() => {
                        setSelectedDentureType(DentureType.Copy);
                    }}
                    description={`I will use the existing denture to establish ${patient_first_name}'s bite and vertical dimension`}
                />
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    label={'No'}
                    isSelected={selectedDentureType === DentureType.WaxRim}
                    onSelect={() => {
                        setSelectedDentureType(DentureType.WaxRim);
                        setIsExactCopy(undefined);
                        addExactCopyNotes(undefined);
                    }}
                    description={`${patient_first_name} has no existing denture to establish bite or vertical dimensions`}
                />
            </Grid>
            {selectedDentureType === DentureType.Copy && (
                <>
                    <Grid item xs={12}>
                        <Text variant={'h6'} medium>
                            Would you like an exact copy of it?
                        </Text>
                    </Grid>
                    <Grid item xs={6}>
                        <RadioButton
                            label={'Yes'}
                            isSelected={isExactCopy === true}
                            onSelect={() => {
                                setIsExactCopy(true);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <RadioButton
                            label={'No'}
                            isSelected={isExactCopy === false}
                            onSelect={() => {
                                setIsExactCopy(false);
                            }}
                        />
                    </Grid>
                    {isExactCopy === false && (
                        <>
                            <Grid item xs={6} />
                            <Grid item xs={6}>
                                <ExactCopyChanges />
                            </Grid>
                        </>
                    )}
                </>
            )}
            {selectedDentureType === DentureType.WaxRim && (
                <>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <AboutBiteRim />
                    </Grid>
                </>
            )}
        </>
    );
};

interface IntialSelectionGridItemsProps {
    newOrImmediate: NewOrImmediateType;
    setNewOrImmediate: (value: NewOrImmediateType) => void;
    exclude_wax_rim: boolean;
}
const InitialSelectionGridItems: React.VFC<IntialSelectionGridItemsProps> = props => {
    const { newOrImmediate, setNewOrImmediate, exclude_wax_rim } = props;
    const patient_first_name = useCheckoutSelector(state => state.patient_first_name);
    const setSelectedDentureType = useDenturesCheckoutAction('CHOOSE_DENTURE_TYPE');
    return (
        <>
            <Grid item xs={12}>
                <Text variant={'h6'} medium style={{ marginTop: 24 }}>
                    What type of denture does {patient_first_name} need?
                </Text>
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    label={'A new denture'}
                    isSelected={newOrImmediate === 'New'}
                    onSelect={() => {
                        setNewOrImmediate('New');
                        if (exclude_wax_rim) {
                            setSelectedDentureType(DentureType.Copy);
                        } else {
                            setSelectedDentureType(undefined);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    label={'An immediate denture'}
                    isSelected={newOrImmediate === 'Immediate'}
                    onSelect={() => {
                        setNewOrImmediate('Immediate');
                        setSelectedDentureType(DentureType.Immediate);
                    }}
                />
            </Grid>
        </>
    );
};

const ImplantSupportedGridItems: React.VFC = () => {
    const setImplantSupport = useDenturesCheckoutAction('SET_IMPLANT_SUPPORT');
    const implantSupport = useDentureCheckoutSelector(s => s.fields.implantSupport);
    const isImplantSupported = implantSupport?.isImplantSupported;

    return (
        <>
            <Grid item xs={12}>
                <Text variant={'h6'} medium style={{ marginTop: 24 }}>
                    Will the new denture be implant-supported?
                </Text>
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    label={'Yes'}
                    description={`The denture will feature recessed holes to simplify your chairside pickup of the housings`}
                    isSelected={isImplantSupported === true}
                    onSelect={() => {
                        setImplantSupport({ ...implantSupport, isImplantSupported: true });
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    label={'No'}
                    isSelected={isImplantSupported === false}
                    onSelect={() => {
                        setImplantSupport({ ...implantSupport, isImplantSupported: false });
                    }}
                />
            </Grid>
        </>
    );
};

export const DentureSelectTypeScreen: React.VFC = () => {
    const { fields } = useDentureCheckoutPropSelector(['fields']);
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(DentureCheckoutStep.InitialOrderChooseTypeStep);

    const selectedDentureType = useDentureCheckoutSelector(state => state.fields.dentureType);
    // eslint-disable-next-line no-nested-ternary
    const newOrImmediateSelected_base: NewOrImmediateType = !selectedDentureType
        ? null
        : selectedDentureType === DentureType.Immediate
          ? 'Immediate'
          : 'New';
    const [newOrImmediate, setNewOrImmediate] = React.useState<NewOrImmediateType>(newOrImmediateSelected_base);

    if (!isActiveStep) {
        return null;
    }
    const exclude_wax_rim = !!fields.waxRimContinuation?.isWaxRimContinuation;

    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <Grid
                container
                direction={'row'}
                wrap={'wrap'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                alignContent={'flex-start'}
                spacing={2}
            >
                <InitialSelectionGridItems
                    newOrImmediate={newOrImmediate}
                    setNewOrImmediate={setNewOrImmediate}
                    exclude_wax_rim={exclude_wax_rim}
                />
                {newOrImmediate === 'New' && <ImplantSupportedGridItems />}
                {newOrImmediate === 'New' && !exclude_wax_rim && <CopyVsWaxRimSelectionGridItems />}
                {newOrImmediate && <ArchSelectionGridItems />}
            </Grid>
        </CheckoutBodyWrapper>
    );
};
