import { useCheckoutSelector } from '../../../../redux';
import { LabsUtils } from '../../../labs/LabsUtils';
import { CheckoutPhotoUploadTypeSelect } from '../../components/PhotoUpload/CheckoutPhotoUploadTypeSelect';
import { useNumNewUploads } from '../../components/PhotoUpload/ImageUploadTypeSelect';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import { useDentureCheckoutStepInfo } from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { useDentureUploadedPhotos } from './dentures-upload-shared';
import { CheckoutBodyWrapper, CheckoutBanner } from '@orthly/dentin';
import { Grid, Green } from '@orthly/ui-primitives';
import React from 'react';

export const denturesPhotosBannerConfig = {
    head: (
        <>
            Cases with photos have <Green>lower remake rates</Green> and <Green>result in better smiles</Green>
        </>
    ),
    subhead: <>You may upload photos now, or after placing the order by navigating to the patient case details</>,
    imgSrc: '/click-photos.svg',
};

const InitialDenturePhotoUploadChoiceBody: React.VFC = () => {
    const scan = useCheckoutSelector(s => s.scan);
    const { numUploadedPhotos } = useDentureUploadedPhotos(scan?.id);
    const setDesktopUpload = useDenturesCheckoutAction('SET_DESKTOP_UPLOAD');
    const numNewUploads = useNumNewUploads(numUploadedPhotos);
    React.useEffect(() => {
        // if user hasn't overridden advance, and they've uploaded a photo via mobile since this component was loaded, automatically advance
        if (numNewUploads > 0) {
            setDesktopUpload(false);
        }
    }, [numNewUploads, setDesktopUpload]);

    return (
        <Grid container>
            <CheckoutPhotoUploadTypeSelect
                onDesktopUpload={() => setDesktopUpload(true)}
                getMobileURL={scanID => LabsUtils.getDenturesPhotoSubmitSrcUrl(scanID) ?? ''}
                productName={'dentures'}
            />
        </Grid>
    );
};

export const InitialDenturePhotoUploadChoiceScreen: React.VFC = () => {
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(
        DentureCheckoutStep.InitialNonWaxRimOrderPhotosChoiceStep,
    );
    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <Grid container alignContent={'flex-start'}>
                <Grid item xs={12} style={{ marginTop: 24, marginBottom: 24 }}>
                    <CheckoutBanner config={denturesPhotosBannerConfig} />
                </Grid>
                <InitialDenturePhotoUploadChoiceBody />
            </Grid>
        </CheckoutBodyWrapper>
    );
};
