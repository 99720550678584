import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const FullDenturesAestheticTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'new-denture-reference',
                type: 'New Dentures & Overdentures',
                requirements: 'Using the reference denture workflow with patient’s existing appliance',
                price: getPrice('Aesthetic Denture - New Denture, Reference Workflow $349'),
            },
            {
                id: 'new-denture-wax-rim',
                type: 'New Dentures & Overdentures',
                requirements: 'Using the wax bite rim workflow (includes wax bite rim and monolithic try-in)',
                price: getPrice('Aesthetic Denture - New Denture, Wax Rim Workflow $449'),
            },
            {
                id: 'extra-set',
                type: 'Extra set of Aesthetic Dentures',
                requirements: 'N/A',
                price: getPrice('Aesthetic Denture - Extra Set $259'),
            },
            {
                id: 'immediate',
                type: 'Immediate Dentures',
                requirements: 'N/A',
                price: getPrice('Aesthetic Denture - Immediate $329'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'full-dentures-aesthetic'}
            title={'Aesthetic dentures'}
            columns={[
                {
                    id: 'type',
                    title: 'Type',
                },
                {
                    id: 'requirements',
                    title: 'Requirements',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
