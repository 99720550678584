import type { NotesSummaryMenuActionProps } from '../OrderDetails/NotesSummary/NotesSummary.types';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useEditRefabricationMutation } from '@orthly/graphql-react';
import { RootActionDialog, SimpleInput, useChangeSubmissionFn } from '@orthly/ui';
import { Button, Text, PencilOutlinedIcon, styled } from '@orthly/ui-primitives';
import React from 'react';

const StyledNoteButton = styled(Button)({
    minWidth: 'unset',
    padding: 0,
    height: 'fit-content',
});

export const RefabGeneralNotesEditorChip: React.VFC<NotesSummaryMenuActionProps> = ({
    refetch,
    childProps,
    open,
    setOpen,
}) => {
    const savedNotesForRefab = childProps?.savedNotesForRefab ?? '';
    const orderId = childProps?.order?.id ?? '';

    const [notes, setNotes] = React.useState(savedNotesForRefab);
    const [submitMtn] = useEditRefabricationMutation();
    const { submit, submitting } = useChangeSubmissionFn(
        () => submitMtn({ variables: { orderId, refabricationNotes: notes } }),
        {
            onSuccess: () => {
                setOpen(false);
                if (refetch) {
                    void refetch();
                }
                BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - Refab notes edited', {
                    $groups: { order: orderId },
                });
            },
        },
    );

    if (!orderId || !childProps?.showRefabEditChips) {
        return null;
    }
    return (
        <>
            <StyledNoteButton onClick={() => setOpen(true)} variant={'ghost'}>
                <PencilOutlinedIcon />
            </StyledNoteButton>
            <RootActionDialog
                hideButton={true}
                loading={submitting}
                open={open}
                setOpen={setOpen}
                title={
                    <Text variant={'body1'} medium>
                        Edit general notes
                    </Text>
                }
                content={
                    <SimpleInput
                        label={'General notes'}
                        value={notes}
                        onChange={value => setNotes(value ?? '')}
                        fullWidth={true}
                        TextFieldProps={{ multiline: true }}
                    />
                }
                actions={
                    <Button variant={'primary'} onClick={submit} disabled={submitting || notes === savedNotesForRefab}>
                        Save
                    </Button>
                }
            />
        </>
    );
};
