import type { SelectStaffState } from './select-staff.reducer';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { ValidationUtils } from '@orthly/runtime-utils';

export enum CreateStaffStep {
    Animation = 'Animation',
    Landing = 'Landing',
    Occupation = 'Occupation',
    Responsibilities = 'Responsibilities',
    Photo = 'Photo',
    Contact = 'Contact',
    OrderPreferences = 'OrderPreferences',
    CreationSuccess = 'CreationSuccess',
}

type StepProgression =
    | {
          nextStep: CreateStaffStep;
          error: null;
      }
    | {
          nextStep: null;
          error: string;
      };

function verifyContact(profileCreationState: SelectStaffState['profileCreationState']) {
    const name = profileCreationState?.name;
    const contact_email = profileCreationState?.contact_email;
    const contact_phone = profileCreationState?.contact_phone;
    const remaining = [name, contact_email, contact_phone].filter(draft => !draft).length;
    if (remaining > 0) {
        return `Complete all ${remaining} remaining fields to continue`;
    }
    if (!ValidationUtils.isEmail(contact_email)) {
        return 'Please enter a valid email';
    }
    if (!ValidationUtils.isPhone(contact_phone)) {
        return 'Please enter a valid phone number';
    }
}

export const CreateStaffStepProgression: {
    [step in CreateStaffStep]: (state: SelectStaffState) => StepProgression;
} = {
    [CreateStaffStep.Animation]: () => ({ error: null, nextStep: CreateStaffStep.Landing }),
    [CreateStaffStep.Landing]: () => ({ error: null, nextStep: CreateStaffStep.Occupation }),
    [CreateStaffStep.Occupation]: ({ profileCreationState }) =>
        typeof profileCreationState?.occupation !== 'undefined'
            ? { error: null, nextStep: CreateStaffStep.Responsibilities }
            : { error: 'Complete Occupation to continue', nextStep: null },
    [CreateStaffStep.Responsibilities]: ({ profileCreationState }) =>
        (profileCreationState?.responsibilities.length === 0 && !profileCreationState.responsibility_notes) ||
        profileCreationState.responsibility_notes === ''
            ? { error: 'Fill out your responsibilities to continue', nextStep: null }
            : { error: null, nextStep: CreateStaffStep.Photo },
    [CreateStaffStep.Photo]: ({ profileCreationState }) =>
        profileCreationState?.profile_photo
            ? { error: null, nextStep: CreateStaffStep.Contact }
            : { error: 'Complete Photo to continue', nextStep: null },
    [CreateStaffStep.Contact]: ({ profileCreationState }) => {
        const error = verifyContact(profileCreationState);
        if (error) {
            return { error, nextStep: null };
        }
        return {
            error: null,
            nextStep:
                profileCreationState?.occupation === LabsGqlStaffRolePractice.PracticeDoctor
                    ? CreateStaffStep.OrderPreferences
                    : CreateStaffStep.CreationSuccess,
        };
    },
    [CreateStaffStep.OrderPreferences]: () => ({ error: null, nextStep: CreateStaffStep.CreationSuccess }),
    [CreateStaffStep.CreationSuccess]: () => ({ error: null, nextStep: CreateStaffStep.Landing }),
};
