import { useAlignerCheckoutPropSelector } from '../../../../redux';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { AlignerRejection, CheckoutBodyWrapper } from '@orthly/dentin';
import React from 'react';

export const AlignerCheckoutRejection: React.VFC = () => {
    const { rejection } = useAlignerCheckoutPropSelector(['step', 'rejection']);
    const { isActiveStep } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.Rejection);

    if (!isActiveStep || !rejection) {
        return null;
    }

    return (
        <CheckoutBodyWrapper style={{ height: '100%', marginBottom: 16 }} visible={isActiveStep} isComplete={true}>
            <AlignerRejection reason={rejection.reason} notes={rejection.notes} />
        </CheckoutBodyWrapper>
    );
};
