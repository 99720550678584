import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrdersByIds } from '@orthly/graphql-react';
import { useToolbarContainerHeight } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';
import type { ListOnItemsRenderedProps } from 'react-window';

const LOAD_EXTRA = 10;

export function useListDisplayState(itemHeight: number, toolbarHeightOverride?: number) {
    const toolbarHeight = useToolbarContainerHeight();
    const listHeight = window.innerHeight - (toolbarHeightOverride ?? toolbarHeight);
    const visibleItemCount = Math.ceil(listHeight / itemHeight);
    return { listHeight, visibleItemCount };
}

export interface UseVirtualizedListOrdersRes<ListItemContent> {
    onItemsRendered: (args: ListOnItemsRenderedProps) => void;
    orders: (LabsGqlOrder | undefined)[];
    listHeight: number;
    ids: string[];
    startIndex: number;
    orderToListItemContent: (order: LabsGqlOrder) => ListItemContent;
    idsLoading: boolean;
    style?: React.CSSProperties;
}

export interface UseVirtualizedListItemsProps<ListItemContent> {
    itemHeight: number;
    initialFocusedId?: string;
    orderIds: string[];
    idsLoading: boolean;
    orderToListItemContent: (order: LabsGqlOrder) => ListItemContent;
    toolbarHeight?: number;
}

export interface VirtualizedListProps {
    itemHeight: number;
    orderIds: string[];
    idsLoading: boolean;
    initialFocusedId?: string;
}

type CancelableRef = ((newVal: [number, number]) => void) & _.Cancelable;

export function useVirtualizedListOrders<ListItemContent>(
    props: UseVirtualizedListItemsProps<ListItemContent>,
): UseVirtualizedListOrdersRes<ListItemContent> {
    const { idsLoading, initialFocusedId, itemHeight, orderIds, orderToListItemContent, toolbarHeight } = props;
    const { listHeight, visibleItemCount } = useListDisplayState(itemHeight, toolbarHeight);
    const initialStartIndex = initialFocusedId ? Math.max(0, orderIds.indexOf(initialFocusedId)) : 0;
    const [[startIndex, stopIndex], setIndexes] = React.useState<[number, number]>([
        initialStartIndex,
        initialStartIndex + visibleItemCount + LOAD_EXTRA,
    ]);
    // keep indexes temporarily and commit them when we're not loading
    const [tempIndexes, setTempIndexes] = React.useState<[number, number]>([0, 0]);
    const debouncedSetIndexes = React.useRef<CancelableRef>(
        _.debounce(setTempIndexes, 100, { trailing: true, leading: false }),
    );
    const visibleIds = React.useMemo(() => orderIds.slice(startIndex, stopIndex), [orderIds, startIndex, stopIndex]);
    const { orders, loading } = useOrdersByIds(visibleIds);

    const onItemsRendered = React.useCallback(
        ({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps) => {
            debouncedSetIndexes.current.cancel();
            debouncedSetIndexes.current([
                Math.max(0, visibleStartIndex - LOAD_EXTRA),
                Math.min(orderIds.length, visibleStopIndex + LOAD_EXTRA + 1),
            ]);
        },
        [orderIds.length],
    );
    React.useEffect(() => {
        !loading && setIndexes(tempIndexes);
    }, [tempIndexes, loading]);
    return { idsLoading, onItemsRendered, orders, listHeight, startIndex, orderToListItemContent, ids: orderIds };
}
