import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { PricingGuideContextProvider } from './PricingGuide.context';
import { PricingGuideTabs } from './tabs/PricingGuideTabs';
import React from 'react';

interface PricingGuideProps {
    isModal?: boolean;
}

export const PricingGuide: React.FC<PricingGuideProps> = ({ isModal }) => (
    <PracticeSettingsBase
        title={'Pricing guide'}
        Content={
            <PricingGuideContextProvider>
                <PricingGuideTabs isModal={isModal} />
            </PricingGuideContextProvider>
        }
    />
);
