import ConfettiOverlay from '../../../assets/confetti-overlay.svg';
import { useCurrentProfile } from '../../../redux';
import { SelectStaffContent } from '../components';
import { useSelectStaffAction } from '../state/select-staff.actions';
import { CreateStaffStep } from '../state/select-staff.progression';
import { useSelectStaffSelector } from '../state/select-staff.selectors';
import { stylesFactory, Card, Grid, Button, Text } from '@orthly/ui-primitives';
import { DoctorProfilePhotoContent, isBlobURL } from '@orthly/veneer';
import clsx from 'clsx';
import React from 'react';

const PHOTO_WIDTH = 128;
const CENTER_GAP = 24;
const CARD_WIDTH = 320;
const CARD_GAP = 12;

enum AnimationStepDelay {
    Confetti = '0s',
    BumpLeft = '1s',
    SecondCardUp = '2.3s',
    ButtonUp = '3.6s',
}
const AnimationCommons = {
    animationDuration: '1s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 1,
    animationFillMode: 'both',
};

const useStyles = stylesFactory(theme => ({
    centerStack: {
        position: 'relative',
        '&>*:not(:first-child)': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
    },
    flex: {
        minHeight: 300,
        flexWrap: 'nowrap',
        justifyContent: 'center',
        gap: `${CENTER_GAP}px`,
        flexDirection: 'row',
        alignItems: 'flex-end',
        marginTop: 32,
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    card: {
        padding: '16px 24px',
        borderRadius: 16,
        boxSizing: 'border-box',
    },
    photoAppear: {
        ...AnimationCommons,
        animationName: '$photoAppear',
    },
    '@keyframes photoAppear': {
        '0%': { opacity: 0, transform: 'scale(0)' },
        '70%': { opacity: 1, transform: 'scale(110%)' },
        '100%': { opacity: 1, transform: 'scale(100%)' },
    },
    bumpLeft: {
        ...AnimationCommons,
        animationName: '$bumpLeft',
        [theme.breakpoints.down('lg')]: { animationName: 'none' },
    },
    '@keyframes bumpLeft': {
        '0%': { transform: `translateX(${(CENTER_GAP + CARD_WIDTH) / 2}px)` },
        '25%': { transform: `translateX(${(CENTER_GAP + CARD_WIDTH) / 2 + 24}px)` },
        '75%': { transform: `translateX(-24px)` },
        '100%': { transform: `translateX(0)` },
    },
    bumpLeftFadeIn: {
        ...AnimationCommons,
        animationName: '$bumpLeftFadeIn',
        [theme.breakpoints.down('lg')]: { animationName: 'none' },
    },
    '@keyframes bumpLeftFadeIn': {
        '0%': { opacity: 0 },
        '50%': { opacity: 0 },
        '100%': { opacity: 1 },
    },
    cardUp: {
        ...AnimationCommons,
        animationName: '$cardUp',
        [theme.breakpoints.down('lg')]: {
            animationName: 'none',
            marginTop: CARD_GAP,
        },
    },
    '@keyframes cardUp': {
        '0%': { marginTop: 0, maxHeight: 0, paddingTop: 0, paddingBottom: 0, opacity: 0 },
        '25%': { marginTop: -CARD_GAP, maxHeight: 0, paddingTop: 0, paddingBottom: 0 },
        '75%': { marginTop: CARD_GAP * 2, maxHeight: 100, opacity: 1 },
        '100%': { marginTop: CARD_GAP },
    },
}));

export const SelectStaffCreationSuccess: React.VFC = () => {
    const classes = useStyles();
    const currentProfile = useCurrentProfile();
    const { created_profile, profile_photo, name, createWithoutAutoLogin } = useSelectStaffSelector(
        ({ profileCreationState, createWithoutAutoLogin }) => ({
            ...profileCreationState,
            createWithoutAutoLogin,
        }),
    );
    const selectEmployee = useSelectStaffAction('SELECT_PRACTICE_EMPLOYEE');
    const logoutStaff = useSelectStaffAction('LOGOUT_STAFF');

    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.CreationSuccess);
    return (
        <SelectStaffContent show={show} alignItems={'center'}>
            <Grid
                container
                className={clsx(classes.flex, classes.bumpLeft)}
                style={{ animationDelay: AnimationStepDelay.BumpLeft }}
            >
                <Grid className={clsx(classes.centerStack, classes.photoAppear)} style={{ zIndex: 1 }}>
                    {/**
                     * if we have a blob url for the profile photo, use it instead of firebase.
                     * firebase is too slow and won't load before animation starts.
                     * @see `SelectStaffWebcam.tsx`
                     */}
                    <DoctorProfilePhotoContent
                        type={profile_photo.type}
                        source={profile_photo.source ?? name ?? 'T'}
                        color={profile_photo.color}
                        style={{ borderRadius: '50%', width: PHOTO_WIDTH }}
                        sourceCanbeBlob
                    />
                    <img src={ConfettiOverlay} alt={''} />
                </Grid>
                <Grid
                    container
                    direction={'column'}
                    wrap={'nowrap'}
                    className={classes.bumpLeftFadeIn}
                    style={{ width: CARD_WIDTH }}
                >
                    <Card
                        className={clsx(classes.card, classes.bumpLeftFadeIn)}
                        elevation={0}
                        style={{ animationDelay: AnimationStepDelay.BumpLeft }}
                    >
                        <Text variant={'h6'} medium>
                            Welcome to Dandy!
                        </Text>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            We're super glad to have you with us{name ? `, ${name}` : ''}!
                        </Text>
                    </Card>
                    <Card
                        className={clsx(classes.card, classes.cardUp)}
                        elevation={0}
                        style={{ animationDelay: AnimationStepDelay.SecondCardUp }}
                    >
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            Are you ready to start your Dandy adventure?
                        </Text>
                    </Card>
                    <Button
                        variant={'primary'}
                        className={classes.cardUp}
                        style={{ animationDelay: AnimationStepDelay.ButtonUp }}
                        onClick={() => {
                            /**
                             * end of profile creation.
                             * if we created a temporary blob url for the profile photo, it will be safe to revoke it now.
                             * @see `SelectStaffWebcam.tsx`
                             */
                            if (profile_photo.source && isBlobURL(profile_photo.source)) {
                                URL.revokeObjectURL(profile_photo.source);
                            }
                            if (!!currentProfile && createWithoutAutoLogin) {
                                logoutStaff();
                                return;
                            }
                            if (created_profile) {
                                selectEmployee(created_profile);
                            }
                        }}
                    >
                        Enter your Dandy portal
                    </Button>
                </Grid>
            </Grid>
        </SelectStaffContent>
    );
};
