import type { ButtonVariant } from '@orthly/ui-primitives';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

interface ExploreHelpCenterButtonProps {
    buttonVariant?: ButtonVariant;
    leftAlign?: boolean;
}

export const ExploreHelpCenterButton: React.FC<ExploreHelpCenterButtonProps> = ({
    buttonVariant = 'ghost',
    leftAlign,
}) => {
    return (
        <Button
            variant={buttonVariant}
            leftAlign={leftAlign}
            startIcon={'BookIcon'}
            onClick={() => window.open(`https://dandy.dental/help-center`, 'DandyHelpCenter')?.focus()}
        >
            Explore help center
        </Button>
    );
};
