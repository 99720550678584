import { useLabsContext } from '../../../LabsContext';
import { useOrderDetailSection } from '../../OrderDetailSectionProvider';
import { OrderDetailSection } from './OrderDetailSection';
import type { OrderDetailSharedProps } from './OrderDetailSection.types';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import { OrderWaxup, shouldShowWaxup } from '@orthly/veneer';
import React from 'react';

const OrderDetailDesignViewerBlock: React.FC<OrderDetailSharedProps> = ({ order, refetch }) => {
    const { userRole } = useLabsContext();
    const isMobile = useScreenIsMobile();

    return <OrderWaxup order={order} userRole={userRole} refetchOrder={refetch} clickToLoad={isMobile} />;
};

export const OrderDetailDesignSection: React.FC<OrderDetailSharedProps> = ({ order, refetch }) => {
    const { setDesignRefs } = useOrderDetailSection();

    const showDesign = shouldShowWaxup(order);

    if (!showDesign) {
        return null;
    }

    return (
        <OrderDetailSection title={'Design'} ref={setDesignRefs}>
            <OrderDetailDesignViewerBlock refetch={refetch} order={order} />
        </OrderDetailSection>
    );
};
