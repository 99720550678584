import {
    useCreatePaymentMethodMutation,
    useLabPaymentMethodsQuery,
    useRemovePaymentMethodMutation,
    useSetDefaultPaymentMethodMutation,
} from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

type CreateRes = { partnerId: string; cardToken: string; setAsDefault: boolean | null };
type EditRes = { partnerId: string; cardId: string };

export function usePaymentMethodControls() {
    const { loading, data, refetch } = useLabPaymentMethodsQuery({ nextFetchPolicy: 'cache-and-network' });

    const [createPaymentMethodMtn] = useCreatePaymentMethodMutation();
    const createSubmitter = (variables: { partnerId: string; cardToken: string; setAsDefault: boolean | null }) =>
        createPaymentMethodMtn({ variables });
    const { submit: createPaymentMethod, submitting: createSubmitting } = useChangeSubmissionFn<any, [CreateRes]>(
        createSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => ['Payment method added', {}],
            onSuccess: async () => {
                await refetch();
            },
        },
    );

    const [removeMethodMtn] = useRemovePaymentMethodMutation();
    const removeSubmitter = (variables: { partnerId: string; cardId: string }) => removeMethodMtn({ variables });
    const { submit: removeMethod, submitting: removeSubmitting } = useChangeSubmissionFn<any, [EditRes]>(
        removeSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => ['Payment method removed', {}],
            onSuccess: async () => {
                await refetch();
            },
        },
    );

    const [setDefaultMtn] = useSetDefaultPaymentMethodMutation();
    const setDefaultSubmitter = (variables: { partnerId: string; cardId: string }) => setDefaultMtn({ variables });
    const { submit: setDefaultMethod, submitting: setDefaultSubmitting } = useChangeSubmissionFn<any, [EditRes]>(
        setDefaultSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => ['Default payment method updated', {}],
            onSuccess: async () => {
                await refetch();
            },
        },
    );

    return {
        createPaymentMethod,
        removeMethod,
        setDefaultMethod,
        methods: data?.getLabsPaymentMethods,
        loading: loading || createSubmitting || removeSubmitting || setDefaultSubmitting,
    };
}
