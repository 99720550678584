import { useAlignerCheckoutSelector, useCheckoutSelector } from '../../../../redux';
import { useScanIsAligner } from '../../state/checkout.selectors';
import { useDentureCheckoutSelector, useScanIsDenture } from '../../state/dentures-checkout.selectors';
import { getAlignerCheckoutSidebarData, getDentureCheckoutSidebarData, getRegularCheckoutSidebarData } from './adapter';
import { CheckoutSidebarWithConcierge } from './components';
import React from 'react';

export const CheckoutSidebarRootV3: React.FC = () => {
    const checkout = useCheckoutSelector(s => s);

    const isAlignerOrder = useScanIsAligner();
    const alignerCheckout = useAlignerCheckoutSelector(alignerCheckout => (isAlignerOrder ? alignerCheckout : null));

    const isDentureOrder = useScanIsDenture();
    const dentureCheckout = useDentureCheckoutSelector(dentureCheckout => (isDentureOrder ? dentureCheckout : null));

    if (alignerCheckout) {
        return <CheckoutSidebarWithConcierge steps={getAlignerCheckoutSidebarData(alignerCheckout, checkout)} />;
    }

    if (dentureCheckout) {
        return <CheckoutSidebarWithConcierge steps={getDentureCheckoutSidebarData(dentureCheckout, checkout)} />;
    }

    return <CheckoutSidebarWithConcierge steps={getRegularCheckoutSidebarData(checkout)} />;
};
