import { useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import { MobileRejectionNotesContainer } from '../MobileRejectionNotesContainer';
import { UnfinishedPresetsMessage } from '../UnfinishedPresetsMessage';
import { OtherNotesControl } from './components/OtherNotesControl';
import { PresetReviewControls } from './components/PresetReviewControls/PresetReviewControls';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Box, FlossPalette, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const GeneralReviewStructuredRejectionNotesContent: React.VFC = () => {
    const presets = useGuidedWaxupSelector(s => s.presets);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                '& > *': {
                    paddingBottom: '16px',
                    '&:not(:last-child)': {
                        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
                    },
                },
            }}
        >
            {Object.entries(presets).map(([presetType, preset]) =>
                presetType === LabsGqlGuidedWaxupPresetType.GeneralView ? (
                    <OtherNotesControl key={presetType} preset={preset} />
                ) : (
                    <PresetReviewControls
                        key={presetType}
                        preset={preset}
                        presetType={presetType as LabsGqlGuidedWaxupPresetType}
                    />
                ),
            )}
        </Box>
    );
};

export const GeneralReviewStructuredRejectionNotes: React.FC<{
    isOpen: boolean;
    onBack: () => any;
    onCancel?: () => any;
    onClose: () => any;
    closeLabel: string;
    closeDisabled?: boolean;
    title: string;
}> = ({ isOpen, onBack, onClose, onCancel, closeDisabled, closeLabel, title }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();

    if (isMobile) {
        return (
            <MobileRejectionNotesContainer
                isOpen={isOpen}
                onBack={onBack}
                onCancel={onCancel}
                onClose={onClose}
                title={title}
                closeLabel={closeLabel}
                closeDisabled={closeDisabled}
                footerPreface={<UnfinishedPresetsMessage />}
            >
                <GeneralReviewStructuredRejectionNotesContent />
            </MobileRejectionNotesContainer>
        );
    }

    return <GeneralReviewStructuredRejectionNotesContent />;
};
