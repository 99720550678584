import { CheckoutPhotoUploadTypeSelect } from './CheckoutPhotoUploadTypeSelect';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Keeps track of the increase in photo uploads since the hook was first called after mount.
 * Will lower that initial count if a photo is deleted, so that additional uploads will be counted.
 *
 * E.g.,
 *
 * Calls: useNumNewUploads(0) -> useNumNewUploads(1) -> useNumNewUploads(1) -> useNumNewUploads(2)
 * Returns:                0                      1                      1                      2
 *
 * Calls: useNumNewUploads(0) -> useNumNewUploads(1) -> useNumNewUploads(0) -> useNumNewUploads(2)
 * Returns:                0                      1                      0                      2
 *
 * Calls: useNumNewUploads(5) -> useNumNewUploads(6) -> useNumNewUploads(4) -> useNumNewUploads(5)
 * Returns:                0                      1                      0                      1
 *
 * @param numUploadedPhotos: current count of uploaded photos from uploadStatus
 * @returns the difference from the initial mount call
 */

export function useNumNewUploads(numUploadedPhotos: number): number {
    const initialNumUploadedPhotosRef = React.useRef<number | null>(null);

    // this will run after the returns below
    React.useEffect(() => {
        // if this is the first run or if photos were removed, set the count so addl uploads will be counted
        if (initialNumUploadedPhotosRef.current === null || numUploadedPhotos < initialNumUploadedPhotosRef.current) {
            initialNumUploadedPhotosRef.current = numUploadedPhotos;
        }
    }, [numUploadedPhotos]);

    const { current: initial } = initialNumUploadedPhotosRef;

    if (initial === null) {
        // this is the first run, so any uploads are from before mount
        return 0;
    }

    if (numUploadedPhotos < initial) {
        // if photos were removed, treat as new baseline so an upload after removal will be counted
        // useEffect will update next time
        return 0;
    }

    // usual case, just return the difference
    return numUploadedPhotos - initial;
}

// A lightweight wrapper around PhotoUploadTypeSelect used by implant and aesthetics checkout flows.
export const ImageUploadTypeSelect: React.FC<{
    numUploadedPhotos: number;
    allowInstructions: boolean;
    onClickDesktop: () => void;
    onMobilePhotoUpload: () => void;
    head: { text: string } | { element: React.ReactElement };
    mobileUrl: (scanId: string) => string;
}> = ({ numUploadedPhotos, allowInstructions, onClickDesktop, onMobilePhotoUpload, head, mobileUrl }) => {
    const numNewUploads = useNumNewUploads(numUploadedPhotos);
    React.useEffect(() => {
        // if user hasn't overridden advance, and they've uploaded a photo via mobile since this component was loaded, automatically advance
        if (!allowInstructions && numNewUploads > 0) {
            onMobilePhotoUpload();
        }
    }, [allowInstructions, numNewUploads, onMobilePhotoUpload]);

    return (
        <>
            {'text' in head ? (
                <Text variant={'body2'} style={{ marginTop: 16, marginBottom: 32, fontSize: '1.25rem' }}>
                    {head.text}
                </Text>
            ) : (
                <Grid item xs={12}>
                    {head.element}
                </Grid>
            )}

            <CheckoutPhotoUploadTypeSelect
                onDesktopUpload={() => onClickDesktop()}
                getMobileURL={mobileUrl}
                productName={'implant'}
            />
        </>
    );
};
