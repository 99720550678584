import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';

export const useForwardingStyles = stylesFactory(theme => ({
    forwardingInfo: {
        backgroundColor: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: 16,
        alignItems: 'center',
        borderRadius: 8,
    },
    forwardingInfoText: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 16,
        },
    },
    receivingIcon: {
        marginRight: 12,
        transform: 'rotate(45deg) scaleX(-1)',
    },
    forwardingIcon: {
        marginRight: 12,
        transform: 'rotate(-45deg)',
    },
}));

export const useForwardingModalStyles = stylesFactory(() => ({
    buttonLabel: {
        whiteSpace: 'nowrap',
    },
}));
