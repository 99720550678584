import { useShowMobileLayout } from '../../../utils/LayoutUtils';
import { useInvoicesTableContext } from '../components/providers/InvoicesTableProvider.graphql';
import { PaymentAction } from '../payments/components/PaymentAction.graphql';
import { PrintableInvoicesList } from './PrintableInvoicesList.graphql';
import { LoadBlocker } from '@orthly/ui';
import { Button, FlossPalette, Icon, Text, styled, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';

const FixedContainer = styled('div', { shouldForwardProp: p => p !== 'isMobile' })<{ isMobile: boolean }>(
    ({ isMobile }) => ({
        position: 'fixed',
        bottom: isMobile ? '98px' : '48px',
        height: '40px',
        backgroundColor: 'white',
        borderRadius: '8px',
        outline: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    }),
);

const CountText = styled(Text)({
    display: 'inline-block',
    height: '40px',
    padding: '8px 16px',
    lineHeight: '20px',
});

const BorderedButton = styled('div')({
    display: 'inline-block',
    borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    height: '40px',
});

const StyledButton = styled(Button)({
    height: '40px',
    padding: '8px 16px',
    borderRadius: 0,
});

const StyledLastButton = styled(Button)({
    height: '40px',
    padding: '8px 16px',
    borderRadius: '0px 8px 8px 0px',
});

const StyledIcon = styled(Icon)({
    marginLeft: '8px',
});

export const InvoicesTableControls: React.FC = () => {
    const {
        selectedInvoiceIds,
        payableSelectedInvoices,
        printableSlipIsOpen,
        openPrintableSlip,
        isReadyToPrint,
        fetchPrintData,
        fetchPrintDataLoading,
    } = useInvoicesTableContext();
    const isMobile = useScreenIsMobile();
    const isMobileLayout = useShowMobileLayout();

    React.useEffect(() => {
        // once the invoice data has been fetched, we can open the printable slip
        if (isReadyToPrint) {
            document.title = `Dandy Statement${selectedInvoiceIds.length > 1 ? 's' : ''}`;
            openPrintableSlip();
        }
    }, [isReadyToPrint, openPrintableSlip, selectedInvoiceIds.length]);

    if (!selectedInvoiceIds.length) {
        return null;
    }

    const payableText =
        payableSelectedInvoices.length > 1
            ? `Pay ${payableSelectedInvoices.length} selected invoices together`
            : 'Pay selected invoice';

    return (
        <>
            {printableSlipIsOpen && <PrintableInvoicesList />}

            <FixedContainer isMobile={isMobile || isMobileLayout}>
                <CountText variant={'body2'} color={'PRIMARY_FOREGROUND'} medium>
                    {selectedInvoiceIds.length} selected
                </CountText>
                {!!payableSelectedInvoices.length && (
                    <BorderedButton>
                        <PaymentAction
                            CustomButton={({ onClick }) => (
                                <StyledButton variant={'ghost'} onClick={onClick}>
                                    {payableText}
                                    <StyledIcon icon={'ChevronRight'} />
                                </StyledButton>
                            )}
                        />
                    </BorderedButton>
                )}

                <BorderedButton>
                    <LoadBlocker blocking={fetchPrintDataLoading}>
                        <StyledLastButton variant={'ghost'} onClick={fetchPrintData}>
                            Download PDFs <StyledIcon icon={'OrderPaperIcon'} />
                        </StyledLastButton>
                    </LoadBlocker>
                </BorderedButton>
            </FixedContainer>
        </>
    );
};
