import { type InvoiceTableRow } from '../invoicing.types';
import { useInvoiceDetailStatus, previewUsageInvoiceId, daysUntilInvoiceDue } from '../invoicing.utils';
import { Icon, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const StatusContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

const StatusText = styled(Text, { shouldForwardProp: p => p !== 'isOverdue' })<{ isOverdue: boolean }>(
    ({ isOverdue }) => ({
        display: 'inline-block',
        fontWeight: isOverdue ? 600 : 400,
    }),
);

const AdditionalStatusText = styled(Text)({
    fontWeight: 400,
    display: 'inline-block',
    paddingLeft: '4px',
});

const StyledIcon = styled(Icon)({
    marginRight: '4px',
});

export const StatusDescriptionCell: React.FC<{
    invoice: InvoiceTableRow;
    autochargeEnabled: boolean;
    isMultiLoc: boolean;
}> = ({ invoice, autochargeEnabled, isMultiLoc }) => {
    const isPreviewInvoice = invoice.id === previewUsageInvoiceId;
    const status = useInvoiceDetailStatus(invoice, autochargeEnabled);
    const isOverdue = invoice.is_overdue && daysUntilInvoiceDue(invoice) < 0;

    let additionalText = '';
    const { spendTermSequence, spendTermInvoiceCount } = invoice;
    if (spendTermInvoiceCount > 1) {
        additionalText = `(month ${spendTermSequence + 1} of ${spendTermInvoiceCount})`;
    }
    return (
        <StatusContainer>
            {isMultiLoc && status === 'Paid' && <StyledIcon icon={'CheckIcon'} fontSize={'small'} />}
            <StatusText variant={'body2'} color={isOverdue ? 'ATTENTION' : 'BLACK'} isOverdue={isOverdue}>
                {isPreviewInvoice ? 'Ongoing' : status}
            </StatusText>
            {additionalText && (
                <AdditionalStatusText color={'GRAY'} variant={'body2'}>
                    {additionalText}
                </AdditionalStatusText>
            )}
        </StatusContainer>
    );
};
