import { PracticeSettingsBaseContent } from '../account/components/PracticeSettings/PracticeSettingsBaseContent';
import { PracticeSettingsTitle } from '../account/components/PracticeSettings/PracticeSettingsBaseTitle';
import { TrainingDialogStage } from '../getting_started/components/TrainingDataIntake/TrainingDataIntake.types';
import { TrainingDataIntakeForm } from '../getting_started/components/TrainingDataIntake/TrainingDataIntakeForm';
import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    background: FlossPalette.WHITE,
    overflow: 'auto',
    minHeight: 0,
    width: '100%',
});
const Content = styled('div')({
    padding: '32px 24px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});
const FormWrapper = styled('div')({
    maxWidth: '522px',
});
const Footer = styled('div')(({ theme }) => ({
    padding: theme.spacing(3),
    paddingTop: theme.spacing(0),
}));

const useTitles = (stage: TrainingDialogStage) => {
    if (stage === TrainingDialogStage.FIRST) {
        return {
            title: 'Your practice’s training session',
            description: `Please fill out this form for each person who plans on attending Launch Training. This will help us tailor the training to your staff's current scanning experience.`,
        };
    }

    return {
        title: 'Retraction methods, prep styles, & more',
        description: 'Help us make your training session as effective as possible.',
    };
};

interface PersonalizeTrainingStepProps {
    stage: TrainingDialogStage;
}

export const PersonalizeTrainingStep: React.VFC<PersonalizeTrainingStepProps> = ({ stage }) => {
    const { title, description } = useTitles(stage);
    return (
        <Container>
            <PracticeSettingsTitle title={'Personalize your training'} style={{ marginTop: 0 }} />
            <PracticeSettingsBaseContent
                Content={
                    <Content>
                        <Text variant={'body1'} medium>
                            {title}
                        </Text>

                        <Text variant={'body2'} color={'GRAY'}>
                            {description}
                        </Text>
                        <FormWrapper>
                            <TrainingDataIntakeForm stage={stage} fullheight />
                        </FormWrapper>
                    </Content>
                }
            />
            <Footer>
                <Text variant={'caption'} color={'GRAY'}>
                    Fields marked with * are required to proceed.
                </Text>
            </Footer>
        </Container>
    );
};
