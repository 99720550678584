import { useCheckoutPropSelector } from '../../../../redux';
import { CheckoutItemV2Manager } from '../../state/CheckoutItemV2Manager';
import { useCheckoutAction } from '../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../state/checkout.state';
import { CheckoutOrderNotesV2 } from './CheckoutOrderNotesV2';
import { CheckoutSummaryItemContent, getSummaryItemSubtitle } from './CheckoutOrderNotesV2';
import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, makeStyles, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemRow: {
            padding: '12px 0',
            // this is just PEARL_HARD at 0.5 opacity
            borderBottom: `1px solid rgba(228, 228, 228, 0.5)`,

            '&:hover > *': {
                color: FlossPalette.HIGHLIGHT_BLUE,
            },
        },
        itemTitlePrice: {
            fontSize: '1.1em',
            fontWeight: 500,
        },
        itemRowsColumn: {
            maxHeight: '100%',
            height: '100%',
            overflow: 'scroll',
            flexWrap: 'nowrap',
            // account for bottom button row
            paddingBottom: 64,
            [theme.breakpoints.down('md')]: {
                paddingBottom: 0,
            },
        },
    }),
);

const CheckoutSummaryItem: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const goToItem = useCheckoutAction('SET_ACTIVE_ITEM_IDX');
    const title = CheckoutItemV2Manager.getFormattedTitle(item);
    const subtitle = getSummaryItemSubtitle(item);

    return (
        <CheckoutSummaryItemContent
            title={title}
            buttonText={'Edit'}
            buttonTooltipText={'Return to item and edit'}
            subtitle={subtitle}
            onClick={() => goToItem(item.item_index)}
        />
    );
};

interface CheckoutSummaryItemsColumnProps {
    onItemHoverStart: (itemIndex: number) => void;
    onItemHoverEnd: (itemIndex: number) => void;
}

export const CheckoutSummaryItemsColumn: React.FC<CheckoutSummaryItemsColumnProps> = props => {
    const classes = useStyles();
    const { items, waxupState } = useCheckoutPropSelector(['items', 'waxupState']);
    const waxupSelected = waxupState.selected;
    const setWaxupSelected = useCheckoutAction('SET_WAXUP_SELECTED');

    return (
        <Grid container item xs={12} md={6} direction={'column'} className={classes.itemRowsColumn}>
            {waxupSelected && (
                <Grid container className={classes.itemRow} direction={'column'}>
                    <CheckoutSummaryItemContent
                        title={'Design Preview'}
                        buttonText={'Remove'}
                        buttonTooltipText={'Remove the waxup from this order'}
                        onClick={() => setWaxupSelected(false)}
                    />
                </Grid>
            )}
            {items.map(item => (
                <Grid
                    container
                    key={item.item_index}
                    className={classes.itemRow}
                    direction={'column'}
                    onMouseEnter={() => props.onItemHoverStart(item.item_index)}
                    onMouseLeave={() => props.onItemHoverEnd(item.item_index)}
                >
                    <CheckoutSummaryItem item={item} />
                </Grid>
            ))}
            <CheckoutOrderNotesV2 />
        </Grid>
    );
};
