import type { CheckoutItemV2WithImplant } from '../../state/reducers/checkout.state';
import { isCheckoutItemV2WithImplantExclBridge } from '../../state/reducers/checkout.state';
import _ from 'lodash';

// Helpers for getting selected props for an implant or implant-bridge item:

export const getSelectedRetention = (item: CheckoutItemV2WithImplant) =>
    isCheckoutItemV2WithImplantExclBridge(item)
        ? item.unit.implant_metadata.relationship ?? undefined
        : item.implants[0]?.implant_metadata.relationship ?? undefined;

export const getSelectedCrownMaterialValue = (item: CheckoutItemV2WithImplant) =>
    isCheckoutItemV2WithImplantExclBridge(item)
        ? item.unit.crown.material ?? undefined
        : item.implants[0]?.crown.material ?? undefined;

export const getSelectedAbutmentMaterialValue = (item: CheckoutItemV2WithImplant) =>
    isCheckoutItemV2WithImplantExclBridge(item)
        ? item.unit.abutment.material ?? undefined
        : item.implants[0]?.abutment.material ?? undefined;

export const getUnnFromItemWithImplant = (item: CheckoutItemV2WithImplant) =>
    isCheckoutItemV2WithImplantExclBridge(item)
        ? item.unit.unn
        : Math.round(_.sumBy(item.implants, implant => implant.unn) / item.implants.length);

export const getSelectedManufacturer = (item: CheckoutItemV2WithImplant) =>
    isCheckoutItemV2WithImplantExclBridge(item)
        ? item.unit.implant_metadata.manufacturer ?? undefined
        : item.implants[0]?.implant_metadata.manufacturer ?? undefined;

export const getSelectedSystem = (item: CheckoutItemV2WithImplant) =>
    isCheckoutItemV2WithImplantExclBridge(item)
        ? item.unit.implant_metadata.system ?? undefined
        : item.implants[0]?.implant_metadata.system ?? undefined;

export const getSelectedConnection = (item: CheckoutItemV2WithImplant) =>
    isCheckoutItemV2WithImplantExclBridge(item)
        ? item.unit.implant_metadata.connection_size ?? undefined
        : item.implants[0]?.implant_metadata.connection_size ?? undefined;
