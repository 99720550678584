import { useCheckoutSelector } from '../../../redux';
import { useCheckoutAction } from '../state/checkout.actions';
import { smileStyleScreenIsComplete } from '../state/checkout.selectors';
import { SmileStyleImage, CheckoutBodyWrapper } from '@orthly/dentin';
import { LabsGqlSmileStyleChoice } from '@orthly/graphql-schema';
import { FlossPalette, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const Smile: React.VFC<{
    style: LabsGqlSmileStyleChoice;
    active: boolean;
    setActive: (active: boolean) => unknown;
}> = ({ style, active, setActive }) => (
    <Grid
        component={`label`}
        item
        container
        style={{
            flexBasis: 0,
            flexGrow: 1,
            padding: 8,
            flexDirection: `column`,
        }}
    >
        <Button
            variant={`ghost`}
            style={{
                backgroundColor: FlossPalette.TAN,
                borderRadius: 16,
                position: `relative`,
                padding: 16,
                flexGrow: 1,
                border: `4px solid ${active ? FlossPalette.STAR_GRASS : `transparent`}`,
            }}
            onClick={() => setActive(!active)}
        >
            <SmileStyleImage
                smile={style}
                style={{ position: `absolute`, top: 0, bottom: 0, left: 0, right: 0, margin: `auto`, height: 90 }}
            />
        </Button>
        <Text variant={`body2`} style={{ fontWeight: `bold`, padding: `16px 0 8px` }}>
            {style === LabsGqlSmileStyleChoice.ReplicatePreOpAnatomy ? 'Replicate pre-op anatomy' : style}
        </Text>
    </Grid>
);

const Column: React.FC = ({ children }) => (
    <Grid item container style={{ flexBasis: 0, flexGrow: 1, flexDirection: `column` }}>
        {children}
    </Grid>
);

const { Aggressive, Youthful, Hollywood, Mature, ReplicatePreOpAnatomy } = LabsGqlSmileStyleChoice;

export const CheckoutSmileStyleScreen: React.VFC = () => {
    const visible = useCheckoutSelector(s => s.step === 2 && s.stepTwoScreen === 'smileStyle');
    const isComplete = useCheckoutSelector(smileStyleScreenIsComplete);
    const style = useCheckoutSelector(s => s.smileStyle);
    const setStyle = useCheckoutAction(`SET_SMILE_STYLE`);
    return (
        <CheckoutBodyWrapper
            visible={visible}
            isComplete={isComplete}
            style={{ height: `75%`, margin: `8px -8px`, width: `auto` }}
        >
            <Column>
                <Smile
                    style={ReplicatePreOpAnatomy}
                    active={style === ReplicatePreOpAnatomy}
                    setActive={active => setStyle(active ? ReplicatePreOpAnatomy : undefined)}
                />
                <Smile
                    style={Mature}
                    active={style === Mature}
                    setActive={active => setStyle(active ? Mature : undefined)}
                />
            </Column>
            <Column>
                <Smile
                    style={Aggressive}
                    active={style === Aggressive}
                    setActive={active => setStyle(active ? Aggressive : undefined)}
                />
                <Smile
                    style={Youthful}
                    active={style === Youthful}
                    setActive={active => setStyle(active ? Youthful : undefined)}
                />
            </Column>
            <Column>
                <Smile
                    style={Hollywood}
                    active={style === Hollywood}
                    setActive={active => setStyle(active ? Hollywood : undefined)}
                />
                {/* This empty Grid component is a placeholder to preserve page layout. */}
                <Grid
                    style={{
                        flexGrow: 1,
                        padding: 8,
                    }}
                />
            </Column>
        </CheckoutBodyWrapper>
    );
};
