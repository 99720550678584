import { getPartnerId } from '../../../../../utils/authorization';
import { SettingsBaseModal } from '../../../components/SettingsBaseModal';
import { useSetDoctorPreferences } from '../../../state/usePracticeStaffControls';
import { NotificationNames, NotificationPrefsGroupSenders } from '../Notifications.types';
import { EmployeeCheckList } from './EmployeeCheckList';
import type { ForwardingSettingsBaseModalProps, ForwardingSettingsBaseModalFormProps } from './ForwardingSettingsBase';
import { FormatNames } from './ForwardingSettingsBase';
import { useGetNotificationGroup } from './hooks';
import { useForwardingModalStyles } from './styles';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { useGetPreferencesByIdsQuery } from '@orthly/graphql-react';
import type { LabsGqlSetReceiversInput } from '@orthly/graphql-schema';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn, SimpleSelect } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import { PersonCardOccupationOptionsDisplay } from '@orthly/veneer';
import React from 'react';

const SetReceiversOnPref_Mutation = graphql(`
    mutation SetReceiversOnPref($data: SetReceiversInput!) {
        setReceiversOnPref(data: $data)
    }
`);

interface EditForwardingSettingsModalProps extends ForwardingSettingsBaseModalProps {
    prefsToShareWith: LabsGqlDoctorPreferencesFragment[];
}

interface EditForwardingSettingsModalFormProps extends ForwardingSettingsBaseModalFormProps {
    prefsToShareWith: LabsGqlDoctorPreferencesFragment[];
}

function useGetSenderField(notificationName: NotificationNames) {
    switch (notificationName) {
        case NotificationNames.status_change:
            return NotificationPrefsGroupSenders.status_change_senders;
        case NotificationNames.review_required:
            return NotificationPrefsGroupSenders.review_required_senders;
        case NotificationNames.order_tracking:
            return NotificationPrefsGroupSenders.order_tracking_senders;
        case NotificationNames.communication:
            return NotificationPrefsGroupSenders.communication_senders;
    }
}

const EditForwardingSettingsForm: React.VFC<EditForwardingSettingsModalFormProps> = props => {
    const {
        setOpen,
        employeePrefs,
        sendersOrReceivers: receivers,
        notificationName,
        prefsToShareWith,
        setShowSuccess,
        refetch,
    } = props;
    const [receiverIds, setReceiverIds] = React.useState<string[]>(receivers.map(receiver => receiver.id));
    const [role, setRole] = React.useState<LabsGqlStaffRolePractice | undefined>();
    const { refetch: currentProfileRefetch } = useSetDoctorPreferences();
    const [submitMtn] = useMutation(SetReceiversOnPref_Mutation);
    const mtnSubmitter = (data: LabsGqlSetReceiversInput) => submitMtn({ variables: { data } });
    const { submit } = useChangeSubmissionFn<any, [LabsGqlSetReceiversInput]>(mtnSubmitter, {
        closeOnComplete: true,
        onSuccess: async () => {
            setShowSuccess(true);
            refetch ? await refetch() : await currentProfileRefetch();
        },
    });
    const notification_group = useGetNotificationGroup(notificationName);
    const senderField = useGetSenderField(notificationName);
    const existingForwardIds = employeePrefs.grouped_notification_forwarding_preferences[senderField] ?? [];
    const { data: forwardingPrefs } = useGetPreferencesByIdsQuery({
        variables: {
            pref_ids: existingForwardIds,
        },
        skip: existingForwardIds.length === 0,
    });
    const existingForwardingPrefs = forwardingPrefs?.preferences ?? [];
    const filteredPrefsToShareWith = role
        ? prefsToShareWith.filter(prefToShareWith => prefToShareWith.roles?.includes(role))
        : prefsToShareWith;

    return (
        <Grid container>
            <Grid container>
                <Text variant={'body2'} style={{ paddingBottom: 40 }}>
                    Select which staff members should receive your{' '}
                    <span style={{ fontWeight: 600 }}>{notificationName}</span> notifications
                    {existingForwardingPrefs.length > 0 && (
                        <>
                            . You will not be sharing <FormatNames sendersOrReceivers={existingForwardingPrefs} />{' '}
                            notifications.
                        </>
                    )}
                </Text>
                <Grid container>
                    <Text variant={'body1'} medium style={{ paddingBottom: 8 }}>
                        Roles
                    </Text>
                    <SimpleSelect
                        label={'Roles'}
                        value={role}
                        options={[
                            { value: '', label: 'All roles' },
                            ...Object.values(LabsGqlStaffRolePractice).map(occupation => ({
                                value: occupation,
                                label: PersonCardOccupationOptionsDisplay[occupation],
                            })),
                        ]}
                        onChange={value => setRole(value as LabsGqlStaffRolePractice)}
                        style={{ width: '50%', marginBottom: 16 }}
                    />
                </Grid>
                <EmployeeCheckList
                    sendersOrReceivers={filteredPrefsToShareWith}
                    senderOrReceiverIds={receiverIds}
                    setSenderOrReceiverIds={setReceiverIds}
                />
            </Grid>
            <Grid container justifyContent={'flex-end'} style={{ paddingTop: 40 }}>
                <Button variant={'secondary'} style={{ marginRight: '8px' }} onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    disabled={receiverIds.length === 0}
                    variant={'primary'}
                    onClick={async () => {
                        await submit({
                            notification_group,
                            preference_id: employeePrefs.id,
                            receiver_ids: receiverIds,
                        });
                        setOpen(false);
                    }}
                >
                    Assign
                </Button>
            </Grid>
        </Grid>
    );
};

export const EditForwardingSettingsModal: React.VFC<EditForwardingSettingsModalProps> = props => {
    const {
        open,
        setOpen,
        employeePrefs,
        sendersOrReceivers: receivers,
        notificationName,
        prefsToShareWith,
        setShowSuccess,
        refetch,
    } = props;
    const classes = useForwardingModalStyles();
    const session = useSession();
    const userId = session?.user_id;
    const partnerId = getPartnerId(session);
    const editingRecipients = receivers.length > 0;

    if (!userId || !partnerId) {
        return null;
    }

    return (
        <SettingsBaseModal
            content={
                <EditForwardingSettingsForm
                    setOpen={setOpen}
                    employeePrefs={employeePrefs}
                    sendersOrReceivers={receivers}
                    notificationName={notificationName}
                    prefsToShareWith={prefsToShareWith}
                    setShowSuccess={setShowSuccess}
                    refetch={refetch}
                />
            }
            loading={false}
            title={editingRecipients ? 'Edit recipients' : 'Forward notifications to staff'}
            open={open}
            setOpen={setOpen}
            buttonText={editingRecipients ? 'Edit recipients' : 'Share your notifications'}
            buttonProps={{
                style: { height: 'fit-content', padding: 0 },
                classes: { root: classes.buttonLabel },
            }}
            decreaseTitlePadding
        />
    );
};
