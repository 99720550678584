import type { PartnerAppState } from '../../../redux/types';
import type { ScanbodyRequestState } from './ScanbodyRequest.reducer';
import { ScanbodyStage } from './ScanbodyRequest.types';
import { generateUseStatePropSelector, generateUseSubstateSelector } from '@orthly/redux-async-actions';

export enum ScanbodyRequestStage {
    // Needs to be an ordered enum. A later stage includes former ones.
    // e.g. enabling APPOINTMENT_DATE field enables CONNECTION
    MANUFACTURER,
    SYSTEM,
    CONNECTION,
    APPOINTMENT_DATE,
    ADDRESS,
    REQUESTING_DOCTOR,
    SURGICAL_REPORT,
    COMPLETE,
    SUBMITTED,
}

export const useScanbodyRequestSelector = generateUseSubstateSelector<PartnerAppState, 'scanbodyRequest'>(
    'scanbodyRequest',
);

export const useScanbodyRequestPropSelector = generateUseStatePropSelector<ScanbodyRequestState>(
    useScanbodyRequestSelector,
    'scanbodyRequest',
);

export const useScanbodyRequestStageSelector = (): ScanbodyRequestStage => {
    const {
        stage,
        request: {
            manufacturer,
            system,
            connection,
            appointment_date,
            address,
            requesting_doctor_id,
            surgical_report_file,
        },
        declinedSurgicalReport,
    } = useScanbodyRequestSelector(s => s);

    if (!manufacturer) {
        return ScanbodyRequestStage.MANUFACTURER;
    }
    if (!system) {
        return ScanbodyRequestStage.SYSTEM;
    }
    if (!connection) {
        return ScanbodyRequestStage.CONNECTION;
    }
    if (!appointment_date) {
        return ScanbodyRequestStage.APPOINTMENT_DATE;
    }
    if (!address) {
        return ScanbodyRequestStage.ADDRESS;
    }
    if (!requesting_doctor_id) {
        return ScanbodyRequestStage.REQUESTING_DOCTOR;
    }
    if (!surgical_report_file && !declinedSurgicalReport) {
        return ScanbodyRequestStage.SURGICAL_REPORT;
    }
    if (stage !== ScanbodyStage.SUBMITTED) {
        return ScanbodyRequestStage.COMPLETE;
    }
    return ScanbodyRequestStage.SUBMITTED;
};

export enum ScanbodyRequestLinkLookupStage {
    EMPTY = 'EMPTY',
    PARTIAL = 'PARTIAL',
    COMPLETE = 'COMPLETE',
}
export const useScanbodyRequestLinkLookupStageSelector = (): ScanbodyRequestLinkLookupStage => {
    const { manufacturer, system, connection } = useScanbodyRequestSelector(s => s.request);
    if (manufacturer && system && connection) {
        return ScanbodyRequestLinkLookupStage.COMPLETE;
    }
    if (!manufacturer && !system && !connection) {
        return ScanbodyRequestLinkLookupStage.EMPTY;
    }
    return ScanbodyRequestLinkLookupStage.PARTIAL;
};
