import type { StarRatingType } from '../inbox/components/tracker/OrderSummaryStepper';
import { useFeedbackSelector } from './state/feedback.selectors';
import type { StarRating } from './state/useSubmitFeedback';
import type { FeedbackAndRefabItem } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useReasonCodesForOrderItemQuery } from '@orthly/graphql-react';
import { LabsGqlFormDisplay } from '@orthly/graphql-schema';
import constate from 'constate';
import { useLocation } from 'react-router-dom';

export const cleanStarRating = (raw_rating: number) => Math.min(5, Math.max(1, Math.round(raw_rating))) as StarRating;

// The rule does not understand this format
// eslint-disable-next-line import/no-unused-modules
export const [FeedbackCurrentLabOrderProvider, useFeedbackCurrentLabOrder] = constate(
    (context: { lab_order?: LabsGqlLabOrderFragment; loading: boolean }) => context,
);

export const useGetStarRating = (item: FeedbackAndRefabItem): StarRatingType => {
    const { search } = useLocation();
    const rawStarRatingFromStore = useFeedbackSelector(state => state.items[item.id]?.star_rating);
    const rawStarRatingFromUrl = Number(new URLSearchParams(search).get('shortcutStarRating'));
    const rawStarRating = rawStarRatingFromStore || rawStarRatingFromUrl || 0;
    const starRating = rawStarRating ? cleanStarRating(rawStarRating) : 0;
    return starRating;
};

/**
 * Hook used to fetch the list of reasons to display in the feedback form.
 *
 * If the feature flag is on, we'll pull the reasons from the new refab & reasons table,
 * if it's off we'll pull them from the hardcoded rubrics we have in the code.
 *
 * Using this hook allows us to hide the changes away from the component and will make it easy to clean up
 */
export const useFeedbackReasons = (item: FeedbackAndRefabItem) => {
    const { lab_order } = useFeedbackCurrentLabOrder();
    const {
        data: dynamicReasonCodesFromQuery,
        loading,
        called,
    } = useReasonCodesForOrderItemQuery({
        variables: { order_id: lab_order?.id ?? '', item_id: item.id, form_display: LabsGqlFormDisplay.Feedback },
        skip: !lab_order?.id || !item.id,
    });

    return {
        isLoading: loading,
        isCompleted: called,
        feedbackReasons: dynamicReasonCodesFromQuery?.reasonCodesForOrderItem ?? [],
    };
};
