import { SimplePhoneInput } from '../../SimpleForm';
import type { FieldLayout, FieldDefPhone } from '../QuickForm.types';
import { FormikRootField } from '../QuickForm.types';
import { Grid } from '@orthly/ui-primitives';
import { getIn } from 'formik';
import _ from 'lodash';
import React from 'react';

interface QFPhoneFieldProps {
    field: FieldDefPhone & { name: string };
}

export const QFPhoneField: React.FC<QFPhoneFieldProps> = props => {
    const { field } = props;
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    return (
        <Grid item {...layout}>
            <FormikRootField
                name={field.name}
                render={formikProps => {
                    const error = getIn(formikProps.form.errors, field.name);
                    const touched = getIn(formikProps.form.touched, field.name);
                    return (
                        <SimplePhoneInput
                            value={formikProps.field.value}
                            onChange={value => formikProps.form.setFieldValue(formikProps.field.name, value)}
                            onBlur={formikProps.field.onBlur}
                            variant={props.field.fieldProps?.variant || ('standard' as const)}
                            fullWidth
                            label={field.label ? field.label : _.startCase(field.name)}
                            TextFieldProps={{
                                'data-test': `quick-form-field-${field.name}`,
                                ...field.fieldProps,
                                helperText: error && touched ? error : field.helperText,
                                error: !!error && !!touched,
                                name: field.name,
                                InputLabelProps: {
                                    required: !field.optional,
                                    ...field.fieldProps?.InputLabelProps,
                                },
                            }}
                        />
                    );
                }}
            />
        </Grid>
    );
};
