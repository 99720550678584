import { CreateTrainingIntakeSurveyProvider } from '../getting_started/components/TrainingDataIntake/hooks/useCreateTrainingIntakeSurveyResponse.graphql';
import { OnboardingNavigationLayout } from './OnboardingNavigationLayout.graphql';
import React from 'react';

export const Onboarding: React.VFC = () => {
    return (
        <CreateTrainingIntakeSurveyProvider>
            <OnboardingNavigationLayout />
        </CreateTrainingIntakeSurveyProvider>
    );
};
