import React from 'react';

export enum CarestreamPostAction {
    OrderOk = 100,
    OrderError = 101,
    OrderClose = 102,
    ReturnToHome = 103,
}

export type CarestreamPostMessage<T> = {
    action: CarestreamPostAction;
    payload?: T;
};

export function useCarestreamPostback<T>(message: CarestreamPostMessage<T>) {
    return React.useCallback(() => {
        console.log({
            webview: window.chrome?.webview,
            postMessage: window.chrome?.webview?.postMessage,
            postbackPayload: message,
        });
        window.chrome?.webview?.postMessage(message);
    }, [message]);
}
