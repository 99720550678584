import { CheckoutItemMetafieldsSection } from '../../screens/CheckoutItemsStep/fields/CheckoutItemMetafields';
import { CheckoutShadePicker } from '../../screens/CheckoutItemsStep/fields/CheckoutShadePicker';
import type { CheckoutScreen } from '../../screens/CheckoutScreen';
import { AbutmentMaterialScreen } from '../../screens/ImplantCheckout/AbutmentMaterialScreen';
import { CrownMaterialScreen } from '../../screens/ImplantCheckout/CrownMaterialScreen';
import { ImplantSystemScreen } from '../../screens/ImplantCheckout/ImplantSystemScreen';
import { RetentionTypeScreen } from '../../screens/ImplantCheckout/RetentionTypeScreen';
import { CheckoutItemV2Manager } from '../CheckoutItemV2Manager';
import type { CheckoutFeatureFlags } from '../checkout.state';
import type { CheckoutItemV2WithImplant } from './checkout.state';
import React from 'react';

export type ImplantScreenInterface = {
    title: string;
    Component: React.VFC<{ item: CheckoutItemV2WithImplant }>;
    enableNext: (props: { item: CheckoutItemV2WithImplant; checkoutFeatureFlags: CheckoutFeatureFlags }) => boolean;
};

const CheckoutShadePickerScreen: ImplantScreenInterface = {
    title: 'Select the crown shade',
    Component: ({ item }) => <CheckoutShadePicker item={item} />,
    enableNext: ({ item }) => !!item.shades.find(shade => shade.name === 'base'),
};

const CheckoutItemMetafieldsSectionScreen: ImplantScreenInterface = {
    title: 'Select a few more implant options',
    Component: ({ item }) => <CheckoutItemMetafieldsSection item={item} />,
    enableNext: ({ item }) => CheckoutItemV2Manager.fieldsRemaining(item) === 0,
};

const implantScreens: ImplantScreenInterface[] = [
    RetentionTypeScreen,
    ImplantSystemScreen,
    CrownMaterialScreen,
    AbutmentMaterialScreen,
    CheckoutShadePickerScreen,
    CheckoutItemMetafieldsSectionScreen,
];

export function screensForImplantItem(
    item: CheckoutItemV2WithImplant,
    checkoutFeatureFlags: CheckoutFeatureFlags,
): CheckoutScreen[] {
    // Map from ImplantScreenInterface to CheckoutScreen. We could convert implant screens
    // to be CheckoutScreen 'natively' but for now there's little gain from doing so.
    return implantScreens.map((screen, i) => ({
        title: screen.title,
        contents: (
            <>
                <screen.Component item={item} />
            </>
        ),
        completionState: () => {
            if (screen.enableNext({ item, checkoutFeatureFlags })) {
                return { complete: true };
            }
            return { complete: false, reason: '' };
        },
        itemIndex: item.item_index,
        identifier: `implantItem:${item.item_index}:${i}`,
    }));
}
