import { AnnotationListModal } from '../Annotations';
import { getAndFormatGuidedPresetAnnotations } from './GuidedWaxups';
import { GuidedWaxupSubmittedDetailDesktop } from './GuidedWaxups/GuidedWaxupBlock.graphql';
import { WaxupSidebarControls } from './OrderWaxupSidebarControls';
import { useShouldShowGuidedWaxupFlow } from './Waxup.util.graphql';
import type { WaxupNotesDisplay, WaxupSidebarProps } from './Waxups.types';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import { LabsGqlDesignOrderDoctorReviewStatus, LabsGqlLabOrderWaxupReviewStatus } from '@orthly/graphql-schema';
import {
    Button,
    FlossPalette,
    useScreenIsMobileOrVerticalTablet,
    Divider,
    Grid,
    Text,
    PhotoLibrary,
} from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
function useWaxupDetails(props: WaxupSidebarProps): WaxupNotesDisplay {
    const { currentWaxup, userRole, order } = props;

    /*
      02/12 - Right now all logic pertaining to what to show the manufacturer during the waxup process
      doesn't actually get shown (waxups are only enabled for internal design workflows and orders are hidden from the lab during this time)
    */
    const { currentDesignFragment, previousDesignFragment } = currentWaxup;

    const selectedDesign = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, currentDesignFragment);
    const previousRejection = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, previousDesignFragment);
    const isSelectedDesignRejected =
        selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected;

    const canUseActions = !(userRole === `manufacturer` || userRole === `external`);

    // The most recent rejection or, if none have recently been rejected, the current.
    // If the currently selected design was rejected, we display it.
    const previousOrSelected =
        selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected
            ? selectedDesign
            : previousRejection ?? selectedDesign;

    // If the selected design was a previous rejection, we handle it as a special case.
    if (isSelectedDesignRejected && selectedDesign.doctor_review && !selectedDesign.is_latest_design) {
        return {
            headline: `Viewing previously rejected waxup`,
            headlineColor: FlossPalette.ATTENTION,
            subTitle: `Viewing waxup submission from ${moment(selectedDesign.doctor_review.created_at).format(
                'MM/DD',
            )}`,
            bodyText: <GuidedWaxupSubmittedDetailDesktop lastRejection={selectedDesign.doctor_review} order={order} />,
            annotations: getAndFormatGuidedPresetAnnotations(selectedDesign.doctor_review),
        };
    }

    switch (order.waxup_status) {
        case LabsGqlLabOrderWaxupReviewStatus.Approved:
            return {
                headline: `You approved this design`,
                headlineColor: FlossPalette.PRIMARY_FOREGROUND,
                subTitle: `We'll get to work fabricating your order right away`,
            };
        case LabsGqlLabOrderWaxupReviewStatus.ReadyForReview:
            return {
                headline: canUseActions ? `Action Required` : `Awaiting Design Preview Approval`,
                headlineColor: canUseActions ? FlossPalette.BURGUNDY : FlossPalette.BLACK,
                subTitle:
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    userRole === `psr`
                        ? `Awaiting doctor submission for design preview`
                        : userRole === `practitioner`
                          ? `Review Design Preview`
                          : ``,
                bodyText: userRole === `psr` ? `Use the below buttons to submit on their behalf` : ``,
            };
        case LabsGqlLabOrderWaxupReviewStatus.AwaitingDesign:
        case null:
            return (!previousRejection &&
                selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected) ||
                previousRejection?.doctor_review
                ? {
                      headline: `Digital design rejected`,
                      headlineColor: FlossPalette.ATTENTION,
                      subTitle: `A new design is under way`,
                      annotations: getAndFormatGuidedPresetAnnotations(previousOrSelected?.doctor_review),
                      bodyText: previousOrSelected?.doctor_review ? (
                          <GuidedWaxupSubmittedDetailDesktop
                              lastRejection={previousOrSelected?.doctor_review}
                              order={order}
                          />
                      ) : undefined,
                  }
                : {
                      headline: `Digital design is under way`,
                      headlineColor: FlossPalette.BLACK,
                      bodyText: `We'll notify you when it's ready. Keep an eye on your inbox!`,
                  };
    }
}

export const WaxupSidebarHeader: React.VFC<WaxupSidebarProps> = props => {
    const { headline, headlineColor, subTitle, bodyText, annotations } = useWaxupDetails(props);
    const [isViewingAnnotations, setIsViewingAnnotations] = React.useState(false);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(props.order);
    const showDivider = enableNewGuidedWaxupFlow && isMobile;

    return (
        <Grid container direction={'column'}>
            <Text variant={'h6'} style={{ color: headlineColor }}>
                {headline}
            </Text>
            {subTitle && (
                <Text variant={'h6'} style={{ color: FlossPalette.BLACK }}>
                    {subTitle}
                </Text>
            )}
            {bodyText && <Text style={{ fontSize: 16, paddingTop: 16, color: FlossPalette.BLACK }}>{bodyText}</Text>}
            {annotations && annotations.length > 0 && (
                <>
                    <Button
                        variant={'ghost'}
                        onClick={() => setIsViewingAnnotations(true)}
                        style={{ justifyContent: 'flex-start', paddingLeft: 0, marginTop: 16 }}
                    >
                        <PhotoLibrary style={{ marginRight: 8 }} />
                        View all markups ({annotations.length})
                    </Button>
                    <AnnotationListModal
                        annotations={annotations.map(annotation => ({
                            comment: annotation.comment,
                            imageUrl: annotation.image_url,
                        }))}
                        open={isViewingAnnotations}
                        setOpen={setIsViewingAnnotations}
                    />
                </>
            )}
            {showDivider && <Divider style={{ margin: '8px 0px', color: FlossPalette.DARK_TAN }} />}
        </Grid>
    );
};

export const WaxupSidebar: React.FC<WaxupSidebarProps> = props => {
    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(props.order);
    return (
        <Grid container direction={'column'} justifyContent={'space-between'} style={{ height: '100%', gap: 16 }}>
            <WaxupSidebarHeader {...props} />
            {(!enableNewGuidedWaxupFlow || props.userRole === 'psr') && <WaxupSidebarControls {...props} />}
        </Grid>
    );
};
