import type { ScanbodyProps } from '@orthly/dentin';
import type { LabsGqlScanbodyInventoryItemFragment } from '@orthly/graphql-operations';
import { useGetPracticeScanbodyInventoryQuery } from '@orthly/graphql-react';
import type { LabsGqlIShipment } from '@orthly/graphql-schema';
import { LabsGqlShipmentStatus } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

type ShipmentStatus = 'delivered' | 'shipping' | 'preparing';

function getShipmentStatus(data: LabsGqlScanbodyInventoryItemFragment): ShipmentStatus {
    const { shipment, fulfillment_order_id } = data;
    if (shipment) {
        const { status } = shipment;
        if (status === LabsGqlShipmentStatus.PreTransit || status === LabsGqlShipmentStatus.Transit) {
            return 'shipping';
        }
        // for shipments with status other than pre-transit or transit we default to delivered
        // since it's either actually delivered, returned, or we can't get tracking info
        return 'delivered';
    }
    // for backwards compatibility from before shipment tracking was added
    // we consider requests with a fulfillment id and no shipment to be delivered
    if (fulfillment_order_id) {
        return 'delivered';
    }

    return 'preparing';
}

const PENDING_SCANBODY_PROP = { manufacturer: 'pending', name: 'pending' };
function resolveGQLFields(data: LabsGqlScanbodyInventoryItemFragment) {
    // 1. resolve and transform GQL fields
    const {
        manufacturer: implantManufacturer,
        system: implantSystem,
        connection: implantConnection,
        fulfillment_order_id: fulfillmentOrderId,
        created_at,
        scanbody,
        shipment,
    } = data;
    const requestedDate = new Date(created_at);

    // 2. get shipment status of request
    const shipmentStatus = getShipmentStatus(data);

    // 3. lookup assigned scanbody
    const { manufacturer: scanbodyManufacturer, name: scanbodyName } = scanbody ?? PENDING_SCANBODY_PROP;

    return {
        implantManufacturer,
        implantSystem,
        implantConnection,
        scanbodyManufacturer,
        scanbodyName,
        requestedDate,
        fulfillmentOrderId,
        shipment,
        shipmentStatus,
    };
}

export interface ScanbodyInventoryItem extends ScanbodyProps {
    fulfillmentOrderId: string | null;
    shipment: Pick<LabsGqlIShipment, 'id' | 'tracking_number' | 'carrier' | 'status'> | null;
    shipmentStatus: ShipmentStatus;
}

export function useScanbodyInventory() {
    const { data, loading } = useGetPracticeScanbodyInventoryQuery();
    const inventory = React.useMemo<ScanbodyInventoryItem[]>(
        () =>
            _.chain(data?.getScanbodyRequests ?? [])
                .map(resolveGQLFields)
                .orderBy(
                    [
                        ({ shipmentStatus }) =>
                            // eslint-disable-next-line no-nested-ternary
                            shipmentStatus === 'delivered' ? 2 : shipmentStatus === 'shipping' ? 1 : 0,
                        ({ requestedDate }) => requestedDate.getTime(),
                    ],
                    ['asc', 'desc'],
                )
                .value(),
        [data],
    );
    return { inventory, loading };
}
