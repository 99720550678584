import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useIsChairside } from '../../../utils/chairside.hooks';
import { Tips, restoreConfidentlyTip } from '../component/Tip';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { DandyMouthToothTypeSelector, PracticeScreen } from '@orthly/dentin';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { ICartImplantBridgeItem, ICartImplantToothGroup, ICartItemSingleToothUnit } from '@orthly/items';
import { Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

export const ImplantBridge: React.VFC = () => {
    const createItems = useImplantReadinessAction('CREATE_ITEMS');
    const { activeItemId, items, implantReadinessId } = useImplantReadinessPropSelector([
        'activeItemId',
        'items',
        'implantReadinessId',
    ]);
    const isChairside = useIsChairside();
    const bridgeItem = items.find(item => item.id === activeItemId);

    // Retrieve the ImplantBridge item & satisfy the prop types for the DandyMouthToothTypeSelector
    const selectedUnits: ICartItemSingleToothUnit<'Abutment' | 'CrownPontic' | 'Crown'>[] =
        bridgeItem && CartItemV2Utils.itemIsType(bridgeItem, LabOrderItemSKUType.ImplantBridge)
            ? [
                  ...bridgeItem.restoratives.filter((u): u is ICartItemSingleToothUnit<'CrownPontic' | 'Crown'> =>
                      ['CrownPontic', 'Crown'].includes(u.unit_type),
                  ),
                  ...bridgeItem.implants.map(i => ({ ...i.abutment, unn: i.unn })),
              ]
            : [];

    const [units, setUnits] =
        React.useState<ICartItemSingleToothUnit<'Abutment' | 'CrownPontic' | 'Crown'>[]>(selectedUnits);

    const nextStep = () => {
        const implant_metadata =
            bridgeItem && CartItemV2Utils.itemTypeHasImplant(bridgeItem)
                ? CartItemV2Utils.getImplantMetadata(bridgeItem)
                : undefined;

        const implants: ICartImplantToothGroup[] = units
            .filter(unit => unit.unit_type === 'Abutment')
            .map(unit => ({
                abutment: {
                    unit_type: 'Abutment',
                },
                crown: { unit_type: 'Crown' },
                implant_metadata: implant_metadata ?? {},
                unn: unit.unn,
            }));

        const restoratives: ICartItemSingleToothUnit<'CrownPontic' | 'Crown'>[] = units
            .filter((unit): unit is ICartItemSingleToothUnit<'CrownPontic' | 'Crown'> =>
                ['CrownPontic', 'Crown'].includes(unit.unit_type),
            )
            .map(unit => ({
                unit_type: unit.unit_type,
                unn: unit.unn,
            }));

        const implantBridgeItem: ICartImplantBridgeItem = {
            sku: LabOrderItemSKUType.ImplantBridge,
            attachments: [],
            preference_fields: [],
            shades: [],
            implants,
            restoratives,
        };
        createItems([implantBridgeItem]);
        AnalyticsClient.track('Practice - Implant Readiness - Implant Bridge Teeth Selected', {
            implantReadinessId,
            units: units,
        });
    };

    // Need at least 2 unns & at least 1 has to be an abutment
    const disabled = units.length < 2 || units.filter(u => u.unit_type === 'Abutment').length === 0;

    return (
        <Grid style={{ marginBottom: 36 }}>
            <Grid item style={{ width: 275, marginTop: isChairside ? 0 : 36 }}>
                <DandyMouthToothTypeSelector<'Abutment' | 'CrownPontic' | 'Crown'>
                    unit_types={['Abutment', 'CrownPontic', 'Crown']}
                    selected={units}
                    onChange={newUnits => setUnits(newUnits)}
                    verticalLayout={true}
                    animate={false}
                    archLabels={true}
                    itemSku={LabOrderItemSKUType.ImplantBridge}
                />
            </Grid>
            <Grid item container style={{ marginTop: isChairside ? 20 : 80 }}>
                <Button variant={'primary'} onClick={nextStep} style={{ width: 275 }} disabled={disabled}>
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export const ImplantBridgeStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/implant_bridge`,
    component: ImplantBridge,
    pageTitle: ({ name }) => ({
        title: `Preparing for ${name}'s implant case`,
        header: 'Select teeth on implant bridge',
    }),
    sidebar: () => <Tips tips={[restoreConfidentlyTip]} />,
};
