import { usePracticeAppSelector } from '../../redux';
import { useOrderAction } from '../../redux/orders/orders.actions';
import { ReviewThisOrderEntrypoint } from '../inbox/components/tracker/Reviews/ReviewThisOrder';
import { LabOrderCard } from './LabOrderCard';
import { SimpleCarousel } from './SimpleCarousel';
import { PracticeScreen } from '@orthly/dentin';
import { useOrdersByIds, usePracticeOrderIdsByStatusQuery } from '@orthly/graphql-react';
import { LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { Grid, ChevronLeft, ChevronRight, Button, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

const LabOrderCarousel: React.VFC = () => {
    const history = useHistory();
    const setStatus = useOrderAction('CHANGE_STATUS');
    const goToNeedsFeedback = React.useCallback(() => {
        history.push(`/${PracticeScreen.orders}`);
        setStatus(LabsGqlPracticeOrderStatus.NeedsOrderFeedback);
    }, [history, setStatus]);
    const [index, setIndex] = React.useState(0);

    const doctor_id_filter = usePracticeAppSelector(s => s.orders.doctor_id_filter);

    const { data: lab_order_ids_data } = usePracticeOrderIdsByStatusQuery({
        variables: {
            filter: {
                doctor_id_filter,
                status: LabsGqlPracticeOrderStatus.NeedsOrderFeedback,
                sort_asc: false,
            },
        },
    });

    const lab_order_ids = lab_order_ids_data?.practiceOrderIdsByStatus.map(entry => entry.id) ?? [];

    const { orders: loading_lab_orders } = useOrdersByIds(lab_order_ids, { skip: !lab_order_ids.length });

    const lab_orders = _.compact(loading_lab_orders);

    if (lab_orders.length === 0) {
        return null;
    }

    return (
        <>
            <Grid item style={{ height: 228, maxWidth: 456, width: `100%`, padding: 16, margin: `64px 0 0` }}>
                <SimpleCarousel index={index}>
                    {lab_orders.map((lab_order, i) => (
                        <LabOrderCard
                            key={i}
                            lab_order={lab_order}
                            active={i === index}
                            actions={
                                <ReviewThisOrderEntrypoint
                                    orderId={lab_order.id}
                                    canRefabricate={lab_order.can_refabricate}
                                    canSubmitFeedback={lab_order.can_submit_feedback}
                                    canSubmitTryInFeedback={lab_order.can_submit_tryin_feedback}
                                />
                            }
                        />
                    ))}
                </SimpleCarousel>
            </Grid>
            <Grid item container spacing={2} style={{ width: `auto` }}>
                <Grid item>
                    <Button
                        disabled={index <= 0}
                        style={{ padding: 12, minWidth: 0 }}
                        variant={`secondary`}
                        onClick={() => setIndex(index - 1)}
                    >
                        <ChevronLeft />
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        disabled={index >= lab_orders.length - 1}
                        style={{ padding: 12, minWidth: 0 }}
                        variant={`secondary`}
                        onClick={() => setIndex(index + 1)}
                    >
                        <ChevronRight />
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <Button
                    style={{ width: 264, fontFamily: 'Inter SemiBold' }}
                    variant={`ghost`}
                    onClick={goToNeedsFeedback}
                >
                    View all unreviewed orders ({lab_order_ids.length})
                </Button>
            </Grid>
        </>
    );
};

export const FeedbackPostSubmitScreenComponent: React.VFC = () => {
    const history = useHistory();
    const goToDashboard = React.useCallback(() => {
        history.push(`/${PracticeScreen.inbox}`);
    }, [history]);

    return (
        <Grid
            container
            alignContent={`center`}
            spacing={8}
            style={{ height: `100%` }}
            data-test={'feedback-thanks-for-feedback-container'}
        >
            <Grid item container xs={12} justifyContent={'center'} direction={`column`} alignItems={`center`}>
                <Grid item>
                    <Text variant={`h4`} style={{ fontWeight: 500 }} align={`center`}>
                        Thanks for your feedback!
                    </Text>
                </Grid>
                <Grid item>
                    <Text variant={`body1`} color={`DARK_GRAY`} style={{ margin: `32px 0` }} align={`center`}>
                        You can continue to review other orders below
                    </Text>
                </Grid>
                <Grid item>
                    <Button variant={`primary`} onClick={goToDashboard}>
                        Return to dashboard
                    </Button>
                </Grid>
                <LabOrderCarousel />
            </Grid>
        </Grid>
    );
};
