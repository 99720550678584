import { FieldsLayout, GridLayout } from '../TrainingDataIntake.styles';
import type { TraineeFormFieldProps } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { DentistMaterialPreferenceField } from './DentistMaterialPreferenceField';
import { DentistPrepStylePreferenceField } from './DentistPrepStylePreferenceField';
import { DentistRetractionPreferenceField } from './DentistRetractionPreferenceField';
import { DentistWillAdaptPreferenceField } from './DentistWillAdaptPreferenceField';
import { RequiredTraineeInputLabel } from './RequiredTraineeInputLabel';
import React from 'react';

export const DentistFormFields: React.VFC<TraineeFormFieldProps> = props => {
    const { trainee } = useTrainee(props.traineeAtom);

    if (trainee.role !== 'Dentist') {
        return null;
    }

    return (
        <FieldsLayout>
            <GridLayout>
                <RequiredTraineeInputLabel text={`Primary material preference for ${trainee.name}`} />
                <DentistMaterialPreferenceField traineeAtom={props.traineeAtom} />
            </GridLayout>

            <GridLayout>
                <RequiredTraineeInputLabel text={'Preferred retraction method'} />
                <DentistRetractionPreferenceField traineeAtom={props.traineeAtom} />
            </GridLayout>

            <GridLayout>
                <RequiredTraineeInputLabel text={'Preferred prep style'} />
                <DentistPrepStylePreferenceField traineeAtom={props.traineeAtom} />
            </GridLayout>

            {trainee.prepStyles?.includes('Knife edge') && (
                <GridLayout>
                    <RequiredTraineeInputLabel text={'Are you willing to adapt to a new prep style?'} />
                    <DentistWillAdaptPreferenceField traineeAtom={props.traineeAtom} />
                </GridLayout>
            )}
        </FieldsLayout>
    );
};
