import { LabsGqlPendingAlignerCaseRejectionReason } from '@orthly/graphql-schema';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const getRejectionHeadline = (reason: LabsGqlPendingAlignerCaseRejectionReason): string => {
    const base = `This case isn't a good fit because of`;

    switch (reason) {
        case LabsGqlPendingAlignerCaseRejectionReason.SkeletalImbalance:
            return `${base} a skeletal imbalance`;
        case LabsGqlPendingAlignerCaseRejectionReason.ExtremeRootExposureGumRecession:
            return `${base} extreme root exposure or gum recession`;
        case LabsGqlPendingAlignerCaseRejectionReason.SeverePeriodontalDisease:
            return `${base} severe periodontal disease`;
        case LabsGqlPendingAlignerCaseRejectionReason.SignificantClassIiiMalocclusion:
            return `${base} significant class III malocclusion`;
    }

    return `This case isn't a good fit with our current treatment protocols`;
};

interface AlignerRejectionProps {
    reason: LabsGqlPendingAlignerCaseRejectionReason;
    notes: string | null;
}

export const AlignerRejection: React.VFC<AlignerRejectionProps> = ({ reason, notes }) => (
    <Grid container direction={'column'} spacing={1} style={{ width: '45%' }}>
        <Grid item>
            <Text variant={'h6'}>{getRejectionHeadline(reason)}</Text>
        </Grid>
        {notes?.length && (
            <Grid item>
                <Text variant={'body2'}>{notes}.</Text>
            </Grid>
        )}
        <Grid item>
            <Text variant={'body2'}>
                We are improving our aligners offering to support more and more cases everyday. We've noted down this
                case and hopefully will be able to support it in the future.
            </Text>
        </Grid>
    </Grid>
);
