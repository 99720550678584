import { AnalyticsClient } from '../analytics/analyticsClient';
import { usePracticeAppSelector } from '../redux';
import { PartnerUiActions } from '../redux/ui.actions';
import { PRACTICE_CHECKOUT_PATHNAME } from '../screens/checkout-v2/state/CheckoutPaths';
import { PracticeScreen } from '@orthly/dentin';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

export function useSetScreen() {
    const { sidebarOpen, hideTopBar, screen: activeScreen } = usePracticeAppSelector(s => s.ui);
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const isMobile = useScreenIsMobile();

    return React.useCallback(
        (newScreen: PracticeScreen, suffix?: string) => () => {
            // need to account for the button going back to root screen (aka labs/submit to just /labs)
            if (newScreen !== activeScreen || location.pathname !== `/${newScreen}`) {
                // we also want to close the sidebar if we're on mobile
                if (isMobile && sidebarOpen) {
                    dispatch(PartnerUiActions.UPDATE_UI({ sidebarOpen: false }));
                }
                if (hideTopBar) {
                    dispatch(PartnerUiActions.UPDATE_UI({ hideTopBar: false }));
                }
                history.push(`/${newScreen}${suffix ?? ''}`);
            }
        },
        [activeScreen, dispatch, hideTopBar, history, isMobile, location.pathname, sidebarOpen],
    );
}

type OpenCheckoutParams = {
    scanId?: string;
    trackingData: {
        AssetType?: 'button' | 'banner' | 'pop-up';
        AssetName: string;
        AssetCTAText: string;
        AssetLocation: string;
        AssetVersion?: string;
    };
};

export function useOpenCheckout() {
    const history = useHistory();
    return React.useCallback(
        ({ scanId, trackingData }: OpenCheckoutParams) => {
            AnalyticsClient.track(`Button Clicked`, {
                AssetType: trackingData.AssetType ?? 'button',
                AssetName: trackingData.AssetName,
                AssetCTAText: trackingData.AssetCTAText,
                AssetLocation: trackingData.AssetLocation,
                AssetVersion: trackingData.AssetVersion ?? 'v1.0',
            });

            const queryString = scanId ? `?scanId=${scanId}` : '';
            const baseUrl = `/${PracticeScreen.orders}/${PRACTICE_CHECKOUT_PATHNAME}${queryString}`;
            history.push(baseUrl);
        },
        [history],
    );
}
