import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import { CheckoutItemDataField } from './data-field/CheckoutItemDataField';
import { ItemDataFieldUtils } from '@orthly/items';
import type { ICartItemV2Update } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface CheckoutItemDataFieldsSectionProps {
    item: CheckoutItemV2;
}

export const CheckoutItemDataFieldsSection: React.VFC<CheckoutItemDataFieldsSectionProps> = ({ item }) => {
    const updateItemAction = useCheckoutAction('UPDATE_LINE_ITEM');

    const updateItem = React.useCallback(
        (update: ICartItemV2Update) => updateItemAction({ item_index: item.item_index, change: update }),
        [item.item_index, updateItemAction],
    );

    const dataFields = ItemDataFieldUtils.getItemDataFields(item);

    return (
        <Grid container style={{ padding: '10px 0', background: '#fff' }} component={'form'}>
            {dataFields.map(field => (
                <CheckoutItemDataField key={field.id} field={field} item={item} updateItem={updateItem} />
            ))}
        </Grid>
    );
};
