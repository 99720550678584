import { checkoutRefabOrderSummaryText } from '../../../state/CheckoutDisplayUtils';
import type { CheckoutState } from '../../../state/checkout.state';
import { CheckoutMouthContainer } from '../../CheckoutSidebar/CheckoutMouth.container';
import { CheckoutSidebarItems } from '../../CheckoutSidebar/CheckoutSidebarRoot';
import type { CheckoutSidebarStepContainerProps, CheckoutSidebarStepProps } from '@orthly/dentin';
import { CheckoutSidebarImageStep, CheckoutSidebarStepVariant } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { Assert } from '@orthly/runtime-utils';
import type { Falsy } from '@orthly/runtime-utils';
import { Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export enum RegularCheckoutStep {
    PatientInfo = 0,
    ReviewItem = 1,
    ReviewOrder = 2,
    Summary = 3,
}

export const patientInfoTexts = ({ doctor, address, patient_first_name, patient_last_name }: CheckoutState) => [
    { caption: 'Doctor', content: doctor?.name },
    { caption: 'Delivery address', content: address?.street_one },
    {
        caption: 'Patient',
        content: patient_first_name && patient_last_name ? `${patient_first_name} ${patient_last_name}` : undefined,
    },
];

export const getRefabricationAlertChildren = (original_order: LabsGqlLabOrderFragment) => (
    // RefabricationAlert
    <Text variant={'body2'} color={'WHITE'}>
        {checkoutRefabOrderSummaryText(original_order)}
    </Text>
);

export const RegularCheckoutReviewItemComponent: React.FC<CheckoutSidebarStepContainerProps> = props => {
    // minimal adaptor from old checkout sidebar CheckoutSidebarItems
    return (
        <CheckoutSidebarImageStep
            image={<CheckoutMouthContainer layout={'horizontal'} />}
            // this component looks really hard to understand!
            imageCaption={<CheckoutSidebarItems />}
            {...props}
        />
    );
};

const toOrderedRegularCheckoutStep = ({ step, stepTwoScreen }: CheckoutState): RegularCheckoutStep => {
    if (step === 0) {
        return RegularCheckoutStep.PatientInfo;
    }
    if (step === 1) {
        return RegularCheckoutStep.ReviewItem;
    }
    if (step === 2) {
        if (stepTwoScreen !== 'summary') {
            return RegularCheckoutStep.ReviewOrder;
        }
        return RegularCheckoutStep.Summary;
    }
    Assert.unreachable(step);
};

export function getRegularCheckoutSidebarData(checkout: CheckoutState): CheckoutSidebarStepProps[] {
    const step = toOrderedRegularCheckoutStep(checkout);

    const variantOfStep = (...currentSteps: RegularCheckoutStep[]) =>
        currentSteps.includes(step) ? CheckoutSidebarStepVariant.Current : undefined;

    const stepsRaw: (Falsy | CheckoutSidebarStepProps)[] = [
        {
            variant: variantOfStep(RegularCheckoutStep.PatientInfo),
            kind: 'text',
            texts: patientInfoTexts(checkout),
            contentPlaceholder: '-',
        },
        checkout.refab?.original_order && {
            variant: CheckoutSidebarStepVariant.Attention,
            kind: 'custom',
            children: getRefabricationAlertChildren(checkout.refab.original_order),
        },
        {
            variant: variantOfStep(RegularCheckoutStep.ReviewItem, RegularCheckoutStep.ReviewOrder),
            kind: 'custom',
            Component: RegularCheckoutReviewItemComponent,
        },
        {
            variant: CheckoutSidebarStepVariant.Checkout,
            kind: 'text',
            texts: [{ caption: 'Checkout' }],
        },
    ];

    return _.compact(stepsRaw);
}
