import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { useCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { useCheckoutAction } from '../../state/checkout.actions';
import { EXTRAS_SCREEN_IDENTIFIER, isActiveScreen } from '../../state/checkout.screens';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { useExperiment } from '@orthly/veneer';
import React from 'react';

export const useIsBundleCrownNgExperimentEnabled = (): boolean => {
    return useExperiment('crown_and_ng_price_bundling_2024').get('show_bundled_ng', false);
};

export const useCanSeeCrownNgBundleOffer = (): boolean => {
    const isExperimentEnabled = useIsBundleCrownNgExperimentEnabled();
    const { items } = useCheckoutPropSelector(['items']);

    const areScanItemsAllCrowns = items
        // Only look at items on the scan, we allow adding discounted night guards if they add other extras
        .filter(item => item.from_scan_export)
        .every(item => CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Crown));

    return isExperimentEnabled && areScanItemsAllCrowns;
};

export const useTrackOnNextExtraScreen = () => {
    const canSeeCrownNgBundleOffer = useCanSeeCrownNgBundleOffer();
    const { items } = useCheckoutPropSelector(['items']);

    return React.useCallback(() => {
        const hasNightGuardItem = items.some(
            item =>
                CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Removeable) &&
                item.unit.unit_type === 'Night Guard',
        );

        const eventName = hasNightGuardItem
            ? 'Practice - Portal - Bundle Discount Accepted'
            : 'Practice - Portal - Bundle Discount Declined';

        if (canSeeCrownNgBundleOffer) {
            AnalyticsClient.track(eventName, {
                bundleType: 'crown_ng',
            });
        }
    }, [items, canSeeCrownNgBundleOffer]);
};

export const useTrackOnNext = () => {
    const trackOnNextExtraScreen = useTrackOnNextExtraScreen();
    const isExtraCurrentScreen = useCheckoutSelector(isActiveScreen(EXTRAS_SCREEN_IDENTIFIER));

    return React.useCallback(() => {
        if (isExtraCurrentScreen) {
            trackOnNextExtraScreen();
        }
    }, [isExtraCurrentScreen, trackOnNextExtraScreen]);
};

export const useSetHasOptedInCrownNgBundle = () => {
    const setPromoCodeInfo = useCheckoutAction('SET_PROMO_CODE_INFO');

    return React.useCallback(
        (hasOptedIn: boolean) => {
            setPromoCodeInfo({ has_opted_in_crown_ng_bundle: hasOptedIn });
        },
        [setPromoCodeInfo],
    );
};
