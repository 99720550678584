import type { ActionProps, UrgentNotification, UrgentNotificationDataWrapper } from './DandyNotifications.types';
import { NotificationPeriodNames } from './DandyNotifications.types';
import { DandyUrgentNotificationsItem } from './DandyUrgentNotificationsItem';
import { FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DandyUrgentNotificationsProps {
    sortedUrgentNotifications: UrgentNotificationDataWrapper[];
    onClick: (action: ActionProps) => void;
}
export const DandyUrgentNotifications: React.FC<DandyUrgentNotificationsProps> = props => {
    const { sortedUrgentNotifications, onClick } = props;

    return (
        <Grid item xs={12}>
            {sortedUrgentNotifications.map((data, idx) => (
                <React.Fragment key={idx}>
                    <Grid
                        container
                        style={{
                            height: 40,
                            backgroundColor:
                                data.periodName === NotificationPeriodNames.TODAY
                                    ? FlossPalette.DARK_TAN
                                    : FlossPalette.ATTENTION_BG,
                        }}
                    >
                        <Grid item xs={12} style={{ padding: '8px 16px' }}>
                            <Text
                                variant={'body2'}
                                medium
                                color={data.periodName === NotificationPeriodNames.TODAY ? 'GRAY' : 'ATTENTION'}
                            >
                                {data.periodName}
                            </Text>
                        </Grid>
                    </Grid>

                    {data.notifications.map((notification: UrgentNotification) => (
                        <DandyUrgentNotificationsItem
                            notification={notification}
                            key={notification.id}
                            onClick={onClick}
                        />
                    ))}
                </React.Fragment>
            ))}
        </Grid>
    );
};
