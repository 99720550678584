import { useOpenOrderDetailPage } from '../../LabsUtils';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { Button, Text, Tooltip, FlossPalette, Menu, SplitIcon, styled, InfoIcon } from '@orthly/ui-primitives';
import React from 'react';

const SubtitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gridColumnGap: '8px',
});

const SplitIconStyled = styled(SplitIcon)({
    color: FlossPalette.DARK_GRAY,
    height: '16px',
    width: '16px',
});

const InfoIconStyled = styled(InfoIcon)({
    color: FlossPalette.PRIMARY_FOREGROUND,
    fontSize: 16,
});

const StyledButton = styled(Button)({
    padding: 0,
    height: 'auto',
});

const productDisplayText = (order: Pick<LabsGqlSingleLabOrderFragment, 'items_v2'>) =>
    LabsUtilsBase.productsForOrderV2(OrderItemV2Utils.parseItems(order.items_v2)).join(', ');

const getCTADisplayInfo = (
    splitOrders: Pick<LabsGqlSingleLabOrderFragment, 'items_v2'>[],
    updatedOrders: Pick<LabsGqlSingleLabOrderFragment, 'id'>[],
    isCurrentOrderArchived: boolean,
): { label: string; button: string; tooltip?: string } => {
    if (isCurrentOrderArchived) {
        return {
            label: 'This is an archived order',
            button: `View updated ${Format.pluralizeNoun('order', updatedOrders.length)}`,
        };
    }

    if (splitOrders.length === 1 && splitOrders[0]) {
        return {
            label: 'This patient has a related order',
            button: 'View order',
            tooltip: productDisplayText(splitOrders[0]),
        };
    }

    return {
        label: 'This patient has related orders',
        button: 'View orders',
    };
};

interface OrderDetailSubtitleProps {
    order: LabsGqlSingleLabOrderFragment;
}

const useButtonClickAction = (
    updatedOrders: Pick<LabsGqlSingleLabOrderFragment, 'id'>[],
    splitOrders: Pick<LabsGqlSingleLabOrderFragment, 'id'>[],
) => {
    const openOrder = useOpenOrderDetailPage();

    if (splitOrders.length !== 1 && updatedOrders.length !== 1) {
        return;
    }

    if (updatedOrders.length > 0) {
        return (event: React.MouseEvent) => {
            event.stopPropagation();
            openOrder(updatedOrders.slice(-1)?.[0]?.id as string, event);
        };
    }

    return (event: React.MouseEvent) => {
        event.stopPropagation();
        openOrder(splitOrders[0]?.id as string, event);
    };
};

export const OrderDetailSubtitle: React.FC<OrderDetailSubtitleProps> = ({ order }) => {
    const openOrder = useOpenOrderDetailPage();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);

    const splitOrders = order.split_orders ?? [];
    const updatedOrders = (order.replaced_by_orders ?? []).filter(updatedOrder =>
        order.replaced_by_ids?.includes(updatedOrder.id),
    );

    const onButtonClickAction = useButtonClickAction(updatedOrders, splitOrders);
    const isArchivedOrder = updatedOrders.length > 0;

    if (!isArchivedOrder && !order.split_order_count) {
        return null;
    }

    const { label, button, tooltip } = getCTADisplayInfo(splitOrders, updatedOrders, isArchivedOrder);

    const onDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <SubtitleContainer>
            <SplitIconStyled fontSize={'small'} />
            <Text variant={'body2'} color={'DARK_GRAY'}>
                {label}
            </Text>

            {tooltip && (
                <Tooltip title={tooltip}>
                    <InfoIconStyled />
                </Tooltip>
            )}

            {onButtonClickAction ? (
                <StyledButton variant={'ghost'} onClick={onButtonClickAction}>
                    {button}
                </StyledButton>
            ) : (
                <>
                    <Button
                        ref={buttonRef}
                        variant={'ghost'}
                        endIcon={anchorEl ? 'ChevronUp' : 'ChevronDown'}
                        onClick={onDropdownClick}
                        style={{ height: 'unset', padding: 0 }}
                    >
                        {button}
                    </Button>

                    <Menu
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 40,
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        {(updatedOrders.length > 1 ? updatedOrders : splitOrders).map(order => (
                            <Button
                                key={order.id}
                                fullWidth
                                leftAlign
                                variant={'ghost'}
                                style={{ height: 'unset', padding: 8 }}
                                onClick={event => {
                                    event.stopPropagation();
                                    openOrder(order.id);
                                }}
                            >{`View updated ${productDisplayText(order)} order`}</Button>
                        ))}
                    </Menu>
                </>
            )}
        </SubtitleContainer>
    );
};
