import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { useInterval } from '@orthly/ui';
import { useFirebaseStorage } from '@orthly/veneer';
import type Firebase from 'firebase/compat/app';
import _ from 'lodash';
import React from 'react';
import { useAsync } from 'react-async-hook';

const isFirebaseError = (obj: any): obj is { code: string } => {
    return typeof obj === 'object' && typeof obj?.code === 'string';
};

export enum FirebaseStorageWatchStage {
    REVALIDATING = 'REVALIDATING',
    REFETCHING = 'REFETCHING',
    READY = 'READY',
}

/**
 * Search and sync file blob and metadata from firebase storage with polling
 *
 * this function uses a stale-while-revalidate-ish cache policy
 *
 * ```
 *                           getMetadata       fetch
 *                              ready          ready
 *                              v   ^          v   ^
 * timer             (or) -> revalidating -> refetching -> blob -> ready
 * manual invocation (or) -^
 * ```
 *
 * @param storageBucketName The name of the google cloud storage bucket
 * @param storagePathConfig The path from the root of the bucket
 * @param pattern pattern to find within the path
 * @param interval interval between polling
 */
export const useFirebaseStorageSearchWatch = (
    storagePathConfig: BucketStoragePathConfig,
    pattern: string | RegExp,
    interval: number = 5000,
) => {
    const { path: storagePath, bucketName } = storagePathConfig;
    const firebase = useFirebaseStorage(bucketName);

    const [stage, setStage] = React.useState<FirebaseStorageWatchStage>(FirebaseStorageWatchStage.REVALIDATING);
    const [metadata, setMetadata] = React.useState<Firebase.storage.FullMetadata | undefined>();
    const [downloadURL, setDownloadURL] = React.useState<string | undefined>();
    const [blob, setBlob] = React.useState<Blob | undefined>();
    const md5HashRef = React.useRef<string | undefined>();

    const { execute } = useAsync(
        async (storagePath: string, pattern: string | RegExp) => {
            try {
                /* in our use case refresh should mean revalidating,
                 * not redownloading the same file again
                 */
                setStage(FirebaseStorageWatchStage.REVALIDATING);
                const { items } = await firebase.ref(storagePath).listAll();

                const ref = items.find(({ name }) => name.match(pattern));
                const metadataNext: Firebase.storage.FullMetadata | undefined = await ref?.getMetadata();

                /**
                 * demand:
                 * 1. keep previous results available while a new request is pending
                 * 2. deep compare to avoid unnecessary rerender
                 *
                 * UseAsyncReturn.setLoading and UseAsyncReturn.setState won't work simultaneously
                 * and there is no documentation on edge cases
                 *
                 * have to do this manually
                 */
                setMetadata(metadata => (_.isEqual(metadata, metadataNext) ? metadata : metadataNext));

                // use md5Hash as file comparision
                const md5Hash = metadataNext?.md5Hash ?? undefined;

                // skip unless md5Hash changes
                if (md5HashRef.current !== md5Hash) {
                    md5HashRef.current = md5Hash;

                    if (ref && md5Hash) {
                        // file updated
                        setStage(FirebaseStorageWatchStage.REFETCHING);
                        const downloadURL: string = await ref.getDownloadURL();
                        setDownloadURL(downloadURL);
                        const response = await fetch(downloadURL, { cache: 'no-cache' });
                        const blob = await response.blob();
                        setBlob(blob);
                    } else {
                        // file removed
                        setDownloadURL(undefined);
                        setBlob(undefined);
                    }
                }
            } catch (error: any) {
                // if not found, just retry later since we are a watcher
                if (!(isFirebaseError(error) && error.code === 'storage/object-not-found')) {
                    throw error;
                }
            } finally {
                setStage(FirebaseStorageWatchStage.READY);
            }
        },
        [storagePath, pattern],
    );
    /**
     * not sure why UseAsyncReturn.execute does not have a stable identity
     *
     * 1. memo it to avoid unnecessary rerender in downstream components
     * 2. and the refetch function still works as expected
     * 3. and matches how useReducer.dispatch has stable identity
     *
     * UseAsyncReturn.loading will cause flickers
     *
     * Caller should implement loading at their side
     */
    const executeRef = React.useRef(execute);
    /**
     * trigger a watch refresh immediately
     *
     * the result will stale while revalidating cache
     */
    const refresh = React.useCallback(() => executeRef.current(storagePath, pattern), [storagePath, pattern]);
    useInterval(refresh, interval);
    return {
        stage,
        refresh,
        metadata,
        downloadURL,
        blob,
    };
};

/**
 * Create a reactive objectURL pointing to a given Blob
 */
export const useBlobObjectURL = (blob?: Blob) => {
    /**
     * The existing FirebaseFileListItemPreview does not revokeObjectURL, causing memory leak and flickers
     */
    const [url, setURL] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        if (!blob) {
            return;
        }
        const url = URL.createObjectURL(blob);
        setURL(url);
        return () => {
            URL.revokeObjectURL(url);
            setURL(undefined);
        };
    }, [blob]);
    return url;
};
