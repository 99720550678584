import { useTrainees } from '../hooks/useTrainees';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const AddTraineeButton: React.VFC = () => {
    const { trainees, addTrainee } = useTrainees();

    return (
        <Button
            variant={'secondary-gray'}
            style={{ padding: '4px 12px', height: 'unset' }}
            startIcon={'AddIcon'}
            disabled={trainees.length === 8}
            onClick={() => addTrainee()}
        >
            Add person
        </Button>
    );
};
