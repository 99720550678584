import { PrintOrderReturnLabel } from '../../../components/PrintOrderReturnLabel';
import { OrderDetailTrackingUnitButton } from '../OrderDetailTrackingActions';
import { OrderDetailsContextProvider, useOrderDetailsContext } from './OrderDetailTracking.context.graphql';
import { OrderDetailTrackingServiceLevelSpeedBanner } from './OrderDetailTrackingServiceLevelSpeedBanner.graphql';
import { OrderDetailTrackingTitle } from './OrderDetailTrackingTitle';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlWorkflowStateEnum } from '@orthly/graphql-schema';
import { Grid, Text, useScreenIsMd, useScreenIsMobile } from '@orthly/ui-primitives';
import type { OrderDetailBlockProps } from '@orthly/veneer';
import { OrderDetailBlock, useFeatureFlag } from '@orthly/veneer';
import { OrderDetailTrackerV2 } from '@orthly/veneer';
import React from 'react';

const OrderDetailTrackingBlock: React.FC<Omit<OrderDetailBlockProps, 'title'>> = props => (
    <OrderDetailBlock
        title={
            <Text variant={'body2'} color={'DARK_GRAY'} medium>
                Live Tracking
            </Text>
        }
        {...props}
    />
);

const OrderDetailTrackingPlaceholder: React.VFC = () => (
    <OrderDetailTrackingBlock variant={'right'}>
        <Grid container />
    </OrderDetailTrackingBlock>
);

const OrderDetailTrackingContent: React.VFC = () => {
    const {
        existingReview,
        needsAttention,
        onPause,
        order,
        pendingReturnForOrder,
        isDataLoading,
        orderTrackerEntries,
    } = useOrderDetailsContext();
    const { value: enableDesignPreviewETA = false } = useFeatureFlag('enableDesignPreviewETA');
    const { value: noRushShippingBannerInOrderPagesVisible } = useFeatureFlag(
        'noRushShippingBannerInOrderPagesVisible',
    );
    const isMobile = useScreenIsMobile();
    const isMd = useScreenIsMd();

    return (
        <OrderDetailTrackingBlock
            updatedAt={order.updated_at}
            attention={needsAttention}
            pause={onPause}
            actions={!isMobile && <OrderDetailTrackingUnitButton order={order} existingReview={existingReview} />}
            variant={isMd ? 'full' : 'right'}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 16 }}>
                {!isDataLoading && <OrderDetailTrackingTitle />}
                {pendingReturnForOrder && (
                    <PrintOrderReturnLabel
                        labelUrl={pendingReturnForOrder.label_url}
                        status={pendingReturnForOrder.status}
                        title={'Print return label'}
                        analyticsLocation={'Order Details'}
                    />
                )}
            </div>
            {isMobile && (
                <Grid container justifyContent={'flex-start'}>
                    <OrderDetailTrackingUnitButton order={order} existingReview={existingReview} />
                </Grid>
            )}
            <OrderDetailTrackerV2
                padded={true}
                isPractice={true}
                isLab={false}
                showDesignPreviewETA={enableDesignPreviewETA}
                orderTrackerEntries={orderTrackerEntries}
                isOnHold={order?.workflow_state.state === LabsGqlWorkflowStateEnum.OnHold && !order?.hold_in_review}
            />
            {noRushShippingBannerInOrderPagesVisible && (
                <OrderDetailTrackingServiceLevelSpeedBanner orderId={order.id} />
            )}
        </OrderDetailTrackingBlock>
    );
};

export const OrderDetailTracking: React.VFC<{
    order: LabsGqlOrder | undefined;
    refetch: () => Promise<void>;
}> = ({ order, refetch }) => {
    if (order) {
        return (
            <OrderDetailsContextProvider order={order} refetch={refetch}>
                <OrderDetailTrackingContent />
            </OrderDetailsContextProvider>
        );
    }

    return <OrderDetailTrackingPlaceholder />;
};
