export const ItemsSectionDisplayClasses = {
    ROOT: 'dandy-orderItems',
    HEADER: 'dandy-orderItems-header',
    ITEMS: 'dandy-orderItems-itemsContainer',

    ITEM_HEADER: 'dandy-orderItems-itemHeader',
    ITEM_HEADER_TITLE: 'dandy-orderItems-itemHeader-title',

    DETAIL_ROW_WRAPPER: 'dandy-orderItems-itemDetailContainer',
    DETAIL_ROW: 'dandy-orderItems-itemDetailRow',

    SHADE_OCCLUSAL_DETAILS: 'dandy-orderItems-shadeOcclusalDetails',
};
