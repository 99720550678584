export const PICKER_INPUT_ROOT_PADDING = {
    '&&': {
        background: 'transparent',
    },
    '& .MuiInputAdornment-root:not(:first-child)': {
        marginTop: '-6px',
    },
    // This padding applies if there is no label
    '& .MuiInputBase-root:first-child': {
        padding: '0px 14px 10px',
        '& .MuiInputAdornment-root': {
            marginTop: '10px',
        },
    },
};
