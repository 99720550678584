/*eslint-disable max-lines-per-function*/
import type {
    DandyNotification,
    DandyNotificationsData,
    NotificationActionOptions,
    NotificationInvoice,
    NotificationOrderInboxItem,
    NotificationOrderScanFile,
    UrgentNotification,
} from '../DandyNotifications.types';
import { NotificationActionType, NotificationType } from '../DandyNotifications.types';
import {
    extractDate,
    getPeriod,
    getSubtitleForInboxItem,
    getTitleForInboxItem,
    isNotificationOrderItemScanRejectedAndVisibleToPractice,
    openCheckoutForScan,
} from './DandyNotifications.utils';
import type { LabsGqlNotificationOrderItemFragment } from '@orthly/graphql-operations';
import { LabsGqlEventNames } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { HoldUtils } from '@orthly/shared-types';
import { OrderPaperIcon, WarningIcon } from '@orthly/ui';
import { Icon } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const DataTransformer = ({ getOrderUrl, getInvoiceUrl }: NotificationActionOptions) => {
    const urgents: UrgentNotification[] = [];
    const updates: DandyNotification[] = [];

    const addPendingWaxupApprovals = (pendingWaxupApprovals: LabsGqlNotificationOrderItemFragment[]) => {
        const events: UrgentNotification[] = pendingWaxupApprovals.map(order => {
            return {
                id: order.id,
                type: NotificationType.PENDING_WAXUP_APPROVAL,
                eventDate: order.created_at,
                dismiss: false,
                icon: <WarningIcon />,
                period: getPeriod(order.created_at),
                title: `You have an order for ${order.patient.first_name} ${order.patient.last_name} that requires your approval to proceed.`,
                subtitle: `Submit your design preview review to continue.`,
                primaryAction: {
                    text: 'View Design Preview',
                    url: getOrderUrl(order.id),
                    type: NotificationActionType.REDIRECT,
                },
            };
        });
        urgents.push(...events);
    };

    const addVisibleHoldOrders = (visibleHoldOrders: LabsGqlNotificationOrderItemFragment[]) => {
        const events: UrgentNotification[] = _.compact(
            visibleHoldOrders.map(order => {
                switch (order.fulfillment_workflow.active_task?.__typename) {
                    case 'ResolveHoldWorkflowTask':
                    case 'DrReviewHoldWorkflowTask':
                        return order.show_hold_status_to_practice
                            ? {
                                  id: order.id,
                                  type: NotificationType.VISIBLE_HOLD_ORDERS,
                                  eventDate: order.created_at,
                                  dismiss: false,
                                  icon: <WarningIcon />,
                                  period: getPeriod(order.created_at),
                                  title: `Your order for ${order.patient.first_name} ${order.patient.last_name} has been placed on hold.`,
                                  subtitle: HoldUtils.composeHoldReason(order.hold_details) ?? '',
                                  primaryAction: {
                                      text: 'View Order',
                                      type: NotificationActionType.REDIRECT,
                                      url: getOrderUrl(order.id),
                                  },
                              }
                            : null;
                    case 'ResolveScanRejectionWorkflowTask':
                        return isNotificationOrderItemScanRejectedAndVisibleToPractice(order)
                            ? {
                                  id: order.id,
                                  type: NotificationType.VISIBLE_HOLD_ORDERS,
                                  eventDate: order.created_at,
                                  dismiss: false,
                                  icon: <WarningIcon />,
                                  period: getPeriod(order.created_at),
                                  title: `We found an issue with ${order.patient.first_name} ${order.patient.last_name}'s scan`,
                                  subtitle: `We need your help resolving this, the delivery date might be affected.`,
                                  primaryAction: {
                                      text: 'Resolve',
                                      type: NotificationActionType.REDIRECT,
                                      url: getOrderUrl(order.id),
                                  },
                              }
                            : null;
                    default:
                        return null;
                }
            }),
        );
        urgents.push(...events);
    };

    const addVisiblePausedOrders = (visiblePausedOrders: LabsGqlNotificationOrderItemFragment[]) => {
        const events: DandyNotification[] = visiblePausedOrders.map(order => {
            return {
                id: order.id,
                type: NotificationType.VISIBLE_PAUSED_ORDERS,
                eventDate: order.hold_details?.hold_created_at ?? '',
                dismiss: false,
                icon: <OrderPaperIcon />,
                period: getPeriod(order.hold_details?.hold_created_at ?? ''),
                title: `You paused ${order.patient.first_name} ${order.patient.last_name}'s order`,
                subtitle: 'This order will be held for 45 days or until you resume the order.',
                primaryAction: {
                    text: 'View Order',
                    type: NotificationActionType.REDIRECT,
                    url: getOrderUrl(order.id),
                },
            };
        });
        updates.push(...events);
    };

    const addVisiblePausedOrdersSlatedForDeletion = (visiblePausedOrders: LabsGqlNotificationOrderItemFragment[]) => {
        const events: UrgentNotification[] = visiblePausedOrders.map(order => {
            const deletionDays = 45 - moment().diff(moment(order.hold_details?.hold_created_at), 'days');

            return {
                id: order.id,
                type: NotificationType.VISIBLE_PAUSED_ORDERS_SLATED_FOR_DELETION,
                eventDate: order.hold_details?.hold_created_at ?? '',
                dismiss: false,
                icon: <WarningIcon />,
                period: getPeriod(order.hold_details?.hold_created_at ?? ''),
                title: `${order.patient.first_name} ${order.patient.last_name}'s order will be deleted in ${Format.pluralize('day', deletionDays)}`,
                subtitle: 'Resume your paused order to preserve the order',
                primaryAction: {
                    text: 'Resume Order',
                    type: NotificationActionType.REDIRECT,
                    url: getOrderUrl(order.id),
                },
            };
        });
        urgents.push(...events);
    };

    const addOverdueInvoices = (overdueInvoices: NotificationInvoice[]) => {
        const events: DandyNotification[] = overdueInvoices.map(invoice => {
            return {
                id: invoice.id,
                type: NotificationType.OVERDUE_INVOICES,
                eventDate: extractDate(invoice.created_at),
                dismiss: false,
                title: 'Invoice overdue',
                subtitle: `Invoice from ${moment.utc(invoice.period_start).format('YYYY MMMM')}`,
                icon: <OrderPaperIcon />,
                primaryAction: {
                    text: 'View',
                    type: NotificationActionType.REDIRECT,
                    url: getInvoiceUrl({ invoiceId: invoice.id }),
                },
            };
        });
        updates.push(...events);
    };

    const addUnsubmittedScans = (unsubmittedScans: NotificationOrderScanFile[], isMobile: boolean = false) => {
        const events: DandyNotification[] = unsubmittedScans.map(scan => {
            return {
                id: scan.id,
                type: NotificationType.UNSUBMITTED_SCANS,
                eventDate: extractDate(scan.created_at),
                dismiss: false,
                title: `Incomplete order for ${scan.patient_first_name} ${scan.patient_last_name}`,
                subtitle: `${Format.pluralize('item', scan.restorations.length)}. Scanned: ${moment(
                    scan.created_at,
                ).format('MM/DD/YY')}.`,
                icon: <Icon icon={'StarRateRounded'} />,
                primaryAction: {
                    text: 'Complete Order',
                    disabled: isMobile,
                    type: NotificationActionType.REDIRECT,
                    url: openCheckoutForScan(scan.id),
                },
            };
        });
        updates.push(...events);
    };

    const addInboxItems = (inboxItems: NotificationOrderInboxItem[]) => {
        const events: DandyNotification[] = inboxItems.map(inboxItem => {
            const title = getTitleForInboxItem(inboxItem);
            const subtitle = getSubtitleForInboxItem(inboxItem);
            const primaryActionText =
                inboxItem.event_name === LabsGqlEventNames.DesignFileConversionCompleted ? 'Review' : 'View order';
            const data: DandyNotification = {
                title,
                subtitle,
                id: inboxItem.order.id,
                type: NotificationType.INBOX_ITEMS,
                eventDate: extractDate(inboxItem.date),
                dismiss: true,
                icon: <OrderPaperIcon />,
                primaryAction: {
                    text: primaryActionText,
                    disabled: false,
                    type: NotificationActionType.REDIRECT,
                    url: getOrderUrl(inboxItem.order.id),
                },
            };

            if (inboxItem.event_name !== LabsGqlEventNames.DesignFileConversionCompleted) {
                data.dismiss = true;
                data.secondaryAction = {
                    text: 'Dismiss',
                    type: NotificationActionType.DISMISS,
                    eventId: inboxItem.event_id,
                };
            }
            return data;
        });
        updates.push(...events);
    };

    const getUrgents = (): UrgentNotification[] => urgents;

    const getUpdates = (): DandyNotification[] => updates;

    return {
        addPendingWaxupApprovals,
        addOverdueInvoices,
        addUnsubmittedScans,
        addVisibleHoldOrders,
        addVisiblePausedOrders,
        addVisiblePausedOrdersSlatedForDeletion,
        addInboxItems,
        getUrgents,
        getUpdates,
    };
};
export interface GenerateNotificationArgs {
    loading: boolean;
    notificationData: DandyNotificationsData | undefined;
    doctorMatches: (cmp: string) => boolean;
    notificationActionOptions: NotificationActionOptions;
    isMobile: boolean;
}

const orderIsSlatedForDeletion = (order: LabsGqlNotificationOrderItemFragment): boolean => {
    if (!order.hold_details) {
        return false;
    }

    // An order is marked as stale and slated for deletion after it has been on hold for 45 days.
    // Here we're checking if the order has been on hold for 43 days or more (2 day grace period).
    return moment().diff(moment(order.hold_details.hold_created_at), 'days') >= 43;
};

const orderIsOnPracticeManagedPause = (order: LabsGqlNotificationOrderItemFragment): boolean => {
    if (!order.hold_details) {
        return false;
    }

    return Boolean(order.hold_details.hold_is_practice_managed_pause);
};

export const generateNotifications = ({
    loading,
    notificationData,
    doctorMatches,
    notificationActionOptions,
    isMobile,
}: GenerateNotificationArgs) => {
    if (loading) {
        return {
            urgents: [],
            updates: [],
        };
    }

    const visibleHoldOrders = notificationData?.actionableHoldOrders?.filter(
        order => doctorMatches(order.doctor_preferences_id) && !orderIsOnPracticeManagedPause(order),
    );
    const visiblePausedOrdersThatWillBeDeletedSoon = notificationData?.actionableHoldOrders?.filter(
        order =>
            doctorMatches(order.doctor_preferences_id) &&
            orderIsOnPracticeManagedPause(order) &&
            orderIsSlatedForDeletion(order),
    );
    const visiblePausedOrdersThatWillNotBeDeletedSoon = notificationData?.actionableHoldOrders?.filter(
        order =>
            doctorMatches(order.doctor_preferences_id) &&
            orderIsOnPracticeManagedPause(order) &&
            !orderIsSlatedForDeletion(order),
    );
    const inboxItems = notificationData?.orderInboxItems?.filter(item =>
        doctorMatches(item.order.doctor_preferences_id),
    );
    const pendingWaxupApprovals = notificationData?.waxupReviews?.filter(order =>
        doctorMatches(order.doctor_preferences_id),
    );

    const transformer = DataTransformer(notificationActionOptions);
    // urgent notifications
    transformer.addPendingWaxupApprovals(pendingWaxupApprovals ?? []);
    transformer.addVisiblePausedOrdersSlatedForDeletion(visiblePausedOrdersThatWillBeDeletedSoon ?? []);
    transformer.addVisibleHoldOrders(visibleHoldOrders ?? []);

    // update notifications
    transformer.addVisiblePausedOrders(visiblePausedOrdersThatWillNotBeDeletedSoon ?? []);
    transformer.addOverdueInvoices(notificationData?.overdueInvoices ?? []);
    transformer.addUnsubmittedScans(notificationData?.scanFiles ?? [], isMobile);
    transformer.addInboxItems(inboxItems ?? []);

    return {
        urgents: transformer.getUrgents(),
        updates: transformer.getUpdates(),
    };
};
