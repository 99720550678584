import { BoldText, LightText, MediumText, SemiBoldText } from '../../LabSlip/components/StyledComponents';
import type { ItemActionComponent } from '../../OrderDetails/OrderItemsTable.types';
import type { TextProps } from '@orthly/ui-primitives';
import { Text, Grid } from '@orthly/ui-primitives';
import constate from 'constate';
import type { ElementType } from 'react';
import React from 'react';
import type { SetOptional } from 'type-fest';

const convertPtToPx = (style: React.CSSProperties = {}) => {
    return Object.assign(
        {},
        ...Object.entries(style).map(([key, val]) => ({
            [key]: /[0-9]pt/.test(val) ? (val as string).replace('pt', 'px') : val,
        })),
    );
};

interface CommonProps {
    children?: React.ReactNode;
    component?: ElementType;
    style?: React.CSSProperties;
    className?: string;
}

const commonHtmlFontStyle = {
    alignItems: 'baseline',
    display: 'inline',
    marginTop: '0',
    marginBottom: '0',
};

type TypographyWithOptionalVariant = SetOptional<TextProps, 'variant'>;

const MediumTextHtml: React.FC<TypographyWithOptionalVariant> = props => (
    <Text
        {...props}
        variant={props.variant ?? 'body2'}
        style={{
            ...convertPtToPx(props.style),
            ...commonHtmlFontStyle,
        }}
    />
);

const BoldTextHtml: React.FC<TypographyWithOptionalVariant> = props => (
    <Text
        {...props}
        variant={props.variant ?? 'body2'}
        style={{
            ...convertPtToPx(props.style),
            ...commonHtmlFontStyle,
            fontWeight: 'bold',
        }}
    />
);

const SemiBoldTextHtml: React.FC<TypographyWithOptionalVariant> = props => (
    <Text
        {...props}
        variant={props.variant ?? 'body2'}
        style={{
            ...convertPtToPx(props.style),
            ...commonHtmlFontStyle,
            fontFamily: 'Bagoss Standard',
        }}
    />
);

const LightTextHtml: React.FC<TypographyWithOptionalVariant> = SemiBoldTextHtml;

const ContainerComponentHTML: React.FC<CommonProps> = props => (
    <Grid container {...props} style={convertPtToPx(props.style)} />
);

const ContainerComponentPDF: React.FC<CommonProps> = props => <Grid {...props} />;
const MediumTextPDF: React.FC<TypographyWithOptionalVariant> = props => (
    <MediumText {...props} component={props.component ?? 'span'} variant={props.variant ?? 'body1'} />
);
const BoldTextPDF: React.FC<TypographyWithOptionalVariant> = props => (
    <BoldText {...props} component={props.component ?? 'span'} variant={props.variant ?? 'body1'} />
);
const SemiBoldTextPDF: React.FC<TypographyWithOptionalVariant> = props => (
    <SemiBoldText {...props} component={props.component ?? 'span'} variant={props.variant ?? 'body1'} />
);
const LightTextPDF: React.FC<TypographyWithOptionalVariant> = props => (
    <LightText {...props} component={props.component ?? 'span'} variant={props.variant ?? 'body1'} />
);
const TextPDF: React.FC<TypographyWithOptionalVariant> = props => (
    <Text {...props} component={props.component ?? 'span'} variant={props.variant ?? 'body1'} />
);

type FontComponentName = 'MediumText' | 'BoldText' | 'SemiBoldText' | 'LightText' | 'Text';
export type OrderSummaryTarget = 'html' | 'pdf';

export interface UseOrderSummaryComponents {
    target?: OrderSummaryTarget;
    Action?: ItemActionComponent;
    ContainerComponent: React.ComponentType<CommonProps>;
    fontComponents: { [K in FontComponentName]-?: React.ComponentType<TypographyWithOptionalVariant> };
}

type OrderSummaryComponentsOpts = { target: OrderSummaryTarget; Action?: ItemActionComponent };

const useSummaryComponents = (props: OrderSummaryComponentsOpts): UseOrderSummaryComponents =>
    React.useMemo<UseOrderSummaryComponents>(() => {
        if (props.target === 'html') {
            return {
                target: props.target,
                Action: props.Action,
                ContainerComponent: ContainerComponentHTML,
                fontComponents: {
                    MediumText: MediumTextHtml,
                    BoldText: BoldTextHtml,
                    SemiBoldText: SemiBoldTextHtml,
                    LightText: LightTextHtml,
                    Text: MediumTextHtml,
                },
            };
        }
        return {
            target: props.target,
            ContainerComponent: ContainerComponentPDF,
            fontComponents: {
                MediumText: MediumTextPDF,
                BoldText: BoldTextPDF,
                SemiBoldText: SemiBoldTextPDF,
                LightText: LightTextPDF,
                Text: TextPDF,
            },
        };
    }, [props.target, props.Action]);

const [_OrderSummaryComponentsProvider, _useOrderSummaryComponents] = constate(useSummaryComponents);

export const useOrderSummaryComponents: () => UseOrderSummaryComponents = _useOrderSummaryComponents;
export const OrderSummaryComponentsProvider: React.FC<OrderSummaryComponentsOpts> = _OrderSummaryComponentsProvider;
