import type { ScanbodyRequestPartial } from './ScanbodyRequest.reducer';
import type { ScanbodyStage } from './ScanbodyRequest.types';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'scanbody_request';

type ScanbodyRequestEntry = Exclude<
    {
        [K in keyof ScanbodyRequestPartial]: {
            property: K;
            value: ScanbodyRequestPartial[K];
        };
    }[keyof ScanbodyRequestPartial],
    undefined
>;

export const ScanbodyRequestActions = {
    SET_REQUEST: createSyncAction<ScanbodyRequestPartial>(`${PREFIX}/SET_REQUEST`),

    // must give second template parameter to get action args type [{} | {} | {}]
    // otherwise typescript will expand args to [{}] | [{}] | [{}]
    SET_REQUEST_FIELD: createSyncAction<ScanbodyRequestEntry, [ScanbodyRequestEntry]>(`${PREFIX}/SET_REQUEST_FIELD`),

    RESET_REQUEST: createSyncAction<undefined>(`${PREFIX}/RESET_REQUEST`),

    SET_STAGE: createSyncAction<ScanbodyStage>(`${PREFIX}/SET_STAGE`),

    SET_DECLINED_SURGICAL_REPORT: createSyncAction<boolean>(`${PREFIX}/SET_DECLINED_SURGICAL_REPORT`),
};

export const useScanbodyRequestAction = generateUseActionHook<typeof ScanbodyRequestActions>(ScanbodyRequestActions);
