import { Text, styled } from '@orthly/ui-primitives';

export const Panel = styled('div')(({ theme }) => ({
    gridArea: 'Panel',
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    justifySelf: 'center',
    width: 'min(100%, 456px)',
    borderRadius: 16,
    backgroundColor: 'white',
    boxShadow: '0px 4px 15px #ECEAE6',
    padding: 24,
    marginTop: 104,
    [theme.breakpoints.down('sm')]: {
        padding: 16,
        marginTop: 32,
    },
}));

export const PanelHeader = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
});

export const Title = styled(Text)({
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    alignItems: 'center',
    '& svg': {
        fontSize: 'inherit',
    },
});

export const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
});
