import type { ItemSearchControls, ItemSearchResults } from '../../hooks';
import { DandyBackdrop, HORIZONTAL_NAV_HEIGHT, TooltipMenu } from '../HorizontalNav';
import { DandySearchInput } from './DandySearchInput';
import { DandySearchOptions } from './DandySearchOptions';
import { useSaveSearchAction } from './DandySearchPresenter.hooks';
import type { CreateSavedOrderSearchMutationType, SavedSearchItem } from './DandySearchPresenter.types';
import { useDandySearchTools } from './utils/DandySearchTools';
import { stylesFactory, ClickAwayListener } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useSearchTooltipStyles = stylesFactory(() => ({
    tooltip: {
        borderRadius: 8,
    },
}));

const useNavigationAction = (
    getOrderUrl: DandySearchPresenterProps['getOrderUrl'],
    setOpen: (open: boolean) => void,
) => {
    const history = useHistory();
    return (orderId: string | number) => {
        const orderUrl = getOrderUrl(orderId);
        history.push(orderUrl);
        setOpen(false);
    };
};

interface DandySearchPresenterProps {
    screenIsMedium: boolean;
    getOrderUrl: (orderId: string | number) => string;
    sessionId?: string;
    createSavedOrderSearchMutation: CreateSavedOrderSearchMutationType;
    useOrderSearchResults: (controls?: ItemSearchControls) => ItemSearchResults;
    savedSearchData: SavedSearchItem[];
    refetchSavedSearches: () => void | Promise<unknown>;
    onSearchAnalyticsAction: (value: string) => void;
}

export const DandySearchPresenter: React.VFC<DandySearchPresenterProps> = ({
    screenIsMedium,
    getOrderUrl,
    sessionId,
    createSavedOrderSearchMutation,
    useOrderSearchResults,
    savedSearchData,
    refetchSavedSearches,
    onSearchAnalyticsAction,
}) => {
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState('');
    const [isDebounced, setIsDebounced] = React.useState(false);

    const classes = useSearchTooltipStyles();
    const inputRef = React.useRef<HTMLDivElement>(null);
    const onNavigationAction = useNavigationAction(getOrderUrl, setOpen);

    const { debouncedOnChange, onInputChangeAction, onInputFocusAction, onInputClearAction, orders, loading } =
        useDandySearchTools({
            setSearch,
            setIsDebounced,
            useOrderSearchResults,
            savedSearchData,
            setOpen,
            onSearchAnalyticsAction,
        });

    React.useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);

    React.useEffect(() => {
        if (sessionId) {
            void refetchSavedSearches();
        }
    }, [refetchSavedSearches, sessionId]);

    const saveSearchAction = useSaveSearchAction(refetchSavedSearches, createSavedOrderSearchMutation);

    return (
        <>
            <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div style={screenIsMedium ? { width: '100%' } : {}}>
                    <TooltipMenu
                        classes={classes}
                        disableHoverListener
                        disableTouchListener
                        disableFocusListener
                        open={open}
                        PopperProps={{
                            disablePortal: true,
                            modifiers: [
                                {
                                    name: 'offset',
                                    options: {
                                        offset: [0, -4],
                                    },
                                },
                            ],
                        }}
                        title={
                            <DandySearchOptions
                                width={screenIsMedium ? inputRef?.current?.clientWidth : undefined}
                                search={search}
                                savedSearches={savedSearchData}
                                orders={orders}
                                loading={loading || isDebounced}
                                onNavigationAction={onNavigationAction}
                                saveSearchAction={saveSearchAction}
                            />
                        }
                    >
                        <DandySearchInput
                            ref={inputRef}
                            search={search}
                            onInputChange={onInputChangeAction}
                            onInputFocus={onInputFocusAction}
                            onInputClear={onInputClearAction}
                            placeholder={'Search for orders'}
                        />
                    </TooltipMenu>
                </div>
            </ClickAwayListener>
            <DandyBackdrop open={open} navbarHeight={HORIZONTAL_NAV_HEIGHT} />
        </>
    );
};
