import type { OrderTimelineV2Item } from '../../../../../PracticeOrderChatV2';
import { DandyAvatarIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    externalAvatar: {
        backgroundColor: '#E59D59',
        height: 16,
        width: 16,
        flexShrink: 0,
        marginRight: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 600,
        color: FlossPalette.WHITE,
        fontSize: 12,
        borderRadius: 2,
    },
    internalAvatar: {
        height: 16,
        width: 16,
        marginRight: 4,
        position: 'relative',
        top: 3,
    },
}));

export const OrderTimelineAvatar: React.FC<{
    actor: OrderTimelineV2Item['actor'];
}> = ({ actor }) => {
    const classes = useStyles();

    if (actor.isInternal) {
        return <DandyAvatarIcon className={classes.internalAvatar} />;
    }

    if (actor.initial) {
        return <div className={classes.externalAvatar}>{actor.initial}</div>;
    }

    return null;
};
