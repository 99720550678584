import { useCheckoutPropSelector, useAlignerCheckoutSelector } from '../../../redux';
import { CheckoutItemV2Manager } from './CheckoutItemV2Manager';
import { useScanIsAligner } from './checkout.selectors';
import type { CheckoutItemV2 } from './checkout.state';
import { useDentureCheckoutSelector, useScanIsDenture } from './dentures-checkout.selectors';
import type { AlignerCheckoutState } from './reducers/aligners-checkout.types';
import type { DentureCheckoutState } from './reducers/dentures-checkout.types';
import type { LabsGqlLabOrderItemV2InputBySku, LabsGqlSmileStyleChoice } from '@orthly/graphql-schema';
import { OrderItemV2InputUtils } from '@orthly/graphql-schema';
import type { OrderItemArch } from '@orthly/items';
import {
    CartItemV2Utils,
    LabOrderItemSKUType,
    DentureType,
    ItemDataFieldUtils,
    DenturesProductionType,
    DenturesFabricationMethod,
} from '@orthly/items';
import React from 'react';

const DENTURE_TYPE_MATERIAL_EQUIVALENT: { [K in DentureType]: string } = {
    [DentureType.Immediate]: 'Immediate Full Denture',
    [DentureType.Copy]: 'Copy / Reference',
    [DentureType.WaxRim]: 'Wax Rim',
    [DentureType.WaxRimContinuation]: 'Final Denture (from wax rim scan)',
    [DentureType.CentricTray]: DentureType.CentricTray, // deprecated
};

function populateAlignersItems(items: CheckoutItemV2[], state: AlignerCheckoutState): CheckoutItemV2[] {
    return items.map(i => {
        if (!CartItemV2Utils.itemIsType(i, LabOrderItemSKUType.Aligners)) {
            return i;
        }
        if (state.retainer) {
            return { ...i, unit: { ...i.unit, unit_type: 'Aligner Retainer', arch: state.retainer.arch } };
        }
        return {
            ...i,
            unit: {
                ...i.unit,
                // this is a safe cast since LabsGqlAlignerArch is created directly from OrderItemArch
                arch: state.alignerArch ? (state.alignerArch as string as OrderItemArch) : undefined,
            },
        };
    });
}

// removes any non-denture items and updates denture items with selections from checkout
function cleanAndPopulateDenturesItems(items: CheckoutItemV2[], state: DentureCheckoutState): CheckoutItemV2[] {
    const { dentureOptions, dentureArch, dentureType } = state.fields;

    // if we have a set production type, we want to use that
    // if we don't, but we have a wax rim, then we know it must be a wax rim production type
    const waxRimProductionType = dentureType === DentureType.WaxRim ? DenturesProductionType.WaxRim : undefined;
    const productionType = dentureOptions?.productionType
        ? DenturesProductionType[dentureOptions.productionType]
        : waxRimProductionType;

    const fabricationMethod = dentureOptions?.fabrication
        ? DenturesFabricationMethod[dentureOptions.fabrication]
        : undefined;

    return items.flatMap(item => {
        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture)) {
            return {
                ...item,
                shades: dentureOptions?.shades ?? [],
                denture_type: dentureType,
                denture_production_type: productionType,
                denture_fabrication_method: fabricationMethod,
                unit: {
                    ...item.unit,
                    arch: dentureArch,
                    material: dentureType ? DENTURE_TYPE_MATERIAL_EQUIVALENT[dentureType] : item.unit.material,
                },
            };
        }
        return [];
    });
}

// we want to remove any preferences that may have been selected
// but no longer apply because something on the item has changed since the time of selection
function cleanExtraPreferences(item: CheckoutItemV2): CheckoutItemV2 {
    const metafieldIds = CheckoutItemV2Manager.getMetafields(item).map(f => f.id);
    return {
        ...item,
        preference_fields: item.preference_fields.filter(pref => metafieldIds.includes(pref.field_id)),
    };
}

// injects the order-level smile style as an item level preference field to items where it's applicable
function injectSmileStylePreference(item: CheckoutItemV2, smileStyle: LabsGqlSmileStyleChoice | null): CheckoutItemV2 {
    if (!smileStyle || !CartItemV2Utils.isItemEligibleForSmileStyle(item)) {
        return item;
    }
    return CheckoutItemV2Manager.applyUpdate(item, {
        name: 'preference_field',
        payload: { field_id: 'smile-style-order-item-meta', value: smileStyle, display_name: 'Smile Style' },
    });
}

export function useInputItemsV2(): LabsGqlLabOrderItemV2InputBySku | null {
    const isAligner = useScanIsAligner();
    const isDenture = useScanIsDenture();
    const { items, smileStyle } = useCheckoutPropSelector(['items', 'smileStyle']);
    const alignerState = useAlignerCheckoutSelector(s => s);
    const dentureState = useDentureCheckoutSelector(s => s);

    return React.useMemo(() => {
        // eslint-disable-next-line no-nested-ternary
        const updatedItems = isAligner
            ? populateAlignersItems(items, alignerState)
            : isDenture
              ? cleanAndPopulateDenturesItems(items, dentureState)
              : items;

        const cleanedItems = updatedItems.map(i =>
            ItemDataFieldUtils.cleanInapplicableValues(
                injectSmileStylePreference(cleanExtraPreferences(i), smileStyle ?? null),
            ),
        );

        return OrderItemV2InputUtils.getOrderItemV2InputBySKU(cleanedItems);
    }, [isAligner, isDenture, items, smileStyle, alignerState, dentureState]);
}
