import type { OrderTimelineV2Item } from '../../../../../PracticeOrderChatV2';
import { stylesFactory, Tooltip, Text } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    timestamp: {
        textAlign: 'right',
    },
}));

export const OrderTimelineTimestamp: React.VFC<{
    className?: string;
    event: OrderTimelineV2Item;
}> = ({ className, event }) => {
    const classes = useStyles({ variant: event.variant });
    const time = new Date(event.date).toLocaleTimeString(undefined, { hour: 'numeric', minute: '2-digit' });
    const dateTime = new Date(event.date).toLocaleString(undefined, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
    });

    return (
        <Text className={classNames(classes.timestamp, className)} color={'LIGHT_GRAY'} variant={'caption'}>
            <Tooltip title={dateTime}>
                <span>{time}</span>
            </Tooltip>
        </Text>
    );
};
