import { SelectScansModelViewer } from './practitioners/AttachScans/SelectScansAction';
import { ScanSource } from './practitioners/AttachScans/utils/Reloader.util';
import { AssignFilesList } from './practitioners/UploadFiles/AssignFilesList';
import { UploadFilesAction } from './practitioners/UploadFiles/UploadFilesAction';
import {
    uploadFilesScansMapAtom,
    uploadFilesUploadedFilesAtom,
} from './practitioners/UploadFiles/atoms/UploadFiles.atoms';
import {
    FileAssignmentContainer,
    FileAssignmentLayout,
    ScanPreviewWrapper,
    SelectFilesContainer,
} from './practitioners/UploadFiles/components/AddAttachmentFormComponents';
import {
    useAttachmentFormControls,
    useOrderFileUploadActions,
} from './practitioners/UploadFiles/utils/UploadFiles.hooks';
import {
    getModelPayloadItemsFromScans,
    getPreviewedFileDisplayName,
} from './practitioners/UploadFiles/utils/UploadFiles.util';
import { MODEL_VIEWER_INITIAL_APPEARANCE, FullScreenWorkflowContainer } from '@orthly/dentin';
import type {
    MainViewCameraControlsRef,
    MainViewModelRef,
    ModelAppearance,
    ModelPayloadItem,
    PayloadModelAppearance,
} from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { ChevronRight, LoadBlocker } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import type { UploadedFile } from '@orthly/veneer';
import {
    getOrderEditMode,
    NewOrderBanner,
    OrderEditDeliveryDate,
    OrderEditMode,
    TryAttachScansOrderBanner,
} from '@orthly/veneer';
import { useAtom } from 'jotai';
import { basename } from 'path-browserify';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface AddAttachmentFormProps {
    order: LabsGqlLabOrderFragment;
    setOpen: (open: boolean) => void;
    refetchMessages: () => void;
    setIsAttachScansOpen: (open: boolean) => void;
    setScansToastOpen?: (toastOpen: boolean) => void;
    setFilesAddedToastOpen?: (toastOpen: boolean) => void;
    setErrorToastOpen?: (toastOpen: boolean) => void;
}

const getNextButtonTitle = (orderIsCancelResubmit: boolean, isAssignmentStep: boolean) => {
    if (!isAssignmentStep) {
        return (
            <>
                {'Next'}
                <ChevronRight />
            </>
        );
    }

    if (orderIsCancelResubmit) {
        return 'Upload Files as New Order';
    }

    return 'Upload';
};

// eslint-disable-next-line max-lines-per-function
export const AddAttachmentForm: React.VFC<AddAttachmentFormProps> = props => {
    const { order, setOpen, refetchMessages, setScansToastOpen, setFilesAddedToastOpen, setErrorToastOpen } = props;
    const { editMode } = getOrderEditMode({ order, newScan: true });
    const orderItems = OrderItemV2Utils.parseItems(order.items_v2);

    const [scansMap, setScansMap] = useAtom(uploadFilesScansMapAtom);
    const [uploadFiles, setUploadFiles] = useAtom(uploadFilesUploadedFilesAtom);
    const groupedFiles: Record<string, UploadedFile[]> = {};

    const [loading, setLoading] = React.useState(false);
    const [loaderText, setLoaderText] = React.useState('');
    const [shouldProcessModelPayloadItems, setShouldProcessModelPayloadItems] = React.useState(false);
    const [processingScans, setProcessingScans] = React.useState(false);
    const [isAssignmentStep, setIsAssignmentStep] = React.useState(false);
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const [previewedFile, setPreviewedFile] = React.useState<string>('');
    const [processingPayloads, setProcessingPayloads] = React.useState<boolean>(false);
    const [scanPayloads, setScanPayloads] = React.useState<ModelPayloadItem[]>([]);
    const [hasUploadedMultipleThreeOxzs, setHasUploadedMultipleThreeOxzs] = React.useState<boolean>(false);
    const [orderEtaValidation, setOrderEtaValidation] = React.useState(false);

    // Refs for the model viewer.
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    // This sessionId links mobile and laptop for component lifetime.
    const [sessionId] = React.useState<string>(() => uuidv4());

    const displayName = getPreviewedFileDisplayName(previewedFile);
    const orderIsCancelResubmit = scansMap.size > 0 && editMode === OrderEditMode.CancelAndResubmit;

    const { onRemoveFile, files } = useOrderFileUploadActions(order.id, sessionId);
    const { disableSelectionStepNext, onNextAction, onBackAction } = useAttachmentFormControls({
        order,
        groupedFiles,
        uploadFiles,
        files,
        isAssignmentStep,
        orderIsCancelResubmit,
        refetchMessages,
        setProcessingScans,
        setOpen,
        setScansToastOpen,
        setFilesAddedToastOpen,
        setErrorToastOpen,
        setLoading,
        setIsAssignmentStep,
        setPreviewedFile,
        setHasUploadedMultipleThreeOxzs,
        setLoaderText,
        onRemoveFile,
    });

    // Clear the atoms when the component unmounts.
    React.useEffect(() => {
        return () => {
            setUploadFiles([]);
            setScansMap(new Map());
        };
    }, [setScansMap, setUploadFiles]);

    // Should reprocess the model payload items when the scans map changes, i.e. when processingScans is toggled.
    React.useEffect(() => {
        setShouldProcessModelPayloadItems(true);
    }, [processingScans]);

    React.useEffect(() => {
        if (!shouldProcessModelPayloadItems) {
            return;
        }

        const getScanModelPayloadItems = async () => {
            setProcessingPayloads(true);
            const scanModelPayloadItems = await getModelPayloadItemsFromScans([...scansMap.entries()]);
            setScanPayloads(scanModelPayloadItems);
            setShouldProcessModelPayloadItems(false);
            setProcessingPayloads(false);
        };

        void getScanModelPayloadItems();
    }, [scansMap, shouldProcessModelPayloadItems]);

    React.useEffect(() => {
        setAppearance(currentAppearance => {
            const scanPayloads = currentAppearance.scans.map((plma: PayloadModelAppearance) => {
                const expectedName = basename(plma.payloadModel.name);

                if (!expectedName) {
                    return plma;
                }

                const isVisible = expectedName.toLowerCase() === previewedFile.toLowerCase();

                return {
                    ...plma,
                    appearance: {
                        ...plma.appearance,
                        visible: isVisible,
                    },
                };
            });

            return { ...currentAppearance, scans: scanPayloads };
        });
    }, [scanPayloads, previewedFile]);

    const disableUploadButton = React.useMemo(() => {
        return (
            loading ||
            [...scansMap.values()].some(scanUpload => {
                return scanUpload.source === ScanSource.SingleScan
                    ? scanUpload.scan.definition === undefined
                    : scanUpload.scans.some(scan => scan.definition === undefined);
            })
        );
    }, [loading, scansMap]);

    const orderEditMode = scansMap.size > 0 && editMode && isAssignmentStep ? editMode : undefined;
    const orderStatus = order.status;
    const orderActiveTaskType = order.fulfillment_workflow.active_task?.type;

    return (
        <>
            {order.can_attach_scans && !isAssignmentStep && (
                <TryAttachScansOrderBanner onClick={() => props.setIsAttachScansOpen(true)} />
            )}
            <FullScreenWorkflowContainer
                title={
                    <>
                        <Text variant={'h4'} medium color={'LIGHT_GRAY'} style={{ marginBottom: -4 }}>
                            {'Additional attachments'}
                        </Text>
                        <Text variant={'h3'} medium style={{ marginBottom: 20 }}>
                            {isAssignmentStep && scansMap.size > 0
                                ? 'How should the designer use the files?'
                                : 'Select your source and upload files'}
                        </Text>
                    </>
                }
                onNext={onNextAction}
                onBack={onBackAction}
                nextTitle={getNextButtonTitle(orderIsCancelResubmit, isAssignmentStep)}
                disableNext={
                    isAssignmentStep
                        ? disableUploadButton || (orderIsCancelResubmit && !orderEtaValidation)
                        : disableSelectionStepNext
                }
                NextButtonAdditionalContent={
                    isAssignmentStep &&
                    scansMap.size > 0 && (
                        <Text
                            variant={'body2'}
                            color={'BLACK'}
                            style={{ marginTop: 4, visibility: disableUploadButton && !loading ? 'visible' : 'hidden' }}
                        >
                            Please assign all uploaded scans to a category
                        </Text>
                    )
                }
                AdditionalContent={
                    orderIsCancelResubmit && isAssignmentStep ? (
                        <OrderEditDeliveryDate
                            originalDeliveryEta={order.practice_dates.estimated_delivery_date}
                            originalDesignPreviewEta={
                                order.practice_dates.digital_design_preview_estimated_completion_date
                            }
                            items={orderItems}
                            hasPassedEtaValidation={orderEtaValidation}
                            setHasPassedEtaValidation={setOrderEtaValidation}
                            doctorId={order.doctor_preferences_id}
                            hasWaxup={order.fulfillment_workflow.configuration.waxup_required}
                            isPaused={!!order.hold_details?.hold_is_practice_managed_pause}
                            mailingAddressId={order.mailing_address_id}
                            // we use the case id from the original scan export because that's what the new order will be submitted with
                            // since the new scans are added post-creation (this property must match what is on the order at time of submit)
                            caseId={order.scan_export.threeshape_order_id}
                        />
                    ) : (
                        <></>
                    )
                }
                nextButtonProps={
                    scansMap.size > 0 && orderIsCancelResubmit && isAssignmentStep ? { variant: 'alert' } : undefined
                }
                BannerComponent={
                    orderStatus &&
                    orderActiveTaskType &&
                    orderEditMode && (
                        <NewOrderBanner
                            orderStatus={orderStatus}
                            orderActiveTaskType={orderActiveTaskType}
                            orderEditMode={orderEditMode}
                        />
                    )
                }
            >
                <LoadBlocker blocking={loading || processingScans || processingPayloads} loaderText={loaderText}>
                    {isAssignmentStep ? (
                        <FileAssignmentContainer>
                            <FileAssignmentLayout style={{ width: scansMap.size > 0 ? '100%' : '50%' }}>
                                <Text variant={'body2'} medium style={{ paddingBottom: 8 }}>
                                    Step 3: Choose the category for each photo or file
                                </Text>
                                <Text variant={'body2'} color={'GRAY'} style={{ paddingBottom: 8 }}>
                                    Please specify what these photos or files are for so that we can ensure the right
                                    specialists receive them:
                                </Text>
                                <AssignFilesList
                                    groupedFiles={groupedFiles}
                                    files={files}
                                    previewedFile={previewedFile}
                                    hasUploadedMultipleThreeOxzs={hasUploadedMultipleThreeOxzs}
                                    setPreviewedFile={setPreviewedFile}
                                    setLoading={setLoading}
                                    onRemoveFile={onRemoveFile}
                                />
                            </FileAssignmentLayout>
                            {scansMap.size > 0 && (
                                <ScanPreviewWrapper>
                                    <SelectScansModelViewer
                                        previewedFile={displayName}
                                        order={order}
                                        designQcConfig={{ appearance, setAppearance, modelRef, controlRef }}
                                        modelPayloadItems={scanPayloads}
                                    />
                                </ScanPreviewWrapper>
                            )}
                        </FileAssignmentContainer>
                    ) : (
                        <SelectFilesContainer data-test={'add-attachment-dialog'}>
                            <UploadFilesAction
                                order={order}
                                sessionId={sessionId}
                                setFiles={setUploadFiles}
                                loading={uploadFiles.length !== 0 && disableSelectionStepNext}
                            />
                        </SelectFilesContainer>
                    )}
                </LoadBlocker>
            </FullScreenWorkflowContainer>
        </>
    );
};
