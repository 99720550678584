import { FlossPalette, styled } from '../../theme';
import { Text } from '../Text';
import React from 'react';
import { v4 } from 'uuid';

const InputWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    '& .MuiInputBase-root': {
        padding: '8px',
    },
    '& .MuiInputBase-input': {
        padding: '12px 16px !important',
    },
    '& .Mui-disabled': {
        background: FlossPalette.DARK_TAN,
    },
}));

const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Inter Regular, Verdana, sans-serif',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 600,
}));

const HelpText = styled(Text)(({ theme }) => ({
    color: FlossPalette.GRAY,
    fontWeight: 400,
    marginLeft: theme.spacing(2),
}));

export interface FormFieldInputWrapperProps {
    /** The label for the input */
    label?: string;
    /** The error text for the input */
    errorText?: string;
    /** The help text for the input */
    helperText?: string;
    /** The placeholder text for the input */
    placeholder?: string;
    /** Whether the input is disabled */
    disabled?: boolean;
    /** Whether the help text should be displayed below the input */
    helpTextBottom?: boolean;
    /** The id of the input */
    id?: string;
}

export const FormFieldInputWrapper: React.FC<FormFieldInputWrapperProps> = ({
    label,
    helperText,
    errorText,
    helpTextBottom,
    id: idParam,
    placeholder,
    children,
    ..._rest
}) => {
    const [id] = React.useState<string>(idParam ?? v4);
    const helpTextContent = (
        <HelpText variant={'caption'} color={'GRAY'}>
            {helperText}
        </HelpText>
    );
    const errorTextContent = errorText ? (
        <HelpText variant={'caption'} color={'ATTENTION'}>
            {errorText}
        </HelpText>
    ) : undefined;

    const showTopHelpText = Boolean(helperText && !helpTextBottom);
    const showBottomHelpText = Boolean(helperText && helpTextBottom && !errorText);

    return (
        <InputWrapper>
            {label && <Label htmlFor={id}>{label}</Label>}
            {showTopHelpText && helpTextContent}
            {children}
            {showBottomHelpText && helpTextContent}
            {errorTextContent}
        </InputWrapper>
    );
};
