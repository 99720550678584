import { AnalyticsClient } from '../../../../../analytics/analyticsClient';
import { OrderAction, OrderActionTrackerIdMap } from '../../OrderActionsUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { ButtonVariant } from '@orthly/ui-primitives';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const CancelOrderButton: React.VFC<{
    order: LabsGqlOrder;
    hideButtonIcon?: boolean;
    buttonStyle?: { [key: string]: number | string };
    buttonVariant?: ButtonVariant;
    leftAlign?: boolean;
    setOpen: (open: boolean) => void;
}> = props => {
    const { order, hideButtonIcon, buttonStyle, buttonVariant = 'ghost-warning', leftAlign, setOpen } = props;
    const icon = 'TrashIcon';

    const openCancelOrderModal = React.useCallback(() => {
        AnalyticsClient.track('All - Cancel Order - Opened', {
            $groups: { order: order.id },
        });
        setOpen(true);
    }, [order.id, setOpen]);

    if (!order.can_cancel) {
        return null;
    }
    return (
        <Button
            disabled={!order.can_cancel}
            leftAlign={leftAlign}
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.CancelOrder]}
            style={buttonStyle}
            onClick={openCancelOrderModal}
            variant={buttonVariant}
            startIcon={hideButtonIcon ? undefined : icon}
        >
            Cancel order
        </Button>
    );
};
