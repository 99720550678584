import { isValidPatientName } from './PatientForm.util';
import { useDebouncedValue, SimpleSelect, SimpleDatePicker } from '@orthly/ui';
import { stylesFactory, Grid, TextField } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

interface SearchTextFieldProps {
    label?: string;
    hiddenLabel?: boolean;
    value: string;
    onChange: (val: string) => void;
    autoFocus?: boolean;
    getError?: (val: string) => string | undefined;
    placeholder?: string;
}

const useSearchTextFieldStyles = stylesFactory(() => ({
    hiddenLabelInputRoot: {
        paddingTop: 6.5,
        paddingBottom: 11.5,
    },
}));

// TODO: move to @orthly/ui
// text field that only calls onChange when user is done typing
// based on time since typing vs SimpleTextField on blur
const SearchTextField: React.FC<SearchTextFieldProps> = props => {
    const { label, hiddenLabel, value, onChange, autoFocus, getError, placeholder } = props;
    const classes = useSearchTextFieldStyles();
    const [internalValue, setInternalValue] = React.useState(value);
    const updateValue = useDebouncedValue(internalValue, 400);
    const [error, setError] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        onChange(updateValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateValue]);

    return (
        <TextField
            variant={'standard'}
            fullWidth
            hiddenLabel
            label={label}
            value={internalValue}
            onChange={e => {
                setInternalValue(e.target.value);
                setError(undefined);
            }}
            onBlur={e => {
                setError(undefined);
                setInternalValue(e.target.value);
                onChange(e.target.value);
                setError(getError?.(e.target.value));
            }}
            autoFocus={autoFocus}
            style={{ fontWeight: 500, alignItems: 'center', flexDirection: 'column' }}
            error={!!error}
            helperText={error}
            placeholder={placeholder}
            InputProps={
                hiddenLabel
                    ? {
                          classes: { root: classes.hiddenLabelInputRoot },
                      }
                    : undefined
            }
        />
    );
};

interface PatientNameFieldProps {
    search: string;
    setSearch: (search: string) => void;
    overrideValidationError: boolean;
    autoFocus?: boolean;
}

export const PatientNameField: React.VFC<PatientNameFieldProps> = props => {
    const { search, setSearch, overrideValidationError, autoFocus } = props;
    const onChange = (newSearch: string) => {
        setSearch(newSearch);
    };
    return (
        <SearchTextField
            hiddenLabel
            value={search}
            onChange={onChange}
            autoFocus={autoFocus}
            placeholder={'Jane Doe'}
            getError={val =>
                isValidPatientName(val) || overrideValidationError
                    ? undefined
                    : 'Please enter both the first and last names'
            }
        />
    );
};

interface PatientDataFieldsProps {
    gender?: string;
    setGender: (gender: string | undefined) => void;
    birthday?: string;
    setBirthday: (birthday: string) => void;
    birthdayError?: string;
    setBirthdayError: (error?: string) => void;
    // whether or not to auto open the birthday picker after gender is selected
    autoOpenBirthday?: boolean;
}

export const PatientDataFields: React.VFC<PatientDataFieldsProps> = props => {
    const { gender, setGender, birthday, setBirthday, birthdayError, setBirthdayError, autoOpenBirthday } = props;
    const [birthdayPickerOpen, setBirthdayPickerOpen] = React.useState<boolean>(false);

    const birthdayDate = React.useMemo(() => (birthday ? new Date(birthday) : null), [birthday]);

    return (
        <Grid container item direction={'row'} spacing={1}>
            <Grid item xs={6}>
                <SimpleSelect
                    label={'Gender'}
                    options={['Male', 'Female', 'Non-Binary', 'Other', 'Prefer not to say'].map(gender => ({
                        value: gender,
                        label: gender,
                    }))}
                    value={gender ?? undefined}
                    onChange={gender => {
                        setGender(gender);

                        if (autoOpenBirthday) {
                            setBirthdayPickerOpen(true);
                        }
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <SimpleDatePicker
                    label={'Birthday'}
                    value={birthdayDate}
                    onChange={date => {
                        const newDate = !date || isNaN(date.valueOf()) || date.valueOf() > Date.now() ? null : date;
                        const isEqual = birthdayDate?.valueOf() === newDate?.valueOf();

                        if (!isEqual && newDate) {
                            setBirthday(
                                moment(newDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString(),
                            );
                            setBirthdayError(undefined);
                        }
                    }}
                    slotProps={{
                        textField: {
                            onBlur: e => {
                                if (!moment(e.target.value).isValid()) {
                                    setBirthdayError(`We don't understand this date, please write Month / Day / Year`);
                                } else {
                                    setBirthdayError(undefined);
                                }
                            },
                        },
                    }}
                    errorText={birthdayError}
                    views={['year', 'month', 'day']}
                    openTo={'year'}
                    open={birthdayPickerOpen}
                    onOpen={() => setBirthdayPickerOpen(true)}
                    onClose={() => setBirthdayPickerOpen(false)}
                    slots={{
                        toolbar: undefined,
                    }}
                    disableFuture={true}
                />
            </Grid>
        </Grid>
    );
};
