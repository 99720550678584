import { SidebarChatSection } from '../../components/PracticeSidebarRight';
import { SidebarRightPageLayout } from '../../layouts/PageLayouts';
import { useChatFeatures } from '../chat/utils';
import { getCurrentStage, isValidUrl, useAbutmentUnns } from './ImplantReadiness.util';
import { PageTitle } from './component/PageTitle';
import { stages } from './stages';
import { AbutmentStage } from './stages/Abutment';
import { ImplantSystemStage } from './stages/ImplantSystem';
import type { Stage } from './stages/Stage';
import { useImplantReadinessAction } from './state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from './state/ImplantReadiness.selectors';
import { PracticeScreen } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import { LoadBlocker, useBeforeUnload } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

const Breadcrumbs: React.VFC<{ activePath: string }> = ({ activePath }) => {
    const history = useHistory();
    const { items, activeItemId, isGrouped } = useImplantReadinessPropSelector(['items', 'activeItemId', 'isGrouped']);
    const hasBack =
        activePath !== `/${PracticeScreen.scanbodies}/patient` &&
        activePath !== `/${PracticeScreen.scanbodies}/success`;

    // When items are grouped, clicking back goes to the previous stage
    // When items are ungrouped, clicking back goes to the previous item
    let activeItemIndex = items.findIndex(item => item.id === activeItemId);
    if (activeItemIndex === -1 || !isGrouped) {
        activeItemIndex = 0;
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center', height: 48 }}>
            {hasBack && (
                <Button
                    variant={'ghost'}
                    style={{ padding: 0, marginRight: 8 }}
                    onClick={() => history.go(-activeItemIndex - 1)}
                    startIcon={'ChevronLeft'}
                >
                    Back
                </Button>
            )}
            <Text variant={'body2'} color={'GRAY'}>
                Supplies /{' '}
                <Text variant={'body2'} color={'BLACK'} bold component={'span'}>
                    Implant Readiness
                </Text>
            </Text>
        </div>
    );
};

const SidebarRight: React.FC<{ name: string; disableFreeScanBody: boolean; openChat: () => void }> = props => {
    const {
        location: { pathname },
    } = useHistory();
    const Sidebar = getCurrentStage(pathname, stages)?.sidebar;
    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ height: '100%' }}>
            <Grid container style={{ flexGrow: 1, overflowY: 'auto' }}>
                {Sidebar && (
                    <Sidebar
                        name={props.name}
                        disableFreeScanBody={props.disableFreeScanBody}
                        openChat={props.openChat}
                    />
                )}
            </Grid>
            <SidebarChatSection />
        </Grid>
    );
};

export const ImplantReadinessRoot: React.VFC = () => {
    const history = useHistory();
    const { implantReadinessId, patient, items, activePath, isGrouped, activeItemId } = useImplantReadinessPropSelector(
        ['implantReadinessId', 'patient', 'items', 'activePath', 'isGrouped', 'activeItemId'],
    );
    const { value: disableFreeScanBody } = useFeatureFlag('disableFreeScanBody');
    const { openChat } = useChatFeatures();
    const setImplantReadinessId = useImplantReadinessAction('SET_IMPLANT_READINESS_ID');
    const name = patient?.first_name ?? '';
    const unns = _.sortBy(items.flatMap(item => CartItemV2Utils.getUniqueUNNs(item)));

    // For the stages where we only care about the implants (abutments)
    // We only show the abutmentUnns on screen
    const abutmentUnnOnlyStages: Stage[] = [ImplantSystemStage, AbutmentStage];
    const { abutmentUnns } = useAbutmentUnns();

    // Give a new id when Root loads
    React.useEffect(() => {
        if (!implantReadinessId) {
            setImplantReadinessId(uuidv4());
        }
    }, [implantReadinessId, setImplantReadinessId]);

    // Quick visual indicator when navigating through ungrouped unns
    const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
        if (!isGrouped) {
            setLoading(true);
            const timer = setTimeout(() => {
                setLoading(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [activeItemId, isGrouped]);

    // If the user has an invalid link we redirect them to the start of the flow
    React.useEffect(() => {
        if (activePath !== history.location.pathname) {
            history.push(activePath);
        } else if (activePath.startsWith(`/${PracticeScreen.scanbodies}`) && !isValidUrl(items, activePath, stages)) {
            history.push(`/${PracticeScreen.scanbodies}/patient`);
        }
    }, [patient, items, activePath, history]);

    // Warning if the user navigates off the page before completing the flow
    useBeforeUnload(event => {
        event.preventDefault();
    });

    return (
        <SidebarRightPageLayout
            mobileTitleHasBackButton
            mobileTitle={'Implant Readiness'}
            sidebarContent={
                <SidebarRight name={name} disableFreeScanBody={!!disableFreeScanBody} openChat={openChat} />
            }
        >
            <Grid container style={{ padding: '20px 56px', height: '100%' }} wrap={'nowrap'} direction={'column'}>
                <Breadcrumbs activePath={activePath} />
                <Switch location={history.location}>
                    {stages.map(stage => (
                        <Route exact key={stage.path} path={stage.path}>
                            {stage.pageTitle && (
                                <PageTitle
                                    {...stage.pageTitle({
                                        name,
                                        unns: abutmentUnnOnlyStages.includes(stage) ? abutmentUnns : unns,
                                    })}
                                />
                            )}
                            <LoadBlocker blocking={loading} loader={'circular'}>
                                <stage.component />
                            </LoadBlocker>
                        </Route>
                    ))}
                </Switch>
            </Grid>
        </SidebarRightPageLayout>
    );
};
