import { MobileTabs, TAB_BAR_HEIGHT } from './MobileTabs';
import {
    DOUBLE_TOOLBAR_TABLET_HEIGHT,
    TOOLBAR_TABLET_DOWN_HEIGHT,
    formatDataTestTitle,
    useScreenIsMd,
    useScreenIsSm,
} from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    Text,
    useScreenIsMobile,
    createStyles,
    Grid,
    makeStyles,
    ChevronLeft,
    Button,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';
import safeAreaInsets from 'safe-area-insets';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        pageWrapper: {
            height: '100%',
            paddingBottom: TAB_BAR_HEIGHT - safeAreaInsets.bottom,
            flexWrap: 'nowrap',
            overflowY: 'auto',
        },
        toolbar: {
            alignItems: 'center',
            flexWrap: 'nowrap',
        },
        toolbarContainer: {
            minHeight: TOOLBAR_TABLET_DOWN_HEIGHT,
            height: TOOLBAR_TABLET_DOWN_HEIGHT,
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            background: FlossPalette.TAN,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: theme.zIndex.appBar,
            '@media print': {
                display: 'none',
            },
        },
        toolbarContainerAsSecondaryHeader: {
            top: '57px',
        },
        titleAction: {
            marginRight: 24,
            display: 'flex',
            flexWrap: 'nowrap',
        },
        title: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        backButton: {
            marginLeft: 12,
            [theme.breakpoints.down('md')]: {
                marginLeft: 0,
            },
        },
    }),
);

interface MobileHeaderProps {
    title?: React.ReactNode;
    onBack?: () => void;
    titleAction?: React.ReactNode;
}

const DefaultHeader: React.FC<MobileHeaderProps> = ({ title, onBack, titleAction }) => {
    const classes = useStyles();
    const hasBackButton = !!onBack;
    const screenIsSm = useScreenIsSm();

    return (
        <Grid container className={classes.toolbar}>
            {onBack && (
                <Button
                    data-test={'mobile-header-back-button'}
                    variant={'ghost'}
                    startIcon={!screenIsSm ? 'ChevronLeft' : undefined}
                    className={classes.backButton}
                    onClick={() => onBack()}
                >
                    {screenIsSm ? <ChevronLeft /> : 'Back'}
                </Button>
            )}
            <Grid
                item
                className={classes.title}
                style={{ flexGrow: 1, padding: !hasBackButton && !titleAction ? '12px 16px' : '12px 12px 12px 0' }}
            >
                <Text
                    className={classes.title}
                    medium
                    data-test={typeof title === 'string' ? `mobile-header-${formatDataTestTitle(title)}` : undefined}
                    variant={!hasBackButton && !titleAction ? 'h5' : 'body2'}
                >
                    {title}
                </Text>
            </Grid>
            {titleAction && (
                <Grid item className={classes.titleAction}>
                    {titleAction}
                </Grid>
            )}
        </Grid>
    );
};

interface MobilePageLayoutProps {
    title?: React.ReactNode; // Optional only if customer header is provided
    titleHasBackButton?: boolean;
    titleAction?: React.ReactNode;
    hideHeader?: boolean;
    customHeaderComponent?: React.ReactNode; // if custom header is provided, hideHeader all other props are ignored except hide header
    hideTabs?: boolean;
    showHeaderAsSecondaryHeader?: boolean;
    // Custom back action. If set, implies `titleHasBackButton`.
    // The default action is to call `history.goBack()`.
    onBack?: () => void;
    containerRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export const MobilePageLayout: React.FC<MobilePageLayoutProps> = ({
    title,
    children,
    titleHasBackButton,
    titleAction,
    hideHeader,
    customHeaderComponent,
    hideTabs,
    showHeaderAsSecondaryHeader = false,
    onBack: onBackProp,
    containerRef,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const isSm = useScreenIsSm();
    const isMd = useScreenIsMd();
    const showHeader = (!hideHeader && isMobile) || showHeaderAsSecondaryHeader;
    const showTabs = !hideTabs;
    const history = useHistory();

    const onBack = onBackProp || titleHasBackButton ? onBackProp ?? (() => history.goBack()) : undefined;

    let paddingTop = 0;
    if (showHeader) {
        paddingTop =
            showHeaderAsSecondaryHeader && (!isMd || isSm) ? DOUBLE_TOOLBAR_TABLET_HEIGHT : TOOLBAR_TABLET_DOWN_HEIGHT;
    }

    return (
        <>
            <Grid
                ref={containerRef}
                container
                direction={'column'}
                className={classes.pageWrapper}
                style={{ paddingTop }}
            >
                {showHeader && (
                    <Grid
                        container
                        className={clsx(classes.toolbarContainer, {
                            [classes.toolbarContainerAsSecondaryHeader]: showHeaderAsSecondaryHeader,
                        })}
                    >
                        {!customHeaderComponent && (
                            <DefaultHeader title={title} onBack={onBack} titleAction={titleAction} />
                        )}
                        {customHeaderComponent}
                    </Grid>
                )}
                {children}
            </Grid>
            {showTabs && <MobileTabs />}
        </>
    );
};
