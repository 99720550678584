import type { TraineeFormFieldProps } from '../TrainingDataIntake.types';
import { WillAdaptPreferenceOptions } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { Text, CheckboxesGroup } from '@orthly/ui-primitives';
import React from 'react';

export const DentistWillAdaptPreferenceField: React.VFC<TraineeFormFieldProps> = props => {
    const { trainee, setTrainee } = useTrainee(props.traineeAtom);

    return (
        <CheckboxesGroup
            hasColumns
            variant={'compact'}
            values={Object.fromEntries(
                WillAdaptPreferenceOptions.map(preference => [preference, trainee.willAdapt === preference]),
            )}
            options={WillAdaptPreferenceOptions.map(preference => ({
                id: preference,
                label: (
                    <Text variant={'body2'} style={{ whiteSpace: 'nowrap' }}>
                        {preference}
                    </Text>
                ),
                value: preference,
            }))}
            handleChange={value => {
                const hasWillAdapt = trainee.willAdapt === value;
                const updatedWillAdapt = hasWillAdapt ? undefined : value;

                setTrainee(oldValue => ({
                    ...oldValue,
                    willAdapt: updatedWillAdapt,
                }));
            }}
        />
    );
};
