// Terminology is a bit confusing here. "Category" in V2 doesn't refer to categories in the previous version of order notes,
// but instead it refers to a new set of categories that are completely unrelated, whose behavior is described
// in CheckoutSummaryStep/CheckoutOrderNotesV2.tsx
import type { CheckoutItemV2, CheckoutState, SurgicalGuideCheckoutState } from '../checkout.state';
import { DoctorNotesCategoryV2 } from '../checkout.state';
import { aestheticCheckoutInitialState } from './aesthetic-checkout';
import { implantCheckoutInitialState } from './implant-checkout-scanbodies.reducer';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';

export type CheckoutItemV2WithImplant = Extract<
    CheckoutItemV2,
    { sku: LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge }
>;
export const isCheckoutItemV2WithImplant = (item: CheckoutItemV2): item is CheckoutItemV2WithImplant => {
    return (
        CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant) ||
        CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)
    );
};

type CheckoutItemV2WithImplantExclBridge = Extract<CheckoutItemV2, { sku: LabOrderItemSKUType.Implant }>;

export const isCheckoutItemV2WithImplantExclBridge = function (
    item: CheckoutItemV2,
): item is CheckoutItemV2WithImplantExclBridge {
    return CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant);
};

const surgicalGuideCheckoutInitialState: SurgicalGuideCheckoutState = {
    cbct_url: null,
};

export const initialState: CheckoutState = {
    step: 0,
    doctorNotesOpen: false,
    doctorNotesV2Category: DoctorNotesCategoryV2.None,
    implantCheckout: implantCheckoutInitialState,
    stepTwoScreen: 'smileStyle',
    items: [],
    removed_scan_items: [],
    bulkEditIds: [],
    itemScreenIndex: 0,
    existingOrderWarningDismissed: false,
    canRegroupBulkIds: [],
    refab: undefined,
    attachments: [],
    waxupState: { selected: false, tooltipDismissed: false },
    is_training_order: false,
    surgicalGuideCheckout: surgicalGuideCheckoutInitialState,
    aestheticCheckout: aestheticCheckoutInitialState,
    featureFlags: {},
    sla_modifier: undefined,
    promo_code_info: {
        has_opted_in_crown_ng_bundle: false,
    },
};
