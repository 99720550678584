import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlSetDoctorPreferencesCommand } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const UpdateDoctorPreferences_Mutation = graphql(`
    mutation UpdateDoctorPreferences($data: SetDoctorPreferencesCommand!) {
        setDoctorPreferences(data: $data) {
            id
        }
    }
`);

export const useUpdateDoctorPreferences = ({
    onSuccess,
    onFailure,
}: {
    onSuccess?: () => void;
    onFailure?: (message?: string) => void;
}) => {
    const [submitMtn, { error }] = useMutation(UpdateDoctorPreferences_Mutation);
    const { submit: updateDoctorPreferences, submitting } = useChangeSubmissionFn(submitMtn, {
        disableDefaultErrorMessage: true,
        onSuccess: () => {
            onSuccess?.();
        },
        onError: e => {
            onFailure?.(e?.message);
        },
    });

    const submit = React.useCallback(
        async (data: LabsGqlSetDoctorPreferencesCommand) => updateDoctorPreferences({ variables: { data } }),
        [updateDoctorPreferences],
    );

    if (error?.message) {
        if (/Cannot remove the last admin/.test(error.message)) {
            return {
                error: /administrative rights for/.test(error.message)
                    ? 'Cannot remove the last admin. Assign a new admin before removing administrative rights for this staff member.'
                    : 'Assign a new admin before removing this staff member.',
                submit,
                submitting,
            };
        }

        if (/Cannot remove the last doctor/.test(error.message)) {
            return {
                error: /practice doctor rights for/.test(error.message)
                    ? 'Cannot remove the last doctor. Assign a new doctor before removing practice doctor rights for this staff member.'
                    : 'Assign a new doctor before removing this staff member.',
                submit,
                submitting,
            };
        }
    }

    return { error: error?.message, submit, submitting };
};
