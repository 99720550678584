import { useQuery } from '@apollo/client';
import type { VeneerOrdersOverviewRootListSalesOrdersByIds_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import _ from 'lodash';
import React from 'react';

/**
 * Fetches all of the Orders IDs for a given search filter that are applicable to the practice.
 * This is generally used in conjunction with `usePracticeOrderListViewEntriesById` to actually fetch the results.
 */
export const VeneerPracticeOrderListViewIds_Query = graphql(`
    query VeneerPracticeOrderListViewIds_Query($args: OrderSearchPracticeSearchInput!) {
        practiceSearchOrderIds(args: $args) {
            id
        }
    }
`);

const VeneerOrdersOverviewRootListSalesOrdersByIds_Fragment = graphql(`
    fragment VeneerOrdersOverviewRootListSalesOrdersByIds_Fragment on OrderPracticeListViewEntry {
        id
        updated_at
        ...PracticeOrdersOverviewRootListSalesOrdersByIds_Fragment
    }
`);

const VeneerOrdersOverviewRootListSalesOrdersByIds_Query = graphql(`
    query VeneerOrdersOverviewRootListSalesOrdersByIds_Query($ids: [String!]!, $timezone_offset_minutes: Int!) {
        getOrderPracticeListViewEntriesByIds(ids: $ids) {
            ...VeneerOrdersOverviewRootListSalesOrdersByIds_Fragment
        }
    }
`);

/**
 * Utility hook for fetching orders for practice-facing list-view experiences (ie, data used in `OrderSummaryRow` or `PracticeOrderSummaryRow`).
 * This is centralized because Chairside and the practice portal uses it.
 *
 * Similar to useOrdersByIds, but uses a local cache instead of fragment queries on the Apollo cache.
 *
 * Note: Without updated_at timestamps on the Ids result, this hook will not be able to detect changes to the orders
 *  in the cache. The best we can do is invalidate the cache when the ID query is rerun.
 *
 * @param {string[]} visibleIds - The IDs of the orders that are currently displayed
 * @param {number} lastFetchedIdsAt - The unix timestamp of the last time the IDs query was run, for cache invalidation
 */
export function usePracticeOrderListViewEntriesById(visibleIds: string[], lastFetchedIdsAt: number) {
    // We key by the lastFetchedIdsAt to ensure we don't mix up results. Old results are cleaned out when we set the latest
    const cacheRef = React.useRef<{
        [lastFetchedIdsAt: string]: {
            [orderId: string]: VeneerOrdersOverviewRootListSalesOrdersByIds_FragmentFragment;
        };
    }>({});
    // The current key for the cache
    const activeCacheKey = lastFetchedIdsAt.toString();
    const neededIds = [...new Set(visibleIds)].filter(id => !cacheRef.current[activeCacheKey]?.[id]);

    const baseDate = new Date();
    const { data, loading, error } = useQuery(VeneerOrdersOverviewRootListSalesOrdersByIds_Query, {
        // this is ok because we are only passing neededIds, which we know are not in the cache
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        variables: { ids: neededIds, timezone_offset_minutes: baseDate.getTimezoneOffset() },
    });

    const fragmentData = getFragmentData(
        VeneerOrdersOverviewRootListSalesOrdersByIds_Fragment,
        data?.getOrderPracticeListViewEntriesByIds ?? [],
    );
    const dataById = _.keyBy(fragmentData, o => o.id);

    const orders = visibleIds.map(
        // check the latest query results, then the cache
        orderId => dataById[orderId] ?? cacheRef.current[activeCacheKey]?.[orderId] ?? undefined,
    );

    React.useEffect(() => {
        // This is in a useEffect to prevent problems when being imported from code that's in concurrent mode.
        // remove old cache keys, we just need the most recent
        cacheRef.current = { [activeCacheKey]: { ...cacheRef.current[activeCacheKey], ...dataById } };
    }, [activeCacheKey, dataById]);

    // refetch is not necessary on this
    return { orders, loading, error };
}
