import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';

const skipForNowAtom = atomWithStorage(
    'skip-add-phone-form',
    false,
    createJSONStorage(() => sessionStorage),
);
const alwaysSkipAtom = atomWithStorage(
    'skip-add-phone-form',
    false,
    createJSONStorage(() => localStorage),
);

export const useSetSkipPhoneNumberForm = () => useSetAtom(skipForNowAtom);
export const useSetAlwaysSkipAddPhoneNumberForm = () => useSetAtom(alwaysSkipAtom);

export const useSkipPhoneNumberForm = () => {
    const alwaysSkip = useAtomValue(alwaysSkipAtom);
    const skipForNow = useAtomValue(skipForNowAtom);
    return skipForNow || alwaysSkip;
};
