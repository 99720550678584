import type { CheckoutSidebarStepContainerProps } from './checkoutSidebarTypes';
import React from 'react';

export interface CheckoutSidebarCustomStepProps extends CheckoutSidebarStepContainerProps {
    children?: React.ReactNode;
    Component?: React.FC<CheckoutSidebarStepContainerProps>;
}

/**
 * This component renders a custom step.
 *
 *
 * - specify `props.children` if purely customizing representation, i.e. no need for new hooks
 * - specify `props.Component` if introducing extra data, i.e. adding new hooks.
 *   it will receive `props.variant` to indicate if it's the current step
 */
export const CheckoutSidebarCustomStep: React.FC<CheckoutSidebarCustomStepProps> = props => {
    if (!props.Component && !props.children) {
        return null;
    }
    const { Component = React.Fragment } = props;
    return <Component {...props} />;
};
