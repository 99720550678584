import { useAlignerCheckoutSelector } from '../../../../redux';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { selectAlignerCaseStage } from '../../state/aligners-checkout.selectors';
import { useCheckoutAction } from '../../state/checkout.actions';
import { AlignerCaseStage } from '../../state/reducers/aligners-checkout.types';
import type { ScanWithExistingOrder } from './ExistingOrder';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useGetDoctorPreferencesByIdQuery, useOrder, useSingleMailingAddressQuery } from '@orthly/graphql-react';
import { LabsGqlAlignerArch } from '@orthly/graphql-schema';
import { OrderItemArch } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, stylesFactory, ButtonBase, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    flowButton: {
        backgroundColor: FlossPalette.TAN,
        padding: '16px 24px',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 272,
        height: 272,
        '&>img': {
            flexGrow: 1,
            overflow: 'hidden',
            objectFit: 'scale-down',
            marginBottom: 16,
        },
    },
}));

interface AlignerCaseStageButtonProps {
    selected: boolean;
    onClick?: () => void;
    label: React.ReactNode;
    icon: string;
}

const AlignerCaseStageButton: React.FC<AlignerCaseStageButtonProps> = props => {
    const { onClick, label, icon } = props;
    const classes = useStyles();
    return (
        <ButtonBase className={classes.flowButton} onClick={onClick}>
            <img src={icon} alt={String(label)} />
            <Text variant={'body1'} medium>
                {label}
            </Text>
        </ButtonBase>
    );
};

const RETAINER_ARCH_BY_ALIGNER_ARCH: { [_ in LabsGqlAlignerArch]: OrderItemArch } = {
    [LabsGqlAlignerArch.Upper]: OrderItemArch.Upper,
    [LabsGqlAlignerArch.Lower]: OrderItemArch.Lower,
    [LabsGqlAlignerArch.Dual]: OrderItemArch.Dual,
};

const useStageFromQueryString = (): AlignerCaseStage | undefined => {
    const [autoStage, setAutoStage] = React.useState<AlignerCaseStage | undefined>();
    const history = useHistory();
    React.useEffect(() => {
        const search = new URLSearchParams(history.location.search);
        if (search.get('stage') === 'retainer') {
            setAutoStage(AlignerCaseStage.Retainer);
            // clear the scan id otherwise SET_SCAN gets double called and that clears all of aligner state
            history.replace({ ...history.location, search: `` });
        }
    }, [history]);
    return autoStage;
};

const useOnSelectRetainer = () => {
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);
    const setRetainer = useAlignerCheckoutAction('SET_RETAINER');
    return React.useCallback(
        (order?: LabsGqlLabOrderFragment) => {
            const aligner_case = order?.aligner_case;
            if (aligner_case) {
                setRetainer({
                    arch: RETAINER_ARCH_BY_ALIGNER_ARCH[aligner_case.aligner_arch],
                    // product wants 4 specifically to upsell
                    quantity: 4,
                    hasRestorations: false,
                    isFixedLingual: false,
                });
                dismissScreen();
            }
        },
        [setRetainer, dismissScreen],
    );
};

const useOnSelectRefinement = () => {
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);
    const setRefinement = useAlignerCheckoutAction('SET_REFINEMENT');
    return React.useCallback(() => {
        dismissScreen();
        setRefinement(true);
    }, [dismissScreen, setRefinement]);
};

const useOnPlaceNewOrder = () => {
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);
    const setExistingOrder = useAlignerCheckoutAction('SET_EXISTING_ORDER');
    return React.useCallback(() => {
        dismissScreen();
        setExistingOrder(undefined);
    }, [dismissScreen, setExistingOrder]);
};

export const AlignerCaseStageSelectionContent: React.FC<{ scanWithExisting: ScanWithExistingOrder }> = props => {
    const {
        scanWithExisting: { existingOrderMatch },
    } = props;
    const setAddress = useCheckoutAction('SET_ADDRESS');
    const setDoctor = useCheckoutAction('SET_DOCTOR');
    const setPatientInfo = useCheckoutAction('SET_PATIENT_INFO');
    const setExistingOrder = useAlignerCheckoutAction('SET_EXISTING_ORDER');
    const stage = useAlignerCheckoutSelector(selectAlignerCaseStage);

    // auto select stage based on query string
    const autoStage = useStageFromQueryString();
    const onSelectRetainer = useOnSelectRetainer();
    const onSelectRefinement = useOnSelectRefinement();
    const onPlaceNewOrder = useOnPlaceNewOrder();

    // auto fill form fields from existing order
    useSingleMailingAddressQuery({
        variables: { id: existingOrderMatch.order.mailing_address_id },
        onCompleted: ({ singleAddress }) => setAddress(singleAddress),
    });
    useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: existingOrderMatch.order.doctor_preferences_id },
        onCompleted: ({ preferences }) => setDoctor(preferences),
    });
    const { order } = useOrder(existingOrderMatch.order.id, {
        onCompleted: ({ lab_order: order }) => {
            if (order.patient.gender) {
                setPatientInfo({ type: 'gender', value: order.patient.gender });
            }
            setExistingOrder(order);
            if (autoStage === AlignerCaseStage.Retainer) {
                onSelectRetainer(order);
            }
        },
    });
    const aligner_case = order?.aligner_case;

    return (
        <LoadBlocker blocking={!aligner_case}>
            {autoStage ? (
                // don't show the buttons if it's automatic
                <Text variant={'h6'}>Looking up your aligner order...</Text>
            ) : (
                <Grid container direction={'row'} wrap={'wrap'} style={{ marginTop: 32, gap: 24 }} spacing={2}>
                    <Grid item>
                        <AlignerCaseStageButton
                            selected={stage === AlignerCaseStage.Refinement}
                            onClick={() => onSelectRefinement()}
                            label={'Refine current plan'}
                            icon={'/checkout/aligner/flow/order-refinement.svg'}
                        />
                    </Grid>
                    <Grid item>
                        <AlignerCaseStageButton
                            selected={stage === AlignerCaseStage.Retainer}
                            onClick={() => onSelectRetainer(order)}
                            label={'Order a retainer'}
                            icon={'/checkout/aligner/flow/order-retainer.svg'}
                        />
                    </Grid>
                    <Grid item>
                        <AlignerCaseStageButton
                            selected={false}
                            onClick={() => onPlaceNewOrder()}
                            label={'Place a new order'}
                            icon={'/checkout/aligner/flow/place-new-order.svg'}
                        />
                    </Grid>
                </Grid>
            )}
        </LoadBlocker>
    );
};
