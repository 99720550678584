import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { useSetSingleClinicalPreferenceMutation } from '@orthly/graphql-react';
import type { ItemMetafieldV2 } from '@orthly/items';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Icon, Medium, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    hoverRadio: { '&:hover': { color: FlossPalette.GRAY }, color: FlossPalette.LIGHT_GRAY },
    selectedRadio: { color: FlossPalette.PRIMARY_FOREGROUND },
}));

export const SingleClinicalPreferenceSelector: React.FC<{
    skuLabel: string;
    doctorId: string;
    field: ItemMetafieldV2;
    selectedValue?: string;
    notifySaving: (saving: boolean) => void;
}> = ({ skuLabel, doctorId, field, selectedValue, notifySaving }) => {
    const classes = useStyles();
    const isSelectedValueValid = !!field.options?.find(opt => opt.value === selectedValue);
    const populatedValue = isSelectedValueValid ? selectedValue : field.default_value;
    const [submitMtn] = useSetSingleClinicalPreferenceMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, any>(
        async (newValue: string) => {
            notifySaving(true);
            await submitMtn({
                variables: {
                    data: {
                        preference_set_id: doctorId,
                        custom_field_preference: {
                            field_id: field.id,
                            value: newValue,
                            display_name: field.label,
                        },
                    },
                },
            });
            AnalyticsClient.track('Practice - Clinical Preference Updated', {
                clinicalPreferencesSkuSection: skuLabel,
                fieldId: field.id,
                fieldLabel: field.label,
                oldOptionValue: String(populatedValue),
                oldOptionLabel: field.options?.find(opt => opt.value === populatedValue)?.label ?? 'Unknown',
                oldOptionIsDefault: populatedValue === field.default_value,
                newOptionValue: newValue,
                newOptionLabel: field.options?.find(opt => opt.value === newValue)?.label ?? 'Unknown',
                newOptionIsDefault: newValue === field.default_value,
            });
        },
        { onSuccess: () => notifySaving(false), onError: () => notifySaving(false) },
    );
    React.useEffect(() => {
        if (!!selectedValue && !isSelectedValueValid && typeof populatedValue === 'string' && !submitting) {
            void submit(populatedValue);
        }
    }, [selectedValue, isSelectedValueValid, populatedValue, submitting, submit]);
    return (
        <LoadBlocker blocking={submitting}>
            <Grid container spacing={4} style={{ borderBottom: `1px solid ${FlossPalette.DARK_TAN}`, marginTop: 24 }}>
                <Grid item xs={4}>
                    <Text variant={'body2'} medium>
                        {field.preference_label ?? field.label}
                    </Text>
                </Grid>
                <Grid item xs={8}>
                    {field.options?.map(opt => {
                        const isPopulated = populatedValue === opt.value;
                        const shouldPrefixDandyRecommended =
                            opt.value === field.default_value && field.default_value !== 'Dandy Recommended';
                        const isStandaloneDandyRecommended =
                            opt.value === field.default_value && field.default_value === 'Dandy Recommended';

                        return (
                            <Grid
                                container
                                wrap={'nowrap'}
                                alignItems={'center'}
                                key={opt.value}
                                onClick={() => submit(opt.value)}
                                style={{ cursor: 'pointer', marginBottom: 24 }}
                            >
                                <Grid item>
                                    <Icon
                                        icon={isPopulated ? 'RadioButtonCheckedIcon' : 'RadioButtonUnCheckedIcon'}
                                        className={isPopulated ? classes.selectedRadio : classes.hoverRadio}
                                        style={{
                                            marginBottom: -6,
                                            marginRight: 6,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text variant={'body2'} style={{ display: 'inline' }}>
                                        {shouldPrefixDandyRecommended && <Medium>Dandy Recommended: </Medium>}
                                        {isStandaloneDandyRecommended ? (
                                            <>
                                                <Medium>Dandy Recommended: </Medium>
                                                <span>Allow us to make an optimal per-case choice</span>
                                            </>
                                        ) : (
                                            opt.label
                                        )}
                                    </Text>
                                </Grid>
                            </Grid>
                        );
                    })}
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};
