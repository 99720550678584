import { useFirebasePreview } from '../../hooks/useFirebaseDownload';
import { useFirebaseFileDownload } from '../../hooks/useFirebaseFileDownload.graphql';
import { withErrorBoundary, SmallTrashCanIcon, Typography as OrthlyTypography } from '@orthly/ui';
import type { Theme, GridProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    createStyles,
    makeStyles,
    Avatar,
    Card,
    CardHeader,
    CardMedia,
    CircularProgress,
    Grid,
    IconButton,
    Typography,
    FolderIcon,
    DownloadIcon,
    Skeleton,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            marginTop: 20,
            width: '100%',
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            // Previously red[500] from https://mui.com/material-ui/customization/color/#color-palette
            // TODO: Get an on-theme color from design
            backgroundColor: '#f44336',
        },
        img: {
            width: '100%',
            height: 'auto',
            minHeight: 100,
        },
        trashIconButton: {
            padding: 0,
        },
        icon: {
            borderRadius: 36,
            padding: 4,
            height: 32,
            width: 32,
        },
        previewImg: {
            color: FlossPalette.GRAY,
            minWidth: '100%',
            minHeight: '100%',
            objectFit: 'cover',
            padding: 0,
        },
        deleteIconWrapper: {
            position: 'absolute',
            width: '100%',
            bottom: 4,
        },
        filePreview: {
            position: 'relative',
            height: 80,
            width: 80,
            borderRadius: 8,
            border: `2px solid ${FlossPalette.GRAY}`,
            background: FlossPalette.WHITE,
            overflow: 'hidden',
        },
        fileTypeCaption: {
            position: 'absolute',
            // top: 18,
            bottom: 'max(40%, 36px)',
            width: '100%',
            textAlign: 'center',
        },
    }),
);

export interface FilePreviewProps {
    src?: string;
    title?: string;
    downloadName?: string;
    uploadDate?: Date;
    showPreview?: boolean;
    imgProps?: React.ImgHTMLAttributes<any>;
    GridProps?: GridProps;
    CustomPreview?: React.ComponentType<{ src: string }>;
}

export type FilePreviewLayoutProps = FilePreviewProps & {
    previewUrl: ReturnType<typeof useFirebasePreview>;
    onClickDownload: ReturnType<typeof useFirebaseFileDownload>;
    loading: boolean;
};

export const FilePreviewLayout: React.FC<FilePreviewLayoutProps> = ({
    CustomPreview,
    loading,
    onClickDownload,
    previewUrl,
    showPreview,
    uploadDate,
    title,
}) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardHeader
                avatar={
                    loading ? (
                        <Skeleton variant={'circular'} width={40} height={40} />
                    ) : (
                        <Avatar aria-label={'recipe'} className={classes.avatar}>
                            {(title || 'P').substr(0, 1)}
                        </Avatar>
                    )
                }
                action={
                    loading ? null : (
                        <IconButton
                            aria-label={'download'}
                            onClick={onClickDownload.execute}
                            disabled={onClickDownload.loading}
                        >
                            {onClickDownload.loading ? <CircularProgress size={30} /> : <DownloadIcon />}
                        </IconButton>
                    )
                }
                title={loading ? <Skeleton height={6} width={'80%'} /> : title}
                subheader={
                    loading ? <Skeleton height={6} width={'40%'} /> : moment(uploadDate).format('MMM Do, YYYY h:mma')
                }
            />
            {previewUrl.error && (
                <Grid container style={{ padding: 20 }} justifyContent={'center'}>
                    <Typography variant={'body1'} color={'error'}>
                        Error: {previewUrl.error.message}
                    </Typography>
                </Grid>
            )}
            {/* eslint-disable-next-line no-nested-ternary */}
            {!showPreview || previewUrl.error ? null : loading || !previewUrl.result ? (
                <Skeleton variant={'rectangular'} className={classes.media} />
            ) : CustomPreview ? (
                <CustomPreview src={previewUrl.result} />
            ) : (
                <CardMedia className={classes.media} image={previewUrl.result} title={title} />
            )}
        </Card>
    );
};

const FilePreviewWrapped: React.FC<FilePreviewProps> = props => {
    const previewUrl = useFirebasePreview(props.showPreview ? props.src : undefined);
    const onClickDownload = useFirebaseFileDownload(props.src, props.downloadName || props.title);
    const loading = previewUrl.loading;
    if (!props.src) {
        return null;
    }
    return <FilePreviewLayout {...props} previewUrl={previewUrl} onClickDownload={onClickDownload} loading={loading} />;
};

export const FilePreview = withErrorBoundary(FilePreviewWrapped, {
    componentName: 'FilePreview',
    FallbackComponent: () => null,
});

interface UploadPreviewProps {
    onDelete: () => Promise<void>;
    disabled: boolean;
    downloadUrl: string;
    file: File;
    enlargeClickableArea?: boolean;
    wrapperProps?: GridProps;
}

export const UploadPreview: React.VFC<UploadPreviewProps> = props => {
    const classes = useStyles();

    const { onDelete, file, disabled, downloadUrl, enlargeClickableArea, wrapperProps } = props;

    const isImg = file.type.includes('image');
    const imgSrc = isImg ? downloadUrl : undefined;

    const fileExtension = file.name.split('.').slice(-1)[0];

    return (
        <Grid
            container
            direction={'column'}
            alignContent={'center'}
            onClick={enlargeClickableArea ? onDelete : undefined}
            {...wrapperProps}
            className={cx(classes.filePreview, wrapperProps?.className)}
        >
            {isImg ? (
                <img alt={file.name} src={imgSrc} className={classes.previewImg} />
            ) : (
                <>
                    <OrthlyTypography medium className={classes.fileTypeCaption} variant={'body2'} color={'WHITE'}>
                        .{fileExtension}
                    </OrthlyTypography>

                    <FolderIcon className={classes.previewImg} />
                </>
            )}

            <Grid container direction={'column'} alignContent={'center'} className={classes.deleteIconWrapper}>
                <IconButton
                    disabled={disabled}
                    onClick={!enlargeClickableArea ? onDelete : undefined}
                    className={classes.trashIconButton}
                >
                    <SmallTrashCanIcon
                        preserveAspectRatio={'xMidYMin'}
                        color={'primary'}
                        className={classes.icon}
                        style={{ color: FlossPalette.STAR_GRASS, background: FlossPalette.WHITE }}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};
