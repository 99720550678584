import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const FullDenturesSignatureTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'new-denture-reference',
                type: 'New Dentures & Overdentures',
                requirements: 'Using the reference denture workflow with patient’s existing appliance',
                price: getPrice('Signature Denture - New Denture, Reference Workflow $249'),
            },
            {
                id: 'new-denture-wax-rim',
                type: 'New Dentures & Overdentures',
                requirements: 'Using the wax bite rim workflow (includes wax bite rim and monolithic try-in)',
                price: getPrice('Signature Denture - New Denture, Wax Rim Workflow $349'),
            },
            {
                id: 'extra-set',
                type: 'Extra set of Signature Dentures',
                requirements: 'N/A',
                price: getPrice('Signature Denture - Extra Set $159'),
            },
            {
                id: 'immediate',
                type: 'Immediate Dentures',
                requirements: 'N/A',
                price: getPrice('Signature Denture - Immediate $229'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'full-dentures-signature'}
            title={'Signature dentures'}
            columns={[
                {
                    id: 'type',
                    title: 'Type',
                },
                {
                    id: 'requirements',
                    title: 'Requirements',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
