import { useAlignerCheckoutSelector, useCheckoutSelector } from '../../../redux/selectors';
import { AlignerCheckoutStep } from './aligners-checkout.types';
import type { CheckoutStepCompletionState } from './checkout.selectors';
import { checkoutPatientInfoStepWithExistingOrderComplete } from './checkout.selectors';
import { ALIGNER_STAGE_STEP_ORDER } from './reducers/aligners-checkout-steps';
import type { AlignerCheckoutState } from './reducers/aligners-checkout.types';
import { AlignerCaseStage } from './reducers/aligners-checkout.types';
import type { LabsGqlPendingAlignerCasePhotoMap as AlignerCasePhotoMap } from '@orthly/graphql-schema';
import { LabsGqlAlignerCasePhotoType as AlignerPhotoType } from '@orthly/graphql-schema';
import { OrderItemArch } from '@orthly/items';
import { getRetainerPriceInCentsV2 } from '@orthly/shared-types';

export const ImageTypeUploadOrder: AlignerPhotoType[] = [
    AlignerPhotoType.LeftBite,
    AlignerPhotoType.RightBite,
    AlignerPhotoType.FrontBite,
    AlignerPhotoType.MandibularOcclusion,
    AlignerPhotoType.MaxillaryOcclusion,
    AlignerPhotoType.FrontPortraitSmile,
    AlignerPhotoType.FrontPortraitNoSmile,
    AlignerPhotoType.SideView,
    AlignerPhotoType.PanoramicXray,
];

export const ImageTypeUploadRequired: { [K in AlignerPhotoType]-?: boolean } = {
    LeftBite: true,
    RightBite: true,
    FrontBite: true,
    MandibularOcclusion: true,
    MaxillaryOcclusion: true,
    FrontPortraitSmile: true,
    FrontPortraitNoSmile: false,
    SideView: false,
    PanoramicXray: false,
};

export function checkoutImageUploadReviewComplete(alignerImages?: AlignerCasePhotoMap): CheckoutStepCompletionState {
    if (!alignerImages || ImageTypeUploadOrder.find(type => ImageTypeUploadRequired[type] && !alignerImages[type])) {
        return { complete: false, reason: 'All required aligner images have not been uploaded.' };
    }
    return { complete: true };
}

function checkoutEstimatingComplete(state: AlignerCheckoutState): CheckoutStepCompletionState {
    if (!state.estimate) {
        return { complete: false, reason: 'The estimate has not been calculated.' };
    }
    return { complete: true };
}

function checkoutQRCodeNextEnabled(state: AlignerCheckoutState): CheckoutStepCompletionState {
    if (state.uploadedImageGCSPaths.length === 0 && !state.deviceUpload) {
        return { complete: false, reason: 'Upload images via mobile or your laptop' };
    }
    return { complete: true };
}

function archSelectionComplete(state: AlignerCheckoutState): CheckoutStepCompletionState {
    if (!state.alignerArch) {
        return { complete: false, reason: 'Aligner arch has not been selected.' };
    }

    if (!state.alignerTreatmentArea) {
        return { complete: false, reason: 'Aligner treatment area has not been selected.' };
    }

    return { complete: true };
}

function movementRestrictedTeethSelectionComplete(state: AlignerCheckoutState): CheckoutStepCompletionState {
    const { movementRestrictedTeeth } = state;
    if (movementRestrictedTeeth === undefined) {
        return { complete: false, reason: 'Please select a movement restriction option.' };
    }
    if (movementRestrictedTeeth && movementRestrictedTeeth.length < 1) {
        return { complete: false, reason: 'Please select at least one restricted tooth.' };
    }
    return { complete: true };
}

function attachmentRestrictedTeethSelectionComplete(state: AlignerCheckoutState): CheckoutStepCompletionState {
    const { attachmentRestrictedTeeth } = state;
    if (attachmentRestrictedTeeth === undefined) {
        return { complete: false, reason: 'Please select an attachment restriction option.' };
    }
    if (attachmentRestrictedTeeth && attachmentRestrictedTeeth.length < 1) {
        return { complete: false, reason: 'Please select at least one restricted tooth.' };
    }
    return { complete: true };
}

function extractionTeethSelectionComplete(state: AlignerCheckoutState): CheckoutStepCompletionState {
    const { extractionTeeth } = state;
    if (extractionTeeth === undefined) {
        return { complete: false, reason: 'Please select an extraction option.' };
    }
    if (extractionTeeth && extractionTeeth.length < 1) {
        return { complete: false, reason: 'Please select at least one tooth to be extracted.' };
    }
    return { complete: true };
}

function crowdingResolutionComplete(state: AlignerCheckoutState): CheckoutStepCompletionState {
    const { crowdingResolution } = state;
    if (!crowdingResolution?.expansion) {
        return { complete: false, reason: 'Please select an option for expansion.' };
    }
    if (!crowdingResolution?.proclination) {
        return { complete: false, reason: 'Please select an option for proclination.' };
    }
    if (!crowdingResolution?.ipr) {
        return { complete: false, reason: 'Please select an option for IPR.' };
    }
    return { complete: true };
}

function spacingSelectionComplete(state: AlignerCheckoutState): CheckoutStepCompletionState {
    const { interproximalSpaceSizes } = state;
    if (interproximalSpaceSizes === undefined) {
        return { complete: false, reason: 'Please select a spacing option.' };
    }
    return { complete: true };
}

export function checkoutCurrentAlignerStepCompletionState(state: AlignerCheckoutState): CheckoutStepCompletionState {
    switch (state.step) {
        // Validation done on Checkout State
        case AlignerCheckoutStep.PatientInformation:
            return { complete: true };
        case AlignerCheckoutStep.ImageUploadLink:
            return checkoutQRCodeNextEnabled(state);
        case AlignerCheckoutStep.ImageUploadReview:
            return checkoutImageUploadReviewComplete(state.alignerImages);
        case AlignerCheckoutStep.ArchSelection:
            return archSelectionComplete(state);
        case AlignerCheckoutStep.MovementRestrictedTeethSelection:
            return movementRestrictedTeethSelectionComplete(state);
        case AlignerCheckoutStep.AttachmentRestrictedTeethSelection:
            return attachmentRestrictedTeethSelectionComplete(state);
        case AlignerCheckoutStep.ExtractionTeethSelection:
            return extractionTeethSelectionComplete(state);
        case AlignerCheckoutStep.SpacingSelection:
            return spacingSelectionComplete(state);
        case AlignerCheckoutStep.CrowdingResolution:
            return crowdingResolutionComplete(state);
        case AlignerCheckoutStep.BiteConcerns:
            return { complete: true };
        case AlignerCheckoutStep.Estimating:
            return checkoutEstimatingComplete(state);
        case AlignerCheckoutStep.EstimateReview:
            return { complete: true };
        case AlignerCheckoutStep.Summary:
            return { complete: true };
        case AlignerCheckoutStep.Rejection:
            return { complete: true };
        case AlignerCheckoutStep.RetainerArchSelection:
            return { complete: true };
        case AlignerCheckoutStep.RetainerAdditionalInfo:
            return { complete: true };
    }
}

export function useAlignerCheckoutStepCompletionState(): CheckoutStepCompletionState {
    const patientDoctorComplete = useCheckoutSelector(checkoutPatientInfoStepWithExistingOrderComplete);
    const alignerStepComplete = useAlignerCheckoutSelector(checkoutCurrentAlignerStepCompletionState);
    const alignerStep = useAlignerCheckoutSelector(s => s.step);
    if (alignerStep === AlignerCheckoutStep.PatientInformation) {
        return patientDoctorComplete;
    }
    return alignerStepComplete;
}

interface CheckoutStepInfo {
    isActiveStep: boolean;
    isComplete: boolean;
}

export const selectAlignerCaseStage = (state: AlignerCheckoutState) => {
    if (state.rejection) {
        return AlignerCaseStage.Rejection;
    }
    if (state.retainer) {
        return AlignerCaseStage.Retainer;
    }
    if (state.isRefinement) {
        return AlignerCaseStage.Refinement;
    }
    return AlignerCaseStage.InitialAligner;
};

export function useAlignerCheckoutStepInfo(step: AlignerCheckoutStep): CheckoutStepInfo {
    const stage = useAlignerCheckoutSelector(selectAlignerCaseStage);
    const activeStep = useAlignerCheckoutSelector(s => s.step);
    const activeStepOrder = ALIGNER_STAGE_STEP_ORDER[stage][activeStep] ?? -1;
    const stepOrder = ALIGNER_STAGE_STEP_ORDER[stage][step] ?? -1;
    const isActiveStep = activeStep === step;
    const isComplete = activeStepOrder > stepOrder;
    if (isActiveStep) {
        if (activeStepOrder < 0) {
            console.error(`Invalid step ${activeStep} for aligner case stage ${stage}`);
        }
        if (stepOrder < 0) {
            console.error(`Invalid step ${step} for aligner case stage ${stage}`);
        }
    }
    return { isActiveStep, isComplete };
}

export const selectAlignerRetainerPriceByQuantity =
    (state: AlignerCheckoutState) =>
    (quantity: number): number => {
        const arch = state.retainer?.arch ?? state.existingOrder?.aligner_case?.aligner_arch ?? OrderItemArch.Dual;
        return getRetainerPriceInCentsV2(arch, quantity, state.retainerPricing?.freeSetsRemaining ?? 0);
    };

export const selectAlignerRetainerPrice = (state: AlignerCheckoutState): number => {
    const quantity = state.retainer?.quantity ?? 1;
    return selectAlignerRetainerPriceByQuantity(state)(quantity);
};
