import type { MinimalScanEntry, ScanUpload } from '../../AttachScans/utils/Reloader.util';
import { ScanSource } from '../../AttachScans/utils/Reloader.util';
import type { ModelPayloadItem } from '@orthly/dentin';
import { loadModel } from '@orthly/dentin';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import _ from 'lodash';

const getModelPayloadItemFromScan = async (scanEntry: MinimalScanEntry, name: string) => {
    if (!scanEntry.buffer) {
        return;
    }
    return {
        name,
        url: scanEntry.file_name,
        type: LabsGqlOrderDesignScanType.Scans,
        model: await loadModel({
            buffer: scanEntry.buffer,
            filePath: scanEntry.file_name ?? '',
            useExperimentalPlyLoader: true,
            shouldCalculateUVs: true,
        }),
        isRestorative: false,
        isPrintedModel: false,
        path: scanEntry.file_name ?? '',
    };
};

export const getModelPayloadItemsFromScans = async (
    scanUploads: [string, ScanUpload][],
): Promise<ModelPayloadItem[]> => {
    return _.compact(
        await Promise.all(
            scanUploads.map(async ([fileName, scanUpload]) => {
                if (scanUpload.source === ScanSource.SingleScan) {
                    return await getModelPayloadItemFromScan(scanUpload.scan, fileName);
                }

                return _.compact(
                    await Promise.all(
                        scanUpload.scans.map(async scan => {
                            return await getModelPayloadItemFromScan(scan, `${fileName}-${scan.file_name ?? ''}`);
                        }),
                    ),
                );
            }),
        ),
    ).flat();
};

export const getPreviewedFileDisplayName = (previewedFile: string) => {
    const previewedFileParts = previewedFile.split('.');

    if (previewedFileParts.length <= 2) {
        return previewedFileParts[0] ?? '';
    }

    return `${previewedFileParts[0]} / ${previewedFileParts[1]?.split('-').slice(1)}`;
};
