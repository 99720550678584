import type { Stage } from './stages/Stage';
import { useImplantReadinessPropSelector } from './state/ImplantReadiness.selectors';
import type { ImplantReadinessUrlData } from './state/ImplantReadiness.types';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { ICartImplantBridgeItem, ICartImplantItem, ToothNumber } from '@orthly/items';
import _ from 'lodash';
import { matchPath } from 'react-router-dom';

export const useAbutmentUnns = (): { abutmentUnns: ToothNumber[] } => {
    const { items, isGrouped, activeItemId } = useImplantReadinessPropSelector(['items', 'isGrouped', 'activeItemId']);
    const activeItem = items.find(item => item.id === activeItemId);

    // Implant Bridges have abutments that are inherently grouped
    const groupedAbutmentUnns = items.flatMap(item => {
        return CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)
            ? item.implants.map(implant => implant.unn)
            : [item.unit.unn];
    });
    // Only Implants can have ungrouped abutments (this is not supported for Implant Bridges)
    const unGroupedAbutmentUnn =
        activeItem && CartItemV2Utils.itemIsType(activeItem, LabOrderItemSKUType.Implant)
            ? [activeItem.unit.unn]
            : groupedAbutmentUnns;

    const sortedAbutmentUnns = _.sortBy(!isGrouped ? unGroupedAbutmentUnn : groupedAbutmentUnns);
    return { abutmentUnns: sortedAbutmentUnns };
};

export const getCurrentStage = (activePath: string, stages: Stage[]): Stage | undefined => {
    return stages.find(stage => {
        const match = matchPath<ImplantReadinessUrlData>(activePath, {
            path: stage.path,
            exact: true,
        });
        return stage.path === match?.path;
    });
};

export const isValidUrl = (
    items: (ICartImplantItem | ICartImplantBridgeItem)[],
    activePath: string,
    stages: Stage[],
): boolean => {
    return stages.some(stage => {
        const match = matchPath<ImplantReadinessUrlData>(activePath, {
            path: stage.path,
            exact: true,
        });
        if (!match) {
            return false;
        }
        return match.params?.id ? items.some(item => item.id === match?.params.id) : true;
    });
};
