import { styled } from '@orthly/ui-primitives';

export const FieldGroup = styled('div')(({ theme }) => ({
    display: 'grid',
    width: '100%',
    gap: '24px',
    gridTemplateColumns: 'repeat(2, 1fr)',
    [theme.breakpoints.only('lg')]: {
        gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
    },
}));

export const FullWidthField = styled('div')({
    gridColumn: '1/-1',
});
