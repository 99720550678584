import { useSetScreen } from '../../../../utils/useSetScreen';
import { TryInFeedbackReturnInstructionsContent } from '../../../try_in_feedback/screens/TryInFeedbackReturnInstructions';
import { TryInFeedbackUtils } from '../../../try_in_feedback/utils/TryInFeedback.util';
import { PrintOrderReturnLabel } from '../../components/PrintOrderReturnLabel';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { usePendingReturnForOrderQuery } from '@orthly/graphql-react';
import { LabsGqlOrderReturnStatus } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { UuidUtils } from '@orthly/runtime-utils';
import { ActionCard, RootActionDialog } from '@orthly/ui';
import { Button, SKUDentureIcon, TruckIconOutlined } from '@orthly/ui-primitives';
import React from 'react';

interface OrderDetailTryInFeedbackReturnAlertProps {
    orderId: string;
    itemLabel: string;
}

const OrderDetailTryInFeedbackReturnAlert: React.VFC<OrderDetailTryInFeedbackReturnAlertProps> = ({
    orderId,
    itemLabel,
}) => {
    const [instructionsOpen, setInstructionsOpen] = React.useState<boolean>(false);
    const { data: pendingReturnData } = usePendingReturnForOrderQuery({
        skip: !UuidUtils.isUUID(orderId),
        variables: { orderId },
    });
    const pendingReturn = pendingReturnData?.pendingReturnForOrder;

    if (!pendingReturn || pendingReturn.status !== LabsGqlOrderReturnStatus.NotShipped) {
        return null;
    }

    const title = `The ${itemLabel} must be returned to continue with your case`;

    return (
        <ActionCard
            variant={'info'}
            title={title}
            subtitle={''}
            primaryAction={
                <PrintOrderReturnLabel
                    labelUrl={pendingReturn.label_url}
                    status={pendingReturn.status}
                    title={'Print return label'}
                    analyticsLocation={'Order Details'}
                />
            }
            secondaryAction={
                <RootActionDialog
                    open={instructionsOpen}
                    setOpen={setInstructionsOpen}
                    loading={false}
                    title={title}
                    showCloseButton={true}
                    content={<TryInFeedbackReturnInstructionsContent />}
                    CustomButton={({ onClick }) => (
                        <Button variant={'secondary'} onClick={onClick}>
                            View return instructions
                        </Button>
                    )}
                />
            }
            IconComponent={TruckIconOutlined}
        />
    );
};

interface OrderDetailTryInFeedbackAlertProps {
    order: Pick<LabsGqlLabOrderFragment, 'id' | 'items_v2' | 'can_submit_tryin_feedback' | 'try_in_feedback'>;
}

export const OrderDetailTryInFeedbackAlert: React.VFC<OrderDetailTryInFeedbackAlertProps> = ({ order }) => {
    const setScreen = useSetScreen();

    const items = OrderItemV2Utils.parseItems(order.items_v2);
    const applicableItem = items.find(item => CartItemV2Utils.isItemEligibleForTryInFeedback(item));
    const itemLabel = applicableItem ? TryInFeedbackUtils.getItemTypeTitle(applicableItem).toLowerCase() : 'try-in';

    if (!order.can_submit_tryin_feedback) {
        // if not enabled because already submitted, we display a message if there's a pending return with link to print
        if (order.try_in_feedback) {
            return <OrderDetailTryInFeedbackReturnAlert orderId={order.id} itemLabel={itemLabel} />;
        }

        // otherwise, we show nothing since the order is not eligible for try-in feedback
        return null;
    }

    return (
        <ActionCard
            variant={'info'}
            title={`Provide feedback on the ${itemLabel}'s fit & aesthetics and decide next steps`}
            subtitle={''}
            primaryAction={{
                text: 'Provide feedback',
                onClick: () => setScreen(PracticeScreen.try_in_feedback, `/${order.id}`)(),
            }}
            IconComponent={SKUDentureIcon}
        />
    );
};
