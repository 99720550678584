import { useGetDoctorPreferencesByIdQuery } from '@orthly/graphql-react';

export const useGetClinicalPrefMapForDoctor = (doctorId: string) => {
    const { loading, data } = useGetDoctorPreferencesByIdQuery({ variables: { doctor_id: doctorId } });
    const prefArr = data?.preferences?.custom_field_preferences ?? [];
    const prefMap = prefArr.reduce((prefMap: Record<string, string>, pref) => {
        prefMap[pref.field_id] = pref.value.toString();
        return prefMap;
    }, {});
    return { loading, prefMap };
};
