import type { DentureCheckoutState } from '../../state/reducers/dentures-checkout.types';
import { WaxRimSummary, CoreSummaryNew } from './DentureSummary';
import { LabsGqlDenturesSingleFulfillmentArchType } from '@orthly/graphql-schema';
import { DentureType, isTeethShade } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

const is_valid_arch_type = (type: string): type is LabsGqlDenturesSingleFulfillmentArchType => {
    return Object.values<string>(LabsGqlDenturesSingleFulfillmentArchType).includes(type);
};

interface DentureSummaryForCheckoutProps {
    fields: DentureCheckoutState['fields'];
}

const WaxRimSummaryForCheckout: React.VFC<DentureSummaryForCheckoutProps> = ({ fields: { dentureArch } }) => {
    if (!dentureArch) {
        return null;
    }
    return <WaxRimSummary arch={dentureArch} />;
};

const CoreSummaryForCheckout: React.VFC<DentureSummaryForCheckoutProps> = ({ fields }) => {
    const { dentureType, dentureArch, dentureOptions, numberOfSpares } = fields;
    if (!dentureType || !is_valid_arch_type(dentureType) || !dentureArch || !dentureOptions) {
        return null;
    }
    const { fabrication, teethQuality } = dentureOptions;
    const baseShade = dentureOptions.shades?.find(shade => shade.name === 'base')?.value;
    const tissueShade = dentureOptions.shades?.find(shade => shade.name === 'tissue')?.value;
    // using _.every([a, b ...]) instead of (a && b && ...) to pacify linter
    if (!_.every([fabrication, teethQuality, baseShade, tissueShade, fields.dentureStyle])) {
        return null;
    }
    return (
        <CoreSummaryNew
            dentureType={dentureType}
            dentureArch={dentureArch}
            fabricationMethod={fabrication}
            teethQuality={teethQuality}
            baseShade={isTeethShade(baseShade) ? baseShade : undefined}
            tissueShade={isTeethShade(tissueShade) ? tissueShade : undefined}
            smileStyle={fields.dentureStyle}
            numberOfSpares={numberOfSpares}
        />
    );
};

export const DentureSummaryForCheckout: React.VFC<DentureSummaryForCheckoutProps> = ({ fields }) => {
    if (fields.dentureType === DentureType.WaxRim) {
        return <WaxRimSummaryForCheckout fields={fields} />;
    }
    return <CoreSummaryForCheckout fields={fields} />;
};
