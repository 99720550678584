import type { DoctorVars } from '../../state/usePracticeStaffControls';
import { smsLabel, emailLabel } from './NotificationMethodToggle';
import type { NotificationNames, NotificationPreferencesNames } from './Notifications.types';
import type { NotificationSettingsProps } from './ProfileNotifications';
import { ManageForwardingSettings } from './forwarding-settings/ManageForwardingSettings';
import { ManageReceivingSettings } from './forwarding-settings/ManageReceivingSettings';
import type { ApolloQueryResult } from '@apollo/client';
import { DEFAULT_TOAST_TIMEOUT, Toast } from '@orthly/dentin';
import type {
    LabsGqlDoctorPreferencesFragment,
    LabsGqlGetDoctorPreferencesByTokenQuery,
} from '@orthly/graphql-operations';
import { useAllPracticeEmployeesPreferences } from '@orthly/graphql-react';
import type { CustomQFComponentProps } from '@orthly/ui';
import { QuickForm, AutoSave, SimpleCheckbox } from '@orthly/ui';
import { useScreenIsMobile, Grid, Text } from '@orthly/ui-primitives';
import { Tooltip } from '@orthly/ui-primitives';
import type { FormikProps } from 'formik';
import React from 'react';
import { z } from 'zod';

interface NotificationMethodSelectFieldProps {
    smsFieldName:
        | NotificationPreferencesNames.status_change_sms
        | NotificationPreferencesNames.review_required_sms
        | NotificationPreferencesNames.order_tracking_sms
        | NotificationPreferencesNames.communication_sms;
    emailFieldName:
        | NotificationPreferencesNames.status_change_email
        | NotificationPreferencesNames.review_required_email
        | NotificationPreferencesNames.order_tracking_email
        | NotificationPreferencesNames.communication_email;
    required?: boolean;
    notificationName: NotificationNames;
    senders?: LabsGqlDoctorPreferencesFragment[];
    receivers?: LabsGqlDoctorPreferencesFragment[];
    refetch?: () => Promise<ApolloQueryResult<LabsGqlGetDoctorPreferencesByTokenQuery>>;
    disableEmailCheckbox?: boolean;
    disableSmsCheckbox?: boolean;
}

type NotificationMethodSelectFormProps = NotificationSettingsProps & NotificationMethodSelectFieldProps;
type NotificationsMethodSelectFieldProps = CustomQFComponentProps<{}, DoctorVars>;

interface NotificationsToastProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const RequiredFieldWarningToast: React.FC<NotificationsToastProps> = ({ open, setOpen }) => {
    return (
        <Toast
            headline={'At least 1 option must stay selected'}
            description={
                'To avoid delays, we require this notification to be enabled. Please select at least 1 option, or change assignee.'
            }
            open={open}
            onDismiss={() => setOpen(false)}
            variant={'warning'}
            timeout={DEFAULT_TOAST_TIMEOUT}
        />
    );
};

// eslint-disable-next-line max-lines-per-function
export const NotificationMethodSelectForm: React.FC<NotificationMethodSelectFormProps> = props => {
    const {
        smsFieldName,
        emailFieldName,
        employeePrefs,
        submit,
        loading,
        required,
        onChange,
        setIsSaved,
        notificationName,
        senders,
        receivers,
        refetch,
        disableEmailCheckbox,
        disableSmsCheckbox,
    } = props;
    const isMobile = useScreenIsMobile();
    const { preferences } = useAllPracticeEmployeesPreferences();
    const prefsToShareWith = preferences.filter(pref => pref.id !== employeePrefs.id);
    const [disableSubmit, setDisableSubmit] = React.useState(false);
    const [showWarning, setShowWarning] = React.useState(false);
    const [showSuccess, setShowSuccess] = React.useState(false);
    const formRef = React.useRef<FormikProps<{}> | undefined>();
    const validateRequiredFields = (smsValue?: boolean | null, emailValue?: boolean | null) => {
        if (required && !(smsValue || emailValue)) {
            setDisableSubmit(true);
            return false;
        }
        setDisableSubmit(false);
        return true;
    };
    const setChecked = (
        otherField: NotificationPreferencesNames,
        value: boolean,
        fieldProps: NotificationsMethodSelectFieldProps,
    ) => {
        const { field, form } = fieldProps;
        // if the notification field is required and we attempt to uncheck the only currently checked method,
        // show the warning message and don't allow the user to uncheck
        if (required && !value && !form.values[otherField]) {
            setShowWarning(true);
        } else {
            form.setFieldValue(field.name, value);
        }
    };
    const receivingNotifications =
        employeePrefs.grouped_notification_preferences[smsFieldName] ||
        employeePrefs.grouped_notification_preferences[emailFieldName];
    const hasSenders = senders && senders.length > 0;

    return (
        <>
            {!isMobile && (
                <Text variant={'body2'} medium style={{ paddingBottom: 8, paddingTop: 32 }}>
                    How do you want to receive these notifications?
                </Text>
            )}
            <QuickForm<DoctorVars>
                onRender={props => {
                    formRef.current = props.formikProps;
                }}
                resetOnInitialValueChange={true}
                containerStyle={{ height: 'fit-content', paddingBottom: required ? 8 : 16 }}
                fields={{
                    preference_set_id: { type: 'text', hidden: true },
                    [emailFieldName]: {
                        type: 'custom',
                        layout: { xs: isMobile ? 12 : undefined },
                        component: ({ field, form }: NotificationsMethodSelectFieldProps) =>
                            disableEmailCheckbox ? (
                                <Tooltip
                                    title={'Set your email address in account settings to receive email notifications.'}
                                >
                                    <div>
                                        <SimpleCheckbox
                                            CheckboxProps={{
                                                color: 'secondary',
                                                style: {
                                                    marginLeft: 0,
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    marginRight: -8,
                                                },
                                                disabled: true,
                                            }}
                                            checked={!!field.value}
                                            label={emailLabel}
                                            setChecked={value => {
                                                setChecked(smsFieldName, value, { field, form });
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <SimpleCheckbox
                                    CheckboxProps={{
                                        color: 'secondary',
                                        style: { marginLeft: 0, marginTop: 0, marginBottom: 0, marginRight: -8 },
                                        disabled: false,
                                    }}
                                    checked={!!field.value}
                                    label={emailLabel}
                                    setChecked={value => {
                                        setChecked(smsFieldName, value, { field, form });
                                    }}
                                />
                            ),
                        validation: z.boolean(),
                    },
                    [smsFieldName]: {
                        type: 'custom',
                        layout: { xs: isMobile ? 12 : undefined },
                        component: ({ field, form }: NotificationsMethodSelectFieldProps) =>
                            disableSmsCheckbox ? (
                                <Tooltip
                                    title={'Set your phone number in account settings to receive sms notifications.'}
                                >
                                    <div>
                                        <SimpleCheckbox
                                            CheckboxProps={{
                                                style: {
                                                    marginLeft: 0,
                                                    marginTop: 0,
                                                    marginBottom: 0,
                                                    marginRight: -8,
                                                },
                                                color: 'secondary',
                                                disabled: true,
                                            }}
                                            checked={!!field.value}
                                            label={smsLabel}
                                            setChecked={value => {
                                                setChecked(emailFieldName, value, { field, form });
                                            }}
                                        />
                                    </div>
                                </Tooltip>
                            ) : (
                                <SimpleCheckbox
                                    CheckboxProps={{
                                        style: { marginLeft: 0, marginTop: 0, marginBottom: 0, marginRight: -8 },
                                        color: 'secondary',
                                        disabled: false,
                                    }}
                                    checked={!!field.value}
                                    label={smsLabel}
                                    setChecked={value => {
                                        setChecked(emailFieldName, value, { field, form });
                                    }}
                                />
                            ),
                        validation: z.boolean(),
                    },
                }}
                initialValues={{
                    preference_set_id: employeePrefs.id,
                    [smsFieldName]: employeePrefs.grouped_notification_preferences[smsFieldName] ?? false,
                    [emailFieldName]: employeePrefs.grouped_notification_preferences[emailFieldName] ?? false,
                }}
                disabled={loading || disableSubmit}
                onSubmit={values => {
                    const validForm = validateRequiredFields(values[smsFieldName], values[emailFieldName]);
                    if (validForm) {
                        submit(values);
                    }
                }}
                onChange={() => {
                    onChange?.();
                }}
                dirtySubmitOnly
                CustomSubmit={() => {
                    return formRef.current ? (
                        <AutoSave debounceMs={500} formik={formRef.current} setIsSaved={setIsSaved} />
                    ) : null;
                }}
            />
            {required && (
                <Grid container style={{ paddingBottom: 16 }}>
                    <Text variant={'body2'} color={'GRAY'}>
                        To avoid delays, we require at least 1 option to be selected.
                    </Text>
                </Grid>
            )}
            {hasSenders && (
                <ManageReceivingSettings
                    sendersOrReceivers={senders}
                    employeePrefs={employeePrefs}
                    notificationName={notificationName}
                    setShowSuccess={setShowSuccess}
                    refetch={refetch}
                />
            )}
            {receivingNotifications && prefsToShareWith.length > 0 && (
                <Grid item style={{ paddingTop: hasSenders ? 16 : 0 }}>
                    <ManageForwardingSettings
                        sendersOrReceivers={receivers ?? []}
                        employeePrefs={employeePrefs}
                        notificationName={notificationName}
                        prefsToShareWith={prefsToShareWith}
                        refetch={refetch}
                    />
                </Grid>
            )}
            <RequiredFieldWarningToast open={showWarning} setOpen={setShowWarning} />
            <Toast
                headline={'Stopped receiving notifications'}
                description={`You have stopped receiving ${notificationName} notifications.`}
                open={showSuccess}
                onDismiss={() => setShowSuccess(false)}
                variant={'success'}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
        </>
    );
};
