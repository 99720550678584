import { getPresetTitle } from '../../GuidedWaxup.util';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { SimpleInput } from '@orthly/ui';
import { Box, FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';

export const StructuredRejectionNotesAddendum: React.VFC<{
    disabled?: boolean;
    hasBorder?: boolean;
    presetType: LabsGqlGuidedWaxupPresetType;
}> = ({ disabled, hasBorder, presetType }) => {
    const preset = useGuidedWaxupSelector(s => s.presets[presetType]);
    const setPresetRejectionNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');
    const presetTitle = React.useMemo(() => getPresetTitle(presetType).toLowerCase(), [presetType]);

    return (
        <Box>
            <Text
                variant={'body2'}
                bold
                sx={{
                    borderTop: hasBorder ? `1px solid ${FlossPalette.DARK_TAN}` : 'none',
                    marginTop: hasBorder ? '12px' : '0',
                    padding: '12px 0',
                }}
            >
                Additional {presetTitle} notes
            </Text>
            <SimpleInput
                disabled={disabled}
                value={preset?.notes ?? ''}
                onChange={(rejectionNotes = '') => setPresetRejectionNotes({ presetName: presetType, rejectionNotes })}
                label={`Optional description for ${presetTitle} issue`}
                fullWidth
                TextFieldProps={{
                    multiline: true,
                    rows: 5,
                }}
            />
        </Box>
    );
};
