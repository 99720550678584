import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { LabsGqlDoctorProfilePhotoColor, LabsGqlDoctorProfilePhotoType } from '@orthly/graphql-schema';
import { Icon } from '@orthly/ui-primitives';
import { DoctorProfilePhotoBackground, DoctorProfilePhotoContent } from '@orthly/veneer';
import React from 'react';

export const StaffProfilePhoto: React.VFC<{
    staff?: Pick<LabsGqlDoctorPreferencesFragment, 'profile_photo' | 'name'>;
    style?: React.CSSProperties;
}> = ({ staff, style }) => {
    if (staff) {
        return (
            <DoctorProfilePhotoContent
                type={staff.profile_photo.type}
                source={staff.profile_photo.source ?? staff.name}
                color={staff.profile_photo.color}
                style={style}
            />
        );
    }
    return (
        <DoctorProfilePhotoBackground
            type={LabsGqlDoctorProfilePhotoType.Avatar}
            source={'none'}
            color={LabsGqlDoctorProfilePhotoColor.LightGreen}
            style={style}
        >
            <Icon icon={'PersonOutline'} style={{ width: '100%', height: '100%' }} />
        </DoctorProfilePhotoBackground>
    );
};
