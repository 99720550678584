import { CHAT_FRAME_ROUTE, CST_FRAME_ROUTE, KUSTOMER_FRAME_ROUTE } from '../screens/chat/chat-routes.constant';
import type { AnalyticsCallback, GlobalClientAnalytics } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';

// Importing Redux State here causes a circular dependency, so we'll settle for this...
let isNativeApp = false;

export const setisNativeApp = (_isNativeApp: boolean) => {
    isNativeApp = _isNativeApp;
};

const SourceEnrichment: AnalyticsCallback['track'] = ctx => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isIos = /iphone|ipod|ipad/.test(userAgent);
    if (isIos && isNativeApp) {
        ctx.updateEvent(`context.app.name`, 'Dandy iOS App');
    }
    if (!isIos && isNativeApp) {
        ctx.updateEvent(`context.app.name`, 'Dandy Android App');
    }
    ctx.updateEvent(`context.app.version`, process.env.REACT_APP_VERSION);
    return ctx;
};

function buildAnalyticsClient(): GlobalClientAnalytics {
    const chatFrameRoutes = [CHAT_FRAME_ROUTE, KUSTOMER_FRAME_ROUTE, CST_FRAME_ROUTE];
    const pathname = window.location.pathname;
    // To stay within limits of Segment's MTU limits, we disable analytics on the chat frame routes by mocking the analytic client
    if (chatFrameRoutes.includes(pathname)) {
        console.log(`Loading Noop Analytics Client on path ${pathname}`);
        const noop = () => {
            console.debug(`Noop Analytics Client method called on path ${pathname}`);
        };
        return {
            identify: noop,
            page: noop,
            track: noop,
            reset: noop,
            register: noop,
            setGroup: noop,
            setGroupProp: noop,
        };
    }

    return BrowserAnalyticsClientFactory.create(process.env.REACT_APP_SEGMENT_KEY || '', {
        initPosthog: false,
        log: true,
        plugins: [
            {
                name: 'Practice Source Enrichment',
                type: 'enrichment',
                version: '1.0',
                isLoaded: () => true,
                load: () => Promise.resolve(),
                track: SourceEnrichment,
                page: SourceEnrichment,
                identify: SourceEnrichment,
            },
        ],
    });
}

export const AnalyticsClient = buildAnalyticsClient();
