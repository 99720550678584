import type { ImplantReadinessTip } from '../state/ImplantReadiness.types';
import { FlossPalette, createStyles, Grid, makeStyles, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        helpItem: {
            padding: 24,
            backgroundColor: FlossPalette.WHITE,
            borderRadius: 16,
            marginTop: 16,
            border: `1px solid ${FlossPalette.DARK_TAN}`,
        },
    }),
);

export const restoreConfidentlyTip: ImplantReadinessTip = {
    title: 'Restore confidently',
    children: (
        <Text variant={'body2'}>
            The digital implant restoration workflow requires you to have the correct set of tools at your practice on
            scanning day. Don’t stress - Dandy has you covered.
        </Text>
    ),
};

export const renderSystemCTATip = (name?: string, openChat?: () => void): ImplantReadinessTip => {
    return {
        title: `Can't find ${name}'s implant system?`,
        children: (
            <Text variant={'body2'}>
                All implant systems that we currently support are listed in the dropdowns. If you can’t find yours,
                please{' '}
                <span
                    onClick={openChat}
                    style={{ color: FlossPalette.PRIMARY_FOREGROUND, fontWeight: 600, cursor: 'pointer' }}
                >
                    click here to start a chat with us
                </span>{' '}
                on how we can support this case.
            </Text>
        ),
    };
};

export const renderWhatIsScanbodyTip = (disableFreeScanBody: boolean): ImplantReadinessTip => {
    return {
        title: 'What is a scan body?',
        children: (
            <>
                <p>
                    Scan bodies are the digital equivalent of an analog transfer coping. They communicate the
                    positioning of your patient's implant(s) using 3D data points that the lab utilizes during the
                    digital design process.
                </p>
                <p>
                    Just like impression copings, scan bodies come in different sizes and are unique to implant
                    manufacturers.
                </p>
                <p>
                    Dandy provides {!disableFreeScanBody && 'free'} scan bodies for all FDA-approved implant systems.
                    Our scan bodies are titanium, autoclavable, and reusable up to 100 times.
                </p>
            </>
        ),
    };
};

const Tip: React.FC<{ title: string }> = ({ title, children }) => {
    const classes = useStyles();

    return (
        <Grid className={classes.helpItem}>
            <Text variant={'h6'}>{title}</Text>
            <Text variant={'body2'} style={{ marginTop: 16 }}>
                {children}
            </Text>
        </Grid>
    );
};

export const Tips: React.VFC<{ tips: ImplantReadinessTip[] }> = ({ tips }) => {
    return (
        <Grid container direction={'column'}>
            {tips.map(tip => (
                <Tip key={tip.title} title={tip.title}>
                    {tip.children}
                </Tip>
            ))}
        </Grid>
    );
};
