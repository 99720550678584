import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { FlossPalette, stylesFactory, Grid, Tooltip, InfoIcon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    tooltipTextBox: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-star',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    tooltip: {
        maxWidth: '220px',
        borderRadius: 5,
        backgroundColor: FlossPalette.GRAY,
    },
    tooltipIcon: {
        fontSize: 15,
        width: 16,
        height: 16,
        color: FlossPalette.GRAY,
        cursor: 'help',
        marginBottom: -3,
    },
}));

const PracticeProductsReviewedTooltipSalesOrder_Fragment = graphql(`
    fragment PracticeProductsReviewedTooltipSalesOrder_Fragment on OrderPracticeListViewEntry {
        delivery_date
        reviewed_items_descriptions
        unreviewed_items_descriptions
    }
`);

export const ProductsReviewedTooltip: React.VFC<{
    orderFragment: FragmentType<typeof PracticeProductsReviewedTooltipSalesOrder_Fragment>;
}> = ({ orderFragment }) => {
    const classes = useStyles();
    const order = getFragmentData(PracticeProductsReviewedTooltipSalesOrder_Fragment, orderFragment);

    const unreviewedCount = order.unreviewed_items_descriptions.length;

    return (
        <Tooltip
            title={
                <Grid container className={classes.tooltipTextBox}>
                    <Grid item>
                        <Text variant={'body2'} medium color={`WHITE`}>
                            Thank you for reviewing:
                        </Text>
                    </Grid>
                    {order.reviewed_items_descriptions.map(
                        (item, idx) =>
                            item && (
                                <Grid key={`${item}-${idx}`} item>
                                    <Text variant={'caption'} color={`WHITE`}>
                                        {item}
                                    </Text>
                                </Grid>
                            ),
                    )}
                    {!!unreviewedCount && (
                        <Grid item style={{ marginTop: 15 }}>
                            <Text variant={'caption'} color={`WHITE`}>
                                You can contact us via chat to submit further feedback
                            </Text>
                        </Grid>
                    )}
                </Grid>
            }
            arrow
            placement={'left'}
            classes={{ tooltip: classes.tooltip }}
        >
            <InfoIcon className={classes.tooltipIcon} />
        </Tooltip>
    );
};
