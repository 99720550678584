import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const NightGuardsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'night-guard',
                type: 'Night Guard (All Materials and Thicknesses)',
                price: getPrice('Night Guard'),
            },
            {
                id: 'sports-guard',
                type: 'Sports Guard (All Materials and Thicknesses)',
                price: getPrice('Sports Guard'),
            },
            {
                id: 'retainer-single',
                type: 'Essix Retainer (Single Arch)',
                price: getPrice('Retainer (Single)'),
            },
            {
                id: 'retainer-double',
                type: 'Essix Retainer (Double Arch)',
                price: getPrice('Retainer (Double)'),
            },
            {
                id: 'essix-retainer-pontic',
                type: 'Essix Retainer Pontic',
                price: getPrice('Essix Retainer Pontic'),
            },
            {
                id: 'hawley-single',
                type: 'Hawley or Lingual Bar Retainer (Single Arch)',
                price: getPrice('Retainer (Single)'),
            },
            {
                id: 'hawley-double',
                type: 'Hawley or Lingual Bar Retainer (Double Arch)',
                price: getPrice('Retainer (Double)'),
            },
            {
                id: 'bleaching-single',
                type: 'Bleaching Tray (Single Arch)',
                price: getPrice('Bleaching Tray (Single)'),
            },
            {
                id: 'bleaching-double',
                type: 'Bleaching Tray (Double Arch)',
                price: getPrice('Bleaching Tray (Double)'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'other-removables-night-guards'}
            title={'Nightguards, Retainers & Bleaching Trays'}
            columns={[
                {
                    id: 'type',
                    title: 'Type',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
