import { stylesFactory, Text, FlossPalette, FlossPaletteUtils, Grid, Icon } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    container: {
        background: FlossPalette.TAN,
        borderColor: FlossPalette.TAN,
        borderRadius: 16,
        padding: 24,
        borderStyle: 'solid',
        borderWidth: 4,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: 12,
        width: '100%',
        position: 'relative',
        '&:last-child': {
            marginRight: 0,
        },
        '&:first-child': {
            marginLeft: 0,
        },
    },
    containerSmall: {
        borderRadius: 4,
        padding: 16,
        borderWidth: 2,
        '&:hover': {
            borderColor: FlossPalette.DARK_TAN,
        },
    },
    containerSelected: {
        borderColor: FlossPalette.STAR_GRASS,
    },
    containerSelectedSmall: {
        background: FlossPaletteUtils.toRgba('STAR_GRASS', 0.1),
        '&:hover': {
            borderColor: FlossPalette.STAR_GRASS,
        },
    },
    containerClickable: {
        cursor: 'pointer',
    },
    titleWrapper: {
        flexWrap: 'nowrap',
    },
    labelRadio: {
        marginLeft: 8,
    },
    helpText: {
        marginTop: 8,
    },
}));

interface CheckoutLargeButtonProps {
    selected: boolean;
    label: string;
    onClick?: () => void;
    radio?: boolean;
    small?: boolean;
}

export const CheckoutLargeButton: React.FC<CheckoutLargeButtonProps> = props => {
    const { selected, radio, onClick, label, small } = props;
    const classes = useStyles();

    return (
        <div
            className={cx(classes.container, {
                [classes.containerSmall]: small,
                [classes.containerSelected]: selected,
                [classes.containerSelectedSmall]: small && selected,
                [classes.containerClickable]: !!onClick,
            })}
            onClick={() => onClick && onClick()}
        >
            <Grid container className={classes.titleWrapper}>
                {radio &&
                    (selected ? (
                        <Icon icon={'RadioButtonCheckedIcon'} style={{ color: FlossPalette.STAR_GRASS }} />
                    ) : (
                        <Icon icon={'RadioButtonUnCheckedIcon'} style={{ color: FlossPalette.GRAY }} />
                    ))}
                <Text variant={small ? 'body2' : 'body1'} medium className={cx({ [classes.labelRadio]: radio })}>
                    {label}
                </Text>
            </Grid>
        </div>
    );
};
