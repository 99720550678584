import { ChairsideAdjustmentMinutesInputSection, ItemNotesInputSection } from './FeedbackItemPropertiesScreen';
import { FeedbackActions } from './state/feedback.actions';
import type { FeedbackFiveStarPropsScreen } from './state/feedback.reducer';
import { useSubmitFeedbackV3 } from './state/useSubmitFeedback';
import { FeedbackAndRefabBodyContainer } from '@orthly/dentin';
import { isArrayMin2 } from '@orthly/runtime-utils';
import { stylesFactory, useScreenIsMobileOrVerticalTablet, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useDispatch } from 'react-redux';

const useStyles = stylesFactory(theme => ({
    root: {
        marginLeft: `5px`,
        marginTop: `24px`,
        [theme.breakpoints.down('lg')]: {
            marginLeft: `0px`,
        },
        maxHeight: `calc(100vh - 72px)`,
    },
}));

export const FeedbackFiveStarPropertiesScreen: React.VFC<{
    screen: FeedbackFiveStarPropsScreen;
}> = ({ screen }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { items, item } = screen;
    const dispatch = useDispatch();
    const { submit } = useSubmitFeedbackV3();

    const hideBack = screen.previous_screen === undefined || items.length === 1;

    const onNext = () => {
        if (isArrayMin2(items)) {
            dispatch(FeedbackActions.GO_TO({ items, id: 'item_select', previous_screen: screen }));
        } else {
            // if this screen was accessed through item select screen, then we want to submit a new feedback submission
            // as we normally would
            submit();
        }
    };

    return (
        <FeedbackAndRefabBodyContainer
            title={
                <>
                    Thank you for the positive feedback!
                    <Text variant={'body1'} color={'GRAY'}>
                        Tell us what we did well so we keep doing it
                    </Text>
                </>
            }
            onBack={hideBack ? undefined : () => dispatch(FeedbackActions.GO_BACK(screen))}
            onNext={onNext}
            nextTitle={items[1] ? 'Next' : 'Submit feedback'}
        >
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <ChairsideAdjustmentMinutesInputSection itemId={item.id} />
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <ItemNotesInputSection itemId={item.id} />
                </Grid>
            </Grid>
        </FeedbackAndRefabBodyContainer>
    );
};
