import type { CheckoutFeatureFlags, CheckoutState, DoctorNotesCategoryV2 } from './checkout.state';
import type {
    LabsGqlDoctorDeliveryAddressFragment,
    LabsGqlDoctorPreferencesFragment,
    LabsGqlLabOrderFragment,
    LabsGqlScanFileCheckoutFragment,
} from '@orthly/graphql-operations';
import type {
    LabsGqlCartSlaModifier,
    LabsGqlLabOrderSinglePhotoInput,
    LabsGqlOrderRefabReasonInput,
    LabsGqlSmileStyleChoice,
} from '@orthly/graphql-schema';
import type { ExtraCartItemV2UnitType, ICartItemV2Update, ICustomFieldSubmission } from '@orthly/items';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'checkout';

type ISetPatient =
    | { type: 'first' | 'last'; value: string }
    | { type: 'birthday'; value: Date | null }
    | { type: 'gender'; value: string };

type ISetMetafield = {
    item_index: number;
    field: ICustomFieldSubmission;
};

export type CheckoutItemChange =
    | ICartItemV2Update
    // if this change type is used outside implant checkout it may cause necessary fields to be missed
    | { name: 'clear_original_material' };

type CheckoutItemUpdate = {
    item_index: number;
    change: CheckoutItemChange;
};

export type ChangeItemTypeUnitType = ExtraCartItemV2UnitType | 'Unknown';

type ChangeItemType = {
    item_index: number;
    unit_type: ChangeItemTypeUnitType;
};

type PatientDoctorInfo = {
    birthday: string;
    gender: string | null;
    doctor?: LabsGqlDoctorPreferencesFragment;
    address?: LabsGqlDoctorDeliveryAddressFragment;
};

export const CheckoutActions = {
    SET_FEATURE_FLAGS: createSyncAction<CheckoutFeatureFlags, [CheckoutFeatureFlags]>(`${PREFIX}/SET_FEATURE_FLAGS`),

    SET_DOCTOR: createSyncAction<CheckoutState['doctor'], [CheckoutState['doctor']]>(`${PREFIX}/SET_DOCTOR`),
    SET_ADDRESS: createSyncAction<CheckoutState['address'], [CheckoutState['address']]>(`${PREFIX}/SET_ADDRESS`),
    SET_SCAN: createSyncAction<LabsGqlScanFileCheckoutFragment, [LabsGqlScanFileCheckoutFragment]>(
        `${PREFIX}/SET_SCAN`,
    ),
    CLEAR_SCAN: createSyncAction<undefined, []>(`${PREFIX}/CLEAR_SCAN`),

    // This exists because sometimes doc's write corrections to the order in the summary screen. We added UI to
    // allow them to go back and fix it themselves instead, and this action implements that UI
    BACK_TO_EDIT_ORDER: createSyncAction<undefined, []>(`${PREFIX}/BACK_TO_EDIT_ORDER`),

    SET_ATTACHMENTS: createSyncAction<LabsGqlLabOrderSinglePhotoInput[], [LabsGqlLabOrderSinglePhotoInput[]]>(
        `${PREFIX}/SET_ATTACHMENTS`,
    ),
    SET_CURRENT_NOTES_V2_CATEGORY: createSyncAction<DoctorNotesCategoryV2, [DoctorNotesCategoryV2]>(
        `${PREFIX}/SET_CURRENT_NOTES_V2_CATEGORY`,
    ),

    SET_SCAN_IMG_SOURCES: createSyncAction<string[], [string[]]>(`${PREFIX}/SET_SCAN_IMG_SOURCES`),

    UPDATE_LINE_ITEM: createSyncAction<CheckoutItemUpdate, [CheckoutItemUpdate]>(`${PREFIX}/UPDATE_LINE_ITEM`),

    SET_LINE_ITEM_METAFIELD: createSyncAction<ISetMetafield, [ISetMetafield]>(`${PREFIX}/SET_LINE_ITEM_METAFIELD`),

    CHANGE_ITEM_TYPE: createSyncAction<ChangeItemType, [ChangeItemType]>(`${PREFIX}/CHANGE_ITEM_TYPE`),

    SET_DOCTOR_NOTES_OPEN: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_DOCTOR_NOTES_OPEN`),
    SET_DOCTOR_NOTES: createSyncAction<string | undefined | null, [string | undefined | null]>(
        `${PREFIX}/SET_DOCTOR_NOTES`,
    ),

    SET_IS_TRAINING_ORDER: createSyncAction<boolean | undefined, [boolean | undefined]>(
        `${PREFIX}/SET_IS_TRAINING_ORDER`,
    ),

    SET_PATIENT_INFO: createSyncAction<ISetPatient, [ISetPatient]>(`${PREFIX}/SET_PATIENT_INFO`),

    SET_PATIENT_AND_DOCTOR_INFO: createSyncAction<PatientDoctorInfo, [PatientDoctorInfo]>(
        `${PREFIX}/SET_PATIENT_AND_DOCTOR_INFO`,
    ),

    DISABLE_BULK_EDIT: createSyncAction<string, [string]>(`${PREFIX}/DISABLE_BULK_EDIT`),

    ENABLE_BULK_EDIT: createSyncAction<string, [string]>(`${PREFIX}/ENABLE_BULK_EDIT`),

    REMOVE_ITEM: createSyncAction<number, [number]>(`${PREFIX}/REMOVE_ITEM`),

    ADD_SCAN_ITEM_BACK: createSyncAction<number, [number]>(`${PREFIX}/ADD_SCAN_ITEM_BACK`),

    NEXT_STEP_V2: createSyncAction<undefined, []>(`${PREFIX}/NEXT_STEP_V2`),

    PREV_STEP_V2: createSyncAction<undefined, []>(`${PREFIX}/PREV_STEP_V2`),

    SET_EXTRA_UNITS: createSyncAction<ExtraCartItemV2UnitType[], [ExtraCartItemV2UnitType[]]>(
        `${PREFIX}/SET_EXTRA_UNITS`,
    ),

    SET_SMILE_STYLE: createSyncAction<LabsGqlSmileStyleChoice | undefined, [LabsGqlSmileStyleChoice | undefined]>(
        `${PREFIX}/SET_SMILE_STYLE`,
    ),

    SET_ACTIVE_ITEM_IDX: createSyncAction<number, [number]>(`${PREFIX}/SET_ACTIVE_ITEM_IDX`),

    DISMISS_EXISTING_ORDER_SCREEN: createSyncAction<undefined, []>(`${PREFIX}/DISMISS_EXISTING_ORDER_SCREEN`),

    START_REFABRICATION: createSyncAction<LabsGqlLabOrderFragment, [LabsGqlLabOrderFragment]>(
        `${PREFIX}/START_REFABRICATION`,
    ),

    SET_REFAB_ATTACHMENTS: createSyncAction<string[], [string[]]>(`${PREFIX}/SET_REFAB_ATTACHMENTS`),

    SET_REFAB_NOTES: createSyncAction<string | undefined, [(string | undefined)?]>(`${PREFIX}/SET_REFAB_NOTES`),

    SET_REFAB_REASONS: createSyncAction<LabsGqlOrderRefabReasonInput[], [LabsGqlOrderRefabReasonInput[]]>(
        `${PREFIX}/SET_REFAB_REASONS`,
    ),

    SET_REFAB_FILES_UPLOADING: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_REFAB_FILES_UPLOADING`),

    SET_WAXUP_SELECTED: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_WAXUP_SELECTED`),

    SET_WAXUP_TOOLTIP_DISMISSED: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_WAXUP_TOOLTIP_DISMISSED`),

    SET_SUGGESTED_ITEM_RENDERED: createSyncAction<undefined, []>(`${PREFIX}/SET_SUGGESTED_ITEM_RENDERED`),
    SET_SUGGESTED_ITEM_SELECTED: createSyncAction<undefined, []>(`${PREFIX}/SET_SUGGESTED_ITEM_SELECTED`),
    SET_AESTHETIC_CHECKOUT_PHOTOS: createSyncAction<{ [key: string]: string }, [{ [key: string]: string }]>(
        `${PREFIX}/SET_AESTHETIC_CHECKOUT_PHOTOS`,
    ),

    START_AESTHETIC_PHOTOS_UPLOAD: createSyncAction<undefined, []>(`${PREFIX}/START_UPLOAD`),
    AESTHETIC_PHOTOS_CHECK_INSTRUCTIONS: createSyncAction<undefined, []>(
        `${PREFIX}/AESTHETIC_PHOTOS_CHECK_INSTRUCTIONS`,
    ),
    SET_SLA_MODIFIER: createSyncAction<LabsGqlCartSlaModifier | undefined, [LabsGqlCartSlaModifier | undefined]>(
        `${PREFIX}/SET_SLA_MODIFIER`,
    ),
    SET_PROMO_CODE_INFO: createSyncAction<CheckoutState['promo_code_info'], [CheckoutState['promo_code_info']]>(
        `${PREFIX}/SET_PROMO_CODE_INFO`,
    ),
};

export const useCheckoutAction = generateUseActionHook<typeof CheckoutActions>(CheckoutActions);
