import { usePortalToChairsideBridgeCommand } from '../../../utils/chairside.hooks';
import { useOpenCheckout } from '../../../utils/useSetScreen';
import { stylesFactory, Button } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    bottomButton: {
        justifyContent: 'space-between',
        width: '100%',
    },
    bottomButtonLabel: { width: '100%' },
}));

const trackingData = {
    AssetName: 'OpenCheckoutSubNavButton',
    AssetCTAText: 'New order',
    AssetLocation: 'Orders sidebar',
};

export const OpenCheckoutSubNavButton: React.VFC = () => {
    const classes = useStyles();
    const openCheckout = useOpenCheckout();
    const startChairsideCase = usePortalToChairsideBridgeCommand('startCase');

    const onClick = React.useMemo(() => {
        if (startChairsideCase) {
            return startChairsideCase;
        }

        return () => openCheckout({ trackingData });
    }, [startChairsideCase, openCheckout]);

    if (!onClick) {
        return null;
    }

    const buttonClasses = {
        root: cx(classes.bottomButton, classes.bottomButtonLabel),
    };

    return (
        <Button fullWidth variant={'primary'} classes={buttonClasses} onClick={() => onClick()} endIcon={'PlusIcon'}>
            {trackingData.AssetCTAText}
        </Button>
    );
};
