import type {
    LabsGqlDenturesBiteAdjustmentType,
    LabsGqlDenturesFabricationMethod,
    LabsGqlDenturesFestooningLevel,
    LabsGqlDenturesHandleDiastemaOption,
    LabsGqlDenturesMidlineCorrectionType,
    LabsGqlDenturesProductionType,
    LabsGqlDenturesTeethQuality,
    LabsGqlSmileStyleChoice,
} from '@orthly/graphql-schema';
import type { IOrderItemShade, DentureType, OrderItemArch } from '@orthly/items';

export enum DentureCheckoutStep {
    PatientInfoStep = 'PatientInfoStep',
    InitialOrderChooseTypeStep = 'InitialOrderChooseTypeStep',
    OrderNotesStep = 'OrderNotesStep',
    InitialNonWaxRimOrderDentureOptionsStep = 'InitialNonWaxRimOrderDentureOptionsStep',
    InitialNonWaxRimOrderDentureStyleStep = 'InitialNonWaxRimOrderDentureStyleStep',
    DentureFestooningStep = 'DentureFestooningStep',
    DentureAestheticStep = 'DentureAestheticStep',
    InitialNonWaxRimOrderPhotosChoiceStep = 'InitialNonWaxRimOrderPhotosChoiceStep',
    InitialNonWaxRimOrderPhotosReviewStep = 'InitialNonWaxRimOrderPhotosReviewStep',
    AddSpareDenturesStep = 'AddSpareDenturesStep',
    Summary = 'Summary',
}

export interface WaxRimContinuationDetails {
    isWaxRimContinuation: boolean;
    orderId: string;
}

export interface DentureImplantSupport {
    isImplantSupported: boolean;
    collarHeightsMm?: string;
}

export interface DentureCheckoutState {
    history: DentureCheckoutStep[];
    currentStep: DentureCheckoutStep;
    desktopUpload?: boolean;
    fields: {
        dentureType?: DentureType;
        dentureArch?: OrderItemArch | null;
        waxRimPhotoUrls?: string[];

        implantSupport?: DentureImplantSupport;

        dentureOptions?: {
            fabrication?: LabsGqlDenturesFabricationMethod;
            teethQuality?: LabsGqlDenturesTeethQuality;
            productionType?: LabsGqlDenturesProductionType;
            shades?: IOrderItemShade[];
        };
        dentureStyle?: LabsGqlSmileStyleChoice;
        festooningLevel?: LabsGqlDenturesFestooningLevel;
        handleDiastema?: LabsGqlDenturesHandleDiastemaOption;
        biteAdjustment?: {
            adjustment: LabsGqlDenturesBiteAdjustmentType;
            adjustmentDistance?: number | null;
        };
        midlineCorrection?: {
            correctionType: LabsGqlDenturesMidlineCorrectionType;
            correctionDistance?: number | null;
        };
        aestheticOptions?: {
            addStippling?: boolean;
            addPostDam?: boolean;
            correctOcclusalScheme?: boolean;
        };

        dentureNotes?: string;
        denturePhotos?: Array<{
            url: string;
            description?: string;
        }>;
        waxRimContinuation?: WaxRimContinuationDetails;

        numberOfSpares?: number;

        isExactCopy?: boolean;
        exactCopyNotes?: string;
    };
}
