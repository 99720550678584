import { usePartnerUiSelector } from '../../../../../redux/ui';
import { useLabsContext } from '../../../LabsContext';
import { MarkNeedsRefabricationAction } from '../../../actions/practitioners/MarkNeedsRefabrication';
import { useOrderDetailSection } from '../../OrderDetailSectionProvider';
import { OrderDetailSection } from './OrderDetailSection';
import type { OrderDetailSharedProps } from './OrderDetailSection.types';
import { OrderItemV2Utils } from '@orthly/items';
import { Grid, Text, useScreenIsMobile } from '@orthly/ui-primitives';
import { OrderDetailAligners, OrderDetailBlock, OrderDetailMouth, OrderItemsTable } from '@orthly/veneer';
import React from 'react';

const OrderDetailNotes: React.FC<{ doctor_notes: string }> = ({ doctor_notes }) => {
    if (doctor_notes.trim().length === 0) {
        return null;
    }
    return (
        <OrderDetailBlock title={'Notes'} variant={'full'} fitHeightToContent rootStyle={{ flexBasis: 'auto' }}>
            <Text variant={'body1'}>{doctor_notes}</Text>
        </OrderDetailBlock>
    );
};

const OrderDetailMouthAndItems: React.FC<OrderDetailSharedProps> = props => {
    const { order, refetch } = props;
    return (
        <Grid container>
            <Grid container item xs={12} md={4} alignContent={'flex-start'} direction={'column'}>
                <OrderDetailMouth order={order} />
            </Grid>
            <Grid container item xs={12} md={8} alignContent={'flex-start'} direction={'column'} wrap={'nowrap'}>
                <OrderItemsTable
                    items={OrderItemV2Utils.parseItems(order?.items_v2 ?? [])}
                    titleAction={
                        order && (
                            <MarkNeedsRefabricationAction order={order} refetch={refetch} analyticsLocation={'body'} />
                        )
                    }
                    variant={'full'}
                />
                <OrderDetailNotes doctor_notes={order?.doctor_notes ?? ''} />
            </Grid>
        </Grid>
    );
};

export const OrderDetailDetailsSection: React.FC<OrderDetailSharedProps> = ({ order, refetch }) => {
    const { setDetailsRefs } = useOrderDetailSection();

    const { userRole } = useLabsContext();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const isMobile = useScreenIsMobile();

    return (
        <OrderDetailSection title={'Item details'} ref={setDetailsRefs}>
            {order.aligner_case ? (
                <OrderDetailAligners
                    lab_order={order}
                    aligner_case={order.aligner_case}
                    refetch={refetch}
                    user_role={userRole}
                    disablePhotoDownload={isNativeApp || isMobile}
                />
            ) : (
                <OrderDetailMouthAndItems refetch={refetch} order={order} />
            )}
        </OrderDetailSection>
    );
};
