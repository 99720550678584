import { useInvoicesTableContext } from '../components/providers/InvoicesTableProvider.graphql';
import {
    PrintableFooterSpace,
    PrintableHeaderSpace,
    PrintableInvoiceBodyContainer,
    PrintArea,
} from '../invoice-detail/PrintableInvoice';
import { PrintableInvoiceAddressContainer } from '../invoice-detail/printable-invoice/PrintableInvoiceAddressContainer';
import { PrintableInvoiceBody } from '../invoice-detail/printable-invoice/PrintableInvoiceBody';
import { PrintableInvoiceFooter } from '../invoice-detail/printable-invoice/PrintableInvoiceFooter';
import { PrintableInvoiceHeader } from '../invoice-detail/printable-invoice/PrintableInvoiceHeader';
import { PrintableInvoiceOverview } from '../invoice-detail/printable-invoice/PrintableInvoiceOverview';
import type { PracticeInvoiceDetail } from '../invoicing.types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { PrintableSlip } from '@orthly/veneer';
import { groupBy } from 'lodash';
import React from 'react';

export const GetBillingContactEmailsForInvoices_Query = graphql(`
    query GetBillingContactEmailsForInvoices($invoiceIds: [String!]!) {
        billingEmails: getBillingContactEmailsForInvoices(invoiceIds: $invoiceIds) {
            organization_id
            primary_contact_email
        }
    }
`);

export const PrintableInvoicesList: React.FC = () => {
    const {
        associatedPractices,
        tableInvoices,
        autochargeEnabled,
        loading,
        selectedInvoiceIds,
        setIsReadyToPrint,
        closePrintableSlip,
        invoicePayments,
        rows,
    } = useInvoicesTableContext();
    const selectedInvoices = tableInvoices.filter(invoice => selectedInvoiceIds.includes(invoice.id));

    const { data: { billingEmails = [] } = {}, loading: billingDetailsLoading } = useQuery(
        GetBillingContactEmailsForInvoices_Query,
        {
            variables: { invoiceIds: selectedInvoiceIds },
        },
    );

    const rowsByInvoice = groupBy(rows, 'invoice_id');
    const paymentsByInvoice = groupBy(invoicePayments, 'invoice_id');
    const slipIsLoading = loading || billingDetailsLoading;

    return (
        <PrintableSlip
            openPrintWindow={!slipIsLoading}
            onPrintComplete={() => {
                setIsReadyToPrint(false);
                document.title = 'Dandy Practice App';
                closePrintableSlip();
            }}
        >
            <PrintArea>
                {/* the header and footer are position: fixed to the top/bottom of the page */}
                <PrintableInvoiceHeader />
                <PrintableInvoiceFooter />

                {selectedInvoices.map(selectedInvoice => {
                    const invoicePayments = paymentsByInvoice[selectedInvoice.id] ?? [];

                    const invoice: PracticeInvoiceDetail = {
                        ...selectedInvoice,
                        payments: invoicePayments,
                    };

                    const associatedPractice = associatedPractices.find(
                        practice => practice.id === invoice.organization_id,
                    );
                    const billingEmail =
                        billingEmails.find(email => email.organization_id === invoice.organization_id)
                            ?.primary_contact_email ?? undefined;

                    const invoiceRows = rowsByInvoice[invoice.id] ?? [];

                    return (
                        <table key={invoice.invoice_number}>
                            <thead>
                                <tr>
                                    <td>
                                        {/* we still need to "reserve" space above the content for the (fixed) header */}
                                        <PrintableHeaderSpace />
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <PrintableInvoiceBodyContainer>
                                            <PrintableInvoiceOverview
                                                invoice={invoice}
                                                autochargeEnabled={autochargeEnabled}
                                            />
                                            <PrintableInvoiceAddressContainer
                                                organizationName={associatedPractice?.name}
                                                billingEmail={billingEmail}
                                            />

                                            <PrintableInvoiceBody
                                                invoice={invoice}
                                                rows={invoiceRows}
                                                rowsLoading={slipIsLoading}
                                            />
                                        </PrintableInvoiceBodyContainer>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td>
                                        {/* we also need to "reserve" space below the content for the (fixed) footer */}
                                        <PrintableFooterSpace />
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    );
                })}
            </PrintArea>
        </PrintableSlip>
    );
};
