import type { DentureImplantSupport, WaxRimContinuationDetails } from './reducers/dentures-checkout.types';
import type {
    LabsGqlDenturesBiteAdjustmentType,
    LabsGqlDenturesFabricationMethod,
    LabsGqlDenturesFestooningLevel,
    LabsGqlDenturesHandleDiastemaOption,
    LabsGqlDenturesMidlineCorrectionType,
    LabsGqlDenturesProductionType,
    LabsGqlDenturesTeethQuality,
    LabsGqlSmileStyleChoice,
} from '@orthly/graphql-schema';
import type { IOrderItemShade, DentureType, OrderItemArch } from '@orthly/items';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';
import type { SetOptional } from 'type-fest';

const prefix = 'dentures_checkout';

type WaxRimContinuationPayload = WaxRimContinuationDetails & {
    arch: OrderItemArch;
    implantSupport?: DentureImplantSupport;
};

export const DenturesCheckoutActions = {
    PREV_DENTURES_STEP: createSyncAction<undefined>(`${prefix}/PREV_DENTURES_STEP`),
    NEXT_DENTURES_STEP: createSyncAction<undefined>(`${prefix}/NEXT_DENTURES_STEP`),

    CHOOSE_DENTURE_TYPE: createSyncAction<DentureType | undefined, [DentureType | undefined]>(
        `${prefix}/CHOOSE_DENTURE_TYPE`,
    ),
    CHOOSE_IS_EXACT_COPY: createSyncAction<boolean | undefined, [boolean | undefined]>(
        `${prefix}/CHOOSE_IS_EXACT_COPY`,
    ),
    ADD_EXACT_COPY_NOTES: createSyncAction<string | undefined, [string | undefined]>(`${prefix}/ADD_EXACT_COPY_NOTES`),
    CHOOSE_DENTURE_ARCH: createSyncAction<OrderItemArch | null, [OrderItemArch | null]>(
        `${prefix}/CHOOSE_DENTURE_ARCH`,
    ),
    ADD_WAX_RIM_PHOTO_URL: createSyncAction<string>(`${prefix}/ADD_WAX_RIM_PHOTO_URL`),

    CHOOSE_DENTURE_FABRICATION: createSyncAction<LabsGqlDenturesFabricationMethod>(
        `${prefix}/CHOOSE_DENTURE_FABRICATION`,
    ),
    CHOOSE_DENTURE_TEETH_QUALITY: createSyncAction<LabsGqlDenturesTeethQuality>(
        `${prefix}/CHOOSE_DENTURE_TEETH_QUALITY`,
    ),
    CHOOSE_DENTURE_PRODUCTION_TYPE: createSyncAction<LabsGqlDenturesProductionType>(
        `${prefix}/CHOOSE_DENTURE_PRODUCTION_TYPE`,
    ),
    CHOOSE_DENTURE_SHADE: createSyncAction<SetOptional<IOrderItemShade, 'value'>>(`${prefix}/CHOOSE_DENTURE_SHADE`),

    CHOOSE_DENTURE_STYLE: createSyncAction<LabsGqlSmileStyleChoice>(`${prefix}/CHOOSE_DENTURE_STYLE`),

    CHOOSE_DENTURE_FESTOONING_LEVEL: createSyncAction<LabsGqlDenturesFestooningLevel>(
        `${prefix}/CHOOSE_DENTURE_FESTOONING_LEVEL`,
    ),

    CHOOSE_DENTURE_HANDLE_DIASTEMA: createSyncAction<LabsGqlDenturesHandleDiastemaOption>(
        `${prefix}/CHOOSE_DENTURE_HANDLE_DIASTEMA`,
    ),

    CHOOSE_DENTURE_BITE_ADJUSTMENT: createSyncAction<{
        adjustment: LabsGqlDenturesBiteAdjustmentType;
        adjustmentDistance?: number | null;
    }>(`${prefix}/CHOOSE_DENTURE_BITE_ADJUSTMENT`),

    CHOOSE_DENTURE_MIDLINE_CORRECTION: createSyncAction<{
        correctionType: LabsGqlDenturesMidlineCorrectionType;
        correctionDistance?: number | null;
    }>(`${prefix}/CHOOSE_DENTURE_MIDLINE_CORRECTION`),

    CHOOSE_DENTURE_AESTHETIC_OPTIONS: createSyncAction<{
        addStippling?: boolean;
        addPostDam?: boolean;
        correctOcclusalScheme?: boolean;
    }>(`${prefix}/CHOOSE_DENTURE_AESTHETIC_OPTIONS`),

    ADD_DENTURE_NOTES: createSyncAction<string>(`${prefix}/ADD_DENTURE_NOTES`),

    SET_DENTURE_PHOTO_URLS: createSyncAction<string[]>(`${prefix}/SET_DENTURE_PHOTO_URLS`),

    SET_DESKTOP_UPLOAD: createSyncAction<boolean, [boolean]>(`${prefix}/SET_DESKTOP_UPLOAD`),

    SET_WAX_RIM_CONTINUATION: createSyncAction<WaxRimContinuationPayload, [WaxRimContinuationPayload]>(
        `${prefix}/SET_WAX_RIM_CONTINUATION`,
    ),

    SET_NUMBER_OF_SPARES: createSyncAction<number, [number]>(`${prefix}/SET_NUMBER_OF_SPARES`),

    SET_IMPLANT_SUPPORT: createSyncAction<DentureImplantSupport | undefined, [DentureImplantSupport | undefined]>(
        `${prefix}/SET_IMPLANT_SUPPORT`,
    ),
};

export const useDenturesCheckoutAction = generateUseActionHook(DenturesCheckoutActions);
