import { useSetNewPasswordValidation } from '../../../../../components/forms/useSetNewPasswordValidation';
import React from 'react';

export const usePasswordSection = () => {
    const [password, setPassword] = React.useState('');
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const [enablePasswordEdit, setEnablePasswordEdit] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);
    const passwordValidation = useSetNewPasswordValidation({
        password,
        confirmedPassword,
    });

    const toggleShowPassword = () => setShowPassword(prev => !prev);
    const passwordType = showPassword ? 'text' : 'password';

    const togglePasswordEdit = () =>
        setEnablePasswordEdit(prev => {
            const next = !prev;

            if (next) {
                setPassword('');
                setConfirmedPassword('');
            } else {
                setPassword('');
                setConfirmedPassword('');
                setShowPassword(false);
            }

            passwordValidation.setShowPasswordError(false);
            passwordValidation.setShowConfirmedPasswordError(false);

            return next;
        });

    return {
        password,
        setPassword,
        confirmedPassword,
        setConfirmedPassword,
        enablePasswordEdit,
        togglePasswordEdit,
        passwordType,
        showPassword,
        toggleShowPassword,
        ...passwordValidation,
    };
};
