import { useCSTAction, useOnCSTOpen } from '../../../../chat/cst-state/cst.actions';
import { SplitOrderBanner } from './SplitOrderBanner';
import { getPauseOrderActionOptions } from './utils/getPauseOrderActionOptions';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { PauseOrderActionReason } from '@orthly/shared-types';
import { MiniWindowVariant, SimpleSelect } from '@orthly/ui';
import { Box, Button, Divider, FlossPalette, Text, styled, useScreenIsMd } from '@orthly/ui-primitives';
import React from 'react';

const ContentBox = styled(Box)({
    display: 'grid',
    gap: '48px',
    marginTop: '24px',
});

const LeftContentBox = styled(Box)(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '32px',

    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'unset',
        gridTemplateRows: '1fr auto',
        gap: '16px',
    },
}));

const RightContentBox = styled(Box)(({ theme }) => ({
    width: '100%',
    [theme.breakpoints.down('lg')]: {
        position: 'fixed',
        bottom: 0,
        left: 0,
    },
}));

const ActionButtonsSection = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'end',
    gap: '8px',

    [theme.breakpoints.down('lg')]: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        padding: '12px 16px',
        gap: '12px',
    },
}));

const SelectBox = styled(Box)({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    gap: '8px',
});

const ExplanationBox = styled(Box)({
    background: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: '8px',
    padding: '24px',
    display: 'grid',
    gap: '12px',
});

const ReviewDesignDivider = styled(Divider)({
    borderColor: FlossPalette.DARK_TAN,
    margin: '8px 0 6px',
});

interface PauseOrderActionInnerProps {
    order: LabsGqlLabOrderFragment;
    selectedOption: string | undefined;
    setSelectedOption: (value: string | undefined) => void;
    onCloseAction: () => void;
    onPauseAction: () => void;
    orderHasSplitOrders: boolean;
}

export const PauseOrderActionContent: React.FC<PauseOrderActionInnerProps> = ({
    order,
    selectedOption,
    setSelectedOption,
    onCloseAction,
    onPauseAction,
    orderHasSplitOrders,
}) => {
    const screenIsMd = useScreenIsMd();

    const options = getPauseOrderActionOptions(order);

    const setCSTVariant = useCSTAction('SET_WINDOW_VARIANT');
    const openCSTWithoutGroupAction = useOnCSTOpen(() => {
        onCloseAction();

        BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Chat Opened', {
            $groups: { order: order.id },
        });

        setCSTVariant(MiniWindowVariant.Maximized, 'move');
    });

    return (
        <ContentBox id={'content-box'} sx={{ paddingBottom: orderHasSplitOrders ? '24px' : 0 }}>
            <LeftContentBox id={'left-content-box'}>
                <SelectBox>
                    <Text variant={'body2'} medium>
                        Why do you want to pause this order?
                    </Text>
                    <SimpleSelect
                        options={options}
                        onChange={v => setSelectedOption(v)}
                        value={selectedOption}
                        label={!!selectedOption ? 'Pause reason' : 'Make a selection'}
                    />
                </SelectBox>

                <ExplanationBox>
                    <Text variant={'body2'} color={'GRAY'}>
                        What happens when I pause my order?
                    </Text>
                    <Text variant={'body2'}>
                        This order will be paused for the next 45 days or until you resume the order.{' '}
                        <strong>After 45 days, the order will be slotted for deletion.</strong>
                    </Text>
                    {selectedOption === PauseOrderActionReason.ReviewDesign && (
                        <>
                            <ReviewDesignDivider orientation={'horizontal'} />

                            <Text variant={'body2'} color={'GRAY'}>
                                How will I be able to review my design?
                            </Text>
                            <Text variant={'body2'}>
                                Your design will be available to review once you resume the order.
                            </Text>
                        </>
                    )}
                </ExplanationBox>
            </LeftContentBox>

            <RightContentBox id={'right-content-box'}>
                {screenIsMd && orderHasSplitOrders && <SplitOrderBanner />}
                <ActionButtonsSection>
                    {!screenIsMd && (
                        <Button variant={'ghost'} startIcon={'SidebarChatIcon'} onClick={openCSTWithoutGroupAction}>
                            I want to consult with a technical expert
                        </Button>
                    )}
                    <Button variant={'secondary-gray'} onClick={onCloseAction}>
                        Cancel
                    </Button>
                    <Button
                        data-test={'pause-order-button'}
                        variant={'primary'}
                        onClick={onPauseAction}
                        disabled={!selectedOption}
                    >
                        Pause order
                    </Button>
                </ActionButtonsSection>
            </RightContentBox>
        </ContentBox>
    );
};
