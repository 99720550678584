import type { PartnerAppState } from '../redux/types';
import { AnalyticsClient } from './analyticsClient';
import { createReduxActionListenerMiddleware } from '@orthly/ui';
import type { LocationChangeAction } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';

// Identity capture for the Chairside chat frame occurs in ScannerTokenSessionLoader.tsx.
export const practiceAnalyticsMiddleware = createReduxActionListenerMiddleware<PartnerAppState>([
    {
        config: {
            actions: ['select-profile/SELECT_PRACTICE_EMPLOYEE'],
        },
        handler: newState => {
            if (newState.staffMember.profile) {
                const firstName = (newState.staffMember.profile.name || '').split(' ')[0];
                const lastName = (newState.staffMember.profile.name || '').split(' ')[1];
                AnalyticsClient.identify(newState.staffMember.profile.id, {
                    firstName,
                    lastName,
                    email: newState.staffMember.profile.contact_email || undefined,
                    roles: newState.staffMember.profile.roles || undefined,
                });
            }
        },
    },
    {
        config: {
            actions: [LOCATION_CHANGE],
        },
        handler: (_newState, action) => {
            AnalyticsClient.page(`Practice`, `Generic Page`, {
                path: (action as LocationChangeAction).payload.location.pathname,
            });
        },
    },
]);
