import { ValidationUtils } from '@orthly/runtime-utils';
import React from 'react';
import { z } from 'zod';

const confirmPhoneNumberSchema = z
    .object({
        phoneNumber: z.string(),
        confirmationCode: z.string().length(6, { message: 'Confirmation code must be 6 digits' }),
    })
    .refine(({ phoneNumber }) => ValidationUtils.isPhone(phoneNumber), {
        message: 'Invalid phone number',
        path: ['phoneNumber'],
    });

export const useAddPhoneNumberValidation = ({
    phoneNumber,
    confirmationCode,
    submittedPhoneNumber,
}: {
    phoneNumber: string;
    confirmationCode: string;
    submittedPhoneNumber: string;
}) => {
    const [showPhoneNumberError, setShowPhoneNumberErrorState] = React.useState(false);
    const [showConfirmationCodeError, setShowConfirmationCodeErrorState] = React.useState(false);

    const validation = React.useMemo(() => {
        return confirmPhoneNumberSchema.safeParse({ phoneNumber, confirmationCode });
    }, [phoneNumber, confirmationCode]);

    const setShowPhoneNumberError = React.useCallback(
        (next = true) => {
            setShowPhoneNumberErrorState(next ? phoneNumber !== '+1' : false);
        },
        [phoneNumber],
    );

    const setShowConfirmationCodeError = React.useCallback(
        (next = true) => {
            setShowConfirmationCodeErrorState(next ? confirmationCode !== '' : false);
        },
        [confirmationCode],
    );

    if (validation.success) {
        return { isFormValid: true, setShowPhoneNumberError, setShowConfirmationCodeError };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: !submittedPhoneNumber && !fieldErrors.phoneNumber?.[0],
        setShowPhoneNumberError,
        setShowConfirmationCodeError,
        phoneNumberError: (showPhoneNumberError && fieldErrors.phoneNumber?.[0]) || '',
        confirmationCodeError: (showConfirmationCodeError && fieldErrors.confirmationCode?.[0]) || '',
    };
};
