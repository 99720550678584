import { ResumeOrderAction } from '../../../../actions/practitioners/PauseOrResumeOrder/ResumeOrderAction';
import { CancelLiveDesignPreviewButton } from '../CancelLiveDesignPreviewButton.graphql';
import type { TrackingDetails } from '../useTrackingDetails.graphql';
import { addBusinessDays } from '@orthly/date-fns-business-days';
import { TextBubble } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import {
    PracticeUseTrackingDetailsLiveDdpReview_FragmentFragmentDoc,
    getFragmentData,
} from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { Format } from '@orthly/runtime-utils';
import { PauseOrderActionReason } from '@orthly/shared-types';
import { Button, CheckIcon, Grid, Text, styled } from '@orthly/ui-primitives';
import type { DoctorRequestHoldInfo } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';

const formatDateTime = (date: Date | string) => dayjs(date).format('MMM D, h:mmA');

const GreenCheckIcon = styled(CheckIcon)({ color: 'green' });

const LiveDesignReviewActionsRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'min-content min-content',
    gridColumnGap: '16px',
});

export function getOnHoldDetails(
    order: LabsGqlOrder,
    refetch: () => Promise<void>,
    holdTitle: string | null | undefined,
    doctorRequestInfo: DoctorRequestHoldInfo,
    liveDdpRequestFragment:
        | FragmentType<typeof PracticeUseTrackingDetailsLiveDdpReview_FragmentFragmentDoc>
        | undefined,
): TrackingDetails {
    const liveDdpRequest = getFragmentData(
        PracticeUseTrackingDetailsLiveDdpReview_FragmentFragmentDoc,
        liveDdpRequestFragment,
    );

    const getOnPauseReason = () => {
        switch (order.hold_details?.hold_subcategory) {
            case PauseOrderActionReason.PatientRequestHold:
                return 'The patient requesting a hold';
            case PauseOrderActionReason.RescanPatient:
                return 'Needing to rescan the patient';
            case PauseOrderActionReason.ReviewDesign:
                return 'Needing more time to review design';
            case PauseOrderActionReason.UpdateRx:
                return 'Needing to update the Rx';
            case PauseOrderActionReason.ScheduledLiveDdpReview:
                return 'Scheduling a live design review';
            case PauseOrderActionReason.Other:
                return 'Other reasons';
        }
    };

    if (liveDdpRequest?.calendly_event) {
        const designPreviewETA = order.practice_dates.digital_design_preview_estimated_completion_date
            ? new Date(order.practice_dates.digital_design_preview_estimated_completion_date)
            : new Date();
        const reviewDue = addBusinessDays(designPreviewETA, 2);
        const differenceInDays = dayjs(reviewDue).diff(dayjs(), 'day');

        return {
            needsAttention: false,
            title: `You scheduled a Live Design Review for `,
            titleDate: formatDateTime(liveDdpRequest.calendly_event.start_time),
            onPause: true,
            subtitle: (
                <>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        This order is on pause until your scheduled design review. A missed appointment will result in
                        the design being auto approved{' '}
                        {differenceInDays > 0
                            ? `after ${differenceInDays} days of the meeting`
                            : 'the same day of the meeting'}
                        .
                    </Text>
                    <LiveDesignReviewActionsRow>
                        <Button
                            variant={'primary'}
                            startIcon={'VideoIcon'}
                            href={liveDdpRequest.calendly_event.meeting_url}
                            fullWidth={false}
                        >
                            Join with Google Meet
                        </Button>
                        <CancelLiveDesignPreviewButton orderId={order.id} refetch={refetch} />
                    </LiveDesignReviewActionsRow>
                </>
            ),
        };
    }

    if (order.hold_details?.hold_is_practice_managed_pause) {
        const reason = getOnPauseReason();

        const daysOrderHasBeenOnHold = dayjs().diff(dayjs(order.hold_details.hold_created_at), 'day');
        const orderIsSlatedForDeletion = daysOrderHasBeenOnHold >= 43;
        const daysUntilDeletion = 45 - daysOrderHasBeenOnHold;

        if (order.hold_details?.hold_subcategory === PauseOrderActionReason.ScheduledLiveDdpReview) {
            return {
                title: 'Your scheduled Live Design Review has ended',
                subtitle: <Text variant={'body2'}>Your order will resume shortly.</Text>,
                onPause: true,
                needsAttention: false,
            };
        }

        return {
            title: 'Resume order to proceed',
            subtitle: (
                <Grid container style={{ gap: '8px' }} direction={'column'}>
                    {orderIsSlatedForDeletion ? (
                        <TextBubble>
                            <Text variant={'body2'}>
                                You have <strong>{Format.pluralize('day', daysUntilDeletion)}</strong> to resume the
                                order, after which your order will be deleted.
                            </Text>
                        </TextBubble>
                    ) : (
                        <Text variant={'body2'}>
                            You placed this order on pause due to <strong>'{reason}'</strong> on{' '}
                            {dayjs(order.hold_details.hold_created_at).format('MMM DD, hh:mm A')}.
                        </Text>
                    )}
                    <Grid item>
                        <ResumeOrderAction order={order} buttonVariant={'primary'} />
                    </Grid>
                </Grid>
            ),
            onPause: true,
            needsAttention: orderIsSlatedForDeletion,
        };
    }

    if (doctorRequestInfo.needsAdditionalScans) {
        return {
            title: 'Resolution of hold pending new scans',
            subtitle: 'You have opted to capture new scans.',
            needsAttention: true,
        };
    }

    if (order.hold_in_review) {
        return {
            title: (
                <Grid container alignItems={'center'} style={{ gap: '0.5ch' }}>
                    <GreenCheckIcon />
                    Hold is pending resolution
                </Grid>
            ),
        };
    }

    return {
        needsAttention: true,
        title: holdTitle ? `On hold: ${holdTitle}` : 'On hold',
    };
}
