import { useChatAction } from './chat-state/chat.actions';
import { isCsAvailable } from '@orthly/date-fns-business-days';
import { useFeatureFlag } from '@orthly/veneer';

export const useChatFeatures = () => {
    const isAvailable = isCsAvailable(new Date());

    const openZDChatAction = useChatAction('OPEN_ZD_CHAT');
    const { value: chatAvailability } = useFeatureFlag('chatAvailability');
    const openChat = () => openZDChatAction(chatAvailability);

    return { openChat, isAvailable };
};
