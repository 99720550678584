import { Grid } from '@orthly/ui-primitives';

type Props = {
    Content: React.ReactNode;
    rightContent?: React.ReactNode;
};

export const PracticeSettingsBaseContent: React.FC<Props> = props => {
    return props.rightContent ? (
        <Grid container style={{ paddingBottom: 80 }}>
            <Grid item xs={8}>
                {props.Content}
            </Grid>
            <Grid container xs={4} style={{ justifyContent: 'end', paddingRight: 24 }}>
                {props.rightContent}
            </Grid>
        </Grid>
    ) : (
        <Grid item xs={12} style={{ minHeight: 0, overflowY: 'auto' }}>
            {props.Content}
        </Grid>
    );
};
