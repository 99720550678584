import { TOOLBAR_TABLET_DOWN_HEIGHT, useScreenIsMd } from '@orthly/ui';
import constate from 'constate';
import React from 'react';
import type { InViewHookResponse } from 'react-intersection-observer';
import { useInView } from 'react-intersection-observer';

function useOrderDetailSectionContext(rootRef: React.MutableRefObject<HTMLDivElement | null>) {
    const screenIsMd = useScreenIsMd();

    const [tabValue, setTabValue] = React.useState('overview');
    const [previousTabValue, setPreviousTabValue] = React.useState<string>('overview');
    const topBarRef = React.useRef<HTMLDivElement | null>(null);

    const topBarHeight = topBarRef?.current?.clientHeight ?? 0;

    const viewProps = {
        root: rootRef?.current,
        rootMargin: `-${topBarHeight + (screenIsMd ? TOOLBAR_TABLET_DOWN_HEIGHT : 0) + 4}px 0px 0px 0px`,
    };

    const useRefs = (ref: React.MutableRefObject<HTMLDivElement | undefined>, inViewObj: InViewHookResponse) =>
        React.useCallback(
            node => {
                ref.current = node;
                inViewObj?.ref(node);
            },
            [inViewObj, ref],
        );

    const overviewRef = React.useRef<HTMLDivElement | undefined>();
    const detailsRef = React.useRef<HTMLDivElement | undefined>();
    const designRef = React.useRef<HTMLDivElement | undefined>();
    const scansRef = React.useRef<HTMLDivElement | undefined>();
    const photosRef = React.useRef<HTMLDivElement | undefined>();

    const overviewInViewObj = useInView(viewProps);
    const detailsInViewObj = useInView(viewProps);
    const designInViewObj = useInView(viewProps);
    const scansInViewObj = useInView(viewProps);
    const photosInViewObj = useInView(viewProps);

    const setOverviewRefs = useRefs(overviewRef, overviewInViewObj);
    const setDetailsRefs = useRefs(detailsRef, detailsInViewObj);
    const setDesignRefs = useRefs(designRef, designInViewObj);
    const setScansRefs = useRefs(scansRef, scansInViewObj);
    const setPhotosRefs = useRefs(photosRef, photosInViewObj);

    const scrollToSection = (value: string, behavior: ScrollBehavior = 'smooth') => {
        let ref;

        switch (value) {
            case 'overview':
                ref = overviewRef;
                break;
            case 'design':
                ref = designRef;
                break;
            case 'details':
                ref = detailsRef;
                break;
            case 'scans':
                ref = scansRef;
                break;
            case 'photos':
                ref = photosRef;
                break;
        }

        ref?.current?.scrollIntoView({ behavior, block: 'start' });
    };

    return {
        tabValue,
        previousTabValue,
        setTabValue,
        setPreviousTabValue,
        topBarHeight,
        topBarRef,
        overviewInViewObj,
        detailsInViewObj,
        designInViewObj,
        scansInViewObj,
        photosInViewObj,
        setOverviewRefs,
        setDetailsRefs,
        setDesignRefs,
        setScansRefs,
        setPhotosRefs,
        scrollToSection,
    };
}

export const [OrderDetailSectionProvider, useOrderDetailSection] = constate(useOrderDetailSectionContext);
