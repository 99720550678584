import { PhoneNumberUtils } from '@orthly/runtime-utils';
import React from 'react';
import { z } from 'zod';

const phoneLoginSchema = z
    .object({
        phoneNumber: z.string(),
    })
    .refine(
        ({ phoneNumber }) =>
            PhoneNumberUtils.isValidPhoneNumber(phoneNumber) || phoneNumber === '+1' || phoneNumber === '',
        {
            message: 'Invalid phone number',
            path: ['phoneNumber'],
        },
    );

export const usePhoneNumberValidation = (phoneNumber: string) => {
    const validation = React.useMemo(() => phoneLoginSchema.safeParse({ phoneNumber }), [phoneNumber]);

    if (validation.success) {
        return { isFormValid: true };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: !fieldErrors.phoneNumber?.[0],
        phoneNumberError: fieldErrors.phoneNumber?.[0],
    };
};
