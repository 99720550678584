import { useAddPhoneNumberValidation } from '../../../../components/forms/ConfirmPhoneNumberForm/useAddPhoneNumberValidation';
import { useGetUserFromDrPrefId } from '../../../../graphql/useGetUserFromDrPrefId.graphql';
import { useSendOneTimeCode } from '../../../../graphql/useSendOneTimeCode.graphql';
import { useUserExistsWithMobileNumber } from '../../../../graphql/useUserExistsWithMobileNumber.graphql';
import { useVerifyLoginPhoneNumber } from '../../graphql/useVerifyLoginPhoneNumber.graphql';
import { useDoctorId } from '../../state/useDoctorId';
import { useSkipPhoneNumberForm } from '../../state/useSkipAddPhone';
import { PracticeScreen } from '@orthly/dentin';
import constate from 'constate';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const [AddPhoneNumberProvider, useAddPhoneNumber] = constate(() => {
    const history = useHistory();
    const skipForm = useSkipPhoneNumberForm();
    const [phoneNumber, setPhoneNumberState] = React.useState('');
    const [confirmationCode, setConfirmationCodeState] = React.useState('');
    const [submittedPhoneNumber, setSubmittedPhoneNumberState] = React.useState('');
    const [userFoundError, setUserFoundError] = React.useState('');

    const drPrefId = useDoctorId();
    const { user } = useGetUserFromDrPrefId(drPrefId);

    React.useEffect(() => {
        if (skipForm || Boolean(user?.mobile_phone_number && user?.is_mobile_verified)) {
            history.replace('/inbox');
        }
    }, [history, skipForm, user?.is_mobile_verified, user?.mobile_phone_number]);

    const {
        submit: sendCode,
        submitting: submittingSendCode,
        error: sendCodeError,
    } = useSendOneTimeCode({ onSuccess: () => setSubmittedPhoneNumberState(phoneNumber) });

    const {
        submit: verifyPhone,
        submitting: submittingVerifyPhone,
        error: verifyPhoneError,
    } = useVerifyLoginPhoneNumber({
        onSuccess: () => {
            setSubmittedPhoneNumberState(phoneNumber);
            history.push(PracticeScreen.inbox);
        },
    });

    const {
        submit: checkUserExists,
        loading: loadingUserExists,
        error: userExistsError,
    } = useUserExistsWithMobileNumber({
        onSuccess: userExists => {
            if (userExists) {
                setUserFoundError('This phone number is already in use.');
            } else {
                void sendCode(phoneNumber);
            }
        },
    });

    const {
        isFormValid,
        phoneNumberError,
        confirmationCodeError,
        setShowPhoneNumberError,
        setShowConfirmationCodeError,
    } = useAddPhoneNumberValidation({
        phoneNumber,
        confirmationCode,
        submittedPhoneNumber,
    });

    const hideConfirmation = () => {
        setShowConfirmationCodeError(false);
        setSubmittedPhoneNumberState('');
        setConfirmationCodeState('');
    };

    const setConfirmationCode = (next = '') => {
        setConfirmationCodeState(next.slice(0, 6));
    };

    const setPhoneNumber = (next = '') => {
        setPhoneNumberState(next);
        hideConfirmation();
    };

    const sendConfirmationCode = () => {
        void checkUserExists(phoneNumber);
    };

    const verifyPhoneNumber = () => {
        void verifyPhone(drPrefId, phoneNumber, confirmationCode, user?.id ?? '');
    };

    return {
        submittedPhoneNumber,
        phoneNumber,
        confirmationCode,
        setPhoneNumber,
        setConfirmationCode,

        phoneNumberError,
        confirmationCodeError,
        setShowPhoneNumberError,
        setShowConfirmationCodeError,

        hideConfirmation,

        verifyPhoneNumber,
        submittingVerifyPhone,
        verifyPhoneError,

        sendConfirmationCode,
        submittingSendCode,
        userExistsError,
        formError: sendCodeError || userFoundError,

        shouldContinue: !loadingUserExists && !submittingVerifyPhone && !submittingSendCode && isFormValid,
    };
});
