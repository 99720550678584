import type { LabsGqlAlignerCasePhotoType as AlignerPhotoType } from '@orthly/graphql-schema';
import {
    BottomBiteIcon,
    FrontBiteIcon,
    FrontNoSmileIcon,
    FrontSmileIcon,
    LeftBiteIcon,
    RightBiteIcon,
    SideProfileIcon,
    ToothXrayIcon,
    TopBiteIcon,
} from '@orthly/ui';
import type { SvgIconProps } from '@orthly/ui-primitives';

export type UploadedImageSourceMap = { [K in AlignerPhotoType]?: string | null };

export const ImageTypeToTitleMap: { [k in AlignerPhotoType]-?: string } = {
    LeftBite: 'Left bite',
    RightBite: 'Right bite',
    FrontBite: 'Front bite',
    MandibularOcclusion: 'Lower Occlusal',
    MaxillaryOcclusion: 'Upper Occlusal',
    PanoramicXray: 'X-ray Pano (preferred) or FMX',
    FrontPortraitSmile: 'Portrait (smiling)',
    FrontPortraitNoSmile: 'Portrait (not smiling)',
    SideView: 'Side View',
};

export const AlignerTypeIconMap: { [K in AlignerPhotoType]-?: React.ComponentType<SvgIconProps> } = {
    LeftBite: LeftBiteIcon,
    RightBite: RightBiteIcon,
    FrontBite: FrontBiteIcon,
    MandibularOcclusion: BottomBiteIcon,
    MaxillaryOcclusion: TopBiteIcon,
    PanoramicXray: ToothXrayIcon,
    FrontPortraitSmile: FrontSmileIcon,
    FrontPortraitNoSmile: FrontNoSmileIcon,
    SideView: SideProfileIcon,
};
