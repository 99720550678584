import { useIsChairside } from '../../utils/chairside.hooks';
import { useInactivityTimer } from '../../utils/useInactivityTimer';
import { loadStaffProfileInfoFromLocalStorage } from './select-staff.util';
import { useSelectStaffAction } from './state/select-staff.actions';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import isElectron from 'is-electron';
import React from 'react';
import { useHistory } from 'react-router-dom';

const TEN_MINUTES_MS = 10 * 60 * 1000;

export function useDoctorInactivityTimer() {
    const logoutStaff = useSelectStaffAction('LOGOUT_STAFF');
    const chairside = useIsChairside();
    const wasInactive = useInactivityTimer(TEN_MINUTES_MS);

    React.useEffect(() => {
        // We only want to sign the user out automatically if the user is using
        // the uploader app in an operating room; in those cases, the app is an electron app,
        // thus an `isElectron` call. We don't want to do this for chairside which is also electron,
        // so we check that we're not in chairside mode.
        if (wasInactive && isElectron() && !chairside) {
            logoutStaff();
        }
    }, [wasInactive, chairside, logoutStaff]);
}

function useLoadEmployeeFromLocalStorage() {
    const selectEmployee = useSelectStaffAction('SELECT_PRACTICE_EMPLOYEE');
    const { hasRetainerToken } = useStaffMemberLoginProps();

    return React.useCallback(() => {
        const employee = loadStaffProfileInfoFromLocalStorage();
        if (employee?.staff_member_id && hasRetainerToken(employee.staff_member_id)) {
            selectEmployee(employee);
        }
    }, [hasRetainerToken, selectEmployee]);
}

// automatically sets logged in doctor from url params or local storage
// returns whether or not we've completed initializing yet
export function useInitializeSelectedDoctor(employees: LabsGqlDoctorPreferencesFragment[], loading: boolean): boolean {
    const history = useHistory();
    const [initialized, setInitialized] = React.useState<boolean>(false);
    const skipEmployee = useSelectStaffAction('SKIP_SELECT_STAFF');
    const selectEmployee = useSelectStaffAction('SELECT_PRACTICE_EMPLOYEE');
    const loadEmployeeFromLocalStorage = useLoadEmployeeFromLocalStorage();

    // once we've used the query param we want to clean it from the url
    // this solves issues such as when a user tries to logout, they don't get auto-logged in again
    const clearQueryParam = React.useCallback(() => {
        const params = new URLSearchParams(history.location.search);
        if (params.has('doctorId') && !loading) {
            params.delete('doctorId');
            history.replace({ search: params.toString() });
        }
    }, [history, loading]);

    React.useEffect(() => {
        // once we've initialized once, we don't want to do it again
        if (initialized) {
            return;
        }

        // we first look at url params - explicitly setting the doctor_id will always
        // override state saved in local storage
        const params = new URLSearchParams(history.location.search);
        const doctorId = params.get('doctorId');

        // if we don't have a url param, then we get the default state
        // based on what's been persisted in local state
        if (!doctorId) {
            loadEmployeeFromLocalStorage();
            setInitialized(true);
            return;
        }

        if (doctorId === 'staff') {
            skipEmployee();
            clearQueryParam();
            setInitialized(true);
            return;
        }

        // don't look for a doctor before they're loaded
        if (loading) {
            return;
        }

        const foundEmployee = employees.find(d => d.id === doctorId);
        if (foundEmployee) {
            selectEmployee(foundEmployee);
        }
        clearQueryParam();

        // even if we had nothing to set, we set initialized to true because we're done looking
        setInitialized(true);
    }, [
        initialized,
        setInitialized,
        history,
        loadEmployeeFromLocalStorage,
        skipEmployee,
        selectEmployee,
        clearQueryParam,
        employees,
        loading,
    ]);

    return initialized;
}
