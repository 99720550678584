import { PRACTICE_CHECKOUT_PATHNAME } from '../checkout-v2/state/CheckoutPaths';

// please use the firestoreProductName from the PhotoUploadConfig as the slug here so that Chairside can be easily compatible too
export const ATTACH_CHECKOUT_FILES_PATH = `${PRACTICE_CHECKOUT_PATHNAME}/notes/attach_photos`;
export const ATTACH_ALIGNERS_PHOTO_PATH = `${PRACTICE_CHECKOUT_PATHNAME}/aligners/attach_photos`;
export const ATTACH_IMPLANT_XRAY_PHOTO_PATH = `${PRACTICE_CHECKOUT_PATHNAME}/implants/attach_photos`;
export const ATTACH_PHOTOS_PATH = `${PRACTICE_CHECKOUT_PATHNAME}/attach_photos`;
export const ATTACH_DENTURES_PHOTO_PATH = `${PRACTICE_CHECKOUT_PATHNAME}/dentures/attach_photos`;
export const ATTACH_WELLNESS_SCAN_PHOTO_PATH = `${PRACTICE_CHECKOUT_PATHNAME}/wellness_scan/attach_photos`;
export const ATTACH_AESTHETICS_PHOTO_PATH = `${PRACTICE_CHECKOUT_PATHNAME}/aesthetics/attach_photos`;
