import { Section } from '../../../components/Section';
import { FieldGroup } from '../FieldGroup.styles';
import { useAccountSettings } from '../context';
import { SimplePhoneInput } from '@orthly/ui';
import { TextField } from '@orthly/ui-primitives';

export const AccountInformationSection: React.FC = () => {
    const {
        accountFirstName,
        setAccountFirstName,
        accountLastName,
        setAccountLastName,
        accountEmail,
        setAccountEmail,
        accountPhone,
        setAccountPhone,
        accountPhoneError,
        accountEmailError,
        firstNameRequiredError,
        lastNameRequiredError,
        accountEmailRequiredError,
        verifyPhoneProps: { userFoundError },
    } = useAccountSettings();

    return (
        <Section
            title={'Account information'}
            subtitle={
                <>
                    <div>Used to login and recover password</div>
                    <div>Make sure the email address is work-accessible for password recovery</div>
                </>
            }
        >
            <FieldGroup>
                <TextField
                    label={'First name'}
                    data-testid={'first-name'}
                    fullWidth
                    variant={'standard'}
                    value={accountFirstName}
                    onChange={event => setAccountFirstName(event.target.value)}
                    required
                    helperText={firstNameRequiredError}
                    error={!!firstNameRequiredError}
                />
                <TextField
                    label={'Last name'}
                    data-testid={'last-name'}
                    fullWidth
                    variant={'standard'}
                    value={accountLastName}
                    onChange={event => setAccountLastName(event.target.value)}
                    required
                    helperText={lastNameRequiredError}
                    error={!!lastNameRequiredError}
                />
                <TextField
                    label={'Login email address'}
                    data-testid={'login-email'}
                    fullWidth
                    variant={'standard'}
                    value={accountEmail}
                    onChange={event => setAccountEmail(event.target.value)}
                    required
                    helperText={accountEmailError || accountEmailRequiredError}
                    error={!!accountEmailError || !!accountEmailRequiredError}
                />
                <SimplePhoneInput
                    label={'Login phone number'}
                    data-testid={'login-phone'}
                    fullWidth
                    variant={'standard'}
                    value={accountPhone}
                    onChange={value => setAccountPhone(value ?? '')}
                    TextFieldProps={{
                        type: 'tel',
                        error: !!accountPhoneError || !!userFoundError,
                        helperText: accountPhoneError || userFoundError,
                    }}
                />
            </FieldGroup>
        </Section>
    );
};
