import { AlignerTeethSelectionContent } from './AlignerTeethSelectionContent';
import type { OrderItemArch, ToothNumber } from '@orthly/items';
import React from 'react';

interface AlignerTeethSelectionProps {
    arch?: OrderItemArch | null;
    value: ToothNumber[] | null;
    onChange: (val: ToothNumber[] | null) => void;
    small?: boolean;
    hideDisclosure?: boolean;
}

export const AlignerMovementRestrictionsSelection: React.VFC<AlignerTeethSelectionProps> = props => (
    <AlignerTeethSelectionContent
        {...props}
        disableButtonLabel={'No restrictions at all (move any teeth that are needed)'}
        enableButtonLabel={'Restrict certain teeth'}
        pickerHeader={{
            title: 'Select which teeth you want to restrict',
            subtitle: 'Make sure you select implants, bridges, ankylosed teeth, and anything else you find relevant.',
        }}
        disclosure={
            props.hideDisclosure
                ? undefined
                : 'Treating doctor solely responsible for completing and interpreting diagnostic records.'
        }
    />
);

export const AlignerAttachmentRestrictionsSelection: React.VFC<AlignerTeethSelectionProps> = props => (
    <AlignerTeethSelectionContent
        {...props}
        disableButtonLabel={'Place attachments as needed'}
        enableButtonLabel={'Do not place attachments on certain teeth'}
        pickerHeader={{
            title: 'Select which teeth should not receive attachments',
            subtitle: 'Note that attachments on buccal restorations may not be stable.',
        }}
        disclosure={
            props.hideDisclosure
                ? undefined
                : 'Treating doctor solely responsible for completing and interpreting diagnostic records.'
        }
    />
);

export const AlignerExtractionSelection: React.VFC<AlignerTeethSelectionProps> = props => (
    <AlignerTeethSelectionContent
        {...props}
        disableButtonLabel={'No'}
        enableButtonLabel={'Yes'}
        pickerHeader={{
            title: `Select which teeth you want to extract`,
        }}
    />
);
