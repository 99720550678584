import type { InlinePrefSubmission } from './InlinePrefsSection';
import { InlinePrefsSection } from './InlinePrefsSection';
import { ItemsSectionDisplayClasses } from './util/displayClasses';
import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import type { IOrderItemV2DTO } from '@orthly/items';
import React from 'react';

interface GroupItemSectionProps {
    item: Pick<IOrderItemV2DTO, 'item_notes'>;
    index: number;
    inlinePrefs: InlinePrefSubmission[];
    Title: React.ReactNode;
    Rows: React.ReactNode[];
}

export const GroupItemSection = ({ item, index, inlinePrefs, Title, Rows }: GroupItemSectionProps) => {
    const { ContainerComponent } = useOrderSummaryComponents();

    return (
        <ContainerComponent style={{ borderTop: index !== 0 ? '1pt solid lightgray' : undefined }}>
            {Title}
            <ContainerComponent
                className={ItemsSectionDisplayClasses.DETAIL_ROW_WRAPPER}
                style={{
                    borderLeft: '2pt solid silver',
                    marginTop: '10pt',
                    paddingLeft: '10pt',
                    marginBottom: '15pt',
                }}
            >
                <ContainerComponent style={{ width: '100%', flexDirection: 'column' }}>
                    {Rows.map((Row, idx) => (
                        <ContainerComponent key={idx} style={{ width: '100%', flexDirection: 'row' }}>
                            {Row}
                        </ContainerComponent>
                    ))}
                </ContainerComponent>
                <InlinePrefsSection inlinePrefs={inlinePrefs} item_notes={item.item_notes} />
            </ContainerComponent>
        </ContainerComponent>
    );
};
