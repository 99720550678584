import { createStyles, Grid, makeStyles, FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        count: {
            height: 16,
            minWidth: 20,
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            marginLeft: 8,
            paddingRight: 4,
            paddingLeft: 4,
            width: `auto`,
        },
        tabWithCount: {
            justifyContent: 'center',
            alignItems: 'center',
        },
    }),
);

interface TabWithCountProps {
    count?: number;
    label?: React.ReactNode;
    active?: boolean;
    critical?: boolean;
}

export const TabWithCount: React.FC<TabWithCountProps> = (props: TabWithCountProps) => {
    const { count, label, active = true, critical = false } = props;
    const classes = useStyles();

    return (
        <Grid container direction={'row'} className={classes.tabWithCount}>
            <Text variant={'body2'} medium color={active ? 'GREEN' : 'GRAY'}>
                {label}
            </Text>
            {count ? (
                <Grid
                    container
                    className={classes.count}
                    style={{
                        backgroundColor: active && critical ? FlossPalette.ATTENTION_FOREGROUND : FlossPalette.DARK_TAN,
                    }}
                >
                    <Text
                        variant={'caption'}
                        style={{
                            color: active && critical ? FlossPalette.WHITE : FlossPalette.GRAY,
                            fontWeight: 600,
                            lineHeight: 1.4,
                        }}
                    >
                        {count}
                    </Text>
                </Grid>
            ) : null}
        </Grid>
    );
};
