import type { PartnerAppState } from '../../../redux/types';
import type { ImplantReadinessState } from './ImplantReadiness.types';
import { generateUseStatePropSelector, generateUseSubstateSelector } from '@orthly/redux-async-actions';

const useImplantReadinessSelector = generateUseSubstateSelector<PartnerAppState, 'implantReadiness'>(
    'implantReadiness',
);

export const useImplantReadinessPropSelector = generateUseStatePropSelector<ImplantReadinessState>(
    useImplantReadinessSelector,
    'implantReadiness',
);
