import { useBlobObjectURL } from '../../hooks/useFirebaseStorageSearchWatch';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { CameraIcon, SmallTrashCanIcon, ExpandIcon } from '@orthly/ui';
import {
    FlossPalette,
    FlossPaletteUtils,
    stylesFactory,
    useScreenIsMobile,
    Dialog,
    Grid,
    IconButton,
    Button,
    Text,
} from '@orthly/ui-primitives';
import type { FileUploaderControlledPreviewProps } from '@orthly/veneer';
import { FileUploaderControlledPreview } from '@orthly/veneer';
import clsx from 'clsx';
import type firebase from 'firebase/compat';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

const useStyles = stylesFactory(() => ({
    iconButton: {
        padding: 0,
    },
    imgIcon: {
        height: 48,
        width: 48,
        color: FlossPalette.GRAY,
    },
    icon: {
        height: 24,
        width: 24,
    },
    deleteIconWrapper: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        justifyContent: 'center',
        alignItems: 'flex-end',
        padding: 12,
    },
    previewImageWrapper: {
        alignContent: 'center',
        justifyContent: 'flex-start',
    },
    dropzoneContent: {
        backgroundColor: FlossPalette.TAN,
        height: '100%',
        padding: 12,
    },
    previewImage: {
        width: '100%',
        height: 200,
        objectFit: 'contain',
        borderRadius: 8,
        backgroundColor: FlossPalette.GRAY,
    },
    floatAnchor: { position: 'relative' },
    floatTopRight: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
    expandIcon: {
        borderRadius: 16,
        padding: 4,
        height: 32,
        width: 32,
        color: FlossPalette.STAR_GRASS,
        backgroundColor: FlossPalette.WHITE,
        boxShadow: `0 0 0 3px ${FlossPaletteUtils.toRgba('BLACK', 0.16)}`,
    },
}));

const DropzoneContent: React.FC<{ isDesktop?: boolean }> = ({ isDesktop = true }) => {
    const classes = useStyles();
    return (
        <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className={classes.dropzoneContent}
            style={{ padding: isDesktop ? 24 : undefined }}
        >
            {isDesktop ? (
                <>
                    <Text variant={'body2'} style={{ marginTop: 16 }}>
                        Drop your photo here
                    </Text>
                    <Text variant={'body2'}>or</Text>
                    <Button variant={'secondary'} startIcon={'AttachIcon'} style={{ marginBottom: 16 }}>
                        Browse files
                    </Button>
                </>
            ) : (
                <CameraIcon
                    preserveAspectRatio={'xMidYMin'}
                    color={'secondary'}
                    className={classes.icon}
                    style={{ color: FlossPalette.WHITE, background: FlossPalette.STAR_GRASS }}
                />
            )}
        </Grid>
    );
};

interface ImageUploadPreviewFullScreenProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    src?: string;
    alt?: string;
}

const ImageUploadPreviewFullScreen: React.FC<ImageUploadPreviewFullScreenProps> = props => {
    const classes = useStyles();
    const { open, setOpen, src, alt } = props;
    return (
        <Dialog
            open={open}
            fullScreen
            PaperProps={{
                elevation: 0,
                style: { backgroundColor: 'transparent', height: '100vh', alignItems: 'center' },
            }}
        >
            <Grid item container justifyContent={'flex-end'} className={classes.floatAnchor}>
                <Button
                    variant={'secondary'}
                    endIcon={'XIcon'}
                    style={{ position: 'absolute', top: 24, right: 24 }}
                    onClick={() => setOpen(false)}
                >
                    Close Image
                </Button>
            </Grid>
            <img src={src} alt={alt} style={{ width: '100%', height: '100%', padding: 52, objectFit: 'contain' }} />
        </Dialog>
    );
};

const ImageUploadPreview: FileUploaderControlledPreviewProps['PreviewComponent'] = props => {
    const classes = useStyles();
    const src = useBlobObjectURL(props.file);
    const [fullScreen, setFullScreen] = React.useState<boolean>(false);
    return (
        <Grid container className={classes.previewImageWrapper} style={props.style}>
            <Grid item container justifyContent={'flex-end'} className={classes.floatAnchor}>
                <IconButton
                    onClick={() => setFullScreen(true)}
                    className={clsx([classes.iconButton, classes.floatTopRight])}
                >
                    <ExpandIcon preserveAspectRatio={'xMidYMin'} color={'primary'} className={classes.expandIcon} />
                </IconButton>
                <ImageUploadPreviewFullScreen
                    src={src}
                    alt={'Scan body Report'}
                    open={fullScreen}
                    setOpen={setFullScreen}
                />
            </Grid>
            <img src={src} alt={'Scan body Report'} className={classes.previewImage} style={{ marginBottom: 8 }} />
            <Grid item container justifyContent={'space-between'}>
                <Text variant={'body2'}>Scan body Report</Text>
                <IconButton onClick={props.onReset} className={classes.iconButton}>
                    <SmallTrashCanIcon
                        preserveAspectRatio={'xMidYMin'}
                        style={{ color: FlossPalette.BLACK }}
                        className={classes.icon}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

interface ScanbodySurgicalReportUploaderFileSelectionProp {
    onBackToMethodSelection?: () => void;
    refresh: () => Promise<void>;
    metadata?: firebase.storage.FullMetadata;
    blob?: Blob;
    storagePathConfig: BucketStoragePathConfig;
    overrideFileName: string;
}

export const ScanbodySurgicalReportUploaderFileSelection: React.FC<
    ScanbodySurgicalReportUploaderFileSelectionProp
> = props => {
    const { blob, refresh, metadata } = props;
    const isMobile = useScreenIsMobile();

    const fileArrayWithMimeType = React.useMemo<File[]>(
        /* FileUploaderControlledPreview expects a File with mime type
         * 1. fetch returns a bare Blob
         * 2. the uploader doesn't set mime type anyway.
         *    in current flow files are just application/octet-stream
         */
        () => (blob ? [new File([blob], metadata?.name ?? '', { type: 'image/*' })] : []),
        [blob, metadata],
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const manualRefresh = useAsyncCallback(async () => {
        setLoading(true);
        await refresh();
        setLoading(false);
    });

    return (
        <Grid container style={{ width: isMobile ? '100%' : 296 }}>
            {props.onBackToMethodSelection && !isMobile && (
                <Button
                    variant={'secondary'}
                    onClick={props.onBackToMethodSelection}
                    style={{ marginBottom: 16 }}
                    endIcon={'CameraIcon'}
                >
                    Re-scan code
                </Button>
            )}
            <FileUploaderControlledPreview
                autoSubmit
                deleteOnReset
                processImages
                overrideFileName={props.overrideFileName}
                storagePathConfig={props.storagePathConfig}
                loadBlockerContainerProps={{ style: { justifyContent: 'center' } }}
                paperStyle={{ padding: 0 }}
                elevation={0}
                dropzoneWrapperStyle={{ padding: 0 }}
                dropzoneOptions={{
                    accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] },
                    multiple: false,
                }}
                dropzoneContent={<DropzoneContent />}
                PreviewComponent={ImageUploadPreview}
                onReset={manualRefresh.execute}
                onComplete={manualRefresh.execute}
                files={fileArrayWithMimeType}
                loading={loading}
            />
        </Grid>
    );
};
