import { useSetScreen } from '../../../utils/useSetScreen';
import type { PracticeScreen } from '@orthly/dentin';
import type { SubNavigationEntry } from '@orthly/ui';
import { SubNavigation } from '@orthly/ui';
import { stylesFactory } from '@orthly/ui-primitives';
import React from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    list: {
        width: '100%',
        bottom: 80,
        top: 56,
        position: 'absolute',
        overflow: 'auto',
        [theme.breakpoints.down('sm')]: {
            top: '0',
            bottom: '0',
            position: 'relative',
        },
    },
    root: {
        padding: 0,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '@media print': {
            display: 'none',
        },
    },
}));

interface SettingsSection {
    subtitle: string;
    entries: SubNavigationEntry<string>[];
}

type StripPrefix<Prefix extends string, T extends string> = T extends `${Prefix}${infer Screen}` ? Screen : never;
type PracticeSettingsScreen = StripPrefix<'my_practice/', PracticeScreen>;
type ProfileSettingsScreen = StripPrefix<'my_profile/', PracticeScreen>;
const screens = {
    practiceSettings: [
        'doctors',
        'addresses',
        'payment',
        'settings',
        'staff',
        'pricing',
        'billing',
    ] as const satisfies readonly PracticeSettingsScreen[],
    profileSettings: [
        'account',
        'settings',
        'notifications',
        'preferences',
    ] as const satisfies readonly ProfileSettingsScreen[],
} as const;
type Selector = keyof typeof screens;

interface SettingsSubNavProps {
    sections: SettingsSection[];
    title: string;
    practiceScreen: PracticeScreen;
    selector: Selector;
}

const useActiveScreen = (selector: Selector) => {
    const location = useLocation();
    const locationMatch = (screen: string) => location.pathname.includes(screen);

    return selector === 'practiceSettings'
        ? screens.practiceSettings.find(locationMatch) ?? 'staff'
        : screens.profileSettings.find(locationMatch) ?? 'settings';
};

export const SettingsSubNav: React.FC<SettingsSubNavProps> = props => {
    const { sections, title, practiceScreen, selector } = props;
    const activeScreen = useActiveScreen(selector);
    const classes = useStyles();
    const setScreen = useSetScreen();

    return (
        <SubNavigation
            title={title}
            classes={{ list: classes.list, root: classes.root }}
            value={activeScreen}
            onChange={value => {
                setScreen(practiceScreen, `/${value}`)();
            }}
            sections={sections}
            collapseOnMobile
        />
    );
};
