import { useAlignerCheckoutPropSelector } from '../../../../redux';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { AlignerCrowdingResolutionSelection, CheckoutBodyWrapper } from '@orthly/dentin';
import React from 'react';

export const AlignerCheckoutCrowdingResolution: React.FC = () => {
    const { crowdingResolution } = useAlignerCheckoutPropSelector(['crowdingResolution']);
    const setCrowdingResolution = useAlignerCheckoutAction('SET_CROWDING_RESOLUTION');
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.CrowdingResolution);
    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <AlignerCrowdingResolutionSelection value={crowdingResolution} onChange={setCrowdingResolution} />
        </CheckoutBodyWrapper>
    );
};
