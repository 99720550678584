import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useShowMobileLayout } from '../../../utils/LayoutUtils';
import { Cart } from '../component/Cart';
import { Scanbody } from '../component/Scanbody';
import { renderWhatIsScanbodyTip, Tips } from '../component/Tip';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { useScanbodies } from './useScanbodies';
import { PracticeScreen } from '@orthly/dentin';
import { Divider, Grid, Button, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const Scanbodies: React.VFC = () => {
    const { scanbodies, implantReadinessId } = useImplantReadinessPropSelector(['scanbodies', 'implantReadinessId']);
    const { value: disableFreeScanBody } = useFeatureFlag('disableFreeScanBody');
    const [cartItems, setCartItems] = React.useState(scanbodies);
    const isMobileLayout = useShowMobileLayout();
    const setScanBodies = useImplantReadinessAction('SET_SCANBODIES');
    const { groups } = useScanbodies();

    React.useEffect(() => {
        if (scanbodies.length === 0) {
            setCartItems(groups.filter(group => !group.scanbody.inventory).map(group => group.scanbody));
        }
        groups.forEach(group => {
            AnalyticsClient.track('Practice - Implant Readiness - Scan Body Displayed', {
                implantReadinessId,
                previouslyShipped: !!group.scanbody.inventory,
            });
        });
        // TODO: I AM DISABLING THIS BECAUSE IT IS THE EXISTING BEHAVIOR. WE SHOULD REVISIT IT
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(groups), scanbodies, implantReadinessId]);

    const nextStep = () => {
        setScanBodies(cartItems);
    };

    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ gap: 16, flexGrow: 1, overflow: 'hidden' }}>
            <Grid container style={{ gap: 60, flexGrow: 1, overflowY: 'auto' }}>
                <div style={{ flexBasis: '50%', flexGrow: 1, minWidth: 600 }}>
                    <Grid container direction={'column'} style={{ gap: 16 }}>
                        <Text variant={'body2'} color={'BLACK'} bold>
                            You need the following at your practice
                        </Text>
                        {groups.map(group => (
                            <React.Fragment key={group.unn}>
                                {groups.length !== 1 && (
                                    <Text variant={'body2'} color={'GRAY'}>
                                        For Implant{' '}
                                        <Text variant={'body2'} color={'BURGUNDY'} bold component={'span'}>
                                            {group.unn}
                                        </Text>
                                    </Text>
                                )}
                                <Scanbody
                                    scanbody={group.scanbody}
                                    isInCart={!!cartItems.find(ci => ci.id === group.scanbody.id)}
                                    addToCart={() => {
                                        setCartItems(existingItems => [...existingItems, group.scanbody]);
                                        AnalyticsClient.track(
                                            'Practice - Implant Readiness - Scan Body Added To Cart',
                                            {
                                                implantReadinessId,
                                                previouslyShipped: !!group.scanbody.inventory,
                                            },
                                        );
                                    }}
                                />
                            </React.Fragment>
                        ))}
                    </Grid>
                </div>
                {!isMobileLayout && <Divider orientation={'vertical'} />}
                <Cart
                    cartItems={cartItems}
                    removeItem={id => setCartItems(existingItems => existingItems.filter(i => i.id !== id))}
                />
            </Grid>
            <Grid container alignItems={'center'} style={{ gap: 16 }}>
                <Button
                    variant={'primary'}
                    onClick={nextStep}
                    endIcon={'ChevronRight'}
                    style={{ width: 240 }}
                    disabled={cartItems.length === 0}
                >
                    Proceed to checkout
                </Button>
                {!disableFreeScanBody && (
                    <Text variant={'body2'} color={'GRAY'}>
                        Scan bodies are provided free of charge!
                    </Text>
                )}
            </Grid>
        </Grid>
    );
};

export const CartStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/scanbody`,
    component: Scanbodies,
    sidebar: ({ disableFreeScanBody }) => <Tips tips={[renderWhatIsScanbodyTip(disableFreeScanBody)]} />,
    pageTitle: ({ name }) => ({
        title: 'Wrapping up',
        header: `Great! We have matched the correct scan body to ${name}'s implant`,
    }),
};
