import type { PracticeUIState } from './ui';
import { createSyncAction } from '@orthly/redux-async-actions';

const PREFIX = 'ui';

export const PartnerUiActions = {
    SET_IS_PWA_MODE: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_IS_PWA_MODE`),
    UPDATE_UI: createSyncAction<Partial<PracticeUIState>, [Partial<PracticeUIState>]>(`${PREFIX}/UPDATE_UI`),
    SET_SPOTLIGHT_OPEN: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_SPOTLIGHT_OPEN`),
    SET_QR_CODE_OPEN: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_QR_CODE_OPEN`),
};
