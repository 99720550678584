import { chatReducer } from '../screens/chat/chat-state/chat.reducer';
import { cstReducer } from '../screens/chat/cst-state/cst.reducer';
import { alignerCheckoutReducer } from '../screens/checkout-v2/state/reducers/aligners-checkout.reducer';
import { checkoutReducer } from '../screens/checkout-v2/state/reducers/checkout.reducer';
import { dentureCheckoutReducer } from '../screens/checkout-v2/state/reducers/dentures-checkout.reducer';
import { feedbackReducer } from '../screens/feedback/state/feedback.reducer';
import { implantReadinessReducer } from '../screens/implant_readiness/state/ImplantReadiness.reducer';
import { mobileAuthReducer } from '../screens/mobile-auth/state/MobileAuth.reducer';
import { scanbodyRequestReducer } from '../screens/scanbodies/state/ScanbodyRequest.reducer';
import { staffMemberReducer } from '../screens/select-staff/state/select-staff.reducer';
import { tryInFeedbackReducer } from '../screens/try_in_feedback/state/TryInFeedback.reducer';
import { onboardingReducer } from './onboarding/onboarding.reducer';
import { ordersReducer } from './orders/orders.reducer';
import type { PartnerAppState } from './types';
import { uiReducer } from './ui';
import { createAsyncReducer } from '@orthly/redux-async-actions';
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';
import type { AnyAction } from 'redux';
import { combineReducers } from 'redux';

const reducer = (history: History) =>
    combineReducers<PartnerAppState>({
        async: createAsyncReducer(false),
        alignerCheckout: alignerCheckoutReducer,
        checkout: checkoutReducer,
        dentureCheckout: dentureCheckoutReducer,
        onboarding: onboardingReducer,
        orders: ordersReducer,
        feedback: feedbackReducer,
        router: connectRouter(history),
        ui: uiReducer,
        scanbodyRequest: scanbodyRequestReducer,
        implantReadiness: implantReadinessReducer,
        tryInFeedback: tryInFeedbackReducer,
        staffMember: staffMemberReducer,
        chat: chatReducer,
        mobileAuth: mobileAuthReducer,
        cst: cstReducer,
    });

export const rootReducer = (history: History) => (state: PartnerAppState | undefined, action: AnyAction) => {
    return reducer(history)(state, action);
};
export * from './selectors';
export * from './types';
