import type { TraineeFormFieldsProps } from '../TrainingDataIntake.types';
import { TrainingScannerOptions } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

export const TraineeScannerFormField: React.VFC<TraineeFormFieldsProps> = props => {
    const { trainee, setTrainee } = useTrainee(props.traineeAtom);

    return (
        <SimpleSelect
            flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
            options={TrainingScannerOptions.map(scanner => ({
                label: scanner,
                value: scanner,
            }))}
            onChange={value => {
                setTrainee(oldValue => ({ ...oldValue, scanner: value }));
            }}
            label={''}
            value={trainee.scanner}
            placeholder={'Select a scanner'}
            FormControlProps={{ style: { marginTop: -4 }, variant: 'standard' }}
            SelectProps={{
                'aria-label': `Select scanner for person ${props.listIndex + 1}`,
                variant: 'standard',
                style: { padding: 0 },
            }}
        />
    );
};
