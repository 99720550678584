import { useFirebaseStorage } from '../../context/firebase.context';
import { fetchFirebaseFile } from '../../util/fetchFirebaseFile';
import { FileUploaderCustomPreview } from '../FirebaseUpload/FileUploaderCustomPreview';
import { HelpTooltip } from './HelpTooltip';
import { ImageUploadPreview } from './ImageUploadPreview';
import type { PhotoSpec, PhotoMap } from './PhotoUpload.types';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { LoadBlocker, CameraIcon } from '@orthly/ui';
import { Text, Button, FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';
import { useAsync } from 'react-async-hook';

const useStyles = stylesFactory(() => ({
    emptyImg: {
        backgroundColor: FlossPalette.TAN,
        height: '100%',
        borderRadius: 8,
        padding: 16,
    },
    imgWrapper: {
        backgroundColor: FlossPalette.TAN,
        height: '100%',
        borderRadius: 8,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    photoGrid: {
        flexDirection: 'column',
        '@media (min-width: 767px)': {
            flexDirection: 'row',
        },
    },
    btnUpload: {
        marginTop: 16,
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        background: FlossPalette.STAR_GRASS,
        border: `solid 1px ${FlossPalette.STAR_GRASS}`,
        color: FlossPalette.WHITE,
    },
    imagePlaceholderIcon: {
        color: FlossPalette.GRAY,
        width: 48,
        height: 48,
    },
    dropzoneContent: {
        backgroundColor: FlossPalette.TAN,
        height: '100%',
        padding: 12,
    },
    actionIcon: {
        borderRadius: 36,
        padding: 4,
        height: 32,
        width: 32,
    },
}));

export interface SinglePhotoUploadProps {
    photoSpec: PhotoSpec;
    uploadedPhotos: PhotoMap;
    isDesktop: boolean;
    onUpload: () => void;
    onDelete: () => void;
    storagePathConfig: BucketStoragePathConfig;
}

/**
 * Renders the upload cell for a single photo.
 *
 * TODO make changes discussed in practice/src/screens/labs/README.md
 */
export function SinglePhotoUpload({
    photoSpec,
    uploadedPhotos,
    isDesktop,
    onUpload,
    onDelete,
    storagePathConfig,
}: SinglePhotoUploadProps) {
    const classes = useStyles();
    const uploadedImage = uploadedPhotos[photoSpec.filename];
    const { result: { file: initialFile } = {}, status } = useAsync(fetchFirebaseFile, [
        useFirebaseStorage(uploadedImage?.bucket),
        uploadedImage?.fullPath,
    ]);
    const Icon = photoSpec.icon;

    return (
        <>
            <Grid
                container
                item
                direction={'row'}
                wrap={'nowrap'}
                alignItems={'center'}
                justifyContent={'space-between'}
                style={{ marginBottom: 4, flexGrow: 0 }}
            >
                <Text variant={'body2'} medium={!!photoSpec.required}>
                    {photoSpec.userFacingTitle}
                    <span style={{ marginLeft: 8, color: FlossPalette.GRAY }}>
                        {photoSpec.required ? 'Required' : 'Optional'}
                    </span>
                </Text>
                <HelpTooltip>{photoSpec.tooltip}</HelpTooltip>
            </Grid>
            <Grid
                container
                item
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                className={uploadedImage ? classes.imgWrapper : classes.emptyImg}
                style={{ backgroundImage: `unset`, height: 232 }}
            >
                <LoadBlocker
                    blocking={!uploadedPhotos || status === 'loading'}
                    ContainerProps={{ style: { height: '100%' } }}
                >
                    <FileUploaderCustomPreview
                        key={!!initialFile ? 'initial' : 'upload'}
                        autoSubmit
                        deleteOnReset
                        processImages
                        overrideFileName={photoSpec.filename}
                        prependTimestampToFilename={true}
                        elevation={0}
                        storagePathConfig={storagePathConfig}
                        paperStyle={{ padding: 0, alignSelf: 'center', backgroundColor: FlossPalette.TAN }}
                        loadBlockerContainerProps={{ style: { justifyContent: 'center' } }}
                        dropzoneWrapperStyle={{ border: 0, padding: 0, height: '100%' }}
                        dropzoneTypographyStyle={{ height: '100%', width: '100%' }}
                        dropzoneOptions={{
                            accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] },
                            multiple: false,
                            ...photoSpec.overrideDropzoneOptions,
                        }}
                        PreviewComponent={ImageUploadPreview}
                        previewComponentStyle={{
                            borderRadius: 8,
                            overflow: 'hidden',
                            height: 224,
                        }}
                        initialFiles={initialFile ? [initialFile] : undefined}
                        dropzoneContent={
                            <Grid
                                container
                                direction={'column'}
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                className={classes.dropzoneContent}
                            >
                                {Icon && <Icon className={classes.imagePlaceholderIcon} />}
                                {isDesktop ? (
                                    <>
                                        <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginTop: 16 }}>
                                            Drop your photo here
                                        </Text>
                                        <Text variant={'body2'} color={'DARK_GRAY'}>
                                            or
                                        </Text>
                                        <Button
                                            variant={'secondary'}
                                            startIcon={'AttachIcon'}
                                            style={{ marginBottom: 16 }}
                                        >
                                            Browse files
                                        </Button>
                                    </>
                                ) : (
                                    <CameraIcon
                                        preserveAspectRatio={'xMidYMin'}
                                        color={'secondary'}
                                        className={classes.actionIcon}
                                        style={{ color: FlossPalette.WHITE, background: FlossPalette.STAR_GRASS }}
                                    />
                                )}
                            </Grid>
                        }
                        onComplete={() => onUpload()}
                        onReset={() => onDelete()}
                    />
                </LoadBlocker>
            </Grid>
        </>
    );
}
