import { useCheckoutSelector, useAlignerCheckoutSelector } from '../../../redux';
import { AlignerCheckoutStep } from './aligners-checkout.types';
import { useScanIsAligner } from './checkout.selectors';
import { useScanIsDenture, useDentureCheckoutSelector } from './dentures-checkout.selectors';
import { DentureCheckoutStep } from './reducers/dentures-checkout.types';

export function useCheckoutSummaryVisible() {
    const step2Complete = useCheckoutSelector(s => s.step === 2 && s.stepTwoScreen === 'summary');
    const isAligner = useScanIsAligner();
    const isDenture = useScanIsDenture();
    const alignerStepIsSummary = useAlignerCheckoutSelector(s => s.step === AlignerCheckoutStep.Summary);
    const dentureStepIsSummary = useDentureCheckoutSelector(s => s.currentStep === DentureCheckoutStep.Summary);

    return step2Complete || (isAligner && alignerStepIsSummary) || (isDenture && dentureStepIsSummary);
}
