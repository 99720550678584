import { Text, stylesFactory, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    checkoutBanner: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        padding: '16px 24px',
        borderRadius: 16,
        display: 'flex',
        alignItems: 'center',
    },
    photo: {
        flex: '0 0',
        marginRight: 24,
    },
}));

export interface CheckoutBannerConfig {
    head: React.ReactElement;
    subhead: React.ReactElement;
    imgSrc: string;
}

/**
 * A banner with an image, headline, and sub-headline.
 *
 * Figma: https://www.figma.com/file/MrsLKK2jUrHnDjTlVKIVVs/?node-id=0%3A1
 */
export const CheckoutBanner: React.VFC<{
    config: CheckoutBannerConfig;
    style?: React.CSSProperties;
}> = props => {
    const { head, subhead, imgSrc } = props.config;
    const classes = useStyles();
    return (
        <div className={classes.checkoutBanner} style={props.style}>
            <div className={classes.photo}>
                <img src={imgSrc} alt={'Click Photos'} />
            </div>
            <div>
                <Text variant={'h6'} bold>
                    {head}
                </Text>
                <Text variant={'body2'}>{subhead}</Text>
            </div>
        </div>
    );
};
