import { useCheckoutPropSelector } from '../../../redux';
import { useInputItemsV2 } from '../state/useInputItemsV2';
import { PRACTICE_CHECKOUT_SUBMITTED_PATHNAME } from './CheckoutPaths';
import { useIsScannerSelector } from './checkout.selectors';
import { useDentureCheckoutSelector } from './dentures-checkout.selectors';
import type { SubmittedOrder, UseSubmitOrderResult } from './orderUtils';
import { placeOrderMutationOptions } from './orderUtils';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlPlacedOrderFragment } from '@orthly/graphql-operations';
import {
    usePlaceDenturesFulfillmentCoreMutation,
    usePlaceDenturesFulfillmentWaxRimMutation,
    useContinueDenturesFulfillmentWaxRimMutation,
    useOrderRefetch,
    useScans,
} from '@orthly/graphql-react';
import type {
    LabsGqlPlaceDenturesFulfillmentCoreCommand,
    LabsGqlContinueDenturesFulfillmentWaxRimCommand,
    LabsGqlLabOrderItemShadeInput,
    LabsGqlDenturesFulfillmentCoreArchInput,
    LabsGqlPlaceDenturesFulfillmentWaxRimCommand,
} from '@orthly/graphql-schema';
import {
    LabsGqlDenturesSingleFulfillmentArchType,
    LabsGqlSmileStyleChoice,
    LabsGqlDenturesArchType,
    LabsGqlDenturesProductionType,
} from '@orthly/graphql-schema';
import { DentureType, OrderItemArch } from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

function useOnPlaceDentureOrderSuccess(isScanner: boolean) {
    const refetchPartialOrders = useOrderRefetch();
    const { refetch: refetchScans } = useScans({ fetchPolicy: 'cache-first' });
    const [submittedOrder, setSubmittedOrder] = React.useState<SubmittedOrder | undefined>();
    const history = useHistory();
    const onSuccess = React.useCallback(
        async (placedFrag?: LabsGqlPlacedOrderFragment) => {
            if (!placedFrag) {
                return;
            }
            setSubmittedOrder(placedFrag);
            // reset checkout redux state
            // only fetch if not in scanner submit mode (auth would fail)
            if (!isScanner) {
                refetchPartialOrders(placedFrag.id);
                await refetchScans();
            }
            history.push(`/${PracticeScreen.orders}/${PRACTICE_CHECKOUT_SUBMITTED_PATHNAME}/${placedFrag.id}`);
        },
        [history, isScanner, refetchPartialOrders, refetchScans],
    );
    return { onSuccess, submittedOrder };
}

type SubmitDentureOrderArgs = {
    waxRimSubmitArgs: LabsGqlPlaceDenturesFulfillmentWaxRimCommand;
    coreSubmitArgs: LabsGqlPlaceDenturesFulfillmentCoreCommand;
    waxRimContinuationSubmitArgs: LabsGqlContinueDenturesFulfillmentWaxRimCommand;
};

function getInputTypeFromRedux(type?: DentureType): LabsGqlDenturesSingleFulfillmentArchType | null {
    if (!type) {
        return null;
    }
    switch (type) {
        case DentureType.CentricTray:
            return LabsGqlDenturesSingleFulfillmentArchType.CentricTray;
        case DentureType.Copy:
            return LabsGqlDenturesSingleFulfillmentArchType.Copy;
        case DentureType.Immediate:
            return LabsGqlDenturesSingleFulfillmentArchType.Immediate;
        case DentureType.WaxRimContinuation:
            return LabsGqlDenturesSingleFulfillmentArchType.WaxRimContinuation;
        case DentureType.WaxRim:
            return null;
    }
}

function useGetArchInput(): LabsGqlDenturesFulfillmentCoreArchInput | null {
    const { dentureOptions, dentureType, isExactCopy } = useDentureCheckoutSelector(s => s.fields);
    const inputDentureType = getInputTypeFromRedux(dentureType);
    if (!dentureOptions || !inputDentureType) {
        return null;
    }
    const { teethQuality, shades, fabrication } = dentureOptions;
    const completeShades: LabsGqlLabOrderItemShadeInput[] =
        shades?.flatMap(({ name, value }) => (!!value ? [{ name, value }] : [])) ?? [];
    if (!teethQuality || !shades || !fabrication) {
        return null;
    }
    return {
        shades: completeShades,
        type: inputDentureType,
        fabrication_method: [fabrication],
        teeth_quality: [teethQuality],
        is_exact_copy: isExactCopy,
    };
}

const ARCH_TO_GQL_DENTURE_ARCH: { [K in OrderItemArch]: LabsGqlDenturesArchType } = {
    [OrderItemArch.Dual]: LabsGqlDenturesArchType.Dual,
    [OrderItemArch.Upper]: LabsGqlDenturesArchType.Upper,
    [OrderItemArch.Lower]: LabsGqlDenturesArchType.Lower,
};

// TODO: separate functionality
// when this function returns null, it means that the patient setup step has not been completed
// so it serves both as a way to get submission args and validate that the first step has been completed for the "next" button
function useSubmitDentureOrderArgs(): SubmitDentureOrderArgs | null {
    const {
        patient_last_name,
        patient_first_name,
        patient_birthday,
        patient_gender,
        address,
        scan,
        doctor,
        waxupState,
    } = useCheckoutPropSelector([
        'patient_first_name',
        'patient_last_name',
        'patient_birthday',
        'patient_gender',
        'address',
        'scan',
        'doctor',
        'waxupState',
    ]);
    const {
        dentureArch,
        dentureNotes,
        dentureStyle,
        denturePhotos,
        waxRimContinuation,
        numberOfSpares,
        implantSupport,
        dentureOptions,
        festooningLevel,
        handleDiastema,
        biteAdjustment,
        midlineCorrection,
        aestheticOptions,
        exactCopyNotes,
    } = useDentureCheckoutSelector(s => s.fields);

    const arch = useGetArchInput();
    const items_v2_by_sku = useInputItemsV2();

    if (!patient_last_name || !patient_first_name || !patient_birthday || !patient_gender) {
        return null;
    }
    if (!scan || !doctor || !address) {
        return null;
    }
    const implantSupportArg =
        implantSupport && implantSupport.isImplantSupported
            ? { collar_heights_mm: implantSupport.collarHeightsMm ?? '' }
            : undefined;

    const baseSubmitArgs = {
        patient_first_name,
        patient_last_name,
        patient_gender,
        items_v2_by_sku,
        patient_birthday: patient_birthday.toISOString(),
        scan_export_id: scan.id,
        mailing_address_id: address.id,
        doctor_preferences_id: doctor.id,
        notes: dentureNotes ?? '',
        implant_support: implantSupportArg,
    };

    const coreSubmitArgs = {
        smile_style: dentureStyle ?? LabsGqlSmileStyleChoice.LabRecommendation,
        photos: denturePhotos?.map(photo => ({ path: photo.url })) ?? [],
        upper_arch: dentureArch && [OrderItemArch.Upper, OrderItemArch.Dual].includes(dentureArch) ? arch : null,
        lower_arch: dentureArch && [OrderItemArch.Lower, OrderItemArch.Dual].includes(dentureArch) ? arch : null,
        number_of_spares: numberOfSpares ?? 0,
        waxup_requested: waxupState.selected,
        add_post_dam: aestheticOptions?.addPostDam,
        add_stippling: aestheticOptions?.addStippling,
        ...(biteAdjustment && {
            bite_adjustment: {
                adjustment: biteAdjustment?.adjustment,
                adjustment_distance: biteAdjustment?.adjustmentDistance,
            },
        }),
        correct_occlusal_scheme: aestheticOptions?.correctOcclusalScheme,
        festooning_level: festooningLevel,
        handle_diastema: handleDiastema,
        ...(midlineCorrection && {
            midline_correction: {
                distance: midlineCorrection?.correctionDistance,
                type: midlineCorrection?.correctionType,
            },
        }),
        copy_change_notes: exactCopyNotes,
        is_try_in: dentureOptions?.productionType === LabsGqlDenturesProductionType.TryIn,
    };

    return {
        waxRimSubmitArgs: {
            ...baseSubmitArgs,
            arch: dentureArch ? ARCH_TO_GQL_DENTURE_ARCH[dentureArch] : dentureArch,
        },
        coreSubmitArgs: { ...coreSubmitArgs, ...baseSubmitArgs },
        waxRimContinuationSubmitArgs: {
            ...coreSubmitArgs,
            items_v2_by_sku,
            lab_order_id: waxRimContinuation?.orderId ?? '',
            notes: dentureNotes ?? '',
            scan_export_id: scan.id,
            implant_support: implantSupportArg,
        },
    };
}

function useDentureOrderMutations(onSuccess: (placedFrag?: LabsGqlPlacedOrderFragment) => Promise<void>) {
    const [placeCoreDentureOrder, { called: coreCalled, error: coreError }] =
        usePlaceDenturesFulfillmentCoreMutation(placeOrderMutationOptions());
    const [placeWaxRimDentureOrder, { called: waxRimCalled, error: waxRimError }] =
        usePlaceDenturesFulfillmentWaxRimMutation(placeOrderMutationOptions());
    const [placeWaxRimContinuationDentureOrder, { called: waxRimContinueCalled, error: waxRimContinueError }] =
        useContinueDenturesFulfillmentWaxRimMutation(placeOrderMutationOptions());
    const isSubmitDisabled =
        (coreCalled && !coreError) || (waxRimCalled && !waxRimError) || (waxRimContinueCalled && !waxRimContinueError);
    const { submitting: submittingCore, submit: submitCore } = useChangeSubmissionFn<any, any>(
        (data: LabsGqlPlaceDenturesFulfillmentCoreCommand) => placeCoreDentureOrder({ variables: { data } }),
        {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSuccess: res => onSuccess(res.data?.placeDenturesFulfillmentCore),
            successMessage: res => {
                const result = res.data;
                const firstOrder = result?.placeDenturesFulfillmentCore;
                const patientName = firstOrder
                    ? `for ${firstOrder.patient.first_name} ${firstOrder.patient.last_name} `
                    : '';
                return [`Order ${patientName} placed!`, {}];
            },
        },
    );

    const { submitting: submittingWaxRim, submit: submitWaxRim } = useChangeSubmissionFn<any, any>(
        (data: LabsGqlPlaceDenturesFulfillmentWaxRimCommand) => placeWaxRimDentureOrder({ variables: { data } }),
        {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSuccess: res => onSuccess(res.data?.placeDenturesFulfillmentWaxRim),
            successMessage: res => {
                const result = res.data;
                const firstOrder = result?.placeDenturesFulfillmentWaxRim;
                const patientName = firstOrder
                    ? `for ${firstOrder.patient.first_name} ${firstOrder.patient.last_name} `
                    : '';
                return [`Order ${patientName} placed!`, {}];
            },
        },
    );

    const { submitting: submittingWaxRimContinuation, submit: submitWaxRimContinuation } = useChangeSubmissionFn<
        any,
        any
    >(
        (data: LabsGqlContinueDenturesFulfillmentWaxRimCommand) =>
            placeWaxRimContinuationDentureOrder({ variables: { data } }),
        {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSuccess: res => onSuccess(res.data?.continueDenturesFulfillmentWaxRim),
            successMessage: res => {
                const result = res.data;
                const firstOrder = result?.continueDenturesFulfillmentWaxRim;
                const patientName = firstOrder
                    ? `for ${firstOrder.patient.first_name} ${firstOrder.patient.last_name} `
                    : '';
                return [`Order ${patientName} placed!`, {}];
            },
        },
    );

    return {
        isSubmitDisabled,
        submitCore,
        submitWaxRim,
        submitWaxRimContinuation,
        submittingCore,
        submittingWaxRim,
        submittingWaxRimContinuation,
    };
}

export function useSubmitDentureOrder(): UseSubmitOrderResult {
    const isScanner = useIsScannerSelector();
    const { submittedOrder, onSuccess } = useOnPlaceDentureOrderSuccess(isScanner);

    const {
        isSubmitDisabled,
        submitCore,
        submittingCore,
        submittingWaxRim,
        submittingWaxRimContinuation,
        submitWaxRim,
        submitWaxRimContinuation,
    } = useDentureOrderMutations(onSuccess);
    const submitArgs = useSubmitDentureOrderArgs();

    const { dentureType, waxRimContinuation } = useDentureCheckoutSelector(s => s.fields);

    const submit = React.useCallback(async () => {
        if (!submitArgs) {
            return;
        }
        if (waxRimContinuation?.isWaxRimContinuation) {
            await submitWaxRimContinuation(submitArgs.waxRimContinuationSubmitArgs);
            return;
        }
        if (dentureType === DentureType.WaxRim) {
            await submitWaxRim(submitArgs.waxRimSubmitArgs);
            return;
        }
        await submitCore(submitArgs.coreSubmitArgs);
    }, [dentureType, submitArgs, submitCore, submitWaxRim, waxRimContinuation, submitWaxRimContinuation]);
    return {
        submittedOrder,
        submit,
        isSubmitDisabled: isSubmitDisabled || !submitArgs,
        submitting: submittingCore || submittingWaxRim || submittingWaxRimContinuation,
    };
}
