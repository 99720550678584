import { usePortalToChairsideBridgeCommand } from '../../../utils/chairside.hooks';
import { useOpenOrderDetailPage } from '../../labs/LabsUtils';
import { PrintOrderReturnLabel } from '../../labs/components/PrintOrderReturnLabel';
import { useTryInFeedbackPropSelector } from '../state/TryInFeedback.selectors';
import { TryInFeedbackUtils } from '../utils/TryInFeedback.util';
import { Grid, Button, Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    outerWrapper: {
        height: '100%',
    },
    innerWrapper: {
        margin: 'auto',
        maxWidth: 400,
    },
    contentWrapper: {
        textAlign: 'center',
    },
}));

export const TryInFeedbackSubmissionCompleted: React.VFC = () => {
    const classes = useStyles();
    const { orderId, submissionData, itemGroups } = useTryInFeedbackPropSelector([
        'orderId',
        'submissionData',
        'itemGroups',
    ]);
    const openOrder = useOpenOrderDetailPage();
    const startDenturesFeedbackCase = usePortalToChairsideBridgeCommand('startDenturesFeedbackCase');
    const attachScansToOrder = usePortalToChairsideBridgeCommand('attachScansToOrder');

    const requiresRescan = TryInFeedbackUtils.needsRescan({ itemGroups });

    const startChairsideCase = React.useMemo(() => {
        // for legacy chairside clients, we pass the original order id that we just submitted feedback for
        if (startDenturesFeedbackCase && orderId) {
            return () => startDenturesFeedbackCase({ order_id: orderId });
        }
        // in newer clients that no longer support the startDenturesFeedbackCase function,
        // we pass the new order id and use the upgraded attach scans flow that handles dentures
        if (attachScansToOrder && submissionData?.newOrderId) {
            return () => submissionData.newOrderId && attachScansToOrder({ id: submissionData.newOrderId });
        }
        return null;
    }, [startDenturesFeedbackCase, attachScansToOrder, orderId, submissionData?.newOrderId]);

    return (
        <Grid container wrap={'nowrap'} className={classes.outerWrapper}>
            <Grid item className={classes.innerWrapper}>
                <Grid container direction={'column'} spacing={2} className={classes.contentWrapper}>
                    <Grid item>
                        {requiresRescan ? (
                            <img src={'/dentures_feedback/3shape-scanner.png'} width={150} />
                        ) : (
                            <img src={'/dentures_feedback/dentures-shred-it.png'} width={200} />
                        )}
                    </Grid>
                    <Grid item>
                        <Text variant={'h5'} color={'BLACK'} medium>
                            {requiresRescan
                                ? 'To continue this order, a re-scan is required.'
                                : `We'll get working on this order right away!`}
                        </Text>
                    </Grid>
                    {requiresRescan && (
                        <>
                            <Grid item>
                                <Text variant={'body2'} color={'DARK_GRAY'}>
                                    Please make your desired changes to this denture and take a 360 scan of the denture
                                    outside of the mouth and a bite scan of the denture inside the mouth.
                                </Text>
                            </Grid>
                            <Grid item>
                                <Text variant={'body2'} color={'DARK_GRAY'}>
                                    Scan this patient in order to continue.
                                </Text>
                            </Grid>
                        </>
                    )}
                    {submissionData?.return && (
                        <Grid item>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                Before you continue, we suggest printing the return label right away to make things that
                                little bit easier!
                            </Text>
                        </Grid>
                    )}
                    {submissionData?.return && (
                        <Grid item>
                            <PrintOrderReturnLabel
                                labelUrl={submissionData.return.label_url}
                                status={submissionData.return.status}
                                title={'Print return label'}
                                analyticsLocation={'Order Details'}
                                style={{ width: 264 }}
                            />
                        </Grid>
                    )}
                    {submissionData?.newOrderId && (
                        <>
                            {requiresRescan && startChairsideCase ? (
                                <Grid item>
                                    <Button
                                        variant={'primary'}
                                        onClick={() => startChairsideCase()}
                                        style={{ width: 264 }}
                                    >
                                        Start scanning
                                    </Button>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <Button
                                        variant={'primary'}
                                        onClick={() =>
                                            submissionData.newOrderId && openOrder(submissionData.newOrderId)
                                        }
                                        style={{ width: 264 }}
                                    >
                                        View new order details
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                    {(!requiresRescan || !startChairsideCase) && (
                        <Grid item>
                            <Button
                                variant={'secondary'}
                                onClick={() => orderId && openOrder(orderId)}
                                style={{ width: 264 }}
                            >
                                Dismiss
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
