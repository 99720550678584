import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';

const GetUserFromDrPrefId_Query = graphql(`
    query GetUserFromDrPrefId($preferences_id: String!) {
        getUserFromDrPrefId(preferences_id: $preferences_id) {
            id
            email
            first_name
            last_name
            mobile_phone_number
            is_mobile_verified
            is_account_activated
        }
    }
`);

export const useGetUserFromDrPrefId = (pref_id: string | undefined, partner_id?: string) => {
    const session = useSession();
    // If the partner_id is different from the session organization_id we want to skip b/c BE will throw
    const diffOrg = Boolean(partner_id && session?.organization_id !== partner_id);
    const { data, loading, error, refetch } = useQuery(GetUserFromDrPrefId_Query, {
        variables: { preferences_id: pref_id ?? '' },
        skip: !pref_id || diffOrg,
    });

    return { user: data?.getUserFromDrPrefId, loading, error: error?.message, refetch };
};
