import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { PostCheckoutAlertArea } from '../../checkout-v2/components/CheckoutConfirmationOverlay/PostCheckoutAlertArea';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { PracticeScreen } from '@orthly/dentin';
import { HomeIcon } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

function sendAnalytics(implantReadinessId: string) {
    AnalyticsClient.track('Practice - Implant Readiness - Order Exited', {
        implantReadinessId,
    });
}

const Success: React.VFC = () => {
    const { implantReadinessId } = useImplantReadinessPropSelector(['implantReadinessId']);
    const history = useHistory();
    return (
        <>
            <Grid
                container
                direction={'column'}
                spacing={6}
                style={{ padding: '20px 20px 0', height: '100%', margin: 0 }}
            >
                <Grid item container spacing={3}>
                    <Grid item>
                        <img
                            src={'/checkout/mitchell.png'}
                            alt={'Logo'}
                            height={125}
                            width={125}
                            style={{ borderRadius: 16 }}
                        />
                    </Grid>
                    <Grid item>
                        <Text variant={'h3'} color={'GRAY'}>
                            Mitchell's on it
                        </Text>
                        <Text variant={'h2'} color={'BLACK'} style={{}}>
                            Your order was placed
                        </Text>
                    </Grid>
                </Grid>

                <Grid item container spacing={4}>
                    <Grid item container direction={'column'} spacing={4} style={{ paddingTop: 26 }}>
                        <Grid item style={{ paddingTop: 24 }}>
                            <Text variant={'body1'} color={'GRAY'} style={{ marginTop: 4 }}>
                                Visit the Dandy Portal to track orders, resolve notifications, learn about Dandy's full
                                service offering, or to get in touch.
                            </Text>
                        </Grid>
                        <Grid item>
                            <Button
                                style={{ width: 240 }}
                                variant={'primary'}
                                onClick={() => {
                                    history.push('/');
                                    sendAnalytics(implantReadinessId);
                                }}
                            >
                                <HomeIcon style={{ marginRight: 8 }} /> Go Back Home
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <PostCheckoutAlertArea />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export const SuccessStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/success`,
    component: Success,
};
