import { useGetUserFromDrPrefId } from '../../../graphql/useGetUserFromDrPrefId.graphql';
import { usePartnerSessionForScanner } from '../../../graphql/usePartnerSessionForScanner.graphql';
import { usePracticeStaffControls } from '../../account/state/usePracticeStaffControls';
import { ALL_CHAT_ROUTES } from '../../chat/chat-routes.constant';
import { loadIsScannerSessionFromSessionStorage } from '../../checkout-v2/ScannerSubmit.graphql';
import { useSelectStaffAction } from '../state/select-staff.actions';
import type { StaffProfileInfo } from '../state/select-staff.reducer';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import { useHistory } from 'react-router-dom';

export const useSelectStaff = (employee?: LabsGqlDoctorPreferencesFragment) => {
    const history = useHistory();
    const session = useSession();
    const isScannerSession = loadIsScannerSessionFromSessionStorage();
    const { loadSessionFromLegacyToken } = useStaffMemberLoginProps();
    const { hasRetainerToken } = useStaffMemberLoginProps();
    const { user } = useGetUserFromDrPrefId(employee?.id, employee?.partner_id);
    const selectEmployee = useSelectStaffAction('SELECT_PRACTICE_EMPLOYEE');
    const pathname = history.location.pathname;
    const isChatiFrame = ALL_CHAT_ROUTES.includes(pathname);
    const controls = usePracticeStaffControls();

    const { submit } = usePartnerSessionForScanner({
        onSuccess: (nativeTokenId: string) => {
            if (!nativeTokenId) {
                return;
            }
            void loadSessionFromLegacyToken(nativeTokenId).then(() => {
                sessionStorage.removeItem('scannerToken');
            });
        },
    });

    const { submit: redirectSubmit } = usePartnerSessionForScanner({
        onSuccess: (nativeTokenId: string) => {
            if (!nativeTokenId) {
                return;
            }
            void loadSessionFromLegacyToken(nativeTokenId).then(() => {
                sessionStorage.removeItem('scannerToken');
                const prefId = controls?.employeePrefs?.id;
                if (prefId) {
                    history.push(`/create_account?prefId=${prefId}&migrate=true`);
                }
            });
        },
    });

    const selectEmployeeAndSession = (employee?: StaffProfileInfo) => {
        if (employee) {
            selectEmployee(employee);
            submit(employee.staff_member_id);
        }
    };

    const selectEmployeeAndSessionRedirect = (employee?: StaffProfileInfo) => {
        if (employee) {
            selectEmployee(employee);
            redirectSubmit(employee.staff_member_id);
        }
    };

    const skipEmployeeAuth =
        session?.isImpersonation ||
        isScannerSession ||
        history.location.pathname.includes('attach_photos') ||
        isChatiFrame;
    const userCannotLogin =
        user?.is_mobile_verified === false && !user?.mobile_phone_number && !user?.is_account_activated;

    return {
        skipEmployeeAuth,
        requireEmployeeAuth:
            !skipEmployeeAuth &&
            employee?.staff_member_id &&
            !hasRetainerToken(employee?.staff_member_id) &&
            !userCannotLogin,
        userCannotLogin,
        selectEmployeeAndSession,
        selectEmployeeAndSessionRedirect,
        isChatiFrame,
    };
};
