import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const ImplantSurgeryAddOnsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'treatment plan consultation',
                type: 'Treatment plan consultation',
                price: getPrice('Implant Surgery - Treatment Plan Consultation'),
            },
            {
                id: 'custom healing abutment',
                type: 'Custom healing abutment (per site, including parts)',
                price: getPrice('Custom Healing Abutment (per site, including parts)'),
            },
            {
                id: 'temporary implant crowns',
                type: 'Temporary implant crowns (per site, including parts)',
                price: getPrice('Temp implant crowns + Ti-base abutment'),
            },
            {
                id: 'immediate denture',
                type: 'Immediate Denture',
                price: getPrice('Signature Denture - Immediate $229'),
            },
            {
                id: 'surgical stent',
                type: 'Surgical Stent',
                price: getPrice('Surgical Stent'),
            },
            {
                id: 'essix retainer',
                type: 'Essix Retainer',
                price: getPrice('Retainer (Single)'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'implant-surgery-add-ons'}
            title={'Add-ons and modifications**'}
            columns={[
                {
                    id: 'type',
                    title: 'Type',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
            footnotes={['** Special pricing only available for customers who purchase an implant surgery product.']}
        />
    );
};
