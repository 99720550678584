import { hasFullStructuredNotesSelection, usePresetStatus } from '../../../../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../../../../state/GuidedWaxupContext';
import type { PresetInfo } from '../../../../state/GuidedWaxupState';
import { useGuidedWaxupAction } from '../../../../state/GuidedWaxupState';
import { StructuredRejectionNotesAddendum } from '../../../StructuredRejectionNotes';
import { PresetReviewCheckboxControl } from './PresetReviewCheckboxControl';
import { PresetReviewControlsTitle } from './PresetReviewControlsTitle';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { Box } from '@orthly/ui-primitives';
import React from 'react';

export const PresetReviewControls: React.VFC<{
    presetType: LabsGqlGuidedWaxupPresetType;
    preset: PresetInfo;
}> = ({ presetType, preset }) => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');

    const presetStatus = usePresetStatus({ presetType }) ?? LabsGqlGuidedWaxupPresetStatus.Skipped;
    const areControlsDisabled =
        presetStatus === LabsGqlGuidedWaxupPresetStatus.Approved || selectedDesignRevisionAlreadyReviewed;

    const showNotesAddendum = React.useMemo(
        () => hasFullStructuredNotesSelection(presetType, preset.structured_notes),
        [presetType, preset],
    );

    React.useEffect(() => {
        if (preset.status !== LabsGqlGuidedWaxupPresetStatus.Approved) {
            setPresetStatus({
                presetName: presetType,
                status: preset.structured_notes?.length
                    ? LabsGqlGuidedWaxupPresetStatus.Rejected
                    : LabsGqlGuidedWaxupPresetStatus.Skipped,
            });
        }
    }, [preset.structured_notes?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <PresetReviewControlsTitle presetStatus={presetStatus} presetType={presetType} />
            <PresetReviewCheckboxControl
                areControlsDisabled={areControlsDisabled}
                preset={preset}
                presetType={presetType}
            />
            {showNotesAddendum && (
                <Box sx={{ marginBottom: '16px' }}>
                    <StructuredRejectionNotesAddendum disabled={areControlsDisabled} presetType={presetType} />
                </Box>
            )}
        </Box>
    );
};
