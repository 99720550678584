import { FlossPalette, Text, styled } from '@orthly/ui-primitives';

const RequiredTitle = styled(Text)({
    '&::after': {
        content: '"*"',
        color: FlossPalette.ATTENTION,
    },
});

const Subtitle = styled(Text)({
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
});

const Content = styled('div')({
    maxWidth: '960px',
});

const Divider = styled('div')({
    width: '100%',
    height: 1,
    backgroundColor: FlossPalette.DARK_TAN,
    gridColumn: '1/-1',
    '&:nth-last-of-type(1)': {
        display: 'none',
    },
});

export const SectionsLayout = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '288px 1fr',
    maxWidth: 'max-content',
    gap: '24px',

    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
    },
}));

export const Section: React.FC<{ title: React.ReactNode; subtitle: React.ReactNode; isRequired?: boolean }> = ({
    title,
    subtitle,
    isRequired = false,
    children,
}) => {
    const Title = isRequired ? RequiredTitle : Text;

    return (
        <>
            <div>
                <Title bold variant={'body2'}>
                    {title}
                </Title>
                <Subtitle variant={'body2'} color={'GRAY'}>
                    {subtitle}
                </Subtitle>
            </div>
            <Content>{children}</Content>
            <Divider />
        </>
    );
};
