import { useStaffForm } from '../../useStaffForm';
import { RootActionDialog } from '@orthly/ui';
import { Button, Text, styled } from '@orthly/ui-primitives';

const ButtonsContainer = styled('div')({
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr 1fr',
});

export const InviteExistingUserDialog: React.FC = () => {
    const { showInviteModal, setShowInviteModal, accountEmail, shouldContinue, inviteExistingUser } = useStaffForm();

    return (
        <RootActionDialog
            open={showInviteModal}
            setOpen={setShowInviteModal}
            hideButton
            loading={false}
            title={<Text variant={'h5'}>A person with this email address already has a Dandy account</Text>}
            subtitle={
                <>
                    {'You can still invite them to your practice, though. Click confirm if you would like to add '}
                    <Text bold variant={'body2'} color={'GRAY'} component={'span'}>
                        {accountEmail}
                    </Text>
                    {' to your practice as a staff member.'}
                </>
            }
            content={
                <ButtonsContainer>
                    <Button variant={'secondary'} onClick={() => setShowInviteModal(false)}>
                        Back
                    </Button>
                    <Button variant={'primary'} onClick={() => inviteExistingUser()} disabled={!shouldContinue}>
                        Confirm
                    </Button>
                </ButtonsContainer>
            }
        />
    );
};
