import { FlossPalette, Icon, Text, styled } from '@orthly/ui-primitives';

export const RequiredLabel = styled(Text)({
    '&::after': {
        content: '"*"',
        color: FlossPalette.ATTENTION,
    },
});

export const FormFieldContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
});

export const InputContainer = styled('div')({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '&>*:last-child:not(:first-child)': {
        position: 'absolute',
        right: 16,
        top: 16,
    },
});

export const RevealPasswordIcon = styled(Icon)({
    cursor: 'pointer',
    color: FlossPalette.GRAY,
});
