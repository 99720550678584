import { OrderDetailTimelineSection } from './components/OrderDetailBodySections/OrderDetailTimelineSection';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { FlossPalette, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const OrderDetailBlock = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: 12,
    borderRadius: 16,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    overflow: 'hidden',
    maxHeight: 430,
});

const HeaderText = styled('div')({
    display: 'flex',
    backgroundColor: FlossPalette.DARK_TAN,
    width: '100%',
    alignItems: 'center',
    padding: '8px 8px 8px 24px',
});

interface OrderDetailTimelineBlockProps {
    order: LabsGqlSingleLabOrderFragment;
}

export const OrderDetailTimelineBlock: React.FC<OrderDetailTimelineBlockProps> = ({ order }) => {
    return (
        <OrderDetailBlock data-pendo-id={'order-detail-timeline'}>
            <HeaderText>
                <Text variant={'body2'} bold color={'DARK_GRAY'}>
                    Timeline
                </Text>
            </HeaderText>
            <OrderDetailTimelineSection order={order} />
        </OrderDetailBlock>
    );
};
