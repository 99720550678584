import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import type { UploadedPhotosState } from '@orthly/veneer';
import { useUploadedPhotoState, denturePhotoUploadConfig } from '@orthly/veneer';
import React from 'react';

export function useDentureUploadedPhotos(scanID?: string): UploadedPhotosState {
    const setPhotos = useDenturesCheckoutAction('SET_DENTURE_PHOTO_URLS');
    const state = useUploadedPhotoState(denturePhotoUploadConfig, scanID);

    React.useEffect(() => {
        setPhotos(Object.values(state.uploadedPhotos).map(ref => ref.fullPath));
    }, [setPhotos, state.uploadedPhotos]);

    return state;
}
