import { ClearAlignerMaterialsTable } from './ClearAlignerMaterialsTable';
import { Grid, Text } from '@orthly/ui-primitives';

export const ClearAlignerPricing: React.FC = () => {
    return (
        <Grid container direction={'column'} data-test={'pricing-content-ClearAligner'} style={{ gap: '24px' }}>
            <Grid item xs={12}>
                <Text data-test={'pricing-header-ClearAligner'} variant={'h4'}>
                    Clear aligners
                </Text>
            </Grid>
            <Grid item xs={12}>
                <ClearAlignerMaterialsTable />
            </Grid>
        </Grid>
    );
};
