import { CheckoutBooleanItemDataField } from './CheckoutBooleanItemDataField';
import { CheckoutDateItemDataField } from './CheckoutDateItemDataField';
import { CheckoutEnumSelectItemDataField } from './CheckoutEnumSelectItemDataField';
import { CheckoutEnumSelectMultiItemDataField } from './CheckoutEnumSelectMultiItemDataField';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import { CheckoutNumberSelectItemDataField } from './CheckoutNumberSelectItemDataField';
import { CheckoutStringFreeformItemDataField } from './CheckoutStringFreeformItemDataField';
import { CheckoutStringSelectItemDataField } from './CheckoutStringSelectItemDataField';
import { CheckoutToothSelectMultiItemDataField } from './CheckoutToothSelectMultiItemDataField';
import type { ItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

interface CheckoutItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends CheckoutItemDataFieldBaseProps<SKU> {
    field: ItemDataField<ID, SKU>;
}

export const CheckoutItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
}: CheckoutItemDataFieldProps<ID, SKU>): React.ReactElement => {
    switch (field.type) {
        case 'enum_select':
            return <CheckoutEnumSelectItemDataField field={field} item={item} updateItem={updateItem} />;
        case 'enum_select_multi':
            return <CheckoutEnumSelectMultiItemDataField field={field} item={item} updateItem={updateItem} />;
        case 'string_select':
            return <CheckoutStringSelectItemDataField field={field} item={item} updateItem={updateItem} />;
        case 'number_select':
            return <CheckoutNumberSelectItemDataField field={field} item={item} updateItem={updateItem} />;
        case 'tooth_select_multi':
            return <CheckoutToothSelectMultiItemDataField field={field} item={item} updateItem={updateItem} />;
        case 'boolean':
            return <CheckoutBooleanItemDataField field={field} item={item} updateItem={updateItem} />;
        case 'date':
            return <CheckoutDateItemDataField field={field} item={item} updateItem={updateItem} />;
        case 'string_freeform':
            return <CheckoutStringFreeformItemDataField field={field} item={item} updateItem={updateItem} />;
    }
};
