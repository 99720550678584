import { useCheckoutAction } from '../../state/checkout.actions';
import type { ImplantScreenInterface } from '../../state/reducers/implantScreens';
import { getSelectedRetention } from './implantSelectionGetters';
import { CheckoutRadioIcon } from '@orthly/dentin';
import { OrderItemLinkRelationship as RetentionType } from '@orthly/items';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import cx from 'clsx';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    radioOptionCard: {
        backgroundColor: FlossPalette.TAN,
        border: `2px solid ${FlossPalette.TAN}`,
        marginBottom: 16,
        padding: 18,
        cursor: 'pointer',
        radius: 4,
    },
    checkedCard: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        border: `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
    },
}));

interface RetentionConfig {
    title: string;
    subtitle: string;
    imgSrc: string;
}
const retentionConfigMap: { [retentionType in RetentionType]: RetentionConfig } = {
    [RetentionType.ScrewRetained]: {
        title: 'Screw-retained',
        subtitle: 'Crown with access-hole for future retrievability. No cementing required.',
        imgSrc: '/checkout/implants/Screw-retained.svg',
    },
    [RetentionType.CementRetained]: {
        title: 'Cement-retained',
        subtitle: 'Crown without an access hole to be cemented intraorally.',
        imgSrc: '/checkout/implants/Cement-retained.svg',
    },
    [RetentionType.Screwmentable]: {
        title: 'Screwmentable',
        subtitle: 'Crown with screw access hole to be cemented intraorally. Allows for future retrievability.',
        imgSrc: '/checkout/implants/Screwmentable.svg',
    },
};

const RetentionOptionCard: React.VFC<{
    retentionType: RetentionType;
    config: RetentionConfig;
    selectedRetention?: RetentionType;
    item_index: number;
}> = props => {
    const { retentionType, config, selectedRetention, item_index } = props;
    const classes = useStyles();
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');
    const checked = selectedRetention === retentionType;
    const onClick = React.useCallback(
        () =>
            !checked &&
            updateItem({ item_index, change: { name: 'implant_metadata', payload: { relationship: retentionType } } }),
        [checked, retentionType, updateItem, item_index],
    );
    return (
        <div className={cx(classes.radioOptionCard, checked && classes.checkedCard)} onClick={onClick}>
            <Grid container direction={'row'} alignItems={'center'} wrap={'nowrap'}>
                <Grid item>
                    <CheckoutRadioIcon checked={checked} />
                </Grid>
                <Grid item>
                    <img src={config.imgSrc} alt={config.title} />
                </Grid>
                <Grid item style={{ paddingLeft: 32 }}>
                    <Text variant={'body2'} medium style={{ marginBottom: 4 }}>
                        {config.title}
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{}}>
                        {config.subtitle}
                    </Text>
                </Grid>
            </Grid>
        </div>
    );
};

export const RetentionTypeScreen: ImplantScreenInterface = {
    title: 'Select the retention type',
    Component: ({ item }) => {
        const selectedRetention = getSelectedRetention(item);
        return (
            <Grid container direction={'row'} style={{ marginTop: 24 }}>
                {_.entries(retentionConfigMap).map(([retentionType, config]) => (
                    <Grid item xs={8} key={retentionType}>
                        <RetentionOptionCard
                            retentionType={retentionType as RetentionType}
                            config={config}
                            selectedRetention={selectedRetention}
                            item_index={item.item_index}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    },
    enableNext: ({ item }) => !!getSelectedRetention(item),
};
