import { PracticeFullScreenDialog } from '@orthly/dentin';
import { Box, Button, FlossPalette, Icon, Slide, Text } from '@orthly/ui-primitives';
import React from 'react';

const Header: React.FC<{ onBack: () => any }> = ({ children, onBack }) => (
    <Box
        sx={{
            display: 'grid',
            alignItems: 'center',
            minHeight: '70px',
            backgroundColor: FlossPalette.TAN,
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            position: 'relative',
        }}
    >
        <Box sx={{ position: 'absolute' }}>
            <Button
                variant={'secondary'}
                sx={{
                    left: '10px',
                    padding: '8px',
                    minWidth: 0,
                    background: FlossPalette.TAN,
                    border: 'none',
                }}
                onClick={onBack}
            >
                <Icon icon={'ChevronLeft'} />
            </Button>
        </Box>
        <Box sx={{ justifySelf: 'center' }}>
            <Text variant={'body2'} medium>
                {children}
            </Text>
        </Box>
    </Box>
);

const Body: React.FC = ({ children }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', padding: '15px', overflow: 'auto', flexGrow: 1 }}>
        {children}
    </Box>
);

const Footer: React.FC<{ onCancel?: () => any; onClose: () => any; closeLabel: string; closeDisabled: boolean }> = ({
    children,
    onCancel,
    onClose,
    closeLabel,
    closeDisabled,
}) => (
    <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            borderTop: `2px solid ${FlossPalette.STROKE_LIGHT}`,
            backgroundColor: FlossPalette.WHITE,
            padding: '15px',
        }}
    >
        {children}
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                gap: '15px',
            }}
        >
            {onCancel && (
                <Button variant={'secondary-gray'} sx={{ width: '100%', textAlign: 'center' }} onClick={onCancel}>
                    Cancel
                </Button>
            )}
            <Button
                variant={'primary'}
                sx={{ width: '100%', textAlign: 'center' }}
                onClick={onClose}
                disabled={closeDisabled}
            >
                {closeLabel}
            </Button>
        </Box>
    </Box>
);

export const MobileRejectionNotesContainer: React.FC<{
    isOpen: boolean;
    onBack: () => any;
    onCancel?: () => any;
    onClose: () => any;
    title: string;
    closeLabel: string;
    closeDisabled?: boolean;
    footerPreface?: React.ReactNode;
}> = ({ children, isOpen, onBack, onCancel, onClose, title, closeLabel, closeDisabled = false, footerPreface }) =>
    isOpen ? (
        <PracticeFullScreenDialog>
            <Slide in direction={'left'}>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <Header onBack={onBack}>{title}</Header>
                    <Body>{children}</Body>
                    <Footer closeLabel={closeLabel} onCancel={onCancel} onClose={onClose} closeDisabled={closeDisabled}>
                        {footerPreface}
                    </Footer>
                </Box>
            </Slide>
        </PracticeFullScreenDialog>
    ) : null;
