import NaturalSmileFull from '../../../assets/images/photo-upload-tips/crown/NaturalSmileFull.png';
import ThreeShadeTabs from '../../../assets/images/photo-upload-tips/crown/ThreeShadeTabs.png';
import ProfileAtRest from '../../../assets/images/photo-upload-tips/dentures/ProfileAtRest.png';
import SmilingBroadly from '../../../assets/images/photo-upload-tips/dentures/SmilingBroadly.png';
import Drawing from '../../../assets/images/photo-upload-tips/partials/Drawing.png';
import ExistingPartial from '../../../assets/images/photo-upload-tips/partials/ExistingPartial.png';
import NaturalSmileClose from '../../../assets/images/photo-upload-tips/shared/NaturalSmileClose.png';
import RetractedNatural from '../../../assets/images/photo-upload-tips/shared/RetractedNatural.png';
import RetractedOpen from '../../../assets/images/photo-upload-tips/shared/RetractedOpen.png';
import SideView from '../../../assets/images/photo-upload-tips/shared/SideView.png';
import StumpShade from '../../../assets/images/photo-upload-tips/shared/StumpShade.png';
import type { ProductUnitType } from '@orthly/items';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    photoRow: {
        marginTop: 16,
        flexWrap: 'nowrap',
    },
    photoCapture: {
        objectFit: 'cover',
        marginRight: 8,
    },
}));

export interface PhotoTipsImage {
    img: string;
    title: string;
    description?: string;
    subtext?: string;
}

const CROWN_GUIDANCE_PHOTOS: PhotoTipsImage[] = [
    { img: NaturalSmileFull, title: 'Natural smile', description: 'Full face' },
    { img: NaturalSmileClose, title: 'Natural smile', description: 'Close up' },
    {
        img: ThreeShadeTabs,
        title: '3 shade tabs',
        description: 'Edge to edge',
        subtext:
            "Hold up three shade tabs in the photo. Tabs should be one shade lighter, the ideal shade, and one shade darker than the patient's tooth.",
    },
    {
        img: SideView,
        title: 'Side view',
        description: 'With shade tabs',
        subtext:
            "Hold up three shade tabs in the photo. Tabs should be one shade lighter, the ideal shade, and one shade darker than the patient's tooth.",
    },
    { img: RetractedNatural, title: 'Retracted', description: 'Natural bite' },
    { img: RetractedOpen, title: 'Retracted', description: 'Open bite' },
];

const PARTIALS_GUIDANCE_PHOTOS: PhotoTipsImage[] = [
    { img: NaturalSmileClose, title: 'Natural smile', description: 'Close up' },
    { img: SideView, title: 'Side view', description: 'With shade tabs' },
    { img: RetractedNatural, title: 'Retracted', description: 'Natural bite' },
    { img: RetractedOpen, title: 'Retracted', description: 'Open bite' },
    { img: Drawing, title: 'Drawing of the desired design' },
    { img: ExistingPartial, title: 'Existing partial' },
];

const DENTURES_GUIDANCE_PHOTOS: PhotoTipsImage[] = [
    { img: SmilingBroadly, title: 'Smiling broadly', description: 'Wearing appliance' },
    { img: ProfileAtRest, title: 'Profile, at rest', description: 'Wearing appliance' },
];

const CROWN_GUIDANCE_GROUP: Array<ProductUnitType> = [
    'Crown',
    'Inlay',
    'Onlay',
    'Veneer',
    'Bridge',
    'ImplantBridge',
    'Implant',
];

export const StumpShadePhoto = StumpShade;

export interface ItemPhotoOptions {
    isReplacementPartial: boolean;
}

export function getPhotosForItem(type: ProductUnitType, { isReplacementPartial }: ItemPhotoOptions) {
    let initialPhotos: PhotoTipsImage[] = [];
    if (CROWN_GUIDANCE_GROUP.includes(type)) {
        initialPhotos = CROWN_GUIDANCE_PHOTOS;
    } else if (type === 'Partial') {
        initialPhotos = PARTIALS_GUIDANCE_PHOTOS;
    } else if (type === 'Denture') {
        initialPhotos = DENTURES_GUIDANCE_PHOTOS;
    }

    return !isReplacementPartial ? initialPhotos.filter(photo => photo.title !== 'Existing partial') : initialPhotos;
}

interface PhotoCaptureTipsProps {
    photos: PhotoTipsImage[];
    imageWidth: number;
}

export const PhotoCaptureTips: React.VFC<PhotoCaptureTipsProps> = ({ photos, imageWidth }) => {
    const classes = useStyles();
    return (
        <Grid container direction={'column'}>
            {photos.map((photo, i) => (
                <Grid key={i} container alignItems={'center'} className={classes.photoRow}>
                    <img
                        src={photo.img}
                        className={classes.photoCapture}
                        style={{
                            width: imageWidth,
                            borderRadius: imageWidth < 50 ? 4 : 8,
                        }}
                        alt={photo.title}
                    />
                    {photo.title}
                    {photo.description && `, ${photo.description.toLowerCase()}`}
                </Grid>
            ))}
        </Grid>
    );
};
