import { CheckoutItemSelectField } from '../CheckoutItemSelectField';
import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import type { ItemDataFieldId, LabOrderItemSKUType, NumberSelectItemDataField } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

interface CheckoutNumberSelectItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends CheckoutItemDataFieldBaseProps<SKU> {
    field: NumberSelectItemDataField<ID, SKU>;
}

export const CheckoutNumberSelectItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
}: CheckoutNumberSelectItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item);

    const applyUpdate = (val: string | undefined) => {
        const numberVal = val ? _.toNumber(val) : null;
        updateItem(field.getUpdatePayload(numberVal, item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <CheckoutItemSelectField
            label={getFieldLabel(field, item)}
            value={!_.isNil(value) ? `${value}` : undefined}
            options={field.getOptions(item).map(o => ({ ...o, value: `${o.value}` }))}
            onChange={val => applyUpdate(val)}
            required={ItemDataFieldUtils.isItemDataFieldRequired(field, item)}
        />
    );
};
