import { LabeledPhoneField, LabeledTextField } from '../../../../../pre-auth/components/labeled-field/LabeledFields';
import { useStaffForm } from '../useStaffForm';
import { Section } from './components/Section';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { styled } from '@orthly/ui-primitives';
import React from 'react';

const ProfileInfo = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 16,
    rowGap: 24,

    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
    },
}));

export const ProfileInformationSection: React.FC = () => {
    const {
        accountEmail,
        accountPhone,
        contactEmail,
        setContactEmail,
        contactEmailError,
        setShowContactEmailError,
        contactPhone,
        setContactPhone,
        contactPhoneError,
        setShowContactPhoneError,
        preferredName,
        setPreferredName,
        preferredNameError,
        setShowPreferredNameError,
        fullName,
        isEditForm,
    } = useStaffForm();

    if (!isEditForm) {
        return null;
    }

    return (
        <Section title={'Profile information'} subtitle={'Used for communications related to this practice'}>
            <ProfileInfo>
                <LabeledTextField
                    label={'Preferred name'}
                    placeholder={fullName}
                    value={preferredName}
                    onChange={(next = '') => {
                        setShowPreferredNameError(false);
                        setPreferredName(next);
                    }}
                    TextFieldProps={{
                        type: 'text',
                        error: !!preferredNameError,
                        helperText: preferredNameError,
                        onBlur: () => {
                            setShowPreferredNameError(true);
                            if (preferredName.trim() === fullName || !preferredName.trim()) {
                                setPreferredName(fullName);
                            }
                        },
                    }}
                />
                <LabeledTextField
                    label={'Contact email address'}
                    placeholder={accountEmail}
                    value={contactEmail}
                    onChange={(next = '') => {
                        setShowContactEmailError(false);
                        setContactEmail(next.trim());
                    }}
                    TextFieldProps={{
                        type: 'email',
                        error: !!contactEmailError,
                        helperText: contactEmailError,
                        onBlur: () => {
                            setShowContactEmailError(true);
                            if (contactEmail.trim() === accountEmail.trim() || !contactEmail.trim()) {
                                setContactEmail(accountEmail.trim());
                            }
                        },
                    }}
                />
                <LabeledPhoneField
                    label={'Contact phone number'}
                    placeholder={
                        PhoneNumberUtils.isValidPhoneNumber(accountPhone)
                            ? PhoneNumberUtils.prettyPhoneNumber(accountPhone)
                            : 'Enter a phone number'
                    }
                    value={contactPhone}
                    onChange={(next = '') => {
                        setShowContactPhoneError(false);
                        setContactPhone(next);
                    }}
                    TextFieldProps={{
                        error: !!contactPhoneError,
                        helperText: contactPhoneError,
                    }}
                    onBlur={() => {
                        setShowContactPhoneError(true);
                        if (contactPhone.trim() === accountPhone.trim() || contactPhone === '+1') {
                            setContactPhone('');
                        }
                    }}
                />
            </ProfileInfo>
        </Section>
    );
};
