import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useIsChairside } from '../../../utils/chairside.hooks';
import { Tips, restoreConfidentlyTip } from '../component/Tip';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { PracticeScreen, DandyMouthToothSelector } from '@orthly/dentin';
import type { ToothNumber, ICartImplantItem } from '@orthly/items';
import { LabOrderItemSKUType, CartItemV2Utils } from '@orthly/items';
import { Grid, Button } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const ImplantRestoration: React.VFC = () => {
    const createItems = useImplantReadinessAction('CREATE_ITEMS');
    const { items, implantReadinessId } = useImplantReadinessPropSelector(['items', 'implantReadinessId']);
    const isChairside = useIsChairside();
    const selectedUnns = items.flatMap(item => CartItemV2Utils.getUniqueUNNs(item));

    const [unns, setUnns] = React.useState<ToothNumber[]>(selectedUnns);

    const getExistingImplantMetadata = (unn: ToothNumber) => {
        const filteredImplantItems = items.filter((item): item is ICartImplantItem =>
            CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant),
        );
        const templateItem = filteredImplantItems.find(item => item.unit.unn === unn) ?? filteredImplantItems[0];
        return templateItem?.unit.implant_metadata;
    };

    const nextStep = () => {
        // Create an implant item for each tooth
        const sortedUnns = _.sortBy(unns);
        const implantItems: ICartImplantItem[] = sortedUnns.map(unn => ({
            sku: LabOrderItemSKUType.Implant,
            attachments: [],
            preference_fields: [],
            shades: [],
            unit: {
                abutment: {
                    unit_type: 'Abutment',
                },
                crown: { unit_type: 'Crown' },
                implant_metadata: getExistingImplantMetadata(unn) ?? {},
                unn: unn,
            },
        }));
        createItems(implantItems);
        AnalyticsClient.track('Practice - Implant Readiness - Implant Teeth Selected', {
            implantReadinessId,
            unns: unns,
        });
    };

    return (
        <Grid style={{ marginBottom: 36 }}>
            <Grid item style={{ width: 275, marginTop: isChairside ? 0 : 36 }}>
                <DandyMouthToothSelector
                    selected={unns}
                    onChange={newUnns => setUnns(newUnns)}
                    verticalLayout={true}
                    animate={false}
                    archLabels={true}
                />
            </Grid>
            <Grid item container style={{ marginTop: isChairside ? 20 : 80 }}>
                <Button variant={'primary'} onClick={nextStep} style={{ width: 275 }} disabled={unns.length === 0}>
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

export const ImplantRestorationStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/implant_restoration`,
    component: ImplantRestoration,
    pageTitle: ({ name }) => ({
        title: `Preparing for ${name}'s implant case`,
        header: 'Select a tooth (or teeth)',
    }),
    sidebar: () => <Tips tips={[restoreConfidentlyTip]} />,
};
