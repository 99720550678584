import {
    useFilterSelectedReasonCodeIdsByCategoryForCurrentItem,
    useSelectOtherNotesByReasonCodeIdForCurrentItem,
    useSelectedReasonCodeIdsForCurrentItem,
    useSetOtherNotesByReasonCodeIdForCurrentItem,
    useSetReasonCodeForCurrentItem,
    useUnsetReasonCodesForCurrentItem,
} from './state/feedback.selectors';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import type { LabsGqlReasonCodeOptionFragment } from '@orthly/graphql-operations';
import { ChevronRight, SimpleCheckbox, SimpleInput, SimpleMultiSelect } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Slide,
    CloseIcon,
    Button,
    Text,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const topMargin = 56;

const useStyles = stylesFactory(theme => ({
    criterionOptions: {
        display: `flex`,
        flexFlow: `row`,
        alignItems: `center`,
        justifyContent: `space-between`,
        gap: 6,
        padding: '0px 16px',
    },
    itemSelectRows: {
        backgroundColor: FlossPalette.TAN,
        alignItems: `center`,
        borderRadius: `16px`,
        justifyContent: `space-between`,
        marginBottom: `8px`,
        height: `56px`,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    criterionContainer: {
        backgroundColor: FlossPalette.TAN,
        width: `100%`,
        maxWidth: '715px',
        marginBottom: `16px`,
        borderRadius: `16px`,
        border: `1px solid rgba(0, 0, 0, 0.08)`,
        display: `flex`,
        flexFlow: `column`,
        alignItems: `stretch`,
        justifyContent: `center`,
        padding: `10px 16px`,
        userSelect: `none`,
        [theme.breakpoints.down(`md`)]: {
            padding: `8px`,
            marginBottom: `8px`,
        },
    },
    navAreaMobile: {
        width: '100%',
        // total height is 78 + 2 = 80
        height: 78,
        borderTop: `2px solid ${FlossPalette.TAN}`,
        position: 'absolute',
        backgroundColor: FlossPalette.WHITE,
        padding: 16,
    },
    complete: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        border: `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
        borderRadius: `16px`,
    },
}));

interface FeedbackReasonCodeCategoryCellProps {
    itemId: string;
    reasonCodes?: LabsGqlReasonCodeOptionFragment[];
    category: string | null;
}

const FeedbackReasonCodeCellDesktop: React.VFC<FeedbackReasonCodeCategoryCellProps> = ({
    itemId,
    reasonCodes,
    category,
}) => {
    const classes = useStyles();
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem(itemId);
    const selectedIdsForCategory = useFilterSelectedReasonCodeIdsByCategoryForCurrentItem(
        itemId,
        reasonCodes,
        category,
    );
    const [open, setOpen] = React.useState<boolean>(false);
    const otherReasonCodeForCategory = reasonCodes?.find(rc => rc.category === category && rc.title === 'Other');
    const otherIsSelected = selectedReasonCodeIds.some(codeId => otherReasonCodeForCategory?.id === codeId);
    const otherNotes = useSelectOtherNotesByReasonCodeIdForCurrentItem(itemId, otherReasonCodeForCategory?.id);
    const isChecked = selectedIdsForCategory.length > 0 || open;
    const unsetReasonCodesForCurrentItem = useUnsetReasonCodesForCurrentItem(itemId);
    const setReasonCodeForCurrentItem = useSetReasonCodeForCurrentItem(itemId);
    const otherNotesSetter = useSetOtherNotesByReasonCodeIdForCurrentItem(itemId, otherReasonCodeForCategory?.id ?? '');
    const onMultiSelectChange = React.useCallback(
        (newValues?: string[]) => {
            const newIds = newValues ?? [];
            const oldIds = selectedIdsForCategory;
            const removedIds = _.difference(oldIds, newIds);
            if (removedIds.length) {
                unsetReasonCodesForCurrentItem(removedIds);
            }
            const addedIds = _.difference(newIds, oldIds);
            addedIds.forEach(addedId => {
                const associatedReasonCode = reasonCodes?.find(code => code.id === addedId);
                if (associatedReasonCode) {
                    setReasonCodeForCurrentItem(associatedReasonCode);
                }
            });
        },
        [selectedIdsForCategory, reasonCodes, unsetReasonCodesForCurrentItem, setReasonCodeForCurrentItem],
    );
    return (
        <Grid
            container
            className={cx(classes.criterionContainer, isChecked && classes.complete)}
            data-test={`feedback-category-checkbox-${category}`}
        >
            <SimpleCheckbox
                checked={isChecked}
                setChecked={() => {
                    const newChecked = !isChecked;
                    if (newChecked) {
                        setOpen(true);
                    } else {
                        unsetReasonCodesForCurrentItem(selectedIdsForCategory);
                    }
                }}
                label={
                    <Text variant={`body2`} bold>
                        {category}
                    </Text>
                }
            />

            {isChecked && (
                <Grid container direction={'column'} style={{ paddingBottom: 16 }}>
                    <Grid item>
                        <SimpleMultiSelect
                            options={
                                reasonCodes
                                    ?.filter(code => code.category === category)
                                    .map(option => {
                                        return { value: option.id, label: option.title };
                                    }) ?? []
                            }
                            label={`What was wrong with ${category?.toLocaleLowerCase()}?`}
                            onChange={onMultiSelectChange}
                            value={selectedIdsForCategory}
                            SelectProps={{
                                open,
                                style: {
                                    backgroundColor: FlossPalette.WHITE,
                                    width: '100%',
                                    maxWidth: '655px',
                                },
                                onOpen: () => setOpen(true),
                                onClose: () => setOpen(false),
                                variant: 'standard',
                            }}
                            InputLabelProps={{ style: { whiteSpace: `nowrap`, textOverflow: 'ellipsis' } }}
                        />
                    </Grid>
                    {otherIsSelected && (
                        <Grid item style={{ marginTop: `8px` }}>
                            <SimpleInput
                                value={otherNotes}
                                onChange={value => otherNotesSetter(value ?? '')}
                                label={`Please describe`}
                                TextFieldProps={{
                                    InputProps: { type: 'text', style: { backgroundColor: FlossPalette.WHITE } },
                                    style: { width: `100%` },
                                    multiline: true,
                                    rows: 2,
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

const FeedbackReasonCodeIndividualCheckbox: React.VFC<{
    itemId: string;
    reasonCode: LabsGqlReasonCodeOptionFragment;
}> = ({ itemId, reasonCode }) => {
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem(itemId);
    const classes = useStyles();
    const [checked, setChecked] = React.useState<boolean>(false);
    const isChecked = selectedReasonCodeIds.includes(reasonCode.id) || checked;
    const unsetReasonCodesForCurrentItem = useUnsetReasonCodesForCurrentItem(itemId);
    const setReasonCodeForCurrentItem = useSetReasonCodeForCurrentItem(itemId);
    return (
        <Grid container className={cx(classes.itemSelectRows, isChecked && classes.complete)}>
            <Grid item className={classes.criterionOptions}>
                <SimpleCheckbox
                    checked={isChecked}
                    setChecked={() => {
                        const newChecked = !isChecked;
                        setChecked(newChecked);
                        if (newChecked) {
                            setReasonCodeForCurrentItem(reasonCode);
                        } else {
                            unsetReasonCodesForCurrentItem([reasonCode.id]);
                        }
                    }}
                    label={
                        <Text variant={'body2'} color={'BLACK'} medium>
                            {reasonCode.title}
                        </Text>
                    }
                />
            </Grid>
        </Grid>
    );
};

const FeedbackReasonCodeCellMobile: React.VFC<FeedbackReasonCodeCategoryCellProps> = ({
    itemId,
    reasonCodes,
    category,
}) => {
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem(itemId);
    const selectedIdsForCategory = useFilterSelectedReasonCodeIdsByCategoryForCurrentItem(
        itemId,
        reasonCodes,
        category,
    );
    const otherReasonCodeForCategory = reasonCodes?.find(rc => rc.category === category && rc.title === 'Other');
    const classes = useStyles();
    const [showOptionsList, setShowOptionsList] = React.useState<boolean>(false);
    const otherIsSelected = selectedReasonCodeIds.some(codeId => otherReasonCodeForCategory?.id === codeId);
    const otherNotes = useSelectOtherNotesByReasonCodeIdForCurrentItem(itemId, otherReasonCodeForCategory?.id);
    const selectedReasonCodeTitles = (reasonCodes ?? [])
        .filter(code => selectedIdsForCategory.includes(code.id))
        .map(selectedCode => selectedCode.title)
        .join(', ');
    const truncatedTitles =
        selectedReasonCodeTitles.length > 32
            ? `${selectedReasonCodeTitles.substring(0, 32)}...`
            : selectedReasonCodeTitles;
    const isComplete = selectedIdsForCategory.length > 0;
    const otherNotesSetter = useSetOtherNotesByReasonCodeIdForCurrentItem(itemId, otherReasonCodeForCategory?.id ?? '');

    return (
        <Grid container className={cx(classes.criterionContainer, isComplete && classes.complete)}>
            <Grid
                container
                className={classes.criterionOptions}
                style={{ padding: 8, flexFlow: 'column' }}
                direction={'column'}
                onClick={() => setShowOptionsList(!showOptionsList)}
            >
                <Grid
                    item
                    container
                    justifyContent={'space-between'}
                    onClick={() => setShowOptionsList(!showOptionsList)}
                >
                    <Text
                        variant={'body2'}
                        medium
                        color={isComplete ? `PRIMARY_FOREGROUND` : 'BLACK'}
                        style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    >
                        <strong>{category}</strong>
                        {isComplete && `: ${truncatedTitles}`}
                    </Text>
                    <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                </Grid>
                {otherIsSelected && (
                    <Grid item style={{ width: `100%` }}>
                        <SimpleInput
                            value={otherNotes ?? ''}
                            onChange={value => otherNotesSetter(value ?? '')}
                            label={`Please describe the issue`}
                            TextFieldProps={{
                                InputProps: { type: 'text', style: { backgroundColor: FlossPalette.WHITE } },
                                style: { width: `100%` },
                                onClick: ev => ev.stopPropagation(),
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid
                container
                className={classes.criterionOptions}
                justifyContent={'center'}
                style={{
                    alignSelf: 'stretch',
                    flexBasis: '80%',
                }}
            >
                {showOptionsList && (
                    <PracticeFullScreenDialog
                        dialogPaperStyle={{
                            overflow: `visible`,
                            backgroundColor: FlossPalette.GRAY,
                        }}
                    >
                        <div style={{ minHeight: topMargin }}>&nbsp;</div>
                        <Slide in={true} direction={'up'} timeout={300}>
                            <div
                                style={{
                                    backgroundColor: FlossPalette.WHITE,
                                    borderTopRightRadius: 20,
                                    borderTopLeftRadius: 20,
                                    minHeight: `calc(100vh - ${topMargin}px)`,
                                }}
                            >
                                <div style={{ minHeight: `calc(100vh - ${topMargin}px - 96px)` }}>
                                    <div
                                        style={{
                                            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
                                            paddingBlock: 12,
                                            textAlign: 'center',
                                        }}
                                    >
                                        <span
                                            style={{ float: 'right', padding: '0 12px 12px 12px', cursor: 'pointer' }}
                                            onClick={() => setShowOptionsList(!showOptionsList)}
                                        >
                                            <CloseIcon style={{ color: FlossPalette.BLACK }} />
                                        </span>
                                        <Text variant={'body2'} medium>
                                            {category}
                                        </Text>
                                    </div>
                                    <div style={{ margin: `24px 0px 16px 0px`, padding: `0px 17px` }}>
                                        <Text variant={'body1'} medium>
                                            What was wrong with the {category}?
                                        </Text>
                                        <Text variant={'body2'} color={'GRAY'}>
                                            Select at least one option
                                        </Text>
                                    </div>
                                    <div style={{ padding: '0px 16px' }}>
                                        {reasonCodes
                                            ?.filter(code => code.category === category)
                                            .map(reasonCode => (
                                                <FeedbackReasonCodeIndividualCheckbox
                                                    key={reasonCode.id}
                                                    itemId={itemId}
                                                    reasonCode={reasonCode}
                                                />
                                            ))}
                                    </div>
                                </div>
                                <Grid container className={classes.navAreaMobile} direction={'row'} spacing={1}>
                                    <Grid item xs={6}>
                                        <Button
                                            variant={'secondary'}
                                            style={{ width: `100%`, textAlign: 'center' }}
                                            onClick={() => setShowOptionsList(!showOptionsList)}
                                        >
                                            Cancel
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            variant={'primary'}
                                            onClick={() => setShowOptionsList(!showOptionsList)}
                                            style={{ width: `100%`, textAlign: 'center' }}
                                        >
                                            Done
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Slide>
                    </PracticeFullScreenDialog>
                )}
            </Grid>
        </Grid>
    );
};

export const FeedbackReasonCodeCategoryCell: React.VFC<FeedbackReasonCodeCategoryCellProps> = props => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return isMobile ? <FeedbackReasonCodeCellMobile {...props} /> : <FeedbackReasonCodeCellDesktop {...props} />;
};
