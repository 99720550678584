import { useLazyQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const GetPartnerSessionForScanner_Query = graphql(`
    query PartnerSessionForScanner($staff_member_id: String) {
        partnerSessionForScanner(staff_member_id: $staff_member_id) {
            native_id_token
        }
    }
`);

export function usePartnerSessionForScanner({ onSuccess }: { onSuccess?: (nativeTokenId: string) => void }) {
    const [partnerSessionForScanner, { error, loading }] = useLazyQuery(GetPartnerSessionForScanner_Query, {
        onCompleted: data => {
            onSuccess?.(data.partnerSessionForScanner.native_id_token);
        },
    });

    return {
        submit: (staffMemberId: string | null) => {
            partnerSessionForScanner({ variables: { staff_member_id: staffMemberId } });
        },
        error: error?.message,
        loading,
    };
}
