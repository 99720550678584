import { CheckoutMouthContainer } from '../../components/CheckoutSidebar/CheckoutMouth.container';
import { useScanIsAligner } from '../../state/checkout.selectors';
import { useScanIsDenture, useDentureCheckoutPropSelector } from '../../state/dentures-checkout.selectors';
import { useCheckoutSummaryVisible } from '../../state/useCheckoutSummaryVisible';
import {
    AlignerCheckoutSummaryItemsColumn,
    AlignerPhotosSection,
} from '../AlignerCheckout/AlignerSummaryScreenSections';
import { DentureSummaryForCheckout } from '../DentureCheckout/DentureSummaryForCheckout';
import { CheckoutSummaryItemsColumn } from './CheckoutSummaryItemsColumn';
import { CheckoutSummaryReviewColumn } from './CheckoutSummaryReviewColumn';
import { CheckoutSummaryScreenTitle } from './CheckoutSummaryScreenTitle';
import { stylesFactory, useScreenIsMobile, Fade, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ isAligner?: boolean }>(theme => ({
    root: {
        maxHeight: 'calc(100vh - 64px)',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('md')]: {
            maxHeight: 'calc(100vh - 56px)',
            height: '100%',
        },
        overflow: 'scroll',
        padding: `0 ${theme.typography.pxToRem(48)} 86px`,
    },
    rootRow: {
        height: '100%',
        alignContent: 'flex-start',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            overflow: 'scroll',
            padding: `0 ${theme.typography.pxToRem(24)} 0`,
            flexDirection: 'row-reverse',
            flexWrap: 'wrap',
        },
    },
    columnMain: {
        height: '100%',
        [theme.breakpoints.down('md')]: {
            paddingBottom: '0 !important',
            height: 'auto',
            // the items and teeth col
            '&:last-of-type': {
                height: 'fit-content',
                paddingTop: 0,
            },
        },
    },
    teethColumn: props => ({
        height: props.isAligner ? 250 : 'auto',
        position: 'relative',
        padding: `${theme.typography.pxToRem(32)} ${theme.typography.pxToRem(48)} !important`,
        maxHeight: 'min(550px, 70vh)',
        [theme.breakpoints.down('md')]: {
            padding: `0 ${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(12)} !important`,
        },
    }),
}));

export const CheckoutSummaryScreen: React.FC = () => {
    const isMobile = useScreenIsMobile();
    const summaryShowing = useCheckoutSummaryVisible();
    const isAligner = useScanIsAligner();
    const is_denture = useScanIsDenture();
    const { fields: denture_fields } = useDentureCheckoutPropSelector([`fields`]);
    const classes = useStyles({ isAligner });
    const [hoveredItems, setHoveredItems] = React.useState<number[]>([]);
    const [showMouth, setShowMouth] = React.useState<boolean>(summaryShowing);
    /* eslint-disable no-nested-ternary */
    return (
        <Grid container direction={'column'} wrap={'nowrap'} className={classes.root}>
            <Grid container className={classes.rootRow} spacing={!isMobile ? 8 : undefined}>
                <Grid container item xs={12} md={isAligner ? 5 : 4} className={classes.columnMain}>
                    <CheckoutSummaryScreenTitle title={'Review Order'} />
                    <CheckoutSummaryReviewColumn />
                </Grid>
                <Grid container item xs={12} md={isAligner ? 7 : 8} className={classes.columnMain}>
                    <CheckoutSummaryScreenTitle title={'Items'} />
                    <Grid container alignContent={'flex-start'} style={{ overflow: 'hidden', height: '100%' }}>
                        {isAligner ? (
                            <AlignerCheckoutSummaryItemsColumn />
                        ) : is_denture ? (
                            <Grid container item xs={12} md={6}>
                                <DentureSummaryForCheckout fields={denture_fields} />
                            </Grid>
                        ) : (
                            <CheckoutSummaryItemsColumn
                                onItemHoverEnd={itemIndex => setHoveredItems(hoveredItems.filter(i => i !== itemIndex))}
                                onItemHoverStart={itemIndex => setHoveredItems(i => [...i, itemIndex])}
                            />
                        )}
                        <Fade
                            in={summaryShowing}
                            exit
                            timeout={{ enter: 200, exit: 300 }}
                            onEntered={() => setShowMouth(true)}
                            onExited={() => setShowMouth(false)}
                        >
                            <Grid container item xs={12} md={6} className={classes.teethColumn}>
                                {showMouth && (
                                    <CheckoutMouthContainer
                                        layout={'vertical'}
                                        forceHighlightItemIndexes={hoveredItems}
                                    />
                                )}
                            </Grid>
                        </Fade>
                    </Grid>
                </Grid>
            </Grid>
            <AlignerPhotosSection />
        </Grid>
    );
};
