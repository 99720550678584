import { useSetNewPasswordValidation } from '../../../../components/forms/useSetNewPasswordValidation';
import { LabeledTextField } from '../../components/labeled-field/LabeledFields';
import { useRevealPassword } from '../../components/labeled-field/useRevealPassword';
import { PageLayout } from '../../components/page-layout/PageLayout';
import { useUpdateUserPassword } from './useUpdateUserPassword.graphql';
import { useSession } from '@orthly/session-client';
import { Button, FormHelperText } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const SetNewPassword: React.FC = () => {
    const history = useHistory();
    const userId = useSession()?.user_id;
    const [password, setPassword] = React.useState('');
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const { action, type } = useRevealPassword();
    const { enqueueSnackbar } = useSnackbar();

    const { isFormValid, passwordError, confirmedPasswordError, setShowConfirmedPasswordError, setShowPasswordError } =
        useSetNewPasswordValidation({
            password,
            confirmedPassword,
        });

    const {
        submit: updateUserPassword,
        submitting: updatingUserPassword,
        error: updateUserPasswordError,
    } = useUpdateUserPassword();

    const shouldContinue = !updatingUserPassword && isFormValid;

    React.useEffect(() => {
        if (!userId) {
            enqueueSnackbar('Reset link invalid', { variant: 'error' });
            history.push('/login');
        }
    }, [enqueueSnackbar, history, userId]);

    return (
        <PageLayout
            pageMessage={'Reset'}
            panelTitle={'Reset your password'}
            panelDescription={`Enter a new password for your account.`}
            onBack={() => history.push('/inbox')}
            onFormSubmit={e => {
                e.preventDefault();
                void updateUserPassword(password);
            }}
        >
            <LabeledTextField
                isRequired
                label={'New password'}
                placeholder={'Enter new password'}
                value={password}
                onChange={(next = '') => {
                    setPassword(next);
                }}
                TextFieldProps={{
                    type,
                    autoFocus: true,
                    onBlur: () => {
                        setShowPasswordError(password !== '');
                    },
                    error: !!passwordError,
                    helperText: passwordError,
                }}
                action={action}
            />
            <LabeledTextField
                isRequired
                label={'Confirm New password'}
                placeholder={'Re-enter new password'}
                value={confirmedPassword}
                onChange={(next = '') => {
                    setConfirmedPassword(next);
                }}
                TextFieldProps={{
                    type,
                    onBlur: () => {
                        setShowConfirmedPasswordError(confirmedPassword !== '');
                    },
                    error: !!confirmedPasswordError,
                    helperText: confirmedPasswordError,
                }}
                action={action}
            />
            {updateUserPasswordError && <FormHelperText error>{updateUserPasswordError}</FormHelperText>}
            <Button variant={'primary'} fullWidth type={'submit'} disabled={!shouldContinue}>
                Continue
            </Button>
        </PageLayout>
    );
};
