import { AlignerPlan } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { ALIGNER_PLANS_DETAIL } from '@orthly/shared-types';
import {
    stylesFactory,
    FlossPalette,
    FlossPaletteUtils,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@orthly/ui-primitives';
import React from 'react';

const useRootStyles = stylesFactory(() => ({
    table: {
        height: '100%',
        [`& td:first-child, & th:first-child`]: {
            paddingLeft: 0,
        },
        [`& tbody tr:last-child td, & tbody tr:last-child th`]: {
            borderBottom: `none`,
            paddingBottom: 24,
        },
        [`& thead th`]: {
            fontSize: 18,
            paddingTop: 24,
        },
        [`& thead th:first-child`]: {
            color: FlossPalette.GRAY,
        },
        [`& thead th:not(:first-child)`]: {
            fontWeight: `bold`,
        },
        [`& tbody th`]: {
            fontWeight: `500`,
            fontSize: 18,
        },
        [`& tbody td`]: {
            fontSize: 18,
        },
        [`& .active`]: {
            backgroundColor: FlossPaletteUtils.toRgba('STAR_GRASS', 0.3),
        },
    },
}));

interface AlignerInfoProps {
    plan: AlignerPlan;
    overriddenPatientSteps?: number | null;
}

export const AlignerPlanInfo: React.FC<AlignerInfoProps> = ({ plan, overriddenPatientSteps }) => {
    const classes = useRootStyles();
    const currentPlan = React.useMemo<AlignerPlan>(() => {
        if (overriddenPatientSteps) {
            if (overriddenPatientSteps <= 10) {
                return AlignerPlan.OnDemand;
            }
            if (overriddenPatientSteps <= 20) {
                return AlignerPlan.Dandy20;
            }
            return AlignerPlan.DandyMax;
        }
        return plan;
    }, [plan, overriddenPatientSteps]);

    const is_active = (name: string) => (name === currentPlan ? `active` : ``);

    return (
        <Table className={classes.table}>
            <TableHead>
                <TableRow>
                    <TableCell>Plans</TableCell>
                    {Object.entries(ALIGNER_PLANS_DETAIL).map(([name, { displayName }]) => (
                        <TableCell key={name} className={is_active(name)}>
                            {displayName}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell component={`th`} scope={`row`}>
                        Steps
                    </TableCell>
                    {Object.entries(ALIGNER_PLANS_DETAIL).map(([name, { durationInStepsMin, durationInStepsMax }]) => (
                        <TableCell key={name} className={is_active(name)}>
                            {Format.rangeToReadable(durationInStepsMin, durationInStepsMax) ?? 'any'}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell component={`th`} scope={`row`}>
                        Refinements
                    </TableCell>
                    {Object.entries(ALIGNER_PLANS_DETAIL).map(([name, { refinements }]) => (
                        <TableCell key={name} className={is_active(name)}>
                            {typeof refinements === 'string' || typeof refinements === 'number'
                                ? refinements
                                : 'à la carte'}
                        </TableCell>
                    ))}
                </TableRow>
                <TableRow>
                    <TableCell component={`th`} scope={`row`}>
                        Retainers
                    </TableCell>
                    {Object.entries(ALIGNER_PLANS_DETAIL).map(([name, { retainers }]) => (
                        <TableCell key={name} className={is_active(name)}>
                            {typeof retainers === 'number' ? Format.pluralize('set', retainers) : 'à la carte'}
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
    );
};
