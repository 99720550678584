import { Section } from '../../../components/Section';
import { FieldGroup, FullWidthField } from '../FieldGroup.styles';
import { useAccountSettings } from '../context';
import { LabsUtilsBase } from '@orthly/dentin';
import { useSession } from '@orthly/session-client';
import { SimplePhoneInput } from '@orthly/ui';
import { CheckboxesGroup, MenuItem, TextField } from '@orthly/ui-primitives';
import React from 'react';

export const ContactInformationSection: React.FC = () => {
    const session = useSession();
    const {
        preferredName,
        setPreferredName,
        duplicateContactInfo,
        toggleDuplicateContactInfo,
        accountEmail,
        contactEmail,
        setContactEmail,
        accountPhone,
        contactPhone,
        setContactPhone,
        timezone,
        setTimezone,
        bestCallHour,
        setBestCallHour,
        contactPhoneError,
        contactEmailError,
    } = useAccountSettings();

    return (
        <Section
            title={'Contact information'}
            subtitle={`Used for communications related to this organization (${session?.organization_name})`}
        >
            <FieldGroup>
                <FullWidthField>
                    <TextField
                        label={'Preferred name'}
                        data-testid={'preferred-name'}
                        fullWidth
                        variant={'standard'}
                        value={preferredName}
                        onChange={event => setPreferredName(event.target.value)}
                    />
                </FullWidthField>
                <FullWidthField>
                    <CheckboxesGroup
                        values={{ duplicateContactInfo }}
                        handleChange={toggleDuplicateContactInfo}
                        variant={'compact'}
                        options={[
                            {
                                id: 'duplicateContactInfo',
                                label: 'The email and phone number that I use to log in are the same as my contact info',
                            },
                        ]}
                    />
                </FullWidthField>
                {duplicateContactInfo ? null : (
                    <>
                        <TextField
                            label={'Contact email address'}
                            data-testid={'contact-email'}
                            fullWidth
                            variant={'standard'}
                            type={'email'}
                            placeholder={accountEmail}
                            value={contactEmail}
                            onChange={event => setContactEmail(event.target.value)}
                            helperText={contactEmail !== '' && contactEmailError}
                            error={contactEmail !== '' && !!contactEmailError}
                        />
                        <SimplePhoneInput
                            label={'Contact phone number'}
                            data-testid={'contact-phone'}
                            fullWidth
                            variant={'standard'}
                            placeholder={accountPhone}
                            value={contactPhone}
                            onChange={value => setContactPhone(value ?? '')}
                            TextFieldProps={{
                                type: 'tel',
                                autoFocus: true,
                                error: !!contactPhoneError,
                                helperText: contactPhoneError,
                            }}
                        />
                    </>
                )}
                <TextField
                    label={'Timezone'}
                    data-testid={'timezone'}
                    fullWidth
                    variant={'standard'}
                    select
                    value={timezone}
                    onChange={event => setTimezone(event.target.value)}
                >
                    {LabsUtilsBase.notificationTimeZoneOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    label={`What's the best time to call?`}
                    data-testid={'best-call-hour'}
                    fullWidth
                    variant={'standard'}
                    select
                    value={bestCallHour}
                    onChange={event => {
                        setBestCallHour(event.target.value);
                    }}
                >
                    {LabsUtilsBase.bestCallHourOptions(timezone).map(option => {
                        return (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </FieldGroup>
        </Section>
    );
};
