import {
    getAskDoctorV2Configuration,
    useAskDoctorResponseDialog,
} from '../../../../../../../../order-detail-v2/components/AskDoctorResponse';
import { useGenericTimelineActionStyles } from './sharedStyles';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { Button } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const PracticeAnswerDoctorQuestionAction_Query = graphql(`
    query PracticeAnswerDoctorQuestionAction_Query($lab_order_id: String!) {
        getActiveScanReviewByLabOrderId(lab_order_id: $lab_order_id) {
            ...PracticeGetAskDoctorV2ConfigurationScanReview_Fragment
        }
    }
`);

export const AnswerDoctorQuestionAction: React.VFC<{
    className?: string;
    order: LabsGqlOrder;
}> = ({ className, order }) => {
    const classes = useGenericTimelineActionStyles();

    const { data: scanReviewData } = useQuery(PracticeAnswerDoctorQuestionAction_Query, {
        variables: {
            lab_order_id: order.id,
        },
    });

    const { doctorRequestId, shouldShowResolveButton } = getAskDoctorV2Configuration(
        order,
        scanReviewData?.getActiveScanReviewByLabOrderId ?? null,
    );

    const [setAskDoctorResponseOpen, askDoctorResponseDialog] = useAskDoctorResponseDialog({
        doctorRequestId,
        orderId: order.id,
    });

    if (shouldShowResolveButton) {
        return (
            <div className={clsx(classes.actionWrapper, className)}>
                <Button variant={'primary'} onClick={() => setAskDoctorResponseOpen(true)}>
                    Resolve issue
                </Button>
                {askDoctorResponseDialog}
            </div>
        );
    }

    return null;
};
