import type { PartnerAppState } from '../../../redux/types';
import { CreateStaffStepProgression } from './select-staff.progression';
import type { SelectStaffState, StaffProfileInfo } from './select-staff.reducer';
import { useGetDoctorPreferencesByIdQuery } from '@orthly/graphql-react';
import { generateUseStatePropSelector, generateUseSubstateSelector } from '@orthly/redux-async-actions';

export const useSelectStaffSelector = generateUseSubstateSelector<PartnerAppState, 'staffMember'>('staffMember');
export const useSelectStaffPropSelector = generateUseStatePropSelector<SelectStaffState>(
    useSelectStaffSelector,
    'staffMember',
);

export function selectShouldShowSelectStaff(state: SelectStaffState) {
    return !(state.skipped || state.profile) || !!state.createWithoutAutoLogin;
}

export function selectCreateStaffProgression(state: SelectStaffState) {
    return CreateStaffStepProgression[state.step](state);
}

export function useRehydratedProfile(profile?: StaffProfileInfo) {
    const { data: doctorData } = useGetDoctorPreferencesByIdQuery(
        profile?.__typename === 'DoctorPreferences'
            ? { variables: { doctor_id: profile.id }, fetchPolicy: 'cache-first' }
            : { skip: true },
    );
    return doctorData?.preferences;
}
