import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const PartialDenturesMaterialsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'flexible',
                material: 'Flexible (Bilateral, Straight to Finish)',
                price: getPrice('Flexible Partial (Bilateral, STF)'),
            },
            {
                id: 'acetal',
                material: 'Acetal (Bilateral, Straight to Finish)',
                price: getPrice('Flexible Partial (Bilateral, STF)'),
            },
            {
                id: 'cast_metal',
                material: 'Cast Metal (Bilateral, Straight to Finish)',
                price: getPrice('Metal Partial (Bilateral, STF)'),
            },
            {
                id: 'hybrid',
                material: 'Hybrid (Bilateral, Straight to Finish)',
                price: getPrice('Hybrid Partial (Bilateral, STF)'),
            },
            {
                id: 'acrylic_partial',
                material: 'Acrylic Partial: 4+ teeth',
                price: getPrice('Acrylic Partial'),
            },
            {
                id: 'acrylic_flipper',
                material: 'Acrylic Flipper: 1-3 teeth',
                price: getPrice('Acrylic Flipper'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'partial-dentures-materials'}
            title={'Materials'}
            columns={[
                {
                    id: 'material',
                    title: 'Material',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
