import { useCurrentPartner } from '../../../../utils/CurrentPartnerContext';
import { CollapsedQuickFormSubmit } from '../../components/CollapsedQuickFormSubmit';
import { useUpdateOrgNameMutation } from '@orthly/graphql-react';
import type { LabsGqlMutationUpdateOrganizationNameArgs } from '@orthly/graphql-schema';
import { useChangeSubmissionFn, QuickForm } from '@orthly/ui';
import React from 'react';
import { z } from 'zod';

const MIN_DISPLAY_NAME_LEN = 5;
const MAX_DISPLAY_NAME_LEN = 75;

type UpdateNameArgs = LabsGqlMutationUpdateOrganizationNameArgs['data'];

export const ChangeDisplayName: React.FC = () => {
    const currentPartner = useCurrentPartner();
    const [submitMtn] = useUpdateOrgNameMutation();
    const mtnSubmitter = (data: UpdateNameArgs) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdateNameArgs]>(mtnSubmitter, {
        successMessage: () => ['Business name updated!', {}],
        onSuccess: async () => {
            void currentPartner.refetch();
        },
        errorMessage: () => ['Failed to change practice name.', {}],
    });

    return (
        <QuickForm<{ name: string }>
            resetOnInitialValueChange={true}
            fields={{
                name: {
                    fieldProps: { style: { marginBottom: 16, position: 'relative' as const }, type: 'text' },
                    label: 'Name',
                    type: 'text',
                    validation: z.string().min(MIN_DISPLAY_NAME_LEN).max(MAX_DISPLAY_NAME_LEN),
                },
            }}
            dirtySubmitOnly={true}
            disabled={submitting}
            CustomSubmit={CollapsedQuickFormSubmit}
            onSubmit={data =>
                submit({ organization_id: currentPartner.data?.id ?? '', legal_name: data.name, ...data })
            }
            submitButtonProps={{ style: { display: 'none' } }}
            initialValues={{ name: currentPartner.data?.name ?? '' }}
            preventDirtyExitMessage={'Your name has not been saved yet. Are you sure you want to leave without saving?'}
        />
    );
};
