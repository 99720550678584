import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import { CheckoutButtonSelect, DandyMouthToothSelector } from '@orthly/dentin';
import type { ItemDataFieldId, LabOrderItemSKUType, ToothSelectMultiItemDataField, ToothNumber } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface CheckoutToothSelectMultiItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends CheckoutItemDataFieldBaseProps<SKU> {
    field: ToothSelectMultiItemDataField<ID, SKU>;
}

export const CheckoutToothSelectMultiItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
}: CheckoutToothSelectMultiItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    const applyUpdate = React.useCallback(
        (val: ToothNumber[]) => {
            updateItem(field.getUpdatePayload(_.sortBy(val), item));
        },
        [field, item, updateItem],
    );

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <Grid container>
            <Grid item xs={12}>
                {getFieldLabel(field, item)}
            </Grid>
            {/* Future improvement: add a way to hide this button when users are required to select at least one entry */}
            <Grid item xl={3} lg={4} md={2} xs={12}>
                <CheckoutButtonSelect
                    label={''}
                    mode={'single'}
                    value={value && !value.length ? 'none' : undefined}
                    onChange={() => applyUpdate([])}
                    options={[{ label: 'None', value: 'none' }]}
                />
            </Grid>
            <Grid item xl={4} lg={7} md={10}>
                <DandyMouthToothSelector
                    selected={value ?? []}
                    onChange={val => applyUpdate(val)}
                    verticalLayout={false}
                    animate={false}
                    archLabels={false}
                />
            </Grid>
        </Grid>
    );
};
