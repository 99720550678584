import type { PhotoUploadConfig } from './PhotoUpload.types';
import { Blue, DentureWithAppliance, DentureWithoutAppliance } from '@orthly/ui';
import { AssignmentIndIcon, InsertDriveFileIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const implantPhotoUploadConfig: PhotoUploadConfig = {
    firestoreProductName: 'implants',
    photos: [
        {
            filename: 'XrayOfScanbody',
            userFacingTitle: 'X-Ray of Scan body',
            // prettier-ignore
            guidanceTitle: isDesktop =>
                <>{isDesktop ? `Upload` : `Take`} a photo of the <Blue>scan body x-ray</Blue>{' '}</>,
            icon: AssignmentIndIcon,
        },
        {
            filename: 'SurgicalReport',
            userFacingTitle: 'Surgical Report',
            // prettier-ignore
            guidanceTitle: isDesktop =>
                <>{isDesktop ? `Upload` : `Take`} a photo of the <Blue>surgical report</Blue>{' '}</>,
            icon: InsertDriveFileIcon,
            overrideDropzoneOptions: {
                accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'], 'application/pdf': ['.pdf'] },
            },
        },
    ],
};

export const denturePhotoUploadConfig: PhotoUploadConfig = {
    firestoreProductName: 'dentures',
    photos: [
        {
            filename: 'WithAppliance',
            userFacingTitle: 'Head-on photo with the appliance inserted',
            icon: DentureWithAppliance,
            required: false,
            // prettier-ignore
            guidanceTitle: isDesktop =>
                <>{isDesktop ? `Upload` : `Take`} a head-on photo <Blue>with the appliance inserted</Blue>{' '}</>,
        },
        {
            filename: 'WithoutAppliance',
            userFacingTitle: 'Head-on photo without the appliance inserted',
            icon: DentureWithoutAppliance,
            required: false,
            // prettier-ignore
            guidanceTitle: isDesktop =>
                <>{isDesktop ? `Upload` : `Take`} a head-on photo <Blue>without the appliance inserted</Blue>{' '}</>,
        },
    ],
};

export const wellnessScansPhotoUploadConfig: PhotoUploadConfig = {
    firestoreProductName: 'wellness_scan',
    photos: _.range(0, 3).map(idx => ({
        filename: `WellnessScan${idx}`,
        userFacingTitle: `Photo ${idx + 1}`,
    })),
};
