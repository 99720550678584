import { ZendeskChatUnavailablePanel } from '../../components/ZendeskChatUnavailablePanel';
import { useChatAction } from './chat-state/chat.actions';
import { useChatSelector } from './chat-state/chat.selectors';

export const ChatUnavailableRoot: React.VFC<{}> = () => {
    const open = useChatSelector(({ outOfOfficeOpen }) => outOfOfficeOpen);
    const closeOutOfOfficeAction = useChatAction('CLOSE_OUT_OF_OFFICE');

    return <ZendeskChatUnavailablePanel open={open} onClose={closeOutOfOfficeAction} />;
};
