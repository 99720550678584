import { trackGuidedWaxupButtonClick } from '../../DesignViewerLiteDetachedContainer';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { ToggleButton } from './ToggleButton/ToggleButton';
import { useToggleMarginLines } from '@orthly/dentin';
import React from 'react';

export const useMarginToggleButton = () => {
    const { appearance, setAppearance } = useGuidedWaxupContext();
    const onToggleMarginLines = useToggleMarginLines(setAppearance);

    const onClick = React.useCallback(() => {
        trackGuidedWaxupButtonClick('Toggle Margin Lines');
        onToggleMarginLines();
    }, [onToggleMarginLines]);

    return { checked: appearance.showMarginLines, onClick };
};

export const MarginToggleButton: React.VFC = () => {
    const { checked, onClick } = useMarginToggleButton();

    return <ToggleButton checked={checked} icon={'ModelMarginIcon'} onClick={onClick} label={'Margin'} />;
};
