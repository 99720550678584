import { FlossPalette, styled } from '@orthly/ui-primitives';

export const NewBadge = styled('span')({
    backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
    color: FlossPalette.SECONDARY_FOREGROUND,
    borderRadius: 2,
    fontSize: 12,
    marginLeft: 8,
    padding: '0 8px',
});
