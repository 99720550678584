import { CheckoutItemSelectField } from '../CheckoutItemSelectField';
import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import type { ItemDataFieldId, LabOrderItemSKUType, BooleanItemDataField } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import React from 'react';

const getStringFromBoolean = (val: boolean | null | undefined): string | undefined => {
    if (val === true) {
        return 'Yes';
    }
    if (val === false) {
        return 'No';
    }
    return undefined;
};

const getBooleanFromString = (val: string | undefined): boolean | null => {
    if (val === 'Yes') {
        return true;
    }
    if (val === 'No') {
        return false;
    }
    return null;
};

interface CheckoutBooleanItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends CheckoutItemDataFieldBaseProps<SKU> {
    field: BooleanItemDataField<ID, SKU>;
}

export const CheckoutBooleanItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
}: CheckoutBooleanItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item);

    const applyUpdate = (val: string | undefined) => {
        updateItem(field.getUpdatePayload(getBooleanFromString(val), item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <CheckoutItemSelectField
            label={getFieldLabel(field, item)}
            value={getStringFromBoolean(value)}
            options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
            ]}
            onChange={val => applyUpdate(val)}
            required={ItemDataFieldUtils.isItemDataFieldRequired(field, item)}
        />
    );
};
