import { useTryInFeedbackAction } from '../state/TryInFeedback.action';
import { useTryInFeedbackPropSelector } from '../state/TryInFeedback.selectors';
import type { TryInFeedbackItemGroup } from '../state/TryInFeedback.types';
import { TryInFeedbackUtils } from '../utils/TryInFeedback.util';
import { AllTryInFeedbackOptions } from './feedback-options/TryInFeedbackOption.definition';
import type { TryInFeedbackOptionDefinition } from './feedback-options/TryInFeedbackOption.types';
import { TryInFeedbackScreenLayout } from './layout/TryInFeedbackScreenLayout';
import type { LabOrderItemSKUType } from '@orthly/items';
import { OrderItemArch } from '@orthly/items';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface TryInFeedbackArchFeedbackProps {
    arch: OrderItemArch;
}

interface FeedbackOptionProps {
    group: TryInFeedbackItemGroup;
    arch: OrderItemArch;
    option: TryInFeedbackOptionDefinition<LabOrderItemSKUType>;
}

const FeedbackOption: React.VFC<FeedbackOptionProps> = ({ arch, option, group }) => {
    const { showError } = useTryInFeedbackPropSelector(['showError']);
    const updateNewItem = useTryInFeedbackAction('UPDATE_NEW_ITEM');
    const selectFeedbackDetail = useTryInFeedbackAction('SELECT_FEEDBACK_DETAIL');
    const removeFeedbackDetail = useTryInFeedbackAction('REMOVE_FEEDBACK_DETAIL');
    const updateFeedbackDetail = useTryInFeedbackAction('UPDATE_FEEDBACK_DETAIL');

    const details = group.feedbackDetails.find(detail => detail.category === option.category && detail.arch === arch);

    const onDescriptionChanged = React.useCallback(
        description =>
            updateFeedbackDetail({
                arch,
                description,
                itemId: group.itemId,
                category: option.category,
            }),
        [updateFeedbackDetail, arch, group.itemId, option.category],
    );

    return (
        <option.Component
            orderItem={group.orderItem}
            newItem={group.newItem}
            updateNewItem={update => updateNewItem({ update, itemId: group.itemId })}
            isSelected={!!details}
            description={details?.description ?? null}
            onSelected={() => selectFeedbackDetail({ arch, itemId: group.itemId, category: option.category })}
            onUnselected={() => removeFeedbackDetail({ arch, itemId: group.itemId, category: option.category })}
            onDescriptionChanged={onDescriptionChanged}
            showError={showError}
        />
    );
};

export const TryInFeedbackArchFeedback: React.VFC<TryInFeedbackArchFeedbackProps> = ({ arch }) => {
    const state = useTryInFeedbackPropSelector(['navigation', 'itemGroups']);
    const group = TryInFeedbackUtils.getActiveGroup(state);

    if (!group) {
        return null;
    }

    return (
        <TryInFeedbackScreenLayout
            header={
                <>
                    What needs to be changed in the{' '}
                    {arch !== OrderItemArch.Dual && (
                        <Text variant={'h6'} color={'BURGUNDY'} component={'span'}>
                            {arch} Arch{' '}
                        </Text>
                    )}
                    {TryInFeedbackUtils.getItemTypeTitle(group.orderItem)}?
                </>
            }
            subHeader={'You can select multiple categories'}
        >
            <Grid container direction={'column'} spacing={2}>
                {AllTryInFeedbackOptions.map(option => {
                    if (
                        !OrderItemV2Utils.itemIsType(group.orderItem, option.sku) ||
                        !CartItemV2Utils.itemIsType(group.newItem, option.sku) ||
                        !option.isEnabled(group.orderItem)
                    ) {
                        return null;
                    }
                    if (!TryInFeedbackUtils.doesOptionArchMatchSubStepForItem(group.orderItem, arch, option)) {
                        return null;
                    }

                    return <FeedbackOption key={option.category} arch={arch} group={group} option={option} />;
                })}
            </Grid>
        </TryInFeedbackScreenLayout>
    );
};
