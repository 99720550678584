import type { QuickFormCustomSubmitProps } from '@orthly/ui';
import { Button, Collapse } from '@orthly/ui-primitives';
import React from 'react';

export const CollapsedQuickFormSubmit: React.VFC<QuickFormCustomSubmitProps> = ({ triggerSubmit, disabled }) => {
    return (
        <Collapse in={!disabled} style={{ width: '100%' }}>
            <Button fullWidth disabled={disabled} onClick={triggerSubmit} variant={'contained'}>
                Save
            </Button>
        </Collapse>
    );
};
