import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import { ItemDataFieldUtils } from '@orthly/items';
import type { DateItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import type { MaterialUiPickersDate } from '@orthly/ui';
import { SimpleDatePopupPicker } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const getValidMoment = (val: MaterialUiPickersDate): moment.Moment | null => {
    if (!val) {
        return null;
    }
    const date = moment(val);
    return date.isValid() ? date : null;
};

interface CheckoutDateItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends CheckoutItemDataFieldBaseProps<SKU> {
    field: DateItemDataField<ID, SKU>;
}

export const CheckoutDateItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
}: CheckoutDateItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = getValidMoment(field.getValue(item) as MaterialUiPickersDate);
    const minDate = getValidMoment(field.getMinValue?.(item) as MaterialUiPickersDate);
    const maxDate = getValidMoment(field.getMaxValue?.(item) as MaterialUiPickersDate);

    const applyUpdate = (val: MaterialUiPickersDate) => {
        const date = getValidMoment(val);
        updateItem(field.getUpdatePayload(date ? date.toISOString() : null, item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <Grid container direction={'column'}>
            <Grid item style={{ paddingBottom: 12 }}>
                <Text style={{ fontWeight: 500 }}>{getFieldLabel(field, item)}</Text>
            </Grid>
            <Grid item>
                <SimpleDatePopupPicker
                    label={'Click to select'}
                    value={value ? value.toDate() : null}
                    onChange={val => applyUpdate(val)}
                    closeOnSelect={true}
                    minDate={minDate ? minDate.toDate() : undefined}
                    maxDate={maxDate ? maxDate.toDate() : undefined}
                    fullWidth={true}
                    slotProps={{
                        textField: {
                            required: ItemDataFieldUtils.isItemDataFieldRequired(field, item),
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};
