import { ScanbodyInventory } from '../component/ScanbodyInventory';
import { ScanbodyRequestLookup } from '../component/ScanbodyRequestLookup';
import { useScanbodyRequestPropSelector } from '../state/ScanbodyRequest.selectors';
import { ScanbodyStage } from '../state/ScanbodyRequest.types';
import { useScreenIsMobile, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const ScanbodyOverview: React.FC = () => {
    const { stage } = useScanbodyRequestPropSelector(['stage']);
    const isMobile = useScreenIsMobile();

    if (stage !== ScanbodyStage.OVERVIEW) {
        return null;
    }
    return (
        <Grid container style={isMobile ? { padding: '0px 16px' } : { padding: '0px 40px', alignSelf: 'flex-start' }}>
            <ScanbodyRequestLookup />
            <ScanbodyInventory />
        </Grid>
    );
};
