import { useCheckoutTransitionTimeLapsed } from '../../state/CheckoutDisplayUtils';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import {
    useDentureCheckoutSelector,
    useDentureCheckoutStepInfo,
    useDentureCheckoutBaseShade,
    useDentureCheckoutTissueShade,
} from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { RadioButton } from './DentureSelectTypeScreen';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import {
    LabsGqlDenturesFabricationMethod,
    LabsGqlDenturesProductionType,
    LabsGqlDenturesTeethQuality,
} from '@orthly/graphql-schema';
import type { ValidShadeType, TeethShade } from '@orthly/items';
import { TeethShades } from '@orthly/items';
import { stylesFactory, FlossPalette, Grid, TextField, Autocomplete, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    section: { padding: 24, backgroundColor: FlossPalette.TAN, borderRadius: 16 },
    sectionTitle: { marginTop: 24 },
    image: { maxWidth: '100%', display: 'block', margin: '40px auto' },
    nonImageDivider: { marginBottom: 24 },
    inputLabel: { marginBottom: 16 },
    inputField: { marginBottom: 24 },
}));

const useAutocompleteStyles = stylesFactory(() => ({
    inputRoot: { backgroundColor: FlossPalette.TAN, paddingTop: 0 },
}));

interface ShadeCombinerPoint {
    values: readonly TeethShade[];
    suffix: string;
}
interface SelectOptionInterface {
    text: string;
    value: string;
}
const combineShadeOptions = (shadeCombinerPoints: ShadeCombinerPoint[]): SelectOptionInterface[] => {
    return shadeCombinerPoints.flatMap(point =>
        point.values.map(value => ({ value, text: `${value} (${point.suffix})` })),
    );
};
const baseShadeOptions = combineShadeOptions([
    { values: TeethShades.classical, suffix: 'Vita Classical' },
    { values: TeethShades.master, suffix: 'Vita 3D Master' },
    { values: TeethShades.bleached, suffix: 'Ivoclar Bleached' },
]);
const tissueShadeOptions = combineShadeOptions([{ values: TeethShades.tissue, suffix: 'Tissue' }]);

interface ShadeBulletInterface {
    title: string;
    shadeName: ValidShadeType;
    shadeOptions: SelectOptionInterface[];
    selectedShade?: TeethShade;
    forceOpen: boolean;
}
export const ShadeSection: React.VFC = () => {
    const classes = useStyles();
    const autocompleteClasses = useAutocompleteStyles();
    const selectedBaseShade = useDentureCheckoutBaseShade();
    const selectedTissueShade = useDentureCheckoutTissueShade();
    const setShade = useDenturesCheckoutAction('CHOOSE_DENTURE_SHADE');
    const transitionDone = useCheckoutTransitionTimeLapsed();

    const shadeBullets: ShadeBulletInterface[] = [
        {
            title: 'Teeth Shade',
            shadeName: 'base',
            shadeOptions: baseShadeOptions,
            selectedShade: selectedBaseShade,
            forceOpen: transitionDone && !selectedBaseShade,
        },
        {
            title: 'Tissue Shade',
            shadeName: 'tissue',
            shadeOptions: tissueShadeOptions,
            selectedShade: selectedTissueShade,
            forceOpen: transitionDone && !!selectedBaseShade && !selectedTissueShade,
        },
    ];

    const [toggledOpenMap, setIsToggledOpen] = React.useState<Record<string, boolean>>(
        shadeBullets.reduce<Record<string, boolean>>((acc, b) => {
            acc[b.shadeName] = false;
            return acc;
        }, {}),
    );

    return (
        <>
            {shadeBullets.map(bullet => (
                <Grid key={bullet.title} item xs={6}>
                    <Text variant={'h6'} medium className={classes.inputLabel}>
                        {bullet.title}
                    </Text>
                    <Autocomplete
                        classes={autocompleteClasses}
                        options={bullet.shadeOptions.map(option => option.value)}
                        renderInput={params => (
                            <TextField {...params} value={bullet.selectedShade} fullWidth variant={'standard'} />
                        )}
                        getOptionLabel={value =>
                            _.find(bullet.shadeOptions, option => value === option.value)?.text ?? 'Unknown'
                        }
                        value={bullet.selectedShade}
                        onChange={(_event, value) => {
                            setShade({ value: value ?? undefined, name: bullet.shadeName });
                            setIsToggledOpen({ ...toggledOpenMap, [bullet.shadeName]: false });
                        }}
                        className={classes.inputField}
                        open={bullet.forceOpen || toggledOpenMap[bullet.shadeName]}
                        onOpen={() => setIsToggledOpen({ ...toggledOpenMap, [bullet.shadeName]: true })}
                        ListboxProps={{ style: { maxHeight: 245 } }}
                    />
                </Grid>
            ))}
        </>
    );
};

const QualitySection: React.VFC = () => {
    const classes = useStyles();
    const selectedFabrication = useDentureCheckoutSelector(s => s.fields.dentureOptions?.fabrication);
    const selectedTeethQuality = useDentureCheckoutSelector(s => s.fields.dentureOptions?.teethQuality);
    const setTeethQuality = useDenturesCheckoutAction('CHOOSE_DENTURE_TEETH_QUALITY');
    const setFabrication = useDenturesCheckoutAction('CHOOSE_DENTURE_FABRICATION');
    React.useEffect(() => {
        if (!selectedFabrication || !selectedTeethQuality) {
            setFabrication(LabsGqlDenturesFabricationMethod.Printed);
            setTeethQuality(LabsGqlDenturesTeethQuality.Printed);
        }
    }, [selectedFabrication, selectedTeethQuality, setFabrication, setTeethQuality]);

    return (
        <>
            <Grid item xs={12}>
                <Text variant={'h6'} bold className={classes.sectionTitle}>
                    What kind of denture would you like?
                </Text>
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    description={
                        '3D-printed base and teeth. Provides superior durability, high-quality aesthetics, and the quickest turnaround time.'
                    }
                    isSelected={
                        selectedFabrication === LabsGqlDenturesFabricationMethod.Printed &&
                        selectedTeethQuality === LabsGqlDenturesTeethQuality.Printed
                    }
                    label={'Signature Denture'}
                    onSelect={() => {
                        setFabrication(LabsGqlDenturesFabricationMethod.Printed);
                        setTeethQuality(LabsGqlDenturesTeethQuality.Printed);
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    description={
                        'Milled base and milled multi-layer teeth. Provides premium aesthetics and features the most realistic gingiva.'
                    }
                    isSelected={
                        selectedFabrication === LabsGqlDenturesFabricationMethod.Milled &&
                        selectedTeethQuality === LabsGqlDenturesTeethQuality.Milled
                    }
                    label={'Aesthetic Denture'}
                    onSelect={() => {
                        setFabrication(LabsGqlDenturesFabricationMethod.Milled);
                        setTeethQuality(LabsGqlDenturesTeethQuality.Milled);
                    }}
                />
            </Grid>
        </>
    );
};

const FabricationSection: React.VFC = () => {
    const classes = useStyles();
    const selectedProductionType = useDentureCheckoutSelector(s => s.fields.dentureOptions?.productionType);
    const setProductionType = useDenturesCheckoutAction('CHOOSE_DENTURE_PRODUCTION_TYPE');
    React.useEffect(() => {
        if (!selectedProductionType) {
            setProductionType(LabsGqlDenturesProductionType.Final);
        }
    }, [selectedProductionType, setProductionType]);

    return (
        <>
            <Grid item xs={12}>
                <Text variant={'h6'} bold className={classes.sectionTitle}>
                    How would you like us to manufacture it?
                </Text>
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    description={
                        'Receive a final denture at your next appointment. Best for cases without major changes or for patients in urgent need of dentures.'
                    }
                    isSelected={selectedProductionType === LabsGqlDenturesProductionType.Final}
                    label={'Straight-to-Finish'}
                    onSelect={() => {
                        setProductionType(LabsGqlDenturesProductionType.Final);
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <RadioButton
                    description={
                        'Receive a monolithic (off-white) 3D-printed try-in to test occlusion, fit, and aesthetics. Best for cases that require significant changes.'
                    }
                    isSelected={selectedProductionType === LabsGqlDenturesProductionType.TryIn}
                    label={'Try-In'}
                    onSelect={() => {
                        setProductionType(LabsGqlDenturesProductionType.TryIn);
                    }}
                />
            </Grid>
        </>
    );
};

export const InitialDenturePreferencesScreen: React.FC = () => {
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(
        DentureCheckoutStep.InitialNonWaxRimOrderDentureOptionsStep,
    );
    if (!isActiveStep) {
        return null;
    }
    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <Grid
                container
                direction={'row'}
                wrap={'wrap'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                alignContent={'flex-start'}
                spacing={2}
                style={{ marginTop: 24 }}
            >
                <ShadeSection />
                <QualitySection />
                <FabricationSection />
            </Grid>
        </CheckoutBodyWrapper>
    );
};
