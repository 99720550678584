import { EmailLogin } from './forms/email-login/EmailLogin';
import { PhoneLogin } from './forms/phone-login/PhoneLogin';
import { useLoginPrefValue } from './useLoginPref';
import { useRedirectOnLogin } from './useRedirectOnLogin';
import type { LocationDescriptorObject } from 'history';
import React from 'react';

export const Login: React.FC<{ originalLocation: LocationDescriptorObject }> = ({ originalLocation }) => {
    useRedirectOnLogin({ originalLocation });
    const loginPref = useLoginPrefValue();

    return loginPref === 'email' ? <EmailLogin /> : <PhoneLogin />;
};
