/* eslint-disable import/no-extraneous-dependencies */
import { PICKER_INPUT_ROOT_PADDING } from './SimplePickerStyles';
import { DesktopTimePicker, PickersLayout, type TimePickerProps } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// This is needed for the DatePicker generics to work.
import type {} from '@mui/x-date-pickers/AdapterDayjs';
import { DATE_PICKER_CLASSNAME, FlossPalette, styled } from '@orthly/ui-primitives';
import { FormFieldInputWrapper } from '@orthly/ui-primitives';
import cx from 'clsx';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React from 'react';

const StyledPickersLayout = styled(PickersLayout)({
    '& .MuiPickersDay-today': {
        backgroundColor: FlossPalette.WHITE,
        borderColor: FlossPalette.BLACK,
    },
    '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        color: FlossPalette.WHITE,
        borderColor: FlossPalette.PRIMARY_FOREGROUND,
    },
});

export type SimpleTimePickerProps = Omit<TimePickerProps<Dayjs>, 'value' | 'onChange' | 'minTime' | 'maxTime'> & {
    value: Date | null;
    onChange: (date: Date | null) => void;
    helperText?: string;
    errorText?: string;
    label?: string;
    minTime?: Date | null;
    maxTime?: Date | null;
    fullWidth?: boolean;
    legacyInputLayout?: boolean;
};

export const SimpleTimePicker = ({
    label,
    errorText,
    helperText,
    minTime,
    maxTime,
    sx,
    fullWidth,
    onChange,
    slotProps,
    legacyInputLayout = true,
    ...rest
}: SimpleTimePickerProps) => {
    const value = rest.value ? dayjs(rest.value) : null;

    if (legacyInputLayout) {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    orientation={'landscape'}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    {...rest}
                    value={value}
                    onChange={(date, _context) => {
                        onChange(date ? date.toDate() : null);
                    }}
                    slots={{
                        // @ts-expect-error it expects a generic, when it should expect the day-js type
                        layout: StyledPickersLayout,
                        ...rest.slots,
                    }}
                    slotProps={{
                        ...slotProps,
                        layout: {
                            ...slotProps?.layout,
                            sx: {
                                '& .MuiPickersDay-today': {
                                    backgroundColor: FlossPalette.WHITE,
                                    borderColor: FlossPalette.BLACK,
                                },
                                '& .MuiPickersDay-root.Mui-selected': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                    color: FlossPalette.WHITE,
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClock-pin': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClockPointer-root': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiPickersClockPointer-thumb': {
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                ...slotProps?.layout?.sx,
                            },
                        },
                        textField: {
                            helperText: errorText || helperText,
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...slotProps?.textField,
                        },
                    }}
                    minTime={minTime ? dayjs(minTime) : undefined}
                    maxTime={maxTime ? dayjs(maxTime) : undefined}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                    }}
                    // Everything before this should be duplicated across fields.
                    label={label}
                    helpText={errorText || helperText}
                />
            </LocalizationProvider>
        );
    }
    return (
        <FormFieldInputWrapper label={label || ''} errorText={errorText} helperText={helperText} helpTextBottom={true}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopTimePicker
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    orientation={'landscape'}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    {...rest}
                    value={value}
                    onChange={(date, _context) => {
                        onChange(date ? date.toDate() : null);
                    }}
                    slots={{
                        // @ts-ignore: TODO: This is a v4/v5 issue. Clean it up after MUI v5 is updated.
                        layout: StyledPickersLayout,
                        ...rest.slots,
                    }}
                    slotProps={{
                        ...slotProps,
                        layout: {
                            ...slotProps?.layout,
                            sx: {
                                '& .MuiPickersDay-today': {
                                    backgroundColor: FlossPalette.WHITE,
                                    borderColor: FlossPalette.BLACK,
                                },
                                '& .MuiPickersDay-root.Mui-selected': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                    color: FlossPalette.WHITE,
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClock-pin': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClockPointer-root': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiPickersClockPointer-thumb': {
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                ...slotProps?.layout?.sx,
                            },
                        },
                        textField: {
                            helperText: errorText || helperText,
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...slotProps?.textField,
                        },
                    }}
                    minTime={minTime ? dayjs(minTime) : undefined}
                    maxTime={maxTime ? dayjs(maxTime) : undefined}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                    }}
                />
            </LocalizationProvider>
        </FormFieldInputWrapper>
    );
};
