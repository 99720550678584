import type { LabOrderActionProps } from '.';
import { useRootStyles } from '../../../components/Containers';
import { OrderAction, OrderActionTrackerIdMap } from './OrderActionsUtils';
import type { LabsGqlOrderLabSlipPreviewFragment } from '@orthly/graphql-operations';
import { LoadBlocker } from '@orthly/ui';
import { Button, CircularProgress, Icon, Tooltip, styled } from '@orthly/ui-primitives';
import { UnboxingSlip, usePrintableState } from '@orthly/veneer';
import React from 'react';

type TopBarPrintButtonProps = Omit<LabOrderActionProps, 'order'> & {
    order: LabsGqlOrderLabSlipPreviewFragment;
};

const StyledButton = styled(Button)({
    height: 'unset',
    minWidth: 'unset',
});

export const PrintOrderAction: React.FC<TopBarPrintButtonProps> = ({ order, isIconOnly = false }) => {
    const rootClasses = useRootStyles();
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);

    return (
        <LoadBlocker blocking={false} ContainerProps={{ style: { width: 'auto', marginRight: '8px' } }}>
            <Tooltip
                arrow
                disableHoverListener
                enterTouchDelay={0}
                onMouseEnter={() => {
                    isIconOnly && setIsTooltipOpen(true);
                }}
                onMouseLeave={() => {
                    setIsTooltipOpen(false);
                }}
                open={isTooltipOpen}
                title={'Print'}
            >
                <div>
                    <StyledButton
                        data-tracker-id={OrderActionTrackerIdMap[OrderAction.Print]}
                        onClick={() => {
                            openPrintable();
                        }}
                        startIcon={isIconOnly ? undefined : 'PrintIcon'}
                        variant={'ghost'}
                        className={rootClasses.noSmallScreenDisplay}
                    >
                        {isIconOnly ? <Icon icon={'PrintIcon'} /> : 'Print'}
                    </StyledButton>
                </div>
            </Tooltip>
            {printableIsOpen && (
                <UnboxingSlip
                    customLoader={<CircularProgress size={20} color={'primary'} variant={'indeterminate'} />}
                    orderId={order.id}
                    onPrintComplete={closePrintable}
                />
            )}
        </LoadBlocker>
    );
};
