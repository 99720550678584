import type { SvgIconProps } from '@orthly/ui-primitives';
import { Slide, FlossPalette, Icon, makeStyles, Grid, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() => ({
    toast: {
        position: 'absolute',
        bottom: 24,
        padding: '16px 12px',
        zIndex: 11000,
        borderRadius: 8,
        boxShadow: `0px 4px 15px 0px rgba(0, 0, 0, 0.15)`,
        flexWrap: 'nowrap',
    },
    button: {
        padding: '8px 16px',
    },
    closeButton: {
        width: 24,
        height: 24,
        minWidth: 'unset',
    },
}));

interface ActionProps {
    text: string;
    action: () => void;
}

export interface ToastProps {
    headline: string;
    description: string;
    open: boolean;
    onDismiss: () => void;
    icon?: React.ComponentType<SvgIconProps>;
    variant: 'success' | 'critical' | 'warning' | 'info';
    direction?: 'right' | 'left';
    style?: React.CSSProperties;
    timeout?: number;
    primaryAction?: ActionProps;
    secondaryAction?: ActionProps;
}

export const DEFAULT_TOAST_TIMEOUT = 5000;

const getBorderLeft = (variant: ToastProps['variant']) => {
    if (['critical', 'warning'].includes(variant)) {
        return `4px solid ${FlossPalette.ATTENTION_FOREGROUND}`;
    }
    if (variant === 'success') {
        return `4px solid ${FlossPalette.STAR_GRASS}`;
    }
    return undefined;
};

const getIconColor = (variant: ToastProps['variant']) => {
    if (variant === 'critical') {
        return FlossPalette.WHITE;
    }
    if (variant === 'warning') {
        return FlossPalette.ATTENTION_FOREGROUND;
    }
    return FlossPalette.STAR_GRASS;
};

// The Toast component is part of the design system, and can be referenced here:
// https://www.figma.com/file/HuCVKGaat3yxkP33weyVnz/Design-System?node-id=9037%3A38964
export const Toast: React.VFC<ToastProps> = ({
    headline,
    description,
    open,
    onDismiss,
    variant,
    icon: IconProp,
    direction,
    style,
    timeout,
    primaryAction,
    secondaryAction,
}) => {
    const classes = useStyles();

    React.useEffect(() => {
        if (open && timeout) {
            const timer = setTimeout(() => {
                onDismiss();
            }, timeout);
            return () => clearTimeout(timer);
        }
    }, [onDismiss, open, timeout]);

    const borderLeft = getBorderLeft(variant);

    const iconColor = getIconColor(variant);

    return (
        <Slide direction={direction ?? 'left'} in={open} mountOnEnter unmountOnExit>
            <Grid
                container
                direction={'row'}
                className={classes.toast}
                style={{
                    right: !direction || direction === 'left' ? 24 : undefined,
                    left: direction === 'right' ? 24 : undefined,
                    width: 400,
                    borderLeft: borderLeft,
                    backgroundColor: variant === 'critical' ? FlossPalette.ATTENTION_FOREGROUND : FlossPalette.WHITE,
                    ...style,
                }}
                role={'toast'}
                data-test={'toast'}
            >
                {IconProp && (
                    <Grid item style={{ marginLeft: variant === 'info' ? 4 : 0 }}>
                        <IconProp
                            style={{
                                color: iconColor,
                                height: 24,
                            }}
                        />
                    </Grid>
                )}
                <Grid container direction={'column'} style={{ margin: '0 4px 0 16px' }}>
                    <Grid container item style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}>
                        <Text
                            variant={'body2'}
                            medium
                            color={variant === 'critical' ? 'WHITE' : 'BLACK'}
                            data-test={'toast-headline'}
                        >
                            {headline}
                        </Text>
                        <Button
                            variant={'text'}
                            className={classes.closeButton}
                            onClick={onDismiss}
                            aria-label={'Close'}
                        >
                            <Icon
                                icon={'CloseIcon'}
                                style={{
                                    width: 20,
                                    color: variant === 'critical' ? FlossPalette.WHITE : FlossPalette.BLACK,
                                }}
                                data-test={'toast-close-icon'}
                            />
                        </Button>
                    </Grid>
                    {description && (
                        <Grid item>
                            <Text
                                variant={'body2'}
                                color={variant === 'critical' ? 'WHITE' : 'BLACK'}
                                data-test={'toast-description'}
                            >
                                {description}
                            </Text>
                        </Grid>
                    )}
                    {(primaryAction || secondaryAction) && (
                        <Grid container style={{ marginTop: 16 }}>
                            {primaryAction && (
                                <Button
                                    className={classes.button}
                                    variant={'primary'}
                                    onClick={primaryAction.action}
                                    data-test={'toast-primary-action'}
                                >
                                    {primaryAction.text}
                                </Button>
                            )}
                            {secondaryAction && (
                                <Button
                                    className={classes.button}
                                    variant={'secondary'}
                                    onClick={secondaryAction.action}
                                    style={{ marginLeft: primaryAction ? 8 : 0 }}
                                    data-test={'toast-secondary-action'}
                                >
                                    {secondaryAction.text}
                                </Button>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Slide>
    );
};
