import { RootActionDialog } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface SettingsBaseModalProps {
    content: React.ReactNode;
    loading: boolean;
    title: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    subtitle?: string;
    CustomButton?: React.FC<ButtonProps>;
    hideButton?: boolean;
    buttonText?: string;
    buttonProps?: Partial<ButtonProps>;
    decreaseTitlePadding?: boolean;
}

export const SettingsBaseModal: React.FC<SettingsBaseModalProps> = props => {
    return (
        <RootActionDialog
            showCloseButton
            closeIconButtonProps={{
                style: { height: 'fit-content' },
            }}
            closeIconProps={{ style: { color: FlossPalette.BLACK } }}
            titleTextProps={{ variant: 'h4', style: { fontSize: 32, fontWeight: 500 } }}
            titleProps={{
                style: {
                    borderBottom: 'none',
                },
            }}
            buttonText={props.buttonText}
            buttonProps={props.buttonProps}
            {...props}
        />
    );
};
