import { StyledIcon, TrainingCard } from './TrainingDataIntake.styles';
import { useDismissCompleteCards } from './hooks/useDismissCompleteCards';
import { PracticeScreen } from '@orthly/dentin';
import { Button, FlossPalette, IconButton, Icon, styled, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const CardsLayout = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'auto',
    },
}));

const TrainingDataIntakeCompletedCard = styled(TrainingCard)({
    background: FlossPalette.WHITE,
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    position: 'relative',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 0,
    minWidth: 250,
    paddingBottom: 90,
});

const TrainingCardButton = styled(Button)(({ theme }) => {
    const widthSpacing = theme.spacing(6);
    const width = widthSpacing.toString().includes('px')
        ? `calc(100% - ${widthSpacing})`
        : `calc(100% - ${widthSpacing}px)`;
    return {
        position: 'absolute',
        bottom: theme.spacing(3),
        left: theme.spacing(3),
        width,
    };
});

const CloseButton: React.VFC<{ onClick: () => void }> = props => (
    <IconButton size={'small'} sx={{ position: 'absolute', top: 12, right: 12 }} {...props}>
        <Icon icon={'CloseIcon'} fontSize={'small'} style={{ color: FlossPalette.LIGHT_GRAY }} />
    </IconButton>
);

export const TrainingDataIntakeCompletedCards: React.VFC = () => {
    const { isBillingCardDismissed, isNotificationsCardDismissed, dismissBillingCard, dismissNotificationsCard } =
        useDismissCompleteCards();
    const history = useHistory();

    if (isBillingCardDismissed && isNotificationsCardDismissed) {
        return null;
    }

    return (
        <CardsLayout>
            {!isBillingCardDismissed && (
                <TrainingDataIntakeCompletedCard>
                    <CloseButton onClick={dismissBillingCard} />
                    <StyledIcon icon={'CreditCardIcon'} />
                    <Text variant={'body1'} medium>
                        Keep your payment method up to date
                    </Text>
                    <Text variant={'body2'} color={'GRAY'} style={{ marginTop: 8 }}>
                        ACH is our preferred payment method. You can update your payment method at any time via practice
                        settings.
                    </Text>
                    <TrainingCardButton
                        variant={'primary'}
                        onClick={() => history.push(`/${PracticeScreen.practice_settings}`)}
                        analytics={{
                            AssetLocation: 'Practice - Onboarding - Training Data Intake',
                            AssetName: 'Update Payment Method',
                        }}
                    >
                        Update payment method
                    </TrainingCardButton>
                </TrainingDataIntakeCompletedCard>
            )}

            {!isNotificationsCardDismissed && (
                <TrainingDataIntakeCompletedCard>
                    <CloseButton onClick={dismissNotificationsCard} />
                    <StyledIcon icon={'NotificationsNone'} />
                    <Text variant={'body1'} medium>
                        Keep your notification settings up to date
                    </Text>
                    <Text variant={'body2'} color={'GRAY'} style={{ marginTop: 8 }}>
                        You can update your notification settings at any time via practice settings.
                    </Text>
                    <TrainingCardButton
                        variant={'primary'}
                        onClick={() => history.push(`/${PracticeScreen.notifications}`)}
                        analytics={{
                            AssetLocation: 'Practice - Onboarding - Training Data Intake',
                            AssetName: 'View Notifications',
                        }}
                    >
                        View notifications
                    </TrainingCardButton>
                </TrainingDataIntakeCompletedCard>
            )}
        </CardsLayout>
    );
};
