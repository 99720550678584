import { useCheckoutPropSelector } from '../../../../redux';
import { CheckoutSelectDoctor } from '../../components/PatientDoctorInfo/CheckoutSelectDoctor';
import { CheckoutSelectScan } from '../../components/PatientDoctorInfo/CheckoutSelectScan';
import { useScanWithExistingOrderSelector } from '../../state/checkout.selectors';
import { CheckoutExistingOrderForScan } from '../CheckoutExistingOrderStep/CheckoutExistingOrderForScan';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import { Collapse } from '@orthly/ui-primitives';
import React from 'react';

interface CheckoutPatientDoctorStepProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
    visible: boolean;
    isComplete: boolean;
}

export const CheckoutPatientDoctorStep: React.FC<CheckoutPatientDoctorStepProps> = ({
    scansQuery,
    visible,
    isComplete,
}) => {
    const { step: checkoutStep, existingOrderWarningDismissed } = useCheckoutPropSelector([
        'step',
        'existingOrderWarningDismissed',
    ]);
    const scanWithExisting = useScanWithExistingOrderSelector();
    const existingOrderScreenVisible = !!scanWithExisting && !existingOrderWarningDismissed;
    const rootVisible = !existingOrderScreenVisible && checkoutStep === 0;
    return (
        <CheckoutBodyWrapper visible={visible} isComplete={isComplete}>
            <Collapse in={existingOrderScreenVisible} style={{ width: '100%' }} mountOnEnter unmountOnExit>
                {scanWithExisting && <CheckoutExistingOrderForScan scanWithExisting={scanWithExisting} />}
            </Collapse>
            <Collapse in={rootVisible} style={{ width: '100%' }} mountOnEnter unmountOnExit>
                <CheckoutSelectDoctor />
                <CheckoutSelectScan scansQuery={scansQuery} />
            </Collapse>
        </CheckoutBodyWrapper>
    );
};
