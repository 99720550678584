import { HelpInfoBox } from '../../components/HelpInfoBox';
import type { PracticeStaffPrefs } from '../../state/usePracticeStaffControls';
import { usePreferencesAreComplete, useNotificationsAreComplete } from '../../state/usePracticeStaffControls';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { stylesFactory, FlossPalette, Grid, LinearProgress, CheckIcon, Icon, Text } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    colorPrimary: {
        '& > .MuiLinearProgress-barColorPrimary': {
            backgroundColor: FlossPalette.STAR_GRASS,
        },
    },
    progress: {
        display: 'block',
        width: '100%',
        height: 8,
        borderRadius: 8,
        backgroundColor: FlossPalette.DARK_TAN,
    },
    progressWrap: {
        height: 8,
        width: '100%',
        paddingTop: '8px',
    },
}));

interface ChecklistItemProps {
    itemComplete: boolean;
    title: string;
    subtitle: string;
}

const ChecklistItem: React.FC<ChecklistItemProps> = ({ itemComplete, title, subtitle }) => {
    return (
        <Grid container style={{ flexWrap: 'unset', paddingBottom: 16 }}>
            {itemComplete ? (
                <CheckIcon style={{ color: FlossPalette.GREEN }} />
            ) : (
                <Icon icon={'RadioButtonUnCheckedIcon'} style={{ color: FlossPalette.GRAY }} />
            )}
            <Grid item style={{ paddingLeft: 8 }}>
                <Text
                    variant={'body2'}
                    style={{ fontWeight: 600, color: itemComplete ? FlossPalette.GREEN : FlossPalette.BLACK }}
                >
                    {title}
                </Text>
                <Text variant={'caption'} color={'DARK_GRAY'}>
                    {subtitle}
                </Text>
            </Grid>
        </Grid>
    );
};

interface ProfileCompletionProps {
    prefs: PracticeStaffPrefs;
}

export const ProfileCompletion: React.FC<ProfileCompletionProps> = ({ prefs }) => {
    const classes = useStyles();
    const preferencesAreComplete = usePreferencesAreComplete(prefs);
    const notificationsAreComplete = useNotificationsAreComplete(prefs);
    if (!prefs.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)) {
        return null;
    }
    // completedStepsCount always starts at 1 since one step is a gimme (the "Get started with Dandy" step)
    const completedStepsCount = 1 + Number(preferencesAreComplete) + Number(notificationsAreComplete);

    return (
        <HelpInfoBox
            header={
                <>
                    <Text variant={'h6'}>Profile completion</Text>
                    <Text variant={'caption'} color={'DARK_GRAY'} style={{ fontWeight: 600 }}>
                        {completedStepsCount} out of 3 steps completed
                    </Text>
                    <Grid item className={classes.progressWrap}>
                        <LinearProgress
                            value={(completedStepsCount / 3) * 100}
                            variant={'determinate'}
                            className={clsx(classes.progress, classes.colorPrimary)}
                        />
                    </Grid>
                </>
            }
            body={
                <Grid container style={{ padding: '0 24px 8px' }}>
                    <ChecklistItem
                        itemComplete={preferencesAreComplete}
                        title={'Set up clinical preferences'}
                        subtitle={'Speed up & personalize the ordering process'}
                    />
                    <ChecklistItem
                        itemComplete={notificationsAreComplete}
                        title={'Set up order notifications'}
                        subtitle={'Makes sure you’re always up to date on your orders and prevent order delays'}
                    />
                    <ChecklistItem
                        itemComplete={true}
                        title={'Get started with Dandy'}
                        subtitle={'Let’s make some people smile'}
                    />
                </Grid>
            }
        />
    );
};
