import { useDownloadOrderFiles } from '../../components/useDownloadOrderFiles.graphql';
import { OrderAction, OrderActionTrackerIdMap } from '../OrderActionsUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LoadBlocker } from '@orthly/ui';
import type { ButtonVariant } from '@orthly/ui-primitives';
import { FlossPalette, Menu, MenuItem, Button, Icon, Tooltip, useIsTouchDevice } from '@orthly/ui-primitives';
import React from 'react';

export const DownloadScanAction: React.FC<{
    order: LabsGqlOrder;
    isIconOnly?: boolean;
    buttonVariant?: ButtonVariant;
    showTooltip?: boolean;
    showDropdownIcon?: boolean;
    fullWidth?: boolean;
    leftAlign?: boolean;
}> = props => {
    const {
        order,
        isIconOnly = false,
        buttonVariant = 'ghost',
        showDropdownIcon = false,
        fullWidth = false,
        leftAlign,
    } = props;
    const { trigger3oxzDownload, loading3oxz, triggerStlDownload, loadingStl, loadingDesign, triggerDesignDownload } =
        useDownloadOrderFiles(order);

    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const isTouchDevice = useIsTouchDevice();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleDownload = (type: 'stl' | '3oxz' | 'design') => () => {
        setAnchorEl(null);
        switch (type) {
            case 'stl':
                triggerStlDownload?.().catch(console.error);
                return;
            case '3oxz':
                trigger3oxzDownload().catch(console.error);
                return;
            case 'design':
                triggerDesignDownload?.().catch(console.error);
                return;
        }
    };

    return (
        <>
            <LoadBlocker
                blocking={loading3oxz || loadingStl || loadingDesign}
                CircularProgressProps={{ style: { color: FlossPalette.STAR_GRASS }, color: 'inherit' }}
                ContainerProps={{ style: { width: fullWidth ? '100%' : 'unset' } }}
            >
                <Tooltip
                    arrow
                    disableHoverListener
                    disableFocusListener
                    disableTouchListener
                    onMouseEnter={() => {
                        isIconOnly && !isTouchDevice && setIsTooltipOpen(true);
                    }}
                    onMouseLeave={() => {
                        setIsTooltipOpen(false);
                    }}
                    open={isTooltipOpen}
                    title={'Download scans'}
                >
                    <span style={{ width: fullWidth ? '100%' : undefined }}>
                        <Button
                            ref={buttonRef}
                            fullWidth={fullWidth}
                            leftAlign={leftAlign}
                            data-tracker-id={OrderActionTrackerIdMap[OrderAction.Download]}
                            variant={buttonVariant}
                            startIcon={isIconOnly ? undefined : 'DownloadIcon'}
                            endIcon={showDropdownIcon ? 'ChevronDown' : undefined}
                            onClick={handleClick}
                        >
                            {isIconOnly ? <Icon icon={'DownloadIcon'} /> : 'Download scans'}
                        </Button>
                    </span>
                </Tooltip>
            </LoadBlocker>

            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 40,
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        width: buttonRef.current && fullWidth ? buttonRef.current.offsetWidth : undefined,
                    },
                }}
            >
                <MenuItem onClick={handleDownload('3oxz')}>Download scan (.3oxz)</MenuItem>
                <MenuItem disabled={!triggerStlDownload} onClick={handleDownload('stl')}>
                    Download scan (.stl)
                </MenuItem>
            </Menu>
        </>
    );
};
