import type { DoctorVars } from '../../state/usePracticeStaffControls';
import { PreferenceFieldGqlUtils } from '@orthly/dentin';
import type { LabsGqlCustomFieldPrefsFragment } from '@orthly/graphql-operations';
import { useLabsPreferences, useSetDoctorPreferencesMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

type CustomFieldsResult = { [fieldId: string]: LabsGqlCustomFieldPrefsFragment['value'] | null | undefined };

export function usePreferenceControls(opts: { onSuccess?: () => void; onError?: (e: Error) => void } = {}) {
    const { preferences, refetch: refetchPreferences, loading: preferencesLoading } = useLabsPreferences();

    const [submitMtn] = useSetDoctorPreferencesMutation();
    const mtnSubmitter = (data: DoctorVars & CustomFieldsResult) => {
        const fields = PreferenceFieldGqlUtils.preferencesFormResultToMutation(data);
        return submitMtn({ variables: { data: fields } });
    };
    const { submit, submitting } = useChangeSubmissionFn<any, any>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Doctor order preferences updated`, {}],
        onSuccess: () => {
            void refetchPreferences?.();
            opts.onSuccess?.();
        },
        onError: e => opts.onError?.(e),
        disableDefaultErrorMessage: true,
    });

    const loading = preferencesLoading || submitting;

    return {
        preferences,
        submit,
        loading,
    };
}
