import { ProductImageWrapper } from '@orthly/dentin';
import { useMobileUploadLoadedMutation } from '@orthly/graphql-react';
import type { ProductUnitType } from '@orthly/items';
import { LabOrderItemSKUType, isValidProductUnitType } from '@orthly/items';
import { isValidFileUploadDisplayLocation } from '@orthly/shared-types';
import { NavigationLogoDandy } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    Dialog,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    Tooltip,
    CloseIcon,
    Button,
    Icon,
    Text,
} from '@orthly/ui-primitives';
import type { FileUploadAnalyticsMetadata, ItemPhotoOptions, UploadedFile } from '@orthly/veneer';
import {
    FileList,
    PhotoCaptureTips,
    SimpleDropzone,
    StumpShadePhoto,
    useGetAttachmentsPath,
    getPhotosForItem,
    isValidUploadType,
    useUploadFiles,
} from '@orthly/veneer';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    buttonWrapper: { padding: 16, flexGrow: 1, alignItems: 'flex-start' },
    root: {
        padding: '32px 16px 0 16px',
        height: '85vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: FlossPalette.WHITE,
        position: 'absolute',
        zIndex: 1202,
    },
    grid: {
        marginBottom: 16,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: 4,
        width: 120,
    },
    titleWrapper: {
        width: '100%',
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 16,
        textAlign: 'center',
    },
    logo: {
        width: 128,
    },
}));

function useQuery() {
    const { search } = useLocation();
    return new URLSearchParams(search);
}

interface PhotoTipsProps {
    productUnitType: ProductUnitType;
    itemOptions: ItemPhotoOptions;
}

const PhotoTips: React.VFC<PhotoTipsProps> = ({ productUnitType, itemOptions }) => {
    const [showPhotoTips, setShowPhotoTips] = React.useState(false);
    const photos = getPhotosForItem(productUnitType, itemOptions);

    if (!photos.length) {
        return null;
    }

    return (
        <>
            <Dialog open={showPhotoTips} onClose={() => setShowPhotoTips(false)}>
                <DialogContent>
                    <Grid container justifyContent={'space-between'}>
                        <Text variant={'body2'} bold>
                            Capture the following photos
                        </Text>
                        <Tooltip title={'Close'}>
                            <IconButton onClick={() => setShowPhotoTips(false)} size={'small'}>
                                <CloseIcon style={{ color: FlossPalette.GRAY, fontSize: 16 }} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <PhotoCaptureTips photos={photos} imageWidth={108} />
                </DialogContent>
            </Dialog>
            <Grid
                item
                style={{
                    cursor: 'pointer',
                }}
                onClick={() => setShowPhotoTips(true)}
            >
                <Grid container direction={'row'} justifyContent={'space-between'}>
                    <Text variant={'body2'} bold>
                        Capture the following photos
                    </Text>
                    <Icon icon={'HelpOutlineIcon'} style={{ color: FlossPalette.GREEN }} />
                </Grid>
                <PhotoCaptureTips photos={photos} imageWidth={24} />
            </Grid>
        </>
    );
};

export const MobilePhotoUpload: React.VFC = () => {
    const classes = useStyles();
    const { caseId } = useParams<{ caseId: string }>();
    const query = useQuery();
    const sessionId = query.get('sessionId');
    const platform = query.get('platform');
    const patientName = query.get('patientName') || 'Patient';
    const productType = query.get('productType') || '';
    const uploadTypeQuery = query.get('uploadType') || '';
    const uploadType = isValidUploadType(uploadTypeQuery) ? uploadTypeQuery : '';
    const displayLocationQuery = query.get('displayLocation') || '';
    const productUnitType = isValidProductUnitType(productType) ? productType : LabOrderItemSKUType.Unknown;
    const displayLocation = isValidFileUploadDisplayLocation(displayLocationQuery)
        ? displayLocationQuery
        : 'item_setup';
    const [files, setFiles] = React.useState<UploadedFile[]>([]);
    const [isFinished, setIsFinished] = React.useState(false);
    const isStumpShade = uploadType === 'StumpShade';
    const isReplacementPartial = uploadType === 'ReplacementPartial';
    const storagePathConfig = useGetAttachmentsPath(caseId, sessionId);
    const { uploadFiles, deleteFile, useListFiles } = useUploadFiles({
        caseId,
        storagePathConfig,
        analyticsEventName: 'Practice - Checkout - File Uploaded',
    });
    const [mobileUploadLoaded] = useMobileUploadLoadedMutation();
    React.useEffect(() => {
        void mobileUploadLoaded({ variables: { case_id: caseId } });
    }, [caseId, mobileUploadLoaded]);

    const analyticsMetadata: FileUploadAnalyticsMetadata = {
        productName: 'aesthetic - new',
        displayLocation,
        device: 'mobile',
    };
    async function onDropAccepted(filesToUpload: File[]) {
        await uploadFiles(filesToUpload, analyticsMetadata, newFiles => {
            setFiles(existingFiles =>
                existingFiles.concat(newFiles.filter(nf => !existingFiles.some(f => nf.uploadName === f.uploadName))),
            );
        });
    }
    async function onRemoveFile(filename: string) {
        await deleteFile(filename);
        setFiles(files => files.filter(f => f.uploadName !== filename));
    }

    const uploadedFiles = useListFiles();
    React.useEffect(() => {
        setFiles(files => {
            const deletedFiles = files.filter(f => f.filepath && !uploadedFiles.some(uf => uf.filepath === f.filepath));
            return uploadedFiles
                .concat(files.filter(file => !uploadedFiles.some(f => f.uploadName === file.uploadName)))
                .filter(uf => !deletedFiles.some(df => df.filepath === uf.filepath));
        });
    }, [uploadedFiles, setFiles]);
    const hasFiles = !!files.length;

    return (
        <Grid className={classes.root}>
            <NavigationLogoDandy className={classes.logo} />
            {isFinished && platform === 'portal' && (
                <Grid container style={{ marginTop: 32 }}>
                    <FileList files={files} onRemove={onRemoveFile} maxHeight={40} maxThumbnailSize={32} />
                </Grid>
            )}
            <Text variant={'h4'} style={{ marginTop: 24 }}>
                {isFinished &&
                    (platform === 'portal'
                        ? 'Return to your laptop to upload these photos'
                        : 'Photos successfully uploaded to the case')}
                {!isFinished && 'Upload patient photos'}
            </Text>
            <Grid container style={{ marginTop: 10, gap: 8 }}>
                <ProductImageWrapper
                    product={productUnitType}
                    size={24}
                    wrapperStyle={{ border: `solid 1px ${FlossPalette.DARK_TAN}`, boxSizing: 'content-box' }}
                />

                <Text variant={'body2'} color={'GRAY'}>
                    {patientName}'s {productUnitType}
                </Text>
            </Grid>
            {isFinished ? (
                <>
                    <Text variant={'body2'} color={'GRAY'} style={{ margin: '20px 0' }}>
                        You can now close this window and{' '}
                        {platform === 'portal'
                            ? 'return to your computer'
                            : 'complete your order in Chairside using your Dandy laptop'}
                        .
                    </Text>
                    <Button variant={'secondary'} fullWidth onClick={() => setIsFinished(false)}>
                        Add more
                        <Icon icon={'AddIcon'} style={{ marginLeft: 8 }} />
                    </Button>
                </>
            ) : (
                <>
                    {hasFiles && (
                        <div
                            style={{
                                border: `1px solid ${FlossPalette.DARK_TAN}`,
                                borderRadius: 8,
                                margin: '20px 0',
                                padding: 10,
                            }}
                        >
                            <Grid container justifyContent={'space-between'} style={{ marginBottom: 16 }}>
                                <Text variant={'body2'} bold>
                                    Your photos
                                </Text>
                                <Text variant={'body2'} color={'GRAY'}>
                                    Tap to view or remove
                                </Text>
                            </Grid>
                            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                            <FileList files={files} onRemove={onRemoveFile} maxHeight={180} />
                        </div>
                    )}
                    <Grid container direction={'row'} style={{ gap: 8, marginTop: 20 }}>
                        <SimpleDropzone
                            options={{
                                accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] },
                                multiple: true,
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onDropAccepted,
                            }}
                            wrapperStyle={{ border: 0, padding: 0, minHeight: 'auto', width: 'auto', flexGrow: 1 }}
                            dropzoneContent={
                                <Button endIcon={'AddIcon'} variant={hasFiles ? 'secondary' : 'primary'} fullWidth>
                                    {hasFiles ? 'Add more' : 'Add photos'}
                                </Button>
                            }
                        />
                        {hasFiles && (
                            <Button
                                endIcon={'CheckIcon'}
                                variant={'primary'}
                                style={{ flexGrow: 1 }}
                                onClick={() => setIsFinished(true)}
                            >
                                Finish
                            </Button>
                        )}
                    </Grid>
                    {platform !== 'portal' && (
                        <>
                            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
                            {isStumpShade ? (
                                <Grid item>
                                    <Text variant={'body2'} bold>
                                        Capture a photo like this
                                    </Text>
                                    <img
                                        src={StumpShadePhoto}
                                        style={{
                                            borderRadius: 8,
                                            objectFit: 'cover',
                                            marginTop: 16,
                                        }}
                                    />
                                </Grid>
                            ) : (
                                <PhotoTips productUnitType={productUnitType} itemOptions={{ isReplacementPartial }} />
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
};
