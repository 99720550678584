import { getFlagVariantValueChecker } from '../../../../utils/utils';
import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import DenturesImage from '@orthly/dentin/assets/images/new-skus/dentures_128_2x.png';

export const DandyUtah2023Config: InboxUpsellItemConfigProps = {
    assetName: '2023-10 Dandy Utah',
    title: 'Introducing Dandy Utah',
    subtitle: 'Our new in-house lab of the future, designed and engineered to produce game-changing quality',
    ctaText: 'See inside',
    destination: {
        url: 'https://www.meetdandy.com/labs/utah/?utm_source=portal&utm_medium=banner&utm_campaign=2023q4-customer-awareness-dandy-utah',
    },
    imgSrc: DenturesImage,
    imgStyle: { maxHeight: 80 },
    skuType: 'dentures',
    filterCriteria: null,
    featureFlagKey: 'upsellDandyUtah2023',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_a'),
};
