import AnteriorOnlyIcon from '../../../assets/images/aligners/anterior-only.svg';
import FullArchIcon from '../../../assets/images/aligners/full-arch.svg';
import { LabsGqlAlignerTreatmentArea } from '@orthly/graphql-schema';
import {
    stylesFactory,
    Text,
    FlossPalette,
    FlossPaletteUtils,
    useScreenIsMobileOrVerticalTablet,
    Button,
    Grid,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    areaButton: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 4,
        padding: '16px 24px',
        width: '100%',
        textAlign: 'left',
        borderColor: FlossPalette.TAN,
    },
    areaButtonSmall: {
        borderWidth: 2,
        '&:hover': {
            borderColor: FlossPalette.DARK_TAN,
        },
    },
    areaButtonSelected: {
        borderColor: FlossPalette.STAR_GRASS,
    },
    areaButtonSmallSelected: {
        background: FlossPaletteUtils.toRgba('STAR_GRASS', 0.1),
        '&:hover': {
            borderColor: FlossPalette.STAR_GRASS,
        },
    },
    areaButtonIcon: {
        height: 38,
        width: 52,
        flexShrink: 0,
        marginLeft: 16,
    },
}));

interface AlignerTreatmentAreaButtonProps {
    selected: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    label: React.ReactNode;
    icon: string;
    description?: React.ReactNode;
    small?: boolean;
}

const AlignerTreatmentAreaButton: React.VFC<AlignerTreatmentAreaButtonProps> = props => {
    const { label, icon, description, selected, onClick, small } = props;
    const classes = useStyles();

    return (
        <Button
            variant={'contained'}
            className={cx(classes.areaButton, {
                [classes.areaButtonSelected]: selected,
                [classes.areaButtonSmall]: small,
                [classes.areaButtonSmallSelected]: small && selected,
            })}
            onClick={onClick}
        >
            <Grid container direction={'row'} wrap={'nowrap'} alignItems={'center'}>
                <Grid container direction={'column'} wrap={'nowrap'} alignItems={'stretch'}>
                    <Text variant={'body2'} color={selected ? 'STAR_GRASS' : 'BLACK'} medium>
                        {label}
                    </Text>
                    {description && (
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {description}
                        </Text>
                    )}
                </Grid>
                <img src={icon} alt={`illustration for ${label}`} className={classes.areaButtonIcon} />
            </Grid>
        </Button>
    );
};

interface AlignerTreatmentAreaSelectionProps {
    value?: LabsGqlAlignerTreatmentArea;
    onChange: (val: LabsGqlAlignerTreatmentArea) => void;
    small?: boolean;
}

export const AlignerTreatmentAreaSelection: React.VFC<AlignerTreatmentAreaSelectionProps> = ({
    value,
    onChange,
    small,
}) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();

    return (
        <Grid
            container
            direction={isMobile ? 'column' : 'row'}
            wrap={'nowrap'}
            alignItems={'stretch'}
            style={{ gap: 16 }}
        >
            <AlignerTreatmentAreaButton
                selected={value === LabsGqlAlignerTreatmentArea.FullArch}
                onClick={() => onChange(LabsGqlAlignerTreatmentArea.FullArch)}
                label={'Full arch'}
                icon={FullArchIcon}
                small={small}
            />
            <AlignerTreatmentAreaButton
                selected={value === LabsGqlAlignerTreatmentArea.AnteriorOnly}
                onClick={() => onChange(LabsGqlAlignerTreatmentArea.AnteriorOnly)}
                label={'Anteriors only (canine to canine)'}
                icon={AnteriorOnlyIcon}
                description={'Attachments may be placed on posterior teeth for support'}
                small={small}
            />
        </Grid>
    );
};
