import { useMutation } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import { Button, useRootActionCommand } from '@orthly/ui';
import React from 'react';

const PracticeCancelLiveDdpRequest_Mutation = graphql(`
    mutation PracticeCancelLiveDdpRequest_Mutation($order_id: String!) {
        cancelLiveDdpReviewRequest(order_id: $order_id)
    }
`);

interface CancelLiveDesignPreviewButtonProps {
    orderId: string;
    refetch: () => Promise<void>;
}

export const CancelLiveDesignPreviewButton: React.VFC<CancelLiveDesignPreviewButtonProps> = ({ orderId, refetch }) => {
    const mutation = useMutation(PracticeCancelLiveDdpRequest_Mutation);

    const { submit, submitting } = useRootActionCommand(mutation, {
        successMessage: 'Live Design Review cancelled!',
        onSuccess: async () => {
            await refetch();
        },
    });

    return (
        <Button
            variant={'ghost'}
            endIcon={'PlayArrowIcon'}
            fullWidth={false}
            disabled={submitting}
            onClick={async () => {
                BrowserAnalyticsClientFactory.Instance?.track(
                    'Practice - Portal - Live Design Review - Canceled Appointment',
                    {
                        $groups: {
                            order: orderId,
                        },
                    },
                );

                await submit({ order_id: orderId });
            }}
        >
            Cancel appointment & resume order
        </Button>
    );
};
