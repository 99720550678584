import { OrthlyApolloProvider } from '@orthly/graphql-react';
import { OrthlyBrowserConfig, OrthlyFrontendApps } from '@orthly/ui';
import type { ReactNode } from 'react';
import React from 'react';

const config = new OrthlyBrowserConfig(OrthlyFrontendApps.practice);

export const AnonymousGqlProvider: React.FC<{ loading: ReactNode }> = ({ children, loading }) => {
    return (
        <OrthlyApolloProvider
            clientName={`${config.appName}-patient${process.env.REACT_APP_LOCAL_PROD ? `-LOCAL_PROD` : ``}`}
            version={config.version}
            env={config.env}
            loadingFallback={loading}
            routerEndpoint={process.env.REACT_APP_GATEWAY_URL ?? '/graphql'}
        >
            {children}
        </OrthlyApolloProvider>
    );
};
