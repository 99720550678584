import type { FeedbackLoaderScreen } from './state/feedback.reducer';
import { FeedbackAndRefabBodyContainer } from '@orthly/dentin';
import { LoadBlocker } from '@orthly/ui';
import React from 'react';

export const FeedbackLoaderScreenComponent: React.VFC<{
    screen: FeedbackLoaderScreen;
}> = () => {
    return (
        <FeedbackAndRefabBodyContainer title={``}>
            <LoadBlocker blocking={true} />
        </FeedbackAndRefabBodyContainer>
    );
};
