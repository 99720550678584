import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import type { SimpleSelectProps } from '@orthly/ui';
import { LoadBlocker, SimpleSelect } from '@orthly/ui';
import { FlossPalette, createStyles, makeStyles, useScreenIsMobile } from '@orthly/ui-primitives';
import cx from 'classnames';

const useStyles = makeStyles(() =>
    createStyles({
        selectFormControl: {
            '&:first-of-type': { marginRight: 2 },
            '&:last-of-type': { marginLeft: 2 },
            '@media screen and (max-width: 768px)': {
                marginTop: '16px',
            },
        },
        select: {
            paddingTop: 8,
            paddingBottom: 8,
            backgroundColor: '#fff',
        },
        selectIcon: {},
        hidePrint: {
            '@media print': {
                display: 'none',
            },
        },
    }),
);

export const InvoiceFilters: React.FC = () => {
    const isMobile = useScreenIsMobile();
    const {
        rowsLoading,
        doctorOptions,
        doctorNameFilter,
        setDoctorNameFilter,
        addresses,
        addressIdFilter,
        setAddressIdFilter,
    } = useInvoiceDetailContext();

    const classes = useStyles();

    const commonSelectProps = (showWhenPrinted: boolean): Partial<SimpleSelectProps> => ({
        variant: 'standard',
        backgroundColor: FlossPalette.WHITE,
        FormControlProps: {
            className: showWhenPrinted ? classes.selectFormControl : cx(classes.selectFormControl, classes.hidePrint),
            variant: 'standard',
        },
        SelectProps: {
            displayEmpty: true,
            disableUnderline: true,
            variant: 'standard',
        },
        disabled: rowsLoading,
    });

    return (
        <LoadBlocker
            blocking={rowsLoading}
            ContainerProps={isMobile ? {} : { style: { width: '50%' }, wrap: 'nowrap' }}
            CircularProgressProps={{ size: 15 }}
        >
            <SimpleSelect
                {...commonSelectProps(doctorOptions.length > 1)}
                options={doctorOptions.map(value => ({ value }))}
                onChange={value => setDoctorNameFilter(value)}
                label={'Doctor'}
                value={doctorNameFilter}
                placeholder={'All doctors'}
            />
            <SimpleSelect
                {...commonSelectProps(addresses.length > 1)}
                options={addresses.map(a => ({ label: a.label, value: a.id }))}
                onChange={id => setAddressIdFilter(id)}
                label={'Address'}
                value={addressIdFilter}
                placeholder={'All Addresses'}
            />
        </LoadBlocker>
    );
};
