import type { OrderTimelineV2Item } from '../../../PracticeOrderChatV2';
import { OrderTimelineDateHeader } from './OrderTimelineDateHeader';
import { OrderTimelineDivider } from './OrderTimelineDivider';
import { OrderTimelineItem } from './OrderTimelineItem/OrderTimelineItem';
import { useStyles } from './css.util';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import _ from 'lodash';
import React from 'react';

type Props = {
    events: OrderTimelineV2Item[];
    order: LabsGqlOrder;
    isLastOrder: boolean;
};

export const OrderTimelineOrderEvents: React.FC<Props> = ({ events, order, isLastOrder }) => {
    const classes = useStyles();

    const wasReplaced = !!order.replaced_by_ids?.length;
    const isReplacement = !!order.cancelled_order_id;
    const sortedEvents = React.useMemo(() => _.sortBy(events, 'date'), [events]);
    const groupedEvents = React.useMemo(
        () => _.groupBy(sortedEvents, event => new Date(event.date).toLocaleDateString('en-us')),
        [sortedEvents],
    );

    return (
        <>
            {isReplacement && <OrderTimelineDivider>Updated order created</OrderTimelineDivider>}
            {Object.entries(groupedEvents).map(([date, groupEvents]) => (
                <div className={classes.dateContainer} key={date}>
                    <OrderTimelineDateHeader date={new Date(date)} />
                    {groupEvents.map(event => (
                        <OrderTimelineItem
                            key={event.date}
                            event={event}
                            order={order}
                            allSortedEvents={sortedEvents}
                            isLastOrder={isLastOrder}
                        />
                    ))}
                </div>
            ))}
            {wasReplaced && isLastOrder && (
                <OrderTimelineDivider>This order has been archived due to an edit</OrderTimelineDivider>
            )}
        </>
    );
};
