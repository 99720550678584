import { getAskDoctorV2Configuration, useAskDoctorResponseDialog } from '../AskDoctorResponse';
import { useOrderDetailsContext } from './OrderDetailTracking.context.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

export const OrderDetailResolveIssueButton: React.VFC = () => {
    const { order, activeScanReviewFragment } = useOrderDetailsContext();
    const { shouldShowResolveButton, doctorRequestId } = getAskDoctorV2Configuration(
        order,
        activeScanReviewFragment ?? null,
    );

    const [setAskDoctorResponseOpen, askDoctorResponseDialog] = useAskDoctorResponseDialog({
        doctorRequestId,
        orderId: order.id,
    });

    const handleResolveIssue = React.useCallback(() => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Ask Doctor - Review Hold Opened', {
            $groups: { order: order.id },
            location: 'order details',
        });
        setAskDoctorResponseOpen(true);
    }, [setAskDoctorResponseOpen, order.id]);

    if (!shouldShowResolveButton) {
        return null;
    }

    return (
        <Grid item>
            <Button variant={'primary'} onClick={handleResolveIssue}>
                Resolve issue
            </Button>
            {askDoctorResponseDialog}
        </Grid>
    );
};
