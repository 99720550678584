import type { QcHeatmapRange } from '../ColorRamp';
import { QCColorLegend } from '../ColorRamp';
import { RestorativeView, type ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { HeatMapType } from '@orthly/forceps';
import { styled, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import React from 'react';
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

interface DraggableQCToolbarProps {
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    dynamicHeatmaps: boolean;
    hideZoom?: boolean;
}

const StyleWrapper = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    cursor: 'grab',
});

export const DraggableQCToolbar: React.FC<DraggableQCToolbarProps> = ({
    appearance,
    setAppearance,
    dynamicHeatmaps,
    hideZoom,
}) => {
    const nodeRef = React.useRef(null);
    const [deltaPosition, setDeltaPosition] = React.useState({ x: 0, y: 40 });
    const isScreenLgOrUp = useScreenIsLgOrUp();

    const handleHeatMapRangeChange = React.useCallback(
        (newValues: QcHeatmapRange): void => {
            setAppearance(current => ({
                ...current,
                heatMapRange: dynamicHeatmaps ? newValues : undefined,
            }));
        },
        [setAppearance, dynamicHeatmaps],
    );

    if (
        !isScreenLgOrUp ||
        !appearance ||
        appearance.restorativeView !== RestorativeView.HeatMap ||
        appearance.activeHeatMap === HeatMapType.VertexDisplacement
    ) {
        return null;
    }

    const handleStart = (_: DraggableEvent, ui: DraggableData) => {
        const { x, y } = deltaPosition;
        setDeltaPosition({
            x: x + ui.deltaX,
            y: y + ui.deltaY,
        });
    };

    return (
        <Draggable
            nodeRef={nodeRef}
            handle={'.handle'}
            position={deltaPosition}
            grid={[25, 25]}
            scale={1}
            onDrag={handleStart}
        >
            <StyleWrapper ref={nodeRef} className={'handle'}>
                <QCColorLegend
                    dynamicHeatmaps={dynamicHeatmaps}
                    heatMapType={appearance.activeHeatMap}
                    heatMapRange={appearance.heatMapRange}
                    setHeatMapRange={handleHeatMapRangeChange}
                    hideZoom={hideZoom}
                />
            </StyleWrapper>
        </Draggable>
    );
};
