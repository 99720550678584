import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useAlignerCheckoutSelector, useCheckoutSelector } from '../../../redux';
import { PRACTICE_CHECKOUT_SUBMITTED_PATHNAME } from './CheckoutPaths';
import { useIsScannerSelector, useScanIsAligner } from './checkout.selectors';
import type { CheckoutState } from './checkout.state';
import { useScanIsDenture } from './dentures-checkout.selectors';
import type { SubmittedOrder, UseSubmitOrderResult } from './orderUtils';
import { placeOrderMutationOptions } from './orderUtils';
import { aestheticCheckoutGetPhotosAsAttachments } from './reducers/aesthetic-checkout';
import type { AlignerCheckoutState } from './reducers/aligners-checkout.types';
import { useInputItemsV2 } from './useInputItemsV2';
import { useSubmitDentureOrder } from './useSubmitDenturesOrder';
import type { FetchResult } from '@apollo/client';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlPlacedOrderFragment, LabsGqlPlaceLabOrderMutation } from '@orthly/graphql-operations';
import {
    useAddRetainerToAlignerCaseMutation,
    useOrderRefetch,
    usePlaceLabOrderMutation,
    useScans,
} from '@orthly/graphql-react';
import type {
    LabsGqlAddRetainerToAlignerCaseCommand,
    LabsGqlPlaceAlignerOrderInfo,
    LabsGqlPlaceLabOrderCommand,
} from '@orthly/graphql-schema';
import { LabsGqlOrderItemArch } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemArch } from '@orthly/items';
import { SharedBundlePromoCodeUtil } from '@orthly/shared-types';
import { apolloErrorMessage, useChangeSubmissionFn } from '@orthly/ui';
import constate from 'constate';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

type SubmitOrderRes = FetchResult<LabsGqlPlaceLabOrderMutation>;

function useOnPlaceOrderSuccess(isScanner: boolean) {
    const refetchPartialOrders = useOrderRefetch();
    const { refetch: refetchScans } = useScans({ fetchPolicy: 'cache-first' });
    const [submittedOrder, setSubmittedOrder] = React.useState<SubmittedOrder | undefined>();
    const history = useHistory();
    const onSuccess = React.useCallback(
        async (res: SubmitOrderRes) => {
            const placedOrders: LabsGqlPlacedOrderFragment[] = res.data?.placeOrder ?? [];
            // we could get multiple orders back, but since we are just displaying estimated_delivery_date and patient name,
            // we want the one with the latest estimated_delivery_date
            const placedOrder = _.maxBy(placedOrders, o =>
                new Date(o.practice_dates.estimated_delivery_date).valueOf(),
            );
            if (!placedOrder) {
                return;
            }
            setSubmittedOrder(placedOrder);
            // reset checkout redux state
            // only fetch if not in scanner submit mode (auth would fail)
            if (!isScanner) {
                await Promise.all(placedOrders.map(o => refetchPartialOrders(o.id)));
                await refetchScans();
            }
            AnalyticsClient.track('Global - Order Placed', {
                $groups: {
                    order: placedOrder.id,
                },
            });
            history.push(`/${PracticeScreen.orders}/${PRACTICE_CHECKOUT_SUBMITTED_PATHNAME}/${placedOrder.id}`);
        },
        [isScanner, history, refetchScans, refetchPartialOrders],
    );
    return { onSuccess, submittedOrder };
}

function placeOrderArgsSelector(state: CheckoutState): LabsGqlPlaceLabOrderCommand | null {
    const {
        scan,
        items,
        address,
        patient_last_name,
        patient_first_name,
        doctor,
        doctor_notes,
        patient_birthday,
        patient_gender,
        refab,
        waxupState,
        is_training_order,
        attachments,
        surgicalGuideCheckout: { cbct_url },
        aestheticCheckout,
        sla_modifier,
        promo_code_info,
    } = state;
    if (!scan || items.length === 0 || !address || !doctor) {
        return null;
    }
    if (refab && !refab.notes) {
        return null;
    }

    return {
        doctor_notes,
        is_training_order,
        cbct_url,
        attachments: [...attachments, ...aestheticCheckoutGetPhotosAsAttachments(aestheticCheckout)],
        patient_first_name: patient_first_name || null,
        patient_last_name: patient_last_name || null,
        patient_birthday: patient_birthday ? patient_birthday.toJSON() : null,
        doctor_preferences_id: doctor.id,
        scan_export_id: scan.id,
        mailing_address_id: address.id,
        patient_gender: patient_gender ?? undefined,
        refabrication_info: !refab
            ? undefined
            : {
                  attachments: refab.attachments.map(attachment => ({ url: attachment })),
                  notes: refab.notes ?? '',
                  original_order_id: refab.original_order.id,
                  reasons: refab.reasons,
              },
        waxup_requested: waxupState.selected,
        sla_modifier,
        promo_codes: SharedBundlePromoCodeUtil.convertPromoCodeInfoStateToPromoCodes(
            promo_code_info,
            items.map(CartItemV2Utils.getProductUnitType),
        ),
    };
}

function placeAlignerOrderArgsSelector(state: AlignerCheckoutState): LabsGqlPlaceAlignerOrderInfo | null {
    const { overriddenPatientSteps } = state;
    if (!overriddenPatientSteps) {
        return null;
    }

    return {
        doctor_proposed_step_limit: overriddenPatientSteps,
    };
}

function useSubmitNonDentureOrder(): UseSubmitOrderResult {
    const isScanner = useIsScannerSelector();
    const [rawSubmit, { called, error }] = usePlaceLabOrderMutation(placeOrderMutationOptions());
    // once the user has attempted a submit, disable submissions
    // unless the request fails
    const isSubmitDisabled = called && !error;

    const { onSuccess, submittedOrder } = useOnPlaceOrderSuccess(isScanner);
    const { submitting, submit } = useChangeSubmissionFn<any, any>(
        (data: LabsGqlPlaceLabOrderCommand) => rawSubmit({ variables: { data } }),
        {
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSuccess,
            successMessage: res => {
                const result = res.data;
                const firstOrder = result?.placeOrder[0];
                const patientName = firstOrder
                    ? `for ${firstOrder.patient.first_name} ${firstOrder.patient.last_name} `
                    : '';
                return [`Order ${patientName} placed!`, {}];
            },
        },
    );

    const submitVariables = useCheckoutSelector<LabsGqlPlaceLabOrderCommand | null>(placeOrderArgsSelector);
    const alignerSubmitVariables = useAlignerCheckoutSelector<LabsGqlPlaceAlignerOrderInfo | null>(
        placeAlignerOrderArgsSelector,
    );
    const items_v2_by_sku = useInputItemsV2();
    const is_aligner_order = useScanIsAligner();
    const maybeSubmit = React.useCallback(async () => {
        if (submitVariables && items_v2_by_sku && !isSubmitDisabled) {
            return submit({
                ...submitVariables,
                items_v2_by_sku,
                is_aligner_order,
                aligner_info: alignerSubmitVariables ?? undefined,
            });
        }
    }, [submitVariables, items_v2_by_sku, is_aligner_order, isSubmitDisabled, submit, alignerSubmitVariables]);

    return {
        submitting,
        submittedOrder,
        isSubmitDisabled,
        submit: maybeSubmit,
    };
}

const ORDER_ITEM_ARCH_GQL_MAP: { [_ in OrderItemArch]: LabsGqlOrderItemArch } = {
    [OrderItemArch.Upper]: LabsGqlOrderItemArch.Upper,
    [OrderItemArch.Lower]: LabsGqlOrderItemArch.Lower,
    [OrderItemArch.Dual]: LabsGqlOrderItemArch.Dual,
};

function useSubmitAlignerRetainerOrder(): UseSubmitOrderResult | null {
    const existingOrder = useAlignerCheckoutSelector(s => s.existingOrder);
    const lab_order_id = existingOrder?.id;
    const scan_export_id = useCheckoutSelector(s => s.scan?.id);
    const retainer = useAlignerCheckoutSelector(
        ({ retainer }) => retainer && { ...retainer, arch: ORDER_ITEM_ARCH_GQL_MAP[retainer.arch] },
    );
    const isScanner = useIsScannerSelector();
    const { onSuccess: onSuccessDefault, submittedOrder } = useOnPlaceOrderSuccess(isScanner);
    const [rawSubmit, { called, error }] = useAddRetainerToAlignerCaseMutation();
    const isSubmitDisabled = called && !error;
    const { submitting, submit } = useChangeSubmissionFn<any, any>(
        (data: LabsGqlAddRetainerToAlignerCaseCommand) => rawSubmit({ variables: { data } }),
        {
            onSuccess: result => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                onSuccessDefault({
                    data: result.data ? { placeOrder: [result.data.addRetainerToAlignerCase] } : undefined,
                    errors: result.errors,
                });
            },
            successMessage: () => [`Retainer order placed!`, {}],
            errorMessage: e => [apolloErrorMessage(e, 'ERROR placing order: '), {}],
        },
    );

    const maybeSubmit = React.useCallback(async () => {
        if (lab_order_id && retainer && !isSubmitDisabled) {
            return submit({ lab_order_id, scan_export_id, retainer });
        }
    }, [lab_order_id, scan_export_id, retainer, isSubmitDisabled, submit]);

    if (!retainer) {
        return null;
    }

    return {
        submitting,
        submittedOrder,
        isSubmitDisabled,
        submit: maybeSubmit,
    };
}

/**
 * Custom hook for managing the submission logic for the checkout form
 */
function useSubmitOrderInner(): UseSubmitOrderResult {
    const isDenture = useScanIsDenture();
    const dentureSubmitOrderResult = useSubmitDentureOrder();
    const normalSubmit = useSubmitNonDentureOrder();
    const alignerRetainerSubmit = useSubmitAlignerRetainerOrder();
    return alignerRetainerSubmit ?? (isDenture ? dentureSubmitOrderResult : normalSubmit);
}

// The rule does not understand this format
// eslint-disable-next-line import/no-unused-modules
export const [CheckoutSubmitOrderProvider, useSubmitOrder] = constate(useSubmitOrderInner);
