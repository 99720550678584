import { SettingsBaseModal } from './SettingsBaseModal';
import { TrashIcon } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import { FlossPalette, IconButton, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

export const RemoveItemModal: React.VFC<{
    title: string;
    subtitle: string;
    confirmRemoveText: string;
    removeItem: () => void;
    onOpen?: () => void;
    openModalButtonText?: string;
    loading?: boolean;
    buttonProps?: Pick<ButtonProps, 'data-test'>;
}> = ({ title, subtitle, confirmRemoveText, removeItem, onOpen, openModalButtonText, loading, buttonProps }) => {
    const [open, setOpen] = React.useState(false);
    const customButton = React.useCallback(
        () =>
            openModalButtonText ? (
                <Button
                    {...buttonProps}
                    variant={'text'}
                    onClick={() => {
                        onOpen?.();
                        setOpen(true);
                    }}
                    style={{ paddingLeft: 24, paddingRight: 0 }}
                >
                    <Text variant={'body2'} color={'STAR_GRASS'} medium>
                        {openModalButtonText}
                    </Text>
                </Button>
            ) : (
                <IconButton
                    {...buttonProps}
                    onClick={() => {
                        onOpen?.();
                        setOpen(true);
                    }}
                >
                    <TrashIcon style={{ color: FlossPalette.GRAY }} />
                </IconButton>
            ),
        [openModalButtonText, onOpen, buttonProps],
    );

    return (
        <SettingsBaseModal
            open={open}
            setOpen={setOpen}
            title={title}
            subtitle={subtitle}
            loading={loading ?? false}
            CustomButton={customButton}
            content={
                <div style={{ float: 'right', marginTop: '16px' }}>
                    <Button variant={'secondary'} style={{ marginRight: '8px' }} onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant={'alert-secondary'}
                        onClick={() => {
                            removeItem();
                            setOpen(false);
                        }}
                    >
                        {confirmRemoveText}
                    </Button>
                </div>
            }
        />
    );
};
