import { Section } from '../../../components/Section';
import { FieldGroup } from '../FieldGroup.styles';
import { useAccountSettings } from '../context';
import { Icon, TextField, styled } from '@orthly/ui-primitives';

const PasswordFieldContainer = styled('div')({
    position: 'relative',
    display: 'flex',
    alignItems: 'start',
});

const Icons = styled('div')({
    position: 'absolute',
    right: '16px',
    top: '16px',
});

export const PasswordSection: React.FC = () => {
    const {
        passwordType,
        password,
        setPassword,
        enablePasswordEdit,
        showPassword,
        toggleShowPassword,
        togglePasswordEdit,
        confirmedPassword,
        setConfirmedPassword,
        passwordError,
        setShowPasswordError,
        confirmedPasswordError,
        setShowConfirmedPasswordError,
    } = useAccountSettings();

    return (
        <Section title={'Password'} subtitle={'Used to access your portal'}>
            <FieldGroup>
                <PasswordFieldContainer>
                    <TextField
                        label={'Password'}
                        data-testid={'password'}
                        variant={'standard'}
                        type={passwordType}
                        fullWidth
                        value={enablePasswordEdit ? password : '********'}
                        helperText={passwordError}
                        error={!!passwordError}
                        onChange={event => setPassword(event.target.value)}
                        disabled={!enablePasswordEdit}
                        InputProps={{
                            autoFocus: true,
                            onBlur: () => {
                                setShowPasswordError(password !== '');
                            },
                        }}
                    />
                    <Icons>
                        {enablePasswordEdit && (
                            <Icon
                                icon={showPassword ? 'VisibilityOffOutlinedIcon' : 'VisibilityOutlinedIcon'}
                                onClick={toggleShowPassword}
                                cursor={'pointer'}
                            />
                        )}
                        <Icon
                            color={'inherit'}
                            icon={enablePasswordEdit ? 'UndoIcon' : 'PencilOutlinedIcon'}
                            onClick={togglePasswordEdit}
                            cursor={'pointer'}
                        />
                    </Icons>
                </PasswordFieldContainer>
                <PasswordFieldContainer>
                    <TextField
                        label={'Confirm password'}
                        data-testid={'confirm-password'}
                        variant={'standard'}
                        fullWidth
                        type={passwordType}
                        value={enablePasswordEdit ? confirmedPassword : '********'}
                        helperText={confirmedPasswordError}
                        error={!!confirmedPasswordError}
                        onChange={event => setConfirmedPassword(event.target.value)}
                        disabled={!enablePasswordEdit}
                        InputProps={{
                            onBlur: () => {
                                setShowConfirmedPasswordError(confirmedPassword !== '');
                            },
                        }}
                    />
                </PasswordFieldContainer>
            </FieldGroup>
        </Section>
    );
};
