import { ProvideProductFeedbackAction } from '../../actions/practitioners/ProvideProductFeedbackAction';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { ShippingUtils } from '@orthly/shared-types';
import { useScreenIsMobile, createStyles, makeStyles, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        trackingLink: {
            height: 'unset',
            padding: '0 0 0 6px',
        },
        combinedTrackingFeedback: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '38px',
            width: 'min-content',
        },
    }),
);

const OrderDetailTrackingActions: React.FC<{ order: LabsGqlOrder }> = props => {
    const { order } = props;
    const classes = useStyles();
    const isMobile = useScreenIsMobile();

    if (!order.tracking_number || isMobile) {
        return null;
    }

    const trackingLink = ShippingUtils.getTrackingLinkForCarrier(order.carrier ?? 'ups', order.tracking_number);

    return (
        <Grid container alignItems={'center'} style={{ justifyContent: 'flex-end' }}>
            <Text variant={'body2'} color={'GRAY'}>
                Track on {ShippingUtils.getCarrierDisplayLink(order.carrier ?? 'ups')}:
            </Text>
            <Button
                variant={'ghost'}
                data-test={'tracking-link'}
                onClick={() => window.open(trackingLink, '_blank')?.focus()}
                className={classes.trackingLink}
                endIcon={'ExpandAltIcon'}
                analytics={{
                    AssetLocation: 'Practice - Order Details',
                    AssetName: 'Tracking Link Clicked',
                }}
            >
                {order.tracking_number}
            </Button>
        </Grid>
    );
};

export const OrderDetailTrackingUnitButton: React.FC<{
    order: LabsGqlOrder;
    existingReview: boolean;
}> = props => {
    const { order, existingReview } = props;
    const classes = useStyles();
    if (order.status === LabsGqlLabOrderStatus.Delivered && !existingReview) {
        return (
            <div className={classes.combinedTrackingFeedback}>
                <ProvideProductFeedbackAction
                    order={order}
                    analyticsLocation={'tracking'}
                    buttonStyle={{ justifyContent: 'end' }}
                />
                <OrderDetailTrackingActions order={order} />
            </div>
        );
    }
    return <OrderDetailTrackingActions order={order} />;
};
