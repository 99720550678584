import { partnerLeftNavButtonLinks, partnerRightNavButtonLinks } from '../constants';
import { useGetLoginOptions } from '../graphql/useGetLoginOptions.graphql';
import { useCurrentProfile, useStaffMemberMatcher } from '../redux';
import { usePracticeLogout } from '../redux/auth';
import { usePracticeStaffControls } from '../screens/account/state/usePracticeStaffControls';
import { useCSTAction, useOnCSTOpen } from '../screens/chat/cst-state/cst.actions';
import { useCSTSelector } from '../screens/chat/cst-state/cst.selectors';
import { useSelectStaffAction } from '../screens/select-staff/state/select-staff.actions';
import { useIsChairside } from '../utils/chairside.hooks';
import { StaffProfilePhoto } from './StaffProfilePhoto';
import { HORIZONTAL_NAV_HEIGHT, HorizontalNav } from '@orthly/dentin';
import { MiniWindowVariant } from '@orthly/ui';
import { FlossPalette, useScreenIsMd } from '@orthly/ui-primitives';
import { DandyNotifications, DandySearch, useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const PracticeTopNav: React.VFC = () => {
    const history = useHistory();
    const profile = useCurrentProfile();
    const chairside = useIsChairside();
    const logoutProfile = useSelectStaffAction('LOGOUT_STAFF');
    const logOut = usePracticeLogout();
    const doctorMatches = useStaffMemberMatcher();
    const { value: enablePMS } = useFeatureFlag('enablePMS');
    const { value: enableDandyShopLinks } = useFeatureFlag('enableDandyShopLinks');

    const screenIsMedium = useScreenIsMd();
    const notificationsComponent = React.useMemo(() => {
        return <DandyNotifications doctorMatches={doctorMatches} navigationAction={path => history.push(path)} />;
    }, [doctorMatches, history]);

    const setCSTVariant = useCSTAction('SET_WINDOW_VARIANT');

    const openCSTAction = useOnCSTOpen(() => {
        setCSTVariant(MiniWindowVariant.Maximized, 'move');
    }, 'horizontal_nav');
    const closeCSTAction = useOnCSTOpen(() => {
        setCSTVariant(MiniWindowVariant.Closed, 'move');
    }, 'horizontal_nav');
    const setCSTOpen = (open: boolean) => {
        open ? openCSTAction() : closeCSTAction();
    };
    const cstOpen = useCSTSelector(({ windowState: { variant } }) => variant !== MiniWindowVariant.Closed);
    const controls = usePracticeStaffControls();
    const { loginOptions } = useGetLoginOptions(controls?.employeePrefs?.staff_member_id ?? '');

    return (
        <HorizontalNav
            enablePMS={!!enablePMS}
            enableDandyShopLinks={!!enableDandyShopLinks}
            chairside={chairside}
            profile={profile}
            switchProfileAction={logoutProfile}
            logoutAction={logOut}
            logoutText={'Logout'}
            notificationsComponent={notificationsComponent}
            searchComponent={<DandySearch />}
            cstProps={{
                unreadCount: 0,
                setCSTOpen,
                cstOpen,
            }}
            leftNavButtonLinks={partnerLeftNavButtonLinks}
            rightNavButtonLinks={partnerRightNavButtonLinks}
            screenIsMedium={screenIsMedium}
            smallProfilePhoto={
                <StaffProfilePhoto staff={profile} style={{ width: 32, height: 32, borderRadius: 2, fontSize: 14 }} />
            }
            largeProfilePhoto={
                <StaffProfilePhoto
                    staff={profile}
                    style={{
                        width: 48,
                        height: 48,
                        border: `1px solid ${FlossPalette.DARK_TAN}`,
                        borderRadius: 4,
                    }}
                />
            }
            navbarHeight={HORIZONTAL_NAV_HEIGHT}
            disableChatBackdrop={true}
            openExternalLinkAction={externalLink =>
                window.open(`https://dandy.dental/${externalLink}`, '_blank')?.focus()
            }
            loginOptions={loginOptions}
        />
    );
};
