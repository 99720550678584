import { AbutmentStage } from './Abutment';
import { CartStage } from './Cart';
import { CheckoutStage } from './Checkout';
import { ImplantBridgeStage } from './ImplantBridge';
import { ImplantRestorationStage } from './ImplantRestoration';
import { ImplantSystemStage } from './ImplantSystem';
import { ImplantTypeStage } from './ImplantType';
import { NoMatchStage } from './NoMatch';
import { PatientStage } from './Patient';
import type { Stage } from './Stage';
import { SuccessStage } from './SuccessStage';
import { UploadStage } from './Upload';

export const stages: Stage[] = [
    PatientStage,
    ImplantTypeStage,
    ImplantRestorationStage,
    ImplantBridgeStage,
    ImplantSystemStage,
    AbutmentStage,
    NoMatchStage,
    UploadStage,
    CartStage,
    CheckoutStage,
    SuccessStage,
];
