import { useAlignerCheckoutPropSelector } from '../../../../../redux';
import { useAlignerCheckoutAction } from '../../../state/aligners-checkout.actions';
import { OrderItemArch } from '@orthly/items';
import {
    FlossPalette,
    useScreenIsMobileOrVerticalTablet,
    ButtonBase,
    createStyles,
    makeStyles,
    Grid,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

const IMAGE_BY_ARCH: { [K in OrderItemArch]: string } = {
    [OrderItemArch.Upper]: '/checkout/upper-arch-aligner.svg',
    [OrderItemArch.Lower]: '/checkout/lower-arch-aligner.svg',
    [OrderItemArch.Dual]: '/checkout/dual-arch-aligner.svg',
};

const TITLE_BY_ARCH: { [K in OrderItemArch]: string } = {
    [OrderItemArch.Upper]: 'Single Arch - Upper',
    [OrderItemArch.Lower]: 'Single Arch - Lower',
    [OrderItemArch.Dual]: 'Dual Arch',
};

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            background: FlossPalette.TAN,
            borderRadius: 16,
            borderStyle: 'solid',
            borderWidth: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            position: 'relative',
        },
        button: {
            height: '100%',
            width: '100%',
        },
        archLabel: {
            position: 'absolute',
            width: '100%',
            pointerEvents: 'none',
            bottom: 24,
        },
    }),
);

interface AlignerCheckoutAlignerArchButtonProps {
    arch: OrderItemArch;
}

export const AlignerCheckoutAlignerArchButton: React.FC<AlignerCheckoutAlignerArchButtonProps> = ({ arch }) => {
    const { alignerArch } = useAlignerCheckoutPropSelector(['alignerArch']);
    const setAlignerArch = useAlignerCheckoutAction('SET_ALIGNER_ARCH');
    const classes = useStyles();
    const isSelected = alignerArch === arch;
    const onClickArchButton = React.useCallback(() => {
        setAlignerArch(isSelected ? undefined : arch);
    }, [setAlignerArch, isSelected, arch]);
    const imageSrc = IMAGE_BY_ARCH[arch];
    const isMobile = useScreenIsMobileOrVerticalTablet();

    return (
        <div
            className={classes.buttonContainer}
            style={{ borderColor: isSelected ? FlossPalette.STAR_GRASS : FlossPalette.TAN }}
        >
            <Grid
                style={{
                    height: '100%',
                    width: '100%',
                    position: isMobile ? 'relative' : 'absolute',
                    flexDirection: isMobile ? 'column' : undefined,
                    padding: isMobile ? 12 : undefined,
                }}
                container
            >
                <ButtonBase
                    onClick={onClickArchButton}
                    className={classes.button}
                    style={{ height: isMobile ? 'unset' : undefined }}
                >
                    <img src={imageSrc} style={{ pointerEvents: 'none' }} alt={`label for ${arch}`} />
                </ButtonBase>
                <Text
                    variant={'body1'}
                    medium
                    className={classes.archLabel}
                    align={'center'}
                    style={{
                        position: isMobile ? 'relative' : undefined,
                        bottom: isMobile ? 0 : undefined,
                        marginTop: isMobile ? 12 : undefined,
                    }}
                    color={isSelected ? 'STAR_GRASS' : 'BLACK'}
                >
                    {TITLE_BY_ARCH[arch]}
                </Text>
            </Grid>
        </div>
    );
};
