import type { PreferencesState } from '../../account/practice-settings/doctors/IndividualPreferences';
import { IndividualPreferences } from '../../account/practice-settings/doctors/IndividualPreferences';
import { SelectStaffContent } from '../components';
import { useSelectStaffAction } from '../state/select-staff.actions';
import { CreateStaffStep } from '../state/select-staff.progression';
import { useSelectStaffSelector } from '../state/select-staff.selectors';
import { PreferenceFieldGqlUtils } from '@orthly/dentin';
import { useSetDoctorPreferencesMutation } from '@orthly/graphql-react';
import { LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import { stylesFactory, Grid, Button, Text } from '@orthly/ui-primitives';
import * as Sentry from '@sentry/react';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    scrollable: {
        overflowY: 'auto',
    },
    flex: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        flexDirection: 'row',
        [theme.breakpoints.down('lg')]: {
            alignItems: 'stretch',
            flexDirection: 'column',
        },
    },
}));

function useSubmitDoctorPreferences(preference_set_id?: string) {
    const [prefsState, setPrefsState] = React.useState<PreferencesState>({});
    const [submitMtn] = useSetDoctorPreferencesMutation();
    const mtnSubmitter = async () => {
        if (!preference_set_id) {
            return Sentry.captureException(
                new Error('create-staff: trying to set doctor preference but profile id is unknown'),
            );
        }
        return submitMtn({
            variables: {
                data: PreferenceFieldGqlUtils.preferencesFormResultToMutation({
                    preference_set_id,
                    ...prefsState.selectedOptions,
                }),
            },
        });
    };
    const { submit, submitting } = useChangeSubmissionFn<any, any>(mtnSubmitter, {});
    return { prefsState, setPrefsState, submit, submitting };
}

export const SelectStaffSetOrderPreferences: React.VFC = () => {
    const classes = useStyles();
    const nextStep = useSelectStaffAction('NEXT_SELECT_STAFF_STEP');
    const individualId = useSelectStaffSelector(({ profileCreationState: { created_profile } }) => created_profile?.id);
    const { setPrefsState, submit, submitting } = useSubmitDoctorPreferences(individualId);
    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.OrderPreferences);
    return (
        <SelectStaffContent show={show}>
            <Grid container className={classes.flex}>
                <Grid container direction={'column'} wrap={'nowrap'} style={{ flexBasis: 1, flexGrow: 1 }}>
                    <Text variant={'h4'} medium>
                        Your order preferences
                    </Text>
                    <Text variant={'body1'} color={'DARK_GRAY'}>
                        Order preferences are used when making orders using Dandy
                    </Text>
                </Grid>
                <Button variant={'secondary'} onClick={nextStep}>
                    Not now, I'll do this later
                </Button>
            </Grid>
            <Grid
                container
                direction={'column'}
                wrap={'nowrap'}
                alignItems={'center'}
                className={classes.scrollable}
                style={{ marginTop: 16 }}
            >
                {individualId && (
                    <IndividualPreferences
                        individualId={individualId}
                        setPrefsState={setPrefsState}
                        containerStyle={{ padding: undefined }}
                    />
                )}
                <LoadBlocker blocking={submitting} ContainerProps={{ style: { width: 'fit-content', marginTop: 4 } }}>
                    <Button variant={'primary'} onClick={() => submit().then(nextStep)}>
                        Continue
                    </Button>
                </LoadBlocker>
            </Grid>
        </SelectStaffContent>
    );
};
