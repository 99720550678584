import { usePartnerUiSelector } from '../../redux/ui';
import { useShowMobileLayout } from '../../utils/LayoutUtils';
import { PracticeSettingsRoot } from './components/PracticeSettingsRoot';
import type { SettingsContentSwitchComponent } from './components/SettingsContentSectionRouter';
import { ProfileSettingsSubNav } from './profile-settings/ProfileSettingsSubNav';
import { AccountSettings } from './profile-settings/account';
import { ProfileNotifications } from './profile-settings/notifications/ProfileNotifications';
import { ProfilePreferencesForSKU } from './profile-settings/preferences/ProfilePreferencesForSKU';
import { ProfilePreferencesHome } from './profile-settings/preferences/ProfilePreferencesHome';
import { PracticeRouteWithMetadata } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';

const ProfileSettingsRouter: SettingsContentSwitchComponent = ({ path, location }) => {
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);

    return (
        <Switch location={location}>
            <PracticeRouteWithMetadata
                path={`${path}/account`}
                title={isNativeApp ? 'Mobile Account Settings' : 'Account Settings'}
            >
                <AccountSettings />
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                path={`${path}/notifications`}
                title={isNativeApp ? 'Mobile Profile Notifications' : 'Profile Notifications'}
            >
                <ProfileNotifications />
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                exact
                path={`${path}/preferences`}
                title={isNativeApp ? 'Mobile Profile Clinical Preferences' : 'Profile Clinical Preferences'}
            >
                {<ProfilePreferencesHome />}
            </PracticeRouteWithMetadata>
            <PracticeRouteWithMetadata
                path={`${path}/preferences/:skuLabel`}
                title={isNativeApp ? 'Mobile Profile Clinical Preferences' : 'Profile Clinical Preferences'}
            >
                <ProfilePreferencesForSKU />
            </PracticeRouteWithMetadata>
            <Redirect to={`${path}/account`} />
        </Switch>
    );
};

export const PartnerProfile: React.FC = () => {
    const isMobileLayout = useShowMobileLayout();

    if (isMobileLayout) {
        return <ProfileSettingsRouter path={`/${PracticeScreen.my_profile}`} />;
    }

    return <PracticeSettingsRoot subNavComponent={<ProfileSettingsSubNav />} Router={ProfileSettingsRouter} />;
};
