import { AccountSettings as AccountSettingsBase } from './AccountSettings';
import { AccountSettingsProvider } from './context';

export const AccountSettings = () => {
    return (
        <AccountSettingsProvider>
            <AccountSettingsBase />
        </AccountSettingsProvider>
    );
};
