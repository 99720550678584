import type { FieldDefBoolean, FieldLayout } from '../QuickForm.types';
import { FormikCheckbox } from '../QuickForm.types';
import { QFHelperText } from './QFHelperText';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface QFBooleanFieldProps {
    field: FieldDefBoolean & { name: string };
}

export const QFBooleanField: React.FC<QFBooleanFieldProps> = ({ field }) => {
    const commonProps = { name: field.name, label: field.label || _.startCase(field.name) };
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});

    return (
        <Grid item {...layout} style={{ display: field.hidden ? 'none' : undefined, ...field.containerStyle }}>
            <div>
                <FormikCheckbox
                    data-test={`quick-form-field-${field.name}`}
                    Label={{ label: commonProps.label, required: !field.hidden } as any}
                    {...field.fieldProps}
                    {...commonProps}
                    color={'secondary'}
                    style={{ ...field.checkboxStyle }}
                />
                <QFHelperText text={field.helperText} />
            </div>
        </Grid>
    );
};
