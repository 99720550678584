import { ATTACH_ALIGNERS_PHOTO_PATH, ATTACH_DENTURES_PHOTO_PATH, ATTACH_AESTHETICS_PHOTO_PATH } from './LabsPaths';
import { LabsUtilsBase, PracticeScreen } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import path from 'path-browserify';
import React from 'react';
import { useHistory } from 'react-router-dom';

export type LabOrderRootStatus = LabsGqlPracticeOrderStatus;

export function useOpenOrderDetailPage() {
    const history = useHistory();

    return React.useCallback(
        (orderId: string, e?: React.MouseEvent) => {
            // button 1 is the middle mouse button.
            const openInNewWindow = e?.metaKey || e?.ctrlKey || e?.button === 1;
            const url = `/${PracticeScreen.orders}/${orderId}`;
            if (openInNewWindow) {
                window.open(url);
            } else {
                history.push(url);
            }
            return openInNewWindow;
        },
        [history],
    );
}

export function useGoToPracticeScreen(screen: PracticeScreen) {
    const history = useHistory();
    return React.useCallback(() => {
        history.push(`/${screen}`);
    }, [history, screen]);
}

export class LabsUtils extends LabsUtilsBase {
    static getAlignerPhotoSubmitSrcUrl(scanId?: string): string | undefined {
        return scanId ? path.join(window.location.origin, 'lab', ATTACH_ALIGNERS_PHOTO_PATH, scanId) : undefined;
    }

    static getDenturesPhotoSubmitSrcUrl(scanId?: string): string | undefined {
        return scanId ? path.join(window.location.origin, 'lab', ATTACH_DENTURES_PHOTO_PATH, scanId) : undefined;
    }

    static getAestheticsPhotoSubmitSrcUrl(scanId?: string): string | undefined {
        return scanId ? path.join(window.location.origin, 'lab', ATTACH_AESTHETICS_PHOTO_PATH, scanId) : undefined;
    }
}

export function getFormattedOrderDetailTitle(order?: LabsGqlOrder): string {
    if (!order) {
        return '';
    }
    const skus = order && CartItemV2Utils.getItemsDisplayNames(OrderItemV2Utils.parseItems(order.items_v2), 'comma');
    const formattedName = order && Format.possessiveNoun(`${order.patient.first_name} ${order.patient.last_name}`);
    return `${formattedName} ${skus} order`;
}
