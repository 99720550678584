import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/**
 * Returns the value of a query parameter and a function to set it.
 * @param {string} name
 * @param {boolean} decodeURI
 * @returns {[(string | undefined), ((value: (string | undefined)) => void)]}
 */
export function useQueryParam(
    name: string,
    decodeURI?: boolean | undefined,
): [string | undefined, (value: string | undefined) => void] {
    const history = useHistory();
    const location = useLocation();

    const paramValue = React.useMemo<string | undefined>(() => {
        const params = new URLSearchParams(location.search);
        return params.get(name) || undefined;
    }, [location.search, name]);
    const setParamValue = React.useCallback(
        (value: string | undefined) => {
            const params = new URLSearchParams(location.search);
            if (!value) {
                params.delete(name);
            } else {
                params.set(name, value);
            }

            history.replace({
                pathname: history.location.pathname,
                search: decodeURI ? decodeURIComponent(params.toString()) : params.toString(),
            });
        },
        [name, history, location.search, decodeURI],
    );

    return [paramValue, setParamValue];
}
