import { PracticeEmployeesTableWrapper } from './PracticeEmployeesTableWrapper';
import { Grid, Tab, Tabs } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

export const PracticeEmployeesTabs: React.FC = () => {
    const [tab, setTab] = React.useState<'activated' | 'deactivated'>('activated');
    const { value: showDeactivatedStaff } = useFeatureFlag('showDeactivatedStaff');
    return (
        <>
            {showDeactivatedStaff && (
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                >
                    <Tab value={'activated'} label={'Active members'} />
                    <Tab value={'deactivated'} label={'Deactivated members'} />
                </Tabs>
            )}
            <Grid container style={{ padding: 10 }}>
                {tab === 'activated' && <PracticeEmployeesTableWrapper includeDeleted={false} />}
                {tab === 'deactivated' && <PracticeEmployeesTableWrapper includeDeleted={true} />}
            </Grid>
        </>
    );
};
