import { MultiProductImageWrapper } from '../../ProductImageWrapper';
import type { ProductUnitType } from '@orthly/items';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grid, Tooltip } from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        countContainer: {
            paddingRight: 24,
            [theme.breakpoints.down('lg')]: {
                paddingRight: 0,
            },
        },
        forceMobile: {
            paddingRight: 0,
        },
        iconContainer: {
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            width: 'auto',
        },
        tooltip: {
            whiteSpace: 'pre-line',
        },
    }),
);

interface OrderSummaryRowItemIconsProps {
    productUnitTypes: ProductUnitType[];
    tooltipTitle: string;
    forceMobileDisplay?: boolean;
}

export const OrderSummaryRowItemIcons: React.VFC<OrderSummaryRowItemIconsProps> = ({
    productUnitTypes,
    tooltipTitle,
    forceMobileDisplay,
}) => {
    const classes = useStyles();

    return (
        <Grid
            container
            wrap={'nowrap'}
            justifyContent={'flex-end'}
            className={cx(classes.countContainer, forceMobileDisplay && classes.forceMobile)}
        >
            <Tooltip title={tooltipTitle} arrow classes={{ tooltip: classes.tooltip }}>
                <div role={'tooltip-anchor'}>
                    <MultiProductImageWrapper products={productUnitTypes} size={24} />
                </div>
            </Tooltip>
        </Grid>
    );
};
