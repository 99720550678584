import { FeedbackFiveStarPropertiesScreen } from './FeedbackFiveStarPropertiesScreen';
import { FeedbackItemNotesScreenComponent } from './FeedbackItemNotesScreen';
import { FeedbackItemPropertiesScreen } from './FeedbackItemPropertiesScreen';
import { FeedbackItemSelectScreenComponent } from './FeedbackItemSelectScreen';
import { FeedbackLoaderScreenComponent } from './FeedbackLoaderScreen';
import type { FeedbackPostSubmitScreen, FeedbackScreen } from './state/feedback.reducer';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const screenToComponent = (screen: Exclude<FeedbackScreen, FeedbackPostSubmitScreen>): React.ReactElement => {
    switch (screen.id) {
        case 'item_notes':
            return <FeedbackItemNotesScreenComponent screen={screen} />;
        case 'item_props':
            return <FeedbackItemPropertiesScreen screen={screen} />;
        case `five_star_props`:
            return <FeedbackFiveStarPropertiesScreen screen={screen} />;
        case 'item_select':
            return <FeedbackItemSelectScreenComponent screen={screen} />;
        case 'loader':
            return <FeedbackLoaderScreenComponent screen={screen} />;
    }
};

export const FeedbackBody: React.VFC<{
    screen: Exclude<FeedbackScreen, FeedbackPostSubmitScreen>;
}> = ({ screen }) => {
    return (
        <Grid item style={{ flexGrow: 1 }}>
            {screenToComponent(screen)}
        </Grid>
    );
};
