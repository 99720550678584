import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlLabSalesOrderServiceSpeed } from '@orthly/graphql-schema';
import { Divider, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const PracticeOrderDetailTrackingNoRushBanner_Query = graphql(`
    query PracticeOrderDetailTrackingNoRushBanner_Query($order_id: String!) {
        getLabSalesOrderById(id: $order_id) {
            service_level {
                speed
            }
        }
    }
`);

const BannerWrapper = styled('div')<{ speed: string }>(({ speed }) => ({
    width: '100%',
    borderRadius: '8px',
    padding: '8px 16px',
    backgroundColor:
        speed === LabsGqlLabSalesOrderServiceSpeed.Rush ? FlossPalette.SECONDARY_BACKGROUND : FlossPalette.GRAY,
}));

interface OrderDetailTrackingServiceLevelSpeedBannerProps {
    orderId: string;
}

interface OrderDetailTrackingServiceLevelSpeedBannerContentProps {
    speed: string | undefined;
}

export const OrderDetailTrackingServiceLevelSpeedBannerContent: React.VFC<
    OrderDetailTrackingServiceLevelSpeedBannerContentProps
> = ({ speed }) => {
    if (speed === LabsGqlLabSalesOrderServiceSpeed.Slow) {
        // TODO: EPDPOR-2182 update this credit amount with a real value that is stored in the backend.
        // We don't presently store this, but will once the value stops being static.
        const creditAmount = 5;

        return (
            <>
                <Divider style={{ marginBottom: 16 }} />
                <BannerWrapper speed={speed}>
                    <Text variant={'body2'} color={'WHITE'}>
                        You've opted into <strong>no-rush shipping</strong> (longer turnaround time) and earned a $
                        {creditAmount} credit
                    </Text>
                </BannerWrapper>
            </>
        );
    }
    if (speed === LabsGqlLabSalesOrderServiceSpeed.Rush) {
        return (
            <>
                <Divider style={{ marginBottom: 16 }} />
                <BannerWrapper speed={speed}>
                    <Text variant={'body2'} color={'SECONDARY_FOREGROUND'}>
                        You’ve opted into <strong>rush shipping</strong>
                    </Text>
                </BannerWrapper>
            </>
        );
    }
    return null;
};

export const OrderDetailTrackingServiceLevelSpeedBanner: React.VFC<OrderDetailTrackingServiceLevelSpeedBannerProps> = ({
    orderId,
}) => {
    const { data } = useQuery(PracticeOrderDetailTrackingNoRushBanner_Query, {
        variables: {
            order_id: orderId,
        },
    });

    return (
        <OrderDetailTrackingServiceLevelSpeedBannerContent speed={data?.getLabSalesOrderById?.service_level.speed} />
    );
};
