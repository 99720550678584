import { useCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { usePartnerNotificationPrefsQuery, useSendCheckoutImagesUploadLinkMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useImpersonatedQrCodeLink, PhotoUploadTypeSelect } from '@orthly/veneer';
import React from 'react';

interface CheckoutPhotoUploadTypeSelectProps {
    onDesktopUpload(): void;
    getMobileURL(scanID: string): string;
    productName: string;
}

export const CheckoutPhotoUploadTypeSelect: React.VFC<CheckoutPhotoUploadTypeSelectProps> = ({
    onDesktopUpload,
    getMobileURL,
    productName,
}) => {
    const scanID = useCheckoutSelector(s => s.scan?.id);
    const qrCodeResult = useImpersonatedQrCodeLink(scanID ? getMobileURL(scanID) : null);

    const { patient_first_name, patient_last_name, doctor } = useCheckoutPropSelector([
        'patient_first_name',
        'patient_last_name',
        'doctor',
    ]);

    const notifPrefs = usePartnerNotificationPrefsQuery();

    const [sendTextMtn] = useSendCheckoutImagesUploadLinkMutation();
    const textSubmitter = async (phoneNumber: string) => {
        if (!doctor) {
            throw new Error(`Doctor info was not found`);
        }
        if (!qrCodeResult.link) {
            throw new Error(`Error generating link`);
        }

        await sendTextMtn({
            variables: {
                data: {
                    upload_link: qrCodeResult.link,
                    patient_name: `${patient_first_name} ${patient_last_name}`,
                    doctor_name: doctor.name,
                    partner_id: doctor.partner_id,
                    phone_number: phoneNumber,
                    product_name: productName,
                },
            },
        });
    };

    const { submit: sendText, submitting } = useChangeSubmissionFn<void, [string]>(textSubmitter, {
        successMessage: () => ['Check your phone for the upload link!', {}],
    });

    return (
        <PhotoUploadTypeSelect
            onDesktopUpload={onDesktopUpload}
            qrCode={qrCodeResult}
            sendTextCTAProps={{
                submitting,
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onSubmit: sendText,
                placeholderPhoneNumber: notifPrefs.data?.getPartnerPreferences?.phone_number ?? undefined,
            }}
        />
    );
};
