import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'chat';

export const ChatActions = {
    OPEN_ZD_CHAT: createSyncAction<string | void>(`${PREFIX}/OPEN_ZD_CHAT`),
    CLOSE_ZD_CHAT: createSyncAction<void>(`${PREFIX}/CLOSE_ZD_CHAT`),
    SET_ZD_CHAT_OPEN: createSyncAction<boolean>(`${PREFIX}/SET_ZD_CHAT_OPEN`),
    SET_ZD_CHAT_UNREAD: createSyncAction<number>(`${PREFIX}/SET_ZD_CHAT_UNREAD`),
    CLOSE_OUT_OF_OFFICE: createSyncAction<void>(`${PREFIX}/CLOSE_OUT_OF_OFFICE`),
    SET_ZD_CHAT_LOADED: createSyncAction<void>(`${PREFIX}/SET_ZD_LOADED`),
};

export const useChatAction = generateUseActionHook(ChatActions);
