import { OrderAction, OrderActionTrackerIdMap } from '../OrderActionsUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useSetDoctorPreferencesIdMutation } from '@orthly/graphql-react';
import { RootActionDialog, SimpleSelect, useRootActionCommand } from '@orthly/ui';
import type { ButtonVariant } from '@orthly/ui-primitives';
import { Button, Icon, Tooltip, Text, styled } from '@orthly/ui-primitives';
import { useDoctorsForPartner } from '@orthly/veneer';
import React from 'react';

const ContainerDiv = styled('div')({
    marginTop: 16,
});

const BottomButtonsContainer = styled('div')({
    marginTop: 48,
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gridGap: 8,
    width: '100%',
});

const StyledButton = styled(Button)({
    height: 'unset',
    minWidth: 'unset',
});

export const EditDoctorAction: React.FC<{
    order: LabsGqlOrder;
    isIconOnly?: boolean;
    buttonVariant?: ButtonVariant;
    showTooltip?: boolean;
    showDropdownIcon?: boolean;
    fullWidth?: boolean;
    leftAlign?: boolean;
    refetch: () => Promise<void>;
}> = ({
    order,
    leftAlign,
    refetch,
    isIconOnly = false,
    buttonVariant = 'ghost',
    showDropdownIcon = false,
    fullWidth = false,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const [doctorId, setDoctorId] = React.useState<string>(order.doctor_preferences_id);
    const { preferences, loading } = useDoctorsForPartner(order.partner_id, false);

    const submitMtn = useSetDoctorPreferencesIdMutation();
    const { submit, submitting } = useRootActionCommand(submitMtn, {
        successMessage: 'Updated doctor!',
        onSuccess: () => {
            void refetch();
            setOpen(false);
        },
    });

    return (
        <>
            <RootActionDialog
                open={open}
                setOpen={setOpen}
                loading={false}
                showCloseButton
                title={'Change doctor'}
                PaperProps={{
                    // We prevent propagation because the mobile app renders this component in a popover.
                    // That popover would close if we allow the upward propagation.
                    onClick: e => e.stopPropagation(),
                }}
                content={
                    <ContainerDiv>
                        <Text variant={'body2'} medium>
                            Doctor
                        </Text>
                        <SimpleSelect
                            options={preferences.map(pref => ({
                                value: pref.id,
                                label: pref.name,
                            }))}
                            label={'Doctor'}
                            onChange={value => {
                                if (value) {
                                    setDoctorId(value);
                                }
                            }}
                            value={doctorId}
                        />
                        <BottomButtonsContainer>
                            <span />
                            <Button onClick={() => setOpen(false)} variant={'secondary'}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    submit({
                                        data: {
                                            doctor_preferences_id: doctorId,
                                            orderId: order.id,
                                        },
                                    })
                                }
                                disabled={loading || submitting}
                                variant={'primary'}
                            >
                                Save changes
                            </Button>
                        </BottomButtonsContainer>
                    </ContainerDiv>
                }
                CustomButton={() => (
                    <Tooltip arrow title={'Change Doctor'}>
                        <span style={{ width: fullWidth ? '100%' : undefined }}>
                            <StyledButton
                                ref={buttonRef}
                                fullWidth={fullWidth}
                                leftAlign={leftAlign}
                                data-tracker-id={OrderActionTrackerIdMap[OrderAction.Download]}
                                variant={buttonVariant}
                                startIcon={isIconOnly ? undefined : 'PencilOutlinedIcon'}
                                endIcon={showDropdownIcon ? 'ChevronDown' : undefined}
                                onClick={e => {
                                    e.stopPropagation();
                                    setOpen(true);
                                }}
                                analytics={{
                                    AssetName: 'Change Doctor',
                                    AssetLocation: 'Practice - Order - Order Details Page',
                                    AssetCTAText: 'Change Doctor',
                                }}
                            >
                                {isIconOnly ? <Icon icon={'PencilOutlinedIcon'} /> : 'Change Doctor'}
                            </StyledButton>
                        </span>
                    </Tooltip>
                )}
            />
        </>
    );
};
