import type { Location } from 'history';
import React from 'react';
import { useRouteMatch, Route } from 'react-router-dom';

export type SettingsContentSwitchComponent = React.VFC<{ path: string; location?: Location }>;

interface SettingsPageRouterProps {
    Switch: SettingsContentSwitchComponent;
}

export const SettingsContentSectionRouter: React.VFC<SettingsPageRouterProps> = ({ Switch }) => {
    const { path } = useRouteMatch();
    return <Route path={path} render={({ location }) => <Switch location={location} path={path} />} />;
};
