import { useTryInFeedbackAction } from '../state/TryInFeedback.action';
import { useTryInFeedbackPropSelector } from '../state/TryInFeedback.selectors';
import type { TryInFeedbackItemGroup, TryInFeedbackNavigation } from '../state/TryInFeedback.types';
import { TryInFeedbackUtils } from '../utils/TryInFeedback.util';
import { TryInFeedbackScreenLayout } from './layout/TryInFeedbackScreenLayout';
import {
    TRY_IN_FEEDBACK_NEXT_STEPS_DENTURE_LABELS,
    TRY_IN_FEEDBACK_NEXT_STEPS_PARTIAL_LABELS,
} from './utils/TryInFeedbackNextStepsLabels';
import { EditIconButton, ProductImageWrapper } from '@orthly/dentin';
import type { ICartItemV2DTO, IOrderItemV2DTO, ProductUnitType } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemArch } from '@orthly/items';
import { Grid, Text, stylesFactory, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    reviewBoxWrapper: {
        borderRadius: 8,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.WHITE,
        padding: `12px 12px 16px`,
    },
    reviewBoxTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    reviewFeedbackDetailWrapper: {
        alignItems: 'center',
        '&:not(:first-child)': {
            paddingTop: 16,
        },
        '&:not(:last-child)': {
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            paddingBottom: 16,
        },
    },
}));

interface TryInFeedbackReviewBoxProps {
    title: string;
    product?: ProductUnitType;
    onEdit?: () => void;
}

const TryInFeedbackReviewBox: React.FC<TryInFeedbackReviewBoxProps> = ({ children, title, product, onEdit }) => {
    const classes = useStyles();

    return (
        <Grid item>
            <Grid container direction={'column'} wrap={'nowrap'} spacing={2} className={classes.reviewBoxWrapper}>
                <Grid item>
                    <Grid container alignItems={'center'}>
                        <Grid item xs className={classes.reviewBoxTitleWrapper}>
                            {product && <ProductImageWrapper product={product} wrapperStyle={{ marginRight: 8 }} />}
                            <Text variant={'h6'} color={'BLACK'} medium>
                                {title}
                            </Text>
                        </Grid>
                        {onEdit && (
                            <Grid item>
                                <EditIconButton variant={'small'} onClick={onEdit} />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid item>{children}</Grid>
            </Grid>
        </Grid>
    );
};

interface TryInFeedbackReviewDetailRowProps {
    title: string;
    value?: string;
    onEdit?: () => void;
}

const TryInFeedbackReviewDetailRow: React.VFC<TryInFeedbackReviewDetailRowProps> = ({ title, value, onEdit }) => {
    const classes = useStyles();

    return (
        <Grid item container wrap={'nowrap'} className={classes.reviewFeedbackDetailWrapper}>
            <Grid item xs={4}>
                <Text variant={'body2'} color={'GRAY'} medium>
                    {title}
                </Text>
            </Grid>
            <Grid item xs>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {value}
                </Text>
            </Grid>
            {onEdit && (
                <Grid item>
                    <EditIconButton variant={'small'} onClick={onEdit} />
                </Grid>
            )}
        </Grid>
    );
};

interface TryInFeedbackReviewArchBoxProps {
    arch: OrderItemArch;
    group: TryInFeedbackItemGroup;
    onEdit: () => void;
}

const TryInFeedbackReviewArchBox: React.VFC<TryInFeedbackReviewArchBoxProps> = ({ arch, group, onEdit }) => {
    const feedbackDetails = group.feedbackDetails.filter(detail => detail.arch === arch);

    return (
        <TryInFeedbackReviewBox
            title={`${CartItemV2Utils.getDisplayName(group.orderItem)} ${TryInFeedbackUtils.getItemTypeTitle(
                group.orderItem,
            )} ${arch !== OrderItemArch.Dual ? `${arch} Arch` : 'General'} Feedback`}
            product={CartItemV2Utils.getProductUnitType(group.orderItem)}
            onEdit={onEdit}
        >
            {feedbackDetails.length ? (
                <Grid container direction={'column'}>
                    {feedbackDetails.map(detail => (
                        <TryInFeedbackReviewDetailRow
                            key={detail.category}
                            title={detail.category}
                            value={detail.description}
                        />
                    ))}
                </Grid>
            ) : (
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    No feedback provided{' '}
                    {arch !== OrderItemArch.Dual
                        ? `- ${TryInFeedbackUtils.getItemTypeTitle(
                              group.orderItem,
                          )} ${arch.toLowerCase()} arch is perfect`
                        : null}
                </Text>
            )}
        </TryInFeedbackReviewBox>
    );
};

interface TryInFeedbackReviewNextStepsBoxProps {
    itemGroups: TryInFeedbackItemGroup[];
    setNavigation: (navigation: TryInFeedbackNavigation) => void;
}

const TryInFeedbackReviewNextStepsBox: React.VFC<TryInFeedbackReviewNextStepsBoxProps> = ({
    itemGroups,
    setNavigation,
}) => {
    const getArch = (item: IOrderItemV2DTO): OrderItemArch | undefined => {
        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial)) {
            return TryInFeedbackUtils.getPartialArchType(item);
        }
        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture)) {
            return item.unit.arch;
        }
    };
    const getValue = (item: ICartItemV2DTO): string | undefined => {
        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial) && item.partial_production_type) {
            return TRY_IN_FEEDBACK_NEXT_STEPS_PARTIAL_LABELS[item.partial_production_type];
        }
        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture) && item.denture_production_type) {
            return TRY_IN_FEEDBACK_NEXT_STEPS_DENTURE_LABELS[item.denture_production_type];
        }
    };

    const firstGroup = itemGroups[0];
    if (firstGroup && itemGroups.length === 1) {
        return (
            <TryInFeedbackReviewBox
                title={`${CartItemV2Utils.getDisplayName(firstGroup.orderItem)} ${TryInFeedbackUtils.getItemTypeTitle(
                    firstGroup.orderItem,
                )} Next Steps`}
                product={CartItemV2Utils.getProductUnitType(firstGroup.orderItem)}
                onEdit={() => setNavigation({ step: 'group', groupIndex: 0, subStep: 'next_steps' })}
            >
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {getValue(firstGroup.newItem)}
                </Text>
            </TryInFeedbackReviewBox>
        );
    }

    return (
        <TryInFeedbackReviewBox title={'Next Steps'}>
            <Grid container direction={'column'}>
                {itemGroups.map((group, idx) => {
                    const arch = getArch(group.orderItem);
                    return (
                        <TryInFeedbackReviewDetailRow
                            key={group.itemId}
                            title={`${CartItemV2Utils.getDisplayName(
                                group.orderItem,
                            )} ${TryInFeedbackUtils.getItemTypeTitle(group.orderItem)}${arch ? ` ${arch} Arch` : ''}`}
                            value={getValue(group.newItem)}
                            onEdit={() => setNavigation({ step: 'group', groupIndex: idx, subStep: 'next_steps' })}
                        />
                    );
                })}
            </Grid>
        </TryInFeedbackReviewBox>
    );
};

export const TryInFeedbackReviewAndSubmit: React.VFC = () => {
    const setNavigation = useTryInFeedbackAction('SET_NAVIGATION');
    const { patient, itemGroups } = useTryInFeedbackPropSelector(['patient', 'itemGroups']);

    return (
        <TryInFeedbackScreenLayout
            header={`Here's your feedback on ${patient ? `${patient.first_name}'s` : 'your'} order`}
            subHeader={'This is all the information the lab tech will see. Please review and make any necessary edits.'}
        >
            <Grid container direction={'column'} wrap={'nowrap'} spacing={4}>
                {itemGroups.map((group, idx) => {
                    const steps = TryInFeedbackUtils.getGroupNavigationSubSteps(group.orderItem);

                    return (
                        <React.Fragment key={group.itemId}>
                            {steps.map(step =>
                                step !== 'next_steps' ? (
                                    <TryInFeedbackReviewArchBox
                                        key={step}
                                        arch={step}
                                        group={group}
                                        onEdit={() =>
                                            setNavigation({
                                                navigation: { step: 'group', groupIndex: idx, subStep: step },
                                            })
                                        }
                                    />
                                ) : null,
                            )}
                        </React.Fragment>
                    );
                })}
                <TryInFeedbackReviewNextStepsBox
                    itemGroups={itemGroups}
                    setNavigation={navigation => setNavigation({ navigation })}
                />
            </Grid>
        </TryInFeedbackScreenLayout>
    );
};
