import { graphql } from '@orthly/graphql-inline-react';

// TODO: EPDB-975: clean up legacy query
export const GetBillingContactDataLegacy_Query = graphql(`
    query GetLegacyBillingContactInfo($organizationId: String!) {
        billingContact: getPartnerBillingAccount(partner_id: $organizationId) {
            primary_billing_contact_user_id
        }
        organization: getOrganization(id: $organizationId) {
            name
        }
    }
`);

// TODO: EPDB-975: clean up legacy query
export const GetBillingContactEmail_Query = graphql(`
    query GetBillingContactEmail($userId: String!) {
        billingContactEmail: getUser(id: $userId) {
            email
        }
    }
`);

export const GetBillingContactData_Query = graphql(`
    query GetBillingContactInfo($invoiceIds: [String!]!) {
        billingEmails: getBillingContactEmailsForInvoices(invoiceIds: $invoiceIds) {
            primary_contact_email
        }
    }
`);

export const GetPracticeContractV2ByOrganizationId_Query = graphql(`
    query GetPracticeContractV2ByOrganizationId($organization_id: String!) {
        getPracticeContractV2ByOrganizationId(organization_id: $organization_id) {
            id
            spend_terms {
                id
                effective_start_date
                effective_end_date
                spend_minimum_cents
                spend_cycle_length
            }
        }
    }
`);

// TODO: EPDB-975 clean up old query
export const ListAddresses_Query = graphql(`
    query ListAddresses {
        addresses: listAddresses {
            id
            partner_id
            street_one
            street_two
            city
            state
            postal_code
            country
            deleted_at
            longitude
            latitude
        }
    }
`);

export const ListItemsForInvoice_Query = graphql(`
    query ListItemsForInvoice($invoiceId: String!) {
        listInvoiceItemsForInvoice(invoiceId: $invoiceId) {
            id
            category
            subcategory
            item_type
            invoice_id
            order_id
            amount_cents
            description
            recurring_item_id
            used_credit_id
            created_at
            updated_at

            order_created_at
            doctor_name
            mailing_address_id
            used_credit_attribution_key
            used_credit_attribution_description
        }
    }
`);

export const ListAllInvoiceCreditsForOrganization_Query = graphql(`
    query ListAllInvoiceCreditsForOrganization($organizationId: String!) {
        credits: listInvoiceCreditsForOrganization(organizationId: $organizationId) {
            id
            credit_category_id
        }
    }
`);

export const ListAllBillingCreditCategories_Query = graphql(`
    query ListAllBillingCreditCategories($include_archived: Boolean) {
        getAllBillingCreditCategories(include_archived: $include_archived) {
            id
            updated_at
            created_at
            name
            archived
        }
    }
`);

export const ListAddressesForContract_Query = graphql(`
    query ListAddressesForContract {
        addresses: listAddressesForContract {
            id
            partner_id
            street_one
            street_two
            city
            state
            postal_code
            country
            deleted_at
            longitude
            latitude
        }
    }
`);

export const CalculateActiveContractAggregateSpendOverPeriod_Query = graphql(`
    query CalculateActiveContractAggregateSpendOverPeriod(
        $organizationId: String!
        $periodStart: DateTime!
        $periodEnd: DateTime!
    ) {
        calculateActiveContractAggregateSpendOverPeriod(
            organizationId: $organizationId
            periodStart: $periodStart
            periodEnd: $periodEnd
        ) {
            organization_id
            aggregate_spend_cents
        }
    }
`);
