import { RootActionDialog } from '@orthly/ui';
import { Button, Divider, FlossPalette, Icon, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const NewPillWrapper = styled('span')({
    backgroundColor: FlossPalette.SECONDARY_FOREGROUND,
    borderRadius: '8px',
    border: `1px solid ${FlossPalette.SECONDARY_FOREGROUND}`,
    height: '32px',
    width: '51px',
    marginLeft: '8px',
    padding: '4px 8px',
});

const StyledRootActionDialog = styled(RootActionDialog)({
    '.MuiPaper-root': {
        maxWidth: '796px',
    },
});

const BottomButtons = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr min-content min-content',
    gridColumnGap: '8px',
    margin: '48px 0px 24px',
});

const ContentWrapper = styled('div')({
    marginTop: '24px',
    display: 'grid',
    gridTemplateRows: `repeat(3, min-content)`,
    gridRowGap: `16px`,
    backgroundColor: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: `8px`,
    padding: `24px`,
});

const BottomBannerWrapper = styled('div')({
    position: 'absolute',
    bottom: '0px',
    left: '0px',
    backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
    height: '40px',
    padding: '8px 16px',
    width: '100%',
});

const ExclamationIcon = styled(Icon)({ height: '12px', width: '12px' });

const BottomBanner: React.VFC<{ numberOfDaysUntilAutoApproveDdp: number }> = ({ numberOfDaysUntilAutoApproveDdp }) => {
    return (
        <BottomBannerWrapper>
            <Text variant={'body2'} medium color={'SECONDARY_FOREGROUND'}>
                <ExclamationIcon icon={'ExclamationIcon'} /> Missed appointments will result in the design being auto
                approved{' '}
                {numberOfDaysUntilAutoApproveDdp > 0
                    ? `after ${numberOfDaysUntilAutoApproveDdp} days of the meeting`
                    : 'the same day of the meeting'}
                .
            </Text>
        </BottomBannerWrapper>
    );
};

interface GuidedWaxupScheduleLiveCallInitialModal {
    open: boolean;
    openAbandomentModal: () => void;
    openModal: () => void;
    openCalendlyModal: () => void;
    numberOfDaysUntilAutoApproveDdp: number;
}

export const GuidedWaxupScheduleLiveCallInitialModal: React.VFC<GuidedWaxupScheduleLiveCallInitialModal> = ({
    open,
    openAbandomentModal,
    openModal,
    openCalendlyModal,
    numberOfDaysUntilAutoApproveDdp,
}) => {
    return (
        <StyledRootActionDialog
            open={open}
            setOpen={open => {
                // If the user attempts to close, we open the abandonment modal
                if (!open) {
                    openAbandomentModal();
                } else {
                    // Otherwise, we re-open the modal.
                    openModal();
                }
            }}
            loading={false}
            buttonText={''}
            maxWidth={'md'}
            title={`Would you like to schedule a Live Design Review?`}
            showCloseButton
            CustomButton={() => (
                <Button
                    variant={'ghost'}
                    startIcon={'CalendarAppointmentsIcon'}
                    onClick={() => openModal()}
                    id={'guided-ddp-schedule-live-call-button'}
                >
                    Schedule Live Design Review{' '}
                    <NewPillWrapper>
                        <Text variant={'body2'} medium color={'WHITE'}>
                            New!
                        </Text>
                    </NewPillWrapper>
                </Button>
            )}
            content={
                <div>
                    <ContentWrapper>
                        <div>
                            <Text variant={'body2'} color={'GRAY'}>
                                What is a Live Design Review?
                            </Text>
                            <Text variant={'body2'}>
                                Live Design Review is a scheduled video call with Dandy. Collaborate with a specialist
                                to update designs in real time, and confirm final results before moving to fabrication.
                            </Text>
                        </div>
                        <Divider />
                        <div>
                            <Text variant={'body2'} color={'GRAY'}>
                                What happens when I schedule a Live Design Review?
                            </Text>
                            <Text variant={'body2'}>Your order will be paused until your scheduled design review.</Text>
                        </div>
                    </ContentWrapper>
                    <BottomButtons>
                        <span />
                        <Button variant={'secondary'} onClick={openAbandomentModal} fullWidth={false}>
                            Cancel
                        </Button>
                        <Button variant={'primary'} onClick={openCalendlyModal} fullWidth={false}>
                            Schedule Appointment & Pause Order
                        </Button>
                    </BottomButtons>
                    <BottomBanner numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp} />
                </div>
            }
        />
    );
};
