export type { FlossColor } from '@orthly/ui-primitives';
export {
    FlossPalette,
    FlossPaletteUtils,
    useScreenIsLgOrUp,
    uiPrimitivesConfig,
    useScreenIsXs,
    useScreenIsSm,
    useScreenIsMd,
    useScreenIsMobile,
    useScreenIsMobileOrVerticalTablet,
    useScreenIsPortrait,
    DANDY_THEME,
    useBrowserIsSafari,
    DandyThemeProvider,
    styled,
} from '@orthly/ui-primitives';

export * from './ui-primitives-icons';
