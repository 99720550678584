import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface TryInFeedbackScreenLayoutProps {
    header: string | React.ReactNode;
    subHeader?: string;
}

export const TryInFeedbackScreenLayout: React.FC<TryInFeedbackScreenLayoutProps> = ({
    children,
    header,
    subHeader,
}) => {
    return (
        <Grid
            item
            container
            direction={'column'}
            spacing={4}
            wrap={'nowrap'}
            style={{ margin: '32px auto 24px auto', maxWidth: 600 }}
        >
            <Grid item>
                <Text variant={'h6'} color={'BLACK'} medium>
                    {header}
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginTop: 8 }}>
                    {subHeader}
                </Text>
            </Grid>
            <Grid item>{children}</Grid>
        </Grid>
    );
};
