import type { ThunkActionState } from './common';
import type { StateWithAsync } from './reducer';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';

type AsyncHook<TSelected> = (state: ThunkActionState, actionName: string) => TSelected;

type AnyObj = { [k: string]: any };

function isAsyncState(s: AnyObj): s is StateWithAsync & AnyObj {
    return !!s.async && s.async.loading && s.async.errors && s.async.succeeded;
}

/**
 * Utility to generate an async state selector hook
 * @param {AsyncHook<TSelected>} selector - Selector fn. Is passed async state and action name.
 * @param {TSelected} defaultVal - Fallback if async state does not exist
 * @return {(actionName: string) => TSelected} - selector hook that accepts action name (called in app)
 */
const createAsyncSelector = <TSelected>(selector: AsyncHook<TSelected>, defaultVal: TSelected) => {
    return function <S extends StateWithAsync = StateWithAsync>(actionName: string): TSelected {
        // marking as possibly any object for better error handling. (if !state.async, return defaultVal)
        return useSelector<S | AnyObj, TSelected>(s => {
            if (isAsyncState(s)) {
                return selector(s.async, actionName);
            }
            return defaultVal;
        });
    };
};

// noinspection PointlessBooleanExpressionJS
export const useAsyncIsLoading = createAsyncSelector<boolean>((s, name) => !!s.loading[name], false);

// noinspection PointlessBooleanExpressionJS
export const useAsyncIsSucceeded = createAsyncSelector<boolean>((s, name) => !!s.succeeded[name], false);

export const useAsyncIsFailed = createAsyncSelector<boolean>((s, name) => !!s.errors[name], false);

// noinspection PointlessBooleanExpressionJS
export const useAsyncIsLoadingOrDone = (actionName: string) => {
    const isLoading = useAsyncIsLoading(actionName);
    const isSucceeded = useAsyncIsSucceeded(actionName);
    const isErrored = useAsyncIsFailed(actionName);
    return isLoading || isSucceeded || isErrored;
};

// noinspection PointlessBooleanExpressionJS
export const useAsyncIsDone = (actionName: string) => {
    const isLoading = useAsyncIsLoading(actionName);
    const isSucceeded = useAsyncIsSucceeded(actionName);
    const isErrored = useAsyncIsFailed(actionName);
    return !isLoading && (isSucceeded || isErrored);
};

export const useAsyncErrorMsg = createAsyncSelector<string | undefined>((s, name) => {
    const propVal = s.errors[name];
    if (!propVal || !propVal.message || typeof propVal.message !== 'string') {
        return undefined;
    }
    return propVal.message;
}, undefined);

export enum AsyncActionStatus {
    initial = 'initial', // has not been called yet
    loading = 'loading',
    errored = 'errored',
    succeeded = 'succeeded',
}

/**
 * Hierarchy if two are true: loading, succeeded, errored, initial
 * @param {string} actionName
 * @return {AsyncActionStatus}
 */
export const useAsyncActionStatus = (actionName: string): AsyncActionStatus => {
    const isLoading = useAsyncIsLoading(actionName);
    const isSucceeded = useAsyncIsSucceeded(actionName);
    const isErrored = useAsyncIsFailed(actionName);

    if (isLoading) {
        return AsyncActionStatus.loading;
    }
    if (isSucceeded) {
        return AsyncActionStatus.succeeded;
    }
    if (isErrored) {
        return AsyncActionStatus.errored;
    }
    return AsyncActionStatus.initial;
};

function defineHookName(hook: Function, substateKey: string, isPropertySelector: boolean) {
    // Start case the substate key
    const substateName = `${substateKey.charAt(0).toUpperCase()}${substateKey.slice(1)}`;
    // Example: substateKey = UI, useUISelector or useUIPropertySelector
    const fnName = `use${substateName}${isPropertySelector ? 'Property' : ''}Selector`;
    Object.defineProperty(hook, 'name', { value: fnName, configurable: true });
}

export interface TypedUsePropSelectorHook<State> {
    <P extends keyof State>(properties: P[]): Pick<State, P>;
}

/**
 * Generates a react hook that selects the given property names from the state used by the provided selector hook
 * @param {TypedUseSelectorHook<State>} useSelectorHook
 * @param {string} stateKey - Name of the state the useSelectorHook is selecting from (ex: reviews, orders)
 * @return {<K extends keyof State>(properties: K[]) => K}
 */
export function generateUseStatePropSelector<State>(
    useSelectorHook: TypedUseSelectorHook<State>,
    stateKey: string,
): TypedUsePropSelectorHook<State> {
    const propSelectorHook: TypedUsePropSelectorHook<State> = <P extends keyof State>(properties: P[]) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useSelectorHook(state =>
            properties.reduce(
                (result, prop) => {
                    result[prop] = state[prop];
                    return result;
                },
                {} as Pick<State, P>,
            ),
        );
    };
    defineHookName(propSelectorHook, stateKey, true);
    return propSelectorHook;
}

/**
 * Generates a useSelector hook for a slice of the root state
 * @param {K} substateKey
 * @return {TypedUseSelectorHook<RootState[K]>}
 */
export function generateUseSubstateSelector<RootState, K extends keyof RootState>(
    substateKey: K,
): TypedUseSelectorHook<RootState[K]> {
    type Substate = RootState[K];
    const substateSelector: TypedUseSelectorHook<Substate> = <TSelected>(
        selector: (state: Substate) => TSelected,
        equalityFn?: (left: TSelected, right: TSelected) => boolean,
    ): TSelected => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useSelector<RootState, TSelected>(s => selector(s[substateKey]), equalityFn);
    };
    defineHookName(substateSelector, `${String(substateKey)}`, false);
    return substateSelector;
}
