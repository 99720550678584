import type { TraineeFormFieldsProps } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { SimpleInput } from '@orthly/ui';
import React from 'react';

export const TraineeNameFormField: React.VFC<TraineeFormFieldsProps> = props => {
    const { trainee, hasError, setTrainee } = useTrainee(props.traineeAtom, 'name');

    return (
        <SimpleInput
            flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
            onChange={name => setTrainee(oldTrainee => ({ ...oldTrainee, name }))}
            value={trainee.name}
            label={''}
            placeholder={'Name'}
            TextFieldProps={{
                error: trainee.showInvalidFields && hasError,
                inputProps: { 'aria-label': `Name for person ${props.listIndex + 1}` },
            }}
            fullWidth
        />
    );
};
