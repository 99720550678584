import { useSetScreen } from '../../../../utils/useSetScreen';
import { CheckoutNewScanConfirmationOverlay } from '../../components/CheckoutConfirmationOverlay/CheckoutNewScanConfirmationOverlay';
import { useCheckoutAction } from '../../state/checkout.actions';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import type { DentureImplantSupport } from '../../state/reducers/dentures-checkout.types';
import type { ScanWithExistingOrder } from './ExistingOrder';
import { CheckoutExistingOrderTitle, ItemsTableForExistingOrder } from './ExistingOrder';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlExistingPatientOrderMatchFragment } from '@orthly/graphql-operations';
import {
    useSingleMailingAddressQuery,
    useGetDoctorPreferencesByIdQuery,
    useReplaceThreeoxzMutation,
} from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import type { IDentureItem, IOrderItemDentureImplantSupport } from '@orthly/items';
import { DenturesProductionType, LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';
import { Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

interface DentureContinuationSectionWrapperProps {
    existingOrderMatch: LabsGqlExistingPatientOrderMatchFragment;
}

const DentureContinuationSectionWrapper: React.FC<DentureContinuationSectionWrapperProps> = props => {
    const { existingOrderMatch } = props;
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);

    return (
        <Grid container spacing={2}>
            <CheckoutExistingOrderTitle existingOrderMatch={existingOrderMatch} />
            <Grid container item xs={12} sm={6}>
                <ItemsTableForExistingOrder items={OrderItemV2Utils.parseItems(existingOrderMatch.order.items_v2)} />
            </Grid>
            <Grid container item spacing={2}>
                {props.children}
                <Grid item>
                    <Button variant={`secondary`} onClick={() => dismissScreen()}>
                        Place new order
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

function convertImplantSupportFromItem(
    implantSupport?: IOrderItemDentureImplantSupport | null,
): DentureImplantSupport | undefined {
    return implantSupport?.is_implant_supported
        ? { isImplantSupported: true, collarHeightsMm: implantSupport.collar_heights_mm ?? undefined }
        : undefined;
}

interface DentureWaxRimContinuationSectionContentProps {
    existingOrderMatch: LabsGqlExistingPatientOrderMatchFragment;
    waxRimItem: IDentureItem;
}

const DentureWaxRimContinuationSectionContent: React.VFC<DentureWaxRimContinuationSectionContentProps> = ({
    existingOrderMatch,
    waxRimItem,
}) => {
    const setWaxRimContinuation = useDenturesCheckoutAction('SET_WAX_RIM_CONTINUATION');
    const setPatientDoctorInfo = useCheckoutAction(`SET_PATIENT_AND_DOCTOR_INFO`);
    const addressQuery = useSingleMailingAddressQuery({
        variables: { id: existingOrderMatch.order.mailing_address_id },
    });
    const prefQuery = useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: existingOrderMatch.order.doctor_preferences_id },
    });

    return (
        <DentureContinuationSectionWrapper existingOrderMatch={existingOrderMatch}>
            <Grid item>
                <Button
                    variant={`primary`}
                    onClick={() => {
                        setWaxRimContinuation({
                            arch: waxRimItem.unit.arch,
                            isWaxRimContinuation: true,
                            orderId: existingOrderMatch.order.id,
                            implantSupport: convertImplantSupportFromItem(
                                waxRimItem.denture_production_metadata?.implant_support,
                            ),
                        });
                        setPatientDoctorInfo({
                            gender: existingOrderMatch.order.patient.gender,
                            birthday: existingOrderMatch.order.patient.birthday,
                            doctor: prefQuery.data?.preferences,
                            address: addressQuery.data?.singleAddress,
                        });
                    }}
                >
                    Create final denture from wax rim
                </Button>
            </Grid>
        </DentureContinuationSectionWrapper>
    );
};

interface DentureItemContinuationSectionContentProps {
    scanWithExisting: ScanWithExistingOrder;
}

const DentureItemContinuationSectionContent: React.VFC<DentureItemContinuationSectionContentProps> = ({
    scanWithExisting,
}) => {
    const { existingOrderMatch } = scanWithExisting;
    const setScreen = useSetScreen();

    const [newScanSubmitted, setNewScanSubmitted] = React.useState(false);

    const [rawSubmitReplaceScan] = useReplaceThreeoxzMutation();
    const { submit: submitReplaceScan, submitting: submittingReplaceScan } = useChangeSubmissionFn<any, any>(
        rawSubmitReplaceScan,
        {
            successMessage: () => ['New scan submitted!', {}],
            onSuccess: () => setNewScanSubmitted(true),
        },
    );

    const isDelivered = existingOrderMatch.order.status === LabsGqlLabOrderStatus.Delivered;
    const canUploadScan = !isDelivered;

    return (
        <DentureContinuationSectionWrapper existingOrderMatch={existingOrderMatch}>
            <CheckoutNewScanConfirmationOverlay
                open={newScanSubmitted}
                orderId={existingOrderMatch.order.id}
                title={'New scan submitted'}
            />
            <Grid item>
                {canUploadScan && (
                    <Button
                        variant={'primary'}
                        disabled={submittingReplaceScan}
                        onClick={() =>
                            !submittingReplaceScan &&
                            submitReplaceScan({
                                variables: {
                                    data: {
                                        order_id: existingOrderMatch.order.id,
                                        new_3oxz_gcs_path: scanWithExisting.original_upload_url,
                                    },
                                },
                            })
                        }
                    >
                        Upload scan for in-progress order
                    </Button>
                )}
                {existingOrderMatch.order.can_submit_tryin_feedback && (
                    <Button
                        variant={'primary'}
                        onClick={() => setScreen(PracticeScreen.try_in_feedback, `/${existingOrderMatch.order.id}`)()}
                    >
                        Submit try in feedback for previous denture order
                    </Button>
                )}
                {existingOrderMatch.order.can_refabricate && (
                    <Button
                        variant={'primary'}
                        onClick={() => setScreen(PracticeScreen.refab_flow, `/${existingOrderMatch.order.id}`)()}
                    >
                        Refabricate last order
                    </Button>
                )}
            </Grid>
        </DentureContinuationSectionWrapper>
    );
};

export const DentureContinuationSectionContent: React.VFC<{ scanWithExisting: ScanWithExistingOrder }> = ({
    scanWithExisting,
}) => {
    const { existingOrderMatch } = scanWithExisting;

    const dentureItems = OrderItemV2Utils.parseItems(existingOrderMatch.order.items_v2).filter(
        OrderItemV2Utils.itemIsType(LabOrderItemSKUType.Denture),
    );
    const waxRimItem = dentureItems.find(item => item.denture_production_type === DenturesProductionType.WaxRim);

    if (waxRimItem) {
        return (
            <DentureWaxRimContinuationSectionContent existingOrderMatch={existingOrderMatch} waxRimItem={waxRimItem} />
        );
    }

    return <DentureItemContinuationSectionContent scanWithExisting={scanWithExisting} />;
};
