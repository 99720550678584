import { useOpenOrderDetailPage } from '../LabsUtils';
import { useOrderDetailSection } from './OrderDetailSectionProvider';
import { OrderDetailDesignSection } from './components/OrderDetailBodySections/OrderDetailDesignSection';
import { OrderDetailDetailsSection } from './components/OrderDetailBodySections/OrderDetailDetailsSection';
import { OrderDetailOverviewSection } from './components/OrderDetailBodySections/OrderDetailOverviewSection';
import { OrderDetailPhotosSection } from './components/OrderDetailBodySections/OrderDetailPhotosSection';
import { OrderDetailScansSection } from './components/OrderDetailBodySections/OrderDetailScansSection';
import type { OrderDetailSharedProps } from './components/OrderDetailBodySections/OrderDetailSection.types';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { Grid } from '@orthly/ui-primitives';
import {
    GuidedWaxupAlertBanner,
    OrderRefabAlertBanner,
    OrderMultiFulfillmentAlertBanner,
    useShouldShowGuidedWaxupFlow,
} from '@orthly/veneer';
import React from 'react';

const OrderDetailTopBanner: React.VFC<{ order: LabsGqlSingleLabOrderFragment }> = ({ order }) => {
    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(order);
    const openOrder = useOpenOrderDetailPage();

    return (
        <>
            <OrderMultiFulfillmentAlertBanner order={order} openOrder={openOrder} />
            <OrderRefabAlertBanner order={order} openOrder={openOrder} />
            {enableNewGuidedWaxupFlow && <GuidedWaxupAlertBanner order={order} />}
        </>
    );
};

export const OrderDetailBody: React.FC<OrderDetailSharedProps> = props => {
    const { order } = props;

    const { tabValue, previousTabValue, scrollToSection } = useOrderDetailSection();

    // Only hook run once on mount
    React.useEffect(() => {
        if (previousTabValue === 'timeline') {
            scrollToSection(tabValue, 'instant');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Grid container style={{ position: 'relative' }}>
            <OrderDetailTopBanner order={order} />

            <Grid container alignItems={'flex-start'}>
                <OrderDetailOverviewSection {...props} />
                <OrderDetailDesignSection {...props} />
                <OrderDetailDetailsSection {...props} />
                <OrderDetailScansSection {...props} />
                <OrderDetailPhotosSection {...props} />
            </Grid>
        </Grid>
    );
};
