import { getAskDoctorV2Configuration } from '../../screens/labs/order-detail-v2/components/AskDoctorResponse/utils.graphql';
import { ResolveFlaggedScanDialog } from './ResolvedFlaggedScanDialog.graphql';
import { ResolveFlaggedScanScreen } from './types';
import { useQuery } from '@apollo/client';
import { isOrderScanRejectedAndVisibleToPractice } from '@orthly/dentin';
import { ScanReviewStatusEnumToLabelMap } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { ActionCard } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import { AttachmentPreview } from '@orthly/veneer';
import React from 'react';

const PracticeScanFlaggedWarning_Query = graphql(`
    query PracticeScanFlaggedWarning_Query($lab_order_id: String!) {
        getActiveScanReviewByLabOrderId(lab_order_id: $lab_order_id) {
            status
            rejection_metadata {
                attachment_gcs_file_paths
                categories
                notes_for_doctor
            }
            ...PracticeResolvedFlaggedScanDialogScanReview_Fragment
            ...PracticeGetAskDoctorV2ConfigurationScanReview_Fragment
        }
    }
`);

export const ScanFlaggedWarning: React.FC<{
    order: LabsGqlLabOrderFragment;
    orderDetail?: boolean;
    refetch: () => Promise<any>;
}> = props => {
    const { order, refetch } = props;
    const [resolveDialogOpen, setResolveDialogOpen] = React.useState<boolean>(false);
    const [attachmentsOpen, setAttachmentsOpen] = React.useState(false);
    const [screen, setScreen] = React.useState<ResolveFlaggedScanScreen>(ResolveFlaggedScanScreen.DOCTOR_CHOICE);

    const { data: scanReviewData } = useQuery(PracticeScanFlaggedWarning_Query, {
        variables: {
            lab_order_id: order.id,
        },
    });
    const scanReview = scanReviewData?.getActiveScanReviewByLabOrderId;

    const { canUseAskDoctorV2Flow } = getAskDoctorV2Configuration(order, scanReview ?? null);

    // In Ask Doctor V2, Scans rejections are resolved in the same banner as the regular holds, so we do not display this scan warning when the doctor can use the new v2 flow from the resolve hold path instead.
    if (!scanReview || !isOrderScanRejectedAndVisibleToPractice(order, scanReview.status) || canUseAskDoctorV2Flow) {
        return null;
    }

    const categories = scanReview.rejection_metadata.categories
        .map(category => ScanReviewStatusEnumToLabelMap[category])
        .join(', ');

    return (
        <>
            <ActionCard
                title={
                    props.orderDetail
                        ? 'There is an issue with the scan for this order'
                        : `We found an issue with ${order.patient.first_name} ${order.patient.last_name}'s scan`
                }
                variant={'alert'}
                primaryAction={{
                    onClick: () => setResolveDialogOpen(true),
                    text: 'Resolve',
                    endIcon: 'ChevronRight',
                }}
                secondaryAction={
                    scanReview.rejection_metadata.attachment_gcs_file_paths?.length ? (
                        <AttachmentPreview
                            title={'Attachments'}
                            buttonText={''}
                            CustomButton={props => (
                                <Button onClick={props.onClick} variant={'ghost'} fullWidth={false}>
                                    View Attachments
                                </Button>
                            )}
                            sources={scanReview.rejection_metadata.attachment_gcs_file_paths.map((url, idx) => ({
                                source: url,
                                name: `Attachment ${idx + 1}`,
                            }))}
                            open={attachmentsOpen}
                            setOpen={setAttachmentsOpen}
                        />
                    ) : undefined
                }
                subtitle={
                    <Grid container>
                        <Grid item xs={12}>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                {props.orderDetail
                                    ? 'Please let Dandy Support know how you would like to proceed'
                                    : 'We need your help resolving this, the delivery date might be affected.'}
                            </Text>
                        </Grid>
                        {props.orderDetail && (
                            <>
                                <Grid item xs={12}>
                                    <Text variant={'body2'} color={'DARK_GRAY'}>
                                        <b>Reason: </b> &nbsp; {categories ?? 'Other'}
                                    </Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Text variant={'body2'} color={'DARK_GRAY'}>
                                        <b>Notes: </b> &nbsp;{' '}
                                        {scanReview.rejection_metadata.notes_for_doctor ?? '(no notes available)'}
                                    </Text>
                                </Grid>
                            </>
                        )}
                    </Grid>
                }
            />
            <ResolveFlaggedScanDialog
                order={order}
                open={resolveDialogOpen}
                setOpen={setResolveDialogOpen}
                refetch={refetch}
                scanReviewFragment={scanReview}
                screen={screen}
                setScreen={setScreen}
            />
        </>
    );
};
