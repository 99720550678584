import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import { CheckoutTextField } from '@orthly/dentin';
import type { ItemDataFieldId, LabOrderItemSKUType, StringFreeformItemDataField } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import React from 'react';

interface CheckoutStringFreeformItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends CheckoutItemDataFieldBaseProps<SKU> {
    field: StringFreeformItemDataField<ID, SKU>;
}

export const CheckoutStringFreeformItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
}: CheckoutStringFreeformItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    const applyUpdate = (val: string | undefined) => {
        updateItem(field.getUpdatePayload(val ?? null, item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <CheckoutTextField
            value={value}
            onChange={val => applyUpdate(val)}
            label={getFieldLabel(field, item)}
            required={ItemDataFieldUtils.isItemDataFieldRequired(field, item)}
            size={'medium'}
            TextFieldProps={{ multiline: true }}
        />
    );
};
