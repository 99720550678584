import { FileList } from '../PhotoUpload/FileList';
import type { UploadedFile } from './FileUploader.utils';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

export interface UploadedFilesProps {
    files: UploadedFile[];
    loading: boolean;
    onRemoveFile: (name: string) => void;
}

export const UploadedFilesList: React.VFC<UploadedFilesProps> = ({ files, onRemoveFile, loading }) => {
    return (
        <>
            <Grid container justifyContent={'flex-start'} direction={'column'} style={{ marginBottom: 16 }}>
                <Text variant={'body2'} bold>
                    Step 2: Review your uploaded photos or files
                </Text>
                <Text variant={'body2'} color={'GRAY'}>
                    Photos or files you upload will appear here.
                </Text>
                {!files.length && !loading && (
                    <Text variant={'body2'} color={'LIGHT_GRAY'} style={{ paddingTop: 8 }}>
                        No files uploaded yet
                    </Text>
                )}
            </Grid>
            {!loading && (
                <FileList files={files} onRemove={onRemoveFile} maxHeight={272} maxThumbnailSize={24} showFileName />
            )}
        </>
    );
};
