import type { LabOrderActionProps } from '../../index';
import type { PauseOrderActionReason } from '@orthly/shared-types';
import type { ButtonVariant } from '@orthly/ui-primitives';

export const SPLIT_ORDER_TEXT =
    'This patient has items in a different order. You will have to pause the order separately if you wish to do so.';

export type PauseOrderActionProps = LabOrderActionProps & {
    /**
     * Not meant for use in production. Used for testing purposes only.
     */
    preselectedOption?: PauseOrderActionReason;
};

export type PauseOrResumeOrderActionProps = PauseOrderActionProps & {
    buttonVariant: ButtonVariant;
    leftAlign?: boolean;
};
