import type { FieldDefNumber, FieldDefText, FieldLayout } from '../QuickForm.types';
import { FormikText } from '../QuickForm.types';
import { QFErrorText, QFHelperText } from './QFHelperText';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface QFInputFieldProps {
    field: (FieldDefText | FieldDefNumber) & { name: string };
}

export const QFInputField: React.FC<QFInputFieldProps> = ({ field }) => {
    const commonProps = { name: field.name, label: field.label || _.startCase(field.name) };
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    const { style: inputStyle = {}, ...fieldProps } = field.fieldProps || {};
    return (
        // Apply styles to the Grid as a whole so Error and Helper text are included
        <Grid item {...layout} style={{ position: 'relative', ...inputStyle }}>
            <div style={{ display: field.hidden ? 'none' : undefined }}>
                <FormikText
                    type={field.type === 'number' ? 'number' : undefined}
                    fullWidth
                    variant={'standard'}
                    data-test={`quick-form-field-${field.name}`}
                    {...fieldProps}
                    {...commonProps}
                    InputProps={{ disableUnderline: true, ...field.fieldProps?.InputProps }}
                    InputLabelProps={{ required: !field.optional, ...field.fieldProps?.InputLabelProps }}
                    // FormHelperText is hidden because we use QFErrorText below to show errors. This will show a
                    // one-letter error for array field children
                    FormHelperTextProps={{ style: { display: 'none' } }}
                />
                <QFErrorText fieldName={field.name} style={field.fieldProps?.FormHelperTextProps?.style} />
                <QFHelperText text={field.helperText} style={field.fieldProps?.FormHelperTextProps?.style} />
            </div>
        </Grid>
    );
};
