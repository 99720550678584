import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';

const GetLoginOptions_Query = graphql(`
    query GetLoginOptions {
        getLoginOptions {
            access_token
            id
            organization_name
            organization_type
        }
    }
`);

//always pull the staff_member_id from the pref object
export const useGetLoginOptions = (staff_member_id: string | undefined) => {
    const session = useSession();
    const { setLoginOptions } = useStaffMemberLoginProps();
    const { data, loading, error, refetch } = useQuery(GetLoginOptions_Query, {
        skip: staff_member_id !== session?.id,
        onCompleted: data => {
            setLoginOptions(data?.getLoginOptions);
        },
    });

    return { loginOptions: data?.getLoginOptions ?? [], loading, error: error?.message, refetch };
};
