import { CheckoutItemV2Manager } from '../../../state/CheckoutItemV2Manager';
import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import { DisabledFieldOverlay } from '@orthly/dentin';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Grid, TextField } from '@orthly/ui-primitives';
import React from 'react';

interface ItemNotesV2Props {
    item: CheckoutItemV2;
    disabled?: boolean;
}

export const ItemNotesV2: React.FC<ItemNotesV2Props> = props => {
    const { item } = props;
    const isOtherItem = CartItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Aligners, LabOrderItemSKUType.Other]);
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');

    const inputRef = React.useRef<HTMLInputElement>();
    function onBlurOrFocus(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const text = event.target.value;
        if (text !== item.item_notes) {
            updateItem({
                item_index: item.item_index,
                change: { name: 'item_notes', payload: text },
            });
        }
    }
    // this ensures input value is updated if scan changes
    React.useEffect(() => {
        const input = inputRef.current;
        if (input && input.value !== item.item_notes) {
            input.value = item.item_notes || '';
        }
    }, [item.item_notes]);

    if (!isOtherItem) {
        return null;
    }
    const disabled = CheckoutItemV2Manager.fieldsRemaining(item) > 0;
    if (!CheckoutItemV2Manager.materialSelected(item)) {
        return null;
    }

    return (
        <Grid container style={{ paddingTop: 32, position: 'relative' }} justifyContent={'center'}>
            <TextField
                disabled={disabled}
                fullWidth
                multiline
                autoFocus
                placeholder={'Add any item specific notes here\n'}
                variant={'outlined'}
                defaultValue={item.item_notes}
                onBlur={onBlurOrFocus}
                onFocus={onBlurOrFocus}
                inputProps={{ ref: inputRef }}
                InputLabelProps={{ shrink: true }}
                label={isOtherItem ? 'Please let us know what you wanted to order' : 'Item Notes'}
            />
            <DisabledFieldOverlay disabled={disabled} />
        </Grid>
    );
};
