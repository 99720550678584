import { useCheckoutSelector, useAlignerCheckoutSelector } from '../../../redux/selectors';
import { SESSION_STORAGE_IS_CARESTREAM_SESSION } from '../ScannerStorage.util';
import type { ScanWithExistingOrder } from '../screens/CheckoutExistingOrderStep/ExistingOrder';
import { SCANNER_SUBMIT_ROUTE } from './CheckoutPaths';
import type { CheckoutState, CheckoutStepTwoScreen } from './checkout.state';
import { CartItemV2Utils, LabOrderItemSKUType, ToothUtils } from '@orthly/items';
import { QueryString } from '@orthly/ui';
import moment from 'moment';
import { useRouteMatch } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

export type CheckoutStepCompletionState = { complete: false; reason: string } | { complete: true; reason?: undefined };

export function checkoutPatientInfoStepComplete(state: CheckoutState): CheckoutStepCompletionState {
    if (!state.scan || !state.doctor) {
        return { complete: false, reason: !state.scan ? 'No patient selected' : 'No doctor selected' };
    }
    if (!state.address) {
        return { complete: false, reason: 'Please select delivery address' };
    }
    const birthdayIncomplete = !state.patient_birthday || !moment(state.patient_birthday).isValid();
    if (!state.patient_gender || !state.patient_first_name || !state.patient_last_name || birthdayIncomplete) {
        return { complete: false, reason: 'Please complete patient details' };
    }
    return { complete: true };
}

export function checkoutPatientInfoStepWithExistingOrderComplete(state: CheckoutState): CheckoutStepCompletionState {
    if (state.scan?.existingOrderMatch && !state.existingOrderWarningDismissed) {
        return { complete: false, reason: 'Please select how to deal with existing order' };
    }
    return checkoutPatientInfoStepComplete(state);
}

export function useCheckoutPatientInfoStepCompletionState(): CheckoutStepCompletionState {
    return useCheckoutSelector(s => {
        return s.step === 0 ? checkoutPatientInfoStepComplete(s) : { complete: true };
    });
}

export function useScanWithExistingOrderSelector(): ScanWithExistingOrder | undefined {
    function isScanWithExisting(scan: CheckoutState['scan']): scan is ScanWithExistingOrder {
        return !!scan && !!scan.existingOrderMatch;
    }
    return useCheckoutSelector<ScanWithExistingOrder | undefined>(s =>
        isScanWithExisting(s.scan) && !s.existingOrderWarningDismissed ? s.scan : undefined,
    );
}

export function useExistingOrderScreenDisplayingSelector(): boolean {
    return useCheckoutSelector<boolean>(
        s => s.step === 0 && !!s.scan && !!s.scan.existingOrderMatch && !s.existingOrderWarningDismissed,
    );
}

export const smileStyleScreenIsNeeded = (s: CheckoutState) => {
    return s.items.some(i => CartItemV2Utils.isItemEligibleForSmileStyle(i));
};

export const sortedCheckoutStepTwoScreens: CheckoutStepTwoScreen[] = [
    'implantImages',
    'surgicalGuideCbctUpload',
    'aestheticPhotoUpload',
    'smileStyle',
    'refab',
    'summary',
];

const stepTwoScreenComplete = (s: CheckoutState, screen: CheckoutStepTwoScreen) =>
    s.step >= 2 && sortedCheckoutStepTwoScreens.indexOf(s.stepTwoScreen) > sortedCheckoutStepTwoScreens.indexOf(screen);

export const smileStyleScreenIsComplete = (s: CheckoutState) => stepTwoScreenComplete(s, 'smileStyle');

export const implantCheckoutEnabled = (s: CheckoutState) =>
    s.items.some(item => CartItemV2Utils.itemTypeHasImplant(item));

export const implantCheckoutVisible = (s: CheckoutState) => s.step >= 2 && s.stepTwoScreen === 'implantImages';

export const implantCheckoutComplete = (s: CheckoutState) =>
    implantCheckoutEnabled(s) && stepTwoScreenComplete(s, 'implantImages');

const refabScreenIsNeeded = (s: CheckoutState) => !!s.refab;

export const refabScreenIsVisible = (s: CheckoutState) => s.step >= 2 && s.stepTwoScreen === 'refab';

export const refabScreenIsComplete = (s: CheckoutState) => refabScreenIsNeeded(s) && stepTwoScreenComplete(s, 'refab');

export function useScanIsAligner(): boolean {
    const items = useCheckoutSelector(s => s.items);
    const existingAlignerCaseMatch = useAlignerCheckoutSelector(s => !!s.existingOrder);
    // the items on the scan won't be "aligners" if the existing order had a new scan attached.
    // if there's an existing order in the aligner checkout state, we should also enter the aligner checkout flow
    return existingAlignerCaseMatch || items.some(item => item.sku === LabOrderItemSKUType.Aligners);
}

export const getIsSurgicalGuideInCheckout = (s: CheckoutState): boolean => {
    return s.items.some(item => CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.SurgicalGuide));
};
export const getIsSurgicalGuideCbctUploadVisible = (s: CheckoutState): boolean => {
    return getIsSurgicalGuideInCheckout(s) && s.step >= 2 && s.stepTwoScreen === 'surgicalGuideCbctUpload';
};
export const getIsSurgicalGuideCbctUploadComplete = (s: CheckoutState): boolean => {
    return getIsSurgicalGuideInCheckout(s) && stepTwoScreenComplete(s, 'surgicalGuideCbctUpload');
};
export function useScanHasSurgicalGuide(): boolean {
    return useCheckoutSelector(getIsSurgicalGuideInCheckout);
}

// Returns true if an aesthetic crown or bridge product is in the checkout.
export const getIsAestheticProductInCheckout = (s: CheckoutState): boolean => {
    return s.items.some(item => {
        if (
            CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Crown) ||
            CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Veneer)
        ) {
            return ToothUtils.toothIsAnterior(item.unit.unn);
        }
        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Bridge)) {
            return item.units.some(unit => ToothUtils.toothIsAnterior(unit.unn));
        }
        return false;
    });
};

export const getIsAestheticPhotoUploadVisible = (s: CheckoutState): boolean => {
    return getIsAestheticProductInCheckout(s) && s.step >= 2 && s.stepTwoScreen === 'aestheticPhotoUpload';
};
export const getIsAestheticPhotoUploadComplete = (s: CheckoutState): boolean => {
    return getIsAestheticProductInCheckout(s) && stepTwoScreenComplete(s, 'aestheticPhotoUpload');
};

export function useIsScannerSelector(): boolean {
    return !!useRouteMatch(SCANNER_SUBMIT_ROUTE);
}

export function useIsCarestreamSelector(): boolean {
    const location = useLocation();
    const queryParams = QueryString.parse(location.search);
    return (
        queryParams['source'] === 'carestream' ||
        sessionStorage.getItem(SESSION_STORAGE_IS_CARESTREAM_SESSION) === 'true'
    );
}
