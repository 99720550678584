import HollywoodIdle from '../../../assets/images/smile-style/vectors/hollywood-idle.svg';
import HollywoodSelected from '../../../assets/images/smile-style/vectors/hollywood-selected.svg';
import MatureIdle from '../../../assets/images/smile-style/vectors/mature-idle.svg';
import MatureSelected from '../../../assets/images/smile-style/vectors/mature-selected.svg';
import RecommendedIdle from '../../../assets/images/smile-style/vectors/recommended-idle.svg';
import RecommendedSelected from '../../../assets/images/smile-style/vectors/recommended-selected.svg';
import YouthfulIdle from '../../../assets/images/smile-style/vectors/youthful-idle.svg';
import YouthfulSelected from '../../../assets/images/smile-style/vectors/youthful-selected.svg';
import { GallerySelect, type GallerySelectOption } from '../CheckoutFormFields';
import { LabsGqlSmileStyleChoice } from '@orthly/graphql-schema';
import React from 'react';

interface DentureCheckoutSmileStyleSelectProps {
    value?: LabsGqlSmileStyleChoice;
    onChange: (val: LabsGqlSmileStyleChoice) => void;
    patientName: string;
}

export const DentureCheckoutSmileStyleSelect: React.VFC<DentureCheckoutSmileStyleSelectProps> = ({
    value,
    onChange,
    patientName,
}) => {
    const smileStyleOptions: GallerySelectOption<LabsGqlSmileStyleChoice>[] = [
        {
            title: 'Lab choice',
            image: value === LabsGqlSmileStyleChoice.LabRecommendation ? RecommendedSelected : RecommendedIdle,
            value: LabsGqlSmileStyleChoice.LabRecommendation,
            helpIcon: true,
            TooltipProps: {
                title: `Our technicians will use ${patientName}'s age and facial features to determine which smile style will look best`,
            },
        },
        {
            title: 'Hollywood',
            image: value === LabsGqlSmileStyleChoice.Hollywood ? HollywoodSelected : HollywoodIdle,
            value: LabsGqlSmileStyleChoice.Hollywood,
        },
        {
            title: 'Mature',
            image: value === LabsGqlSmileStyleChoice.Mature ? MatureSelected : MatureIdle,
            value: LabsGqlSmileStyleChoice.Mature,
        },
        {
            title: 'Youthful',
            image: value === LabsGqlSmileStyleChoice.Youthful ? YouthfulSelected : YouthfulIdle,
            value: LabsGqlSmileStyleChoice.Youthful,
        },
    ];
    React.useEffect(() => {
        if (!value) {
            onChange(LabsGqlSmileStyleChoice.LabRecommendation);
        }
    }, [value, onChange]);

    return (
        <GallerySelect
            options={smileStyleOptions}
            updateSelectedValue={value => {
                onChange(value);
            }}
            selectedValue={value}
            recommendedValue={LabsGqlSmileStyleChoice.LabRecommendation}
        />
    );
};
