import { CreateOrSelectPatientForm } from './CreateOrSelectPatientForm';
import { EditPatientForm } from './EditPatientForm';
import type { PatientFormPatient, PatientFormAnalytics } from './PatientForm.types';
import { PatientsCard } from './PatientsCard';
import { PencilOutlinedIcon } from '@orthly/ui';
import { Grid, Tooltip, Icon, Button } from '@orthly/ui-primitives';
import React from 'react';

/**
 * NOTE: this file was originally created in the `Scanner` repo.
 * The extended history can be found here:
 * https://github.com/orthly/Scanner/commits/develop/packages/fluoride/src/components/case-builder/setup/patient
 */

const PatientFormWrapper: React.FC = ({ children }) => (
    <Grid container direction={'column'} style={{ maxWidth: 456, padding: 0 }}>
        {children}
    </Grid>
);

export interface PatientFormProps {
    patient?: PatientFormPatient;
    onExistingPatientSelected: (patient: PatientFormPatient) => void;
    onNewPatientCreated: (patient: Omit<PatientFormPatient, 'id'>) => void;
    // When this prop isn't provided, editing won't be enabled
    onPatientEdited?: (patient: PatientFormPatient) => void;
    onPatientCleared: () => void;
    onNextClicked: () => void;
    nextButtonText?: string;
    existingPatients: PatientFormPatient[];
    analytics: PatientFormAnalytics;
}

export const PatientForm: React.VFC<PatientFormProps> = ({
    patient,
    onExistingPatientSelected,
    onNewPatientCreated,
    onPatientEdited,
    onPatientCleared,
    onNextClicked,
    nextButtonText,
    existingPatients,
    analytics,
}) => {
    const [editing, setEditing] = React.useState<boolean>(false);

    if (!patient) {
        return (
            <PatientFormWrapper>
                <CreateOrSelectPatientForm
                    onExistingPatientSelected={onExistingPatientSelected}
                    onNewPatientCreated={onNewPatientCreated}
                    existingPatients={existingPatients}
                    analytics={analytics}
                />
            </PatientFormWrapper>
        );
    }

    if (editing && onPatientEdited) {
        return (
            <PatientFormWrapper>
                <EditPatientForm
                    patient={patient}
                    onConfirm={p => {
                        onPatientEdited(p);
                        setEditing(false);
                    }}
                    onCancel={() => setEditing(false)}
                    analytics={analytics}
                />
            </PatientFormWrapper>
        );
    }

    return (
        <PatientFormWrapper>
            <Grid item>
                <PatientsCard
                    patients={[patient]}
                    Action={() => (
                        <div style={{ display: 'flex' }}>
                            {onPatientEdited && (
                                <Tooltip title={'Edit patient'}>
                                    <div>
                                        <Button
                                            variant={'secondary'}
                                            aria-label={'Edit Patient'}
                                            onClick={() => setEditing(true)}
                                            style={{ minWidth: 'unset', width: 48 }}
                                            data-test={'patient-form-edit-patient-button'}
                                            analytics={{
                                                ...analytics,
                                                AssetName: 'Existing Patient Edit',
                                            }}
                                        >
                                            <PencilOutlinedIcon />
                                        </Button>
                                    </div>
                                </Tooltip>
                            )}
                            <Tooltip title={'Use a different patient'}>
                                <div style={{ marginLeft: 4 }}>
                                    <Button
                                        variant={'secondary'}
                                        onClick={() => onPatientCleared()}
                                        style={{ minWidth: 'unset', width: 48 }}
                                        data-test={'patient-form-clear-patient-button'}
                                        analytics={{
                                            ...analytics,
                                            AssetName: 'Existing Patient Clear',
                                        }}
                                    >
                                        <Icon icon={'SwapHoriz'} />
                                    </Button>
                                </div>
                            </Tooltip>
                        </div>
                    )}
                />
            </Grid>
            <Grid item style={{ marginTop: 24 }}>
                <Button
                    variant={'primary'}
                    endIcon={'ChevronRight'}
                    onClick={() => onNextClicked()}
                    style={{ width: 256 }}
                    data-test={'patient-form-existing-patient-next-button'}
                    analytics={{
                        ...analytics,
                        AssetName: 'Existing Patient Next',
                    }}
                >
                    {nextButtonText ?? 'Next'}
                </Button>
            </Grid>
        </PatientFormWrapper>
    );
};
