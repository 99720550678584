import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import type { ICustomFieldSubmission } from '@orthly/items';
import { AllItemMetafields } from '@orthly/items';
import React from 'react';

export type InlinePrefSubmission = Pick<ICustomFieldSubmission, 'field_id' | 'value' | 'display_name'>;

const SingleInlinePref: React.VFC<{ pref: InlinePrefSubmission }> = ({ pref }) => {
    const { ContainerComponent, fontComponents } = useOrderSummaryComponents();
    const { Text } = fontComponents;
    const metafield = AllItemMetafields.find(field => field.id === pref.field_id);
    const optionLabel = metafield?.options?.find(opt => opt.value === pref.value)?.label;
    return (
        <ContainerComponent key={pref.field_id} style={{ width: '100%', flexDirection: 'row', fontSize: '11pt' }}>
            <ContainerComponent style={{ flex: 2, flexDirection: 'row' }}>
                <Text style={{ color: 'gray', marginRight: '8pt' }}>{metafield?.name ?? pref.display_name}</Text>
                <Text style={{ textOverflow: 'wrap', maxWidth: '75%' }}>{optionLabel ?? pref.value.toString()}</Text>
            </ContainerComponent>
        </ContainerComponent>
    );
};

interface InlinePrefsSectionProps {
    item_notes: string | null | undefined;
    inlinePrefs: InlinePrefSubmission[];
}

export const InlinePrefsSection: React.FC<InlinePrefsSectionProps> = ({ inlinePrefs, item_notes }) => {
    const { ContainerComponent, fontComponents } = useOrderSummaryComponents();
    const { Text, BoldText } = fontComponents;
    const displayNotes = item_notes && item_notes.trim().length > 0;
    // rendering an empty fragment will cause react PDF to crash, this prevents that
    if (!displayNotes && inlinePrefs.length === 0) {
        return null;
    }
    return (
        <>
            {displayNotes ? (
                <ContainerComponent style={{ width: '100%', flexDirection: 'row', fontSize: '11pt' }}>
                    <ContainerComponent style={{ flex: 2, flexDirection: 'row' }}>
                        <BoldText style={{ color: 'gray', marginRight: '8pt' }}>NOTES: </BoldText>
                        {/* for some reason style types do not recognize whiteSpace as a valid key, but it works so we use this workaround */}
                        <Text style={{ textOverflow: 'wrap', maxWidth: '75%', ...{ whiteSpace: 'pre-line' } }}>
                            {item_notes}
                        </Text>
                    </ContainerComponent>
                </ContainerComponent>
            ) : null}
            {inlinePrefs.map(pref => (
                <SingleInlinePref key={pref.field_id} pref={pref} />
            ))}
        </>
    );
};
