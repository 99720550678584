import type { LabOrderRootStatus } from '../../screens/labs/LabsUtils';
import type { PartnerAppState } from '../types';
import type { PracticeOrderSortOptions } from './orders.reducer';
import type { useApolloClient } from '@apollo/client';
import type {
    LabsGqlMarkEventReadMutation,
    LabsGqlMarkEventReadMutationVariables,
    LabsGqlUserReadEventsQuery,
    LabsGqlUserReadEventsQueryVariables,
} from '@orthly/graphql-operations';
import { MarkEventReadDocument, UserReadEventsDocument } from '@orthly/graphql-react';
import type { LabsGqlProductLine } from '@orthly/graphql-schema';
import { createAsyncAction, createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'orders';

interface LoadReadEventsPayload {
    client: ReturnType<typeof useApolloClient>;
    userId: string;
}

interface MarkEventReadPayload extends LoadReadEventsPayload {
    eventIds: number[];
}

export const OrdersActions = {
    CHANGE_STATUS: createSyncAction<LabOrderRootStatus, [LabOrderRootStatus]>(`${PREFIX}/CHANGE_STATUS`),

    LOAD_READ_EVENTS: createAsyncAction<number[], PartnerAppState, [LoadReadEventsPayload]>(
        `${PREFIX}/LOAD_READ_EVENTS`,
        async (_thunkParams, { client }) => {
            const query = await client.query<LabsGqlUserReadEventsQuery, LabsGqlUserReadEventsQueryVariables>({
                query: UserReadEventsDocument,
            });
            const readEventIds = query.data?.getUserReadEvents?.read_event_ids ?? ([] as number[]);
            return { payload: readEventIds };
        },
    ),

    MARK_EVENTS_READ: createAsyncAction<number[], PartnerAppState, [MarkEventReadPayload]>(
        `${PREFIX}/MARK_EVENTS_READ`,
        async (thunkParams, { client, eventIds }) => {
            const currentReadEvents = thunkParams.getState().orders.readEventIds;
            await client.mutate<LabsGqlMarkEventReadMutation, LabsGqlMarkEventReadMutationVariables>({
                mutation: MarkEventReadDocument,
                variables: { eventIds },
            });
            const payload = [...currentReadEvents, ...eventIds];
            return { payload };
        },
    ),

    SET_DOCTOR_FILTER: createSyncAction<string[], [string[]]>(`${PREFIX}/SET_DOCTOR_FILTER`),

    SET_PRODUCT_FILTER: createSyncAction<LabsGqlProductLine[], [LabsGqlProductLine[]]>(`${PREFIX}/SET_PRODUCT_FILTER`),

    SET_SORT_BY: createSyncAction<PracticeOrderSortOptions, [PracticeOrderSortOptions]>(`${PREFIX}/SET_SORT_BY`),
};

export const useOrderAction = generateUseActionHook<typeof OrdersActions>(OrdersActions);
