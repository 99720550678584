import { useAlignerCheckoutPropSelector } from '../../../../redux';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { AlignerSpacingSelection, CheckoutBodyWrapper } from '@orthly/dentin';
import React from 'react';

export const AlignerCheckoutSpacing: React.VFC = () => {
    const { interproximalSpaceSizes, alignerArch } = useAlignerCheckoutPropSelector([
        'interproximalSpaceSizes',
        'alignerArch',
    ]);
    const setInterproximalSpaceSizes = useAlignerCheckoutAction('SET_INTERPROXIMAL_SPACE_SIZES');
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.SpacingSelection);
    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <AlignerSpacingSelection
                arch={alignerArch}
                value={interproximalSpaceSizes}
                onChange={setInterproximalSpaceSizes}
            />
        </CheckoutBodyWrapper>
    );
};
