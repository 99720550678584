import { useCSTAction, useOnCSTOpen } from '../../../../chat/cst-state/cst.actions';
import { useAddNewScans } from '../../../actions/practitioners/useAddNewScans';
import { getAskDoctorV2Configuration } from '../AskDoctorResponse/utils.graphql';
import { OrderDetailResolveIssueButton } from './OrderDetailResolveIssueButton';
import { useOrderDetailsContext } from './OrderDetailTracking.context.graphql';
import { TextBubble } from '@orthly/dentin';
import { useMarkFulfillmentDeliveredMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { MiniWindowVariant, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Tooltip, Button, Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    layout: {
        display: 'grid',
        gap: 8,
        width: '100%',
        '& > :first-child': {
            marginBottom: 4,
        },
    },
    titleRow: {
        display: 'flex',
        flexWrap: 'nowrap',
        gap: 8,
        justifyContent: 'space-between',
        alignItems: 'baseline',
        '&>button': {
            flexShrink: 0,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

const useOpenCSTAction = () => {
    const setCSTVariant = useCSTAction('SET_WINDOW_VARIANT');

    return useOnCSTOpen(() => {
        setCSTVariant(MiniWindowVariant.Maximized, 'move');
    }, 'practice_order_detail_tracking');
};

export const OrderDetailTrackingTitle: React.VFC = () => {
    const classes = useStyles();
    const openCSTAction = useOpenCSTAction();
    const {
        title,
        titleDate,
        titleSuffix,
        titleButton,
        subtitle,
        holdMessage,
        onPause,
        order,
        refetch,
        needsAdditionalScans,
        activeScanReviewFragment,
    } = useOrderDetailsContext();

    const { notesForDoctor } = getAskDoctorV2Configuration(order, activeScanReviewFragment ?? null);
    const { invokeAttachScans, canAddNewScans } = useAddNewScans(order);

    const [submitMtn] = useMarkFulfillmentDeliveredMutation({ variables: { data: { orderId: order.id } } });
    const { submit, submitting } = useChangeSubmissionFn<any, any>(() => submitMtn(), {
        closeOnComplete: true,
        onSuccess: refetch,
    });

    const isOnHold = order.status === LabsGqlLabOrderStatus.OnHold;
    const isHoldInReview = order.hold_in_review;
    const holdVisibleToPractice = order.show_hold_status_to_practice;
    const isDelayed = order.practice_dates.is_delivery_delayed;

    return (
        <div className={classes.layout}>
            <div className={classes.titleRow}>
                <Text variant={'h5'} data-test={'tracking-title'}>
                    {title}
                    {titleDate && <span style={{ color: FlossPalette.SECONDARY_FOREGROUND }}>{titleDate}</span>}
                    {titleSuffix}
                </Text>
                {titleButton}
            </div>
            {subtitle}
            {needsAdditionalScans && (
                <span>
                    {canAddNewScans ? (
                        <Button variant={'primary'} onClick={() => invokeAttachScans({ id: order.id })}>
                            Capture new scans
                        </Button>
                    ) : (
                        <Tooltip title={'Access Chairside to capture new scans'} arrow>
                            <span>
                                <Button disabled variant={'primary'}>
                                    Capture new scans
                                </Button>
                            </span>
                        </Tooltip>
                    )}
                </span>
            )}
            {holdMessage && holdVisibleToPractice && !onPause && (
                <span>
                    <span style={{ fontFamily: 'Inter Semibold, Verdana, sans-serif' }}>Order was delayed</span>
                    {holdMessage}
                </span>
            )}
            {!holdMessage && isDelayed && !onPause && (
                <span style={{ fontFamily: 'Inter Semibold, Verdana, sans-serif' }}>Order was delayed.</span>
            )}
            {isOnHold && holdVisibleToPractice && !isHoldInReview && !needsAdditionalScans && !onPause && (
                <span
                    style={{
                        color: FlossPalette.BLACK,
                    }}
                >
                    Avoid delays by getting back to us as soon as possible.
                </span>
            )}
            {isOnHold && holdVisibleToPractice && isHoldInReview && !needsAdditionalScans && (
                <span>Your response is being reviewed by Dandy, and fabrication will proceed shortly.</span>
            )}
            {order.can_mark_delivered && (
                <span>Dandy is not receiving tracking updates from the shipping carrier.</span>
            )}
            {order.status === LabsGqlLabOrderStatus.Cancelled && (
                <span style={{ color: FlossPalette.GRAY }}>
                    <Button
                        variant={'ghost'}
                        onClick={openCSTAction}
                        style={{ margin: '-2px 2px 0 0', padding: 0, height: 'unset' }}
                    >
                        Chat with us
                    </Button>
                    or call us at 267-310-3332
                </span>
            )}
            <OrderDetailResolveIssueButton />

            {order.can_mark_delivered && (
                <span>
                    <Button variant={'secondary'} disabled={submitting} onClick={submit}>
                        Mark as delivered
                    </Button>
                </span>
            )}
            {isOnHold && !isHoldInReview && <TextBubble text={notesForDoctor} />}
        </div>
    );
};
