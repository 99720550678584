import { Icon, Link, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const LinkText = styled(Text)({
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    cursor: 'pointer',
    '& svg': {
        fontSize: 14,
    },
});

export const SwitchLoginPref: React.FC<{
    message: string;
    onClick: () => void;
}> = ({ message, onClick }) => (
    <Link onClick={onClick}>
        <LinkText bold color={'PRIMARY_FOREGROUND'} variant={'caption'}>
            {message}
            <Icon icon={'ArrowForward'} />
        </LinkText>
    </Link>
);
