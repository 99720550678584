import { useQuery } from '@apollo/client';
import type { FetchPracticeStaffUsersQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';

const FetchPracticeStaffUsers_Query = graphql(`
    query FetchPracticeStaffUsers($include_deleted: Boolean) {
        fetchPracticeStaffUsers(include_deleted: $include_deleted) {
            partner_id
            doctor_preference_id
            staff_member_id
            first_name
            last_name
            login_email
            login_phone
            preferred_name
            contact_email
            contact_phone
            is_mobile_verified
            is_active
            is_doctor
            invite_sent
            roles
            deleted_at
        }
    }
`);

export const useFetchPracticeStaffUsers = (includeDeleted: boolean) => {
    const { data, loading, refetch } = useQuery(FetchPracticeStaffUsers_Query, {
        variables: { include_deleted: includeDeleted },
    });

    return { staffUsers: data?.fetchPracticeStaffUsers, loading, refetch };
};

export type StaffUser = FetchPracticeStaffUsersQuery['fetchPracticeStaffUsers'][number];
