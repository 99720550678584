import { AddAttachmentForm } from './AddAttachmentForm';
import { AddExistingScanForm } from './AddExistingScanAction';
import { OrderAction, OrderActionTrackerIdMap } from './OrderActionsUtils';
import type { LabOrderActionProps } from './index';
import { OrderToolbar, PracticeFullScreenDialog } from '@orthly/dentin';
import type { IconName, ButtonVariant } from '@orthly/ui-primitives';
import { FlossPalette, useScreenIsMd, IconButton, Tooltip, Button, Icon } from '@orthly/ui-primitives';
import React from 'react';

const getStartIcon = ({ isMd }: { isMd: boolean }): IconName | undefined => {
    if (isMd) {
        return 'AttachFileIcon';
    }
    return 'UploadIcon';
};

export const AddAttachmentAction = (
    props: LabOrderActionProps & {
        noIcon?: boolean;
        buttonText?: string;
        buttonVariant?: ButtonVariant | 'icon';
        buttonAssetLocation?: string;
        leftAlign?: boolean;
        setToastOpen?: (toastOpen: boolean) => void;
        setScansToastOpen?: (open: boolean) => void;
        setScansErrorToastOpen?: (open: boolean) => void;
    },
) => {
    const {
        noIcon,
        order,
        buttonText,
        buttonVariant = 'ghost',
        buttonAssetLocation,
        leftAlign,
        setToastOpen,
        setScansToastOpen,
        setScansErrorToastOpen,
    } = props;
    const isMd = useScreenIsMd();
    const [open, setOpen] = React.useState(false);
    const [isAttachScansOpen, setIsAttachScansOpen] = React.useState(false);

    if (!order.can_attach_images_or_files) {
        return null;
    }

    return (
        <>
            <PracticeFullScreenDialog open={open}>
                <OrderToolbar
                    order={order}
                    onClose={() => {
                        if (isAttachScansOpen) {
                            setIsAttachScansOpen(false);
                        }
                        setOpen(false);
                    }}
                />
                {isAttachScansOpen ? (
                    <AddExistingScanForm
                        order={order}
                        setOpen={setIsAttachScansOpen}
                        setScansToastOpen={setScansToastOpen}
                        setErrorToastOpen={setScansErrorToastOpen}
                        onUploadAction={() => setIsAttachScansOpen(false)}
                    />
                ) : (
                    <AddAttachmentForm
                        order={order}
                        setOpen={setOpen}
                        setFilesAddedToastOpen={setToastOpen}
                        setScansToastOpen={setScansToastOpen}
                        setErrorToastOpen={setScansErrorToastOpen}
                        refetchMessages={props.refetch}
                        setIsAttachScansOpen={setIsAttachScansOpen}
                    />
                )}
            </PracticeFullScreenDialog>
            {buttonVariant === 'icon' ? (
                <IconButton
                    disabled={!order.can_attach_images_or_files}
                    size={'small'}
                    color={'inherit'}
                    onClick={() => setOpen(true)}
                    style={{ color: FlossPalette.STAR_GRASS, marginRight: 4, ...props.style }}
                    data-test={'open-button'}
                    data-tracker-id={OrderActionTrackerIdMap[OrderAction.UploadImageOrFile]}
                >
                    <Tooltip title={'Attach File'} arrow>
                        <Icon icon={'UploadIcon'} />
                    </Tooltip>
                </IconButton>
            ) : (
                <Button
                    disabled={!order.can_attach_images_or_files}
                    leftAlign={leftAlign}
                    variant={buttonVariant}
                    onClick={() => setOpen(true)}
                    style={{ ...props.style }}
                    data-test={'open-button'}
                    data-tracker-id={OrderActionTrackerIdMap[OrderAction.UploadImageOrFile]}
                    analytics={{
                        AssetLocation: buttonAssetLocation ?? 'Practice - Control Panel - Action Taken',
                        AssetName: 'Attach File',
                    }}
                    startIcon={noIcon ? undefined : getStartIcon({ isMd })}
                >
                    {buttonText ?? 'Upload image or file'}
                </Button>
            )}
        </>
    );
};
