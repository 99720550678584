import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionBodyFreeText } from '../TryInFeedbackOptionBodyFreeText';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

const TryInFeedbackOtherOption: React.VFC<
    TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture>
> = ({ isSelected, description, onSelected, onUnselected, onDescriptionChanged, showError }) => {
    return (
        <TryInFeedbackOptionWrapper
            title={'Other'}
            subtitle={'Provide additional details'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <TryInFeedbackOptionBodyFreeText
                label={'What else do we need to know?'}
                value={description ?? undefined}
                onChange={onDescriptionChanged}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackOtherOption: TryInFeedbackOptionDefinition<
    LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture
> = {
    sku: [LabOrderItemSKUType.Partial, LabOrderItemSKUType.Denture],
    isArchSpecific: true,
    category: 'Other',
    isEnabled: () => true,
    Component: TryInFeedbackOtherOption,
};
