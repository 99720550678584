import { useScanIsAligner } from '../../state/checkout.selectors';
import { useScanIsDenture } from '../../state/dentures-checkout.selectors';
import {
    AlignerCheckoutLayoutBottom,
    DentureCheckoutLayoutBottom,
    RegularCheckoutLayoutBottom,
} from './CheckoutLayoutBottom';
import { CheckoutTopBar } from './CheckoutTopBar';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { LoadBlocker } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, useScreenIsMobile, createStyles, makeStyles, AppBar } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentWrap: {
            position: 'relative',
            backgroundColor: FlossPalette.WHITE,
            height: 'auto',
            overflow: 'hidden',
            minHeight: '100vh',
            [theme.breakpoints.down('md')]: {
                minHeight: 'unset',
                height: '100%',
                flexDirection: 'column',
                overflow: 'scroll',
            },
        },
    }),
);

interface CheckoutLayoutProps {
    loading: boolean;
    onSubmit: () => void;
    hideBottom?: boolean;
}

export const CheckoutLayoutRoot: React.FC<CheckoutLayoutProps> = props => {
    const classes = useStyles();

    const isMobile = useScreenIsMobile();
    const isAligner = useScanIsAligner();
    const isDenture = useScanIsDenture();

    return (
        <PracticeFullScreenDialog>
            {isMobile && (
                <AppBar position={'sticky'} elevation={0}>
                    <CheckoutTopBar />
                </AppBar>
            )}
            <LoadBlocker blocking={props.loading} ContainerProps={{ className: classes.contentWrap }} loader={'dandy'}>
                {props.children}
            </LoadBlocker>
            {!props.hideBottom &&
                // eslint-disable-next-line no-nested-ternary
                (isAligner ? (
                    <AlignerCheckoutLayoutBottom onSubmit={props.onSubmit} />
                ) : isDenture ? (
                    <DentureCheckoutLayoutBottom onSubmit={props.onSubmit} />
                ) : (
                    <RegularCheckoutLayoutBottom onSubmit={props.onSubmit} />
                ))}
        </PracticeFullScreenDialog>
    );
};
