import { AlignerCheckoutStep } from '../aligners-checkout.types';
import { AlignerCaseStage } from './aligners-checkout.types';
import _ from 'lodash';

const ALIGNER_STAGE_STEPS: { [stage in AlignerCaseStage]: AlignerCheckoutStep[] } = {
    [AlignerCaseStage.InitialAligner]: [
        AlignerCheckoutStep.PatientInformation,
        AlignerCheckoutStep.ImageUploadLink,
        AlignerCheckoutStep.ImageUploadReview,
        AlignerCheckoutStep.ArchSelection,
        AlignerCheckoutStep.MovementRestrictedTeethSelection,
        AlignerCheckoutStep.AttachmentRestrictedTeethSelection,
        AlignerCheckoutStep.ExtractionTeethSelection,
        AlignerCheckoutStep.SpacingSelection,
        AlignerCheckoutStep.CrowdingResolution,
        AlignerCheckoutStep.BiteConcerns,
        AlignerCheckoutStep.Estimating,
        AlignerCheckoutStep.EstimateReview,
        AlignerCheckoutStep.Summary,
    ],
    [AlignerCaseStage.Rejection]: [AlignerCheckoutStep.PatientInformation, AlignerCheckoutStep.Rejection],
    [AlignerCaseStage.Retainer]: [
        AlignerCheckoutStep.PatientInformation,
        AlignerCheckoutStep.RetainerArchSelection,
        AlignerCheckoutStep.RetainerAdditionalInfo,
        AlignerCheckoutStep.Summary,
    ],
    [AlignerCaseStage.Refinement]: [
        AlignerCheckoutStep.PatientInformation,
        AlignerCheckoutStep.ImageUploadLink,
        AlignerCheckoutStep.ImageUploadReview,
        AlignerCheckoutStep.ArchSelection,
        AlignerCheckoutStep.MovementRestrictedTeethSelection,
        AlignerCheckoutStep.AttachmentRestrictedTeethSelection,
        AlignerCheckoutStep.ExtractionTeethSelection,
        AlignerCheckoutStep.SpacingSelection,
        AlignerCheckoutStep.CrowdingResolution,
        AlignerCheckoutStep.BiteConcerns,
        AlignerCheckoutStep.Estimating,
        AlignerCheckoutStep.EstimateReview,
        AlignerCheckoutStep.Summary,
    ],
};

export const NEXT_ALIGNER_STEP_BY_STAGE_STEP: {
    [stage in AlignerCaseStage]: { [step in AlignerCheckoutStep]?: AlignerCheckoutStep };
} = _.mapValues(ALIGNER_STAGE_STEPS, stageSteps =>
    _.fromPairs(stageSteps.map((step, index) => [step, stageSteps[index + 1]])),
);

export const PREVIOUS_ALIGNER_STEP_BY_STAGE_STEP: {
    [stage in AlignerCaseStage]: { [step in AlignerCheckoutStep]?: AlignerCheckoutStep };
} = _.mapValues(ALIGNER_STAGE_STEPS, stageSteps =>
    _.fromPairs(stageSteps.map((step, index) => [step, stageSteps[index - 1]])),
);

export const ALIGNER_STAGE_STEP_ORDER: {
    [stage in AlignerCaseStage]: { [step in AlignerCheckoutStep]?: number };
} = _.mapValues(ALIGNER_STAGE_STEPS, stageSteps => _.fromPairs(stageSteps.map((step, index) => [step, index])));
