import { stylesFactory } from '@orthly/ui-primitives';

export const useStyles = stylesFactory(theme => ({
    root: {
        maxWidth: '100vw',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        '@media print': {
            paddingLeft: '0 !important',
            overflow: 'visible',
            maxHeight: 'unset',
            height: 'auto',
        },
    },
    manufacturer: {
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: { paddingTop: 0 },
    },
    practice: {},
}));
