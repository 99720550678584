import type { TextFieldProps as MuiTextFieldProps } from '@orthly/ui-primitives';
import { TextField as MuiTextField } from '@orthly/ui-primitives';
import type { FieldProps } from 'formik';
import { getIn } from 'formik';
import React from 'react';

export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {}

export function fieldToTextField({
    disabled,
    field: { onBlur: fieldOnBlur, ...field },
    form: { isSubmitting, touched, errors },
    onBlur,
    helperText,
    ...props
}: TextFieldProps): MuiTextFieldProps {
    const fieldError = getIn(errors, field.name);
    const showError = getIn(touched, field.name) && !!fieldError;

    return {
        error: showError,
        helperText: showError ? fieldError : helperText,
        disabled: disabled ?? isSubmitting,
        onBlur:
            onBlur ??
            function (e: any) {
                fieldOnBlur(e ?? field.name);
            },
        ...field,
        ...props,
    };
}

export function TextField({ children, ...props }: TextFieldProps) {
    return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>;
}

TextField.displayName = 'FormikMaterialUITextField';
