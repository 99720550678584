import { useFirebase } from '../../context/firebase.context';
import { GetStoragePathConfig_Query } from '../GetStoragePathConfig.graphql';
import type { PhotoUploadConfig, UploadedPhotosState } from './PhotoUpload.types';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import React from 'react';
import useSWR from 'swr';

/** Hook that returns the state of the uploaded photos for the given config in Firestore. */
export function useUploadedPhotoState(
    config: PhotoUploadConfig,
    scanID?: string,
    uploadType: string = 'attachments',
): UploadedPhotosState {
    const storagePathConfig = useQuery(GetStoragePathConfig_Query, {
        variables: {
            whichConfig: 'ordering',
            uploadType,
            paths: scanID ? [scanID] : [],
        },
    });
    const firebase = useFirebase();
    const { error, data, mutate } = useSWR(
        storagePathConfig ? [`firebase-list`, storagePathConfig] : null,
        async _ => {
            if (!storagePathConfig.data) {
                // GetStoragePathConfig_Query has likely not returned yet
                return [];
            }
            const firebaseStorage = firebase.storage(storagePathConfig.data.getStoragePathConfig.bucketName);
            const { items } = await firebaseStorage.app
                .storage()
                .ref(storagePathConfig.data.getStoragePathConfig.path)
                .listAll();
            return items;
        },
        { refreshInterval: 3000, compare: _.isEqual },
    );

    const { uploadedPhotos, isUploadComplete, numUploadedPhotos } = React.useMemo(() => {
        if (data) {
            const uploadedPhotos = _.keyBy(data, item => item.name.substring(0, item.name.indexOf('.')));
            const allFilenames = config.photos.map(s => s.filename);
            const numUploadedPhotos = allFilenames.reduce((num, key) => {
                if (uploadedPhotos[key]) {
                    return num + 1;
                }
                return num;
            }, 0);
            return {
                uploadedPhotos,
                numUploadedPhotos,
                isUploadComplete: allFilenames.length === numUploadedPhotos,
            };
        }
        return {
            uploadedPhotos: {},
            numUploadedPhotos: 0,
            isUploadComplete: false,
        };
    }, [data, config.photos]);

    return { error, uploadedPhotos, isUploadComplete, numUploadedPhotos, refetch: () => mutate() };
}
