import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

const TryInFeedbackRetentionOption: React.VFC<
    TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture>
> = ({ orderItem, isSelected, description, onSelected, onUnselected, onDescriptionChanged, showError }) => {
    const isPartial = OrderItemV2Utils.itemIsType(orderItem, LabOrderItemSKUType.Partial);

    return (
        <TryInFeedbackOptionWrapper
            title={'Retention'}
            subtitle={`${isPartial ? 'Partial denture' : 'Denture'} retention is off`}
            isSelected={isSelected}
            onSelected={() => {
                onSelected();
                onDescriptionChanged('Make tighter');
            }}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <SimpleSelect
                label={'Adjust retention'}
                value={description ?? undefined}
                onChange={val => onDescriptionChanged(val || undefined)}
                options={_.compact([
                    { value: 'Make tighter', label: 'Make tighter' },
                    { value: 'Make looser', label: 'Make looser' },
                    isPartial ? { value: 'Fit is rocking', label: 'Fit is rocking' } : undefined,
                ])}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackRetentionOption: TryInFeedbackOptionDefinition<
    LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture
> = {
    sku: [LabOrderItemSKUType.Partial, LabOrderItemSKUType.Denture],
    isArchSpecific: true,
    category: 'Retention',
    isEnabled: () => true,
    Component: TryInFeedbackRetentionOption,
};
