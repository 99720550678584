import type { AlignerCheckoutStep, AlignerRejection, AlignerRetainerCheckoutState } from './aligners-checkout.types';
import type { AlignerRetainerPricingSummaryCheckout } from './aligners-checkout.types';
import type { LabsGqlOrder, LabsGqlPendingAlignerCaseFragment as PendingAlignerCase } from '@orthly/graphql-operations';
import type {
    LabsGqlAlignerTreatmentArea,
    LabsGqlPendingAlignerCaseBiteConcernsInput,
    LabsGqlPendingAlignerCaseCrowdingResolutionInput,
    LabsGqlPendingAlignerCaseEstimate,
    LabsGqlPendingAlignerCasePhotoMapInput as PendingAlignerCasePhotoMap,
} from '@orthly/graphql-schema';
import type { ToothNumber, InterproximalSpaceSize, OrderItemArch } from '@orthly/items';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';
import type { AlignerEstimateStep } from '@orthly/shared-types';

const PREFIX = 'aligner_checkout';

type CrowdingResolution = LabsGqlPendingAlignerCaseCrowdingResolutionInput;
type BiteConcerns = LabsGqlPendingAlignerCaseBiteConcernsInput;

export const AlignerCheckoutActions = {
    SET_ALIGNER_STEP: createSyncAction<AlignerCheckoutStep, [AlignerCheckoutStep]>(`${PREFIX}/SET_ALIGNER_STEP`),

    NEXT_ALIGNER_STEP: createSyncAction<undefined, []>(`${PREFIX}/NEXT_ALIGNER_STEP`),

    PREV_ALIGNER_STEP: createSyncAction<undefined, []>(`${PREFIX}/PREV_ALIGNER_STEP`),

    GO_TO_SUMMARY: createSyncAction<PendingAlignerCase, [PendingAlignerCase]>(`${PREFIX}/GO_TO_SUMMARY`),

    SET_DESKTOP_UPLOAD: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_DESKTOP_UPLOAD`),

    SET_UPLOADED_GCS_PATHS: createSyncAction<string[], [string[]]>(`${PREFIX}/SET_UPLOADED_GCS_PATHS`),

    SET_ALIGNER_PHOTOS: createSyncAction<PendingAlignerCasePhotoMap, [PendingAlignerCasePhotoMap]>(
        `${PREFIX}/SET_ALIGNER_PHOTOS`,
    ),

    SET_ALIGNER_ARCH: createSyncAction<OrderItemArch | undefined, [OrderItemArch | undefined]>(
        `${PREFIX}/SET_ALIGNER_ARCH`,
    ),

    SET_ALIGNER_TREATMENT_AREA: createSyncAction<LabsGqlAlignerTreatmentArea, [LabsGqlAlignerTreatmentArea]>(
        `${PREFIX}/SET_ALIGNER_TREATMENT_AREA`,
    ),

    SET_MOVEMENT_RESTRICTED_TEETH: createSyncAction<ToothNumber[] | null, [ToothNumber[] | null]>(
        `${PREFIX}/SET_MOVEMENT_RESTRICTED_TEETH`,
    ),

    SET_ATTACHMENT_RESTRICTED_TEETH: createSyncAction<ToothNumber[] | null, [ToothNumber[] | null]>(
        `${PREFIX}/SET_ATTACHMENT_RESTRICTED_TEETH`,
    ),

    SET_EXTRACTION_TEETH: createSyncAction<ToothNumber[] | null, [ToothNumber[] | null]>(
        `${PREFIX}/SET_EXTRACTION_TEETH`,
    ),

    SET_INTERPROXIMAL_SPACE_SIZES: createSyncAction<InterproximalSpaceSize[] | null, [InterproximalSpaceSize[] | null]>(
        `${PREFIX}/SET_INTERPROXIMAL_SPACE_SIZES`,
    ),

    SET_CROWDING_RESOLUTION: createSyncAction<CrowdingResolution, [CrowdingResolution]>(
        `${PREFIX}/SET_CROWDING_RESOLUTION`,
    ),

    SET_BITE_CONCERNS: createSyncAction<BiteConcerns, [BiteConcerns]>(`${PREFIX}/SET_BITE_CONCERNS`),

    SET_ALIGNER_NOTES: createSyncAction<string, [string]>(`${PREFIX}/SET_ALIGNER_NOTES`),

    SET_ALIGNER_ESTIMATE_STEP: createSyncAction<AlignerEstimateStep, [AlignerEstimateStep]>(
        `${PREFIX}/SET_ALIGNER_ESTIMATE_STEP`,
    ),

    SET_ALIGNER_ESTIMATE: createSyncAction<LabsGqlPendingAlignerCaseEstimate, [LabsGqlPendingAlignerCaseEstimate]>(
        `${PREFIX}/SET_ALIGNER_ESTIMATE`,
    ),

    SET_ALIGNER_REJECTION: createSyncAction<AlignerRejection, [AlignerRejection]>(`${PREFIX}/SET_ALIGNER_REJECTION`),

    SET_PATIENT_STEPS: createSyncAction<number | null, [number | null]>(`${PREFIX}/SET_PATIENT_STEPS`),

    SET_EXISTING_ORDER: createSyncAction<LabsGqlOrder | undefined>(`${PREFIX}/SET_EXISTING_ORDER`),

    SET_RETAINER: createSyncAction<AlignerRetainerCheckoutState | undefined>(`${PREFIX}/SET_RETAINER`),

    SET_RETAINER_PRICING: createSyncAction<AlignerRetainerPricingSummaryCheckout>(`${PREFIX}/SET_RETAINER_PRICING`),

    SET_REFINEMENT: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_REFINEMENT`),
};

export const useAlignerCheckoutAction = generateUseActionHook<typeof AlignerCheckoutActions>(AlignerCheckoutActions);
