import type { PracticeInvoiceDetail } from '../../invoicing.types';
import { useInvoiceDetailStatus } from '../../invoicing.utils';
import { formatDate } from '../PrintableInvoice';
import { styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const OverviewContainer = styled('div')({
    marginBottom: '24px',
});

const BlockText = styled(Text)({
    display: 'inline-block',
    width: '120px',
    fontWeight: 600,
});

const ValueText = styled(Text)({
    fontWeight: 600,
});

const OverviewItem: React.FC<{ item: string; value: string }> = ({ item, value }) => (
    <div>
        <BlockText variant={'caption'} color={'GRAY'}>
            {item}
        </BlockText>
        <ValueText variant={'caption'} medium color={'GRAY'}>
            {value}
        </ValueText>
    </div>
);

interface PrintableInvoiceOverviewProps {
    invoice: PracticeInvoiceDetail;
    autochargeEnabled: boolean;
}

export const PrintableInvoiceOverview: React.FC<PrintableInvoiceOverviewProps> = ({ invoice, autochargeEnabled }) => {
    const status = useInvoiceDetailStatus(invoice, autochargeEnabled);
    return (
        <OverviewContainer>
            <OverviewItem item={'Invoice number'} value={`#${invoice.invoice_number}`} />
            <OverviewItem item={'Date of issue'} value={formatDate(invoice.created_at)} />
            <OverviewItem item={'Date due'} value={formatDate(invoice.due_date)} />
            <OverviewItem item={'Status'} value={status} />
        </OverviewContainer>
    );
};
