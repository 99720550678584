import DesktopUpload from '../../../assets/images/checkout/desktop-upload.png';
import QRScan from '../../../assets/images/checkout/qr-scan.png';
import type { ImpersonatedQRCodeLinkResult } from '../QRCode';
import { QRCode } from '../QRCode';
import { ValidationUtils } from '@orthly/runtime-utils';
import { SimpleInput, LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    Button,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Text,
    Link,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        height: '100%',
        alignContent: 'flex-start',
        position: 'relative',
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        overflow: 'hidden',
        padding: 24,
        flexDirection: 'column',
        display: 'flex',
    },
    subtitle: { marginTop: 8 },
    handQrScanImage: { width: '55%', position: 'absolute', right: -60, top: 100 },
    getSmsText: { fontWeight: 500, width: '100%', height: 'fit-content', marginTop: 12, color: FlossPalette.GRAY },
    sendCTA: { marginTop: 16, alignItems: 'center' },
    sendButton: {
        marginLeft: 8,
        height: '75%',
    },
    desktopImage: { flexGrow: 1, justifyContent: 'center', alignItems: 'flex-end' },
    btnDesktopUpload: { marginTop: 16, backgroundColor: FlossPalette.WHITE, width: '65%' },
}));

interface SendImageUploadLinkTextCTAProps {
    onSubmit: (phoneNumber: string) => void;
    placeholderPhoneNumber?: string;
    submitting?: boolean;
}

const SendImageUploadLinkTextCTA: React.FC<SendImageUploadLinkTextCTAProps> = ({
    onSubmit,
    placeholderPhoneNumber,
    submitting,
}) => {
    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>();

    React.useEffect(() => {
        if (!phoneNumber && placeholderPhoneNumber) {
            setPhoneNumber(placeholderPhoneNumber);
        }
    }, [placeholderPhoneNumber, phoneNumber]);

    const isNumber = ValidationUtils.isPhone(phoneNumber) && !!phoneNumber && phoneNumber?.length >= 10;

    return (
        <LoadBlocker blocking={!!submitting} ContainerProps={{ className: classes.sendCTA }}>
            <SimpleInput
                onChange={val => setPhoneNumber(val ?? undefined)}
                value={phoneNumber}
                label={'Phone Number'}
                TextFieldProps={{
                    autoFocus: true,
                    type: 'tel',
                    error: !isNumber,
                    helperText: !isNumber ? 'Please enter a valid phone number' : undefined,
                }}
            />
            <Button
                variant={'primary'}
                onClick={() => phoneNumber && onSubmit(phoneNumber)}
                disabled={!isNumber}
                className={classes.sendButton}
            >
                Send
            </Button>
        </LoadBlocker>
    );
};

interface PhotoUploadTypeSelectProps {
    onDesktopUpload(): void;
    qrCode: ImpersonatedQRCodeLinkResult;
    sendTextCTAProps?: SendImageUploadLinkTextCTAProps;
}

export const PhotoUploadTypeSelect: React.VFC<PhotoUploadTypeSelectProps> = ({
    onDesktopUpload,
    qrCode,
    sendTextCTAProps,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const [showTextInput, setShowTextInput] = React.useState(false);

    return (
        <Grid container>
            {!isMobile && (
                <Grid container item xs={7} className={classes.root}>
                    <img src={QRScan} alt={'Phone scanning QR code'} className={classes.handQrScanImage} />
                    <Text variant={'h6'}>Using your phone?</Text>
                    <Text variant={'body2'} className={classes.subtitle}>
                        Scan the code below
                    </Text>

                    <QRCode
                        qrData={qrCode.qrData}
                        error={qrCode.error}
                        errorText={'Please try using our texting service below, or the desktop uploader.'}
                    />

                    {sendTextCTAProps && (
                        <Text variant={'body2'} className={classes.getSmsText}>
                            Not working?{' '}
                            <Link
                                onClick={() => setShowTextInput(true)}
                                style={{ color: FlossPalette.STAR_GRASS, cursor: 'pointer' }}
                            >
                                Get a text instead
                            </Link>
                        </Text>
                    )}

                    {sendTextCTAProps && showTextInput && <SendImageUploadLinkTextCTA {...sendTextCTAProps} />}
                </Grid>
            )}
            <Grid item className={classes.root} style={{ marginLeft: isMobile ? 0 : 24, flexGrow: 1 }}>
                <Text variant={'h6'}>Using your {isMobile ? `phone` : 'laptop'}?</Text>
                <Text variant={'body2'} className={classes.subtitle}>
                    Hit the button below
                </Text>
                <Button variant={'secondary'} onClick={onDesktopUpload} className={classes.btnDesktopUpload}>
                    Upload using {isMobile ? `phone` : 'laptop'}
                </Button>
                <Grid container className={classes.desktopImage} style={{ marginTop: isMobile ? 12 : undefined }}>
                    <img src={isMobile ? QRScan : DesktopUpload} alt={''} />
                </Grid>
            </Grid>
        </Grid>
    );
};
