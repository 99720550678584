import { ToolbarContainer, TOOLBAR_TABLET_DOWN_HEIGHT, formatDataTestTitle } from '@orthly/ui';
import { Grid, Text, styled } from '@orthly/ui-primitives';

const Toolbar = styled(ToolbarContainer)(({ theme }) => ({
    zIndex: 10,
    flexDirection: 'column',
    width: '100%',
    '&&': {
        height: 'auto',
        minHeight: 'auto',
        padding: 0,
    },
    [theme.breakpoints.down('lg')]: {
        marginTop: TOOLBAR_TABLET_DOWN_HEIGHT,
        top: TOOLBAR_TABLET_DOWN_HEIGHT,
        height: 64,
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        top: 0,
        paddingLeft: 16,
        paddingRight: 16,
    },
}));

const Title = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8px 24px',
});

type Props = {
    style?: React.CSSProperties;
    titleIcon?: React.ReactNode;
    title: React.ReactNode;
    titleAction?: React.ReactNode;
    subtitle?: React.ReactNode;
    subtitleStyle?: React.CSSProperties;
};

export const PracticeSettingsTitle: React.FC<Props> = props => {
    const { title, titleIcon, titleAction, style, subtitle, subtitleStyle } = props;

    return (
        <Toolbar sticky disableMobileSticky style={style}>
            <Title>
                <Grid container wrap={'nowrap'} alignItems={'center'} style={{ width: 'unset' }}>
                    {titleIcon}
                    <Text
                        data-test={`practice-settings-title-${formatDataTestTitle(title?.toString() || '')}`}
                        variant={'h5'}
                    >
                        {title}
                    </Text>
                </Grid>
                {titleAction}
            </Title>
            {subtitle && <div style={subtitleStyle}>{subtitle}</div>}
        </Toolbar>
    );
};
