import { FlossPalette, createStyles, makeStyles } from '@orthly/ui-primitives';

export const useMobileMoreStyles = makeStyles(() =>
    createStyles({
        root: {
            backgroundColor: FlossPalette.TAN,
            padding: 16,
        },
    }),
);

export const useStyles = makeStyles(() =>
    createStyles({
        doctorBadgeIcon: {
            color: FlossPalette.STAR_GRASS,
            height: 16,
            width: 16,
            marginRight: 4,
        },
        doctorSince: {
            fontSize: 12,
        },
        profileName: {
            fontWeight: 500,
            fontSize: 20,
        },
        profileRowWrapper: {
            marginBottom: 16,
            paddingBottom: 16,
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        },
        staffWrapper: {
            marginRight: 16,
        },
    }),
);
