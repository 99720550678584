import { useOrderAction } from '../../redux/orders/orders.actions';
import { getPartnerId } from '../../utils/authorization';
import { useApolloClient } from '@apollo/client';
import { useAsyncIsLoadingOrDone } from '@orthly/redux-async-actions';
import { useSession } from '@orthly/session-client';
import React from 'react';

interface LabsContextType {
    userRole: 'practitioner';
    partnerId?: string;
}

const LabsContext = React.createContext<LabsContextType>({ userRole: 'practitioner' });

export const LabsContextProvider: React.FC = props => {
    const session = useSession();
    const client = useApolloClient();
    const loadEvents = useOrderAction('LOAD_READ_EVENTS');
    const eventsLoaded = useAsyncIsLoadingOrDone(loadEvents.NAME);
    React.useEffect(() => {
        if (!eventsLoaded && session) {
            loadEvents({ client, userId: session.id });
        }
    }, [eventsLoaded, client, loadEvents, session]);
    const partnerId = getPartnerId(session);

    if (!session) {
        return null;
    }
    const contextVal: LabsContextType = { partnerId, userRole: 'practitioner' };
    return <LabsContext.Provider value={contextVal}>{props.children}</LabsContext.Provider>;
};
LabsContextProvider.displayName = 'LabsContextProvider';

export function useLabsContext() {
    return React.useContext(LabsContext);
}
