import { HeadlineItem, PracticeSidebarRight } from '../../../components/PracticeSidebarRight';
import { usePracticeStaffControls, PracticeEmployeeType } from '../../account/state/usePracticeStaffControls';
import { useNextInvoicingDateDetails } from '../../invoices/invoicing.utils';
import { useInboxUpsellDismissedSKUs } from './upsell/InboxUpsellRoot';
import { useGetUpsellConfigToShow } from './upsell/utils';
import { useQuery } from '@apollo/client';
import type { CtaAnalyticsEventData } from '@orthly/analytics/dist/browser';
import { useAnalyticsOnClick, useAnalyticsTrackCtaShown } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import { useGetOrgQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { LaunchDarklyFlagSchema } from '@orthly/shared-types';
import { ToolbarContainer } from '@orthly/ui';
import { Button, FlossPalette, Grid, Text, createStyles, makeStyles } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import cx from 'classnames';
import dayjs from 'dayjs';
import React from 'react';
import { z } from 'zod';

const useStyles = makeStyles(() =>
    createStyles({
        referralSection: {
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            paddingTop: 16,
            paddingBottom: 24,
            alignItems: 'center',
        },
    }),
);

const TopItemStyle: React.CSSProperties = {
    backgroundColor: 'unset',
    paddingRight: 0,
    paddingLeft: 0,
};

const NpsStyle: React.CSSProperties = {
    maxWidth: '100%',
    paddingTop: '56.25%',
    position: 'relative',
    width: '100%',
    height: 679,
    backgroundColor: 'unset',
    paddingRight: 0,
    paddingLeft: 0,
    display: 'flex',
    justifyContent: 'center',
};

const NpsFrameStyle: React.CSSProperties = {
    border: 0,
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
};

const GetPracticeActivationDate_Query = graphql(`
    query GetPracticeActivationDate($organizationId: String!) {
        getPartnerBillingAccount(partner_id: $organizationId) {
            activation_date
        }
    }
`);

const NextInvoiceDateItem: React.FC = () => {
    const organizationId = useSession()?.organization_id ?? '';
    const { data: { getPartnerBillingAccount: partnerBillingAccount } = {} } = useQuery(
        GetPracticeActivationDate_Query,
        {
            variables: { organizationId },
            skip: !organizationId,
        },
    );
    const { nextInvoiceGeneration } = useNextInvoicingDateDetails();
    const nextInvoiceMsg = React.useMemo(() => {
        const days = nextInvoiceGeneration.endOf('day').diff(dayjs().utc(), 'days');
        return `In ${days} days`;
    }, [nextInvoiceGeneration]);
    if (!partnerBillingAccount?.activation_date) {
        return null;
    }
    return (
        <ToolbarContainer style={{ ...TopItemStyle, borderBottom: 'none' }}>
            <HeadlineItem title={'Next invoice'} value={nextInvoiceMsg} data-test={'next-invoice-date-sidebar'} />
        </ToolbarContainer>
    );
};

type ReferralCopySchema = z.infer<typeof LaunchDarklyFlagSchema.shape.homeReferralCopy>;

const getReferralAnalyticsData = (referralCopy: ReferralCopySchema | null): CtaAnalyticsEventData => {
    return {
        AssetCTAText: referralCopy ? referralCopy.ctaText : 'Refer a practice',
        AssetName: referralCopy?.assetName ? referralCopy.assetName : 'Referral',
        AssetType: 'button',
        AssetVersion: 'v1.0',
        AssetLocation: 'Home page',
    };
};

const ReferralSection: React.VFC<{ referralCopy: ReferralCopySchema }> = ({ referralCopy }) => {
    const classes = useStyles();
    const referralAnalyticsData = getReferralAnalyticsData(referralCopy);
    useAnalyticsTrackCtaShown(referralAnalyticsData, !!referralCopy);
    const onClickRefer = useAnalyticsOnClick('Practice - Referral Page Opened', referralAnalyticsData, () => {
        referralCopy && window.open(referralCopy.ctaUrl, '_blank');
    });
    return (
        <Grid container className={cx(classes.referralSection)}>
            <Grid container style={{ paddingBottom: 16 }}>
                <HeadlineItem title={referralCopy.title} />
            </Grid>
            <Grid container>
                <Text style={{ width: '100%' }} variant={'body1'}>
                    {referralCopy.description}
                </Text>
            </Grid>
            <Grid container style={{ paddingTop: 16 }}>
                <Button variant={'secondary'} onClick={onClickRefer}>
                    {referralCopy.ctaText}
                </Button>
            </Grid>
        </Grid>
    );
};

const isCopyValid = (copy: ReferralCopySchema) =>
    copy.title && copy.description && copy.ctaText && z.string().url().safeParse(copy.ctaUrl).success;

const ReferralSectionLoader: React.VFC = () => {
    // we do not want to show the referral section if we are showing a marketing banner
    const [inboxUpsellDismissedSKUs] = useInboxUpsellDismissedSKUs();
    const upsellConfig = useGetUpsellConfigToShow(inboxUpsellDismissedSKUs);

    const referralCopy = useFeatureFlag('homeReferralCopy').value;
    if (!referralCopy || !isCopyValid(referralCopy) || upsellConfig) {
        return null;
    }
    return <ReferralSection referralCopy={referralCopy} />;
};

interface NpsSurveyProps {
    setShowSurvey: (show: Date) => void;
    userId: string;
    userEmail: string;
    userSalesforceContactId: string;
    orgId: string;
    orgName: string;
}

const NpsSurvey: React.VFC<NpsSurveyProps> = props => {
    const { setShowSurvey, userId, userEmail, userSalesforceContactId, orgId, orgName } = props;

    const surveyID = 'eme96shh?';

    const dismissSurvey = () => {
        const itemFromStorage = localStorage.getItem('npsSurvey');
        const parsedStorage = JSON.parse(itemFromStorage || '{}');
        parsedStorage[userId] = new Date();
        setShowSurvey(new Date());
        localStorage.setItem('npsSurvey', JSON.stringify(parsedStorage));
    };

    const surveyIFrame = document.getElementById('nps-survey-iframe') as HTMLIFrameElement | null;
    const childWindow = surveyIFrame?.contentWindow;
    window.addEventListener('message', message => {
        if (message.source === childWindow && message?.data?.includes?.('submittedResponse') && userId) {
            dismissSurvey();
        }
    });

    const url = `https://www.getfeedback.com/e/${surveyID}&User_ID=${userId}&Email=${userEmail}&Contact_ID=${userSalesforceContactId}&Organization_ID=${orgId}&Organization_Name=${orgName}&gf_hide_progress`;
    const text = 'Not now, ask me again later';

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <ToolbarContainer style={NpsStyle}>
                <iframe id={'nps-survey-iframe'} src={url} style={NpsFrameStyle} />
            </ToolbarContainer>
            <Button
                variant={'ghost'}
                onClick={() => dismissSurvey()}
                analytics={{
                    AssetLocation: 'Practice Portal',
                    AssetName: 'NPS Survey Skip Button',
                    AssetCTAText: text,
                }}
            >
                {text}
            </Button>
        </div>
    );
};

const PracticeActiveOrderCountInbox_Query = graphql(`
    query PracticeActiveOrderCountInbox($practiceId: String!, $doctorId: String) {
        practiceOrderCountsOverview(practiceId: $practiceId, doctorId: $doctorId) {
            InFlightOrders
        }
    }
`);

function useActiveOrderCount(): { activeOrderCount: number | null; loading: boolean } {
    const practiceId = useSession()?.organization_id ?? '';
    const controls = usePracticeStaffControls();
    const doctorId = controls?.type === PracticeEmployeeType.Doctor ? controls.employeePrefs.id : null;
    const { data, loading } = useQuery(PracticeActiveOrderCountInbox_Query, {
        variables: { practiceId, doctorId },
    });
    return { activeOrderCount: data?.practiceOrderCountsOverview.InFlightOrders ?? null, loading };
}

const InboxSidebarRightTopItems: React.FC = () => {
    const { loading, activeOrderCount } = useActiveOrderCount();
    return (
        <>
            <ToolbarContainer style={TopItemStyle}>
                <HeadlineItem title={'Active orders'} value={loading ? '...' : `${activeOrderCount ?? 0}`} />
            </ToolbarContainer>
            <NextInvoiceDateItem />
        </>
    );
};

const InboxSidebarRight: React.FC = () => {
    return <PracticeSidebarRight topItems={<InboxSidebarRightTopItems />} bottomItems={<ReferralSectionLoader />} />;
};

const userNeedsToTakeSurvey = (userId: string) => {
    const itemFromStorage = localStorage.getItem('npsSurvey');
    const parsedStorage = JSON.parse(itemFromStorage || '{}');
    return (
        (userId && !(userId in parsedStorage)) ||
        (userId && dayjs(parsedStorage[userId]).isBefore(dayjs().subtract(60, 'days')))
    );
};

const orgOlderThan60Days = (orgCreatedAt?: string) => {
    return orgCreatedAt && dayjs(orgCreatedAt).isBefore(dayjs().subtract(60, 'days'));
};

// half the surveys are sent from salesforce, the other half are shown in practice portal
// this function determines which half the user should see in practice portal
// if the last number of the user uuid is odd, or in the first six letters of the alphabet, they should see the survey
// uuids lettings only go up to f, six in total so we are using the first three
const userShouldSeeSurvey = (userId: string) => {
    const lastItem = userId.slice(-1);
    const int = parseInt(lastItem);
    if (int) {
        return int % 2 !== 0;
    } else {
        const firstHalfOfAlphabet = ['a', 'b', 'c'];
        return lastItem && firstHalfOfAlphabet.includes(lastItem.toLowerCase());
    }
};

export const InboxSidebarRightWrapper: React.FC = () => {
    // showSurvey is not used, but we setShowSurvey to force a re-render when the survey is submitted
    const [, setShowSurvey] = React.useState(new Date());
    const { value: showNpsSurvey } = useFeatureFlag('showNpsSurvey');

    const session = useSession();
    const userId = session?.user.id || '';
    const userEmail = session?.user?.email || '';
    const userSalesforceContactId = session?.user?.salesforce_contact_id || '';
    const orgId = session?.organization_id || '';

    const { data } = useGetOrgQuery({
        variables: { id: orgId || '' },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onError: console.error,
    });
    const orgCreatedAt = data?.getOrganization?.created_at;
    const orgName = data?.getOrganization?.legal_name || '';

    if (
        showNpsSurvey &&
        orgOlderThan60Days(orgCreatedAt) &&
        userNeedsToTakeSurvey(userId) &&
        userShouldSeeSurvey(userId)
    ) {
        return (
            <NpsSurvey
                userId={userId}
                userEmail={userEmail}
                userSalesforceContactId={userSalesforceContactId}
                setShowSurvey={setShowSurvey}
                orgId={orgId}
                orgName={orgName}
            />
        );
    } else {
        return <InboxSidebarRight />;
    }
};
