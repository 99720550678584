/* eslint-disable import/no-extraneous-dependencies */
import { PICKER_INPUT_ROOT_PADDING } from './SimplePickerStyles';
import type { DesktopDateTimePickerProps } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDateTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// This is needed for the DatePicker generics to work.
import type {} from '@mui/x-date-pickers/AdapterDayjs';
import { DATE_PICKER_CLASSNAME, FlossPalette } from '@orthly/ui-primitives';
import { FormFieldInputWrapper } from '@orthly/ui-primitives';
import cx from 'clsx';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React from 'react';

export type SimpleDateTimePickProps = Omit<
    DesktopDateTimePickerProps<Dayjs>,
    'value' | 'onChange' | 'minDateTime' | 'maxDateTime'
> & {
    value: Date | null;
    onChange: (date: Date | null) => void;
    helperText?: string;
    errorText?: string;
    label?: string;
    minDateTime?: Date | null;
    maxDateTime?: Date | null;
    fullWidth?: boolean;
    legacyInputLayout?: boolean;
};

export const SimpleDateTimePicker = ({
    label,
    errorText,
    helperText,
    minDate,
    maxDate,
    sx,
    fullWidth,
    onChange,
    slotProps,
    legacyInputLayout = true,
    ...rest
}: SimpleDateTimePickProps) => {
    const [dateTime, setDateTime] = React.useState<Dayjs | null>(rest.value ? dayjs(rest.value) : null);

    if (legacyInputLayout) {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                    onClose={() => onChange?.(dateTime ? dateTime.toDate() : null)}
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    ampm={true}
                    value={dateTime}
                    onChange={(date, _context) => {
                        setDateTime(date);
                    }}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        width: fullWidth ? '100%' : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                        ...sx,
                    }}
                    minDate={minDate ? dayjs(minDate) : undefined}
                    maxDate={maxDate ? dayjs(maxDate) : undefined}
                    slotProps={{
                        ...slotProps,
                        toolbar: {
                            hidden: true,
                            ...slotProps?.toolbar,
                        },
                        layout: {
                            ...slotProps?.layout,
                            sx: {
                                '& .MuiPickersDay-today': {
                                    backgroundColor: FlossPalette.WHITE,
                                    borderColor: FlossPalette.BLACK,
                                },
                                '& .MuiPickersDay-root.Mui-selected': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                    color: FlossPalette.WHITE,
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClock-pin': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClockPointer-root': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiPickersClockPointer-thumb': {
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                ...slotProps?.layout?.sx,
                            },
                        },
                        textField: {
                            helperText: errorText || helperText,
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...slotProps?.textField,
                        },
                    }}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                    // Everything before this should be duplicated across fields.
                    label={label}
                />
            </LocalizationProvider>
        );
    }

    return (
        <FormFieldInputWrapper label={label || ''} errorText={errorText} helperText={helperText} helpTextBottom={true}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDateTimePicker
                    onClose={() => onChange?.(dateTime ? dateTime.toDate() : null)}
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    ampm={true}
                    value={dateTime}
                    onChange={(date, _context) => {
                        setDateTime(date);
                    }}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        width: fullWidth ? '100%' : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                        ...sx,
                    }}
                    minDate={minDate ? dayjs(minDate) : undefined}
                    maxDate={maxDate ? dayjs(maxDate) : undefined}
                    slotProps={{
                        ...slotProps,
                        toolbar: {
                            hidden: true,
                            ...slotProps?.toolbar,
                        },
                        layout: {
                            ...slotProps?.layout,
                            sx: {
                                '& .MuiPickersDay-today': {
                                    backgroundColor: FlossPalette.WHITE,
                                    borderColor: FlossPalette.BLACK,
                                },
                                '& .MuiPickersDay-root.Mui-selected': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                    color: FlossPalette.WHITE,
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClock-pin': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiClockPointer-root': {
                                    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                '& .MuiPickersClockPointer-thumb': {
                                    borderColor: FlossPalette.PRIMARY_FOREGROUND,
                                },
                                ...slotProps?.layout?.sx,
                            },
                        },
                        textField: {
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...slotProps?.textField,
                        },
                    }}
                    slots={{
                        ...rest.slots,
                    }}
                    viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                    }}
                />
            </LocalizationProvider>
        </FormFieldInputWrapper>
    );
};
