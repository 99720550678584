import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

export const FOOTER_HEIGHT = 48;

const Footer = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '24px',
    width: 'calc(100% - 48px)',
    height: `${FOOTER_HEIGHT}px`,

    position: 'fixed',
    bottom: '8px',
    left: '24px',
    right: '24px',
    borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    lineHeight: '32px',
});

const FooterText = styled('span')({
    fontSize: '10px',
    color: FlossPalette.GRAY,
    fontWeight: 600,
});

interface PrintableInvoiceFooterProps {
    invoiceFooterText?: string;
}

export const PrintableInvoiceFooter: React.FC<PrintableInvoiceFooterProps> = ({ invoiceFooterText }) => (
    <Footer>
        <FooterText>{invoiceFooterText ? `Invoice #${invoiceFooterText}` : ''}</FooterText>
    </Footer>
);
