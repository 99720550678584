import { SettingsSubNav } from '../components/SettingsSubNav';
import { PracticeScreen } from '@orthly/dentin';
import { useLabPaymentMethodsQuery } from '@orthly/graphql-react';
import type { SubNavigationEntry } from '@orthly/ui';
import React from 'react';

export const PracticeSettingsSubNav: React.FC = () => {
    const { data: paymentMethodsData, loading: cardsLoading } = useLabPaymentMethodsQuery();
    const showAlert = !cardsLoading && paymentMethodsData?.getLabsPaymentMethods.length === 0;
    const mainScreenSections = React.useMemo<SubNavigationEntry<string>[]>(() => {
        const sections = [
            { value: 'staff', label: 'Staff' },
            { value: 'pricing', label: 'Pricing guide' },
            { value: 'settings', label: 'Practice settings', alertCount: showAlert ? 1 : 0 },
            { value: 'billing', label: 'Billing' },
        ];
        return sections;
    }, [showAlert]);

    return (
        <SettingsSubNav
            sections={[
                {
                    subtitle: 'PRACTICE SETTINGS',
                    entries: mainScreenSections,
                },
            ]}
            title={'Practice'}
            practiceScreen={PracticeScreen.my_practice}
            selector={'practiceSettings'}
        />
    );
};
