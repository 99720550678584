// This file includes several useEffect calls that we need to ensure
// won't run more times than intended, so their dependency arrays are
// deliberately tailored

/* eslint-disable react-hooks/exhaustive-deps */
import { AnalyticsClient } from '../../analytics/analyticsClient';
import { selectShouldShowSelectStaff, useSelectStaffSelector } from '../select-staff/state/select-staff.selectors';
import { CST_FRAME_ROUTE_NAME } from './chat-routes.constant';
import { CHAT_FRAME_ROUTE_NAME, KUSTOMER_FRAME_ROUTE_NAME } from './chat-routes.constant';
import { useChatAction } from './chat-state/chat.actions';
import { useChatSelector } from './chat-state/chat.selectors';
import { PracticeScreen } from '@orthly/dentin';
import { useZendeskUserQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { StaffMemberSession } from '@orthly/session-client';
import { OrthlyBrowserConfig, OrthlyFrontendApps, QueryString } from '@orthly/ui';
import { useFeatureFlag } from '@orthly/veneer';
import * as Sentry from '@sentry/react';
import { compact } from 'lodash';
import React from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import Smooch from 'smooch';

type SmoochInitOptions = {
    integrationId: string;
    canUserSeeConversationList: boolean;
    businessIconUrl: string;
    externalId?: string;
    jwt?: string;
    embedded: boolean;
    customColors: {
        brandColor: string;
        conversationColor: string;
        actionColor: string;
    };
};

const DANDY_ICON =
    'https://cdn.meetdandy.com/frontends/practice/master/static/media/DandyFaviconDarkBlack.1a5ffc27.svg';

const BASE_OPTIONS: SmoochInitOptions = {
    integrationId: OrthlyBrowserConfig.sunshineConversations.integrationId,
    canUserSeeConversationList: false,
    businessIconUrl: DANDY_ICON,
    embedded: false,
    customColors: {
        brandColor: 'eceae6',
        conversationColor: '158915',
        actionColor: '158915',
    },
};

const DEV_CUSTOM_FIELD_IDS = {
    page_url: '17894835747213',
    partner_id: '17895013778189',
    three_shape_order_id_automated: '24152449853453',
    laptop_serial_id: '28675452909581',
};

const PROD_CUSTOM_FIELD_IDS = {
    page_url: '17918102652301',
    partner_id: '17918045656077',
    three_shape_order_id_automated: '24152261247373',
    laptop_serial_id: '28675471483533',
};

const config = new OrthlyBrowserConfig(OrthlyFrontendApps.practice);
if (!config.isProduction) {
    (window as any).Smooch = Smooch;
}

const CUSTOM_FIELDS = config.isProduction ? PROD_CUSTOM_FIELD_IDS : DEV_CUSTOM_FIELD_IDS;

interface Props {
    autoOpen: boolean;
    embedded?: boolean;
    portal?: HTMLElement | null;
    shouldInit?: boolean;
}

let suncoInitialized: boolean = false;

function pathOrderID(): string | undefined {
    const match = matchPath<{ orderId?: string }>(window.location.pathname, {
        path: `/${PracticeScreen.orders}/:orderId`,
        exact: true,
    });
    return match?.params?.orderId;
}

export async function openZDChat(): Promise<void> {
    if (!suncoInitialized) {
        console.warn('Zendesk is not initialized yet');
        AnalyticsClient.track('Practice - Zendesk Chat - Attempted Close Before Initialization', {});
        return;
    }

    AnalyticsClient.track('Practice - Zendesk Chat - Opened', { $groups: { order: pathOrderID() } });
    showSuncoBubble();
    Smooch.open();
}

export function closeZDChat(): void {
    if (!suncoInitialized) {
        console.warn('Zendesk is not initialized yet');
        AnalyticsClient.track('Practice - Zendesk Chat - Attempted Close Before Initialization', {});
        Smooch.close();
        return;
    }

    AnalyticsClient.track('Practice - Zendesk Chat - Closed', {});
    // setTimeout for a smoother transition
    setTimeout(() => {
        AnalyticsClient.track('Practice - Zendesk Chat - Closed', {});
        hideSuncoBubble();
    }, 400);
}

function showSuncoBubble(): void {
    AnalyticsClient.track('Practice - Zendesk Chat - Bubble Shown', {});
    const suncoContainer = document.getElementById('web-messenger-container');
    if (suncoContainer) {
        suncoContainer.style.display = 'block';
        suncoContainer.style.visibility = 'visible';

        const suncoLogo = (suncoContainer as HTMLIFrameElement)?.contentDocument?.querySelector('div#wrapper div.logo');
        if (suncoLogo) {
            const asElement = suncoLogo as HTMLElement;
            if (asElement) {
                asElement.style.display = 'none';
            }
        }
    }
}

function hideSuncoBubble(): void {
    AnalyticsClient.track('Practice - Zendesk Chat - Bubble Hidden', {});
    const suncoContainer = document.getElementById('web-messenger-container');
    if (suncoContainer) {
        suncoContainer.style.visibility = 'hidden';
        suncoContainer.style.display = 'none';
    }
}

function getSuncoMetadata(
    session: StaffMemberSession | undefined,
    enableAda: boolean,
    threeShapeOrderId: string,
    laptopSerial: string,
): { [key: string]: string } {
    const first_name = session?.user?.first_name;
    const last_name = session?.user?.last_name;
    return {
        [`zen:ticket_field:${CUSTOM_FIELDS.page_url}`]: window?.location?.href ?? '',
        [`zen:ticket_field:${CUSTOM_FIELDS.partner_id}`]: session?.organization_id ?? '',
        [`zen:ticket_field:${CUSTOM_FIELDS.three_shape_order_id_automated}`]: threeShapeOrderId,
        [`zen:ticket_field:${CUSTOM_FIELDS.laptop_serial_id}`]: laptopSerial,
        'zen:ticket:tags': JSON.stringify(['sunco_widget']),
        name: compact([first_name, last_name]).join(' '),
        email: session?.user?.email ?? '',
        enableAda: enableAda ? 'true' : 'false',
    };
}

function useInPortal(): boolean {
    const history = useHistory();
    const pathname = history.location.pathname;
    return ![CHAT_FRAME_ROUTE_NAME, CST_FRAME_ROUTE_NAME, KUSTOMER_FRAME_ROUTE_NAME].includes(
        pathname.replaceAll('/', ''),
    );
}

function useGetChairsideMetadata(): { threeshapeOrderId: string; laptopSerial: string } {
    const history = useHistory();
    const searchParams = QueryString.parse(history.location.search);
    const threeshapeOrderId = !!searchParams.liveScanReviewOrder ? searchParams.liveScanReviewOrder : '';
    const laptopSerial = !!searchParams.laptopSerial ? searchParams.laptopSerial : '';

    return {
        threeshapeOrderId,
        laptopSerial,
    };
}

// useZendeskChat includes and configures the Zendesk chat widget.
// eslint-disable-next-line max-lines-per-function
export const useZendeskChat = ({ autoOpen, embedded, portal, shouldInit = true }: Props) => {
    const zdOpen = useChatSelector(({ zendeskOpen }) => zendeskOpen);
    const unreadCount = useChatSelector(({ zendeskUnread }) => zendeskUnread);

    const inPortal = useInPortal();
    const showStaffBackground = useSelectStaffSelector(selectShouldShowSelectStaff);
    const session = useSession();

    const { value: chatAvailability } = useFeatureFlag('chatAvailability');
    const enableAda = !!useFeatureFlag('enableAda').value;

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const openChatAction = useChatAction('OPEN_ZD_CHAT');
    const setOpenAction = useChatAction('SET_ZD_CHAT_OPEN');
    const setCountAction = useChatAction('SET_ZD_CHAT_UNREAD');
    const setZDChatLoaded = useChatAction('SET_ZD_CHAT_LOADED');

    const [initialJWTTime, setInitialJWTTime] = React.useState<number | null>(null);
    const { data, refetch, loading: authLoading } = useZendeskUserQuery({ skip: !shouldInit });
    const zendeskUser = data?.zendeskUser;
    const userAuthJWT = zendeskUser?.token;
    const expiresAt = zendeskUser?.expires_at;
    const externalID = zendeskUser?.external_id;
    const { laptopSerial, threeshapeOrderId } = useGetChairsideMetadata();

    React.useEffect(() => {
        if (!loaded || !shouldInit) {
            return;
        }

        if (autoOpen) {
            AnalyticsClient.track('Practice - Zendesk Chat - Auto-Opened', {});
            openChatAction(chatAvailability);
        }
    }, [loaded, autoOpen, shouldInit]);

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useEffect(() => {
        // Initialize the Sunco widget.  Declaring this as a separate
        // async function so we can call it from the non-async callback
        async function init(): Promise<void> {
            const suncoContainer = document.getElementById('web-messenger-container');

            if (loaded || suncoContainer || authLoading || !shouldInit || !zendeskUser) {
                return;
            }

            Smooch.on('ready', () => {
                hideSuncoBubble();
                suncoInitialized = true;
                setLoaded(true);
                setZDChatLoaded();
            });

            Smooch.on('widget:opened', () => {
                setOpenAction(true);
            });

            Smooch.on('widget:closed', () => {
                setOpenAction(false);
            });

            Smooch.on('unreadCount', unreadCount => {
                setCountAction(unreadCount);
            });

            AnalyticsClient.track('Practice - Zendesk Chat - Handlers Registered', {});
            const smoochInitOptions: SmoochInitOptions = { ...BASE_OPTIONS };
            if (externalID && userAuthJWT) {
                smoochInitOptions.externalId = externalID;
                smoochInitOptions.jwt = userAuthJWT;
            }
            if (embedded) {
                smoochInitOptions.embedded = true;
            }

            const delegate = {
                beforeDisplay(message: any) {
                    if (message.fields && message.fields[0].name === 'csat_comment') {
                        return null;
                    }
                    const { text } = message;
                    if (text && text.startsWith('<%--') && text.endsWith('--%>')) {
                        return null;
                    }

                    return message;
                },
            };

            try {
                await Smooch.init({ ...smoochInitOptions, delegate });
            } catch (e) {
                Sentry.captureException(e);
                return;
            }
            AnalyticsClient.track('Practice - Zendesk Chat - Initialized', {});

            const conversations = Smooch.getConversations();
            for (const convo of conversations) {
                for (const participant of convo.participants) {
                    if (participant.unreadCount > 0) {
                        // EPDPOR-1125 - For users that have an unread message from the initial conversation creation,
                        // we mark as read so they stop getting the SunCo ping
                        if (convo.messages?.length === 1) {
                            await Smooch.markAllAsRead();
                        } else {
                            setCountAction(participant.unreadCount);
                        }
                        break;
                    }
                }
                await Smooch.updateConversation(convo.id, {
                    metadata: getSuncoMetadata(session, enableAda, threeshapeOrderId, laptopSerial),
                });
            }
        }
        void init();
    }, [loaded, authLoading, shouldInit, zendeskUser]);

    React.useEffect(
        () => () => {
            void Smooch.destroy();
        },
        [],
    );

    // Mount the widget if we're in embedded mode
    React.useEffect(() => {
        if (!embedded || !portal) {
            return;
        }

        // The shipped TS types for Smooch don't include the `render`
        // method, but it does exist and works as expected
        (Smooch as any).render(portal);
    }, [embedded, portal]);

    // Show/hide the SunCo bubble
    React.useEffect(() => {
        if (!loaded) {
            return;
        }

        const openSunco = async () => {
            const conversations = Smooch.getConversations();
            if (conversations.length === 0) {
                try {
                    await Smooch.createConversation({
                        metadata: getSuncoMetadata(session, enableAda, threeshapeOrderId, laptopSerial),
                    });
                } catch (e) {
                    Sentry.captureException(e);
                }
            }
            showSuncoBubble();
        };

        const isPortalSelectStaffPage = inPortal && showStaffBackground;
        const shouldShowBubble = zdOpen || (unreadCount > 0 && !isPortalSelectStaffPage);
        if (shouldShowBubble) {
            void openSunco();
        } else {
            hideSuncoBubble();
        }
    }, [loaded, zdOpen, unreadCount, inPortal, showStaffBackground]);

    // Reload the auth JWT and login when it expires
    React.useEffect(() => {
        if (!userAuthJWT || !externalID || !expiresAt || !loaded) {
            return;
        }

        const beforeExpiration = new Date(expiresAt).getTime() - 2 * 60 * 1000;

        // The first time we load a JWT we don't want to login because
        // it will already have been submitted with the Smooch.init
        // call, so we stash its expiration time and refuse to login again
        // until we're at least one minute from its expiration time.
        // This is required to avoid spurious logins if Apollo loads the
        // auth JWT multiple times on startup
        if (!initialJWTTime) {
            setInitialJWTTime(beforeExpiration);

            // Refetch the user auth JWT one minute before it expires
            const timeoutID = setTimeout(() => {
                void refetch();
            }, beforeExpiration - Date.now());

            return () => {
                clearTimeout(timeoutID);
            };
        }

        // The first JWT we fetch will be sent with the call to
        // Smooch.init, so we don't need to explicitly call `login`
        if (Date.now() < initialJWTTime) {
            return;
        }

        void Smooch.login(externalID, userAuthJWT);

        // Refetch the user auth JWT one minute before it expires
        const timeoutID = setTimeout(() => {
            void refetch();
        }, beforeExpiration - Date.now());

        return () => {
            clearTimeout(timeoutID);
        };
    }, [initialJWTTime, userAuthJWT, loaded]);
};
