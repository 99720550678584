import { LabeledTextField } from '../../../../components/labeled-field/LabeledFields';
import { useSetLoginPref } from '../../useLoginPref';
import { LoginForm } from '../components/LoginForm';
import { LoginTooltip } from '../components/LoginTooltip';
import { SwitchLoginPref } from '../components/SwitchLoginPref';
import { useEmailLoginValidation } from './useEmailLoginValidation';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import { FormHelperText } from '@orthly/ui-primitives';
import React from 'react';

export const EmailLogin: React.FC = () => {
    const setLoginPref = useSetLoginPref();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const { isFormValid, emailError, passwordError, setShowEmailError, setShowPasswordError } = useEmailLoginValidation(
        { email, password },
    );
    const { loading, loginError, onSubmitLogin } = useStaffMemberLoginProps();

    const shouldContinue = !loading && isFormValid;

    return (
        <LoginForm
            onSubmit={e => {
                e.preventDefault();
                onSubmitLogin({ email, password });
            }}
            shouldContinue={shouldContinue}
        >
            <LabeledTextField
                isRequired
                label={'Email'}
                placeholder={'Enter your email'}
                value={email}
                onChange={(next = '') => {
                    setEmail(next);
                }}
                TextFieldProps={{
                    type: 'email',
                    autoFocus: true,
                    autoComplete: 'email',
                    onBlur: () => {
                        setShowEmailError(email !== '');
                    },
                    error: !!emailError,
                    helperText: emailError,
                }}
                action={<LoginTooltip />}
                footer={<SwitchLoginPref message={'Use phone number instead'} onClick={() => setLoginPref('phone')} />}
            />
            <LabeledTextField
                isRequired
                label={'Password'}
                placeholder={'Enter your password'}
                value={password}
                onChange={(next = '') => {
                    setPassword(next);
                }}
                TextFieldProps={{
                    type: 'password',
                    autoComplete: 'current-password',
                    onBlur: () => {
                        setShowPasswordError(password !== '');
                    },
                    error: !!passwordError,
                    helperText: passwordError,
                }}
            />
            {loginError && <FormHelperText error={true}>{loginError}</FormHelperText>}
        </LoginForm>
    );
};
