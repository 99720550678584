import { useGetUserFromDrPrefId } from '../../../../../graphql/useGetUserFromDrPrefId.graphql';
import { usePracticeStaffControls } from '../../../state/usePracticeStaffControls';
import { useEmailValidation } from '../useEmailValidation';
import { usePhoneNumberValidation } from '../usePhoneNumberValidation';
import { useRequiredValidation } from '../useRequiredValidation';
import { isPlaceholderEmail } from '../utils';
import React from 'react';

export const useAccountInformationSection = () => {
    const preferredAccountFirstNameRef = React.useRef<string>();
    const preferredAccountLastNameRef = React.useRef<string>();
    const preferredAccountEmailRef = React.useRef<string>();
    const preferredAccountPhoneRef = React.useRef<string>();
    const { employeePrefs } = usePracticeStaffControls() ?? {};
    const { user } = useGetUserFromDrPrefId(employeePrefs?.id);

    const first_name = user?.first_name ?? '';
    const last_name = user?.last_name ?? '';
    const email = user?.email ?? '';
    const phone = user?.mobile_phone_number ?? '';
    const placeholderEmail = isPlaceholderEmail(email);

    const [accountFirstName, setAccountFirstName] = React.useState('');
    const [accountLastName, setAccountLastName] = React.useState('');
    const [accountEmail, setAccountEmail] = React.useState('');
    const [accountPhone, setAccountPhone] = React.useState('');

    const { requiredError: firstNameRequiredError } = useRequiredValidation(accountFirstName);
    const { requiredError: lastNameRequiredError } = useRequiredValidation(accountLastName);
    const { requiredError: accountEmailRequiredError } = useRequiredValidation(accountEmail);
    const { emailError: accountEmailError } = useEmailValidation(accountEmail);
    const { isFormValid: isAccountPhoneValid, phoneNumberError: accountPhoneError } =
        usePhoneNumberValidation(accountPhone);

    React.useEffect(() => {
        preferredAccountFirstNameRef.current = first_name;
        preferredAccountLastNameRef.current = last_name;
        preferredAccountEmailRef.current = email;
        preferredAccountPhoneRef.current = phone;

        setAccountFirstName(first_name);
        setAccountLastName(last_name);
        setAccountEmail(placeholderEmail ? '' : email);
        setAccountPhone(phone);
    }, [user, first_name, last_name, email, phone, placeholderEmail]);

    return {
        accountFirstName,
        setAccountFirstName,
        accountLastName,
        setAccountLastName,
        accountEmail,
        setAccountEmail,
        accountPhone,
        setAccountPhone,
        phoneNeedsConfirmation: accountPhone !== (user?.mobile_phone_number ?? ''),
        firstNameRequiredError,
        lastNameRequiredError,
        accountEmailRequiredError,
        accountEmailError,
        accountPhoneError,
        isAccountPhoneValid,
        isDirty:
            preferredAccountFirstNameRef.current !== accountFirstName ||
            preferredAccountLastNameRef.current !== accountLastName ||
            preferredAccountEmailRef.current !== accountEmail ||
            preferredAccountPhoneRef.current !== accountPhone,
    };
};
