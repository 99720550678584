import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'surgicalGuide_checkout';

export const SurgicalGuideCheckoutActions = {
    SET_CBCT_URL: createSyncAction<string | null, [string | null]>(`${PREFIX}/SET_CBCT_URL`),
};

export const useSurgicalGuideCheckoutAction =
    generateUseActionHook<typeof SurgicalGuideCheckoutActions>(SurgicalGuideCheckoutActions);
