import { useOrdersToolbarSelectProps } from './useOrdersToolbarSelectProps';
import { useLabsPreferencesBasic } from '@orthly/graphql-react';
import { SimpleMultiSelect } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

interface OrdersOverviewToolbarDoctorFilterProps {
    selectedDoctorIds: string[];
    setSelectedDoctorIds: (selectedDoctors: string[]) => void;
}

export const OrdersToolbarDoctorFilter: React.VFC<OrdersOverviewToolbarDoctorFilterProps> = props => {
    const { selectedDoctorIds, setSelectedDoctorIds } = props;
    const { preferences } = useLabsPreferencesBasic();
    const selectProps = useOrdersToolbarSelectProps();
    return (
        <SimpleMultiSelect
            {...selectProps}
            options={preferences.map(p => ({ value: p.id, label: p.formatted_name }))}
            onChange={val => setSelectedDoctorIds(val ?? [])}
            label={'Doctor'}
            value={selectedDoctorIds}
            placeholder={'All doctors'}
            variant={'filled'}
            backgroundColor={FlossPalette.WHITE}
            style={{ borderRadius: 0, border: 'none', boxShadow: 'none' }}
        />
    );
};
