import { FlossPalette, Text, styled } from '@orthly/ui-primitives';

const RequiredTitle = styled(Text)({
    '&::after': {
        content: '"*"',
        color: FlossPalette.ATTENTION,
    },
});

const Divider = styled('div')({
    width: '100%',
    height: 1,
    backgroundColor: FlossPalette.DARK_TAN,
    gridColumn: '1/-1',
    '&:nth-last-of-type(1)': {
        display: 'none',
    },
});

export const Section: React.FC<{ title: string; subtitle: string; isRequired?: boolean }> = ({
    title,
    subtitle,
    isRequired = false,
    children,
}) => {
    const Title = isRequired ? RequiredTitle : Text;

    return (
        <>
            <div>
                <Title bold variant={'body2'}>
                    {title}
                </Title>
                <Text variant={'body2'} color={'GRAY'}>
                    {subtitle}
                </Text>
            </div>
            <div>{children}</div>
            <Divider />
        </>
    );
};
