import { ORDER_TOOLBAR_HEIGHT } from '../Layout/OrderToolbar';
import type { ButtonProps } from '@orthly/ui';
import type { SlideProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    stylesFactory,
    Text,
    useScreenIsMd,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Slide,
    Button,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        width: `100%`,
    },
    container: {
        padding: '16px 48px',
        overflowY: 'auto',
        alignContent: 'flex-start',
        [theme.breakpoints.down('lg')]: {
            padding: '32px 16px',
        },
    },
    navArea: {
        width: '100%',
        // total height is 78 + 2 = 80
        height: 78,
        borderTopWidth: 2,
        borderTopStyle: `solid`,
        backgroundColor: FlossPalette.WHITE,
    },
    navAreaMobile: {
        borderTopColor: FlossPalette.STROKE_LIGHT,
        // setting position and zIndex so elements are hidden behind nav area when scrolling
        position: 'fixed',
        zIndex: 1,
    },
    navAreaDesktop: {
        borderTopColor: FlossPalette.WHITE,
        display: `flex`,
        justifyContent: `space-between`,
        [theme.breakpoints.down('lg')]: {
            justifyContent: 'center',
        },
    },
    additionalContentWrapper: {
        padding: `8px`,
    },
}));

interface NavAreaProps {
    onBack?: () => void;
    onNext?: () => void;
    nextTitle?: React.ReactNode;
    backTitle?: React.ReactNode;
    disableNext?: boolean;
    isRefabContainer?: boolean;
    refabConfirmation?: boolean;
    nextButtonProps?: Partial<ButtonProps>;
    backButtonProps?: Partial<ButtonProps>;
    AdditionalContent?: React.ReactNode;
    onNavigateClick?: (direction: SlideProps['direction']) => void;
}

const NavAreaMobile: React.VFC<NavAreaProps> = ({
    onBack,
    onNext,
    nextTitle,
    backTitle,
    disableNext,
    nextButtonProps,
    backButtonProps,
    refabConfirmation,
    AdditionalContent,
    onNavigateClick,
}) => {
    const styles = useStyles();
    const buttonStyles = { width: '100%', textAlign: 'center' } as const;
    const singleButton = Number(!!onBack) + Number(!!onNext) === 1;
    return (
        <Grid
            container
            justifyContent={'flex-end'}
            className={cx(styles.navArea, styles.navAreaMobile)}
            alignItems={'center'}
        >
            {AdditionalContent && (
                <Grid container alignItems={'center'} className={styles.additionalContentWrapper}>
                    {AdditionalContent}
                </Grid>
            )}

            <Grid container alignItems={'center'}>
                {onBack && (
                    <Grid item xs={singleButton ? 12 : 6} style={{ paddingLeft: 8, paddingRight: 4 }}>
                        <Button
                            variant={'secondary'}
                            onClick={() => {
                                onNavigateClick && onNavigateClick('up');
                                onBack();
                            }}
                            startIcon={refabConfirmation ? undefined : 'ChevronLeft'}
                            style={buttonStyles}
                            {...backButtonProps}
                        >
                            {backTitle ?? 'Back'}
                        </Button>
                    </Grid>
                )}
                {onNext && (
                    <Grid item xs={singleButton ? 12 : 6} style={{ paddingLeft: 4, paddingRight: 8 }}>
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                onNavigateClick && onNavigateClick('down');
                                onNext();
                            }}
                            startIcon={refabConfirmation ? 'HomeIcon' : undefined}
                            endIcon={refabConfirmation ? undefined : 'ChevronRight'}
                            style={buttonStyles}
                            disabled={disableNext}
                            {...nextButtonProps}
                        >
                            {nextTitle ?? 'Next'}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
const NavAreaDesktop: React.VFC<NavAreaProps> = ({
    onBack,
    onNext,
    nextTitle,
    backTitle,
    disableNext,
    isRefabContainer: isRefab,
    refabConfirmation,
    backButtonProps,
    nextButtonProps,
    AdditionalContent,
    onNavigateClick,
}) => {
    const styles = useStyles();
    const isMd = useScreenIsMd();
    // The confirmation screen uses md as a breakpoint to center text, therefore we exclude padding at this breakpoint to properly align buttons
    const paddingRefabConfirmation = isMd ? '0px' : '174px';
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const paddingSides = !isRefab ? '' : refabConfirmation ? paddingRefabConfirmation : 48;

    return (
        <Grid
            container
            justifyContent={isRefab ? 'flex-start' : 'flex-end'}
            className={cx(styles.navArea, styles.navAreaDesktop)}
            alignItems={'center'}
            style={{ paddingLeft: paddingSides, paddingRight: paddingSides }}
        >
            {AdditionalContent && <Grid alignItems={'center'}>{AdditionalContent}</Grid>}
            <Grid
                alignItems={'center'}
                justifyContent={isRefab ? 'flex-start' : 'flex-end'}
                style={{ display: 'flex' }}
            >
                {onBack && (
                    <Grid item>
                        <Button
                            variant={isRefab ? 'secondary-gray' : 'ghost'}
                            onClick={() => {
                                onNavigateClick && onNavigateClick('up');
                                onBack();
                            }}
                            style={{
                                minWidth: isRefab ? 264 : '',
                                marginRight: 32,
                            }}
                            {...backButtonProps}
                        >
                            {backTitle ?? 'Back'}
                        </Button>
                    </Grid>
                )}
                {onNext && (
                    <Grid item>
                        <Button
                            variant={'primary'}
                            onClick={() => {
                                //Only animate if we are not on the confirmation screen
                                if (onNavigateClick && nextTitle === 'Next') {
                                    onNavigateClick('down');
                                }
                                onNext();
                            }}
                            startIcon={refabConfirmation ? 'HomeIcon' : undefined}
                            endIcon={isRefab && !refabConfirmation ? 'ChevronRight' : undefined}
                            style={{ minWidth: 264, marginRight: isRefab ? 0 : 40 }}
                            disabled={disableNext}
                            data-test={'feedback-refab-next-button'}
                            {...nextButtonProps}
                        >
                            {nextTitle ?? 'Next'}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

type FeedbackAndRefabBodyContainerProps = {
    children: React.ReactNode;
    title: React.ReactNode;
} & NavAreaProps;

export const FeedbackAndRefabBodyContainer: React.VFC<FeedbackAndRefabBodyContainerProps> = props => {
    const [slideIn, setSlideIn] = React.useState(true);
    const [slideDirection, setSlideDirection] = React.useState<SlideProps['direction']>('down');

    const { children, title, onBack, onNext, isRefabContainer, refabConfirmation } = props;
    const styles = useStyles();
    const showNavArea = !!onNext || !!onBack;
    const isMobile = useScreenIsMobileOrVerticalTablet();
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const navHeight = isRefabContainer ? (isMobile ? 130 : '') : isMobile ? 90 : 80;
    const navHeightWithAdditionalContent = typeof navHeight === 'number' ? navHeight + 60 : '';
    const finalNavHeight = props.AdditionalContent ? navHeightWithAdditionalContent : navHeight;
    const height = `calc(100vh - ${ORDER_TOOLBAR_HEIGHT}px - ${showNavArea ? finalNavHeight : 0}px)`;
    const containerId = 'feedback-refab-body-container';

    const onNavigateClick = (direction: SlideProps['direction']) => {
        const oppDirection = direction === 'down' ? 'up' : 'down';
        setSlideDirection(direction);
        setSlideIn(false);

        setTimeout(() => {
            const mainEl = document.getElementById(containerId);
            setSlideDirection(oppDirection);
            setSlideIn(true);
            mainEl?.scrollIntoView();
        }, 300);
    };

    return (
        <div id={containerId} style={{ scrollMargin: 120 }}>
            <Slide in={slideIn} direction={slideDirection}>
                <div
                    className={styles.root}
                    style={{
                        marginTop: refabConfirmation ? 48 : 0,
                        // 424px is width of the FeedbackSidebar for the desktop feedback flow
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        maxWidth: isRefabContainer ? 'none' : isMobile ? 'none' : `calc(100vw - 424px)`,
                    }}
                >
                    <Grid
                        container
                        className={styles.container}
                        columnSpacing={1}
                        style={{
                            height,
                            maxHeight: height,
                        }}
                    >
                        <Grid item xs={12} style={{ marginBottom: isRefabContainer ? 32 : 8 }}>
                            <Text variant={isMobile ? 'h5' : 'h4'}>{title}</Text>
                        </Grid>
                        {children}
                    </Grid>
                    {showNavArea &&
                        (isMobile ? (
                            <NavAreaMobile {...props} />
                        ) : (
                            <NavAreaDesktop {...props} onNavigateClick={onNavigateClick} />
                        ))}
                </div>
            </Slide>
        </div>
    );
};
