import { usePortalToChairsideBridgeCommand } from '../../../../../../../../../../../utils/chairside.hooks';
import { useOrderTimelineLightbox } from '../../../../../OrderTimelineLightbox';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import { useFirebasePreview } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    imageContainer: {
        position: 'relative',
        cursor: 'pointer',
        height: '100%',
        '&:hover': {
            background: FlossPalette.STAR_GRASS,
            '& img': {
                opacity: 0.4,
            },
        },
    },
    image: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
    },
}));

const OrderTimelineFirebaseImage: React.FC<{ source: string; name: string }> = ({ source, name }) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    const { setSelectedAttachmentPreview } = useOrderTimelineLightbox();
    const { result: previewSource, loading: isLoadingPreview } = useFirebasePreview(source);

    return (
        <LoadBlocker
            blocking={isLoading || isLoadingPreview}
            ContainerProps={{
                className: classes.imageContainer,
            }}
        >
            <img
                src={previewSource}
                alt={name}
                className={classes.image}
                onClick={() => setSelectedAttachmentPreview(name)}
                onLoad={() => setIsLoading(false)}
            />
        </LoadBlocker>
    );
};

const OrderTimelineDownloadImage: React.FC<{ source: string; name: string; downloadUrl: string }> = ({
    source,
    name,
    downloadUrl,
}) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = React.useState(true);
    const openPdf = usePortalToChairsideBridgeCommand('openPdf');

    const onClickHandler = React.useCallback(() => {
        if (openPdf) {
            openPdf({ url: downloadUrl });
        } else {
            window.open(downloadUrl, '_blank', 'noreferrer noopener');
        }
    }, [downloadUrl, openPdf]);

    return (
        <LoadBlocker
            blocking={isLoading}
            ContainerProps={{
                className: classes.imageContainer,
            }}
        >
            <img
                src={source}
                alt={name}
                className={classes.image}
                onClick={onClickHandler}
                onLoad={() => setIsLoading(false)}
            />
        </LoadBlocker>
    );
};

export const OrderTimelineImage: React.FC<{ source: string; name: string; downloadUrl?: string | null }> = ({
    downloadUrl,
    ...restProps
}) => {
    if (downloadUrl) {
        return <OrderTimelineDownloadImage {...restProps} downloadUrl={downloadUrl} />;
    }

    return <OrderTimelineFirebaseImage {...restProps} />;
};
