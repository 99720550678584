import type { StarRatingType } from '../inbox/components/tracker/OrderSummaryStepper';
import type { FeedbackItemSelectScreen, FeedbackScreen } from './state/feedback.reducer';
import type { FeedbackAndRefabItem } from '@orthly/dentin';

interface FirstScreenForItemParams {
    screen: FeedbackItemSelectScreen;
    item: FeedbackAndRefabItem;
    fiveStarShortcutUsed: boolean;
    starRating?: StarRatingType;
}

export const firstScreenForItem = (itemScreenParams: FirstScreenForItemParams): FeedbackScreen => {
    const { screen, item, fiveStarShortcutUsed, starRating } = itemScreenParams;
    const { items } = screen;

    if (starRating === 5) {
        return {
            items,
            item,
            id: 'five_star_props',
            shortcutUsed: fiveStarShortcutUsed,
            previous_screen: screen,
        };
    }

    return { items, item, id: 'item_props', previous_screen: screen };
};
