import { StaffProfilePhoto } from '../../../components/StaffProfilePhoto';
import { MobileLinkGroup, mobileLinkGroupStyles } from '../../../layouts/mobile/MobileLinkGroup';
import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { usePracticeLogout } from '../../../redux/auth';
import { useCurrentProfile, useCurrentProfileIsDoctor } from '../../../redux/selectors';
import { usePartnerUiSelector } from '../../../redux/ui';
import { useSelectStaffAction } from '../../../screens/select-staff/state/select-staff.actions';
import { useMobileMoreStyles, useStyles } from './MobileMoreRoot.styles';
import { PracticeScreen } from '@orthly/dentin';
import { DoctorBadgeIcon } from '@orthly/ui';
import { FlossPalette, Grid, Icon, Text } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const MobileProfileRow: React.VFC = () => {
    const linkGroupClasses = mobileLinkGroupStyles({ single: true });
    const classes = useStyles();
    const logoutProfile = useSelectStaffAction('LOGOUT_STAFF');
    const profile = useCurrentProfile();
    const drSince = profile?.created_at && `Dandy doctor since ${moment(profile?.created_at).format('MM-DD-YYYY')}`;
    return (
        <Grid container className={classes.profileRowWrapper}>
            <Grid container className={linkGroupClasses.link} onClick={logoutProfile} style={{ padding: 0 }}>
                <Grid item className={classes.staffWrapper}>
                    <StaffProfilePhoto staff={profile} style={{ fontSize: 18, width: 40, height: 40 }} />
                </Grid>
                <Grid item xs>
                    <Text variant={'h6'} className={classes.profileName}>
                        {profile?.formatted_name}
                    </Text>
                    <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <DoctorBadgeIcon className={classes.doctorBadgeIcon} />
                        <Text variant={'caption'} color={'GRAY'} className={classes.doctorSince}>
                            {drSince}
                        </Text>
                    </Grid>
                </Grid>
                <Grid item>
                    <Icon icon={'SwapHorizontalIcon'} style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export const MobileMoreRoot: React.VFC = () => {
    const classes = useMobileMoreStyles();
    const logOut = usePracticeLogout();
    const isDoctor = useCurrentProfileIsDoctor();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const getHref = (screen: PracticeScreen) => (isNativeApp ? `/${screen}?launch=pwa` : `/${screen}`);

    return (
        <MobilePageLayout title={'More'} hideHeader>
            <Grid container className={classes.root}>
                <MobileProfileRow />
                <MobileLinkGroup
                    header={'Supplies'}
                    subHeader={'Order supplies for your practice'}
                    linkItems={[
                        {
                            href: getHref(PracticeScreen.scanbodies),
                            title: 'Implant Readiness',
                        },
                        {
                            externalUrl: 'https://dandy.dental/chairside-dandy-accessories',
                            title: 'Accessories',
                        },
                        {
                            externalUrl: 'https://dandy.dental/chairside-dandy-tech',
                            title: 'Tech & Setup',
                        },
                        {
                            externalUrl: 'https://dandy.dental/chairside-dandy-marketing',
                            title: 'Marketing materials',
                        },
                    ]}
                />
                <MobileLinkGroup
                    header={'You'}
                    subHeader={'Manage your profile'}
                    linkItems={[
                        {
                            href: getHref(PracticeScreen.account_settings),
                            title: 'Account settings',
                        },
                        ...(isDoctor
                            ? [
                                  {
                                      href: getHref(PracticeScreen.notifications),
                                      title: 'Notifications',
                                  },
                                  {
                                      href: getHref(PracticeScreen.preferences),
                                      title: 'Clinical Preferences',
                                  },
                              ]
                            : []),
                    ]}
                />
                <MobileLinkGroup
                    header={'My practice'}
                    subHeader={'Manage your practice'}
                    linkItems={[
                        { href: getHref(PracticeScreen.billing), title: 'Billing' },
                        { href: getHref(PracticeScreen.staff), title: 'Staff' },
                        {
                            href: getHref(PracticeScreen.practice_settings),
                            title: 'Practice settings',
                        },
                        {
                            href: getHref(PracticeScreen.pricing_guide),
                            title: 'Pricing guide',
                        },
                    ]}
                />

                <MobileLinkGroup
                    linkItems={[
                        {
                            externalUrl: 'https://help.meetdandy.com',
                            title: 'Help center',
                        },
                    ]}
                />
                <MobileLinkGroup linkItems={[{ onClick: logOut, title: 'Log out' }]} />
            </Grid>
        </MobilePageLayout>
    );
};
