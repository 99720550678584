import { useCheckoutSelector } from '../../../../redux';
import { useImplantCheckoutAction } from '../../state/implant-checkout.actions';
import {
    implantCheckoutCurrentToothScanbody,
    useImplantCheckoutSelector,
} from '../../state/implant-checkout.selectors';
import type { CheckoutImplantToothGroup } from '../../state/reducers/implant-checkout-scanbodies.reducer';
import { NonDandyScanbodyDisplay, ScanbodyDisplay } from '@orthly/dentin';
import type { LabsGqlScanbodyRequestFragment } from '@orthly/graphql-operations';
import { useGetFulfilledScanbodyRequestsQuery } from '@orthly/graphql-react';
import { ActionCard, LoadBlocker } from '@orthly/ui';
import { createStyles, makeStyles, Slide, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            paddingTop: 8,
        },
        scanbodyGrid: {
            marginTop: '1em',
        },
        scanbodyItem: {
            '&>*': {
                cursor: 'pointer',
            },
        },
    }),
);

/**
 * Handles scanbody selection.
 * If scanbody is null, renders NonDandyScanbodyDisplay, otherwise ScanbodyDisplay
 */
const ScanbodyChooserItem: React.VFC<{
    scanbodyRequest: LabsGqlScanbodyRequestFragment | null;
    selected: boolean;
    onSelect: () => void;
}> = ({ scanbodyRequest, selected, onSelect }) => {
    const classes = useStyles();
    const date = React.useMemo(() => moment(scanbodyRequest?.created_at).toDate(), [scanbodyRequest?.created_at]);

    return (
        <Grid item onClick={() => !selected && onSelect()} className={classes.scanbodyItem}>
            {scanbodyRequest ? (
                <ScanbodyDisplay
                    selected={selected}
                    scanbodyManufacturer={scanbodyRequest.scanbody?.manufacturer ?? 'Unknown'}
                    scanbodyName={scanbodyRequest.scanbody?.name ?? 'Unknown'}
                    implantManufacturer={scanbodyRequest.manufacturer}
                    implantSystem={scanbodyRequest.system}
                    implantConnection={scanbodyRequest.connection}
                    requestedDate={date}
                />
            ) : (
                <NonDandyScanbodyDisplay selected={selected} />
            )}
        </Grid>
    );
};

function dedupeScanbodyRequests(scanbodyRequests: LabsGqlScanbodyRequestFragment[]): LabsGqlScanbodyRequestFragment[] {
    // sort by -created_at so most recent ones are first
    const latestFirst = _.sortBy(scanbodyRequests, request => -1 * moment(request.created_at).unix());
    // uniquify, lodash keeps the first occurence so keeps the most recent request
    return _.uniqBy(latestFirst, request =>
        JSON.stringify([
            request.system,
            request.manufacturer,
            request.connection,
            request.scanbody?.manufacturer,
            request.scanbody?.sku,
        ]),
    );
}

function useDedupedScanbodyRequests(implantTooth: CheckoutImplantToothGroup | null) {
    const { manufacturer, system, connection_size } = implantTooth?.implant_metadata ?? {};

    const { data, loading, error } = useGetFulfilledScanbodyRequestsQuery({
        variables: {
            manufacturer: manufacturer ?? null,
            system: system ?? null,
            connection: connection_size ?? null,
        },
        skip: !implantTooth,
    });

    return { loading, error, data: data ? dedupeScanbodyRequests(data.getScanbodyRequests) : [] };
}

interface SmartScanbodyChooserProps {
    implantTooth: CheckoutImplantToothGroup;
    loading: boolean;
    data: LabsGqlScanbodyRequestFragment[];
    numUploadedPhotos: number;
}

const SmartScanbodyChooser: React.VFC<SmartScanbodyChooserProps> = props => {
    const { implantTooth, loading, data, numUploadedPhotos } = props;
    const classes = useStyles();
    const motion = useImplantCheckoutSelector(s => s.scanbodiesScreen.motion);
    const setScanbody = useImplantCheckoutAction('ADD_SCANBODY_FOR_ITEM_TOOTH');
    const goToPrevStep = useImplantCheckoutAction('PREV_IMPLANT_STEP');

    // filter the scanbody requests to those that have a valid scanbody
    const filteredRequests = data.filter(request => !!request.scanbody);

    // Convenient wrapper for selecting scanbody
    const handleScanbodySelect = React.useCallback(
        (scanbody_id: string | null) => {
            setScanbody({
                scanbody_id,
                item_index: implantTooth.item_index,
                unn: implantTooth.unn,
            });
        },
        [setScanbody, implantTooth.item_index, implantTooth.unn],
    );

    // Effect auto-selects `null` scanbody if there's no other choice.
    React.useEffect(() => {
        if (!loading && filteredRequests.length === 0) {
            if (motion === 'next') {
                handleScanbodySelect(null);
            } else {
                goToPrevStep({ numUploadedPhotos });
            }
        }
    }, [loading, filteredRequests.length, motion, numUploadedPhotos, handleScanbodySelect, goToPrevStep]);

    const showNTAlert = filteredRequests.some(r => r.scanbody?.manufacturer === 'NT Trading');

    return (
        <>
            <Grid container spacing={3} className={classes.scanbodyGrid}>
                {filteredRequests.map(scanbodyRequest => (
                    <ScanbodyChooserItem
                        key={scanbodyRequest.id}
                        scanbodyRequest={scanbodyRequest}
                        selected={scanbodyRequest.scanbody_id === implantTooth.implant_metadata.scanbody_id}
                        onSelect={() => handleScanbodySelect(scanbodyRequest.scanbody_id)}
                    />
                ))}
                <ScanbodyChooserItem
                    scanbodyRequest={null}
                    selected={!implantTooth.implant_metadata.scanbody_id}
                    onSelect={() => handleScanbodySelect(null)}
                />
            </Grid>
            {showNTAlert && (
                <Grid style={{ marginTop: 36 }}>
                    <ActionCard
                        variant={'alert'}
                        title={''}
                        subtitle={
                            'NT Trading informed all dental labs that, effective immediately, they will be be unable to fulfill any orders for NT Trading branded scan bodies. While we await additional information from the manufacturer, Dandy is unable to fulfill new orders using NT Trading scan bodies.'
                        }
                    />
                </Grid>
            )}
        </>
    );
};

export const ScanbodySourceScreen: React.VFC<{ numUploadedPhotos: number }> = ({ numUploadedPhotos }) => {
    const classes = useStyles();
    const motion = useImplantCheckoutSelector(s => s.scanbodiesScreen.motion);
    const implantTooth = useCheckoutSelector(implantCheckoutCurrentToothScanbody);
    const { data, loading } = useDedupedScanbodyRequests(implantTooth);

    if (!implantTooth) {
        return null;
    }

    return (
        <LoadBlocker blocking={loading}>
            <Slide
                key={`{implantTooth.item_index}-${implantTooth.unn}`}
                direction={motion === 'next' ? 'up' : 'down'}
                in
                exit
            >
                <Grid container direction={'column'} className={classes.wrapper}>
                    <SmartScanbodyChooser
                        implantTooth={implantTooth}
                        loading={loading}
                        data={data}
                        numUploadedPhotos={numUploadedPhotos}
                    />
                </Grid>
            </Slide>
        </LoadBlocker>
    );
};
