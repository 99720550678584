import { FlossPalette, Text, ButtonBase, stylesFactory, Grid } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

// NOTE: This deliberately uses `ButtonBase` to take advantage of the unstyled ButtonBase component.

const useStyles = stylesFactory(() => ({
    imgButton: {
        width: 255,
        height: 255,
        textAlign: 'center',
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        padding: '24px',
        '&>img': {
            flexGrow: 1,
            overflow: 'hidden',
            objectFit: 'scale-down',
            width: '100%',
        },
        '&:hover': {
            borderColor: FlossPalette.STROKE_DARK,
        },
    },
    imgButtonDisabled: {
        opacity: 0.6,
    },
    imgWrapper: {
        flexBasis: 0,
        flexGrow: 1,
        minHeight: 0,
    },
    img: {
        width: '100%',
        height: '100%',
        objectFit: 'scale-down',
    },
    badge: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: 8,
        right: 8,
        borderRadius: 8,
        backgroundColor: FlossPalette.SECONDARY_FOREGROUND,
    },
}));

interface SelectCardProps {
    image: string;
    title: string | React.ReactElement;
    description?: string | React.ReactElement;
    onClick?: () => void;
    disabled?: boolean;
    imgClassName?: string;
    badge?: number;
}

export const SelectCard: React.VFC<SelectCardProps> = ({
    image,
    title,
    description,
    onClick,
    disabled,
    imgClassName,
    badge,
}) => {
    const classes = useStyles();

    return (
        <ButtonBase
            className={classes.imgButton}
            classes={{ disabled: classes.imgButtonDisabled }}
            onClick={onClick}
            disabled={disabled}
            data-test={'select-card'}
        >
            <Grid
                container
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                style={{ height: '100%' }}
            >
                <Grid item className={classes.imgWrapper}>
                    <img src={image} className={cx(classes.img, imgClassName)} alt={''} />
                </Grid>
                <Grid item>
                    {typeof title === 'string' ? (
                        <Text variant={'body2'} medium color={'BLACK'}>
                            {title}
                        </Text>
                    ) : (
                        title
                    )}
                    {typeof description === 'string' ? (
                        <Text variant={'body2'} color={'GRAY'}>
                            {description}
                        </Text>
                    ) : (
                        description
                    )}
                </Grid>
            </Grid>
            {badge !== undefined && (
                <div className={classes.badge} data-test={'select-card-badge'}>
                    <Text variant={'body2'} medium color={'WHITE'}>
                        {badge}
                    </Text>
                </div>
            )}
        </ButtonBase>
    );
};
