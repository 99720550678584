import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import { DandyItemMultiToothSelect } from '@orthly/dentin';
import type { ICartItemV2Update, LabOrderItemSKUType } from '@orthly/items';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface CheckoutItemMultiToothFieldProps {
    item: Extract<CheckoutItemV2, { sku: LabOrderItemSKUType.Partial }>;
}

export const CheckoutItemMultiToothField: React.VFC<CheckoutItemMultiToothFieldProps> = ({ item }) => {
    const updateItemAction = useCheckoutAction('UPDATE_LINE_ITEM');

    const updateItem = (change: ICartItemV2Update) => updateItemAction({ change, item_index: item.item_index });

    return (
        <Grid container>
            <Grid item xs={12}>
                {/* Partials specific title - update if additional item types are added */}
                <Text style={{ fontWeight: 500 }}>Select teeth going on the partial</Text>
            </Grid>
            <Grid item xl={6} lg={8} md={10} style={{ paddingTop: 12, paddingBottom: 16 }}>
                <DandyItemMultiToothSelect
                    item={item}
                    updateItem={updateItem}
                    archLabels={true}
                    verticalLayout={false}
                />
            </Grid>
        </Grid>
    );
};
