import { FlossPalette, Grid, IconButton, Icon, CheckIcon, CloseIcon, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

interface OrderDetailMobileControlPanelBaseProps {
    showSuccessMessage: boolean;
    successMessage: string;
    backAction: () => void;
    closeAction: () => void;
}

const Layout = styled('div')({
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    height: '100vh',
    padding: '16px',
});

export const OrderDetailMobileControlPanelBase: React.FC<OrderDetailMobileControlPanelBaseProps> = props => {
    const { showSuccessMessage, successMessage, backAction, closeAction, children } = props;

    return (
        <Layout>
            <Grid
                container
                justifyContent={showSuccessMessage ? 'flex-end' : 'space-between'}
                alignContent={'flex-start'}
                style={{ paddingBottom: 16 }}
            >
                {!showSuccessMessage && (
                    <IconButton onClick={backAction} size={'small'}>
                        <Icon icon={'ArrowBackIos'} style={{ color: FlossPalette.GREEN, height: 20 }} />
                    </IconButton>
                )}
                <IconButton onClick={closeAction} size={'small'}>
                    <CloseIcon style={{ color: FlossPalette.GRAY }} />
                </IconButton>
            </Grid>
            {showSuccessMessage ? (
                <Grid container justifyContent={'center'} alignContent={'flex-start'} style={{ height: '25%' }}>
                    <Grid container justifyContent={'center'} style={{ marginBottom: 8 }}>
                        <CheckIcon style={{ color: FlossPalette.GREEN }} />
                    </Grid>
                    <Text variant={'h6'}>{successMessage}</Text>
                </Grid>
            ) : (
                <>{children}</>
            )}
        </Layout>
    );
};
