import { StaffProfilePhoto } from '../../components/StaffProfilePhoto';
import { PracticeUtils } from '../../utils/utils';
import { DoctorNotificationSettings } from '../account/profile-settings/notifications/ProfileNotifications';
import { useSetDoctorNotificationPrefsSubmitter, useSendNotification } from './NotificationsRoot.hooks';
import type { ApolloQueryResult } from '@apollo/client';
import type {
    LabsGqlDoctorAndLastNameFragment,
    LabsGqlGetDoctorPreferencesByTokenQuery,
} from '@orthly/graphql-operations';
import { useGetDoctorPreferencesByTokenQuery, useGetLinkedDoctorPreferencesQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, NavigationLogoDandy, useBeforeUnload } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, HomeIcon, Button, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    logo: {
        height: 24,
        minWidth: 128,
        marginLeft: 24,
        alignSelf: 'center',
        color: FlossPalette.DARK_TAN,
    },
    title: {
        display: 'flex',
        alignSelf: 'center',
        textAlign: 'center',
    },
    signIn: {
        alignSelf: 'center',
        marginRight: 12,
        display: 'flex',
    },
    signInButton: {
        marginLeft: 8,
        background: FlossPalette.TAN,
        height: 48,
    },
    settingsHeader: { height: 72, flexWrap: 'nowrap', background: FlossPalette.WHITE },
    settingsBody: { margin: '32px 0 104px', borderRadius: 16, overflow: 'hidden' },
}));

const PHOTO_STYLE: React.CSSProperties = {
    width: 48,
    height: 48,
    borderRadius: 4,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
};

interface NotificationsTokenAuthenticatedProps {
    token: string;
    preferences: LabsGqlDoctorAndLastNameFragment;
    loading: boolean;
    refetch: () => Promise<ApolloQueryResult<LabsGqlGetDoctorPreferencesByTokenQuery>>;
}

// timeout is 15 minutes
const TIMEOUT_SECONDS = 60 * 15;

const NotificationsTokenAuthenticated: React.FC<NotificationsTokenAuthenticatedProps> = props => {
    const { token, preferences, loading, refetch } = props;
    const originalPrefs = React.useRef(preferences);
    const history = useHistory();
    const classes = useStyles();
    const { submit, submitting } = useSetDoctorNotificationPrefsSubmitter(token, refetch);
    // keep track of inactivity time so we can send an email once we've reached timeout time of inactivity
    const [secondsRemaining, setSecondsRemaining] = React.useState(TIMEOUT_SECONDS);
    const onSendNotif = useSendNotification(token, originalPrefs, preferences);
    // countdown
    const timeoutReached = secondsRemaining === 0;
    React.useEffect(() => {
        if (!timeoutReached) {
            const timer = setInterval(() => setSecondsRemaining(secondsRemaining => secondsRemaining - 1), 1000);
            return () => clearInterval(timer);
        }
        onSendNotif(false);
        originalPrefs.current = preferences;
    }, [onSendNotif, preferences, timeoutReached, token]);
    // when the user leaves the page and we haven't already sent an email, send one
    useBeforeUnload(() => {
        secondsRemaining > 0 && onSendNotif(true);
    });

    return (
        <LoadBlocker blocking={loading || submitting}>
            <Grid container justifyContent={'center'} style={{ background: FlossPalette.TAN }}>
                <Grid container justifyContent={'space-between'} className={classes.settingsHeader}>
                    <NavigationLogoDandy className={classes.logo} />
                    <Grid item className={classes.title}>
                        <Text variant={'h5'} medium>
                            Dr. {preferences.doctor_last_name}'s{' '}
                            <span style={{ color: FlossPalette.GRAY }}>notification preferences</span>
                        </Text>
                    </Grid>
                    <Grid item className={classes.signIn}>
                        <StaffProfilePhoto staff={preferences.doctor} style={PHOTO_STYLE} />
                        <Button
                            variant={'ghost'}
                            className={classes.signInButton}
                            onClick={() => {
                                secondsRemaining > 0 && onSendNotif(true);
                                history.push(`/login?email=${preferences.doctor.contact_email}`);
                            }}
                        >
                            <HomeIcon style={{ marginRight: 8 }} />
                            Sign into portal
                        </Button>
                    </Grid>
                </Grid>
                <Grid container xs={7} className={classes.settingsBody}>
                    <DoctorNotificationSettings
                        employeePrefs={preferences.doctor}
                        submit={data => {
                            // reset timeout on submit
                            setSecondsRemaining(TIMEOUT_SECONDS);
                            void submit(data);
                        }}
                        loading={loading || submitting}
                        // reset timeout when a checkbox is clicked
                        onChange={() => setSecondsRemaining(TIMEOUT_SECONDS)}
                        refetch={refetch}
                    />
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};

export const NotificationsRoot: React.FC = () => {
    const history = useHistory();
    const location = useLocation();
    const session = useSession();
    const token = PracticeUtils.getParsedQueryProperty(`prefToken`, location.search);
    const impersonateToken = PracticeUtils.getParsedQueryProperty(`impersonateToken`, location.search);
    const {
        data: sessionData,
        refetch: sessionRefetch,
        loading: sessionLoading,
        error: sessionError,
    } = useGetLinkedDoctorPreferencesQuery({
        skip: !session,
    });
    const {
        data: tokenData,
        refetch: tokenRefetch,
        loading: tokenLoading,
        error: tokenError,
    } = useGetDoctorPreferencesByTokenQuery({
        variables: { token: token ?? '' },
        skip: !token,
    });
    const data = !!token ? tokenData : sessionData;
    const refetch = !!token ? tokenRefetch : sessionRefetch;
    const loading = !!token ? tokenLoading : sessionLoading;
    const error = !!token ? tokenError : sessionError;
    const preferences = data?.preferences;
    if ((!impersonateToken && !token) || error) {
        history.push('/login');
        return null;
    }
    return preferences ? (
        <NotificationsTokenAuthenticated
            token={token ?? ''}
            preferences={preferences}
            loading={loading}
            refetch={refetch}
        />
    ) : (
        <div>Loading...</div>
    );
};
