import { useTryInFeedbackAction } from '../state/TryInFeedback.action';
import { useTryInFeedbackPropSelector } from '../state/TryInFeedback.selectors';
import type { TryInFeedbackItemGroup } from '../state/TryInFeedback.types';
import { TryInFeedbackUtils } from '../utils/TryInFeedback.util';
import { useCreateReturnForTryInMutation, usePlaceLabOrderFromTryInMutation } from '@orthly/graphql-react';
import type { LabsGqlTryInFeedbackDetailInput } from '@orthly/graphql-schema';
import { LabsGqlOrderItemArch, OrderItemV2InputUtils } from '@orthly/graphql-schema';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemArch } from '@orthly/items';
import type { ICartItemV2DTO } from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

// maps "other" free text inputs into the item notes property
function getNewItemFromGroup(group: TryInFeedbackItemGroup): ICartItemV2DTO {
    const item = group.newItem;
    const otherFeedbackDetails = group.feedbackDetails.filter(
        detail => detail.category === 'Other' && detail.description,
    );
    if (!otherFeedbackDetails.length) {
        return item;
    }
    const itemArch = CartItemV2Utils.itemIsType(group.orderItem, [
        LabOrderItemSKUType.Partial,
        LabOrderItemSKUType.Denture,
    ])
        ? TryInFeedbackUtils.getItemArch(group.orderItem)
        : undefined;
    const otherFeedbackNotes = otherFeedbackDetails
        .map(detail => `${itemArch === OrderItemArch.Dual ? `${detail.arch} Arch: ` : ''}${detail.description}`)
        .join('\n');
    const itemNotes = item.item_notes ? `${item.item_notes}\n\n${otherFeedbackNotes}` : otherFeedbackNotes;
    return { ...item, item_notes: itemNotes };
}

export function useSubmitTryInFeedback() {
    const setSubmissionData = useTryInFeedbackAction('SET_SUBMISSION_DATA');
    const { orderId, itemGroups, copiedOrderItems } = useTryInFeedbackPropSelector([
        'orderId',
        'itemGroups',
        'copiedOrderItems',
    ]);

    const [rawSubmitReturn] = useCreateReturnForTryInMutation();
    const { submit: submitReturn, submitting: submittingReturn } = useChangeSubmissionFn(rawSubmitReturn, {
        onSuccess: result => setSubmissionData({ submissionData: { return: result.data?.createReturnForTryIn } }),
    });
    const [rawSubmitOrder] = usePlaceLabOrderFromTryInMutation();
    const { submit: submitOrder, submitting: submittingOrder } = useChangeSubmissionFn(rawSubmitOrder, {
        onSuccess: result =>
            setSubmissionData({ submissionData: { newOrderId: result.data?.placeLabOrderFromTryIn.id } }),
    });

    const submit = React.useCallback(async () => {
        const newOrderItems = [
            ...itemGroups.map(group => getNewItemFromGroup(group)),
            ...(copiedOrderItems ?? []).map(item => _.omit(item, ['id', 'pricing']) as ICartItemV2DTO),
        ];
        const new_order_items_v2_by_sku = OrderItemV2InputUtils.getOrderItemV2InputBySKU(newOrderItems);
        if (!orderId || !new_order_items_v2_by_sku) {
            return;
        }
        const item_ids = [...itemGroups.map(group => group.itemId), ...(copiedOrderItems ?? []).map(item => item.id)];
        const try_in_feedback_details: LabsGqlTryInFeedbackDetailInput[] = itemGroups.flatMap(group =>
            group.feedbackDetails.map(detail => ({
                item_id: group.itemId,
                arch: LabsGqlOrderItemArch[detail.arch],
                category: detail.category,
                details: detail.description ?? 'N/A',
            })),
        );

        if (TryInFeedbackUtils.needsReturn({ itemGroups })) {
            await submitReturn({
                variables: {
                    data: {
                        new_order_items_v2_by_sku,
                        item_ids,
                        try_in_feedback_details,
                        order_id: orderId,
                    },
                },
            });
        } else {
            await submitOrder({
                variables: {
                    data: {
                        new_order_items_v2_by_sku,
                        try_in_feedback_details,
                        original_order_id: orderId,
                    },
                },
            });
        }
    }, [orderId, itemGroups, copiedOrderItems, submitReturn, submitOrder]);

    return { submit, submitting: submittingReturn || submittingOrder };
}
