import { HORIZONTAL_NAV_HEIGHT, IconButtonNavMenu } from '../HorizontalNav';
import { DandyNotificationsTabs } from './DandyNotificationsTabs';
import { sortUpdateNotifications, sortUrgentNotifications } from './utils/DandyNotifications.utils';
import type { GenerateNotificationArgs } from './utils/DandyNotificationsDataTransformer';
import { generateNotifications } from './utils/DandyNotificationsDataTransformer';
import { LoadBlocker } from '@orthly/ui';
import type { PopperPlacementType } from '@orthly/ui-primitives';
import { stylesFactory, Icon, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        height: 688,
        width: 512,
        overflowY: 'scroll',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: '100%',
        },
    },
}));

const useFixMuiTabs = (open: boolean) => {
    React.useEffect(() => {
        if (open) {
            // mui tabs has known bug. when it's used in modal,
            // tab's bottom indicator width is not calculated correctly
            const timer = setTimeout(() => {
                window.dispatchEvent(new CustomEvent('resize'));
            }, 0);
            return () => clearTimeout(timer);
        }
    }, [open]);
};

const useNotificationData = ({
    loading,
    notificationData,
    doctorMatches,
    notificationActionOptions,
    isMobile,
}: GenerateNotificationArgs) => {
    return React.useMemo(() => {
        const { urgents, updates } = generateNotifications({
            loading,
            notificationData,
            doctorMatches,
            notificationActionOptions,
            isMobile,
        });
        return {
            urgents,
            updates,
            sortedUrgents: sortUrgentNotifications(urgents),
            sortedUpdates: sortUpdateNotifications(updates),
            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
            // eslint-disable-next-line no-nested-ternary
            badgeCount: urgents.length > 0 ? urgents.length : updates.length > 0 ? updates.length : 0,
        };
    }, [loading, notificationData, notificationActionOptions, doctorMatches, isMobile]);
};

interface DandyNotificationsPresenterProps extends GenerateNotificationArgs {
    inboxRefetch: () => Promise<void>;
    menuPlacement?: PopperPlacementType;
}

export const DandyNotificationsPresenter: React.VFC<DandyNotificationsPresenterProps> = props => {
    const {
        isMobile,
        loading,
        doctorMatches,
        notificationData,
        inboxRefetch,
        notificationActionOptions,
        menuPlacement,
    } = props;

    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { urgents, updates, sortedUrgents, sortedUpdates, badgeCount } = useNotificationData({
        loading,
        notificationData,
        doctorMatches,
        notificationActionOptions,
        isMobile,
    });
    useFixMuiTabs(open);

    return isMobile ? (
        <Grid container direction={'column'} wrap={'nowrap'} className={classes.root}>
            <LoadBlocker blocking={loading} loader={'dandy'} ContainerProps={{ style: { width: '100vw' } }}>
                <DandyNotificationsTabs
                    setOpen={setOpen}
                    sortedUrgentNotifications={sortedUrgents}
                    sortedUpdateNotifications={sortedUpdates}
                    urgentNotificationsCount={urgents.length}
                    updateNotificationsCount={updates.length}
                    inboxRefetch={inboxRefetch}
                    notificationActionOptions={notificationActionOptions}
                />
            </LoadBlocker>
        </Grid>
    ) : (
        <IconButtonNavMenu
            title={'Notifications'}
            icon={<Icon icon={'NotificationsNone'} />}
            badgeCount={badgeCount}
            open={open}
            setOpen={setOpen}
            headerBorder={false}
            critical={urgents.length > 0}
            navbarHeight={HORIZONTAL_NAV_HEIGHT}
            menuPlacement={menuPlacement}
        >
            <LoadBlocker blocking={loading} loader={'dandy'} ContainerProps={{ className: classes.root }}>
                {!loading && (
                    <Grid container direction={'column'} wrap={'nowrap'}>
                        <DandyNotificationsTabs
                            setOpen={setOpen}
                            sortedUrgentNotifications={sortedUrgents}
                            sortedUpdateNotifications={sortedUpdates}
                            urgentNotificationsCount={urgents.length}
                            updateNotificationsCount={updates.length}
                            inboxRefetch={inboxRefetch}
                            notificationActionOptions={notificationActionOptions}
                        />
                    </Grid>
                )}
            </LoadBlocker>
        </IconButtonNavMenu>
    );
};
