import { selectClasses, styled } from '@mui/material';
import type { LabsGqlCartDeliveryOption, LabsGqlCartSlaModifier } from '@orthly/graphql-schema';
import {
    FormControl,
    FormControlLabel,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    Text,
} from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const StyledFormControlLabel = styled(FormControlLabel)({
    [`& .${selectClasses.root}`]: {
        marginRight: 0,
        paddingBottom: 8,
    },
    [`& .MuiFormControlLabel-label`]: {
        display: 'inline-flex',
        marginLeft: 8,
        width: '100%',
    },
});

const StyledFormControl = styled(FormControl)({
    width: '100%',
    padding: 16,
    paddingBottom: 8,
});

interface DateDisplayProps {
    option: LabsGqlCartDeliveryOption;
}

const DateRadioLabel: React.VFC<DateDisplayProps> = ({ option }) => {
    return (
        <StyledFormControlLabel
            key={option.sla_modifier}
            value={option.sla_modifier}
            control={<Radio color={'secondary'} style={{ margin: 4 }} />}
            label={
                <>
                    <Text variant={'body2'} color={'BLACK'} medium component={'span'}>
                        {moment(option.delivery_date).format('dddd, MMMM D')}
                    </Text>
                    {option.info_text && (
                        <Text variant={'body2'} color={'GRAY'} component={'span'} style={{ marginLeft: 'auto' }}>
                            {option.info_text}
                        </Text>
                    )}
                </>
            }
        />
    );
};

interface DeliveryDateSelectProps {
    options: LabsGqlCartDeliveryOption[];
    value?: LabsGqlCartSlaModifier;
    onSelect: (option: LabsGqlCartSlaModifier) => void;
}

export const DeliveryDateSelect: React.VFC<DeliveryDateSelectProps> = ({ options, value, onSelect }) => {
    return (
        <StyledFormControl variant={'standard'}>
            <RadioGroup
                // since this is an uncontrolled component it doesn't respond to value changing so we force a rerender
                key={value}
                value={value}
                onChange={(_e, val) => {
                    const newValue = options.find(o => o.sla_modifier === val);
                    if (newValue) {
                        onSelect(newValue.sla_modifier);
                    }
                }}
            >
                {options.map(option => {
                    return <DateRadioLabel key={option.sla_modifier} option={option} />;
                })}
            </RadioGroup>
        </StyledFormControl>
    );
};
