import type { DentureCheckoutState } from './dentures-checkout.types';
import { DentureCheckoutStep } from './dentures-checkout.types';
import { DentureType } from '@orthly/items';

const dentureFieldComplete: {
    [Key in keyof DentureCheckoutState['fields']]-?: (state: DentureCheckoutState) => boolean;
} = {
    dentureType: state => !!state.fields.dentureType,
    dentureArch: state => !!state.fields.dentureArch,
    waxRimPhotoUrls: ({ fields: { waxRimPhotoUrls } }) => (waxRimPhotoUrls ?? []).length > 0,
    dentureOptions: ({ fields: { dentureOptions } }) =>
        !!dentureOptions &&
        !!dentureOptions.fabrication &&
        !!dentureOptions.teethQuality &&
        !!dentureOptions?.shades?.find(shade => shade.name === 'tissue') &&
        !!dentureOptions?.shades?.find(shade => shade.name === 'base'),
    dentureStyle: ({ fields: { dentureStyle } }) => !!dentureStyle,
    festooningLevel: ({ fields: { festooningLevel } }) => !!festooningLevel,
    handleDiastema: ({ fields: { handleDiastema } }) => !!handleDiastema,
    biteAdjustment: ({ fields: { biteAdjustment } }) => !!biteAdjustment,
    midlineCorrection: ({ fields: { midlineCorrection } }) => !!midlineCorrection,
    dentureNotes: () => true, // optional
    waxRimContinuation: () => true,
    denturePhotos: () => true,
    numberOfSpares: () => true, // optional
    isExactCopy: state => state.fields.dentureType !== DentureType.Copy || state.fields.isExactCopy !== undefined,
    exactCopyNotes: () => true, // optional
    aestheticOptions: () => true, // optional

    // For implant-supported wax rim continuations or copy dentures, collar heights are required.
    implantSupport: ({ fields: { dentureType, implantSupport } }) =>
        dentureType === DentureType.WaxRim ||
        !implantSupport ||
        !implantSupport.isImplantSupported ||
        (!!implantSupport.collarHeightsMm && implantSupport.collarHeightsMm.length !== 0),
};

type StepProgression = { type: 'CHANGE_STEP'; step: DentureCheckoutStep } | { type: 'NOT_COMPLETE'; reason: string };

const stepNotComplete = (reason: string): StepProgression => ({ reason, type: 'NOT_COMPLETE' });
const goToNextStep = (step: DentureCheckoutStep): StepProgression => ({ step, type: 'CHANGE_STEP' });

type StepProgressionMap = {
    [step in DentureCheckoutStep]: (state: DentureCheckoutState) => StepProgression;
};

const basicStepProgression = (
    checkKey: keyof DentureCheckoutState['fields'],
    nextStep: DentureCheckoutStep,
    reasonNotComplete: string,
): StepProgressionMap[DentureCheckoutStep] => {
    return state => {
        if (!dentureFieldComplete[checkKey](state)) {
            return stepNotComplete(reasonNotComplete);
        }

        return goToNextStep(nextStep);
    };
};

const denturesStepProgressionMap: StepProgressionMap = {
    PatientInfoStep: state => {
        // wax rim continuation will not choose a denture type
        if (state.fields.waxRimContinuation) {
            return goToNextStep(DentureCheckoutStep.InitialNonWaxRimOrderDentureOptionsStep);
        }
        return goToNextStep(DentureCheckoutStep.InitialOrderChooseTypeStep);
    },

    InitialOrderChooseTypeStep: state => {
        if (!dentureFieldComplete.dentureType(state) || !dentureFieldComplete.dentureArch(state)) {
            return stepNotComplete('Please select a denture type');
        }

        if (!dentureFieldComplete.isExactCopy(state)) {
            return stepNotComplete('Please select exact copy option');
        }

        const { dentureType } = state.fields;

        if (dentureType === DentureType.WaxRim) {
            return goToNextStep(DentureCheckoutStep.OrderNotesStep);
        }

        return goToNextStep(DentureCheckoutStep.InitialNonWaxRimOrderDentureOptionsStep);
    },

    InitialNonWaxRimOrderDentureOptionsStep: basicStepProgression(
        'dentureOptions',
        DentureCheckoutStep.InitialNonWaxRimOrderDentureStyleStep,
        'Please choose denture options',
    ),
    InitialNonWaxRimOrderDentureStyleStep: basicStepProgression(
        'dentureStyle',
        DentureCheckoutStep.DentureFestooningStep,
        'Please select a denture style',
    ),
    DentureFestooningStep: basicStepProgression(
        'festooningLevel',
        DentureCheckoutStep.DentureAestheticStep,
        'Please select a festooning level',
    ),
    DentureAestheticStep: state => {
        if (
            !dentureFieldComplete.handleDiastema(state) ||
            !dentureFieldComplete.biteAdjustment(state) ||
            !dentureFieldComplete.midlineCorrection(state)
        ) {
            return stepNotComplete('Please select your aesthetic and functional options');
        }

        return goToNextStep(DentureCheckoutStep.InitialNonWaxRimOrderPhotosChoiceStep);
    },

    InitialNonWaxRimOrderPhotosChoiceStep: () => {
        // removed the conditional, we want to make photos optional
        return goToNextStep(DentureCheckoutStep.InitialNonWaxRimOrderPhotosReviewStep);
    },
    InitialNonWaxRimOrderPhotosReviewStep: state => {
        if (!dentureFieldComplete.denturePhotos(state)) {
            return stepNotComplete(`Please finish uploading the required images.'`);
        }
        return goToNextStep(DentureCheckoutStep.OrderNotesStep);
    },
    OrderNotesStep: state => {
        if (!dentureFieldComplete.implantSupport(state)) {
            return stepNotComplete(`Please provide the collar heights of the implant abutments.`);
        }
        const { dentureType } = state.fields;
        if (dentureType === DentureType.WaxRim) {
            return goToNextStep(DentureCheckoutStep.Summary);
        }
        return goToNextStep(DentureCheckoutStep.AddSpareDenturesStep);
    },
    AddSpareDenturesStep: () => {
        return goToNextStep(DentureCheckoutStep.Summary);
    },
    Summary: () => goToNextStep(DentureCheckoutStep.Summary),
};

export function denturesStepProgression(state: DentureCheckoutState): StepProgression {
    return denturesStepProgressionMap[state.currentStep](state);
}
