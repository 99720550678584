import type { FormikProps } from 'formik';
import _ from 'lodash';
import React from 'react';

interface AutoSaveProps {
    debounceMs: number;
    formik: FormikProps<any>;
    setIsSaved?: (isSaved: boolean) => void;
}

// being used in CustomSubmit in QuickForm, which expects a component
export const AutoSave: React.VFC<AutoSaveProps> = props => {
    const { debounceMs, formik, setIsSaved } = props;

    const debouncedSubmit = React.useMemo(
        () =>
            _.debounce(async () => {
                formik.submitForm();
                return setIsSaved?.(true);
            }, debounceMs),
        [debounceMs, formik, setIsSaved],
    );

    React.useEffect(() => {
        if (formik.isValid && formik.dirty && !formik.isSubmitting) {
            void debouncedSubmit();
        }
        return debouncedSubmit.cancel;
    }, [debouncedSubmit, formik.dirty, formik.isSubmitting, formik.isValid, setIsSaved]);

    // returning null, but can be updated to return a message such as <>Saving...</>
    return null;
};
