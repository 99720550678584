import { OrderDetailBody } from './OrderDetailBody';
import { useOrderDetailSection } from './OrderDetailSectionProvider';
import { OrderDetailTimelineSection } from './components/OrderDetailBodySections/OrderDetailTimelineSection';
import { OrderDetailTopBarV2 } from './components/OrderDetailTopBarV2';
import type { LabsGqlChatMessageDtoFragment, LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import type { ListChatMessagesQueryHookResult, SingleLabOrderQueryHookResult } from '@orthly/graphql-react';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OrderDetailMobileRootProps {
    chatMessages: LabsGqlChatMessageDtoFragment[];
    order: LabsGqlSingleLabOrderFragment;
    refetchMessages: ListChatMessagesQueryHookResult['refetch'];
    refetch: SingleLabOrderQueryHookResult['refetch'];
}

export const OrderDetailMobileRoot: React.FC<OrderDetailMobileRootProps> = props => {
    const { chatMessages, order, ...others } = props;

    const { tabValue } = useOrderDetailSection();

    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ height: 'auto' }}>
            <OrderDetailTopBarV2 order={order} />

            {tabValue === 'timeline' ? (
                <OrderDetailTimelineSection order={order} />
            ) : (
                <OrderDetailBody order={order} {...others} />
            )}
        </Grid>
    );
};
