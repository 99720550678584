import { BadgeStyled } from '../../../../components/BadgeStyled';
import { usePracticeAppSelector } from '../../../../redux';
import { useChatAction } from '../../../chat/chat-state/chat.actions';
import { useCheckoutScrollToContainer } from '../../Checkout.util';
import { CheckoutSidebarRootV3 } from '../../components/CheckoutSidebarV3/CheckoutSidebarRootV3';
import { CheckoutDisplayUtils } from '../../state/CheckoutDisplayUtils';
import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, useScreenIsMobile, createStyles, makeStyles, Button, Grid, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        preSummaryMain: {
            position: 'relative',
            padding: `0 ${theme.typography.pxToRem(48)} 64px`,
            height: 'calc(100vh - 64px)',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            overflowY: 'auto',
            [theme.breakpoints.down('md')]: {
                padding: `0 ${theme.typography.pxToRem(24)} 56px`,
                flexDirection: 'row',
                flexWrap: 'wrap',
                alignContent: 'flex-start',
                overflowX: 'hidden',
            },
            scrollBehavior: `smooth`,
        },
        titleRoot: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            background: '#fff',
            padding: '32px 0 0',
            position: 'relative',
            height: 'fit-content',
            // prevent scroll up from going over the title
            zIndex: 2,
        },
        title: {
            position: 'relative',
            paddingBottom: 8,
        },
        titleAction: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-end',
        },
        helpButton: {
            color: FlossPalette.GRAY,
            marginBottom: 16,
        },
        sidebarWrapper: {
            height: '100vh',
            overflowY: 'scroll',
            backgroundColor: FlossPalette.WHITE,
            // Counter .indicator's negative margin: (ref. CheckoutSidebar.tsx)
            paddingLeft: +(20 - 2) / 2,
        },
    }),
);

interface CheckoutTitleProps {
    title: React.ReactChild;
    titleAction: React.ReactChild | null;
}

export const CheckoutTitle: React.VFC<CheckoutTitleProps> = ({ title, titleAction }) => {
    const classes = useStyles();
    const unreadChatCount = usePracticeAppSelector(s => s.ui.unreadChatCount);
    const isMobile = useScreenIsMobile();
    const { value: chatAvailability } = useFeatureFlag('chatAvailability');
    const openZDAction = useChatAction('OPEN_ZD_CHAT');

    return (
        <Grid container className={classes.titleRoot}>
            <Text variant={'h5'} className={classes.title}>
                {title}
            </Text>
            <Grid className={classes.titleAction}>
                {titleAction}
                {!isMobile && (
                    <BadgeStyled count={unreadChatCount}>
                        <Button
                            variant={'text'}
                            startIcon={'HelpIcon'}
                            className={classes.helpButton}
                            onClick={() => openZDAction(chatAvailability)}
                        >
                            Get Help
                        </Button>
                    </BadgeStyled>
                )}
            </Grid>
        </Grid>
    );
};

interface CheckoutMainScreenContainerProps {
    screens: React.ReactChild;
    titleProps: CheckoutTitleProps;
}

export const CheckoutMainScreensContainer: React.VFC<CheckoutMainScreenContainerProps> = ({ screens, titleProps }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const scroll_ref = React.useRef<HTMLDivElement | null>(null);
    useCheckoutScrollToContainer(scroll_ref);
    return (
        <>
            <Grid container item xs={isMobile ? 12 : 8} id={CheckoutDisplayUtils.PRE_SUMMARY_MAIN_ID}>
                <Grid container item className={classes.preSummaryMain} ref={scroll_ref}>
                    <CheckoutTitle {...titleProps} />
                    {screens}
                </Grid>
            </Grid>
            {!isMobile && (
                <Grid container item xs={4} className={classes.sidebarWrapper}>
                    <CheckoutSidebarRootV3 />
                </Grid>
            )}
        </>
    );
};
