import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder, LabsGqlDoctorRequestFragment } from '@orthly/graphql-operations';
import { useGetDoctorRequestQuery } from '@orthly/graphql-react';
import { LabsGqlDoctorRequestOptionType, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';

const VeneerAskDoctorGetActiveDoctorRequestId_Query = graphql(`
    query VeneerAskDoctorGetActiveDoctorRequestId_Query($lab_order_id: String!) {
        getActiveScanReviewByLabOrderId(lab_order_id: $lab_order_id) {
            current_doctor_request_id
        }
    }
`);

const useGetActiveDoctorRequestId = (order: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'id'>) => {
    const { data: activeScanReviewData } = useQuery(VeneerAskDoctorGetActiveDoctorRequestId_Query, {
        variables: {
            lab_order_id: order.id,
        },
        // We only need to query the scan review if the current task is a scan rejection.
        skip: order.fulfillment_workflow.active_task?.__typename !== 'ResolveScanRejectionWorkflowTask',
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    if (order.fulfillment_workflow.active_task?.__typename === 'ResolveHoldWorkflowTask') {
        return order.fulfillment_workflow.active_task.configuration.doctor_request_id;
    }

    if (order.fulfillment_workflow.active_task?.__typename === 'ResolveScanRejectionWorkflowTask') {
        return activeScanReviewData?.getActiveScanReviewByLabOrderId?.current_doctor_request_id ?? null;
    }

    return null;
};

const getHoldInformationFromDoctorRequestResponse = (
    doctorRequest: LabsGqlDoctorRequestFragment | null | undefined,
) => {
    if (doctorRequest) {
        const selectedResponseOptionId = doctorRequest.doctor_request_response?.selected_doctor_request_option_id;
        const selectedOption = doctorRequest.options.find(o => o.id === selectedResponseOptionId);

        if (selectedOption?.type === LabsGqlDoctorRequestOptionType.NeedsAdditionalScans) {
            return {
                needsAdditionalScans: true,
            };
        }
    }

    return {
        needsAdditionalScans: false,
    };
};

export type DoctorRequestHoldInfo = {
    needsAdditionalScans: boolean;
    isDoctorRequestLoading: boolean;
};

export const useDoctorRequestHoldInfo = (
    order: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'status' | 'id'>,
): DoctorRequestHoldInfo => {
    const isOrderOnHold = order.status === LabsGqlLabOrderStatus.OnHold;
    const doctorRequestId = useGetActiveDoctorRequestId(order);
    const isQualifyingOrder = isOrderOnHold && doctorRequestId;

    const { data, loading } = useGetDoctorRequestQuery({
        variables: { doctorRequestId: doctorRequestId ?? '' },
        skip: !isQualifyingOrder,
        fetchPolicy: 'no-cache',
    });

    if (!isQualifyingOrder) {
        return {
            isDoctorRequestLoading: false,
            needsAdditionalScans: false,
        };
    }

    const { needsAdditionalScans } = getHoldInformationFromDoctorRequestResponse(data?.getDoctorRequest);

    return {
        isDoctorRequestLoading: loading,
        needsAdditionalScans,
    };
};
