import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const SleepApneaTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'dorsal',
                type: 'Dorsal',
                price: getPrice('Sleep Apnea - Dorsal'),
            },
            {
                id: 'herbst',
                type: 'Herbst',
                price: getPrice('Sleep Apnea - Herbst'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'other-removables-sleep-apnea'}
            title={'Sleep Apnea Appliances'}
            columns={[
                {
                    id: 'type',
                    title: 'Type',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
