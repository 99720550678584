import { useAlignerCheckoutSelector } from '../../../../redux';
import { selectAlignerCaseStage, selectAlignerRetainerPrice } from '../../state/aligners-checkout.selectors';
import { AlignerCaseStage } from '../../state/reducers/aligners-checkout.types';
import { AlignerCheckoutDisplayUtils } from '@orthly/dentin';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import { stylesFactory, useScreenIsMobileOrVerticalTablet, Divider, Grid, Text } from '@orthly/ui-primitives';
import { useFirebasePreview } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: { padding: '32px 0', flexDirection: 'column' },
    title: {
        padding: '32px 0',
    },
    imgWrapper: {
        // LoadBlockerContainer will have a position:absolute LoadBlockerLoader child
        position: 'relative',
        alignContent: 'center',
        justifyContent: 'center',
        width: 124,
        height: 124,
        overflow: 'hidden',
        borderRadius: 8,
    },
    img: {
        padding: 0,
        maxWidth: 'unset',
        minWidth: '100%',
        minHeight: '100%',
        flexShrink: 0,
        maxHeight: '175%',
    },
}));

const AlignerCheckoutPhoto: React.VFC<{ filePath: string; type: string }> = ({ filePath, type }) => {
    const classes = useStyles();
    const { result: imgSrc, loading: srcLoading } = useFirebasePreview(filePath);
    return (
        <Grid item>
            <LoadBlocker blocking={srcLoading || !imgSrc} ContainerProps={{ className: classes.imgWrapper }}>
                <img src={imgSrc} alt={type} className={classes.img} />
            </LoadBlocker>
        </Grid>
    );
};

export const AlignerPhotosSection: React.VFC = () => {
    const photos = useAlignerCheckoutSelector(s => s.alignerImages);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles();
    if (!photos || isMobile) {
        return null;
    }
    return (
        <Grid container className={classes.root}>
            <Divider style={{ width: 904 }} />
            <Text variant={'h5'} className={classes.title}>
                Patient photos
            </Text>
            <Grid container spacing={4}>
                {Object.entries(photos).map(
                    ([type, filePath]) =>
                        type !== '__typename' &&
                        filePath && <AlignerCheckoutPhoto type={type} filePath={filePath} key={type} />,
                )}
            </Grid>
        </Grid>
    );
};

const AlignerCheckoutRetainerSummaryItemsColumn: React.VFC = () => {
    const retainer = useAlignerCheckoutSelector(s => s.retainer);
    const price = useAlignerCheckoutSelector(selectAlignerRetainerPrice);
    if (!retainer) {
        return null;
    }

    return (
        <Grid container item xs={12} md={6} style={{ marginTop: 24, rowGap: 4 }} direction={'column'} wrap={'nowrap'}>
            <Text variant={'body2'} medium>
                Retainers
            </Text>
            <Text variant={'body2'}>Number of sets: {retainer.quantity}</Text>
            <Text variant={'body2'}>Price: {price ? Format.currency(price, 'cents', false) : 'Free'}</Text>
            <Text variant={'body2'}>Restorations: {retainer.hasRestorations ? 'yes' : 'no'}</Text>
            <Text variant={'body2'}>Fixed lingual retainer: {retainer.isFixedLingual ? 'yes' : 'no'}</Text>
        </Grid>
    );
};

const AlignerCheckoutRegularSummaryItemsColumn: React.VFC = () => {
    const alignerCheckout = useAlignerCheckoutSelector(s => s);

    return (
        <Grid container item xs={12} md={6} style={{ marginTop: 24 }} direction={'column'} spacing={1}>
            <Grid item>
                <Text variant={'body2'} style={{ fontWeight: 500 }}>
                    1x Clear Aligners
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Arch: {alignerCheckout.alignerArch} ({alignerCheckout.alignerTreatmentArea})
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Movement Restrictions:{' '}
                    {AlignerCheckoutDisplayUtils.getTeethSelectContent(alignerCheckout.movementRestrictedTeeth)}
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Attachment Restrictions:{' '}
                    {AlignerCheckoutDisplayUtils.getTeethSelectContent(alignerCheckout.attachmentRestrictedTeeth)}
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Extractions: {AlignerCheckoutDisplayUtils.getTeethSelectContent(alignerCheckout.extractionTeeth)}
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Spacing:{' '}
                    {AlignerCheckoutDisplayUtils.getInterproximalSpaceSizesContent(
                        alignerCheckout.interproximalSpaceSizes,
                    )}
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Crowding Options:{' '}
                    {AlignerCheckoutDisplayUtils.getCrowdingResolutionContent(alignerCheckout.crowdingResolution)}
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Bite Concerns: {AlignerCheckoutDisplayUtils.getBiteConcernsContent(alignerCheckout.biteConcerns)}
                </Text>
            </Grid>
            {alignerCheckout.alignerNotes && (
                <Grid item>
                    <Text variant={'body2'}>Notes: {alignerCheckout.alignerNotes}</Text>
                </Grid>
            )}
        </Grid>
    );
};

export const AlignerCheckoutSummaryItemsColumn: React.VFC = () => {
    const stage = useAlignerCheckoutSelector(selectAlignerCaseStage);
    switch (stage) {
        case AlignerCaseStage.InitialAligner:
        case AlignerCaseStage.Refinement:
            return <AlignerCheckoutRegularSummaryItemsColumn />;
        case AlignerCaseStage.Retainer:
            return <AlignerCheckoutRetainerSummaryItemsColumn />;
        default:
            return null;
    }
};
