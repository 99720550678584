import { HelpBoxWithImage } from '../../components/HelpBoxWithImage';
import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { SettingsSection } from '../../components/SettingsSection';
import type { DoctorPrefFormProps } from '../../state/usePracticeStaffControls';
import { useContactInfoIsComplete, usePracticeStaffControls } from '../../state/usePracticeStaffControls';
import { NotificationMethodSelectForm } from './NotificationMethodSelectForm';
import type { NotificationPrefsGroupForwarding } from './Notifications.types';
import {
    NotificationNames,
    NotificationPreferencesNames,
    NotificationPrefsGroupReceivers,
    NotificationPrefsGroupSenders,
} from './Notifications.types';
import type { ApolloQueryResult } from '@apollo/client';
import type {
    LabsGqlDoctorPreferencesFragment,
    LabsGqlGetDoctorPreferencesByTokenQuery,
} from '@orthly/graphql-operations';
import { useGetPreferencesByIdsQuery } from '@orthly/graphql-react';
import { Format } from '@orthly/runtime-utils';
import { useScreenIsMobile, stylesFactory, Text, Button, FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

export type NotificationSettingsProps = Omit<DoctorPrefFormProps, 'submitting'> & {
    setIsSaved?: (isSaved: boolean) => void;
};

function useGetSendersOrReceivers(prefs: LabsGqlDoctorPreferencesFragment, isSenderGroup: boolean) {
    const forwardingPrefSettings = prefs.grouped_notification_forwarding_preferences;
    const forwardingPrefFields = isSenderGroup ? NotificationPrefsGroupSenders : NotificationPrefsGroupReceivers;
    const forwardingPrefIdsMapping: Record<string, string[]> = Object.values(forwardingPrefFields).reduce(
        (acc, field) => {
            return { ...acc, [field]: forwardingPrefSettings[field as NotificationPrefsGroupForwarding] ?? [] };
        },
        {},
    );
    const forwardingPrefIds = new Set(Object.values(forwardingPrefIdsMapping).flatMap(prefIds => prefIds));
    const { data: forwardingPrefs } = useGetPreferencesByIdsQuery({
        variables: {
            pref_ids: Array.from(forwardingPrefIds) ?? [],
        },
        skip: forwardingPrefIds.size === 0,
    });
    const forwardingPrefsMapping: Record<string, LabsGqlDoctorPreferencesFragment[]> = _.mapValues(
        forwardingPrefFields,
        () => [],
    );
    forwardingPrefs?.preferences.forEach(pref => {
        Object.entries(forwardingPrefIdsMapping).forEach(([fieldName, prefIds]) => {
            if (prefIds.includes(pref.id)) {
                forwardingPrefsMapping[fieldName]?.push(pref);
            }
        });
    });
    return forwardingPrefsMapping;
}

export const DoctorNotificationSettings: React.VFC<
    NotificationSettingsProps & { refetch?: () => Promise<ApolloQueryResult<LabsGqlGetDoctorPreferencesByTokenQuery>> }
> = props => {
    const { employeePrefs, submit, loading, onChange, setIsSaved, refetch, contactEmailSet, contactPhoneSet } = props;
    const senders = useGetSendersOrReceivers(employeePrefs, true);
    const receivers = useGetSendersOrReceivers(employeePrefs, false);
    const disableSmsCheckbox = !contactPhoneSet;
    const disableEmailCheckbox = !contactEmailSet;

    return (
        <>
            <SettingsSection
                title={Format.pluralizeNoun(_.capitalize(NotificationNames.status_change), 2)}
                subtitle={'Examples of notifications you’ll receive:'}
                exampleList={['Order Delays', 'Order Cancellations', 'Rush request updates']}
                required
                dropDownLayout
                settingsRowRightSideStyle={{ flexDirection: 'column' }}
                longSection={!refetch}
                sectionDividedInHalf={!!refetch}
            >
                <NotificationMethodSelectForm
                    smsFieldName={NotificationPreferencesNames.status_change_sms}
                    emailFieldName={NotificationPreferencesNames.status_change_email}
                    employeePrefs={employeePrefs}
                    submit={submit}
                    loading={loading}
                    onChange={onChange}
                    setIsSaved={setIsSaved}
                    senders={senders[NotificationPrefsGroupSenders.status_change_senders]}
                    receivers={receivers[NotificationPrefsGroupReceivers.status_change_receivers]}
                    notificationName={NotificationNames.status_change}
                    refetch={refetch}
                    disableSmsCheckbox={disableSmsCheckbox}
                    disableEmailCheckbox={disableEmailCheckbox}
                    required
                />
            </SettingsSection>
            <SettingsSection
                title={_.capitalize(NotificationNames.review_required)}
                subtitle={'Examples of notifications you’ll receive:'}
                exampleList={[
                    'Treatment plan approval',
                    'Waxup approval',
                    'Order on hold',
                    'A scan was rejected',
                    'Other confirmations',
                ]}
                required
                dropDownLayout
                settingsRowRightSideStyle={{ flexDirection: 'column' }}
                longSection={!refetch}
                sectionDividedInHalf={!!refetch}
            >
                <NotificationMethodSelectForm
                    smsFieldName={NotificationPreferencesNames.review_required_sms}
                    emailFieldName={NotificationPreferencesNames.review_required_email}
                    employeePrefs={employeePrefs}
                    submit={submit}
                    loading={loading}
                    onChange={onChange}
                    setIsSaved={setIsSaved}
                    senders={senders[NotificationPrefsGroupSenders.review_required_senders]}
                    receivers={receivers[NotificationPrefsGroupReceivers.review_required_receivers]}
                    notificationName={NotificationNames.review_required}
                    refetch={refetch}
                    disableSmsCheckbox={disableSmsCheckbox}
                    disableEmailCheckbox={disableEmailCheckbox}
                    required
                />
            </SettingsSection>
            <SettingsSection
                title={Format.pluralizeNoun(_.capitalize(NotificationNames.order_tracking), 2)}
                subtitle={'Examples of notifications you’ll receive:'}
                exampleList={[
                    'Delivery date changes',
                    'Fabrication updates',
                    'Shipping updates',
                    'Scan body updates',
                    'Return label is ready',
                    'Incomplete order reminder',
                    'Feedback Reminder (email only)',
                ]}
                dropDownLayout
                settingsRowRightSideStyle={{ flexDirection: 'column' }}
                longSection={!refetch}
                sectionDividedInHalf={!!refetch}
            >
                <NotificationMethodSelectForm
                    smsFieldName={NotificationPreferencesNames.order_tracking_sms}
                    emailFieldName={NotificationPreferencesNames.order_tracking_email}
                    employeePrefs={employeePrefs}
                    submit={submit}
                    loading={loading}
                    onChange={onChange}
                    setIsSaved={setIsSaved}
                    senders={senders[NotificationPrefsGroupSenders.order_tracking_senders]}
                    receivers={receivers[NotificationPrefsGroupReceivers.order_tracking_receivers]}
                    notificationName={NotificationNames.order_tracking}
                    disableSmsCheckbox={disableSmsCheckbox}
                    disableEmailCheckbox={disableEmailCheckbox}
                    refetch={refetch}
                />
            </SettingsSection>
            <SettingsSection
                title={Format.pluralizeNoun(_.capitalize(NotificationNames.communication), 2)}
                subtitle={'Examples of notifications you’ll receive:'}
                exampleList={['New message on a chat']}
                settingsRowStyle={{ borderBottom: 'none' }}
                dropDownLayout
                settingsRowRightSideStyle={{ flexDirection: 'column' }}
                longSection={!refetch}
                sectionDividedInHalf={!!refetch}
            >
                <NotificationMethodSelectForm
                    smsFieldName={NotificationPreferencesNames.communication_sms}
                    emailFieldName={NotificationPreferencesNames.communication_email}
                    employeePrefs={employeePrefs}
                    submit={submit}
                    loading={loading}
                    onChange={onChange}
                    setIsSaved={setIsSaved}
                    senders={senders[NotificationPrefsGroupSenders.communication_senders]}
                    receivers={receivers[NotificationPrefsGroupReceivers.communication_receivers]}
                    notificationName={NotificationNames.communication}
                    disableSmsCheckbox={disableSmsCheckbox}
                    disableEmailCheckbox={disableEmailCheckbox}
                    refetch={refetch}
                />
            </SettingsSection>
        </>
    );
};

const useStyles = stylesFactory(() => ({
    timezoneTextWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
}));

export const ProfileNotifications: React.FC = () => {
    const [isSaved, setIsSaved] = React.useState(false);
    const controls = usePracticeStaffControls();
    const classes = useStyles();
    const contactInfoComplete = useContactInfoIsComplete(controls?.employeePrefs);
    const contactEmailSet = !!controls?.employeePrefs?.contact_email;
    const contactPhoneSet = !!controls?.employeePrefs?.contact_phone;
    const isMobile = useScreenIsMobile();
    const history = useHistory();
    if (!controls) {
        return null;
    }
    const { employeePrefs, submit, loading, submitting } = controls;

    // NotificationTimingToggleForm is for scheduled notifications that require a time option to be set
    // NotificationMethodSelectForm is for when there are both email+sms notification methods for a notification
    // and require both the email and sms configuration fields
    // SetNotificationForm is for when there is only one notification method for a notification and requires
    // the configuration field and what type of notification method it is
    return (
        <PracticeSettingsBase
            title={'Notifications'}
            subtitle={
                <div className={classes.timezoneTextWrapper}>
                    <Text variant={'body2'} medium>
                        We only send SMS from 7a-9p in the time zone that you set in your profile settings.
                    </Text>
                    <Button
                        variant={'secondary'}
                        onClick={() => history.push('/my_profile/settings')}
                        endIcon={'ChevronRight'}
                    >
                        View profile settings
                    </Button>
                </div>
            }
            subtitleStyle={{
                background: FlossPalette.TAN,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
                padding: '8px 24px',
            }}
            titleAction={
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {submitting ? 'Saving changes...' : isSaved ? 'All changes saved' : null}
                </Text>
            }
            Content={
                <DoctorNotificationSettings
                    contactPhoneSet={contactPhoneSet}
                    contactEmailSet={contactEmailSet}
                    employeePrefs={employeePrefs}
                    submit={submit}
                    loading={loading}
                    setIsSaved={setIsSaved}
                />
            }
            rightContent={
                contactInfoComplete || isMobile ? undefined : (
                    <HelpBoxWithImage
                        title={'Less delays, more smiles'}
                        subtitle={'Receive notifications about orders, delivery updates and more'}
                        imgTitle={'notifications'}
                        style={{ paddingLeft: 24 }}
                    />
                )
            }
        />
    );
};
