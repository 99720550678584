import type { DoctorVars } from '../account/state/usePracticeStaffControls';
import type { ApolloQueryResult } from '@apollo/client';
import type {
    LabsGqlGetDoctorPreferencesByTokenQuery,
    LabsGqlDoctorAndLastNameFragment,
} from '@orthly/graphql-operations';
import {
    useSetDoctorNotificationPrefsMutation,
    useSendDoctorPrefsUpdatedNotificationMutation,
} from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import _ from 'lodash';
import type { MutableRefObject } from 'react';
import React from 'react';

export function useSetDoctorNotificationPrefsSubmitter(
    token: string,
    refetch: () => Promise<ApolloQueryResult<LabsGqlGetDoctorPreferencesByTokenQuery>>,
) {
    const [doctorMtn] = useSetDoctorNotificationPrefsMutation();
    const doctorMtnSubmitter = (data: DoctorVars) => doctorMtn({ variables: { data: { ...data, token } } });
    return useChangeSubmissionFn<any, [DoctorVars]>(doctorMtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Preferences updated!', {}],
        onSuccess: () => {
            void refetch();
        },
    });
}

export function useSendNotification(
    token: string,
    originalPrefs: MutableRefObject<LabsGqlDoctorAndLastNameFragment>,
    preferences: LabsGqlDoctorAndLastNameFragment,
) {
    const [sendNotifMtn] = useSendDoctorPrefsUpdatedNotificationMutation();
    return React.useCallback(
        (schedule: boolean) => {
            originalPrefs.current &&
                !_.isEqual(
                    originalPrefs.current.doctor.grouped_notification_preferences,
                    preferences.doctor.grouped_notification_preferences,
                ) &&
                void sendNotifMtn({
                    variables: {
                        schedule,
                        data: {
                            doctor_token: token,
                            doctor_id: originalPrefs.current.doctor.id,
                            original_prefs: _.omit(originalPrefs.current.doctor.grouped_notification_preferences, [
                                '__typename',
                                'marketing_email',
                                'marketing_sms',
                            ]),
                        },
                    },
                });
        },
        [originalPrefs, preferences.doctor.grouped_notification_preferences, sendNotifMtn, token],
    );
}
