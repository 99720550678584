import { IconButton, Popover, HelpOutlineIcon } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Renders a (?) icon button that opens a popover on mouseover or click.
 * The children of this component provide the content of the popover.
 * A tooltip with no children will have `visibility: "hidden"`
 *
 * Figma: https://www.figma.com/file/MrsLKK2jUrHnDjTlVKIVVs/?node-id=3%3A2
 */
export const HelpTooltip: React.FC = ({ children }) => {
    const isVisible = !!React.Children.count(children);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const isOpen = !!anchorEl;
    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const closePopover = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                edge={'end'}
                onClick={openPopover}
                onMouseEnter={openPopover}
                onMouseLeave={closePopover}
                style={{ visibility: isVisible ? 'inherit' : 'hidden', padding: 8 }}
            >
                <HelpOutlineIcon />
            </IconButton>
            <Popover
                id={'mouse-over-popover'}
                style={{ pointerEvents: 'none' }}
                open={isOpen}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                PaperProps={{ style: { maxWidth: 328, padding: 16, borderRadius: 16 } }}
                disableRestoreFocus
            >
                {children}
            </Popover>
        </div>
    );
};
