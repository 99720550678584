import React from 'react';
import { z } from 'zod';

const setNewPasswordSchema = z
    .object({
        password: z.string().min(7, { message: 'Password must be at least 7 characters' }),
        confirmedPassword: z.string(),
    })
    .refine(({ password, confirmedPassword }) => password === confirmedPassword, {
        message: 'Passwords do not match',
        path: ['confirmedPassword'],
    });

export const useSetNewPasswordValidation = ({
    password,
    confirmedPassword,
    enablePasswordEdit = true,
}: {
    password: string;
    confirmedPassword: string;
    enablePasswordEdit?: boolean;
}) => {
    const [showPasswordError, setShowPasswordError] = React.useState(false);
    const [showConfirmedPasswordError, setShowConfirmedPasswordError] = React.useState(false);

    const validation = React.useMemo(
        () => setNewPasswordSchema.safeParse({ password, confirmedPassword }),
        [password, confirmedPassword],
    );

    if (!enablePasswordEdit || validation.success) {
        return { isFormValid: true, setShowConfirmedPasswordError, setShowPasswordError };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: false,
        setShowConfirmedPasswordError,
        setShowPasswordError,
        passwordError: showPasswordError && fieldErrors.password?.[0],
        confirmedPasswordError: showConfirmedPasswordError && fieldErrors.confirmedPassword?.[0],
    };
};
