import type { SettingsContentSwitchComponent } from './SettingsContentSectionRouter';
import { SettingsContentSectionRouter } from './SettingsContentSectionRouter';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

interface PracticeSettingsRootProps {
    subNavComponent: React.ReactNode;
    Router: SettingsContentSwitchComponent;
}

const PageContainer = styled('div')(({ theme }) => ({
    alignContent: 'flex-start',
    width: '100%',
    wrap: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',

    height: '100%',
    position: 'relative',
    background: FlossPalette.WHITE,
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const ContentContainer = styled('div')({
    flexDirection: 'column',
    flexGrow: 1,
    overflowY: 'scroll',
    flexWrap: 'wrap',

    boxSizing: 'border-box',
    display: 'flex',
    flexFlow: 'column wrap',
    width: '100%',
    alignContent: 'flex-start',
});

export const PracticeSettingsRoot: React.FC<PracticeSettingsRootProps> = ({ subNavComponent, Router }) => (
    <PageContainer>
        {subNavComponent}
        <ContentContainer>
            <SettingsContentSectionRouter Switch={Router} />
        </ContentContainer>
    </PageContainer>
);
