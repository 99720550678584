import { cxHolidayNames, isHoliday } from './dandyHolidays';
import { isWeekend, isSaturday } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

const csWorkingHours = (date: Date): boolean => {
    //M-F: 7AM-10PM EST or Saturday: 9-4PM EST
    return (
        (!isWeekend(date) && date.getHours() >= 8 && date.getHours() < 21) ||
        (isSaturday(date) && date.getHours() >= 9 && date.getHours() < 16)
    );
};

export const isCsAvailable = (date: Date): boolean => {
    const zonedDate = utcToZonedTime(date, 'America/New_York');
    return !isHoliday(zonedDate, cxHolidayNames) && csWorkingHours(zonedDate);
};
