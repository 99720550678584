import { useTransparency } from '../components/TransparencyControls.hooks';
import type {
    LimitedAppearanceFilterBools,
    MainViewCameraControlsRef,
    ModelAppearance,
    PresetViewControllerFns,
} from '@orthly/dentin';
import {
    GUIDED_WAXUP_APPEARANCE_FILTER,
    INITIAL_APPEARANCE_FILTER,
    RestorativeView,
    ToothViewDirection,
    applyAppearanceFilter,
    useTeethIndices,
    useZoomToVisible,
} from '@orthly/dentin';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

function getBaseAppearance(
    currentAppearance: ModelAppearance,
    appearanceOverrides?: Partial<ModelAppearance>,
): ModelAppearance {
    return {
        ...currentAppearance,
        restorativeView: RestorativeView.CAD,
        showMarginLines: false,
        showDoctorMarginLines: false,
        restoratives: {
            ...currentAppearance.restoratives,
            CAD: currentAppearance.restoratives['CAD'].map(pma => ({
                ...pma,
                appearance: {
                    ...pma.appearance,
                    opacity: 1,
                },
            })),
        },
        ...appearanceOverrides,
    };
}

interface PresetInputs {
    activeTooth: number;
    controlRef: MainViewCameraControlsRef;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    setAppearanceFilters: React.Dispatch<React.SetStateAction<LimitedAppearanceFilterBools>>;
}

function resetModelViewer(
    presetInputs: PresetInputs,
    config: {
        setView: () => void;
        setZoom: () => void;
        appearanceOverrides?: Partial<ModelAppearance>;
        filterValueOverrides?: Partial<LimitedAppearanceFilterBools>;
    },
) {
    const { activeTooth, controlRef, setAppearance, setAppearanceFilters } = presetInputs;
    const { setView, setZoom } = config;
    const showUpperJaw = ToothUtils.toothIsUpper(activeTooth);

    controlRef.current?.reset?.();
    setAppearanceFilters(currentAppearanceFilters => {
        const nextAppearanceFilters: LimitedAppearanceFilterBools = {
            ...currentAppearanceFilters,
            showLower: !showUpperJaw,
            showUpper: showUpperJaw,
            showColor: true,
            showRestos: true,
            showScans: true,
            ...config.filterValueOverrides,
        };

        setAppearance(currentAppearance =>
            applyAppearanceFilter(
                getBaseAppearance(currentAppearance, config.appearanceOverrides),
                nextAppearanceFilters,
            ),
        );

        return nextAppearanceFilters;
    });

    setView();
    setZoom();
}

type Props = {
    appearance: ModelAppearance;
    controlRef: MainViewCameraControlsRef;
    presetViewControls: PresetViewControllerFns;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    setAppearanceFilters: React.Dispatch<React.SetStateAction<LimitedAppearanceFilterBools>>;
    setZoom: React.Dispatch<React.SetStateAction<number>>;
    selectedTab: LabsGqlGuidedWaxupPresetType;
    isImmediateDenture: boolean;
};

export function useResetView({
    appearance,
    controlRef,
    presetViewControls,
    setAppearance,
    setAppearanceFilters,
    setZoom,
    selectedTab,
    isImmediateDenture,
}: Props) {
    const { resetState } = useTransparency(appearance, setAppearance);
    const teethIndices = useTeethIndices(appearance);
    const zoomToVisible = useZoomToVisible(controlRef, appearance);
    const isMobile = useScreenIsMobileOrVerticalTablet();

    // -1 is a placeholder value for satisfying typescript type checking
    const activeTooth = teethIndices[0] ?? -1;

    /**
     * This callback is responsible for updating the appearance of the model and camera view angle of the scene when a new preset is selected.
     * The guided waxup flow introduces pre-determined appearances and views of the model called 'presets', and they are toggled by navigating
     * to the different tabs within the experience. The camera view angle also depends on the active tooth, so our effect depends on
     * the selected tab (the main factor we are considering when determining the preset), the active tooth, and whether the screen is mobile
     * since the camera's zoom is adjusted on smaller screens
     * */
    const resetView = React.useCallback(() => {
        const presetInputs = { activeTooth, controlRef, setAppearance, setAppearanceFilters };
        resetState();

        switch (selectedTab) {
            case LabsGqlGuidedWaxupPresetType.ToothDesign:
                resetModelViewer(presetInputs, {
                    setView: () => presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.FACIAL),
                    setZoom: () => setZoom(isMobile ? 6 : 12),
                    filterValueOverrides: INITIAL_APPEARANCE_FILTER,
                });
                break;

            case LabsGqlGuidedWaxupPresetType.MarginView:
                resetModelViewer(presetInputs, {
                    setView: () => presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.OCCLUSAL),
                    setZoom: () => setZoom(isMobile ? 25 : 45),
                    appearanceOverrides: { showMarginLines: true, showDoctorMarginLines: true },
                    filterValueOverrides: { showColor: false, showRestos: false },
                });
                break;

            case LabsGqlGuidedWaxupPresetType.PosteriorContour:
            case LabsGqlGuidedWaxupPresetType.ContourView:
                resetModelViewer(presetInputs, {
                    setView: () =>
                        presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.DISTAL_FACIAL),
                    setZoom: () => zoomToVisible(),
                });
                break;

            case LabsGqlGuidedWaxupPresetType.AnteriorContour:
                resetModelViewer(presetInputs, {
                    setView: () =>
                        presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.MESIAL_FACIAL),
                    setZoom: () => zoomToVisible(),
                });
                break;

            case LabsGqlGuidedWaxupPresetType.ContactDesign:
                resetModelViewer(presetInputs, {
                    setView: () => presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.FACIAL),
                    setZoom: () => setZoom(isMobile ? 6 : 15),
                    filterValueOverrides: { showColor: false },
                });
                break;

            case LabsGqlGuidedWaxupPresetType.OcclusalAnatomy:
                resetModelViewer(presetInputs, {
                    setView: () => presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.OCCLUSAL),
                    setZoom: () => setZoom(isMobile ? 8 : 15),
                    filterValueOverrides: { showColor: false },
                });
                break;

            case LabsGqlGuidedWaxupPresetType.MarginalRidge:
            case LabsGqlGuidedWaxupPresetType.FacialAnatomy:
                resetModelViewer(presetInputs, {
                    setView: () => presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.FACIAL),
                    setZoom: () => zoomToVisible(),
                });
                break;

            case LabsGqlGuidedWaxupPresetType.GeneralView:
                resetModelViewer(presetInputs, {
                    setView: () =>
                        ToothUtils.isAnterior(teethIndices)
                            ? // if any teeth in the design are anterior, we default to just the general front aligned axis view
                              presetViewControls.generalToothViewSetter(activeTooth, ToothViewDirection.FACIAL)
                            : // otherwise, we know the teeth are posterior, so show a facial view of the active tooth
                              presetViewControls.axisAlignedViewSetter('FRONT'),
                    setZoom: () => setZoom(isMobile ? 4 : 6),
                    appearanceOverrides: { showMarginLines: false, showDoctorMarginLines: false },
                    filterValueOverrides: isImmediateDenture
                        ? GUIDED_WAXUP_APPEARANCE_FILTER
                        : INITIAL_APPEARANCE_FILTER,
                });
                break;

            default:
                selectedTab satisfies never;
        }
    }, [
        activeTooth,
        controlRef,
        isImmediateDenture,
        isMobile,
        presetViewControls,
        resetState,
        selectedTab,
        setAppearance,
        setAppearanceFilters,
        setZoom,
        teethIndices,
        zoomToVisible,
    ]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(resetView, [selectedTab, activeTooth, isMobile]);

    return { resetView };
}
