import { useCheckoutPropSelector } from '../../../../../redux/selectors';
import { CheckoutDisplayUtils } from '../../../state/CheckoutDisplayUtils';
import { CheckoutItemV2Manager } from '../../../state/CheckoutItemV2Manager';
import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import { CheckoutItemSelectField } from './CheckoutItemSelectField';
import { ItemNotesV2 } from './ItemNotesV2';
import { CheckoutTextButton, CheckoutTextField, DisabledFieldOverlay } from '@orthly/dentin';
import type { Scalars } from '@orthly/graphql-schema';
import { CartItemV2Utils, ItemMetafieldV2Utils } from '@orthly/items';
import type { ItemMetafieldV2 } from '@orthly/items';
import { FlossPalette, createStyles, makeStyles, Collapse, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        defaultFieldsWrap: {
            paddingTop: 12,
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            marginTop: 24,
            position: 'relative',
        },
    }),
);

interface CheckoutItemMetaFieldProps {
    item: CheckoutItemV2;
    field: ItemMetafieldV2;
    disabled: boolean;
    hasPreference?: boolean;
}

function useMetafieldManager(props: CheckoutItemMetaFieldProps) {
    const { field, item } = props;
    const setField = useCheckoutAction('SET_LINE_ITEM_METAFIELD');
    const { doctor } = useCheckoutPropSelector(['doctor']);
    const savedField = item.preference_fields.find(p => p.field_id === field.id);
    const [validationError, setValidationError] = React.useState<string | null>(null);

    const saveField = React.useCallback(
        (value: Scalars['MetafieldJSON']['output']) => {
            // dont set empty string submissions
            if (field.type === 'text' && !savedField && value === '') {
                return;
            }

            if (field.validator) {
                const validationResult = field.validator(value);
                setValidationError(validationResult?.error ?? null);
            }

            setField({
                item_index: item.item_index,
                field: { value, display_name: field.label || field.name, field_id: field.id },
            });
        },
        [field, item.item_index, savedField, setField],
    );

    const fieldFromPreferences = React.useMemo(() => {
        if (field.has_preference && doctor && doctor.custom_field_preferences) {
            return doctor.custom_field_preferences.find(f => f.field_id === field.id);
        }
        return undefined;
    }, [doctor, field.has_preference, field.id]);

    React.useEffect(() => {
        // use preferences value first
        if (!savedField && fieldFromPreferences) {
            saveField(fieldFromPreferences.value);
            return;
        }
        // if no preferences submission, but there is a default, set initial value to default
        if (field.default_value !== null && field.default_value !== undefined && !savedField) {
            saveField(field.default_value);
        }
    }, [field.default_value, fieldFromPreferences, saveField, savedField]);

    return { saveField, validationError, value: savedField?.value };
}

const CheckoutItemSingleMetafield: React.FC<CheckoutItemMetaFieldProps> = props => {
    const { field } = props;
    const { value, saveField, validationError } = useMetafieldManager(props);
    const label = field.label || field.name;
    const fieldValString = typeof value === 'string' ? value : value?.toString();
    const commonSelectProps = { label, required: field.optional === false, helperText: field.helper_text };

    if (field.type === 'select') {
        return (
            <CheckoutItemSelectField
                disabled={props.disabled}
                forceDropdownSelect={props.hasPreference}
                options={field.options ?? []}
                onChange={newVal => {
                    newVal && newVal !== fieldValString && saveField(newVal);
                }}
                value={fieldValString}
                {...commonSelectProps}
            />
        );
    }

    if (field.type === 'boolean') {
        // eslint-disable-next-line no-nested-ternary
        const fieldValue = value === undefined ? undefined : value ? 'Yes' : 'No';
        return (
            <CheckoutItemSelectField
                disabled={props.disabled}
                forceDropdownSelect={props.hasPreference}
                options={[{ value: 'Yes' }, { value: 'No' }]}
                onChange={newVal => {
                    newVal !== fieldValue && saveField(newVal === 'Yes');
                }}
                value={fieldValue}
                {...commonSelectProps}
            />
        );
    }

    return (
        <Grid container style={{ paddingTop: 10, paddingBottom: 10, position: 'relative' }}>
            <CheckoutTextField
                onChange={saveField}
                value={fieldValString}
                required={false}
                helperText={field.helper_text}
                label={label}
                size={'medium'}
                TextFieldProps={{
                    InputProps: { autoFocus: !props.disabled },
                    multiline: true,
                    autoFocus: !props.disabled,
                    disabled: props.disabled,
                }}
                error={validationError}
            />
            <DisabledFieldOverlay disabled={props.disabled} />
        </Grid>
    );
};

function useMetafieldsDisplayState(item: CheckoutItemV2) {
    const { doctor } = useCheckoutPropSelector(['doctor']);

    const visibleFields = React.useMemo(() => CheckoutItemV2Manager.getMetafields(item), [item]);

    // Fields with value set from the current doctors preferences
    const fieldsWithPreference = React.useMemo<ItemMetafieldV2[]>(() => {
        const customFieldPrefs = doctor?.custom_field_preferences;
        if (!customFieldPrefs) {
            return [];
        }

        return visibleFields.filter(f => {
            return (
                f.has_preference &&
                customFieldPrefs.find(
                    prefField =>
                        prefField.field_id === f.id &&
                        (!!prefField.value || typeof prefField.value === 'boolean') &&
                        !ItemMetafieldV2Utils.isDeprecatedSelectField(f, prefField.value),
                )
            );
        });
    }, [doctor, visibleFields]);

    const fieldsWithoutPreference = React.useMemo(() => {
        return visibleFields.filter(f => !fieldsWithPreference.find(p => p.id === f.id));
    }, [fieldsWithPreference, visibleFields]);

    const allFieldsDisabled = React.useMemo(
        () => !CheckoutItemV2Manager.materialSelected(item) || !CheckoutItemV2Manager.shadesSelected(item),
        [item],
    );

    // Index
    const minCompleteNoPreferenceFieldIdx = React.useMemo(() => {
        if (allFieldsDisabled) {
            return -1;
        }
        const minComplete = fieldsWithoutPreference.findIndex(
            field => !field.optional && !item.preference_fields.find(p => p.field_id === field.id),
        );
        // minComplete will be -1 if all are complete
        return minComplete < 0 ? fieldsWithoutPreference.length : minComplete;
    }, [allFieldsDisabled, fieldsWithoutPreference, item.preference_fields]);

    return { fieldsWithPreference, fieldsWithoutPreference, minCompleteNoPreferenceFieldIdx, allFieldsDisabled };
}

interface CheckoutItemMetafieldsSectionProps {
    item: CheckoutItemV2;
}

export const CheckoutItemMetafieldsSection: React.FC<CheckoutItemMetafieldsSectionProps> = ({ item }) => {
    const classes = useStyles();
    const [defaultsOpen, setDefaultsOpen] = React.useState<boolean>(false);
    const { fieldsWithPreference, fieldsWithoutPreference, minCompleteNoPreferenceFieldIdx, allFieldsDisabled } =
        useMetafieldsDisplayState(item);

    const nonPreferenceFieldsComplete = fieldsWithoutPreference.length === minCompleteNoPreferenceFieldIdx;
    const showDefaultsSection = fieldsWithPreference.length > 0;

    return (
        <Grid container style={{ padding: '10px 0', background: '#fff' }} component={'form'}>
            {fieldsWithoutPreference.map((field, idx) => (
                <CheckoutItemSingleMetafield
                    key={field.id}
                    item={item}
                    field={field}
                    disabled={idx > minCompleteNoPreferenceFieldIdx}
                />
            ))}
            <Grid container className={classes.defaultFieldsWrap}>
                {showDefaultsSection && (
                    <>
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            <Text variant={'h6'} style={{ fontSize: '1rem', paddingBottom: 4 }}>
                                Default {CartItemV2Utils.getDisplayName(item)} Settings
                            </Text>
                            <CheckoutTextButton onClick={() => setDefaultsOpen(!defaultsOpen)}>
                                {defaultsOpen ? 'Hide' : 'Override'}
                            </CheckoutTextButton>
                        </Grid>
                        <Collapse
                            exit
                            in={defaultsOpen}
                            style={{ width: '100%' }}
                            timeout={200}
                            onEntered={node => {
                                const mainEl = document.getElementById(CheckoutDisplayUtils.PRE_SUMMARY_MAIN_ID);
                                const parentEl = node.offsetParent as HTMLElement | null;
                                if (mainEl && parentEl) {
                                    mainEl.scrollTo({ behavior: 'smooth', left: 0, top: parentEl.offsetTop });
                                }
                            }}
                        >
                            <Grid container item xs={12} md={6}>
                                {!CheckoutItemV2Manager.isUnspecifiedOtherItem(item) && <ItemNotesV2 item={item} />}

                                {fieldsWithPreference.map(field => (
                                    <CheckoutItemSingleMetafield
                                        disabled={false}
                                        hasPreference={true}
                                        key={field.id}
                                        item={item}
                                        field={field}
                                    />
                                ))}
                            </Grid>
                        </Collapse>
                        <DisabledFieldOverlay disabled={allFieldsDisabled || !nonPreferenceFieldsComplete} />
                    </>
                )}
            </Grid>
        </Grid>
    );
};
