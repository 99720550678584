import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

const TryInFeedbackRestsOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial>> = ({
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    return (
        <TryInFeedbackOptionWrapper
            title={'Rests'}
            subtitle={'Partial denture rests are off'}
            isSelected={isSelected}
            onSelected={() => {
                onSelected();
                onDescriptionChanged(`Rests aren't seating`);
            }}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <SimpleSelect
                label={'Adjust rests'}
                value={description ?? undefined}
                onChange={val => onDescriptionChanged(val || undefined)}
                options={[
                    { value: `Rests aren't seating`, label: `Rests aren't seating` },
                    { value: 'Rests are too high', label: 'Rests are too high' },
                ]}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackRestsOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Partial> = {
    sku: LabOrderItemSKUType.Partial,
    isArchSpecific: true,
    category: 'Rests',
    isEnabled: () => true,
    Component: TryInFeedbackRestsOption,
};
