import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { DandyMouthToothSelector } from '@orthly/dentin';
import { LabOrderItemSKUType, OrderItemPartialDentureProductionType } from '@orthly/items';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const TryInFeedbackIncorrectTeethOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial>> = ({
    orderItem,
    newItem,
    updateNewItem,
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    const unselect = () => {
        // on unselect, reset item unn changes
        updateNewItem({ name: 'multi_tooth_unit_item_unns', payload: orderItem.unit.unns });
        onUnselected();
    };

    // auto-set description to match unns
    React.useEffect(() => {
        const itemUnnsString = newItem.unit.unns.join(', ') || undefined;
        if (isSelected && itemUnnsString && itemUnnsString !== description) {
            onDescriptionChanged(itemUnnsString);
        }
    }, [isSelected, newItem.unit.unns, description, onDescriptionChanged]);

    return (
        <TryInFeedbackOptionWrapper
            title={'Incorrect or missing teeth'}
            subtitle={'Teeth intended for the partial denture are missing or incorrect'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={unselect}
            hasError={isSelected && showError && !description}
        >
            <Grid container direction={'column'} spacing={2}>
                <Grid item>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Select all the teeth that should be included in this partial
                    </Text>
                </Grid>
                <Grid item style={{ paddingBottom: 32 }}>
                    <DandyMouthToothSelector
                        selected={newItem.unit.unns}
                        onChange={unns => {
                            updateNewItem({ name: 'multi_tooth_unit_item_unns', payload: unns });
                        }}
                        verticalLayout={false}
                        archLabels={true}
                        animate={false}
                    />
                </Grid>
            </Grid>
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackIncorrectTeethOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Partial> = {
    sku: LabOrderItemSKUType.Partial,
    isArchSpecific: false,
    category: 'Incorrect or missing teeth',
    isEnabled: item => item.partial_production_type !== OrderItemPartialDentureProductionType.WaxRim,
    Component: TryInFeedbackIncorrectTeethOption,
};
