import type { ActionCreatorWithType, AsyncAction } from './common';
import React from 'react';
import { useDispatch as useDispatchIm } from 'react-redux';

interface ActionCreatorObject {
    [k: string]: ActionCreatorWithType<any, any, any> | AsyncAction<any, any, any>;
}

function validateActionObj<T extends ActionCreatorObject>(actionObj: T) {
    Object.entries(actionObj).reduce<string[]>((seenActionNames, [key, actionCreator]) => {
        if (seenActionNames.includes(actionCreator.TYPE)) {
            throw new Error(
                `Action creator (key: ${String(key)}) is attempting to register action ${
                    actionCreator.TYPE
                }, which is already registered`,
            );
        }
        return [...seenActionNames, actionCreator.TYPE];
    }, []);
}

export function generateUseActionHook<T extends ActionCreatorObject>(
    actionObj: T,
    useDispatch: typeof useDispatchIm = useDispatchIm,
) {
    validateActionObj(actionObj);
    return function useAction<K extends keyof T>(key: K): T[K] {
        const dispatch = useDispatch();
        const handler = actionObj[key];
        if (!handler) {
            throw new Error(`No action creator with name ${String(key)} exists!`);
        }
        const newAction = React.useCallback((...args) => dispatch(handler(...args)), [dispatch, handler]);

        return Object.assign(newAction, { ...handler }) as T[K];
    };
}

type AnyActionCreator = AsyncAction<any, any, any> | ActionCreatorWithType<any, any, any>;

export type ActionPayload<Action extends AnyActionCreator> = Parameters<Parameters<Action['successReducer']>[0]>[1];

export type ActionCreatorObjectActions<Obj extends ActionCreatorObject> = {
    [K in keyof Obj]: ActionPayload<Obj[K]>;
}[keyof Obj];
