import { useCurrentProfileIsDoctor } from '../../../redux/selectors';
import { SettingsSubNav } from '../components/SettingsSubNav';
import { useProfileSettingsAlerts } from '../state/usePracticeStaffControls';
import { PracticeScreen } from '@orthly/dentin';
import type { SubNavigationEntry } from '@orthly/ui';
import React from 'react';

export const ProfileSettingsSubNav: React.FC = () => {
    const { showPreferencesAlert, showSettingsAlert, showNotificationsAlert } = useProfileSettingsAlerts();
    const isDoctor = useCurrentProfileIsDoctor();
    const mainScreenSections = React.useMemo<SubNavigationEntry<string>[]>(() => {
        const sections: SubNavigationEntry<string>[] = [
            {
                value: 'account',
                label: 'Account settings',
                alertCount: Number(showSettingsAlert),
            },
        ];

        if (isDoctor) {
            sections.push(
                {
                    value: 'notifications',
                    label: 'Notifications',
                    alertCount: Number(showNotificationsAlert),
                },
                {
                    value: 'preferences',
                    label: 'Clinical preferences',
                    alertCount: Number(showPreferencesAlert),
                },
            );
        }

        return sections;
    }, [showSettingsAlert, isDoctor, showNotificationsAlert, showPreferencesAlert]);
    return (
        <SettingsSubNav
            sections={[
                {
                    subtitle: 'SETTINGS',
                    entries: mainScreenSections,
                },
            ]}
            title={'Profile'}
            practiceScreen={PracticeScreen.my_profile}
            selector={'profileSettings'}
        />
    );
};
