import { NavigationLogoDandy } from '@orthly/ui';
import { FlossPalette, FlossPaletteUtils, stylesFactory, Collapse, Fade, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

const useStyles = stylesFactory(theme => ({
    background: {
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 10000,
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        overflowY: 'auto',

        // NOTE: this disables backdrop filter on Platforms that don't support it;
        // as of today (Nov 01, 2021 Mon 11:19 EDT), this includes Firefox. Without
        // this, the background of this screen bleeds through to the actual portal
        // and makes the user experience pretty confusing and difficult
        //
        // More info: https://caniuse.com/css-backdrop-filter
        '@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
            backgroundColor: FlossPalette.TAN,
        },
        '@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none))': {
            backgroundColor: FlossPaletteUtils.toRgba('DARK_TAN', 0.5),
            backdropFilter: 'blur(74px)',
            [theme.breakpoints.down('lg')]: {
                backgroundColor: FlossPalette.TAN,
                backdropFilter: 'unset',
            },
        },
    },
    contentAnchor: {
        position: 'relative',
        flexGrow: 1,
    },
    content: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        padding: '16px calc(100% / 6)',
        [theme.breakpoints.down('lg')]: {
            padding: 16,
        },
    },
    contentTransition: {
        '&-enter': {
            opacity: 0,
            transform: 'translateY(100vh)',
        },
        '&-enter-active, &-exit': {
            opacity: 1,
            transform: 'translateY(0)',
        },
        '&-exit-active': {
            opacity: 0,
            transform: 'translateY(100vh)',
        },
        '&-enter-active, &-exit-active': {
            transition: 'transform 500ms ease-in-out, opacity 200ms ease-in-out',
        },
    },
    logo: {
        height: 24,
        marginLeft: 32,
        marginTop: 40,
        color: FlossPalette.LIGHT_GRAY,
        alignSelf: 'flex-start',
        [theme.breakpoints.down('lg')]: {
            marginLeft: 16,
            marginTop: 16,
        },
    },
    floatButton: {
        marginLeft: -38,
        alignSelf: 'flex-start',
        [theme.breakpoints.down('lg')]: {
            marginLeft: 'unset',
        },
    },
}));

export const SelectStaffBackground: React.VFC<
    React.ComponentProps<typeof Grid> & { show: boolean; showLogo?: boolean }
> = ({ show, showLogo, ...props }) => {
    const classes = useStyles();
    return (
        <Fade in={show} mountOnEnter unmountOnExit timeout={300}>
            <Grid container direction={'column'} wrap={'nowrap'} className={classes.background}>
                <Collapse in={showLogo} mountOnEnter unmountOnExit>
                    <NavigationLogoDandy className={classes.logo} />
                </Collapse>
                <Grid className={classes.contentAnchor} {...props} />
            </Grid>
        </Fade>
    );
};

export const SelectStaffContent: React.VFC<React.ComponentProps<typeof Grid> & { show: boolean }> = ({
    show,
    ...props
}) => {
    const ref = React.useCallback(
        (node: HTMLDivElement) => node?.scrollIntoView({ behavior: 'smooth', block: 'start' }),
        [],
    );
    const classes = useStyles();
    return (
        <CSSTransition in={show} classNames={classes.contentTransition} mountOnEnter unmountOnExit timeout={500}>
            <Grid container direction={'column'} wrap={'nowrap'} className={classes.content} ref={ref} {...props} />
        </CSSTransition>
    );
};

export const SelectStaffFloatButton: React.VFC<
    Omit<React.ComponentProps<typeof Button>, 'variant'> & {
        variant?: React.ComponentProps<typeof Button>['variant'];
    }
> = props => {
    const classes = useStyles();
    return <Button startIcon={'ChevronLeft'} variant={'ghost'} className={classes.floatButton} {...props} />;
};
