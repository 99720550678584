import LowerDenture from '../../../assets/images/dentures/LowerDenture2.svg';
import UpperDenture from '../../../assets/images/dentures/UpperDenture2.svg';
import { Text, Fade, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DandyDentureMouthDisplayProps {
    upper?: boolean;
    lower?: boolean;
    // Set this to show mouths over-and-under, as opposed to side-by-side
    orientVertically?: true;
}

export const DandyDentureMouthDisplay: React.FC<DandyDentureMouthDisplayProps> = props => {
    const { upper, lower, orientVertically } = props;
    if (!upper && !lower) {
        return null;
    }
    const style: React.CSSProperties = orientVertically
        ? { width: 250, marginBottom: 8, objectFit: 'contain', maxHeight: 235 }
        : { height: 180, marginBottom: 8, objectFit: 'contain', maxWidth: '100%' };
    return (
        <Grid container justifyContent={'center'}>
            {upper && (
                <Fade in={upper}>
                    <Grid item container direction={'column'} xs={orientVertically ? 12 : 6} alignItems={'center'}>
                        <img src={UpperDenture} alt={'Upper Denture'} style={style} />
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            Upper
                        </Text>
                    </Grid>
                </Fade>
            )}
            {lower && (
                <Fade in={lower}>
                    <Grid item container direction={'column'} xs={orientVertically ? 12 : 6} alignItems={'center'}>
                        <img src={LowerDenture} alt={'Lower Denture'} style={style} />
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            Lower
                        </Text>
                    </Grid>
                </Fade>
            )}
        </Grid>
    );
};
