import { useCSTAction, useOnCSTOpen } from '../screens/chat/cst-state/cst.actions';
import { useChatFeatures } from '../screens/chat/utils';
import { InboxUpsellRoot } from '../screens/inbox/components/upsell/InboxUpsellRoot';
import { AvailabilityBanner } from '@orthly/dentin';
import { MiniWindowVariant } from '@orthly/ui';
import { FlossPalette, createStyles, makeStyles, Grid, Button, Text } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        chatSection: {
            borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            paddingTop: 16,
            paddingBottom: 24,
        },
    }),
);

interface PracticeSidebarRightHeadlineProps {
    title: string;
    value?: string;
    ['data-test']?: string;
}

export const HeadlineItem: React.FC<PracticeSidebarRightHeadlineProps> = props => {
    return (
        <Grid container justifyContent={'space-between'} alignItems={'center'} data-test={props['data-test']}>
            <Text variant={'body2'} color={'DARK_GRAY'} style={{ fontWeight: 500 }}>
                {props.title}
            </Text>
            {props.value && (
                <Text variant={'h6'} color={'BLACK'}>
                    {props.value}
                </Text>
            )}
        </Grid>
    );
};

const useOpenChatButtonData = () => {
    return { AssetCTAText: 'Chat with us' };
};

const ChatSection: React.FC = () => {
    const classes = useStyles();
    const { isAvailable } = useChatFeatures();
    const setCSTVariant = useCSTAction('SET_WINDOW_VARIANT');
    const openCSTAction = useOnCSTOpen(() => {
        setCSTVariant(MiniWindowVariant.Maximized, 'move');
    }, 'right_sidebar');
    const openChatButtonData = useOpenChatButtonData();

    return (
        <Grid container className={cx(classes.chatSection)}>
            <Grid container style={{ paddingBottom: 16 }}>
                <HeadlineItem title={'Need a hand?'} />
            </Grid>
            <Grid container alignItems={'center'}>
                <Grid direction={'column'} container item>
                    <AvailabilityBanner available={isAvailable} />
                    <Grid style={{ marginTop: 4 }}>
                        <Button variant={'ghost'} startIcon={'SidebarChatIcon'} onClick={openCSTAction}>
                            {openChatButtonData.AssetCTAText}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface PracticeSidebarRightProps {
    topItems?: React.ReactNode;
    bottomItems?: React.ReactNode;
}

export const PracticeSidebarRight: React.FC<PracticeSidebarRightProps> = props => {
    return (
        <Grid container>
            <Grid item style={{ width: '100%' }}>
                {/* These items need to be in a container so they stayed grouped at the top */}
                {props.topItems}
            </Grid>
            <Grid item style={{ alignSelf: 'flex-end', width: '100%' }}>
                <InboxUpsellRoot />
                {props.bottomItems}
                <ChatSection />
            </Grid>
        </Grid>
    );
};

export { ChatSection as SidebarChatSection };
