import { useCurrentProfile } from '../../redux';
import { usePaymentMethodControls } from '../account/practice-settings/payment/usePaymentMethodControls';
import { PaymentMethodAndAddressSection } from './PaymentMethodAndAddressSection';
import { PaymentMethodAndAddressToolbar } from './PaymentMethodAndAddressToolbar';
import { WelcomeText } from './WelcomeText';
import { PracticeFullScreenDialog } from '@orthly/dentin';
import { useLabsAddresses } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { FlossPalette, stylesFactory, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory<{ isMobile: boolean }>(() => ({
    contentBody: ({ isMobile }) => ({
        width: isMobile ? '100%' : '60%',
    }),
}));

export const PaymentMethodAndAddressRoot: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles({ isMobile });
    const partnerId = useSession()?.organization_id;
    const [open, setOpen] = React.useState(false);

    const { methods, loading: loadingMethods, removeMethod, setDefaultMethod } = usePaymentMethodControls();
    // we want the method with is_default set to true to be first, so reverse the order
    // since sortBy sorts in ascending (false first) order
    const defaultMethodFirst = _.sortBy(methods, m => m.is_default).reverse();

    const { addresses, loading: loadingAddresses } = useLabsAddresses();
    const activeAddresses = (addresses || []).filter(a => a.deleted_at === null);

    const practiceHasNotAddedInfoYet = !defaultMethodFirst?.length && !activeAddresses.length;

    const profile = useCurrentProfile();

    if (!profile) {
        return null;
    }

    return (
        <PracticeFullScreenDialog
            dialogPaperStyle={{
                backgroundColor: FlossPalette.TAN,
                alignItems: 'center',
                overflowY: 'scroll',
                height: '100dvh',
            }}
        >
            <PaymentMethodAndAddressToolbar profile={profile} />
            <Grid container direction={'column'} className={classes.contentBody}>
                {practiceHasNotAddedInfoYet && <WelcomeText />}
                <PaymentMethodAndAddressSection
                    open={open}
                    setOpen={setOpen}
                    partnerId={partnerId}
                    loadingAddresses={loadingAddresses}
                    loadingMethods={loadingMethods}
                    activeAddresses={activeAddresses}
                    methods={defaultMethodFirst}
                    removeMethod={removeMethod}
                    setDefaultMethod={setDefaultMethod}
                />
            </Grid>
        </PracticeFullScreenDialog>
    );
};
