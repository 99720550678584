import type { KnownPrice } from './PricingGuide.types';
import { useListPartnerPricesQuery } from '@orthly/graphql-react';
import { Format } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { keyBy } from 'lodash';
import React from 'react';

const isDefinedArray = <T extends unknown>(items: T[]): items is Exclude<T, undefined>[] => items.every(Boolean);

const usePricingGuideContext = () => {
    const partnerId = useSession()?.organization_id ?? '';
    const { data, loading } = useListPartnerPricesQuery({
        variables: { partnerId },
        skip: !partnerId,
    });

    const naMessage = React.useMemo(() => (data ? 'N/A - Please contact your Account Manager' : ''), [data]);

    const partnerPrices = React.useMemo(() => keyBy(data?.partnerPrices ?? [], 'name'), [data]);

    const getPrice = React.useCallback(
        (name: KnownPrice) => partnerPrices[name]?.partner_prices[0]?.price_cents,
        [partnerPrices],
    );

    const formatPrices = React.useCallback(
        (prices: (number | undefined)[], formatFn: (formattedPrices: string[]) => React.ReactNode) =>
            isDefinedArray(prices) ? formatFn(prices.map(price => Format.currency(price, 'cents', false))) : naMessage,
        [naMessage],
    );

    const formatPrice = React.useCallback(
        (
            price: number | undefined,
            formatFn: (formattedPrice: string) => React.ReactNode = formattedPrice => formattedPrice,
        ) => (price ? formatFn(Format.currency(price, 'cents', false)) : naMessage),
        [naMessage],
    );

    return { formatPrice, formatPrices, getPrice, loading };
};

const pricingGuideContext = React.createContext<ReturnType<typeof usePricingGuideContext> | null>(null);

export const PricingGuideContextProvider: React.FC = ({ children }) => (
    <pricingGuideContext.Provider value={usePricingGuideContext()}>{children}</pricingGuideContext.Provider>
);

export const usePricingGuide = () => {
    const context = React.useContext(pricingGuideContext);

    if (context === null) {
        throw new Error('You must use usePricingGuide within a descendent of PricingGuideContextProvider');
    }

    return context;
};
