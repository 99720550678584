import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { ICartImplantBridgeItem, ICartImplantItem, ToothNumber } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { FlossPalette, stylesFactory, Grid, Switch, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    sidebar: {
        marginTop: 64,
        borderLeft: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        paddingLeft: 24,
        height: 300,
    },
    switchContainer: {
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: 8,
        background: FlossPalette.TAN,
        padding: '16px 24px',
    },
    switchTitle: {
        fontWeight: 600,
    },
    switchSubtitle: {
        display: 'inline',
        fontWeight: 400,
        color: FlossPalette.GRAY,
    },
}));

interface MultiItemSwitchProps {
    item?: ICartImplantItem | ICartImplantBridgeItem;
    label: string;
    isGrouped: boolean;
    abutmentUnns: ToothNumber[];
    hidden?: boolean;
    onChange: (isGrouped: boolean) => void;
}

export const MultiItemSwitch: React.VFC<MultiItemSwitchProps> = ({
    item,
    label,
    isGrouped,
    abutmentUnns,
    hidden,
    onChange,
}) => {
    const classes = useStyles();

    // Implant bridges currently don't have the ability to ungroup implant selections
    if (hidden || !item || CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.ImplantBridge)) {
        return null;
    }
    const toggleGrouped = () => {
        onChange(!isGrouped);
    };

    return (
        <Grid className={classes.sidebar}>
            <Grid container direction={'column'} item xs={7} className={classes.switchContainer}>
                <Grid item>
                    <Text variant={'body2'} className={classes.switchTitle}>
                        {label}
                    </Text>
                </Grid>
                <Grid item style={{ paddingLeft: 0, marginLeft: -12 }}>
                    <Switch
                        color={'secondary'}
                        onChange={() => toggleGrouped()}
                        checked={isGrouped}
                        style={{ verticalAlign: 'middle' }}
                    />
                    <Text variant={'body2'} className={classes.switchSubtitle}>
                        Now editing {Format.pluralizeNoun('tooth', abutmentUnns.length, 'teeth')}{' '}
                        {CartItemV2Utils.getUnnsDisplay(abutmentUnns)} {!isGrouped ? 'only' : ''}
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    );
};
