import { useScanbodyRequestAction } from '../state/ScanbodyRequest.actions';
import { useScanbodyRequestSelector } from '../state/ScanbodyRequest.selectors';
import { ScanbodyStage } from '../state/ScanbodyRequest.types';
import { addBusinessDays } from '@orthly/date-fns-business-days';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    FlossPaletteUtils,
    createStyles,
    makeStyles,
    Button,
    Collapse,
    Dialog,
    Grid,
    Text,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import dayjs from 'dayjs';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            maxWidth: 400,
            height: 'auto',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vh 0 15vh',
            flexWrap: 'nowrap',
            overflow: 'auto',
            color: '#fff',
        },
        backdrop: {
            background: FlossPaletteUtils.toRgba('BLACK', 0.8),
            backdropFilter: 'blur(4px)',
        },
        row: {
            padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(32)}`,
            position: 'relative',
            justifyContent: 'center',
        },
        borderBottom: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        },
        header: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            paddingTop: 12,
        },
        headerRow: {
            maxHeight: 'fit-content',
            padding: '12px 0',
            justifyContent: 'center',
        },
        mitchell: {
            objectFit: 'contain',
            width: 200,
        },
        backButton: {
            '&&': {
                color: FlossPalette.BLACK,
            },
            '&:hover,:focus': {
                '&&': {
                    color: FlossPalette.GRAY,
                },
            },
        },
        buttonsRoot: {
            paddingBottom: 24,
            paddingTop: 24,
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        },
        buttonDisabled: {
            color: `${FlossPaletteUtils.toRgba('BLACK', 0.26)} !important`,
            backgroundColor: `${FlossPaletteUtils.toRgba('BLACK', 0.12)} !important`,
            pointerEvents: 'all !important' as any,
            border: '1px solid transparent !important',
        },
        colorWhite: {
            color: `${FlossPalette.WHITE} !important`,
        },
    }),
);

const PREFERRED_DATE_FORMAT = 'dddd, MMMM D';

export const ScanbodyRequestSuccessOverlay: React.VFC = () => {
    const classes = useStyles();
    const request = useScanbodyRequestSelector(s => s.request);
    const stage = useScanbodyRequestSelector(s => s.stage);
    const resetRequest = useScanbodyRequestAction('RESET_REQUEST');
    const expectedDeliveryDate = React.useMemo<string>(() => {
        const appointmentDate = dayjs(request.appointment_date ?? new Date());

        const earliestPossibleDate = addBusinessDays(new Date(), 3);
        const priorToAppointmentDate = addBusinessDays(appointmentDate.toDate(), -3);

        if (priorToAppointmentDate < earliestPossibleDate) {
            return dayjs(earliestPossibleDate).format(PREFERRED_DATE_FORMAT);
        }

        return dayjs(priorToAppointmentDate).format(PREFERRED_DATE_FORMAT);
    }, [request]);

    return (
        <Dialog
            open={stage === ScanbodyStage.SUBMITTED}
            fullScreen
            TransitionComponent={Collapse as any}
            style={{ zIndex: 2500 }}
            TransitionProps={{ appear: true, exit: true, timeout: 1000, style: { outline: 'none' } }}
            disableEnforceFocus={true}
            BackdropProps={{ className: classes.backdrop }}
            PaperProps={{
                elevation: 0,
                style: { backgroundColor: 'transparent', height: '100vh', alignItems: 'center' },
            }}
        >
            <Grid container className={classes.root}>
                <Grid container className={cx(classes.header, classes.row)}>
                    <Grid container item xs={12} className={classes.headerRow}>
                        <img className={classes.mitchell} src={'/checkout/mitchell.png'} alt={'Logo'} />
                    </Grid>
                </Grid>
                <Grid container className={cx(classes.row, classes.borderBottom)} justifyContent={'center'}>
                    <Text variant={'h4'} component={'h1'} align={'center'}>
                        Scan body Requested!
                    </Text>
                </Grid>
                <Grid container className={cx(classes.row, classes.borderBottom)} direction={'column'}>
                    <Text variant={'caption'} style={{ marginBottom: 10, color: '#fff' }} align={'center'}>
                        EXPECTED DELIVERY
                    </Text>
                    <Text variant={'h4'} component={'h1'} style={{ color: '#4CCEAD' }} align={'center'}>
                        {expectedDeliveryDate}
                    </Text>
                </Grid>
                <Grid container className={classes.row}>
                    <Button
                        fullWidth
                        style={{ backgroundColor: FlossPalette.WHITE, color: FlossPalette.BLACK }}
                        variant={'contained'}
                        onClick={resetRequest}
                    >
                        Back to inventory
                    </Button>
                </Grid>
            </Grid>
        </Dialog>
    );
};
