import type { PartnerAppState } from '../../../redux/types';
import type {
    AbutmentType,
    ImplantReadinessPatient,
    MetadataPayload,
    ScanbodyWithMetadata,
    ScanbodyOptions,
} from './ImplantReadiness.types';
import type { ApolloClient } from '@apollo/client';
import type {
    LabsGqlGetImplantTypesQuery,
    LabsGqlGetImplantTypesQueryVariables,
    LabsGqlGetPracticeScanbodyInventoryQuery,
    LabsGqlGetPracticeScanbodyInventoryQueryVariables,
    LabsGqlImplantToScanbodyFragment,
    LabsGqlScanbodyInventoryItemFragment,
} from '@orthly/graphql-operations';
import { GetImplantTypesDocument, GetPracticeScanbodyInventoryDocument } from '@orthly/graphql-react';
import { CartItemV2Utils } from '@orthly/items';
import type { ICartImplantBridgeItem, ICartImplantItem } from '@orthly/items';
import { createSyncAction, generateUseActionHook, createAsyncAction } from '@orthly/redux-async-actions';

const PREFIX = 'implant_readiness';

interface CompleteImplantSystemsPayload {
    client: ApolloClient<any>;
}

function getHighestPriority(
    implantToScanbodies: LabsGqlImplantToScanbodyFragment[],
    inventory: LabsGqlScanbodyInventoryItemFragment[],
) {
    if (implantToScanbodies.length === 0) {
        return undefined;
    }

    function getMatchedInventory(implantToScanbody: LabsGqlImplantToScanbodyFragment) {
        return inventory.find(
            i =>
                implantToScanbody.scanbody.name === i.scanbody?.name &&
                implantToScanbody.scanbody.manufacturer === i.scanbody.manufacturer,
        );
    }

    const implantToScanbodyWithInventory = implantToScanbodies.find(implantToScanbody =>
        getMatchedInventory(implantToScanbody),
    );

    const implantToScanbody =
        implantToScanbodyWithInventory ??
        implantToScanbodies.reduce((previous, current) => {
            // Priority is supposed to always be set, but if it's not set it to something high so the comparisons still work
            const previousPriority = previous.priority ?? Number.MAX_SAFE_INTEGER;
            const currentPriority = current.priority ?? Number.MAX_SAFE_INTEGER;
            // Lower number means higher priority 1 > 2
            return previousPriority < currentPriority ? previous : current;
        });

    return {
        ...implantToScanbody.scanbody,
        inventory: getMatchedInventory(implantToScanbody),
        priority: implantToScanbody.priority ?? Number.MAX_SAFE_INTEGER,
    };
}

export const ImplantReadinessActions = {
    SET_IMPLANT_READINESS_ID: createSyncAction<string>(`${PREFIX}/SET_IMPLANT_READINESS_ID`),
    SET_PATIENT: createSyncAction<ImplantReadinessPatient | undefined>(`${PREFIX}/SET_PATIENT`),
    CREATE_ITEMS: createSyncAction<Array<ICartImplantItem | ICartImplantBridgeItem>>(`${PREFIX}/CREATE_ITEMS`),
    SET_IMPLANT_TYPE: createSyncAction<'implant_restoration' | 'implant_bridge'>(`${PREFIX}/SET_IMPLANT_TYPE`),
    TOGGLE_GROUPING: createSyncAction<boolean>(`${PREFIX}/TOGGLE_GROUPING`),
    UPDATE_METADATA: createSyncAction<MetadataPayload>(`${PREFIX}/UPDATE_METADATA`),
    COMPLETE_IMPLANT_SYSTEMS: createAsyncAction<ScanbodyOptions, PartnerAppState, [CompleteImplantSystemsPayload]>(
        `${PREFIX}/COMPLETE_IMPLANT_SYSTEMS`,
        async (thunkParams, { client }) => {
            const state = thunkParams.getState().implantReadiness;
            const activeItemId = state.activeItemId;
            const nextItemIndex = state.items.findIndex(({ id }) => id === activeItemId) + 1;
            if (!state.isGrouped && nextItemIndex < state.items.length) {
                return {
                    payload: {},
                };
            }

            const typeSystems = state.items.map(item => {
                const metadata = CartItemV2Utils.getImplantMetadata(item);
                return {
                    manufacturer: metadata?.manufacturer,
                    system: metadata?.system,
                    connection: metadata?.connection_size,
                };
            });

            const implantTypesQuery = await client.query<
                LabsGqlGetImplantTypesQuery,
                LabsGqlGetImplantTypesQueryVariables
            >({
                query: GetImplantTypesDocument,
                variables: { withDeleted: false, typeSystems },
            });

            const inventoryQuery = await client.query<
                LabsGqlGetPracticeScanbodyInventoryQuery,
                LabsGqlGetPracticeScanbodyInventoryQueryVariables
            >({
                query: GetPracticeScanbodyInventoryDocument,
            });

            const scanbodyOptions = state.items.reduce<ScanbodyOptions>((matchedScanbodies, item) => {
                const metadata = CartItemV2Utils.getImplantMetadata(item);
                const match = implantTypesQuery.data.getImplantTypes.find(
                    implant =>
                        metadata &&
                        implant.system === metadata.system &&
                        implant.connection === metadata.connection_size &&
                        implant.manufacturer === metadata.manufacturer &&
                        implant.implantToScanbodies.length > 0,
                );

                if (!item.id || !match) {
                    return matchedScanbodies;
                }
                return {
                    ...matchedScanbodies,
                    [item.id]: {
                        authentic: getHighestPriority(
                            match.implantToScanbodies.filter(implantToScanbody => implantToScanbody.is_authentic),
                            inventoryQuery.data.getScanbodyRequests,
                        ),
                        generic: getHighestPriority(
                            match.implantToScanbodies.filter(implantToScanbody => !implantToScanbody.is_authentic),
                            inventoryQuery.data.getScanbodyRequests,
                        ),
                    },
                };
            }, {});

            return {
                payload: scanbodyOptions,
            };
        },
    ),
    SET_ABUTMENT_TYPES: createSyncAction<{ abutmentTypes: Record<string, AbutmentType> }>(
        `${PREFIX}/SET_ABUTMENT_TYPES`,
    ),
    SET_SURGICAL_REPORT: createSyncAction<string>(`${PREFIX}/SET_SURGICAL_REPORT`),
    SET_SCANBODIES: createSyncAction<ScanbodyWithMetadata[]>(`${PREFIX}/SET_SCANBODIES`),
    COMPLETE_FLOW: createSyncAction(`${PREFIX}/COMPLETE_FLOW`),
};

export const useImplantReadinessAction = generateUseActionHook<typeof ImplantReadinessActions>(ImplantReadinessActions);
