import React from 'react';
import { z } from 'zod';

const emailLoginSchema = z.object({
    email: z.string().email({ message: 'Invalid email address' }),
});

export const useEmailValidation = (email: string) => {
    const validation = React.useMemo(() => emailLoginSchema.safeParse({ email }), [email]);

    if (validation.success) {
        return { isFormValid: true };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: false,
        emailError: fieldErrors.email?.[0],
    };
};
