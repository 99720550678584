import { FieldColumnLayout, FieldsLayout, GridLayout } from '../TrainingDataIntake.styles';
import type { TraineeFormFieldsProps } from '../TrainingDataIntake.types';
import { useTrainees } from '../hooks/useTrainees';
import { RemoveTraineeButton } from './RemoveTraineeButton';
import { RequiredTraineeInputLabel } from './RequiredTraineeInputLabel';
import { TraineeExperienceFormField } from './TraineeExperienceFormField';
import { TraineeNameFormField } from './TraineeNameFormField';
import { TraineeRoleFormField } from './TraineeRoleFormField';
import { TraineeScannerFormField } from './TraineeScannerFormField';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const TraineeFormFields: React.VFC<TraineeFormFieldsProps> = props => {
    const { trainees } = useTrainees();

    const multipleTrainees = trainees.length > 1;

    return (
        <FieldsLayout>
            <GridLayout>
                <RequiredTraineeInputLabel
                    text={`Person ${multipleTrainees ? props.listIndex + 1 : ''} attending training`}
                />
                <FieldColumnLayout>
                    <TraineeNameFormField {...props} />
                    <TraineeRoleFormField {...props} />
                </FieldColumnLayout>
            </GridLayout>

            <GridLayout>
                <Text variant={'body2'} medium style={{ marginBottom: 4 }}>
                    Does this person have any past scanning experience?
                </Text>
                <TraineeExperienceFormField {...props} />
            </GridLayout>

            <GridLayout>
                <Text variant={'body2'} medium>
                    Which scanner has this person used most recently?
                </Text>
                <TraineeScannerFormField {...props} />
            </GridLayout>

            <RemoveTraineeButton {...props} />
        </FieldsLayout>
    );
};
