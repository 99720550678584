import { Button, FlossPalette, stylesFactory, Text, Fade, Grid } from '@orthly/ui-primitives';
import React from 'react';

enum AnimationStepDelay {
    Flash = '0.5s',
    Pop = '1.5s',
}
const animationDuration = 500;
const AnimationCommons = {
    animationDuration: `${animationDuration}ms`,
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 1,
    animationFillMode: 'both',
};

const useStyles = stylesFactory(() => ({
    modal: {
        position: 'fixed',
        inset: 0,
        padding: 16,
        backgroundColor: FlossPalette.WHITE,
        justifyContent: 'center',
    },
    container: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        textAlign: 'center',
        alignItems: 'stretch',
        width: 'fit-content',
        height: '100%',
        gap: '16px',
    },
    photo: {
        borderRadius: 16,
        height: '50%',
        maxWidth: '100%',
        objectFit: 'cover',
        ...AnimationCommons,
        animationName: '$cameraCapture',
    },
    '@keyframes cameraCapture': {
        '0%': { transform: 'scale(150%)' },
        '15%': { transform: 'scale(160%)' },
        '85%': { transform: 'scale(90%)' },
        '100%': { transform: 'scale(100%)' },
    },
    popup: {
        ...AnimationCommons,
        animationName: '$popup',
    },
    '@keyframes popup': {
        '0%': { opacity: 0, transform: 'translateY(500%)' },
        '100%': { opacity: 1, transform: 'translateY(0)' },
    },
}));

export const WebcamCaptureDone: React.VFC<{
    photoBlob: Blob;
    onConfirm: () => void;
    onRetake: () => void;
}> = ({ photoBlob, onConfirm, onRetake }) => {
    const classes = useStyles();
    const [src, setSrc] = React.useState<string>('');
    React.useEffect(() => {
        const src = URL.createObjectURL(photoBlob);
        setSrc(src);
        return () => URL.revokeObjectURL(src);
    }, [photoBlob]);
    return (
        <Grid container className={classes.modal}>
            <Fade in appear style={{ transitionDelay: AnimationStepDelay.Flash }} timeout={animationDuration}>
                <Grid container className={classes.container}>
                    <Text
                        variant={'h4'}
                        medium
                        className={classes.popup}
                        style={{ animationDelay: AnimationStepDelay.Pop }}
                    >
                        Big smiles, looking great!
                    </Text>
                    <img
                        src={src}
                        alt={'your profile'}
                        className={classes.photo}
                        style={{ animationDelay: AnimationStepDelay.Pop }}
                    />
                    <Fade in appear style={{ transitionDelay: AnimationStepDelay.Pop }} timeout={animationDuration}>
                        <Grid container direction={'column'} wrap={'nowrap'}>
                            <Button variant={'primary'} onClick={onConfirm}>
                                Confirm
                            </Button>
                            <Button variant={'secondary'} style={{ backgroundColor: 'unset' }} onClick={onRetake}>
                                Re-take photo
                            </Button>
                        </Grid>
                    </Fade>
                </Grid>
            </Fade>
        </Grid>
    );
};
