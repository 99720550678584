import crown_src from '../../assets/images/new-skus/crown_128_2x.png';
import dentures_src from '../../assets/images/new-skus/dentures_128_2x.png';
import implant_src from '../../assets/images/new-skus/implant_128_2x.png';
import partials_src from '../../assets/images/new-skus/partial_128_2x.png';
import retainer_src from '../../assets/images/new-skus/retainer_128_2x.png';
import type { ItemMetafieldV2 } from '@orthly/items';
import { PreferenceItemMetafields } from '@orthly/items';
import _ from 'lodash';

export const FIRST_ORDER_PREF_SKU_LABEL = 'Crown & Bridge';
type SKULabel =
    | typeof FIRST_ORDER_PREF_SKU_LABEL
    | 'Implants'
    | 'Night Guards'
    | 'Partial Dentures'
    | 'Retainers'
    | 'Full Dentures';
export type OrderPreferencesSKULabel = SKULabel;

type SKUDefinition = {
    label: SKULabel;
    unit_types?: readonly string[];
    hide_unit_types?: readonly string[];
    material_types?: readonly string[];
    img_src?: string;
};

const PFM_MATERIALS = [
    'Porcelain Fused Zirconia Monolithic',
    'PFM - High Noble - Yellow',
    'PFM - High Noble - White',
    'PFM - Noble - White',
    'PFM - Non-Precious',
] as const;

const SKU_DEFINITIONS: readonly SKUDefinition[] = [
    {
        label: 'Crown & Bridge',
        unit_types: ['Crown', 'Bridge', 'CrownPontic', 'Veneer', 'Inlay'],
        hide_unit_types: ['Abutment'],
        material_types: PFM_MATERIALS,
        img_src: crown_src,
    },
    {
        label: 'Implants',
        unit_types: ['Abutment', 'AbutmentScrewRetainedCrown', 'ImplantPlanning'],
        img_src: implant_src,
    },
    { label: 'Partial Dentures', unit_types: ['Partial Denture'], img_src: partials_src },
    { label: 'Retainers', unit_types: ['Retainer'], img_src: retainer_src },
    { label: 'Full Dentures', unit_types: ['FullDenture', 'Denture'], img_src: dentures_src },
] as const;
export const ORDER_PREFERENCES_SKU_DEFINITIONS = SKU_DEFINITIONS;

export const isOrderPreferencesSKULabel = (s: string): s is SKULabel => {
    return !!SKU_DEFINITIONS.find(agg => agg.label === s);
};

export const findEnabledPrefs = (selectedSKUs?: string[]): Partial<Record<SKULabel, ItemMetafieldV2[]>> => {
    const seenFieldIds: Set<string> = new Set<string>(); // Helps prevent double-inclusion of fields
    const filteredSKUs = selectedSKUs
        ? SKU_DEFINITIONS.filter(agg => selectedSKUs.includes(agg.label))
        : SKU_DEFINITIONS;
    return filteredSKUs.reduce((skuwisePrefs: Partial<Record<SKULabel, ItemMetafieldV2[]>>, skuDefinition) => {
        skuwisePrefs[skuDefinition.label] = PreferenceItemMetafields.filter(field => {
            if (seenFieldIds.has(field.id)) {
                return false;
            }
            seenFieldIds.add(field.id);
            // we use the following rules to determine what metafields to show
            // if the unit types or materials overlap with display rules, we show the metafield
            // except in the case that hide_rule_types matches the field's, which will cause us to exclude it
            return (
                (_.intersection(field.display_rules.unit_types ?? [], skuDefinition.unit_types ?? []).length > 0 ||
                    _.intersection(field.display_rules.materials ?? [], skuDefinition.material_types ?? []).length >
                        0) &&
                _.intersection(field.display_rules.unit_types ?? [], skuDefinition.hide_unit_types ?? []).length === 0
            );
        });
        return skuwisePrefs;
    }, {});
};

export const getPreferenceFieldsForSkuLabel = (skuLabel: SKULabel): ItemMetafieldV2[] => {
    return findEnabledPrefs([skuLabel])[skuLabel] ?? [];
};
