import type { ScanbodyInventoryItem } from '../hooks/useScanbodyInventory';
import { useScanbodyRequestAction } from '../state/ScanbodyRequest.actions';
import {
    ScanbodyRequestLinkLookupStage,
    useScanbodyRequestLinkLookupStageSelector,
} from '../state/ScanbodyRequest.selectors';
import { ScanbodyStage } from '../state/ScanbodyRequest.types';
import type { ScanbodyProps } from '@orthly/dentin';
import { ScanbodyDisplay } from '@orthly/dentin';
import { ShippingUtils } from '@orthly/shared-types';
import { TruckIconOutlined, BoxIcon } from '@orthly/ui';
import { stylesFactory, FlossPalette, useScreenIsMobile, Grid, Text } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const bannerStyle = {
    padding: 8,
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 2,
};

const useStyles = stylesFactory(theme => ({
    root: {
        marginRight: 24,
        marginBottom: 24,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
    },
    bannerAnchor: { position: 'relative' },
    bannerContainer: { position: 'absolute', top: 8, right: 8 },
    bannerIcon: { marginRight: 8 },
    preparingShipmentBanner: {
        ...bannerStyle,
        backgroundColor: FlossPalette.GRAY,
        color: FlossPalette.WHITE,
    },
    beingShippedBanner: {
        ...bannerStyle,
        backgroundColor: FlossPalette.BURGUNDY,
        color: FlossPalette.WHITE,
    },
    reorderBanner: {
        ...bannerStyle,
        backgroundColor: FlossPalette.STAR_GRASS,
        color: FlossPalette.WHITE,
    },
    clickable: {
        cursor: 'pointer',
    },
}));

interface ScanbodyDisplayWrapperProps {
    scanbody: ScanbodyProps;
    banner?: React.ReactNode;
    style?: React.CSSProperties;
    onClick?: (e: React.MouseEvent) => void;
}

const ScanbodyDisplayWrapper: React.FC<ScanbodyDisplayWrapperProps> = props => {
    const classes = useStyles();

    return (
        <Grid
            className={clsx([classes.root, props.onClick && classes.clickable])}
            style={props.style}
            onClick={props.onClick}
        >
            <Grid item container justifyContent={'flex-end'} className={classes.bannerAnchor}>
                {props.banner && (
                    <Grid className={classes.bannerContainer} container justifyContent={'flex-end'}>
                        {props.banner}
                    </Grid>
                )}
            </Grid>
            <ScanbodyDisplay {...props.scanbody} />
        </Grid>
    );
};

interface ScanbodyInventoryDisplayWrapperProps {
    scanbody: ScanbodyInventoryItem;
}

export const ScanbodyInventoryDisplayWrapper: React.FC<ScanbodyInventoryDisplayWrapperProps> = props => {
    const { scanbody } = props;
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const linkLookupStage = useScanbodyRequestLinkLookupStageSelector();
    const setField = useScanbodyRequestAction('SET_REQUEST_FIELD');
    const setStage = useScanbodyRequestAction('SET_STAGE');
    const banner = React.useMemo(() => {
        if (linkLookupStage === ScanbodyRequestLinkLookupStage.PARTIAL) {
            return (
                <Text className={classes.reorderBanner} color={'WHITE'} variant={'body2'} medium>
                    <BoxIcon className={classes.bannerIcon} /> Request again
                </Text>
            );
        }
        if (linkLookupStage === ScanbodyRequestLinkLookupStage.EMPTY) {
            if (scanbody.shipmentStatus === 'shipping') {
                return (
                    <Text className={classes.beingShippedBanner} color={'WHITE'} variant={'body2'} medium>
                        <TruckIconOutlined className={classes.bannerIcon} /> Track Package
                    </Text>
                );
            }
            if (scanbody.shipmentStatus === 'preparing') {
                return (
                    <Text className={classes.preparingShipmentBanner} color={'WHITE'} variant={'body2'} medium>
                        <BoxIcon className={classes.bannerIcon} /> Preparing to ship
                    </Text>
                );
            }
        }
    }, [classes, linkLookupStage, scanbody]);
    const onClick = React.useMemo(() => {
        if (linkLookupStage === ScanbodyRequestLinkLookupStage.PARTIAL) {
            return () => {
                setField({ property: 'manufacturer', value: scanbody.implantManufacturer });
                setField({ property: 'system', value: scanbody.implantSystem });
                setField({ property: 'connection', value: scanbody.implantConnection });
                setStage(ScanbodyStage.DETAIL);
            };
        }
        const { shipment, shipmentStatus } = scanbody;
        if (linkLookupStage === ScanbodyRequestLinkLookupStage.EMPTY && shipmentStatus === 'shipping' && shipment) {
            const link = ShippingUtils.getTrackingLinkForCarrier(shipment.carrier, shipment.tracking_number);
            return () => window.open(link, '_blank');
        }
    }, [setField, setStage, linkLookupStage, scanbody]);

    return (
        <ScanbodyDisplayWrapper
            scanbody={props.scanbody}
            banner={banner}
            onClick={onClick}
            style={{ width: isMobile ? '100%' : undefined }}
        />
    );
};
