import type { CheckoutSidebarStepContainerProps } from './checkoutSidebarTypes';
import { CheckoutSidebarStepVariant } from './checkoutSidebarTypes';
import type { TextProps } from '@orthly/ui-primitives';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

export enum CheckoutSidebarTextVariant {
    Header = 'Header',
    Normal = 'Normal',
    Current = 'Current',
}
export interface CheckoutSidebarTextEntry {
    caption: React.ReactNode;
    content?: React.ReactNode;
    variant?: CheckoutSidebarTextVariant;
    onClick?(): void;
}
export interface CheckoutSidebarTextStepProps extends CheckoutSidebarStepContainerProps {
    texts: CheckoutSidebarTextEntry[];
    contentPlaceholder?: React.ReactNode;
}
export const TextStepEntryVariantProps: {
    [_ in CheckoutSidebarTextVariant]: { caption: TextProps; content: TextProps };
} = {
    [CheckoutSidebarTextVariant.Header]: {
        caption: { variant: 'h5', color: 'BLACK' },
        content: { variant: 'body2', color: 'DARK_GRAY' },
    },
    [CheckoutSidebarTextVariant.Normal]: {
        caption: { variant: 'body2', color: 'DARK_GRAY' },
        content: { variant: 'body2', color: 'BLACK', medium: true },
    },
    [CheckoutSidebarTextVariant.Current]: {
        caption: { variant: 'body2', color: 'BURGUNDY', medium: true },
        content: { variant: 'body2', color: 'BURGUNDY', medium: true },
    },
};

export const CheckoutSidebarTextStep: React.FC<CheckoutSidebarTextStepProps> = props => {
    const { texts, contentPlaceholder, variant: stepVariant } = props;
    const textVariantDefault =
        stepVariant === CheckoutSidebarStepVariant.Header
            ? CheckoutSidebarTextVariant.Header
            : CheckoutSidebarTextVariant.Normal;
    return (
        <>
            {texts.map(({ caption, content = contentPlaceholder, variant = textVariantDefault, onClick }, index) => (
                <Grid container alignItems={'center'} justifyContent={'space-between'} wrap={'nowrap'} key={index}>
                    <Text {...TextStepEntryVariantProps[variant].caption} onClick={onClick}>
                        {caption}
                    </Text>
                    {content && <Text {...TextStepEntryVariantProps[variant].content}>{content}</Text>}
                </Grid>
            ))}
        </>
    );
};
