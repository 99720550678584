import { PatientNameField, PatientDataFields } from './PatientFields';
import type { PatientFormPatient, PatientFormAnalytics } from './PatientForm.types';
import { splitPatientName, isValidPatientName } from './PatientForm.util';
import { PatientsCard } from './PatientsCard';
import { Text, Grid, Tooltip, Button } from '@orthly/ui-primitives';
import React from 'react';

interface ExistingPatientSelectProps {
    patients: PatientFormPatient[];
    onPatientSelected: (patient: PatientFormPatient) => void;
    analytics: PatientFormAnalytics;
}

const ExistingPatientSelect: React.VFC<ExistingPatientSelectProps> = ({ patients, onPatientSelected, analytics }) => {
    if (!patients.length) {
        return null;
    }

    return (
        <PatientsCard
            patients={patients}
            title={'Existing Patients'}
            Action={({ patient }) => (
                <Button
                    variant={'secondary'}
                    endIcon={'ChevronRight'}
                    onClick={() => onPatientSelected(patient)}
                    analytics={{
                        ...analytics,
                        AssetName: `Select Existing Patient`,
                    }}
                >
                    Select
                </Button>
            )}
        />
    );
};

interface CreatePatientFormProps {
    onSubmit: (patient: Pick<PatientFormPatient, 'gender' | 'birthday'>) => void;
    searchDisableReason?: string;
    fullName?: string;
    analytics: PatientFormAnalytics;
}

const CreatePatientForm: React.VFC<CreatePatientFormProps> = ({
    onSubmit,
    searchDisableReason,
    fullName,
    analytics,
}) => {
    const [gender, setGender] = React.useState<string | undefined>();
    const [birthday, setBirthday] = React.useState<string | undefined>();
    const [birthdayError, setBirthdayError] = React.useState<string | undefined>(undefined);

    const errorReason = React.useMemo(() => {
        if (!gender) {
            return `Please select a patient gender.`;
        }
        if (!birthday) {
            return `Please select a patient date of birth.`;
        }
        return birthdayError ?? searchDisableReason;
    }, [birthday, birthdayError, searchDisableReason, gender]);

    return (
        <Grid container direction={'column'} spacing={1} style={{ padding: 0 }}>
            <Grid item>
                {fullName && (
                    <Text variant={'body2'} color={'GRAY'} medium style={{ marginTop: 8 }}>
                        Or, create a new patient called{' '}
                        <Text variant={'body2'} component={'span'} medium>
                            {fullName}
                        </Text>
                    </Text>
                )}
            </Grid>
            <Grid container item direction={'row'} spacing={1}>
                <PatientDataFields
                    gender={gender}
                    setGender={setGender}
                    birthday={birthday}
                    setBirthday={setBirthday}
                    birthdayError={birthdayError}
                    setBirthdayError={setBirthdayError}
                    autoOpenBirthday={true}
                />
            </Grid>
            <Grid item>
                <Tooltip title={errorReason ?? ''} disableHoverListener={!errorReason}>
                    <div>
                        <Button
                            variant={'primary'}
                            fullWidth
                            endIcon={'ChevronRight'}
                            disabled={!!errorReason}
                            onClick={() => {
                                if (!gender || !birthday || !!errorReason) {
                                    return;
                                }
                                onSubmit({
                                    gender,
                                    birthday,
                                });
                            }}
                            analytics={{
                                ...analytics,
                                AssetName: `Create Patient`,
                            }}
                        >
                            Create & Continue
                        </Button>
                    </div>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

const searchExistingPatients = (search: string, existingPatients: PatientFormPatient[]): PatientFormPatient[] => {
    const { first, last } = splitPatientName(search);

    if (!existingPatients.length) {
        return [];
    }

    if (first.length < 2 && last.length < 2) {
        return [];
    }

    const nameMatches = (candidate: string, name: string) => {
        return name.length && candidate.toLowerCase().includes(name.toLowerCase());
    };

    return existingPatients.filter(p =>
        first && last
            ? nameMatches(p.first_name, first) && nameMatches(p.last_name, last)
            : [first, last].some(name => nameMatches(p.first_name, name) || nameMatches(p.last_name, name)),
    );
};

interface CreateOrSelectPatientFormProps {
    onExistingPatientSelected: (patient: PatientFormPatient) => void;
    onNewPatientCreated: (patient: Omit<PatientFormPatient, 'id'>) => void;
    existingPatients: PatientFormPatient[];
    analytics: PatientFormAnalytics;
}

export const CreateOrSelectPatientForm: React.VFC<CreateOrSelectPatientFormProps> = ({
    onExistingPatientSelected,
    onNewPatientCreated,
    existingPatients,
    analytics,
}) => {
    const [search, setSearch] = React.useState<string>('');

    const matchingPatients = searchExistingPatients(search, existingPatients);

    return (
        <>
            <Grid container item direction={'column'} spacing={1} style={{ padding: '8px 0px 0px' }}>
                <Grid item>
                    <Text variant={'body2'} medium>
                        What's their name?
                    </Text>
                </Grid>
                <Grid item data-test={'patient-name-input-wrapper'}>
                    <PatientNameField
                        autoFocus
                        search={search}
                        setSearch={setSearch}
                        overrideValidationError={!!matchingPatients.length}
                    />
                </Grid>
            </Grid>
            <ExistingPatientSelect
                patients={matchingPatients}
                onPatientSelected={onExistingPatientSelected}
                analytics={analytics}
            />
            {search.length >= 2 && (
                <CreatePatientForm
                    searchDisableReason={
                        !isValidPatientName(search) ? `Please enter both the first and last names` : undefined
                    }
                    fullName={matchingPatients.length > 0 ? search : undefined}
                    onSubmit={patient => {
                        if (!isValidPatientName(search)) {
                            return;
                        }
                        const { first, last } = splitPatientName(search);
                        onNewPatientCreated({ ...patient, last_name: last, first_name: first });
                    }}
                    analytics={analytics}
                />
            )}
        </>
    );
};
