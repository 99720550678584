import { getPartnerId } from '../../../../../utils/authorization';
import { SettingsBaseModal } from '../../../components/SettingsBaseModal';
import { useSetDoctorPreferences } from '../../../state/usePracticeStaffControls';
import type { ForwardingSettingsBaseModalProps, ForwardingSettingsBaseModalFormProps } from './ForwardingSettingsBase';
import { useGetNotificationGroup } from './hooks';
import { useForwardingModalStyles } from './styles';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlSetReceiversInput } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useScreenIsMobile, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const SetReceiversOnPref_Mutation = graphql(`
    mutation SetReceiversOnPref($data: SetReceiversInput!) {
        setReceiversOnPref(data: $data)
    }
`);

const ConfirmStopSharingContent: React.VFC<
    Omit<ForwardingSettingsBaseModalFormProps, 'sendersOrReceivers'>
> = props => {
    const { setOpen, employeePrefs, notificationName, setShowSuccess, refetch } = props;
    const isMobile = useScreenIsMobile();
    const { refetch: currentProfileRefetch } = useSetDoctorPreferences();
    const notification_group = useGetNotificationGroup(notificationName);
    const [submitMtn] = useMutation(SetReceiversOnPref_Mutation);
    const mtnSubmitter = (data: LabsGqlSetReceiversInput) => submitMtn({ variables: { data } });
    const { submit } = useChangeSubmissionFn<any, [LabsGqlSetReceiversInput]>(mtnSubmitter, {
        closeOnComplete: true,
        onSuccess: async () => {
            setShowSuccess(true);
            refetch ? await refetch() : await currentProfileRefetch();
        },
    });

    return (
        <Grid container>
            <Grid container>
                <Text variant={'body2'}>
                    You are about to stop sharing your <span style={{ fontWeight: 600 }}>{notificationName}</span>{' '}
                    notifications. Are you sure you want to continue?
                </Text>
            </Grid>
            <Grid container justifyContent={'flex-end'} style={{ paddingTop: 40 }}>
                <Button variant={'secondary'} style={{ marginRight: 8 }} onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant={'alert-secondary'}
                    onClick={async () => {
                        await submit({
                            notification_group,
                            preference_id: employeePrefs.id,
                            receiver_ids: [],
                        });
                        setOpen(false);
                    }}
                >
                    Stop sharing{isMobile ? '' : ' notifications'}
                </Button>
            </Grid>
        </Grid>
    );
};

export const ConfirmStopSharingModal: React.VFC<
    Omit<ForwardingSettingsBaseModalProps, 'sendersOrReceivers'>
> = props => {
    const { open, setOpen, employeePrefs, notificationName, setShowSuccess, refetch } = props;
    const classes = useForwardingModalStyles();
    const session = useSession();
    const userId = session?.user_id;
    const partnerId = getPartnerId(session);

    if (!userId || !partnerId) {
        return null;
    }

    return (
        <SettingsBaseModal
            content={
                <ConfirmStopSharingContent
                    setOpen={setOpen}
                    employeePrefs={employeePrefs}
                    notificationName={notificationName}
                    setShowSuccess={setShowSuccess}
                    refetch={refetch}
                />
            }
            loading={false}
            title={'Just confirming...'}
            open={open}
            setOpen={setOpen}
            buttonText={'Stop sharing'}
            buttonProps={{
                style: { padding: 0, paddingLeft: 16, height: 'fit-content' },
                classes: { root: classes.buttonLabel },
            }}
            decreaseTitlePadding
        />
    );
};
