import { ListAbutmentPartsProvider } from '../../context';
import type { ItemActionComponent } from '../OrderDetails/OrderItemsTable.types';
import { BridgeSection } from './BridgeSection';
import { DenturesSection } from './DenturesSection';
import { ImplantSection } from './ImplantSection';
import type { ItemSectionProps } from './ItemsSection.types';
import { ScanbodyUsagesProvider } from './ScanbodyUsages.context';
import { SingleItemSection } from './SingleItemSection';
import { ItemsSectionDisplayClasses } from './util/displayClasses';
import type { DedupedOrderFormItem } from './util/mergeOrderItems';
import { OrderSummaryComponentsProvider, useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import sortBy from 'lodash/sortBy';
import React from 'react';

/**
 * NOTE: For cross-compat between PDF and HTML, we settled on sticking with the pdf-compatible syntax of using 'pt'
 * Every style property matching /[0-9]pt/ is converted to px for html display
 */

const ItemSection = (props: ItemSectionProps) => {
    if (props.item.sku === LabOrderItemSKUType.Denture) {
        return <DenturesSection {...props} item={props.item} />;
    }
    if (props.item.sku === LabOrderItemSKUType.Bridge) {
        return <BridgeSection {...props} item={props.item} />;
    }
    if (props.item.sku === LabOrderItemSKUType.Implant || props.item.sku === LabOrderItemSKUType.ImplantBridge) {
        return <ImplantSection {...props} item={props.item} />;
    }
    return <SingleItemSection {...props} />;
};

/**
 * This is a generalized ItemsSection for displaying the items of an order, for use in both our PDF rendering for Order Form
 * and in our HTML frontend apps
 */
export interface ItemsSectionProps {
    /** Subset of order items to be rendered */
    items: DedupedOrderFormItem[];
    style?: React.CSSProperties;
    hideHeader?: boolean;
}

const ItemsSectionRender: React.VFC<ItemsSectionProps> = ({ items, style, hideHeader }) => {
    const { ContainerComponent, fontComponents, Action } = useOrderSummaryComponents();
    const { SemiBoldText, LightText } = fontComponents;

    const sortedItems = sortBy(items, i => {
        const unns = CartItemV2Utils.getUniqueUNNs(i);
        return Math.min(...(unns.length > 0 ? [...unns, ...i.duplicateTeeth] : [Number.POSITIVE_INFINITY]));
    });

    return (
        <ContainerComponent
            className={ItemsSectionDisplayClasses.ROOT}
            style={{
                width: '100%',
                ...style,
            }}
        >
            {!hideHeader && (
                <ContainerComponent
                    className={ItemsSectionDisplayClasses.HEADER}
                    style={{ width: '100%', flexDirection: 'row', paddingTop: '5pt' }}
                >
                    <SemiBoldText style={{ flex: 25 }}>{items.length === 1 ? 'Item' : 'Items'}</SemiBoldText>
                    <LightText style={{ color: 'gray', flex: 30, fontSize: '12pt', marginTop: '2pt' }}>
                        Details
                    </LightText>
                    <LightText
                        style={{ color: 'gray', flex: 45, textAlign: 'right', fontSize: '12pt', marginTop: '2pt' }}
                    >
                        Shade / Occlusal Staining
                    </LightText>
                    {Action ? <Grid style={{ flex: 20 }} /> : null}
                </ContainerComponent>
            )}
            <ContainerComponent className={ItemsSectionDisplayClasses.ITEMS}>
                {sortedItems.map((item, index) => (
                    <ItemSection
                        key={index}
                        item={item}
                        index={index}
                        inlinePrefs={item.inlinePreferences}
                        allItems={sortedItems}
                    />
                ))}
            </ContainerComponent>
        </ContainerComponent>
    );
};

export const ItemsSection = <TARGET extends 'html' | 'pdf'>(
    props: ItemsSectionProps & { target: TARGET; Action?: ItemActionComponent },
) => {
    return (
        <OrderSummaryComponentsProvider target={props.target} Action={props.Action}>
            <ListAbutmentPartsProvider>
                <ScanbodyUsagesProvider items={props.items}>
                    <ItemsSectionRender {...props} data-test={'items-section'} />
                </ScanbodyUsagesProvider>
            </ListAbutmentPartsProvider>
        </OrderSummaryComponentsProvider>
    );
};
