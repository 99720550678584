import { ChevronRight } from '@orthly/ui';
import { Text, Link, styled } from '@orthly/ui-primitives';
import React from 'react';

const OrderLink = styled(Link)({
    marginLeft: '12px',
    width: 'max-content',
});

const OrderLinkText = styled(Text)({
    display: 'flex',
    alignItems: 'center',
});

export const OrderTimelineLinkedOrder: React.FC<{
    className?: string;
    orderId: string | null | undefined;
}> = ({ children, className, orderId }) => {
    if (!orderId || !children) {
        return null;
    }

    return (
        <OrderLink className={className} href={orderId}>
            <OrderLinkText variant={'body2'} color={'PRIMARY_FOREGROUND'} medium>
                {children}
                <ChevronRight />
            </OrderLinkText>
        </OrderLink>
    );
};
