import { ErrorPage } from '../ErrorPage';
import { PatientShareDesktop } from './PatientShareDesktop';
import { PatientShareMobile } from './PatientShareMobile';
import { useQuery } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import { LoadBlocker, useScreenIsMobile } from '@orthly/ui';
import { useFirebasePreviewMulti } from '@orthly/veneer';
import React from 'react';

const PatientPortalPatientShare_Query = graphql(`
    query PatientPortalPatientShare($token: String!) {
        patientPortalPatientShare(token: $token) {
            organization_name
            organization_phone_number
            appointment_date
            appointment_title
            message
            annotation_urls
            scan_export {
                ...OrderDesignPreviewDesignModelPayloadsScanExport_Fragment
            }
        }
    }
`);

export const PatientShareTokenAuthenticated: React.VFC<{ token: string }> = ({ token }) => {
    const isMobile = useScreenIsMobile();

    const { data, error, loading } = useQuery(PatientPortalPatientShare_Query, {
        variables: {
            token,
        },
    });

    const {
        organization_name,
        organization_phone_number,
        appointment_date,
        appointment_title,
        message,
        annotation_urls,
        scan_export,
    } = data?.patientPortalPatientShare || {};
    const urls = annotation_urls
        ? annotation_urls.map((fullPath: string) => ({
              source: fullPath,
              name: fullPath,
          }))
        : [];
    const { result: previews } = useFirebasePreviewMulti(urls, true);

    React.useEffect(() => {
        BrowserAnalyticsClientFactory.Instance?.track('Patient App - Share Scans - Share Scans Page Loaded', {});
    }, []);

    if (loading) {
        return (
            <LoadBlocker blocking={true}>
                <ErrorPage>Loading...</ErrorPage>
            </LoadBlocker>
        );
    }

    if (error || !data?.patientPortalPatientShare) {
        return <ErrorPage>Error loading data</ErrorPage>;
    }

    if (isMobile) {
        return (
            <PatientShareMobile
                organizationName={organization_name}
                organizationPhoneNumber={organization_phone_number}
                appointmentDate={appointment_date}
                appointmentTitle={appointment_title}
                message={message}
                previews={previews}
                scanExport={scan_export}
            />
        );
    }

    return (
        <PatientShareDesktop
            organizationName={organization_name}
            organizationPhoneNumber={organization_phone_number}
            appointmentDate={appointment_date}
            appointmentTitle={appointment_title}
            message={message}
            previews={previews}
            scanExport={scan_export}
        />
    );
};
