import { ConfirmPhoneNumberForm } from '../../../../components/forms/ConfirmPhoneNumberForm';
import { useAccountSettings } from './context';
import { RootActionDialog } from '@orthly/ui';

interface MobilePhoneConfirmationDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

export const MobilePhoneConfirmationDialog: React.FC<MobilePhoneConfirmationDialogProps> = props => {
    const { open, setOpen } = props;
    const { verifyPhoneProps } = useAccountSettings();

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            dialogProps={{ fullWidth: true }}
            title={'Confirm mobile phone number'}
            content={<ConfirmPhoneNumberForm {...verifyPhoneProps} />}
            hideButton
        />
    );
};
