import axios from 'axios';
import type firebase from 'firebase/compat';

export async function fetchFirebaseFile(firebaseStorage: firebase.storage.Storage, pathToRef?: string) {
    if (!pathToRef) {
        return;
    }

    const ref = firebaseStorage.ref(pathToRef);
    const url = await ref.getDownloadURL();
    const res = await axios({
        url,
        responseType: 'blob',
    });
    return { url, file: new File([res.data], ref.name, { type: 'image' }) };
}
