import { SettingsBaseModal } from '../../../components/SettingsBaseModal';
import type { AccountInfo } from './LinkBankAccountContent';
import { LinkBankAccountContent } from './LinkBankAccountContent';
import React from 'react';

interface LinkBankAccountProps {
    /** The state of the modal */
    open: boolean;
    /** The function to set the state of the modal */
    setOpen: (value: boolean) => void;
    /** The function to link the bank account */
    linkAccount: (routingNumber: string, accountNumber: string) => Promise<void>;
    /** A boolean indicating if the bank account linking is in progress */
    linkingBankAccount: boolean;
    /** The function to close the payment modal if linking a bank account from the payment modal */
    onClosePaymentAction?: () => void;
}

const isValidAccountInfo = (accountInfo: AccountInfo) => {
    return (
        accountInfo.routingNumber.length === 9 &&
        accountInfo.accountNumber.length >= 5 &&
        accountInfo.confirmAccountNumber.length >= 5 &&
        accountInfo.accountNumber === accountInfo.confirmAccountNumber
    );
};

const DEFAULT_ACCOUNT_INFO = {
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
};

export const LinkBankAccount: React.FC<LinkBankAccountProps> = ({
    open,
    setOpen,
    linkAccount,
    linkingBankAccount,
    onClosePaymentAction,
}) => {
    const [accountInfo, setAccountInfo] = React.useState<AccountInfo>({ ...DEFAULT_ACCOUNT_INFO });

    const linkAccountDisabled = !isValidAccountInfo(accountInfo);

    return (
        <SettingsBaseModal
            content={
                <LinkBankAccountContent
                    accountInfo={accountInfo}
                    setAccountInfo={setAccountInfo}
                    linkAccountDisabled={linkAccountDisabled}
                    setOpen={setOpen}
                    linkAccount={async (routingNumber, accountNumber) => {
                        setAccountInfo({ ...DEFAULT_ACCOUNT_INFO });
                        await linkAccount(routingNumber, accountNumber);
                    }}
                    linkingBankAccount={linkingBankAccount}
                    onClosePaymentAction={onClosePaymentAction}
                />
            }
            loading={false}
            title={'Link your bank account'}
            open={open}
            setOpen={(open: boolean) => {
                setOpen(open);
                if (!open) {
                    onClosePaymentAction?.();
                }
            }}
            hideButton
        />
    );
};
