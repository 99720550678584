import type { StarRatingType } from '../inbox/components/tracker/OrderSummaryStepper';
import { StarRatingInput } from '../inbox/components/tracker/OrderSummaryStepper';
import { useFeedbackCurrentLabOrder } from './Feedback.util';
import { FeedbackPersonCard } from './FeedbackPersonCard';
import { feedbackRecipient } from './FeedbackRecipients';
import type { FeedbackScreen } from './state/feedback.reducer';
import { useFeedbackSelector } from './state/feedback.selectors';
import type { FeedbackAndRefabItem } from '@orthly/dentin';
import { ProductImageWrapper } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import type { IOrderItemShade, IOrderItemV2DTO } from '@orthly/items';
import type { GridProps, GridSize } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, Grid, makeStyles, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: 424,
            padding: '20px 70px 40px 22px',
            overflowY: `auto`,
        },
        heading: {
            marginTop: 28,
            marginBottom: 20,
        },
        details: {
            padding: '24px 16px',
            background: FlossPalette.TAN,
            border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            borderRadius: 16,
        },
    }),
);

interface DataPair {
    title: string;
    value: string | DataPair[];
}

const DataGrid: React.VFC<GridProps & { pairs: DataPair[]; widths?: [GridSize, GridSize] }> = props => {
    const [leftWidth, rightWidth] = props.widths ?? [4, 8];

    return (
        <Grid container style={{ rowGap: 4 }} {...props}>
            {props.pairs.map((p, index) => (
                <React.Fragment key={index}>
                    <Grid item xs={leftWidth}>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            {p.title}
                        </Text>
                    </Grid>
                    {typeof p.value === 'string' ? (
                        <Grid item xs={rightWidth}>
                            <Text variant={'body2'}>{p.value}</Text>
                        </Grid>
                    ) : (
                        <DataGrid item xs={rightWidth} pairs={p.value} style={{ rowGap: 0 }} />
                    )}
                </React.Fragment>
            ))}
        </Grid>
    );
};

function dataForShades(shades: IOrderItemShade[]): DataPair[] {
    return shades.map(shade => ({ title: _.capitalize(shade.name), value: shade.value }));
}

function dataForItem(item: IOrderItemV2DTO): DataPair[] {
    const material = CartItemV2Utils.getItemDisplayMaterial(item);
    return _.compact([
        material && { title: 'Material', value: material },
        item.shades && item.shades.length > 0 && { title: 'Shade', value: dataForShades(item.shades) },
    ]);
}

const ItemRows: React.VFC<{ item: FeedbackAndRefabItem; starRating: StarRatingType }> = ({ item, starRating }) => {
    const savedStarRating = useFeedbackSelector(state => state.items[item.id]?.star_rating || null) as StarRatingType;
    return (
        <>
            {/* Icon and name */}
            <Grid item container alignItems={'center'} style={{ gap: 8 }}>
                <ProductImageWrapper
                    backgroundColor={FlossPalette.WHITE}
                    product={CartItemV2Utils.getProductUnitType(item.labOrderItem)}
                    wrapperStyle={{ border: 'solid 1px lightgray' }}
                />
                <Text variant={'body2'} medium>
                    {item.name}
                </Text>
            </Grid>

            <Grid container direction={'column'}>
                <StarRatingInput rating={starRating === 0 ? savedStarRating : starRating} readonly={true} />
            </Grid>
            {/* Item data: Material, shade, etc. */}
            <DataGrid pairs={dataForItem(item.labOrderItem)} />

            {/* Divider */}
            <div
                style={{
                    backgroundColor: FlossPalette.DARK_TAN,
                    width: '100%',
                    height: '1px',
                    margin: '16px 0px',
                }}
            />
        </>
    );
};

const formatDate = (date: string) => moment(date).format('MMMM D, YYYY');

export const FeedbackSidebar: React.VFC<{ screen: FeedbackScreen; starRating: StarRatingType }> = ({
    screen,
    starRating,
}) => {
    const { lab_order } = useFeedbackCurrentLabOrder();
    const classes = useStyles();
    const items = 'items' in screen ? screen.items : [];
    const current_item = 'item' in screen ? screen.item : undefined;

    const orderData: DataPair[] = lab_order
        ? [
              { title: 'Doctor', value: lab_order.doctor_name },
              { title: 'Placed on', value: formatDate(lab_order.created_at) },
              { title: 'Arrived on', value: formatDate(lab_order.delivery_date ?? '') },
          ]
        : [];

    const recipient = feedbackRecipient(items, current_item);
    const fullscreen_sidebar_shown = useFeedbackSelector(s => s.show_fullscreen_sidebar);
    const currentItem = items.find(item => item === current_item);
    if (!currentItem) {
        return null;
    }

    return (
        <div
            className={classes.root}
            style={{ borderLeft: !fullscreen_sidebar_shown ? `1px solid ${FlossPalette.DARK_TAN}` : 'none' }}
        >
            <Text variant={'h6'} medium className={classes.heading}>
                Details
            </Text>
            <Grid container className={classes.details}>
                <ItemRows item={currentItem} starRating={starRating} />
                <DataGrid pairs={orderData} />
            </Grid>
            {recipient && (
                <>
                    <Text variant={'h6'} medium className={classes.heading}>
                        Your feedback will be sent to
                    </Text>
                    <FeedbackPersonCard person={recipient} />
                </>
            )}
        </div>
    );
};
