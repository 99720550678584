import type { TraineeFormFieldProps } from '../TrainingDataIntake.types';
import { MaterialPreferenceOptions } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

export const DentistMaterialPreferenceField: React.VFC<TraineeFormFieldProps> = props => {
    const { trainee, setTrainee } = useTrainee(props.traineeAtom);

    return (
        <SimpleSelect
            flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
            options={MaterialPreferenceOptions.map(material => ({
                label: material,
                value: material,
            }))}
            onChange={value => {
                setTrainee(oldValue => ({ ...oldValue, material: value }));
            }}
            label={''}
            value={trainee.material}
            placeholder={'Select a material'}
            FormControlProps={{ style: { marginTop: -4 }, variant: 'standard' }}
            SelectProps={{
                'aria-label': `Select material for ${trainee.name}`,
                variant: 'standard',
                style: { padding: 0 },
            }}
        />
    );
};
