import { PracticeUtils } from '../../../utils/utils';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const useDoctorId = (): string => {
    const history = useHistory();
    const prefId = PracticeUtils.getParsedQueryProperty(`prefId`, location.search) ?? '';

    React.useEffect(() => {
        if (!prefId) {
            history.push('/inbox');
        }
    }, [history, prefId]);

    return prefId;
};
