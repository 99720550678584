import { useAlignerCheckoutSelector } from '../../../../../redux';
import { useAlignerCheckoutAction } from '../../..//state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../../state/aligners-checkout.types';
import { CheckoutLargeRadioRow, CheckoutBodyWrapper } from '@orthly/dentin';
import { WarningIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Collapse, Divider, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    alertCard: {
        padding: '16px 24px',
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        borderRadius: 8,
    },
}));

const AlertCard: React.FC = props => {
    const classes = useStyles();
    return (
        <Grid container direction={'row'} wrap={'nowrap'} className={classes.alertCard}>
            <WarningIcon style={{ color: FlossPalette.ATTENTION_FOREGROUND, marginRight: 16 }} />
            {props.children}
        </Grid>
    );
};

export const AlignerCheckoutRetainerAdditionalInfo: React.FC = () => {
    const retainer = useAlignerCheckoutSelector(s => s.retainer);
    const setRetainer = useAlignerCheckoutAction('SET_RETAINER');
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.RetainerAdditionalInfo);
    if (!isActiveStep || !retainer) {
        return null;
    }
    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <Grid container direction={'column'} wrap={'nowrap'} style={{ marginTop: 32 }}>
                <CheckoutLargeRadioRow<(typeof retainer)['hasRestorations']>
                    value={retainer.hasRestorations}
                    options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' },
                    ]}
                    onChange={hasRestorations => setRetainer({ ...retainer, hasRestorations })}
                    label={'Are you doing any restorations?'}
                />
                <Collapse in={retainer.hasRestorations}>
                    <AlertCard>
                        <Text variant={'body2'}>
                            To prevent a bad fit, we recommend you order the retainers AFTER the restoration is in
                            place. The patient can continue using their aligners until then.
                        </Text>
                    </AlertCard>
                </Collapse>
                <Collapse in={!retainer.hasRestorations}>
                    <Divider style={{ margin: 16 }} />
                    <CheckoutLargeRadioRow<(typeof retainer)['isFixedLingual']>
                        value={retainer.isFixedLingual}
                        options={[
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ]}
                        onChange={isFixedLingual => setRetainer({ ...retainer, isFixedLingual })}
                        label={'Are you placing a fixed lingual retainer?'}
                    />
                    <Collapse in={retainer.isFixedLingual}>
                        <AlertCard>
                            <Text variant={'body2'}>
                                For a better fit, we recommend taking the scan AFTER the fixed lingual retainer is in
                                place. We will manufacture the retainer to fit over the patient's lingual retainer.
                            </Text>
                        </AlertCard>
                    </Collapse>
                </Collapse>
            </Grid>
        </CheckoutBodyWrapper>
    );
};
