import { SURGICAL_REPORT_OVERRIDE_FILENAME } from '../ScanbodySurgicalReportUploader/ScanbodySurgicalReportUploader';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import type { FileUploaderFieldResult } from '@orthly/veneer';
import { ImageUploaderWithFloatingResetButton, useGetAttachmentsPath } from '@orthly/veneer';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    contentRoot: {
        height: '100vh',
        backgroundColor: FlossPalette.WHITE,
        zIndex: 1202, // magic from checkout
        position: 'fixed',
        padding: '24px 16px',
    },
}));

interface MobileScanbodySurgicalReportUploaderHintFieldProps {
    title?: React.ReactNode;
    content?: React.ReactNode;
}

const MobileScanbodySurgicalReportUploaderHintField: React.FC<
    MobileScanbodySurgicalReportUploaderHintFieldProps
> = props => {
    const { title, content } = props;
    return (
        <Text variant={'body2'}>
            <Text variant={'body2'} display={'inline'} color={'DARK_GRAY'}>
                {title}
            </Text>
            {content}
        </Text>
    );
};

const MobileScanbodySurgicalReportUploaderUploading: React.FC<
    MobileScanbodySurgicalReportUploaderParams & { onComplete: (results: FileUploaderFieldResult<string>[]) => void }
> = props => {
    const { manufacturer, system, connection, storagePathConfig, overrideFileName, onComplete } = props;
    return (
        <>
            <Text variant={'h6'} style={{ marginBottom: 16 }}>
                {'Take a photo of the '}
                <Text variant={'h6'} color={'BURGUNDY'} display={'inline'}>
                    surgical report
                </Text>
            </Text>
            <Grid item style={{ marginBottom: 32 }}>
                <ImageUploaderWithFloatingResetButton
                    storagePathConfig={storagePathConfig}
                    overrideFileName={overrideFileName}
                    replaceExisting
                    processImages
                    exampleStyle={{
                        borderRadius: 8,
                        maxHeight: 264, // magic from checkout
                        width: '100%',
                        objectFit: 'contain',
                        backgroundColor: FlossPalette.GRAY,
                    }}
                    onComplete={async results => {
                        // Safety check
                        if (!results[0]) {
                            window.alert('Could not upload image');
                            return;
                        }
                        onComplete(results);
                    }}
                    // this applies to the buttons
                    style={{ width: '100%', marginTop: 16 }}
                    showExampleDisabled={true}
                    onSkipImage={undefined}
                />
            </Grid>
            <Grid item container direction={'column'} style={{ rowGap: 8 }}>
                <Text variant={'body2'} medium>
                    Scan body request details:
                </Text>
                <MobileScanbodySurgicalReportUploaderHintField title={'Manufacturer: '} content={manufacturer} />
                <MobileScanbodySurgicalReportUploaderHintField title={'System: '} content={system} />
                <MobileScanbodySurgicalReportUploaderHintField title={'Connection: '} content={connection} />
            </Grid>
        </>
    );
};

const MobileScanbodySurgicalReportUploaderSubmitted: React.FC = () => {
    return (
        <>
            <Text variant={'h4'} color={'BURGUNDY'} align={'center'} style={{ marginTop: 80 }}>
                Surgical report uploaded!
            </Text>
            <Text variant={'h6'} color={'BLACK'} align={'center'}>
                You can now return to your laptop
            </Text>
            <img src={'/checkout/aligner-image-complete.png'} alt={'celebration'} style={{ margin: '72px 96px 0' }} />
        </>
    );
};

enum MobileScanbodySurgicalReportUploaderStage {
    UPLOADING = 'UPLOADING',
    SUBMITTED = 'SUBMITTED',
}

interface MobileScanbodySurgicalReportUploaderParams {
    storagePathConfig: BucketStoragePathConfig;
    overrideFileName: string;
    manufacturer: string;
    system: string;
    connection: string;
}

export const MobileScanbodySurgicalReportUploader: React.FC = () => {
    const classes = useStyles();
    const [stage, setStage] = React.useState<MobileScanbodySurgicalReportUploaderStage>(
        MobileScanbodySurgicalReportUploaderStage.UPLOADING,
    );

    const { search } = useLocation();
    const { sessionId } = useParams<{ sessionId: string }>();
    const storagePathConfig = useGetAttachmentsPath(sessionId);
    const params = React.useMemo<MobileScanbodySurgicalReportUploaderParams | null>(() => {
        const urlSearchParams = new URLSearchParams(search);
        const manufacturer = urlSearchParams.get('manufacturer');
        const system = urlSearchParams.get('system');
        const connection = urlSearchParams.get('connection');
        if (manufacturer === null || system === null || connection === null) {
            return null;
        }
        return {
            storagePathConfig: storagePathConfig,
            overrideFileName: SURGICAL_REPORT_OVERRIDE_FILENAME,
            manufacturer: manufacturer,
            system: system,
            connection: connection,
        };
    }, [storagePathConfig, search]);

    if (!params) {
        return null;
    }
    return (
        <Grid container direction={'column'} wrap={'nowrap'} className={classes.contentRoot}>
            {stage === MobileScanbodySurgicalReportUploaderStage.UPLOADING && (
                <MobileScanbodySurgicalReportUploaderUploading
                    {...params}
                    onComplete={() => setStage(MobileScanbodySurgicalReportUploaderStage.SUBMITTED)}
                />
            )}
            {stage === MobileScanbodySurgicalReportUploaderStage.SUBMITTED && (
                <MobileScanbodySurgicalReportUploaderSubmitted />
            )}
        </Grid>
    );
};
