import { useCheckoutPropSelector, useAlignerCheckoutSelector } from '../../../../redux';
import { LabsUtils } from '../../../labs/LabsUtils';
import { CheckoutPhotoUploadTypeSelect } from '../../components/PhotoUpload/CheckoutPhotoUploadTypeSelect';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { usePartnerNotificationPrefsQuery, useUploadedAlignerPhotoGcsPathsQuery } from '@orthly/graphql-react';
import { LabsGqlAlignerCasePhotoType } from '@orthly/graphql-schema';
import React from 'react';

function useListenForImageUpload(scanId: string) {
    const [numPoll, setNumPoll] = React.useState<number>(1);
    const setDesktopUpload = useAlignerCheckoutAction('SET_DESKTOP_UPLOAD');
    const setUploadedImages = useAlignerCheckoutAction('SET_UPLOADED_GCS_PATHS');
    const uploadedImages = useAlignerCheckoutSelector(s => s.uploadedImageGCSPaths);
    const query = useUploadedAlignerPhotoGcsPathsQuery({
        variables: { scan_export_id: scanId },
        pollInterval: 3000,
        onCompleted: data => {
            const result = data.uploadedAlignerPhotoGCSPaths;
            if (result) {
                // switch to the next page when image is uploaded to prevent extra clicks
                // on first render, uploadedImages will be empty, do not switch pages when this difference is found
                // after first render, switch to next page when polling returns a different uploadedImages array
                if (result.length !== uploadedImages.length) {
                    setUploadedImages(result);
                    if (numPoll !== 1) {
                        setDesktopUpload(false);
                    }
                }
                // no longer poll once all the images have been uploaded.
                if (Object.values(LabsGqlAlignerCasePhotoType).length === result.length) {
                    query.stopPolling();
                }
            }
            setNumPoll(poll => poll + 1);
        },
        notifyOnNetworkStatusChange: true,
    });
}

interface AlignerCheckoutQRCodeScreenContentProps {
    step: AlignerCheckoutStep;
    patient_name: string;
    doctor: LabsGqlDoctorPreferencesFragment;
    scanId: string;
    doctorPhone?: string;
}

const UploadTypeSelectionScreenContent: React.FC<AlignerCheckoutQRCodeScreenContentProps> = props => {
    useListenForImageUpload(props.scanId);
    const setDesktopUpload = useAlignerCheckoutAction('SET_DESKTOP_UPLOAD');
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.ImageUploadLink);
    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete} style={{ marginTop: 32 }}>
            <CheckoutPhotoUploadTypeSelect
                onDesktopUpload={() => setDesktopUpload(true)}
                getMobileURL={scanID => LabsUtils.getAlignerPhotoSubmitSrcUrl(scanID) ?? 'undefined'}
                productName={'aligner'}
            />
        </CheckoutBodyWrapper>
    );
};

export const AlignerCheckoutUploadTypeSelectionScreen: React.FC = () => {
    const { patient_first_name, patient_last_name, doctor, scan } = useCheckoutPropSelector([
        'patient_first_name',
        'patient_last_name',
        'doctor',
        'scan',
    ]);
    const scanId = scan?.id;
    const step = useAlignerCheckoutSelector(s => s.step);
    const notifPrefs = usePartnerNotificationPrefsQuery();
    const phoneNumber = notifPrefs ? notifPrefs.data?.getPartnerPreferences?.phone_number : undefined;
    if (!doctor || step !== AlignerCheckoutStep.ImageUploadLink || !scanId) {
        return null;
    }
    return (
        <UploadTypeSelectionScreenContent
            step={step}
            patient_name={`${patient_first_name} ${patient_last_name}`}
            doctor={doctor}
            doctorPhone={phoneNumber ?? undefined}
            scanId={scanId}
        />
    );
};
