import { getFormattedOrderDetailTitle } from '../../../LabsUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { styled, Text } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const GENDER_INITIALS: Record<string, string> = { Male: `M`, Female: `F` };

const TitleTextContainer = styled(Text)({
    wordBreak: 'break-word',
    flexGrow: 1,
});
const StyledText = styled(Text)({
    whiteSpace: 'nowrap',
});

interface TopbarTitleProps {
    order: LabsGqlOrder;
}

export const TopbarTitle: React.FC<TopbarTitleProps> = ({ order }) => {
    const patient = order.patient;
    const title = getFormattedOrderDetailTitle(order);
    const age = patient?.birthday && moment().diff(moment(patient.birthday), 'years');
    const gender = patient?.gender && GENDER_INITIALS[patient.gender];
    return (
        <>
            <TitleTextContainer
                color={order.status === LabsGqlLabOrderStatus.Cancelled ? 'GRAY' : 'BLACK'}
                variant={'body1'}
                medium
            >
                {title}
            </TitleTextContainer>
            {(age || gender) && (
                <StyledText variant={'body1'} color={'GRAY'}>
                    {[age, gender].filter(Boolean).join(' ')}{' '}
                </StyledText>
            )}
        </>
    );
};
