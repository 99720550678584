import {
    useDesignXmlPayload,
    useDesignMetadata,
    useDesignModelPayloads,
    useDesignModelPayloadsForScan,
    type UseDesignModelPayloadsOpts,
} from './OrderDesignPreview.hooks.graphql';
import type { DesignCaseParserOrder, ModelPayloadItem } from '@orthly/dentin';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { LabsGqlDesignRevisionSource } from '@orthly/graphql-schema';
import type { DesignPrepMetadata, InternalDesignMetadata } from '@orthly/shared-types';
import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';

function filterRawFilesIfNoMBs(filePath: string, allPaths: string[]) {
    // if the design has MB files, we want to replace raw scans from the design
    const hasMB = allPaths.some(p => {
        const fileName = p.split('/').slice(-1)[0];
        return fileName?.includes('MB ');
    });
    const fileName = filePath.split('/').slice(-1)[0];
    const isPrePrep = fileName?.includes('PrePreparationScan') ?? false;
    const result = !(fileName?.startsWith('Raw') || isPrePrep);
    if (!hasMB) {
        return true;
    }
    return result;
}

export function useProcessDesignData(
    design: OrderDesignPreviewDesign_FragmentFragment | undefined | null,
    order: DesignCaseParserOrder,
    opts: UseDesignModelPayloadsOpts = {},
    scanExportId: string | undefined = undefined,
    prepMetaData: DesignPrepMetadata | undefined = undefined,
) {
    const { result: modellingTreePayload } = useDesignXmlPayload(
        design?.conversion_artifacts?.design_tree_path ?? undefined,
    );

    const designMetadata = useDesignMetadata(design?.conversion_artifacts?.metadata_json_path);

    if (prepMetaData) {
        // case prepped in browser
        opts.filterFiles = filterRawFilesIfNoMBs;
    }
    const payloads = useDesignModelPayloads(design, order, opts);
    const scanPayloads = useDesignModelPayloadsForScan(
        prepMetaData && scanExportId ? scanExportId : null,
        design ?? undefined,
    );

    const combinedPayloads: ModelPayloadItem[] = React.useMemo(() => {
        // Combine scan export assets and design revision payloads here if the case was prepped in the browser
        let combined: ModelPayloadItem[] = _.compact(payloads.result);
        if (payloads.result && scanPayloads.result && prepMetaData) {
            combined = combineScanExportAndDesignPayloads(
                combined,
                scanPayloads.result.filter(p => !!p).map(p => p as ModelPayloadItem) ?? [],
                getScanTransforms(prepMetaData),
                design?.design_source === LabsGqlDesignRevisionSource.ThreeshapeDentalSystem
                    ? designMetadata
                    : undefined,
            );
        }
        return combined;
    }, [payloads.result, scanPayloads.result, prepMetaData, design?.design_source, designMetadata]);
    const payloadsLoading = payloads.loading || scanPayloads.loading;
    return {
        modellingTreePayload,
        payloads: {
            result: payloadsLoading ? undefined : combinedPayloads,
            loading: payloadsLoading,
        },
        designMetadata,
    };
}

// helper function to get scan transforms matrices from prep metadata
function getScanTransforms(prepMetaData: DesignPrepMetadata): Record<string, THREE.Matrix4> {
    const scanTransforms: Record<string, THREE.Matrix4> = {};
    if (prepMetaData) {
        const keys = Object.keys(prepMetaData.scanTransforms) as Array<string>;
        keys.forEach(key => {
            const transformArray = prepMetaData.scanTransforms[key];
            if (transformArray) {
                scanTransforms[key] = new THREE.Matrix4().fromArray(transformArray);
            }
        });
    }
    return scanTransforms;
}

// Helper function to combine design and scan payloads
function combineScanExportAndDesignPayloads(
    designPayloads: ModelPayloadItem[],
    scanPayloads: ModelPayloadItem[],
    scanTransforms: Record<string, THREE.Matrix4>,
    designMetadata?: InternalDesignMetadata | undefined,
) {
    let combined = [...designPayloads];

    // rename any design payloads that has "raw" in the name
    combined.forEach(p => {
        p.name = p.name.replace('Raw', '').trim();
    });

    // filter out bite scans if there are bite scans in the design payloads
    const payloadsHaveBiteScan = combined.some(p => p.name.includes('Bite'));
    const scanPayloadsResult = scanPayloads.filter(p => {
        if (payloadsHaveBiteScan && getScanAssetTypeFromName(p.name) === 'bitescan') {
            return false;
        }
        return true;
    });

    // apply transforms to scan payloads
    scanPayloadsResult.forEach(scanPayload => {
        const assetType = getScanAssetTypeFromName(scanPayload.name);
        // Apply transforms from PiB if any
        // find transform from prep metadata
        const transform = scanTransforms[assetType];
        if (transform && !scanPayload.model.geometry.userData['isTransformed']) {
            scanPayload.model.geometry.applyMatrix4(transform);
            scanPayload.model.geometry.userData['isTransformed'] = true;
        }
        // Apply transforms from 3Shape if any
        // if design metadata is available (case finished in 3Shape), apply the transform from there
        if (designMetadata) {
            const transform = getTransformFromDesignMetaData(designMetadata, assetType);
            if (transform && !scanPayload.model.geometry.userData['isTransformedBy3Shape']) {
                scanPayload.model.geometry.applyMatrix4(new THREE.Matrix4().fromArray(transform));
                scanPayload.model.geometry.userData['isTransformedBy3Shape'] = true;
            }
        }
        // add raw to the name if it's not already there
        if (!scanPayload.name.includes('Raw')) {
            scanPayload.name = `Raw ${scanPayload.name}`;
        }
    });
    combined = combined.concat(scanPayloadsResult);
    return combined;
}
function getTransformFromDesignMetaData(designMetadata: InternalDesignMetadata, assetType: string) {
    if (!designMetadata || assetType === '') {
        return undefined;
    }
    switch (assetType) {
        case 'lowerjawprepreparationscan':
        case 'lowerjawscan':
            return designMetadata.transforms?.LowerAlign2Bite?.transform;
        case 'upperjawscan':
        case 'upperjawprepreparationscan':
            return designMetadata.transforms?.UpperAlign2Bite
                ? designMetadata.transforms?.UpperAlign2Bite?.transform
                : designMetadata.transforms?.UpperJaw2LowerJaw?.transform;
        default:
            return undefined;
    }
}

// Helper function to get the scan asset type from the scan name
function getScanAssetTypeFromName(name: string) {
    let assetType = '';
    if (name.includes('LowerJawScan_lower')) {
        assetType = 'lowerjawscan';
    } else if (name.includes('UpperJawScan_upper')) {
        assetType = 'upperjawscan';
    } else if (name.includes('BiteScan_bitescan')) {
        assetType = 'bitescan';
    } else if (name.includes('UpperJawPrePreparationScan_upper')) {
        assetType = 'upperjawprepreparationscan';
    } else if (name.includes('LowerJawPrePreparationScan_lower')) {
        assetType = 'lowerjawprepreparationscan';
    } else if (name.includes('BiteScan')) {
        assetType = 'bitescan2';
    }
    if (assetType === '') {
        console.error(`Unknown scan type: ${name}`);
    }
    return assetType;
}
