import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionBodyFreeText } from '../TryInFeedbackOptionBodyFreeText';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType } from '@orthly/items';
import React from 'react';

const TryInFeedbackPartialBiteOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial>> = ({
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    return (
        <TryInFeedbackOptionWrapper
            title={'Bite'}
            subtitle={`Partial denture doesn't occlude properly`}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <TryInFeedbackOptionBodyFreeText
                label={'Specify bite changes'}
                value={description ?? undefined}
                onChange={onDescriptionChanged}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackPartialBiteOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Partial> = {
    sku: LabOrderItemSKUType.Partial,
    isArchSpecific: true,
    category: 'Bite',
    isEnabled: () => true,
    Component: TryInFeedbackPartialBiteOption,
};
