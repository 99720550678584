import type { SvgIconProps } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Grid, CheckIcon, Text } from '@orthly/ui-primitives';
import React from 'react';

/**
 * NOTE: these components originally lived in the Scanner repo. The full git history can be found here:
 * https://github.com/orthly/Scanner/commits/460806a9a6284815a1bd3e5c3c5ebeda8f43941a/packages/fluoride/src/components/case-builder/navigation-sidebar/Stepper.tsx
 */

export interface SidebarStepperStep {
    title: string | React.ReactElement;
    body?: string | React.ReactElement;
    completed?: boolean;
    completionIcon?: React.ComponentType<SvgIconProps>;
    active?: boolean;
    optional?: boolean;
    children?: SidebarStepperStep[];
    onClick?: () => void;
}

interface SidebarStepperListProps {
    step: SidebarStepperStep;
    number?: number;
    hasNextStep: boolean;
    isNextStepCompleted: boolean;
}

const SidebarStepperStepTitle: React.VFC<SidebarStepperListProps> = ({ step }) => {
    const { title, active } = step;
    const textColor = active ? FlossPalette.GREEN : FlossPalette.DARK_GRAY;

    if (typeof title === 'string') {
        return (
            <Text variant={'body2'} style={{ color: textColor }}>
                {title}
            </Text>
        );
    }

    return title;
};

const SidebarStepperStepBody = React.forwardRef<HTMLDivElement, SidebarStepperListProps>(({ step }, ref) => {
    const { body } = step;
    if (!body) {
        return null;
    }

    return (
        <div ref={ref} style={{ maxWidth: '100%', overflowWrap: 'break-word' }} data-test={'stepper-step-body-wrapper'}>
            {typeof body === 'string' ? (
                <Text variant={'body2'} color={'BLACK'}>
                    {body}
                </Text>
            ) : (
                body
            )}
        </div>
    );
});

interface SidebarStepperConnectorProps extends SidebarStepperListProps {
    stepBodyRef: React.RefObject<HTMLDivElement | null>;
}

const SidebarStepperConnector: React.VFC<SidebarStepperConnectorProps> = ({ stepBodyRef, isNextStepCompleted }) => {
    // calculate positioning relative to height of sub step
    const baseHeight = 45;
    const baseMarginTop = -21;
    const [minHeight, setMinHeight] = React.useState<number>(baseHeight);
    const [marginTop, setMarginTop] = React.useState<number>(baseMarginTop);
    React.useEffect(() => {
        const requestId = window.requestAnimationFrame(() => {
            setMinHeight(stepBodyRef.current ? baseHeight + stepBodyRef.current.clientHeight : baseHeight);
            setMarginTop(stepBodyRef.current ? baseMarginTop - stepBodyRef.current.clientHeight : baseMarginTop);
        });
        return () => window.cancelAnimationFrame(requestId);
    });

    return (
        <div
            style={{
                minHeight,
                marginTop,
                marginLeft: 27, // (24px stepper badge width - 2px line width)/2 + 16px padding
                marginBottom: -24,
                borderLeft: `2px solid ${isNextStepCompleted ? FlossPalette.GREEN : FlossPalette.DARK_TAN}`,
                position: 'relative',
                width: 2,
            }}
        />
    );
};

const useStyles = stylesFactory<{ isClickable: boolean }>(() => ({
    stepperListRoot: ({ isClickable }) => ({
        padding: 16,
        borderRadius: 8,
        '&:hover': {
            backgroundColor: isClickable ? FlossPalette.DARK_TAN : undefined,
        },
    }),
}));

const SidebarStepperList: React.VFC<SidebarStepperListProps> = props => {
    const { number, step } = props;
    const classes = useStyles({ isClickable: !!step.onClick });
    const stepBodyRef = React.useRef<HTMLDivElement | null>(null);

    const isActive = step.active && !step.children?.find(child => child.active);

    const CompletionIcon = step.completionIcon ?? CheckIcon;

    return (
        <>
            <Grid
                container
                direction={'row'}
                style={{
                    padding: 16,
                    backgroundColor: isActive ? FlossPalette.PRIMARY_BACKGROUND : undefined,
                    cursor: step.onClick ? 'pointer' : 'cursor',
                    borderRadius: 8,
                }}
                className={classes.stepperListRoot}
                wrap={'nowrap'}
                onClick={step.onClick}
            >
                <Grid
                    item
                    style={{
                        width: 24,
                        height: 24,
                        minWidth: 24,
                        verticalAlign: 'middle',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <div
                        style={{
                            width: number ? 24 : 14,
                            height: number ? 24 : 14,
                            borderRadius: number ? 8 : 14,
                            backgroundColor: step.completed || step.active ? FlossPalette.GREEN : FlossPalette.DARK_TAN,
                            color: step.completed || step.active ? FlossPalette.WHITE : FlossPalette.GRAY,
                            zIndex: 1,
                            position: 'relative',
                            fontSize: 16,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {step.completed && number ? (
                            <CompletionIcon style={{ height: 20, width: 20 }} />
                        ) : (
                            <Text
                                color={step.active ? 'WHITE' : 'GRAY'}
                                variant={'body2'}
                                style={{
                                    textAlign: 'center',
                                }}
                                medium
                            >
                                {number ?? ''}
                            </Text>
                        )}
                    </div>
                </Grid>
                <Grid item style={{ paddingLeft: 16, width: '100%' }} data-test={'stepper-step-content-wrapper'}>
                    <SidebarStepperStepTitle {...props} />
                    <SidebarStepperStepBody ref={stepBodyRef} {...props} />
                </Grid>
            </Grid>
            {props.hasNextStep && <SidebarStepperConnector {...props} stepBodyRef={stepBodyRef} />}
        </>
    );
};

interface SidebarStepperProps {
    steps: SidebarStepperStep[];
}

export const SidebarStepper: React.VFC<SidebarStepperProps> = ({ steps }) => {
    const allSteps = steps.flatMap(step => [step, ...(step.children ?? [])]);

    return (
        <div data-test={'sidebar-stepper'}>
            {allSteps.map((step, idx) => {
                const topLevelIndex = steps.indexOf(step);
                return (
                    <SidebarStepperList
                        key={idx}
                        step={step}
                        number={topLevelIndex !== -1 ? topLevelIndex + 1 : undefined}
                        hasNextStep={allSteps.length > idx + 1}
                        isNextStepCompleted={!!allSteps[idx + 1]?.completed || !!allSteps[idx + 1]?.active}
                    />
                );
            })}
        </div>
    );
};
