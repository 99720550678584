import type { ActionProps, UrgentNotification } from './DandyNotifications.types';
import { FlossPalette, Text, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

interface DandyUrgentNotificationsItemProps {
    notification: UrgentNotification;
    onClick: (action: ActionProps) => void;
}
export const DandyUrgentNotificationsItem: React.FC<DandyUrgentNotificationsItemProps> = props => {
    const { notification, onClick } = props;

    return (
        <Grid container style={{ padding: 16 }}>
            <Grid item xs={1} style={{ color: FlossPalette.ATTENTION }}>
                {notification.icon}
            </Grid>
            <Grid item xs={11}>
                <Text variant={'body2'} medium>
                    {notification.title}
                </Text>
                <Text variant={'body2'}> {notification.subtitle}</Text>
                <Button
                    onClick={() => {
                        onClick(notification.primaryAction);
                    }}
                    variant={'primary'}
                    style={{ marginTop: 8 }}
                    endIcon={'ArrowForwardIos'}
                >
                    {notification.primaryAction.text}
                </Button>
            </Grid>
        </Grid>
    );
};
