export enum NotificationPreferencesNames {
    status_change_sms = 'status_change_sms',
    review_required_sms = 'review_required_sms',
    order_tracking_sms = 'order_tracking_sms',
    communication_sms = 'communication_sms',

    status_change_email = 'status_change_email',
    review_required_email = 'review_required_email',
    order_tracking_email = 'order_tracking_email',
    communication_email = 'communication_email',
}

export enum NotificationNames {
    status_change = 'important status change',
    review_required = 'review required to proceed',
    order_tracking = 'order tracking update',
    communication = 'communication',
}

export enum NotificationPrefsGroupSenders {
    status_change_senders = 'status_change_senders',
    review_required_senders = 'review_required_senders',
    order_tracking_senders = 'order_tracking_senders',
    communication_senders = 'communication_senders',
}

export enum NotificationPrefsGroupReceivers {
    status_change_receivers = 'status_change_receivers',
    review_required_receivers = 'review_required_receivers',
    order_tracking_receivers = 'order_tracking_receivers',
    communication_receivers = 'communication_receivers',
}

export type NotificationPrefsGroupForwarding = NotificationPrefsGroupSenders | NotificationPrefsGroupReceivers;
