import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { AddEditStaff as EditStaff } from './add-edit-staff/useStaffForm';
import { useFetchPracticeStaffUsers } from './useFetchPracticeStaffUsers.graphql';
import { Button } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const PracticeEmployee: React.FC = () => {
    const { id: staffMemberId } = useParams<{ id: string }>();
    // do not allow editing of the user if they are deleted
    // if we want to add editing of deleted users pass true here
    const { staffUsers } = useFetchPracticeStaffUsers(false);
    const staff = staffUsers?.find(s => s.staff_member_id === staffMemberId);

    const history = useHistory();
    const onBackAction = () => history.push('/my_practice/staff');

    // do not allow editing of the user if they are deleted
    // if we want to add editing of deleted users remove this
    if (staff?.deleted_at) {
        return null;
    }

    return (
        <PracticeSettingsBase
            title={
                <Button variant={'ghost'} onClick={onBackAction} startIcon={'ChevronLeft'} style={{ marginLeft: -16 }}>
                    Back to staff
                </Button>
            }
            titleHasBackButton={false}
            Content={<EditStaff staffUser={staff} onBackAction={onBackAction} />}
        />
    );
};
