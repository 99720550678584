import type { LabsGqlScanbodyFragment } from '@orthly/graphql-operations';
import { useGetScanbodiesByIdsQuery } from '@orthly/graphql-react';
import type { IOrderItemV2DTO } from '@orthly/items';
import { LabOrderItemSKUType } from '@orthly/items';
import constate from 'constate';
import _ from 'lodash';
import React from 'react';

function useImplantScanbodiesImpl({ items }: { items: IOrderItemV2DTO[] }): Map<string, LabsGqlScanbodyFragment> {
    const scanbody_ids = items.flatMap(i => {
        if (i.sku === LabOrderItemSKUType.ImplantBridge) {
            return _.compact(i.implants.map(implant => implant.implant_metadata.scanbody_id));
        }
        if (i.sku === LabOrderItemSKUType.Implant) {
            return i.unit.implant_metadata.scanbody_id ? [i.unit.implant_metadata.scanbody_id] : [];
        }
        return [];
    });
    const { data } = useGetScanbodiesByIdsQuery({
        variables: { scanbody_ids },
        skip: scanbody_ids.length === 0,
    });
    return React.useMemo(() => new Map(data?.getScanbodiesByIds.map(sb => [sb.id, sb]) ?? []), [data]);
}

const [Provider, useHook] = constate(useImplantScanbodiesImpl);

/**
 * Provides all relevant scanbodies for the order in a context
 * @type {React.FunctionComponent<{items: IOrderItemV2DTO[]}>}
 */
export const ScanbodyUsagesProvider = Provider;

export const useScanbodyUsages: () => Map<string, LabsGqlScanbodyFragment> = useHook;
