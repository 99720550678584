import { useStaffForm } from '../../useStaffForm';
import { RootActionDialog } from '@orthly/ui';
import { Button, Text, styled } from '@orthly/ui-primitives';

const ButtonsContainer = styled('div')({
    display: 'grid',
    gap: '16px',
    gridTemplateColumns: '1fr 1fr',
});

export const ReactivateStaffDialog: React.FC = () => {
    const { showReactivateModal, setShowReactivateModal, accountEmail, shouldContinue, reactivateDoctorPreferences } =
        useStaffForm();

    return (
        <RootActionDialog
            open={showReactivateModal}
            setOpen={setShowReactivateModal}
            hideButton
            loading={false}
            title={
                <Text variant={'h5'}>
                    A deactivated staff member with this email address already exists at your practice
                </Text>
            }
            subtitle={
                <>
                    {'Click confirm if you would like to reactivate '}
                    <Text bold variant={'body2'} component={'span'}>
                        {accountEmail}
                    </Text>
                    {' as a staff member at this practice.'}
                </>
            }
            content={
                <ButtonsContainer>
                    <Button variant={'secondary'} onClick={() => setShowReactivateModal(false)}>
                        Back
                    </Button>
                    <Button
                        variant={'primary'}
                        onClick={() => reactivateDoctorPreferences()}
                        disabled={!shouldContinue}
                    >
                        Confirm
                    </Button>
                </ButtonsContainer>
            }
        />
    );
};
