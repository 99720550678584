import type { ScanbodyWithMetadata } from '../state/ImplantReadiness.types';
import { ScanbodyImage } from '@orthly/dentin';
import { CartIcon, TrashIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, IconButton, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    cart: {
        gap: '16px',
        flexBasis: '30%',
        flexGrow: 1,
        minWidth: 350,
    },
    container: {
        backgroundColor: FlossPalette.TAN,
        borderColor: FlossPalette.DARK_TAN,
        borderStyle: 'solid',
        borderRadius: 8,
        borderWidth: 1,
        padding: 16,
        width: 'auto',
        flexGrow: 1,
        gap: '16px',
    },
    cartItem: {
        background: FlossPalette.WHITE,
        borderColor: FlossPalette.DARK_TAN,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        padding: 16,
    },
    cartImage: {
        width: 24,
        height: 24,
        background: FlossPalette.WHITE,
        borderColor: FlossPalette.DARK_TAN,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        marginRight: 16,
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
    },
    cartItemName: {
        flexGrow: 1,
    },
}));

export const Cart: React.VFC<{ cartItems: ScanbodyWithMetadata[]; removeItem: (id: string) => void }> = ({
    cartItems,
    removeItem,
}) => {
    const classes = useStyles();
    return (
        <Grid container direction={'column'} className={classes.cart}>
            <Grid container alignContent={'center'}>
                <CartIcon accentHeight={5} />
                <Text variant={'body2'} color={'BLACK'} bold>
                    Your cart
                </Text>
            </Grid>
            <Grid container direction={'column'} className={classes.container}>
                {cartItems.length === 0
                    ? 'Your cart is currently empty.'
                    : cartItems.map(scanbody => (
                          <Grid key={scanbody.id} container className={classes.cartItem}>
                              <Grid container justifyContent={'center'} className={classes.cartImage}>
                                  <ScanbodyImage
                                      scanbodyName={scanbody.name}
                                      scanbodyManufacturer={scanbody.manufacturer}
                                  />
                              </Grid>
                              <Text variant={'body2'} color={'BLACK'} bold className={classes.cartItemName}>
                                  {scanbody.name}
                              </Text>
                              <IconButton onClick={() => removeItem(scanbody.id)}>
                                  <TrashIcon color={'error'} />
                              </IconButton>
                          </Grid>
                      ))}
            </Grid>
        </Grid>
    );
};
