import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, createStyles, makeStyles, Grid } from '@orthly/ui-primitives';
import React from 'react';
import ReactImageMagnify from 'react-image-magnify';

const useStyles = makeStyles(() =>
    createStyles({
        shadeMatchImgLoader: {
            position: 'relative',
            minHeight: 100,
            border: `2px solid ${FlossPalette.DARK_TAN}`,
        },
    }),
);

export const ShadeMatchImgPreview: React.FC<{ imgSrc?: string; name: string }> = props => {
    const { imgSrc, name } = props;
    const classes = useStyles();
    return (
        <Grid item xs={4} style={{ minWidth: '12rem' }}>
            <LoadBlocker blocking={imgSrc === undefined} ContainerProps={{ className: classes.shadeMatchImgLoader }}>
                {imgSrc && (
                    <ReactImageMagnify
                        smallImage={{
                            alt: name,
                            isFluidWidth: true,
                            src: imgSrc,
                        }}
                        largeImage={{
                            src: imgSrc,
                            width: 1000,
                            height: 1000,
                        }}
                        enlargedImageStyle={{
                            backgroundColor: 'white',
                        }}
                        enlargedImagePosition={'over'}
                    />
                )}
            </LoadBlocker>
        </Grid>
    );
};
