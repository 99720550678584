import { AnalyticsClient } from '../../../../../analytics/analyticsClient';
import bankRoutingImage from '../../../../../assets/BankRoutingSVG.svg';
import { SettingsFormCustomSubmit } from '../../../components/SettingsFormCustomSubmit';
import { LoadBlocker } from '@orthly/ui';
import { styled, FlossPalette, InfoIcon, Text, TextInput } from '@orthly/ui-primitives';
import React from 'react';

const ContentWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateRows: 'auto auto',
    gridTemplateAreas: `
    "inputs example"
    "info info"
    "confirm confirm"
    `,
    gridGap: theme.spacing(3),
    maxWidth: 800,
}));

const Inputs = styled('div')(({ theme }) => ({
    gridArea: 'inputs',
    display: 'grid',
    gridGap: theme.spacing(2),
}));

const Example = styled('div')(({ theme }) => ({
    gridArea: 'example',
    display: 'grid',
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    background: FlossPalette.TAN,
    borderRadius: 8,
    padding: 16,
    gridGap: theme.spacing(1),
}));

const Info = styled('div')({
    gridArea: 'info',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
});

const ConfirmButtons = styled(SettingsFormCustomSubmit)({
    gridArea: 'confirm',
});

const Img = styled('img')({
    width: '100%',
});

export type AccountInfo = { routingNumber: string; accountNumber: string; confirmAccountNumber: string };

interface LinkBankAccountProps {
    /** The function to set account info */
    setAccountInfo: (newVal: AccountInfo) => void;
    /** The account info */
    accountInfo: AccountInfo;
    /** The function to set the state of the modal */
    setOpen: (open: boolean) => void;
    /** Whether the link account button is disabled */
    linkAccountDisabled: boolean;
    /** The function to link the bank account */
    linkAccount: (routingNumber: string, accountNumber: string) => Promise<void>;
    /** A boolean indicating if the bank account linking is in progress */
    linkingBankAccount: boolean;
    /** The function to close the payment modal if linking a bank account from the payment modal */
    onClosePaymentAction?: () => void;
}

const ROUTING_NUMBER_PLACEHOLDER = '123456789';
const ACCOUNT_NUMBER_PLACEHOLDER = '00123456789';

export const LinkBankAccountContent: React.FC<LinkBankAccountProps> = ({
    accountInfo,
    setAccountInfo,
    setOpen,
    linkAccountDisabled,
    linkAccount,
    linkingBankAccount,
    onClosePaymentAction,
}) => {
    const [routingNumberError, setRoutingNumberError] = React.useState<string | undefined>(undefined);
    const [accountNumberError, setAccountNumberError] = React.useState<string | undefined>(undefined);
    const [confirmAccountNumberError, setConfirmAccountNumberError] = React.useState<string | undefined>(undefined);
    const { routingNumber, accountNumber, confirmAccountNumber } = accountInfo;
    return (
        <LoadBlocker blocking={linkingBankAccount}>
            <ContentWrapper>
                <Inputs>
                    <TextInput
                        value={routingNumber}
                        placeholder={ROUTING_NUMBER_PLACEHOLDER}
                        onBlurOrFocus={(__type, newRoutingNumber = '') => {
                            if (newRoutingNumber.length && newRoutingNumber.length !== 9) {
                                return setRoutingNumberError('Routing number must be 9 digits');
                            }
                            setRoutingNumberError(undefined);
                        }}
                        helpText={routingNumberError}
                        helpTextBottom
                        onChange={val => setAccountInfo({ ...accountInfo, routingNumber: val ?? '' })}
                        label={'Routing number'}
                    />
                    <TextInput
                        value={accountNumber}
                        placeholder={ACCOUNT_NUMBER_PLACEHOLDER}
                        helpText={accountNumberError}
                        helpTextBottom
                        onBlurOrFocus={(__type, newAccountNumber = '') => {
                            if (newAccountNumber.length && newAccountNumber.length < 5) {
                                return setAccountNumberError('Account number must be at least 5 digits');
                            }
                            setAccountNumberError(undefined);
                        }}
                        onChange={val => setAccountInfo({ ...accountInfo, accountNumber: val ?? '' })}
                        label={'Account number'}
                    />
                    <TextInput
                        value={confirmAccountNumber}
                        placeholder={ACCOUNT_NUMBER_PLACEHOLDER}
                        helpText={confirmAccountNumberError}
                        helpTextBottom
                        onBlurOrFocus={(_type, newConfirmValue = '') => {
                            if (newConfirmValue.length && accountNumber.length && newConfirmValue !== accountNumber) {
                                return setConfirmAccountNumberError('Account numbers must match');
                            }
                            setConfirmAccountNumberError(undefined);
                        }}
                        onChange={val => setAccountInfo({ ...accountInfo, confirmAccountNumber: val ?? '' })}
                        label={'Confirm account number'}
                    />
                </Inputs>
                <Example>
                    <Text variant={'caption'}>Find your routing number & account number</Text>
                    <Img alt={'Dandy logo'} src={bankRoutingImage} />
                </Example>
                <Info>
                    <InfoIcon style={{ marginRight: 8 }} />
                    <div>
                        <Text variant={'body2'} style={{ marginBottom: 8 }}>
                            Linking your bank account sets it as the primary payment method.
                        </Text>
                        <Text variant={'body2'}>
                            By clicking "Submit," you agree to use ACH as your preferred payment method, along with
                            Dandy's Terms of Use and Privacy Policy.
                        </Text>
                    </div>
                </Info>
                <ConfirmButtons
                    setOpen={(open: boolean) => {
                        setOpen(open);
                        if (!open) {
                            onClosePaymentAction?.();
                        }
                    }}
                    disabled={linkAccountDisabled}
                    triggerSubmit={async () => {
                        await linkAccount(routingNumber, accountNumber);
                        setOpen(false);
                        AnalyticsClient.track('Practice - Guided Onboarding - Link Payment Method', {
                            type: 'BankAccount',
                        });
                    }}
                    confirmText={'Link bank account'}
                    style={{ marginTop: 0 }}
                />
            </ContentWrapper>
        </LoadBlocker>
    );
};
