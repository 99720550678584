import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { ScanbodyWithMetadata, ScanbodyWithPriority, ScanbodyOptions } from '../state/ImplantReadiness.types';
import { AbutmentType } from '../state/ImplantReadiness.types';
import { CartItemV2Utils } from '@orthly/items';
import type { ToothNumber, ICartImplantItem, ICartImplantBridgeItem } from '@orthly/items';

export function groupScanBodies({
    items,
    itemAbutmentTypes,
    itemScanbodyOptions,
}: {
    items: (ICartImplantItem | ICartImplantBridgeItem)[];
    itemAbutmentTypes: Record<string, AbutmentType>;
    itemScanbodyOptions: ScanbodyOptions;
}) {
    const groups: Array<{
        unn: ToothNumber;
        scanbody: ScanbodyWithMetadata & ScanbodyWithPriority;
    }> = [];

    items.forEach(item => {
        if (!item.id) {
            return;
        }
        const metadata = CartItemV2Utils.getImplantMetadata(item);
        const abutmentType = itemAbutmentTypes[item.id];
        const isAuthentic = abutmentType === AbutmentType.Authentic;
        const isLabChoice = abutmentType === AbutmentType.LabChoice;

        const scanbodies = itemScanbodyOptions[item.id];
        let scanbody: ScanbodyWithPriority | undefined;
        if (isLabChoice && scanbodies && scanbodies.authentic && scanbodies.generic) {
            if (scanbodies.authentic.priority > scanbodies.generic.priority) {
                scanbody = scanbodies.authentic;
            } else {
                scanbody = scanbodies.generic;
            }
        } else if (isAuthentic) {
            scanbody = scanbodies?.authentic;
        } else {
            scanbody = scanbodies?.generic;
        }
        if (!groups.some(group => group.scanbody?.id === scanbody?.id) && scanbody && metadata) {
            groups.push({
                unn: CartItemV2Utils.getUniqueUNNs(item)[0] || 1,

                scanbody: {
                    ...scanbody,
                    manufacturer: metadata.manufacturer ?? '',
                    system: metadata.system ?? '',
                    connection: metadata.connection_size ?? '',
                    itemId: item.id ?? '',
                },
            });
        }
    });
    return groups;
}

export function useScanbodies() {
    const { items, itemAbutmentTypes, itemScanbodyOptions } = useImplantReadinessPropSelector([
        'items',
        'itemAbutmentTypes',
        'itemScanbodyOptions',
    ]);
    const groups = groupScanBodies({ items, itemAbutmentTypes, itemScanbodyOptions });

    return {
        groups,
    };
}
