import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useAbutmentUnns } from '../ImplantReadiness.util';
import { MultiItemSwitch } from '../component/MultiSwitchSidebar';
import { Tips, renderSystemCTATip, restoreConfidentlyTip } from '../component/Tip';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import { AbutmentType } from '../state/ImplantReadiness.types';
import type { Stage } from './Stage';
import type { BigRadioOption } from '@orthly/dentin';
import { BigRadioSelect, PracticeScreen } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import type { ICartImplantBridgeItem, ICartImplantItem, ToothNumber } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { FlossPalette, Grid, Text, Button } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const AbutmentSelect: React.VFC<{
    value?: string;
    onChange: (val?: string) => void;
}> = ({ value, onChange }) => {
    const options: BigRadioOption<AbutmentType>[] = [
        {
            label: _.startCase(AbutmentType.LabChoice),
            value: AbutmentType.LabChoice,
            body: 'Dandy makes the decision based on your case',
        },
        {
            label: AbutmentType.Generic,
            value: AbutmentType.Generic,
            body: 'Cost-effective parts engineered to fit your patient’s implant system',
        },
        {
            label: `${AbutmentType.Authentic} (+$75 surcharge)`,
            value: AbutmentType.Authentic,
            body: 'Brand name parts developed by the original implant manufacturer',
        },
    ];

    return (
        <Grid>
            <Text variant={'body2'} style={{ marginBottom: 48, color: FlossPalette.GRAY, width: 1200 }}>
                This helps us decide which scan body to send your practice. All parts are FDA approved and milled in
                licensed manufacturing centers.
            </Text>
            <Grid item xs={10}>
                <BigRadioSelect
                    onChange={onChange}
                    options={options}
                    recommendation={AbutmentType.LabChoice}
                    value={value}
                />
            </Grid>
        </Grid>
    );
};

interface AbutmentStepProps {
    items: (ICartImplantItem | ICartImplantBridgeItem)[];
    activeItemId: string;
    itemAbutmentTypes: Record<string, AbutmentType>;
    isGrouped: boolean;
    abutmentUnns: ToothNumber[];
    toggleGrouped: (isGrouped: boolean) => void;
}

export const AbutmentStep: React.VFC<AbutmentStepProps> = ({
    items,
    activeItemId,
    itemAbutmentTypes,
    isGrouped,
    abutmentUnns,
    toggleGrouped,
}) => {
    const { implantReadinessId } = useImplantReadinessPropSelector(['implantReadinessId']);
    const setAbutmentTypes = useImplantReadinessAction('SET_ABUTMENT_TYPES');
    const [localAbutmentTypes, setLocalAbutmentTypes] = React.useState(itemAbutmentTypes);
    const activeItem = items.find(item => item.id === activeItemId);
    const item = isGrouped ? items[0] : activeItem;

    if (!item) {
        return null;
    }

    const updateAbutmentType = (val?: string) => {
        if (val === undefined) {
            return;
        }

        const itemIdsToUpdate = _.compact(isGrouped ? items.map(item => item.id) : [item.id]);
        const updatedAbutmentTypes = itemIdsToUpdate.reduce((acc, itemId) => {
            AnalyticsClient.track('Practice - Implant Readiness - Abutment Type Selected', {
                implantReadinessId,
                itemId,
                abutmentType: val,
            });
            return { ...acc, [itemId]: val };
        }, {});
        setLocalAbutmentTypes({ ...localAbutmentTypes, ...updatedAbutmentTypes });
    };

    return (
        <Grid>
            <Grid container item style={{ marginBottom: 8 }} direction={'row'}>
                <Grid item xs={6}>
                    <AbutmentSelect onChange={updateAbutmentType} value={localAbutmentTypes[item.id ?? '']} />
                </Grid>
                <Grid item xs={6}>
                    <MultiItemSwitch
                        item={item}
                        label={'Use the same type of abutment for all implants'}
                        isGrouped={isGrouped}
                        abutmentUnns={abutmentUnns}
                        onChange={newIsGrouped => {
                            setLocalAbutmentTypes({});
                            toggleGrouped(newIsGrouped);
                        }}
                        hidden={items.length < 2}
                    />
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: 40 }}>
                <Button
                    variant={'primary'}
                    onClick={() => setAbutmentTypes({ abutmentTypes: localAbutmentTypes })}
                    style={{ width: 198 }}
                    disabled={false}
                >
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

const Abutment: React.VFC = () => {
    const { isGrouped, items, activeItemId, itemAbutmentTypes } = useImplantReadinessPropSelector([
        'isGrouped',
        'items',
        'activeItemId',
        'itemAbutmentTypes',
    ]);
    const toggleGrouping = useImplantReadinessAction('TOGGLE_GROUPING');
    const { abutmentUnns } = useAbutmentUnns();

    return (
        <Grid xs={12}>
            <AbutmentStep
                items={items}
                activeItemId={activeItemId}
                itemAbutmentTypes={itemAbutmentTypes}
                isGrouped={isGrouped}
                abutmentUnns={abutmentUnns}
                toggleGrouped={newIsGrouped => toggleGrouping(newIsGrouped)}
            />
        </Grid>
    );
};

export const AbutmentStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/abutment/:id`,
    component: Abutment,
    pageTitle: ({ name, unns }) => ({
        title: `Preparing for ${name}'s implant case`,
        header: (
            <Text variant={'h3'}>
                Choose the type of abutment you prefer when you restore {name}'s{' '}
                {Format.pluralizeNoun('implant', unns.length)}{' '}
                <span style={{ color: FlossPalette.SECONDARY_FOREGROUND }}>{CartItemV2Utils.getUnnsDisplay(unns)}</span>
            </Text>
        ),
    }),
    sidebar: ({ name, openChat }) => <Tips tips={[restoreConfidentlyTip, renderSystemCTATip(name, openChat)]} />,
};
