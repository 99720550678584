import type { FieldDefCustom, FieldLayout } from '../QuickForm.types';
import { FormikRootField } from '../QuickForm.types';
import { QFErrorText, QFHelperText } from './QFHelperText';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type AnyObj = { [k: string]: any };

function CustomFieldRender<R extends AnyObj>(props: { customField: FieldDefCustom<R | any> & { name: string } }) {
    const customField = props.customField;
    const CustomComponent = customField.component;
    return (
        <>
            <FormikRootField
                name={customField.name}
                render={formikProps => {
                    return (
                        <Grid container className={props.customField.wrapperClassName}>
                            <CustomComponent
                                {...customField.fieldProps}
                                form={formikProps.form}
                                field={formikProps.field}
                            />
                            {!customField.hideErrorMessage && <QFErrorText fieldName={customField.name} />}
                        </Grid>
                    );
                }}
            />
            <QFHelperText text={customField.helperText} />
        </>
    );
}

interface QFCustomFieldProps {
    field: FieldDefCustom<any> & { name: string };
}

export const QFCustomField: React.FC<QFCustomFieldProps> = ({ field }) => {
    const commonProps = { name: field.name, label: field.label || _.startCase(field.name) };
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    return (
        <Grid item {...layout}>
            <CustomFieldRender customField={{ ...field, label: field.label, name: commonProps.name }} />
        </Grid>
    );
};
