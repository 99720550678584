import { useInvoicesTableContext } from '../../components/providers/InvoicesTableProvider.graphql';
import { PaymentDialog } from './PaymentDialog.graphql';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';
import type { RootActionDialogProps } from '@orthly/ui';
import { RootActionDialog } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

interface PaymentActionProps extends Pick<RootActionDialogProps, 'CustomButton'> {
    invoiceId?: string;
    invoiceIds?: string[];
    refetchInvoice?: () => Promise<unknown>;
}

export const paymentActionId = 'payment-action-button';

const GetWillPartnerBeChargedCCFee_Query = graphql(`
    query GetWillPartnerBeChargedCCFee($organizationId: String!) {
        getPartnerBillingAccount(partner_id: $organizationId) {
            id
            will_be_charged_cc_fee
        }
    }
`);

export const PaymentAction: React.FC<PaymentActionProps> = ({
    invoiceId,
    invoiceIds,
    refetchInvoice,
    CustomButton = null,
}) => {
    const organizationId = useSession()?.organization_id ?? '';
    const { loading, payableSelectedInvoices } = useInvoicesTableContext();
    let dialogTitle;
    if (invoiceId) {
        dialogTitle = 'Pay invoice';
    } else {
        // if we've passed in a list of invoiceIds, that supersedes any selected invoices
        dialogTitle = `Pay ${(invoiceIds ? invoiceIds : payableSelectedInvoices).length} invoices`;
    }

    const { data: { getPartnerBillingAccount: partnerBillingAccount } = {} } = useQuery(
        GetWillPartnerBeChargedCCFee_Query,
        {
            variables: { organizationId },
            fetchPolicy: 'no-cache',
            skip: !organizationId,
        },
    );
    const willBeChargedCCFee = partnerBillingAccount?.will_be_charged_cc_fee;

    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <RootActionDialog
            loading={loading}
            open={open}
            setOpen={setOpen}
            dialogProps={{ maxWidth: 'sm' }}
            title={dialogTitle}
            content={
                <PaymentDialog
                    invoiceDetailId={invoiceId}
                    invoiceIds={invoiceIds}
                    refetchInvoice={refetchInvoice}
                    onClose={() => setOpen(false)}
                    willBeChargedCCFee={willBeChargedCCFee}
                />
            }
            style={{ padding: 0 }}
            CustomButton={
                CustomButton ??
                (() => (
                    <Button variant={'primary'} onClick={() => setOpen(true)} id={paymentActionId}>
                        Pay now
                    </Button>
                ))
            }
        />
    );
};
