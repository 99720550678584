import type { LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { stylesFactory, FlossPalette } from '@orthly/ui-primitives';

interface ConciergeMarketingCtaConfig {
    ctaLineText: string;
    ctaButtonText: string;
    ctaDisabledText: string;
    dialog: {
        title: string;
        subtitle?: string;
        doctorFieldLabel: string;
        addressFieldLabel: string;
        emailRequestLabel?: string;
    };
}

interface ConciergeTrainingCtaConfig {
    ctaLineText: string;
    ctaButtonText: string;
    ctaDisabledText: string;
    calendlyUrl: string;
}

export type ConciergeSkuType =
    | LabsGqlOrderItemSkuType.Aligners
    | LabsGqlOrderItemSkuType.Denture
    | LabsGqlOrderItemSkuType.Crown;

export interface ConciergeConfigInterface {
    useCheckIfSkuActive: () => boolean;
    sku: ConciergeSkuType;
    assetLocation: string;
    training?: ConciergeTrainingCtaConfig;
    marketing?: ConciergeMarketingCtaConfig;
}

export const useStyles = stylesFactory(() => ({
    conciergeSectionRoot: {
        padding: '16px 48px',
        '&:not(:last-child)': {
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        },
    },
    conciergeContainer: {
        paddingBottom: 16,
        display: 'block',
    },
}));
