import type { AlignerRejection, AlignerRetainerCheckoutState, AlignerCheckoutStep } from '../aligners-checkout.types';
import type { AlignerRetainerPricingSummaryCheckout } from '../aligners-checkout.types';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type {
    LabsGqlAlignerTreatmentArea,
    LabsGqlPendingAlignerCaseBiteConcernsInput,
    LabsGqlPendingAlignerCaseCrowdingResolutionInput,
    LabsGqlPendingAlignerCaseEstimate,
    LabsGqlPendingAlignerCasePhotoMapInput as PendingAlignerCasePhotoMap,
} from '@orthly/graphql-schema';
import type { InterproximalSpaceSize, OrderItemArch, ToothNumber } from '@orthly/items';
import type { AlignerEstimateStep } from '@orthly/shared-types';

export enum AlignerCaseStage {
    InitialAligner = 'InitialAligner',
    Rejection = 'Rejection',
    Retainer = 'Retainer',
    Refinement = 'Refinement',
}

export interface AlignerCheckoutState {
    step: AlignerCheckoutStep;
    deviceUpload: boolean;
    uploadedImageGCSPaths: string[];
    alignerImages?: PendingAlignerCasePhotoMap;
    alignerArch?: OrderItemArch;
    alignerTreatmentArea?: LabsGqlAlignerTreatmentArea;
    movementRestrictedTeeth: ToothNumber[] | null;
    attachmentRestrictedTeeth: ToothNumber[] | null;
    extractionTeeth: ToothNumber[] | null;
    interproximalSpaceSizes: InterproximalSpaceSize[] | null;
    crowdingResolution: LabsGqlPendingAlignerCaseCrowdingResolutionInput;
    biteConcerns: LabsGqlPendingAlignerCaseBiteConcernsInput;
    alignerNotes?: string;
    estimateStep?: AlignerEstimateStep;
    estimate?: LabsGqlPendingAlignerCaseEstimate;
    rejection?: AlignerRejection;
    overriddenPatientSteps?: number | null;
    existingOrder?: LabsGqlOrder;
    retainer?: AlignerRetainerCheckoutState;
    retainerPricing?: AlignerRetainerPricingSummaryCheckout;
    isRefinement?: boolean;
}
