import AGGRESSIVE from '../../../assets/images/smile-style/three-dimensional/aggressive.png';
import ENHANCED from '../../../assets/images/smile-style/three-dimensional/enhanced.png';
import HOLLYWOOD from '../../../assets/images/smile-style/three-dimensional/hollywood.png';
import LAB_RECOMMENDATION from '../../../assets/images/smile-style/three-dimensional/labrecommendation.png';
import MATURE from '../../../assets/images/smile-style/three-dimensional/mature.png';
import SOFT from '../../../assets/images/smile-style/three-dimensional/soft.png';
import YOUTHFUL from '../../../assets/images/smile-style/three-dimensional/youthful.png';
import { LabsGqlSmileStyleChoice } from '@orthly/graphql-schema';
import React from 'react';

const IMAGES: Record<LabsGqlSmileStyleChoice, string> = {
    [LabsGqlSmileStyleChoice.Aggressive]: AGGRESSIVE,
    [LabsGqlSmileStyleChoice.Mature]: MATURE,
    [LabsGqlSmileStyleChoice.Hollywood]: HOLLYWOOD,
    [LabsGqlSmileStyleChoice.ReplicatePreOpAnatomy]: LAB_RECOMMENDATION,
    [LabsGqlSmileStyleChoice.ReplicateWaxUp]: LAB_RECOMMENDATION,
    [LabsGqlSmileStyleChoice.Youthful]: YOUTHFUL,
    [LabsGqlSmileStyleChoice.Enhanced]: ENHANCED,
    [LabsGqlSmileStyleChoice.Soft]: SOFT,
    [LabsGqlSmileStyleChoice.MirrorContralateral]: LAB_RECOMMENDATION,
    [LabsGqlSmileStyleChoice.LabRecommendation]: LAB_RECOMMENDATION,
};

export const SmileStyleImage: React.VFC<
    React.ImgHTMLAttributes<any> & {
        smile: LabsGqlSmileStyleChoice;
    }
> = ({ smile, ...props }) => <img src={IMAGES[smile]} alt={smile} {...props} />;
