import { StaffProfilePhoto } from '../../../../components/StaffProfilePhoto';
import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { SectionsLayout } from '../../components/Section';
import { usePracticeStaffControls } from '../../state/usePracticeStaffControls';
import { ProfileCompletion } from '../settings/ProfileCompletion';
import { MobilePhoneConfirmationDialog } from './MobilePhoneConfirmationDialog';
import { useAccountSettings } from './context';
import { AccountInformationSection } from './sections/AccountInformation';
import { ContactInformationSection } from './sections/ContactInformation';
import { PasswordSection } from './sections/Password';
import { ProfilePictureSection } from './sections/ProfilePicture';
import { Button, styled, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import React from 'react';

const PageLayout = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
    width: '100%',
});

const AccountSections = styled(SectionsLayout)(({ theme }) => ({
    [theme.breakpoints.down('xl')]: {
        gridTemplateColumns: '1fr',
    },
}));

export const AccountSettings: React.FC = () => {
    const controls = usePracticeStaffControls();
    const isLargeScreen = useScreenIsLgOrUp();
    const { onSubmit, showConfirmationModal, setShowConfirmationModal, disableSaveButton } = useAccountSettings();

    if (!controls?.employeePrefs) {
        return null;
    }

    return (
        <PracticeSettingsBase
            titleIcon={<StaffProfilePhoto staff={controls.employeePrefs} style={{ width: 40, marginRight: 16 }} />}
            title={'Account settings'}
            Content={
                <>
                    <PageLayout onSubmit={onSubmit}>
                        <AccountSections>
                            <ProfilePictureSection />
                            <ContactInformationSection />
                            <AccountInformationSection />
                            <PasswordSection />
                        </AccountSections>
                        <div>
                            <Button disabled={disableSaveButton} variant={'primary'} type={'submit'}>
                                Save
                            </Button>
                        </div>
                    </PageLayout>
                    <MobilePhoneConfirmationDialog
                        open={showConfirmationModal}
                        setOpen={() => setShowConfirmationModal(!showConfirmationModal)}
                    />
                </>
            }
            rightContent={isLargeScreen && <ProfileCompletion prefs={controls.employeePrefs} />}
        />
    );
};
