import { RevealPasswordIcon } from './LabeledFields.styles';
import React from 'react';

export const useRevealPassword = () => {
    const [shouldShowPassword, setShouldShowPassword] = React.useState(false);

    return {
        action: (
            <RevealPasswordIcon
                icon={shouldShowPassword ? 'VisibilityOffOutlinedIcon' : 'VisibilityOutlinedIcon'}
                onClick={() => setShouldShowPassword(prev => !prev)}
                data-testid={'reveal-password'}
            />
        ),
        type: shouldShowPassword ? 'text' : 'password',
    };
};
