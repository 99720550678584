import { CheckoutItemV2Manager } from '../../../state/CheckoutItemV2Manager';
import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import { ShadeAutocompletePopover } from './shade-field/ShadeAutocompletePopover';
import { CartItemShadePicker, ScrollEnterFieldContainer } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import type { ICartItemV2Update } from '@orthly/items';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface CheckoutShadePickerProps {
    item: CheckoutItemV2;
}

export const CheckoutShadePicker: React.FC<CheckoutShadePickerProps> = ({ item }) => {
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');

    if (!CartItemV2Utils.itemRequiresShade(item)) {
        return null;
    }

    const disabled = !CheckoutItemV2Manager.materialSelected(item);

    return (
        <ScrollEnterFieldContainer style={{ position: 'relative' }} disabled={disabled}>
            <Grid container alignItems={'center'} style={{ padding: '12px 0' }}>
                <Text style={{ width: '100%', fontWeight: 500 }}>Shade</Text>
            </Grid>
            <CartItemShadePicker
                item={item}
                disabled={disabled}
                hiddenShades={['tissue']}
                CustomPopover={ShadeAutocompletePopover}
                updateItem={(change: ICartItemV2Update) => updateItem({ change, item_index: item.item_index })}
            />
        </ScrollEnterFieldContainer>
    );
};
