import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { CarestreamPostAction, useCarestreamPostback } from '../../state/CarestreamPostUtils';
import { useIsCarestreamSelector } from '../../state/checkout.selectors';
import { PostCheckoutAlertArea } from './PostCheckoutAlertArea';
import type { CtaAnalyticsEventData } from '@orthly/analytics/dist/browser';
import { useAnalyticsOnClick } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import NightGuardImage from '@orthly/dentin/assets/images/hd-skus/NightGuard.png';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrder } from '@orthly/graphql-react';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import {
    FlossPalette,
    FlossPaletteUtils,
    createStyles,
    makeStyles,
    Dialog,
    Grid,
    Button,
    Text,
} from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        backdrop: {
            background: FlossPalette.TAN,
        },
        leftItemWrapper: {
            padding: 48,
        },
        rightItemWrapper: {
            padding: 48,
        },
        mitchell: {
            maxWidth: 48,
            display: 'block',
            borderRadius: 16,
            border: `1px solid ${FlossPaletteUtils.toRgba('BLACK', 0.1)}`,
        },
        horizontalDivider: {
            border: `1px solid ${FlossPaletteUtils.toRgba('BLACK', 0.1)}`,
            marginTop: 24,
            marginBottom: 24,
        },
        dentureBanner: {
            background: FlossPalette.SECONDARY_BACKGROUND,
            borderRadius: 16,
            padding: 32,
        },
        nightGuardBanner: {
            background: FlossPalette.SECONDARY_BACKGROUND,
            border: `1px solid ${FlossPalette.SECONDARY_FOREGROUND}`,
            borderRadius: 32,
            display: 'flex',
            flexWrap: 'wrap',
            padding: 32,
        },
        nightGuardBannerButton: {
            alignSelf: 'flex-end',
            marginBottom: 24,
            paddingLeft: 32,
            // Also pad the right side so it doesn't look as weird on hover.
            paddingRight: 32,
        },
    }),
);

const GoToPortalButton: React.VFC = () => {
    const history = useHistory();
    const ctaText = 'Return to the Portal';
    const goToPortal = React.useCallback(() => {
        AnalyticsClient.track(`Button Clicked`, {
            AssetName: 'Return to Portal',
            AssetType: 'button',
            AssetVersion: 'v1.0',
            AssetCTAText: ctaText,
        });
        history.push(`/${PracticeScreen.orders}`);
    }, [history, ctaText]);
    return (
        <Button variant={'secondary'} onClick={goToPortal}>
            {ctaText}
        </Button>
    );
};

const CheckoutConfirmationLeft: React.VFC<{ order?: LabsGqlOrder }> = props => {
    // This component is the left-hand-slide of the post-checkout screen.
    const classes = useStyles();
    const { order } = props;

    const items = OrderItemV2Utils.parseItems(order?.items_v2 ?? []);

    const { value: checkoutAlignersTreatmentPlanDateAsDdpPreview } = useFeatureFlag(
        'checkoutAlignersTreatmentPlanDateAsDdpPreview',
    );
    const hasAlignerItem =
        checkoutAlignersTreatmentPlanDateAsDdpPreview &&
        items.some(
            item =>
                CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Aligners) &&
                item.unit.unit_type !== 'Aligner Retainer',
        );
    const waxupRequired = order?.fulfillment_workflow.configuration.waxup_required || hasAlignerItem;

    return (
        <div className={classes.leftItemWrapper}>
            <Grid container spacing={3} alignItems={'center'} justifyContent={'flex-start'}>
                <Grid item>
                    <img className={classes.mitchell} src={'/checkout/mitchell.png'} alt={'Logo'} />
                </Grid>
                <Grid item>
                    <Text variant={'h5'} style={{ lineHeight: 1 }}>
                        Your order is placed. <br />
                        Mitchell is on it!
                    </Text>
                </Grid>
            </Grid>
            <div className={classes.horizontalDivider} />
            <div>
                <Text medium variant={'body2'} style={{ color: FlossPalette.GRAY }}>
                    {waxupRequired
                        ? `A ${hasAlignerItem ? 'treatment plan' : 'design preview'} will be ready for approval by`
                        : 'Expected delivery'}
                </Text>
                <Text variant={'h4'} style={{ color: FlossPalette.BURGUNDY }}>
                    {order
                        ? dayjs(
                              waxupRequired
                                  ? order.practice_dates.promised_digital_design_preview_completion_date
                                  : order.practice_dates.estimated_delivery_date,
                          ).format('dddd,  MMM. Do')
                        : 'Loading order'}
                </Text>
            </div>
            <div className={classes.horizontalDivider} />
            <div>
                <Text medium variant={'body2'} style={{ color: FlossPalette.GRAY }}>
                    Visit the Dandy Portal to track orders, resolve notifications, learn about Dandy's full service
                    offering, or to get in touch.
                </Text>
            </div>
            <div style={{ marginTop: 16 }}>
                <GoToPortalButton />
            </div>
        </div>
    );
};

const CheckoutNightGuardUpsell: React.VFC = () => {
    // This component is the bottom-right night guard upsell on the post-checkout screen.
    const classes = useStyles();
    // Below, classes.nightGuardBanner is applied to a wrapping div, not to Grid,
    // as Grid has built-in negative margins which interefer with alignment.
    const ctaText = 'Learn More';
    const nightGuardUpsellEventData: CtaAnalyticsEventData = {
        AssetLocation: 'Post checkout',
        AssetCTAText: ctaText,
        AssetName: 'Night guard Upsell',
        AssetType: 'banner',
        AssetVersion: 'v1.0',
    };
    const onCtaClick = useAnalyticsOnClick('Button Clicked', nightGuardUpsellEventData, () => {
        window.open('https://www.meetdandy.com/hard-soft-3d-printed-night-guards/');
    });
    return (
        <div className={classes.nightGuardBanner}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={9} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <Text
                        variant={'h5'}
                        style={{
                            fontSize: 'xx-large',
                            fontWeight: 500,
                            marginBottom: 16,
                            marginLeft: 32,
                            marginTop: 12,
                        }}
                    >
                        Introducing Hard/Soft 3D Printed Night Guards
                    </Text>
                    <Text
                        variant={'body1'}
                        style={{ fontSize: 'x-large', marginBottom: 24, marginLeft: 32, marginRight: 32 }}
                    >
                        Learn more about the next evolution in night guard technology.
                    </Text>
                    <Button className={classes.nightGuardBannerButton} variant={'ghost'} onClick={onCtaClick}>
                        <Text variant={'body2'} medium style={{ color: FlossPalette.GREEN, fontSize: 'x-large' }}>
                            {ctaText}
                        </Text>
                    </Button>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <img src={NightGuardImage} style={{ float: 'right' }} alt={'Night guard'} />
                </Grid>
            </Grid>
        </div>
    );
};

const CheckoutConfirmationRight: React.VFC = () => {
    // This component is the right-hand-side of the post-checkout screen.
    const classes = useStyles();
    return (
        <div className={classes.rightItemWrapper}>
            <PostCheckoutAlertArea />
            <CheckoutNightGuardUpsell />
        </div>
    );
};

const ErrorOccuredWithOrder: React.VFC = () => {
    const history = useHistory();
    return (
        <div style={{ margin: 8, padding: 8 }}>
            <Text variant={'h4'} style={{ marginBottom: 8 }}>
                Error loading order, please refresh to try again
            </Text>
            <Button variant={'alert-secondary'} onClick={() => history.push(`/${PracticeScreen.inbox}`)}>
                Portal home
            </Button>
        </div>
    );
};

export const CheckoutOrderConfirmationOverlay: React.VFC = () => {
    // This opaque overlay component is the post-checkout screen.
    const classes = useStyles();
    const { orderId } = useParams<{ orderId?: string }>();
    const { loading, error, order } = useOrder(orderId);

    const isCarestream = useIsCarestreamSelector();
    const doCarestreamError = useCarestreamPostback<string>({ action: CarestreamPostAction.OrderError });
    const doCarestreamSuccess = useCarestreamPostback<string>({ action: CarestreamPostAction.OrderOk });
    React.useEffect(() => {
        if (isCarestream && !loading) {
            if (error) {
                doCarestreamError();
            } else {
                doCarestreamSuccess();
            }
        }
    }, [doCarestreamError, doCarestreamSuccess, error, isCarestream, loading]);

    return (
        <Dialog
            open={true}
            fullScreen
            style={{ zIndex: 2500 }}
            disableEnforceFocus={true}
            BackdropProps={{ className: classes.backdrop }}
            PaperProps={{
                elevation: 0,
                style: { backgroundColor: 'transparent', height: '100vh', alignItems: 'center' },
            }}
        >
            <Grid container spacing={3} alignItems={'center'}>
                <Grid item xs={12} lg={4}>
                    <LoadBlocker blocking={loading}>
                        {error ? <ErrorOccuredWithOrder /> : <CheckoutConfirmationLeft order={order} />}
                    </LoadBlocker>
                </Grid>
                <Grid item xs={12} lg={8}>
                    <CheckoutConfirmationRight />
                </Grid>
            </Grid>
        </Dialog>
    );
};
