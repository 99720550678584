import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useAbutmentUnns } from '../ImplantReadiness.util';
import { MultiItemSwitch } from '../component/MultiSwitchSidebar';
import { Tips, renderSystemCTATip, restoreConfidentlyTip } from '../component/Tip';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { useApolloClient } from '@apollo/client';
import { PracticeScreen } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import type { ICartImplantBridgeItem, ICartImplantItem, ToothNumber } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { FlossPalette, Text, Grid, Button } from '@orthly/ui-primitives';
import { AutocompleteImplantSystemInput } from '@orthly/veneer';
import React from 'react';

const ImplantReadinessSystemSelect: React.VFC<{
    item: ICartImplantItem | ICartImplantBridgeItem;
}> = ({ item }) => {
    const updateMetadata = useImplantReadinessAction('UPDATE_METADATA');
    const implant_metadata = CartItemV2Utils.getImplantMetadata(item);

    const implantMeta = {
        manufacturer: implant_metadata?.manufacturer ?? null,
        system: implant_metadata?.system ?? null,
        connection: implant_metadata?.connection_size ?? null,
    };

    const updateField = React.useCallback(
        (fieldName: string, value?: string | null) => {
            const propertyName = fieldName === 'connection' ? 'connection_size' : fieldName;
            updateMetadata({ [propertyName]: value });
        },
        [updateMetadata],
    );

    return (
        <Grid>
            <Text variant={'body2'} style={{ marginBottom: 48, color: FlossPalette.GRAY }}>
                You can find information about your implant system in the surgery report or implant packaging.
            </Text>
            <Grid item xs={10}>
                <AutocompleteImplantSystemInput linkMeta={implantMeta} updateField={updateField} vertical />
            </Grid>
        </Grid>
    );
};

interface ImplantSystemStepProps {
    item?: ICartImplantItem | ICartImplantBridgeItem;
    isGrouped: boolean;
    abutmentUnns: ToothNumber[];
    nextStage: () => void;
    nextDisabled: boolean;
    hiddenSwitch?: boolean;
    onChange: (isGrouped: boolean) => void;
}
export const ImplantSystemStep: React.VFC<ImplantSystemStepProps> = ({
    item,
    isGrouped,
    abutmentUnns,
    nextStage,
    nextDisabled,
    hiddenSwitch,
    onChange,
}) => {
    if (!item) {
        return null;
    }

    return (
        <Grid>
            <Grid container item xs={12} style={{ marginBottom: 8 }} direction={'row'}>
                <Grid item xs={6}>
                    <ImplantReadinessSystemSelect item={item} />
                </Grid>
                <Grid item xs={6}>
                    <MultiItemSwitch
                        item={item}
                        label={'Use the same implant system for all implant restorations'}
                        isGrouped={isGrouped}
                        abutmentUnns={abutmentUnns}
                        hidden={hiddenSwitch}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
            <Grid item style={{ marginTop: 40 }}>
                <Button variant={'primary'} onClick={nextStage} style={{ width: 198 }} disabled={nextDisabled}>
                    Next
                </Button>
            </Grid>
        </Grid>
    );
};

const ImplantSystem: React.VFC = () => {
    const { isGrouped, items, activeItemId, implantReadinessId } = useImplantReadinessPropSelector([
        'isGrouped',
        'items',
        'activeItemId',
        'implantReadinessId',
    ]);
    const toggleGrouping = useImplantReadinessAction('TOGGLE_GROUPING');
    const completeImplantSystems = useImplantReadinessAction('COMPLETE_IMPLANT_SYSTEMS');
    const activeItem = items.find(item => item.id === activeItemId);
    const { abutmentUnns } = useAbutmentUnns();
    const client = useApolloClient();

    const item = isGrouped ? items[0] : activeItem;

    const implant_metadata =
        item && CartItemV2Utils.itemTypeHasImplant(item) ? CartItemV2Utils.getImplantMetadata(item) : undefined;

    const nextDisabled = !(
        !!implant_metadata?.manufacturer &&
        !!implant_metadata.system &&
        !!implant_metadata.connection_size
    );

    const nextStage = React.useCallback(() => {
        completeImplantSystems({ client });

        AnalyticsClient.track('Practice - Implant Readiness - Implant Metadata Selected', {
            implantReadinessId,
            itemSku: item?.sku ?? '',
            itemUnns: abutmentUnns,
            implantMetadata: {
                manufacturer: implant_metadata?.manufacturer ?? undefined,
                system: implant_metadata?.system ?? undefined,
                connectionSize: implant_metadata?.connection_size ?? undefined,
            },
        });
    }, [completeImplantSystems, implant_metadata, item, abutmentUnns, client, implantReadinessId]);

    return (
        <Grid xs={12}>
            <ImplantSystemStep
                // we add a key to force a remount when the item changes
                // so the MUI inputs don't carry values from the previous item
                key={item?.id}
                item={item}
                isGrouped={isGrouped}
                abutmentUnns={abutmentUnns}
                nextStage={nextStage}
                nextDisabled={nextDisabled}
                hiddenSwitch={items.length < 2}
                onChange={newIsGrouped => toggleGrouping(newIsGrouped)}
            />
        </Grid>
    );
};

export const ImplantSystemStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/system/:id`,
    component: ImplantSystem,
    pageTitle: ({ name, unns }) => ({
        title: `Preparing for ${name}'s implant case`,
        header: (
            <Text variant={'h3'}>
                Tell us more about the {Format.pluralizeNoun('implant', unns.length)} on
                <span style={{ color: FlossPalette.SECONDARY_FOREGROUND }}>
                    {' '}
                    {CartItemV2Utils.getUnnsDisplay(unns)}
                </span>
            </Text>
        ),
    }),
    sidebar: ({ name, openChat }) => <Tips tips={[restoreConfidentlyTip, renderSystemCTATip(name, openChat)]} />,
};
