import { usePartnerUiSelector } from '../../../redux/ui';
import { usePendingReturnForOrderQuery } from '@orthly/graphql-react';
import { UuidUtils } from '@orthly/runtime-utils';
import constate from 'constate';

function usePendingReturnForOrder({ orderId }: { orderId: string }) {
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const fetchPolicy = isNativeApp ? 'cache-first' : 'no-cache';
    const { loading, data } = usePendingReturnForOrderQuery({
        variables: { orderId },
        skip: !UuidUtils.isUUID(orderId),
        fetchPolicy,
    });
    const pendingReturn = data?.pendingReturnForOrder;
    return { pendingReturn, loading };
}

// The rule has issues with this export format
// eslint-disable-next-line import/no-unused-modules
export const [PendingReturnProvider, usePendingReturn] = constate<
    { orderId: string },
    ReturnType<typeof usePendingReturnForOrder>,
    []
>(usePendingReturnForOrder);
