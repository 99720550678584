import { CheckoutActions } from '../checkout.actions';
import { getItemScreens } from '../checkout.screens';
import type { CheckoutItemV2, CheckoutState } from '../checkout.state';
import type { ReducerMap } from 'redux-actions';

const bulkIdHasMoreThanOneItem =
    (items: CheckoutItemV2[]) =>
    (bulkId: string): boolean => {
        const itemCount = items.filter(item => item.bulk_edit_id === bulkId).length;
        return itemCount > 1;
    };

export const checkoutBulkStateReducerMap: ReducerMap<CheckoutState, any> = {
    ...CheckoutActions.ENABLE_BULK_EDIT.reducer<CheckoutState>((state, action) => {
        if (!state.canRegroupBulkIds.includes(action.payload)) {
            return state;
        }
        const activeItem = state.items.find(i => i.bulk_edit_id === action.payload);
        if (!activeItem) {
            return state;
        }
        const screenIndex = getItemScreens(state).findIndex(s => s.itemIndex === activeItem.item_index);
        return {
            ...state,
            bulkEditIds: [...state.bulkEditIds, action.payload],
            itemScreenIndex: Math.max(0, screenIndex),
            canRegroupBulkIds: state.canRegroupBulkIds.filter(id => id !== action.payload),
        };
    }),

    ...CheckoutActions.DISABLE_BULK_EDIT.reducer<CheckoutState>((state, action) => {
        return {
            ...state,
            bulkEditIds: state.bulkEditIds.filter(id => id !== action.payload),
            canRegroupBulkIds: [...state.canRegroupBulkIds, action.payload],
        };
    }),

    ...CheckoutActions.UPDATE_LINE_ITEM.reducer<CheckoutState>((state, action) => {
        const item = state.items.find(i => i.item_index === action.payload.item_index);
        if (!item) {
            return state;
        }
        // nothing to change if the current canRegroupBulkIds dont include this item
        if (!state.canRegroupBulkIds.includes(item.bulk_edit_id)) {
            return state;
        }
        return {
            ...state,
            // once items are edited after they're ungrouped, they can no longer be regrouped
            canRegroupBulkIds: state.canRegroupBulkIds.filter(id => id !== item.bulk_edit_id),
        };
    }),

    ...CheckoutActions.REMOVE_ITEM.reducer<CheckoutState>(state => {
        return {
            ...state,
            // we actually want to include deleted items in this, because they can be re-grouped if they are un-deleted
            // before any of their other group items are updated. The UPDATE_LINE_ITEM reducer handles removing the
            // bulk id if the other items are edited
            canRegroupBulkIds: state.canRegroupBulkIds.filter(
                bulkIdHasMoreThanOneItem([...state.items, ...state.removed_scan_items]),
            ),
            bulkEditIds: state.bulkEditIds.filter(bulkIdHasMoreThanOneItem(state.items)),
        };
    }),
};
