import { usePracticeLogout } from '../../../redux/auth';
import { getPartOfDay } from '../../../utils/utils';
import { SelectStaffContent } from '../components';
import { useSelectStaff } from '../hooks/useSelectStaff';
import { useSelectStaffAction } from '../state/select-staff.actions';
import { CreateStaffStep } from '../state/select-staff.progression';
import { useSelectStaffSelector } from '../state/select-staff.selectors';
import { AddButton } from './AddStaffButton';
import { StaffButton } from './StaffButton';
import type {
    LabsGqlDoctorPreferencesFragment,
    LabsGqlFetchAllPracticeEmployeesQuery,
} from '@orthly/graphql-operations';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import { LoadBlocker } from '@orthly/ui';
import { stylesFactory, useScreenIsMd, Button, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    signoutButton: {
        position: 'absolute',
        right: 32,
        top: -36,
        [theme.breakpoints.down('lg')]: {
            position: 'unset',
            marginTop: 'auto',
        },
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 136px)',
    },
}));

interface SelectStaffLandingProps {
    practiceEmployees?: LabsGqlFetchAllPracticeEmployeesQuery;
}

export const SelectStaffLanding: React.VFC<SelectStaffLandingProps> = props => {
    const { practiceEmployees } = props;
    const history = useHistory();
    const classes = useStyles();
    const { hasRetainerToken } = useStaffMemberLoginProps();
    const selectEmployee = useSelectStaffAction('SELECT_PRACTICE_EMPLOYEE');
    const logOut = usePracticeLogout();
    const isMobileOrTablet = useScreenIsMd();
    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.Landing);
    const { skipEmployeeAuth, isChatiFrame } = useSelectStaff();

    const employee = React.useMemo(() => {
        return practiceEmployees?.fetchAllPracticeEmployees.find((employee: LabsGqlDoctorPreferencesFragment) => {
            // get the employee with the current session token
            if (employee.staff_member_id && hasRetainerToken(employee.staff_member_id)) {
                return employee;
            }
        });
    }, [hasRetainerToken, practiceEmployees?.fetchAllPracticeEmployees]);

    const adminUserIsLoggedIn = () => {
        return (
            // if you didn't auth, you can't act as an admin
            !skipEmployeeAuth &&
            (employee?.roles?.includes(LabsGqlStaffRolePractice.Admin) ||
                employee?.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor))
        );
    };

    return (
        <SelectStaffContent show={show}>
            <Text
                variant={isMobileOrTablet ? 'h5' : 'h4'}
                color={'GRAY'}
                data-test={'select-staff-part-of-day-greeting'}
            >
                Good {getPartOfDay()}!
            </Text>
            <Text className={'pendo-upgrade-auth-overlay'} variant={isMobileOrTablet ? 'h4' : 'h2'}>
                Who's making people smile today?
            </Text>
            <LoadBlocker
                blocking={!practiceEmployees}
                ContainerProps={{ className: classes.grid, style: { marginTop: 32, gap: 32 } }}
            >
                {practiceEmployees?.fetchAllPracticeEmployees.map((employee: LabsGqlDoctorPreferencesFragment) => {
                    return <StaffButton key={employee.id} employee={employee} />;
                })}
                {adminUserIsLoggedIn() && employee && !skipEmployeeAuth && (
                    <AddButton
                        onClick={() => {
                            selectEmployee(employee);
                            history.push('/my_practice/staff/add');
                        }}
                    />
                )}
            </LoadBlocker>
            {!isChatiFrame && (
                <Button variant={'secondary'} className={classes.signoutButton} onClick={logOut}>
                    Sign out
                </Button>
            )}
        </SelectStaffContent>
    );
};
