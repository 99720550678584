import { trackGuidedWaxupButtonClick } from '../DesignViewerLiteDetachedContainer';
import { useGuidedWaxupContext } from '../GuidedWaxups/state/GuidedWaxupContext';
import { RestorativeView, orderMaterialCanHaveLayers, useToggleTransparencyPreExtractionScans } from '@orthly/dentin';
import React from 'react';

export const useDesignViewerButtonGroupsState = () => {
    const { appearance, order, setAppearance } = useGuidedWaxupContext();
    const canHaveLayers = orderMaterialCanHaveLayers(order);
    const [heatmapAnchorEl, setHeatmapAnchorEl] = React.useState<HTMLElement | null>(null);
    const [transparencyAnchorEl, setTransparencyAnchorEl] = React.useState<HTMLElement | null>(null);

    //Scans Transparency
    const onToggleTransparencyPreExtractionScans = useToggleTransparencyPreExtractionScans(setAppearance);

    // Heatmap
    const isHeatmapView = appearance.restorativeView === RestorativeView.HeatMap;

    const closeTransparencyMenu = React.useCallback(() => {
        setTransparencyAnchorEl(null);
    }, []);

    const closeHeatmapMenu = React.useCallback(() => {
        setHeatmapAnchorEl(null);
    }, []);

    const resetHeatmapState = React.useCallback(
        (e?: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
            e?.stopPropagation();
            setAppearance(appearance => ({
                ...appearance,
                restorativeView: RestorativeView.CAD,
                showAnatomyLayers: false,
            }));
            setHeatmapAnchorEl(null);
        },
        [setAppearance],
    );

    const heatmapButtonClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        ({ currentTarget }) => {
            setHeatmapAnchorEl(current => (current ? null : currentTarget));
            trackGuidedWaxupButtonClick('Toggle Heatmap Selector');
            // if we open the heatmap selection bar while anatomy/transparency selection bar is open,
            // close the anatomy/transparency selection bar and toggle off anatomy/transparency
            closeTransparencyMenu();
            if (!isHeatmapView) {
                setAppearance(appearance => ({
                    ...appearance,
                    showAnatomyLayers: false,
                }));
            }
        },
        [isHeatmapView, setAppearance, closeTransparencyMenu],
    );

    const transparencyButtonClick = React.useCallback<React.MouseEventHandler<HTMLButtonElement>>(
        ({ currentTarget }) => {
            closeHeatmapMenu();
            setTransparencyAnchorEl(current => (current ? null : currentTarget));

            // if items in order can have layers, then we open a selection bar where doctor can
            // toggle transparency and anatomy layers on.
            if (canHaveLayers) {
                // if we open the anatomy/transparency selection bar while heatmap selection bar is open,
                // close the heatmap selection bar and disable any active heatmap
                resetHeatmapState();
            } else {
                // if no layers, this button simply acts as the toggle for transparency on the restorations
                trackGuidedWaxupButtonClick('Toggle Transparency Restoration');
                onToggleTransparencyPreExtractionScans();
            }
        },
        [canHaveLayers, closeHeatmapMenu, resetHeatmapState, onToggleTransparencyPreExtractionScans],
    );

    return {
        canHaveLayers,
        closeHeatmapMenu,
        closeTransparencyMenu,
        heatmapButtonClick,
        heatmapAnchorEl,
        isHeatmapView,
        resetHeatmapState,
        transparencyButtonClick,
        transparencyAnchorEl,
    };
};
