import { SimpleDialog, WarningIcon } from '@orthly/ui';
import { Button, FlossPalette, stylesFactory, Text, useScreenIsMd, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const PRACTICE_OUTAGE_BANNER_HEIGHT = 40;

const useStyles = stylesFactory(() => ({
    banner: {
        backgroundColor: FlossPalette.WARNING,
        height: PRACTICE_OUTAGE_BANNER_HEIGHT,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconStyles: {
        width: 24,
        height: 24,
        marginRight: 8,
    },
    button: {
        marginLeft: 16,
        height: 32,
    },
    bannerText: {
        maxWidth: 596,
        overflow: 'hidden',
        textTruncate: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

export const PracticeOutageBanner: React.VFC<{ banner: { title: string; description: string } | undefined }> = ({
    banner,
}) => {
    const screenIsMedium = useScreenIsMd();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    if (!banner || screenIsMedium) {
        return null;
    }

    const toggleOpen = () => setOpen(!open);

    return (
        <>
            <Grid className={classes.banner} container>
                <WarningIcon className={classes.iconStyles} />
                <Text medium variant={'body2'} className={classes.bannerText}>
                    {banner.title}
                </Text>
                <Button variant={'secondary'} className={classes.button} onClick={toggleOpen}>
                    Learn More
                </Button>
            </Grid>
            <SimpleDialog
                open={open}
                onClose={toggleOpen}
                title={banner.title}
                actions={
                    <Button variant={'secondary'} onClick={toggleOpen} style={{ marginRight: 8 }}>
                        Close
                    </Button>
                }
            >
                <Grid item style={{ whiteSpace: 'pre-line' }}>
                    {banner.description}
                </Grid>
            </SimpleDialog>
        </>
    );
};
