import { FlossPalette, stylesFactory, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ warning: boolean; noMargin: boolean }>(() => ({
    root: props => ({
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: props.warning ? 8 : 16,
        marginTop: props.noMargin ? 0 : 16,
        borderRadius: 8,
        backgroundColor: props.warning ? FlossPalette.WARNING_BACKGROUND : FlossPalette.WHITE,
        position: 'relative',
    }),
    triangle: props => ({
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '0 8px 8px 8px',
        borderColor: `transparent transparent ${
            props.warning ? FlossPalette.WARNING_BACKGROUND : FlossPalette.WHITE
        } transparent`,
        position: 'absolute',
        top: -8,
        left: 20,
    }),
}));

export const TextBubble: React.FC<{ noMargin?: boolean; text?: string | null; warning?: boolean }> = ({
    children,
    noMargin = false,
    text,
    warning = false,
}) => {
    const classes = useStyles({ noMargin, warning });

    if (!children && !text) {
        return null;
    }

    return (
        <Text variant={'body2'} className={classes.root}>
            <div className={classes.triangle} />
            {text ?? children}
        </Text>
    );
};
