import type { RefabAttachment, RefabFlowDoctorPreferences, RefabFlowLabOrder } from '../state/refab-flow-types';
import type { FetchResult, QueryResult, QueryHookOptions } from '@apollo/client';
import type {
    LabsGqlCreateReturnForRefabMutation,
    LabsGqlLabOrderFragment,
    LabsGqlMarkNeedsRefabricationMutation,
    LabsGqlReasonCodesForOrderItemQuery,
    LabsGqlReasonCodesForOrderItemQueryVariables,
} from '@orthly/graphql-operations';
import type {
    Exact,
    LabsGqlCreateReturnForRefabricationCommand,
    LabsGqlFormDisplay,
    LabsGqlMarkNeedsRefabricationCommand,
} from '@orthly/graphql-schema';
import constate from 'constate';

interface ImpersonatedQRCodeLinkResult {
    qrData?: string;
    error?: Error;
    link?: string;
}

export interface TextUploadLinkResult {
    sendText: (phoneNumber: string) => Promise<void>;
    submitting: boolean;
    placeholderPhoneNumber: string;
}

export type RefabOrReturnSubmitData = {
    command: LabsGqlCreateReturnForRefabricationCommand | LabsGqlMarkNeedsRefabricationCommand;
    needsReturn: boolean;
};
type ReturnRes = FetchResult<LabsGqlCreateReturnForRefabMutation>;
type RefabRes = FetchResult<LabsGqlMarkNeedsRefabricationMutation>;
export type RefabOrReturnSubmitResult = ReturnRes | RefabRes;

export interface RefabFlowContextShape {
    order?: RefabFlowLabOrder;
    refabricatedOrder?: Pick<LabsGqlLabOrderFragment, 'id' | 'practice_dates' | 'manufacturer_sla'>;
    loadingOrder: boolean;
    doctorPreferences?: RefabFlowDoctorPreferences;
    refetch: () => Promise<unknown>;
    submit: (data: RefabOrReturnSubmitData) => Promise<RefabOrReturnSubmitResult>;
    openChairsidePdf?: (props: { url: string }) => void;
    submitting: boolean;
    useGenerateUploaderConfig: (additionalPath?: string) => {
        qrCode: ImpersonatedQRCodeLinkResult;
        textUploadLink: TextUploadLinkResult;
    };
    SupplementalInfoStepOne: React.VFC<{
        order?: RefabFlowLabOrder;
        additionalInfoRequests: string[];
        qrCode?: ImpersonatedQRCodeLinkResult;
        textUploadLink?: TextUploadLinkResult;
        onUploadingChange?: (isUploading: boolean) => void;
    }>;
    SupplementalInfoStepTwo: React.VFC<{
        order?: RefabFlowLabOrder;
        additionalInfoRequests: string[];
        itemsToReturn: {
            itemId: string;
            name: string;
        }[];
        hasAckedReturnPolicy: boolean;
        setHasAckedReturnPolicy: (hasAcked: boolean) => void;
        refetchOrder: () => Promise<unknown> | void;
    }>;
    RefabFileUploader: React.VFC<{
        orderId: string;
        itemId?: string;
        attachments: RefabAttachment[];
        setAttachments: (attachments: RefabAttachment[]) => void;
        qrCode?: ImpersonatedQRCodeLinkResult;
        textUploadLink?: TextUploadLinkResult;
        onUploadingChange?: (isUploading: boolean) => void;
    }>;
    isOps?: boolean;
    useReasonCodesForOrderItemQuery: (
        baseOptions: QueryHookOptions<
            LabsGqlReasonCodesForOrderItemQuery,
            LabsGqlReasonCodesForOrderItemQueryVariables
        >,
    ) => QueryResult<
        LabsGqlReasonCodesForOrderItemQuery,
        Exact<{
            order_id: string;
            item_id: string;
            form_display: LabsGqlFormDisplay;
        }>
    >;
}
export const [RefabFlowContextProvider, useRefabFlowContext] = constate((context: RefabFlowContextShape) => context);
