// Because the upload runs in the background, we could select a scan before shade match images are uploaded.
// This hook polls for the scan file and updates redux state once images have been processed
import { useCheckoutPropSelector } from '../../../../../../redux/selectors';
import { useCheckoutAction } from '../../../../state/checkout.actions';
import { useSingleScanFileCheckoutQuery } from '@orthly/graphql-react';

export function usePollForUpdatedScanImages() {
    const { scan } = useCheckoutPropSelector(['scan']);
    const setScanImages = useCheckoutAction('SET_SCAN_IMG_SOURCES');
    // only poll if we have selected scan, it has shade match images not yet uploaded
    const shouldRunQuery = !!scan && scan.has_shade_match_images && scan.image_urls === null;
    const query = useSingleScanFileCheckoutQuery({
        skip: !shouldRunQuery,
        variables: { scanId: scan?.id ?? '' },
        pollInterval: 15000,
        onCompleted: data => {
            const latestScanSources = data?.scan_file.image_urls;
            if (latestScanSources) {
                // stop polling once images have been updated
                query.stopPolling();
                setScanImages(latestScanSources);
            }
        },
    });
}

export function useShadeMatchImgSources(): string[] {
    const { scan } = useCheckoutPropSelector(['scan']);
    // old scans may still be named with "measure", this keep backwards compat
    return (
        scan?.image_urls?.filter((imgSrc: string) => imgSrc.includes('shade-match') || imgSrc.includes('measure')) ?? []
    );
}
