import { CheckoutItemV2Manager } from '../../../state/CheckoutItemV2Manager';
import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import type { CheckoutItemSelectFieldProps } from './CheckoutItemSelectField';
import type { CheckoutButtonSelectOption } from '@orthly/dentin';
import { CheckoutButtonSelect, DisabledFieldOverlay, useCheckoutButtonSelectClasses } from '@orthly/dentin';
import { CartItemV2Utils, ItemMaterialFieldV2Utils, ITEM_MATERIAL_ABUTMENT_LABEL } from '@orthly/items';
import { Button, Grid, Menu, MenuItem, Tooltip, Icon } from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

interface MaterialFieldSelectProps extends Omit<CheckoutItemSelectFieldProps, 'options'> {
    options: (CheckoutButtonSelectOption & { non_standard?: boolean })[];
}

const MaterialFieldSelect: React.FC<MaterialFieldSelectProps> = props => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const classes = useCheckoutButtonSelectClasses();
    const { options, ...selectProps } = props;
    const sortedOpts = React.useMemo(() => _.sortBy(options, o => !!o.non_standard), [options]);
    const nonStandardOpts = React.useMemo(() => sortedOpts.filter(o => !!o.non_standard), [sortedOpts]);

    const buttonClasses = {
        root: cx(classes.button, classes.buttonInactive, classes.buttonLabel),
        disabled: classes.buttonDisabled,
    };

    return (
        <>
            <CheckoutButtonSelect
                {...selectProps}
                mode={'single'}
                options={sortedOpts.map(({ value, label, non_standard, image_url }) => ({
                    value,
                    image_url,
                    label: label ?? _.startCase(value),
                    hidden: !!non_standard && value !== props.value,
                }))}
                suggestedValue={props.suggestedValue}
            >
                {nonStandardOpts.length > 0 && (
                    <Button
                        variant={'contained'}
                        onClick={e => setAnchorEl(e.currentTarget)}
                        style={{ width: 'auto', padding: 0 }}
                        classes={buttonClasses}
                    >
                        <Tooltip title={'More Options'} arrow placement={'bottom'}>
                            <Grid container alignItems={'center'} justifyContent={'center'} style={{ height: '100%' }}>
                                <Icon icon={'ArrowDropDown'} />
                            </Grid>
                        </Tooltip>
                    </Button>
                )}
            </CheckoutButtonSelect>
            {nonStandardOpts.length > 0 && (
                <Menu
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                >
                    {nonStandardOpts.map(({ value, label }) => (
                        <MenuItem
                            key={value}
                            selected={value === selectProps.value}
                            onClick={() => {
                                setAnchorEl(null);
                                selectProps.onChange(value);
                            }}
                        >
                            {label ?? _.startCase(value)}
                        </MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

const useMaterialFieldSelectProps = (item: CheckoutItemV2): MaterialFieldSelectProps[] => {
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');

    return React.useMemo(() => {
        // sort abutment materials to the end for consistency with legacy custom fields display
        const selectProps = _.sortBy(
            ItemMaterialFieldV2Utils.getMaterialFieldsForItem(item, item.original_material),
            m => m.label === ITEM_MATERIAL_ABUTMENT_LABEL,
        );

        return selectProps.map(props => {
            const implantProperty = props.label === ITEM_MATERIAL_ABUTMENT_LABEL ? 'abutment' : 'crown';
            const currentMaterial = ItemMaterialFieldV2Utils.getSingleMaterial(item, implantProperty);
            const updateMaterial = (value: string) =>
                updateItem({
                    item_index: item.item_index,
                    change: {
                        name: implantProperty === 'abutment' ? 'abutment_material' : 'material',
                        payload: value,
                    },
                });

            const suggestedValue =
                props.get_suggested_value && currentMaterial
                    ? CartItemV2Utils.getSingleToothUnits(item)
                          .map(u => props.get_suggested_value?.({ ...u, material: currentMaterial }))
                          .find(v => !!v)
                    : props.default_option_value;

            return {
                ...props,
                suggestedValue,
                required: true,
                value: currentMaterial,
                onChange: (value?: string) => {
                    if (value && value !== currentMaterial) {
                        updateMaterial(value);
                    }
                },
            };
        });
    }, [item, updateItem]);
};

export const MaterialFields: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const selectProps = useMaterialFieldSelectProps(item);
    React.useEffect(() => {
        selectProps.forEach(select => {
            if (!select.value && select.suggestedValue) {
                select.onChange(select.suggestedValue);
            }
        });
    }, [selectProps]);

    if (selectProps.length === 0) {
        return null;
    }

    return (
        <Grid container style={{ position: 'relative' }}>
            {selectProps.map(select => (
                <MaterialFieldSelect key={select.label} {...select} />
            ))}
            <DisabledFieldOverlay disabled={!CheckoutItemV2Manager.implantMetadataFieldsComplete(item)} />
        </Grid>
    );
};
