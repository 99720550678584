import LaptopCart from '../../../../assets/laptop-cart.png';
import { useShowMobileLayout } from '../../../../utils/LayoutUtils';
import { PackageCard } from './PackageCard';
import ScannerOSUpsell from '@orthly/dentin/assets/images/hd-skus/ScannerOS_Upsell.png';
import ScannerStandalone from '@orthly/dentin/assets/images/hd-skus/scanner-standalone.png';
import { useGetPracticeEquipmentDataQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { FlossPalette, stylesFactory, Card, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import cx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: '24px',
    },
    packageCard: {
        boxShadow: 'none',
        display: 'flex',
        flex: '0 0 60px',
        justifyContent: 'center',
        width: 60,
        height: 60,
        borderRadius: 4,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    cardColumns: {
        display: 'flex',
        gap: '16px',
        justifyContent: 'stretch',
        width: '100%',
    },
    cardColumn: {
        flex: '1 0 50%',
        display: 'flex',
        gap: '16px',
        width: 'fit-content',
        alignItems: 'center',
    },
    multiItem: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 1 100%',
        alignSelf: 'center',
    },
    scannerCard: {
        background: `url(${ScannerStandalone}) 50% / 80% ${FlossPalette.WHITE} no-repeat`,
    },
    laptopCard: {
        background: `url(${ScannerOSUpsell}) 50% / 80% ${FlossPalette.WHITE} no-repeat`,
    },
    cartCard: {
        background: `url(${LaptopCart}) 50% / 80% ${FlossPalette.WHITE} no-repeat`,
    },
}));

function useEquipmentTracking(): [string | null, string | null] {
    const { value: forceOnboarding } = useFeatureFlag('forceOnboarding');

    const session = useSession();
    const practiceId = session?.organization_id ?? '';
    const { data: equipmentData } = useGetPracticeEquipmentDataQuery({
        variables: {
            practiceId,
        },
        skip: !practiceId,
    });

    if (forceOnboarding) {
        // If the forceOnboarding flag is on, we display the equipment
        // tracker with fake tracking numbers for testing purposes
        return ['1Z001985YW965test1', '1Z001985YW965test2'];
    }

    return [
        equipmentData?.getPracticeEquipmentData?.tracking_number_c || null,
        equipmentData?.getPracticeEquipmentData?.tracking_number_d || null,
    ];
}

export const EquipmentTracker: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useShowMobileLayout();

    const [pkgOneTrackingNum, pkgTwoTrackingNum] = useEquipmentTracking();

    if (!pkgOneTrackingNum || !pkgTwoTrackingNum) {
        return null;
    }

    return (
        <div className={classes.wrapper}>
            <Text variant={'h6'} style={{ color: FlossPalette.GRAY }}>
                Track your equipment
            </Text>
            <PackageCard title={'First package'} trackingNumber={pkgOneTrackingNum} isMobile={isMobile}>
                <div className={classes.cardColumns}>
                    <div className={classes.cardColumn}>
                        <Card className={cx(classes.packageCard, classes.scannerCard)} />
                        <Text variant={'body2'}>1x 3D Intraoral Scanner</Text>
                    </div>
                    <div className={classes.cardColumn}>
                        <Card className={cx(classes.packageCard, classes.laptopCard)} />
                        <Text variant={'body2'}>1x Dell Laptop</Text>
                    </div>
                </div>
            </PackageCard>
            <PackageCard
                title={'Second package'}
                trackingNumber={pkgTwoTrackingNum}
                variant={'singleItem'}
                isMobile={isMobile}
            >
                <div className={classes.cardColumn}>
                    <Card className={cx(classes.packageCard, classes.cartCard)} />
                    <div className={classes.multiItem}>
                        <Text variant={'body2'}>1x Cart</Text>
                        <Text variant={'caption'}>This package will weigh 43 pounds and will be 40" x 25" x 9"</Text>
                    </div>
                </div>
            </PackageCard>
        </div>
    );
};
