import type { CheckboxPrimitiveProps as MuiCheckboxProps } from '@orthly/ui-primitives';
import { CheckboxPrimitive as MuiCheckbox } from '@orthly/ui-primitives';
import type { FieldProps } from 'formik';
import React from 'react';

export interface CheckboxProps
    extends FieldProps,
        Omit<
            MuiCheckboxProps,
            | 'name'
            | 'value'
            | 'error'
            | 'form'
            | 'checked'
            | 'defaultChecked'
            // Excluded for conflict with Field type
            | 'type'
        > {
    type?: string;
}

export function fieldToCheckbox({
    disabled,
    field: { onBlur: fieldOnBlur, ...field },
    form: { isSubmitting },
    type,
    onBlur,
    ...props
}: CheckboxProps): MuiCheckboxProps {
    const indeterminate = !Array.isArray(field.value) && field.value === null;

    return {
        disabled: disabled ?? isSubmitting,
        indeterminate,
        onBlur:
            onBlur ??
            function (e: any) {
                fieldOnBlur(e ?? field.name);
            },
        // Formik will pass the value in field.value, but the component uses the "checked" prop
        checked: typeof field.value === 'boolean' ? field.value : undefined,
        ...field,
        ...props,
    };
}

export function Checkbox(props: CheckboxProps) {
    return <MuiCheckbox {...fieldToCheckbox(props)} />;
}

Checkbox.displayName = 'FormikMaterialUICheckbox';
