import { TRY_IN_FEEDBACK_SIDEBAR_WIDTH } from './TryInFeedbackSidebar';
import { useSubmitTryInFeedback } from './hooks/useSubmitTryInFeedback';
import { useTryInFeedbackAction } from './state/TryInFeedback.action';
import { useTryInFeedbackPropSelector } from './state/TryInFeedback.selectors';
import type { TryInFeedbackState } from './state/TryInFeedback.types';
import { TryInFeedbackUtils } from './utils/TryInFeedback.util';
import {
    CartItemV2Utils,
    LabOrderItemSKUType,
    OrderItemArch,
    OrderItemPartialDentureProductionType,
} from '@orthly/items';
import { WarningIcon, Button, Text, FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

// matches the height that's dynamically created with the below styles
export const TRY_IN_FEEDBACK_NAVIGATION_BUTTONS_HEIGHT = 89;

const useStyles = stylesFactory(() => ({
    wrapper: {
        position: 'fixed',
        bottom: 0,
        left: TRY_IN_FEEDBACK_SIDEBAR_WIDTH,
        right: 0,
        display: 'flex',
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.WHITE,
        zIndex: 10000,
        padding: '20px 36px',
        alignItems: 'center',
    },
    button: {
        width: 264,
    },
    spacer: {
        flexGrow: 1,
    },
    errorMessage: {
        marginRight: 24,
        display: 'inline-flex',
    },
    warningIcon: {
        marginRight: 8,
    },
}));

function isNextEnabled(state: Pick<TryInFeedbackState, 'itemGroups' | 'navigation'>): boolean {
    const { itemGroups, navigation } = state;
    if (navigation?.step === 'group') {
        const activeGroup = itemGroups[navigation.groupIndex];
        // broken state, just bail
        if (!activeGroup) {
            return true;
        }
        if (
            navigation.subStep === 'next_steps' &&
            CartItemV2Utils.itemIsType(activeGroup.newItem, LabOrderItemSKUType.Partial)
        ) {
            const validSelections: OrderItemPartialDentureProductionType[] = _.compact([
                OrderItemPartialDentureProductionType.Final,
                OrderItemPartialDentureProductionType.TryInTeethSetup,
                ['Chrome-Cobalt', 'Titanium', 'Acetal'].includes(activeGroup.newItem.unit.material ?? '')
                    ? OrderItemPartialDentureProductionType.TryInFrameworkOnly
                    : undefined,
            ]);
            return (
                !!activeGroup.newItem.partial_production_type &&
                validSelections.includes(activeGroup.newItem.partial_production_type)
            );
        }
        if (
            navigation.subStep === 'next_steps' &&
            CartItemV2Utils.itemIsType(activeGroup.newItem, LabOrderItemSKUType.Denture)
        ) {
            return !!activeGroup.newItem.denture_production_type;
        }
    }
    return true;
}

function getNextText(state: Pick<TryInFeedbackState, 'itemGroups' | 'navigation'>): string {
    const { itemGroups, navigation } = state;
    if (navigation?.step === 'submit') {
        return 'Submit feedback';
    }
    if (navigation?.step === 'group' && navigation.subStep !== 'next_steps') {
        const activeGroup = itemGroups[navigation.groupIndex];
        const archFeedbackDetails = activeGroup?.feedbackDetails.filter(detail => detail.arch === navigation.subStep);
        if (!archFeedbackDetails?.length) {
            return navigation.subStep === OrderItemArch.Dual
                ? `Skip - proceed to arch feedback`
                : `Skip - ${navigation.subStep.toLowerCase()} arch is perfect`;
        }
    }
    return 'Next';
}

export const TryInFeedbackNavigationButtons: React.VFC = () => {
    const classes = useStyles();
    const setNavigation = useTryInFeedbackAction('SET_NAVIGATION');
    const setShowError = useTryInFeedbackAction('SET_SHOW_ERROR');
    const state = useTryInFeedbackPropSelector(['itemGroups', 'navigation', 'showError']);

    const { submit, submitting } = useSubmitTryInFeedback();

    const previousNavigation = TryInFeedbackUtils.getPreviousNavigation(state);
    const nextNavigation = TryInFeedbackUtils.getNextNavigation(state);

    return (
        <div className={classes.wrapper}>
            <Button
                variant={'secondary'}
                onClick={() => previousNavigation && setNavigation({ navigation: previousNavigation })}
                disabled={!previousNavigation}
                startIcon={'ChevronLeft'}
                className={classes.button}
            >
                Previous
            </Button>
            <div className={classes.spacer} />
            {state.showError && (
                <Text variant={'body2'} color={'ATTENTION'} className={classes.errorMessage}>
                    <WarningIcon className={classes.warningIcon} /> Please add instructions for all selected categories
                </Text>
            )}
            <Button
                variant={'primary'}
                onClick={async () => {
                    if (TryInFeedbackUtils.isActiveFeedbackGroupIncomplete(state)) {
                        setShowError({ showError: true });
                        return;
                    }
                    if (state.navigation?.step === 'submit') {
                        await submit();
                        return;
                    }
                    if (nextNavigation) {
                        setNavigation({ navigation: nextNavigation });
                    }
                }}
                disabled={
                    (!nextNavigation && state.navigation?.step !== 'submit') ||
                    !isNextEnabled(state) ||
                    state.showError ||
                    submitting
                }
                endIcon={'ChevronRight'}
                className={classes.button}
            >
                {getNextText(state)}
            </Button>
        </div>
    );
};
