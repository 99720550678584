import { useLazyQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const UserExistsWithMobileNumber_Query = graphql(`
    query UserExistsWithMobilePhoneNumber($mobile_phone_number: String!, $staff_member_id: String) {
        userExistsWithMobileNumber(mobilePhoneNumber: $mobile_phone_number, staff_member_id: $staff_member_id)
    }
`);

export function useUserExistsWithMobileNumber({ onSuccess }: { onSuccess?: (userExists: boolean) => void }) {
    const [checkUserExists, { error, loading }] = useLazyQuery(UserExistsWithMobileNumber_Query, {
        onCompleted: data => {
            onSuccess?.(data.userExistsWithMobileNumber);
        },
    });

    return {
        submit: (mobile_phone_number: string, staff_member_id: string | null = null) => {
            checkUserExists({ variables: { mobile_phone_number, staff_member_id } });
        },
        error: error?.message,
        loading,
    };
}
