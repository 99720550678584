import type { Theme, SlideProps } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grid, Slide } from '@orthly/ui-primitives';
import type { CSSProperties } from 'react';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        notVisible: {
            position: 'absolute',
            left: 0,
            right: 0,
            padding: `0 ${theme.typography.pxToRem(48)}`,
            bottom: 0,
        },
    }),
);

interface CheckoutBodyWrapperProps {
    visible: boolean;
    isComplete?: boolean;
    children: React.ReactNode;
    onEntered?: SlideProps['onEntered'];
    onExit?: SlideProps['onExit'];
    style?: CSSProperties;
}

export const CheckoutBodyWrapper: React.FC<CheckoutBodyWrapperProps> = props => {
    const classes = useStyles();
    const shouldExitUp = props.isComplete || props.visible;
    const [localDir, setLocalDir] = React.useState<'up' | 'down'>(shouldExitUp ? 'down' : 'up');
    React.useEffect(() => {
        const newDir = shouldExitUp ? 'down' : 'up';
        newDir !== localDir && setLocalDir(newDir);
    }, [localDir, shouldExitUp]);

    return (
        <Slide
            direction={localDir}
            in={props.visible}
            timeout={300}
            exit={true}
            unmountOnExit={true}
            mountOnEnter={true}
            onEntered={props.onEntered}
            onExit={props.onExit}
        >
            <Grid
                container
                className={!props.visible ? classes.notVisible : undefined}
                style={{ bottom: 92, ...props.style }}
            >
                {props.children}
            </Grid>
        </Slide>
    );
};
