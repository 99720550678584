import { FlossPalette, stylesFactory, ArrowForwardIcon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        width: '336px',
        height: '240px',
        borderRadius: '8px',
        padding: '24px',
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        '&:hover': {
            cursor: 'pointer',
        },
        '&:hover $title': {
            color: `${FlossPalette.PRIMARY_FOREGROUND} !important`,
        },
        '&:hover $button': {
            width: '64px',
            transition: 'width 0.25s cubic-bezier(0.34, 1.56, 0.64, 1)',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    icon: {
        width: '24px',
        height: '24px',
    },
    button: {
        display: 'flex',
        padding: '4px',
        width: '32px',
        height: '32px',
        marginTop: 'auto',
        borderRadius: '40px',
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        color: FlossPalette.WHITE,
        transition: 'all 0.25s cubic-bezier(0.34, 1.56, 0.64, 1)',
        '& svg': {
            width: '24px',
            height: '24px',
            marginLeft: 'auto',
        },
    },
    title: {},
}));

type Props = {
    icon: string;
    iconAlt: string;
    title: string;
    body: string;
    externalLink: string;
};

export const GettingStartedLink: React.FC<Props> = props => {
    const { icon, iconAlt, title, body, externalLink } = props;
    const onClick = React.useCallback(() => {
        window.open(`https://help.meetdandy.com/hc/en-us/sections/${externalLink}`, '_blank')?.focus();
    }, [externalLink]);

    const classes = useStyles();

    return (
        <div className={classes.root} onClick={onClick} onAuxClick={onClick}>
            <img className={classes.icon} src={icon} alt={iconAlt} />
            <Text className={classes.title} variant={'body2'} medium color={'BLACK'}>
                {title}
            </Text>
            <Text variant={'body2'} color={'DARK_GRAY'}>
                {body}
            </Text>
            <div className={classes.button}>
                <ArrowForwardIcon />
            </div>
        </div>
    );
};
