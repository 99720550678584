import { PatientNameField, PatientDataFields } from './PatientFields';
import type { PatientFormAnalytics, PatientFormPatient } from './PatientForm.types';
import { isValidPatientName, splitPatientName } from './PatientForm.util';
import { Button, Grid, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

interface EditPatientFormProps {
    patient: PatientFormPatient;
    onConfirm: (patient: PatientFormPatient) => void;
    onCancel: () => void;
    analytics: PatientFormAnalytics;
}

export const EditPatientForm: React.VFC<EditPatientFormProps> = ({ patient, onConfirm, onCancel, analytics }) => {
    const [editedPatient, setEditedPatient] = React.useState<PatientFormPatient>(patient);
    const [birthdayError, setBirthdayError] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        setEditedPatient(patient);
    }, [patient, setEditedPatient]);

    const updateField = <K extends keyof PatientFormPatient>(key: K, value: PatientFormPatient[K]) => {
        setEditedPatient(p => ({ ...p, [key]: value }));
    };

    const errorReason = React.useMemo(() => {
        if (!isValidPatientName(`${editedPatient.first_name} ${editedPatient.last_name}`)) {
            return `Please enter both the first and last names`;
        }
        if (!editedPatient.birthday) {
            return `Please select a patient date of birth.`;
        }
        return birthdayError;
    }, [editedPatient, birthdayError]);

    return (
        <>
            <Grid item>
                <PatientNameField
                    search={`${editedPatient.first_name} ${editedPatient.last_name}`}
                    setSearch={search => {
                        const { first, last } = splitPatientName(search);
                        updateField('first_name', first);
                        updateField('last_name', last);
                    }}
                    overrideValidationError={false}
                />
            </Grid>
            <Grid item container direction={'row'} style={{ marginTop: 8 }}>
                <PatientDataFields
                    gender={editedPatient.gender}
                    setGender={gender => gender && updateField('gender', gender)}
                    birthday={editedPatient.birthday}
                    setBirthday={birthday => birthday && updateField('birthday', birthday)}
                    birthdayError={birthdayError}
                    setBirthdayError={setBirthdayError}
                />
            </Grid>
            <Grid item container spacing={1} style={{ marginTop: 4 }}>
                <Grid item xs>
                    <Button
                        variant={'secondary'}
                        onClick={() => onCancel()}
                        style={{ width: '100%' }}
                        analytics={{
                            ...analytics,
                            AssetName: 'Edit Patient Cancel',
                        }}
                    >
                        Cancel
                    </Button>
                </Grid>
                <Grid item xs>
                    <Tooltip title={errorReason || 'No Errors'} disableHoverListener={!errorReason}>
                        <div>
                            <Button
                                variant={'primary'}
                                onClick={() => onConfirm(editedPatient)}
                                disabled={!!errorReason}
                                endIcon={'CheckIcon'}
                                style={{ width: '100%' }}
                                analytics={{
                                    ...analytics,
                                    AssetName: 'Edit Patient Save',
                                }}
                            >
                                Save Changes
                            </Button>
                        </div>
                    </Tooltip>
                </Grid>
            </Grid>
        </>
    );
};
