import { useCurrentProfileIsDoctor, useCurrentSessionIsAdmin } from '../../../../redux/selectors';
import { SettingsBaseModal } from '../../components/SettingsBaseModal';
import { useSetDoctorPreferences, useUpdatePracticeStaff } from '../../state/usePracticeStaffControls';
import type { StaffUser } from './useFetchPracticeStaffUsers.graphql';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { Grid, FormControlLabel, CheckboxPrimitive as Checkbox, Button, Text } from '@orthly/ui-primitives';
import { PersonCardOccupationOptionsDisplay } from '@orthly/veneer';
import React from 'react';

const EditPositionsForm: React.VFC<{
    staffUsers: StaffUser[];
    roles: LabsGqlStaffRolePractice[];
    handleChange: (addRole: boolean, role: LabsGqlStaffRolePractice) => void;
}> = ({ staffUsers, roles, handleChange }) => {
    const numStaff = staffUsers.length;

    return (
        <Grid container>
            {roles.map(role => {
                const numStaffWithRole = staffUsers.filter(pref => pref.roles.includes(role)).length;
                return (
                    <Grid key={role} item sm={4} xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color={'secondary'}
                                    style={{ marginRight: 0 }}
                                    checked={numStaffWithRole === numStaff}
                                    indeterminate={numStaffWithRole > 0 && numStaffWithRole < numStaff}
                                    onChange={event => handleChange(event.target.checked, role)}
                                />
                            }
                            label={<Text variant={'body2'}>{PersonCardOccupationOptionsDisplay[role]}</Text>}
                        />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const EditStaffRolesModal: React.VFC<{
    staffUsers: StaffUser[];
    openModalButtonText: string;
    title: string;
    subtitle: string;
    confirmButtonText: string;
}> = ({ staffUsers, openModalButtonText, title, subtitle, confirmButtonText }) => {
    const isPracticeAdmin = useCurrentSessionIsAdmin();
    const isPracticeDoctor = useCurrentProfileIsDoctor();
    const { submit: submitUpdatePrefs, submitting: submittingUpdatePrefs } = useSetDoctorPreferences();
    const { submit: submitUpdatePracticeStaff, submitting: submittingUpdatePracticeStaff } = useUpdatePracticeStaff();
    const [open, setOpen] = React.useState(false);
    const [newStaffUsers, setNewStaffUsers] = React.useState<StaffUser[]>([]);
    const positionsRoles = React.useMemo(
        () =>
            Object.values(LabsGqlStaffRolePractice).filter(
                role =>
                    role !== LabsGqlStaffRolePractice.Admin &&
                    (isPracticeAdmin || isPracticeDoctor || role !== LabsGqlStaffRolePractice.PracticeDoctor),
            ),
        [isPracticeAdmin, isPracticeDoctor],
    );
    const noRolesSelected = React.useMemo(
        () =>
            newStaffUsers.filter(
                staffUser => staffUser.roles.filter(role => role !== LabsGqlStaffRolePractice.Admin).length === 0,
            ).length > 0,
        [newStaffUsers],
    );

    React.useEffect(() => {
        setNewStaffUsers(staffUsers);
    }, [staffUsers]);

    const handleUpdateRoles = async () => {
        await Promise.all(
            newStaffUsers.map(async staffUser => {
                const roles = staffUser.roles ?? [];

                await submitUpdatePrefs({
                    roles,
                    preference_set_id: staffUser.doctor_preference_id,
                });

                if (staffUser.staff_member_id) {
                    await submitUpdatePracticeStaff({
                        roles,
                        organization_id: staffUser.partner_id,
                        member_id: staffUser.staff_member_id,
                    });
                }
            }),
        );
        setOpen(false);
    };

    const handleChangeRole = (addRole: boolean, changedRole: LabsGqlStaffRolePractice) => {
        const updatedStaffUsers = newStaffUsers.map(staffUser => {
            const roles = staffUser.roles ?? [];
            return {
                ...staffUser,
                roles: addRole ? [...roles, changedRole] : roles.filter(role => role !== changedRole),
            };
        });

        setNewStaffUsers(updatedStaffUsers);
    };

    return (
        <SettingsBaseModal
            open={open}
            setOpen={setOpen}
            title={title}
            subtitle={subtitle}
            loading={submittingUpdatePrefs || submittingUpdatePracticeStaff}
            CustomButton={() => (
                <Button variant={'text'} onClick={() => setOpen(true)} style={{ paddingLeft: 24, paddingRight: 0 }}>
                    <Text variant={'body2'} color={'STAR_GRASS'} medium>
                        {openModalButtonText}
                    </Text>
                </Button>
            )}
            content={
                <Grid container direction={'column'}>
                    <EditPositionsForm staffUsers={staffUsers} roles={positionsRoles} handleChange={handleChangeRole} />
                    <Grid container justifyContent={'flex-end'} style={{ marginTop: 16 }}>
                        <Button variant={'secondary'} style={{ marginRight: 8 }} onClick={() => setOpen(false)}>
                            Cancel
                        </Button>
                        <Button variant={'primary'} onClick={handleUpdateRoles} disabled={noRolesSelected}>
                            {confirmButtonText}
                        </Button>
                    </Grid>
                </Grid>
            }
        />
    );
};
