/* eslint-disable max-lines */
import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { useCheckoutPropSelector, useCheckoutSelector, useCurrentProfile } from '../../../../redux';
import { ATTACH_CHECKOUT_FILES_PATH } from '../../../labs/LabsPaths';
import { CheckoutItemV2Manager } from '../../state/CheckoutItemV2Manager';
import { useCheckoutAction } from '../../state/checkout.actions';
import { implantCheckoutEnabled } from '../../state/checkout.selectors';
import type { CheckoutItemV2 } from '../../state/checkout.state';
import { DoctorNotesCategoryV2 } from '../../state/checkout.state';
import { CheckoutTextField } from '@orthly/dentin';
import { LabsGqlLabOrderPhotoType } from '@orthly/graphql-schema';
import { CartItemV2Utils } from '@orthly/items';
import type { IOrderItemShade, ToothNumber } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { ActionCard, NavigationLogoDandy, RootActionDialog } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    Tooltip,
    Collapse,
    Grid,
    KeyboardArrowDownIcon,
    KeyboardArrowUpIcon,
    Button,
    Text,
} from '@orthly/ui-primitives';
import type { PhotoTypeInfo } from '@orthly/veneer';
import {
    AttachmentUploader,
    QRCode,
    UploadPreview,
    useAttachments,
    useImpersonatedQrCodeLink,
    useOrderDetailPreferences,
} from '@orthly/veneer';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    orderNotesContentBlock: {
        background: FlossPalette.WHITE,
        padding: '16px',
        borderBottom: 'none',
        borderRadius: '16px',
    },
    orderNotesContentWrapper: {
        paddingTop: '12px',
        gap: '12px',
    },
    orderNotesCategory: {
        background: FlossPalette.TAN,
        padding: '16px 24px 16px 24px',
        borderRadius: '4px',
    },
    logo: {
        color: FlossPalette.DARK_TAN,
        height: 24,
        minHeight: 24,
        marginTop: 12,
    },
}));

// Terminology is a bit confusing here. "Category" in V2 doesn't refer to categories in the previous version of order notes,
// but instead it refers to a new set of categories that are completely unrelated
interface CheckoutNoteCategoryProps {
    title: string;
    category: DoctorNotesCategoryV2;
}

const CheckoutNoteCategory: React.FC<CheckoutNoteCategoryProps> = props => {
    const classes = useStyles();
    const { doctorNotesV2Category } = useCheckoutPropSelector(['doctorNotesV2Category']);
    const setCategory = useCheckoutAction('SET_CURRENT_NOTES_V2_CATEGORY');
    const open = doctorNotesV2Category === props.category;

    return (
        <Grid
            container
            className={classes.orderNotesCategory}
            onClick={() => {
                setCategory(open ? DoctorNotesCategoryV2.None : props.category);
            }}
            direction={'column'}
            wrap={'nowrap'}
        >
            <Grid container direction={'row'} wrap={'nowrap'} justifyContent={'space-between'}>
                <Text medium variant={'body2'}>
                    {props.title}
                </Text>

                {open ? (
                    <KeyboardArrowUpIcon style={{ color: FlossPalette.GRAY }} />
                ) : (
                    <KeyboardArrowDownIcon style={{ color: FlossPalette.GRAY }} />
                )}
            </Grid>

            <Collapse in={open}>
                {/* We don't want edits to the expanded inputs to cause the category to collapse */}
                <Grid container className={classes.orderNotesContentWrapper} onClick={event => event.stopPropagation()}>
                    {props.children}
                </Grid>
            </Collapse>
        </Grid>
    );
};

interface CheckoutSummaryItemTitleProps {
    title: string;
    buttonText: string;
    buttonTooltipText: string;
    subtitle?: string;
    onClick?: () => void;
}

export const CheckoutSummaryItemContent: React.VFC<CheckoutSummaryItemTitleProps> = props => {
    const { title, buttonText, buttonTooltipText, subtitle, onClick } = props;

    return (
        <>
            <Grid container wrap={'nowrap'} justifyContent={'space-between'} alignItems={'center'}>
                <Text style={{ fontSize: '1.1em', fontWeight: 500, width: '100%' }} variant={'h6'}>
                    {title}
                </Text>
                <Tooltip title={buttonTooltipText} placement={'right'} arrow>
                    <Button
                        size={'small'}
                        style={{
                            color: FlossPalette.BLACK,
                            minWidth: 'unset',
                            padding: '2px 10px',
                        }}
                        onClick={onClick}
                        variant={'text'}
                    >
                        {buttonText}
                    </Button>
                </Tooltip>
            </Grid>
            {!!subtitle && (
                <Text style={{ width: '100%' }} variant={'body1'}>
                    {subtitle}
                </Text>
            )}
        </>
    );
};

const getShadeSummary = (shades: IOrderItemShade[]): string | undefined => {
    if (shades.length === 0) {
        return undefined;
    }
    const baseOnly = shades.length === 1 && shades[0]?.name === 'base' && !!shades[0]?.value;
    if (baseOnly) {
        return `Shade: ${shades[0]?.value}`;
    }
    return `Shades: ${shades.map(s => (!!s.value ? `${_.capitalize(s.name)}: ${s.value}` : '')).join(', ')}`;
};

const formatToothSubtitle = (unns: ToothNumber[]): string | undefined => {
    if (unns.length > 1) {
        return `Teeth: ${Format.joinWithAnd(unns)}`;
    }

    if (unns.length === 1) {
        return `Tooth: ${unns[0]}`;
    }
};

export const getSummaryItemSubtitle = (item: CheckoutItemV2): string | undefined => {
    const unns = CartItemV2Utils.getUniqueUNNs(item);
    const toothSubtitle = formatToothSubtitle(unns);
    const shadesSubtitle = getShadeSummary(item.shades);

    return toothSubtitle && shadesSubtitle ? `${toothSubtitle}, ${shadesSubtitle}` : toothSubtitle ?? shadesSubtitle;
};

const OrderDetailCategory: React.VFC = () => {
    const classes = useStyles();

    const goToEditOrderScreen = useCheckoutAction('BACK_TO_EDIT_ORDER');
    const goToItem = useCheckoutAction('SET_ACTIVE_ITEM_IDX');

    const { items } = useCheckoutPropSelector(['items']);
    const preferences = useOrderDetailPreferences(items);

    return (
        <CheckoutNoteCategory title={'Edit Order Details'} category={DoctorNotesCategoryV2.OrderDetails}>
            {items.map(item => {
                const title = CheckoutItemV2Manager.getFormattedTitle(item);
                const subtitle = getSummaryItemSubtitle(item);

                return (
                    <Grid key={item.item_index} container className={classes.orderNotesContentBlock}>
                        <CheckoutSummaryItemContent
                            title={title}
                            buttonText={'Edit'}
                            buttonTooltipText={'Return to item and edit'}
                            subtitle={subtitle}
                            onClick={() => goToItem(item.item_index)}
                        />
                    </Grid>
                );
            })}

            <Grid container direction={'column'} className={classes.orderNotesContentBlock}>
                <Text medium variant={'body2'}>
                    Preferences
                </Text>

                {preferences.map(pref => (
                    <div key={pref.name} style={{ marginTop: '4px' }}>
                        <Text color={'DARK_GRAY'} display={'block'} variant={'body2'}>
                            {pref.name}:
                        </Text>

                        <Text display={'block'} variant={'body2'}>
                            {pref.value}
                        </Text>
                    </div>
                ))}
            </Grid>

            <Button style={{ background: FlossPalette.WHITE }} variant={'secondary'} onClick={goToEditOrderScreen}>
                Edit Order / Preferences
            </Button>
        </CheckoutNoteCategory>
    );
};

const CheckoutOrderNotesV2PhotoTypes: PhotoTypeInfo[] = [
    {
        type: LabsGqlLabOrderPhotoType.PatientPhoto,
        title: 'Patient photos/shade',
        acceptFileTypes: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] },
        acceptMultipleFiles: true,
    },
    {
        type: LabsGqlLabOrderPhotoType.SurgicalReport,
        title: 'Surgical report',
    },
    {
        type: LabsGqlLabOrderPhotoType.Xray,
        acceptFileTypes: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] },
        title: 'X-ray',
    },
    {
        type: LabsGqlLabOrderPhotoType.ScanFile,
        title: 'Additional scan files',
        acceptMultipleFiles: true,
    },
];

function useUploadTypes(): PhotoTypeInfo[] {
    const isImplants = useCheckoutSelector(implantCheckoutEnabled);

    return React.useMemo(() => {
        const allowedTypes = isImplants
            ? [LabsGqlLabOrderPhotoType.PatientPhoto, LabsGqlLabOrderPhotoType.ScanFile]
            : [
                  LabsGqlLabOrderPhotoType.Xray,
                  LabsGqlLabOrderPhotoType.SurgicalReport,
                  LabsGqlLabOrderPhotoType.PatientPhoto,
                  LabsGqlLabOrderPhotoType.ScanFile,
              ];

        return CheckoutOrderNotesV2PhotoTypes.filter(photoType => {
            return allowedTypes.some(uploadType => uploadType === photoType.type);
        });
    }, [isImplants]);
}

const AttachmentCategory: React.VFC = () => {
    const { scan } = useCheckoutPropSelector(['scan']);

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [qrcodeDialogOpen, setQrcodeDialogOpen] = React.useState(false);
    const setAttachments = useCheckoutAction('SET_ATTACHMENTS');

    const scanID = scan?.id;
    const { filesByPath, uploadFiles, deleteFile, error, loading } = useAttachments(scanID, '/notes');

    const uploadTypes = useUploadTypes();

    const doctorInfo = useCurrentProfile();
    const doctorId = doctorInfo?.id ?? 'staff';
    // todo: protect if practice staff
    const mobileUrl = React.useMemo(() => {
        const url = new URL(`${window.location.origin}/lab/${ATTACH_CHECKOUT_FILES_PATH}/${scanID}`);
        url.searchParams.append('doctorId', doctorId);

        uploadTypes.forEach(uploadType => {
            url.searchParams.append('uploadType', uploadType.type);
        });

        return url.toString();
    }, [scanID, uploadTypes, doctorId]);

    const qrCodeResult = useImpersonatedQrCodeLink(scanID ? mobileUrl : null);

    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {
        if (error) {
            enqueueSnackbar(`${error}`, { variant: 'error' });
        }
    }, [error, enqueueSnackbar]);

    React.useEffect(() => {
        setAttachments(
            Object.values(filesByPath).map(file => {
                return { type: file.type, fullPath: file.fullPath, sendToChat: true };
            }),
        );
    }, [filesByPath, setAttachments]);

    return (
        <CheckoutNoteCategory title={'Add Attachments'} category={DoctorNotesCategoryV2.Attachments}>
            <Grid container direction={'row'} style={{ gap: 8 }}>
                <RootActionDialog
                    loading={false}
                    open={dialogOpen}
                    setOpen={setDialogOpen}
                    buttonText={'Upload File(s)'}
                    title={'Upload Files'}
                    buttonProps={{
                        style: { background: FlossPalette.WHITE },
                        variant: 'secondary',
                        fullWidth: false,
                    }}
                    content={
                        <Grid container direction={'column'}>
                            <Text variant={'body2'}>Please upload files into the correct categories:</Text>

                            <Grid
                                container
                                justifyContent={'space-evenly'}
                                direction={'row'}
                                wrap={'wrap'}
                                style={{ gap: 20, marginTop: 20 }}
                            >
                                {uploadTypes.map(typeInfo => (
                                    <AttachmentUploader
                                        key={typeInfo.type}
                                        typeInfo={typeInfo}
                                        filesByPath={filesByPath}
                                        uploadFiles={async (type: LabsGqlLabOrderPhotoType, files: File[]) => {
                                            AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                                                productName: 'attachments',
                                                isMobile: false,
                                            });
                                            await uploadFiles(type, files);
                                        }}
                                        deleteFile={deleteFile}
                                        isDesktop={true}
                                        loading={loading}
                                    />
                                ))}
                            </Grid>

                            <Button
                                variant={'primary'}
                                fullWidth={false}
                                style={{ marginTop: 20, alignSelf: 'flex-end', width: '136px' }}
                                onClick={() => setDialogOpen(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                    }
                />

                <RootActionDialog
                    loading={false}
                    open={qrcodeDialogOpen}
                    setOpen={setQrcodeDialogOpen}
                    buttonText={'Upload from your Phone'}
                    title={'Upload from your phone'}
                    PaperProps={{ style: { width: 'fit-content', minWidth: 'none' } }}
                    buttonProps={{
                        style: { background: FlossPalette.WHITE },
                        variant: 'secondary',
                        fullWidth: false,
                    }}
                    content={
                        <Grid container direction={'column'} alignItems={'center'}>
                            <Text variant={'body2'} style={{ marginBottom: 16 }}>
                                Please upload files into the correct categories:
                            </Text>

                            <QRCode
                                qrData={qrCodeResult.qrData}
                                error={qrCodeResult.error}
                                errorText={'Please try using the desktop uploader.'}
                            />

                            <Button
                                variant={'primary'}
                                fullWidth={false}
                                style={{ marginTop: 40, width: '136px' }}
                                onClick={() => setQrcodeDialogOpen(false)}
                            >
                                Close
                            </Button>
                        </Grid>
                    }
                />
            </Grid>

            <Grid container direction={'row'} style={{ gap: 8 }}>
                {Object.values(filesByPath).map(file => (
                    <UploadPreview
                        key={file.fullPath}
                        disabled={loading}
                        downloadUrl={file.downloadUrl}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onDelete={() => deleteFile(file.fullPath)}
                        file={file.file}
                    />
                ))}
            </Grid>
        </CheckoutNoteCategory>
    );
};

export const CheckoutOrderNotesCategorized: React.VFC = () => {
    const { doctor_notes } = useCheckoutPropSelector(['doctor_notes']);
    const setNotes = useCheckoutAction('SET_DOCTOR_NOTES');

    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ paddingTop: 8, gap: 4 }}>
            <AttachmentCategory />

            <CheckoutNoteCategory title={'Extra Instructions'} category={DoctorNotesCategoryV2.ExtraInstructions}>
                <CheckoutTextField
                    onChange={value => setNotes(value && value.length > 0 ? value : null)}
                    label={'Add more details (Optional)'}
                    required={false}
                    value={doctor_notes}
                    TextFieldProps={{
                        autoFocus: true,
                        InputLabelProps: {
                            shrink: true,
                            style: {
                                background: FlossPalette.WHITE,
                            },
                        },
                        minRows: 2,
                        maxRows: 10,
                        InputProps: {
                            style: {
                                background: FlossPalette.WHITE,
                            },
                        },
                        multiline: true,
                    }}
                />
            </CheckoutNoteCategory>

            <OrderDetailCategory />
        </Grid>
    );
};

export const CheckoutOrderNotesV2MobilePhotoUpload: React.VFC = () => {
    const classes = useStyles();

    const { scanID } = useParams<{ scanID: string }>();
    const { filesByPath, uploadFiles, deleteFile, error, loading } = useAttachments(scanID, '/notes');

    const doctorInfo = useCurrentProfile();

    const history = useHistory();

    const uploadTypes = React.useMemo(() => {
        const params = new URLSearchParams(history.location.search);
        const uploadTypes: string[] = params.getAll('uploadType');

        return CheckoutOrderNotesV2PhotoTypes.filter(photoType => {
            return uploadTypes.some(uploadType => uploadType === photoType.type);
        });
    }, [history]);

    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ padding: '0 12px', gap: 12, overflowY: 'auto' }}>
            <NavigationLogoDandy className={classes.logo} />

            <Text variant={'h4'} style={{ textAlign: 'center' }}>
                {doctorInfo?.name ? `Hey ${doctorInfo.name}` : 'Hello'}
            </Text>

            <Text variant={'body2'} medium style={{ textAlign: 'center' }}>
                Your files will be automatically added to your order notes.
            </Text>

            <Text variant={'body2'} style={{ textAlign: 'center' }}>
                Please select the correct categories below:
            </Text>

            {error && (
                <ActionCard
                    variant={'alert'}
                    title={'Failed to load uploaded images.'}
                    subtitle={'This is probably a temporary error. Please try refreshing the page.'}
                    style={{ marginBottom: 12 }}
                />
            )}

            <Grid container direction={'column'} style={{ gap: 16 }}>
                {uploadTypes.map(typeInfo => (
                    <AttachmentUploader
                        key={typeInfo.type}
                        typeInfo={typeInfo}
                        filesByPath={filesByPath}
                        uploadFiles={async (type: LabsGqlLabOrderPhotoType, files: File[]) => {
                            AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                                productName: 'attachments',
                                isMobile: true,
                            });
                            await uploadFiles(type, files);
                        }}
                        deleteFile={deleteFile}
                        isDesktop={false}
                        loading={loading}
                    />
                ))}
            </Grid>
        </Grid>
    );
};

export const CheckoutOrderNotesV2: React.VFC = () => {
    const { scan, doctorNotesOpen: open } = useCheckoutPropSelector(['doctorNotesOpen', 'scan']);
    const setOpen = useCheckoutAction('SET_DOCTOR_NOTES_OPEN');

    const isRefab = useCheckoutSelector(s => !!s.refab);

    if (isRefab) {
        return null;
    }

    return (
        <Grid container style={{ padding: '12px 0' }}>
            <Grid container justifyContent={'space-between'} wrap={'nowrap'} alignItems={'center'}>
                <Collapse in={!open} style={{ width: 'auto' }}>
                    <Grid container>
                        <Button
                            variant={'text'}
                            size={'small'}
                            onClick={() => {
                                setOpen(true);
                                AnalyticsClient.track('Practice - Checkout - Order Notes Opened', {
                                    $groups: {
                                        case: scan?.threeshape_order_id,
                                    },
                                });
                            }}
                            style={{ padding: 0, color: FlossPalette.GRAY }}
                        >
                            Add note
                        </Button>
                    </Grid>
                </Collapse>
            </Grid>

            <Collapse in={open} style={{ width: '100%' }} mountOnEnter unmountOnExit>
                <CheckoutOrderNotesCategorized />
            </Collapse>
        </Grid>
    );
};
