import { useScanbodyDeliveryAddress } from '../hooks/useScanbodyDeliveryAddress';
import { useScanbodyRequestAction } from '../state/ScanbodyRequest.actions';
import {
    ScanbodyRequestStage,
    useScanbodyRequestPropSelector,
    useScanbodyRequestStageSelector,
    useScanbodyRequestSelector,
} from '../state/ScanbodyRequest.selectors';
import { ScanbodyStage } from '../state/ScanbodyRequest.types';
import {
    useScanbodySurgicalReportUploaderSession,
    ScanbodySurgicalReportUploader,
} from './ScanbodySurgicalReportUploader/ScanbodySurgicalReportUploader';
import {
    useBulkCreateScanbodyRequestsMutation,
    useGetPracticeScanbodyInventoryQuery,
    useFetchDoctorsQuery,
} from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { SimpleDatePicker, SimpleSelect, useChangeSubmissionFn, LoadBlocker } from '@orthly/ui';
import { stylesFactory, useScreenIsMobile, Collapse, Grid, Button, Text } from '@orthly/ui-primitives';
import { AutocompleteImplantSystemInput } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    formContainer: { padding: 40 },
    formFieldRow: { marginBottom: 24 },
    formFieldHeader: { display: 'block', paddingBottom: 16 },
    // to match paddingTop of AutocompleteImplantSystemInput
    formFieldContent: { paddingTop: 12 },
    formInlineElement: { marginRight: 16 },
}));

interface ScanbodyRequestFormInlineFieldProps {
    title?: React.ReactNode;
    content?: React.ReactNode;
}

const ScanbodyRequestFormInlineField: React.FC<ScanbodyRequestFormInlineFieldProps> = props => {
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const { title, content } = props;
    return (
        <div style={{ display: isMobile ? 'block' : 'inline' }}>
            <Text variant={'body2'} display={'inline'} color={'DARK_GRAY'}>
                {title}
            </Text>
            <Text variant={'body2'} display={'inline'} className={classes.formInlineElement}>
                {content}
            </Text>
        </div>
    );
};

const ScanbodyRequestFormImplantSystemSection: React.FC = () => {
    const classes = useStyles();
    const request = useScanbodyRequestSelector(s => s.request);
    const setField = useScanbodyRequestAction('SET_REQUEST_FIELD');
    const isMobile = useScreenIsMobile();
    return (
        <Grid
            item
            xs={12}
            className={classes.formFieldRow}
            style={{
                /* formFieldRow.marginBottom=24 - AutocompleteImplantSystemInput.someUnknownDiv.padding=8 * 2 */
                marginBottom: isMobile ? 24 : 24 - 8 * 2,
            }}
        >
            <Text variant={'h5'} className={classes.formFieldHeader}>
                What are the implant details?
            </Text>
            <AutocompleteImplantSystemInput
                linkMeta={request}
                updateField={(property, value) => setField({ property, value })}
                decorativeArrow={!isMobile}
                vertical={isMobile}
            />
        </Grid>
    );
};

const ScanbodyRequestFormAppointmentDateSection: React.FC = () => {
    const classes = useStyles();
    const request = useScanbodyRequestSelector(s => s.request);
    const setField = useScanbodyRequestAction('SET_REQUEST_FIELD');
    const isMobile = useScreenIsMobile();
    return (
        <Grid item xs={12} className={classes.formFieldRow}>
            <Text variant={'h5'} className={classes.formFieldHeader}>
                When is this appointment scheduled?
            </Text>
            <Grid container className={classes.formFieldContent}>
                <SimpleDatePicker
                    label={'Appointment Date'}
                    disablePast={true}
                    autoFocus={!request.appointment_date}
                    sx={{ width: isMobile ? '100%' : 'initial' }}
                    value={request.appointment_date ? new Date(request.appointment_date) : null}
                    onChange={value =>
                        setField({
                            property: 'appointment_date',
                            value: value && !Number.isNaN(value.valueOf()) ? value.toISOString() : null,
                        })
                    }
                />
            </Grid>
        </Grid>
    );
};

const ScanbodyRequestFormAddressSection: React.FC = () => {
    const classes = useStyles();
    const request = useScanbodyRequestSelector(s => s.request);
    const setField = useScanbodyRequestAction('SET_REQUEST_FIELD');
    const { addresses, loading: addressLoading } = useScanbodyDeliveryAddress();
    // default select if only one address
    React.useEffect(() => {
        const property = 'address';
        const value = addresses[0];
        if (value && addresses.length === 1) {
            setField({ property, value });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addresses]);
    return (
        <Grid item xs={12} className={classes.formFieldRow}>
            <Text variant={'h5'} className={classes.formFieldHeader}>
                Which address should we send the scan body to?
            </Text>
            <Grid container className={classes.formFieldContent}>
                <LoadBlocker blocking={addressLoading}>
                    <SimpleSelect
                        options={addresses.map(value => ({ value }))}
                        onChange={value => setField({ property: 'address', value: value ?? null })}
                        value={request.address ?? ''}
                        label={'Address'}
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

const ScanbodyRequestFormDoctorSection: React.FC = () => {
    const classes = useStyles();
    const request = useScanbodyRequestSelector(s => s.request);
    const setField = useScanbodyRequestAction('SET_REQUEST_FIELD');

    const { data: doctorsData, loading } = useFetchDoctorsQuery({
        onCompleted: data => {
            const firstDoctorId = data.fetchDoctors[0]?.id;
            if (data.fetchDoctors.length === 1 && firstDoctorId && firstDoctorId !== request.requesting_doctor_id) {
                setField({ property: 'requesting_doctor_id', value: data.fetchDoctors[0]?.id ?? null });
            }
        },
    });

    const doctorOptions = React.useMemo(() => {
        const doctors = doctorsData?.fetchDoctors ?? [];
        return doctors.map(d => ({ label: d.name, value: d.id }));
    }, [doctorsData]);

    return (
        <Grid item xs={12} className={classes.formFieldRow}>
            <Text variant={'h5'} className={classes.formFieldHeader}>
                Which doctor is ordering the scan body?
            </Text>
            <Grid container className={classes.formFieldContent}>
                <LoadBlocker blocking={loading}>
                    <SimpleSelect
                        options={doctorOptions}
                        onChange={value => setField({ property: 'requesting_doctor_id', value: value ?? null })}
                        value={request.requesting_doctor_id ?? ''}
                        label={'Doctor'}
                    />
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

type ScanbodyRequestFormSurgicalReportAvailable = 'UNSET' | 'YES' | 'NO';

const ScanbodyRequestFormSurgicalReportSection: React.FC = () => {
    const classes = useStyles();
    const { request } = useScanbodyRequestPropSelector(['request']);
    const setField = useScanbodyRequestAction('SET_REQUEST_FIELD');
    // User must decide whether to attach a surgical report before they can request scan body
    const setDeclinedSurgicalReport = useScanbodyRequestAction('SET_DECLINED_SURGICAL_REPORT');
    const session = useScanbodySurgicalReportUploaderSession();

    const setFieldRef = React.useRef(setField);
    React.useEffect(() => {
        setFieldRef.current({ property: 'surgical_report_file', value: session.metadata?.fullPath ?? null });
    }, [session.metadata?.fullPath]);

    const [available, setAvailable] = React.useState<ScanbodyRequestFormSurgicalReportAvailable>('UNSET');
    return (
        <Grid item xs={12} className={classes.formFieldRow}>
            <Text variant={'h5'} className={classes.formFieldHeader}>
                Do you have a surgical report available?
            </Text>
            <Grid container className={classes.formFieldContent}>
                <Grid item container style={{ marginBottom: 24 }}>
                    <Button
                        variant={available === 'YES' ? 'primary' : 'secondary'}
                        className={classes.formInlineElement}
                        onClick={() => {
                            setAvailable('YES');
                            setDeclinedSurgicalReport(false);
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        variant={available === 'NO' ? 'primary' : 'secondary'}
                        className={classes.formInlineElement}
                        onClick={() => {
                            setAvailable('NO');
                            setDeclinedSurgicalReport(true);
                        }}
                    >
                        No
                    </Button>
                </Grid>
                <Collapse in={available === 'YES'} style={{ width: '100%' }}>
                    {/* will force rerender after current stage 
                        should refactor into a controlled component but will break too many other things */}
                    <ScanbodySurgicalReportUploader session={session} />
                </Collapse>
                <Collapse in={available === 'NO'} style={{ width: '100%' }}>
                    <ScanbodyRequestFormInlineField title={'Manufacturer: '} content={request.manufacturer} />
                    <ScanbodyRequestFormInlineField title={'System: '} content={request.system} />
                    <ScanbodyRequestFormInlineField title={'Platform Size: '} content={request.connection} />
                </Collapse>
            </Grid>
        </Grid>
    );
};

const ScanbodyRequestFormSubmitSection: React.FC = () => {
    const classes = useStyles();
    const practiceId = useSession()?.organization_id;
    const request = useScanbodyRequestSelector(s => s.request);
    const setStage = useScanbodyRequestAction('SET_STAGE');
    const requestStage = useScanbodyRequestStageSelector();
    const isMobile = useScreenIsMobile();

    const { refetch: refetchInventory } = useGetPracticeScanbodyInventoryQuery();
    const [submitMutation] = useBulkCreateScanbodyRequestsMutation();
    const { submit, submitting } = useChangeSubmissionFn<any, any>(variables => submitMutation({ variables }), {
        closeOnComplete: true,
        onSuccess: async () => {
            setStage(ScanbodyStage.SUBMITTED);
            await refetchInventory();
        },
    });
    return (
        <Grid item xs={12} className={classes.formFieldRow}>
            <LoadBlocker blocking={submitting}>
                <Button
                    variant={'primary'}
                    onClick={() => submit({ args: { scanbody_requests: request, practice_id: practiceId } })}
                    disabled={requestStage !== ScanbodyRequestStage.COMPLETE}
                    fullWidth={isMobile}
                >
                    Request Scan body
                </Button>
            </LoadBlocker>
        </Grid>
    );
};

export const ScanbodyRequestForm: React.FC = () => {
    const classes = useStyles();
    return (
        <Grid container className={classes.formContainer}>
            <ScanbodyRequestFormImplantSystemSection />
            <ScanbodyRequestFormAppointmentDateSection />
            <ScanbodyRequestFormAddressSection />
            <ScanbodyRequestFormDoctorSection />
            <ScanbodyRequestFormSurgicalReportSection />
            <ScanbodyRequestFormSubmitSection />
        </Grid>
    );
};
