import { PrintOrderAction } from '../../actions/PrintOrderAction';
import { useOrderDetailSection } from '../OrderDetailSectionProvider';
import { OrderDetailSubtitle } from './OrderDetailSubtitle';
import { OrderDetailTabBar } from './OrderDetailTabBar';
import { TopbarCaptions } from './TopBar/TopbarCaptions';
import { TopbarTitle } from './TopBar/TopbarTitle';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useOrderRefetch } from '@orthly/graphql-react';
import { ToolbarContainer, FlossPalette, useScreenIsMd } from '@orthly/ui';
import { Button, Grid, styled, useScreenIsSm } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ToolbarContainerRoot = styled(ToolbarContainer)(({ theme }) => ({
    height: 'fit-content',
    minHeight: 'fit-content',
    '&&': {
        height: 'fit-content',
        minHeight: 'fit-content',
        padding: 0,
        borderBottom: 'none',
        [theme.breakpoints.down('lg')]: {
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        },
    },
}));
const TopBarContainer = styled('div')(({ theme }) => ({
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    gap: '32px',
    padding: '16px 24px',
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    [theme.breakpoints.down('lg')]: {
        border: 'none',
    },
}));
const LeftSideContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
});
const TitleGroupContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});
const MainTitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    flexDirection: 'row',
    flexGrow: 1,
    minWidth: 0,
});

const RightSideContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
const OrderDetailsGroup = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
    gap: '32px',
});
const ActionItemsGroup = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
const BackButton = styled(Button)({
    height: 'unset',
    padding: '12px 2px',
});
const StickyTabBarContainer = styled(Grid)({
    position: 'sticky',
    top: 0,
    height: 'auto',
    zIndex: 2,
});

// Mobile styles
const TopBarMobileContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    minHeight: 'fit-content',
    gap: '16px',
    padding: '12px 24px',
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
});
const FirstRowContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
});
const MobileTitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '8px',
});
const SecondRowContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    overflow: 'hidden',
});

const StickyTabBar = React.forwardRef<HTMLDivElement, { order: LabsGqlSingleLabOrderFragment }>(({ order }, ref) => {
    return (
        <StickyTabBarContainer item ref={ref}>
            <OrderDetailTabBar order={order} />
        </StickyTabBarContainer>
    );
});

interface OrderDetailTopBarSummaryProps {
    order: LabsGqlSingleLabOrderFragment;
}

export const OrderDetailTopBarV2: React.VFC<OrderDetailTopBarSummaryProps> = ({ order }) => {
    const refetchOrder = useOrderRefetch();
    const history = useHistory();
    const screenIsSm = useScreenIsSm();
    const screenIsMd = useScreenIsMd();

    const { topBarRef } = useOrderDetailSection();

    if (screenIsSm) {
        return (
            <>
                <TopBarMobileContainer>
                    <FirstRowContainer>
                        <MobileTitleContainer>
                            <TopbarTitle order={order} />
                        </MobileTitleContainer>
                        <OrderDetailSubtitle order={order} />
                    </FirstRowContainer>
                    <SecondRowContainer>
                        <TopbarCaptions
                            order={order}
                            refetch={async () => {
                                refetchOrder(order.id);
                            }}
                            showCopyToClipboard
                        />
                    </SecondRowContainer>
                </TopBarMobileContainer>

                <StickyTabBar order={order} ref={topBarRef} />
            </>
        );
    }

    return (
        <>
            <ToolbarContainerRoot
                sticky={!screenIsMd}
                dataPendoId={'order-detail-topbar'}
                ref={!screenIsMd ? topBarRef : undefined}
            >
                <TopBarContainer>
                    <LeftSideContainer>
                        {!screenIsMd && (
                            <BackButton
                                variant={'secondary-gray'}
                                startIcon={'ChevronLeft'}
                                onClick={() => history.goBack()}
                            >
                                Back
                            </BackButton>
                        )}
                        <TitleGroupContainer>
                            <MainTitleContainer>
                                <TopbarTitle order={order} />
                            </MainTitleContainer>
                            <OrderDetailSubtitle order={order} />
                        </TitleGroupContainer>
                    </LeftSideContainer>
                    <RightSideContainer>
                        <OrderDetailsGroup>
                            <TopbarCaptions
                                order={order}
                                refetch={async () => {
                                    refetchOrder(order.id);
                                }}
                            />
                        </OrderDetailsGroup>
                        <ActionItemsGroup>
                            <SimpleCopyToClipboard
                                isIconOnly
                                variant={'ghost'}
                                tooltip={'Copy order ID'}
                                value={order?.order_number ?? ''}
                            />
                            <PrintOrderAction order={order} refetch={() => refetchOrder(order.id)} isIconOnly />
                        </ActionItemsGroup>
                    </RightSideContainer>
                </TopBarContainer>

                {!screenIsMd && <OrderDetailTabBar order={order} />}
            </ToolbarContainerRoot>

            {screenIsMd && <StickyTabBar order={order} ref={topBarRef} />}
        </>
    );
};
