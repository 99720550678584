import { useSelectStaffAction } from '../../state/select-staff.actions';
import { useSelectStaffSelector } from '../../state/select-staff.selectors';
import { StaffProfilePhotoSelection } from './SelectStaffAddPhoto.types';
import {
    cropWebcamCaptureFocusFrame,
    WebcamCaptureCountdown,
    WebcamCaptureDone,
    WebcamCaptureInstruction,
    WebcamCapturePermission,
} from '@orthly/dentin';
import { LabsGqlDoctorProfilePhotoColor, LabsGqlDoctorProfilePhotoType } from '@orthly/graphql-schema';
import { FlossPalette, stylesFactory, Grid, Button, Text } from '@orthly/ui-primitives';
import { DoctorProfilePhotoContent } from '@orthly/veneer';
import Avatar1 from '@orthly/veneer/assets/images/shared-profile-avatar/1.svg';
import Avatar2 from '@orthly/veneer/assets/images/shared-profile-avatar/2.svg';
import Avatar3 from '@orthly/veneer/assets/images/shared-profile-avatar/3.svg';
import Avatar4 from '@orthly/veneer/assets/images/shared-profile-avatar/4.svg';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

const useStyles = stylesFactory(theme => ({
    flex: {
        flexWrap: 'nowrap',
        gap: '24px',
        alignItems: 'center',
        justifyContent: 'stretch',
        flexGrow: 1,
        marginBottom: 100,
        flexDirection: 'row',
        [theme.breakpoints.down('lg')]: {
            marginBottom: 'initial',
            flexDirection: 'column',
        },
        '&>*:first-child': {
            zIndex: 1,
        },
    },
    cameraOverlay: {
        alignSelf: 'stretch',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    cameraEmpty: {
        borderRadius: 16,
        backgroundColor: '#F4F3F0',
        border: `8px solid ${FlossPalette.WHITE}`,
        padding: 24,
    },
}));

enum CameraStep {
    Instruction = 'Instruction',
    Countdown = 'Countdown',
    Done = 'Done',
}

const SelectStaffWebcamContent: React.VFC = () => {
    const profile_photo = useSelectStaffSelector(({ profileCreationState: { profile_photo } }) => profile_photo);
    const addCreationField = useSelectStaffAction('ADD_STAFF_CREATION_FIELD');
    const nextStep = useSelectStaffAction('NEXT_SELECT_STAFF_STEP');
    const mediaStreamRequest = useAsyncCallback(() =>
        navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } }),
    );
    const [step, setStep] = React.useState(CameraStep.Instruction);
    const [photoBlob, setPhotoBlob] = React.useState<Blob>();
    React.useEffect(() => {
        // close webcam when component unmounts
        return () => mediaStreamRequest.result?.getVideoTracks()?.forEach(track => track.stop());
    }, [mediaStreamRequest.result]);
    if (step === CameraStep.Instruction) {
        return (
            <WebcamCaptureInstruction
                onNext={() => {
                    // needs to be inside a user event
                    void mediaStreamRequest.execute();
                    setStep(CameraStep.Countdown);
                }}
                onUpload={photoBlob => {
                    setPhotoBlob(photoBlob);
                    setStep(CameraStep.Done);
                }}
                existingPreview={
                    profile_photo.type === LabsGqlDoctorProfilePhotoType.Uploaded &&
                    profile_photo.source && (
                        <>
                            <DoctorProfilePhotoContent
                                type={profile_photo.type}
                                source={profile_photo.source}
                                color={profile_photo.color}
                                style={{ width: 128 }}
                                sourceCanbeBlob
                            />
                            <Button variant={'primary'} onClick={nextStep} style={{ marginTop: 16, marginBottom: 40 }}>
                                Use existing photo
                            </Button>
                        </>
                    )
                }
            />
        );
    }
    if (step === CameraStep.Done && photoBlob) {
        return (
            <WebcamCaptureDone
                photoBlob={photoBlob}
                onConfirm={() => {
                    cropWebcamCaptureFocusFrame(photoBlob, croppedFile => {
                        /**
                         * here we use a blob url instead of a firebase path as a local preview.
                         * the actual photo will be uploaded after we create and get the staff id.
                         * @see `useProfilePhotoDeferedBlobUpload`
                         */
                        const url = URL.createObjectURL(croppedFile);
                        addCreationField(
                            {
                                profile_photo: {
                                    ...profile_photo,
                                    type: LabsGqlDoctorProfilePhotoType.Uploaded,
                                    source: url,
                                },
                            },
                            { autoNextStep: true },
                        );
                    });
                }}
                onRetake={() => {
                    setPhotoBlob(undefined);
                    setStep(CameraStep.Countdown);
                }}
            />
        );
    }
    if (!mediaStreamRequest.result) {
        return (
            <WebcamCapturePermission
                onCancel={() => setStep(CameraStep.Instruction)}
                mediaStreamRequest={mediaStreamRequest}
            />
        );
    }
    if (step === CameraStep.Countdown) {
        return (
            <WebcamCaptureCountdown
                srcObject={mediaStreamRequest.result}
                onCaptured={photoBlob => {
                    setPhotoBlob(photoBlob);
                    setStep(CameraStep.Done);
                }}
                onCancel={() => setStep(CameraStep.Instruction)}
                facingUser
            />
        );
    }
    return null;
};

const AvatarList: { source: string; color: LabsGqlDoctorProfilePhotoColor }[] = [
    { source: Avatar1, color: LabsGqlDoctorProfilePhotoColor.LightGreen },
    { source: Avatar2, color: LabsGqlDoctorProfilePhotoColor.Purple },
    { source: Avatar3, color: LabsGqlDoctorProfilePhotoColor.DarkBlue },
    { source: Avatar4, color: LabsGqlDoctorProfilePhotoColor.Black },
];
const SelectAvatarCallToAction: React.VFC<
    React.ComponentProps<typeof Grid> & { setSelection: (variant: StaffProfilePhotoSelection) => void }
> = ({ setSelection, ...props }) => {
    return (
        <Grid container direction={'column'} wrap={'nowrap'} alignItems={'flex-start'} {...props}>
            <Grid container direction={'row'} wrap={'nowrap'} style={{ gap: 8 }}>
                {AvatarList.map(({ source, color }) => (
                    <DoctorProfilePhotoContent
                        key={source}
                        type={LabsGqlDoctorProfilePhotoType.Avatar}
                        source={source}
                        color={color}
                        style={{ width: 24, borderRadius: '50%', fontSize: 8 }}
                    />
                ))}
                <DoctorProfilePhotoContent
                    type={LabsGqlDoctorProfilePhotoType.None}
                    source={'T'}
                    color={LabsGqlDoctorProfilePhotoColor.Yellow}
                    style={{ width: 24, borderRadius: '50%', fontSize: 8 }}
                />
            </Grid>
            <Text variant={'body2'} color={'DARK_GRAY'}>
                Or pick an avatar from our avatar library
            </Text>
            <Button variant={'secondary'} onClick={() => setSelection(StaffProfilePhotoSelection.SelectAvatar)}>
                Use an avatar instead
            </Button>
        </Grid>
    );
};

export const SelectStaffWebcam: React.VFC<{
    setSelection: (variant: StaffProfilePhotoSelection) => void;
}> = ({ setSelection }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.flex} style={{ marginTop: 32 }}>
            <SelectStaffWebcamContent />
            <SelectAvatarCallToAction setSelection={setSelection} style={{ width: 180, flexShrink: 0 }} />
        </Grid>
    );
};
