import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import { useInvoiceDetailStatus } from '../../invoicing.utils';
import { PaymentAction } from '../../payments/components/PaymentAction.graphql';
import { LabsGqlPaymentStatus } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { Button, FlossPalette, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const StyledContainer = styled('div')({
    background: FlossPalette.TAN,
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    display: 'flex',
    width: '100%',
    padding: '8px 32px',
    '@media print': {
        display: 'none',
    },
    '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
    },
});

const OverviewItemContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '@media screen and (max-width: 768px)': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '8px',
    },
});

const FlexGrowDiv = styled('div')({
    flexGrow: 1,
});

const StyledButton = styled(Button)({
    width: '100%',
});

export const InvoiceDetailOverviewContainer: React.FC = () => {
    const { invoice, autochargeEnabled, refetchInvoice } = useInvoiceDetailContext();
    const status = useInvoiceDetailStatus(invoice, autochargeEnabled);

    const hasPendingPayment = invoice.payments.filter(p => p.status === LabsGqlPaymentStatus.Pending).length > 0;
    const showPaymentButton = invoice.amount_remaining > 0 && !hasPendingPayment;

    return (
        <StyledContainer>
            <OverviewItemContainer>
                <Text variant={'body2'}>Date</Text>
                <Text variant={'body2'} medium>
                    {invoice.month_formatted}
                </Text>
            </OverviewItemContainer>

            <OverviewItemContainer>
                <Text variant={'body2'}>Status</Text>
                <Text variant={'body2'} medium>
                    {status}
                </Text>
            </OverviewItemContainer>

            <OverviewItemContainer>
                <Text variant={'body2'}>Total</Text>
                <Text variant={'body2'} medium>
                    {Format.currency(invoice.amount_due)}
                </Text>
            </OverviewItemContainer>

            {showPaymentButton && (
                <FlexGrowDiv>
                    <PaymentAction
                        invoiceId={invoice.id}
                        refetchInvoice={refetchInvoice}
                        CustomButton={({ onClick }) => (
                            <StyledButton variant={'primary'} onClick={onClick}>
                                Pay
                            </StyledButton>
                        )}
                    />
                </FlexGrowDiv>
            )}
        </StyledContainer>
    );
};
