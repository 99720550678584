import { SelectStaffContent, SelectStaffFloatButton } from '../../components';
import { useSelectStaffAction } from '../../state/select-staff.actions';
import { CreateStaffStep } from '../../state/select-staff.progression';
import { useSelectStaffSelector } from '../../state/select-staff.selectors';
import { StaffProfilePhotoSelection } from './SelectStaffAddPhoto.types';
import { SelectStaffAddPhotoSelectAvatar } from './SelectStaffSelectAvatar';
import { SelectStaffWebcam } from './SelectStaffWebcam';
import { stylesFactory, Text } from '@orthly/ui-primitives';
import React from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

const useStyles = stylesFactory(() => ({
    fade: {
        '&-enter': {
            opacity: 0,
            transform: 'translateY(100%)',
        },
        '&-enter-active, &-exit': {
            opacity: 1,
            transform: 'translateY(0)',
        },
        '&-exit-active': {
            opacity: 0,
            transform: 'translateY(100%)',
        },
        '&-enter-active, &-exit-active': {
            transition: 'transform 500ms ease-in-out, opacity 200ms ease-in-out',
        },
    },
}));

const SelectStaffImage: React.VFC<{
    selection: StaffProfilePhotoSelection;
    setSelection: React.Dispatch<React.SetStateAction<StaffProfilePhotoSelection>>;
}> = props => {
    switch (props.selection) {
        case StaffProfilePhotoSelection.SelectAvatar:
            return <SelectStaffAddPhotoSelectAvatar setSelection={props.setSelection} />;
        case StaffProfilePhotoSelection.Webcam:
            return <SelectStaffWebcam setSelection={props.setSelection} />;
        default:
            return null;
    }
};

export const SelectStaffAddPhoto: React.VFC = () => {
    const classes = useStyles();
    const [selection, setSelection] = React.useState(StaffProfilePhotoSelection.Webcam);
    const prevStep = useSelectStaffAction('PREV_SELECT_STAFF_STEP');
    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.Photo);
    return (
        <SelectStaffContent show={show}>
            <SelectStaffFloatButton onClick={prevStep}>Return to responsibility</SelectStaffFloatButton>
            <Text variant={'h4'} medium>
                Add a smile to your profile
            </Text>
            <SwitchTransition mode={'out-in'}>
                <CSSTransition key={selection} classNames={classes.fade} timeout={500}>
                    <SelectStaffImage selection={selection} setSelection={setSelection} />
                </CSSTransition>
            </SwitchTransition>
        </SelectStaffContent>
    );
};
