import { useDandyBillingAddress } from '../../../../utils/useDandyBillingAddress';
import { Text, styled } from '@orthly/ui-primitives';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const BoldText = styled(Text)({
    display: 'block',
    fontWeight: 600,
    marginBottom: '8px',
});

interface PrintableInvoiceAddressContainerProps {
    organizationName?: string;
    billingEmail?: string;
}

export const PrintableInvoiceAddressContainer: React.FC<PrintableInvoiceAddressContainerProps> = ({
    organizationName,
    billingEmail,
}) => {
    const dandyBillingAddress = useDandyBillingAddress();

    return (
        <Container>
            <div style={{ marginRight: '64px' }}>
                <BoldText variant={'caption'} medium>
                    {dandyBillingAddress.name}
                </BoldText>
                <Text variant={'caption'} color={'GRAY'}>
                    {dandyBillingAddress.street_one}
                    <br />
                    {dandyBillingAddress.city}, {dandyBillingAddress.state} {dandyBillingAddress.postal_code}
                    <br />
                    cxbilling@meetdandy.com
                </Text>
            </div>
            <div>
                {organizationName && (
                    <>
                        <BoldText variant={'caption'}>Bill to</BoldText>
                        <Text variant={'caption'} color={'GRAY'}>
                            {organizationName}
                            <br />
                            {billingEmail ?? ''}
                        </Text>
                    </>
                )}
            </div>
        </Container>
    );
};
