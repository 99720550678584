import type { CheckboxProps } from './Checkbox';
import { fieldToCheckbox } from './Checkbox';
import { CheckboxPrimitive as MuiCheckbox, FormControlLabel } from '@orthly/ui-primitives';
import type { FormControlLabelProps as MuiFormControlLabelProps } from '@orthly/ui-primitives';
import type { FieldProps } from 'formik';
import React from 'react';

/**
 * Exclude props that are passed directly to the control
 * https://github.com/mui-org/material-ui/blob/v3.1.1/packages/material-ui/src/FormControlLabel/FormControlLabel.js#L71
 */
export interface CheckboxWithLabelProps extends FieldProps, CheckboxProps {
    Label: Omit<MuiFormControlLabelProps, 'checked' | 'name' | 'value' | 'control'>;
}

export function CheckboxWithLabel({ Label, ...props }: CheckboxWithLabelProps) {
    return <FormControlLabel control={<MuiCheckbox {...fieldToCheckbox(props)} />} {...Label} />;
}

CheckboxWithLabel.displayName = 'FormikMaterialUICheckboxWithLabel';
