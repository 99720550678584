import { getPartnerId } from '../../../../utils/authorization';
import { PracticeUtils } from '../../../../utils/utils';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, apolloErrorMessage } from '@orthly/ui';
import { FlossPalette, Grid, Alert, styled } from '@orthly/ui-primitives';
import React from 'react';

const CardFormContainer = styled('div')({
    borderRadius: '8px',
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    backgroundColor: FlossPalette.TAN,
    marginBottom: '32px',
    color: FlossPalette.GRAY,
    width: '100%',
    padding: '16px',
});

type AddCardFormProps = {
    createCard: (props: { partnerId: string; cardToken: string }) => Promise<void>;
    onComplete?: (status: { success: boolean }, paymentMethodId?: string) => void;
    onRender?: (props: { submit: () => void; disabled: boolean }) => void;
};

export const AddCardForm = ({ createCard, onComplete, onRender }: AddCardFormProps) => {
    const session = useSession();
    const partnerId = getPartnerId(session);
    const GlobalStripe: typeof window.Stripe = window.Stripe;
    const stripe = React.useMemo(() => GlobalStripe(PracticeUtils.getStripeKey()), [GlobalStripe]);
    const elements = React.useMemo(() => stripe.elements(), [stripe]);
    const cardElt = React.useMemo(() => elements.create('card'), [elements]);
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const [canContinue, setCanContinue] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();

    React.useEffect(() => {
        const { current: container } = containerRef;
        if (container) {
            cardElt.mount(container);
            cardElt.on('change', (evt: any) => {
                setCanContinue(!!evt?.complete);
            });
            return () => cardElt.unmount();
        }
    }, [cardElt]);

    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const submit = React.useCallback(async () => {
        if (!partnerId) {
            return;
        }
        setIsSubmitting(true);
        try {
            const response = await stripe.createToken(cardElt);
            if (response.token) {
                await createCard({ partnerId, cardToken: response.token.id });
                cardElt.clear();
                onComplete?.({ success: true }, response.token.card?.id);
            } else {
                throw new Error(response?.error?.message || 'unknown error');
            }
        } catch (e: any) {
            setErrorMessage(apolloErrorMessage(e));
            onComplete?.({ success: false });
        }
        setIsSubmitting(false);
    }, [cardElt, createCard, onComplete, partnerId, stripe]);

    React.useEffect(() => {
        if (onRender) {
            onRender({ submit, disabled: !canContinue });
        }
    }, [onRender, submit, canContinue]);

    if (!partnerId) {
        return null;
    }

    return (
        <LoadBlocker blocking={isSubmitting}>
            {!!errorMessage && <Alert severity={'error'}>{errorMessage}</Alert>}
            <Grid container>
                <CardFormContainer ref={containerRef} />
            </Grid>
        </LoadBlocker>
    );
};
