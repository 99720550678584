import { LabeledTextField } from '../../components/labeled-field/LabeledFields';
import { PageLayout } from '../../components/page-layout/PageLayout';
import { useRecoverPasswordValidation } from './useRecoverPasswordValidation';
import { useSendResetPasswordEmail } from './useSendResetPasswordEmail.graphql';
import { Button, FormHelperText } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const RecoverPassword: React.FC = () => {
    const history = useHistory();
    const [email, setEmail] = React.useState('');

    const { isFormValid, emailError, setShowEmailError } = useRecoverPasswordValidation({ email });
    const { submit, submitting, error: formError } = useSendResetPasswordEmail();
    const shouldContinue = !submitting && isFormValid;

    return (
        <PageLayout
            pageMessage={'Reset'}
            panelTitle={'Reset your password'}
            panelDescription={`Input your email address, and we'll send you instructions on how to reset our password.`}
            onFormSubmit={e => {
                e.preventDefault();
                void submit({ email });
            }}
            onBack={() => history.push('/login')}
        >
            <LabeledTextField
                isRequired
                label={'Email'}
                placeholder={'Enter your email'}
                value={email}
                onChange={(next = '') => {
                    setEmail(next);
                }}
                TextFieldProps={{
                    type: 'email',
                    autoFocus: true,
                    autoComplete: 'email',
                    onBlur: () => {
                        setShowEmailError(email !== '');
                    },
                    error: !!emailError,
                    helperText: emailError,
                }}
            />
            {formError && <FormHelperText error>{formError}</FormHelperText>}
            <Button variant={'primary'} fullWidth type={'submit'} disabled={!shouldContinue}>
                Reset password
            </Button>
        </PageLayout>
    );
};
