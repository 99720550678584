import { PracticeSettingsBaseContent } from '../account/components/PracticeSettings/PracticeSettingsBaseContent';
import { PracticeSettingsTitle } from '../account/components/PracticeSettings/PracticeSettingsBaseTitle';
import { ProfileSectionAlert } from '../account/components/ProfileSectionAlert';
import { SettingsSection } from '../account/components/SettingsSection';
import { PaymentMethodsTitleAction } from '../account/practice-settings/payment/PaymentMethodsTitleAction';
import { usePaymentMethodControls } from '../account/practice-settings/payment/usePaymentMethodControls';
import { useSession } from '@orthly/session-client';
import { FlossPalette, styled } from '@orthly/ui-primitives';
import { DeliveryAddresses, EditDeliveryAddressModal, PaymentMethods } from '@orthly/veneer';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    background: FlossPalette.WHITE,
    overflow: 'auto',
    minHeight: 0,
    width: '100%',
});

interface BillingAndDeliveryStepProps {
    onPaymentMethodSuccess(): void;
    onDeliveryAddressSuccess(): void;
}

export const BillingAndDeliveryStep: React.VFC<BillingAndDeliveryStepProps> = ({
    onPaymentMethodSuccess,
    onDeliveryAddressSuccess,
}) => {
    const [open, setOpen] = React.useState(false);

    const partnerId = useSession()?.organization_id;
    const { methods: loadedMethods, loading, removeMethod, setDefaultMethod } = usePaymentMethodControls();

    return (
        <Container>
            <PracticeSettingsTitle title={'Billing & delivery info'} style={{ marginTop: 0 }} />
            <PracticeSettingsBaseContent
                Content={
                    <>
                        <SettingsSection
                            title={'Payment methods'}
                            subtitle={'Used to pay your invoices'}
                            longSection
                            action={<PaymentMethodsTitleAction onSuccess={onPaymentMethodSuccess} />}
                        >
                            {!loading && loadedMethods?.length === 0 && (
                                <ProfileSectionAlert
                                    message={"You haven't added a payment method yet!"}
                                    severity={'error'}
                                />
                            )}
                            <PaymentMethods
                                partnerId={partnerId ?? ''}
                                loadedMethods={loadedMethods}
                                removeMethod={removeMethod}
                                setDefaultMethod={setDefaultMethod}
                            />
                        </SettingsSection>

                        <SettingsSection
                            title={'Delivery address'}
                            subtitle={'These addresses are available during checkout'}
                            longSection
                            action={
                                <EditDeliveryAddressModal
                                    open={open}
                                    setOpen={setOpen}
                                    partnerId={partnerId ?? ''}
                                    buttonVariant={'ghost'}
                                    buttonStyles={{ padding: 0, height: 'unset', marginTop: 4 }}
                                    onSuccess={onDeliveryAddressSuccess}
                                />
                            }
                        >
                            <DeliveryAddresses partnerId={partnerId ?? ''} />
                        </SettingsSection>
                    </>
                }
            />
        </Container>
    );
};
