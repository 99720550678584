import type { FeedbackItemReasonCode, FeedbackScreen } from './feedback.reducer';
import { createSyncAction } from '@orthly/redux-async-actions';

const PREFIX = 'feedback';

export const FeedbackActions = {
    START_NEW: createSyncAction<void>(`${PREFIX}/START_NEW`),

    SET_ITEM_NOTES: createSyncAction<{ itemId: string; notes: string }>(`${PREFIX}/SET_ITEM_NOTES`),

    GO_TO: createSyncAction<FeedbackScreen | undefined>(`${PREFIX}/GO_TO`),

    GO_BACK: (current_screen: Extract<FeedbackScreen, { previous_screen?: FeedbackScreen }>) =>
        FeedbackActions.GO_TO(current_screen.previous_screen),

    SET_DOCTOR_IS_HAPPY: createSyncAction<boolean>(`${PREFIX}/SET_DOCTOR_IS_HAPPY`),

    TOGGLE_FULLSCREEN_SIDEBAR: createSyncAction<boolean>(`${PREFIX}/TOGGLE_FULLSCREEN_SIDEBAR`),

    SET_ITEM_CHAIRSIDE_ADJUSTMENT_MINUTES: createSyncAction<{
        itemId: string;
        chairsideAdjustmentMinutes: number | null;
    }>(`${PREFIX}/SET_ITEM_CHAIRSIDE_ADJUSTMENT_MINUTES`),

    SET_ITEM_STAR_RATING: createSyncAction<{
        itemId: string;
        starRating: number | null;
    }>(`${PREFIX}/SET_ITEM_STAR_RATING`),

    SET_SINGLE_REASON_CODE: createSyncAction<{
        itemId: string;
        reasonCode: FeedbackItemReasonCode;
    }>(`${PREFIX}/SET_SINGLE_REASON_CODE`),

    UNSET_REASON_CODES: createSyncAction<{
        itemId: string;
        reasonCodeIds: string[];
    }>(`${PREFIX}/UNSET_REASON_CODES`),

    SET_OTHER_NOTES: createSyncAction<{
        itemId: string;
        reasonCode: FeedbackItemReasonCode;
        otherNotes: string;
    }>(`${PREFIX}/SET_OTHER_NOTES`),
};
