import { getFlagVariantValueChecker } from '../../../../utils/utils';
import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import DandyRecommended from '@orthly/dentin/assets/images/hd-skus/Dandy_Recommended_Upsell.png';

export const DandyUpsellTiersProgramInvited: InboxUpsellItemConfigProps = {
    assetName: '202403 Loyalty - Tiers - Invited',
    title: 'Introducing Dandy Rewards',
    subtitle: 'Every wellness scan, referral, and order counts towards your rewards!',
    ctaText: 'Enroll now',
    destination: {
        url: 'https://www.meetdandy.com/loyalty-points-tiers/?utm_source=portal&utm_medium=banner&utm_campaign=2024-loyalty-banner-invited',
    },
    imgSrc: DandyRecommended,
    imgStyle: { maxHeight: 100, bottom: 30, left: -16, position: 'relative' },
    skuType: 'loyalty',
    filterCriteria: null,
    featureFlagKey: 'upsellLoyaltyProgramMultivariant',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_tiers_invited'),
};

export const DandyUpsellTiersProgramEnrolled: InboxUpsellItemConfigProps = {
    assetName: '202403 Loyalty - Tiers - Enrolled',
    title: 'Earn more points for Dandy Rewards',
    subtitle: 'Every wellness scan, referral, and order counts towards your rewards!',
    ctaText: 'View Points',
    destination: {
        url: 'https://go.meetdandy.com/unlocklabcredit.html',
    },
    imgSrc: DandyRecommended,
    imgStyle: { maxHeight: 100, bottom: 30, left: -16, position: 'relative' },
    skuType: 'loyalty',
    filterCriteria: null,
    featureFlagKey: 'upsellLoyaltyProgramMultivariant',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_tiers_enrolled'),
};

export const DandyUpsellPointsProgramInvited: InboxUpsellItemConfigProps = {
    assetName: '202403 Loyalty - Points - Invited',
    title: 'Introducing Dandy Rewards',
    subtitle: 'Every wellness scan, referral, and order counts towards your rewards!',
    ctaText: 'Enroll now',
    destination: {
        url: 'https://www.meetdandy.com/loyalty-points/?utm_source=portal&utm_medium=banner&utm_campaign=2024-loyalty-banner-invited',
    },
    imgSrc: DandyRecommended,
    imgStyle: { maxHeight: 100, bottom: 30, left: -16, position: 'relative' },
    skuType: 'loyalty',
    filterCriteria: null,
    featureFlagKey: 'upsellLoyaltyProgramMultivariant',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_points_invited'),
};

export const DandyUpsellPointsProgramEnrolled: InboxUpsellItemConfigProps = {
    assetName: '202403 Loyalty - Points - Enrolled',
    title: 'Earn more points for Dandy Rewards',
    subtitle: 'Every wellness scan, referral, and order counts towards your rewards!',
    ctaText: 'View Points',
    destination: {
        url: 'https://go.meetdandy.com/unlocklabcredit.html ',
    },
    imgSrc: DandyRecommended,
    imgStyle: { maxHeight: 100, bottom: 30, left: -16, position: 'relative' },
    skuType: 'loyalty',
    filterCriteria: null,
    featureFlagKey: 'upsellLoyaltyProgramMultivariant',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_points_enrolled'),
};
