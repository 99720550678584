import {
    FlossPalette,
    stylesFactory,
    Grid,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Text,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type Row = { id: string } & Record<string, unknown>;

type Column<R extends Row> = {
    id: string;
    title: string;
    className?: string;
    render?: (data: R) => React.ReactNode;
    sortBy?: (data: R) => unknown;
};

type Props<R extends Row, C = Column<R>> = {
    canSort?: boolean;
    columns: [C, ...C[]];
    footnotes?: string[];
    id: string;
    rows: R[];
    title?: string;
};

const useStyles = stylesFactory(() => ({
    table: {
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    head: {
        backgroundColor: FlossPalette.TAN,
    },
    row: {
        '&:hover': {
            backgroundColor: FlossPalette.TAN,
        },
    },
    footer: {
        padding: '8px 16px',
    },
}));

export function PricingGuideTable<R extends Row>({ canSort, columns, footnotes, id, rows, title }: Props<R>) {
    const [sortConfig, setSortConfig] = React.useState<{ column: Column<R> | null; direction: 'asc' | 'desc' }>({
        column: null,
        direction: 'asc',
    });

    const sortedRows = React.useMemo(
        () =>
            _.orderBy(
                rows,
                (row, rowIndex) => {
                    if (!sortConfig.column) {
                        return rowIndex;
                    }

                    if (sortConfig.column.sortBy) {
                        return sortConfig.column.sortBy(row);
                    }

                    const value = row[sortConfig.column.id];

                    return typeof value === 'string' ? value.toLocaleLowerCase() : value;
                },
                sortConfig.direction,
            ),
        [rows, sortConfig],
    );

    const handleSortClick = (column: Column<R>) => {
        setSortConfig(current => ({
            direction: current.column === column && current.direction === 'asc' ? 'desc' : 'asc',
            column: current.column === column && current.direction === 'desc' ? null : column,
        }));
    };

    const classes = useStyles();

    return (
        <Grid container direction={'column'} style={{ gap: '24px' }}>
            {title && (
                <Grid item>
                    <Text variant={'h6'} data-test={`pricing-table-title-${id}`}>
                        {title}
                    </Text>
                </Grid>
            )}
            <Grid item>
                <TableContainer>
                    <Table className={classes.table}>
                        <TableHead className={classes.head} data-test={`pricing-table-head-${id}`}>
                            <TableRow>
                                {columns.map(column =>
                                    canSort !== false ? (
                                        <TableCell
                                            key={column.title}
                                            variant={'head'}
                                            onClick={() => handleSortClick(column)}
                                            className={column.className}
                                        >
                                            <TableSortLabel
                                                active={sortConfig.column?.id === column.id}
                                                direction={
                                                    sortConfig.column?.id === column.id ? sortConfig.direction : 'asc'
                                                }
                                            >
                                                {column.title}
                                            </TableSortLabel>
                                        </TableCell>
                                    ) : (
                                        <TableCell key={column.title} variant={'head'} className={column.className}>
                                            {column.title}
                                        </TableCell>
                                    ),
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody data-test={`pricing-table-body-${id}`}>
                            {sortedRows.map(row => (
                                <TableRow key={row.id} className={classes.row}>
                                    {columns.map(column => (
                                        <TableCell
                                            key={`${column.id}-${row.id}`}
                                            style={{ verticalAlign: 'top' }}
                                            className={column.className}
                                        >
                                            {column.render ? column.render(row) : row[column.id]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                            {footnotes?.map(footnote => (
                                <TableRow key={footnote} data-test={`pricing-table-footer-${id}`}>
                                    <TableCell colSpan={columns.length} className={classes.footer}>
                                        <Text variant={'caption'}>{footnote}</Text>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    );
}
