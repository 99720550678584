import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { ActionCard } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const OrderDetailAlignersAlert: React.FC<{
    patient_first_name: string;
    order: Pick<LabsGqlOrder, 'fulfillment_workflow'>;
    status: LabsGqlLabOrderStatus;
}> = ({ patient_first_name, order, status }) => {
    const activeTask = order.fulfillment_workflow.active_task;

    if (
        status === LabsGqlLabOrderStatus.Cancelled ||
        activeTask?.type !== LabsGqlWorkflowTaskType.ApproveAlignerFabrication
    ) {
        return null;
    }

    return (
        <ActionCard
            variant={`info`}
            title={`${patient_first_name.trim()}'s treatment plan is ready for review!`}
            subtitle={`Leave us a message if you'd like to make any modifications`}
            style={{ backgroundColor: FlossPalette.SECONDARY_BACKGROUND, margin: 12 }}
            avatarStyle={{ backgroundColor: FlossPalette.SECONDARY_FOREGROUND }}
        />
    );
};
