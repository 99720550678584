import { PracticeUtils } from './utils';
import React from 'react';

const FailedToLoadStripe = () => console.error('Stripe.js has not loaded yet! Something is likely wrong');

/**
 * Utility hook that returns an instance of Stripe.js
 */
export function useStripe() {
    const GlobalStripe: typeof window.Stripe = window.Stripe || FailedToLoadStripe;
    const stripe = React.useMemo(() => GlobalStripe(PracticeUtils.getStripeKey()), [GlobalStripe]);
    return stripe;
}
