import { useUploadOrderPhotosWithNotes } from '../../chat/ChatWindow/ChatThread/ChatThreadInput/util';
import { OrderAction, OrderActionTrackerIdMap } from '../actions/OrderActionsUtils';
import type { LabsGqlCreateChatMessageMutationVariables } from '@orthly/graphql-operations';
import { OrderingStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { DEFAULT_TOAST_TIMEOUT, OrthlyBrowserConfig, QuickForm, RootActionDialog, Toast } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import { Button, CheckIcon, Grid, useScreenIsMobile } from '@orthly/ui-primitives';
import { FileUploaderBulk } from '@orthly/veneer';
import React from 'react';

export type CreateChatMessageMutationVariables = Omit<
    LabsGqlCreateChatMessageMutationVariables['data'],
    'entity_type' | 'entity_id' | 'attachment_urls' | 'visible_to_org_ids' | 'visible_to_roles' | 'action_type'
>;

const getTitle = ({ addPhotoVariant }: { addPhotoVariant?: boolean }) => {
    if (addPhotoVariant) {
        return 'Attach photo';
    }
    return 'Leave a message for our team';
};

const getSubtitle = () => {
    return 'Your message will be shared with our order specialists. We’ll reach out to you if we have any questions.';
};

const getTextBoxTitle = ({ addPhotoVariant }: { addPhotoVariant?: boolean }) => {
    if (addPhotoVariant) {
        return 'Describe these photos';
    } else {
        return 'Your message';
    }
};

interface AddOrderNotesDialogProps {
    addPhotoVariant?: boolean;
    orderId: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    CustomButton: React.FC<ButtonProps> | undefined;
    submit: (vars: CreateChatMessageMutationVariables) => Promise<any>;
    submitting: boolean;
    setAttachmentUrls: (attachmentUrls: string[]) => void;
    toastOpen: boolean;
    setToastOpen: (toastOpen: boolean) => void;
}

export const AddOrderNotesDialog: React.FC<AddOrderNotesDialogProps> = ({
    addPhotoVariant,
    orderId,
    open,
    setOpen,
    CustomButton,
    submit,
    submitting,
    setAttachmentUrls,
    toastOpen,
    setToastOpen,
}) => {
    const isMobile = useScreenIsMobile();

    const [attachments, setAttachments] = React.useState<File[]>([]);
    const uploadPhotosWithNotesAndSaveToOrder = useUploadOrderPhotosWithNotes(orderId);

    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.attachments, orderId);
    const title = getTitle({ addPhotoVariant });
    const subtitle = getSubtitle();

    const customSubmit = React.useCallback(
        ({ triggerSubmit, disabled }) => (
            <Grid container direction={'row'} justifyContent={'flex-end'} style={{ marginTop: 40 }}>
                <Button onClick={() => setOpen(false)} variant={'secondary'} style={{ marginRight: 16 }}>
                    Close
                </Button>
                <Button disabled={disabled} onClick={triggerSubmit} variant={'primary'}>
                    {'Leave message'}
                </Button>
            </Grid>
        ),
        [setOpen],
    );

    return (
        <>
            <RootActionDialog
                loading={submitting}
                open={open}
                setOpen={setOpen}
                title={title}
                subtitle={subtitle}
                showCloseButton={true}
                content={
                    <Grid container data-test={'add-order-notes-dialog'}>
                        {addPhotoVariant && (
                            <FileUploaderBulk
                                autoSubmit
                                elevation={0}
                                storagePathConfig={storagePathConfig}
                                paperStyle={{ padding: '0 0 8px' }}
                                onComplete={results => {
                                    results && setAttachmentUrls(results.map(r => r.uploadedPath));
                                }}
                                onReset={() => {
                                    setAttachmentUrls([]);
                                    setAttachments([]);
                                }}
                                prependTimestampToFilename={true}
                                onGetInputFiles={files => setAttachments(files)}
                            />
                        )}
                        <Grid container>
                            <QuickForm<CreateChatMessageMutationVariables>
                                fields={{
                                    text: {
                                        type: 'text',
                                        label: getTextBoxTitle({ addPhotoVariant }),
                                        fieldProps: { multiline: true, minRows: 3, maxRows: 50 },
                                    },
                                }}
                                resetOnInitialValueChange={true}
                                initialValues={{}}
                                onSubmit={async result => {
                                    await submit({
                                        text: result.text,
                                    });
                                    await uploadPhotosWithNotesAndSaveToOrder(attachments, result.text);
                                    setAttachments([]);
                                }}
                                submitButtonProps={{ style: { marginTop: isMobile ? 24 : 40 } }}
                                submitButtonTitle={'Share note'}
                                CustomSubmit={customSubmit}
                            />
                        </Grid>
                    </Grid>
                }
                buttonText={addPhotoVariant ? 'Add Photo' : 'Add Order Note'}
                CustomButton={
                    CustomButton
                        ? CustomButton
                        : () => (
                              <Button
                                  data-tracker-id={
                                      OrderActionTrackerIdMap[
                                          addPhotoVariant ? OrderAction.AttachPhoto : OrderAction.LeaveNote
                                      ]
                                  }
                                  variant={'ghost'}
                                  onClick={() => setOpen(true)}
                                  startIcon={'ReprioritizeIcon'}
                                  style={{
                                      padding: 'auto',
                                      justifyContent: 'flex-start',
                                      height: 'unset',
                                      width: '100%',
                                  }}
                                  data-test={'open-button'}
                                  analytics={{
                                      AssetLocation: 'Practice - Control Panel - Action Taken',
                                      AssetName: addPhotoVariant ? 'Add Photo' : 'Leave Note',
                                  }}
                              >
                                  {title}
                              </Button>
                          )
                }
            />
            {!isMobile && (
                <Toast
                    headline={'Left Message'}
                    description={'Your message was successfully added to this order.'}
                    open={toastOpen}
                    onDismiss={() => setToastOpen(false)}
                    icon={CheckIcon}
                    variant={'success'}
                    timeout={DEFAULT_TOAST_TIMEOUT}
                />
            )}
        </>
    );
};
