import type { FieldDefBasic } from './QuickForm.types';
import { PhoneField } from './QuickForm.types';
import { QFAutoCompleteField } from './fields/QFAutoCompleteField';
import { QFBooleanField } from './fields/QFBooleanField';
import { QFCurrencyInputField } from './fields/QFCurrencyInputField';
import { QFCustomField } from './fields/QFCustomField';
import { QFDateField } from './fields/QFDateField';
import { QFInputField } from './fields/QFInputField';
import type { QFMaskedFieldPropType } from './fields/QFMaskedField';
import { QFMaskedField } from './fields/QFMaskedField';
import { QFPhoneField } from './fields/QFPhoneField';
import { QFRadioGroupField } from './fields/QFRadioGroupField';
import { QFSelectField, QFMultiSelectField } from './fields/QFSelectField';
import { Assert } from '@orthly/runtime-utils';
import React from 'react';

export function QuickFormField<R extends { [k: string]: any }>(field: FieldDefBasic<R> & { name: string }) {
    switch (field.type) {
        case 'autocomplete':
            return <QFAutoCompleteField field={field} />;
        case 'text':
            return field.mask ? (
                <QFMaskedField field={field as QFMaskedFieldPropType} />
            ) : (
                <QFInputField field={field} />
            );
        case 'number':
            return <QFInputField field={field} />;
        case 'currency':
            return <QFCurrencyInputField field={field} />;
        case PhoneField:
            return <QFPhoneField field={field} />;
        case 'select':
            return <QFSelectField field={field} />;
        case 'boolean':
            return <QFBooleanField field={field} />;
        case 'date':
        case 'time':
        case 'datetime':
            return <QFDateField field={field} />;
        case 'custom':
            return <QFCustomField field={field} />;
        case 'multiselect':
            return <QFMultiSelectField field={field} />;
        case 'radioGroup':
            return <QFRadioGroupField fieldDef={field} />;
    }

    Assert.unreachable(field);
}
