import type { TraineeAtom } from './hooks/useTrainees';

export const TrainingRoleOptions = ['Dentist', 'Dental Hygienist', 'Dental Assistant', 'Office Manager'] as const;

export const TrainingScanningExperienceOptions = [
    'No prior intraoral scanning experience',
    'Some intraoral scanning experience',
    'Regularly uses an intraoral scanner',
] as const;

export const TrainingScannerOptions = [
    '3Shape Trios 3',
    '3Shape Trios 4',
    '3Shape Trios 5',
    'CEREC Omniscan',
    'CEREC Primescan',
    'iTero',
    'Medit',
    'Other',
] as const;

export const MaterialPreferenceOptions = [
    'Zirconia monolithic',
    'Zirconia translucent',
    'PFM',
    'Gold',
    'Emax',
] as const;

export const RetractionPreferenceOptions = ['Cord', 'Paste', 'Laser', 'None'] as const;

export const PrepStylePreferenceOptions = [
    'Chamfer',
    'Shoulder',
    'Chamfer/Shoulder',
    'Knife edge',
    'Feather edge',
    'Bevel',
    'Beveled shoulder',
] as const;

export const WillAdaptPreferenceOptions = [
    'Yes',
    'No',
    'Maybe',
    'N/A - I already use a chamfer/shoulder style prep',
] as const;

export interface TraineeFormFieldProps {
    traineeAtom: TraineeAtom;
}

export interface TraineeFormFieldsProps extends TraineeFormFieldProps {
    listIndex: number;
}

export enum TrainingDialogStage {
    FIRST = 'first',
    SECOND = 'second',
}
