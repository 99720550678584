import type { ThunkActionState } from './common';
import { THUNK_ACTION_INIT_STATE, thunkActionsHandler } from './common';
import type { AnyAction, Reducer } from 'redux';
import type { Action } from 'redux-actions';
import { createAction } from 'redux-actions';

export type StateWithAsync = {
    async: ThunkActionState;
};

type ResetPayload = {
    // clear all action names if not set
    actionNames?: string[];
    // clear all types if not
    asyncType?: keyof ThunkActionState;
};
const RESET = 'redux-async-actions/RESET_ASYNC_STATE';
export const resetAsyncState = createAction<ResetPayload, ResetPayload>(RESET, payload => payload);

// typeguard for better inference
function isResetAction(a: Action<ResetPayload> | AnyAction): a is Action<ResetPayload> {
    return a.type === RESET;
}

const resetSubstate = <T extends { [k: string]: any }>(substate: T, actionNames?: string[]): T => {
    let newSubstate = { ...substate };
    if (actionNames) {
        actionNames.forEach(name => {
            delete newSubstate[name];
        });
    } else {
        newSubstate = {} as T;
    }
    return newSubstate;
};

const internalReducer: Reducer<ThunkActionState, Action<ResetPayload> | AnyAction> = (rawState, action) => {
    const state = rawState ? { ...rawState } : { ...THUNK_ACTION_INIT_STATE };
    if (isResetAction(action)) {
        const { actionNames, asyncType } = action.payload;
        // if asyncType is set we only reset for that key.
        // if actionName is set we only reset each type for that actionName
        // if both are set, we only reset that actionName for that asyncType
        return {
            errors: !asyncType || asyncType === 'errors' ? resetSubstate(state.errors, actionNames) : state.errors,
            succeeded:
                !asyncType || asyncType === 'succeeded' ? resetSubstate(state.succeeded, actionNames) : state.succeeded,
            loading: !asyncType || asyncType === 'loading' ? resetSubstate(state.loading, actionNames) : state.loading,
        };
    }
    return state;
};

export const createAsyncReducer = (clearOnPersist: boolean) =>
    thunkActionsHandler<ThunkActionState>(internalReducer, clearOnPersist) as Reducer<ThunkActionState, AnyAction>;
