import { SelectStaffContent, SelectStaffFloatButton } from '../components';
import { useSelectStaffAction } from '../state/select-staff.actions';
import { CreateStaffStep } from '../state/select-staff.progression';
import type { StaffProfileInfo } from '../state/select-staff.reducer';
import { selectCreateStaffProgression, useSelectStaffSelector } from '../state/select-staff.selectors';
import type { FetchResult } from '@apollo/client';
import type {
    LabsGqlCreateDoctorPreferencesMutation,
    LabsGqlCreateDoctorPreferencesMutationVariables,
} from '@orthly/graphql-operations';
import { useCreateDoctorPreferencesMutation, useSetDoctorProfilePhotoMutation } from '@orthly/graphql-react';
import type { LabsGqlDoctorProfilePhotoDto } from '@orthly/graphql-schema';
import { LabsGqlDoctorProfilePhotoType } from '@orthly/graphql-schema';
import { ValidationUtils } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import {
    FlossInputThemeProvider,
    LoadBlocker,
    OrthlyBrowserConfig,
    SimpleInput,
    SimplePhoneInput,
    useChangeSubmissionFn,
} from '@orthly/ui';
import { stylesFactory, useScreenIsMd, Grid, Button, Text } from '@orthly/ui-primitives';
import { isBlobURL, PersonCard, useFirebaseStorage } from '@orthly/veneer';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

const useStyles = stylesFactory(theme => ({
    flex: {
        flexWrap: 'nowrap',
        columnGap: '20%',
        rowGap: '32px',
        flexDirection: 'row',
    },
    nextSection: {
        flexWrap: 'nowrap',
        columnGap: '16px',
        flexDirection: 'row',
        alignItems: 'baseline',
        marginTop: 40,
        textAlign: 'start',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
            alignItems: 'stretch',
            marginTop: 12,
            textAlign: 'center',
        },
    },
}));

type PrefVars = LabsGqlCreateDoctorPreferencesMutationVariables['data'];
type PrefResult = FetchResult<LabsGqlCreateDoctorPreferencesMutation>;

const PROFILE_PHOTO_FILENAME = 'profile-photo.jpg';
async function uploadProfilePhoto(
    firebaseStorage: ReturnType<typeof useFirebaseStorage>,
    storagePathPrefix: string,
    id: string,
    profile_photo: LabsGqlDoctorProfilePhotoDto,
): Promise<LabsGqlDoctorProfilePhotoDto | undefined> {
    try {
        if (!profile_photo.source) {
            return;
        }
        const photoRes = await fetch(profile_photo.source);
        const photoBlob = await photoRes.blob();
        const { ref } = await firebaseStorage
            .ref(`${storagePathPrefix}/${id}`)
            .child(PROFILE_PHOTO_FILENAME)
            .put(photoBlob);
        return { ...profile_photo, source: ref.fullPath };
    } catch (error: any) {
        Sentry.captureException(error);
    }
}

/**
 * If the source is a local blob URL, we will first create the staff without profile photo.
 * Once we have the staff ID, we can upload the profile photo to the correct bucket.
 * @see `SelectStaffWebcam.tsx`
 */
function useProfilePhotoDeferedBlobUpload() {
    const profile_photo = useSelectStaffSelector(({ profileCreationState: { profile_photo } }) => profile_photo);
    const needsUpload =
        profile_photo.type === LabsGqlDoctorProfilePhotoType.Uploaded && isBlobURL(profile_photo.source);
    const profile_photo_on_creation = needsUpload
        ? { ...profile_photo, type: LabsGqlDoctorProfilePhotoType.None }
        : profile_photo;
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.doctorProfile);
    const firebaseStorage = useFirebaseStorage();

    const [doctorSubmitMtn] = useSetDoctorProfilePhotoMutation();
    const { execute: patchDoctorProfilePhoto } = useAsyncCallback(async ({ id }: { id: string }) => {
        if (!needsUpload) {
            return;
        }
        const profile_photo_finalized = await uploadProfilePhoto(
            firebaseStorage,
            storagePathConfig.path,
            id,
            profile_photo,
        );
        if (profile_photo_finalized) {
            await doctorSubmitMtn({ variables: { data: { doctor_id: id, data: profile_photo_finalized } } });
        }
    });

    return { profile_photo_on_creation, patchDoctorProfilePhoto };
}

function useOnNext() {
    const { name, occupation, contact_email, contact_phone } = useSelectStaffSelector(
        ({ profileCreationState }) => profileCreationState,
    );
    const { profile_photo_on_creation, patchDoctorProfilePhoto } = useProfilePhotoDeferedBlobUpload();
    const practiceId = useSession()?.organization_id;
    const addCreationField = useSelectStaffAction('ADD_STAFF_CREATION_FIELD');

    const [submitMtn] = useCreateDoctorPreferencesMutation();
    const mtnSubmitter = (vars: PrefVars) => submitMtn({ variables: { data: vars } });
    const { submit, submitting } = useChangeSubmissionFn<PrefResult, [any]>(mtnSubmitter, {
        onSuccess: data => {
            const result = data.data?.createDoctorPreferences;
            if (result) {
                const created_profile: StaffProfileInfo = {
                    __typename: result.__typename,
                    id: result.id,
                    contact_email: result.contact_email,
                    name: result.name,
                    roles: result.roles,
                    staff_member_id: result.staff_member_id,
                };
                void patchDoctorProfilePhoto(created_profile);
                addCreationField({ created_profile }, { autoNextStep: true });
            }
        },
        onError: e => console.error(e),
    });

    return {
        nextStep: () =>
            submit({
                name,
                contact_email,
                contact_phone,
                profile_photo: profile_photo_on_creation,
                contact_phone_call_number: contact_phone,
                partner_id: practiceId,
                role: occupation,
                roles: occupation ? [occupation] : null,
            }),
        loading: submitting,
    };
}

export const SelectStaffInputContact: React.VFC = () => {
    const classes = useStyles();
    const addCreationField = useSelectStaffAction('ADD_STAFF_CREATION_FIELD');
    const prevStep = useSelectStaffAction('PREV_SELECT_STAFF_STEP');
    const { nextStep, loading } = useOnNext();
    const { name, occupation, contact_email, contact_phone, profile_photo } = useSelectStaffSelector(
        ({ profileCreationState }) => profileCreationState,
    );
    const { error } = useSelectStaffSelector(selectCreateStaffProgression);
    const isMobile = useScreenIsMd();
    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.Contact);
    return (
        <SelectStaffContent show={show}>
            <SelectStaffFloatButton onClick={prevStep}>Return to photo</SelectStaffFloatButton>
            <Text variant={'h4'} medium>
                Let's put a name to that smile
            </Text>
            <Text variant={'body1'} color={'DARK_GRAY'}>
                This information is used for all Dandy communications, such as information about orders.
            </Text>
            <Grid container className={classes.flex} style={{ marginTop: 32 }}>
                <Grid container direction={'column'} wrap={'nowrap'} style={{ gap: 8, flexBasis: 1, flexGrow: 1 }}>
                    <FlossInputThemeProvider flossInputConfig={{ backgroundColor: 'white' }}>
                        <Text variant={'h6'}>Full name</Text>
                        <SimpleInput
                            value={name}
                            onChange={name => addCreationField({ name })}
                            placeholder={'John Appleseed'}
                            label={''}
                            TextFieldProps={{ style: { marginTop: 0 } }}
                        />
                        <Text variant={'h6'}>Email address</Text>
                        <SimpleInput
                            value={contact_email}
                            onChange={contact_email => addCreationField({ contact_email })}
                            placeholder={'your@email.com'}
                            label={''}
                            TextFieldProps={{
                                type: 'email',
                                error: !!contact_email && !ValidationUtils.isEmail(contact_email),
                                style: { marginTop: 0 },
                            }}
                        />
                        <Text variant={'h6'}>Mobile phone number</Text>
                        <SimplePhoneInput
                            value={contact_phone}
                            onChange={contact_phone => addCreationField({ contact_phone })}
                            placeholder={'(123) 456-7890'}
                            label={''}
                            TextFieldProps={{
                                type: 'tel',
                                error: !!contact_phone && !ValidationUtils.isPhone(contact_phone),
                                style: { marginTop: 0 },
                            }}
                        />
                    </FlossInputThemeProvider>
                </Grid>
                {!isMobile && (
                    <PersonCard
                        person={{ name, occupation, contact_email, contact_phone, profile_photo }}
                        profilePhotoSourceIsBlob
                        style={{ alignSelf: 'flex-start' }}
                    />
                )}
            </Grid>
            <Grid container className={classes.nextSection}>
                <LoadBlocker blocking={loading} ContainerProps={{ style: { width: 'unset' } }}>
                    <Button variant={'primary'} fullWidth disabled={!!error} onClick={nextStep}>
                        Continue
                    </Button>
                </LoadBlocker>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {error}
                </Text>
            </Grid>
        </SelectStaffContent>
    );
};
