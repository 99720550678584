import { useCurrentProfile } from '../../../redux';
import { useMutation, useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import React from 'react';

const ActivateStaff_Mutation = graphql(`
    mutation activateStaff($data: ActivateOrganizationStaffMemberCommand!) {
        activateOrganizationStaffMember(data: $data) {
            id
        }
    }
`);

const GetStaffMemberForActivation_Query = graphql(`
    query getStaffMemberForActivation($id: String!) {
        getStaffMemberById(id: $id) {
            activated_at
            organization_id
            user_id
        }
    }
`);

export const useActivateStaffOnLogin = () => {
    const profile = useCurrentProfile();
    const [submit] = useMutation(ActivateStaff_Mutation);
    const { data: { getStaffMemberById: staff } = {} } = useQuery(GetStaffMemberForActivation_Query, {
        variables: { id: profile?.staff_member_id ?? '' },
        skip: !profile?.staff_member_id,
    });

    React.useEffect(() => {
        if (staff && !staff.activated_at) {
            void submit({
                variables: {
                    data: {
                        organization_id: staff.organization_id,
                        user_id: staff.user_id,
                    },
                },
            });
        }
    }, [staff, submit]);
};
