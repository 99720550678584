import type { PartnerAppState } from '../../../redux/types';
import type { CheckoutStepCompletionState } from './checkout.selectors';
import type { CheckoutState, ImplantCheckoutState } from './checkout.state';
import type { CheckoutImplantToothGroup } from './reducers/implant-checkout-scanbodies.reducer';
import { currentImplantToothScanbodyStatus, implantToothGroups } from './reducers/implant-checkout-scanbodies.reducer';
import { generateUseStatePropSelector } from '@orthly/redux-async-actions';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';

// can't use generateUseSubstateSelector because we're double-nested
export const useImplantCheckoutSelector: TypedUseSelectorHook<ImplantCheckoutState> = <TSelected>(
    selector: (state: ImplantCheckoutState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => useSelector<PartnerAppState, TSelected>(s => selector(s.checkout.implantCheckout), equalityFn);

export const useImplantCheckoutState = () => useImplantCheckoutSelector(state => state);

export const useImplantCheckoutPropSelector = generateUseStatePropSelector(
    useImplantCheckoutSelector,
    'implantCheckout',
);

export function implantCheckoutScreenTitle(state: CheckoutState) {
    const screen = state.implantCheckout.screen;
    const currentTooth = implantCheckoutCurrentToothScanbody(state);

    if (screen === 'scanbodies' && currentTooth !== null) {
        return `Which scan body did you use for tooth ${currentTooth.unn}?`;
    }

    return 'We need a few more pieces of information for your implant';
}

export function implantCheckoutNextButtonEnabledState(
    state: CheckoutState,
): CheckoutStepCompletionState & { fieldsRemaining?: number } {
    const enabled: CheckoutStepCompletionState = { complete: true };

    const { implantCheckout } = state;

    if (implantCheckout.screen !== 'scanbodies') {
        return enabled;
    }

    const teeth = implantToothGroups(state);
    const currentScanbodyStatus = currentImplantToothScanbodyStatus(implantCheckout.scanbodiesScreen, teeth);

    if (currentScanbodyStatus.status !== 'Incomplete') {
        return enabled;
    }

    return {
        complete: false,
        fieldsRemaining: 1,
        reason: 'Please select an option',
    };
}

export function implantCheckoutCurrentToothScanbody(state: CheckoutState): CheckoutImplantToothGroup | null {
    const index = state.implantCheckout.scanbodiesScreen.currentToothIndex;

    const teeth = implantToothGroups(state);
    if (!teeth.length) {
        return null;
    }

    const tooth = teeth[index];
    if (!tooth) {
        console.warn(`invalid tooth index ${index} in ${teeth.length}`);
        return null;
    }

    return tooth;
}
