import { ImageDisplay } from '../../../chat/ChatWindow/ChatThread/ChatThreadInput/ChatInputPhotoUploader';
import { useUploadOrderPhotos } from '../../../chat/ChatWindow/ChatThread/ChatThreadInput/util';
import { OrderDetailMobileControlPanelBase } from './OrderDetailMobileControlPanelBase';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import type { LabsGqlCreateChatMessageMutationVariables } from '@orthly/graphql-operations';
import { useCreateChatMessageMutation } from '@orthly/graphql-react';
import {
    LabsGqlChatEntityTypes,
    LabsGqlControlPanelActionType,
    LabsGqlLabOrderPhotoType,
    LabsGqlStaffRoleWithAny,
} from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, stylesFactory, Button, ButtonGroup, Grid, ChevronRight, Text } from '@orthly/ui-primitives';
import type { UploadedFile } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

const useStyles = stylesFactory(() => ({
    fileType: {
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: 16,
        justifyContent: 'space-between',
        '&&': {
            width: '100%',
        },
        '&.MuiButtonGroup-grouped:hover': {
            borderColor: FlossPalette.DARK_TAN,
        },
    },
}));

type Vars = Omit<
    LabsGqlCreateChatMessageMutationVariables['data'],
    'entity_type' | 'entity_id' | 'visible_to_org_ids' | 'visible_to_roles' | 'action_type' | 'text'
>;

interface OrderPhotoAssignmentMobileProps {
    orderId: string;
    refetchMessages?: () => void;
    setOpen: (open: boolean) => void;
    backAction: () => void;
    attachments: File[];
    setAttachments: (attachments: File[]) => void;
    files: UploadedFile[];
    setFiles: (files: UploadedFile[]) => void;
    onRemoveFile: (filename: string) => Promise<void>;
    setSessionId: (sessionId: string) => void;
}

interface PhotoTypeSelectionProps {
    orderId: string;
    groupedFiles: Record<LabsGqlLabOrderPhotoType, UploadedFile[]>;
    setGroupedFiles: (groupedFiles: Record<LabsGqlLabOrderPhotoType, UploadedFile[]>) => void;
    files: UploadedFile[];
    photoIndex: number;
    setPhotoIndex: (photoIndex: number) => void;
    setShowSuccessMessage: (showSuccesMessage: boolean) => void;
}

const PhotoTypeSelection: React.VFC<PhotoTypeSelectionProps> = props => {
    const classes = useStyles();
    const { orderId, groupedFiles, setGroupedFiles, files, photoIndex, setPhotoIndex, setShowSuccessMessage } = props;

    const organizationType = useSession()?.organization_type ?? 'practice';
    const uploadShadeAndOtherPhotos = useUploadOrderPhotos();
    const uploadPreopPhotos = useUploadOrderPhotos(LabsGqlLabOrderPhotoType.FullFace);
    const uploadXrayPhotos = useUploadOrderPhotos(LabsGqlLabOrderPhotoType.Xray);
    const uploadSurgicalReportPhotos = useUploadOrderPhotos(LabsGqlLabOrderPhotoType.SurgicalReport);
    const [submitMtn] = useCreateChatMessageMutation();
    const mtnSubmitter = (data: Vars) =>
        submitMtn({
            variables: {
                data: {
                    ...data,
                    text: 'Photos added',
                    entity_type: LabsGqlChatEntityTypes.Order,
                    entity_id: orderId,
                    visible_to_roles: getChatMessageVisibilityForWrite(organizationType, [
                        LabsGqlStaffRoleWithAny.LabAny,
                    ]),
                    action_type: LabsGqlControlPanelActionType.AddPhoto,
                },
            },
        });
    const { submit } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        onSuccess: () => {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Control Panel - Add Photo Action Submitted', {
                $groups: { order: orderId },
            });
            setShowSuccessMessage(true);
        },
    });

    const addToPhotoType = async (photoType: LabsGqlLabOrderPhotoType, file: UploadedFile | undefined) => {
        if (!file) {
            return;
        }
        const existingGroupedFiles = groupedFiles[photoType];
        if (existingGroupedFiles) {
            groupedFiles[photoType] = [...existingGroupedFiles, file];
        } else {
            groupedFiles[photoType] = [file];
        }
        if (photoIndex < files.length) {
            setGroupedFiles(groupedFiles);
            setPhotoIndex(photoIndex + 1);
        } else {
            const attachmentUrls = _.compact(files.map(file => file.filepath ?? null));
            if (attachmentUrls && attachmentUrls.length > 0) {
                await submit({ attachment_urls: attachmentUrls });
            }
            await Promise.all([
                uploadShadeAndOtherPhotos(
                    orderId,
                    (groupedFiles[LabsGqlLabOrderPhotoType.PatientPhoto] ?? []).map(file => file.filepath ?? ''),
                ),
                uploadPreopPhotos(
                    orderId,
                    (groupedFiles[LabsGqlLabOrderPhotoType.FullFace] ?? []).map(file => file.filepath ?? ''),
                ),
                uploadXrayPhotos(
                    orderId,
                    (groupedFiles[LabsGqlLabOrderPhotoType.Xray] ?? []).map(file => file.filepath ?? ''),
                ),
                uploadSurgicalReportPhotos(
                    orderId,
                    (groupedFiles[LabsGqlLabOrderPhotoType.SurgicalReport] ?? []).map(file => file.filepath ?? ''),
                ),
            ]);
        }
    };

    return (
        <ButtonGroup
            orientation={'vertical'}
            style={{ width: '100%', border: 'none', backgroundColor: FlossPalette.TAN }}
        >
            <Button
                variant={'contained'}
                className={classes.fileType}
                onClick={async () => await addToPhotoType(LabsGqlLabOrderPhotoType.PatientPhoto, files[photoIndex - 1])}
            >
                <Text variant={'body2'} medium>
                    Shade
                </Text>
                <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            </Button>
            <Button
                variant={'contained'}
                className={classes.fileType}
                onClick={async () => await addToPhotoType(LabsGqlLabOrderPhotoType.FullFace, files[photoIndex - 1])}
            >
                <Text variant={'body2'} medium>
                    Pre-op
                </Text>
                <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            </Button>
            <Button
                variant={'contained'}
                className={classes.fileType}
                onClick={async () => await addToPhotoType(LabsGqlLabOrderPhotoType.Xray, files[photoIndex - 1])}
            >
                <Text variant={'body2'} medium>
                    X-Ray
                </Text>
                <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            </Button>
            <Button
                variant={'contained'}
                className={classes.fileType}
                onClick={async () =>
                    await addToPhotoType(LabsGqlLabOrderPhotoType.SurgicalReport, files[photoIndex - 1])
                }
            >
                <Text variant={'body2'} medium>
                    Surgical report
                </Text>
                <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            </Button>
            <Button
                variant={'contained'}
                className={classes.fileType}
                onClick={async () => await addToPhotoType(LabsGqlLabOrderPhotoType.PatientPhoto, files[photoIndex - 1])}
            >
                <Text variant={'body2'} medium>
                    Other
                </Text>
                <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
            </Button>
        </ButtonGroup>
    );
};

export const OrderPhotoAssignmentMobile: React.VFC<OrderPhotoAssignmentMobileProps> = props => {
    const {
        orderId,
        setOpen,
        backAction,
        refetchMessages,
        attachments,
        setAttachments,
        files,
        setFiles,
        onRemoveFile,
        setSessionId,
    } = props;
    const [showSuccessMessage, setShowSuccessMessage] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(1);
    const [groupedFiles, setGroupedFiles] = React.useState<Record<string, UploadedFile[]>>({});
    const removeFromGroupedFiles = (file: UploadedFile | undefined) => {
        if (!file) {
            return;
        }
        Object.entries(groupedFiles).forEach(([photoType, fileList]) => {
            groupedFiles[photoType] = fileList.filter(f => f.uploadName !== file.uploadName);
        });
    };
    return (
        <OrderDetailMobileControlPanelBase
            showSuccessMessage={showSuccessMessage}
            successMessage={`${files.length} photo${files.length === 1 ? '' : 's'} added to this order`}
            backAction={() => {
                if (photoIndex === 1) {
                    setAttachments([]);
                    setSessionId(uuidv4());
                    backAction();
                } else {
                    removeFromGroupedFiles(files[photoIndex - 1]);
                    setPhotoIndex(photoIndex - 1);
                }
            }}
            closeAction={() => {
                refetchMessages?.();
                setOpen(false);
                setAttachments([]);
                setSessionId(uuidv4());
                backAction();
            }}
        >
            <div>
                <Grid container justifyContent={'center'} style={{ paddingBottom: 32 }}>
                    <Text variant={'h6'} style={{ paddingBottom: 8 }}>
                        What is shown on this photo?
                    </Text>
                    <Grid container justifyContent={'center'}>
                        <Text variant={'body2'}>
                            Photo {photoIndex} of {files.length}
                        </Text>
                    </Grid>
                </Grid>
                <Grid container justifyContent={'space-between'}>
                    <Grid item style={{ width: '48%' }}>
                        {attachments.length > 0 && attachments[photoIndex - 1] && (
                            <ImageDisplay
                                file={attachments[photoIndex - 1]}
                                onRemove={() => {
                                    setAttachments(
                                        attachments.filter(attachment => attachment !== attachments[photoIndex - 1]),
                                    );
                                    removeFromGroupedFiles(files[photoIndex - 1]);
                                    void onRemoveFile(files[photoIndex - 1]?.uploadName ?? '');
                                    setFiles(files.filter(f => f.file !== files[photoIndex - 1]?.file));
                                    if (files.length === 1) {
                                        backAction();
                                    }
                                    if (photoIndex === files.length) {
                                        setPhotoIndex(files.length - 1);
                                    }
                                }}
                                forOrder
                            />
                        )}
                    </Grid>
                    <Grid item style={{ width: '48%' }}>
                        <PhotoTypeSelection
                            orderId={orderId}
                            groupedFiles={groupedFiles}
                            setGroupedFiles={setGroupedFiles}
                            files={files}
                            photoIndex={photoIndex}
                            setPhotoIndex={setPhotoIndex}
                            setShowSuccessMessage={setShowSuccessMessage}
                        />
                    </Grid>
                </Grid>
            </div>
        </OrderDetailMobileControlPanelBase>
    );
};
