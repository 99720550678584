import { LabeledPhoneField, LabeledTextField } from '../../../../../pre-auth/components/labeled-field/LabeledFields';
import { useStaffForm } from '../useStaffForm';
import { Section } from './components/Section';
import { styled } from '@orthly/ui-primitives';

const AccountInfo = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: 16,
    rowGap: 24,

    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
    },
}));

export const AccountInformationSection: React.FC = () => {
    const {
        firstName,
        lastName,
        accountEmail,
        accountPhone,
        setFirstName,
        setLastName,
        setAccountEmail,
        setAccountPhone,
        firstNameError,
        lastNameError,
        accountEmailError,
        accountPhoneError,
        setShowAccountEmailError,
        setShowAccountPhoneError,
        setShowFirstNameError,
        setShowLastNameError,
        fullName,
        setPreferredName,
        isEditForm,
        isMobileVerified,
        staffUser,
    } = useStaffForm();

    const phoneVerifiedHelperText = () => {
        if (!staffUser?.login_phone || staffUser.login_phone !== accountPhone) {
            return '';
        }
        if (isMobileVerified) {
            return 'Phone number verified';
        }
        return 'Phone number has not been verified by the user';
    };

    return (
        <Section title={'Account information'} subtitle={'Used to login and recover password'}>
            <AccountInfo>
                <LabeledTextField
                    label={'First name'}
                    placeholder={'Enter first name'}
                    value={firstName}
                    onChange={(next = '') => {
                        setShowFirstNameError(false);
                        setPreferredName(prev => (prev === fullName ? `${next.trim()} ${lastName.trim()}` : prev));
                        setFirstName(next);
                    }}
                    isRequired
                    TextFieldProps={{
                        type: 'text',
                        error: !!firstNameError,
                        helperText: firstNameError,
                        onBlur: () => {
                            setShowFirstNameError(true);
                        },
                    }}
                />
                <LabeledTextField
                    label={'Last name'}
                    placeholder={'Enter last name'}
                    value={lastName}
                    onChange={(next = '') => {
                        setShowLastNameError(false);
                        setPreferredName(prev => (prev === fullName ? `${firstName.trim()} ${next.trim()}` : prev));
                        setLastName(next);
                    }}
                    isRequired
                    TextFieldProps={{
                        type: 'text',
                        error: !!lastNameError,
                        helperText: lastNameError,
                        onBlur: () => {
                            setShowLastNameError(true);
                        },
                    }}
                />
                <LabeledTextField
                    label={'Login email address'}
                    placeholder={isEditForm ? 'No email address provided' : 'Enter email address'}
                    value={accountEmail}
                    onChange={(next = '') => {
                        setShowAccountEmailError(false);
                        setAccountEmail(next);
                    }}
                    isRequired
                    TextFieldProps={{
                        type: 'email',
                        error: !!accountEmailError,
                        helperText: accountEmailError,
                        onBlur: () => {
                            setShowAccountEmailError(true);
                        },
                    }}
                />
                {isEditForm && (
                    <LabeledPhoneField
                        label={'Login phone number'}
                        placeholder={'No phone number provided'}
                        value={accountPhone}
                        onChange={(next = '') => {
                            setShowAccountPhoneError(false);
                            setAccountPhone(next === '+1' ? '' : next);
                        }}
                        TextFieldProps={{
                            error: !!accountPhoneError,
                            helperText: accountPhoneError || phoneVerifiedHelperText(),
                        }}
                        onBlur={() => {
                            setShowAccountPhoneError(true);
                        }}
                    />
                )}
            </AccountInfo>
        </Section>
    );
};
