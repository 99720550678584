import React from 'react';
import { z } from 'zod';

const recoverPasswordSchema = z.object({
    email: z.string().email({ message: 'Invalid email address' }),
});

export const useRecoverPasswordValidation = ({ email }: { email: string }) => {
    const [showEmailError, setShowEmailError] = React.useState(false);
    const validation = React.useMemo(() => recoverPasswordSchema.safeParse({ email }), [email]);

    if (validation.success) {
        return { isFormValid: true, setShowEmailError };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: false,
        setShowEmailError,
        emailError: showEmailError && fieldErrors.email?.[0],
    };
};
