import { PreferenceSelect } from './PreferenceSelect';
import { PreferenceText } from './PreferenceText';
import type { ItemMetafieldV2 } from '@orthly/items';
import { Button, Collapse, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface PreferenceSectionProps {
    title: string;
    preferenceFields: ItemMetafieldV2[];
    selectedOptions: Record<string, string | boolean | undefined>;
    setSelectedOptions: React.Dispatch<React.SetStateAction<Record<string, string | boolean | undefined>>>;
    allowRemove?: boolean;
}

const PreferenceField: React.FC<{
    pf: ItemMetafieldV2;
    selectedOptions: PreferenceSectionProps['selectedOptions'];
    setSelectedOptions: PreferenceSectionProps['setSelectedOptions'];
}> = ({ pf, selectedOptions, setSelectedOptions }) => {
    switch (pf.type) {
        case 'text':
            return (
                <PreferenceText
                    header={(pf.preference_label ?? pf.label) || pf.name}
                    label={pf.helper_text ?? undefined}
                    value={(selectedOptions[pf.id] as string) ?? ''}
                    onChange={text => setSelectedOptions(old => ({ ...old, [pf.id]: text }))}
                />
            );
        case 'boolean':
            return (
                <PreferenceSelect
                    key={pf.id}
                    multiple={false}
                    header={(pf.preference_label ?? pf.label) || pf.name}
                    selectables={[
                        {
                            id: 'Yes',
                            selected: selectedOptions[pf.id] === true,
                            otherSelected: selectedOptions[pf.id] === false,
                            isDefault: pf.default_value === true,
                        },
                        {
                            id: 'No',
                            selected: selectedOptions[pf.id] === false,
                            otherSelected: selectedOptions[pf.id] === true,
                            isDefault: pf.default_value === false,
                        },
                    ]}
                    onChange={ids => {
                        setSelectedOptions(old => ({
                            ...old,
                            [pf.id]: ids.length > 0 ? ids[0] === 'Yes' : undefined,
                        }));
                    }}
                />
            );
        default:
            return (
                <PreferenceSelect
                    multiple={false}
                    header={(pf.preference_label ?? pf.label) || pf.name}
                    selectables={
                        pf.options?.map(option => ({
                            id: option.value,
                            label: option.label ?? undefined,
                            selected: selectedOptions[pf.id] === option.value,
                            otherSelected:
                                selectedOptions[pf.id] !== option.value && selectedOptions[pf.id] !== undefined,
                            imageUrl: option.image_url,
                            isDefault: pf.default_value === option.value,
                        })) ?? []
                    }
                    onChange={ids => {
                        setSelectedOptions(old => ({
                            ...old,
                            [pf.id]: ids.length > 0 ? ids[0] : undefined,
                        }));
                    }}
                />
            );
    }
};

export const PreferenceSection: React.FC<PreferenceSectionProps> = props => {
    const { title, preferenceFields, selectedOptions, setSelectedOptions } = props;

    return (
        <Grid container style={{ marginTop: '2rem' }}>
            <Grid item xs={12} sm={3}>
                <Text style={{ color: 'gray' }}>{title}</Text>
            </Grid>
            <Grid item xs={12} sm={9}>
                {preferenceFields.map(pf => (
                    <React.Fragment key={pf.id}>
                        <PreferenceField
                            pf={pf}
                            selectedOptions={selectedOptions}
                            setSelectedOptions={setSelectedOptions}
                        />
                        {props.allowRemove && (
                            <Collapse in={selectedOptions[pf.id] !== undefined} style={{ width: '100%' }}>
                                <Grid container style={{ paddingBottom: 12 }}>
                                    <Button
                                        variant={'text'}
                                        size={'medium'}
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                            setSelectedOptions(old => ({ ...old, [pf.id]: undefined }));
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </Grid>
                            </Collapse>
                        )}
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
};
