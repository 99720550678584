import { useCSTAction } from '../cst-state/cst.actions';
import { useCSTSelector } from '../cst-state/cst.selectors';
import { ChatWindowContent } from './ChatWindowContent';
import type { MiniWindowPendingAnimation } from '@orthly/ui';
import { MiniWindowCore, MiniWindowVariant, MiniWindowVariantCSSClass } from '@orthly/ui';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    window: {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: 512,
        borderRadius: '0 0 8px 8px',
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.TAN,
        overflow: 'scroll',
        [`&.${MiniWindowVariantCSSClass.Restored}`]: {
            height: 512,
            borderRadius: 8,
        },
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Maximized]}`]: {
            boxShadow: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
    },
}));

export const ChatWindowBase: React.VFC = () => {
    const classes = useStyles();
    const {
        platform,
        windowState: { variant, pendingAnimation },
    } = useCSTSelector(cstState => cstState);
    const setVariant = useCSTAction('SET_WINDOW_VARIANT');
    const setPendingAnimation = useCSTAction('SET_WINDOW_PENDING_ANIMATION');

    return (
        <MiniWindowCore
            variant={variant}
            setVariant={
                platform === 'portal'
                    ? (variant: MiniWindowVariant, pendingAnimation?: MiniWindowPendingAnimation) => {
                          setVariant(variant, pendingAnimation);
                      }
                    : () => {}
            }
            pendingAnimation={pendingAnimation}
            setPendingAnimation={setPendingAnimation}
            className={classes.window}
        >
            <ChatWindowContent />
        </MiniWindowCore>
    );
};
