import { OrderAlertBanner } from './OrderAlertBanner';
import type { LabsGqlLabOrderWithEvents } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

interface OrderMultiFulfillmentAlertBannerProps {
    order: Pick<LabsGqlLabOrderWithEvents, 'previous_fulfillment_order_id' | 'next_fulfillment_order_ids'>;
    openOrder: (orderId: string) => void;
}

function getMultiFulfillmentCopy(hasPrevious: boolean, futureCount: number) {
    // only time we reference the past order is when there's no future ones. If there's both, the "View past order" button is enough to explain
    if (hasPrevious && futureCount === 0) {
        return `This case has a past order`;
    }
    // only future
    if (futureCount > 1) {
        return `This case has ${futureCount} more recent orders`;
    }
    return `This case has a more recent order`;
}

/**
 * @deprecated Use OrderLinkedOrdersBanners instead
 *
 * Delete when no more usages exist
 */
export const OrderMultiFulfillmentAlertBanner: React.VFC<OrderMultiFulfillmentAlertBannerProps> = ({
    order,
    openOrder,
}) => {
    const hasPreviousFulfillment = !!order.previous_fulfillment_order_id;
    const hasNextFulfillments = !!order.next_fulfillment_order_ids?.length;
    const futureOrdersCount = order.next_fulfillment_order_ids?.length ?? 0;

    if (!hasPreviousFulfillment && !hasNextFulfillments) {
        return null;
    }

    return (
        <OrderAlertBanner
            variant={'primary'}
            title={getMultiFulfillmentCopy(hasPreviousFulfillment, futureOrdersCount)}
            actions={_.compact([
                hasPreviousFulfillment && {
                    label: 'View past order',
                    onClick: () =>
                        order.previous_fulfillment_order_id && openOrder(order.previous_fulfillment_order_id),
                },
                ...(order.next_fulfillment_order_ids ?? []).map((futureOrderId, idx) => ({
                    label: `View next order ${futureOrdersCount > 1 ? ` (${idx + 1})` : ''}`,
                    onClick: () => openOrder(futureOrderId),
                })),
            ])}
        />
    );
};
