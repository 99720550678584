import { useCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { getItemScreens } from '../../state/checkout.screens';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import React from 'react';

export const CheckoutItemsStepRoot: React.FC = () => {
    const { step, itemScreenIndex } = useCheckoutPropSelector(['step', 'itemScreenIndex']);
    const itemScreens = useCheckoutSelector(getItemScreens);

    return (
        <>
            {itemScreens.map((s, index) => {
                const isVisible = step === 1 && index === itemScreenIndex;
                const isComplete = (step === 1 && index < itemScreenIndex) || step === 2;
                return (
                    <CheckoutBodyWrapper key={s.identifier} visible={isVisible} isComplete={isComplete}>
                        {s.contents}
                    </CheckoutBodyWrapper>
                );
            })}
        </>
    );
};
