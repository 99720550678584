import React from 'react';

export function useInactivityTimer(inactiveForMilliseconds: number): boolean {
    const [hasInactivity, setHasInactivity] = React.useState<boolean>(false);
    React.useEffect(() => {
        let timer: ReturnType<typeof setTimeout>;

        const activity = () => {
            setHasInactivity(false);
            clearTimeout(timer);
            timer = setTimeout(() => setHasInactivity(true), inactiveForMilliseconds);
        };
        activity();

        window.addEventListener(`mousedown`, activity);
        window.addEventListener(`keydown`, activity);
        window.addEventListener(`mousemove`, activity);

        return () => {
            clearTimeout(timer);
            window.removeEventListener(`mousedown`, activity);
            window.removeEventListener(`keydown`, activity);
            window.removeEventListener(`mousemove`, activity);
        };
    }, [setHasInactivity, inactiveForMilliseconds]);

    return hasInactivity;
}
