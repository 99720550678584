import { useSelectStaffAction } from '../../../select-staff/state/select-staff.actions';
import { useScreenIsMobile, IconButton, AddIcon, Button } from '@orthly/ui-primitives';
import React from 'react';

export const CreateNewEmployee: React.FC<{ text: string; createAction?: () => void }> = ({ text, createAction }) => {
    const createNewStaff = useSelectStaffAction('CREATE_NEW_STAFF_WITHOUT_LOGOUT');
    const isMobile = useScreenIsMobile();
    return isMobile ? (
        <IconButton data-testid={'add-staff-button'} onClick={() => (createAction ? createAction() : createNewStaff())}>
            <AddIcon />
        </IconButton>
    ) : (
        <Button variant={'primary'} onClick={() => (createAction ? createAction() : createNewStaff())}>
            <AddIcon style={{ marginRight: 8 }} /> {text}
        </Button>
    );
};
