import type { FieldDefText, FieldLayout } from '../QuickForm.types';
import { FormikRootField } from '../QuickForm.types';
import { TextField, Grid } from '@orthly/ui-primitives';
import { getIn } from 'formik';
import _ from 'lodash';
import React from 'react';
import ReactInputMask from 'react-input-mask';

export type QFMaskedFieldPropType = FieldDefText & { name: string; mask: string };

interface QFMaskedFieldProps {
    field: QFMaskedFieldPropType;
}

export const QFMaskedField: React.FC<QFMaskedFieldProps> = props => {
    const { field } = props;
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    return (
        <Grid item {...layout}>
            <FormikRootField
                name={field.name}
                render={formikProps => {
                    const error = getIn(formikProps.form.errors, field.name);
                    const touched = getIn(formikProps.form.touched, field.name);
                    return (
                        <>
                            <ReactInputMask
                                mask={field.mask}
                                onChange={formikProps.field.onChange}
                                onBlur={formikProps.field.onBlur}
                                value={formikProps.field.value || ''}
                            >
                                {(inputProps: any) => {
                                    return (
                                        <TextField
                                            variant={props.field.fieldProps?.variant || ('standard' as const)}
                                            fullWidth
                                            data-test={`quick-form-field-${field.name}`}
                                            {...field.fieldProps}
                                            helperText={error && touched ? error : field.helperText}
                                            error={!!error && !!touched}
                                            name={field.name}
                                            label={field.label ? field.label : _.startCase(field.name)}
                                            InputProps={{ disableUnderline: true, ...field.fieldProps?.InputProps }}
                                            InputLabelProps={{
                                                required: !field.optional,
                                                ...field.fieldProps?.InputLabelProps,
                                            }}
                                            value={formikProps.field.value || ''}
                                            {...inputProps}
                                        />
                                    );
                                }}
                            </ReactInputMask>
                        </>
                    );
                }}
            />
        </Grid>
    );
};
