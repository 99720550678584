import { PricingGuideTable } from '../../table/PricingGuideTable';

export const ImplantSolutionsMaterialAddOnsTable: React.FC = () => (
    <PricingGuideTable
        id={'implant-solutions-add-ons'}
        title={'Add-ons and modifications'}
        columns={[
            {
                id: 'type',
                title: 'Type',
            },
            {
                id: 'quantity',
                title: 'Free of charge with Implant bundle',
            },
            {
                id: 'price',
                title: 'Suggested retail price',
                render: ({ price }) => <span style={{ textDecoration: 'line-through' }}>{price}</span>,
            },
        ]}
        rows={[
            {
                id: 'model',
                type: 'Model',
                quantity: '1 per order',
                price: '$20-50',
            },
            {
                id: 'analog',
                type: 'Analog',
                quantity: '1 per abutment',
                price: '$30-50',
            },
            {
                id: 'screw',
                type: 'Screw',
                quantity: '1 per abutment',
                price: '$10-15',
            },
            {
                id: 'gold anodizing',
                type: 'Gold anodizing',
                quantity: '1 per custom abutment',
                price: '$25',
            },
            {
                id: 'insertion guide',
                type: 'Insertion guide',
                quantity: '1 per cement-retained crown',
                price: '$25',
            },
            {
                id: 'cementation',
                type: 'Cementation',
                quantity: '1 per screw-retained crown',
                price: '$20-25',
            },
            {
                id: 'scan body',
                type: 'Scan body',
                quantity: '1 per system and platform',
                price: '$30-150',
            },
        ]}
    />
);
