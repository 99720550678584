import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useCSTAction, useOnCSTOpen } from '../cst-state/cst.actions';
import { useCSTSelector } from '../cst-state/cst.selectors';
import { useChatFeatures } from '../utils';
import { ChatWindowHeader } from './ChatWindowHeader';
import { CLINICAL_SUPPORT_TEAMS } from './config';
import type { CSTInfo } from './config';
import { MiniWindowVariant } from '@orthly/ui';
import { FlossPalette, stylesFactory, useScreenIsMobile, Grid, Link, Icon, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    messageContainer: {
        overflow: 'scroll',
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: 16,
    },
    chatContainer: {
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: 16,
        background: FlossPalette.WHITE,
        padding: 24,
    },
    row: {
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        backgroundColor: FlossPalette.WHITE,
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        padding: '16px 24px',
        width: '100%',
        '&:first-child': { borderTop: 'none' },
    },
    phoneIcon: {
        color: FlossPalette.GRAY,
        height: 16,
        width: 16,
        marginRight: 8,
    },
}));

const ContactListItem: React.VFC<{ cst: CSTInfo }> = ({ cst }) => {
    const classes = useStyles();
    return (
        <div className={classes.row}>
            <Text variant={'body2'} style={{ paddingRight: 8, whiteSpace: 'nowrap' }} medium>
                {cst.label}
            </Text>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Icon icon={'Phone'} className={classes.phoneIcon} />
                <Text variant={'caption'} color={'GRAY'} medium>
                    {cst.phoneNumber}
                </Text>
            </div>
        </div>
    );
};

export const ChatWindowContent: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const { platform } = useCSTSelector(cstState => cstState);
    const { openChat } = useChatFeatures();
    const isPortal = platform === 'portal';

    const setVariant = useCSTAction('SET_WINDOW_VARIANT');
    const closeCSTAction = useOnCSTOpen(() => setVariant(MiniWindowVariant.Closed, 'move'), 'horizontal_nav');

    return (
        <>
            <ChatWindowHeader />
            <Grid container style={{ padding: '8px 24px 24px' }}>
                <Grid container className={classes.chatContainer}>
                    <Text variant={'body1'} medium data-test={'support-chat-title'}>
                        Chat with clinical, account & IT support
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginBottom: 8 }}>
                        {isMobile
                            ? 'Ask about clinical, account, IT, or billing questions.'
                            : 'Reach out about clinical, account, IT, or billing questions.'}
                    </Text>
                    <Grid container alignItems={'center'}>
                        <Button
                            variant={'secondary'}
                            startIcon={'SidebarChatIcon'}
                            data-test={'start-a-live-chat-nav-button'}
                            style={{
                                padding: '8px 16px',
                                backgroundColor: FlossPalette.TAN,
                                marginRight: 16,
                                marginBottom: isMobile ? 8 : undefined,
                            }}
                            onClick={() => {
                                openChat();
                                AnalyticsClient.track('Practice - CST - Live Chat Clicked', {});
                                if (isPortal && !isMobile) {
                                    closeCSTAction();
                                }
                            }}
                        >
                            Start a live chat
                        </Button>
                        <Grid container style={{ width: isMobile ? undefined : 'unset' }}>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                or call Dandy via {(!isMobile || !isPortal) && '267-310-3332'}
                            </Text>
                            {isMobile && isPortal && (
                                <Link href={'tel:267-310-3332'} target={'_blank'} style={{ paddingLeft: 4 }}>
                                    <Text variant={'body2'} medium color={'PRIMARY_FOREGROUND'}>
                                        267-310-3332
                                    </Text>
                                </Link>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container style={{ padding: '0px 24px 16px' }}>
                <Grid container className={classes.messageContainer}>
                    <Grid container style={{ padding: '24px 24px 8px', backgroundColor: FlossPalette.WHITE }}>
                        <Text variant={'body1'} medium style={{ marginBottom: 8 }}>
                            Call our Clinical Support Team
                        </Text>
                        <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginBottom: 8 }}>
                            Get help with complex cases, shade matching, material selection, refabrications, scan
                            bodies, and more.
                        </Text>
                    </Grid>
                    <Grid container data-test={'cst-group-list'}>
                        {CLINICAL_SUPPORT_TEAMS.map((cst, idx) => (
                            <ContactListItem key={`${cst}-${idx}`} cst={cst} />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
