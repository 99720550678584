import { useChatFeatures } from '../../../../chat/utils';
import { Button } from '@orthly/ui-primitives';

export const ChatWithDandyButton: React.VFC = () => {
    const { openChat } = useChatFeatures();

    return (
        <Button variant={'ghost'} onClick={openChat} startIcon={'SidebarChatIcon'}>
            Chat with a Dandy representative
        </Button>
    );
};
