import type { ModelAppearance, PayloadModelAppearance } from '@orthly/dentin';
import { FlossPalette, styled, Text, Icon, useScreenIsMobile, Box } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const PatientShareScansModelViewerSidebarWrapper = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
    gridRowGap: '4px',
    minWidth: '114px',
    padding: '8px',
    borderRight: `1px solid ${FlossPalette.STROKE_LIGHT}`,

    [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '8px',
        width: 'min-content',
        margin: '8px',
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: '8px',
        gap: '8px',
    },
}));

const ArchSelectorButtonWrapper = styled('div')((props: { selected: boolean }) => ({
    width: '98px',
    padding: '4px 8px',
    borderRadius: '8px',
    cursor: 'pointer',
    backgroundColor: props.selected ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.WHITE,
    border: `1px solid ${props.selected ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.WHITE}`,
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    textAlign: 'center',
}));

const ArchSelectorIcon = styled(Icon)((props: { selected: boolean }) => ({
    color: props.selected ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
    margin: '0px auto',
}));

const ArchSelectorButton: React.VFC<{ selected: boolean; onClick: () => void; variant: 'upper' | 'lower' }> = ({
    selected,
    onClick,
    variant,
}) => {
    return (
        <ArchSelectorButtonWrapper selected={selected} onClick={onClick}>
            <ArchSelectorIcon selected={selected} icon={variant === 'lower' ? 'LowerJawIcon' : 'UpperJawIcon'} />
            <Text variant={'body2'} medium color={selected ? 'PRIMARY_FOREGROUND' : 'GRAY'}>
                {_.startCase(variant)}
            </Text>
        </ArchSelectorButtonWrapper>
    );
};

export const PatientShareScansModelViewerSidebar: React.VFC<{
    appearance: ModelAppearance;
    setAppearance: (appearance: ModelAppearance) => void;
}> = ({ appearance, setAppearance }) => {
    const isMobile = useScreenIsMobile();

    const lowerJawScan = appearance.scans.find(scan => scan.payloadModel.name.includes('Lower'));
    const upperJawScan = appearance.scans.find(scan => scan.payloadModel.name.includes('Upper'));

    const toggleVisibility = (targetScan: PayloadModelAppearance) =>
        setAppearance({
            ...appearance,
            scans: appearance.scans.map(scan =>
                scan.payloadModel.path === targetScan.payloadModel.path
                    ? {
                          ...scan,
                          appearance: {
                              ...scan.appearance,
                              visible: !scan.appearance.visible,
                          },
                      }
                    : scan,
            ),
        });

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr auto' : '1fr',
                alignItems: isMobile ? 'center' : 'unset',
            }}
        >
            {isMobile && (
                <Text variant={'body2'} color={'GRAY'} medium style={{ textAlign: 'center' }}>
                    Arches
                </Text>
            )}

            <PatientShareScansModelViewerSidebarWrapper>
                {upperJawScan && (
                    <ArchSelectorButton
                        variant={'upper'}
                        selected={upperJawScan.appearance.visible}
                        onClick={() => toggleVisibility(upperJawScan)}
                    />
                )}
                {lowerJawScan && (
                    <ArchSelectorButton
                        variant={'lower'}
                        selected={lowerJawScan.appearance.visible}
                        onClick={() => toggleVisibility(lowerJawScan)}
                    />
                )}
            </PatientShareScansModelViewerSidebarWrapper>
        </Box>
    );
};
