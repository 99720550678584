import type { MultipartFormSingleStepProps, NotificationPreferencesSectionProps } from './utils';
import { contactOptionsForDoctor } from './utils';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import type { LabsGqlWhoReceivesDoctorNotifications } from '@orthly/graphql-schema';
import { QuickForm, QuickFormValidations, PhoneField } from '@orthly/ui';
import React from 'react';
import { z } from 'zod';

export interface ContactInfoProps {
    email: string;
    phone_call_number: string;
    phone_is_sms: 'yes' | 'no';
    phone_sms_number?: string;
    whom: LabsGqlWhoReceivesDoctorNotifications;
    best_call_hour: string;
    timezone: string;
}

export function contactInfoForDoctor(doctor: LabsGqlDoctorPreferencesFragment): Partial<ContactInfoProps> {
    return {
        email: doctor.contact_email ?? undefined,
        // default to contact_phone if contact_phone_call_number is missing because ops folks can set
        // contact_phone, so some drs have a .contact_phone but no .contact_phone_call_number
        phone_call_number: doctor.contact_phone_call_number ?? doctor.contact_phone ?? undefined,
        phone_is_sms:
            // eslint-disable-next-line no-nested-ternary
            !doctor.contact_phone_call_number || !doctor.contact_phone
                ? undefined
                : doctor.contact_phone_call_number === doctor.contact_phone
                  ? 'yes'
                  : 'no',
        phone_sms_number:
            doctor.contact_phone_call_number === doctor.contact_phone ? undefined : doctor.contact_phone ?? undefined,
        whom: doctor.who_receives_dr_notifications ?? undefined,
        best_call_hour: doctor.best_call_hour?.toString() ?? undefined,
        timezone: doctor.timezone ?? LabsUtilsBase.normalizedTimezoneFromBrowser,
    };
}

export function cleanedContactInfoResults(values: ContactInfoProps) {
    return {
        who_receives_dr_notifications: values.whom,
        contact_phone_call_number: values.phone_call_number,
        contact_phone: values.phone_is_sms !== 'no' ? values.phone_call_number : values.phone_sms_number ?? '',
        contact_email: values.email,
        best_call_hour: Number(values.best_call_hour),
        timezone: values.timezone,
    };
}

export const ContactInfo: React.FC<
    MultipartFormSingleStepProps<ContactInfoProps> & NotificationPreferencesSectionProps
> = props => {
    const { doctor, formState, setFormState, customSubmit, onSubmit } = props;
    const extra_phone_sms_number_required = formState?.phone_is_sms === 'no';

    return (
        <QuickForm<ContactInfoProps>
            initialValues={formState}
            onChange={setFormState}
            CustomSubmit={customSubmit}
            onSubmit={onSubmit}
            fields={{
                whom: {
                    label: 'Who is the best person to contact with order updates/inquiries?',
                    type: 'select',
                    disableSortOptions: true,
                    options: contactOptionsForDoctor(doctor),
                },
                phone_call_number: {
                    label: `Best phone number to contact?`,
                    type: PhoneField,
                },
                phone_is_sms: {
                    label: 'Can we send texts to this number?',
                    type: 'radioGroup',
                    options: [
                        { value: 'yes', label: 'Yes' },
                        { value: 'no', label: 'No, different number instead' },
                    ],
                },
                phone_sms_number: {
                    label: 'What phone number can we send text messages to?',
                    type: PhoneField,
                    // if phone number is required then use the default validator, but if not allow anything
                    validation: extra_phone_sms_number_required ? undefined : z.string(),
                    hidden: !extra_phone_sms_number_required,
                    optional: !extra_phone_sms_number_required,
                },
                email: {
                    label: `Best email to contact?`,
                    type: 'text',
                    validation: QuickFormValidations.email,
                },
                timezone: {
                    type: 'select',
                    disableSortOptions: true,
                    options: LabsUtilsBase.notificationTimeZoneOptions,
                },
                best_call_hour: {
                    label: 'If we need to call you, what time would work best?',
                    type: 'select',
                    disableSortOptions: true,
                    fieldProps: {
                        variant: 'standard',
                        disabled: !formState.timezone,
                    },
                    options: LabsUtilsBase.bestCallHourOptions(formState.timezone),
                },
            }}
        />
    );
};
