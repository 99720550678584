import { useCheckoutSelector } from '../../../../redux';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import { useDentureCheckoutSelector, useDentureCheckoutStepInfo } from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { RadioButton } from './DentureSelectTypeScreen';
import type { CheckoutBannerConfig } from '@orthly/dentin';
import { CheckoutBodyWrapper, CheckoutSelectField, CheckoutBanner } from '@orthly/dentin';
import { stylesFactory, Grid, Green, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    container: {
        width: '100%',
        height: '100%',
        marginTop: 16,
        paddingBottom: 24,
    },
}));

const bannerConfig: CheckoutBannerConfig = {
    head: (
        <>
            There are <Green>no emergencies</Green> when the patient has spare dentures
        </>
    ),
    subhead: (
        <>
            If anything happens to their main set’s teeth, they can switch to wearing their spares while waiting for
            their primary dentures to be repaired or replaced.
        </>
    ),
    imgSrc: '/checkout/tooth-flag.svg',
};

export const DentureAddSpareDenturesScreen: React.VFC = () => {
    const classes = useStyles();
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(DentureCheckoutStep.AddSpareDenturesStep);
    const patient_first_name = useCheckoutSelector(state => state.patient_first_name);
    const numberOfSpares = useDentureCheckoutSelector(s => s.fields.numberOfSpares);
    const [lastSelection, setLastSelection] = React.useState(numberOfSpares ?? 1);
    const setNumberOfSpares = useDenturesCheckoutAction('SET_NUMBER_OF_SPARES');

    if (!isActiveStep) {
        return null;
    }
    const hasSpare = !!numberOfSpares && numberOfSpares > 0;

    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <Grid
                container
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                alignContent={'flex-start'}
                spacing={2}
                className={classes.container}
            >
                <Grid item xs={12}>
                    <Text variant={'h6'} medium>
                        {patient_first_name
                            ? `Does ${patient_first_name} need a spare denture?`
                            : `Add a spare denture?`}
                    </Text>
                </Grid>
                <Grid item xs={6}>
                    <RadioButton
                        label={'Yes'}
                        isSelected={hasSpare}
                        onSelect={() => {
                            setNumberOfSpares(lastSelection);
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <RadioButton
                        label={'No'}
                        isSelected={numberOfSpares === 0}
                        onSelect={() => {
                            setNumberOfSpares(0);
                        }}
                    />
                </Grid>
                {hasSpare && (
                    <>
                        <Grid item xs={12}>
                            <Text variant={'h6'} medium>
                                How many?
                            </Text>
                        </Grid>
                        <Grid item xs={6}>
                            <CheckoutSelectField
                                required={false}
                                label={'Number of spares'}
                                options={[1, 2, 3, 4, 5].map(n => ({
                                    value: `${n}`,
                                }))}
                                value={`${numberOfSpares}`}
                                onChange={value => {
                                    const numberOfSpares = value && !isNaN(parseInt(value)) ? parseInt(value) : 1;
                                    setNumberOfSpares(numberOfSpares);
                                    setLastSelection(numberOfSpares);
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} />
                    </>
                )}

                <Grid item xs={12}>
                    <CheckoutBanner config={bannerConfig} style={{ marginTop: 16 }} />
                </Grid>
            </Grid>
        </CheckoutBodyWrapper>
    );
};
