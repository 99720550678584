import { PracticeUtils } from '../../utils/utils';
import { ErrorPage } from './ErrorPage';
import { PatientOrderTokenAuthenticated } from './PatientOrderTokenAuthenticated.graphql';
import { PatientShareTokenAuthenticated } from './patient-share/PatientShareTokenAuthenticated.graphql';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const PatientAppRoot: React.VFC = () => {
    React.useEffect(() => void (document.title = `Dandy`), []);
    const location = useLocation();
    const orderToken = PracticeUtils.getParsedQueryProperty(`orderToken`, location.search);
    const patientShareToken = PracticeUtils.getParsedQueryProperty(`patientShareToken`, location.search);
    const token = orderToken || patientShareToken;
    const versionIdParam = PracticeUtils.getParsedQueryProperty(`versionId`, location.search);
    const versionId = versionIdParam && !Number.isNaN(parseInt(versionIdParam)) ? parseInt(versionIdParam) : undefined;

    if (!token) {
        return <ErrorPage>Invalid link</ErrorPage>;
    }

    // Manually pulling this JWT apart is a little bit messy, but we
    // only really need to check the expiration date here and doing a
    // full verification would require syncing the public key config with
    // labs server.  This will prevent us from making invalid requests in
    // the common case of a user clicking an expired link, but we still
    // have the full verification on the backend to catch any less common
    // failure modes
    const payloadBase64 = token.split('.')[1];
    if (!payloadBase64) {
        return <ErrorPage>Invalid link</ErrorPage>;
    }

    try {
        const payload = JSON.parse(atob(payloadBase64));
        if (Date.now() > payload.exp * 1000) {
            return <ErrorPage>Expired link</ErrorPage>;
        }
    } catch (_e) {
        return <ErrorPage>Invalid link</ErrorPage>;
    }

    if (orderToken) {
        return <PatientOrderTokenAuthenticated {...{ token, versionId }} />;
    }
    if (patientShareToken) {
        return <PatientShareTokenAuthenticated {...{ token }} />;
    }
    return null;
};

export const PatientAppLoading: React.VFC = () => <ErrorPage>Loading</ErrorPage>;
