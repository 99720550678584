import { FlossPalette, Icon, styled } from '@orthly/ui-primitives';

export const GridLayout = styled('div')(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(1),
}));

export const PaddedGridLayout = styled(GridLayout)(({ theme }) => ({
    gap: theme.spacing(2),
}));

export const FieldsLayout = styled(PaddedGridLayout)(({ theme }) => ({
    '&:not(:first-child)': {
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        paddingTop: theme.spacing(1.5),
    },
    '&:last-child:not(:only-child)': {
        marginBottom: theme.spacing(2),
    },
}));

export const PaddedColumnGridLayout = styled(PaddedGridLayout)(({ theme }) => ({
    gridAutoFlow: 'column',

    [theme.breakpoints.down('sm')]: {
        gridAutoFlow: 'row',
    },
}));

export const FieldColumnLayout = styled(PaddedColumnGridLayout)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        gap: 0,
    },
}));

export const StyledIcon = styled(Icon)(({ theme }) => ({
    color: FlossPalette.PRIMARY_FOREGROUND,
    fontSize: 40,
    marginBottom: theme.spacing(2),
}));

export const TrainingCard = styled('div')(({ theme }) => ({
    borderRadius: theme.spacing(2),
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
}));
