import type { InboxUpsellSkuType } from './InboxUpsellComponents';
import { InboxUpsellComponent } from './InboxUpsellComponents';
import { useGetUpsellConfigToShow } from './utils';
import React from 'react';
import { createLocalStorageStateHook } from 'use-local-storage-state';

// eslint-disable-next-line
export const useInboxUpsellDismissedSKUs = createLocalStorageStateHook<InboxUpsellSkuType[]>(
    `inbox-upsell-dismissed-sku`,
    [],
);

export const InboxUpsellRoot: React.FC = () => {
    const [inboxUpsellDismissedSKUs] = useInboxUpsellDismissedSKUs();
    const upsellConfig = useGetUpsellConfigToShow(inboxUpsellDismissedSKUs);

    if (!upsellConfig) {
        return null;
    }

    /**
     * To add an SKU, add a props that provides SKU specific information and returns an itemDisplay
     */
    return <InboxUpsellComponent config={upsellConfig} />;
};
