import type { LabsGqlDeleteUnusedScanFileMutationVariables, LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { useDeleteUnusedScanFileMutation, useScans } from '@orthly/graphql-react';
import { RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, IconButton, Tooltip, DeleteIcon, Button } from '@orthly/ui-primitives';
import React from 'react';

interface DeleteUnusedScanCustomRenderProps {
    submitting: boolean;
    openModal: () => void;
    scan: LabsGqlScanFileFragment;
}

interface DeleteUnusedScanProps {
    scan: LabsGqlScanFileFragment;
    CustomRender?: React.ComponentType<DeleteUnusedScanCustomRenderProps>;
}

type Vars = LabsGqlDeleteUnusedScanFileMutationVariables['data'];

export const DeleteUnusedScan: React.FC<DeleteUnusedScanProps> = props => {
    const { CustomRender, scan } = props;
    const { refetch: refetchScans } = useScans({ fetchPolicy: 'cache-first' });
    const [submitMtn] = useDeleteUnusedScanFileMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting, open, setOpen } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Scan File successfully deleted!', {}],
        onSuccess: async () => {
            await refetchScans();
        },
    });

    return (
        <>
            <RootActionDialog
                showCloseButton
                loading={submitting}
                open={open}
                setOpen={setOpen}
                title={'Delete an Unused Scan File'}
                content={
                    <Grid container style={{ padding: 10 }}>
                        <Grid item xs={12}>
                            Are you sure you want to{' '}
                            <b>
                                <i>permanently</i>
                            </b>{' '}
                            delete this Scan File? This action cannot be undone.
                        </Grid>
                        <Grid item xs={12} container justifyContent={'flex-end'}>
                            <Button variant={'primary'} onClick={() => submit({ scan_export_id: props.scan.id })}>
                                Yes, Delete
                            </Button>
                            &nbsp;
                            <Button variant={'secondary'} onClick={() => setOpen(false)}>
                                No, Cancel
                            </Button>
                        </Grid>
                    </Grid>
                }
                style={{ padding: 0, flexBasis: 0 }}
                buttonText={'Add Scan File'}
                buttonProps={{ fullWidth: false }}
                CustomButton={buttonProps => {
                    if (!!CustomRender) {
                        return null;
                    }
                    return (
                        <IconButton onClick={buttonProps.onClick}>
                            <Tooltip title={'Delete Scan'}>
                                <DeleteIcon />
                            </Tooltip>
                        </IconButton>
                    );
                }}
            />
            {CustomRender && <CustomRender scan={scan} submitting={submitting} openModal={() => setOpen(true)} />}
        </>
    );
};
