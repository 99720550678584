export function splitPatientName(name: string): { first: string; last: string } {
    const words = name.trim().split(' ');
    const first = words[0] ?? '';
    const last = words.slice(1).join(' ') ?? '';

    return { first, last };
}

export function isValidPatientName(name: string): boolean {
    const { first, last } = splitPatientName(name);
    return !!first && !!last;
}
