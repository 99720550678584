import type {
    ActionProps,
    DandyNotification,
    DismissAction,
    UpdateNotificationDataWrapper,
} from './DandyNotifications.types';
import { NotificationActionType } from './DandyNotifications.types';
import { DandyUpdateNotificationsItem } from './DandyUpdateNotificationsItem';
import { DateUtils } from '@orthly/shared-types';
import { FlossPalette, stylesFactory, Text, Grid, Link } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(() => ({
    section: {
        height: 40,
        backgroundColor: FlossPalette.DARK_TAN,
        padding: '8px 16px',
        justifyContent: 'space-between',
    },
    dismissAll: { cursor: 'pointer' },
}));

interface DandyUpdateNotificationsProps {
    sortedUpdateNotifications: UpdateNotificationDataWrapper[];
    onClick: (action: ActionProps) => void;
}
export const DandyUpdateNotifications: React.FC<DandyUpdateNotificationsProps> = props => {
    const { sortedUpdateNotifications, onClick } = props;
    const classes = useStyles();

    const dismissAll = (notifications: DandyNotification[]) => {
        const dismissNotifications = notifications.filter(
            notification =>
                notification.dismiss && notification.secondaryAction?.type === NotificationActionType.DISMISS,
        );
        const eventIds = dismissNotifications.map((notification: DandyNotification) => {
            const dismissAction = notification.secondaryAction as DismissAction;
            return dismissAction.eventId;
        });

        const action: ActionProps = {
            eventIds,
            text: 'dismiss all',
            type: NotificationActionType.DISMISS_ALL,
        };
        onClick(action);
    };

    return (
        <Grid item xs={12}>
            {sortedUpdateNotifications.map(data => {
                const key = new Date(data.date).getTime();
                const isToday = moment(data.date).isSame(new Date(), 'day');
                return (
                    <React.Fragment key={key}>
                        <Grid container className={classes.section}>
                            <Text variant={'body2'} medium color={'GRAY'}>
                                {isToday ? 'Today' : DateUtils.format(data.date, 'Do [of] MMMM, YYYY')}
                            </Text>

                            {data.dismissAll && (
                                <Link
                                    className={classes.dismissAll}
                                    onClick={() => {
                                        dismissAll(data.notifications);
                                    }}
                                    underline={'none'}
                                >
                                    <Text variant={'body2'} medium color={'GREEN'}>
                                        Dismiss All
                                    </Text>
                                </Link>
                            )}
                        </Grid>
                        {data.notifications.map((notification: DandyNotification) => (
                            <DandyUpdateNotificationsItem
                                notification={notification}
                                key={notification.id}
                                onClick={onClick}
                            />
                        ))}
                    </React.Fragment>
                );
            })}
        </Grid>
    );
};
