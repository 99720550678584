import { useStyles } from './css.util';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const OrderTimelineDivider: React.FC = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.divider}>
            <div className={classes.dividerLine} />
            <Text className={classes.dividerLabel} color={'WHITE'} variant={'caption'}>
                {children}
            </Text>
        </div>
    );
};
