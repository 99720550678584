import { SelectStaffContent } from '../components';
import { useSelectStaffAction } from '../state/select-staff.actions';
import { CreateStaffStep } from '../state/select-staff.progression';
import { useSelectStaffSelector } from '../state/select-staff.selectors';
import { useSession } from '@orthly/session-client';
import { Fade, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

export const SelectStaffAnimation: React.VFC = () => {
    const session = useSession();
    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.Animation);
    const setStep = useSelectStaffAction('SET_SELECT_STAFF_STEP');

    React.useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                setStep(CreateStaffStep.Landing);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [show, setStep]);

    return (
        <SelectStaffContent show={show}>
            <Grid container justifyContent={'center'} alignItems={'center'} style={{ height: 'calc(100% - 50px)' }}>
                <Grid item>
                    <Fade in appear style={{ transitionDelay: '1s' }} timeout={2500}>
                        <Grid>
                            <Text variant={'h4'} align={'center'}>
                                Welcome back to Dandy,
                            </Text>
                            <Text variant={'h4'} align={'center'}>
                                {session?.organization_name}
                            </Text>
                        </Grid>
                    </Fade>

                    <Fade in appear style={{ transitionDelay: '2s' }} timeout={2500}>
                        <Grid style={{ marginTop: 8 }}>
                            <Text variant={'body1'} align={'center'}>
                                We're making some changes to how you log into Dandy
                            </Text>
                        </Grid>
                    </Fade>
                </Grid>
            </Grid>
        </SelectStaffContent>
    );
};
