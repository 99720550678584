import React from 'react';

export const LoggedOutDefaultLeft: React.FC = () => {
    return (
        <>
            Meet Dandy.
            <br />A smarter way to grow your practice.
        </>
    );
};
