import { PracticeUtils } from '../../../../utils/utils';
import { useChatFeatures } from '../../../chat/utils';
import { useIsScannerSelector } from '../../state/checkout.selectors';
import { PracticeScreen } from '@orthly/dentin';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    FlossPaletteUtils,
    createStyles,
    makeStyles,
    Collapse,
    Dialog,
    Grid,
    Link,
    Button,
    Text,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: 'relative',
            maxWidth: 400,
            height: 'auto',
            minHeight: '100vh',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '5vh 0 15vh',
            flexWrap: 'nowrap',
            overflow: 'auto',
            color: FlossPalette.WHITE,
        },
        backdrop: {
            background: FlossPaletteUtils.toRgba('BLACK', 0.8),
            backdropFilter: 'blur(4px)',
        },
        row: {
            padding: `${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(32)}`,
            position: 'relative',
            justifyContent: 'center',
        },
        borderBottom: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        },
        header: {
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'space-between',
            paddingTop: 12,
        },
        headerRow: {
            maxHeight: 'fit-content',
            padding: '12px 0',
            justifyContent: 'center',
        },
        mitchell: {
            objectFit: 'contain',
            width: 200,
        },
        backButton: {
            '&&': {
                color: FlossPalette.BLACK,
            },
            '&:hover,:focus': {
                '&&': {
                    color: FlossPalette.GRAY,
                },
            },
        },
    }),
);

const ExitOrViewOrderButton: React.VFC<{ orderId?: string }> = ({ orderId }) => {
    const history = useHistory();
    const isScanner = useIsScannerSelector();
    const exitOrViewOrder = React.useCallback(() => {
        if (!isScanner) {
            return history.push(`/${PracticeScreen.orders}/${orderId ?? ``}`);
        }
        PracticeUtils.closeIfElectronWindow().catch(console.error);
    }, [history, isScanner, orderId]);
    return (
        <Button
            variant={'secondary'}
            fullWidth
            style={{ backgroundColor: FlossPalette.WHITE, color: FlossPalette.BLACK }}
            onClick={exitOrViewOrder}
        >
            {isScanner ? 'Exit' : 'View Order'}
        </Button>
    );
};

interface CheckoutNewScanConfirmationOverlayProps {
    open: boolean;
    orderId?: string;
    title: string;
}

export const CheckoutNewScanConfirmationOverlay: React.VFC<CheckoutNewScanConfirmationOverlayProps> = ({
    open,
    orderId,
    title,
}) => {
    const isScanner = useIsScannerSelector();
    const classes = useStyles();
    const { openChat } = useChatFeatures();

    return (
        <Dialog
            open={open}
            fullScreen
            TransitionComponent={Collapse as any}
            style={{ zIndex: 2500 }}
            TransitionProps={{ appear: true, exit: true, timeout: 1000, style: { outline: 'none' } }}
            disableEnforceFocus={true}
            BackdropProps={{ className: classes.backdrop }}
            PaperProps={{
                elevation: 0,
                style: { backgroundColor: 'transparent', height: '100vh', alignItems: 'center' },
            }}
        >
            <Grid container className={classes.root}>
                <Grid container className={cx(classes.header, classes.row)}>
                    <Text variant={'h4'} align={'center'} color={'WHITE'}>
                        {title}
                    </Text>
                </Grid>
                <Grid container className={classes.row}>
                    <Text variant={'body1'} style={{ marginBottom: 10, color: FlossPalette.WHITE }} align={'center'}>
                        You can track your order{' '}
                        {isScanner ? 'in your Practice Portal' : 'by clicking the button below'}. If you have questions,
                        please&nbsp;
                        <Link
                            color={'inherit'}
                            onClick={() => openChat()}
                            style={{ cursor: 'pointer', fontWeight: 500 }}
                        >
                            contact us.
                        </Link>
                    </Text>
                </Grid>
                <Grid container className={classes.row}>
                    <ExitOrViewOrderButton orderId={orderId} />
                </Grid>
            </Grid>
        </Dialog>
    );
};
