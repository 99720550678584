import type { ScanbodyInventoryItem } from '../hooks/useScanbodyInventory';
import { useScanbodyInventory } from '../hooks/useScanbodyInventory';
import {
    ScanbodyRequestLinkLookupStage,
    useScanbodyRequestLinkLookupStageSelector,
    useScanbodyRequestSelector,
} from '../state/ScanbodyRequest.selectors';
import { ScanbodyInventoryDisplayWrapper } from './ScanbodyDisplayWrapper';
import { LoadBlocker } from '@orthly/ui';
import { stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    inventoryTitle: {
        display: 'block',
        marginTop: 48,
        marginBottom: 24,
    },
}));

export const ScanbodyInventory: React.FC = () => {
    const classes = useStyles();
    const request = useScanbodyRequestSelector(s => s.request);
    const linkLookupStage = useScanbodyRequestLinkLookupStageSelector();
    const { inventory, loading } = useScanbodyInventory();
    const hintInventory = React.useMemo<ScanbodyInventoryItem[]>(
        () =>
            inventory.filter(
                hint =>
                    (!request.manufacturer || request.manufacturer === hint.implantManufacturer) &&
                    (!request.system || request.system === hint.implantSystem) &&
                    (!request.connection || request.connection === hint.implantConnection),
            ),
        [inventory, request],
    );

    return (
        <LoadBlocker blocking={loading}>
            <Grid container style={{ flexDirection: 'column' }}>
                <Text variant={'h5'} className={classes.inventoryTitle}>
                    {linkLookupStage === ScanbodyRequestLinkLookupStage.EMPTY &&
                        (hintInventory.length ? 'Scan bodies in your inventory' : null)}
                    {linkLookupStage === ScanbodyRequestLinkLookupStage.PARTIAL &&
                        (hintInventory.length ? 'You already have a Scan body for this implant system' : null)}
                </Text>
                <Grid container id={'scan-bodies-item-wrapper'}>
                    {linkLookupStage !== ScanbodyRequestLinkLookupStage.COMPLETE &&
                        hintInventory.map((itemProps, key) => {
                            return <ScanbodyInventoryDisplayWrapper key={key} scanbody={itemProps} />;
                        })}
                </Grid>
            </Grid>
        </LoadBlocker>
    );
};
