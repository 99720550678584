import { FormFieldContainer, InputContainer, RequiredLabel } from './LabeledFields.styles';
import type { SimpleInputProps, SimplePhoneInputProps } from '@orthly/ui';
import { FlossInputThemeProvider, SimpleInput, SimplePhoneInput } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';

type LabeledFieldProps = {
    action?: React.ReactNode;
    footer?: React.ReactNode;
    isRequired?: boolean;
    label: string;
};

const LabeledField: React.FC<LabeledFieldProps> = ({ action, footer, isRequired = false, label, children }) => {
    const Label = isRequired ? RequiredLabel : Text;

    return (
        <FormFieldContainer>
            <Label variant={'body2'} bold>
                {label}
            </Label>
            <InputContainer>
                {children}
                {action}
            </InputContainer>
            {footer}
        </FormFieldContainer>
    );
};

export type LabeledPhoneFieldProps = SimplePhoneInputProps & LabeledFieldProps;

export const LabeledPhoneField: React.FC<LabeledPhoneFieldProps> = ({
    action,
    footer,
    isRequired,
    label,
    TextFieldProps,
    ...inputProps
}) => {
    return (
        <LabeledField label={label} action={action} footer={footer} isRequired={isRequired}>
            <FlossInputThemeProvider flossInputConfig={{ backgroundColor: 'gray', bordered: true }}>
                <SimplePhoneInput
                    {...inputProps}
                    label={''}
                    fullWidth
                    TextFieldProps={{ ...TextFieldProps, type: 'tel', style: { marginTop: 0 } }}
                />
            </FlossInputThemeProvider>
        </LabeledField>
    );
};

export type LabeledTextFieldProps = SimpleInputProps & LabeledFieldProps;

export const LabeledTextField: React.FC<LabeledTextFieldProps> = ({
    action,
    footer,
    isRequired = false,
    label,
    TextFieldProps,
    ...inputProps
}) => {
    return (
        <LabeledField label={label} action={action} footer={footer} isRequired={isRequired}>
            <SimpleInput
                {...inputProps}
                label={''}
                fullWidth
                TextFieldProps={{ ...TextFieldProps, style: { marginTop: 0 } }}
                flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
            />
        </LabeledField>
    );
};
