import type { CheckoutButtonSelectOption, CheckoutButtonSelectProps } from '@orthly/dentin';
import { CheckoutButtonSelect, CheckoutSelectField } from '@orthly/dentin';
import type { SimpleSelectProps } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const MAX_OPTIONS = 5;

export interface CheckoutItemSelectFieldProps extends SimpleSelectProps {
    required: boolean;
    small?: boolean;
    containerStyle?: React.CSSProperties;
    options: CheckoutButtonSelectOption[];
    forceDropdownSelect?: boolean;
    buttonSelectClasses?: CheckoutButtonSelectProps['classes'];
    suggestedValue?: string;
}

// This is just a convenience wrapper that uses the button select if options count is <= MAX_OPTIONS
export const CheckoutItemSelectField: React.FC<CheckoutItemSelectFieldProps> = props => {
    const { required, small, containerStyle, forceDropdownSelect, suggestedValue, ...selectProps } = props;
    if (props.options.length > MAX_OPTIONS || forceDropdownSelect) {
        return (
            <Grid container style={{ paddingTop: 16, ...containerStyle }}>
                <CheckoutSelectField
                    required={required}
                    small={small}
                    {...selectProps}
                    options={props.options.map(o => ({ disabled: o.disabled, label: o.label, value: o.value }))}
                    suggestedValue={suggestedValue}
                />
            </Grid>
        );
    }
    return (
        <CheckoutButtonSelect
            mode={'single'}
            onChange={props.onChange}
            options={props.options}
            label={props.label}
            value={props.value}
            disabled={props.disabled}
            helperText={props.helperText}
            containerStyle={props.containerStyle}
            classes={props.buttonSelectClasses}
            suggestedValue={suggestedValue}
        />
    );
};
