import type { PartnerAppState } from '../../../redux/types';
import { MobileAuthLoginType, MobileAuthActions } from './MobileAuth.actions';
import { generateUseSubstateSelector } from '@orthly/redux-async-actions';
import { handleActions } from 'redux-actions';

export interface MobileAuthState {
    loginType: MobileAuthLoginType;
}

const initialState: MobileAuthState = {
    loginType: MobileAuthLoginType.one_time_code,
};

export const mobileAuthReducer = handleActions<MobileAuthState, any>(
    {
        ...MobileAuthActions.SET_LOGIN_TYPE.reducer<MobileAuthState>((state, { payload: type }) => ({
            ...state,
            loginType: type,
        })),
    },
    initialState,
);

export const useMobileAuthState = generateUseSubstateSelector<PartnerAppState, 'mobileAuth'>('mobileAuth');
