import { useCheckoutSelector } from '../../../../redux';
import { CheckoutNewScanConfirmationOverlay } from '../../components/CheckoutConfirmationOverlay/CheckoutNewScanConfirmationOverlay';
import { CheckoutContentSection } from '../../components/CheckoutLayout/CheckoutContentSection';
import { checkoutRescanOrderSummaryText } from '../../state/CheckoutDisplayUtils';
import { useCheckoutAction } from '../../state/checkout.actions';
import { useScanWithExistingOrderSelector } from '../../state/checkout.selectors';
import { CheckoutSummaryScreenTitle } from '../CheckoutSummaryStep/CheckoutSummaryScreenTitle';
import { AlignerCaseStageSelectionContent } from './AlignerCaseStageSelection';
import { DentureContinuationSectionContent } from './DentureContinuationSection';
import type { ScanWithExistingOrder } from './ExistingOrder';
import { CheckoutExistingOrderTitle, ItemsTableForExistingOrder } from './ExistingOrder';
import { CheckoutTextField } from '@orthly/dentin';
import type { LabsGqlPlacedOrderFragment } from '@orthly/graphql-operations';
import { useReplaceRejectedScanMutation } from '@orthly/graphql-react';
import { LabsGqlExistingPatientOrderType } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, Text, Button, Typography } from '@orthly/ui-primitives';
import React from 'react';

const TITLE_ORDER_TYPE_TO_READABLE: { [_ in LabsGqlExistingPatientOrderType]: string } = {
    [LabsGqlExistingPatientOrderType.ScanRejected]: 'on hold',
    [LabsGqlExistingPatientOrderType.Delivered]: 'delivered',
    [LabsGqlExistingPatientOrderType.Dentures]: 'denture',
    [LabsGqlExistingPatientOrderType.AlignerCase]: 'aligner',
};

function useExistingOrderMatchDefaultTitle(patient_first_name: string, type: LabsGqlExistingPatientOrderType) {
    return React.useMemo(() => {
        if (type === LabsGqlExistingPatientOrderType.AlignerCase) {
            return 'What are you looking to do?';
        }
        const orderType = TITLE_ORDER_TYPE_TO_READABLE[type];
        return `Here's ${patient_first_name.trim()}'s ${orderType} order, what are you trying to do now?`;
    }, [patient_first_name, type]);
}

const RefabricationSectionContent: React.FC<{ scanWithExisting: ScanWithExistingOrder }> = props => {
    const { scanWithExisting } = props;
    const { existingOrderMatch } = scanWithExisting;
    const startRefabrication = useCheckoutAction(`START_REFABRICATION`);
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);

    return (
        <Grid container spacing={2}>
            <CheckoutExistingOrderTitle existingOrderMatch={existingOrderMatch} />
            <Grid container item xs={12} sm={7}>
                <ItemsTableForExistingOrder items={OrderItemV2Utils.parseItems(existingOrderMatch.order.items_v2)} />
            </Grid>
            <Grid container item spacing={2}>
                <Grid item>
                    <Button variant={`primary`} onClick={() => startRefabrication(existingOrderMatch.order)}>
                        Refabricate last order
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant={`secondary`} onClick={() => dismissScreen()}>
                        Place new order
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

type RescannedOrder = LabsGqlPlacedOrderFragment;

type UseSubmitRescanResult = {
    submitting: boolean;
    submit: (notes: string) => Promise<unknown>;
    rescannedOrder?: RescannedOrder;
};

function useReplaceRejectedScan(): UseSubmitRescanResult {
    const scan_id = useCheckoutSelector(s => s.scan?.id);
    const order_id = useScanWithExistingOrderSelector()?.existingOrderMatch.order.id;

    const [rescannedOrder, setRescannedOrder] = React.useState<RescannedOrder | undefined>();

    const [rawSubmit] = useReplaceRejectedScanMutation({
        onCompleted: () => {},
        onError: error => {
            throw error; // rethrow error or promise.catch will eat it and cause a success snackbar
        },
    });
    const { submitting, submit } = useChangeSubmissionFn<any, any>(
        async (notes: string) =>
            scan_id && order_id && (await rawSubmit({ variables: { data: { order_id, scan_id, notes } } })),
        {
            successMessage: () => [`Rescan submitted!`, {}],
            onSuccess: res => {
                if (!res || !res.data) {
                    return;
                }
                setRescannedOrder(res.data.replaceRejectedScan);
            },
        },
    );

    return { submit, submitting, rescannedOrder };
}

const RescanConfirmation: React.FC<{ startRescan: () => void }> = ({ startRescan }) => {
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);
    const [confirmOpen, setConfirmOpen] = React.useState(false);

    return (
        <Grid item container spacing={2}>
            <Grid item>
                <Button variant={'primary'} onClick={startRescan}>
                    Add scan to this order
                </Button>
            </Grid>
            <Grid item>
                <RootActionDialog
                    loading={false}
                    open={confirmOpen}
                    setOpen={() => setConfirmOpen(true)}
                    title={`Are you sure you want to place a new order?`}
                    dialogProps={{ maxWidth: `xs` }}
                    content={
                        <Grid container spacing={2}>
                            <Grid item>
                                <Text variant={'body1'}>
                                    This will create a second order and you'll be charged for both. If you just want to
                                    update the original order, go back and select "Add scan to this order".
                                </Text>
                            </Grid>
                            <Grid item container justifyContent={`flex-end`} spacing={2}>
                                <Grid item>
                                    <Button variant={'secondary'} onClick={() => setConfirmOpen(false)}>
                                        Cancel
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button variant={`primary`} onClick={() => dismissScreen()}>
                                        OK
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    buttonText={``}
                    CustomButton={({ onClick }) => (
                        <Button variant={'secondary'} onClick={onClick}>
                            Place a new order
                        </Button>
                    )}
                />
            </Grid>
        </Grid>
    );
};

const RescanSection: React.FC = () => {
    const existing_order_match = useScanWithExistingOrderSelector()?.existingOrderMatch;

    const [rescanSelected, setRescanSelected] = React.useState(false);
    const [notes, setNotes] = React.useState(``);

    const replaceScan = useReplaceRejectedScan();

    if (!existing_order_match) {
        return null;
    }

    const rescanScreen = (
        <Grid item container spacing={2} direction={`column`}>
            <Grid item>
                <CheckoutSummaryScreenTitle title={'Rescan Info'} />
                <Typography variant={'subtitle1'} color={'textSecondary'}>
                    {checkoutRescanOrderSummaryText(existing_order_match.order)}
                </Typography>
            </Grid>
            <Grid item>
                <CheckoutTextField
                    required
                    disableErrorMessage
                    onChange={value => setNotes(value)}
                    label={'Rescan Notes'}
                    value={notes}
                    helperText={'Please provide as much info as possible'}
                    TextFieldProps={{
                        autoFocus: true,
                        InputLabelProps: { shrink: true },
                        rows: 2,
                        maxRows: 10,
                        multiline: true,
                    }}
                />
            </Grid>
            <Grid item container spacing={2}>
                <Grid item>
                    {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                    <Button variant={'primary'} onClick={() => replaceScan.submit(notes)} disabled={!notes}>
                        Add scan to this order
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant={'ghost'} onClick={() => setRescanSelected(false)}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );

    return (
        <Grid container spacing={2}>
            <Grid item container>
                <CheckoutNewScanConfirmationOverlay
                    open={!!replaceScan.rescannedOrder}
                    orderId={replaceScan.rescannedOrder?.id}
                    title={'Rescan submitted'}
                />
                <CheckoutExistingOrderTitle existingOrderMatch={existing_order_match} />
            </Grid>
            <Grid container item xs={12} sm={7}>
                <ItemsTableForExistingOrder items={OrderItemV2Utils.parseItems(existing_order_match.order.items_v2)} />
            </Grid>
            {rescanSelected ? rescanScreen : <RescanConfirmation startRescan={() => setRescanSelected(true)} />}
        </Grid>
    );
};

interface CheckoutExistingOrderForScanProps {
    scanWithExisting: ScanWithExistingOrder;
}

export const CheckoutExistingOrderForScan: React.FC<CheckoutExistingOrderForScanProps> = props => {
    const { scanWithExisting } = props;
    const { existingOrderMatch } = scanWithExisting;
    const title = useExistingOrderMatchDefaultTitle(scanWithExisting.patient_first_name, existingOrderMatch.type);
    const isRescan = existingOrderMatch.type === LabsGqlExistingPatientOrderType.ScanRejected;
    const isRefab = existingOrderMatch.type === LabsGqlExistingPatientOrderType.Delivered;
    const isAligner = existingOrderMatch.type === LabsGqlExistingPatientOrderType.AlignerCase;
    /* eslint-disable no-nested-ternary */
    return (
        <CheckoutContentSection
            style={{ paddingTop: 16 }}
            title={
                <Text variant={'h6'} style={{ width: '100%', position: 'relative' }}>
                    {title}
                </Text>
            }
        >
            {isRescan ? (
                <RescanSection />
            ) : isRefab ? (
                <RefabricationSectionContent scanWithExisting={scanWithExisting} />
            ) : isAligner ? (
                <AlignerCaseStageSelectionContent scanWithExisting={scanWithExisting} />
            ) : (
                <DentureContinuationSectionContent scanWithExisting={scanWithExisting} />
            )}
        </CheckoutContentSection>
    );
};
