import { stylesFactory, useScreenIsMobileOrVerticalTablet, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ isMobile: boolean }>(() => ({
    welcomeTextContainer: ({ isMobile }) => ({
        marginTop: 20,
        marginBottom: 30,
        width: isMobile ? '80%' : '100%',
        alignSelf: isMobile ? 'center' : 'flex-start',
    }),
    welcomeText: {
        marginBottom: 15,
    },
}));

export const WelcomeText: React.VFC = () => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles({ isMobile });
    return (
        <Grid item container direction={'column'} className={classes.welcomeTextContainer}>
            <Grid item>
                <Text variant={'h4'} medium className={classes.welcomeText}>
                    Welcome to Dandy!
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'} color={'DARK_GRAY'} className={classes.welcomeText}>
                    To get started, please set your delivery address and payment method below.
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    Delivery address and payment method must be set in order to ship your equipment and schedule your
                    training date.
                </Text>
            </Grid>
        </Grid>
    );
};
