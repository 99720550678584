import { styled } from '@orthly/ui-primitives';

export const Layout = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    justifyContent: 'end',
    alignItems: 'end',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'start',
        alignItems: 'end',
        flexDirection: 'column',
        gap: 8,
    },
}));

export const FeedbackLayout = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'end',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'start',
    },
}));

export const DeliveryDateLayout = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginRight: 40,
    [theme.breakpoints.down('lg')]: {
        marginRight: 0,
    },
}));
