import { CheckoutItemSelectField } from '../CheckoutItemSelectField';
import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import type { ItemDataFieldId, LabOrderItemSKUType, StringSelectItemDataField } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import React from 'react';

interface CheckoutStringSelectItemDataFieldProps<ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>
    extends CheckoutItemDataFieldBaseProps<SKU> {
    field: StringSelectItemDataField<ID, SKU>;
}

export const CheckoutStringSelectItemDataField = <ID extends ItemDataFieldId, SKU extends LabOrderItemSKUType>({
    field,
    item,
    updateItem,
}: CheckoutStringSelectItemDataFieldProps<ID, SKU>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    const applyUpdate = (val: string | undefined) => {
        updateItem(field.getUpdatePayload(val ?? null, item));
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <CheckoutItemSelectField
            label={getFieldLabel(field, item)}
            value={value}
            options={[...field.getOptions(item)]}
            onChange={val => applyUpdate(val)}
            required={ItemDataFieldUtils.isItemDataFieldRequired(field, item)}
        />
    );
};
