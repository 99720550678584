import { NoSidebarPageLayout, SidebarRightPageLayout } from '../../layouts/PageLayouts';
import { useStaffMemberMatcher } from '../../redux';
import { usePartnerUiSelector } from '../../redux/ui';
import { GettingStartedRoot } from '../getting_started/GettingStartedRoot.graphql';
import { InboxContentRoot } from './InboxContentRoot';
import { InboxSidebarRightWrapper } from './components/InboxSidebarRight.graphql';
import { CrmPracticeProvider } from './state/useCRMPractice';
import type { LabsGqlGetIsPracticeByodQuery } from '@orthly/graphql-operations';
import { useGetIsPracticeByodQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useScreenIsMobile } from '@orthly/ui-primitives';
import { DandyNotifications } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Content: React.VFC<{
    isPracticeByod?: LabsGqlGetIsPracticeByodQuery;
    showOnboardingInbox?: boolean;
}> = props => {
    const history = useHistory();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const isMobile = useScreenIsMobile();
    const showMobileNotifications = isNativeApp || isMobile;
    const doctorMatches = useStaffMemberMatcher();

    if (props.showOnboardingInbox) {
        return <GettingStartedRoot isByod={!!props.isPracticeByod?.getIsPracticeByod} />;
    }

    if (showMobileNotifications) {
        return <DandyNotifications doctorMatches={doctorMatches} navigationAction={path => history.push(path)} />;
    }

    return <InboxContentRoot />;
};

const OnboardingInboxContentWrapper: React.VFC<{ isPracticeByod: LabsGqlGetIsPracticeByodQuery | undefined }> = ({
    isPracticeByod,
}) => {
    return (
        <NoSidebarPageLayout mobileTitle={'Getting Started'}>
            <Content isPracticeByod={isPracticeByod} showOnboardingInbox />
        </NoSidebarPageLayout>
    );
};

const PostOnboardingInboxContentWrapper: React.VFC<{ isPracticeByod: LabsGqlGetIsPracticeByodQuery | undefined }> = ({
    isPracticeByod,
}) => {
    return (
        <SidebarRightPageLayout mobileTitle={'Home'} sidebarContent={<InboxSidebarRightWrapper />}>
            <Content isPracticeByod={isPracticeByod} />
        </SidebarRightPageLayout>
    );
};

export const InboxRoot: React.VFC<{
    showOnboardingInbox: boolean;
    loading: boolean;
}> = props => {
    const session = useSession();
    const practiceId = session?.organization_id ?? '';

    const { data: isPracticeByod } = useGetIsPracticeByodQuery({
        variables: {
            practiceId,
        },
        skip: !practiceId || !props.showOnboardingInbox,
    });

    if (props.loading) {
        return null;
    }
    return (
        <CrmPracticeProvider>
            {props.showOnboardingInbox && <OnboardingInboxContentWrapper isPracticeByod={isPracticeByod} />}
            {!props.showOnboardingInbox && <PostOnboardingInboxContentWrapper isPracticeByod={isPracticeByod} />}
        </CrmPracticeProvider>
    );
};
