import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import { useDentureCheckoutSelector, useDentureCheckoutStepInfo } from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import type { BigRadioOption } from '@orthly/dentin';
import { BigRadioSelect, CheckoutBodyWrapper } from '@orthly/dentin';
import {
    LabsGqlDenturesBiteAdjustmentType,
    LabsGqlDenturesHandleDiastemaOption,
    LabsGqlDenturesMidlineCorrectionType,
} from '@orthly/graphql-schema';
import { NumberFlipperInput } from '@orthly/ui';
import { CheckboxPrimitive, FormControlLabel, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const midlineCorrectionTypesWithDistance: readonly LabsGqlDenturesMidlineCorrectionType[] = [
    LabsGqlDenturesMidlineCorrectionType.ShiftLeft,
    LabsGqlDenturesMidlineCorrectionType.ShiftRight,
];

const diastemaOptions: BigRadioOption<LabsGqlDenturesHandleDiastemaOption>[] = [
    {
        label: 'No',
        value: LabsGqlDenturesHandleDiastemaOption.NoDiastema,
    },
    {
        label: 'Yes, keep it',
        value: LabsGqlDenturesHandleDiastemaOption.KeepDiastema,
    },
    {
        label: 'Yes, close it',
        value: LabsGqlDenturesHandleDiastemaOption.CloseDiastema,
    },
];

const biteAdjustmentOptions: BigRadioOption<LabsGqlDenturesBiteAdjustmentType>[] = [
    {
        label: 'No',
        value: LabsGqlDenturesBiteAdjustmentType.LeaveAsIs,
    },
    {
        label: 'Open',
        value: LabsGqlDenturesBiteAdjustmentType.Open,
    },
    {
        label: 'Close',
        value: LabsGqlDenturesBiteAdjustmentType.Close,
    },
];

const midlineCorrectionOptions: BigRadioOption<LabsGqlDenturesMidlineCorrectionType>[] = [
    {
        label: 'Leave as is',
        value: LabsGqlDenturesMidlineCorrectionType.LeaveAsIs,
    },
    {
        label: 'Shift left',
        value: LabsGqlDenturesMidlineCorrectionType.ShiftLeft,
    },
    {
        label: 'Shift right',
        value: LabsGqlDenturesMidlineCorrectionType.ShiftRight,
    },
    {
        label: 'Match upper',
        value: LabsGqlDenturesMidlineCorrectionType.MatchUpperMidline,
    },
    {
        label: 'Match lower',
        value: LabsGqlDenturesMidlineCorrectionType.MatchLowerMidline,
    },
];

export const DentureAestheticScreen: React.FC = () => {
    const selectedHandleDiastema = useDentureCheckoutSelector(s => s.fields.handleDiastema);
    const selectedBiteAdjustment = useDentureCheckoutSelector(s => s.fields.biteAdjustment);
    const selectedMidlineCorrection = useDentureCheckoutSelector(s => s.fields.midlineCorrection);
    const selectedAestheticOptions = useDentureCheckoutSelector(s => s.fields.aestheticOptions);
    const setHandleDiastema = useDenturesCheckoutAction('CHOOSE_DENTURE_HANDLE_DIASTEMA');
    const setBiteAdjustment = useDenturesCheckoutAction('CHOOSE_DENTURE_BITE_ADJUSTMENT');
    const setMidlineCorrection = useDenturesCheckoutAction('CHOOSE_DENTURE_MIDLINE_CORRECTION');
    const setAestheticOptions = useDenturesCheckoutAction('CHOOSE_DENTURE_AESTHETIC_OPTIONS');
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(DentureCheckoutStep.DentureAestheticStep);

    // set default value of true if they never toggle
    React.useEffect(() => {
        if (_.isNil(selectedAestheticOptions?.addPostDam)) {
            setAestheticOptions({
                ...selectedAestheticOptions,
                addPostDam: true,
            });
        }
    }, [selectedAestheticOptions, setAestheticOptions]);

    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <div style={{ marginTop: 24, width: '100%' }}>
                <Text variant={'h6'} bold>
                    Does the patient have diastema?
                </Text>
                <BigRadioSelect<LabsGqlDenturesHandleDiastemaOption>
                    direction={'row'}
                    onChange={val => {
                        if (val !== selectedHandleDiastema) {
                            setHandleDiastema(val);
                        }
                    }}
                    options={diastemaOptions}
                    value={selectedHandleDiastema}
                />
            </div>
            <div style={{ width: '100%' }}>
                <Text variant={'h6'} bold>
                    Would you like to adjust the bite?
                </Text>
                <BigRadioSelect<LabsGqlDenturesBiteAdjustmentType>
                    direction={'row'}
                    onChange={val => {
                        if (val !== selectedBiteAdjustment?.adjustment) {
                            setBiteAdjustment({
                                adjustment: val,
                                adjustmentDistance: val !== LabsGqlDenturesBiteAdjustmentType.LeaveAsIs ? 0.5 : null,
                            });
                        }
                    }}
                    options={biteAdjustmentOptions}
                    endAdornmentDirection={'column'}
                    selectedEndAdornment={
                        selectedBiteAdjustment &&
                        selectedBiteAdjustment.adjustment !== LabsGqlDenturesBiteAdjustmentType.LeaveAsIs && (
                            // Without the stopPropagation, we trigger the big radio select onChange at the same time
                            <div style={{ margin: '8px 0 0 -36px' }} onClick={e => e.stopPropagation()}>
                                <NumberFlipperInput
                                    value={selectedBiteAdjustment?.adjustmentDistance ?? 0.5}
                                    onChange={val => {
                                        setBiteAdjustment({
                                            adjustment: selectedBiteAdjustment.adjustment,
                                            adjustmentDistance: val,
                                        });
                                    }}
                                    stepSize={0.5}
                                    min={0.5}
                                    max={20}
                                    unitLabel={'mm'}
                                    variant={'contained'}
                                />
                            </div>
                        )
                    }
                    value={selectedBiteAdjustment?.adjustment ?? undefined}
                />
            </div>
            <div style={{ width: '100%' }}>
                <Text variant={'h6'} bold>
                    Would you like to correct the midline?
                </Text>
                <BigRadioSelect<LabsGqlDenturesMidlineCorrectionType>
                    direction={'row'}
                    onChange={val => {
                        if (val !== selectedMidlineCorrection?.correctionType) {
                            setMidlineCorrection({
                                correctionType: val,
                                correctionDistance: midlineCorrectionTypesWithDistance.includes(val) ? 0.5 : null,
                            });
                        }
                    }}
                    options={midlineCorrectionOptions}
                    value={selectedMidlineCorrection?.correctionType ?? undefined}
                    endAdornmentDirection={'column'}
                    selectedEndAdornment={
                        !!selectedMidlineCorrection &&
                        midlineCorrectionTypesWithDistance.includes(selectedMidlineCorrection.correctionType) && (
                            // Without the stopPropagation, we trigger the big radio select onChange at the same time
                            <div style={{ margin: '8px 0 0 -36px' }} onClick={e => e.stopPropagation()}>
                                <NumberFlipperInput
                                    value={selectedMidlineCorrection?.correctionDistance ?? 0.5}
                                    onChange={val => {
                                        setMidlineCorrection({
                                            ...selectedMidlineCorrection,
                                            correctionDistance: val,
                                        });
                                    }}
                                    stepSize={0.5}
                                    min={0.5}
                                    max={20}
                                    unitLabel={'mm'}
                                    variant={'contained'}
                                />
                            </div>
                        )
                    }
                />
            </div>
            <div style={{ width: '100%' }}>
                <Text variant={'h6'} bold>
                    Other
                </Text>
                <FormControlLabel
                    control={
                        <CheckboxPrimitive
                            color={'secondary'}
                            checked={selectedAestheticOptions?.addStippling}
                            onChange={() =>
                                setAestheticOptions({
                                    ...selectedAestheticOptions,
                                    addStippling: !selectedAestheticOptions?.addStippling,
                                })
                            }
                        />
                    }
                    label={<Text variant={'body2'}>Add stippling</Text>}
                />
                <FormControlLabel
                    control={
                        <CheckboxPrimitive
                            color={'secondary'}
                            checked={selectedAestheticOptions?.addPostDam}
                            onChange={() =>
                                setAestheticOptions({
                                    ...selectedAestheticOptions,
                                    addPostDam: !selectedAestheticOptions?.addPostDam,
                                })
                            }
                        />
                    }
                    label={<Text variant={'body2'}>Add a post-dam</Text>}
                />
                <FormControlLabel
                    control={
                        <CheckboxPrimitive
                            color={'secondary'}
                            checked={selectedAestheticOptions?.correctOcclusalScheme}
                            onChange={() =>
                                setAestheticOptions({
                                    ...selectedAestheticOptions,
                                    correctOcclusalScheme: !selectedAestheticOptions?.correctOcclusalScheme,
                                })
                            }
                        />
                    }
                    label={<Text variant={'body2'}>Correct occlusal scheme to class I</Text>}
                />
            </div>
        </CheckoutBodyWrapper>
    );
};
