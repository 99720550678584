import { ConfirmPhoneNumberForm } from '../../../../components/forms/ConfirmPhoneNumberForm';
import { PageLayout } from '../../components/page-layout/PageLayout';
import { AddPhoneNumberForm } from './forms/AddPhoneNumberForm';
import { useAddPhoneNumber } from './useAddPhoneNumber';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { styled } from '@orthly/ui-primitives';

const NoWrap = styled('span')({
    whiteSpace: 'nowrap',
});

const PhoneForm = () => {
    const props = useAddPhoneNumber();

    return (
        <PageLayout
            pageMessage={'Create profile'}
            panelTitle={'Verify your mobile phone number'}
            panelDescription={
                <>
                    {`An SMS with a confirmation code has been sent to `}
                    <NoWrap>{`${PhoneNumberUtils.prettyPhoneNumber(props.submittedPhoneNumber)}, `}</NoWrap>
                    {`please enter it below to verify your phone number`}
                </>
            }
            onBack={props.hideConfirmation}
        >
            <ConfirmPhoneNumberForm {...props} />
        </PageLayout>
    );
};

export const AddPhoneNumber = () => {
    const { submittedPhoneNumber } = useAddPhoneNumber();
    return submittedPhoneNumber ? <PhoneForm /> : <AddPhoneNumberForm />;
};
