import type { KnownPrice } from '../../PricingGuide.types';

export const CROWN_BRIDGE_MATERIALS: { label: string; field: KnownPrice; isGold: boolean }[] = [
    {
        label: 'Zirconia Monolithic',
        field: 'Full Contour Zirconia',
        isGold: false,
    },
    {
        label: 'Zirconia Translucent Aesthetic',
        field: 'Zirconia Transluscent Aesthetic',
        isGold: false,
    },
    {
        label: 'E.max',
        field: 'Emax',
        isGold: false,
    },
    {
        label: 'E.max Layered',
        field: 'EMAX Layered',
        isGold: false,
    },
    {
        label: 'Porcelain Fused Zirconia (PFZ)',
        field: 'Porcelain Fused Zirconia - PFZ',
        isGold: false,
    },
    {
        label: 'PFM Non-Precious',
        field: 'PFM Non Precious',
        isGold: false,
    },
    {
        label: 'PFM - Noble - White',
        field: 'PFM - Noble - White',
        isGold: true,
    },
    {
        label: 'PFM - High Noble - White',
        field: 'PFM - High Noble - White',
        isGold: true,
    },
    {
        label: 'PFM - High Noble - Yellow',
        field: 'PFM - High Noble - Yellow',
        isGold: true,
    },
    {
        label: 'Non-Precious',
        field: 'Non-Precious',
        isGold: false,
    },
    {
        label: 'Noble - White',
        field: 'Noble - White',
        isGold: true,
    },
    {
        label: 'Noble - Yellow',
        field: 'Noble - Yellow',
        isGold: true,
    },
    {
        label: 'High Noble - Rich Yellow',
        field: 'High Noble - Rich Yellow',
        isGold: true,
    },
];
