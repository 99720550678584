import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { FETCH_SAVED_ORDER_SEARCH_ARGS } from '@orthly/dentin';
import { useGetSavedSearchesQuery } from '@orthly/graphql-react';

export const useSavedSearchData = () => {
    const { data, refetch } = useGetSavedSearchesQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        variables: FETCH_SAVED_ORDER_SEARCH_ARGS,
    });

    return { savedSearchData: data?.getSavedSearches ?? [], refetchSavedSearches: refetch };
};

export const useSearchAnalyticsAction = () => {
    return (value: string) => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Horizontal Nav - Search Executed', {
            search: value,
        });
    };
};
