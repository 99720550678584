import { FlossPalette, stylesFactory, Text, Grid, CallMadeIcon, Icon } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const mobileLinkGroupStyles = stylesFactory<{ single: boolean }>(() => ({
    link: props => ({
        padding: props.single ? 16 : '12px 16px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    }),
    linkItemsWrapper: {
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        backgroundColor: FlossPalette.WHITE,
        borderRadius: 8,
    },
}));

const MobileLinkItems: React.FC<MobileLinkGroupProps> = props => {
    const classes = mobileLinkGroupStyles({ single: !props.header });
    const history = useHistory();
    return (
        <Grid container className={classes.linkItemsWrapper}>
            {props.linkItems.map((linkItem, index) => {
                const url = linkItem.href ? `${linkItem.href}` : null;
                return (
                    <Grid
                        container
                        key={linkItem.title}
                        style={
                            props.linkItems.length > 1 && index !== props.linkItems.length - 1
                                ? { borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }
                                : { borderBottom: 'none' }
                        }
                    >
                        <Grid
                            container
                            onClick={() => {
                                if (linkItem.externalUrl) {
                                    window.open(linkItem.externalUrl, '_blank');
                                } else {
                                    void (url ? history.push(url) : linkItem.onClick && linkItem.onClick());
                                }
                            }}
                            className={classes.link}
                        >
                            <Text
                                data-test={`mobile-link-item-title-${linkItem.title
                                    .toLowerCase()
                                    .replaceAll(' ', '-')}`}
                                variant={'body2'}
                            >
                                {linkItem.title}
                            </Text>
                            {linkItem.externalUrl ? (
                                <CallMadeIcon
                                    style={{ color: FlossPalette.PRIMARY_FOREGROUND, fontSize: 18, marginRight: 4 }}
                                />
                            ) : (
                                <Icon icon={'ChevronRight'} style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                            )}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

interface MobileLinkItem {
    href?: string;
    title: string;
    externalUrl?: string;
    onClick?: () => Promise<void>;
}

interface MobileLinkGroupProps {
    header?: string;
    subHeader?: string;
    linkItems: MobileLinkItem[];
}

export const MobileLinkGroup: React.FC<MobileLinkGroupProps> = props => {
    const { header, subHeader, linkItems } = props;

    return (
        <Grid container style={{ marginBottom: 16 }}>
            {header && (
                <Grid container direction={'column'}>
                    {header && (
                        <Text data-test={`mobile-link-item-header-${header.toLowerCase()}`} medium variant={'body2'}>
                            {header}
                        </Text>
                    )}
                    {subHeader && (
                        <Text variant={'caption'} color={'DARK_GRAY'} style={{ marginBottom: 16 }}>
                            {subHeader}
                        </Text>
                    )}
                </Grid>
            )}
            <MobileLinkItems linkItems={linkItems} header={header} />
        </Grid>
    );
};
