import type { CheckoutScreen } from '../../screens/CheckoutScreen';
import { CheckoutItemV2Manager } from '../CheckoutItemV2Manager';
import { CheckoutActions } from '../checkout.actions';
import { getItemScreens } from '../checkout.screens';
import {
    checkoutPatientInfoStepComplete,
    getIsAestheticProductInCheckout,
    getIsSurgicalGuideInCheckout,
    implantCheckoutEnabled,
    smileStyleScreenIsNeeded,
    sortedCheckoutStepTwoScreens,
} from '../checkout.selectors';
import type { CheckoutItemV2, CheckoutState, CheckoutStepTwoScreen } from '../checkout.state';
import { ExtraCartItemV2Utils } from '@orthly/items';
import { Assert } from '@orthly/runtime-utils';
import _ from 'lodash';
import type { ReducerMap } from 'redux-actions';

function onBackStep1(state: CheckoutState): CheckoutState {
    if (state.itemScreenIndex > 0) {
        return { ...state, itemScreenIndex: state.itemScreenIndex - 1 };
    }
    return { ...state, step: 0 };
}

function enabledStepTwoScreensSorted(state: CheckoutState) {
    const enabledStepTwoScreens: Record<CheckoutStepTwoScreen, boolean> = {
        smileStyle: smileStyleScreenIsNeeded(state),
        implantImages: implantCheckoutEnabled(state),
        surgicalGuideCbctUpload: getIsSurgicalGuideInCheckout(state),
        aestheticPhotoUpload: getIsAestheticProductInCheckout(state),
        refab: !!state.refab,
        summary: true,
    };
    return sortedCheckoutStepTwoScreens.filter(s => enabledStepTwoScreens[s]);
}

function onBackStep2(state: CheckoutState): CheckoutState {
    const enabledSortedScreens = enabledStepTwoScreensSorted(state);
    const previousStep2Screen = enabledSortedScreens[enabledSortedScreens.indexOf(state.stepTwoScreen) - 1];
    if (!previousStep2Screen) {
        const numItemScreens = getItemScreens(state).length;
        if (numItemScreens === 0) {
            // This should never happen. Go to step 0, because it's safe.
            return { ...state, step: 0, itemScreenIndex: 0 };
        }
        return { ...state, step: 1, itemScreenIndex: numItemScreens - 1 };
    }
    return { ...state, stepTwoScreen: previousStep2Screen };
}

function onNextStep0(state: CheckoutState): CheckoutState {
    if (!checkoutPatientInfoStepComplete(state).complete) {
        return state;
    }
    if (getItemScreens(state).length === 0) {
        // cant advance if we cant find an item to show
        return state;
    }
    return { ...state, step: 1, itemScreenIndex: 0 };
}

function onNextStep1(state: CheckoutState): CheckoutState {
    const itemScreens = getItemScreens(state);
    if (state.itemScreenIndex + 1 < itemScreens.length) {
        return { ...state, itemScreenIndex: state.itemScreenIndex + 1 };
    }
    // No remaining item screens: Advance to step 2.
    // First double check we dont have an incomplete item somewhere earlier in the stack
    const incompleteItem = state.items.find(i => CheckoutItemV2Manager.fieldsRemaining(i) > 0);
    if (incompleteItem) {
        const firstItemScreenIndex = itemScreens.findIndex(s => s.itemIndex === incompleteItem.item_index);
        if (firstItemScreenIndex >= 0) {
            return { ...state, itemScreenIndex: firstItemScreenIndex };
        }
    }
    return {
        ...state,
        stepTwoScreen: enabledStepTwoScreensSorted(state)[0] ?? 'summary',
        step: 2,
    };
}

function onNextStep2(state: CheckoutState): CheckoutState {
    const enabledSortedScreens = enabledStepTwoScreensSorted(state);
    const nextStepTwoScreen = enabledSortedScreens[enabledSortedScreens.indexOf(state.stepTwoScreen) + 1] ?? 'summary';
    return { ...state, stepTwoScreen: nextStepTwoScreen };
}

export function nextCheckoutStepReducer(state: CheckoutState): CheckoutState {
    const { step } = state;
    switch (step) {
        case 0:
            return onNextStep0(state);
        case 1:
            return onNextStep1(state);
        case 2:
            return onNextStep2(state);
        default:
            Assert.unreachable(step);
            return state;
    }
}

export function prevCheckoutStepReducer(state: CheckoutState): CheckoutState {
    const { step } = state;
    switch (step) {
        case 0:
            return state;
        case 1:
            return onBackStep1(state);
        case 2:
            return onBackStep2(state);
        default:
            Assert.unreachable(step);
            return state;
    }
}

// This handles additional logic for checkout v2 active items, is called after the base resolvers in checkout.reducer
export const v2ActiveItemReducerMap: ReducerMap<CheckoutState, any> = {
    ...CheckoutActions.NEXT_STEP_V2.reducer<CheckoutState>(nextCheckoutStepReducer),
    ...CheckoutActions.PREV_STEP_V2.reducer<CheckoutState>(prevCheckoutStepReducer),

    ...CheckoutActions.REMOVE_ITEM.reducer<CheckoutState>((state, action) => {
        const screens = getItemScreens(state);
        // Find the first screen for the item after the one that was removed.
        const targetScreenIndex = screens.findIndex(s => s.itemIndex && s.itemIndex > action.payload);

        if (targetScreenIndex < 0) {
            // No more screens, go to step 2.
            const stepTwoScreen = enabledStepTwoScreensSorted(state)[0] ?? 'summary';
            return { ...state, stepTwoScreen, itemScreenIndex: screens.length - 1, step: 2 };
        }
        return { ...state, itemScreenIndex: targetScreenIndex };
    }),

    ...CheckoutActions.SET_EXTRA_UNITS.reducer<CheckoutState>((state, action) => {
        // Set extra items based on the unit types in action.payload, preserving
        // items that already exist in state.items.

        // First collect extra items that already exist.
        const existingExtras: { [key: string]: CheckoutItemV2 } = {};
        for (const item of state.items) {
            const extraType = ExtraCartItemV2Utils.getExtraItemUnitType(item);
            if (extraType) {
                existingExtras[extraType] = item;
            }
        }

        const scanItems = state.items.filter(i => i.from_scan_export);
        const allItems = [...state.items, ...state.removed_scan_items];
        const highestCurrentIndex = _.maxBy(allItems, i => i.item_index)?.item_index || 0;

        // Build a new extras array based on action.payload, re-using
        // items from `existingExtras` when possible.
        const newExtras = action.payload.map((unit_type, index) => {
            const existing = existingExtras[unit_type];
            if (existing) {
                return existing;
            }
            return CheckoutItemV2Manager.buildNewItem(unit_type, highestCurrentIndex + 1 + index);
        });

        return {
            ...state,
            items: [...scanItems, ...newExtras],
        };
    }),

    ...CheckoutActions.ADD_SCAN_ITEM_BACK.reducer<CheckoutState>((state, action) => {
        // Find the first screen for the newly-inserted item.
        const targetScreenIndex = getItemScreens(state).findIndex(s => s.itemIndex === action.payload);

        // If that index is behind our current position, go back to it.
        if (targetScreenIndex >= 0 && targetScreenIndex < state.itemScreenIndex) {
            return { ...state, itemScreenIndex: targetScreenIndex };
        }
        return state;
    }),

    ...CheckoutActions.SET_ACTIVE_ITEM_IDX.reducer<CheckoutState>((state, action) => {
        const isNewIndex = (screen: CheckoutScreen) => screen.itemIndex === action.payload;
        const screens = getItemScreens(state);

        // Don't jump back unless we're past all the item's screens.
        const lastScreenIndexForItem = _.findLastIndex(screens, isNewIndex);
        if (lastScreenIndexForItem < 0 || state.itemScreenIndex <= lastScreenIndexForItem) {
            return state;
        }
        const firstScreenIndexForItem = screens.findIndex(isNewIndex);
        return { ...state, itemScreenIndex: firstScreenIndexForItem };
    }),
};
