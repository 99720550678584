import { useCheckoutSelector, useAlignerCheckoutSelector } from '../../../../redux';
import { ImageTypeToTitleMap, AlignerTypeIconMap } from '../../AlignerCheckout/MobileCheckout.util';
import { IndividualPhotoUpload } from '../../components/AlignerCheckoutPhotoUpload/IndividualPhotoUpload';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import {
    ImageTypeUploadOrder,
    ImageTypeUploadRequired,
    useAlignerCheckoutStepInfo,
} from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import type { LabsGqlPendingAlignerCasePhotoMapInput as PendingAlignerCasePhotoMap } from '@orthly/graphql-schema';
import { LabsGqlAlignerCasePhotoType as AlignerPhotoType } from '@orthly/graphql-schema';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { OrderingStorageConfigs, getFullStoragePath } from '@orthly/shared-types';
import { OrthlyBrowserConfig, OrthlyFrontendApps, LoadBlocker } from '@orthly/ui';
import { stylesFactory, FlossPalette, Grid, Text } from '@orthly/ui-primitives';
import { useFirebasePreview, useFirebaseFilesInBucket } from '@orthly/veneer';
import { basename, extname } from 'path-browserify';
import React from 'react';

interface UploadReviewScreenContentProps {
    step: AlignerCheckoutStep;
}

const useStyles = stylesFactory(() => ({
    root: { height: '100%', alignContent: 'flex-start', justifyContent: 'center', position: 'relative' },
    imgContainer: {
        height: 220,
        overflow: 'hidden',
        borderRadius: 8,
        marginBottom: 8,
    },
    loadBlocker: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        height: '100%',
        position: 'relative',
    },
    photoRow: { padding: '24px 0', justifyContent: 'space-between' },
    emptyImg: {
        backgroundColor: FlossPalette.TAN,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    img: {
        minHeight: '100%',
        minWidth: '100%',
        maxHeight: '200%',
        flexShrink: 0,
        backgroundColor: FlossPalette.TAN,
    },
}));

interface SavePhotosArgs {
    setPhotos: (map: PendingAlignerCasePhotoMap) => void;
    scanId?: string;
    allUploadedImages: string[];
}

function savePhotos(args: SavePhotosArgs) {
    const { setPhotos, scanId, allUploadedImages } = args;
    if (!scanId) {
        return;
    }
    const typeToFileMap: { [K in AlignerPhotoType]?: string | null } = Object.values(AlignerPhotoType).reduce(
        (typeMap, type: AlignerPhotoType) => {
            const filePath = getUploadedAlignerPhotoForType(allUploadedImages, type);
            if (filePath) {
                return { ...typeMap, [type]: filePath };
            }
            return typeMap;
        },
        {},
    );
    // ensure that all required types have an appropriate image uploaded
    if (!Object.values(AlignerPhotoType).every(type => !ImageTypeUploadRequired[type] || typeToFileMap[type])) {
        return;
    }
    setPhotos(typeToFileMap);
}

function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = React.useRef(callback);

    // Remember the latest callback if it changes.
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Run callback once on mount
    React.useEffect(() => {
        callback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Set up the interval.
    React.useEffect(() => {
        // Don't schedule if no delay is specified.
        if (delay === null) {
            return;
        }

        const id = setInterval(() => savedCallback.current(), delay);

        return () => clearInterval(id);
    }, [delay]);
}

function getUploadedAlignerPhotoForType(uploadedImages: string[], type: AlignerPhotoType) {
    // check if the file stem matches the type
    return uploadedImages.find(path => basename(path, extname(path)) === type);
}

function useAlignerPhotoExistingPath(
    storageConfig: BucketStoragePathConfig,
    setUploadedImages: (state: string[]) => void,
) {
    const [pollingEnabled, setPollingEnabled] = React.useState<boolean>(true);
    const { execute } = useFirebaseFilesInBucket(storageConfig.bucketName);
    const setPhotos = useAlignerCheckoutAction('SET_ALIGNER_PHOTOS');
    const scan = useCheckoutSelector(s => s.scan);

    const poll = React.useCallback(() => {
        if (!pollingEnabled) {
            return;
        }

        execute(storageConfig.path)
            .then(val => {
                const newImages = val.items.map(i => i.toString());
                setUploadedImages(newImages);

                const scanId = scan?.id;

                // Keep polling until _all_ photos are uploaded because we can't be certain if optional photos are coming or not
                const allPhotosUploaded =
                    scanId !== undefined &&
                    Object.values(AlignerPhotoType).every(
                        type => getUploadedAlignerPhotoForType(newImages, type) !== undefined,
                    );
                savePhotos({ setPhotos, scanId, allUploadedImages: newImages });
                if (allPhotosUploaded) {
                    setPollingEnabled(false);
                    return;
                }
                !pollingEnabled && setPollingEnabled(true);
            })
            .catch(() => {});
    }, [pollingEnabled, execute, storageConfig.path, setUploadedImages, scan?.id, setPhotos]);
    useInterval(poll, pollingEnabled ? 5000 : null);
}

interface AlignerPhotoProps {
    uploadedImages: string[];
    initialImages: string[];
    type: AlignerPhotoType;
    scanId: string;
    isProd: boolean;
}

const AlignerPhoto: React.FC<{ path: string; firebaseLoading: boolean }> = props => {
    const classes = useStyles();
    const { path, firebaseLoading } = props;
    const [imgLoading, setImgLoading] = React.useState(false);
    const loading = firebaseLoading || imgLoading;
    // here the nocache random number prevents img from caching a previously deleted image
    return (
        <LoadBlocker blocking={loading} ContainerProps={{ className: classes.loadBlocker }}>
            {path && (
                <img
                    src={`${path}?nocache=${Math.random().toString()}`}
                    alt={'aligner uploaded'}
                    className={classes.img}
                    onLoadStart={() => setImgLoading(true)}
                    onLoad={() => setImgLoading(false)}
                />
            )}
        </LoadBlocker>
    );
};

const AlignerPhotoPlaceholder: React.FC<{ type: AlignerPhotoType }> = ({ type }) => {
    const classes = useStyles();
    const Icon = AlignerTypeIconMap[type];
    return (
        <Grid container className={classes.emptyImg}>
            <Icon style={{ height: 48, width: 48, color: FlossPalette.GRAY }} />
        </Grid>
    );
};

interface DesktopPreviewProps {
    fileUploaded?: string;
    firebaseLoading: boolean;
    type: AlignerPhotoType;
}

const DesktopPreview: React.FC<DesktopPreviewProps> = props => {
    const classes = useStyles();
    const { type, firebaseLoading, fileUploaded } = props;
    return (
        <Grid className={classes.imgContainer}>
            {fileUploaded ? (
                <AlignerPhoto path={fileUploaded} firebaseLoading={firebaseLoading} />
            ) : (
                <AlignerPhotoPlaceholder type={type} />
            )}
        </Grid>
    );
};

const AlignerPhotoWrapper: React.FC<AlignerPhotoProps> = props => {
    const { scanId, type, uploadedImages } = props;
    const isDeviceUpload = useAlignerCheckoutSelector(s => s.deviceUpload);
    const fileUploaded = React.useMemo(
        () => getUploadedAlignerPhotoForType(uploadedImages, type),
        [type, uploadedImages],
    );
    const { result: src, loading: firebaseLoading, reset } = useFirebasePreview(fileUploaded);
    React.useEffect(() => {
        reset();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fileUploaded]);

    return (
        <Grid item xs={4}>
            {isDeviceUpload ? (
                <IndividualPhotoUpload
                    scanId={scanId}
                    type={type}
                    uploadingFromThisDevice={true}
                    uploadedGCSPath={src}
                />
            ) : (
                <DesktopPreview type={type} firebaseLoading={firebaseLoading} fileUploaded={src} />
            )}
            <Grid container justifyContent={'space-between'}>
                <Text variant={'body2'} display={'inline'} style={{ marginTop: 4 }}>
                    {ImageTypeToTitleMap[type]}
                </Text>
                <Text variant={'caption'} display={'inline'} align={'right'} color={'BLACK'} style={{ marginTop: 10 }}>
                    {ImageTypeUploadRequired[type] ? 'Required' : 'Optional'}
                </Text>
            </Grid>
        </Grid>
    );
};

const PhotoReviewRow: React.FC<{ scanId: string }> = ({ scanId }) => {
    const classes = useStyles();
    const previouslyUploadedImages = useAlignerCheckoutSelector(s => s.uploadedImageGCSPaths);
    const [uploadedImages, setUploadedImages] = React.useState<string[]>(previouslyUploadedImages);

    const config = new OrthlyBrowserConfig(OrthlyFrontendApps.practice);
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.aligners, scanId);
    useAlignerPhotoExistingPath(storagePathConfig, setUploadedImages);
    return (
        <Grid container className={classes.photoRow} spacing={3}>
            {ImageTypeUploadOrder.map((type, idx) => (
                <AlignerPhotoWrapper
                    type={type}
                    scanId={scanId}
                    isProd={config.isProduction}
                    uploadedImages={uploadedImages}
                    initialImages={previouslyUploadedImages}
                    key={idx}
                />
            ))}
        </Grid>
    );
};

const AlignerCheckoutUploadReviewScreenContent: React.FC<UploadReviewScreenContentProps> = () => {
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.ImageUploadReview);
    const classes = useStyles();
    const scan = useCheckoutSelector(s => s.scan);
    if (!scan) {
        return null;
    }
    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete} style={{ paddingBottom: 24, flexGrow: 1 }}>
            <Grid container className={classes.root}>
                <PhotoReviewRow scanId={scan.id} />
            </Grid>
        </CheckoutBodyWrapper>
    );
};

export const AlignerCheckoutUploadReviewScreen: React.FC = () => {
    const step = useAlignerCheckoutSelector(s => s.step);
    if (step !== AlignerCheckoutStep.ImageUploadReview) {
        return null;
    }
    return <AlignerCheckoutUploadReviewScreenContent step={step} />;
};
