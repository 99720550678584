import type { InvoiceDetailContextType } from '../../components/providers/InvoiceDetailProvider.graphql';
import { formatDate } from '../PrintableInvoice';
import { PrintableInvoiceSummary } from './PrintableInvoiceSummary';
import { PrintableInvoiceTable } from './PrintableInvoiceTable';
import { Format } from '@orthly/runtime-utils';
import { styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const DueDate = styled(Text)({
    marginTop: '24px',
    marginBottom: '12px',
});

export type PrintableInvoiceBodyProps = Pick<InvoiceDetailContextType, 'invoice' | 'rows' | 'rowsLoading'>;

export const PrintableInvoiceBody: React.FC<PrintableInvoiceBodyProps> = ({ invoice, rows, rowsLoading }) => {
    const invoiceAmountDueText = `${Format.currency(invoice.amount_due)} due ${formatDate(invoice.due_date)}`;

    return (
        <div>
            <DueDate variant={'body1'} medium>
                {invoiceAmountDueText}
            </DueDate>

            <PrintableInvoiceSummary invoice={invoice} rows={rows} rowsLoading={rowsLoading} />

            <PrintableInvoiceTable invoice={invoice} rows={rows} rowsLoading={rowsLoading} />
        </div>
    );
};
