import { setIsScannerSessionFromSessionStorage } from '../screens/checkout-v2/ScannerStorage.util';
import { useApolloClient } from '@apollo/client';
import { clearApolloCache } from '@orthly/graphql-react';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import { Grid, Button } from '@orthly/ui-primitives';
import type { ButtonVariant } from '@orthly/ui-primitives';
import React from 'react';

export const LogoutButton: React.FC<{ variant?: ButtonVariant }> = props => {
    const { variant } = props;
    const client = useApolloClient();
    const onClick = React.useCallback(() => {
        clearApolloCache(client).catch(console.error);
        setIsScannerSessionFromSessionStorage(false);
        RetainerTokenStorage.clearAll();
    }, [client]);
    return (
        <Grid container style={{ paddingTop: 16 }}>
            <Button variant={variant ? variant : 'primary'} fullWidth onClick={onClick}>
                Logout
            </Button>
        </Grid>
    );
};
