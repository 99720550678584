import { LabsUtilsBase } from '@orthly/dentin';
import { useLabDeliveryAddressesQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import React from 'react';

export function useScanbodyDeliveryAddress() {
    const practiceId = useSession()?.organization_id;
    const { data, loading } = useLabDeliveryAddressesQuery({
        variables: { partner_id: practiceId ?? null },
        skip: !practiceId,
    });
    const addresses = React.useMemo<string[]>(() => (data?.addresses ?? []).map(LabsUtilsBase.addressToString), [data]);
    return { addresses, loading };
}
