import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { BigRadioSelect } from '@orthly/dentin';
import { DenturesMidlineCorrectionType, LabOrderItemSKUType } from '@orthly/items';
import { NumberFlipperInput } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

const TryInFeedbackDentureMidlineOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Denture>> = ({
    orderItem,
    newItem,
    updateNewItem,
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    const unselect = () => {
        updateNewItem({
            name: 'denture_production_metadata',
            payload: { midline_correction: orderItem.denture_production_metadata?.midline_correction ?? null },
        });
        onUnselected();
    };

    React.useEffect(() => {
        const midlineCorrectionString = newItem.denture_production_metadata?.midline_correction?.correction_distance_mm
            ? `${_.startCase(newItem.denture_production_metadata.midline_correction.correction_type)} ${
                  newItem.denture_production_metadata.midline_correction.correction_distance_mm
              }mm`
            : undefined;
        if (isSelected && midlineCorrectionString && midlineCorrectionString !== description) {
            onDescriptionChanged(midlineCorrectionString);
        }
    }, [isSelected, newItem.denture_production_metadata?.midline_correction, description, onDescriptionChanged]);

    return (
        <TryInFeedbackOptionWrapper
            title={'Midline'}
            subtitle={'Central incisor alignment is off-center'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={unselect}
            hasError={isSelected && showError && !description}
        >
            <BigRadioSelect<DenturesMidlineCorrectionType>
                value={newItem.denture_production_metadata?.midline_correction?.correction_type ?? undefined}
                onChange={val => {
                    if (val !== newItem.denture_production_metadata?.midline_correction?.correction_type) {
                        updateNewItem({
                            name: 'denture_production_metadata',
                            payload: { midline_correction: { correction_type: val, correction_distance_mm: 1 } },
                        });
                    }
                }}
                options={[
                    { label: 'Shift Right', value: DenturesMidlineCorrectionType.ShiftRight },
                    { label: 'Shift Left', value: DenturesMidlineCorrectionType.ShiftLeft },
                ]}
                selectedEndAdornment={
                    <div style={{ margin: -16 }} onClick={e => e.stopPropagation()}>
                        <NumberFlipperInput
                            value={newItem.denture_production_metadata?.midline_correction?.correction_distance_mm ?? 1}
                            onChange={val => {
                                if (!newItem.denture_production_metadata?.midline_correction) {
                                    return;
                                }
                                updateNewItem({
                                    name: 'denture_production_metadata',
                                    payload: {
                                        midline_correction: {
                                            ...newItem.denture_production_metadata.midline_correction,
                                            correction_distance_mm: val,
                                        },
                                    },
                                });
                            }}
                            stepSize={1}
                            min={1}
                            max={10}
                            unitLabel={'mm'}
                            variant={'contained'}
                        />
                    </div>
                }
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackDentureMidlineOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Denture> = {
    sku: [LabOrderItemSKUType.Denture],
    isArchSpecific: false,
    category: 'Midline',
    isEnabled: () => true,
    Component: TryInFeedbackDentureMidlineOption,
};
