import type { TraineeFormFieldsProps } from '../TrainingDataIntake.types';
import { TrainingScanningExperienceOptions } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { RadioGroup, Text } from '@orthly/ui-primitives';
import React from 'react';

export const TraineeExperienceFormField: React.VFC<TraineeFormFieldsProps> = props => {
    const { trainee, setTrainee } = useTrainee(props.traineeAtom);

    return (
        <RadioGroup
            variant={'compact'}
            value={trainee.experience}
            handleChange={value => {
                setTrainee(oldValue => ({ ...oldValue, experience: value }));
            }}
            options={TrainingScanningExperienceOptions.map(experience => ({
                label: <Text variant={'body2'}>{experience}</Text>,
                id: experience,
            }))}
            aria-label={`Experience level of person ${props.listIndex + 1}`}
        />
    );
};
