import { useCheckoutAction } from '../../state/checkout.actions';
import type { ImplantScreenInterface } from '../../state/reducers/implantScreens';
import { getSelectedCrownMaterialValue, getUnnFromItemWithImplant } from './implantSelectionGetters';
import { CheckoutRadioIcon } from '@orthly/dentin';
import { ToothUtils } from '@orthly/items';
import { FlossPalette, stylesFactory, Grid, Tooltip, Icon, Text } from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    materialOptionCard: {
        backgroundColor: FlossPalette.TAN,
        border: `2px solid ${FlossPalette.TAN}`,
        marginBottom: 16,
        padding: 24,
        cursor: 'pointer',
        borderRadius: 4,
    },
    checkedCard: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        border: `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
    },
}));

interface CrownMaterialOption {
    value: string;
    label?: string;
    subtitle: string;
    star: (unn: number) => boolean;
}

const crownMaterialOptions: CrownMaterialOption[] = [
    {
        value: 'Zirconia Monolithic',
        subtitle: 'Strongest out of all materials. A favorite for posterior crowns.',
        star: ToothUtils.toothIsPosterior,
    },
    {
        value: 'Zirconia Translucent Aesthetic',
        subtitle: 'More translucent than zirconia monolithic, less durable.',
        star: ToothUtils.toothIsPosterior,
    },
    {
        value: 'Porcelain Fused Zirconia',
        label: 'Porcelain Fused Zirconia (Layered)',
        subtitle: 'A strong and aesthetic option.',
        star: () => false,
    },
    {
        value: 'Emax Full Contour',
        subtitle: 'More aesthetic option, least durable.',
        star: () => false,
    },
    {
        value: 'Emax Layered Porcelain',
        subtitle: 'More aesthetic option, least durable. Hand layered for extra translucency.',
        star: () => false,
    },
];

interface CrownMaterialCardProps {
    materialOption: CrownMaterialOption;
    selectedMaterialValue?: string;
    item_index: number;
    unn: number;
}
const CrownMaterialCard: React.VFC<CrownMaterialCardProps> = props => {
    const { materialOption, selectedMaterialValue, item_index, unn } = props;
    const classes = useStyles();
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');
    const checked = materialOption.value === selectedMaterialValue;
    const onClick = React.useCallback(() => {
        if (checked) {
            return null;
        }
        updateItem({ item_index, change: { name: 'material', payload: materialOption.value } });
        // Reset abutment material whenever crown material changes.
        updateItem({ item_index, change: { name: 'abutment_material', payload: '' } });
        // reset original material as well to avoid a original_material vs material mismatch
        // this action should only be used in implant checkout
        updateItem({ item_index, change: { name: `clear_original_material` } });
    }, [checked, materialOption.value, updateItem, item_index]);
    return (
        <div className={cx(classes.materialOptionCard, checked ? classes.checkedCard : '')} onClick={onClick}>
            <Grid container direction={'row'} alignItems={'center'}>
                <Grid item xs={1}>
                    <CheckoutRadioIcon checked={checked} />
                </Grid>
                <Grid item xs={11}>
                    <Text variant={'body2'} medium style={{ marginBottom: 4 }}>
                        {materialOption.star(unn) && (
                            <Tooltip title={`Recommended for tooth ${unn}`}>
                                <Icon
                                    icon={'Star'}
                                    fontSize={'small'}
                                    style={{
                                        marginRight: 10,
                                        marginBottom: -4,
                                        color: FlossPalette.SECONDARY_FOREGROUND,
                                    }}
                                />
                            </Tooltip>
                        )}
                        {materialOption.label ?? materialOption.value}
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ maxWidth: 540 }}>
                        {materialOption.subtitle}
                    </Text>
                </Grid>
            </Grid>
        </div>
    );
};

export const CrownMaterialScreen: ImplantScreenInterface = {
    title: 'Select the crown material',
    Component: ({ item }) => {
        const selectedCrownMaterialValue = getSelectedCrownMaterialValue(item);
        const unn = getUnnFromItemWithImplant(item);
        return (
            <Grid container direction={'row'} style={{ marginTop: 24 }}>
                {crownMaterialOptions.map(materialOption => (
                    <Grid item xs={8} key={materialOption.value}>
                        <CrownMaterialCard
                            materialOption={materialOption}
                            selectedMaterialValue={selectedCrownMaterialValue}
                            item_index={item.item_index}
                            unn={unn}
                        />
                    </Grid>
                ))}
            </Grid>
        );
    },
    enableNext: ({ item }) => {
        const selectedCrownMaterialValue = getSelectedCrownMaterialValue(item);
        return (
            !!selectedCrownMaterialValue && crownMaterialOptions.map(o => o.value).includes(selectedCrownMaterialValue)
        );
    },
};
