import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const TMJTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'anterior',
                type: 'Anterior Deprogrammer',
                price: getPrice('TMJ - Anterior Deprogrammer'),
            },
            {
                id: 'gelb',
                type: 'Gelb',
                price: getPrice('TMJ - Gelb'),
            },
            {
                id: 'nti',
                type: 'NTI',
                price: getPrice('NTI Night Guard'),
            },
            {
                id: 'dawson',
                type: 'Dawson B-Splint',
                price: getPrice('TMJ - Dawson B-splint'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'other-removables-tmj'}
            title={'TMJ Appliances'}
            columns={[
                {
                    id: 'type',
                    title: 'Type',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
