import { useCheckoutSelector } from '../../../../redux';
import { useCheckoutAction } from '../../state/checkout.actions';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import {
    useDentureCheckoutPropSelector,
    useDentureCheckoutSelector,
    useDentureCheckoutStepInfo,
} from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { RadioButton } from './DentureSelectTypeScreen';
import { CheckoutBodyWrapper, DentureCheckoutWaxRimNextSteps } from '@orthly/dentin';
import { DentureType } from '@orthly/items';
import { SimpleInput } from '@orthly/ui';
import { stylesFactory, FlossPalette, Grid, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    container: {
        marginTop: 32,
        paddingBottom: 24,
    },
    aboutNextSteps: {
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        marginTop: 48,
        paddingTop: 24,
    },
    numberedBullet: {
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        display: 'inline-block',
        textAlign: 'center',
        width: 26,
        height: 26,
        marginRight: 16,
        borderRadius: '50%',
    },
}));

const AboutNextSteps: React.VFC = () => {
    const classes = useStyles();

    return (
        <div className={classes.aboutNextSteps}>
            <Text variant={'h5'} bold style={{ marginBottom: 24 }}>
                Once the order is placed, here's what happens next:
            </Text>
            <DentureCheckoutWaxRimNextSteps isChairside={false} />
        </div>
    );
};

const DigitalDesignPreviewSection: React.VFC = () => {
    const setSelected = useCheckoutAction('SET_WAXUP_SELECTED');
    const waxupSelected = useCheckoutSelector(s => s.waxupState.selected);
    return (
        <Grid container direction={'row'} spacing={2}>
            <Grid item xs={12}>
                <Text variant={'h6'} medium style={{ marginTop: 24 }}>
                    Review & approve design before fabrication?
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    Receive a 3D virtual model of the proposed restoration for assessment and approval. Once ready, you
                    have two days to review & approve or request changes.
                </Text>
            </Grid>
            <Grid item container direction={'row'} xs={6} spacing={2}>
                <Grid item xs={12}>
                    <RadioButton
                        label={'Yes'}
                        description={
                            'Important: We will place your case on hold until you approve the design. You can find the proposed design in the Portal.'
                        }
                        isSelected={waxupSelected}
                        onSelect={() => setSelected(true)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RadioButton label={'No'} isSelected={!waxupSelected} onSelect={() => setSelected(false)} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export const DentureNotesScreen: React.VFC = () => {
    const classes = useStyles();
    const { fields } = useDentureCheckoutPropSelector(['fields']);
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(DentureCheckoutStep.OrderNotesStep);
    const selectedDentureType = useDentureCheckoutSelector(state => state.fields.dentureType);
    const { value: enableFullDentureDesignPreview } = useFeatureFlag('enableFullDentureDesignPreview');

    const addDentureNotes = useDenturesCheckoutAction('ADD_DENTURE_NOTES');
    const setImplantSupport = useDenturesCheckoutAction('SET_IMPLANT_SUPPORT');
    const waxRimNotes = fields.dentureNotes;
    if (!isActiveStep) {
        return null;
    }
    const isWaxRim = fields.dentureType === DentureType.WaxRim;
    const implantSupport = fields.implantSupport;

    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <Grid container className={classes.container} alignContent={'flex-start'}>
                {implantSupport && implantSupport.isImplantSupported && (
                    <Grid item xs={7} style={{ marginBottom: 16 }}>
                        <Text variant={'h6'} medium style={{ marginBottom: 8 }}>
                            {isWaxRim ? `(Optional)` : ``} Please detail the collar heights of the abutments (mm)
                        </Text>
                        <SimpleInput
                            onChange={val => setImplantSupport({ ...implantSupport, collarHeightsMm: val })}
                            value={implantSupport.collarHeightsMm}
                            label={'Example: 2.5mm, 2.6mm'}
                            fullWidth
                            TextFieldProps={{ multiline: false }}
                        />
                    </Grid>
                )}
                <Grid item xs={7}>
                    <Text variant={'h6'} medium style={{ marginBottom: 8 }}>
                        Anything else we should know about this order?
                    </Text>
                    <SimpleInput
                        onChange={val => addDentureNotes(val ?? '')}
                        value={waxRimNotes}
                        label={'Optional notes'}
                        fullWidth
                        TextFieldProps={{ multiline: true, rows: 4 }}
                    />
                </Grid>
                {selectedDentureType &&
                    [DentureType.Copy, DentureType.Immediate, DentureType.WaxRimContinuation].includes(
                        selectedDentureType,
                    ) &&
                    enableFullDentureDesignPreview && <DigitalDesignPreviewSection />}
                {isWaxRim && (
                    <Grid item xs={12}>
                        <AboutNextSteps />
                    </Grid>
                )}
            </Grid>
        </CheckoutBodyWrapper>
    );
};
