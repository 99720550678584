import { stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        backgroundColor: FlossPalette.TAN,
        width: 328,
        position: 'absolute',
        marginTop: 24,
        borderRadius: 16,
        alignContent: 'flex-start',
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    header: {
        padding: '24px',
        width: '100%',
    },
}));

interface HelpInfoBoxProps {
    header: React.ReactNode;
    body: React.ReactNode;
}

export const HelpInfoBox: React.FC<HelpInfoBoxProps> = props => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root}>
            <Grid item className={classes.header}>
                {props.header}
            </Grid>
            {props.body}
        </Grid>
    );
};
