import { CancelOrderReturnAction } from '../actions/practitioners/CancelOrderReturn';
import { PrintOrderReturnLabel } from './PrintOrderReturnLabel';
import { useOrderSummaryRowButtonStyles } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { LabsGqlOrderReturnStatus } from '@orthly/graphql-schema';
import { ShippingUtils } from '@orthly/shared-types';
import { useScreenIsMobileOrVerticalTablet, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

const PracticeOrderReturnActionsCellSalesOrder_Fragment = graphql(`
    fragment PracticeOrderReturnActionsCellSalesOrder_Fragment on OrderPracticeListViewEntry {
        active_return {
            type
            status
            label_url
            id

            shipment {
                carrier
                tracking_number
            }
        }
    }
`);

export const OrderReturnActionsCell: React.FC<{
    orderFragment: FragmentType<typeof PracticeOrderReturnActionsCellSalesOrder_Fragment>;
    refetch: () => Promise<void>;
}> = ({ orderFragment, refetch }) => {
    const buttonClasses = useOrderSummaryRowButtonStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const { active_return } = getFragmentData(PracticeOrderReturnActionsCellSalesOrder_Fragment, orderFragment);

    if (
        !active_return ||
        active_return.status === LabsGqlOrderReturnStatus.Cancelled ||
        active_return.status === LabsGqlOrderReturnStatus.Received
    ) {
        return null;
    }

    return (
        <Grid
            container
            style={{ width: 'auto', margin: isMobile ? undefined : 'auto 0px' }}
            wrap={isMobile ? 'wrap' : 'nowrap'}
            onClick={e => e.stopPropagation()}
        >
            {status === LabsGqlOrderReturnStatus.Transit && !isMobile ? (
                <Button
                    variant={'ghost'}
                    endIcon={'TrackIcon'}
                    href={ShippingUtils.getTrackingLinkForCarrier(
                        active_return.shipment.carrier,
                        active_return.shipment.tracking_number,
                    )}
                    className={buttonClasses.button}
                    analytics={{
                        AssetName: 'Track Return',
                        AssetLocation: 'Practice - Order List',
                    }}
                >
                    Track Return
                </Button>
            ) : (
                <div style={{ display: 'flex', gap: 16 }}>
                    <CancelOrderReturnAction
                        orderReturnStatus={active_return.status}
                        orderReturnType={active_return.type}
                        orderReturnId={active_return.id}
                        refetch={refetch}
                    />
                    <PrintOrderReturnLabel
                        slim
                        labelUrl={active_return.label_url}
                        status={active_return.status}
                        title={'Print label'}
                    />
                </div>
            )}
        </Grid>
    );
};
