import { toggleAction } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { VisibilityToggleMobile } from './VisibilityToggleMobile';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    toggleContainer: {
        overflow: 'auto',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        gap: '5px',
    },
}));

export const GeneralReviewVisibilityControlsMobile: React.VFC = () => {
    const classes = useStyles();
    const { appearance, setAppearance, toggles } = useGuidedWaxupContext();
    return (
        <Grid item container className={classes.toggleContainer}>
            {toggles.map(toggle => (
                <VisibilityToggleMobile
                    key={toggle.label}
                    toggleLabel={toggle.label}
                    toggleAction={toggle.setChecked}
                />
            ))}
            {_.sortBy(appearance.restoratives.CAD, pma => pma.payloadModel.name).map(pma => (
                <VisibilityToggleMobile
                    key={pma.payloadModel.name}
                    toggleLabel={pma.payloadModel.name}
                    toggleAction={toggleAction(setAppearance, pma)}
                />
            ))}
        </Grid>
    );
};
