import type { CheckoutState, ImplantCheckoutScanbodiesState, ImplantCheckoutState } from '../checkout.state';
import { CartItemV2Utils } from '@orthly/items';
import type { ICartImplantToothGroup } from '@orthly/items';
import { Assert } from '@orthly/runtime-utils';
import _ from 'lodash';

export type CheckoutImplantToothGroup = ICartImplantToothGroup & { item_index: number };

export const implantToothGroups = (s: CheckoutState): CheckoutImplantToothGroup[] =>
    _.sortBy(
        s.items.flatMap(item => {
            if (!CartItemV2Utils.itemTypeHasImplant(item)) {
                return [];
            }
            return CartItemV2Utils.getImplantGroups(item).map(tooth => ({ ...tooth, item_index: item.item_index }));
        }),
        t => t.unn,
    );

const initialImplantCheckoutScanbodiesState: ImplantCheckoutScanbodiesState = {
    currentToothIndex: 0,
    motion: 'next',
};

export const implantCheckoutInitialState: ImplantCheckoutState = {
    screen: 'instructions',
    missingUploadWarningShowing: false,
    allowInstructions: false,
    scanbodiesScreen: initialImplantCheckoutScanbodiesState,
};

type ImplantToothCompletionState =
    /** Can't advance because haven't picked a scanbody + validated index */
    | { status: 'Incomplete'; index: number }
    /** Picked a scanbody, can advance + validated index */
    | { status: 'Complete'; index: number }
    /** Invalid index, array might have been mutated under us? */
    | { status: 'InvalidIndex' };

// NOTE
// implant checkout treats scanbody_id as:
// undefined -> not selected
// null -> user selected other/no scanbody used

export function currentImplantToothScanbodyStatus(
    state: ImplantCheckoutScanbodiesState,
    teeth: CheckoutImplantToothGroup[],
): ImplantToothCompletionState {
    const index = state.currentToothIndex;
    const tooth = teeth[index];

    if (!tooth) {
        return { status: 'InvalidIndex' };
    }

    if (tooth.implant_metadata.scanbody_id === undefined) {
        return { index, status: 'Incomplete' };
    }

    return { index, status: 'Complete' };
}

function fixupIndex(
    newIndex: number,
    teeth: CheckoutImplantToothGroup[],
    motion: ImplantCheckoutScanbodiesState['motion'],
): 'changeScreen' | ImplantCheckoutScanbodiesState {
    if (newIndex < 0 || newIndex >= teeth.length) {
        return 'changeScreen';
    }

    return { motion, currentToothIndex: newIndex };
}

function changeImplantItemTooth(state: CheckoutState): {
    next: 'changeScreen' | ImplantCheckoutScanbodiesState;
    prev: 'changeScreen' | ImplantCheckoutScanbodiesState;
} {
    const { currentToothIndex } = state.implantCheckout.scanbodiesScreen;
    const teeth = implantToothGroups(state);

    const completionState = currentImplantToothScanbodyStatus(state.implantCheckout.scanbodiesScreen, teeth);

    if (completionState.status === 'InvalidIndex') {
        console.warn(`got invalid tooth index: ${currentToothIndex} into array of len ${teeth.length}`);
        // restart the flow on first tooth
        return { next: fixupIndex(0, teeth, 'next'), prev: fixupIndex(0, teeth, 'back') };
    }

    const validatedIndex = completionState.index;

    if (completionState.status === 'Incomplete') {
        return {
            next: fixupIndex(validatedIndex, teeth, 'next'),
            prev: fixupIndex(validatedIndex - 1, teeth, 'back'),
        };
    }

    if (completionState.status === 'Complete') {
        return {
            next: fixupIndex(validatedIndex + 1, teeth, 'next'),
            prev: fixupIndex(validatedIndex - 1, teeth, 'back'),
        };
    }

    Assert.unreachable(completionState);
}

// these reducer functions will be used in implant-checkout.reducer.ts
export function prevImplantStepForScanbodiesScreen(
    state: CheckoutState,
): 'changeScreen' | ImplantCheckoutScanbodiesState {
    return changeImplantItemTooth(state).prev;
}

export function nextImplantStepForScanbodiesScreen(
    state: CheckoutState,
): 'changeScreen' | ImplantCheckoutScanbodiesState {
    return changeImplantItemTooth(state).next;
}
