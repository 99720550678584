import { useAlignerCheckoutPropSelector } from '../../../../../redux';
import { useAlignerCheckoutAction } from '../../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../../state/aligners-checkout.types';
import { AlignerCheckoutAlignerArchButton } from './AlignerCheckoutArchButton';
import { CheckoutBodyWrapper, AlignerTreatmentAreaSelection } from '@orthly/dentin';
import { OrderItemArch } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { useScreenIsMobileOrVerticalTablet, createStyles, Grid, makeStyles, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            display: 'flex',
            width: '100%',
            gap: '24px',
            marginTop: 32,
            minHeight: 224,
        },
    }),
);

const AlignerCheckoutTreatmentAreaSelection: React.VFC = () => {
    const { alignerTreatmentArea } = useAlignerCheckoutPropSelector(['alignerTreatmentArea']);
    const setAlignerTreatmentArea = useAlignerCheckoutAction('SET_ALIGNER_TREATMENT_AREA');

    return <AlignerTreatmentAreaSelection value={alignerTreatmentArea} onChange={setAlignerTreatmentArea} />;
};

export const AlignerCheckoutArchSelection: React.FC = () => {
    const classes = useStyles();
    const { alignerArch } = useAlignerCheckoutPropSelector(['alignerArch']);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.ArchSelection);
    if (!isActiveStep) {
        return null;
    }
    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <Grid container direction={'column'} wrap={'nowrap'}>
                <div
                    className={classes.container}
                    style={
                        isMobile
                            ? { flexDirection: 'column', height: 'min-content' }
                            : { flexDirection: 'row', height: '50%' }
                    }
                >
                    <AlignerCheckoutAlignerArchButton arch={OrderItemArch.Dual} />
                    <AlignerCheckoutAlignerArchButton arch={OrderItemArch.Upper} />
                    <AlignerCheckoutAlignerArchButton arch={OrderItemArch.Lower} />
                </div>
                <LoadBlocker
                    blocking={alignerArch === undefined}
                    loader={<React.Fragment />}
                    LoaderContainerProps={{ style: { cursor: 'inherit' } }}
                    OverlayProps={{ style: { cursor: 'inherit' } }}
                >
                    <Text variant={'h6'} style={{ marginTop: 40, marginBottom: 24 }}>
                        Which teeth do you want to treat?
                    </Text>
                    <AlignerCheckoutTreatmentAreaSelection />
                </LoadBlocker>
            </Grid>
        </CheckoutBodyWrapper>
    );
};
