import { useOpenOrderDetailPage } from '../labs/LabsUtils';
import { TryInFeedbackContent } from './TryInFeedbackContent';
import { TRY_IN_FEEDBACK_NAVIGATION_BUTTONS_HEIGHT } from './TryInFeedbackNavigationButtons';
import { TryInFeedbackOrderNotFoundError } from './screens/TryInFeedbackError';
import { ORDER_TOOLBAR_HEIGHT, OrderToolbar, PracticeFullScreenDialog } from '@orthly/dentin';
import { useGetLabOrderForTryInFeedbackQuery } from '@orthly/graphql-react';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    rootWrapper: {
        height: `calc(100vh - ${ORDER_TOOLBAR_HEIGHT}px)`,
        backgroundColor: FlossPalette.TAN,
        overflowY: 'auto',
        marginBottom: TRY_IN_FEEDBACK_NAVIGATION_BUTTONS_HEIGHT,
    },
}));

export const TryInFeedbackRoot: React.VFC = () => {
    const classes = useStyles();
    const { orderId: orderIdParam } = useParams<{ orderId: string }>();
    const history = useHistory();
    const openOrder = useOpenOrderDetailPage();

    const { data, loading } = useGetLabOrderForTryInFeedbackQuery({
        variables: { id: orderIdParam },
    });
    const order = data?.lab_order;

    return (
        <PracticeFullScreenDialog>
            <OrderToolbar order={order} onClose={() => (order?.id ? openOrder(order.id) : history.goBack())} />
            <div className={classes.rootWrapper}>
                {!loading && (order ? <TryInFeedbackContent order={order} /> : <TryInFeedbackOrderNotFoundError />)}
            </div>
        </PracticeFullScreenDialog>
    );
};
