import { usePricingGuide } from '../../PricingGuide.context';
import type { KnownPrice } from '../../PricingGuide.types';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import { stylesFactory } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    list: {
        margin: 0,
        padding: 0,
        '&>li': {
            lineHeight: '2em',
        },
    },
    included: {
        paddingLeft: '1em',
        '&>li': {
            listStyleType: '"✓"',
            paddingLeft: '0.5ch',
        },
    },
    price: {
        listStyle: 'none',
    },
}));

export const ImplantSurgeryOptionsTable: React.FC = () => {
    const { getPrice, formatPrices } = usePricingGuide();
    const classes = useStyles();

    const rows = React.useMemo(() => {
        const formatSitePrices = (oneSite: KnownPrice, extraSite: KnownPrice) =>
            formatPrices([getPrice(oneSite), getPrice(extraSite)], ([oneSitePrice, extraSitePrice]) => (
                <ul className={classNames(classes.list, classes.price)}>
                    <li>{`${oneSitePrice} one site`}</li>
                    <li>{`+${extraSitePrice} / additional sites`}</li>
                </ul>
            ));
        // The base price for tissue supported guide is for four sites instead of one
        const formatTissueSupportedSitePrices = (oneSite: KnownPrice, extraSite: KnownPrice) =>
            formatPrices([getPrice(oneSite), getPrice(extraSite)], ([oneSitePrice, extraSitePrice]) => (
                <ul className={classNames(classes.list, classes.price)}>
                    <li>{`${oneSitePrice} up to four sites`}</li>
                    <li>{`+${extraSitePrice} / additional sites`}</li>
                </ul>
            ));

        return [
            {
                id: 'dandy print only',
                option: (
                    <>
                        Dandy <strong>Print-Only*</strong>
                    </>
                ),
                included: ['Surgical Guide Fabrication', 'Sleeve(s)'],
                price: getPrice('Implant Surgery - Dandy Print Only'),
                formattedPrice: formatSitePrices(
                    'Implant Surgery - Dandy Print Only',
                    'Implant Surgery - Dandy Print Only - Extra Site',
                ),
            },
            {
                id: 'dandy treatment planning',
                option: (
                    <>
                        Dandy <strong>Treatment Planning</strong>
                    </>
                ),
                included: ['Treatment Planning', 'Surgical Guide Design', 'Live / Video Review'],
                price: getPrice('Implant Surgery - Dandy Treatment Planning'),
                formattedPrice: formatSitePrices(
                    'Implant Surgery - Dandy Treatment Planning',
                    'Implant Surgery - Dandy Treatment Planning  - Extra Site',
                ),
            },
            {
                id: 'dandy bundle tooth supported guide',
                option: (
                    <>
                        Dandy Bundle <strong>Tooth Supported Guide</strong>
                    </>
                ),
                included: [
                    'Treatment Planning',
                    'Surgical Guide Design',
                    'Surgical Guide Fabrication',
                    'Drill Sleeve(s)',
                    'Printed Model',
                    'Live / Video Review',
                ],
                price: getPrice('Implant Surgery - Dandy Bundle: Tooth Supported Guide'),
                formattedPrice: formatSitePrices(
                    'Implant Surgery - Dandy Bundle: Tooth Supported Guide',
                    'Implant Surgery - Dandy Bundle: Total Supported Guide - Extra Site',
                ),
            },
            {
                id: 'dandy bundle tissue supported guide',
                option: (
                    <>
                        Dandy Bundle <strong>Tissue Supported Guide</strong>
                    </>
                ),
                included: [
                    'Treatment Planning',
                    'Surgical Guide Design',
                    'Surgical Guide Fabrication',
                    'Drill Sleeve(s)',
                    'Printed Model',
                    'Live / Video Review',
                ],
                price: getPrice('Implant Surgery - Dandy Bundle: Tissue Supported Guide'),
                formattedPrice: formatTissueSupportedSitePrices(
                    'Implant Surgery - Dandy Bundle: Tissue Supported Guide',
                    'Implant Surgery - Dandy Bundle: Tissue Supported Guide - Extra Site',
                ),
            },
        ];
    }, [classes, getPrice, formatPrices]);

    return (
        <PricingGuideTable
            id={'implant-surgery-options'}
            title={'Options'}
            columns={[
                {
                    id: 'option',
                    title: 'Option',
                    sortBy: ({ id }) => id,
                },
                {
                    id: 'included',
                    title: 'Included',
                    render: ({ included }) => (
                        <ul className={classNames(classes.included, classes.list)}>
                            {included.map(item => (
                                <li key={item}>{item}</li>
                            ))}
                        </ul>
                    ),
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ formattedPrice }) => formattedPrice,
                },
            ]}
            rows={rows}
            footnotes={[
                '* For Dandy Print-Only, doctor must provide all files including .stl files of the surgical guide.',
            ]}
        />
    );
};
