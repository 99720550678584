import { useCheckoutSelector } from '../../../../redux';
import {
    getIsSurgicalGuideCbctUploadVisible,
    getIsSurgicalGuideCbctUploadComplete,
} from '../../state/checkout.selectors';
import { useSurgicalGuideCheckoutAction } from '../../state/surgicalGuide-checkout.actions';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, TrashIcon } from '@orthly/ui';
import { FlossPalette, Grid, CheckIcon, Button, Text } from '@orthly/ui-primitives';
import type { FileUploaderFieldResult } from '@orthly/veneer';
import { FileUploaderSingle } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

interface CbctUploaderProps {
    scanID: string;
    cbct_url: string | null;
}
const CbctUploader: React.VFC<CbctUploaderProps> = ({ scanID, cbct_url }) => {
    const setCbctUrl = useSurgicalGuideCheckoutAction('SET_CBCT_URL');
    const onUploadComplete = React.useCallback(
        (results: FileUploaderFieldResult[]) => {
            setCbctUrl(results[0]?.uploadedPath ?? null);
        },
        [setCbctUrl],
    );
    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.surgicalGuideCbct,
        scanID,
    );
    return (
        <div style={{ marginTop: 24, width: '100%' }}>
            {!cbct_url ? (
                <FileUploaderSingle
                    storagePathConfig={storagePathConfig}
                    fileFields={[{ fieldKey: 'cbct' }]}
                    onComplete={onUploadComplete}
                    dropzoneContent={<>Drop your ZIP file here, or click to browse files.</>}
                    prependTimestampToFilename={true}
                />
            ) : (
                <>
                    <Text variant={'body1'} medium style={{ color: FlossPalette.SECONDARY_FOREGROUND }}>
                        <CheckIcon style={{ marginBottom: -4, marginRight: 8 }} /> File uploaded successfully!
                    </Text>
                    <Grid
                        container
                        direction={'column'}
                        justifyContent={'center'}
                        style={{
                            width: '100%',
                            height: 240,
                            backgroundColor: FlossPalette.TAN,
                            marginTop: 24,
                            textAlign: 'center',
                        }}
                    >
                        <Grid item>
                            <img src={'/checkout/zipFile.svg'} alt={'Zip File Uploaded'} />
                        </Grid>
                        <Grid item>
                            <Text variant={'caption'} medium>
                                {_.last(cbct_url.split('/'))}
                            </Text>
                        </Grid>
                        <Grid item style={{ marginTop: 16 }}>
                            <Button variant={'ghost'} onClick={() => setCbctUrl(null)} title={'Remove'}>
                                <TrashIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </>
            )}
        </div>
    );
};

export const SurgicalGuideCbctUploader: React.VFC = () => {
    const isVisible = useCheckoutSelector(getIsSurgicalGuideCbctUploadVisible);
    const isComplete = useCheckoutSelector(getIsSurgicalGuideCbctUploadComplete);
    const scanID = useCheckoutSelector(s => s.scan?.id);
    const cbct_url = useCheckoutSelector(s => s.surgicalGuideCheckout.cbct_url);
    return (
        <CheckoutBodyWrapper visible={isVisible} isComplete={isComplete}>
            {scanID && <CbctUploader scanID={scanID} cbct_url={cbct_url} />}
        </CheckoutBodyWrapper>
    );
};
