import { OrderSummaryRowBase } from './OrderSummaryRowBase';
import { OrderSummaryRowItemIcons } from './OrderSummaryRowItemIcons/OrderSummaryRowItemIcons';
import type { ProductUnitType } from '@orthly/items';
import { isValidProductUnitType } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { OrderFormatUtils } from '@orthly/shared-types';
import React from 'react';

interface OrderSummaryRowProps {
    onClick: () => void;
    openOrder: (orderId: string) => void;
    stepperSection?: React.ReactNode;
    isInbox?: boolean;
    isLast?: boolean;
    // When true, the row will be rendered as if it were on mobile
    forceMobileDisplay?: boolean;
    replacedByOrder?: {
        id: string;
        orderNumber: string;
    };
    orderId: string;
    orderNumber: string;
    needsAttention: boolean;
    isCanceled: boolean;
    Buttons?: React.ReactNode;
    itemsDescription: string;
    productUnitTypes: string[];
    doctorName: string;
    patientName: string;
    className?: string;
}

export const OrderSummaryRow: React.VFC<OrderSummaryRowProps> = ({
    onClick,
    openOrder,
    stepperSection,
    isInbox,
    isLast,
    forceMobileDisplay,
    replacedByOrder,
    orderId,
    orderNumber,
    needsAttention,
    patientName,
    doctorName,
    itemsDescription,
    productUnitTypes,
    Buttons,
    className,
    isCanceled,
}) => {
    return (
        <OrderSummaryRowBase
            patientName={Format.possessiveNoun(patientName)}
            patientSKUs={itemsDescription}
            subtitle={OrderFormatUtils.getDisplayedStaffMemberName(doctorName)}
            onClick={onClick}
            openOrder={openOrder}
            iconsSection={
                <OrderSummaryRowItemIcons
                    productUnitTypes={productUnitTypes.filter((t): t is ProductUnitType => isValidProductUnitType(t))}
                    tooltipTitle={itemsDescription}
                    forceMobileDisplay={forceMobileDisplay}
                />
            }
            stepperSection={stepperSection}
            isLast={isLast}
            isInbox={isInbox}
            forceMobileDisplay={forceMobileDisplay}
            Buttons={Buttons}
            orderId={orderId}
            orderNumber={orderNumber}
            isCanceled={isCanceled}
            needsAttention={needsAttention}
            replacedByOrder={replacedByOrder}
            className={className}
        />
    );
};
