import { ShadeMatchImgPreview } from './ShadeMatchImgPreview';
import { usePollForUpdatedScanImages, useShadeMatchImgSources } from './shadeMatch.hooks';
import type { ShadeFieldAutocompleteCustomPopoverProps } from '@orthly/dentin';
import { FlossPalette, Grid, stylesFactory, Text } from '@orthly/ui-primitives';
import { useFirebasePreviewMulti } from '@orthly/veneer';
import React from 'react';

const useShadeAutocompleteStyles = stylesFactory(theme => ({
    popover: {
        backgroundColor: FlossPalette.WHITE,
        height: '18rem',
        width: '45rem',
        boxShadow: theme.shadows[6],
        overflowY: 'hidden',
        flexWrap: 'nowrap',
    },
    reviewPanel: {
        color: FlossPalette.GRAY,
        overflowY: 'auto',
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: '1rem',
    },
    imgSources: {
        flexWrap: 'nowrap',
        flexShrink: 0,
        overflowX: 'auto',
    },
}));

export const ShadeAutocompletePopover: React.FC<ShadeFieldAutocompleteCustomPopoverProps> = props => {
    usePollForUpdatedScanImages();
    const classes = useShadeAutocompleteStyles();
    const { autocompleteProps } = props;

    const shadeMatchSources = useShadeMatchImgSources();
    const rawSources = React.useMemo(
        () => shadeMatchSources.map((source, idx) => ({ source, name: `Shade match #${idx + 1}` })),
        [shadeMatchSources],
    );
    const { result: loadedSources } = useFirebasePreviewMulti(rawSources);

    return (
        // Using absolute sizing to make this not be bounded by the width of the text box
        <Grid container direction={'row'} className={classes.popover}>
            <Grid style={{ overflowY: 'auto', flexShrink: 0 }} {...autocompleteProps} />
            <Grid className={classes.reviewPanel}>
                <Text style={{ fontWeight: 500, marginBottom: '1rem' }}>Here's the reference from your scan</Text>
                {rawSources.length > 0 && (
                    <Grid container spacing={2} className={classes.imgSources}>
                        {rawSources.map(r => (
                            <ShadeMatchImgPreview
                                key={r.name}
                                name={r.name}
                                imgSrc={loadedSources?.find(img => r.name === img.name)?.source}
                            />
                        ))}
                    </Grid>
                )}
                <Text style={{ marginBottom: '1rem' }}>To confirm, please select the shade you want on the left.</Text>
            </Grid>
        </Grid>
    );
};
