import { useSelectStaff } from '../screens/select-staff/hooks/useSelectStaff';
import { useSelectStaffAction } from '../screens/select-staff/state/select-staff.actions';
import { useFetchAllPracticeEmployeesQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import type { ChairsideToPortalBridge, PortalToChairsideBridge } from '@orthly/shared-types';
import { pusherClient } from '@orthly/veneer';
import isElectron from 'is-electron';
import { atom, useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { useHistory } from 'react-router-dom';

const isChairsideAtom = atom(false);
export const useIsChairside = () => useAtomValue(isChairsideAtom);

/*
 * DOCUMENTATION: https://www.notion.so/orthly/Portal-View-8e29f8d5e1a749b6b8330e9a68a70bc4
 */

// Since the chairside app may be out of date, this function will return undefined
// when it's an old version of chairside and therefore these functions are not enabled yet.
export function usePortalToChairsideBridgeCommand<Name extends keyof PortalToChairsideBridge>(
    name: Name,
): PortalToChairsideBridge[Name] {
    const chairside = useIsChairside();

    return React.useMemo(() => {
        return chairside ? window.portalToChairsideBridge?.[name] : undefined;
    }, [name, chairside]);
}

// The Chairside to portalbridge is a set of utility functions
// that enable direct control of the portal when it is open
// inside of the Chairside app.
// By attaching this bridge to the `window` (in `useChairside` below),
// we can execute scripts in Chairside that call these functions.
function useChairsideToPortalBridge(): ChairsideToPortalBridge {
    const history = useHistory();
    const { selectEmployeeAndSession } = useSelectStaff();
    const skipEmployee = useSelectStaffAction('SKIP_SELECT_STAFF');
    const session = useSession();
    const channels = React.useRef<Array<{ name: string; unsubscribe: () => void }>>([]);

    const { data } = useFetchAllPracticeEmployeesQuery({
        skip: !session || session.organization_type === 'lab',
    });

    return React.useMemo(
        () => ({
            healthCheck: () => true,
            selectDoctor: employeeInfo => {
                if (!employeeInfo) {
                    skipEmployee();
                } else {
                    const employee = data?.fetchAllPracticeEmployees.find(emp => emp.id === employeeInfo.id);
                    if (employee) {
                        selectEmployeeAndSession({
                            __typename: 'DoctorPreferences',
                            id: employeeInfo.id,
                            contact_email: employee.contact_email,
                            roles: employee.roles,
                            name: employee.name,
                            staff_member_id: employee.staff_member_id,
                        });
                    } else {
                        skipEmployee();
                    }
                }
            },
            navigate: (slug: string, queryParamsString?: string) => {
                history.push({ pathname: slug, search: queryParamsString });
            },
            // This is called by Chairside to register a listener for pusher notifications for this case. On message received leverages the bridge to trigger the popup.
            subscribeToMobileUploadLoaded: caseId => {
                const channel = pusherClient.subscribe(caseId, async () => {
                    window.portalToChairsideBridge?.['onMobileUploadLoaded']?.();
                });
                channels.current.push({
                    name: caseId,
                    unsubscribe: channel.unsubscribe,
                });
            },
            unsubscribeToMobileUploadLoaded: caseId => {
                const channel = channels.current.find(c => c.name === caseId);
                channel?.unsubscribe();
                channels.current = channels.current.filter(c => c !== channel);
            },
        }),
        [history, selectEmployeeAndSession, skipEmployee, data?.fetchAllPracticeEmployees, channels],
    );
}

export function useInitChairside(): void {
    const history = useHistory();
    const [chairside, setChairside] = useAtom(isChairsideAtom);
    const bridge = useChairsideToPortalBridge();

    // check the url parameters to see if we should enable chairside mode
    React.useEffect(() => {
        if (chairside || !isElectron()) {
            return;
        }

        const params = new URLSearchParams(history.location.search);
        if (params.get('chairside') === 'true') {
            setChairside(true);
        }
    }, [history, chairside, setChairside]);

    // set and update the bridge every time it changes, when enabled
    React.useEffect(() => {
        if (chairside) {
            window.chairsideToPortalBridge = bridge;
        }
    }, [bridge, chairside]);
}
