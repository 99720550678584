import { DANDY_RECOMMENDED } from '@orthly/items';
import { FlossPalette, Grid, createStyles, makeStyles, CheckIcon, Text } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        selectableHeader: {
            fontWeight: 500,
        },
        selectableContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: '0.5rem',
            marginBottom: '1rem',
        },
        preferenceOption: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f4f4f4',
            color: 'black',
            cursor: 'pointer',
            maxWidth: 'max-content',
            marginBottom: '0.4rem',
            marginRight: '0.4rem',
        },
        preferenceOptionImage: {
            minHeight: '11rem',
            minWidth: '11rem',
            maxWidth: '11rem',
            maxHeight: '11rem',
        },
        selected: {
            border: '1px solid black',
            boxShadow: '0.2rem 0.2rem 0.4rem rgba(120,117,120,0.3)',
        },
        otherSelected: {
            opacity: 0.4,
        },
        optionContent: {
            padding: '0.2rem 1rem',
            height: '100%',
        },
        image: {
            width: '4rem',
            height: '4rem',
        },
        checkCircle: {
            borderRadius: '50%',
            width: '1rem',
            height: '1rem',
            marginRight: '1rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        checkMark: {
            color: FlossPalette.GREEN,
            height: '0.9rem',
            width: '0.9rem',
        },
        optionLabel: {
            textAlign: 'center',
            fontWeight: 500,
            marginTop: '-0.1rem',
        },
        imageOptionLabel: {
            minHeight: '3rem',
            margin: '0.2rem 0',
        },
    }),
);

interface SelectablePreferenceProps {
    id: string;
    selected: boolean;
    onClick: () => void;
    otherSelected?: boolean;
    label?: string;
    imageUrl?: string;
    isDefault?: boolean;
}

const SelectablePreference: React.FC<SelectablePreferenceProps> = props => {
    const { id, label, imageUrl, selected, otherSelected, onClick, isDefault } = props;
    const styles = useStyles();

    const display = label ?? id;
    const isImage = imageUrl !== undefined;
    return (
        <Grid
            onClick={onClick}
            className={classNames(
                styles.preferenceOption,
                isImage ? styles.preferenceOptionImage : undefined,
                selected ? styles.selected : undefined,
            )}
        >
            <Grid
                container
                alignItems={'center'}
                justifyContent={isImage ? 'flex-end' : 'flex-start'}
                className={classNames(styles.optionContent, otherSelected ? styles.otherSelected : undefined)}
                style={{ flexDirection: isImage ? 'column' : 'row' }}
            >
                {imageUrl !== undefined && <img className={styles.image} src={imageUrl} alt={label} />}
                {imageUrl === undefined && (
                    <div
                        className={styles.checkCircle}
                        style={{
                            border: selected ? 'none' : '1px solid black',
                            backgroundColor: selected ? 'white' : '#f4f4f4',
                        }}
                    >
                        {selected && <CheckIcon className={styles.checkMark} />}
                    </div>
                )}
                <Text className={classNames(styles.optionLabel, isImage ? styles.imageOptionLabel : undefined)}>
                    <span style={{ color: FlossPalette.GRAY }}>
                        {isDefault && display !== DANDY_RECOMMENDED ? `${DANDY_RECOMMENDED}: ` : ''}
                    </span>
                    {display}
                </Text>
            </Grid>
        </Grid>
    );
};

interface PreferenceSelectProps {
    header: string;
    subHeader?: string;
    multiple: boolean;
    selectables: Omit<SelectablePreferenceProps, 'onClick'>[];
    onChange: (selectedIds: string[]) => void;
}

export const PreferenceSelect: React.FC<PreferenceSelectProps> = props => {
    const { header, subHeader, multiple, selectables, onChange } = props;
    const styles = useStyles();

    const [selected, setSelected] = React.useState<string[]>(selectables.filter(s => s.selected).map(s => s.id));

    const onSelect = React.useCallback(
        (id: string) => {
            let updatedSelections = [];
            if (!multiple) {
                updatedSelections = [id];
            } else {
                updatedSelections = selected.includes(id) ? selected.filter(item => item !== id) : selected.concat(id);
            }
            setSelected(updatedSelections);
            onChange(updatedSelections);
        },
        [selected, multiple, onChange],
    );

    const isImageSelect = selectables.filter(s => s.imageUrl !== undefined).length === selectables.length;

    return (
        <Grid>
            <Text variant={'h6'} className={styles.selectableHeader}>
                {header}
            </Text>
            {subHeader !== undefined && <Text variant={'body1'}>{subHeader}</Text>}
            <Grid className={styles.selectableContainer} style={{ flexDirection: isImageSelect ? 'row' : 'column' }}>
                {selectables.map(s => (
                    <SelectablePreference key={s.id} {...s} onClick={() => onSelect(s.id)} />
                ))}
            </Grid>
        </Grid>
    );
};
