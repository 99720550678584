import { CheckoutLargeButton } from './CheckoutLargeButton';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface CheckoutLargeRadioRowProps<TValue> {
    value: TValue;
    options: { value: TValue; label?: string }[];
    onChange: (value: TValue) => void;
    icon?: React.ReactNode;
    label: string;
    description?: string;
    small?: boolean;
}

export function CheckoutLargeRadioRow<TValue = string>(
    props: CheckoutLargeRadioRowProps<TValue>,
): React.ReactElement<CheckoutLargeRadioRowProps<TValue>> {
    const { options, onChange, icon, label, description, small } = props;
    return (
        <Grid container direction={'column'} wrap={'nowrap'}>
            <Grid container direction={'row'} wrap={'nowrap'}>
                {icon}
                <Grid container direction={'column'} wrap={'nowrap'}>
                    <Text variant={'h6'}>{label}</Text>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {description}
                    </Text>
                </Grid>
            </Grid>
            <Grid container direction={'row'} wrap={'nowrap'}>
                {options.map(({ value, label = String(value) }) => (
                    <CheckoutLargeButton
                        key={label}
                        radio
                        small={small}
                        selected={value === props.value}
                        label={label}
                        onClick={() => onChange(value)}
                    />
                ))}
            </Grid>
        </Grid>
    );
}
