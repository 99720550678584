import { useSetScreen } from '../../../../../utils/useSetScreen';
import {
    ProvideOrderFeedbackButton,
    RefabricateOrderButton,
} from '../../../../labs/order-detail-v2/components/AskDoctorResponse/DeliveredOrderActions';
import { DeliveryDateLayout, FeedbackLayout, Layout } from './Review.styles';
import { formattedDate } from './Reviews.util';
import { PracticeScreen } from '@orthly/dentin';
import { Text, useScreenIsMobile, Box, useScreenIsMd, Button } from '@orthly/ui-primitives';
import React from 'react';

export const ReviewThisOrderEntrypoint: React.VFC<{
    orderId: string;
    delivery_date?: string | null;
    canRefabricate?: boolean | null;
    canSubmitFeedback?: boolean | null;
    canSubmitTryInFeedback?: boolean | null;
    analyticsLocation?: 'feedback post submit';
}> = ({
    orderId,
    analyticsLocation,
    delivery_date,
    canRefabricate = true,
    canSubmitFeedback = true,
    canSubmitTryInFeedback = false,
}) => {
    const isMobile = useScreenIsMobile();
    const isTablet = useScreenIsMd();
    const setScreen = useSetScreen();

    const shouldHide = !canRefabricate && !canSubmitFeedback && !canSubmitTryInFeedback;

    if (shouldHide) {
        return null;
    }

    return (
        <Layout>
            {delivery_date && (
                <DeliveryDateLayout>
                    <Text variant={'caption'} color={'GRAY'}>
                        Delivery
                    </Text>
                    <Text variant={'body2'} medium>
                        {formattedDate(delivery_date)}
                    </Text>
                </DeliveryDateLayout>
            )}

            <FeedbackLayout style={isTablet ? { flex: 1, justifyContent: 'flex-end' } : {}}>
                <Text variant={isMobile ? 'caption' : 'body2'} color={'BLACK'}>
                    How was the product quality?
                </Text>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: 1,
                    }}
                >
                    {canSubmitFeedback && (
                        <ProvideOrderFeedbackButton
                            orderId={orderId}
                            canSubmitFeedback={canSubmitFeedback}
                            analyticsLocation={analyticsLocation}
                        />
                    )}
                    {canSubmitTryInFeedback && (
                        <Button
                            variant={'ghost'}
                            onClick={ev => {
                                ev.stopPropagation();
                                setScreen(PracticeScreen.try_in_feedback, `/${orderId}`)();
                            }}
                        >
                            Provide product feedback
                        </Button>
                    )}
                    {canRefabricate ? (
                        <>
                            <Text variant={'body2'} color={'GRAY'} style={{ lineHeight: '2em' }}>
                                or
                            </Text>
                            <RefabricateOrderButton
                                orderId={orderId}
                                canRefabricate={canRefabricate}
                                analyticsLocation={analyticsLocation}
                            />
                        </>
                    ) : null}
                </Box>
            </FeedbackLayout>
        </Layout>
    );
};
