import { getPartnerId } from '../../../../../utils/authorization';
import { SettingsBaseModal } from '../../../components/SettingsBaseModal';
import { useSetDoctorPreferences } from '../../../state/usePracticeStaffControls';
import { EmployeeCheckList } from './EmployeeCheckList';
import type { ForwardingSettingsBaseModalProps, ForwardingSettingsBaseModalFormProps } from './ForwardingSettingsBase';
import { useGetNotificationGroup } from './hooks';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlRemoveSendersInput } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useScreenIsMobile, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const RemoveSendersFromPref_Mutation = graphql(`
    mutation RemoveSendersFromPref($data: RemoveSendersInput!) {
        removeSendersFromPref(data: $data)
    }
`);

const EditReceivingSettingsForm: React.VFC<ForwardingSettingsBaseModalFormProps> = props => {
    const { setOpen, employeePrefs, sendersOrReceivers: senders, notificationName, setShowSuccess, refetch } = props;
    const isMobile = useScreenIsMobile();
    const [senderIds, setsSenderIds] = React.useState<string[]>(
        senders.length === 1 && senders[0] ? [senders[0].id] : [],
    );
    const { refetch: currentProfileRefetch } = useSetDoctorPreferences();
    const [submitMtn] = useMutation(RemoveSendersFromPref_Mutation);
    const mtnSubmitter = (data: LabsGqlRemoveSendersInput) => submitMtn({ variables: { data } });
    const { submit } = useChangeSubmissionFn<any, [LabsGqlRemoveSendersInput]>(mtnSubmitter, {
        closeOnComplete: true,
        onSuccess: async () => {
            setShowSuccess(true);
            refetch ? await refetch() : await currentProfileRefetch();
        },
    });
    const notification_group = useGetNotificationGroup(notificationName);

    return (
        <Grid container>
            {senders.length > 1 ? (
                <Grid container>
                    <Text variant={'body2'} style={{ paddingBottom: 8 }}>
                        Select who you would like to stop receiving{' '}
                        <span style={{ fontWeight: 600 }}>{notificationName}</span> notifications from:
                    </Text>
                    <EmployeeCheckList
                        sendersOrReceivers={senders}
                        senderOrReceiverIds={senderIds}
                        setSenderOrReceiverIds={setsSenderIds}
                    />
                </Grid>
            ) : (
                senders[0] && (
                    <Text variant={'body2'}>
                        You are about to stop receiving <span style={{ fontWeight: 600 }}>{notificationName}</span>{' '}
                        notifications from <span style={{ fontWeight: 600 }}>{senders[0].formatted_name}</span>. Are you
                        sure you want to continue?
                    </Text>
                )
            )}
            <Grid container justifyContent={'flex-end'} style={{ paddingTop: 40 }}>
                <Button variant={'secondary'} style={{ marginRight: '8px' }} onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <Button
                    variant={'alert-secondary'}
                    onClick={async () => {
                        await submit({
                            notification_group,
                            preference_id: employeePrefs.id,
                            sender_ids_to_remove: senderIds,
                            notification_name: notificationName,
                        });
                        setOpen(false);
                    }}
                >
                    Stop receiving{isMobile ? '' : ' notifications'}
                </Button>
            </Grid>
        </Grid>
    );
};

export const EditReceivingSettingsModal: React.VFC<ForwardingSettingsBaseModalProps> = props => {
    const {
        open,
        setOpen,
        employeePrefs,
        sendersOrReceivers: senders,
        notificationName,
        setShowSuccess,
        refetch,
    } = props;
    const session = useSession();
    const userId = session?.user_id;
    const partnerId = getPartnerId(session);

    if (!userId || !partnerId) {
        return null;
    }

    return (
        <SettingsBaseModal
            content={
                <EditReceivingSettingsForm
                    setOpen={setOpen}
                    employeePrefs={employeePrefs}
                    sendersOrReceivers={senders}
                    notificationName={notificationName}
                    setShowSuccess={setShowSuccess}
                    refetch={refetch}
                />
            }
            loading={false}
            title={'Just confirming...'}
            open={open}
            setOpen={setOpen}
            buttonText={'Stop receiving'}
            buttonProps={{ style: { padding: 0, height: 'fit-content' } }}
            decreaseTitlePadding
        />
    );
};
