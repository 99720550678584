import { ExtraShadesSummary } from './ExtraShadesSummary';
import { GroupItemSection } from './GroupItemSection';
import type { ItemSectionProps } from './ItemsSection.types';
import { ShadeOcclusalDetails } from './ShadeOcclusalDetails';
import { ItemsSectionDisplayClasses } from './util/displayClasses';
import { dedupedItemToItemsArray } from './util/mergeOrderItems';
import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import type { LabOrderItemSKUType } from '@orthly/items';
import { CartItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface BridgeSectionProps extends Omit<ItemSectionProps, 'item'> {
    item: Extract<ItemSectionProps['item'], { sku: LabOrderItemSKUType.Bridge }>;
}

export const BridgeSection: React.FC<BridgeSectionProps> = ({ item, index, inlinePrefs }) => {
    const { ContainerComponent, fontComponents, Action } = useOrderSummaryComponents();
    const { BoldText, Text, MediumText } = fontComponents;

    const typeRow = (
        <ContainerComponent
            className={ItemsSectionDisplayClasses.DETAIL_ROW}
            style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
            }}
        >
            <MediumText style={{ flex: 23 }}>Type</MediumText>
            <Text style={{ flex: 45 }}>{CartItemV2Utils.getItemDisplayMaterial(item) ?? 'Bridge'}</Text>
            <ShadeOcclusalDetails item={item} />
            {Action ? <Grid style={{ flex: 20 }} /> : null}
        </ContainerComponent>
    );

    const extraShadesRow = (
        <ContainerComponent
            className={ItemsSectionDisplayClasses.DETAIL_ROW}
            style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10pt',
                width: '100%',
            }}
        >
            <MediumText style={{ flex: 23 }}>Shades</MediumText>
            <ContainerComponent style={{ flex: 75, flexDirection: 'row', fontSize: '12pt' }}>
                <ExtraShadesSummary item={item} />
            </ContainerComponent>
            {Action ? <Grid style={{ flex: 20 }} /> : null}
        </ContainerComponent>
    );

    return (
        <GroupItemSection
            item={item}
            index={index}
            inlinePrefs={inlinePrefs}
            Title={
                <ContainerComponent
                    style={{ flexDirection: 'row', paddingTop: '10pt' }}
                    className={ItemsSectionDisplayClasses.ITEM_HEADER}
                >
                    <BoldText className={ItemsSectionDisplayClasses.ITEM_HEADER_TITLE} style={{ flex: 100 }}>
                        {CartItemV2Utils.getFullDisplayName(item, true)}
                    </BoldText>
                    {Action ? (
                        <Grid style={{ flex: 20, textAlign: 'right' }}>
                            <Action items={dedupedItemToItemsArray(item)} />
                        </Grid>
                    ) : null}
                </ContainerComponent>
            }
            Rows={[typeRow, extraShadesRow]}
        />
    );
};
