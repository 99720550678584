import { useFirebase } from '../context';
import { UploadPreview } from './FilePreview';
import { useFirebaseMultiFileUpload, SimpleDropzone } from './FirebaseUpload';
import { GetStoragePathConfig_Query } from './GetStoragePathConfig.graphql';
import { useQuery } from '@apollo/client';
import { LabsGqlLabOrderPhotoType } from '@orthly/graphql-schema';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { LoadBlocker, CameraIcon } from '@orthly/ui';
import { Text, FlossPalette, Collapse, Grid, makeStyles, createStyles, Button } from '@orthly/ui-primitives';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';
import type { DropzoneOptions } from 'react-dropzone';
import useSWR from 'swr';

type FileInfo = {
    type: LabsGqlLabOrderPhotoType;
    fullPath: string;
    name: string;
    downloadUrl: string;
    file: File;
};

type AttachmentsInfo = {
    filesByPath: Record<string, FileInfo>;
    error?: any;
    loading: boolean;
    uploadFiles: (type: LabsGqlLabOrderPhotoType, files: File[]) => Promise<void>;
    deleteFile: (key: string) => Promise<void>;
};

function useFilesInBucket(storagePathConfig: BucketStoragePathConfig): {
    error: any;
    files: FileInfo[];
    setFiles: (mutator: (previous: FileInfo[]) => FileInfo[]) => void;
} {
    const firebase = useFirebase();

    const [files, setFilesRaw] = React.useState<FileInfo[]>([]);

    // NOTE(A1Liu): useSWR doesn't update the function it takes in. This ref allows us to read the most
    // up-to-date value of filesByPath, so that we don't rewrite to state willy-nilly.
    const filesRef = React.useRef(files);

    // NOTE(A1Liu): This ensures updates to the state are seen in the ref without editing the ref in the middle of
    // rendering, which can cause unwanted side effects.
    const setFiles = React.useCallback(
        (dispatch: (previous: FileInfo[]) => FileInfo[]) => {
            setFilesRaw(previous => {
                const newValue = dispatch(previous);
                filesRef.current = newValue;

                return newValue;
            });
        },
        [setFilesRaw, filesRef],
    );

    // This loads data from Firebase, ensuring that Firebase is the source of truth for which files have been uploaded
    const { error } = useSWR(
        storagePathConfig ? [`firebase-list`, storagePathConfig] : null,
        async (_unused, storagePathConfig: BucketStoragePathConfig) => {
            if (!storagePathConfig.bucketName || !storagePathConfig.path) {
                return;
            }

            const firebaseStorage = firebase.storage(storagePathConfig.bucketName);
            const { items: firebaseItems } = await firebaseStorage.ref(storagePathConfig.path).listAll();
            const files = filesRef.current;

            const toDelete = _.differenceBy(files, firebaseItems, item => item.fullPath);
            if (toDelete.length > 0) {
                setFiles((previous: FileInfo[]): FileInfo[] => {
                    return _.differenceBy(previous, toDelete, item => item.fullPath);
                });
            }

            const itemsToAdd = _.differenceBy(firebaseItems, files, item => item.fullPath);
            if (itemsToAdd.length <= 0) {
                return;
            }

            const newFiles = await Promise.all(
                itemsToAdd.map(async (item): Promise<FileInfo> => {
                    const url = await item.getDownloadURL();
                    const res = await axios({ url, responseType: 'blob' });

                    const splitIndex = item.name.indexOf('-');

                    const type = item.name.substring(0, splitIndex) as LabsGqlLabOrderPhotoType;
                    const name = item.name.substring(splitIndex + 1);

                    return {
                        type,
                        name,
                        fullPath: item.fullPath,
                        file: new File([res.data], item.name, { type: res.data.type }),
                        downloadUrl: url,
                    };
                }),
            );

            setFiles((previous: FileInfo[]): FileInfo[] => {
                return [...previous, ...newFiles];
            });
        },
        { refreshInterval: 2000 },
    );

    return { error, files, setFiles };
}

export function useAttachments(scanID: string | null | undefined, suffix: string): AttachmentsInfo {
    const storagePathConfig = useQuery(GetStoragePathConfig_Query, {
        variables: {
            whichConfig: 'ordering',
            uploadType: 'attachments',
            paths: [`${scanID}${suffix}`],
        },
    });

    const { path: storagePath, bucketName } = storagePathConfig.data?.getStoragePathConfig ?? {
        path: '',
        bucketName: '',
    };

    const { error: swrError, files: allFiles, setFiles } = useFilesInBucket({ path: storagePath, bucketName });

    const filesByPath = React.useMemo((): Record<string, FileInfo> => {
        return _.keyBy(allFiles, file => file.fullPath);
    }, [allFiles]);

    const [uploadFn, { deleteFn }] = useFirebaseMultiFileUpload({ path: storagePath, bucketName }, []);

    const uploadAction = React.useCallback(
        async (type: LabsGqlLabOrderPhotoType, files: File[]) => {
            if (!scanID) {
                return;
            }

            const newFiles = await Promise.all(
                files.map(async (file): Promise<FileInfo> => {
                    const name = `${type}-${file.name}`;
                    const fullPath = `${storagePath}/${name}`;
                    const task = await uploadFn(fullPath, name, file);

                    return {
                        fullPath,
                        type,
                        file,
                        name: file.name,
                        downloadUrl: await task.ref.getDownloadURL(),
                    };
                }),
            );

            setFiles((previous: FileInfo[]) => _.unionBy(previous, newFiles, item => item.fullPath));
        },
        [scanID, uploadFn, setFiles, storagePath],
    );

    const deleteAction = React.useCallback(
        async (fullPath: string): Promise<void> => {
            if (!scanID) {
                return;
            }

            const file = filesByPath[fullPath];
            if (!file) {
                return;
            }

            await deleteFn(fullPath, `${file.type}-${file.name}`);

            setFiles((previous: FileInfo[]) => previous.filter(file => file.fullPath !== fullPath));
        },
        [scanID, deleteFn, setFiles, filesByPath],
    );

    const { error: uploadError, loading: uploadLoading, execute: uploadFiles } = useAsyncCallback(uploadAction);
    const { error: deleteError, loading: deleteLoading, execute: deleteFile } = useAsyncCallback(deleteAction);

    return {
        filesByPath,
        uploadFiles,
        deleteFile,
        loading: uploadLoading || deleteLoading,
        error: swrError || uploadError || deleteError,
    };
}

const useStyles = makeStyles(() =>
    createStyles({
        previewContainerDesktop: {
            backgroundColor: FlossPalette.TAN,
            height: 200,
            width: 200,
            borderRadius: 8,
            maxWidth: '100%',
        },
        previewContainerMobile: {
            backgroundColor: FlossPalette.TAN,
            height: '120px',
            width: '100%',
            borderRadius: 8,
        },
        dropzoneContent: {
            backgroundColor: FlossPalette.TAN,
            height: '100%',
            padding: 12,
        },
        actionIcon: {
            color: FlossPalette.STAR_GRASS,
            borderRadius: 36,
            padding: 4,
            height: 32,
            width: 32,
        },
        logo: {
            color: FlossPalette.DARK_TAN,
            height: 24,
            marginBottom: 24,
        },
        collapse: {
            '& .MuiCollapse-wrapper': {
                maxHeight: '100%',
            },
            '& .MuiCollapse-wrapperInner': {
                maxHeight: '100%',
            },
        },
    }),
);

export interface PhotoTypeInfo {
    type: LabsGqlLabOrderPhotoType;
    title: string;

    // by default accepts everything
    acceptFileTypes?: DropzoneOptions['accept'];

    // default value: false
    acceptMultipleFiles?: boolean;
}

interface AttachmentUploaderProps {
    typeInfo: PhotoTypeInfo;
    filesByPath: Record<string, FileInfo>;
    uploadFiles: (type: LabsGqlLabOrderPhotoType, files: File[]) => Promise<void>;
    deleteFile: (key: string) => Promise<void>;
    isDesktop: boolean;
    loading: boolean;
}

export const AttachmentUploader: React.VFC<AttachmentUploaderProps> = props => {
    const { typeInfo, filesByPath, uploadFiles, deleteFile, isDesktop, loading } = props;
    const { title, type, acceptFileTypes, acceptMultipleFiles } = typeInfo;

    const classes = useStyles();

    const uploadedFiles = Object.values(filesByPath).filter(file => file.type === type);
    const showUploaded = uploadedFiles.length > 0 || loading;

    const containerClassName = isDesktop ? classes.previewContainerDesktop : classes.previewContainerMobile;

    const isLargePreview = [LabsGqlLabOrderPhotoType.Xray, LabsGqlLabOrderPhotoType.SurgicalReport].includes(
        props.typeInfo.type,
    );

    return (
        <Grid container item direction={'column'} alignItems={'center'} style={{ width: 'auto' }}>
            {isDesktop && (
                <Text variant={'body2'} medium style={{ marginBottom: 8 }}>
                    {title}
                </Text>
            )}
            <LoadBlocker
                blocking={loading}
                ContainerProps={{
                    container: false,
                    className: containerClassName,
                    style: {
                        padding: acceptMultipleFiles ? 8 : 0,
                    },
                }}
            >
                <Collapse in={!showUploaded} style={{ width: '100%', height: !showUploaded ? '100%' : undefined }}>
                    <SimpleDropzone
                        wrapperStyle={{ border: 0, padding: 0, height: isDesktop ? '200px' : '120px' }}
                        options={{
                            accept: acceptFileTypes,
                            multiple: acceptMultipleFiles ?? false,
                            onDropAccepted: (files: File[]) => uploadFiles(type, files),
                        }}
                        dropzoneContent={
                            <Grid
                                container
                                direction={'column'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                className={classes.dropzoneContent}
                            >
                                {isDesktop ? (
                                    <>
                                        <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginTop: 16 }}>
                                            Drop your photo here
                                        </Text>
                                        <Text variant={'body2'} color={'DARK_GRAY'}>
                                            or
                                        </Text>
                                        <Button
                                            variant={'secondary'}
                                            startIcon={'AttachIcon'}
                                            style={{ marginBottom: 16 }}
                                        >
                                            Browse files
                                        </Button>
                                    </>
                                ) : (
                                    <>
                                        <CameraIcon
                                            preserveAspectRatio={'xMidYMin'}
                                            color={'secondary'}
                                            className={classes.actionIcon}
                                        />

                                        <Text variant={'body2'} medium>
                                            {title}
                                        </Text>
                                    </>
                                )}
                            </Grid>
                        }
                    />
                </Collapse>

                <Collapse
                    in={showUploaded}
                    style={{
                        width: '100%',
                        height: '100%',
                        maxHeight: '100%',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                    }}
                    className={classes.collapse}
                >
                    <Grid
                        container
                        spacing={isLargePreview ? 0 : 1}
                        style={{
                            maxHeight: '100%',
                        }}
                    >
                        {uploadedFiles.map(file => (
                            <Grid key={file.fullPath} item xs={isLargePreview ? 12 : 6}>
                                <UploadPreview
                                    key={file.fullPath}
                                    disabled={loading}
                                    file={file.file}
                                    downloadUrl={file.downloadUrl}
                                    onDelete={() => deleteFile(file.fullPath)}
                                    enlargeClickableArea={!isDesktop}
                                    wrapperProps={{
                                        className: !acceptMultipleFiles ? containerClassName : undefined,
                                    }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Collapse>
            </LoadBlocker>
        </Grid>
    );
};
