import { OrdersToolbarItem } from './OrdersToolbarItem';
import React from 'react';

interface ToolbarFiltersContainerProps {
    filterOne: React.ReactElement;
    filterTwo: React.ReactElement;
    filterThree?: React.ReactElement;
}

export const OrdersToolbarFiltersContainer: React.VFC<ToolbarFiltersContainerProps> = props => {
    const { filterOne, filterTwo, filterThree } = props;
    const gridSize: 2 | 3 = typeof filterThree === 'undefined' ? 3 : 2;
    return (
        <>
            <OrdersToolbarItem size={gridSize}>{filterOne}</OrdersToolbarItem>
            <OrdersToolbarItem size={gridSize}>{filterTwo}</OrdersToolbarItem>
            {typeof filterThree !== 'undefined' && <OrdersToolbarItem size={gridSize}>{filterThree}</OrdersToolbarItem>}
        </>
    );
};
