import { GetStoragePathConfig_Query } from '../GetStoragePathConfig.graphql';
import type { PhotoUploadConfig, UploadedPhotosState } from './PhotoUpload.types';
import { SinglePhotoUpload } from './SinglePhotoUpload';
import { useQuery } from '@apollo/client';
import { ActionCard } from '@orthly/ui';
import type { GridSize } from '@orthly/ui-primitives';
import { FlossPalette, Button, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    photoGrid: {
        flexDirection: 'column',
        '@media (min-width: 767px)': {
            flexDirection: 'row',
        },
    },
    imgContainer: {
        minHeight: 220,
    },
}));

interface PhotoUploadProps {
    uploadConfig: PhotoUploadConfig;
    uploadState: UploadedPhotosState;
    scanID?: string;
    isDesktop: boolean;
    viewInstructionsOptions: {
        showButton: boolean;
        onClick: () => void;
    };
    hideTitle?: boolean;
    imageGridWidthOverride?: boolean | GridSize;
    // called when a photo is uploaded - designed for use in performing side effects,
    // such as sending an analytics event, rather than state management which happens entirely through uploadState
    onUpload?: () => void;
}

/**
 * Renders the contents of a photo upload screen
 *
 * Figma: https://www.figma.com/file/BOrsAvid4M5J2zAFW2UeO7/?node-id=110%3A673
 */
export const PhotoUpload: React.VFC<PhotoUploadProps> = ({
    uploadConfig,
    uploadState,
    scanID,
    isDesktop,
    viewInstructionsOptions,
    hideTitle,
    imageGridWidthOverride,
    onUpload,
}) => {
    const classes = useStyles();
    const { uploadedPhotos, error, refetch } = uploadState;
    const storagePathConfig = useQuery(GetStoragePathConfig_Query, {
        variables: {
            whichConfig: 'ordering',
            uploadType: uploadConfig.firestoreProductName,
            paths: scanID ? [scanID] : [],
        },
    });

    if (error) {
        return (
            <div style={{ padding: 16 }}>
                <ActionCard
                    variant={'alert'}
                    title={'Failed to load uploaded images.'}
                    subtitle={'This is probably a temporary error. Please try refreshing the page.'}
                />
            </div>
        );
    }
    if (!scanID) {
        return (
            <ActionCard
                variant={'alert'}
                title={`Could not find scan for product.`}
                subtitle={'This is probably a temporary error. Please try refreshing the page.'}
            />
        );
    }

    const missingPhotosGuidance = _.compact(
        uploadConfig.photos.map(photo => {
            return !uploadedPhotos[photo.filename] && photo.guidanceTitle ? photo.guidanceTitle(isDesktop) : null;
        }),
    );
    const gridWidth = imageGridWidthOverride || (uploadConfig.photos.length < 5 ? 6 : 4);
    return (
        <>
            {(!hideTitle || viewInstructionsOptions.showButton) && (
                <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    style={{ marginTop: 16, marginBottom: 32 }}
                >
                    {!hideTitle && (
                        <Text variant={'h6'}>
                            {missingPhotosGuidance[0] ?? (
                                <>
                                    All good!&nbsp;
                                    <span style={{ color: FlossPalette.BURGUNDY }}>
                                        {isDesktop
                                            ? 'Click next to continue.'
                                            : 'Return to your laptop to place the order'}
                                    </span>
                                </>
                            )}
                        </Text>
                    )}
                    {viewInstructionsOptions.showButton && (
                        <Button
                            variant={'primary'}
                            size={'small'}
                            onClick={_ => {
                                viewInstructionsOptions.onClick();
                            }}
                        >
                            View Instructions
                        </Button>
                    )}
                </Grid>
            )}
            <Grid container spacing={3} className={classes.photoGrid}>
                {uploadConfig.photos.map(photoSpec => (
                    <Grid
                        container
                        item
                        key={photoSpec.filename}
                        direction={'column'}
                        wrap={'nowrap'}
                        className={classes.imgContainer}
                        lg={gridWidth}
                    >
                        <SinglePhotoUpload
                            photoSpec={photoSpec}
                            uploadedPhotos={uploadedPhotos}
                            isDesktop={isDesktop}
                            onUpload={() => {
                                onUpload?.();
                                refetch();
                            }}
                            onDelete={refetch}
                            storagePathConfig={{
                                bucketName: storagePathConfig.data?.getStoragePathConfig.bucketName ?? '',
                                path: storagePathConfig.data?.getStoragePathConfig.path ?? '',
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    );
};
