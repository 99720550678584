import { useCurrentProfile } from '../../../../redux/selectors';
import { useShowMobileLayout } from '../../../../utils/LayoutUtils';
import { SingleClinicalPreferenceSelector } from './SingleClinicalPreferenceSelector';
import { WhatsDandyRecomendedBlurb } from './WhatsDandyRecomendedBlurb';
import { useGetClinicalPrefMapForDoctor } from './useGetClinicalPrefMapForDoctor';
import type { OrderPreferencesSKULabel } from '@orthly/dentin';
import { getPreferenceFieldsForSkuLabel, isOrderPreferencesSKULabel, PracticeScreen } from '@orthly/dentin';
import { LoadBlocker, ToolbarContainer } from '@orthly/ui';
import { FlossPalette, Grid, Button, Text, useMediaQuery } from '@orthly/ui-primitives';
import type { Theme } from '@orthly/ui-primitives';
import React from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';

const ProfilePreferencesForSKUInner: React.FC<{ skuLabel: OrderPreferencesSKULabel; doctorId: string }> = ({
    skuLabel,
    doctorId,
}) => {
    const history = useHistory();
    const { loading, prefMap } = useGetClinicalPrefMapForDoctor(doctorId);
    const applicableFields = getPreferenceFieldsForSkuLabel(skuLabel);
    // Array of ids of fields that we're updating/saving:
    const [savingFieldIds, setSavingFieldIds] = React.useState<string[]>([]);
    // eslint-disable-next-line no-nested-ternary
    const statusText = loading ? 'Loading ...' : savingFieldIds.length ? 'Saving ...' : 'All changes saved';
    const isMobileLayout = useShowMobileLayout();
    const hidden = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
    return (
        <Grid container alignContent={'flex-start'} style={{ height: '100vh', overflow: 'auto' }}>
            <ToolbarContainer
                sticky
                disableMobileSticky
                style={{ justifyContent: 'space-between', padding: '8px 24px', zIndex: 1 }}
            >
                {!isMobileLayout && (
                    <Button variant={'ghost'} startIcon={'ChevronLeft'} onClick={() => history.goBack()}>
                        Back to all clinical preferences
                    </Button>
                )}
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {statusText}
                </Text>
            </ToolbarContainer>
            <LoadBlocker blocking={loading}>
                <Grid container>
                    <Grid item xs={12} lg={8} style={{ paddingInline: 36, paddingBottom: 48 }}>
                        {!isMobileLayout && (
                            <Text variant={'h6'} medium style={{ marginTop: 36 }}>
                                Please configure your preferences for{' '}
                                <span style={{ color: FlossPalette.SECONDARY_FOREGROUND }}>{skuLabel}</span>
                            </Text>
                        )}
                        <Text variant={'body2'} color={'DARK_GRAY'} style={isMobileLayout ? { marginTop: 8 } : {}}>
                            These {isMobileLayout && 'preferences'} will be auto-populated during checkout. And of
                            course, you can make changes on a case-by-case basis.
                        </Text>
                        {applicableFields.map(field => (
                            <SingleClinicalPreferenceSelector
                                key={field.id}
                                skuLabel={skuLabel}
                                doctorId={doctorId}
                                field={field}
                                selectedValue={prefMap[field.id]}
                                notifySaving={(saving: boolean) => {
                                    if (saving) {
                                        setSavingFieldIds(ids => [...ids, field.id]);
                                    } else {
                                        setSavingFieldIds(ids => ids.filter(id => id !== field.id));
                                    }
                                }}
                            />
                        ))}
                    </Grid>
                    {hidden ? null : (
                        <Grid container xs={4} style={{ justifyContent: 'end', paddingRight: 24 }}>
                            <div style={{ marginTop: 100 }}>
                                <WhatsDandyRecomendedBlurb />
                            </div>
                        </Grid>
                    )}
                </Grid>
            </LoadBlocker>
        </Grid>
    );
};

export const ProfilePreferencesForSKU: React.FC = () => {
    const profile = useCurrentProfile();
    const skuLabel = decodeURIComponent(useParams<{ skuLabel?: string }>().skuLabel ?? '');
    if (!profile?.id || profile.__typename !== 'DoctorPreferences') {
        return null;
    }
    if (!skuLabel || !isOrderPreferencesSKULabel(skuLabel)) {
        return <Redirect to={`/${PracticeScreen.my_profile}/preferences`} />;
    }
    return <ProfilePreferencesForSKUInner skuLabel={skuLabel} doctorId={profile.id} />;
};
