import { Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

interface SettingsFormCustomSubmitProps {
    setOpen: (open: boolean) => void;
    disabled: boolean;
    triggerSubmit?: () => void;
    confirmText: string;
    style?: React.CSSProperties;
    className?: string;
}

export const SettingsFormCustomSubmit: React.FC<SettingsFormCustomSubmitProps> = props => {
    return (
        <Grid
            container
            direction={'row'}
            className={props.className}
            style={{ marginTop: '40px', justifyContent: 'right', ...props.style }}
        >
            <Button onClick={() => props.setOpen(false)} variant={'ghost'} style={{ marginRight: 8 }}>
                Cancel
            </Button>
            <Button
                disabled={props.disabled}
                onClick={props.triggerSubmit}
                variant={'primary'}
                style={{ marginLeft: 8 }}
            >
                {props.confirmText}
            </Button>
        </Grid>
    );
};
