import { LabsGqlProductLine } from '@orthly/graphql-schema';

enum CSTLabel {
    crownBridge = 'Crown & Bridge',
    clearAligners = 'Clear Aligners',
    dentures = 'Dentures',
    implants = 'Implants',
    removables = 'Removables',
}
export type CSTInfo = {
    label: CSTLabel;
    phoneNumber: string;
    productLines: LabsGqlProductLine[];
};

// TODO: replace phone numbers
export const CLINICAL_SUPPORT_TEAMS: readonly CSTInfo[] = [
    {
        label: CSTLabel.crownBridge,
        phoneNumber: '914-402-0289',
        productLines: [LabsGqlProductLine.HighComplexityCrownOrBridge, LabsGqlProductLine.SimpleCrownOrBridge],
    },
    {
        label: CSTLabel.clearAligners,
        phoneNumber: '914-402-0381',
        productLines: [LabsGqlProductLine.Aligner],
    },
    {
        label: CSTLabel.dentures,
        phoneNumber: '914-402-0381',
        productLines: [LabsGqlProductLine.Denture, LabsGqlProductLine.Partial],
    },
    {
        label: CSTLabel.implants,
        phoneNumber: '914-402-0785',
        productLines: [LabsGqlProductLine.Implant, LabsGqlProductLine.ImplantSurgery],
    },
    {
        label: CSTLabel.removables,
        phoneNumber: '914-402-0381',
        productLines: [LabsGqlProductLine.Removable, LabsGqlProductLine.Sleep],
    },
];
