import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const FullDenturesAddOnsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'monolithic try-in',
                option: 'Monolithic Try-in (per arch)',
                price: getPrice('Denture Monolithic Try-In'),
            },
            {
                id: 'denture_metal_mesh',
                option: 'Metal Mesh (per arch)',
                price: getPrice('Full Denture Metal Mesh'),
            },
            {
                id: 'denture_metal_framework',
                option: 'Metal Framework (per arch)',
                price: getPrice('Full Denture Metal Framework'),
            },
            {
                id: 'denture_softliner',
                option: 'Softliner (per arch)',
                price: getPrice('Full Denture Softliner'),
            },
            {
                id: 'denture_metal_mesh',
                option: 'Cu-Sil Gaskets (per tooth)',
                price: getPrice('Full Denture Cu-sil Gasket'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'full-dentures-add-ons'}
            title={'Additional options'}
            columns={[
                {
                    id: 'option',
                    title: 'Option',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
