import {
    getGuidedPresetQuestionTitle,
    getPresetTitle,
    makeTitleCase,
    useGetSelectedWaxup,
    usePresetStatus,
} from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import type {
    LabsGqlDesignOrderDtoNote,
    LabsGqlGuidedWaxupPresetType,
    RenderedCategoryTree,
} from '@orthly/graphql-schema';
import {
    getDesignOrderNoteCategoryTrees,
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
} from '@orthly/graphql-schema';
import {
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    FlossPalette,
    styled,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    presetInfoContainer: {
        paddingTop: 15,
        marginBottom: 10,
    },
    presetControlsContainer: {
        marginBottom: 20,
        paddingLeft: 24,
        paddingRight: 20,
    },
    rejectionNoteDisplay: {
        paddingRight: 20,
        justifyContent: 'flex-end',
        paddingLeft: 24,
    },
    rejectionNoteContainer: {
        flexDirection: 'column',
        alignContent: 'flex-start',
    },
}));

const UnorderedList = styled('ul')({
    padding: 0,
    margin: 0,
    paddingLeft: '25px',
    li: {
        color: FlossPalette.GRAY,
        paddingTop: '8px',
    },
});

const StructuredNotesList: React.VFC<{
    presetTitle: string;
    categoryTrees: RenderedCategoryTree[];
    notes?: string;
}> = ({ presetTitle, categoryTrees, notes }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return (
        <UnorderedList>
            {categoryTrees.map(note => (
                <li key={note.category}>
                    <Text variant={isMobile ? 'body2' : 'body1'} color={'GRAY'}>
                        {note.label}
                    </Text>
                    {note.children && note.children.length > 0 && (
                        <StructuredNotesList presetTitle={presetTitle} categoryTrees={note.children} />
                    )}
                </li>
            ))}
            {notes && (
                <li>
                    <Text variant={isMobile ? 'body2' : 'body1'} color={'GRAY'}>
                        {`Additional ${presetTitle.toLowerCase()} notes: "${notes}"`}
                    </Text>
                </li>
            )}
        </UnorderedList>
    );
};

const HistoricalWaxupPresetNotes: React.VFC<{
    presetType: LabsGqlGuidedWaxupPresetType;
    structuredNotes: LabsGqlDesignOrderDtoNote[] | undefined;
    notes: string | undefined;
}> = ({ presetType, structuredNotes, notes }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    if (structuredNotes?.length) {
        const categoryTrees = getDesignOrderNoteCategoryTrees(structuredNotes);
        const presetTitle = getPresetTitle(presetType);
        return (
            <Grid
                item
                sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
                className={classes.rejectionNoteDisplay}
            >
                <Text variant={isMobile ? 'body2' : 'body1'} medium>{`${presetTitle} rejection note`}</Text>
                <StructuredNotesList presetTitle={presetTitle} categoryTrees={categoryTrees} notes={notes} />
            </Grid>
        );
    }

    if (notes) {
        return (
            <Grid item xs={12} md={3} className={classes.rejectionNoteDisplay}>
                <Grid item container className={classes.rejectionNoteContainer}>
                    <Grid item>
                        <Text variant={isMobile ? 'body2' : 'body1'} medium>
                            Rejection Note
                        </Text>
                    </Grid>
                    <Grid item>
                        <Text variant={isMobile ? 'body2' : 'body1'}>{notes}</Text>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    return null;
};

export const HistoricalWaxupPresetInfo: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { selectedTab } = useGuidedWaxupContext();
    const selectedWaxup = useGetSelectedWaxup();
    const presetQuestionTitle = getGuidedPresetQuestionTitle(selectedTab);
    const presetStatus = usePresetStatus({ presetType: selectedTab }) ?? LabsGqlGuidedWaxupPresetStatus.Skipped;
    const selectedWaxupIsRejection = selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected;
    const selectedRejectionPreset = selectedWaxupIsRejection
        ? selectedWaxup?.annotations?.find(preset => preset.preset_type === selectedTab)
        : undefined;

    return (
        <Grid item container direction={isMobile ? 'column' : 'row'} className={classes.presetInfoContainer}>
            {selectedRejectionPreset?.structured_notes?.length ? (
                <HistoricalWaxupPresetNotes
                    presetType={selectedRejectionPreset.preset_type}
                    structuredNotes={selectedRejectionPreset.structured_notes}
                    notes={selectedRejectionPreset.notes}
                />
            ) : (
                <>
                    <Grid
                        item
                        container
                        direction={'column'}
                        justifyContent={'flex-start'}
                        className={classes.presetControlsContainer}
                        xs={12}
                        md={9}
                        key={selectedTab}
                    >
                        <Text variant={isMobile ? 'body2' : 'body1'} medium>
                            {presetQuestionTitle}
                        </Text>
                        <Text variant={isMobile ? 'body1' : 'h5'} color={'GRAY'}>
                            {makeTitleCase(presetStatus)}
                        </Text>
                    </Grid>
                    {selectedRejectionPreset?.notes && (
                        <Grid item xs={12} md={3} className={classes.rejectionNoteDisplay}>
                            <Grid item container className={classes.rejectionNoteContainer}>
                                <Grid item>
                                    <Text variant={isMobile ? 'body2' : 'body1'} medium>
                                        Rejection Note
                                    </Text>
                                </Grid>
                                <Grid item>
                                    <Text variant={isMobile ? 'body2' : 'body1'}>{selectedRejectionPreset?.notes}</Text>
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};
