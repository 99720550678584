import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { Tips, restoreConfidentlyTip } from '../component/Tip';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { PracticeScreen, ProductUnitTypeImages, SelectCard } from '@orthly/dentin';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const ImplantType: React.VFC = () => {
    const { implantReadinessId } = useImplantReadinessPropSelector(['implantReadinessId']);
    const setImplantType = useImplantReadinessAction('SET_IMPLANT_TYPE');
    return (
        <Grid container style={{ marginTop: 40, gap: 16 }}>
            <SelectCard
                image={ProductUnitTypeImages.Large.Implant}
                title={'Implant Crown'}
                onClick={() => {
                    setImplantType('implant_restoration');
                    AnalyticsClient.track('Practice - Implant Readiness - Implant Type Selected', {
                        implantReadinessId,
                        implantType: 'implant_restoration',
                    });
                }}
            />
            <SelectCard
                image={ProductUnitTypeImages.Large.ImplantBridge}
                title={'Implant Bridge'}
                onClick={() => {
                    setImplantType('implant_bridge');
                    AnalyticsClient.track('Practice - Implant Readiness - Implant Type Selected', {
                        implantReadinessId,
                        implantType: 'implant_bridge',
                    });
                }}
            />
        </Grid>
    );
};

export const ImplantTypeStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/type`,
    component: ImplantType,
    pageTitle: ({ name }) => ({
        title: `Preparing for ${name}'s implant case`,
        header: 'Which type of implant will you place?',
    }),
    sidebar: () => <Tips tips={[restoreConfidentlyTip]} />,
};
