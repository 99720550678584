import { useCheckoutAction } from '../../state/checkout.actions';
import type { CheckoutItemV2WithImplant } from '../../state/reducers/checkout.state';
import type { ImplantScreenInterface } from '../../state/reducers/implantScreens';
import { getSelectedConnection, getSelectedManufacturer, getSelectedSystem } from './implantSelectionGetters';
import type { LabsGqlImplantTypeLiteFragment } from '@orthly/graphql-operations';
import { useGetImplantTypesLiteQuery, useGetImplantTypesQuery } from '@orthly/graphql-react';
import type { ApolloError } from '@orthly/ui';
import { ActionCard, LoadBlocker } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, TextField, Autocomplete, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    inputRoot: {
        paddingTop: 0,
        '&:hover': { borderBottom: 'none !important' },
    },
    confirmWrapper: {
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        padding: 18,
        marginTop: 18,
        borderRadius: 6,
        cursor: 'pointer',
    },
}));

interface InputCardProps {
    label: string;
    options: string[];
    selectedValue?: string;
    setValue: (value: string) => void;
}

const InputCard: React.VFC<InputCardProps> = ({ label, options, selectedValue, setValue }) => {
    const classes = useStyles();
    return (
        <div style={{ marginBottom: 24 }}>
            <Text variant={'h6'} medium style={{ marginBottom: 16 }}>
                {label}
            </Text>
            <div style={{ backgroundColor: FlossPalette.TAN, padding: 4 }}>
                <Autocomplete
                    options={options}
                    renderInput={params => (
                        <TextField {...params} value={selectedValue} fullWidth variant={'standard'} />
                    )}
                    value={selectedValue}
                    onChange={(_event, value) => {
                        if (value) {
                            setValue(value);
                        }
                    }}
                    classes={classes}
                />
            </div>
        </div>
    );
};

const useDropdownOptions = (
    implantTypes: LabsGqlImplantTypeLiteFragment[],
    selectedManufacturer?: string,
    selectedSystem?: string,
) => {
    const manufacturers = React.useMemo(() => _.uniq(implantTypes.map(it => it.manufacturer)), [implantTypes]);
    const implantTypesWithSelectedManufacturer = React.useMemo(
        () => (selectedManufacturer ? implantTypes.filter(it => it.manufacturer === selectedManufacturer) : []),
        [selectedManufacturer, implantTypes],
    );
    const systems = React.useMemo(
        () => _.uniq(implantTypesWithSelectedManufacturer.map(it => it.system)),
        [implantTypesWithSelectedManufacturer],
    );
    const implantTypesWithSelectedSystem = React.useMemo(
        () => (selectedSystem ? implantTypesWithSelectedManufacturer.filter(it => it.system === selectedSystem) : []),
        [selectedSystem, implantTypesWithSelectedManufacturer],
    );
    const connections = React.useMemo(
        () => _.uniq(implantTypesWithSelectedSystem.map(it => it.connection)),
        [implantTypesWithSelectedSystem],
    );
    return { manufacturers, systems, connections };
};

interface MetadataPayload {
    manufacturer?: string | null;
    system?: string | null;
    connection_size: string | null;
}
const useMetadataActions = (item_index: number) => {
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');
    const updateMetadata = React.useCallback(
        (payload: MetadataPayload) => {
            updateItem({ item_index, change: { payload, name: 'implant_metadata' } });
        },
        [item_index, updateItem],
    );
    return { updateMetadata };
};

const ImplantSystemScreenDataful: React.VFC<{
    item: CheckoutItemV2WithImplant;
    implantTypes: LabsGqlImplantTypeLiteFragment[];
}> = ({ item, implantTypes }) => {
    const selectedManufacturer = getSelectedManufacturer(item);
    const selectedSystem = getSelectedSystem(item);
    const selectedConnection = getSelectedConnection(item);
    const { manufacturers, systems, connections } = useDropdownOptions(
        implantTypes,
        selectedManufacturer,
        selectedSystem,
    );
    const { updateMetadata } = useMetadataActions(item.item_index);
    return (
        <>
            <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginBottom: 24 }}>
                You can find information about your implant system on your Oral Surgeon’s surgery report.
            </Text>
            <Grid container direction={'row'}>
                <Grid item xs={6}>
                    <InputCard
                        label={'Manufacturer'}
                        options={manufacturers}
                        selectedValue={selectedManufacturer}
                        setValue={value => updateMetadata({ manufacturer: value, system: null, connection_size: null })}
                    />
                    <InputCard
                        label={'System'}
                        options={systems}
                        selectedValue={selectedSystem}
                        setValue={value => updateMetadata({ system: value, connection_size: null })}
                    />
                    <InputCard
                        // labeled updated from 'Connection Size'
                        label={'Platform Size'}
                        options={connections}
                        selectedValue={selectedConnection}
                        setValue={value => updateMetadata({ connection_size: value })}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const useGetImplantTypesData = (): {
    loading: boolean;
    error: ApolloError | undefined;
    implantTypesData: LabsGqlImplantTypeLiteFragment[] | undefined;
} => {
    const useLiteQuery = !!useFeatureFlag('enableGetImplantTypesLite').value;
    const implantTypes = useGetImplantTypesQuery({ variables: { withDeleted: false }, skip: useLiteQuery });
    const implantTypesLite = useGetImplantTypesLiteQuery({
        variables: { withDeleted: false },
        skip: !useLiteQuery,
    });
    const { loading, error } = useLiteQuery ? implantTypesLite : implantTypes;
    const implantTypesData = useLiteQuery
        ? implantTypesLite.data?.getImplantTypesLite
        : implantTypes.data?.getImplantTypes;

    return { loading, error, implantTypesData };
};

const ImplantSystemScreenTypeGuarded: React.VFC<{
    item: CheckoutItemV2WithImplant;
}> = ({ item }) => {
    const { loading, error, implantTypesData } = useGetImplantTypesData();
    if (error) {
        return (
            <ActionCard
                variant={'alert'}
                title={'Failed to load implant system options.'}
                subtitle={'Please try reloading the page.'}
            />
        );
    }
    return (
        <LoadBlocker blocking={loading}>
            <ImplantSystemScreenDataful item={item} implantTypes={implantTypesData ?? []} />
        </LoadBlocker>
    );
};

export const ImplantSystemScreen: ImplantScreenInterface = {
    title: 'What implant system is being used?',
    Component: ({ item }) => {
        return (
            <div style={{ marginTop: 24, width: '100%' }}>
                <ImplantSystemScreenTypeGuarded item={item} />
            </div>
        );
    },
    enableNext: ({ item }) =>
        !!getSelectedManufacturer(item) && !!getSelectedSystem(item) && !!getSelectedConnection(item),
};
