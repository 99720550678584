import { useCurrentPartner } from '../../utils/CurrentPartnerContext';
import { useSelectStaffSelector, selectShouldShowSelectStaff } from '../select-staff/state/select-staff.selectors';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlUpdatePracticeSupportMutationVariables } from '@orthly/graphql-operations';
import { useSession } from '@orthly/session-client';
import { RootActionDialog, SimpleCheckbox, useChangeSubmissionFn, LoadBlocker } from '@orthly/ui';
import { FlossPalette, Grid, Button, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

interface AcknowledgeAgreementDialogContentProps {
    setOpen: (val: boolean) => void;
    link: string;
    practiceId: string;
    agreementVersion: string;
}

type UpdateVars = LabsGqlUpdatePracticeSupportMutationVariables['data'];

const UpdatePracticeSupportMutation = graphql(`
    mutation UpdatePracticeSupport_Mutation($data: UpdatePracticeSupportCommand!) {
        updatePracticeSupport(data: $data) {
            id
        }
    }
`);

const AcknowledgeAgreementDialogContent: React.FC<AcknowledgeAgreementDialogContentProps> = ({
    setOpen,
    link,
    practiceId,
    agreementVersion,
}) => {
    const [checked, setChecked] = React.useState(false);
    const [updateSubmitMtn] = useMutation(UpdatePracticeSupportMutation);
    const updateMtnSubmitter = (data: UpdateVars) => updateSubmitMtn({ variables: { data } });
    const { submit: updateSubmit, submitting: updateSubmitting } = useChangeSubmissionFn<any, [UpdateVars]>(
        updateMtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => ['Agreement accepted!', {}],
            onSuccess: () => {
                setOpen(false);
            },
        },
    );
    const linkStyle = { color: FlossPalette.STAR_GRASS, textDecoration: 'none', fontWeight: 500 };
    return (
        <Grid container style={{ padding: 40 }} data-test={'acknowledge-agreement-dialog-content'}>
            <Text variant={'h4'} style={{ fontWeight: 500 }}>
                Terms of Service have updated
            </Text>
            <Text variant={'body2'} style={{ marginTop: 24 }}>
                Dandy has updated its Terms of Service, and we want to make sure you've been made aware of the changes.
            </Text>
            <Text variant={'body2'} style={{ marginTop: 12 }}>
                Please review the updated Terms of Service by clicking this{' '}
                <a href={link} target={'_blank'} rel={'noreferrer'} style={linkStyle}>
                    link
                </a>
                .
            </Text>
            <Grid container direction={'row'} alignItems={'center'} style={{ marginTop: 24 }}>
                <SimpleCheckbox
                    checked={checked}
                    setChecked={setChecked}
                    label={''}
                    CheckboxProps={{ style: { marginLeft: 0, marginRight: 0 }, color: 'secondary' }}
                />
                <Text variant={'body2'}>
                    I've read and agreed to the new{' '}
                    <a href={link} target={'_blank'} rel={'noreferrer'} style={linkStyle}>
                        Terms of Service
                    </a>
                    .
                </Text>
            </Grid>
            <Grid container direction={'row'} style={{ marginTop: 40 }} justifyContent={'flex-end'}>
                <LoadBlocker blocking={updateSubmitting} ContainerProps={{ style: { width: 'fit-content' } }}>
                    <Button
                        variant={'primary'}
                        disabled={!checked}
                        onClick={() =>
                            void updateSubmit({ practice_id: practiceId, agreement_version: agreementVersion })
                        }
                    >
                        Agree & proceed to Portal
                    </Button>
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

export const AcknowledgeAgreementDialog: React.VFC<{ agreementVersion?: string; loading: boolean }> = ({
    agreementVersion,
    loading,
}) => {
    const { value: tosUpdateData } = useFeatureFlag('tosUpdate');
    const showSelectStaff = useSelectStaffSelector(selectShouldShowSelectStaff);
    const session = useSession();
    const orgId = session?.organization_id;
    const [open, setOpen] = React.useState(true);
    const currentPartner = useCurrentPartner();
    const created_at = currentPartner.data?.created_at;
    if (!tosUpdateData || !orgId || !created_at || loading) {
        return null;
    }
    const { required_version, tos_url } = tosUpdateData;

    if (showSelectStaff || !required_version || !tos_url || agreementVersion === required_version) {
        return null;
    }
    return (
        <RootActionDialog
            loading={loading}
            title={null}
            titleProps={{ style: { display: 'none' } }}
            open={open}
            setOpen={setOpen}
            hideButton={true}
            content={
                <AcknowledgeAgreementDialogContent
                    agreementVersion={required_version}
                    setOpen={setOpen}
                    link={tos_url}
                    practiceId={orgId}
                />
            }
        />
    );
};
