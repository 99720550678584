import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useCheckoutPropSelector, useCheckoutSelector } from '../../../redux/selectors';
import { CheckoutContentSection } from '../components/CheckoutLayout/CheckoutContentSection';
import { useCanSeeCrownNgBundleOffer, useSetHasOptedInCrownNgBundle } from '../components/CheckoutLayout/utils';
import { useCheckoutAction } from '../state/checkout.actions';
import type { CheckoutButtonSelectOption } from '@orthly/dentin';
import { CheckoutButtonSelect, ExtraCartItemV2DisplayUtils } from '@orthly/dentin';
import { ExtraCartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import type { AddToCartExtraUnitType, ExtraCartItemV2UnitType } from '@orthly/items';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    createStyles,
    makeStyles,
    Grid,
    Text,
    Typography,
    Zoom,
    CheckIcon,
    stylesFactory,
} from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory((theme: Theme) => ({
    button: {
        minHeight: '200px',
        margin: '10px !important',
        borderLeftColor: FlossPalette.DARK_TAN,
        borderRightColor: FlossPalette.DARK_TAN,
        maxWidth: 'calc(33% - 20px)',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'unset',
            margin: '0 !important',
        },
    },
    buttonSelected: {
        '& + $button': {
            borderLeftColor: FlossPalette.DARK_TAN,
        },
    },
    buttonGroup: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    imgWrap: {
        flexGrow: 1,
    },
}));

const useButtonGroupStyles = stylesFactory(() => ({
    groupedOutlinedHorizontal: {
        '&:not(:last-child)': {
            borderRightColor: FlossPalette.DARK_TAN,
        },
    },
}));

const useLabelStyles = makeStyles(() =>
    createStyles<string, {}>({
        textContainer: {
            position: 'absolute',
            alignItems: 'center',
            flexWrap: 'nowrap',
        },
        text: {
            fontWeight: 500,
            textAlign: 'left',
        },
        icon: {
            color: FlossPalette.STAR_GRASS_HIGHLIGHTED,
            marginRight: 4,
        },
    }),
);

const ExtrasButtonSelectLabel: React.FC<{ itemType: string; selected: boolean; fromScan: boolean }> = props => {
    const { selected, itemType, fromScan } = props;
    const classes = useLabelStyles();
    const timeout = 200;

    return (
        <Grid container style={{ paddingBottom: 16, position: 'relative', minHeight: 30 }} alignItems={'center'}>
            <Zoom in={selected} timeout={timeout} exit={true}>
                <Grid container className={classes.textContainer}>
                    <CheckIcon className={classes.icon} />
                    <Typography color={'textPrimary'} variant={'body1'} className={classes.text}>
                        {itemType} {fromScan ? 'configured' : 'added'}
                    </Typography>
                </Grid>
            </Zoom>
            <Zoom in={!selected} timeout={timeout} exit={true}>
                <Grid container className={classes.textContainer}>
                    <Typography color={'textPrimary'} variant={'body1'} className={classes.text}>
                        Add {itemType}
                    </Typography>
                </Grid>
            </Zoom>
        </Grid>
    );
};

const getRecommendedText = (
    isSelected: boolean,
    unitType: AddToCartExtraUnitType,
    canSeeCrownNgBundleOffer: boolean,
) => {
    if (canSeeCrownNgBundleOffer && unitType === 'Night Guard') {
        return isSelected ? 'Discounted Night Guard included' : 'Extend crown life with a discounted Night Guard';
    }

    return null;
};

function useAvailableExtraOptions(): { extraOptions: CheckoutButtonSelectOption[]; existingOtherUnitTypes: string[] } {
    const { items } = useCheckoutPropSelector(['items']);
    const canSeeCrownNgBundleOffer = useCanSeeCrownNgBundleOffer();

    const availableExtras = ExtraCartItemV2Utils.getAddToCartOptions(_.filter(items, i => i.from_scan_export));
    // Get the values of all currently existing item unit types
    const existingOtherUnitTypes = _.compact(
        items.filter(i => !i.from_scan_export).map(ExtraCartItemV2Utils.getExtraItemUnitType),
    );

    // Turn the availableExtras into type CheckoutButtonSelectOption and set the disabled/selected flags depending on
    // whether it is already in the cart
    const extraOptions = React.useMemo<CheckoutButtonSelectOption[]>(() => {
        return availableExtras.map<CheckoutButtonSelectOption>(extraItem => {
            const hasExistingItem = existingOtherUnitTypes.includes(extraItem.unit_type);

            return {
                value: extraItem.unit_type,
                image_url: ExtraCartItemV2DisplayUtils.getImageForUnitType(extraItem.unit_type),
                label: (
                    <ExtrasButtonSelectLabel
                        fromScan={hasExistingItem}
                        itemType={extraItem.label || extraItem.unit_type}
                        selected={hasExistingItem}
                    />
                ),
                recommendedText: getRecommendedText(hasExistingItem, extraItem.unit_type, canSeeCrownNgBundleOffer),
            };
        });
    }, [existingOtherUnitTypes, availableExtras, canSeeCrownNgBundleOffer]);

    return { extraOptions, existingOtherUnitTypes };
}

const WaxupTooltipButton: React.VFC<{ onClick: () => void }> = ({ onClick }) => {
    return (
        <Grid onClick={onClick} style={{ cursor: 'pointer' }}>
            <Text variant={'body2'} color={'WHITE'}>
                You'll need to approve the design preview in the portal before we can fabricate this order.
            </Text>
            <Text variant={'body2'} color={'WHITE'} style={{ paddingTop: 8 }}>
                We'll notify you when the design preview is ready for review. Keep in mind that a response time over 24
                hours could result in delay to the order delivery.
            </Text>
            <Text variant={'body2'} color={'WHITE'} medium style={{ paddingTop: 8, paddingBottom: 8 }}>
                Dismiss »
            </Text>
        </Grid>
    );
};

const useWaxupExtraOption = (entered: boolean): CheckoutButtonSelectOption | null => {
    const selected = useCheckoutSelector(s => s.waxupState.selected);
    const setTooltipDismissed = useCheckoutAction('SET_WAXUP_TOOLTIP_DISMISSED');
    const tooltipDismissed = useCheckoutSelector(s => s.waxupState.tooltipDismissed);
    const scanItems = useCheckoutSelector(s => s.items.filter(i => i.from_scan_export));
    const { value: enableFlexiPartialDesignPreview } = useFeatureFlag('enableFlexiPartialDesignPreview');
    const optionEnabled = ExtraCartItemV2Utils.shouldEnableWaxupOption(scanItems);
    const disabledPartialDDP =
        scanItems.some(i => i.sku === LabOrderItemSKUType.Partial) && !enableFlexiPartialDesignPreview;

    if (!optionEnabled || disabledPartialDDP) {
        return null;
    }

    return {
        disabled: false,
        value: 'waxup',
        image_url: '/checkout/digital-waxup.svg',
        TooltipProps: {
            open: !tooltipDismissed && selected && entered,
            title: <WaxupTooltipButton onClick={() => setTooltipDismissed(true)} />,
            arrow: true,
            placement: 'bottom',
        },
        label: <ExtrasButtonSelectLabel fromScan={false} itemType={'Design Preview'} selected={selected} />,
    };
};

export const SelectExtrasSection: React.VFC<{ entered: boolean }> = ({ entered }) => {
    const classes = useStyles();
    const canSeeCrownNgBundleOffer = useCanSeeCrownNgBundleOffer();
    const btnGroupClasses = useButtonGroupStyles();
    const setSelectedUnits = useCheckoutAction('SET_EXTRA_UNITS');
    const { extraOptions, existingOtherUnitTypes } = useAvailableExtraOptions();
    const setHasOptedInCrownNgBundle = useSetHasOptedInCrownNgBundle();
    const setWaxupSelected = useCheckoutAction('SET_WAXUP_SELECTED');
    const waxupSelected = useCheckoutSelector(s => s.waxupState.selected);
    const hasNightGuardOption = extraOptions.some(option => option.value === 'Night Guard');
    const canSelectDiscountedNightGuard = canSeeCrownNgBundleOffer && hasNightGuardOption;
    const waxupOption = useWaxupExtraOption(entered);

    React.useEffect(() => {
        if (canSelectDiscountedNightGuard) {
            AnalyticsClient.track('Practice - Portal - Bundle Discount Shown', {
                bundleType: 'crown_ng',
            });
        }
    }, [canSelectDiscountedNightGuard]);

    return (
        <CheckoutContentSection style={{ paddingTop: 0 }}>
            <CheckoutButtonSelect
                classes={classes}
                buttonGroupClasses={btnGroupClasses}
                mode={'multiple'}
                options={waxupOption ? [waxupOption, ...extraOptions] : extraOptions}
                onChange={(newValue: string[]) => {
                    setWaxupSelected(newValue.includes('waxup'));
                    const updatedSelectedUnits = newValue.filter(
                        (v: string): v is ExtraCartItemV2UnitType =>
                            v !== 'waxup' && ExtraCartItemV2Utils.isExtraUnitType(v),
                    );

                    setSelectedUnits(updatedSelectedUnits);

                    if (canSelectDiscountedNightGuard) {
                        setHasOptedInCrownNgBundle(updatedSelectedUnits.includes('Night Guard'));
                    }
                }}
                value={[...existingOtherUnitTypes, ...(waxupSelected ? ['waxup'] : [])]}
                label={''}
            />
        </CheckoutContentSection>
    );
};
