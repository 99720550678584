import { isOrderScanRejectedAndVisibleToPractice } from '@orthly/dentin';
import { graphql, getFragmentData, type FragmentType } from '@orthly/graphql-inline-react';
import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';

const PracticeGetAskDoctorV2ConfigurationScanReview_Fragment = graphql(`
    fragment PracticeGetAskDoctorV2ConfigurationScanReview_Fragment on ScanReviewWorkOrderDTO {
        status
        current_doctor_request {
            id
            question_text
        }
        rejection_metadata {
            notes_for_doctor
        }
    }
`);

export const getAskDoctorV2Configuration = (
    order: Pick<LabsGqlOrderPreviewFragment, 'fulfillment_workflow'>,
    scanReviewFragment: FragmentType<typeof PracticeGetAskDoctorV2ConfigurationScanReview_Fragment> | null,
) => {
    const scanReview = scanReviewFragment
        ? getFragmentData(PracticeGetAskDoctorV2ConfigurationScanReview_Fragment, scanReviewFragment)
        : null;
    // Before the Ask Doctor V2 project, we had a different UI to resolve Scan Rejection or Regular holds. With V2, we're removing the scan rejection UI flow
    // and will instead use the regular hold experience to resolve issues.

    // The new logic for scan rejection is that if the feature flag is enabled, AND the request was created using the new ask doctor flow
    // (eg: it includes a doctor request id) then we can use the new ask doctor v2 flow, if not we still display the old scan rejection UI.
    if (scanReview && isOrderScanRejectedAndVisibleToPractice(order, scanReview.status)) {
        const doctorRequestIdScanRejection = scanReview.current_doctor_request?.id ?? null;
        const shouldUseV2Flow = Boolean(doctorRequestIdScanRejection);
        return {
            notesForDoctor: shouldUseV2Flow
                ? scanReview.current_doctor_request?.question_text ?? scanReview.rejection_metadata.notes_for_doctor
                : '',
            doctorRequestId: doctorRequestIdScanRejection,
            canUseAskDoctorV2Flow: shouldUseV2Flow,
            shouldShowResolveButton: shouldUseV2Flow,
        };
    }

    // For regular holds, we will use the same button whether we are in v1 or v2 but clicking on the button will open a different modal based on the version
    if (order.fulfillment_workflow.active_task?.__typename === 'DrReviewHoldWorkflowTask') {
        const doctorRequestIdHold = order.fulfillment_workflow.active_task.configuration.doctor_request_id;

        return {
            notesForDoctor: order.fulfillment_workflow.active_task.configuration.dr_visible_notes,
            doctorRequestId: doctorRequestIdHold,
            canUseAskDoctorV2Flow: Boolean(doctorRequestIdHold),
            shouldShowResolveButton: true,
        };
    }

    // If the current task is neither a scan rejection or a review hold, then we send back an empty configuration
    return {
        notesForDoctor: null,
        doctorRequestId: null,
        canUseResolveAction: false,
        shouldShowResolveButton: false,
    };
};
