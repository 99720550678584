import { getFlagVariantValueChecker } from '../../../../utils/utils';
import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import NightGuardImage from '@orthly/dentin/assets/images/hd-skus/NightGuard2.png';

export const NightGuardUpsellConfig: InboxUpsellItemConfigProps = {
    assetName: '202310 Night Guard $89 Promo',
    title: '$89 Hard/Soft 3D Printed Night Guard Offer*',
    subtitle: 'Offer good through November 2023 for a limited time only, while supplies last.',
    ctaText: 'Learn More',
    destination: {
        url: 'https://www.meetdandy.com/labs/splints-and-guards/hard-soft-3d-printed-night-guards/?utm_source=portal&utm_medium=banner&utm_campaign=2023q4-customer-promo-night-guards',
    },
    imgSrc: NightGuardImage,
    imgStyle: { maxHeight: 80 },
    skuType: 'night-guards',
    filterCriteria: null,
    featureFlagKey: 'upsellNightGuard-202310',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_a'),
};
