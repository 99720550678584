import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionBodyFreeText } from '../TryInFeedbackOptionBodyFreeText';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType, OrderItemPartialDentureProductionType, OrderItemV2Utils } from '@orthly/items';
import React from 'react';

const TryInFeedbackTeethShapeOption: React.VFC<
    TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture>
> = ({ isSelected, description, onSelected, onUnselected, onDescriptionChanged, showError }) => {
    return (
        <TryInFeedbackOptionWrapper
            title={'Teeth shape'}
            subtitle={'Teeth shape or design needs adjustment'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <TryInFeedbackOptionBodyFreeText
                label={'Specify teeth shape changes'}
                value={description ?? undefined}
                onChange={onDescriptionChanged}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackTeethShapeOption: TryInFeedbackOptionDefinition<
    LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture
> = {
    sku: [LabOrderItemSKUType.Partial, LabOrderItemSKUType.Denture],
    isArchSpecific: true,
    category: 'Teeth shape',
    isEnabled: item =>
        !OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial) ||
        item.partial_production_type !== OrderItemPartialDentureProductionType.WaxRim,
    Component: TryInFeedbackTeethShapeOption,
};
