import { useGetUserFromDrPrefId } from '../../../../../graphql/useGetUserFromDrPrefId.graphql';
import { useUpdateDoctorPreferences } from '../../../../../graphql/useUpdateDoctorPreferences.graphql';
import { usePracticeStaffControls } from '../../../state/usePracticeStaffControls';
import { isPlaceholderEmail } from '../utils';
import { useAccountInformationSection } from './useAccountInformationSection';
import { useContactInformationSection } from './useContactInformationSection';
import { usePasswordSection } from './usePasswordSection';
import { useVerifyPhoneNumber } from './useVerifyPhoneNumber';
import constate from 'constate';
import { useSnackbar } from 'notistack';
import React from 'react';

interface PrefData {
    preference_set_id: string;
    name: string;
    contact_email: string;
    best_call_hour: number | null;
    timezone: string | undefined;
    user_first_name: string;
    user_last_name: string;
    mobile_phone_number?: string | null;
    contact_phone?: string | null;
    user_email: string;
    password_raw?: string;
}

const userExistsError = 'User with this email already exists';

// eslint-disable-next-line sonarjs/cognitive-complexity
export const [AccountSettingsProvider, useAccountSettings] = constate(() => {
    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
    const accountInformationSection = useAccountInformationSection();
    const contactInformationSection = useContactInformationSection();
    const { isFormValid: isPasswordFormValid, ...passwordSection } = usePasswordSection();

    const { enqueueSnackbar } = useSnackbar();
    const { employeePrefs, refetch: refetchDrPref } = usePracticeStaffControls() ?? {};
    const { refetch: refetchUser } = useGetUserFromDrPrefId(employeePrefs?.id);
    const reloadFormState = async () => {
        const {
            data: { getUserFromDrPrefId: user },
        } = await refetchUser();
        const placeholderEmail = isPlaceholderEmail(user.email);
        accountInformationSection.setAccountFirstName(user?.first_name ?? '');
        accountInformationSection.setAccountLastName(user?.last_name ?? '');
        accountInformationSection.setAccountPhone(user?.mobile_phone_number ?? '');
        accountInformationSection.setAccountEmail(placeholderEmail ? '' : user?.email ?? '');
        refetchDrPref && (await refetchDrPref());
        passwordSection.enablePasswordEdit && passwordSection.togglePasswordEdit();
        passwordSection.setShowConfirmedPasswordError(false);
        passwordSection.setShowPasswordError(false);
        enqueueSnackbar('Updated user', { variant: 'success' });
    };
    const onSuccess = () => reloadFormState();
    const onFailure = (e: string | undefined) => {
        let message = 'Did not update user.';
        if (e === userExistsError) {
            message = `${message} ${userExistsError}.`;
        }
        enqueueSnackbar(message, { variant: 'error' });
    };
    const { submit: updatePref, submitting: _submitUpdatingPref } = useUpdateDoctorPreferences({
        onSuccess,
        onFailure,
    });

    const { accountPhone, accountEmail } = accountInformationSection;
    const { bestCallHour, contactEmail, contactPhone } = contactInformationSection;

    const data: PrefData = {
        //mobile phone is handled by useVerifyPhoneNumber unless null
        preference_set_id: employeePrefs?.id ?? '',
        name:
            contactInformationSection.preferredName !== ''
                ? contactInformationSection.preferredName
                : `${accountInformationSection.accountFirstName} ${accountInformationSection.accountLastName}`,
        contact_email: contactEmail || accountEmail,
        best_call_hour: bestCallHour ? parseInt(bestCallHour) : null,
        timezone: contactInformationSection.timezone,
        user_first_name: accountInformationSection.accountFirstName,
        user_last_name: accountInformationSection.accountLastName,
        user_email: accountEmail,
        contact_phone: contactPhone || accountPhone || null,
        password_raw: isPasswordFormValid ? passwordSection.password : undefined,
    };

    if (accountPhone === '' || accountPhone === '+1') {
        data.mobile_phone_number = null;
    }

    const updatePrefCall = () => {
        void updatePref(data);
    };

    const verifyPhoneProps = useVerifyPhoneNumber({
        setShowConfirmationModal,
        updatePrefCall,
        accountPhone: accountInformationSection.accountPhone,
    });

    const onSubmit: React.FormEventHandler = e => {
        e.preventDefault();
        if (accountInformationSection.phoneNeedsConfirmation && accountPhone !== '') {
            verifyPhoneProps.sendConfirmationCode();
        } else {
            updatePrefCall();
        }
    };

    const disableSaveButton = Boolean(
        // Disable save button if no changes have been made
        !(
            contactInformationSection.isDirty ||
            accountInformationSection.isDirty ||
            passwordSection.enablePasswordEdit
        ) ||
            // Disable save button if any of the fields are invalid
            (!isPasswordFormValid && passwordSection.enablePasswordEdit) ||
            !contactInformationSection.isContactPhoneValid ||
            !accountInformationSection.isAccountPhoneValid ||
            accountInformationSection.accountEmailError ||
            (contactInformationSection.contactEmailError && contactEmail !== '') ||
            accountInformationSection.firstNameRequiredError ||
            accountInformationSection.lastNameRequiredError ||
            accountInformationSection.accountEmailRequiredError,
    );

    return {
        onSubmit,
        showConfirmationModal,
        setShowConfirmationModal,
        updatePrefCall,
        verifyPhoneProps,
        disableSaveButton,
        ...contactInformationSection,
        ...accountInformationSection,
        ...passwordSection,
    };
});
