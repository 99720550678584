import { SPLIT_ORDER_TEXT } from './PauseOrResumeOrderAction.types';
import { FlossPalette, Icon, styled } from '@orthly/ui';
import { Box, Text } from '@orthly/ui-primitives';
import React from 'react';

const SplitOrderContentBox = styled(Box)(({ theme }) => ({
    background: FlossPalette.SECONDARY_BACKGROUND,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'start',
    gap: '8px',
    padding: '8px 16px',
    [theme.breakpoints.up('lg')]: {
        position: 'absolute',
        left: 0,
        bottom: 0,
    },
}));

export const SplitOrderIcon = styled(Icon)({
    color: FlossPalette.SECONDARY_FOREGROUND,
    fontSize: 12,
});

export const SplitOrderBanner: React.VFC = () => {
    return (
        <SplitOrderContentBox data-testid={'split-order-banner'}>
            <SplitOrderIcon icon={'ExclamationIcon'} color={'inherit'} />
            <Text variant={'body2'} medium color={'SECONDARY_FOREGROUND'}>
                {SPLIT_ORDER_TEXT}
            </Text>
        </SplitOrderContentBox>
    );
};
