import { usePortalToChairsideBridgeCommand } from '../../../../utils/chairside.hooks';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';

export const useAddNewScans = (
    order: LabsGqlLabOrderFragment,
): { canAddNewScans: boolean; invokeAttachScans: (props: { id: string }) => void } => {
    const invokeAttachScans = usePortalToChairsideBridgeCommand('attachScansToOrder');
    if (
        !invokeAttachScans ||
        order.status === LabsGqlLabOrderStatus.Cancelled ||
        order.status === LabsGqlLabOrderStatus.Delivered ||
        order.status === LabsGqlLabOrderStatus.NeedsRefabrication
    ) {
        return {
            invokeAttachScans: () => null,
            canAddNewScans: false,
        };
    }

    return {
        canAddNewScans: true,
        invokeAttachScans,
    };
};
