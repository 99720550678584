import { OrderDetailMobileControlPanelBase } from '../../../order-detail-v2/components/OrderDetailMobileControlPanelBase';
import { OrderAction, OrderActionTrackerIdMap } from '../../OrderActionsUtils';
import type { LabOrderActionProps } from '../../index';
import { NewBadge } from '../NewBadge';
import type { PauseOrderActionProps } from './PauseOrResumeOrderAction.types';
import { PauseOrderActionContent } from './PauseOrderActionContent';
import { useCreateOrderHold } from './hooks/useCreateOrderHold';
import { useRemoveOrderHoldOrCancelLiveDdpReview } from './hooks/useRemoveOrderHold.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PauseOrderActionReason } from '@orthly/shared-types';
import { LoadBlocker } from '@orthly/ui';
import { Button, Drawer, styled, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const StyledPaper = styled('div')({
    height: '92%',
    borderRadius: 16,
});

const StyledButton = styled(Button)({
    height: 'unset',
    minWidth: 'unset',
});

const Layout = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

type PauseOrderMobileDialogProps = PauseOrderActionProps & {
    open: boolean;
    setOpen: (value: boolean) => void;
};

export const PauseOrderMobileDialog: React.VFC<PauseOrderMobileDialogProps> = ({
    order,
    refetch,
    open,
    setOpen,
    preselectedOption,
}) => {
    const [selectedOption, setSelectedOption] = React.useState<string | undefined>(preselectedOption);

    const orderHasSplitOrders = (order.split_order_count ?? 0) > 0;

    const closePauseOrderModal = React.useCallback(() => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Modal Abandoned', {
            $groups: { order: order.id },
        });

        setOpen(false);
        setSelectedOption(undefined);
    }, [setOpen, setSelectedOption, order.id]);

    const { submit: submitCreateHold, submitting: submittingCreateHold } = useCreateOrderHold(
        order.id,
        () => {
            refetch();
            setOpen(false);
        },
        () => setOpen(false),
    );

    const onPauseAction = () => {
        void submitCreateHold({
            hold_details: {
                hold_category: 'Doctor requested hold',
                hold_subcategory: selectedOption ?? '',
                hold_is_practice_managed_pause: true,
            },
            orderId: order.id,
            show_hold_status_to_practice: true,
        });
    };

    return (
        <Drawer
            open={open}
            onClose={closePauseOrderModal}
            variant={'temporary'}
            anchor={'bottom'}
            PaperProps={{
                component: StyledPaper,
            }}
        >
            <OrderDetailMobileControlPanelBase
                showSuccessMessage={false}
                successMessage={''}
                backAction={closePauseOrderModal}
                closeAction={() => {
                    refetch?.();
                    closePauseOrderModal();
                }}
            >
                <LoadBlocker blocking={submittingCreateHold}>
                    <Layout>
                        <Text variant={'h6'} medium>
                            Pause this order
                        </Text>
                        <PauseOrderActionContent
                            order={order}
                            selectedOption={selectedOption}
                            setSelectedOption={setSelectedOption}
                            orderHasSplitOrders={orderHasSplitOrders}
                            onCloseAction={closePauseOrderModal}
                            onPauseAction={onPauseAction}
                        />
                    </Layout>
                </LoadBlocker>
            </OrderDetailMobileControlPanelBase>
        </Drawer>
    );
};

export const ResumeOrderMobileAction: React.VFC<LabOrderActionProps> = ({ order, refetch }) => {
    const holdIsDDPHold = order.hold_details?.hold_subcategory === PauseOrderActionReason.ReviewDesign;

    const { onSubmit, isLiveDdp } = useRemoveOrderHoldOrCancelLiveDdpReview(refetch, {
        orderId: order.id,
        holdId: order.hold_details?.hold_id,
    });

    return (
        <StyledButton
            data-test={'resume-order-button'}
            variant={'ghost'}
            startIcon={'PlayArrowIcon'}
            onClick={onSubmit}
        >
            {isLiveDdp ? `Cancel appointment & resume order` : `Resume order ${holdIsDDPHold ? ' to view design' : ''}`}
        </StyledButton>
    );
};

type PauseOrResumeOrderMobileButtonProps = LabOrderActionProps & {
    setOpen: (value: boolean) => void;
};

type PauseOrResumeOrderMobileInnerProps = PauseOrResumeOrderMobileButtonProps & {
    enablePauseOrder: boolean;
};

export const PauseOrResumeOrderActionMobileInner: React.VFC<PauseOrResumeOrderMobileInnerProps> = ({
    order,
    enablePauseOrder,
    setOpen,
    ...others
}) => {
    const shouldShowButton = order.can_put_on_hold && enablePauseOrder;
    const shouldShowResumeButton = order.hold_details?.hold_is_practice_managed_pause;

    const openPauseOrderModal = React.useCallback(() => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Modal Opened', {
            $groups: { order: order.id },
        });

        setOpen(true);
    }, [setOpen, order.id]);

    if (!shouldShowButton) {
        return null;
    }

    if (shouldShowResumeButton) {
        return <ResumeOrderMobileAction order={order} {...others} />;
    }

    return (
        <StyledButton
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.PauseOrder]}
            data-test={'open-pause-order-modal-button'}
            variant={'ghost'}
            startIcon={'PauseIcon'}
            onClick={openPauseOrderModal}
        >
            Pause order <NewBadge>New</NewBadge>
        </StyledButton>
    );
};

export const PauseOrResumeOrderMobileButton: React.VFC<PauseOrResumeOrderMobileButtonProps> = props => {
    const { value: enablePauseOrder } = useFeatureFlag('enablePauseOrder');

    return <PauseOrResumeOrderActionMobileInner enablePauseOrder={Boolean(enablePauseOrder)} {...props} />;
};
