import { FlossPalette, FlossPaletteUtils, stylesFactory } from '@orthly/ui-primitives';

const MOBILE_AUTH_CONTENT_PADDING = 56;
const MOBILE_AUTH_HALF_CONTENT_PADDING = MOBILE_AUTH_CONTENT_PADDING / 2;
const MOBILE_AUTH_MOBILE_CONTENT_PADDING = 16;

export const useFullPageFormStyles = stylesFactory(theme => ({
    root: {
        backgroundColor: FlossPaletteUtils.toRgba('TAN', 0.99), // workaround to override blur background in FireFox
        height: '100vh',
        alignContent: 'baseline',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'nowrap',
            flexDirection: 'column',
        },
    },
    logo: {
        color: FlossPalette.BLACK,
        height: 32,
        margin: '40px 32px',
        [theme.breakpoints.down('sm')]: {
            margin: '24px 16px 8px',
        },
    },
    leftContentWrapper: {
        padding: `120px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
        [theme.breakpoints.down('lg')]: {
            padding: `${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
        },
        [theme.breakpoints.down('md')]: {
            padding: `${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: MOBILE_AUTH_MOBILE_CONTENT_PADDING,
            width: '100%',
        },
    },
    rightContentWrapper: {
        padding: `120px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px`,
        [theme.breakpoints.down('lg')]: {
            padding: `${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px`,
        },
        [theme.breakpoints.down('md')]: {
            padding: `${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
        },
        [theme.breakpoints.down('sm')]: {
            padding: MOBILE_AUTH_MOBILE_CONTENT_PADDING,
        },
    },
    signOutButton: {
        position: 'absolute',
        right: 32,
        top: 32,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    contentWrapper: {
        margin: `120px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
        [theme.breakpoints.down('lg')]: {
            margin: `${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
        },
        [theme.breakpoints.down('md')]: {
            margin: `${MOBILE_AUTH_CONTENT_PADDING}px 0 ${MOBILE_AUTH_HALF_CONTENT_PADDING}px 0`,
        },
        [theme.breakpoints.down('sm')]: {
            margin: `${MOBILE_AUTH_MOBILE_CONTENT_PADDING}px 0`,
        },
    },
}));
