import { bodyFont, Box, Button, FlossPalette, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

interface DesignViewLiteButtonProps {
    icon: React.ReactNode;
    id?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    label: string;
    extraLabel?: string;
    isActive: boolean;
}

const LabelIconContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: '4px',
    [theme.breakpoints.down('lg')]: {
        fontSize: '10px',
    },
}));

const LabelWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    columnGap: '0.5ch',
    flexWrap: 'wrap',
    [theme.breakpoints.down('lg')]: {
        alignItems: 'flex-start',
    },
}));

const StyledButton = styled(Button)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    '&&, &&:active, &&:focus, &&:hover': {
        backgroundColor: isSelected ? FlossPalette.PRIMARY_BACKGROUND : FlossPalette.TAN,
        color: isSelected ? FlossPalette.PRIMARY_PRESSED : FlossPalette.PRIMARY_FOREGROUND,
    },
    [theme.breakpoints.down('lg')]: {
        height: 'unset',
        flexDirection: 'column',
        gap: 0,
        padding: '8px',
    },
    [theme.breakpoints.down('sm')]: {
        width: 'min-content',
    },
}));

const StyledIcon = styled(Icon)(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
    },
}));

export const DesignViewLiteButton: React.VFC<DesignViewLiteButtonProps> = ({
    icon,
    id,
    label,
    extraLabel,
    onClick,
    onClose,
    isActive,
}) => {
    const isSelected = Boolean(isActive || extraLabel);

    return (
        <StyledButton variant={'secondary'} size={'small'} id={id} onClick={onClick} isSelected={isSelected}>
            {icon}
            <LabelIconContainer>
                <LabelWrapper>
                    <Box>{extraLabel ? `${label}:` : label}</Box>
                    {extraLabel && <Box sx={{ fontFamily: bodyFont }}>{extraLabel}</Box>}
                </LabelWrapper>
                {extraLabel ? (
                    <StyledIcon icon={'CloseIcon'} onClick={onClose} />
                ) : (
                    <StyledIcon icon={'ExpandMoreIcon'} />
                )}
            </LabelIconContainer>
        </StyledButton>
    );
};
