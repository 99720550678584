import { InvoiceDetailCtxProvider } from '../components/providers/InvoiceDetailProvider.graphql';
import type { PracticeInvoiceDetail } from '../invoicing.types';
import { InvoiceDetailActionContainer } from './components/InvoiceDetailActionContainer';
import { InvoiceDetailBody } from './components/InvoiceDetailBody';
import { InvoiceDetailOverviewContainer } from './components/InvoiceDetailOverviewContainer';
import React from 'react';

interface InvoiceDetailRootProps {
    invoice: PracticeInvoiceDetail;
    refetchInvoice: () => Promise<unknown>;
    autochargeEnabled: boolean;
}

export const InvoiceDetailRoot: React.FC<InvoiceDetailRootProps> = ({ invoice, refetchInvoice, autochargeEnabled }) => (
    <InvoiceDetailCtxProvider invoice={invoice} refetchInvoice={refetchInvoice} autochargeEnabled={autochargeEnabled}>
        <InvoiceDetailActionContainer />
        <InvoiceDetailOverviewContainer />
        <InvoiceDetailBody />
    </InvoiceDetailCtxProvider>
);
