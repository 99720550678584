import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { BigRadioSelect } from '@orthly/dentin';
import { DenturesBiteAdjustmentType, LabOrderItemSKUType } from '@orthly/items';
import { NumberFlipperInput } from '@orthly/ui';
import React from 'react';

const TryInFeedbackVDOOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Denture>> = ({
    orderItem,
    newItem,
    updateNewItem,
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    const unselect = () => {
        updateNewItem({
            name: 'denture_production_metadata',
            payload: { bite_adjustment: orderItem.denture_production_metadata?.bite_adjustment ?? null },
        });
        onUnselected();
    };

    React.useEffect(() => {
        const biteAdjustmentString = newItem.denture_production_metadata?.bite_adjustment?.adjustment_distance_mm
            ? `${newItem.denture_production_metadata.bite_adjustment.adjustment_type} Bite ${newItem.denture_production_metadata.bite_adjustment.adjustment_distance_mm}mm`
            : undefined;
        if (isSelected && biteAdjustmentString && biteAdjustmentString !== description) {
            onDescriptionChanged(biteAdjustmentString);
        }
    }, [isSelected, newItem.denture_production_metadata?.bite_adjustment, description, onDescriptionChanged]);

    return (
        <TryInFeedbackOptionWrapper
            title={'Vertical dimension of occlusion (VDO)'}
            subtitle={'Denture height is incorrect'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={unselect}
            hasError={isSelected && showError && !description}
        >
            <BigRadioSelect<DenturesBiteAdjustmentType>
                value={newItem.denture_production_metadata?.bite_adjustment?.adjustment_type ?? undefined}
                onChange={val => {
                    if (val !== newItem.denture_production_metadata?.bite_adjustment?.adjustment_type) {
                        updateNewItem({
                            name: 'denture_production_metadata',
                            payload: { bite_adjustment: { adjustment_type: val, adjustment_distance_mm: 1 } },
                        });
                    }
                }}
                options={[
                    { label: 'Close Bite', value: DenturesBiteAdjustmentType.Close },
                    { label: 'Open Bite', value: DenturesBiteAdjustmentType.Open },
                ]}
                selectedEndAdornment={
                    <div style={{ margin: -16 }} onClick={e => e.stopPropagation()}>
                        <NumberFlipperInput
                            value={newItem.denture_production_metadata?.bite_adjustment?.adjustment_distance_mm ?? 1}
                            onChange={val => {
                                if (!newItem.denture_production_metadata?.bite_adjustment) {
                                    return;
                                }
                                updateNewItem({
                                    name: 'denture_production_metadata',
                                    payload: {
                                        bite_adjustment: {
                                            ...newItem.denture_production_metadata.bite_adjustment,
                                            adjustment_distance_mm: val,
                                        },
                                    },
                                });
                            }}
                            stepSize={1}
                            min={1}
                            max={10}
                            unitLabel={'mm'}
                            variant={'contained'}
                        />
                    </div>
                }
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackVDOOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Denture> = {
    sku: LabOrderItemSKUType.Denture,
    isArchSpecific: false,
    category: 'VDO',
    isEnabled: () => true,
    Component: TryInFeedbackVDOOption,
};
