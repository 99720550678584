import type { TraineeFormFieldsProps } from '../TrainingDataIntake.types';
import type { TraineeAtom } from '../hooks/useTrainees';
import { useTrainees } from '../hooks/useTrainees';
import { Button } from '@orthly/ui-primitives';
import { useAtomValue } from 'jotai';
import React from 'react';

const useButtonLabel = (traineeAtom: TraineeAtom, listIndex: number) => {
    const trainee = useAtomValue(traineeAtom);
    const label = trainee.name ? trainee.name : `Person ${listIndex + 1}`;
    return `Remove ${label}`;
};

export const RemoveTraineeButton: React.VFC<TraineeFormFieldsProps> = props => {
    const { traineeAtom, listIndex } = props;
    const { trainees, removeTrainee } = useTrainees();
    const label = useButtonLabel(traineeAtom, listIndex);

    if (trainees.length === 1) {
        return null;
    }

    return (
        <div>
            <Button
                variant={'secondary-gray'}
                style={{ padding: '4px 12px', height: 'unset' }}
                startIcon={'TrashIcon'}
                onClick={() => removeTrainee(traineeAtom)}
            >
                {label}
            </Button>
        </div>
    );
};
