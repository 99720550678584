import type { LabsGqlPendingAlignerCaseRejectionReason } from '@orthly/graphql-schema';
import type { OrderItemArch } from '@orthly/items';

export enum AlignerCheckoutStep {
    PatientInformation = 'PatientInformation',
    ImageUploadLink = 'ImageUploadLink',
    ImageUploadReview = 'ImageUploadReview',
    ArchSelection = 'ArchSelection',
    MovementRestrictedTeethSelection = 'MovementRestrictedTeethSelection',
    AttachmentRestrictedTeethSelection = 'AttachmentRestrictedTeethSelection',
    ExtractionTeethSelection = 'ExtractionTeethSelection',
    SpacingSelection = 'SpacingSelection',
    CrowdingResolution = 'CrowdingResolution',
    BiteConcerns = 'BiteConcerns',
    Estimating = 'Estimating',
    EstimateReview = 'EstimateReview',
    Summary = 'Summary',
    Rejection = 'Rejection',
    RetainerArchSelection = 'RetainerArchSelection',
    RetainerAdditionalInfo = 'RetainerAdditionalInfo',
}

export type AlignerRejection = {
    reason: LabsGqlPendingAlignerCaseRejectionReason;
    notes: string | null;
};

export interface AlignerRetainerCheckoutState {
    arch: OrderItemArch;
    quantity: number;
    hasRestorations: boolean;
    isFixedLingual: boolean;
}

export interface AlignerRetainerPricingSummaryCheckout {
    freeSetsRemaining: number;
    totalSetsOrdered: number;
}
