import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { DateUtils } from '@orthly/shared-types';
import React from 'react';

export class CheckoutDisplayUtils {
    static summaryEnterTimeout = 400;
    static summaryExitTimeout = 400;

    static PRE_SUMMARY_MAIN_ID = 'pre-summary-main';
    static DEFAULT_STROKE = '#A6A6A7';
}

export function checkoutRefabOrderSummaryText(original_order?: LabsGqlLabOrderFragment): string {
    const placedDate = original_order?.created_at;
    const deliveryDate = original_order?.delivery_date;
    if (!deliveryDate && !placedDate) {
        return '';
    }
    const dateFmt = deliveryDate
        ? `delivered on ${DateUtils.format(deliveryDate, 'MMM Do')}`
        : `placed on ${DateUtils.format(placedDate, 'MMM Do')}`;
    return `Refabricating order ${dateFmt}`;
}

export function checkoutRescanOrderSummaryText(original_order?: LabsGqlLabOrderFragment): string {
    const placedDate = original_order?.created_at;
    if (!placedDate) {
        return '';
    }
    const dateFmt = `placed on ${DateUtils.format(placedDate, 'MMM Do')}`;
    return `Rescan for order ${dateFmt}`;
}

export const useCheckoutTransitionTimeLapsed = () => {
    const [done, setDone] = React.useState<boolean>(false);
    React.useEffect(() => {
        window.setTimeout(() => setDone(true), CheckoutDisplayUtils.summaryEnterTimeout);
    }, [setDone]);
    return done;
};
