import { AnalyticsClient } from '../../analytics/analyticsClient';
import { PracticeOrderSortOptions } from '../../redux/orders/orders.reducer';
import { useOrdersToolbarSelectProps } from './useOrdersToolbarSelectProps';
import { SimpleSelect } from '@orthly/ui';
import { FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OrdersOverviewToolbarSortProps {
    sortBy: PracticeOrderSortOptions;
    setSortBy: (sort: PracticeOrderSortOptions) => void;
}

const OrdersToolbarSortOptionsToLabelMap: Record<PracticeOrderSortOptions, string> = {
    [PracticeOrderSortOptions.Relevancy]: 'Relevancy',
    [PracticeOrderSortOptions.ExpectedDeliveryDate]: 'Expected Delivery Date',
    [PracticeOrderSortOptions.MostRecentlyCreated]: 'Most Recently Created',
    [PracticeOrderSortOptions.MostRecentlyUpdated]: 'Most Recently Updated',
    [PracticeOrderSortOptions.PatientFirstAscending]: 'Patient First Name (A-Z)',
    [PracticeOrderSortOptions.PatientFirstDescending]: 'Patient First Name (Z-A)',
    [PracticeOrderSortOptions.PatientLastAscending]: 'Patient Last Name (A-Z)',
    [PracticeOrderSortOptions.PatientLastDescending]: 'Patient Last Name (Z-A)',
};

export const OrdersToolbarSort: React.VFC<OrdersOverviewToolbarSortProps> = props => {
    const selectProps = useOrdersToolbarSelectProps();
    const { sortBy, setSortBy } = props;
    return (
        <Grid container wrap={'nowrap'}>
            <SimpleSelect
                {...selectProps}
                options={Object.entries(OrdersToolbarSortOptionsToLabelMap).map(([value, label]) => ({
                    value: `${value}`,
                    label,
                }))}
                onChange={value => {
                    if (value) {
                        AnalyticsClient.track('Practice - Order List - Sort By Option Selected', {
                            sortBy: value,
                        });
                        setSortBy(value as any);
                    }
                }}
                label={'Sort by'}
                value={`${sortBy}`}
                SelectProps={{ ...selectProps.SelectProps, displayEmpty: false, variant: 'standard' }}
                variant={'filled'}
                backgroundColor={FlossPalette.WHITE}
                style={{ borderRadius: 0, border: 'none', boxShadow: 'none' }}
            />
        </Grid>
    );
};
