import { useCheckoutPropSelector } from '../../../../redux';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import { useDentureCheckoutSelector, useDentureCheckoutStepInfo } from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { CheckoutBodyWrapper, DentureCheckoutSmileStyleSelect } from '@orthly/dentin';
import React from 'react';

export const InitialDentureStyleScreen: React.FC = () => {
    const selectedSmileChoice = useDentureCheckoutSelector(s => s.fields.dentureStyle);
    const { patient_first_name } = useCheckoutPropSelector(['patient_first_name']);
    const setTeethStyle = useDenturesCheckoutAction('CHOOSE_DENTURE_STYLE');
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(
        DentureCheckoutStep.InitialNonWaxRimOrderDentureStyleStep,
    );
    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper
            style={{ height: '100%', marginBottom: 16 }}
            visible={isActiveStep}
            isComplete={isComplete}
        >
            <div style={{ marginTop: 24 }}>
                <DentureCheckoutSmileStyleSelect
                    value={selectedSmileChoice}
                    onChange={setTeethStyle}
                    patientName={patient_first_name ?? ''}
                />
            </div>
        </CheckoutBodyWrapper>
    );
};
