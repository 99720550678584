import { AddPhoneNumber as AddPhoneNumberBase } from './AddPhoneNumber';
import { AddPhoneNumberProvider } from './useAddPhoneNumber';

export const AddPhoneNumber = () => {
    return (
        <AddPhoneNumberProvider>
            <AddPhoneNumberBase />
        </AddPhoneNumberProvider>
    );
};
