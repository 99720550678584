import { CheckoutSidebarConcierge, useConciergeData } from './CheckoutSidebarConcierge/CheckoutSidebarConcierge';
import type { CheckoutSidebarStepProps } from '@orthly/dentin';
import { CheckoutSidebar } from '@orthly/dentin';
import React from 'react';

interface CheckoutConciergeSidebarProps {
    orderNumber?: string;
    steps: CheckoutSidebarStepProps[];
}

export const CheckoutSidebarWithConcierge: React.VFC<CheckoutConciergeSidebarProps> = props => {
    const conciergeData = useConciergeData();

    return (
        <CheckoutSidebar
            steps={props.steps}
            orderNumber={props.orderNumber}
            BottomSection={conciergeData && <CheckoutSidebarConcierge {...conciergeData} />}
        />
    );
};
