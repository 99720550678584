import { useCheckoutSelector } from '../../redux';
import { CheckoutScrollToProvider } from './Checkout.util';
import { CheckoutLayoutRoot } from './components/CheckoutLayout/CheckoutLayoutRoot';
import { CheckoutMainScreens } from './screens/CheckoutMainScreens';
import { CheckoutSummaryScreen } from './screens/CheckoutSummaryStep/CheckoutSummaryScreen';
import { CheckoutDisplayUtils } from './state/CheckoutDisplayUtils';
import { useCheckoutScansQuery } from './state/useCheckoutScans';
import { useCheckoutSummaryVisible } from './state/useCheckoutSummaryVisible';
import { CheckoutSubmitOrderProvider, useSubmitOrder } from './state/useSubmitOrder';
import { useBeforeUnload } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grid, Slide } from '@orthly/ui-primitives';
import cx from 'classnames';
import isElectron from 'is-electron';
import React from 'react';

function createSlideTransition(theme: Theme): string {
    return theme.transitions.create(['transform', 'visibility', 'opacity'], {
        duration: CheckoutDisplayUtils.summaryExitTimeout,
        easing: theme.transitions.easing.sharp,
    });
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        slideWrap: {
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            background: '#fff',
            transition: `${createSlideTransition(theme)} !important`,
            minHeight: '80vh',
            [theme.breakpoints.down('md')]: {
                height: '100%',
                minHeight: 'unset',
                // '-webkit-overflow-scrolling': 'auto'
            },
        },
        slideWrapHidden: {
            opacity: 0.7,
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
    }),
);

const SLIDE_TIMEOUT = {
    enter: CheckoutDisplayUtils.summaryEnterTimeout,
    exit: CheckoutDisplayUtils.summaryExitTimeout,
};

const CheckoutV2: React.FC = () => {
    const summaryShowing = useCheckoutSummaryVisible();
    const classes = useStyles();
    const scansQuery = useCheckoutScansQuery();
    const submitOrder = useSubmitOrder();

    const isCheckoutActive = useCheckoutSelector(s => !!s.scan);
    useBeforeUnload(event => {
        // We only prevent leaving the page if they're on the web interface,
        // as electron cannot be refreshed easily, and the warning popup doesn't render in electron.
        if (!isElectron) {
            event.preventDefault();
        }
    }, isCheckoutActive && !isElectron);

    return (
        <CheckoutScrollToProvider>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <CheckoutLayoutRoot loading={scansQuery.loading || submitOrder.submitting} onSubmit={submitOrder.submit}>
                <Slide direction={'down'} in={!summaryShowing} timeout={SLIDE_TIMEOUT} exit>
                    <Grid container className={cx(classes.slideWrap, summaryShowing && classes.slideWrapHidden)}>
                        <CheckoutMainScreens scansQuery={scansQuery} />
                    </Grid>
                </Slide>
                <Slide direction={'up'} in={summaryShowing} timeout={SLIDE_TIMEOUT} exit>
                    <Grid container className={cx(classes.slideWrap, !summaryShowing && classes.slideWrapHidden)}>
                        {summaryShowing && <CheckoutSummaryScreen />}
                    </Grid>
                </Slide>
            </CheckoutLayoutRoot>
        </CheckoutScrollToProvider>
    );
};

export const CheckoutV2Root: React.FC = () => (
    <CheckoutSubmitOrderProvider>
        <CheckoutV2 />
    </CheckoutSubmitOrderProvider>
);
