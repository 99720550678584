import { ItemEditorV2Utils } from '../../ItemEditorV2/utils/ItemEditorV2.util';
import { useGetCartDeliveryOptionsQuery } from '@orthly/graphql-react';
import { LabsGqlCartSlaModifier, OrderItemV2InputUtils } from '@orthly/graphql-schema';
import type { ICartItemV2DTO } from '@orthly/items';
import { CartItemV2Utils } from '@orthly/items';
import { LoadBlocker, DeliveryMopedIcon } from '@orthly/ui';
import {
    stylesFactory,
    FlossPalette,
    CheckboxPrimitive,
    Text,
    DesignV2Icon,
    ExclamationIcon,
    styled,
    FormControlLabel,
} from '@orthly/ui-primitives';
import moment from 'moment/moment';
import React from 'react';

const StyledExclamationIcon = styled(ExclamationIcon)({
    height: 12,
    width: 12,
    color: FlossPalette.ATTENTION,
});
const EtaErrorContainer = styled('div')({
    display: 'flex',
    gridGap: 4,
    maxWidth: 438,
});

const useStyles = stylesFactory(() => ({
    arrowIcon: {
        height: 18,
        width: 18,
        placeContent: 'center',
    },
    delayed: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    delayedInner: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        gridGap: 12,
    },
    delayTitle: {
        display: 'flex',
    },
    delayedDateWrapper: {
        display: 'flex',
        gap: 4,
        alignItems: 'center',
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        padding: 8,
    },
    deliveryIcon: {
        color: FlossPalette.GRAY,
        marginRight: 4,
    },
    displayedDate: {
        marginLeft: `8px`,
    },
    estimatedDelivery: {
        marginRight: 8,
    },
    checkboxControl: {
        marginLeft: `-10px`,
    },
    strikeThroughDate: {
        textDecoration: 'line-through',
    },
}));

interface OrderEditDeliveryDateProps {
    originalDeliveryEta: string;
    originalDesignPreviewEta: string | null;
    items: ICartItemV2DTO[];
    hasPassedEtaValidation: boolean;
    setHasPassedEtaValidation: (hasAcked: boolean) => void;
    doctorId: string;
    hasWaxup: boolean;
    isPaused: boolean;
    mailingAddressId: string;
    /** NOTE: this needs to be the threeshape case id (threeshape_order_id) not the order id */
    caseId: string;
}

const formatDate = (dateString: string | undefined) => {
    return dateString ? moment(dateString).format('MMM DD') : '';
};

const calculateDelay = (originalDate: string, newDate: string | undefined): number => {
    if (!newDate || originalDate === newDate) {
        return 0;
    }

    const originalMomentDate = moment(originalDate);
    const newMomentDate = moment(newDate);

    const diffInDays = newMomentDate.diff(originalMomentDate, 'days');

    // diff days will return 0 if 2 dates are separated by less than 24 hours
    // so we check if the diff in days is 0, that the day is actually the same,
    // and if not we return 1 as there'll be a one day delay for the doctor
    if (
        diffInDays === 0 &&
        newMomentDate.isAfter(originalMomentDate) &&
        originalMomentDate.dayOfYear() !== newMomentDate.dayOfYear()
    ) {
        return 1;
    } else {
        return newMomentDate.diff(originalMomentDate, 'days');
    }
};

const useDeliveryEstimate = ({
    items,
    doctorId,
    hasWaxup,
    originalDeliveryEta,
    originalDesignPreviewEta,
    mailingAddressId,
    caseId,
}: Pick<
    OrderEditDeliveryDateProps,
    | 'items'
    | 'doctorId'
    | 'hasWaxup'
    | 'originalDeliveryEta'
    | 'originalDesignPreviewEta'
    | 'mailingAddressId'
    | 'caseId'
>) => {
    const cleanedItems = items.map(i => ItemEditorV2Utils.cleanItem(i));

    const { loading, data } = useGetCartDeliveryOptionsQuery({
        variables: {
            waxup: hasWaxup,
            doctor_id: doctorId,
            items_v2_by_sku: OrderItemV2InputUtils.getOrderItemV2InputBySKU(cleanedItems) ?? {},
            mailing_address_id: mailingAddressId,
            case_id: caseId,
            // we only want the standard option so we don't ask for any other potential options
            enable_delivery_options: false,
        },
        skip: !cleanedItems.length,
    });

    // note: endpoint will always return the standard option when the request succeeds, so we should always find it
    const standardDeliveryOption = data?.getCartDeliveryOptions.options.find(
        o => o.sla_modifier === LabsGqlCartSlaModifier.Standard,
    );
    const updatedDesignPreviewEta = standardDeliveryOption?.design_preview_eta;
    const updatedDeliveryEta = standardDeliveryOption?.delivery_date;

    const isDelayed = React.useMemo(() => {
        if (hasWaxup) {
            return (
                updatedDesignPreviewEta &&
                originalDesignPreviewEta &&
                calculateDelay(originalDesignPreviewEta, updatedDesignPreviewEta) > 0
            );
        } else {
            return calculateDelay(originalDeliveryEta, updatedDeliveryEta) > 0;
        }
    }, [hasWaxup, originalDeliveryEta, originalDesignPreviewEta, updatedDesignPreviewEta, updatedDeliveryEta]);

    return {
        isDelayed: !!isDelayed,
        updatedEta: hasWaxup ? updatedDesignPreviewEta : updatedDeliveryEta,
        loading,
    };
};

const FormattedEtaText: React.VFC<{
    updatedEta?: string | null;
    isDelayed: boolean;
    items: ICartItemV2DTO[];
    originalEta: string | null;
    loading: boolean;
}> = ({ updatedEta, isDelayed, items, originalEta, loading }) => {
    const classes = useStyles();

    const formattedItems = CartItemV2Utils.getItemsDisplayNames(items, 'comma');

    if (loading) {
        return (
            <div className={classes.delayedDateWrapper}>
                <Text variant={'caption'} medium color={'PRIMARY_FOREGROUND'}>
                    {formattedItems}: Loading
                </Text>
            </div>
        );
    }

    if ((!isDelayed && updatedEta) || !items.length) {
        return (
            <div className={classes.delayedDateWrapper}>
                <Text variant={'caption'} medium color={'PRIMARY_FOREGROUND'}>
                    {formattedItems}: No changes to delivery date
                </Text>
            </div>
        );
    }

    if (updatedEta && isDelayed) {
        /* delayed show old and new date */
        return (
            <div className={classes.delayedDateWrapper}>
                <Text variant={'caption'} medium color={'PRIMARY_FOREGROUND'}>
                    {formattedItems}:
                </Text>
                {originalEta && (
                    <Text variant={'caption'} medium color={'GRAY'} className={classes.strikeThroughDate}>
                        {formatDate(originalEta)}
                    </Text>
                )}
                <Text variant={'caption'} medium color={'PRIMARY_FOREGROUND'}>
                    {formatDate(updatedEta)}
                </Text>
            </div>
        );
    }

    return (
        <EtaErrorContainer>
            <StyledExclamationIcon />
            <Text variant={'caption'} color={'ATTENTION'}>
                Something went wrong and we're unable to calculate a new delivery date. Please contact us if the problem
                persists.
            </Text>
        </EtaErrorContainer>
    );
};

/**
 * Please be careful about rendering this component! Generating delivery dates is an expensive operation
 * and should be done as infrequently as possible within a given flow.
 */
export const OrderEditDeliveryDate: React.VFC<OrderEditDeliveryDateProps> = ({
    originalDeliveryEta,
    items,
    hasPassedEtaValidation,
    setHasPassedEtaValidation,
    doctorId,
    hasWaxup,
    originalDesignPreviewEta,
    isPaused,
    mailingAddressId,
    caseId,
}) => {
    const classes = useStyles();
    const { updatedEta, isDelayed, loading } = useDeliveryEstimate({
        items,
        doctorId,
        hasWaxup,
        originalDeliveryEta,
        originalDesignPreviewEta,
        mailingAddressId,
        caseId,
    });

    const originalEta = hasWaxup ? originalDesignPreviewEta : originalDeliveryEta;

    const needsValidation = isDelayed && items.length > 0;

    React.useEffect(() => {
        // If there is no delay, we automatically accept new ETA
        if (updatedEta && !needsValidation) {
            setHasPassedEtaValidation(true);
        }
    }, [updatedEta, needsValidation, setHasPassedEtaValidation]);

    return (
        <LoadBlocker blocking={loading}>
            <div className={classes.delayed}>
                <div className={classes.delayedInner}>
                    <div className={classes.delayTitle}>
                        {hasWaxup ? (
                            <DesignV2Icon className={classes.deliveryIcon} />
                        ) : (
                            <DeliveryMopedIcon className={classes.deliveryIcon} />
                        )}
                        <Text variant={'body2'} medium color={'GRAY'} className={classes.estimatedDelivery}>
                            {hasWaxup ? 'Design preview will be ready by:' : 'Estimated delivery:'}
                        </Text>
                    </div>
                    <FormattedEtaText
                        updatedEta={updatedEta}
                        isDelayed={isDelayed}
                        items={items}
                        originalEta={originalEta}
                        loading={loading}
                    />
                </div>
                {needsValidation && (
                    <FormControlLabel
                        control={
                            <CheckboxPrimitive
                                data-testid={'accept-delay-checkbox'}
                                color={'secondary'}
                                checked={hasPassedEtaValidation}
                                onChange={e => {
                                    setHasPassedEtaValidation(e.target.checked);
                                }}
                            />
                        }
                        label={
                            <Text style={{ marginLeft: -16 }} variant={'body2'}>
                                I acknowledge proceeding will result in a delay as the product will need to be
                                redesigned.
                            </Text>
                        }
                        className={classes.checkboxControl}
                    />
                )}
                {isPaused && (
                    <Text style={{ marginLeft: needsValidation ? '40px' : 0 }} variant={'body2'}>
                        The order will resume with your changes applied.
                    </Text>
                )}
            </div>
        </LoadBlocker>
    );
};
