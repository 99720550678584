import type { LabsGqlOrderTimelineV2Attachment } from '@orthly/graphql-schema';
import type { FirebasePreviewFileMulti } from '@orthly/veneer';
import { DandyLightbox, useFirebasePreviewMulti } from '@orthly/veneer';
import React from 'react';

const useOrderTimelineLightboxContext = ({ attachments }: { attachments: FirebasePreviewFileMulti[] }) => {
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);
    const { result: previews, loading: isLoadingPreviews } = useFirebasePreviewMulti(attachments);

    return { isLoadingPreviews, previews, selectedAttachmentPreview, setSelectedAttachmentPreview };
};

const orderTimelineLightboxContext = React.createContext<
    ReturnType<typeof useOrderTimelineLightboxContext> | undefined
>(undefined);

export const useOrderTimelineLightbox = () => {
    const context = React.useContext(orderTimelineLightboxContext);

    if (context === undefined) {
        throw new Error('You must use useOrderTimelineLightbox within a descendent of OrderTimelineLightboxProvider');
    }

    return context;
};

export const OrderTimelineLightboxProvider: React.FC<{
    attachments: LabsGqlOrderTimelineV2Attachment[];
    orderId: string | undefined;
}> = ({ attachments, children, orderId }) => {
    const filteredAttachments = attachments.filter(
        attachment => !attachment.downloadUrl && !/https?:\/\//i.test(attachment.source),
    );
    const context = useOrderTimelineLightboxContext({ attachments: filteredAttachments });

    return (
        <orderTimelineLightboxContext.Provider value={context}>
            <DandyLightbox
                loading={context.isLoadingPreviews}
                previews={context.previews}
                setSelectedAttachmentPreview={context.setSelectedAttachmentPreview}
                selectedAttachmentPreview={context.selectedAttachmentPreview}
                onPhotoViewedAnalytics={(source, name) => {
                    return {
                        name: 'All - Portal - Photo Lightbox Viewed',
                        data: {
                            $groups: { order: orderId },
                            displayLocation: 'order_timeline',
                            photoSource: source,
                            photoName: name,
                        },
                    };
                }}
            />
            {children}
        </orderTimelineLightboxContext.Provider>
    );
};
