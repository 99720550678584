import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { usePracticeAppSelector } from '../../../redux';
import { useOrderAction } from '../../../redux/orders/orders.actions';
import { OpenCheckoutSubNavButton } from '../components/OpenCheckoutSubNavButton';
import { useOrdersOverviewSidebarItems } from './useOrdersOverviewItems';
import { LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { PRACTICE_ORDER_STATUS_FILTER_NAME } from '@orthly/shared-types';
import { SubNavigation } from '@orthly/ui';
import { stylesFactory, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    root: {
        [theme.breakpoints.down('md')]: {
            width: 300,
        },
    },
}));

const ORDER_STATUSES = Object.values(LabsGqlPracticeOrderStatus);

export const OrdersSidebar: React.FC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const status = usePracticeAppSelector(s => s.orders.status);
    const setStatus = useOrderAction('CHANGE_STATUS');
    const sections = useOrdersOverviewSidebarItems();
    const { search } = useLocation();
    const history = useHistory();

    /**
     * Effect used to allow the order list to be filtered by statuses from the URL.
     *
     * We have an email sending doctors to http:/.../lab/?statusFilter=NeedsOrderFeedback
     * where we want the order listing to only show orders that need feedback.
     *
     * After we propagate the Redux action, we update the URL to remove the url filter as otherwise
     * it might get confusing since the URL parameter wouldn't update when a user clicks on other
     * sections in the UI.
     */
    React.useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const statusUrlFilter = urlParams.get(PRACTICE_ORDER_STATUS_FILTER_NAME);

        if (statusUrlFilter) {
            const statusToFilterBy = ORDER_STATUSES.find(status => status === statusUrlFilter);
            if (statusToFilterBy) {
                setStatus(statusToFilterBy);
            }

            // Remove ?statusFilter=xyz from the URL and update the displayed URL in the browser
            urlParams.delete(PRACTICE_ORDER_STATUS_FILTER_NAME);
            history.replace({ search: urlParams.toString() });
        }
    }, [setStatus, search, history]);

    if (isMobile) {
        return null;
    }

    return (
        <SubNavigation
            title={'Orders'}
            classes={{ root: classes.root }}
            value={status}
            onChange={statusFilter => {
                AnalyticsClient.track('Practice - Order List - Left Side Filter Selection', {
                    statusFilter: statusFilter,
                });
                setStatus(statusFilter);
            }}
            sections={sections}
            bottomContent={<OpenCheckoutSubNavButton />}
        />
    );
};
