import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { BigRadioSelect } from '@orthly/dentin';
import { LabOrderItemSKUType, OrderItemPartialDentureProductionType } from '@orthly/items';
import { NumberFlipperInput } from '@orthly/ui';
import React from 'react';

const CHANGE_LENGTH_REGEX = /(Shift right|Shift left) (\d+)mm/;

function parseDescription(description: string | null): { direction: string; distance: number } | null {
    if (!description) {
        return null;
    }
    const [, direction, distance] = (description ?? '').match(CHANGE_LENGTH_REGEX) ?? [];
    if (direction && distance) {
        return { direction, distance: parseInt(distance) };
    }
    return null;
}

const TryInFeedbackPartialMidlineOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial>> = ({
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    const parsed = parseDescription(description);

    return (
        <TryInFeedbackOptionWrapper
            title={'Midline'}
            subtitle={'Central incisor alignment is off-center'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <BigRadioSelect
                value={parsed?.direction ?? undefined}
                onChange={val => {
                    if (val !== parsed?.direction) {
                        onDescriptionChanged(`${val} 1mm`);
                    }
                }}
                options={[
                    { label: 'Shift right', value: 'Shift right' },
                    { label: 'Shift left', value: 'Shift left' },
                ]}
                selectedEndAdornment={
                    parsed?.direction ? (
                        <div style={{ margin: -16 }} onClick={e => e.stopPropagation()}>
                            <NumberFlipperInput
                                value={parsed.distance ?? 1}
                                onChange={val => {
                                    onDescriptionChanged(`${parsed.direction} ${val}mm`);
                                }}
                                stepSize={1}
                                min={1}
                                max={10}
                                unitLabel={'mm'}
                                variant={'contained'}
                            />
                        </div>
                    ) : undefined
                }
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackPartialMidlineOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Partial> = {
    sku: LabOrderItemSKUType.Partial,
    isArchSpecific: true,
    category: 'Midline',
    isEnabled: item => item.partial_production_type !== OrderItemPartialDentureProductionType.WaxRim,
    Component: TryInFeedbackPartialMidlineOption,
};
