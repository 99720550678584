import { ChatUnavailableRoot } from '../ChatUnavailableRoot';
import { ZendeskChatSessionWrapper } from '../ZendeskChat';
import { useCSTAction } from '../cst-state/cst.actions';
import { useZendeskChat } from '../useZendeskChat';
import { ChatWindowBase } from './ChatWindowBase';
import { Grid } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const StandAloneChatWrapper: React.VFC = () => {
    const onMounted = useCSTAction('ON_STANDALONE_CST_MOUNTED');
    useZendeskChat({ autoOpen: false });

    React.useEffect(() => {
        onMounted();
    }, [onMounted]);

    return (
        <Grid container style={{ height: '100vh' }}>
            <ChatUnavailableRoot />
            <ChatWindowBase />
        </Grid>
    );
};

/**
 * Standalone Chat is used in Uploader and Chairside
 */
export const StandaloneChatWindowRoot: React.VFC = () => {
    const { value: showZdChatDirectlyInChairside } = useFeatureFlag('showZdChatDirectlyInChairside');

    return (
        <Grid container style={{ height: '100vh' }}>
            {showZdChatDirectlyInChairside ? <ZendeskChatSessionWrapper /> : <StandAloneChatWrapper />}
        </Grid>
    );
};
