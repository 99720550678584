import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import {
    PracticeScreen,
    DandySearchInput,
    DandySearchOptions,
    useDandySearchTools,
    useSaveSearchAction,
} from '@orthly/dentin';
import { useCreateSavedOrderSearchMutation, useOrderSearchResults } from '@orthly/graphql-react';
import { Grid, Text } from '@orthly/ui-primitives';
import { useSavedSearchData, useSearchAnalyticsAction } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const MobileSearchRoot: React.FC = () => {
    const history = useHistory();
    const [search, setSearch] = React.useState('');
    const [isDebounced, setIsDebounced] = React.useState(false);
    const { savedSearchData, refetchSavedSearches } = useSavedSearchData();
    const onSearchAnalyticsAction = useSearchAnalyticsAction();
    const [submitMutation] = useCreateSavedOrderSearchMutation();
    const saveSearchAction = useSaveSearchAction(refetchSavedSearches, async () => {
        await submitMutation();
    });

    const { debouncedOnChange, onInputChangeAction, onInputFocusAction, onInputClearAction, orders, loading } =
        useDandySearchTools({
            setSearch,
            setIsDebounced,
            useOrderSearchResults,
            savedSearchData,
            onSearchAnalyticsAction,
        });

    React.useEffect(() => {
        return () => {
            debouncedOnChange.cancel();
        };
    }, [debouncedOnChange]);

    const onNavigationAction = (orderId: string | number) => {
        history.push(`/${PracticeScreen.orders}/${orderId}`);
    };

    return (
        <MobilePageLayout
            title={'Search'}
            titleHasBackButton={false}
            customHeaderComponent={
                <Grid container style={{ padding: '11px 16px' }}>
                    <DandySearchInput
                        search={search}
                        onInputChange={onInputChangeAction}
                        onInputFocus={onInputFocusAction}
                        onInputClear={onInputClearAction}
                    />
                </Grid>
            }
        >
            {!loading && savedSearchData.length === 0 && orders.length === 0 && (
                <Text variant={'body2'} medium color={'GRAY'} style={{ padding: 16 }}>
                    No recent searches. Try searching for an active order.
                </Text>
            )}
            {(savedSearchData.length > 0 || orders.length > 0) && (
                <DandySearchOptions
                    search={search}
                    savedSearches={savedSearchData}
                    onNavigationAction={onNavigationAction}
                    orders={orders}
                    loading={loading || isDebounced}
                    saveSearchAction={saveSearchAction}
                />
            )}
        </MobilePageLayout>
    );
};
