export enum CheckoutSidebarStepVariant {
    Header = 'Header',
    Attention = 'Attention',
    Complete = 'Complete',
    Current = 'Current',
    Pending = 'Pending',
    Checkout = 'Checkout',
}

export const orderOfVariant: { [_ in CheckoutSidebarStepVariant]: number } = {
    [CheckoutSidebarStepVariant.Header]: 0,
    [CheckoutSidebarStepVariant.Attention]: 1,
    [CheckoutSidebarStepVariant.Complete]: 2,
    [CheckoutSidebarStepVariant.Current]: 3,
    [CheckoutSidebarStepVariant.Pending]: 4,
    [CheckoutSidebarStepVariant.Checkout]: 5,
};

export interface CheckoutSidebarStepContainerProps {
    variant?: CheckoutSidebarStepVariant;
}
