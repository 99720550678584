import type { CheckoutSidebarCustomStepProps } from './CheckoutSidebarCustomStep';
import { CheckoutSidebarCustomStep } from './CheckoutSidebarCustomStep';
import type { CheckoutSidebarImageStepProps } from './CheckoutSidebarImageStep';
import { CheckoutSidebarImageStep } from './CheckoutSidebarImageStep';
import type { CheckoutSidebarTextStepProps } from './CheckoutSidebarTextStep';
import { CheckoutSidebarTextStep } from './CheckoutSidebarTextStep';
import type { CheckoutSidebarStepContainerProps } from './checkoutSidebarTypes';
import { CheckoutSidebarStepVariant, orderOfVariant } from './checkoutSidebarTypes';
import { Assert } from '@orthly/runtime-utils';
import { CartBackgroundSafeIcon, CheckIcon } from '@orthly/ui';
import { FlossPalette, FlossPaletteUtils, stylesFactory, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    sidebarSubsectionContainer: {
        boxShadow: `inset 2px 0 ${FlossPalette.DARK_TAN}`,
        backgroundColor: FlossPalette.TAN,
        width: '100%',
        flexGrow: 1,
    },
    sidebarContainer: {
        overflowY: 'scroll',
        paddingLeft: 12,
    },
    sidebarStep: {
        boxShadow: `inset 0 1px ${FlossPalette.DARK_TAN}`,
        padding: '16px 48px',
    },
    sidebarStepAttention: {
        backgroundColor: FlossPalette.ATTENTION,
    },
    sidebarStepLeftBlue: {
        boxShadow: `inset 2px 0 ${FlossPalette.BURGUNDY}, inset 0 1px ${FlossPalette.DARK_TAN}`,
    },
    sidebarCurrentStepBackground: {
        backgroundColor: FlossPaletteUtils.toRgba('BURGUNDY', 0.1),
        transition: theme.transitions.create(['background-color'], {
            duration: 200,
            easing: theme.transitions.easing.sharp,
        }),
    },
    indicatorAnchor: {
        position: 'relative',
        '& > *': {
            position: 'absolute',
        },
    },
    indicator: {
        top: '50%',
        height: 20,
        width: 20,
        // icon height / 2 so it's vertically centered
        marginTop: -20 / 2,
        // (icon width - the left blue line width) / 2 so it's horizontally centered
        marginLeft: -(20 - 2) / 2,
    },
    indicatorWithCircle: {
        backgroundColor: FlossPalette.WHITE,
        border: '2px solid',
        borderRadius: '50%',
        display: 'inline-block',
        boxSizing: 'border-box',
    },
}));

const CheckoutSidebarStepContainer = React.forwardRef<
    HTMLDivElement,
    React.PropsWithChildren<CheckoutSidebarStepContainerProps>
>((props, _ref) => {
    const { variant, children } = props;
    const classes = useStyles();
    return (
        <Grid
            container
            direction={'row'}
            alignItems={'stretch'}
            wrap={'nowrap'}
            className={variant === CheckoutSidebarStepVariant.Current ? classes.sidebarCurrentStepBackground : ''}
        >
            {variant !== undefined && (
                <Grid
                    item
                    className={classes.indicatorAnchor}
                    style={{
                        color:
                            orderOfVariant[variant] <= orderOfVariant[CheckoutSidebarStepVariant.Current]
                                ? FlossPalette.BURGUNDY
                                : FlossPalette.DARK_TAN,
                    }}
                >
                    {variant === CheckoutSidebarStepVariant.Complete && (
                        <CheckIcon className={clsx(classes.indicator, classes.indicatorWithCircle)} />
                    )}
                    {orderOfVariant[variant] >= orderOfVariant[CheckoutSidebarStepVariant.Current] &&
                        orderOfVariant[variant] <= orderOfVariant[CheckoutSidebarStepVariant.Pending] && (
                            <Grid className={clsx(classes.indicator, classes.indicatorWithCircle)} />
                        )}
                    {variant === CheckoutSidebarStepVariant.Checkout && (
                        <CartBackgroundSafeIcon
                            className={classes.indicator}
                            style={{
                                // this icon appears smaller than others
                                transform: 'scale(1.2)',
                                transformOrigin: 'center',
                                color: FlossPalette.GRAY,
                            }}
                        />
                    )}
                </Grid>
            )}
            {children && (
                <Grid
                    container
                    direction={'column'}
                    alignItems={'center'}
                    wrap={'nowrap'}
                    className={clsx(
                        classes.sidebarStep,
                        variant === CheckoutSidebarStepVariant.Attention && classes.sidebarStepAttention,
                        variant &&
                            orderOfVariant[variant] <= orderOfVariant[CheckoutSidebarStepVariant.Current] &&
                            classes.sidebarStepLeftBlue,
                    )}
                >
                    {children}
                </Grid>
            )}
        </Grid>
    );
});

type WithKind<TStepProps, TKind extends string> = TStepProps & { kind: TKind };
export type CheckoutSidebarStepProps =
    | WithKind<CheckoutSidebarTextStepProps, 'text'>
    | WithKind<CheckoutSidebarImageStepProps, 'image'>
    | WithKind<CheckoutSidebarCustomStepProps, 'custom'>;

export interface CheckoutSidebarProps {
    orderNumber?: string;
    steps: CheckoutSidebarStepProps[];
    BottomSection?: React.ReactNode;
}

/**
 * The sidebar accepts an array of configs.
 *
 * There should be one and only one step with variant=current.
 *
 * all other steps will have variant automatically set relative to the current step
 *
 * Each config must have a `kind`:
 * - `text`: renders a list of entries
 * - `image`: renders an image, optional caption, and optional bullet point list
 * - `custom`: renders a custom component passed in
 */
export const CheckoutSidebar: React.VFC<CheckoutSidebarProps> = props => {
    const { orderNumber, steps, BottomSection } = props;
    const classes = useStyles();

    const currentStepIndex = steps.findIndex(({ variant }) => variant === CheckoutSidebarStepVariant.Current);

    return (
        <>
            <Grid
                container
                direction={'column'}
                wrap={'nowrap'}
                className={classes.sidebarContainer}
                style={{ height: !!BottomSection ? `75%` : '100%' }}
            >
                <Grid container direction={'column'} wrap={'nowrap'} className={classes.sidebarSubsectionContainer}>
                    <CheckoutSidebarStepContainer variant={CheckoutSidebarStepVariant.Header}>
                        <CheckoutSidebarTextStep
                            variant={CheckoutSidebarStepVariant.Header}
                            texts={[
                                {
                                    caption: 'Summary',
                                    content: orderNumber ? `Order #${orderNumber}` : undefined,
                                },
                            ]}
                        />
                    </CheckoutSidebarStepContainer>
                    {steps.map((step, index) => {
                        const variant =
                            step.variant ??
                            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                            // eslint-disable-next-line no-nested-ternary
                            (index < currentStepIndex
                                ? CheckoutSidebarStepVariant.Complete
                                : index > currentStepIndex
                                  ? CheckoutSidebarStepVariant.Pending
                                  : undefined);
                        /* eslint-disable no-nested-ternary */
                        return (
                            <CheckoutSidebarStepContainer key={index} variant={variant}>
                                {step.kind === 'text' ? (
                                    <CheckoutSidebarTextStep {...step} />
                                ) : step.kind === 'image' ? (
                                    <CheckoutSidebarImageStep {...step} />
                                ) : step.kind === 'custom' ? (
                                    <CheckoutSidebarCustomStep {...step} />
                                ) : (
                                    Assert.unreachable(step)
                                )}
                            </CheckoutSidebarStepContainer>
                        );
                    })}
                </Grid>
            </Grid>
            {!!BottomSection && (
                <Grid
                    container
                    className={classes.sidebarSubsectionContainer}
                    style={{
                        borderTop: `2px solid ${FlossPalette.DARK_TAN}`,
                        marginLeft: 12,
                        height: '25%',
                    }}
                >
                    {BottomSection}
                </Grid>
            )}
        </>
    );
};
