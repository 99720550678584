/* eslint-disable @typescript-eslint/no-non-null-assertion */
import type { Dispatch } from 'redux';
import type { Action, Reducer } from 'redux-actions';
import { createAction } from 'redux-actions';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';

interface ActionCreatorBase<Payload, Name extends string> {
    TYPE: Name;
    SUCCEEDED: string;
    successReducer: <State = any>(
        reducer: NamedActionReducer<State, Payload, Name>,
    ) => NamedActionReducer<State, Payload, Name>;
    handle: <State = any>(
        reducer: NamedActionReducer<State, Payload, Name>,
    ) => NamedActionReducer<State, Payload, Name>;
    reducer: <State = any>(
        reducer: NamedActionReducer<State, Payload, Name>,
    ) => { [SUCCEEDED: string]: NamedActionReducer<State, Payload, Name> };
}

export interface AsyncAction<Args extends any[], R, S extends {}, E extends any = void, A extends Action<R> = Action<R>>
    extends ActionCreatorBase<R, string> {
    (...args: Args): ThunkAction<Promise<AsyncActionFnResult<R, E> | void>, S, E, A>;

    NAME: string;
    START: string;
    STARTED: string;
    SUCCEEDED: string;
    FAILED: string;
    ENDED: string;
    successReducer: <State>(
        reducer: (state: State, action: Action<R>) => State,
    ) => (state: State, action: Action<R>) => ReturnType<typeof reducer>;
    handle: <State>(
        reducer: (state: State, action: Action<R>) => State,
    ) => (state: State, action: Action<R>) => ReturnType<typeof reducer>;
}

type AsyncActionFnResult<R, E = void> = {
    payload: R;
    meta?: E;
};
export type AsyncStartAction<Args = any[]> = Action<{ args: Args; error: undefined }>;
export type AsyncEndAction<Args = any[]> = Action<{ args: Args; elapsed: number }>;
export type AsyncErrorAction<Args = any[]> = Action<{ args: Args; error: Error }>;

export type AsyncActionFn<
    R,
    S extends Record<string, any>,
    I extends any[] = [],
    E = void,
    A extends Action<R> = Action<R>,
> = (
    thunkParams: ThunkParams<R, S, E, A>, // Accepts thunk action params as first
    ...userParams: I
) => Promise<AsyncActionFnResult<R, E>>;

export type ThunkParams<R, S extends Record<string, any>, E, A extends Action<R> = Action<R>> = {
    dispatch: ThunkDispatch<S, E, A | Action<any>>;
    getState: () => S;
    extra: E;
};

export interface NamedAction<Payload, Name extends string> {
    type: Name;
    payload: Payload;
    error?: boolean;
}

export type NamedActionReducer<State, Payload, Name extends string> = (
    state: State,
    action: NamedAction<Payload, Name>,
) => State;

export interface ActionCreatorWithType<Payload, Args extends any[], Name extends string>
    extends ActionCreatorBase<Payload, Name> {
    (...args: Args): NamedAction<Payload, Name>;
}

export function createSyncAction<
    Payload,
    Args extends any[] = [Payload] extends [undefined] ? [] : [Payload],
    Name extends string = string,
>(actionType: Name, payloadCreator?: (...args: Args) => Payload): ActionCreatorWithType<Payload, Args, Name> {
    const actionCreator: ActionCreatorWithType<Payload, Args, Name> = (
        payloadCreator ? createAction(actionType, payloadCreator) : createAction(actionType)
    ) as any;
    actionCreator.TYPE = actionType;
    actionCreator.SUCCEEDED = actionType;
    actionCreator.successReducer = reducer => reducer;
    actionCreator.handle = actionCreator.successReducer;
    actionCreator.reducer = reducer => ({ [actionCreator.SUCCEEDED]: reducer });
    return actionCreator;
}

export function createAsyncAction<
    R, // result
    S extends Record<string, any>, // Store
    Args extends any[] = [],
    A extends Action<R> = Action<R>,
    E extends any = void,
>(type: string, fn: AsyncActionFn<R, S, Args, E, A>, suppressException: boolean = true): AsyncAction<Args, R, S, E, A> {
    // Extra
    const TYPE_STARTED = `${type}_STARTED`;
    const TYPE_SUCCEEDED = `${type}_SUCCEEDED`;
    const TYPE_FAILED = `${type}_FAILED`;
    const TYPE_ENDED = `${type}_ENDED`;

    const actionCreators = {
        [TYPE_STARTED]: createAction(TYPE_STARTED),
        [TYPE_SUCCEEDED]: createAction(TYPE_SUCCEEDED),
        [TYPE_FAILED]: createAction(TYPE_FAILED),
        [TYPE_ENDED]: createAction(TYPE_ENDED),
    };

    const successActionWithMeta = createAction<R, any>(
        TYPE_SUCCEEDED,
        ({ payload }) => payload,
        ({ meta }) => meta,
    );

    const factory: AsyncAction<Args, R, S, E, A> =
        (...args: Args) =>
        async (dispatch, getState, extra) => {
            const thunkParams = { getState, dispatch, extra };
            const startedAt = new Date().getTime();
            // once we are in the function we are dispatching normal actions
            const syncDispatch = dispatch as Dispatch;
            // START
            dispatch(actionCreators[TYPE_STARTED]!({ args }) as any);

            const succeeded = (data: AsyncActionFnResult<R, E>) => {
                syncDispatch<Action<R>>(
                    data && data.hasOwnProperty('payload')
                        ? successActionWithMeta(data)
                        : actionCreators[TYPE_SUCCEEDED]!(data),
                );

                const endedAt = new Date().getTime();

                syncDispatch(
                    actionCreators[TYPE_ENDED]!({
                        args,
                        elapsed: endedAt - startedAt,
                    }),
                );
                return data;
            };

            const failed = (err: Error) => {
                const endedAt = new Date().getTime();
                console.log(err, TYPE_FAILED);
                syncDispatch(
                    actionCreators[TYPE_FAILED]!({
                        args,
                        error: err,
                    }),
                );
                syncDispatch(
                    actionCreators[TYPE_ENDED]!({
                        args,
                        elapsed: endedAt - startedAt,
                    }),
                );
                if (!suppressException) {
                    throw err;
                }
            };
            return fn(thunkParams, ...args)
                .then(succeeded)
                .catch(failed);
        };
    factory.TYPE = type;
    factory.NAME = type;
    factory.START = actionCreators[TYPE_STARTED]!.toString();
    factory.STARTED = factory.START;
    factory.SUCCEEDED = actionCreators[TYPE_SUCCEEDED]!.toString();
    factory.FAILED = actionCreators[TYPE_FAILED]!.toString();
    factory.ENDED = actionCreators[TYPE_ENDED]!.toString();
    // this is just a utility to enforce action typings so you dont have to retype the action in the reducer map
    factory.successReducer = reducer => reducer;
    factory.handle = factory.successReducer;
    factory.reducer = reducer => ({ [factory.SUCCEEDED]: reducer });
    return factory;
}

const ACTION_KEYS = ['STARTED', 'ENDED', 'SUCCEEDED', 'FAILED'];

function getKeyAndType(actionType: string) {
    const split = actionType.split('_');
    let type: undefined | string = split[split.length - 1];
    const key = split.slice(0, split.length - 1).join('_');
    if (ACTION_KEYS.indexOf(type ?? '') < 0) {
        type = undefined;
    }
    return { key, type };
}

export interface ThunkActionsLoadingState {
    loading: {
        [k: string]: boolean;
    };
}

export function loadingHandler<S extends ThunkActionsLoadingState = ThunkActionsLoadingState, P = any>(
    state: S,
    action: Action<P>,
): S {
    const { key, type } = getKeyAndType(action.type);
    if (key && type && (type === 'STARTED' || type === 'ENDED')) {
        return {
            ...state,
            loading: {
                ...state.loading,
                [key]: type === 'STARTED',
            },
        };
    }
    return state;
}

export interface ThunkActionsErrorState {
    errors: {
        [k: string]: any;
    };
}

export interface ThunkActionsSuccessState {
    // Mapped to json stringified date
    succeeded: Record<string, string>;
}

export function successHandler<S extends ThunkActionsSuccessState = ThunkActionsSuccessState, P = any>(
    state: S,
    action: Action<P>,
): S {
    const { key, type } = getKeyAndType(action.type);

    if (key && type && type !== 'ENDED') {
        return {
            ...state,
            succeeded: {
                ...state.succeeded,
                [key]: type === 'SUCCEEDED' ? new Date().toJSON() : undefined,
            },
        };
    }
    return state;
}

export type GenPayload = {
    [k: string]: any;
};

export function errorHandler<
    S extends ThunkActionsErrorState = ThunkActionsErrorState,
    P extends GenPayload = GenPayload,
>(state: S, action: Action<P>): S {
    const { key, type } = getKeyAndType(action.type);
    const error = type && type === 'FAILED' && !!action.payload && action.payload.error;

    if (error) {
        return {
            ...state,
            errors: {
                ...state.errors,
                [key]: error,
            },
        };
    }
    if (key && type && type === 'STARTED') {
        return {
            ...state,
            errors: {
                ...state.errors,
                [key]: undefined,
            },
        };
    }
    return state;
}

function persistHandler<S extends ThunkActionsErrorState = ThunkActionsErrorState, P extends GenPayload = GenPayload>(
    state: S,
    action: Action<P>,
): S {
    if (action.type === 'persist/REHYDRATE') {
        return {
            ...state,
            loading: {},
            errors: {},
            succeeded: {},
        };
    }
    return state;
}

export type ThunkActionState = ThunkActionsErrorState & ThunkActionsLoadingState & ThunkActionsSuccessState;

export function thunkActionsHandler<S extends ThunkActionState = ThunkActionState, P extends GenPayload = GenPayload>(
    handler: Reducer<S, P>,
    clearOnPersist: boolean = true,
    actionPrefix?: string,
): Reducer<S, P> {
    function asyncThunkHandler(state: S, action: Action<P>) {
        const afterPersistState = clearOnPersist ? persistHandler<S, P>(state, action) : state;
        // noinspection SuspiciousTypeOfGuard
        if (actionPrefix && typeof action.type === 'string' && !action.type.includes(actionPrefix)) {
            return handler(afterPersistState, action);
        }
        // Apply all standard handlers
        const thunkState = successHandler<S, P>(
            errorHandler<S, P>(loadingHandler<S, P>(afterPersistState, action), action),
            action,
        );
        return handler(thunkState, action);
    }
    return asyncThunkHandler;
}

export const THUNK_ACTION_INIT_STATE: ThunkActionState = {
    loading: {},
    errors: {},
    succeeded: {},
};
