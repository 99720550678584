import { EditReceivingSettingsModal } from './EditReceivingSettingsModal.graphql';
import type { ManageForwardingSettingsProps } from './ForwardingSettingsBase';
import { ForwardingSettingsBase, FormatNames } from './ForwardingSettingsBase';
import { ForwardingType } from './types';
import React from 'react';

export const ManageReceivingSettings: React.VFC<
    ManageForwardingSettingsProps & { setShowSuccess: (value: boolean) => void }
> = props => {
    const { sendersOrReceivers: senders, employeePrefs, notificationName, setShowSuccess, refetch } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <ForwardingSettingsBase
            forwardingInfoText={
                <>
                    <FormatNames sendersOrReceivers={senders} /> {senders.length === 1 ? 'is' : 'are'} sharing their
                    notifications with you
                </>
            }
            forwardingSettingsModal={
                <EditReceivingSettingsModal
                    open={open}
                    setOpen={setOpen}
                    employeePrefs={employeePrefs}
                    sendersOrReceivers={senders}
                    notificationName={notificationName}
                    setShowSuccess={setShowSuccess}
                    refetch={refetch}
                />
            }
            forwardingType={ForwardingType.receiving}
        />
    );
};
