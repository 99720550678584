export enum OnboardingPageIndex {
    BillingAndDelivery = 0,
    PersonalizedTraining = 1,
}

export interface OnboardingStep {
    title: string;
    subTitle: string;
    icon: React.ReactNode;
    component: React.ReactNode;
    previousAction?: () => void;
    saveAndContinueActionDisabled: boolean;
    saveAndContinueAction: () => void;
}
