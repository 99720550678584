import type { CreateSavedOrderSearchMutationType } from './DandySearchPresenter.types';
import { LabsGqlCustomSearchType, LabsGqlFilterComparator, LabsGqlSavedSearchVisibility } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';

export interface AddSaveCommandArgs {
    patientName: string;
    orderId: string;
    patientGender: string | null;
    patientBirthday: string;
}

export const useSaveSearchAction = (
    refetch: () => void | Promise<unknown>,
    createSavedOrderSearchMutation: CreateSavedOrderSearchMutationType,
) => {
    const mtnSubmitter = ({ patientName, orderId, patientGender, patientBirthday }: AddSaveCommandArgs) =>
        createSavedOrderSearchMutation({
            variables: {
                data: {
                    name: patientName,
                    visibility: LabsGqlSavedSearchVisibility.Private,
                    search: {
                        type: LabsGqlCustomSearchType.Order,
                        search: orderId,
                        order_criteria: [
                            {
                                filter_id: 'patient_gender',
                                comparator: LabsGqlFilterComparator.Equals,
                                comparison_value: patientGender,
                            },
                            {
                                filter_id: 'patient_birthday',
                                comparator: LabsGqlFilterComparator.Equals,
                                comparison_value: patientBirthday,
                            },
                        ],
                    },
                },
            },
        });
    const { submit: saveSearch } = useChangeSubmissionFn<void, [AddSaveCommandArgs]>(mtnSubmitter, {
        closeOnComplete: true,
        onSuccess: () => void refetch(),
    });
    return saveSearch;
};
