import { stylesFactory, FlossPalette } from '@orthly/ui-primitives';

export const useStyles = stylesFactory(() => ({
    dateContainer: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        gap: 32,
        zIndex: 1,
        // The last date in the timeline, and a date immediately preceding a divider
        '&:last-of-type, &:has(+ $divider)': {
            // The border of the last item container in this date
            '& $itemContainer:last-of-type::before': {
                height: 0,
            },
        },
    },
    header: {
        display: 'block',
        backgroundColor: FlossPalette.LIGHT_GRAY,
        padding: '4px 8px',
        borderRadius: 16,
        width: 'fit-content',
        marginLeft: 24,
        position: 'sticky',
        top: 0,
        zIndex: 2500,
    },
    divider: {
        position: 'relative',
        zIndex: 0,
        '& + $dateContainer::before': {
            top: '0px',
            height: '100%',
        },
    },
    dividerLine: {
        borderTop: `dashed 1px ${FlossPalette.LIGHT_GRAY}`,
        width: '200%',
        position: 'absolute',
        left: '-50%',
        top: '50%',
        zIndex: -1,
    },
    dividerLabel: {
        display: 'block',
        backgroundColor: FlossPalette.LIGHT_GRAY,
        color: 'white',
        padding: '4px 8px',
        width: 'fit-content',
        marginLeft: 'auto',
        marginRight: 'auto',
        borderRadius: 2,
    },
    itemContainer: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
            // Extend the border to account for the flex container's gap
            // The border should extend to the top of the next item container
            height: 'calc(100% + 32px)',
        },
        // The border of last item container in a date
        '&:last-of-type::before': {
            //  Extend the border to account for the height of the date header (24px + another 32px gap)
            height: 'calc(100% + 32px + 56px)',
        },
    },
}));
