import type { CheckoutState } from '../screens/checkout-v2/state/checkout.state';
import type { AlignerCheckoutState } from '../screens/checkout-v2/state/reducers/aligners-checkout.types';
import { useRehydratedProfile, useSelectStaffSelector } from '../screens/select-staff/state/select-staff.selectors';
import type { PartnerAppState } from './types';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import React from 'react';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';

export const usePracticeAppSelector: TypedUseSelectorHook<PartnerAppState> = useSelector;

export const useCheckoutSelector: TypedUseSelectorHook<CheckoutState> = <TSelected>(
    selector: (state: CheckoutState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
    return useSelector<PartnerAppState, TSelected>(s => selector(s.checkout), equalityFn);
};
export function useCheckoutPropSelector<K extends keyof CheckoutState>(props: K[]): Pick<CheckoutState, K> {
    return useCheckoutSelector(state => {
        return props.reduce((result, prop) => ({ ...result, [prop]: state[prop] }), {} as Pick<CheckoutState, K>);
    });
}
export const useAlignerCheckoutSelector: TypedUseSelectorHook<AlignerCheckoutState> = <TSelected>(
    selector: (state: AlignerCheckoutState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
    return useSelector<PartnerAppState, TSelected>(s => selector(s.alignerCheckout), equalityFn);
};
export function useAlignerCheckoutPropSelector<K extends keyof AlignerCheckoutState>(
    props: K[],
): Pick<AlignerCheckoutState, K> {
    return useAlignerCheckoutSelector(state => {
        return props.reduce(
            (result, prop) => ({ ...result, [prop]: state[prop] }),
            {} as Pick<AlignerCheckoutState, K>,
        );
    });
}

export function useStaffMemberMatcher() {
    const id = useSelectStaffSelector(({ profile, skipped }) => (skipped ? undefined : profile?.id));
    return React.useCallback((cmp: string) => cmp === id, [id]);
}
export const useCurrentProfile = () => {
    const profileMinimal = useSelectStaffSelector(({ profile }) => profile);
    return useRehydratedProfile(profileMinimal);
};
export const useCurrentProfileIsDoctor = () => {
    const currentProfile = useCurrentProfile();
    return currentProfile?.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor);
};
export const useCurrentSessionIsAdmin = () => {
    const session = useSession();
    return session?.roles?.includes(LabsGqlStaffRolePractice.Admin);
};
