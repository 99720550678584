import { getFlagVariantValueChecker } from '../../../../utils/utils';
import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import ImplantQ32022UpsellImage from '@orthly/dentin/assets/images/hd-skus/Implant_q3_2022_upsell.png';
import ImplantServicesImage from '@orthly/dentin/assets/images/new-skus/implant_bridge_128_2x.png';

export const ImplantUpsellConfigA: InboxUpsellItemConfigProps = {
    assetName: 'Implants Upsell Banner A',
    title: 'Place implants with precision.',
    subtitle: 'We help you control the end to end implant workflow.',
    ctaText: 'Book info session',
    destination: {
        url: 'https://www.meetdandy.com/implant-surgery/?utm_medium=practiceportal&utm_source=pract[…]n=implant-surgery&utm_content=implant-surgery-customer-xsell',
    },
    imgSrc: ImplantServicesImage,
    imgStyle: { maxHeight: 80 },
    skuType: 'implants-v2',
    filterCriteria: null,
    featureFlagKey: 'upsellImplantServicesMultivariant',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_a'),
};

export const ImplantUpsellConfigB: InboxUpsellItemConfigProps = {
    assetName: 'Implants Upsell Banner B',
    title: 'Still coping with impression copings? Experience the digital difference.',
    subtitle: 'Sign up for a LIVE Digital Implants training session and get 1-hour of CE credit.',
    ctaText: 'Register Now',
    destination: {
        url: 'https://www.meetdandy.com/implants-live-training?utm_medium=practiceportal&utm_source=banner&utm_campaign=implants-customer-training&utm_content=implants-customers-xs',
    },
    imgSrc: ImplantQ32022UpsellImage,
    imgStyle: { maxHeight: 100 },
    skuType: 'implants-v2',
    filterCriteria: null,
    featureFlagKey: 'upsellImplantServicesMultivariant',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_b'),
};
