import { useMountingMatrix } from '../../../../hooks/useMountingMatrix';
import type { VeneerUseOrderRevisionItemsDesign_Fragment } from '../../OrderDesignActions.graphql';
import { useAppearance } from '../hooks/useAppearance';
import { useResetView } from '../hooks/useResetView';
import { useStructuredNotesExperiment } from '../hooks/useStructuredNotesExperiment';
import { useUnfinishedPresets } from '../hooks/useUnfinishedPresets';
import { useGuidedWaxupAction } from './GuidedWaxupState';
import type { MainViewCameraControlsRef, MainViewModelRef } from '@orthly/dentin';
import { AGGREGATE_CONTROL_LABELS, useAggregateToggles, usePresetViewSetter } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import constate from 'constate';
import React from 'react';

export interface GuidedWaxupContextShape {
    order: LabsGqlSingleLabOrderFragment;
    patientName: string;
    refetch: () => Promise<unknown>;
    viewingPreviousDesign: boolean;
    setSelectedDesignRevisionId: (id?: string) => void;
    selectedTab: LabsGqlGuidedWaxupPresetType;
    selectedTabInitialized: boolean;
    setSelectedTab: (tab: LabsGqlGuidedWaxupPresetType) => void;
    onBack: () => void;
    userRole: string;
    selectedDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    selectedDesignRevisionAlreadyReviewed: boolean;
    previousDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    designRevisionFragments: FragmentType<typeof VeneerUseOrderRevisionItemsDesign_Fragment>[];
    selectedDesignRevisionIdx?: number;
    selectedDesignRevisionId?: string;
    isWarningDialogModalOpen: boolean;
    setIsWarningDialogModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isSkipToGeneralViewModalOpen: boolean;
    setIsSkipToGeneralViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isIncompletedWorkReminderModalOpen: boolean;
    setIsIncompletedWorkReminderModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileRejectionNoteOpen: boolean;
    setIsMobileRejectionNoteOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileTimelineDialogOpen: boolean;
    setIsMobileTimelineDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileDoctorImagesDialogOpen: boolean;
    setIsMobileDoctorImagesDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isAnnotatingScreenshot: boolean;
    setIsAnnotatingScreenshot: React.Dispatch<React.SetStateAction<boolean>>;
    teeth: number[];
    revisionId?: number;
    enableGuidedWaxupComparison?: boolean;
    isSidebarExpanded: boolean;
    setIsSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    OrderChatWrapper?: React.FC<{
        order: LabsGqlSingleLabOrderFragment;
        hideFooter?: boolean;
    }>;
    internalEvaluation?: boolean;
    isImmediateDenture?: boolean;
    isRemoveable?: boolean;
}

export const [GuidedWaxupContextProvider, useGuidedWaxupContext] = constate((context: GuidedWaxupContextShape) => {
    const enableStructuredDesignReviewNotes = useStructuredNotesExperiment(context.order);
    const unfinishedPresets = useUnfinishedPresets(enableStructuredDesignReviewNotes);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { appearance, appearanceFilters, setAppearance, setAppearanceFilters, toggleAppearanceFilters } =
        useAppearance();
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);
    const [zoom, setZoom] = React.useState<number>(isMobile ? 4 : 6);

    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, context.selectedDesignFragment);
    const mountingMatrix = useMountingMatrix(design);
    const presetViewControls = usePresetViewSetter(mountingMatrix, controlRef, appearance);

    // to avoid having to change existing logic within the useAggregateToggles hook being used here, just exclude Scans and Restorations
    // toggles
    const unfilteredToggles = useAggregateToggles(appearance, setAppearance, setAppearanceFilters);
    const toggles = unfilteredToggles.filter(
        toggle =>
            toggle.label !== AGGREGATE_CONTROL_LABELS.SCANS && toggle.label !== AGGREGATE_CONTROL_LABELS.RESTORATIONS,
    );

    const setPresetAnnotation = useGuidedWaxupAction('SET_PRESET_ANNOTATION');
    const setAnnotation = React.useCallback(
        (annotatedImageUrls: string[]) => {
            setPresetAnnotation({ presetName: context.selectedTab, annotatedImageUrls });
        },
        [context.selectedTab, setPresetAnnotation],
    );

    const { resetView } = useResetView({
        appearance,
        controlRef,
        presetViewControls,
        setAppearance,
        setAppearanceFilters,
        setZoom,
        selectedTab: context.selectedTab,
        isImmediateDenture: context.isImmediateDenture ?? false,
    });

    return {
        ...context,
        appearance,
        setAppearance,
        appearanceFilters,
        toggleAppearanceFilters,
        enableStructuredDesignReviewNotes,
        design,
        modelRef,
        controlRef,
        zoom,
        setZoom,
        presetViewControls,
        toggles,
        setAnnotation,
        resetView,
        unfinishedPresets,
    };
});
