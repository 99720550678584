import { useAbutmentPartsMap } from '../../context';
import { ExtraShadesSummary } from './ExtraShadesSummary';
import { GroupItemSection } from './GroupItemSection';
import type { ItemSectionProps } from './ItemsSection.types';
import { useScanbodyUsages } from './ScanbodyUsages.context';
import { ShadeOcclusalDetails } from './ShadeOcclusalDetails';
import { ItemsSectionDisplayClasses } from './util/displayClasses';
import { getImplantSectionTitle } from './util/getItemDisplay';
import type { DedupedOrderFormItem } from './util/mergeOrderItems';
import { dedupedItemToItemsArray } from './util/mergeOrderItems';
import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import type { IImplantItem, IImplantBridgeItem, IImplantToothGroup, IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, ImplantItemQuantitiesUtils, LabOrderItemSKUType } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const rowStyles: React.CSSProperties = {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: '5pt',
};

interface ItemDetailRowProps {
    title: string;
    value: string;
}

const ItemDetailRow: React.FC<ItemDetailRowProps> = ({ title, value, children }) => {
    const {
        ContainerComponent,
        fontComponents: { Text, MediumText },
        Action,
    } = useOrderSummaryComponents();

    return (
        <ContainerComponent className={ItemsSectionDisplayClasses.DETAIL_ROW} style={rowStyles}>
            <MediumText style={{ flex: 23 }}>{title}</MediumText>
            <Text style={{ flex: 45, fontSize: '12pt' }}>{value}</Text>
            {children ?? <Text style={{ flex: 30, textAlign: 'right' }} />}
            {Action ? <Grid style={{ flex: 20 }} /> : null}
        </ContainerComponent>
    );
};

const ImplantShadeSection: React.VFC<{ item: IImplantBridgeItem | IImplantItem }> = ({ item }) => {
    const { ContainerComponent, fontComponents, Action } = useOrderSummaryComponents();
    const { MediumText } = fontComponents;
    if (item.shades.length === 0) {
        return null;
    }
    return (
        <ContainerComponent
            className={ItemsSectionDisplayClasses.DETAIL_ROW}
            style={{ ...rowStyles, flexDirection: 'row' }}
        >
            <MediumText style={{ flex: 23 }}>Shades</MediumText>
            <ContainerComponent style={{ flex: 75, flexDirection: 'row', fontSize: '12pt' }}>
                <ExtraShadesSummary item={item} />
            </ContainerComponent>
            {Action ? <Grid style={{ flex: 20 }} /> : null}
        </ContainerComponent>
    );
};

interface ImplantRow {
    unn: number;
    manufacturer: string;
    system: string;
    connection: string;
    abutmentSku?: string | null;
    abutmentManufacturer?: string | null;
    screwSku?: string | null;
    analogSku?: string | null;
    crownMaterial?: string;
    healingCapMaterial?: string;
    abutmentMaterial: string;
    scanbodyId?: string | null;
}

// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
function useImplantRows(
    item: Extract<DedupedOrderFormItem, { sku: LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge }>,
    allItems: IOrderItemV2DTO[],
): React.ReactNode[] {
    const abutmentPartList = useAbutmentPartsMap();
    const scanbodyUsages = useScanbodyUsages();
    const implants: IImplantToothGroup[] = item.sku === LabOrderItemSKUType.Implant ? [item.unit] : item.implants;

    const rows = implants.map<ImplantRow>(implant => {
        const {
            connection_size,
            system: implantSystem,
            manufacturer: implantManufacturer,
            part_id: abutmentPartId,
            scanbody_id,
        } = implant.implant_metadata;

        const abutmentPart = abutmentPartId ? abutmentPartList.get(abutmentPartId) : null;
        const abutment = implant.abutment;
        const crown = implant.crown.unit_type === 'Crown' ? implant.crown : null;
        const healingCap = implant.crown.unit_type === 'HealingCap' ? implant.crown : null;

        return {
            unn: implant.unn,
            manufacturer: implantManufacturer,
            abutmentMaterial: abutment.material,
            system: implantSystem,
            connection: connection_size,
            crownMaterial: crown?.material,
            healingCapMaterial: healingCap?.material,
            abutmentSku: abutmentPart?.sku,
            abutmentManufacturer: abutmentPart?.manufacturer,
            screwSku: abutmentPart?.screw_sku,
            analogSku: abutmentPart?.analog_sku,
            scanbodyId: scanbody_id,
        };
    });
    const groupedByAllButUnn = _.groupBy(rows, r => JSON.stringify(_.omit(r, 'unn')));
    const components = Object.values(groupedByAllButUnn).flatMap<React.ReactElement>((rows, idx) => {
        const firstRow = rows[0];

        if (!firstRow) {
            // never happens
            return [];
        }

        // For implant bridges, there will be one row per abutment.
        // For implants, we will need to reference the `duplicateTeeth`.
        const numberAbutmentsInComponent =
            item.sku === LabOrderItemSKUType.Implant
                ? [...CartItemV2Utils.getUniqueUNNs(item), ...item.duplicateTeeth].length
                : rows.length;

        // Since we "merge" our items by the preference values matching, we can assume that the extra screw preference
        // is the same for all items in this group.
        // Thus, we can take the total number of abutments in the component and multiply by how many extra the master item requires per abutment.
        const extraScrewsPerAbutment = ImplantItemQuantitiesUtils.getExtraScrewQuantityPerAbutmentForItem(
            item,
            allItems,
        );
        const numberExtraScrews = numberAbutmentsInComponent * extraScrewsPerAbutment;

        const suffix = item.sku === 'ImplantBridge' ? ` (#${rows.map(r => r.unn).join(',')})` : '';
        const genTitleAndKey = (title: string) => ({ title: `${title}${suffix}`, key: `${title}${suffix}-${idx}` });
        const {
            analogSku,
            abutmentSku,
            abutmentMaterial,
            abutmentManufacturer,
            screwSku,
            crownMaterial,
            healingCapMaterial,
            system,
            connection,
            manufacturer,
        } = firstRow;
        const implantManufacturerRow = (
            <ItemDetailRow {...genTitleAndKey('Implant Manufacturer')} value={manufacturer} />
        );
        const implantSystemRow = <ItemDetailRow {...genTitleAndKey('System')} value={system} />;
        const connectionRow = <ItemDetailRow value={connection} {...genTitleAndKey('Connection')} />;

        const abutmentRow = <ItemDetailRow {...genTitleAndKey('Abutment')} value={abutmentMaterial} />;
        const abutmentSkuRow = abutmentSku ? (
            <ItemDetailRow
                {...genTitleAndKey('Abutment SKU')}
                value={`${abutmentSku}, quantity ${numberAbutmentsInComponent}`}
            />
        ) : null;
        const abutmentManufacturerRow = abutmentManufacturer ? (
            <ItemDetailRow {...genTitleAndKey('Abutment Manufacturer')} value={abutmentManufacturer} />
        ) : null;
        const screwSkuRow = (
            <ItemDetailRow
                {...genTitleAndKey('Screw SKU')}
                value={`${screwSku ?? 'TBD'}, ${numberExtraScrews} extra`}
            />
        );
        const analogSkuRow = analogSku ? (
            <ItemDetailRow
                {...genTitleAndKey('Analog SKU')}
                value={`${analogSku}, quantity ${numberAbutmentsInComponent}`}
            />
        ) : null;

        const crownRow = !crownMaterial ? null : (
            <ItemDetailRow {...genTitleAndKey('Crown')} value={crownMaterial}>
                <ShadeOcclusalDetails item={item} />
            </ItemDetailRow>
        );

        const healingCapRow = !healingCapMaterial ? null : (
            <ItemDetailRow {...genTitleAndKey('Healing Cap')} value={healingCapMaterial} />
        );

        const scanbody = scanbodyUsages.get(firstRow.scanbodyId ?? '');
        const fallbackScanbodyValue = firstRow.scanbodyId ? 'Unknown / Non-Dandy' : 'Not available for this item';
        const implantScanbodiesSection = (
            <ItemDetailRow
                {...genTitleAndKey('Scan bodies')}
                value={scanbody ? `${scanbody.name} (${scanbody.manufacturer})` : fallbackScanbodyValue}
            />
        );
        return _.compact([
            implantManufacturerRow,
            implantSystemRow,
            connectionRow,
            abutmentRow,
            abutmentSkuRow,
            abutmentManufacturerRow,
            screwSkuRow,
            analogSkuRow,
            crownRow,
            healingCapRow,
            implantScanbodiesSection,
        ]);
    });
    return [...components, <ImplantShadeSection key={'shade'} item={item} />];
}

interface ImplantSectionProps extends Omit<ItemSectionProps, 'item'> {
    item: Extract<DedupedOrderFormItem, { sku: LabOrderItemSKUType.Implant | LabOrderItemSKUType.ImplantBridge }>;
}

export const ImplantSection: React.VFC<ImplantSectionProps> = ({
    item,
    index,
    inlinePrefs,
    allItems,
}: ImplantSectionProps) => {
    const {
        ContainerComponent,
        fontComponents: { BoldText, Text },
        Action,
    } = useOrderSummaryComponents();
    const implantRows = useImplantRows(item, allItems);
    const { boldSection, regularSection } = getImplantSectionTitle(item);

    return (
        <GroupItemSection
            item={item}
            index={index}
            inlinePrefs={inlinePrefs}
            Title={
                <ContainerComponent
                    className={ItemsSectionDisplayClasses.ITEM_HEADER}
                    style={{ flexDirection: 'row', paddingTop: '10pt' }}
                >
                    <BoldText className={ItemsSectionDisplayClasses.ITEM_HEADER_TITLE} style={{ flex: 25 }}>
                        {boldSection}
                    </BoldText>
                    <Text style={{ flex: 30 }}>{regularSection}</Text>
                    <Text style={{ flex: 45 }} />
                    {Action ? (
                        <Grid style={{ flex: 20, textAlign: 'right' }}>
                            <Action items={dedupedItemToItemsArray(item)} />
                        </Grid>
                    ) : null}
                </ContainerComponent>
            }
            Rows={implantRows}
        />
    );
};
