import { useCurrentProfile } from '../../../redux';
import type {
    LabsGqlSetDoctorPreferencesMutationVariables,
    LabsGqlDoctorPreferencesFragment,
    LabsGqlRemoveStaffMemberMutationVariables,
    LabsGqlUpdatePracticeStaffMemberRolesMutationVariables,
} from '@orthly/graphql-operations';
import {
    useGetDoctorPreferencesByIdQuery,
    useSetDoctorPreferencesMutation,
    useDeleteDoctorPreferencesMutation,
    useRemoveStaffMemberMutation,
    useUpdatePracticeStaffMemberRolesMutation,
} from '@orthly/graphql-react';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { ItemMetafieldV2Utils } from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

type DeleteStaffMemberVars = LabsGqlRemoveStaffMemberMutationVariables['data'];
type PracticeStaffRolesVars = LabsGqlUpdatePracticeStaffMemberRolesMutationVariables['data'];
export type PracticeStaffPrefs = LabsGqlDoctorPreferencesFragment;
export type DoctorVars = LabsGqlSetDoctorPreferencesMutationVariables['data'];

export enum PracticeEmployeeType {
    Doctor = 'Doctor',
    PracticeStaff = 'PracticeStaff',
}

export interface DoctorPrefFormProps {
    employeePrefs: LabsGqlDoctorPreferencesFragment;
    submit: (data: DoctorVars) => void;
    loading: boolean;
    onChange?: () => void;
    submitting: boolean;
    contactPhoneSet?: boolean;
    contactEmailSet?: boolean;
    refetch?: () => Promise<any>;
}

type PracticeStaffPrefControls =
    | ({ type: PracticeEmployeeType.Doctor } & DoctorPrefFormProps)
    | ({ type: PracticeEmployeeType.PracticeStaff } & DoctorPrefFormProps);

export function useSetDoctorPreferences(onSuccess?: () => void) {
    const profile = useCurrentProfile();
    const { data, refetch, loading } = useGetDoctorPreferencesByIdQuery({
        skip: !profile?.id || profile?.__typename !== 'DoctorPreferences',
        variables: { doctor_id: profile?.id ?? '' },
    });

    const prefs = data?.preferences;

    const [mtn] = useSetDoctorPreferencesMutation();
    const mtnSubmitter = (data: DoctorVars) => mtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [DoctorVars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Preferences updated!', {}],
        onSuccess: async () => {
            await refetch();
            onSuccess?.();
        },
    });

    return {
        submit,
        submitting,
        prefs,
        loading,
        refetch,
    };
}

export function usePracticeStaffControls(): PracticeStaffPrefControls | undefined {
    const { submit, submitting, prefs, loading, refetch } = useSetDoctorPreferences();

    if (prefs) {
        return {
            submit,
            submitting,
            refetch,
            employeePrefs: prefs,
            type: prefs.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)
                ? PracticeEmployeeType.Doctor
                : PracticeEmployeeType.PracticeStaff,
            loading: submitting || loading,
        };
    }
    return undefined;
}

export function useDeleteEmployeePreferences(refetchPreferences?: () => Promise<any>) {
    const [deleteMtn] = useDeleteDoctorPreferencesMutation();
    const deleteSubmitter = (data: { preferences_id: string }) => deleteMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [{ preferences_id: string }]>(deleteSubmitter, {
        closeOnComplete: true,
        onSuccess: () => refetchPreferences?.(),
        disableDefaultErrorMessage: true,
    });
    return { submit, submitting };
}

export const useDeleteStaffMember = () => {
    const [submitMtn] = useRemoveStaffMemberMutation();
    const mtnSubmitter = (data: DeleteStaffMemberVars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [DeleteStaffMemberVars]>(mtnSubmitter, {
        closeOnComplete: true,
    });

    return { submit, submitting };
};

export function useUpdatePracticeStaff() {
    const [submitMtn] = useUpdatePracticeStaffMemberRolesMutation();
    const mtnSubmitter = (data: PracticeStaffRolesVars) => submitMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [PracticeStaffRolesVars]>(mtnSubmitter, {
        closeOnComplete: true,
    });
}

export function usePreferencesAreComplete(prefs: PracticeStaffPrefs | undefined) {
    const summaryItems = React.useMemo(
        () =>
            ItemMetafieldV2Utils.preferenceSummary(
                prefs?.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)
                    ? prefs?.custom_field_preferences || []
                    : [],
            ),
        [prefs],
    );

    if (!prefs?.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)) {
        // practice staff do not have custom field preferences
        return true;
    }
    return summaryItems.length > 0;
}

export function useContactInfoIsComplete(prefs: PracticeStaffPrefs | undefined) {
    return !!prefs?.contact_email && !!prefs?.contact_phone;
}

export function useNotificationsAreComplete(prefs: PracticeStaffPrefs | undefined) {
    const contactInfoIsComplete = useContactInfoIsComplete(prefs);
    if (!prefs?.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)) {
        // practice staff do not have order notifications
        return true;
    }
    // if any values other than the default __typename/status_change_email/review_required_email are set then we treat
    // them as completed; only filter out undefined/null values
    const nonNullNotificationPrefs = Object.values(
        _.omit(prefs?.grouped_notification_preferences, ['__typename', 'status_change_email', 'review_required_email']),
    ).filter(value => value !== undefined && value !== null);
    return contactInfoIsComplete && nonNullNotificationPrefs.length > 0;
}

export function useProfileSettingsAlerts() {
    const controls = usePracticeStaffControls();
    const preferencesAreComplete = usePreferencesAreComplete(
        controls?.employeePrefs.__typename === 'DoctorPreferences' ? controls?.employeePrefs : undefined,
    );
    const contactInfoIsComplete = useContactInfoIsComplete(controls?.employeePrefs);
    const notificationsAreComplete = useNotificationsAreComplete(
        controls?.employeePrefs.__typename === 'DoctorPreferences' ? controls?.employeePrefs : undefined,
    );
    const showPreferencesAlert =
        !controls?.loading && !preferencesAreComplete && controls?.type === PracticeEmployeeType.Doctor;
    const showSettingsAlert = !controls?.loading && !contactInfoIsComplete;
    const showNotificationsAlert =
        !controls?.loading && !notificationsAreComplete && controls?.type === PracticeEmployeeType.Doctor;
    return { showPreferencesAlert, showSettingsAlert, showNotificationsAlert };
}
