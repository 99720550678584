import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { usePartnerUiSelector } from '../../../redux/ui';
import { useCalculateContentHeight } from '../../../utils/LayoutUtils';
import { useIsChairside } from '../../../utils/chairside.hooks';
import { getFormattedOrderDetailTitle } from '../LabsUtils';
import { OrderActionParent, trackRenderedOrderDetailActions } from '../actions/OrderActionsUtils';
import {
    showOrderDetailsPhotosOrFilesAddedToastAtom,
    showOrderDetailsScansAddedToastAtom,
    showOrderDetailsScansErrorToastAtom,
} from '../components/atoms/OrderDetailToasts.atoms';
import { OrderDetailBody } from './OrderDetailBody';
import { OrderDetailMobileRoot } from './OrderDetailMobileRoot';
import { OrderDetailRightPanel } from './OrderDetailRightPanel';
import { OrderDetailSectionProvider } from './OrderDetailSectionProvider';
import { PendingReturnProvider } from './PendingReturnProvider';
import { OrderDetailTopBarActionsV2 } from './components/OrderDetailMobileTopBarActionsV2';
import { OrderDetailTopBarV2 } from './components/OrderDetailTopBarV2';
import type { LabsGqlSingleLabOrderFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useListChatMessagesQuery, useOrder } from '@orthly/graphql-react';
import { UuidUtils } from '@orthly/runtime-utils';
import { DOUBLE_TOOLBAR_TABLET_HEIGHT, LoadBlocker, useScreenIsMd } from '@orthly/ui';
import { stylesFactory, Grid, useScreenIsMobile } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import { useSetAtom } from 'jotai';
import React from 'react';
import { useParams } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    mainContainer: {
        overflow: 'auto',
        flexWrap: 'nowrap',
    },
}));

type InnerOrderDetailRootProps = {
    order?: LabsGqlSingleLabOrderFragment;
    refetch: (variables?: any) => Promise<any>;
    loading: boolean;
};

const InnerOrderDetailRoot: React.FC<InnerOrderDetailRootProps> = props => {
    const { order, refetch, loading } = props;
    const classes = useStyles();
    const isScreenMd = useScreenIsMd();
    const isScreenMobile = useScreenIsMobile();
    const chairside = useIsChairside();

    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');

    const { data: chatMessagesData, refetch: refetchChatMessages } = useListChatMessagesQuery({
        variables: { entity_ids: [order?.id ?? ''] },
        skip: !UuidUtils.isUUID(order?.id),
    });
    const chatMessages = React.useMemo(() => {
        return chatMessagesData?.listChatMessages ?? [];
    }, [chatMessagesData]);

    const contentHeight = useCalculateContentHeight({
        chairside,
        maintenanceBannerPractice: !!maintenanceBannerPractice?.length,
    });

    const rootRef = React.useRef<HTMLDivElement | null>(null);

    const formattedTitle = getFormattedOrderDetailTitle(order);
    const [title, setTitle] = React.useState('');

    React.useEffect(() => {
        setTitle('');
    }, []);

    React.useEffect(() => {
        const container = rootRef.current;
        const handleScroll = () => {
            if (container && container.scrollTop >= DOUBLE_TOOLBAR_TABLET_HEIGHT) {
                setTitle(formattedTitle);
            } else {
                setTitle('');
            }
        };
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [formattedTitle]);

    if (!order) {
        return <LoadBlocker blocking={loading} ContainerProps={{ style: { height: '100vh', width: '100%' } }} />;
    }

    if (isScreenMd) {
        return (
            <OrderDetailSectionProvider current={rootRef?.current}>
                <MobilePageLayout
                    containerRef={rootRef}
                    title={title}
                    titleHasBackButton
                    titleAction={<OrderDetailTopBarActionsV2 order={order} />}
                    showHeaderAsSecondaryHeader={!isScreenMobile}
                >
                    <OrderDetailMobileRoot
                        chatMessages={chatMessages}
                        order={order}
                        refetchMessages={refetchChatMessages}
                        refetch={refetch}
                    />
                </MobilePageLayout>
            </OrderDetailSectionProvider>
        );
    }

    return (
        <OrderDetailSectionProvider current={rootRef?.current}>
            <Grid container style={{ background: '#fff', height: '100%' }}>
                <Grid
                    container
                    item
                    xs={9}
                    direction={'column'}
                    className={classes.mainContainer}
                    style={{ maxHeight: contentHeight }}
                    ref={rootRef}
                >
                    <OrderDetailTopBarV2 order={order} />
                    <OrderDetailBody order={order} refetch={refetch} refetchMessages={refetchChatMessages} />
                </Grid>
                <Grid container item xs={12} md={3} direction={'column'}>
                    <OrderDetailRightPanel order={order} />
                </Grid>
            </Grid>
        </OrderDetailSectionProvider>
    );
};

const useHideToastsOnUnmount = (orderId: string) => {
    const setPhotosOrFilesAddedToastOpen = useSetAtom(showOrderDetailsPhotosOrFilesAddedToastAtom);
    const setScansAddedToastOpen = useSetAtom(showOrderDetailsScansAddedToastAtom);
    const setScansErrorToastOpen = useSetAtom(showOrderDetailsScansErrorToastAtom);

    React.useEffect(() => {
        return () => {
            setPhotosOrFilesAddedToastOpen(false);
            setScansAddedToastOpen(false);
            setScansErrorToastOpen(false);
        };
    }, [orderId, setPhotosOrFilesAddedToastOpen, setScansAddedToastOpen, setScansErrorToastOpen]);
};

const useTrackOrderLoaded = (order: LabsGqlLabOrderFragment | undefined) => {
    React.useEffect(() => {
        if (order) {
            trackRenderedOrderDetailActions(order.id, OrderActionParent.Global);
        }
    }, [order]);
};

export const OrderDetailRoot: React.FC = () => {
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const { orderId } = useParams<{ orderId: string }>();
    const fetchPolicy = isNativeApp ? 'cache-first' : 'no-cache';
    const { order, refetch, loading } = useOrder(orderId, {
        fetchPolicy,
    });

    useHideToastsOnUnmount(orderId);
    useTrackOrderLoaded(order);

    return (
        <PendingReturnProvider orderId={orderId}>
            <InnerOrderDetailRoot order={order} refetch={refetch} loading={loading} />
        </PendingReturnProvider>
    );
};
