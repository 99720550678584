import { useAlignerCheckoutPropSelector, useCheckoutPropSelector } from '../../../../redux';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { AlignerEstimateReview, CheckoutBodyWrapper } from '@orthly/dentin';
import React from 'react';

export const AlignerCheckoutEstimateReview: React.VFC = () => {
    const setPatientSteps = useAlignerCheckoutAction('SET_PATIENT_STEPS');
    const { estimate, overriddenPatientSteps } = useAlignerCheckoutPropSelector(['estimate', 'overriddenPatientSteps']);
    const { scan } = useCheckoutPropSelector(['scan']);
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.EstimateReview);

    if (!estimate || !scan) {
        return null;
    }

    return (
        <CheckoutBodyWrapper visible={isActiveStep && !!estimate} isComplete={isComplete}>
            <AlignerEstimateReview
                estimate={estimate}
                overriddenPatientSteps={overriddenPatientSteps}
                setPatientSteps={setPatientSteps}
            />
        </CheckoutBodyWrapper>
    );
};
