import {
    Grid,
    Icon,
    KeyboardArrowDownIcon,
    KeyboardArrowUpIcon,
    stylesFactory,
    FlossPalette,
    Text,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    optionWrapper: {
        borderRadius: 8,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        width: '100%',
        backgroundColor: FlossPalette.WHITE,
        '&:hover': {
            borderColor: FlossPalette.STROKE_DARK,
        },
    },
    optionWrapperSelected: {
        borderColor: FlossPalette.GREEN,
        '&:hover': {
            borderColor: FlossPalette.GREEN,
        },
    },
    optionWrapperError: {
        borderColor: FlossPalette.ATTENTION,
        '&:hover': {
            borderColor: FlossPalette.ATTENTION,
        },
    },
    optionHeader: {
        cursor: 'pointer',
        padding: 16,
        paddingLeft: 20,
    },
    optionBody: {
        padding: 16,
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
}));

interface TryInFeedbackOptionWrapperProps {
    title: string;
    subtitle: string;
    isSelected: boolean;
    onSelected: () => void;
    onUnselected: () => void;
    hasError: boolean;
}

export const TryInFeedbackOptionWrapper: React.FC<TryInFeedbackOptionWrapperProps> = ({
    children,
    title,
    subtitle,
    isSelected,
    onSelected,
    onUnselected,
    hasError,
}) => {
    const classes = useStyles();

    return (
        <Grid item xs>
            <Grid
                item
                container
                xs={12}
                direction={'column'}
                className={cx(classes.optionWrapper, {
                    [classes.optionWrapperSelected]: isSelected,
                    [classes.optionWrapperError]: hasError,
                })}
            >
                <Grid item>
                    <Grid
                        container
                        wrap={'nowrap'}
                        alignItems={'center'}
                        className={classes.optionHeader}
                        onClick={() => (isSelected ? onUnselected() : onSelected())}
                    >
                        <Grid item>
                            {isSelected ? (
                                <Icon icon={'CheckBox'} style={{ color: FlossPalette.GREEN }} />
                            ) : (
                                <Icon icon={'CheckBoxOutlineBlank'} style={{ color: FlossPalette.LIGHT_GRAY }} />
                            )}
                        </Grid>
                        <Grid item xs>
                            <Grid container direction={'column'} style={{ marginLeft: 16 }}>
                                <Grid item>
                                    <Text variant={'body2'} color={'BLACK'} medium>
                                        {title}
                                    </Text>
                                </Grid>
                                <Grid item>
                                    <Text variant={'body2'} color={'DARK_GRAY'}>
                                        {subtitle}
                                    </Text>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {isSelected ? (
                                <KeyboardArrowUpIcon style={{ color: FlossPalette.LIGHT_GRAY }} />
                            ) : (
                                <KeyboardArrowDownIcon style={{ color: FlossPalette.LIGHT_GRAY }} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {isSelected && (
                    <Grid item className={classes.optionBody}>
                        {children}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
