import { AnalyticsClient } from '../../../analytics/analyticsClient';
import shadeTabsSamplePhoto from '../../../assets/shade_tab_sample.png';
import { useCheckoutSelector } from '../../../redux';
import { LabsUtils } from '../../labs/LabsUtils';
import { ImageUploadTypeSelect } from '../components/PhotoUpload/ImageUploadTypeSelect';
import { useCheckoutAction } from '../state/checkout.actions';
import { getIsAestheticPhotoUploadVisible, getIsAestheticPhotoUploadComplete } from '../state/checkout.selectors';
import type { AestheticCheckoutState } from '../state/checkout.state';
import { CheckoutBodyWrapper, CheckoutBanner } from '@orthly/dentin';
import { Grid, HelpOutlineIcon, Blue, Green, Text } from '@orthly/ui-primitives';
import type { PhotoUploadConfig, UploadedPhotosState } from '@orthly/veneer';
import { PhotoUpload, useUploadedPhotoState } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const shadeTabsTooltip = (
    <>
        <Grid
            container
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            style={{ marginBottom: 8 }}
        >
            <Text variant={'h6'} bold color={'DARK_GRAY'}>
                Shade tab photo
            </Text>
            <HelpOutlineIcon color={'action'} />
        </Grid>
        <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginBottom: 16 }}>
            Three shade tabs at the same plane and orientation as the natural tooth (lighter, target, and darker shade)
        </Text>
        <img src={shadeTabsSamplePhoto} alt={`Shade tabs sample`} style={{ width: '100%' }} />
    </>
);

// IMPORTANT: `photos` must be a const or memoized to avoid spurious re-renders.

export const aestheticPhotoUploadConfig: PhotoUploadConfig = {
    firestoreProductName: 'aesthetics',
    photos: [
        {
            filename: 'PatientPhoto',
            userFacingTitle: 'Teeth With Shade Tabs',
            // prettier-ignore
            guidanceTitle: isDesktop =>
                        <>{isDesktop ? `Upload` : `Take`} a photo of the <Blue>teeth with shade tabs</Blue>{' '}</>,
            tooltip: shadeTabsTooltip,
        },
        {
            filename: 'FullFace',
            userFacingTitle: 'Natural Smile (Full Face)',
        },
        {
            filename: 'CloseUp',
            userFacingTitle: 'Natural Smile (Close-up)',
        },
        {
            filename: 'Side',
            userFacingTitle: 'Side',
        },
        {
            filename: 'RetractedTeethTogether',
            userFacingTitle: 'Retracted Teeth (Together)',
        },
        {
            filename: 'RetractedTeethSeparated',
            userFacingTitle: 'Retracted Teeth (Separated)',
        },
    ],
};

const aestheticPhotosBannerConfig = {
    head: (
        <>
            Cases with photos have the <Green>best shade outcomes</Green> and result in <Green>better smiles</Green>
        </>
    ),
    subhead: <>Photos allow us to best identify and match shades</>,
    imgSrc: '/click-photos.svg',
};

export function useAestheticUploadedPhotos(uploadConfig: PhotoUploadConfig, scanID?: string): UploadedPhotosState {
    const state = useUploadedPhotoState(uploadConfig, scanID);

    const setAestheticCheckoutPhotos = useCheckoutAction('SET_AESTHETIC_CHECKOUT_PHOTOS');
    React.useEffect(() => {
        setAestheticCheckoutPhotos(_.mapValues(state.uploadedPhotos, ref => ref.fullPath));
    }, [setAestheticCheckoutPhotos, state.uploadedPhotos]);

    return state;
}

const AestheticImagesBody: React.VFC = () => {
    const startUpload = useCheckoutAction('START_AESTHETIC_PHOTOS_UPLOAD');
    const checkInstructions = useCheckoutAction('AESTHETIC_PHOTOS_CHECK_INSTRUCTIONS');

    const aestheticCheckoutState: AestheticCheckoutState = useCheckoutSelector(s => s.aestheticCheckout);
    const { screen, allowInstructions } = aestheticCheckoutState;

    const scanID = useCheckoutSelector(s => s.scan?.id);

    const uploadState = useAestheticUploadedPhotos(aestheticPhotoUploadConfig, scanID);

    switch (screen) {
        case 'instructions':
            return (
                <ImageUploadTypeSelect
                    numUploadedPhotos={uploadState.numUploadedPhotos}
                    allowInstructions={allowInstructions}
                    onMobilePhotoUpload={() => {
                        startUpload();
                    }}
                    onClickDesktop={() => {
                        startUpload();
                    }}
                    head={{
                        element: (
                            <CheckoutBanner
                                config={aestheticPhotosBannerConfig}
                                style={{ marginTop: 24, marginBottom: 24 }}
                            />
                        ),
                    }}
                    mobileUrl={scanID => LabsUtils.getAestheticsPhotoSubmitSrcUrl(scanID) ?? ''}
                />
            );
        case 'upload':
            return (
                <PhotoUpload
                    uploadConfig={aestheticPhotoUploadConfig}
                    uploadState={uploadState}
                    viewInstructionsOptions={{
                        showButton: true,
                        onClick: checkInstructions,
                    }}
                    scanID={scanID}
                    isDesktop={true}
                    onUpload={() => {
                        AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                            productName: aestheticPhotoUploadConfig.firestoreProductName,
                            isMobile: false,
                        });
                    }}
                />
            );
    }
};

export const AestheticPhotoUploader: React.VFC = () => {
    const isVisible = useCheckoutSelector(getIsAestheticPhotoUploadVisible);
    const isComplete = useCheckoutSelector(getIsAestheticPhotoUploadComplete);

    return (
        <CheckoutBodyWrapper visible={isVisible} isComplete={isComplete}>
            <AestheticImagesBody />
        </CheckoutBodyWrapper>
    );
};
