import type { InlinePrefItem } from './useGroupPreferences';
import { toInlinePrefsItem } from './useGroupPreferences';
import type { IOrderItemV2DTO, ICustomFieldSubmission } from '@orthly/items';
import { LabOrderItemSKUType, OrderItemV2Utils, CartItemV2Utils } from '@orthly/items';
import _ from 'lodash';

export type DedupedOrderFormItem = IOrderItemV2DTO & {
    duplicateTeeth: number[];
    merged_items: IOrderItemV2DTO[];
    inlinePreferences: ICustomFieldSubmission[];
};

function dedupedToStandardItem(dedupedItem: DedupedOrderFormItem): IOrderItemV2DTO {
    const { duplicateTeeth, merged_items, ...rest } = dedupedItem;
    return rest;
}

export function dedupedItemToItemsArray(dedupedItem: DedupedOrderFormItem): IOrderItemV2DTO[] {
    return [dedupedToStandardItem(dedupedItem), ...(dedupedItem.merged_items ?? [])];
}

const deepEqualOrUndefined = (prop1: any, prop2: any) => _.isEqual(prop1, prop2) || (!prop1 && !prop2);

export interface MergeOrderItemOptions {
    /** Optional: This is what causes individual prefs to show inline if they differ from the most common selection */
    majorityPrefs?: { unitType: string; prefs: Record<string, string> }[];
    respectMetafieldInlineValues?: boolean;
    hiddenPrefMap?: { [itemId: string]: string[] };
}

// Two items are duplicates if they are the same link/unit/material types throughout, with same preferences, just for different tooth numbers
// In these cases we merge them together for display
const areMergeable = (item1: InlinePrefItem, item2: InlinePrefItem) => {
    const mergeableSkus = [
        LabOrderItemSKUType.Crown,
        LabOrderItemSKUType.Veneer,
        LabOrderItemSKUType.Inlay,
        LabOrderItemSKUType.Implant,
    ] as const;
    if (!OrderItemV2Utils.itemIsType(item1, mergeableSkus) || !OrderItemV2Utils.itemIsType(item2, mergeableSkus)) {
        return false;
    }
    if (item1.sku !== item2.sku) {
        return false;
    }
    const commonPropsEqual =
        deepEqualOrUndefined(item1.shades, item2.shades) &&
        deepEqualOrUndefined(item1.inlinePreferences, item2.inlinePreferences) &&
        deepEqualOrUndefined(item1.item_notes, item2.item_notes);
    if (!commonPropsEqual) {
        return false;
    }
    // we check OR here to deal with typescript union typing annoyance
    if (item1.sku === 'Implant' || item2.sku === 'Implant') {
        if (item2.sku !== 'Implant' || item1.sku !== 'Implant') {
            return false;
        }
        const implantsEqual = deepEqualOrUndefined(item1.unit.implant_metadata, item2.unit.implant_metadata);
        const crownMaterialMatch = deepEqualOrUndefined(item1.unit.crown.material, item2.unit.crown.material);
        const abutmentMaterialMatch = deepEqualOrUndefined(item1.unit.abutment.material, item2.unit.abutment.material);
        return implantsEqual && crownMaterialMatch && abutmentMaterialMatch;
    }
    return (
        deepEqualOrUndefined(item1.unit.material, item2.unit.material) &&
        deepEqualOrUndefined(item1.unit.unit_type, item2.unit.unit_type)
    );
};

/**
 * Merge items that are identical except for tooth numbers.
 */
export const mergeOrderItems = (items: IOrderItemV2DTO[], opts: MergeOrderItemOptions) => {
    return items.reduce<DedupedOrderFormItem[]>((deduped, rawItem) => {
        const currentItem = toInlinePrefsItem(rawItem, opts);
        const duplicateIndex = deduped.findIndex(item => areMergeable(item, currentItem));
        if (duplicateIndex < 0) {
            return [...deduped, { ...currentItem, duplicateTeeth: [], merged_items: [] }];
        }
        return deduped.map<DedupedOrderFormItem>((existingItem, existingItemIdx) => {
            // this existing item is not the merge-able item for the current item
            if (existingItemIdx !== duplicateIndex) {
                return existingItem;
            }
            return {
                ...existingItem,
                duplicateTeeth: [...existingItem.duplicateTeeth, ...CartItemV2Utils.getUniqueUNNs(currentItem)],
                merged_items: [...existingItem.merged_items, currentItem],
            };
        });
    }, []);
};
