import { getAlignerPlanFromGql } from '../utils';
import { AlignerPlanInfo } from './AlignerPlanInfo';
import type { LabsGqlPendingAlignerCaseEstimate } from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { ALIGNER_PLANS_DETAIL } from '@orthly/shared-types';
import {
    FlossPalette,
    FlossPaletteUtils,
    stylesFactory,
    Text,
    Grid,
    Collapse,
    Fade,
    TextField,
    Icon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    buttonContainer: { display: 'flex', flexGrow: 1, justifyContent: 'flex-end' },
    largeBox: {
        padding: '24px 0 12px 12px',
        [theme.breakpoints.down('md')]: {
            padding: '12px 0 24px 0',
        },
    },
    largeBoxContent: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        padding: '16px 24px',
        height: '100%',
    },
    priceAndPlanBoxContent: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        padding: '16px 24px',
    },
    smallBox: {
        padding: '24px 12px 12px 0',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            padding: '24px 0',
        },
    },
    smallBoxContent: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        padding: '16px 24px',
    },
    spreadRow: {
        justifyContent: 'space-between',
        flexWrap: 'nowrap',
        alignItems: 'baseline',
    },
    editingButton: {
        background: FlossPalette.TAN,
        borderColor: FlossPalette.TAN,
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 2,
        cursor: 'pointer',
        padding: '12px 16px',
        display: 'flex',
        marginTop: 8,
    },
    overrideStepsAlert: {
        backgroundColor: FlossPaletteUtils.toRgba('BURGUNDY', 0.1),
        borderRadius: 16,
        padding: 16,
    },
}));

interface EstimateDurationPreviewProps {
    estimate: LabsGqlPendingAlignerCaseEstimate;
    overriddenPatientSteps?: number | null;
}

const EstimatePlanPreview: React.VFC<EstimateDurationPreviewProps> = ({ estimate, overriddenPatientSteps }) => {
    const { durationInStepsMin, durationInStepsMax } = ALIGNER_PLANS_DETAIL[getAlignerPlanFromGql(estimate)];
    const classes = useStyles();

    /* eslint-disable no-nested-ternary */
    return (
        <Grid item className={classes.smallBox}>
            <div className={classes.priceAndPlanBoxContent}>
                <Grid container>
                    <Text variant={'body2'} medium color={'DARK_GRAY'}>
                        Duration
                    </Text>
                </Grid>
                <Text variant={'h4'} style={{ left: '1rem', marginBottom: 16 }}>
                    {overriddenPatientSteps
                        ? `≤ ${overriddenPatientSteps} steps`
                        : durationInStepsMin || durationInStepsMax
                          ? `${Format.rangeToReadable(durationInStepsMin, durationInStepsMax)} steps`
                          : '-'}
                </Text>
            </div>
        </Grid>
    );
};

const EstimateExtrasPreview: React.VFC<{ estimate: LabsGqlPendingAlignerCaseEstimate }> = ({ estimate }) => {
    const classes = useStyles();
    if (!estimate.needsIpr && !estimate.needsAttachments) {
        return null;
    }

    return (
        <Grid item className={classes.smallBox}>
            <div className={classes.smallBoxContent}>
                <Text variant={'body2'} medium color={'DARK_GRAY'}>
                    For optimal results, this treatment will likely require
                </Text>
                {estimate.needsIpr && (
                    <Text variant={'body2'} style={{ width: '100%' }}>
                        IPR
                    </Text>
                )}
                {estimate.needsAttachments && (
                    <Text variant={'body2'} style={{ width: '100%' }}>
                        Attachments
                    </Text>
                )}
            </div>
        </Grid>
    );
};

interface SetEditingEstimateButtonProps {
    label: string;
    onClick: () => void;
    isSelected: boolean;
}

const SetEditingEstimateButton: React.VFC<SetEditingEstimateButtonProps> = ({ isSelected, onClick, label }) => {
    const classes = useStyles();

    return (
        <Grid
            item
            className={classes.editingButton}
            style={{
                borderColor: isSelected ? FlossPalette.STAR_GRASS : undefined,
                background: isSelected ? FlossPalette.PRIMARY_BACKGROUND : undefined,
            }}
            onClick={onClick}
        >
            {isSelected ? (
                <Icon icon={'RadioButtonCheckedIcon'} style={{ color: FlossPalette.STAR_GRASS }} />
            ) : (
                <Icon icon={'RadioButtonUnCheckedIcon'} style={{ color: FlossPalette.GRAY }} />
            )}
            <Text variant={'body2'} medium style={{ marginLeft: 16 }} color={'DARK_GRAY'}>
                {label}
            </Text>
        </Grid>
    );
};

interface EstimateStepsEditInputProps {
    overriddenPatientSteps?: number | null;
    setPatientSteps: (val: number | null) => void;
}

const EstimateStepsEditInput: React.VFC<EstimateStepsEditInputProps> = ({
    overriddenPatientSteps,
    setPatientSteps,
}) => (
    <Grid container style={{ padding: '8px 0' }} alignItems={'center'} justifyContent={'space-between'}>
        <Text variant={'body2'}>To how many steps?</Text>
        <TextField
            variant={'standard'}
            value={overriddenPatientSteps ?? ''}
            type={'number'}
            InputProps={{
                style: { padding: '8px 0' },
                onFocus: event => event.target.select(),
            }}
            inputProps={{
                min: 0,
                step: 1,
                max: 100,
                style: { padding: '8px 16px !important', textAlign: 'center' },
            }}
            onChange={e => {
                const parsed = e.target.value ? parseInt(e.target.value) : null;
                setPatientSteps(typeof parsed === 'number' && !isNaN(parsed) ? parsed : null);
            }}
        />
    </Grid>
);

interface EstimateStepsEditSectionProps {
    overriddenPatientSteps?: number | null;
    setPatientSteps: (val: number | null) => void;
}

const EstimateStepsEditSection: React.VFC<EstimateStepsEditSectionProps> = ({
    overriddenPatientSteps,
    setPatientSteps,
}) => {
    const [editing, setEditing] = React.useState(!!overriddenPatientSteps);

    return (
        <Grid container direction={'column'}>
            <Text variant={'h5'}>Do you want to limit the number of steps?</Text>
            <Grid container style={{ marginTop: 8 }}>
                <Grid item xs={5} style={{ paddingRight: 12 }}>
                    <SetEditingEstimateButton
                        onClick={() => {
                            setEditing(false);
                            setPatientSteps(null);
                        }}
                        isSelected={!editing}
                        label={'No'}
                    />
                    <SetEditingEstimateButton onClick={() => setEditing(true)} isSelected={editing} label={'Yes'} />
                    <Collapse in={editing}>
                        <EstimateStepsEditInput
                            overriddenPatientSteps={overriddenPatientSteps}
                            setPatientSteps={setPatientSteps}
                        />
                    </Collapse>
                </Grid>
                <Grid item md={7} style={{ paddingLeft: 12 }}>
                    <Fade in={(overriddenPatientSteps ?? 0) > 0} style={{ marginTop: 8 }}>
                        <div
                            style={{
                                backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
                                borderRadius: 16,
                                padding: 24,
                            }}
                        >
                            <Text variant={'body2'} color={'ATTENTION_FOREGROUND'} medium>
                                Warning
                            </Text>
                            <Text variant={'body2'} color={'BLACK'} style={{ marginTop: 8 }}>
                                Limiting setup steps may impact ability to fully treat the case.
                            </Text>
                            <Text variant={'body2'} color={'BLACK'} style={{ marginTop: 8 }}>
                                Final pricing plan will be selected once you approve a treatment setup for production.
                                You are not invoiced for submitting a case for planning.
                            </Text>
                        </div>
                    </Fade>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface AlignerEstimateReviewProps {
    estimate: LabsGqlPendingAlignerCaseEstimate;
    overriddenPatientSteps?: number | null;
    setPatientSteps: (val: number | null) => void;
}

export const AlignerEstimateReview: React.VFC<AlignerEstimateReviewProps> = ({
    estimate,
    overriddenPatientSteps,
    setPatientSteps,
}) => {
    const classes = useStyles();
    return (
        <Grid container>
            <Grid item xs={12} md={5} style={{ flexDirection: 'column' }}>
                <EstimatePlanPreview estimate={estimate} overriddenPatientSteps={overriddenPatientSteps} />
                <EstimateExtrasPreview estimate={estimate} />
            </Grid>
            <Grid item xs={12} md={7} className={classes.largeBox}>
                <div className={classes.largeBoxContent} style={{ padding: '0 24px' }}>
                    <AlignerPlanInfo plan={getAlignerPlanFromGql(estimate)} />
                </div>
            </Grid>
            <EstimateStepsEditSection
                overriddenPatientSteps={overriddenPatientSteps}
                setPatientSteps={setPatientSteps}
            />
        </Grid>
    );
};
