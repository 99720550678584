import { useAlignerCheckoutPropSelector, useAlignerCheckoutSelector, useCheckoutSelector } from '../../../redux';
import { useAlignerCheckoutAction } from '../state/aligners-checkout.actions';
import { checkoutImageUploadReviewComplete } from '../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../state/aligners-checkout.types';
import { useScanWithExistingOrderSelector } from '../state/checkout.selectors';
import { AlignerCheckoutArchSelection } from './AlignerCheckout/AlignerCheckoutArchSelection/AlignerCheckoutArchSelection';
import { AlignerCheckoutBiteConcernsScreen } from './AlignerCheckout/AlignerCheckoutBiteConcernsScreen';
import { AlignerCheckoutCrowdingResolution } from './AlignerCheckout/AlignerCheckoutCrowdingResolution';
import { AlignerCheckoutEstimateReview } from './AlignerCheckout/AlignerCheckoutEstimateReview';
import { AlignerCheckoutEstimatingScreen } from './AlignerCheckout/AlignerCheckoutEstimatingScreen';
import { AlignerCheckoutPatientDoctorStep } from './AlignerCheckout/AlignerCheckoutPatientDoctorStep';
import { AlignerCheckoutRejection } from './AlignerCheckout/AlignerCheckoutRejectionScreen';
import { AlignerCheckoutRetainerAdditionalInfo } from './AlignerCheckout/AlignerCheckoutRetainer/AlignerCheckoutRetainerAdditionalInfo';
import { AlignerCheckoutRetainerArchSelection } from './AlignerCheckout/AlignerCheckoutRetainer/AlignerCheckoutRetainerArchSelection';
import { AlignerCheckoutSpacing } from './AlignerCheckout/AlignerCheckoutSpacing';
import {
    AlignerCheckoutMovementRestrictions,
    AlignerCheckoutAttachmentRestrictions,
    AlignerCheckoutExtractions,
} from './AlignerCheckout/AlignerCheckoutTeethSelections';
import { AlignerCheckoutUploadReviewScreen } from './AlignerCheckout/AlignerCheckoutUploadReviewScreen';
import { AlignerCheckoutUploadTypeSelectionScreen } from './AlignerCheckout/AlignerCheckoutUploadTypeSelectionScreen';
import { CheckoutMainScreensContainer } from './CheckoutMainScreensContainer/CheckoutMainScreensContainer';
import { useQuery } from '@apollo/client';
import type {
    GetAlignerRetainerSummaryForOrderQuery as RetainerSummaryQuery,
    GetAlignerRetainerSummaryForOrderQueryVariables as RetainerSummaryVariables,
} from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import { useScreenIsMobileOrVerticalTablet, Button } from '@orthly/ui-primitives';
import { VeneerAlignerRetainersSummary_Query, VeneerAlignerRetainersSummary_Fragment } from '@orthly/veneer';
import React from 'react';

interface AlignerCheckoutMainScreensProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

function useAlignerStepTitle(): string {
    const patientName = useCheckoutSelector(s => s.patient_first_name);
    const scanWithExisting = useScanWithExistingOrderSelector();
    const { step, alignerImages, deviceUpload } = useAlignerCheckoutPropSelector([
        'step',
        'alignerImages',
        'deviceUpload',
    ]);
    switch (step) {
        case AlignerCheckoutStep.PatientInformation: {
            const patientLabel = patientName ?? 'this patient';
            return scanWithExisting
                ? `We noticed ${patientLabel} is in treatment for clear aligners`
                : `Let's get your order started`;
        }
        case AlignerCheckoutStep.ImageUploadLink:
            return `First, we need a few photos of the patient's mouth`;
        case AlignerCheckoutStep.ImageUploadReview:
            return checkoutImageUploadReviewComplete(alignerImages).complete
                ? `We're ready to continue!`
                : `Uploading photos from your ${deviceUpload ? 'desktop' : 'phone'}...`;
        case AlignerCheckoutStep.ArchSelection:
            return 'Which arch(es) do you want to treat?';
        case AlignerCheckoutStep.MovementRestrictedTeethSelection:
            return 'Do you want to restrict the movement of any teeth?';
        case AlignerCheckoutStep.AttachmentRestrictedTeethSelection:
            return 'Do you want to restrict the placement of attachments?';
        case AlignerCheckoutStep.ExtractionTeethSelection:
            return 'Are you doing any extractions?';
        case AlignerCheckoutStep.SpacingSelection:
            return 'How do you want to treat spacing?';
        case AlignerCheckoutStep.CrowdingResolution:
            return 'How do you want to resolve crowding?';
        case AlignerCheckoutStep.BiteConcerns:
            return 'How do you want to treat the bite?';
        case AlignerCheckoutStep.Estimating:
            return `Estimating`;
        case AlignerCheckoutStep.EstimateReview: {
            const patientLabel = patientName ? `${patientName}'s` : 'this';
            return `Based on what you told us, here's our estimate for ${patientLabel} treatment`;
        }
        case AlignerCheckoutStep.Summary:
            return ``;
        case AlignerCheckoutStep.Rejection:
            return `Sorry, we can't accept this case`;
        case AlignerCheckoutStep.RetainerArchSelection:
            return `Which arch(es) does the patient need retainers for?`;
        case AlignerCheckoutStep.RetainerAdditionalInfo:
            return `Just a couple more questions...`;
    }
}

const AlignerTitleAction: React.VFC = () => {
    const alignerStep = useAlignerCheckoutSelector(s => s.step);
    const onBack = useAlignerCheckoutAction('PREV_ALIGNER_STEP');
    const isMobile = useScreenIsMobileOrVerticalTablet();
    if (isMobile) {
        return null;
    }
    if (alignerStep === AlignerCheckoutStep.ImageUploadReview) {
        return (
            <Button variant={'secondary'} endIcon={'CameraIcon'} style={{ marginBottom: 16 }} onClick={() => onBack()}>
                Re-scan code
            </Button>
        );
    }

    return null;
};

function useAlignerRetainerPriceSummary() {
    const isRetainer = useAlignerCheckoutSelector(s => !!s.retainer);
    const orderId = useAlignerCheckoutSelector(s => s.existingOrder?.id);
    const setPricingSummary = useAlignerCheckoutAction('SET_RETAINER_PRICING');
    useQuery<RetainerSummaryQuery, RetainerSummaryVariables>(VeneerAlignerRetainersSummary_Query, {
        variables: { order_id: orderId ?? '' },
        skip: !isRetainer || !orderId,
        onCompleted: data => {
            const summary = getFragmentData(
                VeneerAlignerRetainersSummary_Fragment,
                data?.getAlignerRetainerSummaryForOrder,
            );

            setPricingSummary({
                freeSetsRemaining: summary?.freeSetsRemaining ?? 0,
                totalSetsOrdered: summary?.totalSetsOrdered ?? 0,
            });
        },
    });
}

export const AlignerCheckoutMainScreens: React.VFC<AlignerCheckoutMainScreensProps> = ({ scansQuery }) => {
    const title = useAlignerStepTitle();
    useAlignerRetainerPriceSummary();
    return (
        <CheckoutMainScreensContainer
            titleProps={{
                title,
                titleAction: <AlignerTitleAction />,
            }}
            screens={
                <>
                    <AlignerCheckoutPatientDoctorStep scansQuery={scansQuery} />
                    <AlignerCheckoutUploadTypeSelectionScreen />
                    <AlignerCheckoutUploadReviewScreen />
                    <AlignerCheckoutArchSelection />
                    <AlignerCheckoutMovementRestrictions />
                    <AlignerCheckoutAttachmentRestrictions />
                    <AlignerCheckoutExtractions />
                    <AlignerCheckoutSpacing />
                    <AlignerCheckoutCrowdingResolution />
                    <AlignerCheckoutBiteConcernsScreen />
                    <AlignerCheckoutEstimatingScreen />
                    <AlignerCheckoutEstimateReview />
                    <AlignerCheckoutRejection />
                    <AlignerCheckoutRetainerArchSelection />
                    <AlignerCheckoutRetainerAdditionalInfo />
                </>
            }
        />
    );
};
