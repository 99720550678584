import { LoggedOutLayout } from './screens/logged-out/components/LoggedOutLayout';
import { useApolloClient } from '@apollo/client';
import { clearApolloCache, useGetOwnImpersonationTokenQuery } from '@orthly/graphql-react';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import { stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import { PracticeOutageBanner, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    headerWrapper: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
    },
}));

export const PerhapsYouMeantToBeSomewhereElse: React.VFC = () => {
    const classes = useStyles();
    const { data: impersonateTokenData } = useGetOwnImpersonationTokenQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const client = useApolloClient();
    const onLogoutClick = React.useCallback(() => {
        clearApolloCache(client).catch(console.error);
        RetainerTokenStorage.removeToken();
    }, [client]);
    const newLabPortalUrl = impersonateTokenData?.getOwnImpersonationToken.labPortalUrl?.split('?')[0];
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');

    return (
        <Grid container>
            <Grid container className={classes.headerWrapper}>
                <PracticeOutageBanner banner={maintenanceBannerPractice?.[0]} />
                <LoggedOutLayout
                    leftTitle={
                        <>
                            Meet Dandy.
                            <br />A smarter way to grow your practice.
                        </>
                    }
                    leftContent={
                        <Text variant={'body1'} style={{ fontSize: '1.1rem', color: 'inherit' }}>
                            Digital dentistry designed to work for you.
                        </Text>
                    }
                    rightColumnContent={
                        <div>
                            <h2>The lab portal has moved!</h2>
                            <p>
                                The lab portal is under construction and is moving to a new location. Please visit the
                                link below and login there using the same credentials you used here.
                            </p>
                            <p>
                                <a href={newLabPortalUrl}>{newLabPortalUrl}</a>
                            </p>
                            <p>
                                If you believe this page is in error, please contact support. Additionally if you
                                experience any issues with the new portal, please don't hesisate to reach out to us as
                                well!
                            </p>
                            <p>Alternatively, perhaps these links will help you find what you're looking for:</p>
                            <ul>
                                <li>
                                    <a href={'javascript::void()'} onClick={onLogoutClick}>
                                        Oops! I logged in as the wrong user and would like to try again
                                    </a>
                                </li>
                            </ul>
                        </div>
                    }
                />
            </Grid>
        </Grid>
    );
};
