import type { SimpleMultiSelectProps, SimpleSelectProps } from '@orthly/ui';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useOverviewToolbarSelectClasses = stylesFactory(() => ({
    selectIcon: { right: 4 },
    topBarSelect: {
        backgroundColor: 'transparent !important',
        // makes sure placeholder is not italic, which is simple select default
        '& em': { fontStyle: 'normal' },
        '& *': { backgroundColor: 'transparent !important' },
    },
    selectFormControl: { '& > div': { backgroundColor: FlossPalette.WHITE } },
    inputLabel: { backgroundColor: 'transparent !important' },
    inputLabelShrink: {},
}));

type ToolbarSelectCommonProps = Omit<
    SimpleMultiSelectProps | SimpleSelectProps,
    'options' | 'onChange' | 'label' | 'placeholder' | 'value'
>;

export function useOrdersToolbarSelectProps(): ToolbarSelectCommonProps {
    const classes = useOverviewToolbarSelectClasses();
    return React.useMemo<ToolbarSelectCommonProps>(() => {
        return {
            variant: 'standard',
            SelectProps: {
                disableUnderline: true,
                displayEmpty: true,
                classes: { select: classes.topBarSelect, icon: classes.selectIcon },
                variant: 'standard',
            },
            FormControlProps: { classes: { root: classes.selectFormControl }, variant: 'standard' },
            InputLabelProps: { shrink: true, classes: { root: classes.inputLabel, shrink: classes.inputLabelShrink } },
        };
    }, [classes]);
}
