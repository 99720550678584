import { OrderAction, OrderActionTrackerIdMap } from '../../OrderActionsUtils';
import type { PauseOrResumeOrderActionProps } from './PauseOrResumeOrderAction.types';
import { useRemoveOrderHoldOrCancelLiveDdpReview } from './hooks/useRemoveOrderHold.graphql';
import { useOrderRefetch } from '@orthly/graphql-react';
import { PauseOrderActionReason } from '@orthly/shared-types';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

type ResumeOrderActionProps = Omit<PauseOrResumeOrderActionProps, 'preselectedOption' | 'refetch'>;

export const ResumeOrderAction: React.VFC<ResumeOrderActionProps> = ({ order, buttonVariant, leftAlign }) => {
    const holdIsDDPHold = order.hold_details?.hold_subcategory === PauseOrderActionReason.ReviewDesign;

    const refetch = useOrderRefetch();

    const { onSubmit, isLiveDdp } = useRemoveOrderHoldOrCancelLiveDdpReview(() => refetch(order.id), {
        orderId: order.id,
        holdId: order.hold_details?.hold_id,
    });

    const shouldAllowUnpausing =
        order.hold_details?.hold_subcategory !== PauseOrderActionReason.ScheduledLiveDdpReview || isLiveDdp;

    if (!shouldAllowUnpausing) {
        return null;
    }

    return (
        <Button
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.ResumeOrder]}
            data-test={'resume-order-button'}
            leftAlign={leftAlign}
            variant={buttonVariant}
            startIcon={'PlayArrowIcon'}
            onClick={onSubmit}
        >
            {isLiveDdp ? `Cancel appointment & resume order` : `Resume order ${holdIsDDPHold ? ' to view design' : ''}`}
        </Button>
    );
};
