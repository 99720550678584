import type { RawPracticeInvoice } from '../invoicing.types';
import { convertToPracticeInvoices } from '../invoicing.utils';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

const ListInvoices_Query = graphql(`
    query ListInvoices {
        listInvoicesWithItemSummariesForOrganization {
            amount_due
            amount_paid
            amount_remaining
            created_at
            due_date
            id
            invoice_number
            items_order_count
            items_total_amount_cents
            is_overdue
            pending_payment_amount_cents
            period_end
            period_start
            status
            total
            organization_id
        }
    }
`);

export const useInvoicesQuery = () => {
    const {
        data: { listInvoicesWithItemSummariesForOrganization: updatedInvoicesRaw = [] } = {},
        loading: invoicesLoading,
        refetch: refetchInvoices,
    } = useQuery(ListInvoices_Query);

    const invoices = convertToPracticeInvoices<RawPracticeInvoice>(updatedInvoicesRaw);

    return { invoices: invoices ?? [], loading: invoicesLoading, refetch: refetchInvoices };
};
