import { stylesFactory, FlossPalette } from '@orthly/ui-primitives';

export const useMobileCheckoutStyles = stylesFactory(() => ({
    buttonWrapper: { padding: 16, flexGrow: 1, alignItems: 'flex-start' },
    logo: {
        color: FlossPalette.DARK_TAN,
        width: 128,
    },
    root: {
        height: '100vh',
        backgroundColor: FlossPalette.WHITE,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1202,
        position: 'fixed',
        width: '100%',
    },
    carousel: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingBottom: 24,
    },
    imagePreview: { maxWidth: '150%', minWidth: '100%' },
    imageTag: {
        backgroundColor: FlossPalette.BURGUNDY,
        position: 'absolute',
        top: 16,
        left: 0,
        padding: '0 16px',
        borderRadius: 4,
    },
    overviewRoot: {
        padding: '32px 4px 0',
        display: 'flex',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        overflow: 'scroll',
    },
    grid: {
        marginBottom: 8,
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: 4,
        flexWrap: 'nowrap',
    },
    carouselItem: {
        backgroundColor: FlossPalette.TAN,
        padding: 12,
        borderRadius: 8,
    },
    carouselItemWrapper: {
        padding: '0 24px',
        marginTop: 64,
    },
    titleWrapper: { width: '100%', paddingLeft: 16, paddingRight: 16, marginBottom: 16 },
    gridItemRoot: {
        backgroundColor: FlossPalette.TAN,
        height: 64,
        padding: 12,
        borderRadius: 8,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
    },
    placeholderIcon: {
        height: 48,
        width: 48,
        color: FlossPalette.GRAY,
    },
    icon: {
        borderRadius: 36,
        padding: 4,
        height: 32,
        width: 32,
    },
    deleteIconWrapper: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        padding: 12,
    },
    trashIconButton: {
        padding: 0,
    },
}));
