import type { PersonInfo } from './FeedbackPersonCard';
import type { FeedbackAndRefabItem } from '@orthly/dentin';
import { CartItemV2Utils } from '@orthly/items';
import ChadVMPhoto from '@orthly/ui/assets/people/chad_v_m.jpg';
import FrancoGPhoto from '@orthly/ui/assets/people/franco_g.jpg';
import GreggFPhoto from '@orthly/ui/assets/people/gregg_f.jpg';
import PeterCPhoto from '@orthly/ui/assets/people/peter_c.jpg';
import RobertCPhoto from '@orthly/ui/assets/people/robert_c.jpg';
import RonnyYPhoto from '@orthly/ui/assets/people/ronny_y.jpg';
import _ from 'lodash';

function recipientForItem(item: FeedbackAndRefabItem): PersonInfo | undefined {
    const productUnitType = CartItemV2Utils.getProductUnitType(item.labOrderItem);
    switch (productUnitType) {
        case 'Aligners':
            return {
                name: 'Peter C.', // Peter Chen
                title: 'Aligner Design Lead',
                photoSrc: PeterCPhoto,
            };
        case 'Bridge':
        case 'Crown':
        case 'Veneer':
        case 'Inlay':
        case 'Onlay':
            return {
                name: 'Ronny Y.', // Ronny Yeagy
                title: 'Crown & Bridge Design Lead',
                photoSrc: RonnyYPhoto,
            };
        case 'Denture':
            return {
                name: 'Chad V. M.', // Chad Van Maele
                title: 'Denture Design Lead',
                photoSrc: ChadVMPhoto,
            };
        case 'Implant':
        case 'Implant Model':
        case 'ImplantBridge':
            return {
                name: 'Gregg F.', // Gregg Fabrizio
                title: 'Implant Design Lead',
                photoSrc: GreggFPhoto,
            };
        case 'Bleaching Tray':
        case 'Night Guard':
        case 'Retainer':
            return {
                name: 'Franco G.', // Franco Garcia
                title: 'Removables Design Lead',
                photoSrc: FrancoGPhoto,
            };
        case 'Partial':
            return {
                name: 'Franco G.', // Franco Garcia
                title: 'Partials Design Lead',
                photoSrc: FrancoGPhoto,
            };
        case 'SurgicalGuide':
            return {
                name: 'Robert C.', // Robert Cairo
                title: 'Surgical Guide Design Lead',
                photoSrc: RobertCPhoto,
            };
        case 'Unknown':
        case 'Waxup':
        case 'Model':
            return undefined;
    }
}

export function feedbackRecipient(
    items: FeedbackAndRefabItem[],
    currentItem?: FeedbackAndRefabItem,
): PersonInfo | undefined {
    // On the screen for a specific item, use that item.
    if (currentItem) {
        return recipientForItem(currentItem);
    }

    // On other screens, only show the recipient if all feedback is going to the same person.
    const allRecipients = _.compact(items.map(recipientForItem));
    const firstRecipient = allRecipients[0];
    if (firstRecipient && allRecipients.find(person => person.name !== firstRecipient.name)) {
        return undefined;
    }
    return firstRecipient;
}
