import { SmallTrashCanIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, IconButton } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    actionIcon: {
        borderRadius: 36,
        padding: 4,
        height: 32,
        width: 32,
    },
    trashIconButton: {
        padding: 0,
    },
    deleteIconWrapper: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        justifyContent: 'center',
        alignItems: 'flex-end',
        padding: 12,
    },
}));

export const ImageUploadPreview: React.FC<{ onReset: () => void; file: File; style?: React.CSSProperties }> = ({
    onReset,
    file,
    style,
}) => {
    const classes = useStyles();
    const imageSrc = React.useMemo(() => URL.createObjectURL(file), [file]);

    return (
        <Grid
            container
            alignItems={'center'}
            justifyContent={'center'}
            style={{
                ...style,
                backgroundSize: 'cover',
                backgroundPosition: 'center center',
                backgroundImage: `url(${imageSrc})`,
            }}
        >
            <Grid container className={classes.deleteIconWrapper}>
                <IconButton onClick={onReset} className={classes.trashIconButton}>
                    <SmallTrashCanIcon
                        preserveAspectRatio={'xMidYMin'}
                        color={'primary'}
                        className={classes.actionIcon}
                        style={{ color: FlossPalette.STAR_GRASS, background: FlossPalette.WHITE }}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};
