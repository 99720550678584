import { useCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { getActiveItemIndex } from '../../state/checkout.screens';
import { useScanIsAligner } from '../../state/checkout.selectors';
import type { CheckoutItemV2 } from '../../state/checkout.state';
import { useScanIsDenture, useDentureCheckoutSelector } from '../../state/dentures-checkout.selectors';
import type { DandyMouthArchItemsProps, BridgeGroupProps, SingleMouthItem } from '@orthly/dentin';
import { DandyMouthDisplay, DandyDentureMouthDisplay } from '@orthly/dentin';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemArch } from '@orthly/items';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

// TODO: at some point we will want to figure out how to not hard-code this logic
// we can now use items v2 but we need to update removable select to set it properly
const isLowerOrUpperNightGuard = (targetUpper: boolean) => (i: CheckoutItemV2) => {
    return !!i.preference_fields.find(
        p => typeof p.value === 'string' && p.value.toLowerCase().includes(targetUpper ? 'upper' : 'lower'),
    );
};

function useSingleMouthItems(
    items: CheckoutItemV2[],
    currentBulkEditId?: string,
    forceHighlightItemIndexes: number[] = [],
): SingleMouthItem[] {
    const { step: activeStep } = useCheckoutPropSelector(['step']);
    const activeItemIndex = useCheckoutSelector(getActiveItemIndex);

    return React.useMemo(() => {
        return items.flatMap(item => {
            // bridges get their own props in useBridgesProp
            if (CartItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Bridge, LabOrderItemSKUType.ImplantBridge])) {
                return [];
            }

            const isActiveItem =
                activeStep === 1 && (activeItemIndex === item.item_index || currentBulkEditId === item.bulk_edit_id);
            const teeth = CartItemV2Utils.getUniqueUNNs(item);

            return teeth.map(tooth => ({
                number: tooth,
                highlighted: isActiveItem || forceHighlightItemIndexes.includes(item.item_index),
                isImplant: CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant),
            }));
        });
    }, [activeItemIndex, activeStep, items, currentBulkEditId, forceHighlightItemIndexes]);
}

function useBridgesProp(items: CheckoutItemV2[], forceHighlightItemIndexes: number[] = []): BridgeGroupProps[] {
    const { step: activeStep } = useCheckoutPropSelector(['step']);
    const activeItemIndex = useCheckoutSelector(getActiveItemIndex);

    return React.useMemo(() => {
        return items.flatMap(item => {
            // we only want bridge items here
            if (!CartItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Bridge, LabOrderItemSKUType.ImplantBridge])) {
                return [];
            }

            const isActiveItem = activeStep === 1 && activeItemIndex === item.item_index;
            const singleUnits = CartItemV2Utils.getSingleToothUnits(item);

            return {
                highlighted: isActiveItem || forceHighlightItemIndexes.includes(item.item_index),
                // filter out non-bridge units (such as abutments for ib)
                items: singleUnits
                    .filter(unit => ['Crown', 'CrownPontic'].includes(unit.unit_type))
                    .map(unit => ({
                        number: unit.unn,
                        pontic: unit.unit_type === 'CrownPontic',
                    })),
            };
        });
    }, [activeItemIndex, activeStep, items, forceHighlightItemIndexes]);
}

function useFullMouthProp(
    items: CheckoutItemV2[],
    forceHighlightItemIndexes: number[] = [],
): DandyMouthArchItemsProps | undefined {
    const { step: activeStep } = useCheckoutPropSelector(['step']);
    const activeItemIndex = useCheckoutSelector(getActiveItemIndex);

    const { upperItems, lowerItems } = React.useMemo(() => {
        const fullMouthItems = items.filter(item => CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Removeable));

        return {
            lowerItems: fullMouthItems.filter(isLowerOrUpperNightGuard(false)),
            upperItems: fullMouthItems.filter(isLowerOrUpperNightGuard(true)),
        };
    }, [items]);

    return React.useMemo(() => {
        if (upperItems.length === 0 && lowerItems.length === 0) {
            return undefined;
        }

        const upperActive = activeStep === 1 && !!upperItems.find(i => i.item_index === activeItemIndex);
        const upperInHighlights = !!upperItems.find(i => forceHighlightItemIndexes.includes(i.item_index));
        const lowerActive = activeStep === 1 && !!lowerItems.find(i => i.item_index === activeItemIndex);
        const lowerInHighlights = !!lowerItems.find(i => forceHighlightItemIndexes.includes(i.item_index));

        return {
            upperVisible: upperItems.length > 0,
            upperHighlighted: upperActive || upperInHighlights,
            lowerVisible: lowerItems.length > 0,
            lowerHighlighted: lowerActive || lowerInHighlights,
        };
    }, [activeItemIndex, activeStep, forceHighlightItemIndexes, lowerItems, upperItems]);
}

function useCurrentBulkEditId() {
    const { items, bulkEditIds } = useCheckoutPropSelector(['items', 'bulkEditIds']);
    const activeItemIndex = useCheckoutSelector(getActiveItemIndex);

    return React.useMemo(() => {
        const activeItem = items.find(i => i.item_index === activeItemIndex);
        return activeItem?.bulk_edit_id && bulkEditIds.includes(activeItem.bulk_edit_id)
            ? activeItem.bulk_edit_id
            : undefined;
    }, [activeItemIndex, bulkEditIds, items]);
}

interface CheckoutMouthContainerProps {
    layout?: 'vertical' | 'horizontal';
    forceHighlightItemIndexes?: number[];
}

export const CheckoutMouthContainer: React.FC<CheckoutMouthContainerProps> = props => {
    const { items } = useCheckoutPropSelector(['items']);
    const currentBulkId = useCurrentBulkEditId();

    const itemsProp = useSingleMouthItems(items, currentBulkId, props.forceHighlightItemIndexes);
    const bridgesProp = useBridgesProp(items, props.forceHighlightItemIndexes);
    const fullMouthProp = useFullMouthProp(items, props.forceHighlightItemIndexes);

    const isAligner = useScanIsAligner();
    const isDenture = useScanIsDenture();
    const dentureArchType = useDentureCheckoutSelector(s => s.fields.dentureArch);

    const isMobile = useScreenIsMobileOrVerticalTablet();
    if (isAligner) {
        return (
            <div
                style={{
                    justifyContent: 'center',
                    display: 'flex',
                    width: '100%',
                    marginTop: isMobile ? 24 : undefined,
                }}
            >
                <img src={'/checkout/aligner.png'} alt={'Aligners'} />
            </div>
        );
    }

    if (isDenture) {
        return (
            <DandyDentureMouthDisplay
                upper={dentureArchType === OrderItemArch.Dual || dentureArchType === OrderItemArch.Upper}
                lower={dentureArchType === OrderItemArch.Dual || dentureArchType === OrderItemArch.Lower}
                orientVertically={true}
            />
        );
    }

    return (
        <DandyMouthDisplay
            items={itemsProp}
            bridges={bridgesProp}
            fullMouth={fullMouthProp}
            verticalLayout={props.layout === 'vertical'}
            animate={true}
        />
    );
};
