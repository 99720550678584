import { useOrderSummaryRowButtonStyles } from '@orthly/dentin';
import { useCancelOrderReturnMutation } from '@orthly/graphql-react';
import { LabsGqlOrderReturnStatus, LabsGqlOrderReturnType } from '@orthly/graphql-schema';
import { QuickForm, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

interface CancelOrderReturnProps {
    orderReturnStatus: LabsGqlOrderReturnStatus;
    orderReturnType: LabsGqlOrderReturnType;
    orderReturnId: string;
    refetch: () => Promise<void>;
}

export const CancelOrderReturnAction: React.FC<CancelOrderReturnProps> = ({
    orderReturnStatus,
    orderReturnType,
    orderReturnId,
    refetch,
}) => {
    const buttonClasses = useOrderSummaryRowButtonStyles();
    const [submitMtn] = useCancelOrderReturnMutation();
    const mtnSubmitter = (reason: string) => submitMtn({ variables: { reason, returnId: orderReturnId } });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [string]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Return canceled', {}],
        onSuccess: () => refetch(),
    });
    // practices cannot cancel iteration returns
    if (
        orderReturnStatus !== LabsGqlOrderReturnStatus.NotShipped ||
        orderReturnType === LabsGqlOrderReturnType.Iteration
    ) {
        return null;
    }
    return (
        <>
            <RootActionDialog
                loading={submitting}
                open={open}
                setOpen={setOpen}
                title={'Cancel Return'}
                content={
                    <QuickForm<{ reason: string }>
                        fields={{
                            reason: {
                                type: 'text',
                                label: 'Cancellation Reason',
                                helperText: 'Please let us know why you need to cancel this return',
                                fieldProps: { multiline: true, rows: 2 },
                            },
                        }}
                        initialValues={{ reason: '' }}
                        onSubmit={async result => {
                            await submit(result.reason);
                        }}
                    />
                }
                style={{ padding: 0, display: 'none' }}
                buttonText={'Cancel Return'}
                buttonProps={{ style: { display: 'none' } }}
            />
            <Button
                endIcon={'CloseIcon'}
                className={buttonClasses.button}
                variant={'ghost'}
                onClick={e => {
                    e.stopPropagation();
                    setOpen(true);
                }}
                analytics={{
                    AssetName: 'Cancel Return',
                    AssetLocation: 'Practice - Order List',
                }}
            >
                Cancel return
            </Button>
        </>
    );
};
