import { OrderAlertBanner } from '../OrderAlertBanner/OrderAlertBanner';
import type { LabsGqlLabOrderWithEvents } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

interface OrderRefabAlertBannerProps {
    order: Pick<LabsGqlLabOrderWithEvents, 'refabrication_order_id' | 'original_order_id'>;
    openOrder: (orderId: string, e?: React.MouseEvent) => void;
}

/**
 * @deprecated Use OrderLinkedOrdersBanners instead
 *
 * Delete when no more usages exist
 */
export const OrderRefabAlertBanner: React.VFC<OrderRefabAlertBannerProps> = ({ order, openOrder }) => {
    const { refabrication_order_id, original_order_id } = order;

    if (!refabrication_order_id && !original_order_id) {
        return null;
    }

    const getTitle = () => {
        if (refabrication_order_id && original_order_id) {
            return 'This is a refabrication order that has been refabricated.';
        }
        if (refabrication_order_id) {
            return 'This order has been refabricated.';
        }
        return 'This is a refabrication order.';
    };

    return (
        <OrderAlertBanner
            variant={'alert'}
            title={getTitle()}
            actions={_.compact([
                !!original_order_id && {
                    label: 'View Original',
                    onClick: e => openOrder(original_order_id, e),
                },
                !!refabrication_order_id && {
                    label: 'View New',
                    onClick: e => openOrder(refabrication_order_id, e),
                },
            ])}
        />
    );
};
