import { FullDenturesAddOnsTable } from './FullDenturesAddOnsTable';
import { FullDenturesAestheticTable } from './FullDenturesAestheticTable';
import { FullDenturesSignatureTable } from './FullDenturesSignatureTable';
import { NightGuardsTable } from './NightGuardsTable';
import { PartialDenturesAddOnsTable } from './PartialDenturesAddOnsTable';
import { PartialDenturesMaterialsTable } from './PartialDenturesMaterialsTable';
import { SleepApneaTable } from './SleepApneaTable';
import { TMJTable } from './TMJTable';
import { Grid, Text } from '@orthly/ui-primitives';

export const RemovablePricing: React.FC = () => (
    <Grid container direction={'column'} data-test={'pricing-content-Removable'} style={{ gap: '24px' }}>
        <Grid item xs={12}>
            <Text data-test={'pricing-header-partial-dentures'} variant={'h4'}>
                Partial Dentures
            </Text>
        </Grid>
        <Grid item xs={12}>
            <PartialDenturesMaterialsTable />
        </Grid>
        <Grid item xs={12}>
            <PartialDenturesAddOnsTable />
        </Grid>
        <Grid item xs={12}>
            <Text data-test={'pricing-header-full-dentures'} variant={'h4'}>
                Full Dentures
            </Text>
        </Grid>
        <Grid item xs={12}>
            <FullDenturesSignatureTable />
        </Grid>
        <Grid item xs={12}>
            <FullDenturesAestheticTable />
        </Grid>
        <Grid item xs={12}>
            <FullDenturesAddOnsTable />
        </Grid>
        <Grid item xs={12}>
            <Text data-test={'pricing-header-other-removables'} variant={'h4'}>
                Other removables
            </Text>
        </Grid>
        <Grid item xs={12}>
            <NightGuardsTable />
        </Grid>
        <Grid item xs={12}>
            <TMJTable />
        </Grid>
        <Grid item xs={12}>
            <SleepApneaTable />
        </Grid>
    </Grid>
);
