/* eslint-disable no-nested-ternary */
import { AnalyticsClient } from '../../analytics/analyticsClient';
import type { StarRatingType } from '../inbox/components/tracker/OrderSummaryStepper';
import { cleanStarRating, FeedbackCurrentLabOrderProvider, useFeedbackCurrentLabOrder } from './Feedback.util';
import { FeedbackBody } from './FeedbackBody';
import { FeedbackPostSubmitScreenComponent } from './FeedbackPostSubmitScreen';
import { FeedbackSidebar } from './FeedbackSidebar';
import { FeedbackActions } from './state/feedback.actions';
import type { FeedbackScreen } from './state/feedback.reducer';
import { useFeedbackSelector } from './state/feedback.selectors';
import { useSubmitFeedbackV3 } from './state/useSubmitFeedback';
import { firstScreenForItem } from './utils';
import type { FeedbackAndRefabItem } from '@orthly/dentin';
import {
    OrderToolbar,
    ORDER_TOOLBAR_HEIGHT,
    feedbackOrRefabItemFromOrderItem,
    PracticeFullScreenDialog,
    PracticeScreen,
} from '@orthly/dentin';
import { useOrder } from '@orthly/graphql-react';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import type { ArrayMin1 } from '@orthly/runtime-utils';
import { isArrayMin1, isArrayMin2 } from '@orthly/runtime-utils';
import {
    FlossPalette,
    useScreenIsMobileOrVerticalTablet,
    createStyles,
    Grid,
    makeStyles,
    useMediaQuery,
    Slide,
    CloseIcon,
    Text,
} from '@orthly/ui-primitives';
import type { Theme } from '@orthly/ui-primitives';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useLocation, useParams } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        sidebarPlaceholder: {
            width: 424,
            padding: '20px 56px 40px 32px',
            overflowY: `auto`,
        },
    }),
);

const firstScreenForOrder = (items: ArrayMin1<FeedbackAndRefabItem>, starRating?: StarRatingType): FeedbackScreen => {
    // if it is a multi-item order or we've initiated feedback through the entrypoint from the order detail page,
    // go to the item select screen. Otherwise, determine if we should be going to item props or item notes screen
    if (isArrayMin2(items) || starRating === 0) {
        return { items, id: 'item_select', previous_screen: undefined };
    }
    return firstScreenForItem({
        starRating,
        screen: { items, id: 'item_select', previous_screen: undefined },
        item: items[0],
        fiveStarShortcutUsed: true,
    });
};

const useGetSidebarStatusAndActions = () => {
    const dispatch = useDispatch();
    const fullscreen_sidebar_shown = useFeedbackSelector(s => s.show_fullscreen_sidebar);
    const toggleFullscreenSidebar = React.useCallback(() => {
        dispatch(FeedbackActions.TOGGLE_FULLSCREEN_SIDEBAR(true));
    }, [dispatch]);
    return {
        fullscreen_sidebar_shown,
        toggleFullscreenSidebar,
    };
};

const FeedbackDetailsOnlyLayout: React.VFC<{ starRating: StarRatingType }> = ({ starRating }) => {
    const screen = useFeedbackSelector(s => s.screen);
    const { lab_order } = useFeedbackCurrentLabOrder();
    const patient = lab_order?.patient ?? {
        first_name: '',
        last_name: '',
    };
    const patientName = `${patient.first_name} ${patient.last_name}`;
    const dispatch = useDispatch();
    const onClose = React.useCallback(() => {
        dispatch(FeedbackActions.TOGGLE_FULLSCREEN_SIDEBAR(false));
    }, [dispatch]);
    const topMargin = 8;
    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ overflow: `visible`, backgroundColor: FlossPalette.GRAY }}>
            <div style={{ minHeight: topMargin }}>&nbsp;</div>
            <Slide in={true} direction={'up'} timeout={300}>
                <div
                    style={{
                        backgroundColor: FlossPalette.WHITE,
                        borderRadius: 20,
                        minHeight: `calc(100vh - ${topMargin}px)`,
                    }}
                >
                    <div
                        style={{
                            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
                            paddingBlock: 12,
                            textAlign: 'center',
                        }}
                    >
                        <span
                            style={{ float: 'right', padding: '0 12px 12px 12px', cursor: 'pointer' }}
                            onClick={onClose}
                        >
                            <CloseIcon style={{ color: FlossPalette.GRAY }} />
                        </span>
                        <Text variant={'body2'} medium>
                            {patientName}
                        </Text>
                    </div>
                    <FeedbackSidebar screen={screen} starRating={starRating} />
                </div>
            </Slide>
        </PracticeFullScreenDialog>
    );
};

const FeedbackContainer: React.FC = ({ children }) => (
    <Grid
        container
        wrap={'nowrap'}
        style={{ height: `calc(100vh - ${ORDER_TOOLBAR_HEIGHT}px)`, flexGrow: 1 }}
        justifyContent={'center'}
    >
        {children}
    </Grid>
);

const FeedbackLayout: React.VFC<{ starRating: StarRatingType }> = ({ starRating }) => {
    const classes = useStyles();
    const screen = useFeedbackSelector(s => s.screen);
    const show_fullscreen_sidebar = useFeedbackSelector(s => s.show_fullscreen_sidebar);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { lab_order } = useFeedbackCurrentLabOrder();
    const { fullscreen_sidebar_shown, toggleFullscreenSidebar } = useGetSidebarStatusAndActions();
    const history = useHistory();
    const hidden = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));

    if (show_fullscreen_sidebar) {
        return <FeedbackDetailsOnlyLayout starRating={starRating} />;
    }
    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ overflow: `visible` }}>
            <OrderToolbar
                order={lab_order}
                fullscreenSidebarShown={fullscreen_sidebar_shown}
                toggleFullscreenSidebar={toggleFullscreenSidebar}
                onClose={() => history.goBack()}
                showFeedbackOrderDetails={screen.id === 'item_props'}
            />
            <FeedbackContainer>
                {screen.id !== `post_submit` ? (
                    <>
                        <FeedbackBody screen={screen} />
                        {screen.id === `item_props` || screen.id === 'item_notes' ? (
                            hidden ? null : (
                                <FeedbackSidebar screen={screen} starRating={starRating} />
                            )
                        ) : (
                            !isMobile && <div className={classes.sidebarPlaceholder} />
                        )}
                    </>
                ) : (
                    <FeedbackPostSubmitScreenComponent />
                )}
            </FeedbackContainer>
        </PracticeFullScreenDialog>
    );
};

const FeedbackInitializer: React.VFC<{ starRating: StarRatingType }> = ({ starRating }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { lab_order, loading } = useFeedbackCurrentLabOrder();
    const { submit } = useSubmitFeedbackV3();

    React.useEffect(() => {
        if (loading) {
            return;
        }

        if (!lab_order) {
            // We used to redirect to the inbox page here, however this happened to users when it shouldn't occur
            // In order to try and debug this issue, we're adding an event here, and we fail silently as we believe
            // the feature will work as expected if we don't redirect the end-user
            AnalyticsClient.track('Practice - Feedback - Error Initializing Page', {});
            return;
        }

        const { patient } = lab_order;
        const items_v2 = OrderItemV2Utils.parseItems(lab_order.items_v2);
        // We don't want to include mini models in feedback as they are never actually sent to the practice
        const items_without_mini_model = items_v2.filter(
            item =>
                !(OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Other) && item.unit.unit_type === 'Mini Model'),
        );
        const items = items_without_mini_model.map((item, index) =>
            feedbackOrRefabItemFromOrderItem(item, index, patient.first_name),
        );

        dispatch(FeedbackActions.START_NEW());

        // if there`s more than zero items on the order, start with the first item
        if (isArrayMin1(items)) {
            dispatch(FeedbackActions.GO_TO(firstScreenForOrder(items, starRating)));
            return;
        }

        // there's zero items, we have nothing to ask!
        submit();
    }, [lab_order, loading, submit, history, dispatch, starRating]);

    return null;
};

const FeedbackLabOrderLoader: React.VFC<{ lab_order_id: string }> = ({ lab_order_id }) => {
    // Important: we use `no-cache` as otherwise some other queries (like the one that lists partial fulfillment orders) override this value
    const { order: lab_order, loading } = useOrder(lab_order_id, { fetchPolicy: 'no-cache' });
    const { search } = useLocation();
    const rawStarRating = new URLSearchParams(search).get('shortcutStarRating');
    const starRating = rawStarRating ? cleanStarRating(Number(rawStarRating)) : 0;
    const { fullscreen_sidebar_shown, toggleFullscreenSidebar } = useGetSidebarStatusAndActions();
    const history = useHistory();

    return (
        <FeedbackCurrentLabOrderProvider loading={loading} lab_order={lab_order}>
            {/* This is an edge case that should not happen often, but if for some reason the user navigates
            to this old feedback URL (back button for instance), then we display this error message
            so they don't go through the process of submitting a review again since we don't support it */}
            {lab_order?.review_submission ? (
                <>
                    <OrderToolbar
                        order={lab_order}
                        fullscreenSidebarShown={fullscreen_sidebar_shown}
                        toggleFullscreenSidebar={toggleFullscreenSidebar}
                        onClose={() => history.goBack()}
                    />
                    <div style={{ padding: `24px` }}>Feedback has already been submitted for this order.</div>;
                </>
            ) : (
                <>
                    <FeedbackInitializer starRating={starRating} />
                    <FeedbackLayout starRating={starRating} />
                </>
            )}
        </FeedbackCurrentLabOrderProvider>
    );
};

export const FeedbackPage: React.VFC = () => {
    const { lab_order_id } = useParams<{ lab_order_id?: string }>();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(FeedbackActions.GO_TO({ id: `loader` }));
    }, [dispatch, lab_order_id]);

    if (!lab_order_id) {
        return <Redirect to={{ pathname: `/${PracticeScreen.inbox}` }} />;
    }

    return <FeedbackLabOrderLoader lab_order_id={lab_order_id} />;
};
