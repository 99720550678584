import { SimpleCopyToClipboard } from '../SimpleCopyToClipboard';
import { getOrderListItemHeight } from './OrderSummaryRow.util';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { Button, FlossPalette, Grid, styled, Text, useScreenIsMd, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';

const RootContainer = styled(Grid)(({ theme }) => ({
    position: 'relative',
    padding: '16px 0',
    alignContent: 'flex-start',
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    [theme.breakpoints.down('lg')]: {
        flexFlow: 'column',
    },
    [theme.breakpoints.down('sm')]: {
        flexFlow: 'column',
    },
}));
const GridItem = styled(Grid)({
    zIndex: 1,
    position: 'relative',
    '&:has(.MuiGrid-justify-xs-space-between)': {
        marginBottom: 8,
    },
});
const OrderNumberContainer = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('lg')]: {
        paddingRight: 0,
    },
}));
const TitleContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'space-between',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'start',
    },
}));
const TitleText = styled(Text)({
    paddingRight: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
});
const SubtitleRowConatiner = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
});
const OrderLinkContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
});
const OrderLinkText = styled(Text)({
    textWrap: 'nowrap',
});
const OrderLinkButton = styled(Button)({
    padding: 0,
    height: 'auto',
});

const getRowHeight = (isInbox: boolean, isTablet: boolean) => {
    if (!isInbox) {
        return 'auto !important';
    }

    return getOrderListItemHeight(isTablet);
};

const getTitleColor = (needsAttention: boolean, isCanceled: boolean) => {
    if (needsAttention) {
        return 'ATTENTION';
    }

    return isCanceled ? 'GRAY' : 'BLACK';
};

export interface OrderSummaryRowBaseProps {
    patientName: string;
    patientSKUs?: string;
    subtitle: string | React.ReactNode;
    onClick: () => void;
    openOrder: (orderId: string) => void;
    iconsSection?: React.ReactNode;
    stepperSection?: React.ReactNode;
    isLast?: boolean;
    isInbox?: boolean;
    // When true, the row will be rendered as if it were on mobile
    // This is used in Chairside because we display the row in a column on the main
    // screen and is not full width.
    forceMobileDisplay?: boolean;
    replacedByOrder?: {
        id: string;
        orderNumber: string;
    };
    orderId: string | null;
    orderNumber: string | null;
    needsAttention: boolean;
    isCanceled: boolean;
    Buttons?: React.ReactNode;
    className?: string;
}

export const OrderSummaryRowBase: React.FC<OrderSummaryRowBaseProps> = ({
    patientName,
    patientSKUs,
    subtitle,
    onClick,
    openOrder,
    iconsSection,
    stepperSection,
    isLast,
    isInbox,
    forceMobileDisplay,
    replacedByOrder,
    orderId,
    orderNumber,
    Buttons,
    className,
    needsAttention,
    isCanceled,
}) => {
    const isTablet = useScreenIsMd();
    const isMobile = useScreenIsMobile();
    const rowHeight = getRowHeight(!!isInbox, isTablet);
    const titleColor = getTitleColor(needsAttention, isCanceled);

    const openUpdatedOrder = (event: React.MouseEvent) => {
        event.stopPropagation();
        if (replacedByOrder) {
            openOrder(replacedByOrder.id);
        }
    };

    return (
        <RootContainer
            container
            onClick={() => onClick()}
            style={{
                borderBottom: isLast ? 'none' : undefined,
                height: rowHeight,
            }}
            data-test={'order-summary-row'}
            role={'row'}
            data-test-value={orderId}
            className={className}
        >
            <Grid
                container
                item
                xs={12}
                lg={!forceMobileDisplay ? 5 : undefined}
                wrap={'nowrap'}
                style={!isTablet ? { height: '100%' } : undefined}
            >
                <GridItem container item>
                    <TitleContainer>
                        <div style={{ display: 'flex' }}>
                            <TitleText variant={'body2'} color={titleColor}>
                                <Text component={'span'} medium variant={'body2'} color={titleColor}>
                                    {patientName}{' '}
                                </Text>
                                {patientSKUs}
                            </TitleText>
                            {iconsSection && <div style={{ marginLeft: 8 }}>{iconsSection}</div>}
                        </div>

                        {replacedByOrder && (
                            <OrderLinkContainer>
                                <OrderLinkText variant={'body2'}>
                                    {'This order is archived. The updated order is: '}
                                </OrderLinkText>
                                <OrderLinkButton onClick={openUpdatedOrder} variant={'ghost'}>
                                    #{replacedByOrder.orderNumber}
                                </OrderLinkButton>
                            </OrderLinkContainer>
                        )}
                        <SubtitleRowConatiner>
                            <Text color={'GRAY'} variant={'caption'}>
                                {subtitle}
                            </Text>

                            {orderNumber && orderId && (
                                <OrderNumberContainer
                                    style={{ paddingRight: !forceMobileDisplay && !isTablet ? 29 : 0 }}
                                >
                                    <SimpleCopyToClipboard
                                        onClick={event => {
                                            event.stopPropagation();
                                            BrowserAnalyticsClientFactory.Instance?.track('Order Number Copied', {
                                                $groups: { order: orderId },
                                            });
                                        }}
                                        size={'small'}
                                        tooltip={'Copy order ID'}
                                        value={orderNumber}
                                        variant={'secondary'}
                                    >
                                        {`Order ID: #${orderNumber}`}
                                    </SimpleCopyToClipboard>
                                </OrderNumberContainer>
                            )}
                        </SubtitleRowConatiner>
                    </TitleContainer>
                </GridItem>
            </Grid>
            <GridItem
                container
                item
                xs={12}
                lg={!forceMobileDisplay ? 7 : undefined}
                wrap={'nowrap'}
                alignItems={'flex-start'}
                justifyContent={'flex-end'}
                direction={isMobile ? 'column' : 'row'}
                style={{ marginTop: 0 }}
            >
                {stepperSection}
                {Buttons}
            </GridItem>
        </RootContainer>
    );
};
