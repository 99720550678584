import { useGetUserFromDrPrefId } from '../../../../../graphql/useGetUserFromDrPrefId.graphql';
import { usePracticeStaffControls } from '../../../state/usePracticeStaffControls';
import { useEmailValidation } from '../useEmailValidation';
import { usePhoneNumberValidation } from '../usePhoneNumberValidation';
import { LabsUtilsBase } from '@orthly/dentin';
import React from 'react';

const DEFAULT_TIMEZONE = LabsUtilsBase.normalizedTimezoneFromBrowser;

export const useContactInformationSection = () => {
    const preferredNameRef = React.useRef<string>();
    const contactEmailRef = React.useRef<string>();
    const contactPhoneRef = React.useRef<string>();
    const timezoneRef = React.useRef<string>();
    const bestCallHourRef = React.useRef<string>();
    const { employeePrefs } = usePracticeStaffControls() ?? {};
    const { user } = useGetUserFromDrPrefId(employeePrefs?.id);

    const drPrefName = employeePrefs?.name ?? '';
    const drPrefEmail = employeePrefs?.contact_email ?? '';
    const drPrefEmailMobilePhone = employeePrefs?.contact_phone ?? '';
    const drPrefTimeZone = employeePrefs?.timezone ?? DEFAULT_TIMEZONE ?? '';
    const drPrefBetterCallHour = employeePrefs?.best_call_hour;

    const userEmail = user?.email ?? '';
    const userMobilePhone = user?.mobile_phone_number ?? '';

    const [preferredName, setPreferredName] = React.useState('');
    const [contactEmail, setContactEmail] = React.useState('');
    const [contactPhone, setContactPhone] = React.useState('');
    const [timezone, setTimezone] = React.useState('');
    const [bestCallHour, setBestCallHour] = React.useState('');
    const [duplicateContactInfo, setDuplicateContactInfo] = React.useState(true);

    const { isFormValid: isContactPhoneValid, phoneNumberError: contactPhoneError } =
        usePhoneNumberValidation(contactPhone);
    const { emailError: contactEmailError } = useEmailValidation(contactEmail);

    React.useEffect(() => {
        preferredNameRef.current = drPrefName;
        contactEmailRef.current = userEmail === drPrefEmail ? '' : drPrefEmail;
        contactPhoneRef.current = userMobilePhone === drPrefEmailMobilePhone ? '' : drPrefEmailMobilePhone;
        timezoneRef.current = drPrefTimeZone;
        bestCallHourRef.current = drPrefBetterCallHour ? `${drPrefBetterCallHour}` : '';
        setPreferredName(preferredNameRef.current);
        setContactEmail(contactEmailRef.current);
        setContactPhone(contactPhoneRef.current);
        setTimezone(timezoneRef.current);
        setBestCallHour(bestCallHourRef.current);
    }, [
        drPrefName,
        drPrefEmail,
        drPrefEmailMobilePhone,
        drPrefTimeZone,
        drPrefBetterCallHour,
        userEmail,
        userMobilePhone,
    ]);

    React.useEffect(() => {
        if (drPrefEmail !== userEmail || drPrefEmailMobilePhone !== userMobilePhone) {
            setDuplicateContactInfo(false);
        } else {
            setDuplicateContactInfo(true);
        }
    }, [drPrefEmail, drPrefEmailMobilePhone, userEmail, userMobilePhone]);

    const toggleDuplicateContactInfo = () =>
        setDuplicateContactInfo(prev => {
            const next = !prev;

            if (next) {
                setContactEmail('');
                setContactPhone('');
            }

            return next;
        });

    return {
        preferredName,
        setPreferredName,
        duplicateContactInfo,
        toggleDuplicateContactInfo,
        contactEmail,
        setContactEmail,
        contactPhone,
        setContactPhone,
        timezone,
        setTimezone,
        bestCallHour,
        setBestCallHour,
        isContactPhoneValid,
        contactEmailError,
        contactPhoneError,
        isDirty:
            preferredName !== preferredNameRef.current ||
            contactEmail !== contactEmailRef.current ||
            contactPhone !== contactPhoneRef.current ||
            timezone !== timezoneRef.current ||
            bestCallHour !== bestCallHourRef.current,
    };
};
