/* eslint-disable no-nested-ternary */
import type {
    FieldDefArray,
    FieldDefBasic,
    FieldDefMultiSelect,
    FieldsDefProp,
    QuickFormBasicFieldType,
    FieldDefNested,
} from './QuickForm.types';
import { PhoneField } from './QuickForm.types';
import { ValidationUtils, PhoneNumberUtils } from '@orthly/runtime-utils';
import _ from 'lodash';
import moment from 'moment';
import { z } from 'zod';

type AnyObj = { [k: string]: any };

type QuickFormFieldTypeMap<V> = { [K in QuickFormBasicFieldType]-?: V };

export const InitialValMap: QuickFormFieldTypeMap<any> = {
    boolean: false,
    datetime: null,
    date: null,
    time: null,
    number: null,
    select: '',
    text: null,
    custom: null,
    autocomplete: null,
    currency: null,
    [PhoneField]: null,
    multiselect: null,
    radioGroup: null,
};

const nameValidate = z.string().regex(ValidationUtils.regexes.name, 'Please only enter letters!');
// checks that the name is a full name, i.e. has a first+last name, by testing that there is at least one space in the name
const fullNameValidate = z
    .string()
    .regex(/^[a-zA-Z0-9.'-]{1,50}(?: [a-zA-Z0-9.'-]{1,50})+$/gim, 'Please enter a valid full name!');

const phoneError = 'Please enter a valid phone number';
const phoneValidator = z
    .string()
    .min(10, phoneError)
    .regex(ValidationUtils.regexes.phone, phoneError)
    .refine(PhoneNumberUtils.isValidPhoneNumber, phoneError);

const emailValidator = z.string().trim().regex(ValidationUtils.regexes.email, 'Please enter a valid email');

export const QuickFormValidations = {
    name: nameValidate,
    phone: phoneValidator,
    email: emailValidator,
    fullName: fullNameValidate,
};

export const DefaultValidationMap = {
    boolean: z.boolean({ invalid_type_error: 'Must be true or false' }),
    datetime: z.date({ invalid_type_error: 'Must be a valid date' }),
    date: z.coerce.date({ invalid_type_error: 'Must be a valid date' }),
    time: z.coerce.date({ invalid_type_error: 'Must be a valid time' }),
    number: z.number({ invalid_type_error: 'Must be a number' }),
    select: z.string({ invalid_type_error: 'Must select an option' }),
    multiselect: z.array(z.string()),
    text: z.string(),
    custom: z.string({ invalid_type_error: 'Must be text' }),
    autocomplete: z.string({ invalid_type_error: 'Must be text' }),
    currency: z.string({ invalid_type_error: 'Must be a valid amount' }),
    [PhoneField]: phoneValidator,
    radioGroup: z.string({
        invalid_type_error: 'Must select an option',
    }),
};

function makePassthrough(schema: z.Schema<any>): z.Schema<any> {
    if (schema instanceof z.ZodObject) {
        return schema.passthrough();
    }
    return schema;
}

function singleFieldValidation(_key: string, field: FieldDefBasic | FieldDefMultiSelect) {
    const nonOptionalValidator = field.validation
        ? makePassthrough(field.validation)
        : DefaultValidationMap[field.type];

    // Need to optionalize the validator before it's passed to `preprocess`. Optionalizing after
    // `preprocess` only works when the input value is literally `null` or `optional`. Otherwise
    // the value goes through preprocessing and then to the validator passed to z.preprocess.
    const validator = field.optional ? nonOptionalValidator.optional().nullable() : nonOptionalValidator;

    if (field.type === 'number' || field.type === 'text') {
        // Turn empty strings into undefined so they fail existence validation rather than type validation.
        return z.preprocess(arg => (arg === '' ? undefined : arg), validator);
    }
    if (field.type === 'date') {
        // Formik date fields give us strings. Parse them into Dates before validation.
        return z.preprocess(arg => {
            if (typeof arg === 'string' || arg instanceof Date) {
                return new Date(arg);
            }
        }, validator);
    }
    return validator;
}

export function buildQFValidation<R extends AnyObj>(fields: FieldsDefProp<R>): z.Schema<R> {
    function buildArrayFieldValidation(key: string, arrayField: FieldDefArray) {
        if (arrayField.validation) {
            return arrayField.validation;
        }
        const arrayValidationOf =
            arrayField.of.type === 'nested'
                ? buildQFValidation(arrayField.of.fields)
                : singleFieldValidation(key, arrayField.of);
        const arrMax = arrayField.max || 1000000000000;
        const arrMin = arrayField.min || 0;
        const label = arrayField.label || _.startCase(key);
        return z
            .array(arrayValidationOf)
            .max(arrMax, `${label} must have less than ${arrMax} ${arrMax === 1 ? 'entry' : 'entries'}`)
            .min(arrMin, `${label} must have at least ${arrMin} ${arrMin === 1 ? 'entry' : 'entries'}`);
    }

    function quickFormValidation<R extends AnyObj>(fields: FieldsDefProp<R>): z.Schema<R> {
        const validation = z.object(
            _.mapValues(fields, (field, key) => {
                const validation =
                    field.type === 'array'
                        ? buildArrayFieldValidation(key, field as FieldDefArray)
                        : field.type === 'nested'
                          ? quickFormValidation((field as FieldDefNested<any>).fields)
                          : singleFieldValidation(key, field as FieldDefBasic);

                return field.optional ? validation.optional().nullable() : validation;
            }),
        );
        // Force cast since we can't prove that `validation` is for `R`.
        return validation as unknown as z.Schema<R>;
    }

    return quickFormValidation(fields);
}

export function buildQFInitialValues<R extends AnyObj>(
    fields: FieldsDefProp<R>,
    initialValues: Partial<R>,
): Partial<R> {
    return Object.entries(fields).reduce((initial, [fieldKey, field]) => {
        if (field.type === 'array') {
            return {
                ...initial,
                [fieldKey]: initial[fieldKey] || [],
            };
        }
        if (field.type === 'nested') {
            return {
                ...initial,
                [fieldKey]:
                    field.optional && initial[fieldKey] === undefined
                        ? null
                        : buildQFInitialValues<any>(field.fields, { ...initial[fieldKey] }),
            };
        }
        const fieldValue = initial[fieldKey];
        if (fieldValue !== undefined && fieldValue !== null) {
            return {
                ...initial,
                [fieldKey]:
                    field.type === 'datetime'
                        ? moment(initial[fieldKey]).format('YYYY-MM-DDTHH:mm')
                        : initial[fieldKey],
            };
        }
        return {
            ...initial,
            [fieldKey]: InitialValMap[field.type as QuickFormBasicFieldType],
        };
    }, initialValues);
}
