import type {
    LabsGqlDenturesFabricationMethod,
    LabsGqlDenturesTeethQuality,
    LabsGqlDenturesSingleFulfillmentArchType,
    LabsGqlSmileStyleChoice,
} from '@orthly/graphql-schema';
import type { DentureType, OrderItemArch, TeethShade } from '@orthly/items';
import { stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import { OrderDetailFactRow } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    relatedFactArea: { marginTop: 16 },
}));

const DualFactRow: React.VFC<{ title: string; upper?: string; lower?: string }> = ({ title, upper, lower }) => {
    if (upper === lower) {
        return <OrderDetailFactRow flexBasisLeft={200} title={title} value={upper} />;
    }
    return (
        <>
            {upper && <OrderDetailFactRow flexBasisLeft={200} title={`Upper ${title}`} value={upper} />}
            {lower && <OrderDetailFactRow flexBasisLeft={200} title={`Lower ${title}`} value={lower} />}
        </>
    );
};

export const WaxRimSummary: React.VFC<{ arch: OrderItemArch }> = ({ arch }) => {
    return (
        <Grid container item>
            <Text variant={`h6`}>Wax Bite Rim, {arch} Arch</Text>
        </Grid>
    );
};

interface CoreSummaryPropsArch {
    type: LabsGqlDenturesSingleFulfillmentArchType;
    fabrication_method: LabsGqlDenturesFabricationMethod[];
    teeth_quality: LabsGqlDenturesTeethQuality[];
}

interface CoreSummaryProps {
    upper?: CoreSummaryPropsArch;
    lower?: CoreSummaryPropsArch;
}

const getArch = ({ upper, lower }: { upper: unknown; lower: unknown }) =>
    // eslint-disable-next-line no-nested-ternary
    upper && lower ? 'Dual' : upper ? 'Upper' : 'Lower';

export const CoreSummary: React.VFC<CoreSummaryProps> = ({ upper, lower }) => {
    return (
        <Grid container item direction={`column`}>
            <Grid item>
                <Text variant={`h6`}>Full Dentures, {getArch({ upper, lower })} Arch</Text>
            </Grid>
            <Grid item>
                <DualFactRow title={`Type`} upper={upper?.type} lower={lower?.type} />
                <DualFactRow
                    title={`Fabrication Method`}
                    upper={upper?.fabrication_method.join(`, `)}
                    lower={lower?.fabrication_method.join(`, `)}
                />
                <DualFactRow
                    title={`Teeth Quality`}
                    upper={upper?.teeth_quality.join(`, `)}
                    lower={lower?.teeth_quality.join(`, `)}
                />
            </Grid>
        </Grid>
    );
};

const SingleFactRow: React.VFC<{ title: string; value?: string }> = ({ title, value }) =>
    value ? <OrderDetailFactRow flexBasisLeft={200} title={title} value={value} /> : null;

interface CoreSummaryNewProps {
    dentureArch?: OrderItemArch;
    dentureType?: DentureType;
    fabricationMethod?: LabsGqlDenturesFabricationMethod;
    teethQuality?: LabsGqlDenturesTeethQuality;
    baseShade?: TeethShade;
    tissueShade?: TeethShade;
    smileStyle?: LabsGqlSmileStyleChoice;
    numberOfSpares?: number;
}
export const CoreSummaryNew: React.VFC<CoreSummaryNewProps> = props => {
    const classes = useStyles();
    const {
        dentureArch,
        dentureType,
        fabricationMethod,
        teethQuality,
        baseShade,
        tissueShade,
        smileStyle,
        numberOfSpares,
    } = props;
    const displayDentureType = dentureType === 'Copy' ? 'New' : dentureType;
    const displaySmileStyle = smileStyle === 'LabRecommendation' ? 'Lab Choice' : smileStyle;

    return (
        <Grid container item direction={`row`} alignContent={'flex-start'}>
            <Grid item xs={12} className={classes.relatedFactArea} style={{ marginTop: 16 }}>
                <Text variant={`h6`}>Full Dentures, {dentureArch} Arch</Text>
            </Grid>
            {displayDentureType && (
                <Grid item xs={12} className={classes.relatedFactArea}>
                    <SingleFactRow title={'Type'} value={displayDentureType} />
                </Grid>
            )}
            {(baseShade || tissueShade) && (
                <Grid item xs={12} className={classes.relatedFactArea}>
                    <SingleFactRow title={'Tooth Shade'} value={baseShade} />
                    <SingleFactRow title={'Tissue Shade'} value={tissueShade} />
                </Grid>
            )}
            {(fabricationMethod || teethQuality) && (
                <Grid item xs={12} className={classes.relatedFactArea}>
                    <SingleFactRow title={'Base Fabrication'} value={fabricationMethod} />
                    <SingleFactRow title={'Tooth Fabrication'} value={teethQuality} />
                </Grid>
            )}
            {displaySmileStyle && (
                <Grid item xs={12} className={classes.relatedFactArea}>
                    <SingleFactRow title={'Smile Style'} value={displaySmileStyle} />
                </Grid>
            )}
            {numberOfSpares && (
                <Grid item xs={12} className={classes.relatedFactArea}>
                    <SingleFactRow title={'Spare Dentures'} value={`${numberOfSpares}`} />
                </Grid>
            )}
        </Grid>
    );
};
