import { Grid, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const ErrorPage: React.VFC<{ children: React.ReactNode }> = ({ children }) => (
    <Grid
        container
        direction={`column`}
        alignItems={`center`}
        style={{ backgroundColor: FlossPalette.TAN, height: `100vh`, padding: 16 }}
    >
        <Grid item style={{ width: 250, backgroundColor: FlossPalette.WHITE, borderRadius: 16, padding: 16 }}>
            {children}
        </Grid>
    </Grid>
);
