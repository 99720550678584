import { InboxBodySection } from '../InboxBodySection';
import { PracticeOrderSummaryRow } from './PracticeOrderSummaryRow.graphql';
import { PracticeScreen } from '@orthly/dentin';
import {
    getFragmentData,
    PracticeOrdersOverviewRootListSalesOrdersByIds_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import { Button, FlossPalette, Grid, stylesFactory } from '@orthly/ui-primitives';
import { usePracticeOrderListViewEntriesById } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface InboxTrackingRootProps {
    orderIds: string[];
    title: string;
    lastIdFetchAt: number;
    refetch: () => Promise<void>;
}

export const useStyles = stylesFactory(theme => ({
    listItemWrap: {
        cursor: 'pointer',
        padding: '0 40px',
        [theme.breakpoints.down('md')]: {
            padding: '0 16px',
        },
        '&:hover': {
            background: FlossPalette.PRIMARY_BACKGROUND,
        },
    },
}));

export const InboxTrackingRoot: React.FC<InboxTrackingRootProps> = ({ orderIds, title, lastIdFetchAt, refetch }) => {
    const history = useHistory();
    const classes = useStyles();

    const { orders: orderFragments } = usePracticeOrderListViewEntriesById(orderIds, lastIdFetchAt);
    const orders = _.compact(
        orderFragments.map(o => getFragmentData(PracticeOrdersOverviewRootListSalesOrdersByIds_FragmentFragmentDoc, o)),
    );

    if (orderIds.length === 0) {
        return null;
    }

    return (
        <InboxBodySection
            title={title}
            titleSecondary={
                <Button
                    variant={'ghost'}
                    onClick={() => history.push(`/${PracticeScreen.orders}`)}
                    style={{ paddingRight: 0 }}
                >
                    All orders
                </Button>
            }
        >
            {orders.map((o, idx) => (
                <Grid key={o.id} container className={classes.listItemWrap}>
                    <PracticeOrderSummaryRow orderFragment={o} isLast={idx === orders.length - 1} refetch={refetch} />
                </Grid>
            ))}
        </InboxBodySection>
    );
};
