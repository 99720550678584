import { useAlignerCheckoutPropSelector } from '../../../../redux';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { AlignerBiteConcernsSelection, CheckoutBodyWrapper } from '@orthly/dentin';
import React from 'react';

export const AlignerCheckoutBiteConcernsScreen: React.FC = () => {
    const setBiteConcerns = useAlignerCheckoutAction('SET_BITE_CONCERNS');
    const setAlignerNotes = useAlignerCheckoutAction('SET_ALIGNER_NOTES');

    const { biteConcerns, alignerNotes } = useAlignerCheckoutPropSelector(['biteConcerns', 'alignerNotes']);
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.BiteConcerns);
    if (!isActiveStep) {
        return null;
    }
    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <AlignerBiteConcernsSelection
                biteConcerns={biteConcerns}
                updateBiteConcerns={setBiteConcerns}
                notes={alignerNotes}
                updateNotes={setAlignerNotes}
            />
        </CheckoutBodyWrapper>
    );
};
