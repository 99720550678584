import { useFeatureFlag } from '../../../Providers/LaunchDarkly';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction } from '../state/GuidedWaxupState';
import { getSortedPresetConfigForOrder } from './useGuidedWaxupPresets.utils';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import React from 'react';

export const useGuidedWaxupPresets = (
    order: Pick<LabsGqlOrder, 'items_v2'>,
    design: Pick<OrderDesignPreviewDesign_FragmentFragment, 'id' | 'doctor_review'> | null | undefined,
) => {
    const { id: designRevisionId, doctor_review: selectedReview } = design ?? {};

    const { value: organizeGuidedWaxupPresetsBySKU = false } = useFeatureFlag('organizeGuidedWaxupPresetsBySKU');
    const { setSelectedTab, selectedTabInitialized } = useGuidedWaxupContext();
    const patchState = useGuidedWaxupAction('PATCH_STATE');
    const presets = React.useMemo(
        () => getSortedPresetConfigForOrder(order, selectedReview, organizeGuidedWaxupPresetsBySKU),
        [order, selectedReview, organizeGuidedWaxupPresetsBySKU],
    );

    React.useEffect(() => {
        patchState({
            designRevisionId,
            presets: Object.fromEntries(
                selectedReview?.annotations?.length
                    ? selectedReview.annotations.map(annotation => [
                          annotation.preset_type,
                          {
                              ...annotation,
                              structured_notes: annotation?.structured_notes?.map(({ category }) => category) ?? [],
                          },
                      ]) ?? []
                    : presets.map(presetType => [presetType, {}]),
            ),
        });
    }, [presets, designRevisionId, patchState, selectedReview]);

    React.useEffect(() => {
        const initialSelection = presets[0];

        if (!selectedTabInitialized && initialSelection) {
            setSelectedTab(initialSelection);
        }
    }, [presets, selectedTabInitialized, setSelectedTab]);
};
