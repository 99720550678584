import { useZendeskChat } from '../chat/useZendeskChat';
import { useChatFeatures } from '../chat/utils';
import { Button, styled, Text, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    padding: '8px 16px 64px 16px',
});
const InnerContainer = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});
const MainContent = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '64px',
    [theme.breakpoints.down('sm')]: {
        gap: '32px',
    },
}));
const Footer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
});

interface OnboardingStepLayoutProps {
    id?: string;
    title?: string;
    children?: React.ReactNode;
}

export const OnboardingStepLayout: React.VFC<OnboardingStepLayoutProps> = ({ title, id, children }) => {
    useZendeskChat({ autoOpen: false });
    const { openChat } = useChatFeatures();
    const isMobile = useScreenIsMobile();
    return (
        <Container id={id} data-testid={id}>
            <InnerContainer>
                <MainContent>
                    {title && (
                        <Text variant={isMobile ? 'h4' : 'h2'} medium>
                            {title}
                        </Text>
                    )}
                    {children}
                </MainContent>
                <Footer>
                    <Text variant={'caption'} color={'GRAY'}>
                        Feeling stuck or confused?
                    </Text>
                    <Button variant={'ghost'} inline onClick={openChat}>
                        <Text variant={'caption'} bold color={'GREEN'}>
                            Get in touch with our team
                        </Text>
                    </Button>
                </Footer>
            </InnerContainer>
        </Container>
    );
};
