import { useListDisplayState } from '../../hooks';
import { LoadBlocker } from '@orthly/ui';
import { useSnackbar } from 'notistack';
import React from 'react';
import type { ListOnItemsRenderedProps, ListChildComponentProps } from 'react-window';
import { FixedSizeList as List } from 'react-window';

const LOAD_EXTRA = 10;

export const VIRTUAL_LIST_LOADING = 'loading' as const;

type ListItemBase = { key: string };
export type VirtualListHookResult<Item> = {
    listItems: ((Item & ListItemBase) | undefined)[];
    listItemCount: number | typeof VIRTUAL_LIST_LOADING;
    itemsLoading: boolean;
    error?: any;
};

interface VirtualListProps<Item> {
    listItemHeight: number;
    toolbarHeight?: number;

    useItemData: (startIndex: number, endIndex: number) => VirtualListHookResult<Item>;

    ListItem: React.ComponentType<{ listItem?: Item }>;
    noResultsMessage?: React.ReactNode;

    className?: string;
}

interface VirtualListItemData<Item> {
    startIndex: number;
    ListItem: React.ComponentType<{ listItem?: Item }>;
    noResultsMessage?: React.ReactNode;
    noResults: boolean;
    listItems: ((Item & ListItemBase) | undefined)[];
}

interface VirtualListItemProps<Item> extends ListChildComponentProps {
    data: VirtualListItemData<Item>;
}

function VirtualListItem<Item>(props: VirtualListItemProps<Item>) {
    const { index, data } = props;
    const { ListItem, noResultsMessage, noResults, startIndex, listItems } = data;
    const content = listItems[index - startIndex];

    if (noResults) {
        return <div style={props.style}>{noResultsMessage}</div>;
    }

    return (
        <div style={props.style}>
            <ListItem listItem={content} />
        </div>
    );
}

/**
 * An implementation of a VirtualList, that's generic over any data type.
 * The user passes in a data hook which takes indices as parameters, and produces
 * the data visible at those indices. The virtual list handles index manipulation,
 * displaying items, and handing errors/loading state.
 *
 * The items array returned from the data hook does not need to be a stable reference.
 * However, the items in the array will only be stable references if the items in the
 * array returned from the data hook are stable references.
 *
 * @param listItemHeight The vertical screen height unused by each item in the virtual list.
 * @param toolbarHeight The vertical screen height unused by the virtual list.
 *                      This is used to determine the overall list height.
 * @param useItemData The data hook which takes indices as input and returns an items array.

 * @param ListItem The component used for the items of the VirtualList.
 * @param noResultsMessage The message to display when there is no data in the list.
 *
 * @param className A class to attach to the outer container of the VirtualList.
 */
export function VirtualList<Item>(props: VirtualListProps<Item>) {
    const { listItemHeight, toolbarHeight, useItemData } = props;
    const { ListItem, noResultsMessage, className } = props;

    const { listHeight, visibleItemCount } = useListDisplayState(listItemHeight, toolbarHeight);

    const initialStartIndex = 0;

    const [[startIndex, stopIndex], setIndexes] = React.useState<[number, number]>([
        initialStartIndex,
        initialStartIndex + visibleItemCount + LOAD_EXTRA,
    ]);

    // keep indexes temporarily and commit them when we're not loading. This prevents us from sending
    // off a ton of requests while the user is scrolling.
    const tempIndexes = React.useRef<[number, number]>([0, 0]);

    const { listItems, listItemCount, itemsLoading, error } = useItemData(startIndex, stopIndex);

    const { enqueueSnackbar } = useSnackbar();
    React.useEffect(() => {
        if (error) {
            console.error('VirtualList useItemData error:', error);

            // For now this will just say "list data", but maybe in the future
            // the user should specify what kind of data is being listed.
            enqueueSnackbar(`Failed to load list`, {
                variant: 'error',
                autoHideDuration: 3000,
                anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            });
        }
    }, [enqueueSnackbar, error]);

    React.useEffect(() => {
        const [tempStart, tempStop] = tempIndexes.current;
        const indicesChanged = tempStart !== startIndex || tempStop !== stopIndex;
        if (!itemsLoading && indicesChanged) {
            setIndexes(tempIndexes.current);
        }
    }, [itemsLoading, startIndex, stopIndex]);

    const onItemsRendered = React.useCallback(
        ({ visibleStartIndex, visibleStopIndex }: ListOnItemsRenderedProps) => {
            if (listItemCount === VIRTUAL_LIST_LOADING) {
                return;
            }

            tempIndexes.current = [
                Math.max(0, visibleStartIndex - LOAD_EXTRA),
                Math.min(listItemCount, visibleStopIndex + LOAD_EXTRA),
            ];

            if (!itemsLoading) {
                setIndexes(tempIndexes.current);
            }
        },
        [listItemCount, itemsLoading],
    );

    const itemKey = React.useCallback((index: number) => listItems[index]?.key ?? index, [listItems]);

    const itemData: VirtualListItemData<Item> = {
        startIndex,
        listItems,
        ListItem,
        noResultsMessage,
        noResults: listItemCount === 0,
    };

    return (
        <LoadBlocker
            blocking={listItemCount === VIRTUAL_LIST_LOADING}
            loader={'dandy'}
            ContainerProps={{ className, style: { position: 'relative' } }}
        >
            <List
                itemKey={itemKey}
                overscanCount={LOAD_EXTRA}
                height={listHeight}
                itemCount={listItemCount === VIRTUAL_LIST_LOADING ? 0 : Math.max(listItemCount, 1)}
                itemData={itemData}
                width={'100%'}
                itemSize={listItemHeight}
                onItemsRendered={onItemsRendered}
            >
                {VirtualListItem}
            </List>
        </LoadBlocker>
    );
}
