import { CSTActions } from './cst.actions';
import type { MiniWindowPendingAnimation } from '@orthly/ui';
import { MiniWindowVariant } from '@orthly/ui';
import { handleActions } from 'redux-actions';

export interface CSTState {
    windowState: {
        variant: MiniWindowVariant;
        pendingAnimation: MiniWindowPendingAnimation;
    };
    platform: 'portal' | 'uploader' | 'chairside';
    liveScanReviewOrder?: string;
}

const initialState: CSTState = {
    windowState: {
        variant: MiniWindowVariant.Closed,
        pendingAnimation: 'move',
    },
    platform: 'portal',
};

const maximizeWindowIfClosed = (windowState: CSTState['windowState']): CSTState['windowState'] => {
    return windowState.variant === MiniWindowVariant.Closed
        ? { variant: MiniWindowVariant.Maximized, pendingAnimation: 'move' }
        : windowState;
};

export const cstReducer = handleActions<CSTState, any>(
    {
        ...CSTActions.SET_WINDOW_VARIANT.reducer((state, action) => ({
            ...state,
            windowState: action.payload,
        })),
        ...CSTActions.SET_WINDOW_PENDING_ANIMATION.reducer((state, action) => ({
            ...state,
            windowState: { ...state.windowState, pendingAnimation: action.payload },
        })),
        ...CSTActions.SET_LIVE_SCAN_REVIEW_ORDER.reducer((state, action) => ({
            ...state,
            liveScanReviewOrder: action.payload,
        })),
        ...CSTActions.OPEN_CHAIRSIDE.reducer(state => ({
            ...state,
            platform: 'chairside',
            windowState: maximizeWindowIfClosed(state.windowState),
        })),
        ...CSTActions.OPEN_UPLOADER.reducer(state => ({
            ...state,
            platform: 'uploader',
            windowState: maximizeWindowIfClosed(state.windowState),
        })),
    },
    initialState,
);
