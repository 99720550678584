import { useAlignerCheckoutSelector } from '../../../../../redux';
import {
    ImageTypeUploadOrder,
    selectAlignerCaseStage,
    selectAlignerRetainerPrice,
} from '../../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../../state/aligners-checkout.types';
import type { CheckoutState } from '../../../state/checkout.state';
import type { AlignerCheckoutState } from '../../../state/reducers/aligners-checkout.types';
import { AlignerCaseStage } from '../../../state/reducers/aligners-checkout.types';
import { CheckoutSidebarItems } from '../../CheckoutSidebar/CheckoutSidebarRoot';
import { getRefabricationAlertChildren, patientInfoTexts } from './getRegularCheckoutSidebarData';
import type { CheckoutSidebarStepContainerProps, CheckoutSidebarStepProps } from '@orthly/dentin';
import { CheckoutSidebarImageStep, CheckoutSidebarStepVariant, AlignerCheckoutDisplayUtils } from '@orthly/dentin';
import { Format } from '@orthly/runtime-utils';
import type { Falsy } from '@orthly/runtime-utils';
import { Collapse } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const variantOfStepInCheckout =
    (alignerCheckout: AlignerCheckoutState) =>
    (...currentSteps: AlignerCheckoutStep[]) =>
        currentSteps.includes(alignerCheckout.step) ? CheckoutSidebarStepVariant.Current : undefined;

const AlignerCheckoutReviewItemComponent: React.FC<CheckoutSidebarStepContainerProps> = props => {
    // minimal adaptor from old checkout sidebar CheckoutSidebarItems
    const currentStep = useAlignerCheckoutSelector(s => s.step);
    return (
        <CheckoutSidebarImageStep
            // needs a fixed height for slider to work
            image={
                <Collapse in={currentStep === AlignerCheckoutStep.PatientInformation}>
                    <img src={'/checkout/aligner.png'} alt={'Aligners'} style={{ height: 168 }} />
                </Collapse>
            }
            imageCaption={<CheckoutSidebarItems />}
            {...props}
        />
    );
};

function getAlignerCheckoutRegularSidebarData(
    alignerCheckout: AlignerCheckoutState,
    checkout: CheckoutState,
): CheckoutSidebarStepProps[] {
    const variantOfStep = variantOfStepInCheckout(alignerCheckout);

    const stepsRaw: (Falsy | CheckoutSidebarStepProps)[] = [
        {
            variant: variantOfStep(AlignerCheckoutStep.PatientInformation),
            kind: 'text',
            texts: patientInfoTexts(checkout),
            contentPlaceholder: '-',
        },
        checkout.refab?.original_order && {
            variant: CheckoutSidebarStepVariant.Attention,
            kind: 'custom',
            children: getRefabricationAlertChildren(checkout.refab.original_order),
        },
        {
            kind: 'custom',
            Component: AlignerCheckoutReviewItemComponent,
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.ImageUploadLink, AlignerCheckoutStep.ImageUploadReview),
            kind: 'text',
            texts: [
                {
                    caption: 'Upload Photos',
                    content: ImageTypeUploadOrder.every(type => alignerCheckout.alignerImages?.[type])
                        ? 'Done'
                        : 'To Select',
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.ArchSelection),
            kind: 'text',
            texts: [
                {
                    caption: 'Arch',
                    content: alignerCheckout.alignerArch
                        ? `${alignerCheckout.alignerArch}${
                              alignerCheckout.alignerTreatmentArea
                                  ? ` (${_.startCase(alignerCheckout.alignerTreatmentArea)})`
                                  : ''
                          }`
                        : 'To Select',
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.MovementRestrictedTeethSelection),
            kind: 'text',
            texts: [
                {
                    caption: 'Movement Restrictions',
                    content: AlignerCheckoutDisplayUtils.getTeethSelectContent(alignerCheckout.movementRestrictedTeeth),
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.AttachmentRestrictedTeethSelection),
            kind: 'text',
            texts: [
                {
                    caption: 'Attachment Restrictions',
                    content: AlignerCheckoutDisplayUtils.getTeethSelectContent(
                        alignerCheckout.attachmentRestrictedTeeth,
                    ),
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.ExtractionTeethSelection),
            kind: 'text',
            texts: [
                {
                    caption: 'Extractions',
                    content: AlignerCheckoutDisplayUtils.getTeethSelectContent(alignerCheckout.extractionTeeth),
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.SpacingSelection),
            kind: 'text',
            texts: [
                {
                    caption: 'Spacing',
                    content: AlignerCheckoutDisplayUtils.getInterproximalSpaceSizesContent(
                        alignerCheckout.interproximalSpaceSizes,
                    ),
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.CrowdingResolution),
            kind: 'text',
            texts: [
                {
                    caption: 'Crowding Options',
                    content: AlignerCheckoutDisplayUtils.getCrowdingResolutionContent(
                        alignerCheckout.crowdingResolution,
                    ),
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.BiteConcerns),
            kind: 'text',
            texts: [
                {
                    caption: 'Bite Concerns',
                    content: AlignerCheckoutDisplayUtils.getBiteConcernsContent(alignerCheckout.biteConcerns),
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.Estimating, AlignerCheckoutStep.EstimateReview),
            kind: 'text',
            texts: [{ caption: 'Estimate' }],
        },
        {
            variant: CheckoutSidebarStepVariant.Checkout,
            kind: 'text',
            texts: [{ caption: 'Checkout' }],
        },
    ];
    return _.compact(stepsRaw);
}

const getRetainerArchSelectionContent = (alignerCheckout: AlignerCheckoutState) => {
    const priceInCents = selectAlignerRetainerPrice(alignerCheckout);
    return `${Format.pluralize('set', alignerCheckout.retainer?.quantity ?? 1)}, ${
        priceInCents ? Format.currency(priceInCents, 'cents', false) : 'Free'
    }`;
};

function getAlignerCheckoutRetainerSidebarData(
    alignerCheckout: AlignerCheckoutState,
    checkout: CheckoutState,
): CheckoutSidebarStepProps[] {
    const variantOfStep = variantOfStepInCheckout(alignerCheckout);

    const stepsRaw: (Falsy | CheckoutSidebarStepProps)[] = [
        {
            variant: variantOfStep(AlignerCheckoutStep.PatientInformation),
            kind: 'text',
            texts: patientInfoTexts(checkout),
            contentPlaceholder: '-',
        },
        checkout.refab?.original_order && {
            variant: CheckoutSidebarStepVariant.Attention,
            kind: 'custom',
            children: getRefabricationAlertChildren(checkout.refab.original_order),
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.RetainerArchSelection),
            kind: 'text',
            texts: [
                { caption: '○ Retainer order', content: 'Reviewing' },
                {
                    caption: 'Retainer sets',
                    content: getRetainerArchSelectionContent(alignerCheckout),
                },
            ],
        },
        {
            variant: variantOfStep(AlignerCheckoutStep.RetainerAdditionalInfo),
            kind: 'text',
            texts: [
                { caption: 'Restorations', content: alignerCheckout.retainer?.hasRestorations ? 'Yes' : 'No' },
                {
                    caption: 'Fixed lingual retainer',
                    content: alignerCheckout.retainer?.isFixedLingual ? 'Yes' : 'No',
                },
            ],
        },
        {
            variant: CheckoutSidebarStepVariant.Checkout,
            kind: 'text',
            texts: [{ caption: 'Checkout' }],
        },
    ];
    return _.compact(stepsRaw);
}

export function getAlignerCheckoutSidebarData(
    alignerCheckout: AlignerCheckoutState,
    checkout: CheckoutState,
): CheckoutSidebarStepProps[] {
    switch (selectAlignerCaseStage(alignerCheckout)) {
        case AlignerCaseStage.InitialAligner:
        case AlignerCaseStage.Refinement:
            return getAlignerCheckoutRegularSidebarData(alignerCheckout, checkout);
        case AlignerCaseStage.Retainer:
            return getAlignerCheckoutRetainerSidebarData(alignerCheckout, checkout);
        default:
            return [];
    }
}
