import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useRecordDesignDownloadMutation } from '@orthly/graphql-react';
import { useFirebaseFileDownload } from '@orthly/veneer';
import last from 'lodash/last';

const PracticeUseDownloadOrderFiles_Query = graphql(`
    query PracticeUseDownloadOrderFiles_Query($orderId: String!) {
        getLatestDesignOrderDesignRevision(labOrderId: $orderId) {
            source_file_zip_path
            design_source
        }
    }
`);

export function useDownloadOrderFiles(order?: LabsGqlOrder) {
    const patientName = !!order ? `${order.patient.first_name} ${order.patient.last_name}` : '';
    const modified3oxzDownload = useFirebaseFileDownload(order?.scan_export.sanitized_url || '', patientName);
    const stlUrl = order?.scan_export.stl_url;
    const stlDownload = useFirebaseFileDownload(stlUrl ?? '', `${patientName} STLs`);

    const { data: downloadOrderFilesData } = useQuery(PracticeUseDownloadOrderFiles_Query, {
        variables: {
            orderId: order?.id ?? '',
        },
        skip: !order,
    });
    const latestDesign = downloadOrderFilesData?.getLatestDesignOrderDesignRevision;

    const designUrl = latestDesign?.source_file_zip_path;
    // NOTE: The design filename actually matters here, it needs to match exactly how it was extracted in order to successfully load into 3shape dental design
    const designFilename = last(designUrl?.split('/'));
    const designDownload = useFirebaseFileDownload(designUrl ?? '', designFilename);

    // TODO EPDCAD-156, add portal manufacturing download mutation
    const [reportDesignDownload] = useRecordDesignDownloadMutation({
        variables: { order_id: order?.id ?? ``, filePath: designUrl ?? '' },
    });

    const downloadDesignAndReport = () => designDownload.execute().then(() => reportDesignDownload());

    return {
        loading3oxz: modified3oxzDownload.loading,
        trigger3oxzDownload: modified3oxzDownload.execute,
        triggerStlDownload: stlUrl ? stlDownload.execute : undefined,
        loadingStl: stlDownload.loading,
        designAvailable: !!designUrl,
        triggerDesignDownload: designUrl ? downloadDesignAndReport : undefined,
        loadingDesign: designDownload.loading,
        latestDesign,
    };
}
