import { SidebarRightPageLayout } from '../../layouts/PageLayouts';
import { ScanbodiesContentRoot } from './ScanbodiesContentRoot';
import { ATTACH_SURGICAL_REPORT_PATH } from './ScanbodiesPaths';
import { ScanbodySidebarRight } from './component/ScanbodySidebarRight';
import { MobileScanbodySurgicalReportUploader } from './component/mobile/MobileScanbodySurgicalReportUploader';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

export const ScanbodiesRoot: React.FC = () => {
    const { path } = useRouteMatch();
    return (
        <Switch>
            <Route path={`${path}/${ATTACH_SURGICAL_REPORT_PATH}/:sessionId`}>
                <MobileScanbodySurgicalReportUploader />
            </Route>
            <Route path={path}>
                <SidebarRightPageLayout
                    mobileTitleHasBackButton
                    mobileTitle={'Scan Bodies'}
                    sidebarContent={<ScanbodySidebarRight />}
                >
                    <ScanbodiesContentRoot />
                </SidebarRightPageLayout>
            </Route>
        </Switch>
    );
};
