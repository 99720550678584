import type { RequireChildrenProps } from '@orthly/ui';
import { FlossPalette, stylesFactory, Chip, Grid, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useSettingsSectionStyles = stylesFactory(theme => ({
    settingsRowContainer: {
        padding: '32px 24px 0',
        background: FlossPalette.WHITE,
        [theme.breakpoints.down('sm')]: {
            padding: '24px 16px 0',
        },
    },
    settingsRow: {
        paddingBottom: '24px',
        background: FlossPalette.WHITE,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    sectionTitle: {
        paddingBottom: 8,
    },
    chevron: {
        [theme.breakpoints.up('lg')]: {
            display: 'none',
        },
    },
    subtitleText: {
        [theme.breakpoints.down('lg')]: {
            fontWeight: 'bold',
        },
    },
    requiredTag: {
        marginLeft: 8,
        backgroundColor: FlossPalette.DARK_TAN,
        borderRadius: 2,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 4,
        },
    },
    listItemText: {
        [theme.breakpoints.down('lg')]: {
            display: 'none',
        },
    },
}));

interface SettingsSectionProps extends RequireChildrenProps {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    exampleList?: React.ReactNode[];
    sectionDividedInHalf?: boolean;
    longSection?: boolean;
    settingsRowStyle?: React.CSSProperties;
    required?: boolean;
    dropDownLayout?: boolean;
    settingsRowRightSideStyle?: React.CSSProperties;
    action?: React.ReactNode;
}

export const SettingsSection: React.FC<SettingsSectionProps> = props => {
    const [showList, setShowList] = React.useState(true);
    const classes = useSettingsSectionStyles();
    const {
        title,
        subtitle,
        exampleList,
        sectionDividedInHalf,
        longSection,
        settingsRowStyle,
        required,
        dropDownLayout,
        settingsRowRightSideStyle,
        action,
    } = props;
    return (
        <Grid container className={classes.settingsRowContainer}>
            <Grid container className={classes.settingsRow} style={settingsRowStyle}>
                <Grid
                    item
                    xs={12}
                    md={sectionDividedInHalf ? 6 : 4}
                    container
                    style={{
                        minWidth: longSection ? undefined : 350,
                    }}
                >
                    <Grid item>
                        <Grid container className={classes.sectionTitle}>
                            <Text variant={'h6'}>{title}</Text>
                            {required && (
                                <Chip
                                    label={
                                        <Text variant={'caption'} medium color={'GRAY'}>
                                            Required
                                        </Text>
                                    }
                                    className={classes.requiredTag}
                                    size={'small'}
                                />
                            )}
                        </Grid>

                        {subtitle && (
                            <Text
                                variant={'body2'}
                                color={'GRAY'}
                                style={{ display: 'flex', marginBottom: dropDownLayout && !showList ? 16 : 0 }}
                                className={dropDownLayout ? classes.subtitleText : ''}
                                onClick={() => setShowList(!showList)}
                            >
                                {subtitle}
                                {dropDownLayout && (
                                    <Icon
                                        icon={'ChevronRight'}
                                        style={{
                                            alignSelf: 'flex-end',
                                            transform: showList ? `rotate(0)` : 'rotate(90deg)',
                                            transition: 'transform .3s',
                                        }}
                                        className={classes.chevron}
                                    />
                                )}
                            </Text>
                        )}
                        {action}
                        {showList && (
                            <ul style={{ paddingLeft: 16, marginTop: 8 }}>
                                {exampleList &&
                                    exampleList.map((element, index) => (
                                        <li key={index} style={{ color: FlossPalette.GRAY }}>
                                            <Text variant={'body2'} color={'GRAY'}>
                                                {element}
                                            </Text>
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    // eslint-disable-next-line no-nested-ternary
                    md={longSection ? 8 : sectionDividedInHalf ? 6 : 5}
                    container
                    style={settingsRowRightSideStyle}
                >
                    {props.children}
                </Grid>
            </Grid>
        </Grid>
    );
};
