import logo from '../../../../assets/logo/LogoDark.svg';
import { useIsChairside, usePortalToChairsideBridgeCommand } from '../../../../utils/chairside.hooks';
import { Logo, Page, PageMessage, ReturnLink } from './PageLayout.styles';
import { PanelContent } from './components/PanelContent';
import { Text, Button, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

const StyledButton = styled(Button)({
    width: 'fit-content',
    gap: '10px',
    marginLeft: 'auto',
});

export const PageLayout: React.FC<
    Parameters<typeof PanelContent>[0] & {
        pageMessage: string;
    }
> = ({ pageMessage, ...panelContentProps }) => {
    const isChairside = useIsChairside();
    const quitChairside = usePortalToChairsideBridgeCommand('quitChairside');

    return (
        <Page>
            <Logo alt={'Dandy logo'} src={logo} />
            {isChairside ? (
                <StyledButton
                    variant={'secondary'}
                    onClick={() => {
                        quitChairside?.();
                    }}
                >
                    <Icon icon={'PowerSettingsNewOutlined'} />
                    Close Dandy Chairside
                </StyledButton>
            ) : (
                <ReturnLink href={'https://www.meetdandy.com/'}>
                    <Text bold color={'PRIMARY_FOREGROUND'} variant={'body2'}>
                        Return to meetdandy.com
                    </Text>
                </ReturnLink>
            )}
            <PageMessage variant={'h1'} color={'DARK_TAN'}>
                {pageMessage}
            </PageMessage>
            <PanelContent {...panelContentProps} />
        </Page>
    );
};
