import { HelpInfoBox } from './HelpInfoBox';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

interface HelpBoxWithImageProps {
    title: string;
    subtitle: string;
    imgTitle: string;
    style?: React.CSSProperties;
}

export const HelpBoxWithImage: React.FC<HelpBoxWithImageProps> = props => {
    const { title, subtitle, imgTitle, style } = props;
    return (
        <HelpInfoBox
            header={
                <>
                    <Text variant={'h6'}>{title}</Text>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {subtitle}
                    </Text>
                </>
            }
            body={<img style={style} src={`/account/${imgTitle}.png`} alt={imgTitle} />}
        />
    );
};
