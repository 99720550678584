import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const FilterTextValue = styled(Text)({
    marginLeft: '4px',
});

const ValueText = styled(Text)({
    fontWeight: 600,
});

const BlockText = styled(Text)({
    display: 'inline-block',
    width: '120px',
    fontWeight: 600,
});

const FilterText: React.FC<{ item: string; value: string }> = ({ item, value }) => {
    return (
        <div>
            <ValueText variant={'caption'} medium color={'GRAY'}>
                {item}:
            </ValueText>
            <FilterTextValue variant={'caption'} color={'GRAY'}>
                {value}
            </FilterTextValue>
        </div>
    );
};

const StyledFilterContainer = styled('div')({
    paddingBottom: '12px',
    marginBottom: '12px',
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    display: 'flex',
    flexDirection: 'row',
});

const FilterValueContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

export const PrintableInvoiceFilter: React.FC = () => {
    const { addressIdFilter, addressFilterName, doctorNameFilter } = useInvoiceDetailContext();

    return (
        <StyledFilterContainer>
            <div>
                <BlockText variant={'caption'} color={'GRAY'}>
                    Filters on invoice
                </BlockText>
            </div>
            <FilterValueContainer>
                {doctorNameFilter && <FilterText item={'Doctor'} value={doctorNameFilter} />}
                {addressIdFilter && addressFilterName && <FilterText item={'Address'} value={addressFilterName} />}
            </FilterValueContainer>
        </StyledFilterContainer>
    );
};
