import { usePracticeAppSelector } from '../../../redux';
import { useQuery } from '@apollo/client';
import type { PracticeSidebarStatusCountQuery } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import React from 'react';

export const PracticeSidebarStatusCount_Query = graphql(`
    query PracticeSidebarStatusCount($doctorIds: [String!], $productLines: [ProductLine!]) {
        labOrderCountByStatus(doctor_ids: $doctorIds, product_lines: $productLines) {
            New
            NeedsReview
            WaxupReview
            Shipped
            Cancelled
            Delivered
            Fabrication
            OnHold
            UnshippedReturns
            InTransitReturns
            UnsubmittedScans
            All
            NeedsOrderFeedback
        }
    }
`);

export function useOrderStatusCounts(): Omit<PracticeSidebarStatusCountQuery['labOrderCountByStatus'], '__typename'> {
    const doctorIds = usePracticeAppSelector(s => s.orders.doctor_id_filter);
    const productLines = usePracticeAppSelector(s => s.orders.product_line_filter);
    const statusFilter = usePracticeAppSelector(s => s.orders.status);
    const { data, refetch } = useQuery(PracticeSidebarStatusCount_Query, {
        // poll every 5 minutes
        pollInterval: 5 * 60 * 1000,
        variables: { doctorIds, productLines },
    });
    // refetch the counts when the status filter changes. This is necessary to make sure the count matches the number
    // of orders on the screen
    React.useEffect(() => {
        void refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statusFilter]);

    const counts = data?.labOrderCountByStatus;
    return {
        New: counts?.New ?? 0,
        NeedsReview: counts?.NeedsReview ?? 0,
        WaxupReview: counts?.WaxupReview ?? 0,
        Shipped: counts?.Shipped ?? 0,
        Cancelled: counts?.Cancelled ?? 0,
        Delivered: counts?.Delivered ?? 0,
        Fabrication: counts?.Fabrication ?? 0,
        OnHold: counts?.OnHold ?? 0,
        UnshippedReturns: counts?.UnshippedReturns ?? 0,
        InTransitReturns: counts?.InTransitReturns ?? 0,
        UnsubmittedScans: counts?.UnsubmittedScans ?? 0,
        All: counts?.All ?? 0,
        NeedsOrderFeedback: counts?.NeedsOrderFeedback ?? 0,
    };
}
