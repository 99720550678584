import type {
    LabsGqlPendingAlignerCaseCrowdingResolutionInput,
    LabsGqlPendingAlignerCaseBiteConcernsInput,
} from '@orthly/graphql-schema';
import { LabsGqlBiteConcernOptions, LabsGqlCrowdingResolutionOption } from '@orthly/graphql-schema';
import type { InterproximalSpaceSize } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import _ from 'lodash';

export abstract class AlignerCheckoutDisplayUtils {
    static getTeethSelectContent(arr: number[] | null | undefined): string | undefined {
        if (arr) {
            return [...arr].sort((a, b) => a - b).join(', ');
        }
        if (arr === null) {
            return 'None';
        }
    }

    static getInterproximalSpaceSizesContent(sizes: InterproximalSpaceSize[] | null | undefined): string | undefined {
        if (sizes) {
            const filteredSizes = sizes.filter(({ size }) => size > 0);
            if (filteredSizes.length > 0) {
                return Format.pluralize('Space', filteredSizes.length);
            }
        }
        if (sizes !== undefined) {
            return 'None';
        }
    }

    static getCrowdingResolutionContent(
        crowdingResolution: Partial<LabsGqlPendingAlignerCaseCrowdingResolutionInput> = {},
    ): string | undefined {
        const { expansion, proclination, ipr } = crowdingResolution;
        if (!expansion || !proclination || !ipr) {
            return undefined;
        }
        const selected = _.compact([
            expansion === LabsGqlCrowdingResolutionOption.OnlyIfNeeded ? 'expansion' : undefined,
            proclination === LabsGqlCrowdingResolutionOption.OnlyIfNeeded ? 'proclination' : undefined,
            ipr === LabsGqlCrowdingResolutionOption.OnlyIfNeeded ? 'IPR' : undefined,
        ]);
        if (!selected.length) {
            return 'None';
        }
        return `By ${selected.join(', ')} only if needed`;
    }

    static getBiteConcernsContent(biteConcerns: LabsGqlPendingAlignerCaseBiteConcernsInput): string | undefined {
        const { improve_overjet, improve_overbite, improve_midline } = biteConcerns;
        const selected = _.compact([
            improve_overjet === LabsGqlBiteConcernOptions.Improve ? 'Overjet' : undefined,
            improve_overbite === LabsGqlBiteConcernOptions.Improve ? 'Overbite' : undefined,
            improve_midline === LabsGqlBiteConcernOptions.Improve ? 'Midline' : undefined,
        ]);
        if (!selected.length) {
            return 'None';
        }
        return `Improve ${selected.join(', ')}`;
    }
}
