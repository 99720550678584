import { PricingGuide } from '../../account/practice-settings/pricing-guide/PricingGuide';
import { useOpenOrderDetailPage } from '../LabsUtils';
import { AddAttachmentAction } from '../actions/AddAttachmentAction';
import { AddExistingScanAction } from '../actions/AddExistingScanAction';
import { ChatAction } from '../actions/ChatAction';
import { ExploreHelpCenterButton } from '../actions/ExploreHelpCenterButton';
import { AddNewScanAction } from '../actions/practitioners/AttachNewScanAction';
import { CancelOrderButton } from '../actions/practitioners/CancelOrder/CancelOrderButton';
import { CancelOrderDialog } from '../actions/practitioners/CancelOrder/CancelOrderDialog';
import { DownloadScanAction } from '../actions/practitioners/DownloadScanAction';
import { PauseOrResumeOrderAction } from '../actions/practitioners/PauseOrResumeOrder/PauseOrResumeOrderAction';
import {
    ProvideOrderFeedbackButton,
    RefabricateOrderButton,
} from './components/AskDoctorResponse/DeliveredOrderActions';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrderRefetch } from '@orthly/graphql-react';
import { QueryString } from '@orthly/ui';
import { FlossPalette, LightningBolt, Text, styled } from '@orthly/ui-primitives';
import { OrderEditActionButton, OrderEditActionDialog } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const RightPanelContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
    backgroundColor: FlossPalette.TAN,
    height: '100%',
});

const TopBodyContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const TitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gridGap: '4px',
    padding: '20px 32px',
});

const MainTitleContainer = styled('div')({
    display: 'flex',
    gridGap: '4px',
});

const ButtonContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gridGap: '16px',
    padding: '16px 24px',
});

const FooterContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
});

const FooterText = styled(Text)({
    padding: '0px 36px',
});

const FooterButtonContainer = styled('div')({
    padding: '0px 24px 16px 24px',
    display: 'flex',
    flexDirection: 'column',
});

interface OrderDetailRightPanelProps {
    order: LabsGqlOrder;
}

export const OrderDetailRightPanel: React.FC<OrderDetailRightPanelProps> = ({ order }) => {
    const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
    const [isCancelOrderDialogOpen, setIsCancelOrderDialogOpen] = React.useState(false);
    const openOrder = useOpenOrderDetailPage();
    const refetchOrder = useOrderRefetch();

    const history = useHistory();
    let action = QueryString.parse(history.location.search).action;
    action = action?.toLowerCase();

    React.useEffect(() => {
        if (action) {
            //if url param ?action=edit is present, open the edit modal
            if (action === 'edit' && order.can_edit) {
                history.replace(history.location.pathname);
                setIsEditDialogOpen(true);
            }
            //if url param ?action=cancel is present, open the edit modal
            if (action === 'cancel' && order.can_cancel) {
                history.replace(history.location.pathname);
                setIsCancelOrderDialogOpen(true);
            }
        }
    }, [action, order, history]);

    return (
        <>
            <RightPanelContainer data-pendo-id={'order-detail-right-panel'}>
                <TopBodyContainer>
                    <TitleContainer>
                        <MainTitleContainer>
                            <LightningBolt />
                            <Text bold variant={'body1'} color={'BLACK'}>
                                Quick order actions
                            </Text>
                        </MainTitleContainer>
                        <Text variant={'body2'} color={'GRAY'}>
                            Make changes to your order instantly
                        </Text>
                    </TitleContainer>
                    <ButtonContainer>
                        <OrderEditActionButton
                            disableInsteadOfHide
                            order={order}
                            buttonVariant={'secondary'}
                            leftAlign
                            setEditOpen={setIsEditDialogOpen}
                            buttonLabel={'Modify RX & instructions'}
                        />
                        <AddNewScanAction
                            fullWidth
                            leftAlign
                            buttonVariant={'secondary'}
                            order={order}
                            refetch={() => {
                                refetchOrder(order.id);
                            }}
                        />
                        <AddAttachmentAction
                            order={order}
                            buttonVariant={'secondary'}
                            leftAlign
                            refetch={() => {
                                refetchOrder(order.id);
                            }}
                        />
                        <AddExistingScanAction order={order} buttonVariant={'secondary'} leftAlign fullWidth />
                        <DownloadScanAction
                            order={order}
                            buttonVariant={'secondary'}
                            leftAlign
                            showDropdownIcon
                            fullWidth
                        />
                        <RefabricateOrderButton
                            leftAlign
                            padded
                            icon={'RefreshIcon'}
                            buttonVariant={'secondary'}
                            orderId={order.id}
                            canRefabricate={order.can_refabricate}
                            analyticsLocation={'order details - quick order actions'}
                        />
                        <ProvideOrderFeedbackButton
                            leftAlign
                            padded
                            icon={'ThumbsUpIcon'}
                            buttonVariant={'secondary'}
                            orderId={order.id}
                            canSubmitFeedback={order.can_submit_feedback}
                            analyticsLocation={'order details - quick order actions'}
                        />
                        <PauseOrResumeOrderAction
                            leftAlign
                            buttonVariant={'secondary'}
                            order={order}
                            refetch={() => {
                                refetchOrder(order.id);
                            }}
                        />
                        <CancelOrderButton
                            order={order}
                            buttonVariant={'alert-secondary'}
                            leftAlign
                            setOpen={setIsCancelOrderDialogOpen}
                        />
                    </ButtonContainer>
                </TopBodyContainer>

                <FooterContainer>
                    <FooterText variant={'body2'} color={'GRAY'} bold>
                        Still need help?
                    </FooterText>
                    <FooterButtonContainer>
                        <ExploreHelpCenterButton leftAlign />
                        <ChatAction order={order} />
                    </FooterButtonContainer>
                </FooterContainer>
            </RightPanelContainer>
            <OrderEditActionDialog
                order={order}
                InjectedPricingGuideComponent={<PricingGuide isModal={true} />}
                openOrder={openOrder}
                isEditOpen={isEditDialogOpen}
                setEditOpen={setIsEditDialogOpen}
            />
            <CancelOrderDialog
                order={order}
                setOpen={setIsCancelOrderDialogOpen}
                open={isCancelOrderDialogOpen}
                refetch={() => {
                    refetchOrder(order.id);
                }}
            />
        </>
    );
};
