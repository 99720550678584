import { useAddPhoneNumberValidation } from '../../../../../components/forms/ConfirmPhoneNumberForm/useAddPhoneNumberValidation';
import { useGetUserFromDrPrefId } from '../../../../../graphql/useGetUserFromDrPrefId.graphql';
import { useSendOneTimeCode } from '../../../../../graphql/useSendOneTimeCode.graphql';
import { useUserExistsWithMobileNumber } from '../../../../../graphql/useUserExistsWithMobileNumber.graphql';
import { usePracticeStaffControls } from '../../../state/usePracticeStaffControls';
import { useVerifyLoginPhoneNumber } from '../graphql/useVerifyLoginPhoneNumber.graphql';
import React from 'react';

// Profile settings Page
export const useVerifyPhoneNumber = ({
    setShowConfirmationModal,
    updatePrefCall,
    accountPhone,
}: {
    setShowConfirmationModal: (value: boolean) => void;
    updatePrefCall: () => void;
    accountPhone: string;
}) => {
    const [confirmationCode, setConfirmationCodeState] = React.useState('');
    const [submittedPhoneNumber, setSubmittedPhoneNumberState] = React.useState('');
    const [userFoundError, setUserFoundError] = React.useState('');

    const { employeePrefs } = usePracticeStaffControls() ?? {};
    const drPrefId = employeePrefs?.id ?? '';
    const { user } = useGetUserFromDrPrefId(drPrefId);

    const {
        submit: sendCode,
        submitting: submittingSendCode,
        error: sendCodeError,
    } = useSendOneTimeCode({ onSuccess: () => setSubmittedPhoneNumberState(accountPhone) });

    const {
        submit: verifyPhone,
        submitting: submittingVerifyPhone,
        error: verifyPhoneError,
    } = useVerifyLoginPhoneNumber({
        onSuccess: () => {
            setShowConfirmationModal(false);
            updatePrefCall();
        },
    });

    const {
        submit: checkUserExists,
        loading: loadingUserExists,
        error: userExistsError,
    } = useUserExistsWithMobileNumber({
        onSuccess: userExists => {
            if (userExists) {
                setUserFoundError('This phone number is already in use.');
            } else {
                void sendCode(accountPhone);
                setShowConfirmationModal(true);
            }
        },
    });

    const {
        isFormValid,
        phoneNumberError,
        confirmationCodeError,
        setShowPhoneNumberError,
        setShowConfirmationCodeError,
    } = useAddPhoneNumberValidation({
        phoneNumber: accountPhone,
        confirmationCode,
        submittedPhoneNumber,
    });

    const setConfirmationCode = (next = '') => {
        setConfirmationCodeState(next.slice(0, 6));
    };

    const sendConfirmationCode = () => {
        void checkUserExists(accountPhone);
    };

    const verifyPhoneNumber = () => {
        void verifyPhone(confirmationCode, accountPhone, user?.id ?? '');
    };

    return {
        submittedPhoneNumber,
        confirmationCode,
        setConfirmationCode,

        phoneNumberError,
        confirmationCodeError,
        setShowPhoneNumberError,
        setShowConfirmationCodeError,

        verifyPhoneNumber,
        submittingVerifyPhone,
        verifyPhoneError,

        sendConfirmationCode,
        submittingSendCode,
        userExistsError,
        userFoundError,
        formError: sendCodeError || userFoundError,
        shouldContinue: !loadingUserExists && !submittingVerifyPhone && !submittingSendCode && isFormValid, // shouldContinue: true,
    };
};
