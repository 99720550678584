import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import type { CheckoutItemV2WithImplant } from '../../../state/reducers/checkout.state';
import { CheckoutItemSelectField } from './CheckoutItemSelectField';
import { LabsGqlOrderItemLinkRelationship } from '@orthly/graphql-schema';
import { CartItemV2Utils } from '@orthly/items';
import { Grid, Text, Typography } from '@orthly/ui-primitives';
import { AutocompleteImplantSystemInput } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

// TODO: we should update items v2 to use connection instead of connection_size like everywhere else does
type UpdateImplantMetaFieldName = 'relationship' | 'manufacturer' | 'system' | 'connection';
type CartItemImplantFieldName = 'relationship' | 'manufacturer' | 'system' | 'connection_size';
function convertFieldName(name: UpdateImplantMetaFieldName): CartItemImplantFieldName {
    if (name === 'connection') {
        return 'connection_size';
    }
    return name;
}

interface LinkRelationshipFieldProps {
    value?: string;
    onChange: (val?: string) => void;
}

const LinkRelationshipField: React.FC<LinkRelationshipFieldProps> = ({ value, onChange }) => {
    return (
        <CheckoutItemSelectField
            value={value}
            onChange={onChange}
            label={'Retention'}
            forceDropdownSelect={false}
            required={true}
            containerStyle={{ paddingTop: 0 }}
            options={Object.values(LabsGqlOrderItemLinkRelationship).map(option => ({
                value: option,
                label: _.startCase(option),
            }))}
        />
    );
};

function useImplantMetadata(item: CheckoutItemV2WithImplant) {
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');
    const implantMeta = React.useMemo(() => CartItemV2Utils.getImplantMetadata(item) ?? {}, [item]);

    const updateField = React.useCallback(
        (fieldName: UpdateImplantMetaFieldName, value?: string | null) => {
            const propertyName = convertFieldName(fieldName);
            updateItem({
                item_index: item.item_index,
                change: { name: 'implant_metadata', payload: { [propertyName]: value } },
            });
        },
        [item.item_index, updateItem],
    );

    return { implantMeta, updateField };
}

interface ImplantRelationshipFieldsContentProps {
    item: CheckoutItemV2WithImplant;
}

const ImplantRelationshipFieldsContent: React.FC<ImplantRelationshipFieldsContentProps> = ({ item }) => {
    const { implantMeta, updateField } = useImplantMetadata(item);

    return (
        <Grid container style={{ position: 'relative', padding: '16px 0' }}>
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Text style={{ width: '100%', fontWeight: 500 }}>Implant Details</Text>
                <Typography style={{ width: '100%' }} color={'textSecondary'} variant={'caption'}>
                    Attention: Please confirm or edit these details to ensure your implant is manufactured correctly
                </Typography>
            </Grid>
            <AutocompleteImplantSystemInput
                linkMeta={{
                    manufacturer: implantMeta.manufacturer ?? null,
                    system: implantMeta.system ?? null,
                    connection: implantMeta.connection_size ?? null,
                }}
                updateField={updateField}
                LinkRelationshipField={
                    <LinkRelationshipField
                        value={implantMeta.relationship ?? undefined}
                        onChange={val => updateField('relationship', val)}
                    />
                }
            />
        </Grid>
    );
};

export const ImplantRelationshipFields: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    if (!CartItemV2Utils.itemTypeHasImplant(item)) {
        return null;
    }
    return <ImplantRelationshipFieldsContent item={item} />;
};
