import { useCheckoutPropSelector, useAlignerCheckoutSelector } from '../../../../redux';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { AlignerEstimatingStatus, CheckoutBodyWrapper } from '@orthly/dentin';
import { usePendingAlignerCaseForScanExportQuery } from '@orthly/graphql-react';
import { AlignerEstimateStep } from '@orthly/shared-types';
import React from 'react';

function useListenForEstimate(scanId: string) {
    const onNext = useAlignerCheckoutAction('NEXT_ALIGNER_STEP');
    const setAlignerEstimate = useAlignerCheckoutAction('SET_ALIGNER_ESTIMATE');
    const setAlignerRejection = useAlignerCheckoutAction('SET_ALIGNER_REJECTION');

    const query = usePendingAlignerCaseForScanExportQuery({
        variables: { scan_export_id: scanId },
        pollInterval: 5000,
        onCompleted: data => {
            const { estimate, rejection } = data?.pendingAlignerCaseForScanExport ?? {};
            if (!estimate && !rejection) {
                return;
            }

            if (estimate) {
                setAlignerEstimate(estimate);
                onNext();
            } else if (rejection) {
                setAlignerRejection(rejection);
            }

            query.stopPolling();
        },
        notifyOnNetworkStatusChange: true,
    });
}

// this component is separate from the screen component below since it allows us to more easily
// control polling and auto-progression with conditional rendering
const AlignerCheckoutEstimating: React.VFC<{ scanId: string }> = ({ scanId }) => {
    const setAlignerEstimateStep = useAlignerCheckoutAction('SET_ALIGNER_ESTIMATE_STEP');
    const { patient_first_name } = useCheckoutPropSelector(['patient_first_name']);
    const activeEstimateStep = useAlignerCheckoutSelector(s => s.estimateStep ?? AlignerEstimateStep.AnalyzingScan);

    useListenForEstimate(scanId);

    return (
        <AlignerEstimatingStatus
            patientFirstName={patient_first_name ?? ''}
            activeStep={activeEstimateStep}
            setActiveStep={setAlignerEstimateStep}
        />
    );
};

export const AlignerCheckoutEstimatingScreen: React.VFC = () => {
    const { scan } = useCheckoutPropSelector(['scan']);
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.Estimating);
    if (!isActiveStep || !scan) {
        return null;
    }

    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <AlignerCheckoutEstimating scanId={scan.id} />
        </CheckoutBodyWrapper>
    );
};
