import { useNextStep } from './useNextStep';
import { useMutation } from '@apollo/client';
import type { CreatePassword_MutationMutationVariables } from '@orthly/graphql-inline-react';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';

const CreatePassword_Mutation = graphql(`
    mutation CreatePassword_Mutation($args: CreatePasswordArgs!) {
        createPassword(args: $args) {
            user_id
        }
    }
`);

export function useCreatePassword() {
    const [submitMtn] = useMutation(CreatePassword_Mutation);
    const goToNextStep = useNextStep();

    const mtnSubmitter = ({ args }: CreatePassword_MutationMutationVariables) => {
        const { password, preferences_id, email } = args;
        return submitMtn({
            variables: {
                args: {
                    password,
                    preferences_id,
                    email,
                },
            },
        });
    };
    const { submit, submitting } = useChangeSubmissionFn(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Successfully added user to organization!', {}],
        onSuccess: goToNextStep,
        // error state is handled by generic error handling in useChangeSubmissionFn
    });

    return { submit, submitting };
}
