import type { SimpleSelectOption } from '../../SimpleForm';
import { SimpleMultiSelect } from '../../SimpleForm';
import type { FieldDefMultiSelect, FieldDefSelect, FieldLayout } from '../QuickForm.types';
import { FormikRootField, FormikSelect } from '../QuickForm.types';
import { QFHelperText } from './QFHelperText';
import { Format } from '@orthly/runtime-utils';
import { FormControl, MenuItem, Grid } from '@orthly/ui-primitives';
import { getIn } from 'formik';
import _ from 'lodash';
import React from 'react';

function useSortedQFSelectOptions(
    options: (SimpleSelectOption | string)[],
    disableSortOptions: boolean,
): SimpleSelectOption[] {
    return React.useMemo(() => {
        const simpleSelectOptions = options.map<SimpleSelectOption>(opt =>
            typeof opt === 'string' ? { value: opt, label: Format.labelForSelectValue(opt) } : opt,
        );
        if (disableSortOptions) {
            return simpleSelectOptions;
        }
        return _.sortBy(simpleSelectOptions, o => o.label || o.value);
    }, [options, disableSortOptions]);
}

interface QFSelectFieldProps {
    field: FieldDefSelect & { name: string };
}

export const QFSelectField: React.FC<QFSelectFieldProps> = ({ field }) => {
    const commonProps = { name: field.name, label: field.label || _.startCase(field.name) };
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    const variant = field.fieldProps?.variant || 'standard';
    const sortedOptions = useSortedQFSelectOptions(field.options, !!field.disableSortOptions);
    const {
        InputLabelProps: inputLabelProps,
        FormHelperTextProps: formHelperTextProps,
        ...mainFieldProps
    } = field.fieldProps ?? {};
    return (
        <Grid item {...layout}>
            <FormControl style={{ width: '100%', display: field.hidden ? 'none' : undefined }} variant={variant}>
                <FormikSelect
                    disableUnderline
                    fullWidth
                    data-test={`quick-form-field-${field.name}`}
                    {...mainFieldProps}
                    inputProps={{ name: field.name }}
                    inputLabel={{ required: !field.optional, ...inputLabelProps }}
                    {...commonProps}
                    variant={variant}
                >
                    {field.optional && (
                        <MenuItem value={undefined}>
                            <em>Clear Selection</em>
                        </MenuItem>
                    )}
                    {sortedOptions.map(opt => (
                        <MenuItem key={opt.value} value={opt.value} disabled={opt.disabled}>
                            {opt.label || opt.value}
                        </MenuItem>
                    ))}
                </FormikSelect>
                <QFHelperText text={field.helperText} style={formHelperTextProps?.style} />
            </FormControl>
        </Grid>
    );
};

interface QFMultiSelectFieldProps {
    field: FieldDefMultiSelect & { name: string };
}

export const QFMultiSelectField: React.FC<QFMultiSelectFieldProps> = ({ field }) => {
    const commonProps = { name: field.name, label: field.label || _.startCase(field.name) };
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    const variant = field.fieldProps?.variant || 'standard';
    const sortedOptions = useSortedQFSelectOptions(field.options, !!field.disableSortOptions);
    return (
        <Grid item {...layout}>
            <FormikRootField
                name={field.name}
                render={formikProps => {
                    const fieldError = getIn(formikProps.form.errors, field.name);
                    const fieldTouched = getIn(formikProps.form.touched, field.name);
                    return (
                        <SimpleMultiSelect
                            label={commonProps.label}
                            variant={variant}
                            options={sortedOptions}
                            data-test={`quick-form-field-${field.name}`}
                            {...field.fieldProps}
                            value={formikProps.field.value || []}
                            onChange={value => {
                                formikProps.form.setFieldValue(field.name, value || []);
                            }}
                            errorText={typeof fieldError === 'string' && fieldTouched ? fieldError : undefined}
                            helperText={field.helperText}
                            SelectProps={{
                                ...field.fieldProps?.SelectProps,
                                disableUnderline: true,
                                onClose: () => {
                                    !fieldTouched && formikProps.form.setFieldTouched(field.name, true);
                                },
                                variant: 'standard',
                            }}
                        />
                    );
                }}
            />
        </Grid>
    );
};
