import { practiceAnalyticsMiddleware } from './analytics/practice-analytics.middleware';
import type { PartnerAppState } from './redux';
import { rootReducer } from './redux';
import * as Sentry from '@sentry/react';
import { routerMiddleware } from 'connected-react-router';
import type { History } from 'history';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

export const initRedux = (history: History, initialState: Partial<PartnerAppState> = {}) => {
    const isDev = process.env.NODE_ENV === 'development' || process.env.REACT_APP_STAGING === 'true';
    const composeEnhancers =
        (isDev &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                trace: false,
                traceLimit: 5,
            })) ||
        compose;
    const middleware = [routerMiddleware(history), thunk];
    if (!!window.analytics) {
        middleware.unshift(practiceAnalyticsMiddleware);
    }

    const store = createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(...middleware), Sentry.createReduxEnhancer()),
    );

    return store;
};

// we export singletons so that we use the same store across the entire app
export const reduxHistory = createBrowserHistory();
export const store = initRedux(reduxHistory);

export const storeWithInitialState = (initialState: any) => initRedux(reduxHistory, initialState);

if (window.Cypress || process.env.REACT_APP_NODE_ENV === 'test') {
    window.store = store;
}
