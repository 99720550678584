import type { PatientFormPatient, PatientFormProps } from '@orthly/dentin';
import { PatientForm } from '@orthly/dentin';
import type { LabsGqlCreatePatientMutationVariables } from '@orthly/graphql-operations';
import { useCreatePatientMutation, useListPracticePatientsQuery } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

interface ConnectedPatientFormProps
    extends Omit<PatientFormProps, 'onPatientEdited' | 'existingPatients' | 'onNewPatientCreated'> {
    onNewPatientCreated: (patient: PatientFormPatient) => void;
}

type Vars = LabsGqlCreatePatientMutationVariables['data'];

export const ConnectedPatientForm: React.VFC<ConnectedPatientFormProps> = props => {
    const { data: existingPatientsData } = useListPracticePatientsQuery();
    const [createPatientMtn] = useCreatePatientMutation();

    const { submit } = useChangeSubmissionFn(
        (data: Vars) =>
            createPatientMtn({
                variables: { data: { ...data } },
            }),
        {
            successMessage: () => ['Patient Created'],
            closeOnComplete: true,
        },
    );

    // Make our own function work with the type from PatientForm
    const onNewPatientCreated = async (patient: Omit<PatientFormPatient, 'id'>) => {
        const createdPatient = await submit(patient);
        createdPatient.data?.createPatient && props.onNewPatientCreated(createdPatient.data.createPatient);
    };

    return (
        <PatientForm
            {...props}
            onNewPatientCreated={onNewPatientCreated}
            existingPatients={existingPatientsData?.listPracticePatients ?? []}
        />
    );
};
