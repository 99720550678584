import type { LabColor } from '@orthly/ui-primitives';
import { FlossPalette, Icon, WarningIcon, Alert } from '@orthly/ui-primitives';
import React from 'react';

interface ProfileSectionAlertProps {
    message: React.ReactNode;
    severity: Exclude<LabColor, 'info'>;
}

export const ProfileSectionAlert: React.FC<ProfileSectionAlertProps> = props => {
    const bgColor = React.useMemo(
        () =>
            ({
                error: FlossPalette.ATTENTION,
                warning: FlossPalette.MATERIAL_YELLOW,
                success: FlossPalette.STAR_GRASS,
            })[props.severity],
        [props.severity],
    );
    const textColor = React.useMemo(
        () =>
            ({
                error: FlossPalette.TAN,
                warning: FlossPalette.BLACK,
                success: FlossPalette.TAN,
            })[props.severity],
        [props.severity],
    );
    return (
        <Alert
            severity={props.severity}
            style={{ width: '100%', marginBottom: 10, color: textColor, backgroundColor: bgColor }}
            iconMapping={{
                error: <Icon icon={'Report'} style={{ color: FlossPalette.TAN }} />,
                warning: <WarningIcon style={{ color: FlossPalette.BLACK }} />,
                success: <Icon icon={'CheckCircleIcon'} style={{ color: FlossPalette.TAN }} />,
            }}
        >
            <b>{props.message}</b>
        </Alert>
    );
};
