import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { useCheckoutSelector } from '../../../../redux';
import { ATTACH_IMPLANT_XRAY_PHOTO_PATH } from '../../../labs/LabsPaths';
import { CheckoutLayoutBottom } from '../../components/CheckoutLayout/CheckoutLayoutBottom';
import { ImageUploadTypeSelect } from '../../components/PhotoUpload/ImageUploadTypeSelect';
import {
    implantCheckoutComplete,
    implantCheckoutEnabled,
    implantCheckoutVisible,
} from '../../state/checkout.selectors';
import { useImplantCheckoutAction } from '../../state/implant-checkout.actions';
import { useImplantCheckoutPropSelector, useImplantCheckoutState } from '../../state/implant-checkout.selectors';
import { ScanbodySourceScreen } from './ScanbodySource';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, Collapse, Grid, Text } from '@orthly/ui-primitives';
import type { UploadedPhotosState } from '@orthly/veneer';
import { PhotoUpload, useUploadedPhotoState, implantPhotoUploadConfig } from '@orthly/veneer';
import React from 'react';

interface ImplantCheckoutButtonsProps {
    uploadState: UploadedPhotosState;
}

const ImplantCheckoutButtons: React.FC<ImplantCheckoutButtonsProps> = ({
    uploadState: { isUploadComplete, uploadedPhotos, numUploadedPhotos },
}) => {
    const goToPrevStep = useImplantCheckoutAction('PREV_IMPLANT_STEP');
    const tryGoToNextStep = useImplantCheckoutAction('TRY_NEXT_IMPLANT_STEP');
    const setMissingUploadWarning = useImplantCheckoutAction('SET_MISSING_UPLOAD_WARNING');

    const { missingUploadWarningShowing } = useImplantCheckoutState();

    const onClickBack = React.useCallback(() => {
        goToPrevStep({ numUploadedPhotos });
    }, [goToPrevStep, numUploadedPhotos]);

    const onClickNext = React.useCallback(() => {
        tryGoToNextStep({ isUploadComplete, numUploadedPhotos });
    }, [tryGoToNextStep, isUploadComplete, numUploadedPhotos]);

    // if the user finishes the mobile upload after the missing/incomplete upload warning shows, automatically hide it
    React.useEffect(() => {
        if (missingUploadWarningShowing && isUploadComplete) {
            setMissingUploadWarning(false);
        }
    }, [setMissingUploadWarning, missingUploadWarningShowing, isUploadComplete]);

    return (
        <LoadBlocker blocking={!uploadedPhotos} ContainerProps={{ style: { zIndex: 99 } }}>
            <Collapse in={missingUploadWarningShowing}>
                <Grid
                    container
                    style={{ background: FlossPalette.ATTENTION_BG, padding: 18, marginTop: 50 }}
                    alignItems={'center'}
                >
                    <Text variant={'h6'} style={{ fontWeight: 400 }}>
                        Sending along an x-ray of the seated scan body and a picture of the surgical report help our
                        team design and manufacture each implant correctly. To skip anyway, click "Skip".
                    </Text>
                </Grid>
            </Collapse>
            <CheckoutLayoutBottom
                nextButtonWarning={missingUploadWarningShowing}
                hideBack={false}
                onNextClick={onClickNext}
                backButtonProps={{ onClick: onClickBack }}
                backButtonText={'Back'}
                nextButtonText={missingUploadWarningShowing ? 'Skip' : 'Next'}
            />
        </LoadBlocker>
    );
};

interface ImplantImagesBodyProps {
    uploadState: UploadedPhotosState;
}

const ImplantImagesBody: React.FC<ImplantImagesBodyProps> = ({ uploadState }) => {
    const startUpload = useImplantCheckoutAction('START_UPLOAD');
    const checkInstructions = useImplantCheckoutAction('CHECK_INSTRUCTIONS');

    const { screen, allowInstructions } = useImplantCheckoutPropSelector(['screen', 'allowInstructions']);

    const scanID = useCheckoutSelector(s => s.scan?.id);

    switch (screen) {
        case 'instructions':
            return (
                <ImageUploadTypeSelect
                    numUploadedPhotos={uploadState.numUploadedPhotos}
                    allowInstructions={allowInstructions}
                    onMobilePhotoUpload={() => {
                        startUpload();
                    }}
                    onClickDesktop={() => {
                        startUpload();
                    }}
                    head={{
                        text: 'The surgical report and an x-ray of the seated scan body help us double-check our design.',
                    }}
                    mobileUrl={scanID => `${window.location.origin}/lab/${ATTACH_IMPLANT_XRAY_PHOTO_PATH}/${scanID}`}
                />
            );
        case 'upload':
            return (
                <PhotoUpload
                    uploadConfig={implantPhotoUploadConfig}
                    uploadState={uploadState}
                    viewInstructionsOptions={{
                        showButton: true,
                        onClick: checkInstructions,
                    }}
                    scanID={scanID}
                    isDesktop={true}
                    onUpload={() => {
                        AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                            productName: implantPhotoUploadConfig.firestoreProductName,
                            isMobile: false,
                        });
                    }}
                />
            );
        case 'scanbodies':
            return <ScanbodySourceScreen numUploadedPhotos={uploadState.numUploadedPhotos} />;
    }
};

const ImplantCheckoutContent: React.FC = () => {
    const isComplete = useCheckoutSelector(implantCheckoutComplete);
    const visible = useCheckoutSelector(implantCheckoutVisible);
    const scanID = useCheckoutSelector(s => s.scan?.id);
    const uploadState = useUploadedPhotoState(
        implantPhotoUploadConfig,
        scanID,
        implantPhotoUploadConfig.firestoreProductName,
    );

    return (
        <>
            <CheckoutBodyWrapper visible={visible} isComplete={isComplete}>
                {visible && <ImplantImagesBody uploadState={uploadState} />}
            </CheckoutBodyWrapper>
            {visible && <ImplantCheckoutButtons uploadState={uploadState} />}
        </>
    );
};

export const ImplantCheckout: React.FC = () => {
    const enabled = useCheckoutSelector(implantCheckoutEnabled);
    if (!enabled) {
        return null;
    }
    return <ImplantCheckoutContent />;
};
