import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { LabsGqlWhoReceivesDoctorNotifications } from '@orthly/graphql-schema';
import type { QuickFormCustomSubmitProps } from '@orthly/ui';
import type React from 'react';

export interface NotificationPreferencesSectionProps {
    doctor: LabsGqlDoctorPreferencesFragment;
}

export function contactOptionsForDoctor(doctor: LabsGqlDoctorPreferencesFragment): {
    value: LabsGqlWhoReceivesDoctorNotifications;
    label: string;
}[] {
    return [
        {
            value: LabsGqlWhoReceivesDoctorNotifications.Dr,
            label: doctor.name ? `Me (${doctor.formatted_name})` : `Me`,
        },
        { value: LabsGqlWhoReceivesDoctorNotifications.Assistant, label: 'My assistant' },
        { value: LabsGqlWhoReceivesDoctorNotifications.Admin, label: 'Office administrator' },
        { value: LabsGqlWhoReceivesDoctorNotifications.Other, label: 'Other' },
    ];
}

export interface MultipartFormSingleStepProps<T> {
    formState: Partial<T>;
    setFormState: (values: Partial<T>) => void;
    onSubmit: (values: T) => void | Promise<void>;
    customSubmit: React.ComponentType<QuickFormCustomSubmitProps>;
}
