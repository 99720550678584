import { useDoctorId } from '../../state/useDoctorId';
import { PracticeScreen } from '@orthly/dentin';
import { useHistory } from 'react-router-dom';

export const useNextStep = () => {
    const history = useHistory();
    const prefId = useDoctorId();

    return () => history.push(`${PracticeScreen.add_phone}/?prefId=${prefId}`);
};
