import ReturnInstructionsImage from '../../../assets/try_in_feedback_packing_instructions.svg';
import { TryInFeedbackScreenLayout } from './layout/TryInFeedbackScreenLayout';
import { RootActionDialog } from '@orthly/ui';
import { Grid, FlossPalette, Icon, Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    bulletNum: {
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

interface TryInFeedbackReturnInstructionBulletProps {
    bulletNum: number;
    title: string | React.ReactNode;
    subtitle: string;
    titleAction?: React.ReactNode;
}

const TryInFeedbackReturnInstructionBullet: React.VFC<TryInFeedbackReturnInstructionBulletProps> = ({
    bulletNum,
    title,
    subtitle,
    titleAction,
}) => {
    const classes = useStyles();

    return (
        <Grid item>
            <Grid container spacing={3} wrap={'nowrap'}>
                <Grid item>
                    <div className={classes.bulletNum}>
                        <Text variant={'body2'} color={'GREEN'} component={'span'} medium>
                            {bulletNum}
                        </Text>
                    </div>
                </Grid>
                <Grid item>
                    <Grid container direction={'column'} spacing={1}>
                        <Grid item>
                            <Text variant={'body2'} medium>
                                {title}
                            </Text>
                            {titleAction}
                        </Grid>
                        <Grid item>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                {subtitle}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

const TryInFeedbackReturnExampleModal: React.VFC = () => {
    const [open, setOpen] = React.useState<boolean>(false);

    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            loading={false}
            title={'How to pack your box'}
            showCloseButton={true}
            CustomButton={({ onClick }) => (
                <Text
                    variant={'body2'}
                    color={'GREEN'}
                    medium
                    component={'span'}
                    onClick={onClick}
                    style={{ cursor: 'pointer', marginLeft: 8, display: 'inline-flex' }}
                >
                    See an example <Icon icon={'HelpIcon'} style={{ marginLeft: 4 }} />
                </Text>
            )}
            content={<img src={ReturnInstructionsImage} />}
            // width of image plus 40 padding on each side
            PaperProps={{ style: { maxWidth: 701 } }}
        />
    );
};

export const TryInFeedbackReturnInstructionsContent: React.VFC = () => {
    return (
        <Grid container direction={'column'} spacing={3}>
            <TryInFeedbackReturnInstructionBullet
                bulletNum={1}
                title={'Prepare the partial denture try-in'}
                subtitle={
                    'Place the partial denture on its model. For added protection, slip each arch separately into bubble sleeves.'
                }
            />
            <TryInFeedbackReturnInstructionBullet
                bulletNum={2}
                title={
                    <>
                        Pack your box <TryInFeedbackReturnExampleModal />
                    </>
                }
                subtitle={`Place the bottom foam in the box base, then add the bubble-wrapped partial. Top with foam to secure during transit. Don't forget the order form.`}
            />
            <TryInFeedbackReturnInstructionBullet
                bulletNum={3}
                title={'Seal your box and attach return label'}
                subtitle={
                    'Retrieve the return label from the order page, assemble the mailer, and attach the return label to it. Then, seal the box securely with tape to avoid accidental openings.'
                }
            />
            <TryInFeedbackReturnInstructionBullet
                bulletNum={4}
                title={'Drop-off'}
                subtitle={
                    'Take your sealed box to the nearest carrier drop-off location. Ensure you receive a receipt or tracking number for your shipment.'
                }
            />
        </Grid>
    );
};

export const TryInFeedbackReturnInstructions: React.VFC = () => {
    return (
        <TryInFeedbackScreenLayout
            header={`After submitting your feedback, ship the try-in back to us to continue with your case. Here's how:`}
        >
            <TryInFeedbackReturnInstructionsContent />
        </TryInFeedbackScreenLayout>
    );
};
