import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import DandyRecommended from '@orthly/dentin/assets/images/hd-skus/Dandy_Recommended_Upsell.png';

export const DDPWebinarConfig: InboxUpsellItemConfigProps = {
    assetName: '202407 DDP Webinar',
    title: 'Discover how to markup your design previews',
    subtitle:
        'Join for our July 11 webinar and learn the tips and markup tools to communicate your design notes with the lab.',
    ctaText: 'Register now',
    destination: {
        url: 'https://www.meetdandy.com/learning-center/webinars/digital-design-previews-and-markup-tools/',
    },
    imgSrc: DandyRecommended,
    imgStyle: { maxHeight: 100, bottom: 30, left: -16, position: 'relative' },
    skuType: 'ddp-webinar',
    filterCriteria: null,
    featureFlagKey: 'upsellDDPWebinar',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
};
