import { CollapsedQuickFormSubmit } from '../../components/CollapsedQuickFormSubmit';
import { usePracticeStaffControls } from '../../state/usePracticeStaffControls';
import type { LabsGqlSetDoctorProfilePhotoMutationVariables } from '@orthly/graphql-operations';
import { useSetDoctorProfilePhotoMutation } from '@orthly/graphql-react';
import type { LabsGqlDoctorProfilePhotoDtoInput } from '@orthly/graphql-schema';
import { LabsGqlDoctorProfilePhotoColor, LabsGqlDoctorProfilePhotoType } from '@orthly/graphql-schema';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import type { CustomQFComponentProps } from '@orthly/ui';
import { DownloadIcon, OrthlyBrowserConfig, QuickForm, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, Grid, CloseIcon, Text } from '@orthly/ui-primitives';
import type { FileUploaderCustomPreviewProps } from '@orthly/veneer';
import {
    DoctorProfilePhotoBackground,
    DoctorProfilePhotoColorPicker,
    DoctorProfilePhotoContent,
    FileUploaderCustomPreview,
    FirebaseFileListItemPreview,
    PROFILE_PHOTO_AVATAR_OPTIONS,
    useFirebaseStorage,
    fetchFirebaseFile,
} from '@orthly/veneer';
import React from 'react';
import { useAsync } from 'react-async-hook';
import { z } from 'zod';

type Vars = LabsGqlSetDoctorProfilePhotoMutationVariables['data'];

const PreviewComponent: FileUploaderCustomPreviewProps['PreviewComponent'] = props => {
    return (
        <>
            <FirebaseFileListItemPreview
                style={{ width: '100%', aspectRatio: '1', padding: 0, objectFit: 'cover', maxHeight: 'unset' }}
                file={props.file}
            />
            <Grid container justifyContent={'center'} style={{ position: 'relative' }}>
                <CloseIcon
                    preserveAspectRatio={'xMidYMin'}
                    color={'primary'}
                    onClick={props.onReset}
                    style={{
                        position: 'absolute',
                        width: 16,
                        height: 16,
                        padding: 2,
                        bottom: 8,
                        borderRadius: '50%',
                        color: FlossPalette.STAR_GRASS,
                        background: FlossPalette.WHITE,
                    }}
                />
            </Grid>
        </>
    );
};

interface DoctorProfilePhotoUploadButtonProps {
    source?: string;
    color: LabsGqlDoctorProfilePhotoColor;
    selected?: boolean;
    onChange: (data: Vars['data']) => void;
    employeeId: string;
}
const DoctorProfilePhotoUploadButton: React.VFC<DoctorProfilePhotoUploadButtonProps> = props => {
    const { source, color, selected, onChange, employeeId } = props;
    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.doctorProfile,
        employeeId,
    );
    const firebaseStorage = useFirebaseStorage();
    const { result } = useAsync(
        () => fetchFirebaseFile(firebaseStorage, selected ? source : undefined),
        [firebaseStorage, selected, source],
    );

    const initialFiles = result?.file && [result.file];
    return (
        <DoctorProfilePhotoBackground
            type={LabsGqlDoctorProfilePhotoType.Uploaded}
            style={{ border: `1px solid ${FlossPalette.STROKE_LIGHT}` }}
            source={source || ''}
            color={color}
            selected={selected}
        >
            <FileUploaderCustomPreview
                key={initialFiles?.length ? 'uploaded' : 'empty'}
                autoSubmit
                deleteOnReset
                processImages
                overrideFileName={'profile-photo'}
                storagePathConfig={storagePathConfig}
                loadBlockerContainerProps={{ style: { justifyContent: 'center' } }}
                paperStyle={{ padding: 0 }}
                elevation={0}
                initialFiles={initialFiles}
                dropzoneWrapperStyle={{
                    padding: 0,
                    aspectRatio: '1',
                    border: 'none',
                    minHeight: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: FlossPalette.TAN,
                }}
                dropzoneOptions={{ accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] }, multiple: false }}
                dropzoneContent={
                    <>
                        <DownloadIcon
                            style={{
                                transform: 'scaleY(-1)',
                                color: FlossPalette.STAR_GRASS,
                            }}
                        />
                        <Text variant={'body1'} color={'STAR_GRASS'} style={{ fontSize: 12, lineHeight: 1.5 }}>
                            Upload
                        </Text>
                    </>
                }
                PreviewComponent={PreviewComponent}
                onComplete={([file]) => {
                    if (file) {
                        onChange({
                            type: LabsGqlDoctorProfilePhotoType.Uploaded,
                            source: file.uploadedPath,
                            color: props.color,
                        });
                    }
                }}
                onReset={() => {
                    onChange({ type: LabsGqlDoctorProfilePhotoType.None, color: props.color });
                }}
            />
        </DoctorProfilePhotoBackground>
    );
};

const ChangeDoctorProfilePhotoContent: React.VFC<CustomQFComponentProps<unknown, Vars>> = props => {
    const {
        field: { value },
        form,
    } = props;
    const controls = usePracticeStaffControls();
    if (!controls) {
        return null;
    }
    const { employeePrefs } = controls;
    return (
        <>
            <Text variant={'body2'} color={'DARK_GRAY'}>
                Pick an icon
            </Text>
            <Grid container direction={'row'} wrap={'wrap'} style={{ gap: '16px 24px', margin: '8px 0 16px' }}>
                <DoctorProfilePhotoUploadButton
                    source={value.source}
                    color={value.color}
                    selected={value.type === LabsGqlDoctorProfilePhotoType.Uploaded}
                    onChange={({ type, source }) => {
                        form.setFieldValue('data', { ...value, type, source });
                        // to keep firebase and labs-server in sync
                        form.submitForm();
                    }}
                    employeeId={employeePrefs.id}
                />
                {PROFILE_PHOTO_AVATAR_OPTIONS.map(source => (
                    <DoctorProfilePhotoContent
                        key={source}
                        type={LabsGqlDoctorProfilePhotoType.Avatar}
                        source={source}
                        color={value.color}
                        selected={value.type === LabsGqlDoctorProfilePhotoType.Avatar && value.source === source}
                        onClick={({ type, source }) => form.setFieldValue('data', { ...value, type, source })}
                    />
                ))}
                <DoctorProfilePhotoContent
                    type={LabsGqlDoctorProfilePhotoType.None}
                    source={employeePrefs.name}
                    color={value.color}
                    selected={value.type === LabsGqlDoctorProfilePhotoType.None}
                    onClick={({ type, source }) => form.setFieldValue('data', { ...value, type, source })}
                />
            </Grid>
            <Text variant={'body2'} color={'DARK_GRAY'}>
                Color
            </Text>
            <Grid container direction={'row'} wrap={'wrap'} style={{ gap: 8, margin: '8px 0 16px' }}>
                {Object.values(LabsGqlDoctorProfilePhotoColor).map(color => (
                    <DoctorProfilePhotoColorPicker
                        key={color}
                        color={color}
                        selected={value.color === color}
                        onClick={color => form.setFieldValue('data', { ...value, color })}
                    />
                ))}
            </Grid>
        </>
    );
};

interface ChangeDoctorProfilePhotoVars {
    data: LabsGqlDoctorProfilePhotoDtoInput;
    staff_id: string;
}

type DoctorVars = LabsGqlSetDoctorProfilePhotoMutationVariables['data'];

export const ChangeDoctorProfilePhoto: React.VFC = () => {
    const controls = usePracticeStaffControls();
    const [doctorSubmitMtn] = useSetDoctorProfilePhotoMutation();
    const doctorMtnSubmitter = (data: DoctorVars) => doctorSubmitMtn({ variables: { data } });
    const { submit: doctorSubmit, submitting: doctorSubmitting } = useChangeSubmissionFn<any, any>(doctorMtnSubmitter, {
        successMessage: () => ['Profile Photo updated!'],
    });
    if (!controls) {
        return null;
    }
    const { employeePrefs } = controls;

    return (
        <QuickForm<ChangeDoctorProfilePhotoVars>
            resetOnInitialValueChange
            fields={{
                staff_id: { type: 'text', hidden: true },
                data: {
                    type: 'custom',
                    component: ChangeDoctorProfilePhotoContent,
                    validation: z
                        .object({
                            type: z.nativeEnum(LabsGqlDoctorProfilePhotoType),
                            source: z.string().optional().nullable(),
                            color: z.nativeEnum(LabsGqlDoctorProfilePhotoColor),
                        })
                        .refine(value => value.type === LabsGqlDoctorProfilePhotoType.None || !!value.source),
                },
            }}
            initialValues={{
                staff_id: employeePrefs.id,
                data: {
                    type: employeePrefs.profile_photo.type,
                    source: employeePrefs.profile_photo.source,
                    color: employeePrefs.profile_photo.color,
                },
            }}
            dirtySubmitOnly
            disabled={doctorSubmitting}
            onSubmit={async data => {
                await doctorSubmit({ data: data.data, doctor_id: data.staff_id });
            }}
            CustomSubmit={CollapsedQuickFormSubmit}
            preventDirtyExitMessage={
                'Your profile photo has not been saved yet. Are you sure you want to leave without saving?'
            }
        />
    );
};
