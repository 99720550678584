import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ScrollEnterFieldContainerProps {
    disabled: boolean;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

export const ScrollEnterFieldContainer: React.FC<ScrollEnterFieldContainerProps> = props => {
    const { disabled, style } = props;
    const containerRef = React.useRef<HTMLDivElement>();
    React.useEffect(() => {
        const currentWrapper = containerRef.current;
        if (!disabled && currentWrapper) {
            currentWrapper.scrollIntoView({ block: 'end', behavior: 'smooth' });
        }
    }, [disabled]);
    return (
        <Grid container style={{ position: 'relative', ...style }}>
            {props.children}
        </Grid>
    );
};
