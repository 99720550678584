import { stylesFactory } from '../../utils';
import { Text } from '../Text';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiFormGroup from '@mui/material/FormGroup';
import { styled } from '@mui/material/styles';
import React from 'react';

const useCompactLabelStyles = stylesFactory(theme => ({
    label: {
        marginLeft: theme.spacing(1),
        width: '100%',
    },
}));

const SectionHeading = styled(Text)(({ theme }) => ({
    borderBottom: '1px solid #ccc',
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(1),
}));

const CompactCheckbox = styled(MuiCheckbox)({
    margin: '6px 0',
});

const ColumnFormGroup = styled(MuiFormGroup)({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fit, minmax(var(--column-width), 1fr))`,
    gap: 12,
});

export interface CheckboxesGroupProps {
    /** The values of the checkboxes */
    values: Record<string, boolean>;
    /** A callback function that is called when the selected checkbox changes */
    handleChange: (id: string, value: boolean) => void;
    /** An array of objects with the id and label of each checkbox */
    options: { id: string; label: React.ReactNode; disabled?: boolean }[];
    /** The label for the checkbox group */
    label?: string;
    /** Whether the checkbox group is disabled */
    disabled?: boolean;
    /** The variant of the checkboxes group, which controls the spacing between elements */
    variant?: 'regular' | 'compact';
    /** If true, the component will take up the full width of its container */
    fullWidth?: boolean;
    /** Whether the group should be rendered as a column layout */
    hasColumns?: boolean;
    /** The minimum width applied to checkbox columns @default 150px */
    columnWidth?: string;
}

interface CheckboxGroupCSSProperties extends React.CSSProperties {
    '--column-width': string;
}

const CheckboxesGroupBase: React.FC<CheckboxesGroupProps> = ({
    options,
    values,
    handleChange,
    disabled,
    variant = 'regular',
}) => {
    const compactLabelClasses = useCompactLabelStyles();
    const Checkbox = variant === 'compact' ? CompactCheckbox : MuiCheckbox;

    return (
        <>
            {options.map(({ id, label, disabled: disabledParam }) => (
                <FormControlLabel
                    key={id}
                    control={
                        <Checkbox
                            checked={Boolean(values[id])}
                            disabled={disabledParam || disabled}
                            onChange={event => {
                                if (disabledParam || disabled) {
                                    return;
                                }
                                handleChange(id, event.target.checked);
                            }}
                            value={id}
                        />
                    }
                    classes={variant === 'compact' ? compactLabelClasses : undefined}
                    label={label}
                />
            ))}
        </>
    );
};

export const CheckboxesGroup: React.FC<CheckboxesGroupProps> = ({
    label,
    hasColumns,
    columnWidth = '150px',
    fullWidth,
    ...props
}) => {
    const FormGroup = hasColumns ? ColumnFormGroup : MuiFormGroup;

    return (
        <FormControl component={'fieldset'} fullWidth={fullWidth}>
            {label && <SectionHeading variant={'h6'}>{label}</SectionHeading>}
            <FormGroup style={{ '--column-width': columnWidth } as CheckboxGroupCSSProperties}>
                <CheckboxesGroupBase {...props} />
            </FormGroup>
        </FormControl>
    );
};
