import { PracticeScreen } from '../../PracticeScreen';
import type { NavMenuButtonProps } from './NavMenu';
import { NavMenu } from './NavMenu';
import React from 'react';

const menuButtonConfig: Omit<NavMenuButtonProps, 'navigationAction'>[] = [
    { title: 'Bookings', screen: PracticeScreen.appointments },
    { title: 'Setup and Installation', screen: PracticeScreen.setup },
];
export const HorizontalNavOnlineBooking: React.VFC = () => {
    const [open, setOpen] = React.useState(false);

    return (
        <NavMenu
            open={open}
            setOpen={setOpen}
            title={'Scheduling'}
            description={'Allow patients to book online'}
            menuButtons={menuButtonConfig}
        />
    );
};
