import type { DedupedOrderFormItem } from './mergeOrderItems';
import type { IImplantToothGroup } from '@orthly/items';
import { CartItemV2Utils, DenturesProductionType, type ICustomFieldSubmission, type IDentureItem } from '@orthly/items';
import _ from 'lodash';

const OCCLUSAL_STAINING_PREFERENCE = 'occlusal-staining-preference-order-item-meta';
const BASE_SHADE = 'base';

type ItemForShadesAndStain = {
    preference_fields: Pick<ICustomFieldSubmission, 'field_id' | 'value'>[];
    shades: { name: string; value: string }[];
};

export const getShadesAndStain = (item: ItemForShadesAndStain) => {
    // If the tooth has exactly one shade and that shade is "Base", display it inline
    const noShade = !item.shades || item.shades.length === 0;
    const firstShade = (item.shades ?? [])[0];
    const baseOnly = item.shades?.length === 1 && firstShade && firstShade.name === BASE_SHADE;
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    let toothDisplayColor = noShade ? '' : baseOnly && firstShade?.value ? firstShade.value : 'See below';

    // From 11/3/2020 it is no longer possible to choose "Use Shade Match" as a shade
    if (toothDisplayColor.toLowerCase().includes('shade match')) {
        toothDisplayColor = 'Shade Match';
    }

    let occlusalStain =
        item.preference_fields.find(field => field.field_id === OCCLUSAL_STAINING_PREFERENCE)?.value || '';
    occlusalStain = occlusalStain === 'Lab Decision' ? 'Lab' : `${occlusalStain}`;
    const extraShades =
        !!item.shades && item.shades.length > 1
            ? [...item.shades.filter(s => s.name === 'base'), ...item.shades.filter(s => s.name !== 'base')]
            : undefined;
    return { occlusalStain, toothDisplayColor, extraShades };
};

export const getDenturesTitle = (item: IDentureItem): string => {
    const part2 =
        item.denture_production_type && item.denture_production_type !== DenturesProductionType.WaxRim
            ? ` - ${_.startCase(item.denture_type)}`
            : '';
    switch (item.denture_production_type) {
        case DenturesProductionType.Final:
            return `Final Denture${part2}`;
        case DenturesProductionType.WaxRim:
            return `Wax Rim${part2}`;
        case DenturesProductionType.TryIn:
            return `Monolithic Try-In${part2}`;
    }
    // default to material
    return `${CartItemV2Utils.getItemDisplayMaterial(item) ?? ''}${part2}`;
};

function getLinkRelationshipTitle(implants: IImplantToothGroup[]): string {
    const firstLinkRelationship = implants[0]?.implant_metadata.relationship ?? '';
    if (implants.every(i => i.implant_metadata.relationship === firstLinkRelationship)) {
        return _.startCase(firstLinkRelationship);
    }
    const byLinkRelationship = _.groupBy(implants, i => _.startCase(i.implant_metadata.relationship));
    return Object.entries(byLinkRelationship)
        .map(([relationship, implants]) => `${relationship} (#${implants.map(i => i.unn).join(', ')})`)
        .join(',');
}

export const getImplantSectionTitle = (item: Extract<DedupedOrderFormItem, { sku: 'Implant' | 'ImplantBridge' }>) => {
    if (item.sku === 'ImplantBridge') {
        return {
            boldSection: CartItemV2Utils.getFullDisplayName(item, true),
            regularSection: getLinkRelationshipTitle(item.implants),
        };
    }
    const linkRelationship = item.unit.implant_metadata.relationship;
    const hasHealingCap = item.unit.crown.unit_type === `HealingCap`;
    const title = `${hasHealingCap ? 'Custom Healing Abutment' : 'Implant'}${item.duplicateTeeth.length ? 's' : ''}`;
    const unnsTitle = item.duplicateTeeth.length
        ? CartItemV2Utils.getUnnsDisplay([...CartItemV2Utils.getUniqueUNNs(item), ...item.duplicateTeeth])
        : item.unit.unn;
    return {
        boldSection: `${title} ${unnsTitle}`,
        regularSection: `${linkRelationship}`,
    };
};

const getDisplayUnitType = (unitType: string): string => {
    // a Waxup Sku has 2 possible unit type, Waxup and Physical Waxup. To make it clearer the Waxup is "Digital", we explicitely return it as the display name
    if (unitType === 'Waxup') {
        return 'Digital Waxup';
    }

    return unitType;
};

export const getSingleItemSectionTitle = (item: DedupedOrderFormItem): string => {
    const unitType = CartItemV2Utils.getPrimaryUnitType(item);
    const displayableUnitType = getDisplayUnitType(unitType);
    const titleString = item.duplicateTeeth.length > 0 ? `${displayableUnitType}s` : displayableUnitType;
    const unns = [...CartItemV2Utils.getUniqueUNNs(item), ...item.duplicateTeeth];
    const hasToothNumber = unns.length > 0;
    const unnString = _.sortBy(unns).join(', ');
    const quantity = item.duplicateTeeth.length + 1;
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const suffix = hasToothNumber ? unnString : quantity > 1 ? `(x${quantity})` : undefined;
    return `${titleString}${suffix ? ` ${suffix}` : ``}`;
};
