import type {
    LabsGqlExistingPatientOrderMatchFragment,
    LabsGqlScanFileCheckoutFragment,
} from '@orthly/graphql-operations';
import { LabsGqlExistingPatientOrderType } from '@orthly/graphql-schema';
import type { IOrderItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { HoldUtils } from '@orthly/shared-types';
import { FlossPalette, Grid, Text, Typography } from '@orthly/ui-primitives';
import moment from 'moment';

export type ScanWithExistingOrder = Omit<LabsGqlScanFileCheckoutFragment, 'existingOrderMatch'> & {
    existingOrderMatch: LabsGqlExistingPatientOrderMatchFragment;
};

interface CheckoutExistingOrderTitleProps {
    existingOrderMatch: LabsGqlExistingPatientOrderMatchFragment;
}

export const CheckoutExistingOrderTitle: React.FC<CheckoutExistingOrderTitleProps> = ({ existingOrderMatch }) => {
    const { order, type } = existingOrderMatch;
    const shortId = order.partner_order_number ?? order.id.split('-').slice(-1)[0];
    return (
        <Grid container item>
            <Typography color={'textSecondary'} style={{ width: '100%', fontWeight: 500 }}>
                Order #{shortId}, placed on {moment(order.created_at).format('MMMM Do')}.
                {order.delivery_date &&
                    type === LabsGqlExistingPatientOrderType.Delivered &&
                    ` Delivered on ${moment(order.delivery_date).format('MMMM Do')}`}
            </Typography>
            {order.hold_details && existingOrderMatch.type === LabsGqlExistingPatientOrderType.ScanRejected && (
                <Grid container style={{ paddingTop: 12 }}>
                    <Typography variant={'caption'} color={'textSecondary'} style={{ width: '100%' }}>
                        Reason placed on hold:
                    </Typography>
                    <Text variant={'body1'} style={{ width: '100%' }}>
                        {HoldUtils.composeHoldReason(order.hold_details)}
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};

interface ItemsTableForExistingOrderProps {
    items: IOrderItemV2DTO[];
}

export const ItemsTableForExistingOrder: React.FC<ItemsTableForExistingOrderProps> = ({ items }) => {
    return (
        <>
            {items.map(item => {
                const unns = CartItemV2Utils.getUniqueUNNs(item);
                return (
                    <Grid
                        container
                        key={item.id}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        style={{ padding: '16px 8px', borderTop: `1px solid ${FlossPalette.DARK_TAN}` }}
                    >
                        <Grid container style={{ width: 'auto' }}>
                            <Text style={{ fontWeight: 500, marginLeft: 8 }}>
                                {CartItemV2Utils.getDisplayName(item)}, {CartItemV2Utils.getItemDisplayMaterial(item)}
                            </Text>
                        </Grid>
                        <Text>
                            {unns.length > 0 &&
                                `${Format.pluralizeNoun(
                                    'Tooth',
                                    unns.length,
                                    'Teeth',
                                )} #${CartItemV2Utils.getUnnsDisplay(unns)}`}
                            {OrderItemV2Utils.isArchItem(item) && `${item.unit.arch} Arch`}
                        </Text>
                    </Grid>
                );
            })}
        </>
    );
};
