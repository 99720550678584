import { ATTACH_PHOTOS_PATH } from '../../../LabsPaths';
import type { TextUploadLinkResult } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useSendAttachPhotosUploadLinkMutation } from '@orthly/graphql-react';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { useChangeSubmissionFn } from '@orthly/ui';
import type { UploadedFile } from '@orthly/veneer';
import { OrderPhotoFileUploader, useImpersonatedQrCodeLink } from '@orthly/veneer';
import React from 'react';

function useTextUploadLink(uploadLink: string, order: LabsGqlLabOrderFragment): TextUploadLinkResult {
    const [sendTextMtn] = useSendAttachPhotosUploadLinkMutation();
    const textSubmitter = async (phoneNumber: string) => {
        await sendTextMtn({
            variables: {
                data: {
                    upload_link: uploadLink,
                    doctor_name: order.doctor_name,
                    practice_id: order.partner_id ?? '',
                    phone_number: phoneNumber,
                    patient_name: `${order.patient.first_name} ${order.patient.last_name}`,
                    order_number: order.order_number,
                },
            },
        });
    };
    const { submit: sendText, submitting } = useChangeSubmissionFn<void, [string]>(textSubmitter, {
        successMessage: () => ['Check your phone for the upload link!', {}],
    });

    return {
        sendText,
        submitting,
        placeholderPhoneNumber: '',
    };
}

interface UploadFilesActionProps {
    order: LabsGqlLabOrderFragment;
    sessionId: string;
    setFiles: (files: UploadedFile[]) => void;
    loading?: boolean;
}

export const UploadFilesAction: React.VFC<UploadFilesActionProps> = props => {
    const { order, sessionId, setFiles, loading } = props;
    const orderId = order.id;

    const items = OrderItemV2Utils.parseItems(order.items_v2);
    const products = items.map(item => CartItemV2Utils.getProductUnitType(item));
    const queryParams = new URLSearchParams({
        sessionId,
        platform: 'portal',
        patientName: `${order.patient.first_name}+${order.patient.last_name}`,
        ...(products[0] ? { productType: products[0] } : {}),
    }).toString();
    const mobileUploadUrl = `${window.location.origin}/lab/${ATTACH_PHOTOS_PATH}/${orderId}?${queryParams}`;
    const qrCode = useImpersonatedQrCodeLink(mobileUploadUrl);
    const textUploadLink = useTextUploadLink(mobileUploadUrl, order);

    return (
        <OrderPhotoFileUploader
            orderId={orderId}
            sessionId={sessionId}
            setFiles={setFiles}
            qrCode={qrCode}
            textUploadLink={textUploadLink}
            loading={loading}
        />
    );
};
