import { useCheckoutPropSelector } from '../../../../../redux/selectors';
import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import { useLastMatchingOrderByUnitTypeQuery } from '@orthly/graphql-react';
import type { LabsGqlICustomFieldSubmission } from '@orthly/graphql-schema';
import type { LabOrderItemSKUType } from '@orthly/items';
import { ItemMaterialFieldV2Utils, ItemMetafieldV2Utils } from '@orthly/items';
import { FlossPalette, Grid, Icon, Button, Text } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

type RemovableCheckoutItem = Extract<CheckoutItemV2, { sku: LabOrderItemSKUType.Removeable }>;

const ItemField: React.FC<{ value?: React.ReactNode; name: string }> = props => {
    const { value, name } = props;
    if (!value) {
        return null;
    }
    return (
        <Grid container>
            <Text variant={'body2'} style={{ marginRight: 32, color: FlossPalette.GRAY }}>
                {name}
            </Text>
            <Text variant={'body2'}>{value}</Text>
        </Grid>
    );
};

function useCopySuggestedOrder(
    item: RemovableCheckoutItem,
    preference_fields: LabsGqlICustomFieldSubmission[],
    material: string,
) {
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');
    const setField = useCheckoutAction('SET_LINE_ITEM_METAFIELD');
    const visibleFields = ItemMetafieldV2Utils.getMetafieldsForItem(item);
    const updateMaterial = React.useCallback(
        (value: string) => updateItem({ item_index: item.item_index, change: { name: 'material', payload: value } }),
        [updateItem, item.item_index],
    );

    return React.useCallback(() => {
        updateMaterial(material);

        preference_fields.forEach(field => {
            const fieldFragment = visibleFields.find(vf => vf.id === field.field_id);
            if (!fieldFragment) {
                return;
            }

            setField({
                item_index: item.item_index,
                field: {
                    value: field.value,
                    display_name: fieldFragment.label || fieldFragment.name,
                    field_id: fieldFragment.id,
                },
            });
        });
    }, [item.item_index, material, preference_fields, setField, visibleFields, updateMaterial]);
}

interface CheckoutSuggestOrderButtonsProps {
    setDismissed: (val: boolean) => void;
    item: RemovableCheckoutItem;
    prefFields: LabsGqlICustomFieldSubmission[];
    material: string;
}

const CheckoutSuggestOrderButtons: React.FC<CheckoutSuggestOrderButtonsProps> = props => {
    const { setDismissed, item, prefFields, material } = props;
    const onUseSuggested = useCopySuggestedOrder(item, prefFields, material);
    // we use these redux actions for analytics and tracking purposes
    const setSelected = useCheckoutAction('SET_SUGGESTED_ITEM_SELECTED');
    const setRendered = useCheckoutAction('SET_SUGGESTED_ITEM_RENDERED');
    const { suggestedItemState } = useCheckoutPropSelector(['suggestedItemState']);

    React.useEffect(
        () => {
            !suggestedItemState && setRendered();
        },
        // Set rendered on first render of buttons
        [suggestedItemState, setRendered],
    );

    return (
        <Grid item container xs={5} justifyContent={'flex-end'} alignItems={'center'}>
            <Button
                variant={'primary'}
                onClick={() => {
                    onUseSuggested();
                    setSelected();
                }}
            >
                Use this suggestion
            </Button>
            <Button
                variant={'secondary'}
                onClick={() => setDismissed(true)}
                style={{ backgroundColor: FlossPalette.WHITE, marginLeft: 16 }}
            >
                Dismiss
            </Button>
        </Grid>
    );
};

export const CheckoutSuggestedOrder: React.FC<{ item: RemovableCheckoutItem }> = ({ item }) => {
    const { unit_type } = item.unit;
    const { doctor } = useCheckoutPropSelector(['doctor']);
    const [dismissed, setDismissed] = React.useState(false);
    const materialFieldCount = ItemMaterialFieldV2Utils.getMaterialFieldsForItem(item).length;

    const query = useLastMatchingOrderByUnitTypeQuery({ variables: { unit_type, doctor_id: doctor?.id ?? '' } });
    const lastOrder = query.data?.lastMatchingOrderByUnitType;
    const firstInitial = lastOrder?.patient.first_name[0] ?? '';
    const matchingItem = lastOrder?.items_v2.find(
        item => item.__typename === 'RemovableItem' && item.unit.unit_type === unit_type,
    );

    const hideSuggestedOrder = materialFieldCount === 0 || dismissed;
    if (hideSuggestedOrder || !lastOrder || !matchingItem || matchingItem.__typename !== 'RemovableItem') {
        return null;
    }
    const material = matchingItem.unit.material;

    return (
        <Grid container style={{ margin: '16px 0' }}>
            <Grid container alignItems={'center'}>
                <Icon icon={'InfoOutlinedIcon'} style={{ color: FlossPalette.BURGUNDY }} />
                <div style={{ marginLeft: 8 }}>
                    <Text variant={'body1'} color={'DARK_GRAY'}>
                        Order Suggestion
                    </Text>
                    <Text variant={'body2'} color={'BLACK'} style={{ fontWeight: 500 }}>
                        This looks similar to a past order. Would you like to use the same details?
                    </Text>
                </div>
            </Grid>
            <Grid container style={{ backgroundColor: FlossPalette.TAN, padding: 16, marginTop: 16 }}>
                <Grid item xs={7} container direction={'column'}>
                    <Grid container style={{ borderLeft: `2px solid ${FlossPalette.DARK_TAN}`, paddingLeft: 16 }}>
                        <ItemField name={'Material'} value={material} />
                    </Grid>
                    <Text variant={'body2'} style={{ marginTop: 8 }}>{`Ordered ${moment(
                        lastOrder.created_at,
                    ).fromNow()} for ${firstInitial}${firstInitial ? '.' : ''} ${lastOrder.patient.last_name}`}</Text>
                </Grid>
                <CheckoutSuggestOrderButtons
                    setDismissed={setDismissed}
                    item={item}
                    material={material}
                    prefFields={matchingItem.preference_fields}
                />
            </Grid>
        </Grid>
    );
};
