import { LabeledTextField } from '../../../screens/pre-auth/components/labeled-field/LabeledFields';
import { Form } from '../../../screens/pre-auth/components/page-layout/components/PanelContent.styles';
import { Button, FormHelperText, Link, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const ResendLink = styled(Link)({
    cursor: 'pointer',
});

interface ConfirmPhoneNumberFormProps {
    confirmationCode: string;
    setConfirmationCode: (value: string | undefined) => void;
    setShowConfirmationCodeError: (e: React.FocusEvent<HTMLInputElement>) => void;
    confirmationCodeError: string | undefined;
    shouldContinue: boolean;
    sendConfirmationCode: () => void;
    verifyPhoneError: string | undefined;
    submittingVerifyPhone: boolean;
    verifyPhoneNumber: () => void;
}

export const ConfirmPhoneNumberForm: React.FC<ConfirmPhoneNumberFormProps> = ({
    confirmationCode,
    setConfirmationCode,
    setShowConfirmationCodeError,
    confirmationCodeError,
    shouldContinue,
    sendConfirmationCode,
    verifyPhoneError,
    submittingVerifyPhone,
    verifyPhoneNumber,
}) => {
    return (
        <Form
            onSubmit={e => {
                e.preventDefault();
                verifyPhoneNumber();
            }}
        >
            <LabeledTextField
                isRequired
                label={'Enter confirmation code'}
                placeholder={'Enter confirmation code'}
                value={confirmationCode}
                onChange={setConfirmationCode}
                disabled={submittingVerifyPhone}
                TextFieldProps={{
                    type: 'text',
                    autoFocus: true,
                    onBlur: setShowConfirmationCodeError,
                    error: !!confirmationCodeError,
                    helperText: confirmationCodeError,
                }}
                footer={
                    <Text variant={'body2'} color={'GRAY'}>
                        {`You're able to request another code in 30 seconds. `}
                        <ResendLink onClick={sendConfirmationCode}>
                            <Text bold variant={'body2'} color={'PRIMARY_FOREGROUND'} component={'span'}>
                                Resend
                            </Text>
                        </ResendLink>
                    </Text>
                }
            />
            {verifyPhoneError && <FormHelperText error>{verifyPhoneError}</FormHelperText>}
            <Button disabled={!shouldContinue} variant={'primary'} fullWidth type={'submit'}>
                Continue
            </Button>
        </Form>
    );
};
