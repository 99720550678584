import { LabsGqlLabOrderWaxupReviewStatus } from '@orthly/graphql-schema';

export enum OrderSummaryButton {
    ModifyRx = 'modify rx',
    ReviewDesign = 'review design',
    ResolveHold = 'resolve hold',
    RefabricateOrder = 'refabricate order',
    ProvideFeedback = 'provide feedback',
    ResumeOrder = 'resume order',
    MarkDelivered = 'mark delivered',
}

type GetOrderButtonsToRenderArgs = {
    waxup_status: LabsGqlLabOrderWaxupReviewStatus | null;
    can_refabricate: boolean;
    can_submit_feedback: boolean;
    can_edit: boolean;
    is_delivered: boolean;
    is_on_practice_managed_pause: boolean;
    has_live_ddp_review: boolean;
    can_mark_delivered: boolean;
};

export const getOrderButtonsToRender = (
    args: GetOrderButtonsToRenderArgs,
    shouldShowResolveButton: boolean,
): OrderSummaryButton[] => {
    if (args.is_delivered) {
        const buttonsToRender = [];
        if (args.can_refabricate) {
            buttonsToRender.push(OrderSummaryButton.RefabricateOrder);
        }
        if (args.can_submit_feedback) {
            buttonsToRender.push(OrderSummaryButton.ProvideFeedback);
        }
        return buttonsToRender;
    }

    if (args.is_on_practice_managed_pause) {
        // We do not sho any buttons when DDP Review is active in the timeline menu,
        // it can only be canceled via the order details page for now.
        return args.has_live_ddp_review ? [] : [OrderSummaryButton.ResumeOrder];
    }

    if (shouldShowResolveButton) {
        return [OrderSummaryButton.ResolveHold];
    }

    if (args.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview) {
        return [OrderSummaryButton.ReviewDesign];
    }

    if (args.can_edit) {
        return [OrderSummaryButton.ModifyRx];
    }
    if (args.can_mark_delivered) {
        return [OrderSummaryButton.MarkDelivered];
    }

    return [];
};
