import { LoginRoutes } from '../../../logged-out/LoginPaths';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const useRedirectOnLogin = ({ originalLocation }: { originalLocation: LocationDescriptorObject }) => {
    const history = useHistory();
    const session = useSession();
    const { loginOptions } = useStaffMemberLoginProps();

    React.useEffect(() => {
        const isOriginalLocationFrom = (paths: string[]) => {
            return paths.some(path => originalLocation.pathname?.includes(path));
        };

        // If the user is not logged in and loginOptions are defined,
        // the session just requires a location selection decision
        if (!session?.organization_type && loginOptions) {
            return history.push(LoginRoutes.select_location);
        }

        if (!session?.organization_type) {
            return;
        }

        // User is either impersonating or accessing payment methods and
        // addresses page through email after initial partner registration
        // Redirect them where they originally intended to go or to the inbox
        if (history.location.pathname !== LoginRoutes.select_location) {
            if (originalLocation.pathname === '/' || isOriginalLocationFrom(['login', 'notification_preferences'])) {
                return history.push('/inbox');
            }

            return history.push(originalLocation);
        }
    }, [history, loginOptions, originalLocation, session]);
};
