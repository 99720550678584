import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import type { UploadedImageSourceMap } from '../../AlignerCheckout/MobileCheckout.util';
import { AlignerTypeIconMap } from '../../AlignerCheckout/MobileCheckout.util';
import type { LabsGqlAlignerCasePhotoType as AlignerPhotoType } from '@orthly/graphql-schema';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { CameraIcon, OrthlyBrowserConfig, OrthlyFrontendApps, SmallTrashCanIcon } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    IconButton,
    Button,
    Text,
} from '@orthly/ui-primitives';
import { FileUploaderCustomPreview, FirebaseFileListItemPreview } from '@orthly/veneer';
import _ from 'lodash';
import type { Dispatch, SetStateAction, CSSProperties } from 'react';
import React from 'react';

const useStyles = stylesFactory(() => ({
    trashIconButton: {
        padding: 0,
    },
    imgIcon: {
        height: 48,
        width: 48,
        color: FlossPalette.GRAY,
    },
    icon: {
        borderRadius: 36,
        padding: 4,
        height: 32,
        width: 32,
    },
    deleteIconWrapper: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        justifyContent: 'center',
        alignItems: 'flex-end',
        padding: 12,
    },
    imgWrapper: { alignContent: 'center', justifyContent: 'center' },
    uploaderWrapper: { borderRadius: 8, overflow: 'hidden', width: '100%' },
    dropzoneContent: { backgroundColor: FlossPalette.TAN, height: '100%', padding: 12 },
}));

const DropzoneContent: React.FC<{ type: AlignerPhotoType; deviceUpload?: boolean }> = ({ type, deviceUpload }) => {
    const classes = useStyles();
    const ImageIcon = AlignerTypeIconMap[type];
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return (
        <Grid
            container
            direction={'column'}
            alignItems={'center'}
            justifyContent={'space-between'}
            className={classes.dropzoneContent}
            style={{ padding: deviceUpload ? 24 : undefined }}
        >
            <ImageIcon className={classes.imgIcon} />
            {deviceUpload && !isMobile ? (
                <>
                    <Text variant={'body2'} style={{ marginTop: 16 }}>
                        Drop your photo here
                    </Text>
                    <Text variant={'body2'}>or</Text>
                    <Button variant={'secondary'} startIcon={'AttachIcon'} style={{ marginBottom: 16 }}>
                        Browse files
                    </Button>
                </>
            ) : (
                <CameraIcon
                    preserveAspectRatio={'xMidYMin'}
                    color={'secondary'}
                    className={classes.icon}
                    style={{ color: FlossPalette.WHITE, background: FlossPalette.STAR_GRASS }}
                />
            )}
        </Grid>
    );
};

interface IndividualPhotoUploadProps {
    scanId: string;
    type: AlignerPhotoType;
    setImgSrcMap?: Dispatch<SetStateAction<UploadedImageSourceMap>>;
    // true if the device being used for checkout is the same one uploading images
    uploadingFromThisDevice?: boolean;
    uploadedGCSPath?: string;
}

const ImageUploadPreview: React.FC<{ onReset: () => void; file: File; style?: CSSProperties }> = props => {
    const classes = useStyles();
    return (
        <Grid container className={classes.imgWrapper} style={props.style}>
            <FirebaseFileListItemPreview
                style={{
                    padding: 0,
                    maxWidth: 'unset',
                    minWidth: '100%',
                    minHeight: '100%',
                    flexShrink: 0,
                    maxHeight: '200%',
                }}
                file={props.file}
            />
            <Grid container className={classes.deleteIconWrapper}>
                <IconButton onClick={props.onReset} className={classes.trashIconButton}>
                    <SmallTrashCanIcon
                        preserveAspectRatio={'xMidYMin'}
                        color={'primary'}
                        className={classes.icon}
                        style={{ color: FlossPalette.STAR_GRASS, background: FlossPalette.WHITE }}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

async function getInitialFile(type: AlignerPhotoType, uploadedFile?: string): Promise<File | undefined> {
    if (!uploadedFile) {
        return undefined;
    }
    const res = await fetch(uploadedFile);
    const uploadedBlob = await res.blob();
    return new File([uploadedBlob], `${type}.${_.last(uploadedFile.split('.'))}`, { type: 'image' });
}

export const IndividualPhotoUpload: React.FC<IndividualPhotoUploadProps> = props => {
    const { scanId, type, setImgSrcMap, uploadingFromThisDevice, uploadedGCSPath } = props;
    const classes = useStyles();
    const config = new OrthlyBrowserConfig(OrthlyFrontendApps.practice);
    const storagePathConfig = getFullStoragePath(config.env, OrderingStorageConfigs.aligners, scanId);
    const [initialFile, setInitialFile] = React.useState<File | undefined>(undefined);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    React.useEffect(() => {
        getInitialFile(type, uploadedGCSPath)
            .then(file => setInitialFile(file))
            .catch(console.error);
    }, [uploadedGCSPath, type]);
    return (
        <div className={classes.uploaderWrapper}>
            <FileUploaderCustomPreview
                key={!!initialFile ? 'initial' : 'upload'}
                autoSubmit
                deleteOnReset
                processImages
                overrideFileName={type}
                prependTimestampToFilename={true}
                elevation={0}
                storagePathConfig={storagePathConfig}
                paperStyle={{ padding: 0 }}
                loadBlockerContainerProps={{ style: { justifyContent: 'center' } }}
                dropzoneWrapperStyle={{ border: 0, padding: 0, height: uploadingFromThisDevice ? undefined : 120 }}
                dropzoneTypographyStyle={{ height: '100%', width: '100%' }}
                dropzoneOptions={{ accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] }, multiple: false }}
                dropzoneContent={<DropzoneContent type={type} deviceUpload={uploadingFromThisDevice} />}
                PreviewComponent={ImageUploadPreview}
                previewComponentStyle={{ height: uploadingFromThisDevice && !isMobile ? 224 : 120 }}
                onReset={() => setImgSrcMap && setImgSrcMap(state => ({ ...state, [type]: null }))}
                onComplete={results => {
                    // Safety check
                    if (!results[0]) {
                        window.alert('Could not upload image');
                        return;
                    }
                    setImgSrcMap && setImgSrcMap(state => ({ ...state, [type]: results[0]?.uploadedPath }));

                    AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                        productName: 'aligners',
                        isMobile: false,
                    });
                }}
                initialFiles={initialFile ? [initialFile] : undefined}
            />
        </div>
    );
};
