import type { TraineeFormFieldsProps } from '../TrainingDataIntake.types';
import { TrainingRoleOptions } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

export const TraineeRoleFormField: React.VFC<TraineeFormFieldsProps> = props => {
    const { trainee, hasError, setTrainee } = useTrainee(props.traineeAtom, 'role');

    return (
        <SimpleSelect
            flossInputConfig={{ backgroundColor: 'gray', bordered: true }}
            options={TrainingRoleOptions.map(role => ({ label: role, value: role }))}
            onChange={value => {
                setTrainee(oldValue => ({ ...oldValue, role: value }));
            }}
            value={trainee.role}
            label={''}
            placeholder={'Select role'}
            FormControlProps={{
                error: trainee.showInvalidFields && hasError,
                variant: 'standard',
            }}
            SelectProps={{
                'aria-label': `Select role for person ${props.listIndex + 1}`,
                variant: 'standard',
                style: { padding: 0 },
            }}
        />
    );
};
