import { ConfigService } from '../../../../utils';
import { useLabsContext } from '../../LabsContext';
import type { LabsGqlProcess3OxzFileMutationVariables } from '@orthly/graphql-operations';
import { useProcess3OxzFileMutation } from '@orthly/graphql-react';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import type { RootActionDialogProps } from '@orthly/ui';
import { RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import { Grid, IconButton, Tooltip, AddIcon, Button } from '@orthly/ui-primitives';
import { FileUploaderBulk } from '@orthly/veneer';
import React from 'react';

type Vars = LabsGqlProcess3OxzFileMutationVariables;

interface ManualScanUploadDialogProps {
    refetch: () => Promise<any>;
    inlineButton?: boolean;
    CustomButton?: RootActionDialogProps['CustomButton'];
    managedOpenState?: {
        setOpen: (open: boolean) => void;
        open: boolean;
    };
}

export const ManualScanUploadDialog: React.FC<ManualScanUploadDialogProps> = props => {
    const { managedOpenState } = props;
    const { partnerId } = useLabsContext();
    const [submitMtn] = useProcess3OxzFileMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: data });
    const changeState = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Scan file submitted!', {}],
        onSuccess: async () => {
            await props.refetch();
            managedOpenState?.setOpen(false);
        },
    });
    const { submit, submitting } = changeState;
    const { open, setOpen } = { ...changeState, ...managedOpenState };
    const [uploadedScanUrl, setUploadedScanUrl] = React.useState<string>();
    const onClickSubmit = React.useCallback(() => {
        if (!submitting && uploadedScanUrl && partnerId) {
            submit({ partnerId, file_url: uploadedScanUrl }).catch(console.error);
        }
    }, [partnerId, submit, submitting, uploadedScanUrl]);
    if (!partnerId) {
        return null;
    }
    const storagePathConfig = getFullStoragePath(ConfigService.env, OrderingStorageConfigs.scans, partnerId);
    return (
        <RootActionDialog
            loading={submitting}
            open={open}
            setOpen={setOpen}
            title={'Submit Scan File'}
            content={
                <>
                    <FileUploaderBulk
                        autoSubmit
                        elevation={0}
                        paperStyle={{ padding: 0 }}
                        dropzoneOptions={{ multiple: false }}
                        dropzoneWrapperStyle={{ minHeight: 50, padding: 0, marginTop: 12 }}
                        title={uploadedScanUrl ? '.3oxz File' : 'Add .3oxz File'}
                        storagePathConfig={storagePathConfig}
                        onComplete={results => {
                            results && results[0] && setUploadedScanUrl(results[0].uploadedPath);
                        }}
                        onReset={() => {
                            setUploadedScanUrl(undefined);
                        }}
                        prependTimestampToFilename={true}
                        accept={{ 'application/zip': ['.3oxz', '.zip'] }}
                    />{' '}
                    <Grid container>
                        <Button
                            onClick={onClickSubmit}
                            fullWidth
                            disabled={!uploadedScanUrl || submitting}
                            variant={'primary'}
                        >
                            Submit
                        </Button>
                    </Grid>
                </>
            }
            style={{ padding: 0 }}
            buttonText={'Add Scan File'}
            CustomButton={
                // eslint-disable-next-line no-nested-ternary
                props.CustomButton
                    ? props.CustomButton
                    : !props.inlineButton
                      ? undefined
                      : buttonProps => {
                            return (
                                <IconButton onClick={buttonProps.onClick}>
                                    <Tooltip title={'Upload Scan'}>
                                        <AddIcon />
                                    </Tooltip>
                                </IconButton>
                            );
                        }
            }
        />
    );
};
