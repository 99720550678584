import { OrderAction, OrderActionTrackerIdMap } from '../../../actions/OrderActionsUtils';
import { useRefabAction } from '../../../actions/practitioners/useRefabAction';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import type { ButtonVariant, IconName } from '@orthly/ui-primitives';
import { Button, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    ctaButton: {
        padding: 0,
        height: 'unset',
    },
}));

interface DeliveredOrderActionProps {
    orderId: string;
    buttonVariant?: ButtonVariant;
    leftAlign?: boolean;
    padded?: boolean;
    icon?: IconName;
    analyticsLocation?: 'feedback post submit' | 'order details - quick order actions';
}

interface RefabricateOrderButtonProps extends DeliveredOrderActionProps {
    canRefabricate: boolean | null;
}

export const RefabricateOrderButton: React.VFC<RefabricateOrderButtonProps> = props => {
    const { orderId, canRefabricate, analyticsLocation, buttonVariant, icon, leftAlign, padded } = props;
    const classes = useStyles();
    const { triggerRefabAction } = useRefabAction(orderId);

    if (!canRefabricate) {
        return null;
    }

    return (
        <Button
            disabled={!canRefabricate}
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.Refabricate]}
            variant={buttonVariant ?? 'ghost'}
            className={padded ? undefined : classes.ctaButton}
            leftAlign={leftAlign}
            startIcon={icon}
            onClick={ev => {
                ev.stopPropagation();
                triggerRefabAction(analyticsLocation ?? 'order list');
            }}
        >
            Refabricate order
        </Button>
    );
};

interface ProvideOrderFeedbackButtonProps extends DeliveredOrderActionProps {
    canSubmitFeedback: boolean | null;
}

export const ProvideOrderFeedbackButton: React.VFC<ProvideOrderFeedbackButtonProps> = props => {
    const { orderId, canSubmitFeedback, analyticsLocation, buttonVariant, icon, leftAlign, padded } = props;

    const classes = useStyles();
    const history = useHistory();

    if (!canSubmitFeedback) {
        return null;
    }

    return (
        <Button
            disabled={!canSubmitFeedback}
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.ProvideProductFeedback]}
            data-test={'provide-feedback-button'}
            variant={buttonVariant ?? 'ghost'}
            className={padded ? undefined : classes.ctaButton}
            leftAlign={leftAlign}
            startIcon={icon}
            onClick={ev => {
                ev.stopPropagation();
                BrowserAnalyticsClientFactory.Instance?.track('Practice - Feedback - Opened', {
                    $groups: { order: orderId },
                    location: analyticsLocation ?? 'order list',
                });
                history.push(`/${PracticeScreen.feedback}/${orderId}`);
            }}
        >
            Provide product feedback
        </Button>
    );
};
