import { PreferenceSection } from './PreferenceSection';
import { PreferenceSelect } from './PreferenceSelect';
import { usePreferenceControls } from './usePreferenceControls';
import {
    ORDER_PREFERENCES_SKU_DEFINITIONS as SKU_DEFINITIONS,
    findEnabledPrefs,
    FIRST_ORDER_PREF_SKU_LABEL,
} from '@orthly/dentin';
import type { ItemMetafieldV2 } from '@orthly/items';
import { LoadBlocker, apolloErrorMessage } from '@orthly/ui';
import { useScreenIsMobile, Grid, Alert, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

type OptionVal = string | boolean | undefined;
type SelectedOptions = Record<string, OptionVal>;

export interface PreferencesState {
    remaining?: number;
    selectedAggs?: string[];
    selectedOptions?: SelectedOptions;
}

interface IndividualPreferencesProps {
    individualId: string;
    resetIndividualId?: () => void;
    initialAggs?: Array<string>;
    setPrefsState?: React.Dispatch<React.SetStateAction<PreferencesState>>;
    showSubmitButton?: boolean;
    hideCancelButton?: boolean;
    containerStyle?: React.CSSProperties;
}
export const IndividualPreferences: React.FC<IndividualPreferencesProps> = props => {
    const {
        individualId,
        resetIndividualId,
        initialAggs,
        setPrefsState,
        showSubmitButton = false,
        hideCancelButton = false,
        containerStyle,
    } = props;
    const isMobile = useScreenIsMobile();

    const [savedOptions, setSavedOptions] = React.useState<Record<string, string> | undefined>(undefined);
    const [selectedAggs, setSelectedAggs] = React.useState<string[]>(initialAggs || [FIRST_ORDER_PREF_SKU_LABEL]);
    const [selectedOptions, setSelectedOptions] = React.useState<SelectedOptions>({});
    const [error, setError] = React.useState<string>();

    const { preferences, submit, loading } = usePreferenceControls({
        onSuccess: () => {
            resetIndividualId?.();
        },
        onError: e => {
            setError(apolloErrorMessage(e));
            setTimeout(() => setError(undefined), 5000);
        },
    });

    const submitPreferences = React.useCallback(
        () =>
            submit({
                preference_set_id: individualId,
                ...selectedOptions,
            }),
        [submit, individualId, selectedOptions],
    );

    const selectedPref = preferences.find(pref => pref.id === individualId);

    React.useEffect(() => {
        if (savedOptions === undefined && !loading) {
            const savedSelections = (selectedPref?.custom_field_preferences ?? []).map(pref => ({
                [pref.field_id]: pref.value,
            }));
            setSavedOptions(Object.assign({}, ...savedSelections));
            setSelectedOptions(Object.assign({}, ...savedSelections));
        }
    }, [savedOptions, loading, selectedPref]);

    const enabledPrefFields: Record<string, ItemMetafieldV2[]> = React.useMemo(
        () => findEnabledPrefs(selectedAggs),
        [selectedAggs],
    );

    const remaining = React.useMemo(
        () =>
            Object.values(enabledPrefFields)
                .flat()
                .filter(pref => selectedOptions[pref.id] === undefined).length,
        [enabledPrefFields, selectedOptions],
    );

    React.useEffect(() => {
        if (setPrefsState !== undefined) {
            setPrefsState({
                remaining,
                selectedAggs,
                selectedOptions,
            });
        }
    }, [setPrefsState, selectedAggs, remaining, selectedOptions]);

    if (selectedPref === undefined) {
        return null;
    }

    return (
        <LoadBlocker blocking={loading}>
            <Grid
                container
                direction={'column'}
                style={{ padding: isMobile ? '2rem 2rem 0' : '5rem 5rem 0', ...containerStyle }}
            >
                <Grid container style={{ maxWidth: '60rem', marginTop: '1rem' }}>
                    <Grid item xs={12} sm={3}>
                        <Text style={{ color: 'gray' }}>{isMobile ? 'Items' : "What you'll order"}</Text>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <PreferenceSelect
                            multiple={true}
                            header={`What will ${selectedPref.name} order with Dandy?`}
                            subHeader={"You will not be prevented from ordering options you don't select right now"}
                            selectables={
                                SKU_DEFINITIONS.map(agg => ({
                                    id: agg.label,
                                    selected: selectedAggs.includes(agg.label),
                                })) || []
                            }
                            onChange={ids => setSelectedAggs(ids)}
                        />
                    </Grid>
                    {SKU_DEFINITIONS.map(agg => {
                        const enabledField = selectedAggs.includes(agg.label) ? enabledPrefFields[agg.label] : null;
                        return (
                            enabledField &&
                            enabledField.length > 0 && (
                                <PreferenceSection
                                    key={agg.label}
                                    title={agg.label}
                                    preferenceFields={enabledField}
                                    selectedOptions={selectedOptions}
                                    setSelectedOptions={setSelectedOptions}
                                />
                            )
                        );
                    })}
                    {error !== undefined && <Alert severity={'error'}>{error}</Alert>}
                </Grid>
                {showSubmitButton && (
                    <Grid
                        style={{
                            position: 'sticky',
                            bottom: 0,
                            padding: '2rem',
                            backgroundColor: 'white',
                            borderTop: '1px solid gray',
                            zIndex: 2,
                            display: isMobile ? 'block' : 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Button
                            fullWidth={isMobile}
                            variant={'primary'}
                            style={{ marginRight: isMobile ? 0 : '2rem', marginBottom: isMobile ? '1rem' : 0 }}
                            onClick={submitPreferences}
                        >
                            Save Preferences
                        </Button>
                        {!hideCancelButton && (
                            <Button
                                fullWidth={isMobile}
                                variant={'secondary'}
                                style={{ marginLeft: isMobile ? 0 : '2rem', display: isMobile ? 'block' : undefined }}
                                onClick={resetIndividualId}
                            >
                                Cancel
                            </Button>
                        )}
                    </Grid>
                )}
            </Grid>
        </LoadBlocker>
    );
};
