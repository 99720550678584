import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const SendOneTimeCode_Mutation = graphql(`
    mutation SendOneTimeCode($mobile_phone_number: String!) {
        sendOneTimeCode(mobilePhoneNumber: $mobile_phone_number)
    }
`);

export function useSendOneTimeCode({ onSuccess }: { onSuccess?: () => void }) {
    const [submitMtn, { error }] = useMutation(SendOneTimeCode_Mutation);
    const { submit: sendCode, ...rest } = useChangeSubmissionFn<any, any>(variables => submitMtn({ variables }), {
        disableDefaultErrorMessage: true,
        onSuccess: () => {
            onSuccess?.();
        },
    });

    const submit = React.useCallback(
        async (phoneNumber: string) => {
            if (phoneNumber) {
                return sendCode({ mobile_phone_number: phoneNumber });
            }
        },
        [sendCode],
    );

    return {
        error: error?.message,
        submit,
        ...rest,
    };
}
