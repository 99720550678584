import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { useIsOrderEligibleForLiveDesignReview } from './GuidedWaxupScheduleLiveCall.hooks';
import { GuidedWaxupScheduleLiveCallAbandonmentModal } from './GuidedWaxupScheduleLiveCallAbandonmentModal';
import { GuidedWaxupScheduleLiveCallCalendlyModal } from './GuidedWaxupScheduleLiveCallCalendlyModal';
import { GuidedWaxupScheduleLiveCallInitialModal } from './GuidedWaxupScheduleLiveCallInitialModal';
import { useMutation } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { addBusinessDays } from '@orthly/date-fns-business-days';
import { graphql } from '@orthly/graphql-inline-react';
import { OrthlyBrowserConfig, useRootActionCommand } from '@orthly/ui';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

const VeneerScheduleLiveDDPCall_Mutation = graphql(`
    mutation VeneerScheduleLiveDDPCall_Mutation($args: CreateLiveDdpReviewRequestArgs!) {
        createLiveDdpReviewRequest(args: $args) {
            id
        }
    }
`);

export const GuidedWaxupScheduleLiveCall: React.VFC<{ orderId: string; revisionId: string }> = ({
    orderId,
    revisionId,
}) => {
    const [modal, setModal] = React.useState<'closed' | 'initial' | 'abandonment' | 'calendly'>('closed');
    const history = useHistory();

    const rawMtn = useMutation(VeneerScheduleLiveDDPCall_Mutation);
    const { submit } = useRootActionCommand(rawMtn, {
        onSuccess: async () => {
            setModal('closed');
            history.push(`/orders/${orderId}`);
        },
        successMessage: 'Live Design Review successfully scheduled!',
    });

    // We normally allow 2 days past DDP due date for the customer to review the DDP before a BPO agent will auto approve it.
    // If the customer is pausing the order, that time is also paused.
    // Thus, the time they will have to approve the DDP _after_ the meeting is equal to how much time they have left _now_.
    // ie, if they have 1 day left at the time of placing the pause, they will have 1 day left when the pause is removed.
    const { order } = useGuidedWaxupContext();
    const designPreviewETA = order.practice_dates.digital_design_preview_estimated_completion_date
        ? new Date(order.practice_dates.digital_design_preview_estimated_completion_date)
        : new Date();
    const reviewDue = addBusinessDays(designPreviewETA, 2);
    const numberOfDaysUntilAutoApproveDdp = dayjs(reviewDue).diff(dayjs(), 'day');

    const isEnabled = useIsOrderEligibleForLiveDesignReview();
    if (!isEnabled) {
        return null;
    }

    return (
        <>
            <GuidedWaxupScheduleLiveCallInitialModal
                open={modal === 'initial'}
                openModal={() => {
                    BrowserAnalyticsClientFactory.Instance?.track(
                        'Practice - Portal - Live Design Review - Initiated Scheduling Flow',
                        {
                            $groups: { order: order.id },
                        },
                    );
                    setModal('initial');
                }}
                openAbandomentModal={() => setModal('abandonment')}
                openCalendlyModal={() => {
                    BrowserAnalyticsClientFactory.Instance?.track(
                        'Practice - Portal - Live Design Review - Opened Calendly Scheduler',
                        {
                            $groups: { order: order.id },
                        },
                    );
                    setModal('calendly');
                }}
                numberOfDaysUntilAutoApproveDdp={numberOfDaysUntilAutoApproveDdp}
            />
            {modal === 'abandonment' && (
                <GuidedWaxupScheduleLiveCallAbandonmentModal
                    closeRootModal={reason => {
                        BrowserAnalyticsClientFactory.Instance?.track(
                            'Practice - Portal - Live Design Review - Abandoned Scheduling Flow',
                            {
                                $groups: { order: order.id },
                                reason,
                            },
                        );
                        setModal('closed');
                    }}
                />
            )}
            {modal === 'calendly' && (
                <GuidedWaxupScheduleLiveCallCalendlyModal
                    orderId={orderId}
                    revisionId={revisionId}
                    variant={OrthlyBrowserConfig.env === 'production' ? 'production' : 'development'}
                    onModalClose={() => {
                        setModal('abandonment');
                    }}
                    submit={args => submit({ args })}
                />
            )}
        </>
    );
};
