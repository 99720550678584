import { MapMarkerIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    practiceProfilePhoto: {
        width: 136,
        height: 136,
        borderRadius: 16,
        fontSize: 32,
        aspectRatio: '1',
        color: FlossPalette.STAR_GRASS,
        overflow: 'hidden',
        backgroundSize: 'cover',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: FlossPalette.DARK_TAN,
    },
    selectedStyles: {
        borderWidth: 2,
        borderStyle: 'solid',
    },
}));

export const PracticeProfilePhoto: React.VFC<{ selected: boolean }> = ({ selected }) => {
    const classes = useStyles();
    return (
        <Grid
            item
            className={clsx(classes.practiceProfilePhoto, {
                [classes.selectedStyles]: selected,
            })}
        >
            <MapMarkerIcon style={{ width: 24, height: 34 }} viewBox={'0 0 24 34'} />
        </Grid>
    );
};
