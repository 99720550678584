import FlossToothHappy from '../../../assets/images/FlossToothHappy.svg';
import FlossToothSad from '../../../assets/images/FlossToothSad.svg';
import { FlossPalette, stylesFactory, useScreenIsMobile, Grid, Text } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

interface OrderDesignPreviewPlaceholderProps {
    text?: string;
    isError?: boolean;
}

const useStyles = stylesFactory(_ => ({
    root: {
        margin: 24,
        alignItems: 'center',
        spacing: 0,
    },
}));

export const OrderDesignPreviewPlaceholder: React.FC<OrderDesignPreviewPlaceholderProps> = props => {
    const classes = useStyles(props);
    const isMobile = useScreenIsMobile();
    const { text = `We're working on this, hang tight!`, isError = false } = props;
    return (
        <Grid
            container
            className={cx(classes.root)}
            justifyContent={isMobile ? 'flex-end' : 'center'}
            direction={'column'}
        >
            <Grid item>
                <img src={isError ? FlossToothSad : FlossToothHappy} alt={text} />
            </Grid>
            <Grid item>
                <Text style={{ color: isError ? FlossPalette.ATTENTION : FlossPalette.GRAY }}>{text}</Text>
            </Grid>
        </Grid>
    );
};

export { FlossToothHappy, FlossToothSad };
