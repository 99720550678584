import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const LOCAL_STORAGE_LAST_LOGIN_WAS_PASSWORD_KEY = 'LOCAL_STORAGE_LAST_LOGIN_WAS_PASSWORD_KEY';

type LoginPref = 'email' | 'phone';
const lastLoginWasPasswordAtom = atomWithStorage<boolean>(LOCAL_STORAGE_LAST_LOGIN_WAS_PASSWORD_KEY, false);

export const useLoginPrefValue = (): LoginPref => (useAtomValue(lastLoginWasPasswordAtom) ? 'email' : 'phone');

export const useSetLoginPref = () => {
    const setLoginPref = useSetAtom(lastLoginWasPasswordAtom);

    return (next: LoginPref) => setLoginPref(next === 'email');
};
