import { usePortalToChairsideBridgeCommand } from '../../utils/chairside.hooks';
import { useSelectStaffPropSelector } from '../select-staff/state/select-staff.selectors';
import type { RefabOrReturnSubmitData, RefabOrReturnSubmitResult, TextUploadLinkResult } from '@orthly/dentin';
import {
    ATTACH_REFAB_FILES_PATH,
    OrderToolbar,
    PracticeFullScreenDialog,
    PracticeScreen,
    RefabFlowWrapper,
} from '@orthly/dentin';
import {
    useCreateReturnForRefabMutation,
    useGetDoctorPreferencesByIdQuery,
    useMarkNeedsRefabricationMutation,
    useOrder,
    usePartnerNotificationPrefsQuery,
    useReasonCodesForOrderItemQuery,
    useSendRefabFilesUploadLinkMutation,
} from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn, LoadBlockerLoader } from '@orthly/ui';
import type { ImpersonatedQRCodeLinkResult } from '@orthly/veneer';
import {
    RefabFileUploader,
    RefabSupplementalInfoStepOne,
    RefabSupplementalInfoStepTwoGraphql,
    useImpersonatedQrCodeLink,
} from '@orthly/veneer';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const RefabFlowRoot: React.VFC = () => {
    const { orderId: order_id } = useParams<{ orderId: string }>();
    const {
        order,
        loading: loadingOrder,
        refetch,
    } = useOrder(order_id, {
        fetchPolicy: 'no-cache',
    });
    const { order: refabricatedOrder } = useOrder(order?.refabrication_order_id ?? '', {
        skip: !order?.refabrication_order_id,
    });

    const { data: doctorPreferences } = useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: order?.doctor_preferences_id ?? '' },
        skip: !order?.doctor_preferences_id,
    });
    const history = useHistory();
    React.useEffect(() => {
        if (!loadingOrder && !order) {
            history.replace('/');
        }
    }, [loadingOrder, order, history]);

    const openChairsidePdf = usePortalToChairsideBridgeCommand('openPdf');
    const [submitFullRefabMtn] = useMarkNeedsRefabricationMutation();
    const [submitReturnMtn] = useCreateReturnForRefabMutation();
    const mtnSubmitter = React.useCallback(
        async ({ command, needsReturn }: RefabOrReturnSubmitData) => {
            if (needsReturn) {
                // returns are submitted here
                return await submitReturnMtn({
                    variables: {
                        data: command,
                    },
                });
            }
            return submitFullRefabMtn({
                variables: {
                    data: command,
                },
            });
        },
        [submitReturnMtn, submitFullRefabMtn],
    );
    const { submit, submitting } = useChangeSubmissionFn<RefabOrReturnSubmitResult, [RefabOrReturnSubmitData]>(
        mtnSubmitter,
        {},
    );

    // this function generates the base url required for both the text message and the qr code.
    // It will get impersonated / shortened automatically by the QR code component and the send text mutation (useSendRefabFilesUploadLinkMutation).
    function useMobileURL(additionalPath?: string) {
        const mobileURL = React.useMemo(() => {
            const rootUrl = `${PracticeScreen.refab_flow}/${order_id}/${ATTACH_REFAB_FILES_PATH}`;
            const fullUrl = additionalPath ? `${rootUrl}/${additionalPath}` : rootUrl;

            const mobileURL = new URL(fullUrl, window.location.origin);

            return mobileURL.toString();
        }, [additionalPath]);
        return mobileURL;
    }

    function useTextUploadLink(uploadLink: string): TextUploadLinkResult {
        const session = useSession();
        const practiceId = session?.organization_id ?? '';

        // autofill phoneNumber
        const [phoneNumber, setPhoneNumber] = React.useState<string>('');
        usePartnerNotificationPrefsQuery({
            onCompleted: ({ getPartnerPreferences }) => setPhoneNumber(getPartnerPreferences?.phone_number ?? ''),
        });

        const { profile } = useSelectStaffPropSelector(['profile']);

        const [sendTextMtn] = useSendRefabFilesUploadLinkMutation();
        const textSubmitter = async (phoneNumber: string) => {
            await sendTextMtn({
                variables: {
                    data: {
                        upload_link: uploadLink,
                        doctor_name: profile?.name,
                        practice_id: practiceId,
                        phone_number: phoneNumber,
                    },
                },
            });
        };
        const { submit: sendText, submitting } = useChangeSubmissionFn<void, [string]>(textSubmitter, {
            successMessage: () => ['Check your phone for the upload link!', {}],
        });

        return {
            sendText,
            submitting,
            placeholderPhoneNumber: phoneNumber,
        };
    }

    const useGenerateUploaderConfig = (
        additionalPath?: string,
    ): { qrCode: ImpersonatedQRCodeLinkResult; textUploadLink: TextUploadLinkResult } => {
        const mobileUrl = useMobileURL(additionalPath);
        const qrCode = useImpersonatedQrCodeLink(mobileUrl);
        const textUploadLink = useTextUploadLink(mobileUrl);

        return {
            qrCode,
            textUploadLink,
        };
    };

    if (!order && loadingOrder) {
        return <LoadBlockerLoader blocking={loadingOrder} />;
    }

    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ overflowY: 'auto', overflowX: 'clip' }}>
            <OrderToolbar order={order} onClose={() => history.goBack()} />
            <RefabFlowWrapper
                order={order}
                refabricatedOrder={refabricatedOrder}
                loadingOrder={loadingOrder}
                doctorPreferences={doctorPreferences?.preferences}
                refetch={refetch}
                submit={submit}
                submitting={submitting}
                openChairsidePdf={openChairsidePdf}
                // these screens relies on veneer code (which can't be imported into dentin)
                // we use dependency injection to provide it.
                SupplementalInfoStepOne={RefabSupplementalInfoStepOne}
                SupplementalInfoStepTwo={RefabSupplementalInfoStepTwoGraphql}
                // this uploader relies on veneer code (which can't be imported into dentin)
                // we use dependency injection to provide it.
                RefabFileUploader={RefabFileUploader}
                useGenerateUploaderConfig={useGenerateUploaderConfig}
                useReasonCodesForOrderItemQuery={useReasonCodesForOrderItemQuery}
            />
        </PracticeFullScreenDialog>
    );
};
