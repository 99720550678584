import type { PauseOrResumeOrderActionProps } from './PauseOrResumeOrderAction.types';
import { PauseOrderAction } from './PauseOrderAction';
import { ResumeOrderAction } from './ResumeOrderAction';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

type PauseOrResumeOrderActionInnerProps = PauseOrResumeOrderActionProps & {
    enablePauseOrder: boolean;
};

export const PauseOrResumeOrderActionInner: React.VFC<PauseOrResumeOrderActionInnerProps> = ({
    order,
    enablePauseOrder,
    refetch,
    ...others
}) => {
    const shouldShowResumeButton = order.hold_details?.hold_is_practice_managed_pause;
    const shouldShowPauseButton = order.can_put_on_hold;

    if (!enablePauseOrder) {
        return null;
    }

    if (shouldShowResumeButton) {
        return <ResumeOrderAction order={order} {...others} />;
    }

    if (shouldShowPauseButton) {
        return <PauseOrderAction order={order} refetch={refetch} {...others} />;
    }

    return null;
};

export const PauseOrResumeOrderAction: React.VFC<PauseOrResumeOrderActionProps> = props => {
    const { value: enablePauseOrder } = useFeatureFlag('enablePauseOrder');

    return <PauseOrResumeOrderActionInner {...props} enablePauseOrder={Boolean(enablePauseOrder)} />;
};
