import { useStructuredNotesOptions } from '../../../../hooks/useStructuredNotesOptions';
import type { PresetInfo } from '../../../../state/GuidedWaxupState';
import { useGuidedWaxupAction } from '../../../../state/GuidedWaxupState';
import { NestedCheckboxList, nestedCheckboxListUtils } from '@orthly/dentin';
import type { LabsGqlDesignOrderNoteCategory, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Box, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

export const PresetReviewCheckboxControl: React.VFC<{
    areControlsDisabled: boolean;
    preset: PresetInfo;
    presetType: LabsGqlGuidedWaxupPresetType;
}> = ({ areControlsDisabled, preset, presetType }) => {
    const setPresetStructuredRejectionNotes = useGuidedWaxupAction('SET_PRESET_STRUCTURED_REJECTION_NOTES');
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const options = useStructuredNotesOptions(presetType);
    const structuredNotes = React.useMemo(
        () => nestedCheckboxListUtils.unflattenSelections(preset?.structured_notes ?? [], options),
        [options, preset],
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '16px',
                flexDirection: isMobile ? 'column' : 'row',
            }}
        >
            {options.map(option => (
                <Box
                    key={option.category}
                    sx={{ width: 'max-content', maxWidth: '350px', minWidth: '100px', marginRight: '16px' }}
                >
                    <NestedCheckboxList
                        disabled={areControlsDisabled}
                        options={[option]}
                        selections={structuredNotes}
                        onChange={selections =>
                            setPresetStructuredRejectionNotes({
                                presetName: presetType,
                                structuredRejectionNotes: nestedCheckboxListUtils.flattenSelections(
                                    selections,
                                ) as LabsGqlDesignOrderNoteCategory[],
                            })
                        }
                    />
                </Box>
            ))}
        </Box>
    );
};
