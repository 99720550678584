import { SelectStaffContent, SelectStaffFloatButton } from '../components';
import { useSelectStaffAction } from '../state/select-staff.actions';
import { CreateStaffStep } from '../state/select-staff.progression';
import { useSelectStaffSelector } from '../state/select-staff.selectors';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { FlossPalette, stylesFactory, Grid, Button, Text } from '@orthly/ui-primitives';
import { PersonCardOccupationOptionsDisplay } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    button: {
        padding: 16,
        backgroundColor: FlossPalette.WHITE,
        borderRadius: 16,
        height: 72,
        '&>.MuiButton-label': {
            color: FlossPalette.BLACK,
            width: '100%',
            justifyContent: 'space-between',
        },
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(min(100%, 328px), 1fr))',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        flexGrow: 1,
        gap: '24px',
        [theme.breakpoints.down('lg')]: {
            gap: '16px',
        },
    },
}));

const OccupationButton: React.FC<{ onClick: () => void }> = props => {
    const { children, onClick } = props;
    const classes = useStyles();
    return (
        <Button variant={'secondary'} onClick={onClick} endIcon={'ChevronRight'} className={classes.button}>
            {children}
        </Button>
    );
};

export const SelectStaffChooseOccupation: React.VFC = () => {
    const addCreationField = useSelectStaffAction('ADD_STAFF_CREATION_FIELD');
    const prevStep = useSelectStaffAction('PREV_SELECT_STAFF_STEP');
    const classes = useStyles();
    const organization_name = useSession()?.organization_name;
    const show = useSelectStaffSelector(({ step }) => step === CreateStaffStep.Occupation);
    return (
        <SelectStaffContent show={show}>
            <SelectStaffFloatButton onClick={prevStep}>Return to profiles</SelectStaffFloatButton>
            <Text variant={'h4'} medium>
                What is your occupation{organization_name ? ` at ${organization_name}` : ''}?
            </Text>
            <Grid className={classes.grid} style={{ marginTop: 32 }}>
                {Object.values(LabsGqlStaffRolePractice).map(occupation =>
                    occupation === LabsGqlStaffRolePractice.Admin ? null : (
                        <OccupationButton
                            onClick={() => addCreationField({ occupation }, { autoNextStep: true })}
                            key={occupation}
                        >
                            {PersonCardOccupationOptionsDisplay[occupation]}
                        </OccupationButton>
                    ),
                )}
                <OccupationButton
                    onClick={() => addCreationField({ occupation: null }, { autoNextStep: true })}
                    key={'other'}
                >
                    Other
                </OccupationButton>
            </Grid>
        </SelectStaffContent>
    );
};
