import type { LabsGqlOrderTimelineV2EventIconEnum } from '@orthly/graphql-schema';
import { FlossPalette, Icon } from '@orthly/ui-primitives';

export const OrderTimelineIcons: Record<LabsGqlOrderTimelineV2EventIconEnum, React.ReactNode> = {
    add: <Icon icon={'AddIcon'} style={{ fill: FlossPalette.LIGHT_GRAY }} />,
    arrowForward: <Icon icon={'ArrowForward'} style={{ fill: FlossPalette.LIGHT_GRAY }} />,
    cancel: <Icon icon={'CancelIcon'} style={{ height: 18, width: 18, fill: FlossPalette.ATTENTION_FOREGROUND }} />,
    checkGray: <Icon icon={'CheckIcon'} style={{ fill: FlossPalette.LIGHT_GRAY }} />,
    checkPrimary: <Icon icon={'CheckIcon'} style={{ fill: FlossPalette.PRIMARY_FOREGROUND }} />,
    pencil: <Icon icon={'EditIcon'} style={{ width: 10, height: 10, fill: FlossPalette.LIGHT_GRAY }} />,
    priorityHigh: (
        <Icon icon={'PriorityHigh'} style={{ height: 12, width: 12, fill: FlossPalette.ATTENTION_FOREGROUND }} />
    ),
    refab: <Icon icon={'Replay'} style={{ transform: 'scaleX(-1)', fill: FlossPalette.LIGHT_GRAY }} />,
    x: <Icon icon={'CloseIcon'} style={{ height: 14, width: 14, fill: FlossPalette.LIGHT_GRAY }} />,
    dangerous: <Icon icon={'DangerousIcon'} style={{ fill: FlossPalette.WARNING }} />,
};
