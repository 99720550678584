import { DownloadIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, IconButton, Tooltip } from '@orthly/ui-primitives';
import type { FirebasePreviewFileMulti } from '@orthly/veneer';
import { useFirebaseFileDownload } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    attachment: {
        display: 'flex',
        gap: '1ch',
        alignItems: 'center',
    },
    fileName: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontWeight: 'bold',
        color: FlossPalette.PRIMARY_FOREGROUND,
        cursor: 'pointer',
    },
}));

const useDownloadAttachment = ({ source, name }: FirebasePreviewFileMulti) => {
    const downloadAttachmentFromFirebase = useFirebaseFileDownload(source, name);

    return () => {
        if (/https?:\/\//.test(source)) {
            window.open(source, '_blank', 'noreferrer noopener');
        } else {
            void downloadAttachmentFromFirebase.execute();
        }
    };
};

export const OrderTimelineDownload: React.FC<FirebasePreviewFileMulti> = props => {
    const classes = useStyles();
    const downloadAttachment = useDownloadAttachment(props);

    return (
        <Tooltip title={props.name}>
            <div className={classes.attachment} onClick={downloadAttachment}>
                <span className={classes.fileName}>{props.name}</span>
                <IconButton size={'small'} color={'primary'}>
                    <DownloadIcon />
                </IconButton>
            </div>
        </Tooltip>
    );
};
