import { Button, FlossPalette, styled, Text } from '@orthly/ui-primitives';

export const FileLayout = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px 8px',
    '&:not(:last-child)': {
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    },
});

export const FileTitleLayout = styled('div')({
    display: 'flex',
    maxWidth: 300,
    paddingRight: 16,
});

export const ScanFileContainer = styled('div')({
    '&:not(:last-child)': {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
});

export const ScanFileLayout = styled('div')({
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
});

export const ScanFileControlsLayout = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .MuiButton-root': {
        marginLeft: 16,
    },
});

export const ScanFileTitleLayout = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const ScanFileTitle = styled(Text)({
    paddingLeft: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 300,
});

export const ScanFileButton = styled(Button)(({ isPreviewing }: { isPreviewing?: boolean }) => ({
    minWidth: 'unset',
    height: 'fit-content',
    padding: 0,
    background: isPreviewing ? FlossPalette.PRIMARY_BACKGROUND : 'transparent',
    color: isPreviewing ? FlossPalette.STAR_GRASS_ACTIVE : undefined,
    '&:hover': {
        background: isPreviewing ? FlossPalette.PRIMARY_BACKGROUND : 'transparent',
        color: isPreviewing ? FlossPalette.STAR_GRASS_ACTIVE : undefined,
    },
}));

export const ScanTypeAssignmentLayout = styled('div')({
    display: 'flex',
    width: 'calc(100% - 40px)',
    marginLeft: 40,
});

export const SubScanFileContainer = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '16px 8px',
});

export const SubScanFileLayout = styled('div')({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 40,
    flexWrap: 'nowrap',
    flexDirection: 'row',
});

export const SubScanFileControlsLayout = styled('div')({
    display: 'flex',
    maxWidth: '50%',
    flexDirection: 'column',
    alignItems: 'flex-start',
});

export const SubScanTypeAssignmentLayout = styled('div')({
    display: 'flex',
    width: '50%',
    alignItems: 'center',
    paddingLeft: 20,
    '& .MuiButton-root': {
        marginLeft: 16,
    },
});
