import {
    useSendScanbodySurgicalReportUploadLinkMutation,
    usePartnerNotificationPrefsQuery,
} from '@orthly/graphql-react';
import { ValidationUtils } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { ActionCard, SimpleInput, LoadBlocker, useChangeSubmissionFn } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    FlossPaletteUtils,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Link,
    Button,
    Text,
} from '@orthly/ui-primitives';
import * as qrCode from 'qrcode';
import React from 'react';
import { useAsync } from 'react-async-hook';

const useStyles = stylesFactory(() => ({
    root: {
        position: 'relative',
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        overflow: 'hidden',
        padding: 24,
        height: '100%',
    },
    subtitle: { marginTop: 8 },
    handQrScanImage: { width: '55%', position: 'absolute', right: -60, top: 100 },
    qrCode: {
        maxWidth: '100%',
        maxHeight: '100%',
        boxShadow: `0 0 3px ${FlossPaletteUtils.toRgba('BLACK', 0.16)}`,
        borderRadius: '16px',
    },
    getSmsText: { fontWeight: 500, width: '100%', height: 'fit-content', marginTop: 12, color: FlossPalette.GRAY },
    sendCTA: { marginTop: 16, alignItems: 'center' },
    sendButton: {
        marginLeft: 8,
        height: '75%',
    },
    desktopImage: { flexGrow: 1, justifyContent: 'center', alignItems: 'flex-end' },
    btnDesktopUpload: { marginTop: 16, backgroundColor: FlossPalette.WHITE, width: '65%' },
}));

interface SendImageUploadLinkTextCTAProps {
    uploadLink: string;
    doctorName?: string;
}

const SendImageUploadLinkTextCTA: React.FC<SendImageUploadLinkTextCTAProps> = props => {
    const classes = useStyles();
    const { uploadLink, doctorName } = props;
    const practiceId = useSession()?.organization_id;

    // autofill phoneNumber
    const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>();
    const { loading } = usePartnerNotificationPrefsQuery({
        onCompleted: ({ getPartnerPreferences }) => setPhoneNumber(getPartnerPreferences?.phone_number ?? undefined),
    });

    const [sendTextMtn] = useSendScanbodySurgicalReportUploadLinkMutation();
    const textSubmitter = async () => {
        if (!phoneNumber) {
            // Button.disabled={!isNumber} so we should never need this
            throw new Error(`Please enter a phone number`);
        }
        if (!practiceId) {
            // if !practiceId no parent will render this component so we should never need this
            throw new Error(`Practice info was not found`);
        }
        await sendTextMtn({
            variables: {
                data: {
                    upload_link: uploadLink,
                    doctor_name: doctorName,
                    practice_id: practiceId,
                    phone_number: phoneNumber,
                },
            },
        });
    };
    const { submit: sendText, submitting: sending } = useChangeSubmissionFn<void, []>(textSubmitter, {
        successMessage: () => ['Check your phone for the upload link!', {}],
    });
    const isValidPhoneNumber = React.useMemo(() => phoneNumber && ValidationUtils.isPhone(phoneNumber), [phoneNumber]);

    return (
        <LoadBlocker blocking={sending} ContainerProps={{ className: classes.sendCTA }}>
            <SimpleInput
                onChange={setPhoneNumber}
                value={phoneNumber}
                label={'Phone Number'}
                TextFieldProps={{
                    autoFocus: true,
                    type: 'tel',
                    error: !loading && !isValidPhoneNumber,
                    helperText: !isValidPhoneNumber ? 'Please enter a valid phone number' : undefined,
                }}
            />
            <Button
                variant={'primary'}
                onClick={sendText}
                disabled={!isValidPhoneNumber}
                className={classes.sendButton}
            >
                Send
            </Button>
        </LoadBlocker>
    );
};

interface ScanbodySurgicalReportUploaderMethodSelectionProps {
    mobileURL: string;
    onDesktopUpload?(): void;
    SendImageUploadLinkTextCTAComponent?: React.FC<SendImageUploadLinkTextCTAProps>;
    doctorName?: string;
}

export const ScanbodySurgicalReportUploaderMethodSelection: React.FC<
    ScanbodySurgicalReportUploaderMethodSelectionProps
> = props => {
    const classes = useStyles();
    const {
        mobileURL,
        onDesktopUpload,
        doctorName,
        SendImageUploadLinkTextCTAComponent = SendImageUploadLinkTextCTA,
    } = props;
    const [showTextInput, setShowTextInput] = React.useState(false);
    const qrCodeCanvas = React.useRef<HTMLCanvasElement | null>(null);
    const { error: qrCodeError, loading: qrCodeLoading } = useAsync(async () => {
        if (qrCodeCanvas.current) {
            await qrCode.toCanvas(qrCodeCanvas.current, mobileURL);
        }
    }, [mobileURL]);

    const isMobile = useScreenIsMobileOrVerticalTablet();

    return (
        <Grid container spacing={3}>
            {!isMobile && (
                <Grid item xs={7}>
                    <Grid container alignContent={'flex-start'} direction={'column'} className={classes.root}>
                        <img
                            src={'/checkout/qr-scan.png'}
                            alt={'Phone scanning QR code'}
                            className={classes.handQrScanImage}
                        />
                        <Text variant={'h6'}>Using your phone?</Text>
                        <Text variant={'body2'} className={classes.subtitle}>
                            Scan the code below
                        </Text>
                        {qrCodeError && (
                            <Grid style={{ paddingTop: 12 }}>
                                <ActionCard
                                    variant={'alert'}
                                    title={'Failed to load the QR code.'}
                                    subtitle={'Please try using our texting service below, or the desktop uploader.'}
                                />
                            </Grid>
                        )}
                        {!qrCodeError && (
                            <LoadBlocker
                                blocking={qrCodeLoading}
                                ContainerProps={{ style: { width: 168, height: 168 } }}
                            >
                                <canvas ref={qrCodeCanvas} className={classes.qrCode} />
                            </LoadBlocker>
                        )}

                        <Text variant={'body2'} className={classes.getSmsText}>
                            {'Not working? '}
                            <Link
                                onClick={() => setShowTextInput(true)}
                                style={{ color: FlossPalette.STAR_GRASS, cursor: 'pointer' }}
                            >
                                Get a text instead
                            </Link>
                        </Text>

                        {showTextInput && (
                            <SendImageUploadLinkTextCTAComponent uploadLink={mobileURL} doctorName={doctorName} />
                        )}
                    </Grid>
                </Grid>
            )}

            <Grid item className={classes.root} style={{ marginLeft: isMobile ? 0 : 24, flexGrow: 1 }}>
                <Grid container alignContent={'flex-start'} direction={'column'} className={classes.root}>
                    <Text variant={'h6'}>Using your {isMobile ? `phone` : 'laptop'}?</Text>
                    <Text variant={'body2'} className={classes.subtitle}>
                        Hit the button below
                    </Text>
                    <Button variant={'secondary'} onClick={onDesktopUpload} className={classes.btnDesktopUpload}>
                        Upload using {isMobile ? `phone` : 'laptop'}
                    </Button>
                    <Grid container className={classes.desktopImage} style={{ marginTop: isMobile ? 12 : undefined }}>
                        <img src={isMobile ? '/checkout/qr-scan.png' : '/checkout/desktop-upload.png'} alt={''} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
