import { trackGuidedWaxupButtonClick } from './DesignViewerLiteDetachedContainer';
import { useGuidedWaxupContext } from './GuidedWaxups/state/GuidedWaxupContext';
import { useZoomToVisible } from '@orthly/dentin';
import { Box, Button, ButtonGroup, Icon, styled, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import type { IconName } from '@orthly/ui-primitives';
import React from 'react';

const StyledButton = styled(Button)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
    [theme.breakpoints.down('lg')]: {
        height: '58px',
    },
}));

const AppearanceIconButton: React.VFC<{ icon: IconName; label: string; onClick: () => void }> = ({
    icon,
    label,
    onClick,
}) => {
    const isLarge = useScreenIsLgOrUp();

    return (
        <StyledButton variant={'secondary'} onClick={onClick}>
            <Icon icon={icon} />
            {isLarge && label}
        </StyledButton>
    );
};

const ResetViewButton: React.VFC = () => {
    const { resetView } = useGuidedWaxupContext();

    const onClick = React.useCallback(() => {
        trackGuidedWaxupButtonClick('Reset camera view');
        resetView();
    }, [resetView]);

    return <AppearanceIconButton icon={'XYZArrowsIcon'} label={'Reset View'} onClick={onClick} />;
};

const ZoomToFitButton: React.VFC = () => {
    const { appearance, controlRef } = useGuidedWaxupContext();
    const zoomToFit = useZoomToVisible(controlRef, appearance);

    const onClick = React.useCallback(() => {
        trackGuidedWaxupButtonClick('Zoom To Fit');
        zoomToFit();
    }, [zoomToFit]);

    return <AppearanceIconButton icon={'ZoomInIcon'} label={'Zoom to Fit'} onClick={onClick} />;
};

export const ModelViewControls = () => {
    const isLarge = useScreenIsLgOrUp();

    return isLarge ? (
        <Box sx={{ display: 'flex', gap: '16px' }}>
            <ResetViewButton />
            <ZoomToFitButton />
        </Box>
    ) : (
        <ButtonGroup>
            <ResetViewButton />
            <ZoomToFitButton />
        </ButtonGroup>
    );
};
