import { Link, Text, styled } from '@orthly/ui-primitives';

const FooterText = styled(props => <Text {...props} bold color={'PRIMARY_FOREGROUND'} variant={'body2'} />)({
    display: 'flex',
    width: '100%',
    alignItems: 'center',

    '& svg': {
        fontSize: 'inherit',
        position: 'relative',
        top: 2,
    },
});

export const CenteredFooterText = styled(FooterText)({
    justifyContent: 'center',
});

export const JustifiedFooterText = styled(FooterText)(({ theme }) => ({
    justifyContent: 'space-between',
    gap: '16px',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
    },
}));

export const FooterLink = styled(Link)({
    color: 'inherit',
    cursor: 'pointer',
});
