import type { SimpleSelectOption } from '../../SimpleForm';
import type { FieldDefRadioGroup } from '../QuickForm.types';
import { FormikRootField } from '../QuickForm.types';
import { QFHelperText } from './QFHelperText';
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    Grid,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface QFRadioGroupFieldProps {
    fieldDef: FieldDefRadioGroup & { name: string };
}

const isStringArray = (options: string[] | SimpleSelectOption[]): options is string[] => typeof options[0] === 'string';

export const QFRadioGroupField: React.FC<QFRadioGroupFieldProps> = ({ fieldDef }) => {
    const commonProps = { name: fieldDef.name, label: fieldDef.label || _.startCase(fieldDef.name) };
    const options: SimpleSelectOption[] = isStringArray(fieldDef.options)
        ? fieldDef.options.map(str => ({ value: str, label: str }))
        : fieldDef.options;
    return (
        <Grid item xs={12} {...fieldDef.layout}>
            <FormikRootField
                name={fieldDef.name}
                render={formikProps => {
                    const { form, field } = formikProps;
                    return (
                        <FormControl
                            variant={'standard'}
                            style={{ width: '100%', display: fieldDef.hidden ? 'none' : undefined }}
                        >
                            {fieldDef.overrideToEdit ? /* the override wrapper has a field label */ null : (
                                <Grid container>
                                    <InputLabel
                                        required={!fieldDef.optional}
                                        htmlFor={field.name}
                                        style={{
                                            position: 'relative',
                                            transform: 'unset',
                                            paddingBottom: 12,
                                            marginLeft: 4,
                                            ...fieldDef.InputLabelProps?.style,
                                        }}
                                    >
                                        {commonProps.label}
                                    </InputLabel>
                                </Grid>
                            )}

                            <Grid container>
                                <RadioGroup
                                    style={{ flexDirection: 'row', ...fieldDef.radioGroupStyle }}
                                    name={field.name}
                                    value={field.value}
                                    onChange={evt => {
                                        form.setFieldTouched(field.name, true);
                                        form.setFieldValue(field.name, evt.target.value);
                                    }}
                                    data-test={`quick-form-field-${field.name}`}
                                >
                                    {options.map(option => (
                                        <FormControlLabel
                                            key={option.value}
                                            control={<Radio color={'secondary'} />}
                                            label={option.label}
                                            value={option.value}
                                        />
                                    ))}
                                </RadioGroup>
                            </Grid>

                            <QFHelperText
                                text={fieldDef.helperText}
                                style={fieldDef.fieldProps?.FormHelperTextProps?.style}
                            />
                        </FormControl>
                    );
                }}
            />
        </Grid>
    );
};
