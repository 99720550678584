import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { useSetScreen } from '../../../../utils/useSetScreen';
import { InboxUpsellItemDisplay } from './InboxUpsellItemDisplay';
import type { CtaAnalyticsEventData } from '@orthly/analytics/dist/browser';
import { useAnalyticsTrackCtaShown } from '@orthly/analytics/dist/browser';
import type { PracticeScreen } from '@orthly/dentin';
import { useOrders } from '@orthly/graphql-react';
import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import type { BooleanFlagKey, StringFlagKey } from '@orthly/shared-types';
import type { FlossColor } from '@orthly/ui';
import React from 'react';

export type InboxUpsellSkuType =
    | 'clear-aligners'
    | 'implants'
    | 'implants-v2'
    | 'dentures'
    | 'night-guards'
    | 'scanner-os'
    | 'referrals'
    | 'holiday-rush'
    | 'loyalty'
    | 'wellness-scan-insurance'
    | 'ddp-webinar';

export interface InboxUpsellItemConfigProps {
    assetName: string; // used for event tracking
    title: string | React.ReactNode;
    subtitle: string | React.ReactNode;
    ctaText: string;
    destination: { url: string } | { screen: PracticeScreen };
    imgSrc: string;
    skuType: InboxUpsellSkuType;
    // set filterCriteria to null to entirely skip querying
    filterCriteria: LabsGqlFilterCriteriaSubmissionInput[] | null;
    featureFlagKey: StringFlagKey | BooleanFlagKey;
    checkFeatureEnabled?: (flagValue: boolean | string) => boolean;
    backgroundColor?: FlossColor;
    imgStyle?: React.CSSProperties;
    ctaButtonColor?: FlossColor;
}

function getUpsellBannerEventData(ctaText: string, assetName: string): CtaAnalyticsEventData {
    return {
        AssetName: assetName,
        AssetCTAText: ctaText,
        AssetLocation: 'Right rail',
        AssetType: 'banner',
        AssetVersion: 'v1.0',
    };
}

function useTrackUpsellBannerEvent(assetName: string) {
    return React.useCallback(
        (ctaText: string) => {
            AnalyticsClient.track('Button Clicked', getUpsellBannerEventData(ctaText, assetName));
        },
        [assetName],
    );
}

export const InboxUpsellComponent: React.FC<{ config: InboxUpsellItemConfigProps }> = props => {
    const setScreen = useSetScreen();
    const { config } = props;
    const { assetName, title, subtitle, ctaText, destination, imgSrc, skuType, filterCriteria } = config;

    const skipOrderQuerying = config.filterCriteria === null;

    const { orders: filteredOrders, loading: loadingFiltered } = useOrders(
        { limit: 1, criteria: filterCriteria },
        { skip: skipOrderQuerying },
    );
    const { orders: unfilteredOrders, loading: loadingUnfiltered } = useOrders(
        { limit: 1, criteria: [] },
        { skip: skipOrderQuerying },
    );
    const trackBannerClicked = useTrackUpsellBannerEvent(assetName);

    const isHidden = skipOrderQuerying
        ? false
        : loadingFiltered || loadingUnfiltered || filteredOrders.length || !unfilteredOrders.length;

    useAnalyticsTrackCtaShown(getUpsellBannerEventData(ctaText, assetName), !isHidden);

    if (isHidden) {
        return null;
    }

    const imgStyle = config.imgStyle ?? { bottom: -12, right: 36, maxHeight: 218, maxWidth: 218 };

    return (
        <InboxUpsellItemDisplay
            title={title}
            subtitle={subtitle}
            primaryAction={{
                text: ctaText,
                onClick: () => {
                    trackBannerClicked(ctaText);
                    if ('url' in destination) {
                        window.open(destination.url, '_blank');
                    } else {
                        setScreen(destination.screen)();
                    }
                },
                endIcon: 'ChevronRight',
                ctaButtonColor: config.ctaButtonColor,
            }}
            image={<img src={imgSrc} alt={skuType} style={imgStyle} />}
            backgroundColor={config.backgroundColor}
        />
    );
};
