import type { IconName } from '../../icons';
import { Icon } from '../../icons';
import { FlossPalette, styled } from '../../theme';
import { stylesFactory } from '../../utils';
import { Text } from '../Text';
import { SimpleTextField } from './SimpleTextInput';
import React from 'react';
import { v4 } from 'uuid';

const useStyles = stylesFactory(() => ({
    errorContent: {
        '&& .MuiInputBase-root': {
            background: FlossPalette.ATTENTION_BACKGROUND,
        },
    },
}));

const InputWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
}));

const Label = styled('label')(({ theme }) => ({
    fontFamily: 'Inter Regular, Verdana, sans-serif',
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    fontWeight: 600,
}));

const HelpText = styled(Text)({
    color: FlossPalette.GRAY,
    fontWeight: 400,
}) as React.FC<{ variant: 'caption'; style: { color: string } }>;

const StyledTextInput = styled(SimpleTextField)({
    gridArea: 'input',
    '&& .MuiInputBase-root': {
        padding: '8px',
    },
    '& .MuiInputBase-input': {
        padding: '12px 16px !important',
    },
    '& .Mui-disabled': {
        background: FlossPalette.DARK_TAN,
    },
});

export interface TextInputProps {
    /** The label for the input */
    label?: string;
    /** The error text for the input */
    errorText?: string;
    /** The help text for the input */
    helpText?: string;
    /** The function to update the value of the input */
    onChange: (value?: string) => void;
    /** The function to call when the input is blurred */
    onBlurOrFocus?: (type?: string, value?: string) => void;
    /** The value of the input */
    value: string;
    /** The placeholder text for the input */
    placeholder?: string;
    /** Whether the input is disabled */
    disabled?: boolean;
    /** Whether the help text should be displayed below the input */
    helpTextBottom?: boolean;
    /** The icon to display at the end of the input */
    icon?: IconName;
}

export const TextInput: React.VFC<TextInputProps> = props => {
    const classes = useStyles();
    const [id] = React.useState<string>(v4);
    const { label, helpText, errorText, helpTextBottom, icon, placeholder, ...rest } = props;
    const helpTextContent = (
        <HelpText variant={'caption'} style={{ color: FlossPalette.GRAY }}>
            {helpText}
        </HelpText>
    );
    const errorTextContent = errorText ? (
        <HelpText variant={'caption'} style={{ color: FlossPalette.ATTENTION }}>
            {errorText}
        </HelpText>
    ) : undefined;

    const showTopHelpText = Boolean(helpText && !helpTextBottom);
    const showBottomHelpText = Boolean(helpText && helpTextBottom && !errorText);

    return (
        <InputWrapper>
            {label && <Label htmlFor={id}>{label}</Label>}
            {showTopHelpText && helpTextContent}
            <StyledTextInput
                id={id}
                className={errorText ? classes.errorContent : undefined}
                {...rest}
                TextFieldProps={{
                    placeholder,
                    InputProps: {
                        endAdornment: icon ? <Icon icon={icon} /> : undefined,
                    },
                }}
            />
            {showBottomHelpText && helpTextContent}
            {errorTextContent}
        </InputWrapper>
    );
};
