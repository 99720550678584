import { useCheckoutAction } from '../../../state/checkout.actions';
import type { CheckoutItemV2 } from '../../../state/checkout.state';
import { ImplantSystemInput } from '@orthly/dentin';
import { useGetImplantTypesLiteQuery } from '@orthly/graphql-react';
import { AllSurgicalGuideDrillKits } from '@orthly/items';
import type { ICartItemSurgicalGuideImplantMetadata, LabOrderItemSKUType } from '@orthly/items';
import { SimpleAutocomplete, SimpleTextField } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';

interface CheckoutSurgicalGuideImplantMetadataFieldsProps {
    item: Extract<CheckoutItemV2, { sku: LabOrderItemSKUType.SurgicalGuide }>;
}

export const CheckoutSurgicalGuideImplantMetadataFields: React.VFC<CheckoutSurgicalGuideImplantMetadataFieldsProps> = ({
    item,
}) => {
    const updateItem = useCheckoutAction('UPDATE_LINE_ITEM');

    const { data: implantTypes } = useGetImplantTypesLiteQuery({ variables: { withDeleted: false } });

    const updateSurgicalGuideImplantMetadata = (payload: Partial<ICartItemSurgicalGuideImplantMetadata>) => {
        updateItem({ item_index: item.item_index, change: { payload, name: 'surgical_guide_implant_metadata' } });
    };

    const implantMetadata = item.unit.implant_metadata;

    return (
        <Grid container spacing={2} style={{ marginBottom: -16 }}>
            <Grid item xs={12}>
                <Text style={{ fontWeight: 500 }}>What implant will you use?</Text>
            </Grid>
            <Grid item md={6}>
                <ImplantSystemInput
                    hideConnection={true}
                    implantSystems={implantTypes?.getImplantTypesLite ?? []}
                    linkMeta={{
                        manufacturer: implantMetadata?.manufacturer ?? null,
                        system: implantMetadata?.system ?? null,
                        connection: null,
                    }}
                    updateField={(fieldName, value) => {
                        if (fieldName !== 'manufacturer' && fieldName !== 'system') {
                            return;
                        }
                        updateSurgicalGuideImplantMetadata({ [fieldName]: value });
                    }}
                />
            </Grid>
            <Grid item md={3}>
                <SimpleAutocomplete
                    label={'Drill Kit'}
                    options={AllSurgicalGuideDrillKits.map(kit => ({
                        value: kit,
                        label: kit,
                    }))}
                    onChange={val => updateSurgicalGuideImplantMetadata({ drill_kit: val ?? null })}
                    AutocompleteProps={{
                        value: implantMetadata.drill_kit,
                    }}
                />
            </Grid>
            <Grid item md={3}>
                <SimpleTextField
                    label={'Preferred Implant Size (Optional)'}
                    helperText={'Diameter x length (mm)'}
                    value={implantMetadata.desired_size ?? undefined}
                    onChange={val => updateSurgicalGuideImplantMetadata({ desired_size: val })}
                />
            </Grid>
        </Grid>
    );
};
