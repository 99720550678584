import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import { wellnessScansPhotoUploadConfig, PhotoUpload, useUploadedPhotoState } from '@orthly/veneer';
import React from 'react';
import { useParams } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    buttonWrapper: { padding: 16, flexGrow: 1, alignItems: 'flex-start' },
    root: {
        alignItems: 'center',
        paddingTop: 32,
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: FlossPalette.WHITE,
        position: 'absolute',
        zIndex: 1202,
    },
    grid: { marginBottom: 16, flexDirection: 'column', justifyContent: 'flex-start', padding: 4, width: 120 },
    titleWrapper: { width: '100%', paddingLeft: 16, paddingRight: 16, marginBottom: 16, textAlign: 'center' },
}));

export const WellnessScanMobilePhotoUpload: React.VFC = () => {
    const classes = useStyles();
    const { scanID } = useParams<{ scanID: string }>();
    const uploadState = useUploadedPhotoState(wellnessScansPhotoUploadConfig, scanID);

    return (
        <Grid className={classes.root}>
            <div style={{ padding: 16, width: '100%' }}>
                <PhotoUpload
                    uploadConfig={wellnessScansPhotoUploadConfig}
                    uploadState={uploadState}
                    viewInstructionsOptions={{
                        showButton: false,
                        onClick() {},
                    }}
                    scanID={scanID}
                    isDesktop={false}
                    onUpload={() => {
                        AnalyticsClient.track('Practice - Checkout - Photo Uploaded', {
                            productName: wellnessScansPhotoUploadConfig.firestoreProductName,
                            isMobile: true,
                        });
                    }}
                />
            </div>
        </Grid>
    );
};
