import { isPlaceholderEmail } from '../../../profile-settings/account/utils';
import type { StaffUser } from '../useFetchPracticeStaffUsers.graphql';
import { AddEditStaff as AddEditStaffBase } from './AddEditStaff';
import { useCreateDoctorPreferences } from './graphql/useCreateDoctorPreferences.graphql';
import { useUpdateDoctorPreferences } from './graphql/useUpdateDoctorPreferences.graphql';
import { useStaffFormValidation } from './useStaffFormValidation';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import constate from 'constate';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import React from 'react';

const isValidRole = (role: string): role is LabsGqlStaffRolePractice => {
    const roles: readonly string[] = Object.values(LabsGqlStaffRolePractice);
    return roles.includes(role);
};

type ContextProps = { staffUser?: StaffUser; onBackAction: () => void };

const useStaffFormContext = ({ staffUser, onBackAction }: ContextProps) => {
    const isEditForm = !!staffUser;
    const { enqueueSnackbar } = useSnackbar();

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [accountEmail, setAccountEmail] = React.useState('');
    const [accountPhone, setAccountPhone] = React.useState('');
    const [preferredName, setPreferredName] = React.useState('');
    const [contactEmail, setContactEmail] = React.useState('');
    const [contactPhone, setContactPhone] = React.useState('');
    const [roles, setRoles] = React.useState<LabsGqlStaffRolePractice[]>([]);

    const [showReactivateModal, setShowReactivateModal] = React.useState(false);
    const [showInviteModal, setShowInviteModal] = React.useState(false);

    React.useEffect(() => {
        if (staffUser) {
            const placeholderEmail = isPlaceholderEmail(staffUser.login_email);

            setFirstName(staffUser.first_name);
            setLastName(staffUser.last_name);
            setAccountEmail(placeholderEmail ? '' : staffUser.login_email);
            setAccountPhone(staffUser.login_phone || '');
            setPreferredName(
                staffUser.preferred_name || [staffUser.first_name.trim(), staffUser.last_name.trim()].join(' '),
            );
            setContactEmail(staffUser.contact_email || staffUser.login_email || '');
            setContactPhone(staffUser.contact_phone || staffUser.login_phone || '');
            setRoles(staffUser.roles);
        }
    }, [staffUser]);

    const {
        submit: submitCreateDoctorPreferences,
        submitting: submittingCreateDoctorPreferences,
        error: createDoctorPreferencesError,
    } = useCreateDoctorPreferences({
        onSuccess: () => {
            enqueueSnackbar('Staff member created successfully', { variant: 'success' });
            onBackAction();
        },
        onError: message => {
            setShowReactivateModal(false);
            switch (message) {
                case 'User already exists at another organization':
                    setShowInviteModal(true);
                    return;

                case 'A deactivated staff member with this email address already exists at this organization':
                    setShowReactivateModal(true);
                    return;
            }
        },
    });

    const {
        submit: submitUpdateDoctorPreferences,
        submitting: submittingUpdateCreateDoctorPreferences,
        error: updateDoctorPreferencesError,
    } = useUpdateDoctorPreferences({
        onSuccess: () => {
            enqueueSnackbar('Staff member updated successfully', { variant: 'success' });
            onBackAction();
        },
    });

    const handleRoleChange = (next: string, checked: boolean) => {
        if (!isValidRole(next)) {
            return;
        }
        if (checked) {
            setRoles(prev => [...prev, next]);
        } else {
            setRoles(prev => prev.filter(r => r !== next));
        }
    };

    const updateDoctorPreferences = () => {
        if (!staffUser) {
            return;
        }

        void submitUpdateDoctorPreferences({
            user_first_name: firstName.trim(),
            user_last_name: lastName.trim(),
            name: preferredName.trim(),
            user_email: accountEmail.trim().toLowerCase(),
            mobile_phone_number: accountPhone.trim() || null,
            contact_email: contactEmail.trim().toLowerCase(),
            contact_phone: contactPhone.trim() || undefined,
            roles,
            preference_set_id: staffUser.doctor_preference_id,
        });
    };

    const createDoctorPreferences = (force = false) => {
        void submitCreateDoctorPreferences({
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            contactEmail: accountEmail.trim(),
            roles,
            force,
        });
    };

    const isFormDirty = React.useMemo(() => {
        if (staffUser) {
            const isEqual = <T extends string | undefined | null>(a: T, b: T) => (a ?? '').trim() === (b ?? '').trim();
            return (
                !isEqual(firstName, staffUser.first_name) ||
                !isEqual(lastName, staffUser.last_name) ||
                !isEqual(preferredName, staffUser.preferred_name) ||
                !isEqual(accountEmail, staffUser.login_email) ||
                !isEqual(accountPhone, staffUser.login_phone) ||
                !isEqual(contactEmail, staffUser.contact_email) ||
                !isEqual(contactPhone, staffUser.contact_phone) ||
                !_.isEqual(_.sortBy(roles), _.sortBy(staffUser.roles))
            );
        } else {
            return firstName.trim() || lastName.trim() || contactEmail.trim() || roles.length > 0;
        }
    }, [accountEmail, accountPhone, contactEmail, contactPhone, firstName, lastName, preferredName, roles, staffUser]);

    const inviteExistingUser = () => createDoctorPreferences(true);
    const reactivateDoctorPreferences = () => createDoctorPreferences(true);

    const { isFormValid, ...formValidation } = useStaffFormValidation(
        {
            firstName,
            lastName,
            accountEmail,
            accountPhone,
            contactEmail,
            contactPhone,
            preferredName,
            roles,
        },
        isEditForm,
    );

    return {
        ...formValidation,
        staffUser,
        isEditForm,
        handleRoleChange,
        firstName,
        lastName,
        accountEmail,
        accountPhone,
        roles,
        setFirstName,
        setLastName,
        setAccountEmail,
        setAccountPhone,
        contactEmail,
        setContactEmail,
        contactPhone,
        setContactPhone,
        preferredName,
        setPreferredName,
        shouldContinue:
            !submittingCreateDoctorPreferences &&
            !submittingUpdateCreateDoctorPreferences &&
            isFormValid &&
            isFormDirty,
        employeeName: staffUser?.preferred_name,
        formError: createDoctorPreferencesError || updateDoctorPreferencesError,
        onBackAction,
        showInviteModal,
        showReactivateModal,
        setShowInviteModal,
        setShowReactivateModal,
        updateDoctorPreferences,
        createDoctorPreferences,
        reactivateDoctorPreferences,
        inviteExistingUser,
        fullName: _.compact([firstName.trim(), lastName.trim()]).join(' '),
        isMobileVerified: staffUser?.is_mobile_verified,
    };
};

const [Provider, useHook] = constate(useStaffFormContext);

export const useStaffForm = useHook;
export const AddEditStaff: React.FC<ContextProps> = props => (
    <Provider {...props}>
        <AddEditStaffBase />
    </Provider>
);
