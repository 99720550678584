import { useAlignerCheckoutPropSelector } from '../../../../redux';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { useAlignerCheckoutStepInfo } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import {
    AlignerExtractionSelection,
    AlignerMovementRestrictionsSelection,
    AlignerAttachmentRestrictionsSelection,
    CheckoutBodyWrapper,
} from '@orthly/dentin';
import React from 'react';

export const AlignerCheckoutMovementRestrictions: React.VFC = () => {
    const { movementRestrictedTeeth, alignerArch } = useAlignerCheckoutPropSelector([
        'movementRestrictedTeeth',
        'alignerArch',
    ]);
    const setMovementRestrictedTeeth = useAlignerCheckoutAction('SET_MOVEMENT_RESTRICTED_TEETH');
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(
        AlignerCheckoutStep.MovementRestrictedTeethSelection,
    );
    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <AlignerMovementRestrictionsSelection
                arch={alignerArch}
                value={movementRestrictedTeeth}
                onChange={setMovementRestrictedTeeth}
            />
        </CheckoutBodyWrapper>
    );
};

export const AlignerCheckoutAttachmentRestrictions: React.VFC = () => {
    const { attachmentRestrictedTeeth, alignerArch } = useAlignerCheckoutPropSelector([
        'attachmentRestrictedTeeth',
        'alignerArch',
    ]);
    const setAttachmentRestrictedTeeth = useAlignerCheckoutAction('SET_ATTACHMENT_RESTRICTED_TEETH');
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(
        AlignerCheckoutStep.AttachmentRestrictedTeethSelection,
    );
    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <AlignerAttachmentRestrictionsSelection
                arch={alignerArch}
                value={attachmentRestrictedTeeth}
                onChange={setAttachmentRestrictedTeeth}
            />
        </CheckoutBodyWrapper>
    );
};

export const AlignerCheckoutExtractions: React.VFC = () => {
    const { extractionTeeth, alignerArch } = useAlignerCheckoutPropSelector(['extractionTeeth', 'alignerArch']);
    const setExtractionTeeth = useAlignerCheckoutAction('SET_EXTRACTION_TEETH');
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.ExtractionTeethSelection);
    if (!isActiveStep) {
        return null;
    }

    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <AlignerExtractionSelection arch={alignerArch} value={extractionTeeth} onChange={setExtractionTeeth} />
        </CheckoutBodyWrapper>
    );
};
