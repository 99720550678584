import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    cell: {
        borderRight: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    shadedCell: {
        backgroundColor: FlossPalette.TAN,
    },
    list: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
        '&>li': {
            lineHeight: '2em',
        },
    },
    checkPrefix: {
        '&::before': {
            content: '"✓"',
            display: 'inline-block',
            paddingRight: '0.5ch',
        },
    },
}));

export const ClearAlignerMaterialsTable: React.FC = () => {
    const { getPrice, formatPrice, formatPrices } = usePricingGuide();
    const classes = useStyles();

    const clearAlignerMaterials = React.useMemo(
        () => [
            {
                id: 'steps',
                clearAligners: 'Steps',
                dandyOnDemand: 'Pay per step',
                dandy20: 'Up to 20 steps',
                dandyMax: 'Unlimited steps',
            },
            {
                id: 'description',
                clearAligners: 'Description',
                dandyOnDemand: 'A flexible option designed for very mild cases',
                dandy20: 'A more inclusive option designed for treating mild to moderate cases',
                dandyMax: 'An all-inclusive option for treating mild to advanced cases',
            },
            {
                id: 'price',
                clearAligners: 'Price',
                dandyOnDemand: formatPrices(
                    [
                        getPrice('Clear Aligners Dandy on Demand - Base Price'),
                        getPrice('Clear Aligners Dandy on Demand - Each Aligner'),
                    ],
                    ([basePrice, eachAligner]) => (
                        <ul className={classes.list}>
                            <li>{`${basePrice} base price +`}</li>
                            <li>{`${eachAligner} / aligner`}</li>
                        </ul>
                    ),
                ),
                dandy20: formatPrices(
                    [
                        getPrice('Clear Aligners Dandy 20 - Dual Arch'),
                        getPrice('Clear Aligners Dandy 20 - Single Arch'),
                    ],
                    ([dualArch, singleArch]) => (
                        <ul className={classes.list}>
                            <li>{`Dual arch: ${dualArch}`}</li>
                            <li>{`Single arch: ${singleArch}`}</li>
                        </ul>
                    ),
                ),
                dandyMax: formatPrices(
                    [
                        getPrice('Clear Aligners Dandy Max - Dual Arch'),
                        getPrice('Clear Aligners Dandy Max - Single Arch'),
                    ],
                    ([dualArch, singleArch]) => (
                        <ul className={classes.list}>
                            <li>{`Dual arch: ${dualArch}`}</li>
                            <li>{`Single arch: ${singleArch}`}</li>
                        </ul>
                    ),
                ),
            },
            {
                id: 'planExpiration',
                clearAligners: 'Plan expiration',
                dandyOnDemand: '1 year',
                dandy20: '2 years',
                dandyMax: '3 years',
            },
            {
                id: 'refinement',
                clearAligners: 'Refinement',
                dandyOnDemand: formatPrice(
                    getPrice('Clear Aligners Dandy on Demand - Refinement Add on'),
                    formattedPrice => `Add-on: + ${formattedPrice}`,
                ),
                dandy20: <span className={classes.checkPrefix}>1 refinement included</span>,
                dandyMax: <span className={classes.checkPrefix}>Unlimited refinements</span>,
            },
            {
                id: 'retainers',
                clearAligners: 'Retainers',
                dandyOnDemand: 'Add-on available',
                dandy20: <span className={classes.checkPrefix}>1 set included</span>,
                dandyMax: <span className={classes.checkPrefix}>2 sets included</span>,
            },
            {
                id: 'extra',
                clearAligners: 'Extra Retainers',
                dandyOnDemand: formatPrices(
                    [
                        getPrice('Clear Aligners Dandy on Demand - Retainers Extras - Single'),
                        getPrice('Clear Aligners Dandy on Demand - Retainers Extras - Dual (1 set)'),
                        getPrice('Clear Aligners Dandy on Demand - Retainers Extras - Multiple (4 sets)'),
                    ],
                    ([single, dual, multi]) => (
                        <ul className={classes.list}>
                            <li>{`Single: ${single}`}</li>
                            <li>{`Dual (1 set): ${dual}`}</li>
                            <li>{`Multiple (4 sets): ${multi}`}</li>
                        </ul>
                    ),
                ),
                dandy20: formatPrices(
                    [
                        getPrice('Clear Aligners Dandy 20 - Retainers Extras - Single'),
                        getPrice('Clear Aligners Dandy 20 - Retainers Extras - Dual (1 set)'),
                        getPrice('Clear Aligners Dandy 20 - Retainers Extras - Multiple (3 sets)'),
                    ],
                    ([single, dual, multi]) => (
                        <ul className={classes.list}>
                            <li>{`Single: ${single}`}</li>
                            <li>{`Dual (1 set): ${dual}`}</li>
                            <li>{`Multiple (3 sets): ${multi}`}</li>
                        </ul>
                    ),
                ),
                dandyMax: formatPrices(
                    [
                        getPrice('Clear Aligners Dandy Max - Retainers Extras - Single'),
                        getPrice('Clear Aligners Dandy Max - Retainers Extras - Dual (1 set)'),
                        getPrice('Clear Aligners Dandy Max - Retainers Extras - Multiple (2 sets)'),
                    ],
                    ([single, dual, multi]) => (
                        <ul className={classes.list}>
                            <li>{`Single: ${single}`}</li>
                            <li>{`Dual (1 set): ${dual}`}</li>
                            <li>{`Multiple (2 sets): ${multi}`}</li>
                        </ul>
                    ),
                ),
            },
            {
                id: 'turnaround',
                clearAligners: 'Turnaround time (incl shipping)',
                dandyOnDemand: '8 business days from treatment plan approval',
                dandy20: '8 business days from treatment plan approval',
                dandyMax: '8 business days from treatment plan approval',
            },
        ],
        [classes, getPrice, formatPrice, formatPrices],
    );

    return (
        <PricingGuideTable
            id={'clear-aligner-materials'}
            canSort={false}
            columns={[
                {
                    id: 'clearAligners',
                    title: '',
                    className: classNames(classes.shadedCell, classes.cell),
                },
                {
                    id: 'dandyOnDemand',
                    title: 'Dandy On-Demand',
                    className: classes.cell,
                },
                {
                    id: 'dandy20',
                    title: 'Dandy 20',
                    className: classes.cell,
                },
                {
                    id: 'dandyMax',
                    title: 'Dandy Max',
                    className: classes.cell,
                },
            ]}
            rows={clearAlignerMaterials}
        />
    );
};
