import { PhoneNumberUtils } from '@orthly/runtime-utils';
import React from 'react';
import { z } from 'zod';

const phoneLoginSchema = z
    .object({
        phoneNumber: z.string(),
        confirmationCode: z.string().length(6, { message: 'Confirmation code must be 6 digits' }),
    })
    .refine(({ phoneNumber }) => PhoneNumberUtils.isValidPhoneNumber(phoneNumber), {
        message: 'Invalid phone number',
        path: ['phoneNumber'],
    });

export const usePhoneLoginValidation = ({
    phoneNumber,
    confirmationCode,
    submittedPhoneNumber,
}: {
    phoneNumber: string;
    confirmationCode: string;
    submittedPhoneNumber: string;
}) => {
    const [showPhoneNumberError, setShowPhoneNumberError] = React.useState(false);
    const [showConfirmationCodeError, setShowConfirmationCodeError] = React.useState(false);

    const validation = React.useMemo(
        () => phoneLoginSchema.safeParse({ phoneNumber, confirmationCode }),
        [phoneNumber, confirmationCode],
    );

    if (validation.success) {
        return { isFormValid: true, setShowConfirmationCodeError, setShowPhoneNumberError };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: !submittedPhoneNumber && !fieldErrors.phoneNumber?.[0],
        setShowConfirmationCodeError,
        setShowPhoneNumberError,
        phoneNumberError: showPhoneNumberError && fieldErrors.phoneNumber?.[0],
        confirmationCodeError: showConfirmationCodeError && fieldErrors.confirmationCode?.[0],
    };
};
