import { useUndo } from '../hooks/useUndo';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { GeneralReviewStructuredRejectionNotes } from './GeneralReviewStructuredRejectionNotes';
import React from 'react';

export const CurrentWaxupGeneralStructuredNotesControls: React.FC = () => {
    const { isMobileRejectionNoteOpen, setIsMobileRejectionNoteOpen, unfinishedPresets } = useGuidedWaxupContext();
    const { setSnapshot, resetToSnapshot } = useUndo();
    const resetPresets = React.useCallback(() => {
        resetToSnapshot();
        setIsMobileRejectionNoteOpen(false);
    }, [resetToSnapshot, setIsMobileRejectionNoteOpen]);

    return (
        <GeneralReviewStructuredRejectionNotes
            title={'Add a rejection note'}
            closeLabel={'Save'}
            closeDisabled={unfinishedPresets.length > 0}
            isOpen={isMobileRejectionNoteOpen}
            onCancel={resetPresets}
            onBack={resetPresets}
            onClose={() => {
                setIsMobileRejectionNoteOpen(false);
                setSnapshot();
            }}
        />
    );
};
