import type { LabsGqlScanbodyFragment, LabsGqlScanbodyInventoryItemFragment } from '@orthly/graphql-operations';
import type { LabsGqlPatientDto } from '@orthly/graphql-schema';
import type { ICartImplantBridgeItem, ICartImplantItem, ICartItemImplantMetadata } from '@orthly/items';

export interface MetadataPayload extends Omit<ICartItemImplantMetadata, 'relationship' | 'scanbody_id'> {}

export interface ImplantReadinessTip {
    title: string;
    children: React.ReactNode;
}

export enum AbutmentType {
    LabChoice = 'LabChoice',
    Generic = 'Generic',
    Authentic = 'Authentic',
}

export interface ImplantReadinessUrlData {
    path?: string;
    id?: string;
}

export type ImplantReadinessPatient = Pick<
    LabsGqlPatientDto,
    'birthday' | 'first_name' | 'last_name' | 'id' | 'gender'
>;

export interface ScanbodyWithMetadata extends LabsGqlScanbodyFragment {
    manufacturer: string;
    system: string;
    connection: string;
    itemId: string;
}

export interface ScanbodyWithPriority extends LabsGqlScanbodyFragment {
    inventory: LabsGqlScanbodyInventoryItemFragment | undefined;
    priority: number;
}

export type ScanbodyOptions = Record<
    string,
    { generic: ScanbodyWithPriority | undefined; authentic: ScanbodyWithPriority | undefined }
>;

export interface ImplantReadinessState {
    implantReadinessId: string;
    patient?: ImplantReadinessPatient;
    items: Array<ICartImplantItem | ICartImplantBridgeItem>;
    scanbodies: ScanbodyWithMetadata[];
    isGrouped: boolean;
    itemAbutmentTypes: Record<string, AbutmentType>;
    itemScanbodyOptions: ScanbodyOptions;
    surgicalReportPath: string;
    activeItemId: string;
    activePath: string;
}
