import { useScanIsAligner } from '../state/checkout.selectors';
import { useScanIsDenture } from '../state/dentures-checkout.selectors';
import { AlignerCheckoutMainScreens } from './AlignerCheckoutMainScreens';
import { DentureCheckoutMainScreens } from './DentureCheckoutMainScreens';
import { RegularCheckoutMainScreens } from './RegularCheckoutMainScreens';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import React from 'react';

interface CheckoutMainScreensProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

export const CheckoutMainScreens: React.FC<CheckoutMainScreensProps> = props => {
    const isAlignerOrder = useScanIsAligner();
    const isDentureOrder = useScanIsDenture();

    if (isAlignerOrder) {
        return <AlignerCheckoutMainScreens {...props} />;
    }

    if (isDentureOrder) {
        return <DentureCheckoutMainScreens {...props} />;
    }

    return <RegularCheckoutMainScreens {...props} />;
};
