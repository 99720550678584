import type { SecondarySidebarItem } from '../../../components/PracticeSecondarySidebar.types';
import { useOrdersSelector } from '../../../redux/orders/orders.selectors';
import type { LabOrderRootStatus } from '../LabsUtils';
import { useOrderStatusCounts } from './useOrderStatusCounts.graphql';
import type {
    FragmentType,
    PracticeOrdersOverviewRootListSalesOrdersByIds_FragmentFragmentDoc,
} from '@orthly/graphql-inline-react';
import type { LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { useScans } from '@orthly/graphql-react';
import { LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

export type OrdersOverviewItem =
    | {
          id: string;
          type: 'order';
          orderFragment: FragmentType<typeof PracticeOrdersOverviewRootListSalesOrdersByIds_FragmentFragmentDoc>;
          refetch: () => Promise<void>;
      }
    | { id: string; type: 'scan'; scan: LabsGqlScanFileFragment };

export function useUnsubmittedScanOverviewItems(isAscending: boolean): {
    scanRows: OrdersOverviewItem[];
    loading: boolean;
} {
    const status = useOrdersSelector(s => s.status);
    const scansVisible = status === 'All' || status === 'Draft';
    const { scans, loading } = useScans({ skip: !scansVisible });
    return React.useMemo(() => {
        if (!scansVisible) {
            return { loading, scanRows: [] };
        }
        const filteredSorted = _.sortBy(scans, s => {
            const dateValue = new Date(s.created_at).valueOf();
            return isAscending ? dateValue : -dateValue;
        });
        return {
            loading,
            scanRows: filteredSorted.map<OrdersOverviewItem>(scan => ({ scan, id: scan.id, type: 'scan' })),
        };
    }, [scans, scansVisible, loading, isAscending]);
}

export function useOrdersOverviewSidebarItems() {
    const statusOrderCount = useOrderStatusCounts();
    return React.useMemo<SecondarySidebarItem<LabOrderRootStatus>[][]>(() => {
        // The `special` statuses (scans, returns, on hold) we only show if they have active items
        const top: SecondarySidebarItem<LabOrderRootStatus>[] = [
            // we show the "special" items in the All section, so include them in the count
            {
                value: LabsGqlPracticeOrderStatus.All,
                count:
                    statusOrderCount.All +
                    statusOrderCount.UnsubmittedScans +
                    statusOrderCount.InTransitReturns +
                    statusOrderCount.UnshippedReturns,
            },
            {
                value: LabsGqlPracticeOrderStatus.Draft,
                label: 'Unsubmitted orders',
                count: statusOrderCount.UnsubmittedScans,
                variant: 'error',
            },
            {
                value: LabsGqlPracticeOrderStatus.OnHold,
                label: 'On hold',
                count: statusOrderCount.OnHold,
                variant: 'error',
            },
            {
                value: LabsGqlPracticeOrderStatus.Waxup,
                label: 'Awaiting Approval',
                count: statusOrderCount.WaxupReview,
                variant: 'error',
            },
            {
                value: LabsGqlPracticeOrderStatus.Return,
                label: 'Returns',
                count: statusOrderCount.UnshippedReturns + statusOrderCount.InTransitReturns,
                variant: statusOrderCount.UnshippedReturns > 0 ? 'error' : undefined,
            },
            {
                value: LabsGqlPracticeOrderStatus.NeedsOrderFeedback,
                label: 'Needs order feedback',
                count: statusOrderCount.NeedsOrderFeedback,
            },
        ];
        const middle: SecondarySidebarItem<LabOrderRootStatus>[] = [
            { value: LabsGqlPracticeOrderStatus.New, count: statusOrderCount.New },
            { value: LabsGqlPracticeOrderStatus.Fabrication, count: statusOrderCount.Fabrication },
            { value: LabsGqlPracticeOrderStatus.Shipped, count: statusOrderCount.Shipped, label: 'In Transit' },
        ];
        const bottom: SecondarySidebarItem<LabOrderRootStatus>[] = [
            { value: LabsGqlPracticeOrderStatus.Delivered, count: statusOrderCount.Delivered },
            { value: LabsGqlPracticeOrderStatus.Cancelled, count: statusOrderCount.Cancelled },
        ];
        return [
            top.filter(s => s.count > 0 || s.value === LabsGqlPracticeOrderStatus.NeedsOrderFeedback),
            middle,
            bottom,
        ];
    }, [statusOrderCount]);
}
