import { useAlignerCheckoutSelector } from '../../../../../redux';
import { useAlignerCheckoutAction } from '../../../state/aligners-checkout.actions';
import {
    selectAlignerRetainerPriceByQuantity,
    useAlignerCheckoutStepInfo,
} from '../../../state/aligners-checkout.selectors';
import type { AlignerRetainerCheckoutState } from '../../../state/aligners-checkout.types';
import { AlignerCheckoutStep } from '../../../state/aligners-checkout.types';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import { OrderItemArch } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import {
    FlossPalette,
    FlossPaletteUtils,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    ButtonBase,
    Grid,
    InfoIcon,
    Icon,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    archButton: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 16,
        borderStyle: 'solid',
        borderWidth: 2,
        padding: '16px 24px',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        height: 200,
        width: '100%',
        '&>img': {
            flexGrow: 1,
            overflow: 'hidden',
            objectFit: 'scale-down',
            width: '100%',
            marginBottom: 16,
        },
    },
    quantityButton: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 4,
        borderStyle: 'solid',
        borderWidth: 2,
        padding: 16,
        flexDirection: 'row',
        flexWrap: 'nowrap',
        columnGap: 8,
    },
}));

interface ArchButtonProps {
    selected: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    label: string;
    icon: string;
}

const ArchButton: React.VFC<ArchButtonProps> = props => {
    const { selected, onClick, label, icon } = props;
    const classes = useStyles();
    return (
        <ButtonBase
            className={classes.archButton}
            style={{ borderColor: selected ? FlossPalette.STAR_GRASS : FlossPalette.TAN }}
            onClick={onClick}
        >
            <img src={icon} alt={label} />
            <Text variant={'body1'} medium color={selected ? 'STAR_GRASS' : 'BLACK'}>
                {label}
            </Text>
        </ButtonBase>
    );
};

interface QuantityButtonProps {
    selected: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
    label: string;
    priceInCents?: number;
    description?: string;
}

const QuantityButton: React.FC<QuantityButtonProps> = props => {
    const { selected, onClick, label, priceInCents, description, children } = props;
    const classes = useStyles();
    return (
        <ButtonBase
            className={classes.quantityButton}
            style={{
                borderColor: selected ? FlossPalette.STAR_GRASS : FlossPalette.TAN,
                backgroundColor: selected ? FlossPaletteUtils.toRgba('STAR_GRASS', 0.16) : undefined,
            }}
            onClick={onClick}
        >
            <Icon
                icon={selected ? 'RadioButtonCheckedIcon' : 'RadioButtonUnCheckedIcon'}
                style={{ color: FlossPalette.STAR_GRASS }}
            />
            <Text variant={'body1'} medium>
                {label}
            </Text>
            <Grid style={{ flexGrow: 1 }} />
            {children}
            {priceInCents !== undefined && (
                <Text variant={'body1'}>{priceInCents ? Format.currency(priceInCents, 'cents', false) : 'Free'}</Text>
            )}
            {description && (
                <Text variant={'body1'} color={'DARK_GRAY'}>
                    {description}
                </Text>
            )}
        </ButtonBase>
    );
};

interface QuantitySectionProps {
    retainer: AlignerRetainerCheckoutState;
    setRetainer: (retainer: AlignerRetainerCheckoutState) => void;
    getRetainerPrice: (quantity: number) => number;
}

const QUANTITY_LISTING = [1, 2, 3, 4];

const QuantitySection: React.VFC<QuantitySectionProps> = props => {
    const { retainer, setRetainer, getRetainerPrice } = props;
    const [moreSelected, setMoreSelected] = React.useState<boolean>(false);
    return (
        <>
            {QUANTITY_LISTING.map(quantity => (
                <QuantityButton
                    key={quantity}
                    selected={!moreSelected && retainer.quantity === quantity}
                    onClick={() => {
                        setMoreSelected(false);
                        setRetainer({ ...retainer, quantity });
                    }}
                    label={String(quantity)}
                    priceInCents={getRetainerPrice(quantity)}
                    description={getRetainerPrice(quantity) === 0 ? '(In your plan)' : undefined}
                />
            ))}
        </>
    );
};

export const AlignerCheckoutRetainerArchSelection: React.FC = () => {
    const retainer = useAlignerCheckoutSelector(s => s.retainer);
    const setRetainer = useAlignerCheckoutAction('SET_RETAINER');
    const getRetainerPrice = useAlignerCheckoutSelector(selectAlignerRetainerPriceByQuantity);
    const existingSets = useAlignerCheckoutSelector(s => s.retainerPricing?.totalSetsOrdered ?? 0);
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { isActiveStep, isComplete } = useAlignerCheckoutStepInfo(AlignerCheckoutStep.RetainerArchSelection);
    if (!isActiveStep || !retainer) {
        return null;
    }
    return (
        <CheckoutBodyWrapper visible={isActiveStep} isComplete={isComplete}>
            <Grid container direction={'column'} wrap={'nowrap'} style={{ marginTop: 32 }}>
                <Grid container direction={isMobile ? 'column' : 'row'} wrap={'nowrap'} style={{ gap: 24 }}>
                    <ArchButton
                        selected={retainer.arch === OrderItemArch.Dual}
                        onClick={() => setRetainer({ ...retainer, arch: OrderItemArch.Dual })}
                        label={'Dual arch'}
                        icon={'/checkout/dual-arch-aligner.svg'}
                    />
                    <ArchButton
                        selected={retainer.arch === OrderItemArch.Upper}
                        onClick={() => setRetainer({ ...retainer, arch: OrderItemArch.Upper })}
                        label={'Single arch: upper'}
                        icon={'/checkout/upper-arch-aligner.svg'}
                    />
                    <ArchButton
                        selected={retainer.arch === OrderItemArch.Lower}
                        onClick={() => setRetainer({ ...retainer, arch: OrderItemArch.Lower })}
                        label={'Single arch: lower'}
                        icon={'/checkout/lower-arch-aligner.svg'}
                    />
                </Grid>
                <Text variant={'h6'} style={{ marginTop: 32, marginBottom: 16 }}>
                    How many sets of retainers do you need?
                </Text>
                <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    wrap={'nowrap'}
                    alignItems={'flex-start'}
                    style={{ columnGap: 100, rowGap: 24 }}
                >
                    <Grid container direction={'column'} wrap={'nowrap'} style={{ rowGap: 8 }}>
                        <QuantitySection
                            retainer={retainer}
                            setRetainer={setRetainer}
                            getRetainerPrice={getRetainerPrice}
                        />
                    </Grid>
                    <Grid
                        container
                        direction={'row'}
                        wrap={'nowrap'}
                        style={{
                            padding: '16px 24px',
                            backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
                            borderRadius: 8,
                            visibility: existingSets === 0 ? 'visible' : 'hidden',
                        }}
                    >
                        <InfoIcon style={{ color: FlossPalette.SECONDARY_FOREGROUND, marginRight: 16 }} />
                        <Text variant={'body2'}>
                            We recommend having backups, in case the patient loses or breaks their retainers.
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        </CheckoutBodyWrapper>
    );
};
