import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import { CheckoutButtonSelect } from '@orthly/dentin';
import type { EnumSelectMultiItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';
import React from 'react';

interface CheckoutEnumSelectMultiItemDataFieldProps<
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
> extends CheckoutItemDataFieldBaseProps<SKU> {
    field: EnumSelectMultiItemDataField<ID, SKU, Enum>;
}

export const CheckoutEnumSelectMultiItemDataField = <
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
>({
    field,
    item,
    updateItem,
}: CheckoutEnumSelectMultiItemDataFieldProps<ID, SKU, Enum>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    const applyUpdate = React.useCallback(
        (val: string[] | undefined) => {
            // belongs to enum should always be true, but we check for type reasons
            if (val === undefined || val.every((v): v is Enum => belongsToEnum(v, field.enum))) {
                updateItem(field.getUpdatePayload(val ?? null, item));
            }
        },
        [field, item, updateItem],
    );

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <CheckoutButtonSelect
            mode={'multiple'}
            label={getFieldLabel(field, item)}
            value={value ?? []}
            options={[...field.getOptions(item)]}
            onChange={val => applyUpdate(val)}
        />
    );
};
