import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'mobile_auth';

export enum MobileAuthLoginType {
    email = 'email',
    one_time_code = 'one_time_code',
    select_staff = 'select_staff',
}

export const MobileAuthActions = {
    SET_LOGIN_TYPE: createSyncAction<MobileAuthLoginType>(`${PREFIX}/SET_LOGIN_TYPE`),
};

export const useMobileAuthAction = generateUseActionHook<typeof MobileAuthActions>(MobileAuthActions);
