import type { MinimalScanEntry } from '../AttachScans/utils/Reloader.util';
import { ScanSource } from '../AttachScans/utils/Reloader.util';
import { uploadFilesScansMapAtom, uploadFilesUploadedFilesAtom } from './atoms/UploadFiles.atoms';
import {
    FileLayout,
    FileTitleLayout,
    ScanFileButton,
    ScanFileContainer,
    ScanFileControlsLayout,
    ScanFileLayout,
    ScanFileTitle,
    ScanFileTitleLayout,
    ScanTypeAssignmentLayout,
    SubScanFileContainer,
    SubScanFileControlsLayout,
    SubScanFileLayout,
    SubScanTypeAssignmentLayout,
} from './components/AssignFilesListComponents';
import type { ThreeshapeScanName } from '@orthly/forceps';
import { deSuffixFilename, ThreeshapeScanNameToScanDefinition } from '@orthly/forceps';
import { LabsGqlLabOrderPhotoType } from '@orthly/graphql-schema';
import type { SimpleSelectOption } from '@orthly/ui';
import { SimpleSelect, TrashIcon } from '@orthly/ui';
import type { UploadedFile } from '@orthly/veneer';
import { FileListPreviewImage, getFileExtension } from '@orthly/veneer';
import { useAtom, useSetAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';

const SCAN_OPTIONS: SimpleSelectOption[] = Object.keys(ThreeshapeScanNameToScanDefinition).map(type => ({
    value: type,
    label: _.startCase(type),
}));

const ScanTypeAssignment: React.VFC<{
    scanSource: ScanSource;
    scanEntry: MinimalScanEntry;
    scanOptions: SimpleSelectOption[];
    didUploadMultipleThreeOxzFiles?: boolean;
    fileName?: string;
}> = ({ scanEntry, scanSource, scanOptions, didUploadMultipleThreeOxzFiles, fileName }) => {
    const [scansMap, setScansMap] = useAtom(uploadFilesScansMapAtom);
    const selectValue = (scanEntry.definition && !didUploadMultipleThreeOxzFiles && scanEntry.scan_type) || undefined;

    return (
        <SimpleSelect
            flossInputConfig={{ backgroundColor: 'white', bordered: true }}
            placeholder={'Select scan type'}
            label={''}
            value={selectValue}
            disabled={!didUploadMultipleThreeOxzFiles && scanSource === ScanSource.ThreeOxz}
            FormControlProps={{ style: { marginTop: -8 }, variant: 'standard' }}
            options={scanOptions}
            onChange={scanType => {
                const newScanType = scanType as ThreeshapeScanName;
                const definition = scanType ? ThreeshapeScanNameToScanDefinition[newScanType] : undefined;
                if (fileName) {
                    const newScansMap = new Map(scansMap);
                    const scanUpload = newScansMap.get(fileName);
                    if (
                        scanUpload &&
                        (scanUpload.source === ScanSource.Zip || scanUpload.source === ScanSource.ThreeOxz)
                    ) {
                        const newZipFile = scanUpload.scans.map(scan => {
                            if (scan.file_name === scanEntry.file_name) {
                                return {
                                    ...scan,
                                    scan_type: newScanType,
                                    definition,
                                };
                            } else {
                                return scan;
                            }
                        });

                        newScansMap.set(fileName, { source: scanUpload.source, scans: newZipFile });
                        setScansMap(newScansMap);
                    }
                } else {
                    const newScansMap = new Map(scansMap);
                    const newScanEntry = {
                        ...scanEntry,
                        scan_type: newScanType,
                        definition,
                    };
                    newScansMap.set(scanEntry.file_name ?? '', { source: ScanSource.SingleScan, scan: newScanEntry });
                    setScansMap(newScansMap);
                }
            }}
        />
    );
};

const isScanFile = (file: UploadedFile) => {
    return ['3oxz', 'stl', 'ply'].includes(getFileExtension(file.file.name));
};

const isZipFile = (file: UploadedFile) => {
    return ['zip', '7z', 'rar', 'tar', 'gz'].includes(getFileExtension(file.file.name));
};

const getAssignmentTypeOptions = (file: UploadedFile) => {
    if (isScanFile(file) || isZipFile(file)) {
        return [
            { value: LabsGqlLabOrderPhotoType.ScanFile, label: 'Scan' },
            ...(isZipFile(file) ? [{ value: 'other', label: 'Other' }] : []),
        ];
    }

    return [
        { value: LabsGqlLabOrderPhotoType.PatientPhoto, label: 'Shade' },
        { value: LabsGqlLabOrderPhotoType.FullFace, label: 'Pre-op' },
        { value: LabsGqlLabOrderPhotoType.Xray, label: 'X-Ray' },
        { value: LabsGqlLabOrderPhotoType.SurgicalReport, label: 'Surgical report' },
        { value: 'other', label: 'Other' },
    ];
};

const AttachmentTypeAssignment: React.VFC<{
    file: UploadedFile;
    groupedFiles: Record<string, UploadedFile[]>;
    style?: React.CSSProperties;
}> = ({ file, groupedFiles, style }) => {
    const [value, setValue] = React.useState<string>('');
    const fileToFileType: Record<string, string> = {};

    return (
        <SimpleSelect
            flossInputConfig={{ backgroundColor: 'white', bordered: true }}
            placeholder={!isZipFile(file) ? 'Select image type' : 'Select file type'}
            label={''}
            FormControlProps={{ style: { marginTop: -8, ...style }, variant: 'standard' }}
            value={isScanFile(file) ? LabsGqlLabOrderPhotoType.ScanFile : value}
            options={getAssignmentTypeOptions(file)}
            onChange={e => {
                let newPhotoType: LabsGqlLabOrderPhotoType | 'other';
                if ((Object.values(LabsGqlLabOrderPhotoType) as unknown as string[]).includes(e ?? '')) {
                    newPhotoType = e as LabsGqlLabOrderPhotoType;
                } else {
                    newPhotoType = 'other';
                }
                // only apply photo type logic to non zip files
                if (!isZipFile(file)) {
                    const originalPhotoType = fileToFileType[file.uploadName];
                    if (originalPhotoType) {
                        const originalGroupedFiles = groupedFiles[originalPhotoType];
                        if (originalGroupedFiles) {
                            groupedFiles[originalPhotoType] = originalGroupedFiles.filter(
                                f => f.uploadName !== file.uploadName,
                            );
                        }
                    }
                }
                fileToFileType[file.uploadName] = newPhotoType;
                setValue(fileToFileType[file.uploadName] ?? '');
                if (!isZipFile(file)) {
                    const newGroupedFiles = groupedFiles[newPhotoType];
                    if (newGroupedFiles) {
                        groupedFiles[newPhotoType] = [...newGroupedFiles, file];
                    } else {
                        groupedFiles[newPhotoType] = [file];
                    }
                }
            }}
        />
    );
};

const FilesList: React.VFC<{
    filesToDisplay: UploadedFile[];
    groupedFiles: Record<string, UploadedFile[]>;
}> = props => {
    const { filesToDisplay, groupedFiles } = props;
    return (
        <>
            {filesToDisplay.map(file => (
                <FileLayout key={file.file.name}>
                    <FileTitleLayout>
                        <FileListPreviewImage file={file} variant={'listView'} maxThumbnailSize={24} />
                        <ScanFileTitle variant={'body2'} medium>
                            {file.file.name}
                        </ScanFileTitle>
                    </FileTitleLayout>
                    <AttachmentTypeAssignment
                        file={file}
                        groupedFiles={groupedFiles}
                        style={{ width: 'calc(100% - 40px)', marginLeft: 40 }}
                    />
                </FileLayout>
            ))}
        </>
    );
};

interface AssignFilesListProps {
    previewedFile: string;
    hasUploadedMultipleThreeOxzs: boolean;
    setLoading: (value: boolean) => void;
    setPreviewedFile: (value: string) => void;
    groupedFiles: Record<string, UploadedFile[]>;
    onRemoveFile: (uploadName: string) => Promise<void>;
    files: UploadedFile[];
}

/* eslint-disable-next-line max-lines-per-function */
export const AssignFilesList: React.VFC<AssignFilesListProps> = props => {
    const {
        groupedFiles,
        previewedFile,
        hasUploadedMultipleThreeOxzs,
        setPreviewedFile,
        setLoading,
        onRemoveFile,
        files,
    } = props;

    const setUploadFiles = useSetAtom(uploadFilesUploadedFilesAtom);
    const [scansMap, setScansMap] = useAtom(uploadFilesScansMapAtom);
    const [deletedFiles, setDeletedFiles] = React.useState<string[]>([]);

    const filesToDisplay = files.filter(
        file => !scansMap.has(file.file.name) && !deletedFiles.includes(file.file.name),
    );

    const scanOptions = React.useMemo(() => {
        const assignedScanTypes: Set<string | null> = new Set();
        scansMap.forEach(scanUpload => {
            if (scanUpload.source !== ScanSource.SingleScan) {
                scanUpload.scans.map(s => {
                    if (s.definition && !hasUploadedMultipleThreeOxzs) {
                        assignedScanTypes.add(s.scan_type);
                    }
                });
            } else {
                if (scanUpload.scan.definition) {
                    assignedScanTypes.add(scanUpload.scan.scan_type);
                }
            }
        });
        return SCAN_OPTIONS.map(option => {
            // check to see if option is already assigned in assignedScanTypes
            if (option.value !== 'BiteScan' && assignedScanTypes.has(option.value)) {
                return { ...option, disabled: true };
            }
            return option;
        });
    }, [scansMap, hasUploadedMultipleThreeOxzs]);

    React.useEffect(() => {
        setUploadFiles(files);
    }, [files, setUploadFiles]);

    return (
        <>
            <FilesList filesToDisplay={filesToDisplay} groupedFiles={groupedFiles} />
            {[...scansMap.entries()].map(([fileName, scanUpload]) => {
                const file = files.find(file => file.file.name === fileName);
                const scanHasMultipleScanFiles =
                    scanUpload.source === ScanSource.ThreeOxz || scanUpload.source === ScanSource.Zip;
                const isPreviewingScan = previewedFile === fileName;

                return (
                    <ScanFileContainer key={fileName}>
                        <ScanFileLayout style={{ padding: scanHasMultipleScanFiles ? '16px 8px 0 8px' : '16px 8px' }}>
                            <ScanFileControlsLayout>
                                <ScanFileTitleLayout>
                                    {file && (
                                        <FileListPreviewImage
                                            file={file}
                                            variant={scanHasMultipleScanFiles ? 'listView' : 'scanView'}
                                            maxThumbnailSize={24}
                                        />
                                    )}
                                    <ScanFileTitle variant={'body2'} medium>
                                        {fileName}
                                    </ScanFileTitle>
                                    {!scanHasMultipleScanFiles && (
                                        <ScanFileButton
                                            variant={'ghost'}
                                            isPreviewing={isPreviewingScan}
                                            onClick={() => setPreviewedFile(fileName ?? '')}
                                        >
                                            {isPreviewingScan ? 'Previewing' : 'Preview'}
                                        </ScanFileButton>
                                    )}
                                </ScanFileTitleLayout>
                                {!scanHasMultipleScanFiles && (
                                    <ScanFileButton
                                        variant={'ghost'}
                                        onClick={async () => {
                                            setLoading(true);
                                            if (isPreviewingScan) {
                                                setPreviewedFile('');
                                            }
                                            // Add the file name to the deletedFiles array
                                            setDeletedFiles([...deletedFiles, fileName]);
                                            // If the file has an uploadName, remove the file
                                            if (file?.uploadName) {
                                                await onRemoveFile(file.uploadName);
                                            }
                                            // Delete the file from the scansMap
                                            const newScansMap = new Map(scansMap);
                                            newScansMap.delete(fileName);
                                            // Update the scansMap state
                                            setScansMap(newScansMap);
                                            setLoading(false);
                                        }}
                                    >
                                        <TrashIcon />
                                    </ScanFileButton>
                                )}
                            </ScanFileControlsLayout>
                            {!scanHasMultipleScanFiles && (
                                <ScanTypeAssignmentLayout>
                                    <ScanTypeAssignment
                                        scanEntry={scanUpload.scan}
                                        scanSource={scanUpload.source}
                                        scanOptions={scanOptions}
                                    />
                                </ScanTypeAssignmentLayout>
                            )}
                        </ScanFileLayout>
                        {scanHasMultipleScanFiles && (
                            <SubScanFileContainer>
                                {scanUpload.scans.map((scanEntry, idx) => {
                                    const isPreviewingSubScan = previewedFile === `${fileName}-${scanEntry.file_name}`;
                                    return (
                                        <SubScanFileLayout key={idx}>
                                            <SubScanFileControlsLayout>
                                                <div style={{ display: 'flex' }}>
                                                    {file && (
                                                        <FileListPreviewImage
                                                            file={file}
                                                            variant={'scanView'}
                                                            maxThumbnailSize={24}
                                                        />
                                                    )}
                                                    <ScanFileTitle variant={'body2'} medium>
                                                        {deSuffixFilename(scanEntry.file_name ?? '')}
                                                    </ScanFileTitle>
                                                </div>
                                                <ScanFileButton
                                                    variant={'ghost'}
                                                    isPreviewing={isPreviewingSubScan}
                                                    onClick={() =>
                                                        setPreviewedFile(`${fileName}-${scanEntry.file_name ?? ''}`)
                                                    }
                                                >
                                                    {isPreviewingSubScan ? 'Previewing' : 'Preview'}
                                                </ScanFileButton>
                                            </SubScanFileControlsLayout>
                                            {file && (
                                                <SubScanTypeAssignmentLayout>
                                                    <ScanTypeAssignment
                                                        didUploadMultipleThreeOxzFiles={hasUploadedMultipleThreeOxzs}
                                                        scanEntry={scanEntry}
                                                        scanSource={scanUpload.source}
                                                        scanOptions={scanOptions}
                                                        fileName={fileName}
                                                    />
                                                    <ScanFileButton
                                                        variant={'ghost'}
                                                        onClick={async () => {
                                                            setLoading(true);
                                                            if (isPreviewingSubScan) {
                                                                setPreviewedFile('');
                                                            }
                                                            const newScansMap = new Map(scansMap);
                                                            // If this is the last file, remove the source file
                                                            if (scanUpload.scans.length === 1 && file?.uploadName) {
                                                                newScansMap.delete(fileName);
                                                                await onRemoveFile(file.uploadName);
                                                                const updatedDeletedFiles = deletedFiles.filter(
                                                                    file =>
                                                                        ![...scanUpload.scans.values()]
                                                                            .map(file => file.file_name ?? '')
                                                                            .includes(file),
                                                                );
                                                                setDeletedFiles([...updatedDeletedFiles, fileName]);
                                                            } else {
                                                                const updatedScans = scanUpload.scans;
                                                                updatedScans.splice(idx, 1);
                                                                newScansMap.set(fileName, {
                                                                    source: scanUpload.source,
                                                                    scans: updatedScans,
                                                                });
                                                                setDeletedFiles([
                                                                    ...deletedFiles,
                                                                    scanEntry.file_name ?? '',
                                                                ]);
                                                            }
                                                            setScansMap(newScansMap);
                                                            setLoading(false);
                                                        }}
                                                    >
                                                        <TrashIcon />
                                                    </ScanFileButton>
                                                </SubScanTypeAssignmentLayout>
                                            )}
                                        </SubScanFileLayout>
                                    );
                                })}
                            </SubScanFileContainer>
                        )}
                    </ScanFileContainer>
                );
            })}
        </>
    );
};
