import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType } from '@orthly/items';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

const TryInFeedbackExtensionsOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Denture>> = ({
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    return (
        <TryInFeedbackOptionWrapper
            title={'Extensions'}
            subtitle={'Denture extensions are off'}
            isSelected={isSelected}
            onSelected={() => {
                onSelected();
                onDescriptionChanged('Reduce extension');
            }}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <SimpleSelect
                label={'Adjust extensions'}
                value={description ?? undefined}
                onChange={val => onDescriptionChanged(val || undefined)}
                options={[
                    { value: 'Reduce extension', label: 'Reduce extension' },
                    { value: 'Extend further', label: 'Extend further' },
                ]}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackExtensionsOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Denture> = {
    sku: LabOrderItemSKUType.Denture,
    isArchSpecific: true,
    category: 'Extensions',
    isEnabled: () => true,
    Component: TryInFeedbackExtensionsOption,
};
