import { usePortalToChairsideBridgeCommand } from '../../../../utils/chairside.hooks';
import { TryInFeedbackUtils } from '../../../try_in_feedback/utils/TryInFeedback.util';
import { getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { ActionCardIcon } from '@orthly/ui';
import { stylesFactory, FlossPalette, Grid, InfoOutlinedIcon, Button, Text } from '@orthly/ui-primitives';
import { TryInFeedbackDetailForOrder_Fragment, useTryInFeedbackForOrder } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    banner: {
        borderRadius: 16,
        padding: 24,
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
}));

interface TryInFeedbackScanNeededAlertProps {
    // for legacy chairside clients, we actually want to start the case in chairside with the previous order rather than the current one
    // in newer chairside clients, we want to attach scans to the current order
    orderId: string;
    previousFulfillmentOrderId: string;
}

const TryInFeedbackScanNeededAlert: React.VFC<TryInFeedbackScanNeededAlertProps> = ({
    orderId,
    previousFulfillmentOrderId,
}) => {
    const classes = useStyles();
    const startDenturesFeedbackCase = usePortalToChairsideBridgeCommand('startDenturesFeedbackCase');
    const attachScansToOrder = usePortalToChairsideBridgeCommand('attachScansToOrder');

    // see TryInFeedbackSubmissionCompleted for more context on when each function is used
    const startChairsideCase = React.useMemo(() => {
        if (startDenturesFeedbackCase) {
            return () => startDenturesFeedbackCase({ order_id: previousFulfillmentOrderId });
        }
        if (attachScansToOrder) {
            return () => attachScansToOrder({ id: orderId });
        }
        return null;
    }, [startDenturesFeedbackCase, attachScansToOrder, orderId, previousFulfillmentOrderId]);

    return (
        <Grid container alignItems={'center'} className={classes.banner} wrap={'nowrap'}>
            <Grid item>
                <ActionCardIcon variant={'info'} IconComponent={InfoOutlinedIcon} />
            </Grid>
            <Grid item>
                <Text variant={'body1'} medium>
                    This order needs a scan
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    A wash impression and a 360 scan are required. Scan your patient in{' '}
                    {startChairsideCase ? 'Chairside' : '3Shape'} to continue this order.
                </Text>
            </Grid>
            {!!startChairsideCase && (
                <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
                    <Button variant={'primary'} onClick={() => startChairsideCase()} endIcon={'ChevronRight'}>
                        Start Scanning
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

const useTryInFeedbackDetailsForOrder = (orderId: string) => {
    const tryInFeedback = useTryInFeedbackForOrder(orderId);
    if (!tryInFeedback) {
        return null;
    }
    return tryInFeedback.try_in_feedback_details.map(detail =>
        getFragmentData(TryInFeedbackDetailForOrder_Fragment, detail),
    );
};

interface OrderDetailTryInFeedbackNeedsScanAlertProps {
    order: Pick<LabsGqlOrder, 'id' | 'items_v2' | 'replaced_scan_export_ids'> & {
        previous_fulfillment_order_id: string;
    };
}

/**
 * Used to display a message when feedback provided for the previous order in a sequence indicates that a scan is needed for this one.
 */
export const OrderDetailTryInFeedbackNeedsScanAlert: React.VFC<OrderDetailTryInFeedbackNeedsScanAlertProps> = ({
    order,
}) => {
    const tryInFeedbackDetails = useTryInFeedbackDetailsForOrder(order.previous_fulfillment_order_id);

    if (!tryInFeedbackDetails) {
        return null;
    }

    const items = OrderItemV2Utils.parseItems(order.items_v2);
    const feedbackRequiresNewScan = TryInFeedbackUtils.feedbackNeedsRescan(items, tryInFeedbackDetails);
    const hasReplacedScan = order.replaced_scan_export_ids?.length > 0;

    if (!feedbackRequiresNewScan || hasReplacedScan) {
        return null;
    }

    return (
        <TryInFeedbackScanNeededAlert
            orderId={order.id}
            previousFulfillmentOrderId={order.previous_fulfillment_order_id}
        />
    );
};
