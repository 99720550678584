import { MobileAuthLoginType } from '../mobile-auth/state/MobileAuth.actions';
import { useMobileAuthState } from '../mobile-auth/state/MobileAuth.reducer';
import { SelectStaffBackground } from './components';
import { SelectStaffAddPhoto } from './screens/SelectStaffAddPhoto';
import { SelectStaffAnimation } from './screens/SelectStaffAnimation';
import { SelectStaffChooseOccupation } from './screens/SelectStaffChooseOccupation';
import { SelectStaffChooseResponsibilities } from './screens/SelectStaffChooseResponsibilities';
import { SelectStaffCreationSuccess } from './screens/SelectStaffCreationSuccess';
import { SelectStaffInputContact } from './screens/SelectStaffInputContact';
import { SelectStaffLanding } from './screens/SelectStaffLanding';
import { SelectStaffSetOrderPreferences } from './screens/SelectStaffSetOrderPreferences';
import { useDoctorInactivityTimer, useInitializeSelectedDoctor } from './select-staff.hooks';
import { useSelectStaffAction } from './state/select-staff.actions';
import { CreateStaffStep } from './state/select-staff.progression';
import { selectShouldShowSelectStaff, useSelectStaffSelector } from './state/select-staff.selectors';
import { useFetchAllPracticeEmployeesQuery } from '@orthly/graphql-react';
import { useScreenIsMd } from '@orthly/ui-primitives';
import React from 'react';

export const SelectStaffRoot: React.VFC = () => {
    const show = useSelectStaffSelector(selectShouldShowSelectStaff);
    const createWithoutAutoLogin = useSelectStaffSelector(s => s.createWithoutAutoLogin);
    const {
        data: practiceEmployees,
        refetch: refetchPracticeEmployees,
        loading,
    } = useFetchAllPracticeEmployeesQuery({
        onCompleted: () => {},
    });

    React.useEffect(() => {
        show && void refetchPracticeEmployees();
    }, [show, refetchPracticeEmployees, createWithoutAutoLogin]);
    const initialized = useInitializeSelectedDoctor(practiceEmployees?.fetchAllPracticeEmployees ?? [], loading);
    const step = useSelectStaffSelector(({ step }) => step);
    const setStep = useSelectStaffAction('SET_SELECT_STAFF_STEP');
    const loginType = useMobileAuthState(s => s.loginType);
    const isMobile = useScreenIsMd();
    useDoctorInactivityTimer();

    const [isInitialAnimation, setIsInitialAnimation] = React.useState(true);
    React.useEffect(() => {
        if (isInitialAnimation && step === CreateStaffStep.Landing && loginType === MobileAuthLoginType.email) {
            setIsInitialAnimation(false);
            setStep(CreateStaffStep.Animation);
        }
    }, [isInitialAnimation, loginType, setStep, step]);

    return (
        <SelectStaffBackground
            show={show && initialized}
            showLogo={!isMobile || step === CreateStaffStep.Landing || step === CreateStaffStep.Animation}
        >
            <SelectStaffAnimation />
            <SelectStaffLanding practiceEmployees={practiceEmployees} />
            <SelectStaffChooseOccupation />
            <SelectStaffChooseResponsibilities />
            <SelectStaffAddPhoto />
            <SelectStaffInputContact />
            <SelectStaffSetOrderPreferences />
            <SelectStaffCreationSuccess />
        </SelectStaffBackground>
    );
};
