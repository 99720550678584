import { DEFAULT_TRANSPARENT_OPACITY } from '../ModelAppearance/Transparent.util';

/**
 * Calculates the current and past restorative transparencies from the transparency slider position
 * @param sliderValue The transparency slider value, on the interval [0, 1]
 * @returns Current and past restorative transparencies, if a valid slider value was received
 */
export function getOpacities(sliderValue: number): { current: number; past: number } | undefined {
    if (0 <= sliderValue && sliderValue <= 0.499) {
        return {
            current: DEFAULT_TRANSPARENT_OPACITY + ((1 - DEFAULT_TRANSPARENT_OPACITY) * sliderValue) / 0.5,
            past: 1,
        };
    }

    if (0.499 <= sliderValue && sliderValue <= 0.501) {
        return { current: 1, past: 1 };
    }

    if (0.501 <= sliderValue && sliderValue <= 1) {
        return { current: 1, past: 1 - ((1 - DEFAULT_TRANSPARENT_OPACITY) * (sliderValue - 0.5)) / 0.5 };
    }
}
