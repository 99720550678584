/* eslint-disable import/no-extraneous-dependencies */
import { PICKER_INPUT_ROOT_PADDING } from './SimplePickerStyles';
import type { DatePickerProps } from '@mui/x-date-pickers';
import { PickersLayout } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// This is needed for the DatePicker generics to work.
import type {} from '@mui/x-date-pickers/AdapterDayjs';
import { DATE_PICKER_CLASSNAME, FlossPalette, styled } from '@orthly/ui-primitives';
import { FormFieldInputWrapper } from '@orthly/ui-primitives';
import cx from 'clsx';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import React from 'react';

export type MaterialUiPickersDate = Date | null;

const StyledPickersLayout = styled(PickersLayout)({
    '& .MuiPickersDay-today': {
        backgroundColor: FlossPalette.WHITE,
        borderColor: FlossPalette.BLACK,
    },
    '& .MuiPickersDay-root.Mui-selected': {
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        color: FlossPalette.WHITE,
        borderColor: FlossPalette.PRIMARY_FOREGROUND,
    },
});

export type SimpleDatePickProps = Omit<
    DatePickerProps<Dayjs>,
    'value' | 'onChange' | 'minDate' | 'maxDate' | 'label'
> & {
    value: Date | null;
    onChange: (date: Date | null) => void;
    helperText?: string;
    errorText?: string;
    label?: string;
    fullWidth?: boolean;
    minDate?: Date | null;
    maxDate?: Date | null;
    legacyInputLayout?: boolean;
};

export const SimpleDatePicker = ({
    label,
    errorText,
    helperText,
    minDate,
    maxDate,
    sx,
    fullWidth,
    onChange,
    legacyInputLayout = true,
    ...rest
}: SimpleDatePickProps) => {
    const value = rest.value ? dayjs(rest.value) : null;

    if (legacyInputLayout) {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    {...rest}
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    value={value}
                    minDate={minDate ? dayjs(minDate) : undefined}
                    maxDate={maxDate ? dayjs(maxDate) : undefined}
                    slots={{
                        // @ts-expect-error it expects a generic, when it should expect the day-js type
                        layout: StyledPickersLayout,
                        ...rest.slots,
                    }}
                    slotProps={{
                        ...rest.slotProps,
                        textField: {
                            helperText: errorText || helperText,
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...rest.slotProps?.textField,
                        },
                    }}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        width: fullWidth ? '100%' : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                        ...sx,
                    }}
                    onChange={(date, _context) => {
                        onChange(date ? date.toDate() : null);
                    }}
                    label={label}
                />
            </LocalizationProvider>
        );
    }

    return (
        <FormFieldInputWrapper label={label || ''} errorText={errorText} helperText={helperText} helpTextBottom={true}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                    {...rest}
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    value={value}
                    minDate={minDate ? dayjs(minDate) : undefined}
                    maxDate={maxDate ? dayjs(maxDate) : undefined}
                    slots={{
                        // @ts-expect-error it expects a generic, when it should expect the day-js type
                        layout: StyledPickersLayout,
                        ...rest.slots,
                    }}
                    slotProps={{
                        ...rest.slotProps,
                        textField: {
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...rest.slotProps?.textField,
                        },
                    }}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        width: fullWidth ? '100%' : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                        ...sx,
                    }}
                    onChange={(date, _context) => {
                        onChange(date ? date.toDate() : null);
                    }}
                />
            </LocalizationProvider>
        </FormFieldInputWrapper>
    );
};

export type SimpleDatePopupPickProps = Omit<DatePickerProps<Dayjs>, 'value' | 'onChange' | 'minDate' | 'maxDate'> & {
    value: Date | null;
    onChange: (date: Date | null) => void;
    helperText?: string;
    errorText?: string;
    label?: string;
    minDate?: Date | null;
    maxDate?: Date | null;
    fullWidth?: boolean;
    legacyInputLayout?: boolean;
};

export const SimpleDatePopupPicker = ({
    label,
    errorText,
    helperText,
    minDate,
    maxDate,
    sx,
    fullWidth,
    onChange,
    closeOnSelect,
    slotProps,
    legacyInputLayout = true,
    ...rest
}: SimpleDatePopupPickProps) => {
    const [open, setOpen] = React.useState(false);
    const value = rest.value ? dayjs(rest.value) : null;

    if (legacyInputLayout) {
        return (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    slotProps={{
                        ...slotProps,
                        toolbar: {
                            hidden: true,
                            ...slotProps?.toolbar,
                        },
                        textField: {
                            helperText: errorText || helperText,
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...slotProps?.textField,
                        },
                    }}
                    slots={{
                        // @ts-ignore: TODO: This is a v4/v5 issue. Clean it up after MUI v5 is updated.
                        layout: StyledPickersLayout,
                        ...rest.slots,
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    label={label}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        width: fullWidth ? '100%' : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                        ...sx,
                    }}
                    {...rest}
                    value={value}
                    minDate={minDate ? dayjs(minDate) : undefined}
                    maxDate={maxDate ? dayjs(maxDate) : undefined}
                    onChange={(date, _context) => {
                        onChange(date ? date.toDate() : null);
                        if (closeOnSelect) {
                            setOpen(false);
                        }
                    }}
                />
            </LocalizationProvider>
        );
    }

    return (
        <FormFieldInputWrapper label={label || ''} errorText={errorText} helperText={helperText} helpTextBottom={true}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                    className={cx(DATE_PICKER_CLASSNAME, rest.className)}
                    slotProps={{
                        ...slotProps,
                        toolbar: {
                            hidden: true,
                            ...slotProps?.toolbar,
                        },
                        textField: {
                            // @ts-expect-error The types do not expect data-test, but it works
                            'data-test': rest['data-test'],
                            ...slotProps?.textField,
                        },
                    }}
                    slots={{
                        // @ts-ignore: TODO: This is a v4/v5 issue. Clean it up after MUI v5 is updated.
                        layout: StyledPickersLayout,
                        ...rest.slots,
                    }}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    sx={{
                        color: errorText ? FlossPalette.ATTENTION_FOREGROUND : undefined,
                        width: fullWidth ? '100%' : undefined,
                        ...PICKER_INPUT_ROOT_PADDING,
                        ...sx,
                    }}
                    {...rest}
                    value={value}
                    minDate={minDate ? dayjs(minDate) : undefined}
                    maxDate={maxDate ? dayjs(maxDate) : undefined}
                    onChange={(date, _context) => {
                        onChange(date ? date.toDate() : null);
                        if (closeOnSelect) {
                            setOpen(false);
                        }
                    }}
                />
            </LocalizationProvider>
        </FormFieldInputWrapper>
    );
};
