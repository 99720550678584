import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionBodyFreeText } from '../TryInFeedbackOptionBodyFreeText';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { LabOrderItemSKUType, OrderItemPartialDentureProductionType } from '@orthly/items';
import React from 'react';

const TryInFeedbackTeethSpacingOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial>> = ({
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    return (
        <TryInFeedbackOptionWrapper
            title={'Teeth spacing'}
            subtitle={'Inconsistencies in space between teeth'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={onUnselected}
            hasError={isSelected && showError && !description}
        >
            <TryInFeedbackOptionBodyFreeText
                label={'Specify teeth spacing changes'}
                value={description ?? undefined}
                onChange={onDescriptionChanged}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackTeethSpacingOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Partial> = {
    sku: LabOrderItemSKUType.Partial,
    isArchSpecific: true,
    category: 'Teeth spacing',
    isEnabled: item => item.partial_production_type !== OrderItemPartialDentureProductionType.WaxRim,
    Component: TryInFeedbackTeethSpacingOption,
};
