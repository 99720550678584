import { CrownBridgeAddOnsTable } from './CrownBridgeAddOnsTable';
import { CrownBridgeMaterialsTable } from './CrownBridgeMaterialsTable';
import { Grid, Text } from '@orthly/ui-primitives';

export const CrownBridgePricing: React.FC = () => (
    <Grid container direction={'column'} data-test={'pricing-content-CrownEtc'} style={{ gap: '24px' }}>
        <Grid item xs={12}>
            <Text data-test={'pricing-header'} variant={'h4'}>
                Crown & Bridge, Veneers, Inlays & Onlays
            </Text>
        </Grid>
        <Grid item xs={12}>
            <CrownBridgeMaterialsTable />
        </Grid>
        <Grid item xs={12}>
            <CrownBridgeAddOnsTable />
        </Grid>
    </Grid>
);
