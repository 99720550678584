import { FlossPaletteUtils, FlossPalette, Text } from '@orthly/ui-primitives';
import React from 'react';

export const WhatsDandyRecomendedBlurb: React.VFC = () => {
    return (
        <div
            style={{
                padding: 24,
                borderRadius: 16,
                border: `1px solid ${FlossPalette.SECONDARY_BACKGROUND}`,
                backgroundColor: FlossPaletteUtils.toRgba('SECONDARY_BACKGROUND', 0.3),
            }}
        >
            <Text variant={'h6'} bold style={{ marginBottom: 8 }}>
                What is Dandy Recommended?
            </Text>
            <Text variant={'body2'} medium color={'DARK_GRAY'}>
                Our recommended default preferences correlate with lower remake rates and result in better smiles.
            </Text>
        </div>
    );
};
