/* eslint-disable max-lines */
import {
    useAlignerCheckoutPropSelector,
    useAlignerCheckoutSelector,
    useCheckoutPropSelector,
    useCheckoutSelector,
} from '../../../../redux';
import { CarestreamPostAction, useCarestreamPostback } from '../../state/CarestreamPostUtils';
import { CheckoutDisplayUtils } from '../../state/CheckoutDisplayUtils';
import { useAlignerCheckoutAction } from '../../state/aligners-checkout.actions';
import { selectAlignerCaseStage, useAlignerCheckoutStepCompletionState } from '../../state/aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { useCheckoutAction } from '../../state/checkout.actions';
import { getCurrentScreen } from '../../state/checkout.screens';
import type { CheckoutStepCompletionState } from '../../state/checkout.selectors';
import {
    implantCheckoutVisible,
    useCheckoutPatientInfoStepCompletionState,
    useExistingOrderScreenDisplayingSelector,
    useIsCarestreamSelector,
    useIsScannerSelector,
    useScanHasSurgicalGuide,
    useScanIsAligner,
} from '../../state/checkout.selectors';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import {
    dentureCheckoutStepCompletionState,
    useDentureCheckoutPropSelector,
    useDentureCheckoutSelector,
    useScanIsDenture,
} from '../../state/dentures-checkout.selectors';
import { implantCheckoutNextButtonEnabledState } from '../../state/implant-checkout.selectors';
import { AlignerCaseStage } from '../../state/reducers/aligners-checkout.types';
import { useCheckoutSummaryVisible } from '../../state/useCheckoutSummaryVisible';
import { useSubmitOrder } from '../../state/useSubmitOrder';
import { CheckoutNextButton } from './CheckoutNextButton';
import { useTrackOnNext } from './utils';
import { CheckoutTextButton, PracticeScreen } from '@orthly/dentin';
import type { LabsGqlCreatePendingAlignerCaseMutationVariables } from '@orthly/graphql-operations';
import {
    useCreatePendingAlignerCaseMutation,
    usePendingAlignerCaseForScanExportLazyQuery,
} from '@orthly/graphql-react';
import { LoadBlocker, RootActionDialog, useChangeSubmissionFn } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, Link, makeStyles, Tooltip, Fade, Grid, Text } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';
import { shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonRow: {
            padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(32)}`,
            right: '33.3333%',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: 'auto',
            transition: theme.transitions.create(['right'], {
                duration: 150,
                easing: theme.transitions.easing.sharp,
                delay: `${CheckoutDisplayUtils.summaryExitTimeout - 100}ms`,
            }),
            [theme.breakpoints.down('md')]: {
                position: 'relative',
                background: '#fff',
                padding: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(12)} ${theme.typography.pxToRem(
                    16,
                )}`,
            },
        },
        buttonRowPreStep2: {
            backgroundImage: `linear-gradient(to top, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0))`,
            zIndex: 1,
        },
        buttonRowSummary: {
            background: '#fff',
            right: theme.typography.pxToRem(48),
            [theme.breakpoints.down('md')]: {
                right: 0,
            },
        },
        nextButtonWrap: {
            width: theme.typography.pxToRem(300),
            position: 'relative',
            [theme.breakpoints.down('md')]: {
                width: '100%',
            },
        },
    }),
);

// Next button completion state is slightly different than CheckoutStepCompletionState because the next button needs
// to be enabled when the current item is complete, even though we are not ready to fully hop to the next CheckoutStep
// TODO: refactor all the checkout step logic so this type of comment isn't needed
function useNextButtonEnabledState(): CheckoutStepCompletionState & { fieldsRemaining?: number } {
    const isAlignerOrder = useScanIsAligner();
    const isDentureOrder = useScanIsDenture();
    const patientInfoStepComplete = useCheckoutPatientInfoStepCompletionState();
    const alignerStepComplete = useAlignerCheckoutStepCompletionState();
    const dentureStepComplete = useDentureCheckoutSelector(dentureCheckoutStepCompletionState);
    const { step: currentStep, stepTwoScreen } = useCheckoutPropSelector(['step', 'stepTwoScreen']);

    const refabState = useCheckoutSelector(s => s.refab);
    const implantCheckoutEnabledState = useCheckoutSelector(implantCheckoutNextButtonEnabledState);
    const stepOneComplete: CheckoutStepCompletionState = useCheckoutSelector(
        s => getCurrentScreen(s)?.completionState(s) ?? { complete: true },
        shallowEqual,
    );

    if (isDentureOrder) {
        return dentureStepComplete;
    }
    if (isAlignerOrder) {
        return alignerStepComplete;
    }

    switch (currentStep) {
        case 0: {
            return patientInfoStepComplete;
        }
        case 1: {
            return stepOneComplete;
        }
        case 2: {
            if (stepTwoScreen === 'refab' && refabState) {
                if (refabState.reasons.length === 0) {
                    return { complete: false, fieldsRemaining: 2, reason: `Please select one or more reason` };
                }
                if (!refabState.notes) {
                    return { complete: false, fieldsRemaining: 1, reason: `Please add notes` };
                }
                if (refabState.attachmentsUploading) {
                    return { complete: false, reason: 'Refabrication files uploading, please wait' };
                }
            }
            if (stepTwoScreen === 'implantImages') {
                return implantCheckoutEnabledState;
            }
        }
    }

    return { complete: true };
}

function useStep2NextText(): string {
    const { stepTwoScreen } = useCheckoutPropSelector(['stepTwoScreen']);
    const smileStyle = useCheckoutSelector(s => s.smileStyle);
    const cbct_url = useCheckoutSelector(s => s.surgicalGuideCheckout.cbct_url);
    switch (stepTwoScreen) {
        case 'summary':
            return 'Place Order';
        case 'smileStyle':
            return smileStyle ? `Continue` : `Skip`;
        case 'refab':
        case 'implantImages':
            return `Next`;
        case 'surgicalGuideCbctUpload':
            return cbct_url ? 'Next' : 'Skip';
        case 'aestheticPhotoUpload':
            return 'Next';
    }
}

function useNextText() {
    const { step } = useCheckoutPropSelector(['step']);
    const step2Text = useStep2NextText();

    switch (step) {
        case 0:
        case 1:
            return 'Next';
        case 2:
            return step2Text;
    }
}

function useAlignerNextText(): string {
    const step = useAlignerCheckoutSelector(s => s.step);
    switch (step) {
        case AlignerCheckoutStep.EstimateReview:
            return 'Review order';
        case AlignerCheckoutStep.Summary:
            return 'Submit case';
        default:
            return 'Next';
    }
}

type Vars = LabsGqlCreatePendingAlignerCaseMutationVariables['data'];

function useRequestEstimate(onNext: () => void) {
    const [submitMtn] = useCreatePendingAlignerCaseMutation();
    const mtnSubmitter = (vars: Vars) => submitMtn({ variables: { data: vars } });
    return useChangeSubmissionFn<any, [any]>(mtnSubmitter, {
        onSuccess: () => {
            onNext();
        },
        onError: e => console.error(e),
    });
}

interface CheckoutLayoutBottomProps {
    preStepClass?: boolean;
    hideBack: boolean;
    hideNext?: boolean;
    onNextClick: () => void;
    backButtonProps: { link: string } | { onClick: () => void };
    backButtonText: string;
    nextButtonText: string;
    loading?: boolean;
    nextButtonWarning?: boolean;
}

const SurgicalGuideLiabilityClause: React.FC = () => {
    const [isDisclaimerOpen, setIsDisclaimerOpen] = React.useState(false);
    const summaryShowing = useCheckoutSummaryVisible();
    const scanHasSurgicalGuide = useScanHasSurgicalGuide();
    return (
        <>
            <Fade in={summaryShowing && scanHasSurgicalGuide} timeout={800}>
                <Text
                    variant={'caption'}
                    medium
                    style={{
                        display: 'block',
                        color: FlossPalette.GRAY,
                        paddingLeft: 2,
                        marginBottom: 16,
                    }}
                >
                    By clicking "Place Order", you agree to Dandy's{' '}
                    <Link
                        style={{ color: FlossPalette.PRIMARY_FOREGROUND, cursor: 'pointer' }}
                        onClick={() => setIsDisclaimerOpen(true)}
                    >
                        Guided Surgery Liability Clause.
                    </Link>
                </Text>
            </Fade>
            <RootActionDialog
                loading={false}
                open={isDisclaimerOpen}
                setOpen={setIsDisclaimerOpen}
                title={<Text variant={'h4'}>Guided Surgery Liability Clause</Text>}
                content={
                    <Text variant={'body1'}>
                        You and your practice are wholly responsible for the treatment of patients with the products
                        provided by Dandy and with providing patients all relevant information about the products and
                        any related risks prior to and after treatment. You acknowledge that Dandy does not provide
                        medical, dental, or healthcare services and does not and cannot practice medicine, dentistry, or
                        give medical advice.
                    </Text>
                }
                showCloseButton={true}
                hideButton={true}
            />
        </>
    );
};

export const CheckoutLayoutBottom: React.FC<CheckoutLayoutBottomProps> = props => {
    const {
        preStepClass,
        backButtonProps,
        hideBack,
        hideNext,
        backButtonText,
        nextButtonText,
        onNextClick,
        nextButtonWarning,
    } = props;
    const classes = useStyles();
    const summaryShowing = useCheckoutSummaryVisible();
    const { complete: nextEnabled, reason: tooltipText, fieldsRemaining } = useNextButtonEnabledState();
    const showTooltip = !nextEnabled && fieldsRemaining === undefined;
    const { isSubmitDisabled } = useSubmitOrder();
    const isCarestream = useIsCarestreamSelector();
    const returnToCarestreamPartners = useCarestreamPostback<string>({ action: CarestreamPostAction.ReturnToHome });

    return (
        <Grid
            container
            className={cx({
                [classes.buttonRow]: true,
                [classes.buttonRowPreStep2]: preStepClass,
                [classes.buttonRowSummary]: summaryShowing,
            })}
        >
            <Grid container justifyContent={'flex-end'} alignItems={'flex-end'} wrap={'nowrap'}>
                {isCarestream && (
                    <CheckoutTextButton onClick={returnToCarestreamPartners} style={{ marginRight: 8, height: 48 }}>
                        Return to partners list
                    </CheckoutTextButton>
                )}
                <CheckoutTextButton
                    {...backButtonProps}
                    style={{ display: hideBack ? 'none' : undefined, marginRight: 8, height: 48 }}
                >
                    {backButtonText}
                </CheckoutTextButton>
                <Tooltip
                    arrow
                    // empty string is fine if the next button is enabled
                    title={tooltipText ?? ''}
                    disableHoverListener={!showTooltip}
                    disableFocusListener={!showTooltip}
                    disableTouchListener={!showTooltip}
                    style={{ display: hideNext ? 'none' : undefined }}
                >
                    <LoadBlocker
                        ContainerProps={{
                            className: classes.nextButtonWrap,
                            style: { display: hideNext ? 'none' : undefined },
                        }}
                        LoaderContainerProps={{ style: { height: '100%' } }}
                        blocking={props.loading ?? false}
                    >
                        <SurgicalGuideLiabilityClause />
                        <CheckoutNextButton
                            warning={!!nextButtonWarning}
                            next_disabled={!nextEnabled || isSubmitDisabled}
                            next={onNextClick}
                            remaining={fieldsRemaining ?? 0}
                            text={nextButtonText}
                        />
                    </LoadBlocker>
                </Tooltip>
            </Grid>
        </Grid>
    );
};

const nextButtonHiddenSteps: AlignerCheckoutStep[] = [AlignerCheckoutStep.Estimating, AlignerCheckoutStep.Rejection];

const backButtonHiddenSteps: AlignerCheckoutStep[] = [
    AlignerCheckoutStep.Estimating,
    AlignerCheckoutStep.EstimateReview,
];

interface SelectAlignerScanResult {
    loading: boolean;
    trigger: () => void;
}

function useOnSelectAlignerScan(): SelectAlignerScanResult {
    const scan = useCheckoutSelector(s => s.scan);
    const goToSummary = useAlignerCheckoutAction('GO_TO_SUMMARY');
    const nextStep = useAlignerCheckoutAction('NEXT_ALIGNER_STEP');
    const [triggerQuery, query] = usePendingAlignerCaseForScanExportLazyQuery({
        variables: { scan_export_id: scan?.id ?? '' },
        onCompleted: data => {
            const pendingCase = data.pendingAlignerCaseForScanExport;
            pendingCase ? goToSummary(pendingCase) : nextStep();
        },
    });
    const trigger = React.useCallback(triggerQuery, [triggerQuery]);
    return { trigger, loading: query.loading };
}

function useBackToPortal() {
    const history = useHistory();
    return React.useCallback(() => {
        history.push(`/${PracticeScreen.orders}`);
    }, [history]);
}

export const DentureCheckoutLayoutBottom: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
    const isScanner = useIsScannerSelector();
    const isCarestream = useIsCarestreamSelector();
    const { currentStep } = useDentureCheckoutPropSelector(['currentStep']);
    const onBack = useDenturesCheckoutAction('PREV_DENTURES_STEP');
    const onNext = useDenturesCheckoutAction('NEXT_DENTURES_STEP');
    const backButtonText = currentStep === 'PatientInfoStep' ? 'Back to Portal' : 'Back';
    const backToPortal = useBackToPortal();
    const backButtonProps = React.useMemo(() => {
        return { onClick: currentStep === 'PatientInfoStep' ? backToPortal : onBack };
    }, [currentStep, onBack, backToPortal]);
    const summaryShowing = useCheckoutSummaryVisible();
    const onNextClick = React.useCallback(() => {
        if (summaryShowing) {
            onSubmit();
        }
        onNext();
    }, [summaryShowing, onSubmit, onNext]);

    return (
        <CheckoutLayoutBottom
            backButtonProps={backButtonProps}
            backButtonText={backButtonText}
            hideBack={currentStep === 'PatientInfoStep' && (isScanner || isCarestream)}
            hideNext={false}
            nextButtonText={summaryShowing ? 'Place order' : 'Continue'}
            onNextClick={onNextClick}
            preStepClass={!summaryShowing}
        />
    );
};

export const AlignerCheckoutLayoutBottom: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
    const isScanner = useIsScannerSelector();
    const {
        step: currentStep,
        alignerImages,
        alignerArch,
        alignerTreatmentArea,
        biteConcerns,
        alignerNotes,
        crowdingResolution,
        movementRestrictedTeeth,
        attachmentRestrictedTeeth,
        extractionTeeth,
        interproximalSpaceSizes,
    } = useAlignerCheckoutPropSelector([
        'step',
        'alignerImages',
        'alignerArch',
        'alignerTreatmentArea',
        'biteConcerns',
        'alignerNotes',
        'crowdingResolution',
        'movementRestrictedTeeth',
        'attachmentRestrictedTeeth',
        'extractionTeeth',
        'interproximalSpaceSizes',
    ]);
    const stage = useAlignerCheckoutSelector(selectAlignerCaseStage);
    const { scan, patient_first_name, patient_last_name, doctor } = useCheckoutPropSelector([
        'scan',
        'patient_first_name',
        'patient_last_name',
        'doctor',
    ]);
    const summaryShowing = useCheckoutSummaryVisible();
    const onBack = useAlignerCheckoutAction('PREV_ALIGNER_STEP');
    const onNext = useAlignerCheckoutAction('NEXT_ALIGNER_STEP');
    const hideBack =
        (currentStep === AlignerCheckoutStep.PatientInformation && isScanner) ||
        backButtonHiddenSteps.includes(currentStep);
    const hideNext = nextButtonHiddenSteps.includes(currentStep);
    const nextButtonText = useAlignerNextText();
    const backButtonText = [AlignerCheckoutStep.Rejection, AlignerCheckoutStep.PatientInformation].includes(currentStep)
        ? 'Back to Portal'
        : 'Back';
    const backToPortal = useBackToPortal();
    const backButtonProps = React.useMemo(() => {
        return { onClick: currentStep === AlignerCheckoutStep.PatientInformation ? backToPortal : onBack };
    }, [currentStep, onBack, backToPortal]);
    const { submit: requestEstimate } = useRequestEstimate(onNext);
    const { loading, trigger: selectAlignerScan } = useOnSelectAlignerScan();
    const onNextClick = React.useCallback(() => {
        if (summaryShowing) {
            return onSubmit();
        }
        if (stage === AlignerCaseStage.InitialAligner || stage === AlignerCaseStage.Refinement) {
            if (currentStep === AlignerCheckoutStep.PatientInformation) {
                return selectAlignerScan();
            }
            if (currentStep === AlignerCheckoutStep.BiteConcerns && !!alignerImages) {
                return requestEstimate({
                    scan_export_id: scan?.id ?? '',
                    photos: alignerImages,
                    aligner_arch: alignerArch,
                    aligner_treatment_area: alignerTreatmentArea,
                    patient_name:
                        patient_first_name && patient_last_name
                            ? `${patient_first_name} ${patient_last_name}`
                            : undefined,
                    doctor_name: doctor?.name,
                    movement_restricted_teeth: movementRestrictedTeeth ?? [],
                    attachment_restricted_teeth: attachmentRestrictedTeeth ?? [],
                    extraction_teeth: extractionTeeth ?? [],
                    interproximal_space_sizes: interproximalSpaceSizes
                        ? interproximalSpaceSizes.filter(({ size }) => !Number.isNaN(size) && size > 0)
                        : [],
                    crowding_resolution: crowdingResolution,
                    bite_concerns: biteConcerns,
                    aligner_notes: alignerNotes,
                });
            }
        }
        onNext();
    }, [
        summaryShowing,
        stage,
        currentStep,
        alignerImages,
        onNext,
        onSubmit,
        requestEstimate,
        scan?.id,
        selectAlignerScan,
        doctor?.name,
        patient_first_name,
        patient_last_name,
        alignerArch,
        alignerTreatmentArea,
        biteConcerns,
        alignerNotes,
        crowdingResolution,
        movementRestrictedTeeth,
        attachmentRestrictedTeeth,
        extractionTeeth,
        interproximalSpaceSizes,
    ]);
    return (
        <CheckoutLayoutBottom
            backButtonProps={backButtonProps}
            backButtonText={backButtonText}
            hideBack={hideBack}
            hideNext={hideNext}
            nextButtonText={nextButtonText}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onNextClick={onNextClick}
            loading={loading}
        />
    );
};

export const RegularCheckoutLayoutBottom: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
    const isScanner = useIsScannerSelector();
    const currentStep = useCheckoutSelector(s => s.step);
    const existingOrderScreenDisplaying = useExistingOrderScreenDisplayingSelector();
    const implantImagesShowing = useCheckoutSelector(implantCheckoutVisible);
    const trackOnNext = useTrackOnNext();
    const onNext = useCheckoutAction('NEXT_STEP_V2');
    const onBack = useCheckoutAction('PREV_STEP_V2');
    const backToPortal = useBackToPortal();
    const backButtonProps = React.useMemo(
        () => ({ onClick: currentStep === 0 ? backToPortal : onBack }),
        [currentStep, onBack, backToPortal],
    );
    const hideBack = currentStep === 0 && isScanner;
    const nextButtonText = useNextText();
    const backButtonText = currentStep === 0 ? 'Back to Portal' : 'Back';
    const summaryShowing = useCheckoutSummaryVisible();
    const onNextClick = React.useCallback(() => {
        trackOnNext();

        if (summaryShowing) {
            return onSubmit();
        }
        onNext();
    }, [onNext, onSubmit, summaryShowing, trackOnNext]);

    if (existingOrderScreenDisplaying || implantImagesShowing) {
        return null;
    }
    return (
        <CheckoutLayoutBottom
            backButtonProps={backButtonProps}
            backButtonText={backButtonText}
            hideBack={hideBack}
            nextButtonText={nextButtonText}
            onNextClick={onNextClick}
            preStepClass={currentStep < 2}
        />
    );
};
