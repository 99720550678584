import type { TraineeFormFieldProps } from '../TrainingDataIntake.types';
import { PrepStylePreferenceOptions } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { Text, CheckboxesGroup } from '@orthly/ui-primitives';
import React from 'react';

export const DentistPrepStylePreferenceField: React.VFC<TraineeFormFieldProps> = props => {
    const { trainee, setTrainee } = useTrainee(props.traineeAtom);

    return (
        <CheckboxesGroup
            hasColumns
            variant={'compact'}
            values={Object.fromEntries(
                PrepStylePreferenceOptions.map(style => [style, trainee.prepStyles?.includes(style) ?? false]),
            )}
            options={PrepStylePreferenceOptions.map(style => ({
                id: style,
                label: (
                    <Text variant={'body2'} style={{ whiteSpace: 'nowrap' }}>
                        {style}
                    </Text>
                ),
                value: style,
            }))}
            handleChange={value => {
                const hasPrepStyle = trainee.prepStyles?.includes(value);
                const updatedPrepStyles = hasPrepStyle
                    ? trainee.prepStyles?.filter(style => style !== value) ?? []
                    : trainee.prepStyles?.concat(value) ?? [value];

                setTrainee(oldValue => ({
                    ...oldValue,
                    prepStyles: updatedPrepStyles,
                }));
            }}
        />
    );
};
