import { OrderAction, OrderActionTrackerIdMap } from '../OrderActionsUtils';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useAddressesForPartner, useChangeDeliveryAddressMutation } from '@orthly/graphql-react';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { RootActionDialog, SimpleSelect, useRootActionCommand } from '@orthly/ui';
import type { ButtonVariant } from '@orthly/ui-primitives';
import { Button, Icon, Tooltip, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const ContainerDiv = styled('div')({
    marginTop: 16,
});

const BottomButtonsContainer = styled('div')({
    marginTop: 48,
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gridGap: 8,
    width: '100%',
});

const StyledButton = styled(Button)({
    height: 'unset',
    minWidth: 'unset',
});

// Order statues in which editing the delivery address is no longer valid.
const BANNED_STATUSES: LabsGqlLabOrderStatus[] = [
    LabsGqlLabOrderStatus.Shipped,
    LabsGqlLabOrderStatus.Delivered,
    LabsGqlLabOrderStatus.NeedsRefabrication,
    LabsGqlLabOrderStatus.Fabrication,
    LabsGqlLabOrderStatus.Cancelled,
];

export const EditAddressAction: React.FC<{
    order: LabsGqlOrder;
    isIconOnly?: boolean;
    buttonVariant?: ButtonVariant;
    showTooltip?: boolean;
    showDropdownIcon?: boolean;
    fullWidth?: boolean;
    leftAlign?: boolean;
    refetch: () => Promise<void>;
}> = ({
    order,
    leftAlign,
    refetch,
    isIconOnly = false,
    buttonVariant = 'ghost',
    showDropdownIcon = false,
    fullWidth = false,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [addressId, setAddressId] = React.useState<string>(order.mailing_address_id);
    const { addresses, loading } = useAddressesForPartner(order.partner_id);

    const submitMtn = useChangeDeliveryAddressMutation();
    const { submit, submitting } = useRootActionCommand(submitMtn, {
        successMessage: 'Updated delivery address!',
        onSuccess: () => {
            void refetch();
            setOpen(false);
        },
    });

    const canEditAddress = !BANNED_STATUSES.includes(order.status);

    return (
        <>
            <RootActionDialog
                open={open}
                setOpen={setOpen}
                loading={false}
                showCloseButton
                title={'Change delivery address'}
                PaperProps={{
                    // We prevent propagation because the mobile app renders this component in a popover.
                    // That popover would close if we allow the upward propagation.
                    onClick: e => e.stopPropagation(),
                }}
                content={
                    <ContainerDiv>
                        <Text variant={'body2'} medium>
                            Delivery Address
                        </Text>
                        <SimpleSelect
                            options={addresses.map(addy => ({
                                value: addy.id,
                                label: LabsUtilsBase.addressToString(addy),
                            }))}
                            label={'Delivery Address'}
                            onChange={value => {
                                if (value) {
                                    setAddressId(value);
                                }
                            }}
                            value={addressId}
                        />
                        <BottomButtonsContainer>
                            <span />
                            <Button onClick={() => setOpen(false)} variant={'secondary'}>
                                Cancel
                            </Button>
                            <Button
                                onClick={() =>
                                    submit({
                                        data: {
                                            address_id: addressId,
                                            orderId: order.id,
                                        },
                                    })
                                }
                                disabled={loading || submitting}
                                variant={'primary'}
                            >
                                Save changes
                            </Button>
                        </BottomButtonsContainer>
                    </ContainerDiv>
                }
                CustomButton={() => (
                    <Tooltip
                        arrow
                        title={canEditAddress ? 'Change Delivery Address' : 'Delivery address can no longer be edited'}
                    >
                        <span style={{ width: fullWidth ? '100%' : undefined }}>
                            <StyledButton
                                fullWidth={fullWidth}
                                leftAlign={leftAlign}
                                data-tracker-id={OrderActionTrackerIdMap[OrderAction.Download]}
                                variant={buttonVariant}
                                startIcon={isIconOnly ? undefined : 'PencilOutlinedIcon'}
                                endIcon={showDropdownIcon ? 'ChevronDown' : undefined}
                                disabled={!canEditAddress}
                                onClick={e => {
                                    e.stopPropagation();
                                    setOpen(true);
                                }}
                                analytics={{
                                    AssetName: 'Change Address',
                                    AssetLocation: 'Practice - Order - Order Details Page',
                                    AssetCTAText: 'Change Address',
                                }}
                            >
                                {isIconOnly ? <Icon icon={'PencilOutlinedIcon'} /> : 'Change Address'}
                            </StyledButton>
                        </span>
                    </Tooltip>
                )}
            />
        </>
    );
};
