import type { CheckoutState } from '../../../state/checkout.state';
import { useDentureCheckoutSelector } from '../../../state/dentures-checkout.selectors';
import type { DentureCheckoutState } from '../../../state/reducers/dentures-checkout.types';
import { DentureCheckoutStep as DentureCheckoutStepUnordered } from '../../../state/reducers/dentures-checkout.types';
import { patientInfoTexts } from './getRegularCheckoutSidebarData';
import type { CheckoutSidebarStepContainerProps, CheckoutSidebarStepProps } from '@orthly/dentin';
import {
    CheckoutSidebarImageStep,
    CheckoutSidebarStepVariant,
    CheckoutSidebarTextVariant,
    DandyDentureMouthDisplay,
} from '@orthly/dentin';
import {
    LabsGqlDenturesBiteAdjustmentType,
    LabsGqlDenturesHandleDiastemaOption,
    LabsGqlDenturesMidlineCorrectionType,
    type LabsGqlSmileStyleChoice,
} from '@orthly/graphql-schema';
import { LabsGqlDenturesFabricationMethod, LabsGqlDenturesProductionType } from '@orthly/graphql-schema';
import { DentureType, OrderItemArch } from '@orthly/items';
import type { Falsy } from '@orthly/runtime-utils';
import _ from 'lodash';
import React from 'react';

enum DentureCheckoutStep {
    // this needs to be a comparable enum
    PatientInfo = 0,

    InitialOrderChooseType = 1,
    OrderNotes = 2,

    // normal branch
    InitialNonWaxRimOrderDentureOptions = 3,
    InitialNonWaxRimOrderDentureStyle = 4,
    DentureFestooningLevel = 5,
    DentureAestheticOptions = 6,
    InitialNonWaxRimOrderPhotosChoice = 7,
    InitialNonWaxRimOrderPhotosReview = 8,

    AddSpareDenturesStep = 17,

    Summary = 18,
}

const DentureCheckoutStepMap: { [_ in DentureCheckoutStepUnordered]: DentureCheckoutStep } = {
    [DentureCheckoutStepUnordered.PatientInfoStep]: DentureCheckoutStep.PatientInfo,
    [DentureCheckoutStepUnordered.InitialOrderChooseTypeStep]: DentureCheckoutStep.InitialOrderChooseType,
    [DentureCheckoutStepUnordered.InitialNonWaxRimOrderDentureOptionsStep]:
        DentureCheckoutStep.InitialNonWaxRimOrderDentureOptions,
    [DentureCheckoutStepUnordered.InitialNonWaxRimOrderDentureStyleStep]:
        DentureCheckoutStep.InitialNonWaxRimOrderDentureStyle,
    [DentureCheckoutStepUnordered.DentureFestooningStep]: DentureCheckoutStep.DentureFestooningLevel,
    [DentureCheckoutStepUnordered.DentureAestheticStep]: DentureCheckoutStep.DentureAestheticOptions,
    [DentureCheckoutStepUnordered.InitialNonWaxRimOrderPhotosChoiceStep]:
        DentureCheckoutStep.InitialNonWaxRimOrderPhotosChoice,
    [DentureCheckoutStepUnordered.InitialNonWaxRimOrderPhotosReviewStep]:
        DentureCheckoutStep.InitialNonWaxRimOrderPhotosReview,
    [DentureCheckoutStepUnordered.OrderNotesStep]: DentureCheckoutStep.OrderNotes,
    [DentureCheckoutStepUnordered.AddSpareDenturesStep]: DentureCheckoutStep.AddSpareDenturesStep,
    [DentureCheckoutStepUnordered.Summary]: DentureCheckoutStep.Summary,
};

const toOrderedDentureCheckoutStep = ({ currentStep }: DentureCheckoutState): DentureCheckoutStep => {
    return DentureCheckoutStepMap[currentStep];
};

const DentureCheckoutReviewItemComponent: React.FC<CheckoutSidebarStepContainerProps> = props => {
    // minimal adaptor from old checkout sidebar CheckoutSidebarItems
    const { dentureType, dentureArch, waxRimContinuation } = useDentureCheckoutSelector(s => s.fields);
    const isSelectionMade = (dentureType || waxRimContinuation) && dentureArch;
    return (
        <CheckoutSidebarImageStep
            image={
                dentureArch && (
                    <DandyDentureMouthDisplay
                        upper={([`Dual`, `Upper`] as const).some(a => OrderItemArch[a] === dentureArch)}
                        lower={([`Dual`, `Lower`] as const).some(a => OrderItemArch[a] === dentureArch)}
                    />
                )
            }
            texts={[
                {
                    caption: isSelectionMade
                        ? `1x ${dentureArch} Arch ${
                              waxRimContinuation ? 'Wax Rim Continuation' : _.startCase(dentureType)
                          } Denture`
                        : 'Making a selection',
                    // eslint-disable-next-line no-nested-ternary
                    content: !isSelectionMade
                        ? '-'
                        : props.variant === CheckoutSidebarStepVariant.Current
                          ? 'To review'
                          : 'Done',
                    variant:
                        props.variant === CheckoutSidebarStepVariant.Current
                            ? CheckoutSidebarTextVariant.Current
                            : undefined,
                },
            ]}
            {...props}
        />
    );
};

const getQualityContent = (fabrication: LabsGqlDenturesFabricationMethod | undefined) => {
    if (!fabrication) {
        return undefined;
    }

    return fabrication === LabsGqlDenturesFabricationMethod.Printed ? 'Signature' : 'Aesthetic';
};

const getProductionTypeContent = (productionType: LabsGqlDenturesProductionType | undefined) => {
    if (!productionType) {
        return undefined;
    }

    return productionType === LabsGqlDenturesProductionType.Final ? 'Straight-to-Finish' : 'Try-In';
};

const dentureOptionsTexts = ({ fields: { dentureOptions } }: DentureCheckoutState) => [
    { caption: 'Tooth Shade', content: dentureOptions?.shades?.find(shade => shade.name === 'base')?.value },
    { caption: 'Tissue Shade', content: dentureOptions?.shades?.find(shade => shade.name === 'tissue')?.value },
    {
        caption: 'Quality',
        content: getQualityContent(dentureOptions?.fabrication),
    },
    {
        caption: 'Fabrication',
        content: getProductionTypeContent(dentureOptions?.productionType),
    },
];

const dentureStyleReadableMap: { [_ in LabsGqlSmileStyleChoice]: string } = {
    Aggressive: 'Aggressive',
    Hollywood: 'Hollywood',
    Youthful: 'Youthful',
    Mature: 'Mature',
    LabRecommendation: 'Lab Choice',
    ReplicatePreOpAnatomy: 'Replicate pre-op anatomy',
    ReplicateWaxUp: 'Replicate wax-up',
    Enhanced: 'Enhanced',
    Soft: 'Soft',
    MirrorContralateral: 'Mirror Contralateral',
};

const dentureStyleTexts = ({ fields: { dentureStyle } }: DentureCheckoutState) => [
    {
        caption: 'Smile Style',
        content: dentureStyle && dentureStyleReadableMap[dentureStyle],
    },
];

const dentureFestooningTexts = ({ fields: { festooningLevel } }: DentureCheckoutState) => [
    {
        caption: 'Festooning Level',
        content: festooningLevel,
    },
];

const getDiastemaContent = (handleDiastema: LabsGqlDenturesHandleDiastemaOption | undefined) => {
    if (!handleDiastema) {
        return undefined;
    }

    if (handleDiastema === LabsGqlDenturesHandleDiastemaOption.NoDiastema) {
        return 'No';
    } else if (handleDiastema === LabsGqlDenturesHandleDiastemaOption.KeepDiastema) {
        return 'Keep it';
    }

    return 'Close it';
};

const getMidlineContent = (
    correctionType: LabsGqlDenturesMidlineCorrectionType | undefined,
    distance: number | null | undefined,
) => {
    if (!correctionType) {
        return undefined;
    }

    if (correctionType === LabsGqlDenturesMidlineCorrectionType.LeaveAsIs) {
        return 'Leave as is';
    } else if (correctionType === LabsGqlDenturesMidlineCorrectionType.ShiftLeft) {
        return `Shift left ${distance}mm`;
    } else if (correctionType === LabsGqlDenturesMidlineCorrectionType.ShiftRight) {
        return `Shift right ${distance}mm`;
    } else if (correctionType === LabsGqlDenturesMidlineCorrectionType.MatchUpperMidline) {
        return 'Match upper';
    }

    return 'Match lower';
};

const dentureAestheticTexts = ({ fields }: DentureCheckoutState) => [
    {
        caption: 'Diastema',
        content: getDiastemaContent(fields.handleDiastema),
    },
    {
        caption: 'Bite Adjustment',
        content:
            fields.biteAdjustment?.adjustment === LabsGqlDenturesBiteAdjustmentType.LeaveAsIs
                ? 'No'
                : fields.biteAdjustment?.adjustment &&
                  `${fields.biteAdjustment?.adjustment} ${fields.biteAdjustment?.adjustmentDistance}mm`,
    },
    {
        caption: 'Midline Correction',
        content: getMidlineContent(
            fields.midlineCorrection?.correctionType,
            fields.midlineCorrection?.correctionDistance,
        ),
    },
    {
        caption: 'Stippling',
        content: fields.aestheticOptions?.addStippling === true ? 'Yes' : 'No',
    },
    {
        caption: 'Post-Dam',
        content: fields.aestheticOptions?.addPostDam === true ? 'Yes' : 'No',
    },
    {
        caption: 'Correct Occlusal',
        content: fields.aestheticOptions?.correctOcclusalScheme === true ? 'Yes' : 'No',
    },
];

interface DentureCheckoutSidebarSubFlowParameters {
    dentureCheckout: DentureCheckoutState;
    checkout: CheckoutState;
    step: DentureCheckoutStep;
    variantOfStep: (...currentSteps: DentureCheckoutStep[]) => CheckoutSidebarStepVariant | undefined;
}

function getDentureCheckoutSidebarNormalFlowData({
    dentureCheckout,
    checkout,
    step,
    variantOfStep,
}: DentureCheckoutSidebarSubFlowParameters): CheckoutSidebarStepProps[] {
    const isWaxRim = dentureCheckout.fields.dentureType === DentureType.WaxRim;

    const initialSteps: (Falsy | CheckoutSidebarStepProps)[] = [
        {
            variant: variantOfStep(DentureCheckoutStep.PatientInfo),
            kind: 'text',
            texts: patientInfoTexts(checkout),
            contentPlaceholder: '-',
        },
        {
            variant: variantOfStep(DentureCheckoutStep.InitialOrderChooseType),
            kind: 'custom',
            Component: DentureCheckoutReviewItemComponent,
        },
    ];

    const middleWaxRimSteps: (Falsy | CheckoutSidebarStepProps)[] = [
        {
            variant: variantOfStep(DentureCheckoutStep.OrderNotes),
            kind: 'text',
            texts: [{ caption: 'Notes' }],
            contentPlaceholder: '-',
        },
    ];

    const middleNonWaxRimSteps: (Falsy | CheckoutSidebarStepProps)[] = [
        {
            variant: variantOfStep(DentureCheckoutStep.InitialNonWaxRimOrderDentureOptions),
            kind: 'text',
            texts: dentureOptionsTexts(dentureCheckout),
            contentPlaceholder: '-',
        },
        {
            variant: variantOfStep(DentureCheckoutStep.InitialNonWaxRimOrderDentureStyle),
            kind: 'text',
            texts: dentureStyleTexts(dentureCheckout),
            contentPlaceholder: '-',
        },
        {
            variant: variantOfStep(DentureCheckoutStep.DentureFestooningLevel),
            kind: 'text',
            texts: dentureFestooningTexts(dentureCheckout),
            contentPlaceholder: '-',
        },
        {
            variant: variantOfStep(DentureCheckoutStep.DentureAestheticOptions),
            kind: 'text',
            texts: dentureAestheticTexts(dentureCheckout),
            contentPlaceholder: '-',
        },
        {
            variant: variantOfStep(
                DentureCheckoutStep.InitialNonWaxRimOrderPhotosChoice,
                DentureCheckoutStep.InitialNonWaxRimOrderPhotosReview,
                DentureCheckoutStep.OrderNotes,
            ),
            kind: 'text',
            texts: [
                {
                    caption: 'Photos & notes',
                    content: step < DentureCheckoutStep.InitialNonWaxRimOrderPhotosChoice ? '-' : 'To do',
                },
            ],
        },
    ];

    const checkoutStep: CheckoutSidebarStepProps = {
        variant: CheckoutSidebarStepVariant.Checkout,
        kind: 'text',
        texts: [{ caption: 'Checkout' }],
    };

    const middleSteps = isWaxRim ? middleWaxRimSteps : middleNonWaxRimSteps;
    return _.compact([...initialSteps, ...middleSteps, checkoutStep]);
}

export function getDentureCheckoutSidebarData(
    dentureCheckout: DentureCheckoutState,
    checkout: CheckoutState,
): CheckoutSidebarStepProps[] {
    const step = toOrderedDentureCheckoutStep(dentureCheckout);

    const variantOfStep = (...currentSteps: DentureCheckoutStep[]) =>
        currentSteps.includes(step) ? CheckoutSidebarStepVariant.Current : undefined;

    return getDentureCheckoutSidebarNormalFlowData({ dentureCheckout, checkout, step, variantOfStep });
}
