import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

const UpdateUserPassword_Mutation = graphql(`
    mutation UpdateUserPassword($data: UpdateUserPasswordCommand!) {
        updateUserPassword(data: $data) {
            id
        }
    }
`);

export const useUpdateUserPassword = () => {
    const [submit] = useMutation(UpdateUserPassword_Mutation);
    const [error, setError] = React.useState<string>();
    const user_id = useSession()?.user_id;
    const history = useHistory();

    return {
        error,
        ...useChangeSubmissionFn(
            (password_raw: string) => {
                if (!user_id) {
                    throw new Error('Reset link invalid');
                }
                return submit({ variables: { data: { user_id, password_raw } } });
            },
            {
                disableDefaultErrorMessage: true,
                successMessage: () => ['Successfully updated password!', {}],
                onSuccess: () => {
                    setError(undefined);
                    history.push('/inbox');
                },
                onError: e => {
                    setError(e.message);
                },
            },
        ),
    };
};
