import { stylesFactory } from '../../util/stylesFactory';
import React from 'react';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';

const TIMEOUT = 200;
const useStyles = stylesFactory(() => ({
    fade: {
        '&-enter': {
            opacity: 0,
        },
        '&-enter-active, &-exit': {
            opacity: 1,
        },
        '&-exit-active': {
            opacity: 0,
        },
        '&-enter-active, &-exit-active': {
            transition: `opacity ${TIMEOUT}ms ease-in-out`,
        },
    },
}));

export interface SwitchFadeProps {
    /** if key changes, fade in/out children */
    switchKey: React.Key;
    /** has to be a `isValidElement` or `undefined` */
    children?: React.ReactElement;
    /** access DOM node safely in strict mode */
    nodeRef?: React.RefObject<HTMLElement>;
}

export const SwitchFade: React.VFC<SwitchFadeProps> = ({ switchKey, children, nodeRef }) => {
    const classes = useStyles();
    return (
        <SwitchTransition>
            <CSSTransition nodeRef={nodeRef} key={switchKey} classNames={classes.fade} timeout={TIMEOUT}>
                {children}
            </CSSTransition>
        </SwitchTransition>
    );
};

/**
 * Fade in child if it `isValidElement`, otherwise fade out.
 * Similar to `Fade` but does not mess with element.style
 */
export const SwitchFadeBinary: React.FC<Pick<React.ComponentPropsWithoutRef<typeof CSSTransition>, 'nodeRef'>> = ({
    children,
    nodeRef,
}) => {
    const classes = useStyles();
    return (
        <TransitionGroup component={null}>
            {React.isValidElement(children) && (
                <CSSTransition nodeRef={nodeRef} classNames={classes.fade} timeout={TIMEOUT}>
                    {children}
                </CSSTransition>
            )}
        </TransitionGroup>
    );
};
