import { FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')(({ theme }) => ({
    position: 'relative',
    width: '120px',
    [theme.breakpoints.down('sm')]: {
        width: '60px',
    },
}));

const FullBar = styled('div')({
    position: 'absolute',
    width: '100%',
    height: '8px',
    borderRadius: '8px',
    backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
});

const ProgressSection = styled('div')<{ percentComplete: number }>(({ percentComplete }) => ({
    position: 'absolute',
    width: `${percentComplete}%`,
    height: '8px',
    borderRadius: '8px',
    backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
}));

interface ProgressBarProps {
    percentComplete: number;
}

export const ProgressBar: React.VFC<ProgressBarProps> = ({ percentComplete }) => {
    return (
        <Container>
            <FullBar />
            <ProgressSection percentComplete={percentComplete} />
        </Container>
    );
};
