import type { SecondarySidebarItem } from '../PracticeSecondarySidebar.types';
import { OrdersToolbarItem } from './OrdersToolbarItem';
import { useOrdersToolbarSelectProps } from './useOrdersToolbarSelectProps';
import { SimpleSelect } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface ToolbarMobileFilterProps<ScreenType extends string> {
    screenItems: SecondarySidebarItem<ScreenType>[][];
    setScreen: (screen: ScreenType) => void;
    screen: ScreenType;
}

export function OrdersToolbarMobileScreenSelect<ScreenType extends string>(
    props: ToolbarMobileFilterProps<ScreenType>,
): React.ReactElement {
    const { screen, screenItems, setScreen } = props;
    const selectProps = useOrdersToolbarSelectProps();
    const options = React.useMemo(() => {
        return _.flatten(screenItems).map(section => ({
            value: section.value,
            label: `${section.label ?? section.value} (${section.count})`,
            disabled: section.count === 0,
        }));
    }, [screenItems]);
    return (
        <OrdersToolbarItem size={6}>
            <SimpleSelect
                {...selectProps}
                options={options}
                value={screen}
                onChange={value => {
                    value && setScreen(value as any);
                }}
                label={'Status'}
                variant={'filled'}
                backgroundColor={FlossPalette.WHITE}
                style={{ borderRadius: 0, border: 'none', boxShadow: 'none' }}
            />
        </OrdersToolbarItem>
    );
}
