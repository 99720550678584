import type { NotificationPreferencesSectionProps } from './utils';
import { contactOptionsForDoctor } from './utils';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import type { LabsGqlNotificationGroupDto } from '@orthly/graphql-schema';
import { Grid, Text } from '@orthly/ui-primitives';
import { NameForNotificationChannel } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const notifyPrefForPotentialIssues = (grouped_notification_preferences: LabsGqlNotificationGroupDto): string => {
    const notification_methods = [];
    if (
        grouped_notification_preferences.review_required_email ||
        grouped_notification_preferences.status_change_email
    ) {
        notification_methods.push(NameForNotificationChannel.email);
    }
    if (grouped_notification_preferences.review_required_sms || grouped_notification_preferences.status_change_sms) {
        notification_methods.push(NameForNotificationChannel.sms);
    }
    return notification_methods.join(' and ') || 'Not set';
};

export function doctorHasMissingNotificationPreferences(doctor: LabsGqlDoctorPreferencesFragment) {
    return (
        doctor.who_receives_dr_notifications === null ||
        doctor.contact_phone_call_number === null ||
        doctor.contact_phone === null ||
        doctor.contact_email === null ||
        doctor.best_call_hour === null ||
        doctor.timezone === null
    );
}

const SummaryRow: React.FC<{ label: string; value?: string | null }> = props => {
    const { label, value } = props;
    if (!value) {
        return null;
    }
    return (
        <Grid container justifyContent={'space-between'} alignItems={'center'} style={{ paddingBottom: 2 }}>
            <Text variant={'body2'} color={'DARK_GRAY'} style={{ maxWidth: '50%' }}>
                {label}
            </Text>
            <Text variant={'body2'} style={{ maxWidth: '50%', textAlign: 'right', wordBreak: 'break-word' }}>
                {value}
            </Text>
        </Grid>
    );
};
export const NotificationPreferencesSummaryTable: React.FC<NotificationPreferencesSectionProps> = props => {
    const { doctor } = props;
    return (
        <Grid container style={{ flexDirection: 'column', gap: 8 }}>
            <SummaryRow
                label={'Information for'}
                value={
                    contactOptionsForDoctor(doctor).find(opt => opt.value === doctor.who_receives_dr_notifications)
                        ?.label
                }
            />
            <SummaryRow
                label={'Notify about potential order or scan issues'}
                value={`Method: ${notifyPrefForPotentialIssues(doctor.grouped_notification_preferences)}`}
            />
            <SummaryRow label={'Email Address'} value={doctor.contact_email} />
            <SummaryRow label={'Phone number'} value={doctor.contact_phone_call_number} />
            {doctor.contact_phone_call_number !== doctor.contact_phone && (
                <SummaryRow label={'SMS number'} value={doctor.contact_phone} />
            )}
            {doctor.best_call_hour && (
                <SummaryRow
                    label={'Best time to call'}
                    value={_.compact([
                        LabsUtilsBase.nameForHourRange(doctor.best_call_hour),
                        doctor.timezone ? LabsUtilsBase.notificationTimeZoneAbbreviations[doctor.timezone] : null,
                    ]).join(' ')}
                />
            )}
        </Grid>
    );
};
