import { PracticeSidebarRight } from '../../../components/PracticeSidebarRight';
import { useChatFeatures } from '../../chat/utils';
import { HelpIcon } from '@orthly/ui';
import { FlossPalette, createStyles, makeStyles, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        helpItem: {
            padding: 24,
            backgroundColor: FlossPalette.WHITE,
            borderRadius: 16,
            marginTop: 16,
            border: `1px solid ${FlossPalette.DARK_TAN}`,
        },
    }),
);

const HelpItem: React.FC = () => {
    const classes = useStyles();
    const { openChat } = useChatFeatures();
    return (
        <Grid>
            <Grid className={classes.helpItem}>
                <HelpIcon />
                <Text variant={'h6'} style={{ marginTop: 16 }}>
                    What's a Scan body
                </Text>
                <Text variant={'body2'} style={{ marginTop: 16 }}>
                    Scan bodies capture the position and angulation of implants, allowing us to design the perfect
                    implant for your patients.
                </Text>
            </Grid>
            <Grid className={classes.helpItem} style={{ backgroundColor: FlossPalette.PRIMARY_BACKGROUND }}>
                <HelpIcon />
                <Text variant={'h6'} style={{ marginTop: 16 }}>
                    Can't find your implant system?
                </Text>
                <Text variant={'body2'} style={{ marginTop: 16 }}>
                    All implant systems that we currently support are listed here. If you can’t find yours, please
                    <span
                        onClick={openChat}
                        style={{ color: FlossPalette.PRIMARY_FOREGROUND, fontWeight: 700, cursor: 'pointer' }}
                    >
                        {' '}
                        click here to start a chat with us
                    </span>{' '}
                    on how we can support this case.
                </Text>
            </Grid>
        </Grid>
    );
};

export const ScanbodySidebarRight: React.FC = () => {
    return <PracticeSidebarRight topItems={<HelpItem />} />;
};
