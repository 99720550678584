import { ImplantSolutionsMaterialAddOnsTable } from './ImplantSolutionsMaterialAddOnsTable';
import { ImplantSolutionsMaterialsTable } from './ImplantSolutionsMaterialsTable';
import { ImplantSurgeryAddOnsTable } from './ImplantSurgeryAddOnsTable';
import { ImplantSurgeryOptionsTable } from './ImplantSurgeryOptionsTable';
import { Grid, Text } from '@orthly/ui-primitives';

export const ImplantPricing: React.FC = () => {
    return (
        <Grid container direction={'column'} data-test={'pricing-content-Implant'} style={{ gap: '24px' }}>
            <Grid item xs={12}>
                <Text data-test={'pricing-header-Implant-solutions'} variant={'h4'}>
                    {'Implant solutions'}
                </Text>
            </Grid>
            <Grid item xs={12}>
                <ImplantSolutionsMaterialsTable />
            </Grid>
            <Grid item xs={12}>
                <ImplantSolutionsMaterialAddOnsTable />
            </Grid>
            <Grid item xs={12}>
                <Text data-test={'pricing-header-Implant-surgery'} variant={'h4'}>
                    {'Implant surgery'}
                </Text>
            </Grid>
            <Grid item xs={12}>
                <ImplantSurgeryOptionsTable />
            </Grid>
            <Grid item xs={12}>
                <ImplantSurgeryAddOnsTable />
            </Grid>
        </Grid>
    );
};
