import { PracticeSettingsBase } from '../../../components/PracticeSettingsBase';
import { StaffRoutes } from '../staff-paths';
import { AddEditStaff as AddStaff } from './useStaffForm';
import { Button } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const AddStaffWrapper: React.FC = () => {
    const history = useHistory();
    const onBackAction = () => {
        history.push(StaffRoutes.all_staff);
    };

    return (
        <PracticeSettingsBase
            title={
                <Button
                    variant={'ghost'}
                    onClick={onBackAction}
                    startIcon={'ChevronLeft'}
                    style={{ marginLeft: '-16px' }}
                >
                    Back to staff
                </Button>
            }
            titleHasBackButton={false}
            Content={<AddStaff onBackAction={onBackAction} />}
        />
    );
};
