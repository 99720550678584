import { EmployeePreferencesTable } from './EmployeePreferencesTable';
import { useFetchPracticeStaffUsers } from './useFetchPracticeStaffUsers.graphql';
import { DEFAULT_TOAST_TIMEOUT, Toast } from '@orthly/dentin';
import { CheckIcon } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface PracticeEmployeesTableWrapperProps {
    includeDeleted: boolean;
}

export const PracticeEmployeesTableWrapper: React.FC<PracticeEmployeesTableWrapperProps> = ({ includeDeleted }) => {
    const history = useHistory();
    const {
        staffUsers,
        refetch: refetchStaffUsers,
        loading: staffUsersLoading,
    } = useFetchPracticeStaffUsers(includeDeleted);

    const [firstName, setFirstName] = React.useState('');
    const [toastOpen, setToastOpen] = React.useState(false);

    const refetch = async () => {
        void refetchStaffUsers();
    };

    const onInviteAction = (firstName: string) => {
        setFirstName(firstName);
        setToastOpen(true);
    };

    return (
        <>
            <EmployeePreferencesTable
                staffUsers={staffUsers}
                loading={staffUsersLoading}
                refetch={refetch}
                selectStaffMember={staff => history.push(`/my_practice/staff/${staff.staff_member_id}`)}
                onInviteAction={onInviteAction}
                deactivated={includeDeleted}
            />
            <Toast
                headline={`${firstName} has been invited to your practice`}
                description={`${firstName} will have to confirm the invitation to get access to your practice portal.`}
                open={toastOpen}
                onDismiss={() => setToastOpen(false)}
                icon={CheckIcon}
                variant={'info'}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
        </>
    );
};
