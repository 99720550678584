import type { PartnerAppState } from '../index';
import type { OrdersState } from './orders.reducer';
import type { TypedUseSelectorHook } from 'react-redux';
import { useSelector } from 'react-redux';

export const useOrdersSelector: TypedUseSelectorHook<OrdersState> = <TSelected>(
    selector: (state: OrdersState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
    return useSelector<PartnerAppState, TSelected>(s => selector(s.orders), equalityFn);
};

export function useOrdersPropSelector<K extends keyof OrdersState>(props: K[]): Pick<OrdersState, K> {
    return useOrdersSelector(state => {
        return props.reduce((result, prop) => ({ ...result, [prop]: state[prop] }), {} as Pick<OrdersState, K>);
    });
}
