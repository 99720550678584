import type { OrderTimelineV2Item } from '../../../../PracticeOrderChatV2';
import { useStyles as useParentStyles } from '../css.util';
import { OrderTimelineAction } from './components/OrderTimelineAction';
import { OrderTimelineAttachments } from './components/OrderTimelineAttachments/OrderTimelineAttachments';
import { OrderTimelineIcons } from './components/OrderTimelineIcons';
import { OrderTimelineLinkedOrder } from './components/OrderTimelineLinkedOrder';
import { OrderTimelineMarkdown } from './components/OrderTimelineMarkdown';
import { OrderTimelineTimestamp } from './components/OrderTimelineTimestamp';
import { OrderTimelineTitle } from './components/OrderTimelineTitle';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlOrderTimelineV2EventEnum, type LabsGqlOrderTimelineV2EventVariantEnum } from '@orthly/graphql-schema';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const getBackgroundColor = (variant: LabsGqlOrderTimelineV2EventVariantEnum) => {
    switch (variant) {
        case 'problem':
            return FlossPalette.ATTENTION_BACKGROUND;
        case 'success':
            return FlossPalette.PRIMARY_BACKGROUND;
        case 'modification':
            return FlossPalette.DARK_TAN;
        case 'plain':
        default:
            return 'transparent';
    }
};

const getIconBackgroundColor = (variant: LabsGqlOrderTimelineV2EventVariantEnum) => {
    switch (variant) {
        case 'problem':
            return FlossPalette.ATTENTION_BACKGROUND;
        case 'success':
            return FlossPalette.PRIMARY_BACKGROUND;
        case 'modification':
        case 'plain':
        default:
            return FlossPalette.DARK_TAN;
    }
};

const useStyles = stylesFactory<{ variant: LabsGqlOrderTimelineV2EventVariantEnum }>(() => ({
    icon: {
        position: 'absolute',
        top: 0,
        left: -10,
        height: 20,
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            height: 14,
            width: 14,
        },
    },
    item: props => ({
        position: 'relative',
        backgroundColor: getBackgroundColor(props.variant),
        borderRadius: 8,
        padding: props.variant === 'plain' ? '2px 0' : 12,
        paddingRight: 4,
        marginLeft: 24,
        display: 'grid',
        gridTemplateAreas: `
            "title       timestamp"
            "message     message"
            "attachments attachments"
            "action      action"
            "link        link"
        `,
        gridTemplateColumns: '1fr auto',
        '&::before': {
            content: '""',
            position: 'absolute',
            left: -34,
            height: 20,
            width: 20,
            borderRadius: '50%',
            border: `2px solid ${FlossPalette.WHITE}`,
            backgroundColor: getIconBackgroundColor(props.variant),
        },
    }),
    itemTitle: {
        gridArea: 'title',
    },
    itemTimestamp: {
        gridArea: 'timestamp',
        marginLeft: 8,
    },
    itemMessage: {
        gridArea: 'message',
        marginTop: 8,
    },
    itemAttachments: {
        gridArea: 'attachments',
        marginTop: 8,
    },
    itemAction: {
        gridArea: 'action',
        marginTop: 8,
    },
    itemLink: {
        gridArea: 'link',
        marginTop: 8,
    },
}));

export const OrderTimelineItem: React.VFC<{
    event: OrderTimelineV2Item;
    order: LabsGqlOrder;
    allSortedEvents: OrderTimelineV2Item[];
    isLastOrder: boolean;
}> = ({ event, order, allSortedEvents, isLastOrder }) => {
    const parentClasses = useParentStyles();
    const classes = useStyles({ variant: event.variant });
    const hideLinkedOrder = event.name === LabsGqlOrderTimelineV2EventEnum.OrderCancelled && !isLastOrder;

    return (
        <div className={parentClasses.itemContainer}>
            <div className={classes.item}>
                <OrderTimelineTitle className={classes.itemTitle} actor={event.actor} showAvatar={event.showAvatar}>
                    <OrderTimelineMarkdown>{event.title}</OrderTimelineMarkdown>
                </OrderTimelineTitle>
                <OrderTimelineTimestamp className={classes.itemTimestamp} event={event} />
                <OrderTimelineMarkdown className={classes.itemMessage}>{event.details}</OrderTimelineMarkdown>
                <OrderTimelineAttachments className={classes.itemAttachments} attachments={event.attachments} />
                <OrderTimelineAction
                    className={classes.itemAction}
                    event={event}
                    order={order}
                    allSortedEvents={allSortedEvents}
                />
                {!hideLinkedOrder && (
                    <OrderTimelineLinkedOrder className={classes.itemLink} orderId={event.linked_order_id}>
                        {event.linked_order_text}
                    </OrderTimelineLinkedOrder>
                )}
            </div>
            <div className={classes.icon}>{OrderTimelineIcons[event.icon]}</div>
        </div>
    );
};
