import { OrderAction, OrderActionTrackerIdMap } from '../OrderActionsUtils';
import type { LabOrderActionProps } from '../index';
import { useAddNewScans } from './useAddNewScans';
import type { ButtonVariant } from '@orthly/ui-primitives';
import { Button, IconButton, Icon } from '@orthly/ui-primitives';
import React from 'react';

interface AddNewScanActionProps extends LabOrderActionProps {
    fullWidth?: boolean;
    leftAlign?: boolean;
    buttonVariant?: ButtonVariant;
}

export const AddNewScanAction: React.VFC<AddNewScanActionProps> = ({
    order,
    style,
    isIconOnly = false,
    fullWidth,
    leftAlign,
    buttonVariant = 'contained',
}) => {
    const { invokeAttachScans, canAddNewScans } = useAddNewScans(order);

    if (!canAddNewScans) {
        return null;
    }

    return (
        <>
            {isIconOnly ? (
                <IconButton
                    data-tracker-id={OrderActionTrackerIdMap[OrderAction.CaptureNewScans]}
                    disabled={!canAddNewScans}
                    onClick={() => invokeAttachScans({ id: order.id })}
                    style={style}
                    size={'large'}
                    color={'primary'}
                >
                    <Icon icon={'ScanIcon'} />
                </IconButton>
            ) : (
                <Button
                    data-tracker-id={OrderActionTrackerIdMap[OrderAction.CaptureNewScans]}
                    fullWidth={fullWidth}
                    leftAlign={leftAlign}
                    disabled={!canAddNewScans}
                    variant={buttonVariant}
                    onClick={() => invokeAttachScans({ id: order.id })}
                    startIcon={isIconOnly ? undefined : 'ScanIcon'}
                    style={style}
                >
                    Capture new scans
                </Button>
            )}
        </>
    );
};
