import { SimpleInput } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface TryInFeedbackOptionBodyFreeTextProps {
    label: string;
    value?: string;
    onChange: (value?: string) => void;
}

export const TryInFeedbackOptionBodyFreeText: React.VFC<TryInFeedbackOptionBodyFreeTextProps> = ({
    label,
    value,
    onChange,
}) => {
    return (
        <Grid container direction={'column'} spacing={2}>
            <Grid item>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {label}
                </Text>
            </Grid>
            <Grid item>
                <SimpleInput
                    fullWidth
                    label={'Type here...'}
                    value={value}
                    onChange={onChange}
                    TextFieldProps={{ multiline: true, rows: 3 }}
                />
            </Grid>
        </Grid>
    );
};
