import { LabeledPhoneField } from '../../../components/labeled-field/LabeledFields';
import { PageLayout } from '../../../components/page-layout/PageLayout';
import { FooterLink, JustifiedFooterText } from '../../../components/page-layout/components/PanelFooterLink';
import { useSetAlwaysSkipAddPhoneNumberForm, useSetSkipPhoneNumberForm } from '../../../state/useSkipAddPhone';
import { useAddPhoneNumber } from '../useAddPhoneNumber';
import { Button, FormHelperText, Icon } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const AddPhoneNumberForm: React.FC = () => {
    const history = useHistory();
    const alwaysSkipForm = useSetAlwaysSkipAddPhoneNumberForm();
    const skipFormForNow = useSetSkipPhoneNumberForm();
    const {
        sendConfirmationCode,
        phoneNumber,
        phoneNumberError,
        setPhoneNumber,
        setShowPhoneNumberError,
        shouldContinue,
        submittingSendCode,
        formError,
        userExistsError,
    } = useAddPhoneNumber();

    return (
        <PageLayout
            pageMessage={'Create profile'}
            panelTitle={'Do you want to add a mobile phone number?'}
            panelDescription={
                'You can add an optional phone number to your account. This phone number can then be used to log in to Dandy.'
            }
            onFormSubmit={e => {
                e.preventDefault();
                sendConfirmationCode();
            }}
        >
            <LabeledPhoneField
                label={'Phone number'}
                placeholder={'Enter your phone number'}
                value={phoneNumber}
                disabled={submittingSendCode}
                onChange={(next = '') => {
                    setPhoneNumber(next);
                }}
                TextFieldProps={{
                    type: 'tel',
                    autoFocus: true,
                    error: !!phoneNumberError,
                    helperText: phoneNumberError,
                }}
                onBlur={setShowPhoneNumberError}
            />
            {formError && <FormHelperText error>{formError}</FormHelperText>}
            {userExistsError && <FormHelperText error>{userExistsError}</FormHelperText>}
            <Button disabled={!shouldContinue} variant={'primary'} fullWidth type={'submit'}>
                Continue
            </Button>
            <JustifiedFooterText>
                <FooterLink
                    onClick={() => {
                        alwaysSkipForm(true);
                        history.push('/inbox');
                    }}
                >
                    Never show this again
                </FooterLink>
                <FooterLink
                    onClick={() => {
                        skipFormForNow(true);
                        history.push('/inbox');
                    }}
                >
                    {'Skip for now '}
                    <Icon icon={'ArrowForward'} />
                </FooterLink>
            </JustifiedFooterText>
        </PageLayout>
    );
};
