import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { useShowMobileLayout } from '../../../utils/LayoutUtils';
import { PracticeSettingsBaseContent } from './PracticeSettings/PracticeSettingsBaseContent';
import { PracticeSettingsTitle } from './PracticeSettings/PracticeSettingsBaseTitle';
import { FlossPalette, stylesFactory, useScreenIsMobile, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        background: FlossPalette.WHITE,
        overflow: 'auto',
        minHeight: 0,
    },
}));

interface PracticeSettingsBaseProps {
    titleIcon?: React.ReactNode;
    title: React.ReactNode;
    titleAction?: React.ReactNode;
    titleHasBackButton?: boolean;
    Content: React.ReactNode;
    rightContent?: React.ReactNode;
    subtitle?: React.ReactNode;
    subtitleStyle?: React.CSSProperties;
}

export const PracticeSettingsBase: React.FC<PracticeSettingsBaseProps> = ({
    titleIcon,
    title,
    titleAction,
    titleHasBackButton = true,
    Content,
    rightContent,
    subtitle,
    subtitleStyle,
}) => {
    const classes = useStyles();
    const isMobileLayout = useShowMobileLayout();
    const isMobile = useScreenIsMobile();

    if (isMobileLayout) {
        return (
            <MobilePageLayout title={title} titleHasBackButton={titleHasBackButton} titleAction={titleAction}>
                {!isMobile && (
                    <PracticeSettingsTitle
                        style={{ top: 0, marginTop: 0 }}
                        title={title}
                        subtitle={subtitle}
                        subtitleStyle={subtitleStyle}
                        titleAction={titleAction}
                        titleIcon={titleIcon}
                    />
                )}
                <PracticeSettingsBaseContent Content={Content} rightContent={rightContent} />
            </MobilePageLayout>
        );
    }

    return (
        <Grid container className={classes.root} direction={'column'} wrap={'nowrap'}>
            <PracticeSettingsTitle
                title={title}
                titleAction={titleAction}
                titleIcon={titleIcon}
                subtitle={subtitle}
                subtitleStyle={subtitleStyle}
            />
            <PracticeSettingsBaseContent Content={Content} rightContent={rightContent} />
        </Grid>
    );
};
