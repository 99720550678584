import { useCurrentPartner } from '../../../../utils/CurrentPartnerContext';
import { CollapsedQuickFormSubmit } from '../../components/CollapsedQuickFormSubmit';
import { useUpdateOrgPhoneNumberMutation } from '@orthly/graphql-react';
import type { LabsGqlMutationUpdateOrganizationPhoneNumberArgs } from '@orthly/graphql-schema';
import { useChangeSubmissionFn, QuickForm, QuickFormValidations, SimplePhoneInput } from '@orthly/ui';
import { getIn } from 'formik';
import React from 'react';
import z from 'zod';

type UpdatePhoneNumberArgs = LabsGqlMutationUpdateOrganizationPhoneNumberArgs['data'];

const EMPTY_PHONE_NUMBER = '+1';

export const ChangePhoneNumber: React.FC = () => {
    const currentPartner = useCurrentPartner();
    const [submitMtn] = useUpdateOrgPhoneNumberMutation();
    const mtnSubmitter = (data: UpdatePhoneNumberArgs) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [UpdatePhoneNumberArgs]>(mtnSubmitter, {
        successMessage: () => ['Phone number updated!', {}],
        onSuccess: async () => {
            void currentPartner.refetch();
        },
        errorMessage: () => ['Failed to change practice phone number.', {}],
    });

    return (
        <QuickForm<{ phone_number: string }>
            resetOnInitialValueChange={true}
            fields={{
                phone_number: {
                    type: 'custom',
                    optional: true,
                    validation: QuickFormValidations.phone.or(z.literal(EMPTY_PHONE_NUMBER)).or(z.literal('')),
                    component: ({ form, field }) => {
                        const error = getIn(form.errors, field.name);

                        return (
                            <SimplePhoneInput
                                fullWidth
                                onChange={v => form.setFieldValue(field.name, v)}
                                value={field.value}
                                label={'Phone number'}
                                TextFieldProps={{
                                    type: 'tel',
                                    style: { marginBottom: 12, position: 'relative' as const },
                                    helperText: error ? error : undefined,
                                    error: !!error,
                                }}
                            />
                        );
                    },
                },
            }}
            dirtySubmitOnly={true}
            disabled={submitting}
            CustomSubmit={CollapsedQuickFormSubmit}
            onSubmit={data =>
                submit({
                    organization_id: currentPartner.data?.id ?? '',
                    phone_number: data.phone_number !== EMPTY_PHONE_NUMBER ? data.phone_number : '',
                })
            }
            submitButtonProps={{ style: { display: 'none' } }}
            initialValues={{ phone_number: currentPartner.data?.phone_number ?? '' }}
            preventDirtyExitMessage={
                'Your phone number has not been saved yet. Are you sure you want to leave without saving?'
            }
        />
    );
};
