import type { NotificationNames } from '../Notifications.types';
import { useForwardingStyles } from './styles';
import { ForwardingType } from './types';
import type { ApolloQueryResult } from '@apollo/client';
import type {
    LabsGqlDoctorPreferencesFragment,
    LabsGqlGetDoctorPreferencesByTokenQuery,
} from '@orthly/graphql-operations';
import { Format } from '@orthly/runtime-utils';
import { useScreenIsMobile, Grid, RedoIcon, Text } from '@orthly/ui-primitives';
import React from 'react';

export interface ManageForwardingSettingsProps {
    sendersOrReceivers: LabsGqlDoctorPreferencesFragment[];
    employeePrefs: LabsGqlDoctorPreferencesFragment;
    notificationName: NotificationNames;
    refetch?: () => Promise<ApolloQueryResult<LabsGqlGetDoctorPreferencesByTokenQuery>>;
}

export interface ForwardingSettingsBaseModalFormProps extends ManageForwardingSettingsProps {
    setOpen: (value: boolean) => void;
    setShowSuccess: (value: boolean) => void;
}

export interface ForwardingSettingsBaseModalProps extends ForwardingSettingsBaseModalFormProps {
    open: boolean;
}

interface ForwardingSettingsBaseProps {
    forwardingInfoText: React.ReactNode;
    forwardingSettingsModal: React.ReactNode;
    forwardingType: ForwardingType;
}

export const FormatNames: React.VFC<{ sendersOrReceivers: LabsGqlDoctorPreferencesFragment[] }> = ({
    sendersOrReceivers,
}) => {
    return (
        <span style={{ fontWeight: 600 }}>
            {Format.joinWithAnd(sendersOrReceivers.map(sendersOrReceiver => sendersOrReceiver.formatted_name))}
        </span>
    );
};

export const ForwardingSettingsBase: React.VFC<ForwardingSettingsBaseProps> = props => {
    const { forwardingInfoText, forwardingSettingsModal, forwardingType } = props;
    const classes = useForwardingStyles();
    const isMobile = useScreenIsMobile();
    return (
        <Grid container className={classes.forwardingInfo}>
            <Grid
                item
                className={classes.forwardingInfoText}
                style={{
                    // eslint-disable-next-line no-nested-ternary
                    width: isMobile
                        ? '100%'
                        : forwardingType === ForwardingType.receiving
                          ? 'calc(100% - 112px)'
                          : 'calc(100% - 216px)',
                }}
            >
                <RedoIcon className={forwardingType === 'receiving' ? classes.receivingIcon : classes.forwardingIcon} />
                <Text variant={'body2'}>{forwardingInfoText}</Text>
            </Grid>
            <Grid item style={{ paddingLeft: 8 }}>
                {forwardingSettingsModal}
            </Grid>
        </Grid>
    );
};
