import { formatDataTestTitle } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const PageTitle: React.VFC<{ title: string; header: string | JSX.Element; subtitle?: string }> = ({
    title,
    header,
    subtitle,
}) => {
    return (
        <div style={{ marginBottom: 24 }}>
            <Text
                variant={'h4'}
                color={'LIGHT_GRAY'}
                style={{ marginTop: 24 }}
                data-test={`implant-readiness-page-title-${formatDataTestTitle(title)}`}
            >
                {title}
            </Text>
            {typeof header !== 'string' ? (
                header
            ) : (
                <Text
                    data-test={`implant-readiness-page-header-${formatDataTestTitle(header)}`}
                    variant={'h3'}
                    color={'BLACK'}
                >
                    {header}
                </Text>
            )}
            {subtitle && (
                <Text
                    data-test={`implant-readiness-page-subtitle-${formatDataTestTitle(subtitle)}`}
                    variant={'body2'}
                    color={'GRAY'}
                    style={{ marginTop: 24 }}
                >
                    {subtitle}
                </Text>
            )}
        </div>
    );
};
