import { OrderAction, OrderActionTrackerIdMap } from '../OrderActionsUtils';
import type { LabOrderActionProps } from '../index';
import { useRefabAction } from './useRefabAction';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import type { FileUploaderBulkProps } from '@orthly/veneer';
import { FileUploaderBulk } from '@orthly/veneer';
import React from 'react';

interface MarkNeedsRefabFileUploaderProps {
    orderId: string;
    attachmentUrls: string[];
    setAttachmentUrls: (attachmentUrls: string[]) => void;
    onLoadingStateChange?: FileUploaderBulkProps['onLoadingStateChange'];
}

interface MarkNeedsRefabButtonProps {
    hideButtonIcon?: boolean;
    buttonStyle?: React.CSSProperties;
    buttonText?: string;
    analyticsLocation: 'body' | 'quick order actions';
}

export const MarkNeedsRefabFileUploader: React.FC<MarkNeedsRefabFileUploaderProps> = props => {
    const { orderId, attachmentUrls, setAttachmentUrls, onLoadingStateChange } = props;
    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.refabAttachments,
        orderId,
    );
    return (
        <Grid container style={{ paddingTop: '12px' }}>
            <Text variant={'h6'}>
                {attachmentUrls.length > 0
                    ? 'Need to attach a file?'
                    : 'Optionally, attach any files, photos, or x-rays below'}
            </Text>
            <FileUploaderBulk
                autoSubmit
                paperStyle={{ padding: 0 }}
                dropzoneWrapperStyle={{ minHeight: 50, padding: 0, marginTop: 12 }}
                storagePathConfig={storagePathConfig}
                onReset={() => setAttachmentUrls([])}
                elevation={0}
                onComplete={results => setAttachmentUrls(results.map(r => r.uploadedPath))}
                onLoadingStateChange={onLoadingStateChange}
                prependTimestampToFilename={true}
            />
            <Text style={{ marginBottom: '24px' }}>
                If you are refabbing this order due to shade, please attach a photo with your request. This will assist
                the lab in remaking the appliance correctly.
            </Text>
        </Grid>
    );
};

export const MarkNeedsRefabricationAction: React.VFC<LabOrderActionProps & MarkNeedsRefabButtonProps> = props => {
    const { order, hideButtonIcon, buttonStyle, buttonText, analyticsLocation } = props;
    const { triggerRefabAction } = useRefabAction(order.id);

    if (!order.can_refabricate) {
        return null;
    }

    const onClickAction = () => {
        triggerRefabAction(`order details - ${analyticsLocation}`);
    };

    return (
        <Button
            onClick={onClickAction}
            startIcon={hideButtonIcon ? undefined : 'RefreshIcon'}
            style={{ padding: '0 4px', ...buttonStyle }}
            variant={'ghost'}
            data-test={'refabricate-order-action'}
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.Refabricate]}
        >
            {buttonText ?? 'Refabricate'}
        </Button>
    );
};
