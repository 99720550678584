import type { StaffMemberSession } from '@orthly/session-client';

function getOrganizationIdOfType(type: 'practice' | 'lab', maybeSession: StaffMemberSession | undefined) {
    return maybeSession?.organization_type === type ? maybeSession.organization_id : undefined;
}

export const getPartnerId = (maybeSession: StaffMemberSession | undefined): string | undefined => {
    return getOrganizationIdOfType('practice', maybeSession);
};

export const getManufacturerId = (maybeSession: StaffMemberSession | undefined): string | undefined => {
    return getOrganizationIdOfType('lab', maybeSession);
};
