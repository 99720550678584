import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useShowMobileLayout } from '../../../utils/LayoutUtils';
import { useScanbodyDeliveryAddress } from '../../scanbodies/hooks/useScanbodyDeliveryAddress';
import { useSelectStaffPropSelector } from '../../select-staff/state/select-staff.selectors';
import { Cart } from '../component/Cart';
import { Tips, renderWhatIsScanbodyTip } from '../component/Tip';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { PracticeScreen } from '@orthly/dentin';
import {
    useCreateCaseReadinessMutation,
    useFetchDoctorsQuery,
    useBulkCreateScanbodyRequestsMutation,
} from '@orthly/graphql-react';
import { CartItemV2InputUtils } from '@orthly/graphql-schema';
import type { SimpleSelectOption } from '@orthly/ui';
import { LoadBlocker, SimpleDatePicker, SimpleSelect, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, stylesFactory, Divider, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    container: {
        backgroundColor: FlossPalette.TAN,
        borderColor: FlossPalette.DARK_TAN,
        borderStyle: 'solid',
        borderRadius: 8,
        borderWidth: 1,
        padding: 16,
        width: 'auto',
        flexGrow: 1,
        gap: '16px',
    },
}));

function usePlaceOrder() {
    const { patient, items, scanbodies, surgicalReportPath, itemAbutmentTypes } = useImplantReadinessPropSelector([
        'patient',
        'items',
        'scanbodies',
        'surgicalReportPath',
        'itemAbutmentTypes',
    ]);
    const completeFlow = useImplantReadinessAction('COMPLETE_FLOW');
    const { profile } = useSelectStaffPropSelector(['profile']);
    const [submitScanbodyRequestsMutation] = useBulkCreateScanbodyRequestsMutation();
    const [submitCaseReadinessMutation] = useCreateCaseReadinessMutation();
    const { submit: submitScanbodyRequests, submitting: submittingScanbodyRequests } = useChangeSubmissionFn<any, any>(
        variables => submitScanbodyRequestsMutation({ variables }),
        {},
    );
    const { submit: submitCaseReadiness, submitting: submittingCaseReadiness } = useChangeSubmissionFn<any, any>(
        variables => submitCaseReadinessMutation({ variables }),
        {},
    );
    const items_v2_by_sku = CartItemV2InputUtils.getCartItemV2InputBySKU(items);
    const placeOrder = React.useCallback(
        async (appointmentDate: string, address: string, doctorId: string) => {
            const result = await submitCaseReadiness({
                data: { patient_id: patient?.id ?? '', doctor_id: profile?.id, items_v2_by_sku },
            });
            const caseReadinessId = result.data?.createCaseReadiness.id;
            if (caseReadinessId) {
                await submitScanbodyRequests({
                    args: {
                        scanbody_requests: scanbodies.map(scanbody => {
                            return {
                                requested_scanbody_id: scanbody.id,
                                appointment_date: appointmentDate,
                                address,
                                connection: scanbody.connection,
                                manufacturer: scanbody.manufacturer,
                                system: scanbody.system,
                                requesting_doctor_id: doctorId,
                                surgical_report_file: surgicalReportPath || null,
                                case_readiness_id: caseReadinessId,
                                abutment_type: itemAbutmentTypes[scanbody.itemId],
                            };
                        }),
                    },
                });
                completeFlow({});
            }
            return caseReadinessId;
        },

        [
            patient?.id,
            profile?.id,
            items_v2_by_sku,
            submitCaseReadiness,
            itemAbutmentTypes,
            scanbodies,
            submitScanbodyRequests,
            surgicalReportPath,
            completeFlow,
        ],
    );

    return {
        placeOrder,
        submitting: submittingCaseReadiness || submittingScanbodyRequests,
    };
}

const Checkout: React.VFC = () => {
    const classes = useStyles();
    const [doctorId, setDoctorId] = React.useState<string>('');
    const [doctorOptions, setDoctorOptions] = React.useState<SimpleSelectOption[]>([]);
    const [address, setAddress] = React.useState<string>('');
    const [appointmentDate, setAppointmentDate] = React.useState<Date>();
    const { placeOrder, submitting } = usePlaceOrder();
    const isMobileLayout = useShowMobileLayout();
    const { patient, scanbodies, implantReadinessId } = useImplantReadinessPropSelector([
        'patient',
        'scanbodies',
        'implantReadinessId',
    ]);
    const setScanBodies = useImplantReadinessAction('SET_SCANBODIES');

    const { loading: loadingDoctorOptions } = useFetchDoctorsQuery({
        onCompleted: data => {
            const doctors = data?.fetchDoctors ?? [];
            setDoctorOptions(doctors.map(d => ({ label: d.name, value: d.id })));
        },
    });
    const { addresses, loading: loadingAddresses } = useScanbodyDeliveryAddress();
    const canPlaceOrder = !!patient && !!address && !!doctorId && scanbodies.length > 0 && !!appointmentDate;

    return (
        <LoadBlocker blocking={submitting}>
            <Grid container style={{ gap: 60, flexGrow: 1, overflowY: 'auto' }}>
                <div style={{ flexBasis: '50%', flexGrow: 1 }}>
                    <Grid container direction={'column'} style={{ gap: 16, maxWidth: 400 }}>
                        <Text variant={'body1'} medium>
                            When is the appointment?
                        </Text>
                        <SimpleDatePicker
                            label={'Appointment Date'}
                            disablePast={true}
                            autoFocus={!appointmentDate}
                            value={appointmentDate ? appointmentDate : null}
                            onChange={value =>
                                setAppointmentDate(
                                    value && !Number.isNaN(value.valueOf()) ? new Date(value.toISOString()) : undefined,
                                )
                            }
                        />
                        <Text variant={'body1'} medium>
                            Patient
                        </Text>
                        <Grid className={classes.container}>
                            {patient?.first_name} {patient?.last_name}
                        </Grid>
                        <Text variant={'body1'} medium>
                            Doctor
                        </Text>
                        <LoadBlocker blocking={loadingDoctorOptions}>
                            <SimpleSelect
                                options={doctorOptions}
                                onChange={value => setDoctorId(value ?? '')}
                                value={doctorId}
                                label={'Doctor'}
                            />
                        </LoadBlocker>
                        <Text variant={'body1'} medium>
                            Delivery Address
                        </Text>
                        <LoadBlocker blocking={loadingAddresses}>
                            <SimpleSelect
                                options={addresses.map(value => ({ value }))}
                                onChange={value => setAddress(value ?? '')}
                                value={address}
                                label={'Address'}
                            />
                        </LoadBlocker>
                        <Button
                            variant={'primary'}
                            style={{ flexGrow: 1 }}
                            endIcon={'CartIcon'}
                            onClick={async () => {
                                if (canPlaceOrder) {
                                    const caseReadinessId = await placeOrder(
                                        appointmentDate.toISOString(),
                                        address,
                                        doctorId,
                                    );

                                    AnalyticsClient.track('Practice - Implant Readiness - Order Placed', {
                                        implantReadinessId,
                                        caseReadinessId,
                                    });
                                }
                            }}
                            disabled={!canPlaceOrder}
                        >
                            Place order
                        </Button>
                    </Grid>
                </div>
                {!isMobileLayout && <Divider orientation={'vertical'} />}
                <Cart cartItems={scanbodies} removeItem={id => setScanBodies(scanbodies.filter(i => i.id !== id))} />
            </Grid>
        </LoadBlocker>
    );
};

export const CheckoutStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/checkout`,
    component: Checkout,
    pageTitle: () => ({
        title: 'Final step',
        header: 'Place order',
    }),
    sidebar: ({ disableFreeScanBody }) => <Tips tips={[renderWhatIsScanbodyTip(disableFreeScanBody)]} />,
};
