import { useAlignerCheckoutSelector, useCheckoutSelector } from '../../../../redux';
import { AlignerCheckoutStep } from '../../state/aligners-checkout.types';
import { CheckoutPatientDoctorStep } from '../CheckoutPatientDoctorStep/CheckoutPatientDoctorStep';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import React from 'react';

interface AlignerCheckoutPatientDoctorStepProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

export const AlignerCheckoutPatientDoctorStep: React.VFC<AlignerCheckoutPatientDoctorStepProps> = ({ scansQuery }) => {
    const checkoutStep = useCheckoutSelector(({ step }) => step);
    const alignerStep = useAlignerCheckoutSelector(({ step }) => step);

    return (
        <CheckoutPatientDoctorStep
            scansQuery={scansQuery}
            visible={alignerStep === AlignerCheckoutStep.PatientInformation}
            isComplete={alignerStep !== AlignerCheckoutStep.PatientInformation || checkoutStep > 0}
        />
    );
};
