import type { PatientFormPatient } from './PatientForm.types';
import { FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(() => ({
    patientsCardRoot: {
        borderRadius: 8,
        backgroundColor: FlossPalette.TAN,
        marginTop: 8,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
    patientsCardTitle: {
        padding: '16px 16px 0',
    },
    patientsCardList: {
        maxHeight: 226,
        overflowY: 'scroll',
        overflowX: 'hidden',
    },
    patientsCardItem: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        padding: 16,
        '&:last-of-type': {
            borderBottom: 'unset',
        },
    },
}));

interface PatientsCardProps {
    patients: PatientFormPatient[];
    title?: string;
    Action?: React.ComponentType<{ patient: PatientFormPatient }>;
}

export const PatientsCard: React.VFC<PatientsCardProps> = ({ patients, title, Action }) => {
    const classes = useStyles();

    return (
        <Grid item container className={classes.patientsCardRoot} direction={'column'}>
            {title && (
                <Grid item className={classes.patientsCardTitle}>
                    <Text variant={'body2'} medium color={'DARK_GRAY'}>
                        {title}
                    </Text>
                </Grid>
            )}
            <div className={classes.patientsCardList}>
                {patients.map(patient => (
                    <Grid
                        key={patient.id}
                        item
                        container
                        direction={'row'}
                        wrap={'nowrap'}
                        className={classes.patientsCardItem}
                        data-test={'patients-card-item'}
                    >
                        <Grid item xs style={{ margin: 'auto' }}>
                            <Text variant={'body2'} medium>
                                <span className={'fs-mask'}>
                                    {patient.first_name} {patient.last_name}
                                </span>
                            </Text>
                        </Grid>
                        <Grid item xs style={{ margin: 'auto' }}>
                            <Text variant={'body2'} style={{ color: FlossPalette.DARK_GRAY }}>
                                {moment().diff(moment(patient.birthday), 'years')}, {patient.gender}
                            </Text>
                        </Grid>
                        {Action && (
                            <Grid item>
                                <Action patient={patient} />
                            </Grid>
                        )}
                    </Grid>
                ))}
            </div>
        </Grid>
    );
};
