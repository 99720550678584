import { FeedbackActions } from './feedback.actions';
import type { FeedbackAndRefabItem, RefabFlowItemReasonCode } from '@orthly/dentin';
import _ from 'lodash';
import { handleActions } from 'redux-actions';

export type FeedbackLoaderScreen = {
    id: 'loader';
};
export type FeedbackItemSelectScreen = {
    id: 'item_select';
    items: [FeedbackAndRefabItem, ...FeedbackAndRefabItem[]];
    previous_screen?: FeedbackItemNotesScreen | FeedbackItemPropsScreen | FeedbackFiveStarPropsScreen;
};
export type FeedbackItemPropsScreen = {
    id: 'item_props';
    items: [FeedbackAndRefabItem, ...FeedbackAndRefabItem[]];
    item: FeedbackAndRefabItem;
    previous_screen: FeedbackItemSelectScreen;
};
export type FeedbackFiveStarPropsScreen = {
    id: 'five_star_props';
    items: [FeedbackAndRefabItem, ...FeedbackAndRefabItem[]];
    item: FeedbackAndRefabItem;
    shortcutUsed: boolean;
    previous_screen: FeedbackItemSelectScreen;
};
export type FeedbackItemNotesScreen = {
    id: 'item_notes';
    items: [FeedbackAndRefabItem, ...FeedbackAndRefabItem[]];
    item: FeedbackAndRefabItem;
    previous_screen: FeedbackItemSelectScreen;
};
export type FeedbackPostSubmitScreen = {
    id: 'post_submit';
};

export type FeedbackScreen =
    | FeedbackLoaderScreen
    | FeedbackItemSelectScreen
    | FeedbackItemPropsScreen
    | FeedbackFiveStarPropsScreen
    | FeedbackItemNotesScreen
    | FeedbackPostSubmitScreen;

interface ItemDetails {
    notes: string;
    tags: Record<string, FeedbackItemReasonCode>;
    other_reason_code_description?: Record<string, string | null>;
    chairside_adjustment_minutes: number | null;
    star_rating: number | null;
}

export type FeedbackItemReasonCode = Omit<
    RefabFlowItemReasonCode,
    'requiresReturn' | 'additionalInfoRequest' | 'defaultFault'
>;

export interface FeedbackState {
    screen: FeedbackScreen;
    items: Record<string, ItemDetails>;
    doctor_is_happy?: boolean;
    show_fullscreen_sidebar: boolean;
}

const INITIAL_STATE: FeedbackState = {
    screen: { id: 'loader' },
    items: {},
    doctor_is_happy: undefined,
    show_fullscreen_sidebar: false,
};

export const feedbackReducer = handleActions<FeedbackState, any>(
    {
        ...FeedbackActions.START_NEW.reducer(() => {
            return INITIAL_STATE;
        }),
        ...FeedbackActions.SET_ITEM_NOTES.reducer((state: FeedbackState, { payload: { itemId, notes } }) => {
            return {
                ...state,
                items: {
                    ...state.items,
                    [itemId]: {
                        tags: {},
                        chairside_adjustment_minutes: null,
                        star_rating: null,
                        ...state.items[itemId],
                        notes,
                    },
                },
            };
        }),

        ...FeedbackActions.GO_TO.reducer((state: FeedbackState, { payload: screen }) => {
            if (screen === undefined) {
                return state;
            }
            return { ...state, screen };
        }),

        ...FeedbackActions.SET_DOCTOR_IS_HAPPY.reducer((state: FeedbackState, { payload: doctor_is_happy }) => {
            return { ...state, doctor_is_happy };
        }),

        ...FeedbackActions.TOGGLE_FULLSCREEN_SIDEBAR.reducer(
            (state: FeedbackState, { payload: show_fullscreen_sidebar }) => {
                return { ...state, show_fullscreen_sidebar };
            },
        ),

        ...FeedbackActions.SET_ITEM_CHAIRSIDE_ADJUSTMENT_MINUTES.reducer(
            (state: FeedbackState, { payload: { itemId, chairsideAdjustmentMinutes } }) => {
                return {
                    ...state,
                    items: {
                        ...state.items,
                        [itemId]: {
                            notes: '',
                            tags: {},
                            star_rating: null,
                            ...state.items[itemId],
                            chairside_adjustment_minutes: chairsideAdjustmentMinutes,
                        },
                    },
                };
            },
        ),

        ...FeedbackActions.SET_ITEM_STAR_RATING.reducer((state: FeedbackState, { payload: { itemId, starRating } }) => {
            return {
                ...state,
                items: {
                    ...state.items,
                    [itemId]: {
                        notes: '',
                        tags: {},
                        chairside_adjustment_minutes: null,
                        ...state.items[itemId],
                        star_rating: starRating,
                    },
                },
            };
        }),

        ...FeedbackActions.SET_OTHER_NOTES.reducer(
            (state: FeedbackState, { payload: { itemId, reasonCode, otherNotes } }) => {
                return {
                    ...state,
                    items: {
                        ...state.items,
                        [itemId]: {
                            notes: '',
                            chairside_adjustment_minutes: null,
                            star_rating: null,
                            ...state.items[itemId],
                            tags: {
                                ...state.items[itemId]?.tags,
                                [reasonCode.codeId]: {
                                    ...reasonCode,
                                    otherNotes: otherNotes,
                                },
                            },
                        },
                    },
                };
            },
        ),
        ...FeedbackActions.SET_SINGLE_REASON_CODE.reducer(
            (state: FeedbackState, { payload: { itemId, reasonCode } }) => {
                return {
                    ...state,
                    items: {
                        ...state.items,
                        [itemId]: {
                            notes: '',
                            chairside_adjustment_minutes: null,
                            star_rating: null,
                            ...state.items[itemId],
                            tags: {
                                ...state.items[itemId]?.tags,
                                [reasonCode.codeId]: reasonCode,
                            },
                        },
                    },
                };
            },
        ),
        ...FeedbackActions.UNSET_REASON_CODES.reducer(
            (state: FeedbackState, { payload: { itemId, reasonCodeIds } }) => {
                return {
                    ...state,
                    items: {
                        ...state.items,
                        [itemId]: {
                            notes: '',
                            chairside_adjustment_minutes: null,
                            star_rating: null,
                            ...state.items[itemId],
                            tags: _.omit(state.items[itemId]?.tags ?? {}, reasonCodeIds),
                        },
                    },
                };
            },
        ),
    },
    INITIAL_STATE,
);
