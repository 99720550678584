import { Grid, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

interface CheckoutContentSectionProps {
    // If you pass a string it will be wrapped in Typography
    title: React.ReactNode | string;
    // If you pass a string it will be wrapped in Typography
    subtitle?: React.ReactNode | string;
    children: React.ReactNode;
    style?: React.CSSProperties;
}

const CheckoutContentSection: React.FC<CheckoutContentSectionProps> = props => {
    return (
        <Grid container style={{ padding: '24px 0 0', ...props.style }}>
            <Grid container style={{ paddingBottom: 12 }}>
                {typeof props.title === 'string' ? (
                    <Text variant={'h5'} style={{ width: '100%' }}>
                        {props.title}
                    </Text>
                ) : (
                    props.title
                )}
                {typeof props.subtitle === 'string' ? (
                    <Text variant={'body1'} style={{ width: '100%' }}>
                        {props.subtitle}
                    </Text>
                ) : (
                    props.subtitle ?? null
                )}
            </Grid>
            {props.children}
        </Grid>
    );
};

type ErrorBtnProp = {
    readonly text: string;
    readonly onClick: () => void | Promise<void>;
};

export interface CheckoutErrorSectionProps extends Omit<CheckoutContentSectionProps, 'children'> {
    firstButton: ErrorBtnProp;
    secondButton?: ErrorBtnProp;
    children?: React.ReactNode;
}

export const CheckoutErrorSection: React.FC<CheckoutErrorSectionProps> = props => {
    const { firstButton, secondButton, ...contentProps } = props;
    return (
        <CheckoutContentSection {...contentProps}>
            <Grid container justifyContent={'space-evenly'}>
                <Button
                    variant={'primary'}
                    onClick={() => {
                        void firstButton.onClick();
                    }}
                >
                    {firstButton.text}
                </Button>
                {!!secondButton && (
                    <Button
                        variant={'secondary'}
                        onClick={() => {
                            void secondButton.onClick();
                        }}
                    >
                        {secondButton.text}
                    </Button>
                )}
            </Grid>
            {props.children}
        </CheckoutContentSection>
    );
};
