import { Section } from '../../../components/Section';
import { ChangeDoctorProfilePhoto } from '../../settings/ChangeDoctorProfilePhoto';

export const ProfilePictureSection: React.FC = () => {
    return (
        <Section title={'Profile picture'} subtitle={'Displayed on the login screen'}>
            <ChangeDoctorProfilePhoto />
        </Section>
    );
};
