import { useCurrentProfile } from '../../redux';
import { useSession } from '@orthly/session-client';
import React from 'react';

export function useInboxTitleMessage() {
    const session = useSession();
    const profile = useCurrentProfile();
    const currentHour = new Date().getHours();
    return React.useMemo(() => {
        const name = profile?.formatted_name ?? session?.organization_name;
        const suffix = name ? ` ${name}!` : '!';
        // eslint-disable-next-line no-nested-ternary
        const timeOfDay = currentHour < 12 ? 'morning' : currentHour < 18 ? 'afternoon' : 'evening';
        return `Good ${timeOfDay}${suffix}`;
    }, [profile?.formatted_name, currentHour, session]);
}
