import { AttachScansAction } from '../../actions/practitioners/AttachScans/AttachScansAction';
import { SelectScansAction } from '../../actions/practitioners/AttachScans/SelectScansAction';
import { useRepackageScansAction } from '../../actions/practitioners/AttachScans/utils/Reloader.util';
import { OrderDetailMobileControlPanelBase } from './OrderDetailMobileControlPanelBase';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import type { ScanEntry } from '@orthly/forceps';
import type { LabsGqlLabOrderFragment, LabsGqlPatientScanFileFragment } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { LoadBlocker, useScreenIsMobile } from '@orthly/ui';
import type { ButtonVariant } from '@orthly/ui-primitives';
import { styled, stylesFactory, FlossPalette, InfoIcon, Text, Button } from '@orthly/ui-primitives';
import { getOrderEditMode, OrderEditMode, OrderEditDeliveryDate } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    navAreaWrapper: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        borderTop: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        background: 'white',
        alignItems: 'center',
        zIndex: 10000,
        flexDirection: 'column',
    },
    navAreaInnerWrapper: {
        alignItems: 'center',
        display: 'flex',
        padding: 16,
        justifyContent: 'space-between',
        width: '100%',
        gap: 8,
    },
    navButton: {
        width: '100%',
    },
    newOrderWarning: {
        backgroundColor: FlossPalette.ATTENTION_BG,
        width: '100%',
        padding: 16,
    },
    newOrderContent: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 32,
        paddingTop: 64,
        textAlign: 'center',
    },
    newOrderContentIcon: {
        width: 32,
        height: 32,
        color: FlossPalette.STAR_GRASS,
        marginBottom: 16,
    },
}));

enum AttachActionType {
    AttachScans = 'AttachScans',
    SelectScans = 'SelectScans',
}

const Layout = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const HeaderLayout = styled('div')({
    marginBottom: 16,
});

interface AttachScansMobileProps {
    order: LabsGqlLabOrderFragment;
    backAction: () => void;
    refetchMessages?: () => void;
    setOpen: (open: boolean) => void;
}

const getOnBackAction = (
    attachAction: AttachActionType,
    setAttachAction: (action: AttachActionType) => void,
    setSelectedScanFiles: (files: string[]) => void,
    setOpen: (value: boolean) => void,
    setPreviewingScan: (value: boolean) => void,
    previewingScan: boolean,
    setConfirmNewOrder: (value: boolean) => void,
    confirmNewOrder: boolean,
) => {
    if (previewingScan) {
        return () => {
            setAttachAction(AttachActionType.SelectScans);
            setPreviewingScan(false);
        };
    } else if (confirmNewOrder) {
        return () => {
            setAttachAction(AttachActionType.SelectScans);
            setConfirmNewOrder(false);
        };
    } else if (attachAction === AttachActionType.SelectScans) {
        return () => {
            setSelectedScanFiles([]);
            setAttachAction(AttachActionType.AttachScans);
        };
    } else {
        return () => setOpen(false);
    }
};

const AttachScansActionBar: React.VFC<{
    onBackText: string;
    onBackAction: () => void;
    hideOnBack?: boolean;
    onNextText?: string;
    onNextAction?: () => void;
    onNextDisabled?: boolean;
    additionalTopContent?: React.ReactNode;
    additionalBottomContent?: React.ReactNode;
    nextButtonVariant?: ButtonVariant;
}> = ({
    onBackAction,
    onBackText,
    hideOnBack,
    onNextAction,
    onNextText,
    onNextDisabled,
    additionalTopContent,
    additionalBottomContent,
    nextButtonVariant,
}) => {
    const styles = useStyles();

    return (
        <div className={styles.navAreaWrapper}>
            {additionalTopContent && <div style={{ padding: '8px 16px' }}>{additionalTopContent}</div>}
            <div className={styles.navAreaInnerWrapper}>
                {!hideOnBack && (
                    <Button variant={'secondary-gray'} onClick={onBackAction} className={styles.navButton}>
                        {onBackText}
                    </Button>
                )}
                {onNextAction && (
                    <Button
                        variant={nextButtonVariant ?? 'primary'}
                        onClick={onNextAction}
                        className={styles.navButton}
                        disabled={onNextDisabled}
                    >
                        {onNextText}
                    </Button>
                )}
            </div>
            {additionalBottomContent}
        </div>
    );
};

// eslint-disable-next-line max-lines-per-function
export const AttachScansMobile: React.VFC<AttachScansMobileProps> = props => {
    const { order, backAction, refetchMessages, setOpen } = props;
    const history = useHistory();
    const styles = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [loaderText, setLoaderText] = React.useState('');
    const [attachAction, setAttachAction] = React.useState(AttachActionType.AttachScans);
    const [orderEtaValidation, setOrderEtaValidation] = React.useState(false);
    const { editMode } = getOrderEditMode({ order, newScan: true });
    const orderIsCancelResubmit = editMode === OrderEditMode.CancelAndResubmit;
    const [previewingScan, setPreviewingScan] = React.useState<boolean>(false);
    const [confirmNewOrder, setConfirmNewOrder] = React.useState<boolean>(false);
    const isMobile = useScreenIsMobile();

    /*
     * Tracks the patient scan selected on the AttachScansAction screen.
     */
    const [selectedScan, setSelectedScan] = React.useState<LabsGqlPatientScanFileFragment | null>(null);
    /*
     * Tracks the scan files that are downloaded when the doctor selects a patient scan.
     */
    const [scans, setScans] = React.useState<ScanEntry[]>([]);
    /*
     * Tracks the scan file names the doctor wishes to attach to the order.
     */
    const [selectedScanFiles, setSelectedScanFiles] = React.useState<string[]>([]);

    const onScansUploadSuccess = (orderId: string) => {
        if (orderId !== order.id) {
            history.push(`/${PracticeScreen.orders}/${orderId}`);
        }

        setOpen(false);

        /*
         * This is impossible, but I'd rather not force cast selectedScan.
         */
        if (!selectedScan) {
            return;
        }

        const selectedScanTypes = scans
            .filter(scan => selectedScanFiles.includes(scan.file_name ?? ''))
            .map(scan => scan.definition.type);

        if (orderIsCancelResubmit) {
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Attach Scans - Order Cancelled and Resubmitted With New Scans',
                {
                    scanFileCreationDate: new Date(selectedScan.created_at),
                    scanFileTypes: selectedScanTypes,
                    $groups: { order: orderId },
                },
            );
        } else {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Attach Scans - Order Edited With New Scans', {
                scanFileCreationDate: new Date(selectedScan.created_at),
                scanFileTypes: selectedScanTypes,
                $groups: { order: orderId },
            });
        }
    };

    const selectedScans = scans.filter(scan => selectedScanFiles.includes(scan.file_name ?? ''));
    const repackageScanAction = useRepackageScansAction(
        order,
        selectedScans,
        setLoading,
        setLoaderText,
        onScansUploadSuccess,
    );
    const onBackAction = getOnBackAction(
        attachAction,
        setAttachAction,
        setSelectedScanFiles,
        setOpen,
        setPreviewingScan,
        previewingScan,
        setConfirmNewOrder,
        confirmNewOrder,
    );

    const disableNext = React.useMemo(() => {
        if (confirmNewOrder) {
            return !orderEtaValidation;
        } else {
            return selectedScanFiles.length === 0;
        }
    }, [confirmNewOrder, selectedScanFiles.length, orderEtaValidation]);

    const orderItems = OrderItemV2Utils.parseItems(order.items_v2);

    return (
        <OrderDetailMobileControlPanelBase
            showSuccessMessage={false}
            successMessage={''}
            backAction={onBackAction}
            closeAction={() => {
                refetchMessages?.();
                setOpen(false);
                backAction();
            }}
        >
            <LoadBlocker blocking={loading} loaderText={loaderText}>
                <Layout>
                    {!confirmNewOrder && (
                        <HeaderLayout>
                            <Text variant={'body1'} color={'LIGHT_GRAY'} style={{ marginBottom: 4 }}>
                                Additional scans
                            </Text>
                            <Text variant={'h5'} medium>
                                {selectedScan
                                    ? 'What scan do you want to upload to this order?'
                                    : 'What case do you want to pull the scan from?'}
                            </Text>
                        </HeaderLayout>
                    )}
                    {attachAction === AttachActionType.AttachScans && (
                        <>
                            <AttachScansAction
                                order={order}
                                setScans={setScans}
                                setLoading={setLoading}
                                setSelectedScan={setSelectedScan}
                                onSelectScanAction={() => setAttachAction(AttachActionType.SelectScans)}
                            />
                            <AttachScansActionBar onBackAction={onBackAction} onBackText={'Cancel'} />
                        </>
                    )}
                    {attachAction === AttachActionType.SelectScans && !!selectedScan && (
                        <>
                            <SelectScansAction
                                order={order}
                                scans={scans}
                                selectedScan={selectedScan}
                                selectedScanFiles={selectedScanFiles}
                                setSelectedScanFiles={setSelectedScanFiles}
                                setLoading={setLoading}
                                setLoaderText={setLoaderText}
                                onBackAction={onBackAction}
                                onSelectPreviewFile={fileName => setPreviewingScan(fileName.length > 0)}
                                previewingScan={previewingScan}
                                confirmNewOrder={confirmNewOrder}
                            />
                            {confirmNewOrder && (
                                <div className={styles.newOrderContent}>
                                    <InfoIcon className={styles.newOrderContentIcon} />
                                    <Text variant={'h6'} medium>
                                        New scans will be processed and shipped as a new order
                                    </Text>
                                </div>
                            )}
                            {(!previewingScan || !isMobile) && (
                                <AttachScansActionBar
                                    onBackAction={onBackAction}
                                    onBackText={'Back'}
                                    hideOnBack={confirmNewOrder}
                                    onNextAction={async () => {
                                        if (orderIsCancelResubmit && !confirmNewOrder) {
                                            return setConfirmNewOrder(true);
                                        }

                                        await repackageScanAction();
                                    }}
                                    onNextText={confirmNewOrder ? 'Upload Files as New Order' : 'Upload Scans'}
                                    onNextDisabled={disableNext}
                                    nextButtonVariant={confirmNewOrder ? 'alert' : undefined}
                                    additionalTopContent={
                                        confirmNewOrder ? (
                                            <OrderEditDeliveryDate
                                                originalDeliveryEta={order.practice_dates.estimated_delivery_date}
                                                originalDesignPreviewEta={
                                                    order.practice_dates
                                                        .digital_design_preview_estimated_completion_date
                                                }
                                                items={orderItems}
                                                hasPassedEtaValidation={orderEtaValidation}
                                                setHasPassedEtaValidation={setOrderEtaValidation}
                                                doctorId={order.doctor_preferences_id}
                                                hasWaxup={order.fulfillment_workflow.configuration.waxup_required}
                                                isPaused={!!order.hold_details?.hold_is_practice_managed_pause}
                                                mailingAddressId={order.mailing_address_id}
                                                // we use the case id from the original scan export because that's what the new order will be submitted with
                                                // since the new scans are added post-creation (this property must match what is on the order at time of submit)
                                                caseId={order.scan_export.threeshape_order_id}
                                            />
                                        ) : null
                                    }
                                    additionalBottomContent={
                                        confirmNewOrder ? (
                                            <div className={styles.newOrderWarning}>
                                                <Text variant={'body2'} color={'ATTENTION_FOREGROUND'} medium>
                                                    Your order is already in design. The changes you are submitting
                                                    above will be processed as a new order and your original order will
                                                    be canceled.
                                                </Text>
                                            </div>
                                        ) : null
                                    }
                                />
                            )}
                        </>
                    )}
                </Layout>
            </LoadBlocker>
        </OrderDetailMobileControlPanelBase>
    );
};
