import { DandyMouthToothSelector } from '../../DandyMouth/DandyMouthToothSelector';
import { CheckoutLargeButton } from '../../LegacyCheckoutFormComponents/CheckoutLargeButton';
import type { ToothNumber } from '@orthly/items';
import { OrderItemArch } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { Text, useScreenIsMobile, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface TitleAndSubtitleProps {
    title: string;
    subtitle?: string;
}

interface AlignerTeethSelectionContentProps {
    arch?: OrderItemArch | null;
    disableButtonLabel: string;
    enableButtonLabel: string;
    pickerHeader: TitleAndSubtitleProps;
    disclosure?: string;
    value?: ToothNumber[] | null;
    disabled?: ToothNumber[];
    onChange: (val: ToothNumber[] | null) => void;
    small?: boolean;
}

export const AlignerTeethSelectionContent: React.VFC<AlignerTeethSelectionContentProps> = props => {
    const { arch, disableButtonLabel, enableButtonLabel, pickerHeader, disclosure, value, disabled, onChange, small } =
        props;
    const isMobile = useScreenIsMobile();

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: small ? 4 : 8 }}>
                <Grid item sm={6} xs={12}>
                    <CheckoutLargeButton
                        radio
                        small={small}
                        selected={value === null}
                        onClick={() => onChange(null)}
                        label={disableButtonLabel}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CheckoutLargeButton
                        radio
                        small={small}
                        selected={!!value}
                        onClick={() => onChange([])}
                        label={enableButtonLabel}
                    />
                </Grid>
            </Grid>
            <LoadBlocker
                blocking={!value}
                loader={<React.Fragment />}
                LoaderContainerProps={{ style: { cursor: 'inherit', zIndex: 1000 } }}
                OverlayProps={{ style: { cursor: 'inherit', zIndex: 999 } }}
            >
                <Grid container spacing={2} style={{ marginTop: small ? 8 : 16 }}>
                    <Grid item xs={12}>
                        <Text variant={small ? 'body1' : 'h5'} medium={small}>
                            {pickerHeader.title}
                        </Text>
                        {pickerHeader.subtitle && (
                            <Text variant={small ? 'body2' : 'body1'}>{pickerHeader.subtitle}</Text>
                        )}
                    </Grid>
                    <Grid item container style={{ marginTop: 16, marginBottom: 16 }}>
                        <Grid item xl={small ? 7 : 9} lg={small ? 7 : 10} md={11} xs={12}>
                            <DandyMouthToothSelector
                                selected={value ?? []}
                                disabled={disabled}
                                onChange={onChange}
                                upperEnabled={arch === OrderItemArch.Dual || arch === OrderItemArch.Upper}
                                lowerEnabled={arch === OrderItemArch.Dual || arch === OrderItemArch.Lower}
                                verticalLayout={isMobile}
                            />
                        </Grid>
                    </Grid>
                    {disclosure && (
                        <Grid item xs={12}>
                            <Text variant={small ? 'body2' : 'body1'} color={'DARK_GRAY'} medium={small}>
                                Disclosure
                            </Text>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                {disclosure}
                            </Text>
                        </Grid>
                    )}
                </Grid>
            </LoadBlocker>
        </>
    );
};
