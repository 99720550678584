import { SettingsFormCustomSubmit } from '../../components/SettingsFormCustomSubmit';
import { AddCardForm } from './AddCardForm';
import { FlossPalette, Text, WarningIcon, styled } from '@orthly/ui-primitives';
import React from 'react';

type AddCreditCardFormProps = {
    createCard: (props: { partnerId: string; cardToken: string }) => Promise<void>;
    setOpen: (value: boolean) => void;
    onOpenBankAccountModal: () => void;
    setNewlyCreatedPaymentMethodId?: (pm: string) => void;
    onClosePaymentAction?: () => void;
};

const ContentContainer = styled('div')({
    marginTop: 32,
});

const LinkBankAccountContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '10px',
});

const WarningBanner = styled('div')({
    display: 'flex',
    backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
    borderRadius: '8px',
    gap: '10px',
    padding: '20px',
    marginBottom: '48px',
});

const StyledText = styled(Text)({
    cursor: 'pointer',
});

export const AddCreditCardForm: React.VFC<AddCreditCardFormProps> = ({
    createCard,
    setOpen,
    onOpenBankAccountModal,
    setNewlyCreatedPaymentMethodId,
    onClosePaymentAction,
}) => {
    const [triggerSubmit, setTriggerSubmit] = React.useState<() => void>();
    const setSubmit = React.useCallback((target: () => void) => setTriggerSubmit(() => target), []);
    const [formDisabled, setFormDisabled] = React.useState<boolean>(true);

    return (
        <ContentContainer>
            <WarningBanner>
                <WarningIcon style={{ color: FlossPalette.ATTENTION }} />
                <Text variant={'body2'}>
                    If you choose to pay by credit card, a 2.9% processing fee will be added to your monthly invoice.
                </Text>
            </WarningBanner>
            <AddCardForm
                createCard={createCard}
                onComplete={(_status, paymentMethodId) => {
                    setOpen(false);
                    setNewlyCreatedPaymentMethodId?.(paymentMethodId ?? '');
                }}
                onRender={({ submit, disabled }) => {
                    if (triggerSubmit === undefined) {
                        setSubmit(submit);
                    }
                    setFormDisabled(disabled);
                }}
            />
            <LinkBankAccountContainer>
                <Text variant={'body2'}>Avoid the processing fee by paying via ACH (bank account).</Text>
                <StyledText variant={'body2'} color={'PRIMARY_FOREGROUND'} bold onClick={onOpenBankAccountModal}>
                    View instructions here
                </StyledText>
            </LinkBankAccountContainer>
            {triggerSubmit && (
                <SettingsFormCustomSubmit
                    setOpen={(open: boolean) => {
                        setOpen(open);
                        if (!open) {
                            onClosePaymentAction?.();
                        }
                    }}
                    disabled={formDisabled}
                    triggerSubmit={triggerSubmit}
                    confirmText={'Add Credit Card'}
                    style={{ marginTop: 20 }}
                />
            )}
        </ContentContainer>
    );
};
