import { PracticeScreen } from '../PracticeScreen';
import { getOrderButtonsToRender, OrderSummaryButton } from './OrderSummary.utils';
import { useOrderSummaryRowButtonStyles } from './OrderSummaryRow.styles';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { useMarkFulfillmentDeliveredMutation } from '@orthly/graphql-react';
import type { LabsGqlLabOrderWaxupReviewStatus } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import { Button, styled } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const OrderSummaryRowButtonContainer = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
        justifyContent: 'end',
        marginTop: 20,
    },
}));

const OrderSummaryRowGhostButton = styled(Button)(({ theme }) => ({
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
    },
}));

const OrderSummaryRowPrimaryButton = styled(Button)(({ theme }) => ({
    marginLeft: 32,
    [theme.breakpoints.down('lg')]: {
        marginLeft: 20,
    },
    [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: 16,
    },
}));

const useOrderSummaryRowButtonClickAction = ({
    orderId,
    path,
    eventName,
}: {
    orderId: string;
    path: string;
    eventName: 'Practice - Order List - Edit Order Clicked' | 'Practice - Order List - Review Design Clicked';
}) => {
    const history = useHistory();

    return React.useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            history.push(path);
            BrowserAnalyticsClientFactory.Instance?.track(eventName, {
                $groups: { order: orderId },
            });
        },
        [history, path, eventName, orderId],
    );
};

interface OrderSummaryRowButtonBaseProps {
    orderId: string;
}

interface OrderSummaryRowResolveHoldButtonProps {
    setAskDoctorResponseOpen: (opening: boolean) => void;
    orderId: string;
}

interface OrderSummaryRowResumeOrderButtonProps {
    onSubmitRemoveHold: () => void;
    orderId: string;
}

interface OrderSummaryRowButtonsProps {
    setAskDoctorResponseOpen: (opening: boolean) => void;
    onSubmitRemoveHold: () => void;
    shouldShowResolveButton: boolean;
    orderId: string;
    waxup_status: LabsGqlLabOrderWaxupReviewStatus | null;
    can_refabricate: boolean;
    can_submit_feedback: boolean;
    can_edit: boolean;
    can_mark_delivered: boolean;
    is_delivered: boolean;
    is_on_practice_managed_pause: boolean;
    has_live_ddp_review: boolean;
}

const OrderSummaryRowModifyRXButton: React.VFC<OrderSummaryRowButtonBaseProps> = ({ orderId }) => {
    const classes = useOrderSummaryRowButtonStyles();

    const onClickAction = useOrderSummaryRowButtonClickAction({
        orderId: orderId,
        path: `/${PracticeScreen.orders}/${orderId}?action=edit`,
        eventName: 'Practice - Order List - Edit Order Clicked',
    });

    return (
        <OrderSummaryRowButtonContainer>
            <OrderSummaryRowGhostButton
                className={classes.button}
                variant={'ghost'}
                onClick={onClickAction}
                endIcon={'PencilOutlinedIcon'}
            >
                Modify RX
            </OrderSummaryRowGhostButton>
        </OrderSummaryRowButtonContainer>
    );
};

const OrderSummaryRowReviewDesignButton: React.VFC<OrderSummaryRowButtonBaseProps> = ({ orderId }) => {
    const classes = useOrderSummaryRowButtonStyles();

    const onClickAction = useOrderSummaryRowButtonClickAction({
        orderId: orderId,
        path: `/${PracticeScreen.guided_waxup}/${orderId}`,
        eventName: 'Practice - Order List - Review Design Clicked',
    });

    return (
        <OrderSummaryRowButtonContainer>
            <OrderSummaryRowPrimaryButton className={classes.button} variant={'primary'} onClick={onClickAction}>
                Review design
            </OrderSummaryRowPrimaryButton>
        </OrderSummaryRowButtonContainer>
    );
};

const OrderSummaryRowResolveHoldButton: React.VFC<OrderSummaryRowResolveHoldButtonProps> = ({
    orderId,
    setAskDoctorResponseOpen,
}) => {
    const classes = useOrderSummaryRowButtonStyles();

    const onClickAction = (event: React.MouseEvent) => {
        setAskDoctorResponseOpen(true);
        event.stopPropagation();
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Order List - Resolve Hold Button Clicked', {
            $groups: { order: orderId },
        });
    };

    return (
        <OrderSummaryRowButtonContainer>
            <OrderSummaryRowPrimaryButton className={classes.button} variant={'primary'} onClick={onClickAction}>
                Resolve hold
            </OrderSummaryRowPrimaryButton>
        </OrderSummaryRowButtonContainer>
    );
};

const OrderSummaryRowResumeOrderButton: React.VFC<OrderSummaryRowResumeOrderButtonProps> = ({ onSubmitRemoveHold }) => {
    const classes = useOrderSummaryRowButtonStyles();

    const onClickAction = (event: React.MouseEvent) => {
        onSubmitRemoveHold();
        event.stopPropagation();
    };

    return (
        <OrderSummaryRowButtonContainer>
            <OrderSummaryRowPrimaryButton className={classes.button} variant={'primary'} onClick={onClickAction}>
                Resume order
            </OrderSummaryRowPrimaryButton>
        </OrderSummaryRowButtonContainer>
    );
};

const OrderSummaryRowMarkDeliveredButton: React.VFC<OrderSummaryRowButtonBaseProps> = ({ orderId }) => {
    const classes = useOrderSummaryRowButtonStyles();

    const [submitMtn] = useMarkFulfillmentDeliveredMutation({ variables: { data: { orderId } } });
    const { submit, submitting } = useChangeSubmissionFn<any, any>(() => submitMtn(), {
        closeOnComplete: true,
    });
    const onClickAction = async (event: React.MouseEvent) => {
        await submit();
        event.stopPropagation();
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Order List - Mark Delivered Button Clicked', {
            $groups: { order: orderId },
        });
    };

    return (
        <OrderSummaryRowButtonContainer>
            <OrderSummaryRowPrimaryButton
                className={classes.button}
                variant={'primary'}
                onClick={onClickAction}
                disabled={submitting}
            >
                Mark Delivered
            </OrderSummaryRowPrimaryButton>
        </OrderSummaryRowButtonContainer>
    );
};

export const OrderSummaryRowButtons: React.VFC<OrderSummaryRowButtonsProps> = props => {
    const { orderId, setAskDoctorResponseOpen, onSubmitRemoveHold, shouldShowResolveButton, ...buttonArgs } = props;

    const buttonsToRender = getOrderButtonsToRender(buttonArgs, shouldShowResolveButton);

    if (buttonsToRender.includes(OrderSummaryButton.ResumeOrder)) {
        return <OrderSummaryRowResumeOrderButton orderId={orderId} onSubmitRemoveHold={onSubmitRemoveHold} />;
    }

    if (buttonsToRender.includes(OrderSummaryButton.ResolveHold)) {
        return (
            <OrderSummaryRowResolveHoldButton orderId={orderId} setAskDoctorResponseOpen={setAskDoctorResponseOpen} />
        );
    }

    if (buttonsToRender.includes(OrderSummaryButton.ReviewDesign)) {
        return <OrderSummaryRowReviewDesignButton orderId={orderId} />;
    }

    if (buttonsToRender.includes(OrderSummaryButton.ModifyRx)) {
        return <OrderSummaryRowModifyRXButton orderId={orderId} />;
    }

    if (buttonsToRender.includes(OrderSummaryButton.MarkDelivered)) {
        return <OrderSummaryRowMarkDeliveredButton orderId={orderId} />;
    }

    return null;
};
