import type { ToothNumber } from '@orthly/items';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'implant_checkout';

interface PrevImplantStep {
    numUploadedPhotos: number;
}

interface NextImplantStep {
    numUploadedPhotos: number;
    isUploadComplete: boolean;
}

interface AddScanbodyForItemTooth {
    item_index: number;
    unn: ToothNumber;
    scanbody_id: string | null;
}

export const ImplantCheckoutActions = {
    PREV_IMPLANT_STEP: createSyncAction<PrevImplantStep, [PrevImplantStep]>(`${PREFIX}/PREV_IMPLANT_STEP`),
    TRY_NEXT_IMPLANT_STEP: createSyncAction<NextImplantStep, [NextImplantStep]>(`${PREFIX}/NEXT_IMPLANT_STEP`),
    START_UPLOAD: createSyncAction<undefined, []>(`${PREFIX}/START_UPLOAD`),
    CHECK_INSTRUCTIONS: createSyncAction<undefined, []>(`${PREFIX}/CHECK_INSTRUCTIONS`),
    SET_MISSING_UPLOAD_WARNING: createSyncAction<boolean, [boolean]>(`${PREFIX}/SET_MISSING_UPLOAD_WARNING`),
    ADD_SCANBODY_FOR_ITEM_TOOTH: createSyncAction<AddScanbodyForItemTooth, [AddScanbodyForItemTooth]>(
        `${PREFIX}/ADD_SCANBODY_FOR_ITEM_TOOTH`,
    ),
};

export const useImplantCheckoutAction = generateUseActionHook<typeof ImplantCheckoutActions>(ImplantCheckoutActions);
