import { ExtraShadesSummary } from './ExtraShadesSummary';
import { rowStyles } from './ImplantSection';
import { InlinePrefsSection } from './InlinePrefsSection';
import type { ItemSectionProps } from './ItemsSection.types';
import { ItemsSectionDisplayClasses } from './util/displayClasses';
import { getDenturesTitle } from './util/getItemDisplay';
import { dedupedItemToItemsArray } from './util/mergeOrderItems';
import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import type { LabOrderItemSKUType } from '@orthly/items';
import { DENTURES_ARCH_CHOICE_METAFIELD_ID } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface DenturesSectionProps extends Omit<ItemSectionProps, 'item'> {
    item: Extract<ItemSectionProps['item'], { sku: LabOrderItemSKUType.Denture }>;
}

export const DenturesSection: React.VFC<DenturesSectionProps> = props => {
    const { item, index, inlinePrefs } = props;
    const archType = inlinePrefs.find(pref => pref.field_id === DENTURES_ARCH_CHOICE_METAFIELD_ID)?.value;
    const displayInlinePrefs = inlinePrefs.filter(pref => pref.field_id !== DENTURES_ARCH_CHOICE_METAFIELD_ID);
    const { ContainerComponent, fontComponents, Action } = useOrderSummaryComponents();
    const { BoldText, MediumText } = fontComponents;

    return (
        <ContainerComponent
            style={{
                borderTop: index !== 0 ? '1pt solid lightgray' : undefined,
                minHeight: '35pt',
                flexDirection: 'column',
            }}
        >
            <ContainerComponent style={{ paddingLeft: '0', marginBottom: '2pt' }}>
                <ContainerComponent
                    className={ItemsSectionDisplayClasses.ITEM_HEADER}
                    style={{ width: '100%', paddingTop: '5pt', flexDirection: 'row', alignItems: 'flex-end' }}
                >
                    <BoldText className={ItemsSectionDisplayClasses.ITEM_HEADER_TITLE} style={{ flex: 70 }}>
                        {getDenturesTitle(item)}
                    </BoldText>
                    {Action ? (
                        <Grid style={{ flex: 20, textAlign: 'right' }}>
                            <Action items={dedupedItemToItemsArray(item)} />
                        </Grid>
                    ) : null}
                </ContainerComponent>
                {!!archType && (
                    <ContainerComponent className={ItemsSectionDisplayClasses.DETAIL_ROW} style={rowStyles}>
                        <MediumText>{`${archType} Arch${archType === 'Both' ? 'es' : ''}`}</MediumText>
                    </ContainerComponent>
                )}
                {item.shades.length > 0 && (
                    <ContainerComponent
                        className={ItemsSectionDisplayClasses.DETAIL_ROW}
                        style={{ ...rowStyles, flexDirection: 'row' }}
                    >
                        <MediumText style={{ flex: 23 }}>Shades</MediumText>
                        <ContainerComponent style={{ flex: 75, flexDirection: 'row', fontSize: '12pt' }}>
                            <ExtraShadesSummary item={item} visibleShades={['base', 'tissue']} />
                        </ContainerComponent>
                        {Action ? <Grid style={{ flex: 20 }} /> : null}
                    </ContainerComponent>
                )}

                <InlinePrefsSection inlinePrefs={displayInlinePrefs} item_notes={item.item_notes} />
            </ContainerComponent>
        </ContainerComponent>
    );
};
