import { useTryInFeedbackPropSelector } from './state/TryInFeedback.selectors';
import type {
    TryInFeedbackGroupNavigationSubStep,
    TryInFeedbackItemGroup,
    TryInFeedbackNavigation,
} from './state/TryInFeedback.types';
import { TryInFeedbackUtils } from './utils/TryInFeedback.util';
import type { SidebarStepperStep } from '@orthly/dentin';
import { SidebarStepper, ProductImageWrapper } from '@orthly/dentin';
import { CartItemV2Utils, OrderItemArch } from '@orthly/items';
import { Grid, FlossPalette } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export const TRY_IN_FEEDBACK_SIDEBAR_WIDTH = 288;

const SUB_STEP_TITLES: Record<TryInFeedbackGroupNavigationSubStep, string> = {
    [OrderItemArch.Dual]: 'General feedback',
    [OrderItemArch.Upper]: 'Upper arch feedback',
    [OrderItemArch.Lower]: 'Lower arch feedback',
    next_steps: 'Decide next steps',
};

function getItemGroupStepperStep(
    itemGroup: TryInFeedbackItemGroup,
    itemGroupIndex: number,
    navigation?: TryInFeedbackNavigation,
): SidebarStepperStep {
    const isActive = navigation?.step === 'group' && navigation.groupIndex === itemGroupIndex;
    const isComplete = navigation?.step !== 'group' || navigation.groupIndex > itemGroupIndex;
    const subSteps = TryInFeedbackUtils.getGroupNavigationSubSteps(itemGroup.orderItem);

    return {
        title: (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <ProductImageWrapper product={CartItemV2Utils.getProductUnitType(itemGroup.orderItem)} />
                </div>
                <span style={{ marginLeft: 4 }}>{CartItemV2Utils.getDisplayName(itemGroup.orderItem)}</span>
            </div>
        ),
        active: isActive,
        completed: isComplete,
        children: subSteps.map((subStep, idx) => {
            const activeIndex = isActive ? subSteps.indexOf(navigation.subStep) : -1;

            return {
                title: SUB_STEP_TITLES[subStep],
                active: isActive && activeIndex === idx,
                completed: isComplete || (isActive && activeIndex > idx),
            };
        }),
    };
}

function getReturnInstructionsStepperStep(navigation?: TryInFeedbackNavigation): SidebarStepperStep {
    return {
        title: 'Shipping instructions',
        active: navigation?.step === 'return_instructions',
        completed: navigation?.step === 'submit',
    };
}

function getSubmitStepperStep(navigation?: TryInFeedbackNavigation): SidebarStepperStep {
    return {
        title: 'Review & submit',
        active: navigation?.step === 'submit',
        completed: false,
    };
}

function useSteps(): SidebarStepperStep[] {
    const { itemGroups, navigation } = useTryInFeedbackPropSelector(['itemGroups', 'navigation']);

    const itemGroupSteps = itemGroups.map((itemGroup, idx) => getItemGroupStepperStep(itemGroup, idx, navigation));
    const returnInstructionsStep = TryInFeedbackUtils.needsReturn({ itemGroups })
        ? getReturnInstructionsStepperStep(navigation)
        : undefined;
    const submitStep = getSubmitStepperStep(navigation);

    return _.compact([...itemGroupSteps, returnInstructionsStep, submitStep]);
}

export const TryInFeedbackSidebar: React.VFC = () => {
    const steps = useSteps();

    return (
        <Grid
            item
            container
            direction={'column'}
            wrap={'nowrap'}
            style={{
                borderRight: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                width: TRY_IN_FEEDBACK_SIDEBAR_WIDTH,
                position: 'relative',
                zIndex: 10,
                backgroundColor: FlossPalette.WHITE,
                height: '100%',
            }}
        >
            <div style={{ overflowY: 'auto', padding: 16 }}>
                <SidebarStepper steps={steps} />
            </div>
        </Grid>
    );
};
