import type { ScanbodyWithPriority } from '../state/ImplantReadiness.types';
import { ScanbodyImage } from '@orthly/dentin';
import type { LabsGqlScanbodyFragment, LabsGqlScanbodyInventoryItemFragment } from '@orthly/graphql-operations';
import { LabsGqlShipmentStatus } from '@orthly/graphql-schema';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { ActionCard } from '@orthly/ui';
import { FlossPalette, stylesFactory, Dialog, DialogContent, Grid, Button, Medium, Text } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    container: {
        backgroundColor: FlossPalette.TAN,
        borderColor: FlossPalette.DARK_TAN,
        borderStyle: 'solid',
        borderRadius: 8,
        borderWidth: 1,
        padding: 16,
        width: 'auto',
        flexGrow: 1,
        gap: '16px',
    },
    disabled: {
        opacity: 0.4,
    },
    scanbodyImage: {
        width: 80,
        height: 80,
        background: FlossPalette.WHITE,
        borderColor: FlossPalette.DARK_TAN,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 8,
        marginRight: 16,
        '& img': {
            maxWidth: '100%',
            maxHeight: '100%',
        },
    },
    details: {
        width: 'auto',
        flexGrow: 1,
    },
    inventory: {
        position: 'relative',
        '&:before': {
            content: '""',
            position: 'absolute',
            top: -10,
            left: 0.5 * 80 - 8, // (.5 * scanbodyImage.width) - arrowWidth
            width: 0,
            height: 0,
            borderLeft: '8px solid transparent',
            borderRight: '8px solid transparent',
            borderBottom: `10px solid ${FlossPalette.PRIMARY_BACKGROUND}`,
        },
    },
}));

const InventoryMessage: React.VFC<{ inventory: LabsGqlScanbodyInventoryItemFragment | undefined }> = ({
    inventory,
}) => {
    if (!inventory) {
        return null;
    }
    let shippingStatus = 'was shipped';
    let orderStatus = 'We already shipped you';
    if (!!inventory.shipment?.status) {
        orderStatus = 'You already ordered';
        shippingStatus = 'will be shipped';
    }
    if (inventory.shipment?.status === LabsGqlShipmentStatus.Delivered) {
        shippingStatus = 'was delivered';
    }
    const hasCaseInfo = inventory.patient && inventory.item;
    const inventoryItem = inventory.item && CartItemV2Utils.parseItem(inventory.item);
    let implantDetails = '';
    if (
        inventoryItem &&
        CartItemV2Utils.itemIsType(inventoryItem, [LabOrderItemSKUType.Implant, LabOrderItemSKUType.ImplantBridge])
    ) {
        implantDetails = `Implant ${CartItemV2Utils.getUniqueUNNs(inventoryItem)}`;
    }

    return (
        <Text variant={'body2'}>
            Good news! {orderStatus} this scan body
            {hasCaseInfo && (
                <>
                    {' '}
                    for{' '}
                    <Medium>
                        {inventory.patient?.first_name} {inventory.patient?.last_name} {implantDetails}
                    </Medium>{' '}
                    case
                </>
            )}
            . It {shippingStatus} to <Medium>{inventory.address}</Medium> and it's autoclavable and reusable up to 50
            times.
        </Text>
    );
};

const InventoryDialog: React.VFC<InventoryProps> = ({ open, addToCart, close, scanbody }) => {
    const classes = useStyles();
    const height = scanbody.manufacturer.split(':')?.[1];

    return (
        <Dialog open={open} onClose={close} PaperProps={{ style: { borderRadius: 8 } }}>
            <DialogContent style={{ padding: 20 }}>
                <Grid container direction={'column'} style={{ gap: 24 }}>
                    <Text variant={'h4'}>Order this scan body again?</Text>
                    <InventoryMessage inventory={scanbody.inventory} />
                    <Text variant={'body2'}>Please verify if you still have it with you before ordering it again.</Text>
                    <Grid container alignItems={'center'} className={classes.container}>
                        <Grid container justifyContent={'center'} className={classes.scanbodyImage}>
                            <ScanbodyImage scanbodyName={scanbody.name} scanbodyManufacturer={scanbody.manufacturer} />
                        </Grid>
                        <Grid container direction={'column'} justifyContent={'center'} className={classes.details}>
                            <Text variant={'body2'} color={'BLACK'} bold>
                                {scanbody.name}
                            </Text>
                            {height && (
                                <Text variant={'body2'} color={'GRAY'} bold>
                                    {height} height
                                </Text>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container justifyContent={'flex-end'} style={{ gap: 8 }}>
                        <Button variant={'secondary'} onClick={close}>
                            No, I've found it
                        </Button>
                        <Button variant={'primary'} onClick={addToCart}>
                            Yes, add to cart
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

interface InventoryProps {
    open: boolean;
    addToCart(): void;
    close(): void;
    scanbody: LabsGqlScanbodyFragment & { inventory?: LabsGqlScanbodyInventoryItemFragment };
}

const Inventory: React.VFC<InventoryProps> = props => {
    const classes = useStyles();

    if (!props.scanbody.inventory) {
        return null;
    }

    return (
        <>
            <InventoryDialog {...props} />
            <div className={classes.inventory}>
                <ActionCard
                    title={''}
                    subtitle={<InventoryMessage inventory={props.scanbody.inventory} />}
                    variant={'info'}
                />
            </div>
        </>
    );
};

interface ScanbodyProps {
    scanbody: LabsGqlScanbodyFragment & {
        inventory: LabsGqlScanbodyInventoryItemFragment | undefined;
    } & ScanbodyWithPriority;
    isInCart: boolean;
    addToCart(): void;
}

export const Scanbody: React.VFC<ScanbodyProps> = ({ scanbody, isInCart, addToCart }) => {
    const classes = useStyles();
    const [showOrderAgain, setShowOrderAgain] = React.useState(false);
    // When a manufacturer has multiple scanbodies the height is encoded in the name like: `Name: size`
    const height = scanbody.manufacturer.split(':')?.[1];
    const cannotBeFulfilled = scanbody.priority === 99;

    return (
        <Grid container direction={'column'} wrap={'nowrap'} className={classes.container}>
            <Grid container alignItems={'center'}>
                <Grid
                    container
                    justifyContent={'center'}
                    className={clsx(classes.scanbodyImage, isInCart && classes.disabled)}
                >
                    <ScanbodyImage scanbodyName={scanbody.name} scanbodyManufacturer={scanbody.manufacturer} />
                </Grid>
                <Grid
                    container
                    direction={'column'}
                    justifyContent={'center'}
                    className={clsx(classes.details, isInCart && classes.disabled)}
                >
                    <Text variant={'body2'} color={'BLACK'} bold>
                        {scanbody.name}
                    </Text>
                    {height && (
                        <Text variant={'body2'} color={'GRAY'} bold>
                            {height} height
                        </Text>
                    )}
                </Grid>
                {cannotBeFulfilled ? (
                    <Text variant={'body2'} color={'GRAY'} style={{ maxWidth: 200 }}>
                        If needed, purchase directly from the implant manufacturer
                    </Text>
                ) : (
                    <Button
                        disabled={isInCart}
                        variant={'secondary'}
                        endIcon={isInCart ? 'CheckIcon' : 'CartIcon'}
                        onClick={() => {
                            if (scanbody.inventory) {
                                setShowOrderAgain(true);
                            } else {
                                addToCart();
                            }
                        }}
                    >
                        {isInCart ? 'Added to cart' : 'Add to cart'}
                    </Button>
                )}
            </Grid>
            <Inventory
                scanbody={scanbody}
                addToCart={() => {
                    addToCart();
                    setShowOrderAgain(false);
                }}
                open={showOrderAgain}
                close={() => setShowOrderAgain(false)}
            />
        </Grid>
    );
};
