import { makeStyles } from '../../re-export-mui';
import { Text } from '../Text';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroupRoot from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import React from 'react';

const useCompactLabelStyles = makeStyles(() => ({
    label: {
        marginLeft: 8,
    },
}));

const SectionHeading = styled(Text)(() => ({
    borderBottom: '1px solid #ccc',
    paddingBottom: 8,
    marginBottom: 8,
}));

const CompactRadio = styled(Radio)({
    margin: '4px 0',
});

export interface RadioGroupProps {
    /** The value of the currently selected radio button */
    value: string | undefined;
    /** A callback function that is called when the selected radio button changes */
    handleChange: (val: string) => void;
    /** An array of objects with the id and label of each radio button */
    options: { id: string; label: React.ReactNode; disabled?: boolean }[];
    /** The label for the radio group */
    label?: string;
    /** Whether the radio group is disabled */
    disabled?: boolean;
    /** The variant of the radio group, which controls the spacing between elements */
    variant?: 'regular' | 'compact';
}

export const RadioGroup: React.FC<RadioGroupProps> = ({
    value,
    handleChange,
    options,
    label,
    disabled,
    variant = 'regular',
}) => {
    const compactLabelClasses = useCompactLabelStyles();

    return (
        <div>
            <FormControl component={'fieldset'}>
                {label && <SectionHeading variant={'h6'}>{label}</SectionHeading>}
                <RadioGroupRoot
                    aria-label={label}
                    name={label}
                    value={value}
                    onChange={event => {
                        !disabled && handleChange(event.target.value);
                    }}
                >
                    {options.map(({ id, label, disabled: disabledLocal }) => (
                        <FormControlLabel
                            classes={variant === 'compact' ? compactLabelClasses : undefined}
                            key={id}
                            value={id}
                            control={variant === 'compact' ? <CompactRadio /> : <Radio />}
                            label={label}
                            checked={value === id}
                            disabled={disabledLocal || disabled}
                        />
                    ))}
                </RadioGroupRoot>
            </FormControl>
        </div>
    );
};
