import { FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const RequiredLabel = styled(Text)({
    '&::after': {
        color: FlossPalette.ATTENTION,
        content: '"*"',
    },
});

export const RequiredTraineeInputLabel: React.VFC<{ text: string }> = ({ text }) => {
    return (
        <RequiredLabel variant={'body2'} medium>
            {text}
        </RequiredLabel>
    );
};
