import type { AestheticCheckoutState } from '../checkout.state';
import type { LabsGqlLabOrderSinglePhotoInput } from '@orthly/graphql-schema';
import { LabsGqlLabOrderPhotoType } from '@orthly/graphql-schema';

export const aestheticCheckoutInitialState: AestheticCheckoutState = {
    photos: {},
    screen: 'instructions',
    missingUploadWarningShowing: false,
    allowInstructions: false,
};

export function aestheticCheckoutGetPhotosAsAttachments(
    state: AestheticCheckoutState,
): LabsGqlLabOrderSinglePhotoInput[] {
    return Object.values(LabsGqlLabOrderPhotoType).flatMap(type => {
        const fullPath = state.photos[type];
        return fullPath ? [{ type, fullPath }] : [];
    });
}
