import type { ActionProps, DandyNotification } from './DandyNotifications.types';
import { FlossPalette, Text, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

interface DandyUpdateNotificationsItemProps {
    notification: DandyNotification;
    onClick: (action: ActionProps) => void;
}

export const DandyUpdateNotificationsItem: React.FC<DandyUpdateNotificationsItemProps> = props => {
    const { notification, onClick } = props;

    return (
        <Grid container style={{ padding: 16 }} key={notification.id}>
            <Grid item xs={1} style={{ color: FlossPalette.GRAY }}>
                {notification.icon}
            </Grid>
            <Grid item xs={11}>
                <Text variant={'body2'} medium>
                    {notification.title}
                </Text>
                <Text variant={'body2'}>{notification.subtitle}</Text>
                <Grid container spacing={1}>
                    <Grid item>
                        <Button
                            variant={'secondary'}
                            disabled={notification.primaryAction.disabled}
                            onClick={() => {
                                onClick(notification.primaryAction);
                            }}
                            style={{ marginTop: 8 }}
                            endIcon={'ArrowForwardIos'}
                        >
                            {notification.primaryAction.text}
                        </Button>
                    </Grid>
                    {notification.secondaryAction && (
                        <Grid item>
                            <Button
                                variant={'secondary'}
                                onClick={() => {
                                    if (notification.secondaryAction) {
                                        onClick(notification.secondaryAction);
                                    }
                                }}
                                style={{ marginTop: 8 }}
                            >
                                {notification.secondaryAction.text}
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
