import { useCheckoutSelector } from '../../../redux';
import { MarkNeedsRefabFileUploader } from '../../labs/actions/practitioners/MarkNeedsRefabrication';
import { useCheckoutAction } from '../state/checkout.actions';
import { refabScreenIsComplete, refabScreenIsVisible } from '../state/checkout.selectors';
import type { CheckoutRefabState } from '../state/checkout.state';
import { CheckoutTextField, CheckoutBodyWrapper } from '@orthly/dentin';
import { Grid, Text } from '@orthly/ui-primitives';
import { RefabReasonSelector, useRefabReasonOptions } from '@orthly/veneer';
import React from 'react';

const CheckoutRefabFieldsContent: React.FC<CheckoutRefabState> = props => {
    const setRefabAttachments = useCheckoutAction('SET_REFAB_ATTACHMENTS');
    const setRefabNotes = useCheckoutAction('SET_REFAB_NOTES');
    const setRefabReasons = useCheckoutAction('SET_REFAB_REASONS');
    const setAttachmentsUploading = useCheckoutAction('SET_REFAB_FILES_UPLOADING');
    const { attachments, notes, original_order } = props;
    const { reasons, loading: reasonsLoading } = useRefabReasonOptions(original_order.id, props.reasons);

    return (
        <Grid container direction={'row'} wrap={'nowrap'} style={{ paddingTop: 16 }}>
            <Grid item xs={6} style={{ paddingRight: 32 }}>
                <RefabReasonSelector
                    loading={reasonsLoading}
                    possibleReasons={reasons}
                    selected={props.reasons}
                    setSelected={setRefabReasons}
                    headerText={'Select one or more reasons'}
                />
            </Grid>
            <Grid item xs={6} alignItems={'flex-end'} style={{ paddingLeft: 32 }}>
                <Grid item style={{ marginBottom: '24px' }}>
                    <Text variant={'h6'}>Tell us as much as possible to help us correct the issues</Text>
                    <CheckoutTextField
                        required
                        disableErrorMessage
                        onChange={value => setRefabNotes(value)}
                        label={'Notes'}
                        value={notes}
                        TextFieldProps={{
                            autoFocus: true,
                            InputLabelProps: { shrink: true },
                            rows: 2,
                            maxRows: 10,
                            multiline: true,
                        }}
                    />
                </Grid>
                <MarkNeedsRefabFileUploader
                    orderId={original_order.id}
                    setAttachmentUrls={value => {
                        setRefabAttachments(value);
                    }}
                    attachmentUrls={attachments}
                    onLoadingStateChange={setAttachmentsUploading}
                />
            </Grid>
        </Grid>
    );
};

const CheckoutRefabFields: React.FC = () => {
    const refabInfo = useCheckoutSelector(s => s.refab);
    return !refabInfo ? null : <CheckoutRefabFieldsContent {...refabInfo} />;
};

export const CheckoutRefabScreen: React.VFC = () => {
    const visible = useCheckoutSelector(refabScreenIsVisible);
    const isComplete = useCheckoutSelector(refabScreenIsComplete);
    return (
        <CheckoutBodyWrapper visible={visible} isComplete={isComplete}>
            <CheckoutRefabFields />
        </CheckoutBodyWrapper>
    );
};
