import { useCheckoutPropSelector } from '../../../../redux';
import { CheckoutSelectDoctor } from '../../components/PatientDoctorInfo/CheckoutSelectDoctor';
import { CheckoutSelectScan } from '../../components/PatientDoctorInfo/CheckoutSelectScan';
import { useScanWithExistingOrderSelector } from '../../state/checkout.selectors';
import { useDentureCheckoutStepInfo } from '../../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../../state/reducers/dentures-checkout.types';
import { CheckoutExistingOrderForScan } from '../CheckoutExistingOrderStep/CheckoutExistingOrderForScan';
import { CheckoutBodyWrapper } from '@orthly/dentin';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import { Collapse } from '@orthly/ui-primitives';
import React from 'react';

interface DentureCheckoutPatientDoctorStepProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

export const DentureCheckoutPatientDoctorStep: React.FC<DentureCheckoutPatientDoctorStepProps> = ({ scansQuery }) => {
    const { step: checkoutStep, existingOrderWarningDismissed } = useCheckoutPropSelector([
        'step',
        'existingOrderWarningDismissed',
    ]);
    const { isActiveStep, isComplete } = useDentureCheckoutStepInfo(DentureCheckoutStep.PatientInfoStep);
    const scanWithExisting = useScanWithExistingOrderSelector();
    const existingOrderScreenVisible = !!scanWithExisting && !existingOrderWarningDismissed;
    const rootVisible = !existingOrderScreenVisible && checkoutStep === 0;

    return (
        <CheckoutBodyWrapper visible={checkoutStep === 0 && isActiveStep} isComplete={checkoutStep > 0 || isComplete}>
            <Collapse in={existingOrderScreenVisible} style={{ width: '100%' }} mountOnEnter unmountOnExit>
                {scanWithExisting && <CheckoutExistingOrderForScan scanWithExisting={scanWithExisting} />}
            </Collapse>
            <Collapse in={rootVisible} style={{ width: '100%' }} mountOnEnter unmountOnExit>
                <CheckoutSelectDoctor />
                <CheckoutSelectScan scansQuery={scansQuery} />
            </Collapse>
        </CheckoutBodyWrapper>
    );
};
