import type { SelectStaffState, StaffProfileInfo } from './state/select-staff.reducer';
import { UuidUtils } from '@orthly/runtime-utils';
import type { ReduxCompatibleReducer } from 'redux-actions';

const LOCAL_STORAGE_SELECT_STAFF_KEY = 'LOCAL_STORAGE_SELECT_STAFF_KEY';

function isStaffProfileInfo(value: any): value is StaffProfileInfo {
    return (
        value &&
        typeof value === 'object' &&
        typeof value.id === 'string' &&
        UuidUtils.isUUID(value.id) &&
        typeof value.__typename === 'string' &&
        ['PracticeStaffPreferencesDTO', 'DoctorPreferences'].includes(value.__typename)
    );
}

export const loadStaffProfileInfoFromLocalStorage = (): StaffProfileInfo | undefined => {
    try {
        const string = localStorage.getItem(LOCAL_STORAGE_SELECT_STAFF_KEY);
        if (!string) {
            return undefined;
        }
        const value = JSON.parse(string);
        if (!isStaffProfileInfo(value)) {
            return undefined;
        }
        return value;
    } catch {
        return undefined;
    }
};

export const persistStaffProfileReducerToLocalStorage = (
    staffMemberReducerRaw: ReduxCompatibleReducer<SelectStaffState, any>,
): ReduxCompatibleReducer<SelectStaffState, any> => {
    return (state, action) => {
        const nextState = staffMemberReducerRaw(state, action);
        if (nextState.profile !== state?.profile) {
            if (nextState.profile) {
                const { __typename, id, roles, name, contact_email, staff_member_id } = nextState.profile;
                const payload: StaffProfileInfo = { __typename, id, roles, name, contact_email, staff_member_id };
                localStorage.setItem(LOCAL_STORAGE_SELECT_STAFF_KEY, JSON.stringify(payload));
            } else {
                localStorage.removeItem(LOCAL_STORAGE_SELECT_STAFF_KEY);
            }
        }
        return nextState;
    };
};
