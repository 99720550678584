import { stylesFactory, Text, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    numberedBullet: {
        backgroundColor: FlossPalette.PRIMARY_FOREGROUND,
        display: 'inline-block',
        textAlign: 'center',
        width: 26,
        height: 26,
        marginRight: 16,
        borderRadius: '50%',
    },
}));

const triosBulletPoints: readonly string[] = [
    `Custom designed wax bite rim(s) will be delivered to your office`,
    `Adjust and guide the wax bite rim into your patient's mouth to register their bite and mark mid-lines, canine lines, and smile lines with the wax rims(s) in the patient's mouth.`,
    `Perform a 360 extraoral scan of the wax bite rim and send to Dandy`,
    `Receive the final denture at your office and deliver your patient a perfect smile!`,
] as const;

const chairsideBulletPoints: readonly string[] = [
    `At the patient’s follow-up appointment, simply click start from the home screen and type in the patient's name to continue this order.`,
    `During the appointment, use the bite rim to register the patient's bite. Submit those bite rim scans to us.`,
    `After we receive the bite rim scans, we'll begin fabricating the final denture. Simply schedule a third appointment for final delivery and we'll handle the rest!`,
] as const;

export const DentureCheckoutWaxRimNextSteps: React.VFC<{ isChairside: boolean }> = props => {
    const classes = useStyles();
    const bulletPoints = props.isChairside ? chairsideBulletPoints : triosBulletPoints;

    return (
        <>
            {bulletPoints.map((bullet, index) => (
                <Grid
                    container
                    key={index}
                    wrap={'nowrap'}
                    alignItems={'center'}
                    style={{ marginTop: 16, maxWidth: 700 }}
                >
                    <Grid item>
                        <Text variant={'body2'} medium color={'WHITE'} className={classes.numberedBullet}>
                            {index + 1}
                        </Text>
                    </Grid>
                    <Grid item>
                        <Text variant={'body2'}>{bullet}</Text>
                    </Grid>
                </Grid>
            ))}
        </>
    );
};
