import { useRootStyles } from '../../../components/Containers';
import { useScanbodyRequestAction } from '../state/ScanbodyRequest.actions';
import {
    useScanbodyRequestSelector,
    ScanbodyRequestLinkLookupStage,
    useScanbodyRequestLinkLookupStageSelector,
} from '../state/ScanbodyRequest.selectors';
import { ScanbodyStage } from '../state/ScanbodyRequest.types';
import unknownScanbodyImageUrl from '@orthly/dentin/assets/images/UnknownScanbody.png';
import { FlossPalette, stylesFactory, useScreenIsMobile, Collapse, Grid, Button, Text } from '@orthly/ui-primitives';
import { AutocompleteImplantSystemInput } from '@orthly/veneer';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    lookupBlock: {
        alignSelf: 'flex-start',
        marginTop: 32,
        padding: 24,
        paddingTop: 32,
        borderRadius: 16,
        backgroundColor: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    backgroundAnchor: {
        height: 0,
        position: 'relative',
        '& img': {
            position: 'absolute',
        },
    },
    backgroundImage: {
        transform: 'rotate(30deg)',
        right: 0,
        top: -36,
    },
    implantSystemInput: {
        '& .MuiAutocomplete-inputRoot': {
            backgroundColor: FlossPalette.WHITE,
        },
    },
}));

export const ScanbodyRequestLookup: React.FC = () => {
    const classes = useStyles();
    const rootClasses = useRootStyles();
    const request = useScanbodyRequestSelector(s => s.request);
    const linkLookupStage = useScanbodyRequestLinkLookupStageSelector();
    const setField = useScanbodyRequestAction('SET_REQUEST_FIELD');
    const setStage = useScanbodyRequestAction('SET_STAGE');
    const resetRequest = useScanbodyRequestAction('RESET_REQUEST');
    const isMobile = useScreenIsMobile();
    return (
        <Grid container className={classes.lookupBlock}>
            <Grid
                item
                container
                justifyContent={'flex-end'}
                className={clsx(classes.backgroundAnchor, rootClasses.noMobileDisplay)}
            >
                {/* empty alt because the image is decorative, information is conveyed in labels return */}
                <img src={unknownScanbodyImageUrl} alt={''} className={classes.backgroundImage} />
            </Grid>
            <Grid item container xs={10}>
                <Text style={{ flexGrow: 1, paddingBottom: isMobile ? 0 : 32 }} variant={'h5'}>
                    Request a Scan body
                </Text>
                <Button
                    variant={'ghost'}
                    onClick={resetRequest}
                    style={{
                        color: FlossPalette.GREEN,
                        visibility: linkLookupStage === ScanbodyRequestLinkLookupStage.EMPTY ? 'hidden' : undefined,
                    }}
                    startIcon={'RefreshIcon'}
                >
                    Reset
                </Button>
            </Grid>
            <Grid item container xs={12} className={classes.implantSystemInput}>
                <AutocompleteImplantSystemInput
                    linkMeta={request}
                    updateField={(property, value) => setField({ property, value })}
                    decorativeArrow={!isMobile}
                    vertical={isMobile}
                />
            </Grid>
            <Collapse in={linkLookupStage === ScanbodyRequestLinkLookupStage.COMPLETE} style={{ width: '100%' }}>
                <Grid container justifyContent={'flex-start'}>
                    <Button
                        variant={'primary'}
                        onClick={() => setStage(ScanbodyStage.DETAIL)}
                        size={'large'}
                        style={{ width: isMobile ? '100%' : 200, marginTop: isMobile ? 16 : 0 }}
                        endIcon={'ChevronRight'}
                    >
                        Next
                    </Button>
                </Grid>
            </Collapse>
        </Grid>
    );
};
