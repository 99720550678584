import type { OnboardingPageIndex, OnboardingStep } from './OnboardingTypes';
import { Button, FlossPalette, styled } from '@orthly/ui-primitives';
import React from 'react';

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
    },
    padding: '24px 0px',
    [theme.breakpoints.down('sm')]: {
        padding: '16px',
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    justifyContent: 'space-between',
    columnGap: '32px',
    rowGap: '8px',
}));
const StyledButton = styled(Button)(({ theme }) => ({
    height: '32px',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

interface OnboardingNavigationButtonsProps {
    steps: OnboardingStep[];
    activeStepIndex: OnboardingPageIndex;
}

export const OnboardingNavigationButtons: React.VFC<OnboardingNavigationButtonsProps> = ({
    steps,
    activeStepIndex,
}) => {
    return (
        <ButtonContainer>
            <StyledButton
                variant={'secondary'}
                onClick={steps[activeStepIndex]?.previousAction}
                disabled={!steps[activeStepIndex]?.previousAction}
            >
                Previous
            </StyledButton>
            <StyledButton
                variant={'primary'}
                onClick={steps[activeStepIndex]?.saveAndContinueAction}
                disabled={steps[activeStepIndex]?.saveAndContinueActionDisabled}
            >
                Save & Continue
            </StyledButton>
        </ButtonContainer>
    );
};
