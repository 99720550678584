import { ItemsSection } from '../OrderSummary/ItemsSection';
import { mergeOrderItems } from '../OrderSummary/util/mergeOrderItems';
import { useGroupPreferences } from '../OrderSummary/util/useGroupPreferences';
import { OrderDetailBlock } from './OrderDetailBlock';
import type { ItemActionComponent } from './OrderItemsTable.types';
import type { IOrderItemV2DTO } from '@orthly/items';
import React from 'react';

export interface OrderItemsTableProps {
    items: IOrderItemV2DTO[];
    titleAction?: React.ReactNode;
    ItemAction?: ItemActionComponent;
    variant: 'left' | 'right' | 'full';
}

/**
 * @deprecated Use new OrderDetailItemsTable instead.
 */
export const OrderItemsTable: React.FC<OrderItemsTableProps> = props => {
    const { variant, titleAction, ItemAction } = props;
    const { majorityPrefValues } = useGroupPreferences(props.items);
    const mergedItems = mergeOrderItems(props.items, { majorityPrefs: majorityPrefValues });

    return (
        <OrderDetailBlock title={'Items'} variant={variant} actions={titleAction}>
            <ItemsSection target={'html'} Action={ItemAction} items={mergedItems} />
            {props.children}
        </OrderDetailBlock>
    );
};
