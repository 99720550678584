import { FlossPalette, createStyles, makeStyles, Text } from '@orthly/ui-primitives';
import FaviconBlack from '@orthly/ui/assets/logos/DandyFaviconBlack.svg';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        namePlate: {
            padding: '24px',
            background: FlossPalette.TAN,
            border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            borderRadius: 16,
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'stretch',
        },
        portrait: {
            width: 56,
            height: 56,
            borderRadius: 28,
            marginRight: 16,
        },
        logo: {
            width: 24,
            height: 24,
            alignSelf: 'flex-end',
        },
    }),
);

export interface PersonInfo {
    name: string;
    title: string;
    email?: string;
    photoSrc: string;
}

// Figma link: See "Person card" in https://www.figma.com/file/HuCVKGaat3yxkP33weyVnz/Main-System?node-id=8518%3A18197
export const FeedbackPersonCard: React.VFC<{ person: PersonInfo }> = props => {
    const classes = useStyles();
    const { person } = props;
    return (
        <div className={classes.namePlate}>
            <div style={{ display: 'flex', flexFlow: 'row', alignItems: 'center' }}>
                <img className={classes.portrait} src={person.photoSrc} alt={''} />
                <div>
                    <Text variant={'body2'} medium style={{ lineHeight: '1rem' }}>
                        {person.name}
                    </Text>
                    <Text variant={'caption'} color={'DARK_GRAY'}>
                        {person.title}
                    </Text>
                </div>
            </div>

            {person.email && (
                <div style={{ display: 'flex', flexFlow: 'row' }}>
                    <div style={{ marginTop: 16, marginRight: 16, flexGrow: 1, display: 'flex', flexFlow: 'column' }}>
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            Email address
                        </Text>
                        <Text variant={'body2'} style={{ overflowWrap: 'anywhere' }}>
                            {person.email}
                        </Text>
                    </div>
                    <img className={classes.logo} src={FaviconBlack} alt={''} />
                </div>
            )}
        </div>
    );
};
