import { useStyles } from './CheckoutSidebarConcierge.util';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

interface CheckoutSidebarConciergeCTAProps {
    text: string;
    onClick: () => void;
    buttonText: string;
    disabled: boolean;
    disabledText: string;
    blocking: boolean;
}

export const CheckoutSidebarConciergeCTA: React.VFC<CheckoutSidebarConciergeCTAProps> = props => {
    const { text, buttonText, onClick, disabled, disabledText, blocking } = props;
    const classes = useStyles();
    return (
        <Grid container className={classes.conciergeSectionRoot} alignItems={'center'}>
            <Grid item xs={8}>
                <Text variant={'body2'} color={'DARK_GRAY'}>
                    {text}
                </Text>
            </Grid>
            <Grid container item xs={4}>
                <LoadBlocker blocking={blocking} ContainerProps={{ style: { justifyContent: 'flex-end' } }}>
                    <Button
                        variant={'ghost'}
                        onClick={onClick}
                        disabled={disabled}
                        startIcon={disabled ? 'CheckCircleOutlineIcon' : undefined}
                        style={{ backgroundColor: FlossPalette.WHITE, height: 'fit-content' }}
                    >
                        {disabled ? disabledText : buttonText}
                    </Button>
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};
