import type { TryInFeedbackOptionDefinition } from './TryInFeedbackOption.types';
import { tryInFeedbackClaspPlacementOption } from './definitions/TryInFeedbackClaspPlacementOption';
import { tryInFeedbackDentureBiteOption } from './definitions/TryInFeedbackDentureBiteOption';
import { tryInFeedbackDentureMidlineOption } from './definitions/TryInFeedbackDentureMidlineOption';
import { tryInFeedbackExtensionsOption } from './definitions/TryInFeedbackExtensionOption';
import { tryInFeedbackIncorrectTeethOption } from './definitions/TryInFeedbackIncorrectTeethOption';
import { tryInFeedbackOtherOption } from './definitions/TryInFeedbackOtherOption';
import { tryInFeedbackPartialBiteOption } from './definitions/TryInFeedbackPartialBiteOption';
import { tryInFeedbackPartialMidlineOption } from './definitions/TryInFeedbackPartialMidlineOption';
import { tryInFeedbackRestsOption } from './definitions/TryInFeedbackRestsOption';
import { tryInFeedbackRetentionOption } from './definitions/TryInFeedbackRetentionOption';
import { tryInFeedbackShadeOption } from './definitions/TryInFeedbackShadeOption';
import { tryInFeedbackTeethLengthOption } from './definitions/TryInFeedbackTeethLengthOption';
import { tryInFeedbackTeethShapeOption } from './definitions/TryInFeedbackTeethShapeOption';
import { tryInFeedbackTeethSpacingOption } from './definitions/TryInFeedbackTeethSpacingOption';
import { tryInFeedbackVDOOption } from './definitions/TryInFeedbackVDOOption';
import type { LabOrderItemSKUType } from '@orthly/items';

// Options are listed in the order that they will appear.
// The type is casted due to some typescript difficulties with understanding generics
// so any time it's used, it's only safe to call functions
// after checking that the item type matches the option sku type,
// even if ts does not give an error when that check is omitted.
export const AllTryInFeedbackOptions = [
    tryInFeedbackTeethLengthOption,
    tryInFeedbackTeethShapeOption,
    tryInFeedbackTeethSpacingOption,
    tryInFeedbackDentureMidlineOption,
    tryInFeedbackPartialMidlineOption,
    tryInFeedbackIncorrectTeethOption,
    tryInFeedbackShadeOption,
    tryInFeedbackRetentionOption,
    tryInFeedbackRestsOption,
    tryInFeedbackExtensionsOption,
    tryInFeedbackVDOOption,
    tryInFeedbackDentureBiteOption,
    tryInFeedbackClaspPlacementOption,
    tryInFeedbackPartialBiteOption,
    tryInFeedbackOtherOption,
] as TryInFeedbackOptionDefinition<LabOrderItemSKUType>[];
