import { useInvoiceDetailContext } from '../components/providers/InvoiceDetailProvider.graphql';
import { PrintableInvoiceAddressContainer } from './printable-invoice/PrintableInvoiceAddressContainer';
import { PrintableInvoiceBody } from './printable-invoice/PrintableInvoiceBody';
import { PrintableInvoiceFilter } from './printable-invoice/PrintableInvoiceFilter';
import { FOOTER_HEIGHT, PrintableInvoiceFooter } from './printable-invoice/PrintableInvoiceFooter';
import { HEADER_HEIGHT, PrintableInvoiceHeader } from './printable-invoice/PrintableInvoiceHeader';
import { PrintableInvoiceOverview } from './printable-invoice/PrintableInvoiceOverview';
import { Format } from '@orthly/runtime-utils';
import { styled } from '@orthly/ui-primitives';
import { PrintableSlip, usePrintableState } from '@orthly/veneer';
import moment from 'moment';
import React from 'react';

export const PrintableHeaderSpace = styled('div')({
    height: `${HEADER_HEIGHT}px`,
});

export const PrintableFooterSpace = styled('div')({
    height: `${FOOTER_HEIGHT}px`,
});

export const PrintableInvoiceBodyContainer = styled('div')({
    padding: `0 24px`,
});

export const PrintArea = styled('div')({
    '@media screen': {
        display: 'none',
    },
    '@media print': {
        thead: { display: 'table-header-group' },
        tfoot: { display: 'table-footer-group' },
        body: { margin: 0 },
    },
});

export const formatDate = (date: string) => {
    return moment.utc(date).format('MMMM D, YYYY');
};

export const PrintableInvoice: React.FC = () => {
    const {
        invoice,
        autochargeEnabled,
        rows,
        rowsLoading,
        addressIdFilter,
        doctorNameFilter,
        billingContactInfoLoading,
        organizationName,
        billingEmail,
    } = useInvoiceDetailContext();
    const { closePrintable } = usePrintableState();
    const invoiceAmountDueText = `${Format.currency(invoice.amount_due)} due ${formatDate(invoice.due_date)}`;
    const hasFilter = !!addressIdFilter || !!doctorNameFilter;

    return (
        <PrintableSlip
            openPrintWindow={!rowsLoading && !billingContactInfoLoading}
            onPrintComplete={() => {
                document.title = `Dandy Practice App`;
                closePrintable();
            }}
        >
            <PrintArea>
                <PrintableInvoiceHeader />
                <PrintableInvoiceFooter invoiceFooterText={`${invoice.invoice_number} - ${invoiceAmountDueText}`} />
                <table>
                    <thead>
                        <tr>
                            <td>
                                <PrintableHeaderSpace />
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <PrintableInvoiceBodyContainer>
                                    {hasFilter && <PrintableInvoiceFilter />}
                                    <PrintableInvoiceOverview invoice={invoice} autochargeEnabled={autochargeEnabled} />

                                    <PrintableInvoiceAddressContainer
                                        organizationName={organizationName}
                                        billingEmail={billingEmail}
                                    />

                                    <PrintableInvoiceBody invoice={invoice} rows={rows} rowsLoading={rowsLoading} />
                                </PrintableInvoiceBodyContainer>
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <PrintableFooterSpace />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </PrintArea>
        </PrintableSlip>
    );
};
