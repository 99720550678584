import { useSendOneTimeCode } from '../../../../../../graphql/useSendOneTimeCode.graphql';
import { useUserExistsWithMobileNumber } from '../../../../../../graphql/useUserExistsWithMobileNumber.graphql';
import { LabeledPhoneField, LabeledTextField } from '../../../../components/labeled-field/LabeledFields';
import { useSetLoginPref } from '../../useLoginPref';
import { LoginForm } from '../components/LoginForm';
import { LoginTooltip } from '../components/LoginTooltip';
import { SwitchLoginPref } from '../components/SwitchLoginPref';
import { usePhoneLoginValidation } from './usePhoneLoginValidation';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import { FormHelperText, Link, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const ResendLink = styled(Link)({
    cursor: 'pointer',
});

const NoWrap = styled('span')({
    whiteSpace: 'nowrap',
});

export const PhoneLogin: React.FC = () => {
    const setLoginPref = useSetLoginPref();
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [confirmationCode, setConfirmationCode] = React.useState('');
    const [submittedPhoneNumber, setSubmittedPhoneNumber] = React.useState('');
    const [userExistsError, setUserExistsError] = React.useState('');

    const {
        isFormValid,
        phoneNumberError,
        confirmationCodeError,
        setShowConfirmationCodeError,
        setShowPhoneNumberError,
    } = usePhoneLoginValidation({
        phoneNumber,
        confirmationCode,
        submittedPhoneNumber,
    });

    const hideConfirmation = () => {
        setShowConfirmationCodeError(false);
        setConfirmationCode('');
        setSubmittedPhoneNumber('');
    };

    const {
        submit: sendCode,
        submitting: submittingSendCode,
        error: sendCodeError,
    } = useSendOneTimeCode({
        onSuccess: () => {
            setSubmittedPhoneNumber(phoneNumber);
        },
    });

    const {
        submit: checkUserExists,
        loading: checkingUserExists,
        error: checkUserExistsError,
    } = useUserExistsWithMobileNumber({
        onSuccess: userExists => {
            if (userExists) {
                void sendCode(phoneNumber);
                setUserExistsError('');
            } else {
                setUserExistsError('User not found');
            }
        },
    });

    const { onSubmitOneTimeCodeLogin, oneTimeCodeLoginLoading, oneTimeCodeLoginError, loginError } =
        useStaffMemberLoginProps();

    const shouldContinue = !oneTimeCodeLoginLoading && !submittingSendCode && !checkingUserExists && isFormValid;
    const formError = loginError || oneTimeCodeLoginError || sendCodeError || checkUserExistsError || userExistsError;

    return (
        <LoginForm
            onSubmit={e => {
                e.preventDefault();

                if (submittedPhoneNumber) {
                    onSubmitOneTimeCodeLogin({ mobile_phone_number: phoneNumber, code: confirmationCode });
                } else {
                    void checkUserExists(phoneNumber);
                }
            }}
            shouldContinue={shouldContinue}
        >
            <LabeledPhoneField
                isRequired
                label={'Phone number'}
                placeholder={'Enter your phone number'}
                value={phoneNumber}
                onChange={(next = '') => {
                    setPhoneNumber(next);
                    hideConfirmation();
                }}
                TextFieldProps={{
                    type: 'tel',
                    autoFocus: true,
                    error: !!phoneNumberError,
                    helperText: phoneNumberError,
                }}
                onBlur={() => {
                    setShowPhoneNumberError(phoneNumber !== '+1');
                }}
                disabled={submittingSendCode}
                action={<LoginTooltip />}
                footer={<SwitchLoginPref message={'Use email address instead'} onClick={() => setLoginPref('email')} />}
            />
            {submittedPhoneNumber && (
                <LabeledTextField
                    isRequired
                    label={'Confirmation'}
                    placeholder={'Enter confirmation code'}
                    value={confirmationCode}
                    onChange={(next = '') => {
                        setConfirmationCode(next.slice(0, 6));
                    }}
                    TextFieldProps={{
                        type: 'text',
                        onBlur: () => {
                            setShowConfirmationCodeError(confirmationCode !== '');
                        },
                        error: !!confirmationCodeError,
                        helperText: confirmationCodeError,
                    }}
                    footer={
                        <Text variant={'body2'} color={'GRAY'}>
                            {
                                <>
                                    {`We've sent a code to `}
                                    <NoWrap>{`${PhoneNumberUtils.prettyPhoneNumber(submittedPhoneNumber)}. `}</NoWrap>
                                    {`You can request another code in 30 seconds. `}
                                </>
                            }
                            <ResendLink
                                onClick={() => {
                                    void sendCode(submittedPhoneNumber);
                                }}
                            >
                                <Text bold color={'PRIMARY_FOREGROUND'} component={'span'} variant={'body2'}>
                                    Resend
                                </Text>
                            </ResendLink>
                        </Text>
                    }
                />
            )}
            {formError && <FormHelperText error={true}>{formError}</FormHelperText>}
        </LoginForm>
    );
};
