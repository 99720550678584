import type { TraineeFormFieldProps } from '../TrainingDataIntake.types';
import { RetractionPreferenceOptions } from '../TrainingDataIntake.types';
import { useTrainee } from '../hooks/useTrainee';
import { Text, CheckboxesGroup } from '@orthly/ui-primitives';
import React from 'react';

export const DentistRetractionPreferenceField: React.VFC<TraineeFormFieldProps> = props => {
    const { trainee, setTrainee } = useTrainee(props.traineeAtom);

    return (
        <CheckboxesGroup
            hasColumns
            variant={'compact'}
            values={Object.fromEntries(
                RetractionPreferenceOptions.map(retraction => [
                    retraction,
                    trainee.retractions?.includes(retraction) ?? false,
                ]),
            )}
            options={RetractionPreferenceOptions.map(retraction => ({
                id: retraction,
                label: (
                    <Text variant={'body2'} style={{ whiteSpace: 'nowrap' }}>
                        {retraction}
                    </Text>
                ),
                value: retraction,
            }))}
            handleChange={value => {
                const hasRetraction = trainee.retractions?.includes(value);
                const updatedRetractions = hasRetraction
                    ? trainee.retractions?.filter(retraction => retraction !== value) ?? []
                    : trainee.retractions?.concat(value) ?? [value];

                setTrainee(oldValue => ({
                    ...oldValue,
                    retractions: updatedRetractions,
                }));
            }}
        />
    );
};
