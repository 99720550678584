import { useScanIsAligner } from '../../../../state/checkout.selectors';
import { useScanIsDenture } from '../../../../state/dentures-checkout.selectors';
import { ConciergeMarketingMaterialsCTA } from './CheckoutMarketingMaterialsCTA';
import { ConciergeScheduleTrainingCTA } from './CheckoutScheduleTrainingCTA';
import type { ConciergeConfigInterface, ConciergeSkuType } from './CheckoutSidebarConcierge.util';
import { useStyles } from './CheckoutSidebarConcierge.util';
import { useGetPracticeRequestsQuery } from '@orthly/graphql-react';
import { LabsGqlPracticeRequestType, LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { Grid, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const alignerConciergeConfig: ConciergeConfigInterface = {
    useCheckIfSkuActive: useScanIsAligner,
    sku: LabsGqlOrderItemSkuType.Aligners,
    assetLocation: 'Aligner order flow',
    training: {
        ctaLineText: 'Would you like additional Aligners workflow training and guidance?',
        ctaButtonText: 'Schedule',
        ctaDisabledText: 'Scheduled',
        calendlyUrl: 'https://calendly.com/d/dzr-hft-66r/aligners-overview',
    },
    marketing: {
        ctaLineText: 'Need Dandy Aligners marketing materials for your office?',
        ctaButtonText: 'Request',
        ctaDisabledText: 'Requested',
        dialog: {
            title: 'Dandy Aligners marketing materials request',
            subtitle: 'Materials include a sample of our dazzling premium packaging',
            doctorFieldLabel: 'Who is requesting the materials?',
            addressFieldLabel: 'Where should we ship the materials?',
        },
    },
};

const dentureConciergeConfig: ConciergeConfigInterface = {
    useCheckIfSkuActive: useScanIsDenture,
    sku: LabsGqlOrderItemSkuType.Denture,
    assetLocation: 'Denture order flow',
    training: {
        ctaLineText: 'Still have workflow questions? Schedule a call with a denture expert!',
        ctaButtonText: 'Schedule',
        ctaDisabledText: 'Scheduled',
        calendlyUrl: 'https://calendly.com/d/cj4-z8p-99r/talk-to-a-dandy-digital-denture-expert?month=2022-01',
    },
};

const aestheticCrownConciergeConfig: ConciergeConfigInterface = {
    useCheckIfSkuActive: () => false, // Hide temporarily; ref: DND-4282
    sku: LabsGqlOrderItemSkuType.Crown,
    assetLocation: 'Dandy Concierge',
    training: {
        ctaLineText: 'Would you like to further discuss any case nuances with a lab technician? Please schedule a call',
        ctaButtonText: 'Schedule',
        ctaDisabledText: 'Scheduled',
        calendlyUrl: 'https://meetdandy.chilipiper.com/book/me/ronny-yeagy',
    },
};

const conciergeConfigMap: { [sku in ConciergeSkuType]: ConciergeConfigInterface } = {
    [LabsGqlOrderItemSkuType.Aligners]: alignerConciergeConfig,
    [LabsGqlOrderItemSkuType.Denture]: dentureConciergeConfig,
    [LabsGqlOrderItemSkuType.Crown]: aestheticCrownConciergeConfig,
};

const CheckoutSidebarConciergeCTASection: React.FC<{ config: ConciergeConfigInterface; partnerId: string }> = props => {
    const { config, partnerId } = props;
    const { data, refetch, loading } = useGetPracticeRequestsQuery({
        variables: {
            sku: config.sku,
            organization_id: partnerId,
            types: [LabsGqlPracticeRequestType.ScheduleTraining, LabsGqlPracticeRequestType.MarketingMaterials],
        },
    });
    const trainingScheduled = !!data?.getPracticeRequests.find(
        pr => pr.metadata.type === LabsGqlPracticeRequestType.ScheduleTraining,
    );
    const [materialsRequested, setMaterialsRequested] = React.useState(false);

    return (
        <>
            {config.training && (
                <ConciergeScheduleTrainingCTA
                    config={config}
                    partnerId={partnerId}
                    trainingScheduled={trainingScheduled}
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    refetch={() => refetch()}
                    queryLoading={loading}
                />
            )}
            {config.marketing && (
                <ConciergeMarketingMaterialsCTA
                    config={config}
                    partnerId={partnerId}
                    materialsRequested={materialsRequested}
                    setMaterialsRequested={setMaterialsRequested}
                />
            )}
        </>
    );
};

interface CheckoutSidebarConciergeProps {
    config: ConciergeConfigInterface;
    partnerId: string;
}

export const useConciergeData = (): CheckoutSidebarConciergeProps | null => {
    const activeConfigList = _.values(conciergeConfigMap).filter(config => config.useCheckIfSkuActive());
    const partnerId = useSession()?.organization_id;
    if (activeConfigList.length >= 2) {
        throw new Error('Error: More than one active config found for dandy concierge.');
    }
    const config = activeConfigList[0];
    if (!(config && partnerId)) {
        return null;
    }
    return { config, partnerId };
};

export const CheckoutSidebarConcierge: React.VFC<CheckoutSidebarConciergeProps> = ({ config, partnerId }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.conciergeContainer}>
            <Grid
                container
                className={classes.conciergeSectionRoot}
                style={{ paddingBottom: 8, height: 'fit-content' }}
            >
                <Text variant={'h6'} style={{ height: 'fit-content' }}>
                    Your Dandy Concierge
                </Text>
            </Grid>
            <CheckoutSidebarConciergeCTASection config={config} partnerId={partnerId} />
        </Grid>
    );
};
