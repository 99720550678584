import type { PrintableInvoiceBodyProps } from './PrintableInvoiceBody';
import { LabsGqlInvoiceItemCategory } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, OrthlyBrowserConfig } from '@orthly/ui';
import { ArrowEastIcon, FlossPalette, Link, styled } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const Cell = styled('div')({
    color: FlossPalette.BLACK,
    fontSize: '10px',
    fontWeight: 600,
    '&:last-child': {
        textAlign: 'right',
    },
});

const gridStyles = {
    width: '100%',
    display: 'grid',
    gridGap: '0px 16px',
    gridTemplateColumns: '1fr 4fr 2fr 68px 1fr',
    gridTemplateRows: 'max-content',
};

const GridHeaderRow = styled('div')({
    ...gridStyles,
    height: '24px',
    borderBottom: `1px solid ${FlossPalette.GRAY}`,
});

const GridRow = styled('div')({
    ...gridStyles,
    borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    padding: '8px 0px',
    pageBreakInside: 'avoid',
});

const LinkContainer = styled('div')({
    color: FlossPalette.PRIMARY_FOREGROUND,
    fontSize: '10px',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'baseline',
});

const MultiLineContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    fontSize: '10px',
    color: FlossPalette.BLACK,
});

const MultiLineBold = styled('span', { shouldForwardProp: p => p !== 'isCredit' })<{ isCredit: boolean }>(
    ({ isCredit }) => ({
        fontWeight: 600,
        color: isCredit ? FlossPalette.GREEN : FlossPalette.BLACK,
    }),
);

const MultiLine = styled('span')({
    fontWeight: 400,
});

const MultiLineCell: React.FC<{ lines: string[]; isCredit: boolean }> = ({ lines, isCredit }) => (
    <MultiLineContainer>
        {lines.map((desc, idx) => {
            return idx === 0 ? (
                <MultiLineBold key={idx} isCredit={isCredit}>
                    {desc}
                </MultiLineBold>
            ) : (
                <MultiLine key={idx}>{desc}</MultiLine>
            );
        })}
    </MultiLineContainer>
);

export const PrintableInvoiceTable: React.FC<PrintableInvoiceBodyProps> = ({ invoice, rows, rowsLoading }) => {
    const organizationId = useSession()?.organization_id;
    const canViewOrder = invoice.organization_id === organizationId;
    return (
        <Container>
            <LoadBlocker blocking={rowsLoading}>
                <GridHeaderRow>
                    <Cell>Date</Cell>
                    <Cell>Description</Cell>
                    <Cell>Doctor</Cell>
                    <Cell> </Cell>
                    <Cell>Amount</Cell>
                </GridHeaderRow>

                {rows.map(row => {
                    const showLink = row.order_id && !row.recurring_item_id && canViewOrder;
                    return (
                        <GridRow key={row.id}>
                            <Cell color={'BLACK'}>{dayjs(row.effective_date).format('MM/DD/YY')}</Cell>
                            <Cell color={'BLACK'}>
                                <MultiLineCell lines={row.descriptions} isCredit={!!row.used_credit_id} />
                            </Cell>
                            <Cell color={'BLACK'}>
                                {row.category === LabsGqlInvoiceItemCategory.OtherCharge && !!row.recurring_item_id
                                    ? 'Dandy Internal'
                                    : row.doctor_name}
                            </Cell>
                            <Cell color={'BLACK'}>
                                {showLink && (
                                    <Link
                                        href={`${OrthlyBrowserConfig.practicePortalUrl}/orders/${row.order_id}`}
                                        color={'primary'}
                                    >
                                        <LinkContainer>
                                            Open order
                                            <ArrowEastIcon fontSize={'inherit'} />
                                        </LinkContainer>
                                    </Link>
                                )}
                            </Cell>
                            <Cell color={'BLACK'}>
                                <MultiLineCell lines={row.display_amounts} isCredit={!!row.used_credit_id} />
                            </Cell>
                        </GridRow>
                    );
                })}
            </LoadBlocker>
        </Container>
    );
};
