import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { ChatWindowContent } from '../../../screens/chat/ChatWindow/ChatWindowContent';
import { useCSTAction } from '../../../screens/chat/cst-state/cst.actions';
import { useCSTSelector } from '../../../screens/chat/cst-state/cst.selectors';
import { MiniWindowCore, MiniWindowVariant } from '@orthly/ui';
import { stylesFactory, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    window: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        backgroundColor: FlossPalette.TAN,
    },
}));

export const MobileChatRoot: React.VFC = () => {
    const classes = useStyles();
    const {
        windowState: { variant, pendingAnimation },
    } = useCSTSelector(cstState => cstState);
    const setVariant = useCSTAction('SET_WINDOW_VARIANT');
    const setPendingAnimation = useCSTAction('SET_WINDOW_PENDING_ANIMATION');

    React.useEffect(() => {
        setVariant(MiniWindowVariant.Maximized, 'none');
    }, [setVariant]);

    return (
        <MobilePageLayout title={'CST'} hideHeader>
            <MiniWindowCore
                variant={variant}
                setVariant={() => {}}
                pendingAnimation={pendingAnimation}
                setPendingAnimation={setPendingAnimation}
                className={classes.window}
            >
                <ChatWindowContent />
            </MiniWindowCore>
        </MobilePageLayout>
    );
};
