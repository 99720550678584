import type { ChangeItemTypeUnitType, CheckoutItemChange } from './checkout.actions';
import type { CheckoutItemV2 } from './checkout.state';
import type { LabsGqlScanFileCheckoutFragment } from '@orthly/graphql-operations';
import type {
    ExtraCartItemV2UnitType,
    ICartImplantBridgeItem,
    ICartImplantItem,
    ICartItemV2DTO,
    ICartUnknownItem,
    ItemMetafieldV2,
} from '@orthly/items';
import {
    CartItemV2Utils,
    CartItemV2UpdateUtils,
    ExtraCartItemV2Utils,
    ItemMaterialFieldV2Utils,
    ItemMetafieldV2Utils,
    ITEM_MATERIAL_ABUTMENT_LABEL,
    LabOrderItemSKUType,
    ItemDataFieldUtils,
} from '@orthly/items';
import _ from 'lodash';

const UNKNOWN_MATERIAL_TYPE = 'N/A';

const NO_BULK_EDIT_SKUS: readonly LabOrderItemSKUType[] = [
    LabOrderItemSKUType.Bridge,
    // The following implant preferences depend on tooth #. Since they can
    // have different defaults, bulk editing would require special handling.
    // 1. posterior-insufficient-space-preference-order-item-meta
    // 2. insufficient-clearance-preference-order-item-meta
    LabOrderItemSKUType.Implant,
    LabOrderItemSKUType.ImplantBridge,
];

// metafields that are intentionally ingored because they're handled with dedicated components
const IGNORED_METAFIELD_IDS: readonly string[] = [
    'surgical-guide-tooth-numbers-order-item-meta',
    'surgical-guide-implant-manufacturer-order-item-meta',
    'surgical-guide-implant-system-order-item-meta',
    'surgical-guide-kit-order-item-meta',
    'surgical-guide-implant-length-order-item-meta',
    'partial-teeth-selection-order-item-meta',
    'smile-style-order-item-meta',
];

export class CheckoutItemV2Manager {
    static applyUpdate(item: CheckoutItemV2, update: CheckoutItemChange): CheckoutItemV2 {
        // if this change type is used outside implant checkout it may cause necessary fields to be missed
        if (update.name === 'clear_original_material') {
            return { ...item, original_material: undefined };
        }

        return {
            ...CartItemV2UpdateUtils.updateItem(item, update),
            ..._.pick(item, ['item_index', 'bulk_edit_id', 'from_scan_export', 'original_material']),
        };
    }

    // when changing the sku type we want to get rid of any selections for the previous item
    static changeSKUType(item: CheckoutItemV2, unit_type: ChangeItemTypeUnitType): CheckoutItemV2 {
        const baseProps = _.pick(item, ['id', 'item_index', 'from_scan_export', 'original_material']);
        const partialUnitItem =
            unit_type === 'Unknown'
                ? // needs to know it's not just any LabOrderItemSKUType so we safely cast
                  ({ sku: LabOrderItemSKUType.Unknown } as Pick<ICartUnknownItem, 'sku'>)
                : ExtraCartItemV2Utils.partialItemFromUnitType(unit_type);

        const partialItem = {
            ...baseProps,
            ...partialUnitItem,
            preference_fields: [],
            shades: [],
            attachments: [],
        };

        return {
            ...partialItem,
            bulk_edit_id: CheckoutItemV2Manager.bulkEditItemId(partialItem),
        };
    }

    // when changing the sku type we want to get rid of any selections for the previous item
    static buildNewItem(unit_type: ExtraCartItemV2UnitType, item_index: number): CheckoutItemV2 {
        const partialItem = {
            ...ExtraCartItemV2Utils.partialItemFromUnitType(unit_type),
            item_index,
            from_scan_export: false,
            preference_fields: [],
            shades: [],
            attachments: [],
        };

        return {
            ...partialItem,
            bulk_edit_id: CheckoutItemV2Manager.bulkEditItemId(partialItem),
        };
    }

    private static prepareImplantItem(
        item: ICartImplantItem | ICartImplantBridgeItem,
    ): ICartImplantItem | ICartImplantBridgeItem {
        // we intentionally set scanbody_id to be undefined
        // because implant checkout treats scanbody_id as:
        // undefined -> not selected
        // null -> user selected other/no scanbody used

        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant)) {
            return {
                ...item,
                unit: {
                    ...item.unit,
                    implant_metadata: {
                        ...item.unit.implant_metadata,
                        scanbody_id: undefined,
                    },
                },
            };
        }

        // implant bridge
        return {
            ...item,
            implants: item.implants.map(implant => ({
                ...implant,
                implant_metadata: {
                    ...implant.implant_metadata,
                    scanbody_id: undefined,
                },
            })),
        };
    }

    private static prepareCartItem(item: ICartItemV2DTO): ICartItemV2DTO {
        if (CartItemV2Utils.itemTypeHasImplant(item)) {
            return CheckoutItemV2Manager.prepareImplantItem(item);
        }
        return item;
    }

    static cartItemsToCheckoutItems(items: LabsGqlScanFileCheckoutFragment['cart_items_v2']): CheckoutItemV2[] {
        return items.map((item, idx) => {
            const baseItem = CartItemV2Utils.parseItem(item);

            const partialItem = {
                ...baseItem,
                item_index: idx,
                from_scan_export: true,
            };

            // we only need to know the crown material for implants since
            // implant items coming from a scan will have to select an abutment material regardless
            const original_material = ItemMaterialFieldV2Utils.getSingleMaterial(partialItem, 'crown');

            return {
                ...partialItem,
                original_material,
                bulk_edit_id: CheckoutItemV2Manager.bulkEditItemId({ ...partialItem, original_material }),
            };
        });
    }

    static bulkEditItemId(item: Omit<CheckoutItemV2, 'bulk_edit_id'>): string {
        // ensure group items dont get bulk edited
        if (NO_BULK_EDIT_SKUS.includes(item.sku)) {
            return `${item.item_index}`;
        }
        // we have to cast since the type system doesn't understand the union given the Omit above
        const unitType = CartItemV2Utils.getPrimaryUnitType(item as ICartItemV2DTO);
        return `${unitType}-${item.original_material ? item.original_material : ''}`;
    }

    static getBulkGroups(items: CheckoutItemV2[]) {
        return _.groupBy(items, t => t.bulk_edit_id);
    }

    static getInitialBulkEditIds(items: CheckoutItemV2[]): string[] {
        const byBulkId = CheckoutItemV2Manager.getBulkGroups(items);
        return Object.keys(byBulkId).filter(bulkId => (byBulkId[bulkId] ?? []).length > 1);
    }

    static sortItems(items: CheckoutItemV2[]): CheckoutItemV2[] {
        return _.sortBy(items, t => t.item_index);
    }

    static getFormattedTitle(item: CheckoutItemV2): string {
        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Implant)) {
            return item.unit.implant_metadata.relationship
                ? `Implant - ${_.startCase(item.unit.implant_metadata.relationship)}`
                : 'Implant';
        }
        const name = CartItemV2Utils.getDisplayName(item);
        const materialRaw = CartItemV2Utils.getItemDisplayMaterial(item);
        const material = materialRaw && materialRaw.toUpperCase() !== UNKNOWN_MATERIAL_TYPE ? materialRaw : undefined;
        return material ? `${name}, ${material}` : name;
    }

    static getMetafields(item: CheckoutItemV2): ItemMetafieldV2[] {
        const metafields = ItemMetafieldV2Utils.getMetafieldsForItemExcludingDataFields(item, item.original_material);
        return metafields.filter(field => !IGNORED_METAFIELD_IDS.includes(field.id));
    }

    static fieldsRemaining(item: CheckoutItemV2): number {
        const unitTypeOK = !CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Unknown);
        const materialFieldsRemaining = CheckoutItemV2Manager.materialFieldsRemaining(item);
        const surgicalGuideImplantMetadataFieldsRemaining =
            CheckoutItemV2Manager.surgicalGuideImplantMetadataFieldsRemaining(item);
        const dataFieldsRemaining = ItemDataFieldUtils.countFieldsRemaining(item);
        const metafieldsRemaining = CheckoutItemV2Manager.metafieldsRemaining(item);
        const shadesDone = CheckoutItemV2Manager.shadesSelected(item);
        const multiToothSelectDone = CheckoutItemV2Manager.isMultiToothSelectComplete(item);

        return (
            (unitTypeOK ? 0 : 1) +
            materialFieldsRemaining +
            surgicalGuideImplantMetadataFieldsRemaining +
            dataFieldsRemaining +
            metafieldsRemaining +
            (shadesDone ? 0 : 1) +
            (multiToothSelectDone ? 0 : 1)
        );
    }

    static metafieldsRemaining(item: CheckoutItemV2): number {
        const metafields = CheckoutItemV2Manager.getMetafields(item);

        return metafields.reduce((sum, field) => {
            if (field.optional) {
                return sum;
            }
            const matchingSubmission = item.preference_fields.find(
                pf => pf.field_id === field.id && !ItemMetafieldV2Utils.isDeprecatedSelectField(field, pf.value),
            );
            const isIncomplete =
                !matchingSubmission ||
                matchingSubmission.value === `` ||
                field.validator?.(matchingSubmission.value)?.error;
            return sum + (isIncomplete ? 1 : 0);
        }, 0);
    }

    static materialFieldsRemaining(item: CheckoutItemV2): number {
        const selectFields = ItemMaterialFieldV2Utils.getMaterialFieldsForItem(item, item.original_material);

        return selectFields.reduce((sum, field) => {
            const implantProperty = field.label === ITEM_MATERIAL_ABUTMENT_LABEL ? 'abutment' : 'crown';
            const currentMaterial = ItemMaterialFieldV2Utils.getSingleMaterial(item, implantProperty);
            const isComplete = !!currentMaterial && field.options.map<string>(t => t.value).includes(currentMaterial);

            return isComplete ? sum : sum + 1;
        }, 0);
    }

    static surgicalGuideImplantMetadataFieldsRemaining(item: CheckoutItemV2): number {
        if (!CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.SurgicalGuide)) {
            return 0;
        }
        const implantMetadata = item.unit.implant_metadata;
        return (
            (implantMetadata.manufacturer ? 0 : 1) +
            (implantMetadata.system ? 0 : 1) +
            (implantMetadata.drill_kit ? 0 : 1)
        );
    }

    static materialSelected(item: CheckoutItemV2): boolean {
        return CheckoutItemV2Manager.materialFieldsRemaining(item) === 0;
    }

    static implantMetadataFieldsComplete(item: CheckoutItemV2): boolean {
        if (CartItemV2Utils.itemTypeHasImplant(item)) {
            const metadata = CartItemV2Utils.getImplantMetadata(item);
            return !!metadata && CartItemV2Utils.isImplantMetadataComplete(metadata);
        }

        return true;
    }

    static shadesSelected(item: CheckoutItemV2): boolean {
        return !CartItemV2Utils.itemRequiresShade(item) || !!item.shades.find(s => s.name === 'base')?.value;
    }

    // the long name is to differentiate from an item that just has a sku of Other
    // this is an item with a sku of 'Other' and a `unit_type` of 'Other'
    // which happens when a user selects the "Other (specify in notes)" option for an unknown sku
    static isUnspecifiedOtherItem(item: CheckoutItemV2): boolean {
        return CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Other) && item.unit.unit_type === 'Other';
    }

    static isMultiToothSelectItem(
        item: CheckoutItemV2,
    ): item is Extract<CheckoutItemV2, { sku: LabOrderItemSKUType.Partial }> {
        return CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial);
    }

    static isMultiToothSelectComplete(item: CheckoutItemV2): boolean {
        if (!CheckoutItemV2Manager.isMultiToothSelectItem(item)) {
            return true;
        }
        return item.unit.unns.length > 0;
    }
}
