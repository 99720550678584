import { ConfirmStopSharingModal } from './ConfirmStopSharingModal.graphql';
import { EditForwardingSettingsModal } from './EditForwardingSettingsModal.graphql';
import type { ManageForwardingSettingsProps } from './ForwardingSettingsBase';
import { ForwardingSettingsBase, FormatNames } from './ForwardingSettingsBase';
import { useForwardingStyles } from './styles';
import { ForwardingType } from './types';
import { DEFAULT_TOAST_TIMEOUT, Toast } from '@orthly/dentin';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { FlossPalette, stylesFactory, Grid, RedoIcon } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    forwardingInfoButton: {
        width: 'fit-content',
        padding: '12px 16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'center',
        },
    },
}));

export const ManageForwardingSettings: React.FC<
    ManageForwardingSettingsProps & { prefsToShareWith: LabsGqlDoctorPreferencesFragment[] }
> = props => {
    const { sendersOrReceivers: receivers, employeePrefs, notificationName, prefsToShareWith, refetch } = props;
    const classes = useStyles();
    const forwardingClasses = useForwardingStyles();
    const [openEditModal, setOpenEditModal] = React.useState(false);
    const [openStopSharingModal, setOpenStopSharingModal] = React.useState(false);
    const [showStartSharingSuccess, setShowStartSharingSuccess] = React.useState(false);
    const [showStopSharingSuccess, setShowStopSharingSuccess] = React.useState(false);
    const forwardingModal = (
        <EditForwardingSettingsModal
            open={openEditModal}
            setOpen={setOpenEditModal}
            employeePrefs={employeePrefs}
            sendersOrReceivers={receivers}
            notificationName={notificationName}
            prefsToShareWith={prefsToShareWith}
            setShowSuccess={setShowStartSharingSuccess}
            refetch={refetch}
        />
    );

    return (
        <>
            {receivers.length > 0 ? (
                <ForwardingSettingsBase
                    forwardingInfoText={
                        <>
                            You are sharing your notifications with <FormatNames sendersOrReceivers={receivers} />
                        </>
                    }
                    forwardingSettingsModal={
                        <Grid item style={{ display: 'flex' }}>
                            {forwardingModal}
                            <ConfirmStopSharingModal
                                open={openStopSharingModal}
                                setOpen={setOpenStopSharingModal}
                                employeePrefs={employeePrefs}
                                notificationName={notificationName}
                                setShowSuccess={setShowStopSharingSuccess}
                                refetch={refetch}
                            />
                        </Grid>
                    }
                    forwardingType={ForwardingType.forwarding}
                />
            ) : (
                <Grid container className={clsx(forwardingClasses.forwardingInfo, classes.forwardingInfoButton)}>
                    <Grid item style={{ display: 'flex' }}>
                        <RedoIcon
                            className={forwardingClasses.forwardingIcon}
                            style={{ color: FlossPalette.PRIMARY_FOREGROUND }}
                        />
                        {forwardingModal}
                    </Grid>
                </Grid>
            )}
            <Toast
                headline={'Notification forward removed'}
                description={`You are not forwarding your ${notificationName} notifications any longer.`}
                open={showStopSharingSuccess}
                onDismiss={() => setShowStopSharingSuccess(false)}
                variant={'success'}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
            <Toast
                headline={'Notification recipients saved'}
                description={`Your ${notificationName} notifications are now being forwarded.`}
                open={showStartSharingSuccess}
                onDismiss={() => setShowStartSharingSuccess(false)}
                variant={'success'}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
        </>
    );
};
