import { styled, Text } from '@orthly/ui-primitives';

export const BoldText = styled(Text)({
    fontWeight: 'bold',
});

export const MediumText = Text;

export const SemiBoldText = BoldText;

export const LightText = styled(Text)({
    fontWeight: 'lighter',
});
