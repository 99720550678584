import { usePricingGuide } from '../../PricingGuide.context';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const CrownBridgeAddOnsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();
    const rows = React.useMemo(
        () => [
            {
                id: 'porcelain butt margin',
                type: 'Porcelain Butt Margin (per crown)',
                price: getPrice('Porcelain Butt margin'),
            },
            {
                id: 'partial rests',
                type: 'Partial Rests/Crown Under Partial',
                price: getPrice('Partial Rests/Crown Under Partial'),
            },
            {
                id: 'temp crown',
                type: 'Temporary Crown (per crown)',
                price: getPrice('Temps'),
            },
            {
                id: 'physical wax-up',
                type: 'Physical Wax-Up',
                price: getPrice('Physical Waxup'),
            },
            {
                id: 'reduction coping',
                type: 'Reduction Coping (per crown)',
                price: getPrice('Reduction Coping'),
            },
            {
                id: 'model single quad',
                type: 'Model: Single Arch Quadrant',
                price: getPrice('Model - Single Quadrant'),
            },
            {
                id: 'model dual quad',
                type: 'Model: Dual Arch Quadrant Articulated',
                price: getPrice('Model - Dual Quadrant'),
            },
            {
                id: 'model dual full',
                type: 'Model: Dual Full Arch Articulated',
                price: getPrice('Model - Dual Full'),
            },
            {
                id: 'pink porcelain',
                type: 'Pink Porcelain',
                price: getPrice('Pink Porcelain'),
            },
            {
                id: 'putty matrix',
                type: 'Matrix',
                price: getPrice('Matrix'),
            },
        ],
        [getPrice],
    );

    return (
        <PricingGuideTable
            id={'crown-bridge-add-ons'}
            title={'Add-ons and modifications'}
            columns={[
                {
                    id: 'type',
                    title: 'Type',
                },
                {
                    id: 'price',
                    title: 'Price',
                    render: ({ price }) => formatPrice(price),
                },
            ]}
            rows={rows}
        />
    );
};
