import type {
    ActionProps,
    NotificationActionOptions,
    UpdateNotificationDataWrapper,
    UrgentNotificationDataWrapper,
} from './DandyNotifications.types';
import { NotificationActionType } from './DandyNotifications.types';
import { DandyUpdateNotifications } from './DandyUpdateNotifications';
import { DandyUrgentNotifications } from './DandyUrgentNotifications';
import { EmptyStateText } from './EmptyStateText';
import { TabWithCount, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, stylesFactory, Tab, Tabs, Icon, CheckIcon } from '@orthly/ui-primitives';
import React from 'react';

export enum NotificationTabs {
    URGENT = 'urgent',
    UPDATES = 'updates',
}

const TAB_HEIGHT = '40px';

const useStyles = stylesFactory(theme => ({
    tabs: {
        minHeight: TAB_HEIGHT,
        height: TAB_HEIGHT,
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    tab: {
        minHeight: TAB_HEIGHT,
        height: TAB_HEIGHT,
        color: FlossPalette.GREEN,
        fontWeight: 600,
    },
}));

interface DandyNotificationsTabsProps {
    setOpen: (value: boolean) => void;
    sortedUrgentNotifications: UrgentNotificationDataWrapper[];
    urgentNotificationsCount: number;
    sortedUpdateNotifications: UpdateNotificationDataWrapper[];
    updateNotificationsCount: number;
    inboxRefetch: () => Promise<void>;
    notificationActionOptions: NotificationActionOptions;
}

export const DandyNotificationsTabs: React.FC<DandyNotificationsTabsProps> = props => {
    const {
        sortedUrgentNotifications,
        sortedUpdateNotifications,
        urgentNotificationsCount,
        updateNotificationsCount,
        setOpen,
        inboxRefetch,
        notificationActionOptions: { markAsRead, navigationAction },
    } = props;

    const classes = useStyles();
    const [tab, setTab] = React.useState<NotificationTabs>(NotificationTabs.URGENT);

    React.useEffect(() => {
        if (updateNotificationsCount > 0 && urgentNotificationsCount === 0) {
            setTab(NotificationTabs.UPDATES);
        }
    }, [urgentNotificationsCount, updateNotificationsCount]);

    const { submit: markRead } = useChangeSubmissionFn<any, number[]>(markAsRead, {
        closeOnComplete: true,
        successMessage: () => ['Event marked read!', {}],
        onSuccess: () => void inboxRefetch(),
    });

    const handleClick = (action: ActionProps) => {
        setOpen(false);

        switch (action.type) {
            case NotificationActionType.REDIRECT:
                navigationAction(action.url);
                break;
            case NotificationActionType.FUNCTION:
                action.onClick();
                break;
            case NotificationActionType.DISMISS:
                markRead(action.eventId).catch(console.error);
                break;
            case NotificationActionType.DISMISS_ALL:
                markRead(...action.eventIds).catch(console.error);
                break;
        }
    };

    return (
        <>
            <Tabs
                indicatorColor={'secondary'}
                textColor={'inherit'}
                className={classes.tabs}
                variant={'standard'}
                value={tab}
                onChange={(_, value) => setTab(value)}
            >
                <Tab
                    value={NotificationTabs.URGENT}
                    className={classes.tab}
                    label={
                        <TabWithCount
                            critical
                            count={urgentNotificationsCount}
                            label={'Urgent'}
                            active={tab === NotificationTabs.URGENT}
                        />
                    }
                />
                <Tab
                    value={NotificationTabs.UPDATES}
                    className={classes.tab}
                    label={
                        <TabWithCount
                            count={updateNotificationsCount}
                            label={'Updates'}
                            active={tab === NotificationTabs.UPDATES}
                        />
                    }
                />
            </Tabs>
            {tab === NotificationTabs.URGENT &&
                (sortedUrgentNotifications.length === 0 ? (
                    <EmptyStateText
                        title={'You can sit back and relax'}
                        subTitle={'No urgent notifications need your attention right now'}
                        icon={<Icon icon={'BeachAccess'} />}
                    />
                ) : (
                    <DandyUrgentNotifications
                        sortedUrgentNotifications={sortedUrgentNotifications}
                        onClick={handleClick}
                    />
                ))}
            {tab === NotificationTabs.UPDATES &&
                (sortedUpdateNotifications.length === 0 ? (
                    <EmptyStateText
                        title={'You’re all caught up!'}
                        subTitle={'New notes, order updates and Dandy announcements will appear here'}
                        icon={<CheckIcon />}
                    />
                ) : (
                    <DandyUpdateNotifications
                        sortedUpdateNotifications={sortedUpdateNotifications}
                        onClick={handleClick}
                    />
                ))}
        </>
    );
};
