import { useCheckoutPropSelector, useCheckoutSelector } from '../../../redux';
import { getCurrentScreen } from '../state/checkout.screens';
import {
    useExistingOrderScreenDisplayingSelector,
    useScanWithExistingOrderSelector,
} from '../state/checkout.selectors';
import { implantCheckoutScreenTitle } from '../state/implant-checkout.selectors';
import { AestheticPhotoUploader } from './AestheticImagesBody';
import { CheckoutItemsStepRoot } from './CheckoutItemsStep/CheckoutItemsStepRoot';
import { CheckoutMainScreensContainer } from './CheckoutMainScreensContainer/CheckoutMainScreensContainer';
import { CheckoutPatientDoctorStep } from './CheckoutPatientDoctorStep/CheckoutPatientDoctorStep';
import { CheckoutRefabScreen } from './CheckoutRefabScreen';
import { CheckoutSmileStyleScreen } from './CheckoutSmileStyleScreen';
import { ImplantCheckout } from './ImplantCheckout/ImplantCheckout';
import { SurgicalGuideCbctUploader } from './SurgicalGuideCheckout/SurgicalGuideCbctUploader';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import { LabsGqlExistingPatientOrderType } from '@orthly/graphql-schema';
import React from 'react';

interface RegularCheckoutMainScreensProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

function useStepTwoScreenTitle(): string {
    const stepTwoScreen = useCheckoutSelector(s => s.stepTwoScreen);
    const implantTitle = useCheckoutSelector(implantCheckoutScreenTitle);

    switch (stepTwoScreen) {
        case 'summary':
            return 'Place order';
        case 'implantImages':
            return implantTitle;
        case 'surgicalGuideCbctUpload':
            return "(Optional) Upload the patient's CBCT scan here as a zipped DCM file";
        case 'aestheticPhotoUpload':
            return 'We need some photos…';
        case 'refab':
            return `Please give us more details about this refabrication`;
        case 'smileStyle':
            return `What design style are you looking for?`;
    }
}

function useCheckoutTitle(): string {
    const currentStep = useCheckoutSelector(s => s.step);
    const shouldShowExistingOrderScreen = useExistingOrderScreenDisplayingSelector();
    const scanWithExistingOrder = useScanWithExistingOrderSelector();
    const stepOneTitle = useCheckoutSelector(s => getCurrentScreen(s)?.title ?? 'Reviewing items');
    const stepTwoScreen = useStepTwoScreenTitle();

    if (shouldShowExistingOrderScreen && scanWithExistingOrder) {
        const patientName = `${scanWithExistingOrder.patient_first_name} ${scanWithExistingOrder.patient_last_name}`;
        if (scanWithExistingOrder.existingOrderMatch.type === LabsGqlExistingPatientOrderType.Delivered) {
            return `You recently received an order for ${patientName}`;
        }
        return `Attention, ${patientName} already has an active order on hold`;
    }
    switch (currentStep) {
        case 0:
            return `Let's get your order started`;
        case 1:
            return stepOneTitle;
        case 2:
            return stepTwoScreen;
    }
}

interface RegularCheckoutPatientDoctorStepProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

const RegularCheckoutPatientDoctorStep: React.VFC<RegularCheckoutPatientDoctorStepProps> = ({ scansQuery }) => {
    const { step: checkoutStep } = useCheckoutPropSelector(['step', 'existingOrderWarningDismissed']);

    return (
        <CheckoutPatientDoctorStep scansQuery={scansQuery} visible={checkoutStep === 0} isComplete={checkoutStep > 0} />
    );
};

export const RegularCheckoutMainScreens: React.VFC<RegularCheckoutMainScreensProps> = ({ scansQuery }) => {
    const title = useCheckoutTitle();
    return (
        <CheckoutMainScreensContainer
            titleProps={{ title, titleAction: null }}
            screens={
                <>
                    <RegularCheckoutPatientDoctorStep scansQuery={scansQuery} />
                    <CheckoutItemsStepRoot />
                    <ImplantCheckout />
                    <SurgicalGuideCbctUploader />
                    <AestheticPhotoUploader />
                    <CheckoutSmileStyleScreen />
                    <CheckoutRefabScreen />
                </>
            }
        />
    );
};
