import ortho from '../../../../assets/images/aligners/ortho.png';
import { EstimateProgress } from './EstimateProgress';
import type { AlignerEstimateStep } from '@orthly/shared-types';
import { Text, useScreenIsMobileOrVerticalTablet, createStyles, Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        bodyText: {
            marginBottom: 40,
            textAlign: 'center',
        },
        container: {
            alignItems: 'center',
            height: '100%',
            justifyContent: 'center',
            paddingTop: 56,
        },
        headerText: {
            marginBottom: 16,
            textAlign: 'center',
        },
        image: {
            width: 192,
            height: 192,
            marginBottom: 16,
        },
    }),
);

interface AlignerEstimatingStatusProps {
    patientFirstName: string;
    activeStep: AlignerEstimateStep;
    setActiveStep: (step: AlignerEstimateStep) => void;
    horizontalLayout?: boolean;
}

export const AlignerEstimatingStatus: React.VFC<AlignerEstimatingStatusProps> = ({
    patientFirstName,
    activeStep,
    setActiveStep,
    horizontalLayout,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    return (
        <Grid container direction={!isMobile && horizontalLayout ? 'row' : 'column'} className={classes.container}>
            <Grid item xs style={{ textAlign: 'center' }}>
                {!isMobile && <img src={ortho} className={classes.image} alt={'Doctor at work'} />}
                <Text variant={'h5'} className={classes.headerText}>
                    Hang tight, our team is analyzing {patientFirstName}'s case...
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'} align={'center'} style={{ marginBottom: 12 }}>
                    The following screen will show estimated treatment duration.
                </Text>
                <Text variant={'body2'} color={'DARK_GRAY'} className={classes.bodyText}>
                    This should only take a few minutes.
                </Text>
            </Grid>
            <Grid item xs>
                <EstimateProgress activeStep={activeStep} setActiveStep={setActiveStep} />
            </Grid>
        </Grid>
    );
};
