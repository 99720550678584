import { useTryInFeedbackAction } from '../state/TryInFeedback.action';
import { useTryInFeedbackPropSelector } from '../state/TryInFeedback.selectors';
import { TryInFeedbackUtils } from '../utils/TryInFeedback.util';
import { TryInFeedbackScreenLayout } from './layout/TryInFeedbackScreenLayout';
import {
    TRY_IN_FEEDBACK_NEXT_STEPS_DENTURE_LABELS,
    TRY_IN_FEEDBACK_NEXT_STEPS_PARTIAL_LABELS,
} from './utils/TryInFeedbackNextStepsLabels';
import { BigRadioSelect } from '@orthly/dentin';
import type { ICartDentureItem, ICartItemV2Update, ICartPartialDentureItem } from '@orthly/items';
import { DenturesProductionType } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemPartialDentureProductionType } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

interface TryInFeedbackPartialNextStepsSelectProps {
    item: ICartPartialDentureItem;
    updateItem: (update: ICartItemV2Update) => void;
}

const TryInFeedbackPartialNextStepsSelect: React.VFC<TryInFeedbackPartialNextStepsSelectProps> = ({
    item,
    updateItem,
}) => {
    return (
        <BigRadioSelect<OrderItemPartialDentureProductionType>
            value={item.partial_production_type ?? undefined}
            options={_.compact([
                {
                    value: OrderItemPartialDentureProductionType.Final,
                    label: TRY_IN_FEEDBACK_NEXT_STEPS_PARTIAL_LABELS[OrderItemPartialDentureProductionType.Final],
                    body: `You'll receive the final partial denture with all the requested changes`,
                },
                {
                    value: OrderItemPartialDentureProductionType.TryInTeethSetup,
                    label: TRY_IN_FEEDBACK_NEXT_STEPS_PARTIAL_LABELS[
                        OrderItemPartialDentureProductionType.TryInTeethSetup
                    ],
                    body: `You'll receive a new try-in with all the requested changes`,
                },
                ['Chrome-Cobalt', 'Titanium', 'Acetal'].includes(item.unit.material ?? '')
                    ? {
                          value: OrderItemPartialDentureProductionType.TryInFrameworkOnly,
                          label: TRY_IN_FEEDBACK_NEXT_STEPS_PARTIAL_LABELS[
                              OrderItemPartialDentureProductionType.TryInFrameworkOnly
                          ],
                          body: `You'll receive a new try-in with all the requested changes`,
                      }
                    : undefined,
            ])}
            onChange={val => updateItem({ name: 'partial_production_type', payload: val })}
            optionBackgroundColor={'WHITE'}
        />
    );
};

interface TryInFeedbackDentureNextStepsSelectProps {
    item: ICartDentureItem;
    updateItem: (update: ICartItemV2Update) => void;
}

const TryInFeedbackDentureNextStepsSelect: React.VFC<TryInFeedbackDentureNextStepsSelectProps> = ({
    item,
    updateItem,
}) => {
    return (
        <BigRadioSelect<DenturesProductionType>
            value={item.denture_production_type ?? undefined}
            options={_.compact([
                {
                    value: DenturesProductionType.Final,
                    label: TRY_IN_FEEDBACK_NEXT_STEPS_DENTURE_LABELS[DenturesProductionType.Final],
                    body: `You'll receive the final denture with all the requested changes`,
                },
                {
                    value: DenturesProductionType.TryIn,
                    label: TRY_IN_FEEDBACK_NEXT_STEPS_DENTURE_LABELS[DenturesProductionType.TryIn],
                    body: `You'll receive a new try-in with all the requested changes`,
                },
            ])}
            onChange={val => updateItem({ name: 'denture_production_type', payload: val })}
            optionBackgroundColor={'WHITE'}
        />
    );
};

export const TryInFeedbackNextSteps: React.VFC = () => {
    const updateItem = useTryInFeedbackAction('UPDATE_NEW_ITEM');
    const state = useTryInFeedbackPropSelector(['navigation', 'itemGroups']);
    const group = TryInFeedbackUtils.getActiveGroup(state);

    if (!group) {
        return null;
    }

    const item = group.newItem;

    return (
        <TryInFeedbackScreenLayout header={'How would you like to proceed?'}>
            {CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial) && (
                <TryInFeedbackPartialNextStepsSelect
                    item={item}
                    updateItem={update => updateItem({ update, itemId: group.itemId })}
                />
            )}
            {CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture) && (
                <TryInFeedbackDentureNextStepsSelect
                    item={item}
                    updateItem={update => updateItem({ update, itemId: group.itemId })}
                />
            )}
        </TryInFeedbackScreenLayout>
    );
};
