import { StaffProfilePhoto } from '../../../../components/StaffProfilePhoto';
import { useCurrentProfile } from '../../../../redux';
import { useInboxTitleMessage } from '../../useInboxTitleMessage';
import { Button, FlossPalette, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const IMAGE_WIDTH = 64;

const BannerWrapper = styled('div')({
    padding: '12px 24px',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    position: 'relative',
    marginLeft: IMAGE_WIDTH / 2,
});

const BannerWrapperPreSignUp = styled(BannerWrapper)({
    background: FlossPalette.DARK_TAN,
});

const BannerWrapperPostSignUp = styled(BannerWrapper)({
    background: FlossPalette.BLACK,
});

const BannerButton = styled(Button)({
    height: 'fit-content',
    '&&': {
        padding: '2px 12px',
    },
});

const BannerText = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 32,
});

const StaffProfilePhotoWrapper = styled('div')({
    position: 'absolute',
    width: 64,
    left: `-${IMAGE_WIDTH / 2}px`,
    top: -2,
});

const PointsText = styled(Text)({
    background: FlossPalette.BLACK,
    padding: 4,
    borderRadius: 12,
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

const StaffProfilePhotoStyles: React.CSSProperties = {
    borderRadius: '50%',
    height: `${IMAGE_WIDTH}px`,
    aspectRatio: 1,
};

const StaffProfilePhotoStylesPreSignUp: React.CSSProperties = {
    ...StaffProfilePhotoStyles,
    position: 'absolute',
    width: 64,
    left: `-${IMAGE_WIDTH / 2}px`,
    border: `2px solid ${FlossPalette.TAN}`,
};

const StaffProfilePhotoStylesPostSignUp: React.CSSProperties = {
    ...StaffProfilePhotoStyles,
    border: `2px solid ${FlossPalette.BLACK}`,
};

const InboxLoyaltyBannerPreSignUp: React.VFC = () => {
    const profile = useCurrentProfile();
    const title = useInboxTitleMessage();

    return (
        <BannerWrapperPreSignUp>
            <StaffProfilePhoto staff={profile} style={StaffProfilePhotoStylesPreSignUp} />
            <BannerText>
                <Text variant={'h5'} data-test={'inbox-title'}>
                    {title}
                </Text>
                <Text variant={'body2'}>You have not activated your loyalty profile</Text>
            </BannerText>
            <BannerButton
                variant={'primary'}
                endIcon={'ChevronRight'}
                analytics={{
                    AssetLocation: 'Practice - Inbox - Loyalty',
                    AssetName: 'Loyalty Opt In',
                }}
                onClick={() => window.open('https://www.meetdandy.com/loyalty-points', '_blank')}
            >
                Join loyalty program
            </BannerButton>
        </BannerWrapperPreSignUp>
    );
};

const InboxLoyaltyBannerPostSignUp: React.VFC<{ points: number }> = ({ points }) => {
    const profile = useCurrentProfile();
    const prettyPoints = points.toLocaleString();
    const title = useInboxTitleMessage();

    return (
        <BannerWrapperPostSignUp>
            <StaffProfilePhotoWrapper>
                <StaffProfilePhoto staff={profile} style={StaffProfilePhotoStylesPostSignUp} />
                <PointsText variant={'caption'} medium color={'WHITE'}>
                    {prettyPoints}
                </PointsText>
            </StaffProfilePhotoWrapper>
            <BannerText>
                <Text variant={'h5'} data-test={'inbox-title'} color={'WHITE'}>
                    {title}
                </Text>
                <Text variant={'body2'} color={'WHITE'}>
                    You have earned <span style={{ fontWeight: 600 }}>{prettyPoints}</span> loyalty points
                </Text>
            </BannerText>
            <BannerButton
                variant={'primary'}
                endIcon={'PointsIcon'}
                onClick={() => window.open('https://go.meetdandy.com/unlockloyaltypointbalance.html', '_blank')}
                analytics={{
                    AssetLocation: 'Practice - Inbox - Loyalty',
                    AssetName: 'Loyalty View Balance',
                }}
            >
                View point balance
            </BannerButton>
        </BannerWrapperPostSignUp>
    );
};

interface InboxLoyaltyBannerProps {
    isOptedIn: boolean;
    points: number;
}

export const InboxLoyaltyBanner: React.VFC<InboxLoyaltyBannerProps> = props => {
    const { isOptedIn, points } = props;

    if (isOptedIn) {
        return <InboxLoyaltyBannerPostSignUp points={points} />;
    }

    return <InboxLoyaltyBannerPreSignUp />;
};
