import { BankCardToNameMap, usePaymentMethodStyles } from './PaymentMethods.util';
import type { LabsGqlPaymentMethodFragment } from '@orthly/graphql-operations';
import {
    AMEXIcon,
    AchIcon,
    DiscoverIcon,
    JCBIcon,
    MastercardIcon,
    RootActionDialog,
    TrashIcon,
    VisaIcon,
} from '@orthly/ui';
import { Button, FlossPalette, Text, Grid, IconButton, CreditCardIcon } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

interface PaymentMethodsProps {
    partnerId: string;
    loadedMethods?: LabsGqlPaymentMethodFragment[];
    removeMethod: (props: { partnerId: string; cardId: string }) => Promise<void>;
    setDefaultMethod: (props: { partnerId: string; cardId: string }) => Promise<void>;
}

const PrimaryTag: React.FC = () => {
    const classes = usePaymentMethodStyles({});

    return <div className={classes.primaryTag}>Primary method</div>;
};

const RemoveMethodDialog: React.VFC<{
    source: LabsGqlPaymentMethodFragment;
    removeMethod: () => void;
    isLastMethod: boolean;
}> = ({ source, removeMethod, isLastMethod }) => {
    const classes = usePaymentMethodStyles({});
    const { brand, last4, method } = source;
    const [open, setOpen] = React.useState(false);
    const isBank = method === 'bank_account';
    const subtitle = isLastMethod
        ? 'This is your only payment method; deleting it could cause problems with future orders. Please add another payment method before deleting it.'
        : `Are you sure you want to remove this ${
              isBank ? 'bank account' : `${_.startCase(brand)} card`
          } ending in ${last4} from your payment methods?`;
    return (
        <RootActionDialog
            open={open}
            setOpen={setOpen}
            title={'You’re about to remove a payment method'}
            subtitle={subtitle}
            loading={false}
            CustomButton={() => (
                <IconButton onClick={() => setOpen(!open)}>
                    <TrashIcon style={{ color: FlossPalette.GRAY }} />
                </IconButton>
            )}
            content={
                <Grid className={classes.removeMethodContainer}>
                    {isLastMethod ? (
                        <Button
                            variant={'secondary'}
                            className={classes.removeMethodBtns}
                            onClick={() => setOpen(false)}
                        >
                            Okay
                        </Button>
                    ) : (
                        <>
                            <Button
                                variant={'secondary'}
                                className={classes.removeMethodBtns}
                                onClick={() => setOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant={'alert'}
                                onClick={() => {
                                    removeMethod();
                                    setOpen(false);
                                }}
                            >
                                Yes, remove payment method
                            </Button>
                        </>
                    )}
                </Grid>
            }
            showCloseButton
            closeIconButtonProps={{
                style: { height: 'fit-content' },
            }}
            closeIconProps={{ style: { color: FlossPalette.BLACK } }}
            titleTextProps={{ variant: 'h4' }}
            titleProps={{ style: { borderBottom: 'none' } }}
            contentProps={{ style: { borderTop: 'none' } }}
            buttonText={''}
        />
    );
};

interface PaymentMethodBaseProps {
    source: LabsGqlPaymentMethodFragment;
    removeMethod: () => void;
    setAsDefault: () => void;
    isLastMethod: boolean;
}

const PaymentMethodBase: React.VFC<PaymentMethodBaseProps> = ({ source, removeMethod, setAsDefault, isLastMethod }) => {
    const { method, brand, last4, is_default } = source;
    const classes = usePaymentMethodStyles({ is_default });
    const isBank = method === 'bank_account';

    const bankCardIcon = () => {
        switch (_.startCase(brand)) {
            case BankCardToNameMap.american_express:
                return <AMEXIcon />;
            case BankCardToNameMap.discover:
                return <DiscoverIcon />;
            case BankCardToNameMap.jcb:
                return <JCBIcon />;
            case BankCardToNameMap.mastercard:
                return <MastercardIcon />;
            case BankCardToNameMap.visa:
                return <VisaIcon />;
            default:
                return <CreditCardIcon />;
        }
    };

    return (
        <Grid container direction={'column'} className={classes.root}>
            <Grid container>
                <Grid item className={classes.paymentMethodIcon}>
                    {isBank ? <AchIcon /> : bankCardIcon()}
                </Grid>
                <Grid item>
                    <Text variant={'caption'} color={'DARK_GRAY'} medium>
                        {isBank ? 'ACH Transfer' : _.startCase(brand)}
                    </Text>
                    <Grid item>
                        <Text variant={'body2'}>{isBank ? `Acct. #: ****${last4}` : `**** **** **** ${last4}`}</Text>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.footerContainer}>
                {is_default ? (
                    <PrimaryTag />
                ) : (
                    <Button variant={'ghost'} onClick={setAsDefault} className={classes.setPrimaryBtn}>
                        Set as Primary
                    </Button>
                )}
                <RemoveMethodDialog source={source} isLastMethod={isLastMethod} removeMethod={removeMethod} />
            </Grid>
        </Grid>
    );
};

type PaymentCardProps = {
    partnerId: string;
    source: LabsGqlPaymentMethodFragment;
    removeMethod: (props: { partnerId: string; cardId: string }) => void;
    setDefaultMethod: (props: { partnerId: string; cardId: string }) => void;
    isLastMethod: boolean;
};

const PaymentMethod: React.FC<PaymentCardProps> = ({
    partnerId,
    source,
    removeMethod,
    setDefaultMethod,
    isLastMethod,
}) => {
    const setDefault = React.useCallback(() => {
        setDefaultMethod({ partnerId, cardId: source.id });
    }, [setDefaultMethod, partnerId, source.id]);

    const doRemove = React.useCallback(() => {
        removeMethod({ partnerId, cardId: source.id });
    }, [removeMethod, partnerId, source.id]);

    return (
        <PaymentMethodBase
            source={source}
            removeMethod={doRemove}
            setAsDefault={setDefault}
            isLastMethod={isLastMethod}
        />
    );
};

export const PaymentMethods: React.FC<PaymentMethodsProps> = props => {
    const { partnerId, loadedMethods, removeMethod, setDefaultMethod } = props;
    const methods = React.useMemo(() => loadedMethods ?? [], [loadedMethods]);

    return (
        <Grid container spacing={2}>
            {methods.map(method => (
                <Grid item key={method.id} xs={12} sm={6} lg={4}>
                    <PaymentMethod
                        key={method.id}
                        partnerId={partnerId}
                        source={method}
                        removeMethod={removeMethod}
                        setDefaultMethod={setDefaultMethod}
                        isLastMethod={methods.length === 1}
                    />
                </Grid>
            ))}
        </Grid>
    );
};
