import { Grid, TextField, Text } from '@orthly/ui-primitives';
import React from 'react';

interface PreferenceTextProps {
    header: string;
    subHeader?: string;
    label?: string;
    value: string;
    onChange: (val: string) => void;
}

export const PreferenceText: React.FC<PreferenceTextProps> = props => {
    const { header, subHeader, label, value, onChange } = props;

    return (
        <Grid>
            <Text variant={'h6'} style={{ fontWeight: 500 }}>
                {header}
            </Text>
            {subHeader !== undefined && <Text variant={'body1'}>{subHeader}</Text>}
            <TextField
                fullWidth
                multiline={true}
                variant={'outlined'}
                label={label}
                value={value}
                onChange={e => onChange(e.target.value)}
                style={{ margin: '0.5rem 0 1rem', maxWidth: '37rem' }}
            />
        </Grid>
    );
};
