import { BadgeStyled } from '../../../../components/BadgeStyled';
import { usePracticeAppSelector } from '../../../../redux';
import { useChatAction } from '../../../chat/chat-state/chat.actions';
import { CheckoutSidebarRoot } from '../CheckoutSidebar/CheckoutSidebarRoot';
import { PracticeScreen } from '@orthly/dentin';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    Avatar,
    Button,
    Drawer,
    Grid,
    IconButton,
    Toolbar,
    createStyles,
    makeStyles,
    Icon,
} from '@orthly/ui-primitives';
import logo from '@orthly/ui/assets/logos/DandyLogoWhite.svg';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        toolbar: {
            zIndex: theme.zIndex.modal + 1,
        },
        menuButtonHelp: {
            color: 'white',
        },
        menuButtonMobile: {
            marginLeft: 3,
            color: 'white',
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        logo: {
            marginRight: theme.spacing(2),
            borderRadius: 0,
            cursor: 'pointer',
            width: 'auto',
            '& img': {
                height: 25,
                width: 'auto',
            },
        },
        sidebarPaper: {
            background: FlossPalette.TAN,
            maxWidth: '85%',
            marginTop: 56,
            maxHeight: 'calc(100vh - 56px)',
        },
    }),
);

export const CheckoutTopBar: React.FC = () => {
    const classes = useStyles();
    const unreadChatCount = usePracticeAppSelector(s => s.ui.unreadChatCount);
    const [sidebarOpen, setSidebarOpen] = React.useState<boolean>(false);
    const { value: chatAvailability } = useFeatureFlag('chatAvailability');
    const openZDAction = useChatAction('OPEN_ZD_CHAT');
    return (
        <>
            <Toolbar className={classes.toolbar}>
                <Grid container style={{ width: 'auto' }} justifyContent={'flex-start'} alignItems={'center'}>
                    <RouterLink to={`/${PracticeScreen.orders}`} style={{ textDecoration: 'none' }}>
                        <Avatar className={classes.logo} src={logo} alt={'Logo'} />
                    </RouterLink>
                </Grid>
                <Grid container justifyContent={'flex-end'} wrap={'nowrap'}>
                    <Button
                        variant={'contained'}
                        startIcon={'HelpIcon'}
                        className={classes.menuButtonHelp}
                        onClick={() => openZDAction(chatAvailability)}
                    >
                        Get Help
                    </Button>
                    <BadgeStyled count={unreadChatCount}>
                        <IconButton className={classes.menuButtonMobile} onClick={() => setSidebarOpen(!sidebarOpen)}>
                            <Icon icon={'Menu'} color={'inherit'} style={{ color: '#fff' }} />
                        </IconButton>
                    </BadgeStyled>
                </Grid>
            </Toolbar>
            <Drawer
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}
                variant={'temporary'}
                anchor={'left'}
                PaperProps={{ className: classes.sidebarPaper }}
            >
                <Grid container wrap={'nowrap'} style={{ overflowY: 'scroll' }}>
                    <CheckoutSidebarRoot />
                </Grid>
            </Drawer>
        </>
    );
};
