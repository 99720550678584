import { CheckoutLargeRadioRow } from '../LegacyCheckoutFormComponents/CheckoutLargeRadioRow';
import type { LabsGqlPendingAlignerCaseCrowdingResolutionInput } from '@orthly/graphql-schema';
import { LabsGqlCrowdingResolutionOption } from '@orthly/graphql-schema';
import { FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const ALIGNER_CHECKOUT_CROWDING_RESOLUTION_OPTIONS = [
    {
        value: LabsGqlCrowdingResolutionOption.OnlyIfNeeded,
        label: 'Only if needed',
    },
    {
        value: LabsGqlCrowdingResolutionOption.No,
        label: 'No',
    },
];

interface AlignerCrowdingResolutionSelectionProps {
    value: LabsGqlPendingAlignerCaseCrowdingResolutionInput;
    onChange: (val: LabsGqlPendingAlignerCaseCrowdingResolutionInput) => void;
    small?: boolean;
}

export const AlignerCrowdingResolutionSelection: React.VFC<AlignerCrowdingResolutionSelectionProps> = ({
    value,
    onChange,
    small,
}) => {
    const showWarning = [value.expansion, value.proclination, value.ipr].every(
        cr => cr === LabsGqlCrowdingResolutionOption.No,
    );

    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ marginTop: 16 }}>
            <CheckoutLargeRadioRow<LabsGqlCrowdingResolutionOption>
                small={small}
                value={value.expansion}
                options={ALIGNER_CHECKOUT_CROWDING_RESOLUTION_OPTIONS}
                onChange={val => onChange({ ...value, expansion: val })}
                label={'Expansion'}
                description={'Widening the dental arch'}
            />
            <CheckoutLargeRadioRow<LabsGqlCrowdingResolutionOption>
                small={small}
                value={value.proclination}
                options={ALIGNER_CHECKOUT_CROWDING_RESOLUTION_OPTIONS}
                onChange={val => onChange({ ...value, proclination: val })}
                label={'Proclination'}
                description={'Inclining the teeth forward'}
            />
            <CheckoutLargeRadioRow<LabsGqlCrowdingResolutionOption>
                small={small}
                value={value.ipr}
                options={ALIGNER_CHECKOUT_CROWDING_RESOLUTION_OPTIONS}
                onChange={val => onChange({ ...value, ipr: val })}
                label={'IPR'}
                description={'Removing enamel to reduce crown width'}
            />
            {showWarning && (
                <div
                    style={{
                        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
                        marginTop: 24,
                        borderRadius: 16,
                        padding: 24,
                    }}
                >
                    <Text variant={'body2'} color={'ATTENTION_FOREGROUND'} medium>
                        Proceed with caution
                    </Text>
                    <Text variant={'body2'} color={'BLACK'} style={{ marginTop: 8 }}>
                        Note that we may not be able to fully resolve crowding without the use of any of the above
                        methods.
                    </Text>
                </div>
            )}
        </Grid>
    );
};
