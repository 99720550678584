import { usePracticeAppSelector } from '../../../redux';
import { PartnerUiActions } from '../../../redux/ui.actions';
import { getPartnerId } from '../../../utils/authorization';
import { useInvoicesQuery } from '../../invoices/hooks/useInvoicesQuery.graphql';
import { useActivateStaffOnLogin } from './useActivateStaffOnLogin.graphql';
import { useQuery } from '@apollo/client';
import { isActiveTaskScanRejectedAndVisibleToPractice } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import {
    useLabsInboxItems,
    useOrders,
    useOrdersByIds,
    usePendingReviewOrderIdsQuery,
    useScans,
} from '@orthly/graphql-react';
import { LabsGqlFilterComparator, LabsGqlResponsiblePartyEnum, LabsGqlWorkflowTaskType } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import constate from 'constate';
import { orderBy } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';

const PracticeInboxProviderTasks_Query = graphql(`
    query PracticeInboxProviderTasks_Query($task_filter: ListWorkflowTasksFilter) {
        orderWorkflowTasksByListView(task_filter: $task_filter, order_filter: null, search: null, sort: null) {
            ... on IWorkflowTask {
                id
                lab_order_id
                type
                __typename
            }

            ... on ResolveScanRejectionWorkflowTask {
                active_scan_review_work_order {
                    status
                }
            }
        }
    }
`);

function usePracticeInboxDataRaw() {
    useActivateStaffOnLogin();

    const session = useSession();
    const dispatch = useDispatch();
    const partnerId = getPartnerId(session);
    const unreadInboxItemsRedux = usePracticeAppSelector(s => s.ui.unreadInboxItems);
    const { isComplete: onboardingComplete, loading: onboardingLoading } = usePracticeAppSelector(s => s.onboarding);
    const pendingReviewsQuery = usePendingReviewOrderIdsQuery();
    const pendingWaxupApprovalsQuery = useOrders({
        criteria: [
            {
                filter_id: OrderFilterIdEnum.active_task_type,
                comparator: LabsGqlFilterComparator.Equals,
                comparison_value: 'WaxupReview',
            },
        ],
    });

    const {
        data,
        loading: actionableHoldTasksLoading,
        refetch: refetchOnHoldOrders,
    } = useQuery(PracticeInboxProviderTasks_Query, {
        variables: {
            task_filter: {
                responsible_party: { role: LabsGqlResponsiblePartyEnum.Practice, id: partnerId },
                completed: false,
                type: [LabsGqlWorkflowTaskType.ResolveScanRejection, LabsGqlWorkflowTaskType.DrReviewHold],
            },
        },
    });

    const actionableHoldTasksOrderIds = data
        ? data.orderWorkflowTasksByListView
              .filter(
                  task =>
                      task.__typename !== 'ResolveScanRejectionWorkflowTask' ||
                      isActiveTaskScanRejectedAndVisibleToPractice(
                          task?.type ?? null,
                          task.active_scan_review_work_order?.status ?? null,
                      ),
              )
              .map(task => task.lab_order_id)
        : [];

    const { loading: actionableHoldOrdersLoading, orders: actionableHoldOrders } =
        useOrdersByIds(actionableHoldTasksOrderIds);
    const { invoices, loading: invoicesLoading } = useInvoicesQuery();
    const overdueInvoices = React.useMemo(() => {
        return invoices.filter(p => p.is_overdue);
    }, [invoices]);
    const { scans: unsubmittedScans } = useScans();
    const { inboxItems } = useLabsInboxItems();

    const pendingReviewOrderIds = pendingReviewsQuery.data?.listPendingReviewLabOrderIds ?? [];
    const pendingWaxupApprovals = pendingWaxupApprovalsQuery.orders ?? [];

    const totalItemsCount = React.useMemo(() => {
        if (!onboardingComplete) {
            return 1;
        }
        return (
            overdueInvoices.length +
            unsubmittedScans.length +
            inboxItems.length +
            pendingReviewOrderIds.length +
            pendingWaxupApprovals.length +
            actionableHoldOrders.length
        );
    }, [
        inboxItems.length,
        onboardingComplete,
        overdueInvoices.length,
        pendingReviewOrderIds.length,
        unsubmittedScans.length,
        pendingWaxupApprovals.length,
        actionableHoldOrders.length,
    ]);
    React.useEffect(() => {
        if (unreadInboxItemsRedux !== totalItemsCount) {
            dispatch(PartnerUiActions.UPDATE_UI({ unreadInboxItems: totalItemsCount }));
        }
    }, [dispatch, totalItemsCount, unreadInboxItemsRedux]);
    const sortedInboxItems = React.useMemo(
        () => orderBy(inboxItems, i => new Date(i.date).valueOf(), 'desc'),
        [inboxItems],
    );
    return {
        unsubmittedScans,
        overdueInvoices,
        onboardingComplete,
        totalItemsCount,
        pendingReviewOrderIds,
        pendingWaxupApprovals,
        actionableHoldOrders,
        refetchOnHoldOrders,
        inboxItems: sortedInboxItems,
        refetchPendingReviews: pendingReviewsQuery.refetch,
        loading:
            invoicesLoading ||
            onboardingLoading ||
            pendingReviewsQuery.loading ||
            pendingWaxupApprovalsQuery.loading ||
            actionableHoldTasksLoading ||
            actionableHoldOrdersLoading,
    };
}

// The rule has an issue with this format
// eslint-disable-next-line import/no-unused-modules
export const [PracticeInboxProvider, usePracticeInboxData] = constate(usePracticeInboxDataRaw);
