import type { OrderPreferencesSKULabel } from '@orthly/dentin';
import { ORDER_PREFERENCES_SKU_DEFINITIONS } from '@orthly/dentin';
import { FlossPalette, Grid, ChevronRight, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const SkuSelectionCard: React.VFC<{ skuLabel: OrderPreferencesSKULabel }> = ({ skuLabel }) => {
    const history = useHistory();
    const img_src = ORDER_PREFERENCES_SKU_DEFINITIONS.find(agg => agg.label === skuLabel)?.img_src;
    const { path } = useRouteMatch();
    return (
        <div
            style={{
                backgroundColor: FlossPalette.TAN,
                border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                borderRadius: 16,
                cursor: 'pointer',
                padding: '24px 16px',
            }}
            onClick={() => history.push(`${path}/${encodeURIComponent(skuLabel)}`)}
        >
            <div style={{ height: 80, textAlign: 'center', marginBottom: 16 }}>
                <img src={img_src} alt={skuLabel} style={{ maxHeight: '100%', maxWidth: '100%' }} />
            </div>
            <Grid container justifyContent={'space-between'}>
                <Grid item>
                    <Text variant={'body2'} medium>
                        {skuLabel}
                    </Text>
                </Grid>
                <Grid item>
                    <ChevronRight style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                </Grid>
            </Grid>
        </div>
    );
};
