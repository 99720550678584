import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import React from 'react';
import type { ZodRawShape } from 'zod';
import { z } from 'zod';

export type StaffMember = {
    firstName: string;
    lastName: string;
    accountEmail: string;
    accountPhone: string;
    contactEmail: string;
    contactPhone: string;
    preferredName: string;
    roles: LabsGqlStaffRolePractice[];
};

const baseSchema = z.object({
    firstName: z.string().trim().nonempty('First name is required'),
    lastName: z.string().trim().nonempty('Last name is required'),
    accountEmail: z.string().email('Invalid email address'),
    accountPhone: z.string(),
    roles: z.array(z.nativeEnum(LabsGqlStaffRolePractice)).nonempty('At least one role is required'),
});

const refinePhoneNumberValidation = (fields: string[], schema: ZodRawShape = {}) => {
    const extendedSchema = baseSchema.extend(schema);

    fields.forEach(field =>
        extendedSchema.refine(
            ({ [field]: phoneField }) =>
                ['', '+1'].includes(phoneField) || PhoneNumberUtils.isValidPhoneNumber(phoneField),
            {
                message: 'Invalid phone number',
                path: [field],
            },
        ),
    );

    return extendedSchema;
};

const createStaffMemberSchema = refinePhoneNumberValidation(['accountPhone']);
const updateStaffMemberSchema = refinePhoneNumberValidation(['accountPhone', 'contactPhone'], {
    preferredName: z.string(),
    contactEmail: z.string().email('Invalid email address'),
    contactPhone: z.string(),
});

export const useStaffFormValidation = (staffMember: StaffMember, isEditForm: boolean) => {
    const [showPreferredNameError, setShowPreferredNameError] = React.useState(false);
    const [showFirstNameError, setShowFirstNameError] = React.useState(false);
    const [showLastNameError, setShowLastNameError] = React.useState(false);
    const [showAccountEmailError, setShowAccountEmailError] = React.useState(false);
    const [showAccountPhoneError, setShowAccountPhoneError] = React.useState(false);
    const [showContactEmailError, setShowContactEmailError] = React.useState(false);
    const [showContactPhoneError, setShowContactPhoneError] = React.useState(false);
    const [showRolesError, setShowRolesError] = React.useState(false);

    const validation = React.useMemo(
        () =>
            isEditForm
                ? updateStaffMemberSchema.safeParse(staffMember)
                : createStaffMemberSchema.safeParse(staffMember),
        [isEditForm, staffMember],
    );

    if (validation.success) {
        return {
            isFormValid: true,
            setShowFirstNameError,
            setShowLastNameError,
            setShowAccountEmailError,
            setShowAccountPhoneError,
            setShowContactEmailError,
            setShowContactPhoneError,
            setShowRolesError,
            setShowPreferredNameError,
        };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: false,
        setShowFirstNameError,
        setShowLastNameError,
        setShowPreferredNameError,
        setShowAccountEmailError,
        setShowAccountPhoneError,
        setShowContactEmailError,
        setShowContactPhoneError,
        setShowRolesError,
        firstNameError: showFirstNameError && fieldErrors.firstName?.[0],
        lastNameError: showLastNameError && fieldErrors.lastName?.[0],
        preferredNameError: showPreferredNameError && fieldErrors.preferredName?.[0],
        accountEmailError: showAccountEmailError && fieldErrors.accountEmail?.[0],
        accountPhoneError: showAccountPhoneError && fieldErrors.accountPhone?.[0],
        contactEmailError: showContactEmailError && fieldErrors.contactEmail?.[0],
        contactPhoneError: showContactPhoneError && fieldErrors.contactPhone?.[0],
        rolesError: showRolesError && fieldErrors.roles?.[0],
    };
};
