import { useUploadOrderPhotos } from '../../../../../chat/ChatWindow/ChatThread/ChatThreadInput/util';
import type { CBCTScanUpload, MinimalScanEntry, ScanUpload } from '../../AttachScans/utils/Reloader.util';
import { ScanSource, useGetScansFromFilesAction, useRepackageScansAction } from '../../AttachScans/utils/Reloader.util';
import { uploadFilesCBCTScanFileAtom, uploadFilesScansMapAtom } from '../atoms/UploadFiles.atoms';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import { PracticeScreen } from '@orthly/dentin';
import type { ScanEntry } from '@orthly/forceps';
import type { LabsGqlCreateChatMessageMutationVariables, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { useCreateChatMessageMutation, useSetCbctScanUrlMutation } from '@orthly/graphql-react';
import {
    LabsGqlChatEntityTypes,
    LabsGqlControlPanelActionType,
    LabsGqlLabOrderPhotoType,
    LabsGqlStaffRoleWithAny,
} from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, useChangeSubmissionFn } from '@orthly/ui';
import type { FileUploadAnalyticsMetadata, UploadedFile } from '@orthly/veneer';
import { useFirebaseStorage, useOrderFileUpload } from '@orthly/veneer';
import * as Sentry from '@sentry/react';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

type Vars = Omit<
    LabsGqlCreateChatMessageMutationVariables['data'],
    'entity_type' | 'visible_to_org_ids' | 'visible_to_roles' | 'action_type' | 'text'
>;

const useCreateChatMessage = (onSuccess: (orderId?: string) => void) => {
    const organizationType = useSession()?.organization_type ?? 'practice';

    const [submitMtn] = useCreateChatMessageMutation();
    const mtnSubmitter = (data: Vars) =>
        submitMtn({
            variables: {
                data: {
                    ...data,
                    text: 'Files attached to order',
                    entity_type: LabsGqlChatEntityTypes.Order,
                    visible_to_roles: getChatMessageVisibilityForWrite(organizationType, [
                        LabsGqlStaffRoleWithAny.LabAny,
                    ]),
                    action_type: LabsGqlControlPanelActionType.AddPhoto,
                },
            },
        });

    return useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        onSuccess: result => onSuccess(result.data?.createChatMessage?.entity_id),
    });
};

export const useOrderFileUploadActions = (orderId: string, sessionId: string) => {
    const analyticsMetadata: FileUploadAnalyticsMetadata = {
        productName: 'order files',
        device: 'web',
    };
    return useOrderFileUpload({
        orderId,
        itemId: undefined,
        analyticsMetadata,
        forRefab: false,
        sessionId,
    });
};

const uploadPhotosAndAttachmentsAction = async (
    orderId: string,
    files: UploadedFile[],
    groupedFiles: Record<string, UploadedFile[]>,
    scansMap: Map<string, ScanUpload>,
    uploadShadeAndOtherPhotos: (orderId: string, attachmentPaths: string[] | undefined) => Promise<void>,
    uploadPreopPhotos: (orderId: string, attachmentPaths: string[] | undefined) => Promise<void>,
    uploadXrayPhotos: (orderId: string, attachmentPaths: string[] | undefined) => Promise<void>,
    uploadSurgicalReportPhotos: (orderId: string, attachmentPaths: string[] | undefined) => Promise<void>,
    submit: (data: Vars) => Promise<void>,
) => {
    const attachmentUrls = _.compact(
        files.map(file => {
            if (!file.filepath || scansMap.has(file.file.name)) {
                return undefined;
            }
            return file.filepath;
        }),
    );
    const patientPhotos = groupedFiles[LabsGqlLabOrderPhotoType.PatientPhoto] ?? [];
    const otherPhotos = groupedFiles['other'] ?? [];
    const fullFacePhotos = groupedFiles[LabsGqlLabOrderPhotoType.FullFace] ?? [];
    const xrayPhotos = groupedFiles[LabsGqlLabOrderPhotoType.Xray] ?? [];
    const surgicalReportPhotos = groupedFiles[LabsGqlLabOrderPhotoType.SurgicalReport] ?? [];

    await Promise.all([
        uploadShadeAndOtherPhotos(
            orderId,
            patientPhotos.concat(otherPhotos).map(file => file.filepath ?? ''),
        ),
        uploadPreopPhotos(
            orderId,
            fullFacePhotos.map(file => file.filepath ?? ''),
        ),
        uploadXrayPhotos(
            orderId,
            xrayPhotos.map(file => file.filepath ?? ''),
        ),
        uploadSurgicalReportPhotos(
            orderId,
            surgicalReportPhotos.map(file => file.filepath ?? ''),
        ),
    ]);

    if (attachmentUrls && attachmentUrls.length > 0) {
        await submit({
            entity_id: orderId,
            attachment_urls: attachmentUrls,
        });
    }
};

const useUploadCBCTScanAction = (cbctScan: CBCTScanUpload | null, setLoading: (loading: boolean) => void) => {
    const storagePathConfig = getFullStoragePath(OrthlyBrowserConfig.env, OrderingStorageConfigs.surgicalGuideCbct);
    const firebase = useFirebaseStorage(storagePathConfig.bucketName);

    const [rawSetCbctUrl] = useSetCbctScanUrlMutation();
    const { submit } = useChangeSubmissionFn(rawSetCbctUrl, {});

    return React.useCallback(
        async (order: Pick<LabsGqlLabOrderFragment, 'id' | 'scan_export_id'>) => {
            if (!cbctScan) {
                return;
            }

            setLoading(true);

            try {
                const storageRef = firebase.ref(`${storagePathConfig.path}/${order.scan_export_id}/${cbctScan.path}`);
                const output = await storageRef.put(cbctScan.buffer);
                await submit({ variables: { order_id: order.id, cbct_url: output.metadata.fullPath } });
            } catch (err) {
                Sentry.captureException(err);
            } finally {
                setLoading(false);
            }
        },
        [cbctScan, firebase, submit, setLoading, storagePathConfig.path],
    );
};

const trackAddPhotoAction = (
    orderId: string,
    files: UploadedFile[],
    scansMap: Map<string, ScanUpload>,
    activeTask?: string,
) => {
    const fileTypes = files
        .filter(file => !scansMap.has(file.file.name))
        .map(file => file.file.name.split('.')[1] ?? '');

    BrowserAnalyticsClientFactory.Instance?.track('Practice - Control Panel - Files Uploaded', {
        fileTypes,
        activeTask,
        $groups: { order: orderId },
    });
};

const trackUploadScansOpenedAction = (orderId: string) => {
    BrowserAnalyticsClientFactory.Instance?.track('Practice - Upload Scans - Opened Upload Scans', {
        $groups: { order: orderId },
    });
};

const getFileTypes = (files: UploadedFile[], scansMap: Map<string, ScanUpload>) => {
    const nonScanFileTypes = files
        .filter(file => !scansMap.has(file.file.name))
        .map(file => file.file.name.split('.')[1] ?? '');
    const scanFileTypes = [...scansMap.keys()].map(key => key.split('.')[1] ?? '');

    return { nonScanFileTypes, scanFileTypes };
};

const trackOrderEditedWithUploadedScansAction = (
    orderId: string,
    files: UploadedFile[],
    scansMap: Map<string, ScanUpload>,
    activeTask?: string,
) => {
    const { nonScanFileTypes, scanFileTypes } = getFileTypes(files, scansMap);

    BrowserAnalyticsClientFactory.Instance?.track('Practice - Upload Scans - Order Edited With Uploaded Scans', {
        nonScanFileTypes,
        scanFileTypes,
        activeTask,
        $groups: { order: orderId },
    });
};

const trackOrderCancelledAndResubmittedWithUploadedScansAction = (
    orderId: string,
    files: UploadedFile[],
    scansMap: Map<string, ScanUpload>,
    activeTask?: string,
) => {
    const { nonScanFileTypes, scanFileTypes } = getFileTypes(files, scansMap);

    BrowserAnalyticsClientFactory.Instance?.track(
        'Practice - Upload Scans - Order Cancelled and Resubmitted With Uploaded Scans',
        {
            nonScanFileTypes,
            scanFileTypes,
            activeTask,
            $groups: { order: orderId },
        },
    );
};

const trackScanUploadErrorAction = (orderId: string) => {
    BrowserAnalyticsClientFactory.Instance?.track(
        'Practice - Upload Scans - Error Encountered When Attempting to Upload Scans',
        {
            $groups: { order: orderId },
        },
    );
};

const makeScanEntry = (scan: MinimalScanEntry): ScanEntry | undefined => {
    if (scan.definition && scan.scan_type && scan.buffer) {
        return {
            ...scan,
            id: uuidv4(),
            definition: scan.definition,
            scan_type: scan.scan_type,
            buffer: Buffer.from(scan.buffer),
        };
    }
};

export const useAttachmentFormControls = (data: {
    order: LabsGqlLabOrderFragment;
    isAssignmentStep: boolean;
    refetchMessages: () => void;
    setIsAssignmentStep: (value: boolean) => void;
    setPreviewedFile: (value: string) => void;
    uploadFiles: UploadedFile[];
    files: UploadedFile[];
    groupedFiles: Record<string, UploadedFile[]>;
    setLoading: (value: boolean) => void;
    setProcessingScans: (value: boolean) => void;
    setOpen: (value: boolean) => void;
    setHasUploadedMultipleThreeOxzs: (value: boolean) => void;
    setLoaderText: (value: string) => void;
    orderIsCancelResubmit: boolean;
    onRemoveFile: (filename: string) => Promise<void>;
    setScansToastOpen?: (value: boolean) => void;
    setFilesAddedToastOpen?: (value: boolean) => void;
    setErrorToastOpen?: (value: boolean) => void;
}) => {
    const {
        order,
        refetchMessages,
        setProcessingScans,
        setOpen,
        setScansToastOpen,
        setFilesAddedToastOpen,
        setErrorToastOpen,
        setLoading,
        setIsAssignmentStep,
        setPreviewedFile,
        setLoaderText,
        setHasUploadedMultipleThreeOxzs,
        onRemoveFile,
        groupedFiles,
        uploadFiles,
        files,
        isAssignmentStep,
        orderIsCancelResubmit,
    } = data;
    const history = useHistory();
    const [scansMap] = useAtom(uploadFilesScansMapAtom);
    const [cbctScan] = useAtom(uploadFilesCBCTScanFileAtom);
    const uploadShadeAndOtherPhotos = useUploadOrderPhotos();
    const uploadPreopPhotos = useUploadOrderPhotos(LabsGqlLabOrderPhotoType.FullFace);
    const uploadXrayPhotos = useUploadOrderPhotos(LabsGqlLabOrderPhotoType.Xray);
    const uploadSurgicalReportPhotos = useUploadOrderPhotos(LabsGqlLabOrderPhotoType.SurgicalReport);
    const { submit } = useCreateChatMessage(orderId => {
        if (orderId) {
            trackAddPhotoAction(orderId, files, scansMap, order.fulfillment_workflow.active_task?.type);
        }
    });

    const getScansFromFilesAction = useGetScansFromFilesAction(
        order,
        setProcessingScans,
        setHasUploadedMultipleThreeOxzs,
        onRemoveFile,
        didFindScans => {
            if (didFindScans) {
                trackUploadScansOpenedAction(order.id);
            }
        },
    );

    const scans = _.compact(
        [...scansMap.values()].flatMap<ScanEntry | undefined>(scanUpload => {
            return scanUpload.source === ScanSource.SingleScan
                ? makeScanEntry(scanUpload.scan)
                : scanUpload.scans.map(makeScanEntry);
        }),
    );

    const uploadCBCTScanAction = useUploadCBCTScanAction(cbctScan, setLoading);

    const repackageScansAction = useRepackageScansAction(
        order,
        scans,
        setLoading,
        setLoaderText,
        async orderId => {
            await uploadPhotosAndAttachmentsAction(
                orderId,
                uploadFiles,
                groupedFiles,
                scansMap,
                uploadShadeAndOtherPhotos,
                uploadPreopPhotos,
                uploadXrayPhotos,
                uploadSurgicalReportPhotos,
                submit,
            );

            await uploadCBCTScanAction({ id: orderId, scan_export_id: order.scan_export_id });

            if (orderIsCancelResubmit && orderId !== order.id) {
                trackOrderCancelledAndResubmittedWithUploadedScansAction(
                    orderId,
                    files,
                    scansMap,
                    order.fulfillment_workflow.active_task?.type,
                );
                return history.push(`/${PracticeScreen.orders}/${orderId}`);
            }

            trackOrderEditedWithUploadedScansAction(
                orderId,
                files,
                scansMap,
                order.fulfillment_workflow.active_task?.type,
            );
            setScansToastOpen?.(true);
        },
        async () => {
            if (!orderIsCancelResubmit) {
                await uploadPhotosAndAttachmentsAction(
                    order.id,
                    uploadFiles,
                    groupedFiles,
                    scansMap,
                    uploadShadeAndOtherPhotos,
                    uploadPreopPhotos,
                    uploadXrayPhotos,
                    uploadSurgicalReportPhotos,
                    submit,
                );

                await uploadCBCTScanAction(order);
            }

            trackScanUploadErrorAction(order.id);
            setErrorToastOpen?.(true);
        },
    );

    const disableSelectionStepNext = uploadFiles.length === 0 || uploadFiles.length !== files.length;
    const onBackSelectionStepAction = () => setOpen(false);
    const onNextSelectionStepAction = async () => {
        if (order.can_attach_scans) {
            await getScansFromFilesAction();
        }

        setIsAssignmentStep(true);
    };

    const onBackAssignmentStepAction = () => {
        setPreviewedFile('');
        setIsAssignmentStep(false);
    };
    const onNextAssignmentStepAction = async () => {
        setLoading(true);
        if (scans.length > 0) {
            await repackageScansAction();
        } else {
            await uploadPhotosAndAttachmentsAction(
                order.id,
                uploadFiles,
                groupedFiles,
                scansMap,
                uploadShadeAndOtherPhotos,
                uploadPreopPhotos,
                uploadXrayPhotos,
                uploadSurgicalReportPhotos,
                submit,
            );
            await uploadCBCTScanAction(order);
            setFilesAddedToastOpen?.(true);
        }
        setLoading(false);
        setOpen(false);
        refetchMessages();
    };

    return {
        disableSelectionStepNext,
        onBackAction: isAssignmentStep ? onBackAssignmentStepAction : onBackSelectionStepAction,
        onNextAction: isAssignmentStep ? onNextAssignmentStepAction : onNextSelectionStepAction,
    };
};
