import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionBodyFreeText } from '../TryInFeedbackOptionBodyFreeText';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { BigRadioSelect } from '@orthly/dentin';
import { LabOrderItemSKUType, OrderItemPartialDentureProductionType, OrderItemV2Utils } from '@orthly/items';
import { NumberFlipperInput } from '@orthly/ui';
import React from 'react';

const CHANGE_LENGTH_REGEX = /(Make longer|Make shorter) (\d+)mm/;

type TeethLengthOption = 'longer' | 'shorter' | 'other';

function parseDescription(
    description: string | null,
): { option: 'longer' | 'shorter'; distance: number } | { option: 'other'; value: string } | null {
    if (!description) {
        return null;
    }
    const [, direction, distance] = (description ?? '').match(CHANGE_LENGTH_REGEX) ?? [];
    if (direction && distance) {
        return { option: direction === 'Make longer' ? 'longer' : 'shorter', distance: parseInt(distance) };
    }
    return { option: 'other', value: description };
}

const TryInFeedbackTeethLengthOption: React.VFC<
    TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture>
> = ({ isSelected, description, onSelected, onUnselected, onDescriptionChanged, showError }) => {
    const parsed = parseDescription(description);

    const [selectedOption, setSelectedOption] = React.useState<TeethLengthOption | null>(parsed?.option ?? null);

    return (
        <TryInFeedbackOptionWrapper
            title={'Teeth length'}
            subtitle={'Teeth length is too short or long'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={() => {
                setSelectedOption(null);
                onUnselected();
            }}
            hasError={isSelected && showError && !description}
        >
            <BigRadioSelect<TeethLengthOption>
                value={selectedOption ?? undefined}
                onChange={val => {
                    if (val === selectedOption) {
                        return;
                    }
                    setSelectedOption(val);
                    if (val === 'longer') {
                        onDescriptionChanged('Make longer 1mm');
                    } else if (val === 'shorter') {
                        onDescriptionChanged('Make shorter 1mm');
                    } else {
                        onDescriptionChanged(undefined);
                    }
                }}
                options={[
                    { label: 'Make longer', value: 'longer' },
                    { label: 'Make shorter', value: 'shorter' },
                    { label: 'Other', value: 'other' },
                ]}
                selectedEndAdornment={
                    parsed?.option === 'longer' || parsed?.option === 'shorter' ? (
                        <div style={{ margin: -16 }} onClick={e => e.stopPropagation()}>
                            <NumberFlipperInput
                                value={parsed.distance ?? 1}
                                onChange={val => {
                                    if (parsed?.option === 'longer') {
                                        onDescriptionChanged(`Make longer ${val}mm`);
                                    } else if (parsed?.option === 'shorter') {
                                        onDescriptionChanged(`Make shorter ${val}mm`);
                                    }
                                }}
                                stepSize={1}
                                min={1}
                                max={10}
                                unitLabel={'mm'}
                                variant={'contained'}
                            />
                        </div>
                    ) : undefined
                }
            />
            {selectedOption === 'other' && (
                <div style={{ marginTop: 8 }}>
                    <TryInFeedbackOptionBodyFreeText
                        label={'Specify teeth length changes'}
                        value={description ?? undefined}
                        onChange={onDescriptionChanged}
                    />
                </div>
            )}
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackTeethLengthOption: TryInFeedbackOptionDefinition<
    LabOrderItemSKUType.Partial | LabOrderItemSKUType.Denture
> = {
    sku: [LabOrderItemSKUType.Partial, LabOrderItemSKUType.Denture],
    isArchSpecific: true,
    category: 'Teeth length',
    isEnabled: item =>
        !OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial) ||
        item.partial_production_type !== OrderItemPartialDentureProductionType.WaxRim,
    Component: TryInFeedbackTeethLengthOption,
};
