import type { TryInFeedbackNavigation, TryInFeedbackSubmissionData } from './TryInFeedback.types';
import type { LabsGqlLabOrderForTryInFeedbackFragment } from '@orthly/graphql-operations';
import type { ICartItemV2Update, OrderItemArch } from '@orthly/items';
import { createSyncAction, generateUseActionHook } from '@orthly/redux-async-actions';

const PREFIX = 'try_in_feedback';

interface InitializePayload {
    order: LabsGqlLabOrderForTryInFeedbackFragment;
}

interface SetNavigationPayload {
    navigation: TryInFeedbackNavigation;
}

interface UpdateActiveNewItemPayload {
    itemId: string;
    update: ICartItemV2Update;
}

interface SelectFeedbackDetailPayload {
    itemId: string;
    arch: OrderItemArch;
    category: string;
}

interface RemoveFeedbackDetailPayload {
    itemId: string;
    arch: OrderItemArch;
    category: string;
}

interface UpdateFeedbackDetailPayload {
    itemId: string;
    arch: OrderItemArch;
    category: string;
    description?: string;
}

interface SetShowErrorPayload {
    showError: boolean;
}

interface SetSubmissionDataPayload {
    submissionData: TryInFeedbackSubmissionData;
}

export const TryInFeedbackActions = {
    INITIALIZE: createSyncAction<InitializePayload>(`${PREFIX}/INITIALIZE`),
    SET_NAVIGATION: createSyncAction<SetNavigationPayload>(`${PREFIX}/SET_NAVIGATION`),
    UPDATE_NEW_ITEM: createSyncAction<UpdateActiveNewItemPayload>(`${PREFIX}/UPDATE_NEW_ITEM`),
    SELECT_FEEDBACK_DETAIL: createSyncAction<SelectFeedbackDetailPayload>(`${PREFIX}/SELECT_FEEDBACK_DETAIL`),
    REMOVE_FEEDBACK_DETAIL: createSyncAction<RemoveFeedbackDetailPayload>(`${PREFIX}/REMOVE_FEEDBACK_DETAIL`),
    UPDATE_FEEDBACK_DETAIL: createSyncAction<UpdateFeedbackDetailPayload>(`${PREFIX}/UPDATE_FEEDBACK_DETAIL`),
    SET_SHOW_ERROR: createSyncAction<SetShowErrorPayload>(`${PREFIX}/SET_SHOW_ERROR`),
    SET_SUBMISSION_DATA: createSyncAction<SetSubmissionDataPayload>(`${PREFIX}/SET_SUBMISSION_DATA`),
};

export const useTryInFeedbackAction = generateUseActionHook(TryInFeedbackActions);
