import { OrderDetailBlock } from './OrderDetailBlock';
import type { BridgeGroupProps, SingleMouthItem, DandyMouthDisplayProps } from '@orthly/dentin';
import { DandyMouthDisplay } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabOrderItemSKUType, OrderItemV2Utils, CartItemV2Utils, OrderItemArch } from '@orthly/items';
import type { IOrderItemV2DTO } from '@orthly/items';
import { stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

type MouthDisplayPropsFromItems = Pick<DandyMouthDisplayProps, 'bridges' | 'items' | 'fullMouth'>;

export function mouthPropsFromItems(items: IOrderItemV2DTO[]): MouthDisplayPropsFromItems {
    return items.reduce<MouthDisplayPropsFromItems>(
        (acc, item) => {
            if (OrderItemV2Utils.itemIsType(item, [LabOrderItemSKUType.ImplantBridge, LabOrderItemSKUType.Bridge])) {
                const units =
                    item.sku === LabOrderItemSKUType.Bridge ? item.units : [...item.implants, ...item.restoratives];
                const bridge: BridgeGroupProps = {
                    highlighted: true, // Order details doesn't have dynamic highlighting and mouth looks better if all are highlighted
                    items: units.map(unit => ({
                        number: unit.unn,
                        pontic: 'crown' in unit ? false : unit.unit_type === 'CrownPontic',
                    })),
                };
                return { ...acc, bridges: [...acc.bridges, bridge] };
            }
            if (OrderItemV2Utils.isArchItem(item)) {
                return {
                    ...acc,
                    fullMouth: {
                        upperHighlighted: false,
                        lowerHighlighted: false,
                        upperVisible: item.unit.arch !== OrderItemArch.Lower || acc.fullMouth?.upperVisible === true,
                        lowerVisible: item.unit.arch !== OrderItemArch.Upper || acc.fullMouth?.lowerVisible === true,
                    },
                };
            }
            const toothItems = CartItemV2Utils.getUniqueUNNs(item).map<SingleMouthItem>(unn => ({
                number: unn,
                isImplant: item.sku === LabOrderItemSKUType.Implant,
                highlighted: true,
            }));
            return { ...acc, items: [...acc.items, ...toothItems] };
        },
        { bridges: [], fullMouth: undefined, items: [] },
    );
}

const useStyles = stylesFactory(() => ({ contentRoot: { padding: '0 12px' } }));

export const OrderDetailMouth: React.FC<{ order?: LabsGqlOrder }> = props => {
    const { order } = props;
    const classes = useStyles();
    const { items, bridges, fullMouth } = React.useMemo(() => {
        return mouthPropsFromItems(OrderItemV2Utils.parseItems(order?.items_v2 ?? []));
    }, [order?.items_v2]);

    return (
        <OrderDetailBlock title={'Summary'} variant={'full'} classes={{ contentRoot: classes.contentRoot }}>
            <DandyMouthDisplay
                items={items}
                bridges={bridges}
                fullMouth={fullMouth}
                verticalLayout={true}
                animate={false}
            />
        </OrderDetailBlock>
    );
};
