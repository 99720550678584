import { OrderTimelineV2 } from '../../../components/PracticeOrderChatV2/components/OrderTimeline/OrderTimeline';
import { refetchTimelineItemsAtom } from '../../../components/atoms/Timeline.atoms';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useTimelineV2ItemsQuery } from '@orthly/graphql-react';
import { useSetAtom } from 'jotai';
import React from 'react';

interface OrderDetailTimelineBlockProps {
    order: LabsGqlSingleLabOrderFragment;
}

export const OrderDetailTimelineSection: React.FC<OrderDetailTimelineBlockProps> = ({ order }) => {
    const {
        data,
        refetch,
        loading: isLoadingItems,
    } = useTimelineV2ItemsQuery({
        variables: { entityId: order?.id ?? '' },
        skip: !order?.id,
    });

    const setRefetchTimelineItemsAtom = useSetAtom(refetchTimelineItemsAtom);

    React.useEffect(() => {
        setRefetchTimelineItemsAtom(() => refetch);
        return () => setRefetchTimelineItemsAtom(null);
    }, [refetch, setRefetchTimelineItemsAtom]);

    return (
        <OrderTimelineV2
            events={data?.timelineV2Items ?? []}
            isLoadingItems={isLoadingItems}
            order={order}
            showScrollToTop
        />
    );
};
