import { StackX } from '@orthly/ui';
import { FlossPalette, stylesFactory, Text, Avatar, styled } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    transparent: {
        opacity: 0.4,
    },
    aligned: {
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: { flexWrap: 'wrap' },
    },
}));

const AvailabilityAvatar = styled(Avatar)({
    width: 28,
    height: 28,
    fontSize: 12,
    borderRadius: '50%',
    border: `2px solid ${FlossPalette.WHITE}`,
    '&:not(:first-child)': {
        marginLeft: -8,
    },
});

export const AvailabilityAvatarGroup: React.VFC<
    { available: boolean } & React.ComponentPropsWithoutRef<typeof StackX>
> = ({ available, ...props }) => {
    const classes = useStyles();
    return (
        <StackX {...props} className={clsx(!available && classes.transparent, props.className)}>
            {Array.from({ length: 5 }, (_, key) => (
                <AvailabilityAvatar key={key} alt={`CX ${key}`} src={`https://i.pravatar.cc/128?u=${key}`} />
            ))}
        </StackX>
    );
};

export const AvailabilityBanner: React.VFC<{ available: boolean } & React.ComponentPropsWithoutRef<typeof StackX>> = ({
    available,
    ...props
}) => {
    const classes = useStyles();
    return (
        <StackX {...props} className={clsx(classes.aligned, props.className)}>
            <AvailabilityAvatarGroup available={available} />
            <Text variant={'body2'} color={'DARK_GRAY'} style={{ fontSize: 12, lineHeight: 1.5, marginLeft: 8 }}>
                {available ? `We're available!` : `We may be slower to respond right now.`}
            </Text>
        </StackX>
    );
};
