import { useCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { useChatFeatures } from '../../../chat/utils';
import { ManualScanUploadDialog } from '../../../labs/actions/practitioners/ManualScanUpload';
import { useCheckoutAction } from '../../state/checkout.actions';
import { useExistingOrderScreenDisplayingSelector } from '../../state/checkout.selectors';
import { useScanAlreadySubmittedQueryParam, useScanIdFromQueryString } from '../../state/useCheckoutScans';
import { CheckoutContentSection } from '../CheckoutLayout/CheckoutContentSection';
import type { CheckoutErrorSectionProps } from '../CheckoutLayout/CheckoutErrorSection';
import { CheckoutErrorSection } from '../CheckoutLayout/CheckoutErrorSection';
import { CheckoutSelectField, CheckoutTextButton, CheckoutTextButtonBase, CheckoutTextField } from '@orthly/dentin';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import type { SimpleSelectOption } from '@orthly/ui';
import { LoadBlocker, SimpleDatePicker } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, makeStyles, Collapse, Grid } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        selectWrapperFocused: {
            '& fieldset': {
                borderColor: FlossPalette.BLACK,
            },
        },
    }),
);

const ManualUploadButton: React.FC<Omit<ButtonProps, 'variant'>> = props => {
    return <CheckoutTextButtonBase {...props}>Manually Upload Scan</CheckoutTextButtonBase>;
};

const ChatWithSupportTextButton: React.FC = () => {
    const { openChat } = useChatFeatures();

    return (
        <Grid container justifyContent={'center'} style={{ paddingTop: 10 }}>
            <CheckoutTextButton onClick={() => openChat()}>Contact Support</CheckoutTextButton>
        </Grid>
    );
};

// Utility type because there are a bunch of different error states for scans
type ScanErrorState = 'networkError' | 'scanFromQueryAlreadyOrdered' | 'scanFromQueryMissing' | 'zeroScans';

function useScanErrorState(scansQuery: ScanFilesCheckoutQueryHookResult): ScanErrorState | undefined {
    const scan = useCheckoutSelector(s => s.scan);
    const scans = scansQuery.data?.scan_files ?? [];
    const scanIdFromQuery = useScanIdFromQueryString();
    const alreadySubmitted = useScanAlreadySubmittedQueryParam();

    if (scansQuery.loading) {
        return undefined;
    }

    if (scansQuery.error) {
        return 'networkError';
    }

    // If we passed a scan id in the query string
    if (!!scanIdFromQuery && scan?.id !== scanIdFromQuery && scan?.threeshape_order_id !== scanIdFromQuery) {
        if (alreadySubmitted) {
            return 'scanFromQueryAlreadyOrdered';
        }
        return 'scanFromQueryMissing';
    }
    // no scans to order
    if (scans.length === 0) {
        return 'zeroScans';
    }
    return undefined;
}

interface CheckoutSelectScanErrorProps {
    scanErrorState: ScanErrorState;
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

const CheckoutSelectScanError: React.FC<CheckoutSelectScanErrorProps> = props => {
    const history = useHistory();
    const { scanErrorState, scansQuery } = props;
    const { loading, refetch } = scansQuery;

    const refetchScans = React.useCallback(async () => {
        !loading && refetch().catch(console.error);
    }, [loading, refetch]);

    const removeScanIdFromQueryParam = React.useCallback(() => {
        const params = new URLSearchParams(history.location.search);
        params.delete('scanId');
        history.push({ ...history.location, search: params.toString() });
    }, [history]);
    const { openChat } = useChatFeatures();
    const errorSectionProps = React.useMemo<CheckoutErrorSectionProps>(() => {
        const refreshButton = { text: 'Refresh Scans List', onClick: refetchScans };
        const differentScanBtn = { text: 'Use Different Scan', onClick: removeScanIdFromQueryParam };
        switch (scanErrorState) {
            case 'networkError':
                return {
                    title: 'Error Loading Scans 😔',
                    subtitle: `Uh oh, it looks like an error occurred. Try refreshing below; if the problem persists please contact support`,
                    firstButton: refreshButton,
                };
            case 'scanFromQueryAlreadyOrdered':
                return {
                    title: 'Scan Already Ordered',
                    subtitle: `The requested scan has already been ordered.`,
                    firstButton: refreshButton,
                    secondButton: differentScanBtn,
                    children: <ChatWithSupportTextButton />,
                };
            case 'scanFromQueryMissing':
                return {
                    title: 'Error Loading Scan 😔',
                    subtitle: `Uh oh, it looks the requested scan was not found. Try refreshing below; if the problem persists please contact support`,
                    firstButton: refreshButton,
                    secondButton: differentScanBtn,
                    children: <ChatWithSupportTextButton />,
                };
            case 'zeroScans':
                return {
                    title: 'No Unsubmitted Scans Found',
                    subtitle: `If you are expecting to see a scan please try refreshing below; if the problem persists please contact support`,
                    firstButton: refreshButton,
                    secondButton: {
                        text: 'Chat with support',
                        onClick: openChat,
                    },
                    children: (
                        <Grid container justifyContent={'center'} style={{ paddingTop: 10 }}>
                            <ManualScanUploadDialog
                                CustomButton={ManualUploadButton as React.FC<ButtonProps>}
                                refetch={refetchScans}
                            />
                        </Grid>
                    ),
                };
        }
    }, [openChat, refetchScans, removeScanIdFromQueryParam, scanErrorState]);

    return <CheckoutErrorSection {...errorSectionProps} />;
};

const EditPatientName: React.FC = () => {
    const clearScan = useCheckoutAction('CLEAR_SCAN');
    const { patient_first_name, patient_last_name } = useCheckoutPropSelector([
        'patient_first_name',
        'patient_last_name',
    ]);
    const setPatientInfo = useCheckoutAction('SET_PATIENT_INFO');
    return (
        <Grid container>
            <Grid container spacing={2}>
                <Grid container item xs={12} md={6}>
                    <CheckoutTextField
                        onChange={value => setPatientInfo({ value, type: 'first' })}
                        label={'First name'}
                        required={false}
                        value={patient_first_name}
                    />
                </Grid>
                <Grid container item xs={12} md={6}>
                    <CheckoutTextField
                        onChange={value => setPatientInfo({ value, type: 'last' })}
                        label={'Last name'}
                        required={false}
                        value={patient_last_name}
                    />
                </Grid>
            </Grid>
            <Grid container>
                <CheckoutTextButton onClick={() => clearScan()} style={{ padding: '6px 0', minWidth: 'unset' }}>
                    Change Patient
                </CheckoutTextButton>
            </Grid>
        </Grid>
    );
};

const GENDER_OPTS = [{ value: 'Male' }, { value: 'Female' }, { value: 'Prefer not to say' }];

const PatientGenderBirthdaySelect: React.FC = () => {
    const classes = useStyles();
    const setPatientInfo = useCheckoutAction('SET_PATIENT_INFO');
    const scanSelected = useCheckoutSelector(s => s.scan !== undefined);
    const existingOrderScreenShowing = useExistingOrderScreenDisplayingSelector();
    const { patient_birthday, patient_gender } = useCheckoutPropSelector(['patient_gender', 'patient_birthday']);

    const [bdayOpen, setBdayOpen] = React.useState<boolean>(false);
    const genderNeedsSelect = !patient_gender && scanSelected && !existingOrderScreenShowing && !!patient_birthday;
    const [genderOpen, setGenderOpen] = React.useState<boolean>(genderNeedsSelect);
    const [autoOpened, setAutoOpened] = React.useState<boolean>(genderOpen);
    React.useEffect(() => {
        if (genderNeedsSelect && !bdayOpen && !genderOpen && !autoOpened) {
            setGenderOpen(true);
            setAutoOpened(true);
        }
    }, [genderNeedsSelect, bdayOpen, genderOpen, autoOpened]);

    return (
        <>
            <Grid container item xs={12} md={3}>
                <SimpleDatePicker
                    label={'Birthday'}
                    value={patient_birthday || null}
                    onChange={date => {
                        const newDate = !date || isNaN(date.valueOf()) ? null : date;
                        const isEqual = patient_birthday?.valueOf() === newDate?.valueOf();
                        if (!isEqual && newDate) {
                            setPatientInfo({ type: 'birthday', value: newDate });
                        }
                    }}
                    sx={{ background: !scanSelected ? FlossPalette.DARK_TAN : undefined }}
                    disabled={!scanSelected}
                    fullWidth={true}
                    slotProps={{
                        textField: {
                            required: true,
                        },
                    }}
                    views={['year', 'day']}
                    openTo={'year'}
                    slots={{
                        toolbar: undefined,
                    }}
                    disableFuture={true}
                    errorText={!patient_birthday && scanSelected ? 'Please enter birthdate' : undefined}
                    onClose={() => {
                        setBdayOpen(false);
                        !genderOpen && genderNeedsSelect && setGenderOpen(true);
                    }}
                    onOpen={() => setBdayOpen(true)}
                />
            </Grid>
            <Grid container item xs={12} md={3} className={cx({ [classes.selectWrapperFocused]: genderNeedsSelect })}>
                <CheckoutSelectField
                    required={false}
                    label={'Gender'}
                    disabled={!scanSelected}
                    options={GENDER_OPTS}
                    value={patient_gender ?? ''}
                    onChange={value => {
                        setPatientInfo({ type: 'gender', value: value ?? '' });
                    }}
                    SelectProps={{
                        open: genderOpen,
                        onOpen: () => setGenderOpen(true),
                        onClose: () => setGenderOpen(false),
                        variant: 'standard',
                    }}
                />
            </Grid>
        </>
    );
};

interface CheckoutSelectScanProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

const UPLOAD_SELECT_VAL = `_______UPLOAD_______`;

const RenderScanOption: React.FC<{ option: SimpleSelectOption }> = ({ option }) => {
    if (option.value === UPLOAD_SELECT_VAL) {
        const uploadLabel = option.label ?? 'Upload Scan';
        return <i style={{ color: FlossPalette.HIGHLIGHT_BLUE }}>{uploadLabel}</i>;
    }
    return <React.Fragment key={option.value}>{option.label ?? option.value}</React.Fragment>;
};

export const CheckoutSelectScan: React.FC<CheckoutSelectScanProps> = props => {
    const classes = useStyles();
    const needsFocused = useCheckoutSelector(s => !s.scan && !!s.doctor && !!s.address);
    const selectedScanId = useCheckoutSelector(s => s.scan?.id);
    const [uploadOpen, setUploadOpen] = React.useState<boolean>(false);
    const setScan = useCheckoutAction('SET_SCAN');
    const clearScan = useCheckoutAction('CLEAR_SCAN');
    const { patient_first_name, patient_last_name } = useCheckoutPropSelector([
        'patient_first_name',
        'patient_last_name',
    ]);
    const { scansQuery } = props;
    const { data: scansData, loading } = scansQuery;
    const scans = React.useMemo(() => scansData?.scan_files ?? [], [scansData]);
    const scanErrorState = useScanErrorState(scansQuery);
    const scanOptions = React.useMemo(() => {
        const baseOpts = scans.map(d => ({
            // we use the patient name from state in case the doctor has made edits to the name on the scan
            label:
                d.id === selectedScanId
                    ? `${patient_first_name} ${patient_last_name}`
                    : `${d.patient_first_name} ${d.patient_last_name}`,
            value: d.id,
        }));
        return [...baseOpts, { label: 'Upload 3Shape File', value: UPLOAD_SELECT_VAL }];
    }, [patient_first_name, patient_last_name, scans, selectedScanId]);

    if (scanErrorState) {
        return <CheckoutSelectScanError scanErrorState={scanErrorState} scansQuery={scansQuery} />;
    }
    return (
        <CheckoutContentSection title={'What patient is this for?'}>
            <LoadBlocker blocking={loading}>
                <Grid container spacing={2}>
                    <Grid container item xs={12} md={6}>
                        <Collapse
                            in={!selectedScanId}
                            style={{ width: '100%' }}
                            classes={{ entered: needsFocused ? classes.selectWrapperFocused : undefined }}
                        >
                            <CheckoutSelectField
                                required={false}
                                options={scanOptions}
                                onChange={value => {
                                    if (value === UPLOAD_SELECT_VAL) {
                                        return setUploadOpen(true);
                                    }
                                    const newScan = value ? scans.find(d => d.id === value) : undefined;
                                    return newScan ? setScan(newScan) : clearScan();
                                }}
                                label={'Patient'}
                                value={selectedScanId}
                                placeholder={'Select a patient'}
                                RenderOption={RenderScanOption}
                                SelectProps={{ autoFocus: true, variant: 'standard' }}
                            />
                        </Collapse>
                        <Collapse in={!!selectedScanId} style={{ width: '100%' }} mountOnEnter unmountOnExit>
                            <EditPatientName />
                        </Collapse>
                    </Grid>
                    <PatientGenderBirthdaySelect />
                </Grid>
            </LoadBlocker>
            <ManualScanUploadDialog
                CustomButton={() => null}
                refetch={props.scansQuery.refetch}
                managedOpenState={{ open: uploadOpen, setOpen: setUploadOpen }}
            />
        </CheckoutContentSection>
    );
};
