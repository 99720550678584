import { ScanbodyRequestForm } from '../component/ScanbodyRequestForm';
import { ScanbodyRequestSuccessOverlay } from '../component/ScanbodyRequestSubmittedOverlay';
import { useScanbodyRequestAction } from '../state/ScanbodyRequest.actions';
import { useScanbodyRequestPropSelector } from '../state/ScanbodyRequest.selectors';
import { ScanbodyStage } from '../state/ScanbodyRequest.types';
import { stylesFactory, FlossPalette, useScreenIsMd, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const useRootStyles = stylesFactory<{ isTablet?: boolean }>(() => ({
    titleWrapper: { height: 64, borderBottom: `1px solid ${FlossPalette.DARK_TAN}` },
    titleButtonAnchor: { width: 0, overflowX: 'visible' },
    root: props => ({
        // 56 here is the size of top bar on mobile
        marginTop: props.isTablet ? 56 : 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: FlossPalette.DARK_TAN,
    }),
    row: { alignItems: 'center', marginBottom: 32 },
    fieldText: { marginLeft: 32, fontWeight: 500 },
    block: { width: '100%', margin: 24, backgroundColor: FlossPalette.WHITE, paddingBottom: 16 },
}));

export const ScanbodyRequestDetail: React.FC = () => {
    const isTablet = useScreenIsMd();
    const classes = useRootStyles({ isTablet });
    const { stage } = useScanbodyRequestPropSelector(['stage']);
    const setStage = useScanbodyRequestAction('SET_STAGE');

    if (stage !== ScanbodyStage.DETAIL && stage !== ScanbodyStage.SUBMITTED) {
        return null;
    }
    return (
        <Grid container style={{ alignSelf: 'flex-start' }}>
            <Grid container alignItems={'center'} className={classes.titleWrapper}>
                <Grid className={classes.titleButtonAnchor}>
                    <Button
                        variant={'ghost'}
                        onClick={() => setStage(ScanbodyStage.OVERVIEW)}
                        startIcon={'ChevronLeft'}
                    >
                        Back
                    </Button>
                </Grid>
                {!isTablet && (
                    <Text variant={'h6'} align={'center'} style={{ flexGrow: 1 }}>
                        Scan body Requests
                    </Text>
                )}
            </Grid>
            <ScanbodyRequestForm />
            <ScanbodyRequestSuccessOverlay />
        </Grid>
    );
};
