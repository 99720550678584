import { FlossPalette, stylesFactory, Card, Grid, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    card: {
        borderRadius: 16,
        alignItems: 'stretch',
        boxShadow: 'none',
        padding: 24,
        background: FlossPalette.TAN,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    trackingLink: {
        paddingLeft: 6,
        display: 'flex',
        color: FlossPalette.STAR_GRASS,
        textDecoration: 'none',
        fontWeight: 600,
        alignItems: 'center',
    },
    trackingIcon: {
        marginLeft: 6,
        width: 18,
        height: 18,
    },
}));

export type PackageCardProps = {
    title: string;
    trackingNumber: string;
    children: React.ReactNode;
    variant?: string;
    isMobile?: boolean;
};

type LinkProps = {
    trackingNumber: string;
    isMobile?: boolean;
};

const Link: React.VFC<LinkProps> = props => {
    const { trackingNumber, isMobile } = props;

    const classes = useStyles();

    return (
        <Text variant={'body2'} style={{ display: 'flex' }}>
            {!isMobile && 'Track on UPS.com:'}
            <a
                data-test={'tracking-link'}
                href={`https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${trackingNumber}`}
                target={'_blank'}
                rel={'noreferrer'}
                className={classes.trackingLink}
            >
                {isMobile ? 'Track' : trackingNumber}
                <Icon icon={'CallMadeIcon'} className={classes.trackingIcon} />
            </a>
        </Text>
    );
};

export const PackageCard: React.VFC<PackageCardProps> = props => {
    const { children, title, trackingNumber, variant = '', isMobile } = props;
    const sanitizedTrackingNumber = trackingNumber.replace(' UPS', '');
    const isSingleElement = variant === 'singleItem';
    const classes = useStyles();

    return (
        <Card className={classes.card} style={{ width: '100%' }}>
            <Grid
                container
                direction={'row'}
                alignContent={'center'}
                alignItems={'center'}
                style={{ justifyContent: 'space-between', gap: '16px' }}
            >
                <Text variant={'body1'} medium>
                    {title}
                </Text>
                <Link trackingNumber={sanitizedTrackingNumber} isMobile={isMobile} />
            </Grid>
            <Grid
                xs={12}
                container
                direction={'row'}
                wrap={isSingleElement ? 'nowrap' : 'wrap'}
                alignContent={'center'}
                alignItems={'stretch'}
                style={{ justifyContent: 'space-between', marginTop: 16 }}
            >
                {children}
            </Grid>
        </Card>
    );
};
