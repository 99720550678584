import { RequiredFieldWarningToast } from '../../../account/profile-settings/notifications/NotificationMethodSelectForm';
import { emailLabel, smsLabel } from '../../../account/profile-settings/notifications/NotificationMethodToggle';
import { NotificationPreferencesNames } from '../../../account/profile-settings/notifications/Notifications.types';
import type { MultipartFormSingleStepProps } from './utils';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { QuickForm } from '@orthly/ui';
import { useScreenIsMobile, Grid, Tooltip, Text } from '@orthly/ui-primitives';
import React from 'react';

export type NotificationSettingsProps = {
    [key in NotificationPreferencesNames]: boolean;
};

export function notificationSettingsForDoctor(
    doctor: LabsGqlDoctorPreferencesFragment,
): Partial<NotificationSettingsProps> {
    return Object.values(NotificationPreferencesNames).reduce(
        (result, prefName) => ({
            ...result,
            [prefName]: doctor.grouped_notification_preferences[prefName],
        }),
        {},
    );
}

export const NotificationSettings: React.FC<MultipartFormSingleStepProps<NotificationSettingsProps>> = props => {
    const { formState, setFormState, onSubmit, customSubmit } = props;
    const [showWarning, setShowWarning] = React.useState(false);
    const validateRequiredFields = (smsValue?: boolean | null, emailValue?: boolean | null) => {
        if (!(smsValue || emailValue)) {
            setShowWarning(true);
            return false;
        }
        return true;
    };
    const isMobile = useScreenIsMobile();
    const notifSettings = [
        {
            title: 'Important status changes',
            required: true,
        },
        {
            title: isMobile ? 'Review required' : 'Review required to proceed',
            required: true,
        },
        {
            title: 'Order tracking updates',
            required: false,
        },
        {
            title: 'Communications',
            required: false,
        },
    ];

    return (
        <Grid container>
            <Grid container xs={6} style={{ height: 'fit-content' }}>
                {notifSettings.map(setting => (
                    <Grid container key={setting.title} style={{ height: 32 }}>
                        <Text variant={'body2'}>
                            {setting.title}
                            {setting.required && (
                                <Tooltip
                                    title={`To avoid delays, we require at least 1 option to be selected.`}
                                    placement={'top'}
                                >
                                    <span style={{ cursor: 'pointer' }}>*</span>
                                </Tooltip>
                            )}
                        </Text>
                    </Grid>
                ))}
            </Grid>
            <Grid container xs={6}>
                <QuickForm<NotificationSettingsProps>
                    initialValues={formState}
                    onChange={setFormState}
                    CustomSubmit={customSubmit}
                    onSubmit={values => {
                        const statusChangeValid = validateRequiredFields(
                            values['status_change_sms'],
                            values['status_change_email'],
                        );
                        const reviewRequiredValid = validateRequiredFields(
                            values['review_required_sms'],
                            values['review_required_email'],
                        );
                        if (statusChangeValid && reviewRequiredValid) {
                            void onSubmit(values);
                        }
                    }}
                    fields={{
                        status_change_email: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: emailLabel,
                            layout: { xs: 6 },
                        },
                        status_change_sms: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: smsLabel,
                            layout: { xs: 6 },
                        },
                        review_required_email: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: emailLabel,
                            layout: { xs: 6 },
                        },
                        review_required_sms: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: smsLabel,
                            layout: { xs: 6 },
                        },
                        order_tracking_email: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: emailLabel,
                            layout: { xs: 6 },
                        },
                        order_tracking_sms: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: smsLabel,
                            layout: { xs: 6 },
                        },
                        communication_email: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: emailLabel,
                            layout: { xs: 6 },
                        },
                        communication_sms: {
                            checkboxStyle: { marginLeft: 0, marginTop: 0, marginBottom: 0 },
                            type: 'boolean',
                            label: smsLabel,
                            layout: { xs: 6 },
                        },
                    }}
                />
            </Grid>
            <RequiredFieldWarningToast open={showWarning} setOpen={setShowWarning} />
        </Grid>
    );
};
