import { useOpenOrderDetailPage } from '../../labs/LabsUtils';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const STANDARD_ERROR_SUBTITLE = `It's possible that the link you've used is broken or has expired. If you believe this is an error or need further assistance, please chat with us.`;

interface TryInFeedbackErrorProps {
    title: string;
    subtitle: string;
    orderId?: string;
}

const TryInFeedbackError: React.VFC<TryInFeedbackErrorProps> = ({ title, subtitle, orderId }) => {
    const history = useHistory();
    const openOrder = useOpenOrderDetailPage();

    return (
        <Grid container style={{ height: '100%' }}>
            <Grid
                item
                container
                direction={'column'}
                spacing={2}
                style={{ margin: 'auto', maxWidth: 600, textAlign: 'center' }}
            >
                <Grid item>
                    <Text variant={'h6'} color={'BLACK'} medium>
                        {title}
                    </Text>
                </Grid>
                <Grid item>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {subtitle}
                    </Text>
                </Grid>
                <Grid item>
                    <Button variant={'primary'} onClick={() => (orderId ? openOrder(orderId) : history.goBack())}>
                        Go Back{orderId && ' to Order'}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface TryInFeedbackErrorWithOrderProps {
    orderId: string;
}

export const TryInFeedbackOrderNotFoundError: React.VFC = () => {
    return (
        <TryInFeedbackError
            title={`We couldn't find an order associated with this link.`}
            subtitle={STANDARD_ERROR_SUBTITLE}
        />
    );
};

export const TryInFeedbackAlreadyCompletedError: React.VFC<TryInFeedbackErrorWithOrderProps> = ({ orderId }) => {
    return (
        <TryInFeedbackError
            title={`Thank you for your feedback on this order!`}
            subtitle={`It looks like we've already received your feedback for this order. If you believe this is an error or need further assistance, please chat with us.`}
            orderId={orderId}
        />
    );
};

export const TryInFeedbackIneligibleError: React.VFC<TryInFeedbackErrorWithOrderProps> = ({ orderId }) => {
    return (
        <TryInFeedbackError
            title={`This order is not eligible for try-in feedback.`}
            subtitle={STANDARD_ERROR_SUBTITLE}
            orderId={orderId}
        />
    );
};

export const TryInFeedbackNoTryInError: React.VFC<TryInFeedbackErrorWithOrderProps> = ({ orderId }) => {
    return (
        <TryInFeedbackError
            title={`We couldn't find any try-ins associated with this order.`}
            subtitle={STANDARD_ERROR_SUBTITLE}
            orderId={orderId}
        />
    );
};

export const TryInFeedbackNotDeliveredError: React.VFC<TryInFeedbackErrorWithOrderProps> = ({ orderId }) => {
    return (
        <TryInFeedbackError
            title={`This order is not delivered yet.`}
            subtitle={`Once your order is delivered, you can come back to this link to provide feedback on your try-ins.`}
            orderId={orderId}
        />
    );
};
