import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const isBillingCardDismissedAtom = atomWithStorage('onboardingBillingCardDismissed', false);
const isNotificationsCardDismissedAtom = atomWithStorage('onboardingNotificationsCardDismissed', false);

export const useDismissCompleteCards = () => {
    const [isBillingCardDismissed, setIsBillingCardDismissed] = useAtom(isBillingCardDismissedAtom);
    const [isNotificationsCardDismissed, setIsNotificationsCardDismissed] = useAtom(isNotificationsCardDismissedAtom);

    const dismissBillingCard = () => setIsBillingCardDismissed(true);

    const dismissNotificationsCard = () => setIsNotificationsCardDismissed(true);

    return {
        isBillingCardDismissed,
        isNotificationsCardDismissed,
        dismissBillingCard,
        dismissNotificationsCard,
    };
};
