import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';

export const useButtonStyles = stylesFactory(() => ({
    iconButton: {
        minWidth: 'unset',
        width: 40,
        '& .MuiGrid-root': {
            width: 'auto',
        },
    },
    iconAttachmentButton: {
        '&&': {
            padding: '0px !important',
        },
        '&:hover .MuiAvatarGroup-avatar': {
            outline: `2px solid ${FlossPalette.DARK_TAN}`,
        },
    },
    active: {
        '&&': {
            color: FlossPalette.PRIMARY_FOREGROUND,
        },
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        '& .MuiAvatarGroup-avatar': {
            outline: `2px solid ${FlossPalette.PRIMARY_BACKGROUND}`,
        },
        '&:hover': {
            color: FlossPalette.PRIMARY_FOREGROUND,
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            '& .MuiAvatarGroup-avatar': {
                outline: `2px solid ${FlossPalette.PRIMARY_BACKGROUND}`,
            },
        },
    },
}));
