import { ImageTypeUploadOrder, ImageTypeUploadRequired } from '../state/aligners-checkout.selectors';
import { useMobileCheckoutStyles } from './MobileCheckout-styles';
import type { UploadedImageSourceMap } from './MobileCheckout.util';
import { ImageTypeToTitleMap, AlignerTypeIconMap } from './MobileCheckout.util';
import { MobileCheckoutUploadPopup } from './MobileCheckoutPopup';
import { useUploadedAlignerPhotoGcsPathsQuery } from '@orthly/graphql-react';
import { LabsGqlAlignerCasePhotoType as AlignerPhotoType } from '@orthly/graphql-schema';
import { FileNameUtils } from '@orthly/runtime-utils';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { NavigationLogoDandy, OrthlyBrowserConfig, OrthlyFrontendApps, SmallTrashCanIcon } from '@orthly/ui';
import { FlossPalette, Grid, IconButton, Button, Text } from '@orthly/ui-primitives';
import { useFirebasePreview, useFirebaseStorage } from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import { basename, extname } from 'path-browserify';
import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { useParams } from 'react-router-dom';

interface MobileCheckoutPhotoProps {
    scanId: string;
    setImgSrcMap: Dispatch<SetStateAction<UploadedImageSourceMap>>;
    imgSrcMap: UploadedImageSourceMap;
    type: AlignerPhotoType;
}

const MobileCheckoutPlaceholder: React.FC<{ type: AlignerPhotoType }> = ({ type }) => {
    const classes = useMobileCheckoutStyles();
    const ImageIcon = AlignerTypeIconMap[type];
    return (
        <Grid container className={classes.gridItemRoot}>
            <ImageIcon className={classes.placeholderIcon} />
        </Grid>
    );
};

interface MobileCheckoutGridImageProps {
    setImgSrcMap: Dispatch<SetStateAction<UploadedImageSourceMap>>;
    imgSrc: string;
    storagePathConfig: BucketStoragePathConfig;
    type: AlignerPhotoType;
}

function useDeleteFileCallback(imgSrc: string, type: AlignerPhotoType, storagePathConfig: BucketStoragePathConfig) {
    const { path: storagePath, bucketName } = storagePathConfig;
    const firebaseStorage = useFirebaseStorage(bucketName);
    const fileName = `${type}.${FileNameUtils.getFileExtension(imgSrc)}`;
    return React.useCallback(async () => {
        await firebaseStorage.ref(storagePath).child(fileName).delete();
    }, [storagePath, fileName, firebaseStorage]);
}

const MobileCheckoutGridImage: React.FC<MobileCheckoutGridImageProps> = props => {
    const classes = useMobileCheckoutStyles();
    const { imgSrc, setImgSrcMap, storagePathConfig, type } = props;
    const { enqueueSnackbar } = useSnackbar();

    const deleteFile = useDeleteFileCallback(imgSrc, type, storagePathConfig);
    return (
        <Grid className={classes.gridItemRoot} style={{ position: 'relative', padding: 0, display: 'flex' }}>
            <img src={imgSrc} alt={'Preview'} className={classes.imagePreview} />
            <Grid container className={classes.deleteIconWrapper}>
                <IconButton
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onClick={() =>
                        deleteFile()
                            .then(() => setImgSrcMap(prevMap => ({ ...prevMap, [type]: null })))
                            .catch(e => {
                                enqueueSnackbar(`Unable to delete image`, { variant: 'error' });
                                console.error(e);
                            })
                    }
                    className={classes.trashIconButton}
                >
                    <SmallTrashCanIcon
                        preserveAspectRatio={'xMidYMin'}
                        color={'primary'}
                        className={classes.icon}
                        style={{ color: FlossPalette.STAR_GRASS, background: FlossPalette.WHITE }}
                    />
                </IconButton>
            </Grid>
        </Grid>
    );
};

const MobileCheckoutPhoto: React.FC<MobileCheckoutPhotoProps> = props => {
    const { setImgSrcMap, imgSrcMap, type, scanId } = props;
    const config = new OrthlyBrowserConfig(OrthlyFrontendApps.practice);
    const storagePathConfig = getFullStoragePath(config.env, OrderingStorageConfigs.aligners, scanId);

    const classes = useMobileCheckoutStyles();
    const src = imgSrcMap[type];
    return (
        <Grid
            item
            container
            xs={4}
            justifyContent={'center'}
            alignItems={'center'}
            className={classes.grid}
            direction={'column'}
        >
            {!!src ? (
                <MobileCheckoutGridImage
                    setImgSrcMap={setImgSrcMap}
                    imgSrc={src}
                    storagePathConfig={storagePathConfig}
                    type={type}
                />
            ) : (
                <MobileCheckoutPlaceholder type={type} />
            )}
            <Text variant={'body2'} align={'center'} color={'BLACK'} style={{ margin: '0 4px' }}>
                {ImageTypeToTitleMap[type]}
            </Text>
        </Grid>
    );
};

const MobileCheckoutPhotoGrid: React.FC<{
    scanId: string;
    setImgSrcMap: Dispatch<SetStateAction<UploadedImageSourceMap>>;
    imgSrcMap: UploadedImageSourceMap;
    requiredPhotoValue: boolean;
}> = props => {
    const { scanId, setImgSrcMap, imgSrcMap, requiredPhotoValue } = props;
    return (
        <Grid container style={{ padding: 12 }}>
            {ImageTypeUploadOrder.map(value => {
                if ((ImageTypeUploadRequired[value] ?? false) !== requiredPhotoValue) {
                    // We want to split photos into required/optional sets; skip photos without the correct required value
                    return null;
                }
                return (
                    <MobileCheckoutPhoto
                        type={value}
                        key={value}
                        setImgSrcMap={setImgSrcMap}
                        imgSrcMap={imgSrcMap}
                        scanId={scanId}
                    />
                );
            })}
        </Grid>
    );
};

const MobileCheckoutCompleted: React.FC = () => {
    const classes = useMobileCheckoutStyles();

    return (
        <Grid className={classes.root} style={{ width: '100%' }}>
            <Text variant={'h4'} color={'BURGUNDY'} align={'center'} style={{ marginTop: 80 }}>
                Done!
            </Text>
            <Text variant={'h6'} color={'BLACK'} align={'center'}>
                Return to your laptop for your estimate
            </Text>
            <img src={'/checkout/aligner-image-complete.png'} alt={'celebration'} style={{ margin: '72px 96px 0' }} />
        </Grid>
    );
};

interface MobileCheckoutUploadPreviewProps {
    setImgSrcMap: Dispatch<SetStateAction<UploadedImageSourceMap>>;
    imgSrcMap: UploadedImageSourceMap;
    allUploaded: boolean;
    setSubmitted: (submitted: boolean) => void;
    setUploadOpen: (open: boolean) => void;
    scanId: string;
    setNumOpens: Dispatch<SetStateAction<number>>;
}

const MobileCheckoutUploadPreview: React.FC<MobileCheckoutUploadPreviewProps> = props => {
    const classes = useMobileCheckoutStyles();
    const { imgSrcMap, setImgSrcMap, setUploadOpen, allUploaded, setSubmitted, scanId, setNumOpens } = props;
    const noneUploaded = Object.values(imgSrcMap).length === 0;

    return (
        <Grid className={classes.overviewRoot}>
            <NavigationLogoDandy className={classes.logo} />
            <Text variant={'h4'} style={{ marginTop: 24 }}>
                {allUploaded ? `Great!` : `Upload photos`}
            </Text>
            <Text variant={'h6'} align={'center'} style={{ marginTop: 8, lineHeight: '24px' }}>
                {allUploaded
                    ? `Please review the photos and submit below`
                    : `We're going to need the following photos, get started below.`}
            </Text>
            <MobileCheckoutPhotoGrid
                scanId={scanId}
                setImgSrcMap={setImgSrcMap}
                imgSrcMap={imgSrcMap}
                requiredPhotoValue={true}
            />
            <Text variant={'h6'} align={'center'} style={{ marginTop: 8, lineHeight: '24px' }}>
                The following photos are optional, but still great to have.
            </Text>
            <MobileCheckoutPhotoGrid
                scanId={scanId}
                setImgSrcMap={setImgSrcMap}
                imgSrcMap={imgSrcMap}
                requiredPhotoValue={false}
            />
            <Grid container className={classes.buttonWrapper} style={{ paddingBottom: 96 }}>
                <Button
                    variant={'primary'}
                    onClick={() => {
                        if (allUploaded) {
                            setSubmitted(true);
                        }
                        setUploadOpen(true);
                        setNumOpens(prev => prev + 1);
                    }}
                    style={{ width: '100%' }}
                    endIcon={'ChevronRight'}
                >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {allUploaded ? 'Submit' : noneUploaded ? 'Get started' : 'Continue'}
                </Button>
            </Grid>
        </Grid>
    );
};

export const MobileCheckoutPhotoUpload: React.FC = () => {
    const classes = useMobileCheckoutStyles();
    const { scanId } = useParams<{ scanId: string }>();
    const { execute } = useFirebasePreview();

    const [imgSrcMap, setImgSrcMap] = React.useState<UploadedImageSourceMap>({});
    const [skippedImages, setSkippedImages] = React.useState<{ [K in AlignerPhotoType]?: boolean }>({});
    const [uploadState, setUploadState] = React.useState<AlignerPhotoType>(
        ImageTypeUploadOrder[0] ?? AlignerPhotoType.FrontPortraitSmile,
    );
    const [uploadOpen, setUploadOpen] = React.useState<boolean>(false);
    const [submitted, setSubmitted] = React.useState(false);
    const [numOpens, setNumOpens] = React.useState(0);
    const allUploaded = React.useMemo(() => {
        const nextIndex = ImageTypeUploadOrder.findIndex(type => !skippedImages[type] && !imgSrcMap[type]);
        return !ImageTypeUploadOrder[nextIndex];
    }, [skippedImages, imgSrcMap]);
    useUploadedAlignerPhotoGcsPathsQuery({
        variables: { scan_export_id: scanId },
        notifyOnNetworkStatusChange: true,
        onCompleted: data => {
            const initialMap: UploadedImageSourceMap = Object.values(AlignerPhotoType).reduce((srcMap, type) => {
                // check if the file stem matches the type
                const url = data.uploadedAlignerPhotoGCSPaths.find(path => basename(path, extname(path)) === type);
                return { ...srcMap, [type]: url ?? null };
            }, {});
            // get photo urls so we don't have to fetch everytime we want to render the image
            Object.entries<string | null>(initialMap).forEach(([type, path]) => {
                !!path &&
                    execute(path)
                        .then(result => setImgSrcMap(prevMap => ({ ...prevMap, [type]: result })))
                        .catch(() => {});
            });
        },
    });

    if (submitted) {
        return <MobileCheckoutCompleted />;
    }

    return (
        <Grid className={classes.root}>
            <MobileCheckoutUploadPreview
                setUploadOpen={setUploadOpen}
                imgSrcMap={imgSrcMap}
                setImgSrcMap={setImgSrcMap}
                allUploaded={allUploaded}
                setSubmitted={setSubmitted}
                scanId={scanId}
                setNumOpens={setNumOpens}
            />
            <MobileCheckoutUploadPopup
                uploadOpen={uploadOpen}
                setUploadOpen={setUploadOpen}
                uploadState={uploadState}
                setUploadState={setUploadState}
                scanId={scanId}
                setImgSrcMap={setImgSrcMap}
                imgSrcMap={imgSrcMap}
                numOpens={numOpens}
                skippedImages={skippedImages}
                setSkippedImages={setSkippedImages}
            />
        </Grid>
    );
};
