import { AllTryInFeedbackOptions } from '../screens/feedback-options/TryInFeedbackOption.definition';
import type {
    TryInFeedbackGroupNavigationSubStep,
    TryInFeedbackItemGroup,
    TryInFeedbackNavigation,
    TryInFeedbackState,
} from '../state/TryInFeedback.types';
import type { LabsGqlLabOrderForTryInFeedbackFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import type { ICartItemV2DTO, IDentureItem, IOrderItemV2DTO, IPartialDentureItem } from '@orthly/items';
import {
    LabOrderItemSKUType,
    OrderItemArch,
    OrderItemPartialDentureProductionType,
    OrderItemV2Utils,
    ToothUtils,
    CartItemV2Utils,
} from '@orthly/items';
import _ from 'lodash';

type TryInFeedbackDisabledReason = 'already_completed' | 'ineligible' | 'no_try_in' | 'use_refab' | 'not_delivered';

export class TryInFeedbackUtils {
    // this should not be used to determine if an order is eligible or not for try in feedback,
    // only to get more information as to why it may be disabled
    // use the can_submit_tryin_feedback property of lab order to determine if an order is eligible
    static getTryInFeedbackDisabledReason(
        order: LabsGqlLabOrderForTryInFeedbackFragment,
    ): TryInFeedbackDisabledReason | null {
        if (order.try_in_feedback) {
            return 'already_completed';
        }

        const orderItems = OrderItemV2Utils.parseItems(order.items_v2);
        const hasItemForFeedback = orderItems.some(item => CartItemV2Utils.isItemEligibleForTryInFeedback(item));
        if (!hasItemForFeedback) {
            // if we have dentures items that are delivered but not try ins, we should be using a refab instead
            if (
                orderItems.some(item => OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture)) &&
                order.status === LabsGqlLabOrderStatus.Delivered
            ) {
                return 'use_refab';
            }

            return 'no_try_in';
        }

        if (
            order.status !== LabsGqlLabOrderStatus.Delivered &&
            // we exclude these two statuses because they're just plain ineligible and will be reflected in can_submit_tryin_feedback being false
            order.status !== LabsGqlLabOrderStatus.Cancelled &&
            order.status !== LabsGqlLabOrderStatus.NeedsRefabrication
        ) {
            return 'not_delivered';
        }

        if (!order.can_submit_tryin_feedback) {
            return 'ineligible';
        }

        return null;
    }

    // returns null if item is not a valid item type
    static getFirstNavigationArch(item: IOrderItemV2DTO): TryInFeedbackGroupNavigationSubStep | null {
        return TryInFeedbackUtils.getGroupNavigationSubSteps(item)[0] ?? null;
    }

    static getGroupNavigationSubSteps(item: IOrderItemV2DTO): TryInFeedbackGroupNavigationSubStep[] {
        if (!OrderItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Partial, LabOrderItemSKUType.Denture])) {
            return [];
        }
        // only metal partial wax rims actually require feedback, for non-metal wax rims we just need to know what to do next
        if (
            OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial) &&
            item.partial_production_type === OrderItemPartialDentureProductionType.WaxRim &&
            !['Chrome-Cobalt', 'Titanium'].includes(item.unit.material)
        ) {
            return ['next_steps'];
        }

        const arch = TryInFeedbackUtils.getItemArch(item);
        return _.compact([
            OrderItemArch.Dual,
            arch === OrderItemArch.Upper || arch === OrderItemArch.Dual ? OrderItemArch.Upper : null,
            arch === OrderItemArch.Lower || arch === OrderItemArch.Dual ? OrderItemArch.Lower : null,
            'next_steps',
        ]).filter(subStep => TryInFeedbackUtils.hasOptionsForSubStep(item, subStep));
    }

    static hasOptionsForSubStep(item: IOrderItemV2DTO, subStep: TryInFeedbackGroupNavigationSubStep): boolean {
        if (subStep === 'next_steps') {
            return true;
        }
        const applicableOptions = AllTryInFeedbackOptions.filter(option => {
            if (!OrderItemV2Utils.itemIsType(item, option.sku) || !option.isEnabled(item)) {
                return false;
            }
            if (!TryInFeedbackUtils.doesOptionArchMatchSubStepForItem(item, subStep, option)) {
                return false;
            }
            return true;
        });
        return applicableOptions.length > 0;
    }

    // if we have a dual arch item, then non-arch specific steps are brought out into the general (dual arch) step
    // otherwise, for a single arch item, then non-arch specific steps are still shown under that arch
    static doesOptionArchMatchSubStepForItem(
        item: IOrderItemV2DTO,
        arch: OrderItemArch,
        option: (typeof AllTryInFeedbackOptions)[number],
    ): boolean {
        if (!OrderItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Partial, LabOrderItemSKUType.Denture])) {
            return false;
        }
        const isDualArchItem = TryInFeedbackUtils.getItemArch(item) === OrderItemArch.Dual;
        // if we do have a dual arch item, then we check if the option is arch specific and whether we're on a specific arch or general (dual)
        if (isDualArchItem) {
            return (
                (!option.isArchSpecific && arch === OrderItemArch.Dual) ||
                (option.isArchSpecific && arch !== OrderItemArch.Dual)
            );
        }
        // if we do not have a dual arch item, then steps will apply to individual arches and not dual
        return arch !== OrderItemArch.Dual;
    }

    static needsReturn(state: Pick<TryInFeedbackState, 'itemGroups'>): boolean {
        return TryInFeedbackUtils.itemsNeedReturn(state.itemGroups.map(group => group.orderItem));
    }

    static itemsNeedReturn(items: IOrderItemV2DTO[]): boolean {
        return items.some(item => OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial));
    }

    static needsRescan(state: Pick<TryInFeedbackState, 'itemGroups'>): boolean {
        return TryInFeedbackUtils.feedbackNeedsRescan(
            state.itemGroups.map(group => group.orderItem),
            state.itemGroups.flatMap(group => group.feedbackDetails),
        );
    }

    static feedbackNeedsRescan(items: IOrderItemV2DTO[], feedbackDetails: { category: string }[]) {
        if (TryInFeedbackUtils.itemsNeedReturn(items)) {
            return false;
        }
        return feedbackDetails.some(detail => ['VDO', 'Retention', 'Extensions', 'Bite'].includes(detail.category));
    }

    static getNextNavigation(
        state: Pick<TryInFeedbackState, 'itemGroups' | 'navigation'>,
    ): TryInFeedbackNavigation | null {
        const { itemGroups, navigation } = state;
        // if navigation hasn't been initialized or there are no next steps return null
        if (!navigation || navigation.step === 'submit') {
            return null;
        }
        if (navigation.step === 'return_instructions') {
            return { step: 'submit' };
        }
        const activeGroup = itemGroups[navigation.groupIndex];
        // should never happen
        if (!activeGroup) {
            return null;
        }
        const subSteps = TryInFeedbackUtils.getGroupNavigationSubSteps(activeGroup.orderItem);
        const currentSubStepIndex = subSteps.indexOf(navigation.subStep);
        const nextSubStep = subSteps[currentSubStepIndex + 1];
        if (nextSubStep) {
            return { ...navigation, subStep: nextSubStep };
        }
        const nextGroupIndex = navigation.groupIndex + 1;
        const nextGroup = itemGroups[nextGroupIndex];
        if (nextGroup) {
            const nextGroupFirstSubStep = TryInFeedbackUtils.getFirstNavigationArch(nextGroup.orderItem);
            if (nextGroupFirstSubStep) {
                return { step: 'group', groupIndex: nextGroupIndex, subStep: nextGroupFirstSubStep };
            }
        }
        if (TryInFeedbackUtils.needsReturn(state)) {
            return { step: 'return_instructions' };
        }
        return { step: 'submit' };
    }

    private static getGroupLastNavigation(
        itemGroups: TryInFeedbackItemGroup[],
        groupIndex: number,
    ): TryInFeedbackNavigation | null {
        const itemGroup = itemGroups[groupIndex];
        if (itemGroup) {
            const groupSubSteps = TryInFeedbackUtils.getGroupNavigationSubSteps(itemGroup.orderItem);
            const groupLastSubStep = groupSubSteps[groupSubSteps.length - 1];
            if (groupLastSubStep) {
                return { step: 'group', groupIndex: groupIndex, subStep: groupLastSubStep };
            }
        }
        return null;
    }

    static getPreviousNavigation(
        state: Pick<TryInFeedbackState, 'itemGroups' | 'navigation'>,
    ): TryInFeedbackNavigation | null {
        const { itemGroups, navigation } = state;
        // if navigation hasn't been initialized return null
        if (!navigation) {
            return null;
        }
        if (navigation.step === 'submit') {
            if (TryInFeedbackUtils.needsReturn(state)) {
                return { step: 'return_instructions' };
            }
            return TryInFeedbackUtils.getGroupLastNavigation(itemGroups, itemGroups.length - 1);
        }
        if (navigation.step === 'return_instructions') {
            return TryInFeedbackUtils.getGroupLastNavigation(itemGroups, itemGroups.length - 1);
        }
        const activeGroup = itemGroups[navigation.groupIndex];
        // should never happen
        if (!activeGroup) {
            return null;
        }
        const subSteps = TryInFeedbackUtils.getGroupNavigationSubSteps(activeGroup.orderItem);
        const currentSubStepIndex = subSteps.indexOf(navigation.subStep);
        const previousSubStep = subSteps[currentSubStepIndex - 1];
        if (previousSubStep) {
            return { ...navigation, subStep: previousSubStep };
        }
        return TryInFeedbackUtils.getGroupLastNavigation(itemGroups, navigation.groupIndex - 1);
    }

    static getActiveGroup(state: Pick<TryInFeedbackState, 'itemGroups' | 'navigation'>): TryInFeedbackItemGroup | null {
        if (state.navigation?.step !== 'group') {
            return null;
        }
        return state.itemGroups[state.navigation.groupIndex] ?? null;
    }

    static isActiveFeedbackGroupIncomplete(state: Pick<TryInFeedbackState, 'itemGroups' | 'navigation'>): boolean {
        const { itemGroups, navigation } = state;
        if (navigation?.step === 'group') {
            const activeGroup = itemGroups[navigation.groupIndex];
            // broken state, just bail
            if (!activeGroup) {
                return false;
            }
            if (navigation.subStep === 'next_steps') {
                return false;
            }
            return !activeGroup.feedbackDetails
                .filter(detail => detail.arch === navigation.subStep)
                .every(detail => detail.description);
        }
        return false;
    }

    static getPartialArchType(item: IPartialDentureItem): OrderItemArch {
        if (ToothUtils.isDual(item.unit.unns)) {
            return OrderItemArch.Dual;
        }
        if (ToothUtils.isLower(item.unit.unns)) {
            return OrderItemArch.Lower;
        }
        if (ToothUtils.isUpper(item.unit.unns)) {
            return OrderItemArch.Upper;
        }
        // if no unns we default to dual
        return OrderItemArch.Dual;
    }

    static getItemArch(item: IPartialDentureItem | IDentureItem): OrderItemArch {
        if (OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial)) {
            return TryInFeedbackUtils.getPartialArchType(item);
        }
        return item.unit.arch;
    }

    static getItemTypeTitle(item: ICartItemV2DTO): string {
        if (
            CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial) &&
            item.partial_production_type === OrderItemPartialDentureProductionType.WaxRim
        ) {
            return 'Bite Rim';
        }
        return 'Try-In';
    }
}
