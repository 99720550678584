import { useCurrentSessionIsAdmin } from '../../../../redux/selectors';
import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { CreateNewEmployee } from '../doctors/CreateNewEmployee';
import { PracticeEmployeesTabs } from './PracticeEmployeesTabs';
import { StaffRoutes } from './staff-paths';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const PracticeEmployees: React.FC = () => {
    const history = useHistory();
    const isAdmin = useCurrentSessionIsAdmin();

    return (
        <PracticeSettingsBase
            title={'Staff'}
            titleHasBackButton={true}
            titleAction={
                isAdmin && (
                    <CreateNewEmployee
                        text={'Add new staff member'}
                        createAction={() => {
                            history.push(StaffRoutes.add_staff);
                        }}
                    />
                )
            }
            Content={<PracticeEmployeesTabs />}
        />
    );
};
