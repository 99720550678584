import React from 'react';
import { z } from 'zod';

const emailLoginSchema = z.object({
    email: z.string().email({ message: 'Invalid email address' }),
    password: z.string().nonempty({ message: 'Password cannot be empty' }),
});

export const useEmailLoginValidation = ({ email, password }: { email: string; password: string }) => {
    const [showEmailError, setShowEmailError] = React.useState(false);
    const [showPasswordError, setShowPasswordError] = React.useState(false);

    const validation = React.useMemo(() => emailLoginSchema.safeParse({ email, password }), [email, password]);

    if (validation.success) {
        return { isFormValid: true, setShowEmailError, setShowPasswordError };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: false,
        setShowEmailError,
        setShowPasswordError,
        emailError: showEmailError && fieldErrors.email?.[0],
        passwordError: showPasswordError && fieldErrors.password?.[0],
    };
};
