import { Icon, Tooltip, styled } from '@orthly/ui-primitives';

const CheckboxLabelText = styled('div')({
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
    width: '100%',
});

export const CheckboxLabel: React.FC<{ helpText?: React.ReactNode }> = ({ children, helpText }) => {
    return (
        <CheckboxLabelText>
            {children}
            {helpText && (
                <Tooltip title={helpText} arrow placement={'right'}>
                    <Icon icon={'InfoOutlinedIcon'} />
                </Tooltip>
            )}
        </CheckboxLabelText>
    );
};
