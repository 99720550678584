import { getPresetTitle } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { Format } from '@orthly/runtime-utils';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const UnfinishedPresetsMessage: React.FC = () => {
    const { unfinishedPresets } = useGuidedWaxupContext();

    if (unfinishedPresets.length === 0) {
        return null;
    }

    return (
        <Text variant={'caption'} color={'GRAY'}>
            {`Please complete required fields for `}
            <strong>{Format.joinWithAnd(unfinishedPresets.map(presetType => getPresetTitle(presetType)))}</strong>
        </Text>
    );
};
