import { EditAddressAction } from '../../../actions/practitioners/EditAddressAction';
import { EditDoctorAction } from '../../../actions/practitioners/EditDoctorAction';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useLabsAddresses } from '@orthly/graphql-react';
import { OrderFormatUtils } from '@orthly/shared-types';
import { styled, Text, useScreenIsMobile } from '@orthly/ui-primitives';
import { startCase } from 'lodash';
import React from 'react';

const CaptionsText = styled(Text)({
    whiteSpace: 'nowrap',
});
const CaptionsWrappedText = styled(Text)({
    maxWidth: 200,
});
const TopbarItemEditWrapper = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
});
const MinWidthContainer = styled('div')({
    minWidth: '100px',
});

interface TopbarCaptionsProps {
    order: LabsGqlOrder;
    showCopyToClipboard?: boolean;
    isMobile?: boolean;
    refetch: () => Promise<void>;
}

function useOrderAddressLabel(mailing_address_id?: string, partner_id?: string) {
    const { addresses } = useLabsAddresses({ variables: { partner_id: partner_id ?? '' }, skip: !partner_id });
    return React.useMemo<string>(() => {
        const address = addresses.find(a => a.id === mailing_address_id);
        return startCase(address?.street_one?.toLowerCase());
    }, [addresses, mailing_address_id]);
}

export const TopbarCaptions: React.FC<TopbarCaptionsProps> = ({ order, showCopyToClipboard, refetch }) => {
    const doctorLabel = OrderFormatUtils.getDisplayedStaffMemberName(order?.doctor_name);
    const addressLabel = useOrderAddressLabel(order?.mailing_address_id, order?.partner_id);
    const isMobile = useScreenIsMobile();

    return (
        <>
            <TopbarItemEditWrapper>
                <MinWidthContainer>
                    <CaptionsText variant={'caption'} color={'GRAY'}>
                        Doctor
                    </CaptionsText>
                    <CaptionsWrappedText variant={'body2'}>{doctorLabel}</CaptionsWrappedText>
                </MinWidthContainer>
                {!isMobile && <EditDoctorAction order={order} isIconOnly refetch={refetch} />}
            </TopbarItemEditWrapper>
            <TopbarItemEditWrapper>
                <MinWidthContainer>
                    <CaptionsText variant={'caption'} color={'GRAY'}>
                        Delivery address
                    </CaptionsText>
                    <CaptionsWrappedText variant={'body2'}>{addressLabel || '—'}</CaptionsWrappedText>
                </MinWidthContainer>
                {!isMobile && <EditAddressAction order={order} isIconOnly refetch={refetch} />}
            </TopbarItemEditWrapper>
            <div>
                <CaptionsText variant={'caption'} color={'GRAY'}>
                    Order ID
                </CaptionsText>
                {showCopyToClipboard ? (
                    <SimpleCopyToClipboard
                        size={'small'}
                        variant={'primary'}
                        tooltip={'Copy order ID'}
                        value={order?.order_number ?? ''}
                    >
                        <CaptionsWrappedText variant={'body2'}>{order?.order_number}</CaptionsWrappedText>
                    </SimpleCopyToClipboard>
                ) : (
                    <CaptionsWrappedText variant={'body2'}>{order?.order_number}</CaptionsWrappedText>
                )}
            </div>
        </>
    );
};
