import React from 'react';

type HandleBeforeUnloadEvent = (event: BeforeUnloadEvent) => void;

export const useBeforeUnload = (handler: HandleBeforeUnloadEvent, enabled: boolean = true) => {
    const eventListenerRef = React.useRef<HandleBeforeUnloadEvent>();

    React.useEffect(() => {
        eventListenerRef.current = (event: BeforeUnloadEvent) => {
            handler(event);
            // Legacy `event.returnValue` property is no longer supported so we can't set a custom message.
            // Chrome doesn't support `event.preventDefault()` on `BeforeUnloadEvent`,
            // instead it requires `event.returnValue` to be set
            // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#browser_compatibility
            if (event.defaultPrevented) {
                event.returnValue = '';
            }
        };
    }, [handler]);

    React.useEffect(() => {
        if (enabled) {
            const eventListener = (event: BeforeUnloadEvent) =>
                eventListenerRef.current && eventListenerRef.current(event);
            window.addEventListener('beforeunload', eventListener);
            return () => {
                window.removeEventListener('beforeunload', eventListener);
            };
        }
    }, [enabled]);
};
