import {
    FlossPalette,
    stylesFactory,
    useScreenIsMobile,
    Divider,
    Grid,
    IconButton,
    Slide,
    Icon,
    Button,
    Text,
} from '@orthly/ui-primitives';
import FaviconBlack from '@orthly/ui/assets/logos/DandyFaviconDarkBlack.svg';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        position: 'absolute',
        bottom: 0,
        right: 16,
        width: 380,
        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
        zIndex: theme.zIndex.appBar,
        [theme.breakpoints.down('sm')]: {
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            zIndex: theme.zIndex.appBar - 1,
            background: FlossPalette.WHITE,
        },
    },
    header: {
        padding: '20px 24px',
        background: '#25484C',
    },
    body: {
        padding: 24,
        background: FlossPalette.WHITE,
        height: 350,
    },
    actions: {
        flexWrap: 'nowrap',
    },
    iconButton: {
        background: 'none',
        margin: 0,
        padding: 0,
        borderRadius: '100%',
        '&:hover': {
            background: 'rgba(255, 255, 255, 0.08) !important',
            border: '1px solid transparent',
        },
        '&:focus': {
            background: 'none !important',
        },
        '&:active': {
            background: 'rgba(255, 255, 255, 0.12) !important',
        },
    },
}));

interface ZendeskChatUnavailableProps {
    open: boolean;
    onClose?: () => void;
}

export const ZendeskChatUnavailablePanel: React.VFC<ZendeskChatUnavailableProps> = props => {
    const { open, onClose } = props;
    const classes = useStyles();
    const isMobile = useScreenIsMobile();

    let closeButton = null;

    if (!isMobile && onClose) {
        closeButton = (
            <IconButton className={classes.iconButton} onClick={onClose}>
                <Icon icon={'ChevronDown'} style={{ color: FlossPalette.WHITE }} />
            </IconButton>
        );
    }

    return (
        <Slide direction={'up'} mountOnEnter unmountOnExit in={open} timeout={{ enter: 300, exit: 300 }}>
            <div className={classes.root}>
                <Grid
                    container
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    className={classes.header}
                >
                    <Grid item style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
                        <img src={FaviconBlack} alt={''} style={{ marginRight: 12 }} />
                        <Text variant={'body1'} medium color={'WHITE'}>
                            Chat with Dandy
                        </Text>
                    </Grid>

                    <Grid item>{closeButton}</Grid>
                </Grid>

                <Grid
                    container
                    className={classes.body}
                    direction={'column'}
                    justifyContent={'center'}
                    alignContent={'center'}
                >
                    <Text variant={'body1'} medium align={'center'}>
                        Our live chat support hours are:
                    </Text>

                    <Text variant={'body2'} color={'DARK_GRAY'} align={'center'} style={{ marginTop: 8 }}>
                        Monday through Friday 8AM to 9PM <br />
                        and Saturday 9AM to 4PM EST
                    </Text>

                    <Grid item style={{ margin: '16px 0' }}>
                        <Divider light />
                    </Grid>

                    <Text variant={'body2'} color={'DARK_GRAY'} align={'center'} style={{ marginBottom: 16 }}>
                        Need help now?
                    </Text>

                    <Grid
                        container
                        direction={'row'}
                        className={classes.actions}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Button variant={'primary'} onClick={() => window.open('https://help.meetdandy.com', '_blank')}>
                            Visit the help center
                        </Button>
                        <Text variant={'body2'}>or</Text>
                        <Button
                            variant={'ghost'}
                            onClick={() => (window.location.href = 'mailto:doctors@meetdandy.com')}
                            style={{ padding: 0, height: 'unset' }}
                        >
                            Send us an email
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </Slide>
    );
};
