import { PracticeOrderChatV2 } from './PracticeOrderChatV2/PracticeOrderChatV2';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useListChatMessagesQuery } from '@orthly/graphql-react';
import { UuidUtils } from '@orthly/runtime-utils';
import React from 'react';

// This wrapper component allows for injection of the practice chat timeline into other areas of the codebase
// outside of the practice app
export const PracticeOrderChatWrapper: React.FC<{
    order: LabsGqlSingleLabOrderFragment;
}> = ({ order }) => {
    const { refetch: refetchChatMessages } = useListChatMessagesQuery({
        variables: { entity_ids: [order.id] },
        skip: !UuidUtils.isUUID(order.id),
    });

    return <PracticeOrderChatV2 order={order} refetchMessages={refetchChatMessages} />;
};
