import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import type { PatientFormPatient } from '@orthly/dentin';
import { PracticeScreen, DandySearchInput, ScanbodyImage } from '@orthly/dentin';
import type { LabsGqlScanbodyFragment } from '@orthly/graphql-operations';
import { useGetCaseReadinessCasesQuery } from '@orthly/graphql-react';
import type { ToothNumber } from '@orthly/items';
import { CartItemV2Utils } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { ShippingUtils } from '@orthly/shared-types';
import { LoadBlocker, PopOutIcon } from '@orthly/ui';
import { FlossPalette, Divider, Grid, Link, createStyles, makeStyles, Text } from '@orthly/ui-primitives';
import { ConnectedPatientForm } from '@orthly/veneer';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        history: {
            paddingTop: 24,
            gap: 12,
        },
        case: {
            padding: 12,
            backgroundColor: FlossPalette.WHITE,
            borderRadius: 16,
            marginTop: 16,
            gap: 8,
            border: `1px solid ${FlossPalette.DARK_TAN}`,
        },
        scanbodyImage: {
            width: 24,
            height: 24,
            background: FlossPalette.WHITE,
            borderColor: FlossPalette.DARK_TAN,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 8,
            marginRight: 16,
            '& img': {
                maxWidth: '100%',
                maxHeight: '100%',
            },
        },
    }),
);

const History: React.VFC = () => {
    const classes = useStyles();
    const [search, setSearch] = React.useState('');
    const { data, loading } = useGetCaseReadinessCasesQuery({});
    const lowerCaseSearch = search.toLowerCase();
    const cases =
        data?.getCaseReadinessCases?.filter(c => {
            if (lowerCaseSearch === '') {
                return true;
            }

            return lowerCaseSearch
                .split(' ')
                .every(
                    term =>
                        c.patient?.first_name.toLowerCase().includes(term) ||
                        c.patient?.last_name.toLowerCase().includes(term) ||
                        c.scanbody_requests?.some(
                            ({ scanbody }) =>
                                scanbody?.name.toLowerCase().includes(term) ||
                                scanbody?.manufacturer.toLowerCase().includes(term),
                        ),
                );
        }) ?? [];

    return (
        <LoadBlocker blocking={loading}>
            <Grid container className={classes.history} direction={'column'}>
                <Text variant={'h6'}>History</Text>
                <DandySearchInput
                    search={search}
                    onInputChange={value => setSearch(value)}
                    onInputFocus={() => {}}
                    onInputClear={() => setSearch('')}
                    placeholder={'Search for patient or scan body'}
                />
                <Text variant={'body2'} color={'GRAY'}>
                    {data?.getCaseReadinessCases.length === 0
                        ? 'Your history is empty. Once you request a scan body, it will be visible here for easy reference and tracking.'
                        : cases.length === 0 && 'No results found.'}
                </Text>
                {cases.map(caseItem => {
                    const shipment = caseItem.scanbody_requests?.[0]?.shipment;
                    // eslint-disable-next-line no-nested-ternary
                    const shipmentStatus = !shipment
                        ? 'Order placed'
                        : shipment.status === 'DELIVERED'
                          ? 'Delivered'
                          : 'Expected';
                    const eta = dayjs(shipment?.eta).format('MM/DD');
                    const items = CartItemV2Utils.parseItems(caseItem.items);
                    function trackPackage() {
                        if (!shipment) {
                            return;
                        }
                        const link = ShippingUtils.getTrackingLinkForCarrier(
                            shipment.carrier,
                            shipment.tracking_number,
                        );
                        window.open(link, '_blank');
                    }
                    const unns = _.sortBy(items.flatMap(item => item && CartItemV2Utils.getUniqueUNNs(item)));

                    const groupedScanbodies =
                        caseItem.scanbody_requests?.reduce<
                            {
                                unns: ToothNumber[];
                                scanbody: Pick<LabsGqlScanbodyFragment, 'name' | 'manufacturer'>;
                            }[]
                        >((groups, request) => {
                            if (!request.scanbody || groups.some(g => g.scanbody === request.scanbody)) {
                                return groups;
                            }
                            const matchingItems = items.filter(i => {
                                const metadata = CartItemV2Utils.itemTypeHasImplant(i)
                                    ? CartItemV2Utils.getImplantMetadata(i)
                                    : {};
                                return (
                                    metadata?.manufacturer === request.manufacturer &&
                                    metadata.connection_size === request.connection &&
                                    metadata.system === request.system
                                );
                            });

                            return [
                                ...groups,
                                {
                                    scanbody: request.scanbody,
                                    unns: CartItemV2Utils.getItemGroupUniqueUNNs(matchingItems),
                                },
                            ];
                        }, []) ?? [];
                    return (
                        <Grid container key={caseItem.id} direction={'column'} className={classes.case}>
                            <Text variant={'body2'} color={'GRAY'}>
                                Case
                            </Text>
                            <Text variant={'body2'} color={'BLACK'} medium>
                                {caseItem.patient?.first_name} {caseItem.patient?.last_name}'s{' '}
                                {Format.pluralizeNoun('Implant', unns.length)} {CartItemV2Utils.getUnnsDisplay(unns)}
                            </Text>
                            {groupedScanbodies.length === 0 && (
                                <>
                                    <Text variant={'body2'} color={'GRAY'}>
                                        Status
                                    </Text>
                                    <Text variant={'body2'} color={'BLACK'} medium>
                                        In preparation
                                    </Text>
                                </>
                            )}
                            {groupedScanbodies.map(group => {
                                const unnDisplay = CartItemV2Utils.getUnnsDisplay(group.unns);
                                const count = group.unns.length;
                                return (
                                    <React.Fragment key={group.scanbody?.name}>
                                        <Text variant={'body2'} color={'GRAY'}>
                                            {groupedScanbodies.length > 1
                                                ? `For ${Format.pluralizeNoun('Implant', count)} ${unnDisplay}`
                                                : 'Item'}
                                        </Text>
                                        <Grid container wrap={'nowrap'}>
                                            <Grid container justifyContent={'center'} className={classes.scanbodyImage}>
                                                <ScanbodyImage
                                                    scanbodyName={group.scanbody.name}
                                                    scanbodyManufacturer={group.scanbody.manufacturer}
                                                />
                                            </Grid>
                                            <Text variant={'body2'} color={'BLACK'} medium>
                                                {group.scanbody.manufacturer} {group.scanbody.name}
                                            </Text>
                                        </Grid>
                                    </React.Fragment>
                                );
                            })}
                            <Divider />
                            {shipment && shipmentStatus === 'Expected' && (
                                <Link style={{ cursor: 'pointer' }} onClick={trackPackage}>
                                    <Text variant={'body2'} color={'GREEN'} medium>
                                        Track on {ShippingUtils.getCarrierDisplayLink(shipment?.carrier)}
                                        <PopOutIcon style={{ marginLeft: 8, marginBottom: -6 }} />
                                    </Text>
                                </Link>
                            )}
                            <Text variant={'body2'} color={'GRAY'}>
                                {shipmentStatus} {eta}
                            </Text>
                        </Grid>
                    );
                })}
            </Grid>
        </LoadBlocker>
    );
};

const Patient: React.VFC = () => {
    const { implantReadinessId, patient } = useImplantReadinessPropSelector(['patient', 'implantReadinessId']);
    const setPatientAction = useImplantReadinessAction('SET_PATIENT');

    const setPatient = (patient: PatientFormPatient) => {
        setPatientAction(patient);
        AnalyticsClient.track('Practice - Implant Readiness - Patient Selected', {
            implantReadinessId,
            patientId: patient.id,
        });
    };

    return (
        <Grid item xs={12} style={{ marginTop: 24 }}>
            <ConnectedPatientForm
                onNewPatientCreated={patient => setPatient(patient)}
                onExistingPatientSelected={patient => setPatient(patient)}
                analytics={{ AssetLocation: 'Practice - Implant Readiness' }}
                patient={patient}
                onPatientCleared={() => setPatientAction(undefined)}
                // Button shown when user goes back to patient select
                onNextClicked={() => setPatientAction(patient)}
            />
        </Grid>
    );
};

export const PatientStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/patient`,
    component: Patient,
    pageTitle: () => ({
        title: "Let's prepare for your upcoming implant restoration",
        header: "Who's the patient?",
    }),
    sidebar: () => <History />,
};
