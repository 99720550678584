import { TryInFeedbackUtils } from '../utils/TryInFeedback.util';
import { TryInFeedbackActions } from './TryInFeedback.action';
import type { TryInFeedbackItemGroup, TryInFeedbackNavigation, TryInFeedbackState } from './TryInFeedback.types';
import type { ICartItemV2DTO } from '@orthly/items';
import {
    CartItemV2UpdateUtils,
    ItemDataFieldUtils,
    LabOrderItemSKUType,
    OrderItemV2Utils,
    CartItemV2Utils,
} from '@orthly/items';
import _ from 'lodash';
import { handleActions } from 'redux-actions';

const initialState: TryInFeedbackState = { itemGroups: [], showError: false };

export const tryInFeedbackReducer = handleActions<TryInFeedbackState, any>(
    {
        ...TryInFeedbackActions.INITIALIZE.reducer<TryInFeedbackState>((_state, action) => {
            const orderItems = OrderItemV2Utils.parseItems(action.payload.order.items_v2);
            const applicableItems = orderItems.filter(item => CartItemV2Utils.isItemEligibleForTryInFeedback(item));
            const itemGroups: TryInFeedbackItemGroup[] = applicableItems.map(item => {
                const baseNewItem: ICartItemV2DTO = _.omit(item, [
                    'id',
                    'pricing',
                    'partial_production_type',
                    'denture_production_type',
                ]) as ICartItemV2DTO;
                // filter out all preference fields that are replaced by dedicated properties
                // as we'll manipulate the dedicated properties directly and they'll be re-created server side post submission
                const newItem = {
                    ...baseNewItem,
                    preference_fields: baseNewItem.preference_fields.filter(
                        pref => !ItemDataFieldUtils.allReplacedMetafieldIds.includes(pref.field_id),
                    ),
                };
                return {
                    newItem,
                    itemId: item.id,
                    orderItem: item,
                    feedbackDetails: [],
                };
            });
            const firstGroup = itemGroups[0];
            const navigationArch = firstGroup ? TryInFeedbackUtils.getFirstNavigationArch(firstGroup.orderItem) : null;
            const navigation: TryInFeedbackNavigation | undefined = navigationArch
                ? { step: 'group', groupIndex: 0, subStep: navigationArch }
                : undefined;

            return {
                ...initialState,
                itemGroups,
                navigation,
                orderId: action.payload.order.id,
                patient: _.pick(action.payload.order.patient, ['first_name', 'last_name']),
                copiedOrderItems: orderItems.filter(
                    item =>
                        OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture) &&
                        !CartItemV2Utils.isItemEligibleForTryInFeedback(item) &&
                        // just for safety so we don't double count items
                        !itemGroups.some(group => group.itemId === item.id),
                ),
            };
        }),
        ...TryInFeedbackActions.SET_NAVIGATION.reducer<TryInFeedbackState>((state, action) => ({
            ...state,
            navigation: action.payload.navigation,
            // if we're changing navigation, we should stop showing errors
            showError: false,
        })),
        ...TryInFeedbackActions.UPDATE_NEW_ITEM.reducer<TryInFeedbackState>((state, action) => ({
            ...state,
            itemGroups: state.itemGroups.map(group => {
                if (group.itemId !== action.payload.itemId) {
                    return group;
                }
                return {
                    ...group,
                    newItem: CartItemV2UpdateUtils.updateItem(group.newItem, action.payload.update),
                };
            }),
        })),
        ...TryInFeedbackActions.SELECT_FEEDBACK_DETAIL.reducer<TryInFeedbackState>((state, action) => ({
            ...state,
            itemGroups: state.itemGroups.map(group => {
                if (group.itemId !== action.payload.itemId) {
                    return group;
                }
                // if we've already selected this category, do nothing
                if (
                    group.feedbackDetails.some(
                        detail => detail.category === action.payload.category && detail.arch === action.payload.arch,
                    )
                ) {
                    return group;
                }
                return {
                    ...group,
                    feedbackDetails: [
                        ...group.feedbackDetails,
                        { category: action.payload.category, arch: action.payload.arch },
                    ],
                };
            }),
        })),
        ...TryInFeedbackActions.REMOVE_FEEDBACK_DETAIL.reducer<TryInFeedbackState>((state, action) => ({
            ...state,
            itemGroups: state.itemGroups.map(group => {
                if (group.itemId !== action.payload.itemId) {
                    return group;
                }
                return {
                    ...group,
                    feedbackDetails: group.feedbackDetails.filter(
                        detail => !(detail.category === action.payload.category && detail.arch === action.payload.arch),
                    ),
                };
            }),
        })),
        ...TryInFeedbackActions.UPDATE_FEEDBACK_DETAIL.reducer<TryInFeedbackState>((state, action) => {
            const newState: TryInFeedbackState = {
                ...state,
                itemGroups: state.itemGroups.map(group => {
                    if (group.itemId !== action.payload.itemId) {
                        return group;
                    }
                    return {
                        ...group,
                        feedbackDetails: group.feedbackDetails.map(detail => {
                            if (detail.category === action.payload.category && detail.arch === action.payload.arch) {
                                return { ...detail, description: action.payload.description };
                            }
                            return detail;
                        }),
                    };
                }),
            };
            // if the current group is now complete because of the update, stop showing errors
            return !TryInFeedbackUtils.isActiveFeedbackGroupIncomplete(newState)
                ? { ...newState, showError: false }
                : newState;
        }),
        ...TryInFeedbackActions.SET_SHOW_ERROR.reducer<TryInFeedbackState>((state, action) => ({
            ...state,
            showError: action.payload.showError,
        })),
        ...TryInFeedbackActions.SET_SUBMISSION_DATA.reducer<TryInFeedbackState>((state, action) => ({
            ...state,
            submissionData: action.payload.submissionData,
        })),
    },
    initialState,
);
