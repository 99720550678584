import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';

export enum OrderAction {
    ModifyRx = 'Modify Rx',
    Download = 'Download',
    Print = 'Print',
    CaptureNewScans = 'Capture new scans',
    PauseOrder = 'Pause order',
    ResumeOrder = 'Resume order',
    CancelOrder = 'Cancel order',
    UploadImageOrFile = 'Upload image or file ',
    AttachExistingScan = 'Attach existing scan',
    UploadOrTakePhoto = 'Upload or take a photo',
    LeaveNote = 'Leave note',
    AttachPhoto = 'Attach photo',
    Refabricate = 'Refabricate',
    ProvideInstructions = 'Provide instructions',
    ProvideProductFeedback = 'Provide product feedback',
}

export enum OrderActionParent {
    EditOrAddOrderInformation = 'Edit or add order information',
    MobileActionsMenu = 'Mobile action menu',
    MobileControlPanel = 'Mobile control panel',
    Global = 'Global',
}

export const OrderActionTrackerIdMap: Record<OrderAction, string> = {
    [OrderAction.ModifyRx]: 'modify-rx-tracker-id',
    [OrderAction.Download]: 'download-tracker-id',
    [OrderAction.Print]: 'print-tracker-id',
    [OrderAction.CaptureNewScans]: 'capture-new-scans-tracker-id',
    [OrderAction.PauseOrder]: 'pause-order-tracker-id',
    [OrderAction.ResumeOrder]: 'resume-order-tracker-id',
    [OrderAction.CancelOrder]: 'cancel-order-tracker-id',
    [OrderAction.UploadImageOrFile]: 'upload-image-or-file-tracker-id',
    [OrderAction.AttachExistingScan]: 'attach-existing-scan-tracker-id',
    [OrderAction.UploadOrTakePhoto]: 'upload-or-take-a-photo-tracker-id',
    [OrderAction.LeaveNote]: 'leave-note-tracker-id',
    [OrderAction.AttachPhoto]: 'attatch-photo-tracker-id',
    [OrderAction.Refabricate]: 'refabricate-tracker-id',
    [OrderAction.ProvideInstructions]: 'provide-instructions-tracker-id',
    [OrderAction.ProvideProductFeedback]: 'provide-product-feedback-tracker-id',
};

export const OrderActionParentTrackerIdMap: Partial<Record<OrderActionParent, string>> = {
    [OrderActionParent.EditOrAddOrderInformation]: 'edit-or-add-order-information-tracker-id',
    [OrderActionParent.MobileActionsMenu]: 'mobile-action-menu-tracker-id',
    [OrderActionParent.MobileControlPanel]: 'mobile-control-panel-tracker-id',
};

export function trackRenderedOrderDetailActions(orderId: string, parent: OrderActionParent): void {
    const renderedOrderActions: OrderAction[] = [];
    const parentElement =
        parent === OrderActionParent.Global
            ? document
            : document.querySelector(`[data-tracker-id='${OrderActionParentTrackerIdMap[parent]}']`);

    Object.entries(OrderActionTrackerIdMap).forEach(([key, value]) => {
        const element = parentElement?.querySelector(`[data-tracker-id='${value}']`);
        if (element) {
            const style = getComputedStyle(element);
            const isDisabled = element.classList.contains('Mui-disabled') || element.hasAttribute('disabled');
            if (style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0' && !isDisabled) {
                renderedOrderActions.push(key as OrderAction);
            }
        }
    });
    if (renderedOrderActions.length) {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Order Details - Order Actions Loaded', {
            $groups: { order: orderId },
            parent: parent,
            orderDetailRedesignEnabled: true,
            actions: renderedOrderActions,
        });
    }
}
