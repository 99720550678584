import type { FileUploadAnalyticsMetadata, UploadedFile } from './FirebaseUpload';
import { FileUpload, FileUploadStatus, useOrderFileUpload } from './FirebaseUpload';
import { UploadedFilesList } from './FirebaseUpload/UploadedFilesList';
import type { ImpersonatedQRCodeLinkResult } from './QRCode';
import type { TextUploadLinkResult } from '@orthly/dentin';
import { FlossPalette, CircularProgress, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    gap: '48px',
    display: 'flex',
    paddingTop: '16px',
    width: '100%',
    flexWrap: 'wrap',
});

const FileSection = styled('div')({
    flex: 1,
    height: '100%',
    minWidth: '235px',
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: 8,
    backgroundColor: FlossPalette.TAN,
    padding: 24,
    overflow: 'scroll',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const LoaderContainer = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
});

export const OrderPhotoFileUploader: React.VFC<{
    orderId: string;
    sessionId: string;
    setFiles?: (files: UploadedFile[]) => void;
    qrCode?: ImpersonatedQRCodeLinkResult;
    textUploadLink?: TextUploadLinkResult;
    loading?: boolean;
}> = ({ orderId, sessionId, setFiles, qrCode, textUploadLink, loading }) => {
    const analyticsMetadata: FileUploadAnalyticsMetadata = {
        productName: 'order files',
        device: 'web',
    };
    const { onDropAccepted, onRemoveFile, files } = useOrderFileUpload({
        orderId,
        itemId: undefined,
        analyticsMetadata,
        forRefab: false,
        sessionId,
    });

    React.useEffect(() => {
        setFiles?.(files);
    }, [setFiles, files]);

    if (!textUploadLink) {
        return null;
    }

    const isLoading = loading ?? files.some(file => file.status === FileUploadStatus.uploading);

    return (
        <Container>
            <FileSection>
                <FileUpload
                    title={'Step 1: Upload photos or files here'}
                    onDropAccepted={onDropAccepted}
                    qrCode={qrCode ?? {}}
                    textUploadLink={textUploadLink}
                    allowMultipleFiles
                    acceptAllFileTypes
                />
            </FileSection>
            <FileSection>
                {isLoading && (
                    <LoaderContainer>
                        <CircularProgress variant={'indeterminate'} size={24} color={'primary'} />
                        <Text variant={'body2'} style={{ marginTop: 8 }}>
                            Uploading files...
                        </Text>
                    </LoaderContainer>
                )}
                <UploadedFilesList files={files} onRemoveFile={onRemoveFile} loading={isLoading} />
            </FileSection>
        </Container>
    );
};
