import logo from '../../../../assets/logo/LogoDark.svg';
import { styled, Text } from '@orthly/ui-primitives';
import React from 'react';

export const HEADER_HEIGHT = 80;

const Header = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
    width: 'calc(100% - 48px)',
    height: `${HEADER_HEIGHT}px`,

    position: 'fixed',
    top: '24px',
    left: '24px',
    right: '24px',
});

export const PrintableInvoiceHeader: React.FC = () => (
    <Header>
        <Text variant={'h5'} medium>
            Invoice
        </Text>
        <img alt={'Dandy logo'} src={logo} style={{ width: 88, height: 32 }} />
    </Header>
);
