import { OrderTimelineV2 } from './components/OrderTimeline/OrderTimeline';
import type { LabsGqlSingleLabOrderFragment, LabsGqlTimelineV2ItemsQuery } from '@orthly/graphql-operations';
import { useOrderRefetch, useTimelineV2ItemsQuery } from '@orthly/graphql-react';
import { LoadBlocker, ToolbarContainer } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { IconButton, createStyles, makeStyles, RefreshIcon } from '@orthly/ui-primitives';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'grid',
            height: '100%',
            width: '100%',
            gridTemplateRows: 'auto 1fr auto',
        },
        titleSection: {
            justifyContent: 'space-between',
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
        loadBlocker: {
            width: 'auto',
        },
    }),
);

export const PracticeOrderChatV2: React.FC<{
    order: LabsGqlSingleLabOrderFragment | undefined;
    refetchMessages: () => unknown;
    showToolbar?: boolean;
}> = ({ order, refetchMessages, showToolbar = false }) => {
    const classes = useStyles();
    const refetchOrder = useOrderRefetch();
    const {
        data,
        loading: isLoadingItems,
        refetch: refetchItems,
    } = useTimelineV2ItemsQuery({ variables: { entityId: order?.id ?? '' }, skip: !order?.id });

    if (!order) {
        return null;
    }

    const refetch = () => {
        if (order) {
            refetchOrder(order.id);
        }

        void refetchItems();
        refetchMessages();
    };

    return (
        <div className={classes.container}>
            {showToolbar && (
                <ToolbarContainer className={classes.titleSection}>
                    Timeline
                    <LoadBlocker blocking={isLoadingItems} ContainerProps={{ className: classes.loadBlocker }}>
                        <IconButton size={'small'} onClick={refetch}>
                            <RefreshIcon />
                        </IconButton>
                    </LoadBlocker>
                </ToolbarContainer>
            )}
            <OrderTimelineV2 events={data?.timelineV2Items ?? []} isLoadingItems={isLoadingItems} order={order} />
        </div>
    );
};

export type OrderTimelineV2Item = LabsGqlTimelineV2ItemsQuery['timelineV2Items'][number];
