import { useCSTAction, useOnCSTOpen } from '../../chat/cst-state/cst.actions';
import { Tips, renderWhatIsScanbodyTip } from '../component/Tip';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { PracticeScreen } from '@orthly/dentin';
import { MiniWindowVariant } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const NoMatch: React.VFC = () => {
    const { patient } = useImplantReadinessPropSelector(['patient']);
    const setCSTVariant = useCSTAction('SET_WINDOW_VARIANT');
    const openCSTAction = useOnCSTOpen(() => {
        setCSTVariant(MiniWindowVariant.Maximized, 'move');
    }, 'right_sidebar');

    return (
        <Grid container direction={'column'} wrap={'nowrap'} style={{ gap: 16, flexGrow: 1, overflow: 'hidden' }}>
            <Grid container style={{ gap: 60, flexGrow: 1, overflowY: 'auto' }}>
                <div style={{ flexBasis: '50%', flexGrow: 1, minWidth: 600 }}>
                    <Grid container direction={'column'} style={{ gap: 16 }}>
                        <Text variant={'body2'}>
                            Recheck the information you have provided for {patient?.first_name}'s implant system. If
                            there has been an error, please correct it and try again.
                        </Text>
                        <Text variant={'body2'}>
                            If you believe the implant system information is correct, chat with us to get help.
                        </Text>
                        <Button
                            variant={'secondary-gray'}
                            style={{ width: 148 }}
                            startIcon={'SidebarChatIcon'}
                            onClick={openCSTAction}
                        >
                            Chat with us
                        </Button>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
};

export const NoMatchStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/nomatch`,
    component: NoMatch,
    sidebar: ({ disableFreeScanBody }) => <Tips tips={[renderWhatIsScanbodyTip(disableFreeScanBody)]} />,
    pageTitle: ({ name }) => ({
        title: 'Oops',
        header: `We're unable to match a scan body for ${name}'s implant`,
    }),
};
