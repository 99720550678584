import { PHOTO_STYLE } from './StaffButton';
import { FlossPalette, Grid, AddIcon, Button, Text, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    button: {
        margin: 0,
        padding: 0,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
}));

export const AddButton: React.VFC<{ onClick: () => void }> = ({ onClick }) => {
    const classes = useStyles();
    return (
        <Button variant={'text'} onClick={onClick} className={classes.button} data-test={'select-staff-add-button'}>
            <Grid style={{ display: 'block' }}>
                <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    style={{ ...PHOTO_STYLE, backgroundColor: FlossPalette.WHITE }}
                >
                    <AddIcon style={{ color: FlossPalette.GRAY, width: 28, height: 28 }} />
                </Grid>
                <Text variant={'body1'} color={'DARK_GRAY'} style={{ fontSize: 16, marginTop: 8 }}>
                    Add team member
                </Text>
            </Grid>
        </Button>
    );
};
