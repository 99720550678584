import { OrderPhotoAssignmentMobile } from '../order-detail-v2/components/OrderPhotoAssignmentMobile';
import { OrderAction, OrderActionTrackerIdMap } from './OrderActionsUtils';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { styled, Drawer, Button, UploadIcon } from '@orthly/ui-primitives';
import type { FileUploadAnalyticsMetadata, UploadedFile } from '@orthly/veneer';
import { FileUploadStatus, useOrderFileUpload } from '@orthly/veneer';
import React from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';

const ButtonContainer = styled('div')({
    display: 'flex',
});
const StyledPaper = styled('div')({
    height: '92%',
    borderRadius: 16,
});
const StyledButton = styled(Button)({
    height: 'unset',
    minWidth: 'unset',
});

interface UploadOrTakePhotoActionProps {
    order: LabsGqlOrder;
    isIconOnly?: boolean;
}

export const UploadOrTakePhotoAction: React.FC<UploadOrTakePhotoActionProps> = ({ order, isIconOnly }) => {
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [sessionId, setSessionId] = React.useState(uuidv4());
    const [attachments, setAttachments] = React.useState<File[]>([]);
    const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>([]);

    const analyticsMetadata: FileUploadAnalyticsMetadata = {
        productName: 'order files',
        device: 'web',
    };
    const { onDropAccepted, onRemoveFile, files } = useOrderFileUpload({
        orderId: order.id,
        itemId: undefined,
        analyticsMetadata,
        forRefab: false,
        sessionId,
    });
    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] },
        onDropAccepted: files => {
            void onDropAccepted(files);
            setAttachments(files);
        },
    });

    React.useEffect(() => {
        setUploadedFiles(files);
        if (files.length > 0 && !files?.find(file => file.status === FileUploadStatus.uploading)) {
            setDrawerOpen(true);
        }
    }, [setUploadedFiles, files]);

    return (
        <>
            <ButtonContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <StyledButton
                    variant={'ghost'}
                    size={'large'}
                    data-test={'open-button'}
                    disabled={!order.can_attach_images_or_files}
                    data-tracker-id={OrderActionTrackerIdMap[OrderAction.UploadOrTakePhoto]}
                    endIcon={!isIconOnly ? 'UploadIcon' : undefined}
                >
                    {isIconOnly ? <UploadIcon /> : 'Upload photo or file'}
                </StyledButton>
            </ButtonContainer>
            <Drawer
                open={drawerOpen}
                onClose={() => {
                    setDrawerOpen(false);
                    setAttachments([]);
                    setSessionId(uuidv4());
                }}
                variant={'temporary'}
                anchor={'bottom'}
                PaperProps={{
                    component: StyledPaper,
                }}
            >
                <OrderPhotoAssignmentMobile
                    orderId={order.id}
                    backAction={() => setDrawerOpen(false)}
                    setOpen={setDrawerOpen}
                    attachments={attachments}
                    setAttachments={setAttachments}
                    files={uploadedFiles}
                    setFiles={setUploadedFiles}
                    onRemoveFile={onRemoveFile}
                    setSessionId={setSessionId}
                />
            </Drawer>
        </>
    );
};
