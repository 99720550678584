import type { RequireChildrenProps } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    topSection: {
        height: '100%',
        overflow: 'hidden',
        flexDirection: 'column',
        '&:not(:first-of-type)': { borderLeft: `1px solid ${FlossPalette.DARK_TAN}` },
    },
}));

interface OrdersOverviewToolbarItemProps extends RequireChildrenProps {
    innerClassName?: string;
    size: 6 | 3 | 2;
}

export const OrdersToolbarItem: React.FC<OrdersOverviewToolbarItemProps> = props => {
    const classes = useStyles();
    return (
        <Grid item xs={props.size} className={classes.topSection}>
            <Grid container className={props.innerClassName}>
                {props.children}
            </Grid>
        </Grid>
    );
};
