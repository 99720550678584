import { useAddLabOrderPhotosMutation, useAddLabOrderPhotosWithNotesMutation } from '@orthly/graphql-react';
import type { LabsGqlLabOrderSinglePhotoInput } from '@orthly/graphql-schema';
import { LabsGqlLabOrderPhotoType } from '@orthly/graphql-schema';
import { getFullStoragePath, OrderingStorageConfigs } from '@orthly/shared-types';
import { OrthlyBrowserConfig, useChangeSubmissionFn } from '@orthly/ui';
import type { FileUploaderBulkProps, FileUploaderFieldResult } from '@orthly/veneer';
import { useFileUploaderVars } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';
import { v4 } from 'uuid';

/**
 * Utility for uploading and saving order photos of the same type to blob storage.
 * Once the photos have been saved, the storage URLs of each photo are saved
 * to the 'photos' property, with a default type of LabsGqlLabOrderPhotoType.PatientPhoto
 *
 * @link https://meetdandy.atlassian.net/browse/EPDSCM-57
 */
export function useUploadOrderPhotos(photoType: LabsGqlLabOrderPhotoType = LabsGqlLabOrderPhotoType.PatientPhoto) {
    const [rawSubmit] = useAddLabOrderPhotosMutation();
    const { submit: submitPhotos } = useChangeSubmissionFn(rawSubmit, {
        closeOnComplete: true,
    });

    return React.useCallback(
        async (orderId: string, attachmentPaths: string[] | undefined) => {
            if (!attachmentPaths || attachmentPaths.length === 0) {
                return;
            }

            const attachments: LabsGqlLabOrderSinglePhotoInput[] = _.compact(
                attachmentPaths.map(path => {
                    if (!!path) {
                        return {
                            type: photoType,
                            fullPath: path,
                        };
                    }
                    return null;
                }),
            );
            await submitPhotos({ variables: { data: { photos: attachments, order_id: orderId } } });
        },
        [photoType, submitPhotos],
    );
}

/**
 * Utility for uploading and saving order photos of the same type to blob storage.
 * Once the photos have been saved, the storage URLs of each photo are saved
 * to the 'photos' property, with a default type of LabsGqlLabOrderPhotoType.PatientPhoto
 *
 * @link https://meetdandy.atlassian.net/browse/EPDSCM-57
 */
export function useUploadOrderPhotosWithNotes(
    orderId?: string,
    attachments?: File[],
    notes?: string,
    photoType: LabsGqlLabOrderPhotoType = LabsGqlLabOrderPhotoType.PatientPhoto,
) {
    const [rawSubmit] = useAddLabOrderPhotosWithNotesMutation();
    const { submit: submitPhotos } = useChangeSubmissionFn(rawSubmit, {
        closeOnComplete: true,
        successMessage: () => [`Order photos uploaded!`, {}],
    });

    const storagePathConfig = getFullStoragePath(
        OrthlyBrowserConfig.env,
        OrderingStorageConfigs.attachments,
        orderId ?? v4(),
    );

    const fileUploadConfig: FileUploaderBulkProps = {
        storagePathConfig: storagePathConfig,
        prependTimestampToFilename: true,
        onComplete: async (results: FileUploaderFieldResult[], _files: File[], notes?: string) => {
            if (!results[0]) {
                window.alert('Unable to upload image, try again.');
                return;
            }
            const photos: LabsGqlLabOrderSinglePhotoInput[] = _.compact(
                results.map(result => {
                    if (result.uploadedPath) {
                        return {
                            type: photoType,
                            fullPath: result.uploadedPath,
                        };
                    }
                    return null;
                }),
            );
            if (orderId && notes) {
                await submitPhotos({ variables: { data: { photos, notes, order_id: orderId } } });
            }
        },
    };
    const { onSubmit } = useFileUploaderVars(fileUploadConfig);

    return React.useCallback(
        async (attachmentsToUpload: File[] | undefined = attachments, notesToUpload: string | undefined = notes) => {
            if (!orderId || !notesToUpload || !attachmentsToUpload || attachmentsToUpload.length === 0) {
                return;
            }
            const photos = attachmentsToUpload.filter(attachment => attachment.type.startsWith('image'));
            await onSubmit(photos, notesToUpload);
        },
        [onSubmit, attachments, notes, orderId],
    );
}
