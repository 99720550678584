import { Link, Text, styled } from '@orthly/ui-primitives';

export const Page = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'grid',
    gridTemplateAreas: `
        "Logo  Link"
        "Panel Panel"`,
    gridTemplateRows: 'min-content min-content',
    width: '100%',
    overflow: 'auto',
    padding: '32px 56px',
    [theme.breakpoints.down('sm')]: {
        padding: '16px 24px',
    },
}));

export const Logo = styled('img')(({ theme }) => ({
    gridArea: 'Logo',
    aspectRatio: '11 / 4',
    height: 32,
    [theme.breakpoints.down('sm')]: {
        height: 24,
    },
}));

export const ReturnLink = styled(Link)({
    gridArea: 'Link',
    justifySelf: 'right',
    alignSelf: 'center',
    textAlign: 'right',
    whiteSpace: 'nowrap',
});

export const PageMessage = styled(Text)(({ theme }) => ({
    gridArea: 'PageMessage',
    justifySelf: 'center',
    textAlign: 'center',
    top: 96,
    position: 'absolute',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));
