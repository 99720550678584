import type { LabsGqlLabSlipAlertFragment } from '@orthly/graphql-operations';
import { LabsGqlDoctorAlertLocation } from '@orthly/graphql-schema';

export const NOTE_TO_DR_PREFIX = 'NOTE TO DR:';

/**
 * Filters lab slip alerts into two categories: lab notes and notes to doctor
 * @param labSlipAlerts The `lab_slip_alerts` from the order
 */
export function getNotesByCategory(labSlipAlerts?: LabsGqlLabSlipAlertFragment[]): {
    labNotes: LabsGqlLabSlipAlertFragment[];
    notesToDoctor: LabsGqlLabSlipAlertFragment[];
} {
    if (!labSlipAlerts) {
        return { labNotes: [], notesToDoctor: [] };
    }

    const labNotes: LabsGqlLabSlipAlertFragment[] = [];
    const notesToDoctor: LabsGqlLabSlipAlertFragment[] = [];
    labSlipAlerts.forEach(alert => {
        if (!alert.visibility?.includes(LabsGqlDoctorAlertLocation.LabSlip)) {
            return;
        }
        if (alert.text.startsWith(NOTE_TO_DR_PREFIX)) {
            notesToDoctor.push(alert);
        } else {
            labNotes.push(alert);
        }
    });

    return { labNotes, notesToDoctor };
}
