import type { PracticeInvoiceItem } from '../invoicing.types';
import type { LabsGqlDoctorDeliveryAddressFragment as Address } from '@orthly/graphql-operations';
import _ from 'lodash';
import React from 'react';

export type AddressOption = {
    id: string;
    label: string;
};

// the order props we need to filter
type Order = {
    doctor_name?: string;
    mailing_address_id?: string;
};

type FilterSettings = { doctorName?: string; addressId?: string };

type RowForFiltering = { doctor_name?: string; mailing_address_id?: string };
type Filter = {
    doctorNameOptions: string[];
    addressIdOptions: AddressOption[];

    // returns a lambda you can use to filter rows with provided `filters`
    rowFilter: (filters: FilterSettings) => (row: RowForFiltering) => boolean;
};

// returns a react-memoized `invoiceFilter`
export function useInvoiceItemFilter(orders: Order[], items: any[], addresses: Address[]): Filter {
    return React.useMemo(() => _invoiceItemFilter(orders, items, addresses), [orders, items, addresses]);
}

/*
  Returns a `Filter`, which provides the options for the
  doctor and address filters, as well as a function `rowFilter`
  that takes the current options and provides a lambda that will
  filter rows according to those options.

  Exported for tests - use `useInvoiceFilter`.
*/
export function _invoiceItemFilter(orders: Order[], items: PracticeInvoiceItem[], addresses: Address[]): Filter {
    const doctorNameOptions = createDoctorOptions(orders, items);

    const addressIdOptions = createAddressOptions(addresses, items, orders);

    const rowFilter =
        ({ doctorName, addressId }: FilterSettings) =>
        (row: RowForFiltering) => {
            return passesFilter(doctorName, row.doctor_name) && passesFilter(addressId, row.mailing_address_id);
        };

    return { doctorNameOptions, addressIdOptions, rowFilter };
}

function passesFilter(filter: string | undefined, value: string | undefined): boolean {
    return filter === undefined || filter === value;
}

function createDoctorOptions(orders: Order[], items: PracticeInvoiceItem[]): string[] {
    const allDoctorNames = [...items.map(i => i.doctor_name), ...orders.map(o => o.doctor_name)];
    return _.compact(_.uniq(allDoctorNames));
}

function createAddressOptions(addresses: Address[], items: PracticeInvoiceItem[], orders: Order[]): AddressOption[] {
    const allAddressIds = [...items.map(i => i.mailing_address_id), ...orders.map(o => o.mailing_address_id)];
    // `Set` to eliminate duplicates and provide O(1) lookup
    const orderAddressIds = new Set(allAddressIds);
    return _(addresses)
        .uniqBy(a => a.id)
        .filter(a => orderAddressIds.has(a.id))
        .map(addressOption)
        .value();
}

function addressOption(address: Address): AddressOption {
    return {
        ...address,
        label: [address.street_one, address.street_two].join(', '),
    };
}
