import { useScreenIsMobile, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

export const DownloadTermsOfUse: React.VFC = () => {
    const isMobile = useScreenIsMobile();

    return (
        <Grid
            container
            alignItems={isMobile ? 'baseline' : 'center'}
            direction={isMobile ? 'column' : 'row'}
            style={{ height: !isMobile ? 50 : undefined }}
        >
            <Button
                variant={'secondary-gray'}
                endIcon={'DownloadIcon'}
                onClick={() =>
                    window
                        .open(
                            'https://cdn.meetdandy.com/practice-portal/Dandy%20Practice%20Portal%20and%20Chairside%20Terms%20of%20Use.pdf',
                            '_blank',
                        )
                        ?.focus()
                }
            >
                Practice Portal Terms of Use
            </Button>
            <Text variant={'body2'} color={'GRAY'} style={{ margin: isMobile ? '8px 0 0 0' : '0 0 0 16px' }}>
                Last updated: 5-17-2023
            </Text>
        </Grid>
    );
};
