import React from 'react';

interface ShadeDisplaySVGProps {
    baseHex?: string;
    gingivalHex?: string;
    incisalHex?: string;
    style?: React.CSSProperties;
}

/*eslint-disable-next-line max-lines-per-function*/
export const ShadeDisplaySVG = (props: ShadeDisplaySVGProps) => {
    const { baseHex, gingivalHex, incisalHex, style } = props;
    const noFill = !baseHex && !gingivalHex && !incisalHex;

    const baseColor = baseHex ?? 'white';
    const gingivalColor = gingivalHex ?? baseHex ?? 'white';
    const incisalColor = incisalHex ?? baseHex ?? 'white';

    return (
        <svg
            width={'233'}
            height={'238'}
            viewBox={'0 0 233 238'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            style={style}
        >
            {/* Base color */}
            <g filter={noFill ? 'url(#filter0_d)' : ''}>
                <path
                    d={
                        'M196.187 20H36.1872C36.1872 20 25.3095 80.0785 22.1872 119C21.1281 132.202 20.5599 147.973 20.2705 163.006C19.6829 193.534 44.651 218 75.1846 218H157.19C187.723 218 212.691 193.534 212.104 163.006C211.814 147.973 211.246 132.202 210.187 119C207.065 80.0785 196.187 20 196.187 20Z'
                    }
                    fill={noFill ? 'white' : 'url(#vertical_gradient)'}
                />
            </g>
            {/* Overlay for tooth-like gradient*/}
            <g>
                <path
                    d={
                        'M196.187 20H36.1872C36.1872 20 25.3095 80.0785 22.1872 119C21.1281 132.202 20.5599 147.973 20.2705 163.006C19.6829 193.534 44.651 218 75.1846 218H157.19C187.723 218 212.691 193.534 212.104 163.006C211.814 147.973 211.246 132.202 210.187 119C207.065 80.0785 196.187 20 196.187 20Z'
                    }
                    fill={'url(#tooth_like_gradient)'}
                />
            </g>

            {/* Only show explicit segments for gingival and incisal if no shades are set */}
            {noFill && (
                <>
                    <g filter={'url(#filter1_d)'}>
                        <path
                            d={
                                'M75.1846 218H157.19C187.723 218 212.691 193.535 212.104 163.007C212.072 161.345 212.036 159.675 211.997 158C211.997 158 188 168 116.187 168C44.3743 168 20.377 158 20.377 158C20.3379 159.675 20.3025 161.345 20.2705 163.007C19.6829 193.535 44.651 218 75.1846 218Z'
                            }
                            fill={'white'}
                        />
                    </g>
                    <g filter={'url(#filter2_d)'}>
                        <path
                            d={
                                'M196.187 20H36.1872C36.1872 20 32.6052 39.7835 28.9622 64C28.9622 64 53.1871 76 116.187 76C179.187 76 203.412 64 203.412 64C199.769 39.7835 196.187 20 196.187 20Z'
                            }
                            fill={'white'}
                        />
                    </g>
                </>
            )}

            <g filter={'url(#filter3_i)'}>
                <ellipse cx={'116'} cy={'19.5'} rx={'80'} ry={'13.5'} fill={'#FDFCFA'} />
            </g>
            <defs>
                <linearGradient
                    id={'tooth_like_gradient'}
                    gradientUnits={'userSpaceOnUse'}
                    x1={'0'}
                    y1={'0'}
                    x2={'233'}
                    y2={'0'}
                >
                    <stop offset={'0'} stopColor={'rgb(255,255,255,0.8)'} />
                    <stop offset={'.2'} stopColor={'rgb(255,255,255,0.2)'} />
                    <stop offset={'.5'} stopColor={'rgb(255,255,255,0)'} />
                    <stop offset={'.8'} stopColor={'rgb(255,255,255,0.2)'} />
                    <stop offset={'1'} stopColor={'rgb(255,255,255,0.8)'} />
                </linearGradient>
                <linearGradient
                    id={'vertical_gradient'}
                    gradientUnits={'userSpaceOnUse'}
                    x1={'0'}
                    y1={'0'}
                    x2={'0'}
                    y2={'238'}
                >
                    <stop offset={'0'} stopColor={gingivalColor} />
                    <stop offset={'0.2'} stopColor={gingivalColor} />
                    <stop offset={'.5'} stopColor={baseColor} />
                    <stop offset={'0.8'} stopColor={incisalColor} />
                    <stop offset={'1'} stopColor={incisalColor} />
                </linearGradient>
                <filter
                    id={'filter0_d'}
                    x={'0.260376'}
                    y={'0'}
                    width={'231.854'}
                    height={'238'}
                    filterUnits={'userSpaceOnUse'}
                    colorInterpolationFilters={'sRGB'}
                >
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feColorMatrix
                        in={'SourceAlpha'}
                        type={'matrix'}
                        values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 200 0'}
                    />
                    <feGaussianBlur stdDeviation={'4'} />
                    <feColorMatrix type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0'} />
                    <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow'} result={'shape'} />
                </filter>
                <filter
                    id={'gingival_blur'}
                    height={'170'}
                    width={'162'}
                    x={'34'}
                    y={'20'}
                    filterUnits={'userSpaceOnUse'}
                >
                    <feGaussianBlur stdDeviation={'8'} />
                </filter>
                <filter
                    id={'incisal_blur'}
                    height={'150'}
                    width={'190'}
                    x={'20'}
                    y={'130'}
                    filterUnits={'userSpaceOnUse'}
                >
                    <feGaussianBlur stdDeviation={'10'} />
                </filter>
                <filter
                    id={'gingival_filter'}
                    x={'28.9622'}
                    y={'20'}
                    width={'174.45'}
                    height={'60'}
                    filterUnits={'userSpaceOnUse'}
                    colorInterpolationFilters={'sRGB'}
                >
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feColorMatrix
                        in={'SourceAlpha'}
                        type={'matrix'}
                        values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
                    />
                    <feOffset dy={'4'} />
                    <feColorMatrix type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'} />
                    <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow'} result={'shape'} />
                </filter>
                <filter
                    id={'filter1_d'}
                    x={'20.2603'}
                    y={'154'}
                    width={'191.854'}
                    height={'64'}
                    filterUnits={'userSpaceOnUse'}
                    colorInterpolationFilters={'sRGB'}
                >
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feColorMatrix
                        in={'SourceAlpha'}
                        type={'matrix'}
                        values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
                    />
                    <feOffset dy={'-4'} />
                    <feColorMatrix type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'} />
                    <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow'} result={'shape'} />
                </filter>
                <filter
                    id={'filter2_d'}
                    x={'28.9622'}
                    y={'20'}
                    width={'174.45'}
                    height={'60'}
                    filterUnits={'userSpaceOnUse'}
                    colorInterpolationFilters={'sRGB'}
                >
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feColorMatrix
                        in={'SourceAlpha'}
                        type={'matrix'}
                        values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
                    />
                    <feOffset dy={'4'} />
                    <feColorMatrix type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'} />
                    <feBlend mode={'normal'} in2={'BackgroundImageFix'} result={'effect1_dropShadow'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'effect1_dropShadow'} result={'shape'} />
                </filter>
                <filter
                    id={'filter3_i'}
                    x={'36'}
                    y={'6'}
                    width={'160'}
                    height={'27'}
                    filterUnits={'userSpaceOnUse'}
                    colorInterpolationFilters={'sRGB'}
                >
                    <feFlood floodOpacity={'0'} result={'BackgroundImageFix'} />
                    <feBlend mode={'normal'} in={'SourceGraphic'} in2={'BackgroundImageFix'} result={'shape'} />
                    <feColorMatrix
                        in={'SourceAlpha'}
                        type={'matrix'}
                        values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'}
                        result={'hardAlpha'}
                    />
                    <feOffset dy={'-4'} />
                    <feComposite in2={'hardAlpha'} operator={'arithmetic'} k2={'-1'} k3={'1'} />
                    <feColorMatrix type={'matrix'} values={'0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'} />
                    <feBlend mode={'normal'} in2={'shape'} result={'effect1_innerShadow'} />
                </filter>
            </defs>
        </svg>
    );
};
