import { FlossPalette, styled } from '@orthly/ui-primitives';

export const FileAssignmentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    marginTop: 16,
    gap: 24,
    width: '100%',
});

export const FileAssignmentLayout = styled('div')({
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: 8,
    backgroundColor: FlossPalette.TAN,
    padding: 24,
});

export const SelectFilesContainer = styled('div')({
    display: 'flex',
    width: '100%',
});

export const ScanPreviewWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: 600,
});
