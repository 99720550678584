import type { StaffUser } from '../practice-settings/staff/useFetchPracticeStaffUsers.graphql';
import { SettingsBaseModal } from './SettingsBaseModal';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlSendInviteEmailData } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, IconButton, Icon, Button, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

const sentInviteEmailMutation = graphql(`
    mutation SentInviteEmail($data: SendInviteEmailData!) {
        sendInviteEmail(data: $data)
    }
`);

function useSendEmailInvite(onInviteAction: () => void) {
    const [sendInviteEmailMtn] = useMutation(sentInviteEmailMutation);
    const sendInviteEmailSubmitter = (data: LabsGqlSendInviteEmailData) => sendInviteEmailMtn({ variables: { data } });
    return useChangeSubmissionFn(sendInviteEmailSubmitter, {
        closeOnComplete: true,
        onSuccess: result => {
            if (result.data?.sendInviteEmail) {
                onInviteAction();
            }
        },
    });
}

export const SendInviteEmailModal: React.VFC<{
    staffUser: StaffUser;
    onInviteAction: (firstName: string) => void;
}> = ({ staffUser, onInviteAction }) => {
    const session = useSession();
    const [open, setOpen] = React.useState(false);

    const { submit: sendInviteEmail, submitting: emailSending } = useSendEmailInvite(() => {
        onInviteAction(staffUser.preferred_name?.split(' ')[0] ?? '');
        setOpen(false);
    });

    const customButton = React.useCallback(
        () => (
            <Tooltip title={`Send an invite to ${staffUser.contact_email}`} arrow>
                <IconButton
                    style={{ color: FlossPalette.GRAY }}
                    onClick={() => setOpen(true)}
                    data-test={'send-invite-email-button'}
                >
                    <Icon icon={'Email'} />
                </IconButton>
            </Tooltip>
        ),
        [staffUser.contact_email],
    );

    return (
        <SettingsBaseModal
            open={open}
            setOpen={setOpen}
            title={`Resend invite email to ${staffUser.preferred_name}?`}
            subtitle={`${staffUser.preferred_name} will receive an invite email at ${staffUser.contact_email}.`}
            loading={emailSending}
            CustomButton={customButton}
            content={
                <div style={{ float: 'right', marginTop: '16px' }}>
                    <Button variant={'secondary'} style={{ marginRight: '8px' }} onClick={() => setOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant={'primary'}
                        onClick={async () => {
                            await sendInviteEmail({
                                preference_id: staffUser.doctor_preference_id,
                                organization_name: session?.organization_name ?? '',
                                referrer_name: `${session?.user?.first_name} ${session?.user?.last_name}`,
                                referrer_email: session?.user?.email ?? '',
                            });
                        }}
                    >
                        Resend invite
                    </Button>
                </div>
            }
        />
    );
};
