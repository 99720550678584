import { AccountInformationSection } from './sections/AccountInformationSection';
import { ProfileInformationSection } from './sections/ProfileInformationSection';
import { RolesSection } from './sections/RolesSection';
import { InviteExistingUserDialog } from './sections/components/InviteExistingUserDialog';
import { ReactivateStaffDialog } from './sections/components/ReactivateStaffDialog';
import { useStaffForm } from './useStaffForm';
import { Button, FormHelperText, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const PageLayout = styled('div')({
    maxWidth: '960px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    padding: '24px',
});

const SectionsLayout = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '288px 1fr',
    gap: '24px',
    padding: '24px 0',

    [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: '1fr',
    },
}));

const ButtonsLayout = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
});

export const AddEditStaff: React.FC = () => {
    const {
        updateDoctorPreferences,
        createDoctorPreferences,
        shouldContinue,
        isEditForm,
        employeeName,
        formError,
        onBackAction,
    } = useStaffForm();

    return (
        <PageLayout>
            <form
                onSubmit={event => {
                    event.preventDefault();
                    if (isEditForm) {
                        updateDoctorPreferences();
                    } else {
                        createDoctorPreferences();
                    }
                }}
            >
                <Text variant={'h5'}>{employeeName ? `Editing ${employeeName}` : 'Add a staff member'}</Text>
                <SectionsLayout>
                    <AccountInformationSection />
                    <ProfileInformationSection />
                    <RolesSection />
                </SectionsLayout>
                {formError && <FormHelperText error>{formError}</FormHelperText>}
                <ButtonsLayout>
                    <Button variant={'primary'} type={'submit'} disabled={!shouldContinue}>
                        {isEditForm ? 'Save changes' : 'Add staff member'}
                    </Button>
                    <Button variant={'secondary'} onClick={onBackAction}>
                        Cancel
                    </Button>
                </ButtonsLayout>
            </form>
            <InviteExistingUserDialog />
            <ReactivateStaffDialog />
        </PageLayout>
    );
};
