import { OrderDetailTimelineBlock } from './OrderDetailTimelineBlock';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useScreenIsSm } from '@orthly/ui-primitives';

export const InfoBlock: React.FC<{
    order?: LabsGqlSingleLabOrderFragment;
}> = ({ order }) => {
    const screenIsSm = useScreenIsSm();
    return order && !screenIsSm ? <OrderDetailTimelineBlock order={order} /> : null;
};
