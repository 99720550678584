import { useCurrentProfile } from '../../../../../../redux';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlCreateOrderHoldMutationVariables } from '@orthly/graphql-operations';
import { useCreateOrderHoldMutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';

type CreateOrderHoldVars = LabsGqlCreateOrderHoldMutationVariables['data'];

const isOrderAlreadyOnHold = (errorMessage: string): boolean => errorMessage.includes('Order is already on');

export const useCreateOrderHold = (orderId: string, onSuccess: () => void, onError: () => void) => {
    const doctor = useCurrentProfile();

    const [submitCreateHoldMtn] = useCreateOrderHoldMutation();
    const createHoldMtnSubmitter = (data: CreateOrderHoldVars) => submitCreateHoldMtn({ variables: { data } });
    return useChangeSubmissionFn<any, [CreateOrderHoldVars]>(createHoldMtnSubmitter, {
        successMessage: () => ['This order will be paused for the next 45 days or until you resume the order.'],
        onSuccess: result => {
            const hold = result?.data?.createOrderHold?.hold_details;

            if (hold) {
                BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Order Paused', {
                    holdId: hold.hold_id,
                    doctorPref: doctor?.id ?? '',
                    reason: hold.hold_subcategory,
                    $groups: { order: orderId },
                });
            }

            onSuccess();
        },
        errorMessage: e => [
            e.message,
            { submessage: isOrderAlreadyOnHold(e.message) ? 'Please refresh the page.' : '' },
        ],
        onError,
    });
};
