import { AnalyticsClient } from '../../../../../../analytics/analyticsClient';
import { useCheckoutPropSelector } from '../../../../../../redux';
import type { ConciergeConfigInterface } from './CheckoutSidebarConcierge.util';
import { CheckoutSidebarConciergeCTA } from './CheckoutSidebarConciergeCTA';
import type { CtaAnalyticsEventData } from '@orthly/analytics/dist/browser';
import { useAnalyticsTrackCtaShown } from '@orthly/analytics/dist/browser';
import { LabsUtilsBase } from '@orthly/dentin';
import type { LabsGqlCreatePracticeRequestMarketingMaterialsMutationVariables } from '@orthly/graphql-operations';
import {
    useCreatePracticeRequestMarketingMaterialsMutation,
    useFetchDoctorsQuery,
    useLabDeliveryAddressesQuery,
} from '@orthly/graphql-react';
import { LabsGqlPracticeRequestType } from '@orthly/graphql-schema';
import { useChangeSubmissionFn, RootActionDialog, SimpleSelect, LoadBlocker, SimpleCheckbox } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

type Vars = LabsGqlCreatePracticeRequestMarketingMaterialsMutationVariables['data'];

interface ConciergeMarketingMaterialsDialogBodyProps {
    config: ConciergeConfigInterface;
    partnerId: string;
    setOpen: (open: boolean) => void;
    submissionEventAnalyticsName?: string;
    setMaterialsRequested: (requested: boolean) => void;
}

const ConciergeMarketingMaterialsDialogBody: React.FC<ConciergeMarketingMaterialsDialogBodyProps> = props => {
    const { config, partnerId, setOpen, setMaterialsRequested } = props;
    const { data: doctorsData, loading: doctorsLoading } = useFetchDoctorsQuery();
    const doctors = doctorsData?.fetchDoctors || [];
    const { data, loading: addressesLoading } = useLabDeliveryAddressesQuery({
        variables: { partner_id: partnerId },
    });
    const { doctor: initalDoctor, address: initialAddress } = useCheckoutPropSelector(['doctor', 'address']);
    const [doctor, setDoctor] = React.useState<string | undefined>(initalDoctor?.id);
    const [address, setAddress] = React.useState<string | undefined>(initialAddress?.id);
    const [emailRequested, setEmailRequested] = React.useState<boolean>(false);

    const addresses = React.useMemo<{ address: string; id: string }[]>(
        () => (data?.addresses ?? []).map(addr => ({ address: LabsUtilsBase.addressToString(addr), id: addr.id })),
        [data],
    );

    const [submitMtn] = useCreatePracticeRequestMarketingMaterialsMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Marketing materials requested!', {}],
        onSuccess: () => {
            setMaterialsRequested(true);
            setOpen(false);
            AnalyticsClient.track('Practice - Checkout - Marketing Materials Requested', {
                sku: config.sku,
            });
        },
    });
    if (!config.marketing) {
        return null;
    }
    return (
        <Grid container spacing={3}>
            <Grid container item justifyContent={'space-between'}>
                <Grid item xs={4}>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {config.marketing.dialog.doctorFieldLabel}
                    </Text>
                </Grid>
                <Grid item xs={7}>
                    <LoadBlocker blocking={doctorsLoading}>
                        <SimpleSelect
                            value={doctor}
                            options={doctors.map(doc => ({
                                value: doc.id,
                                label: doc.name,
                            }))}
                            onChange={val => setDoctor(val)}
                            label={'Doctor'}
                        />
                    </LoadBlocker>
                </Grid>
            </Grid>
            <Grid container item justifyContent={'space-between'}>
                <Grid item xs={5}>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {config.marketing.dialog.addressFieldLabel}
                    </Text>
                </Grid>
                <Grid item xs={7}>
                    <LoadBlocker blocking={addressesLoading}>
                        <SimpleSelect
                            value={address}
                            options={addresses.map(addr => ({ value: addr.id, label: addr.address }))}
                            onChange={val => setAddress(val)}
                            label={'Practice Address'}
                        />
                    </LoadBlocker>
                </Grid>
            </Grid>
            {config.marketing.dialog.emailRequestLabel && (
                <Grid container item justifyContent={'flex-end'}>
                    <Grid item xs={7}>
                        <SimpleCheckbox
                            checked={emailRequested}
                            setChecked={setEmailRequested}
                            label={config.marketing.dialog.emailRequestLabel}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid item container justifyContent={'flex-end'}>
                <Button variant={'secondary'} onClick={() => setOpen(false)} style={{ marginRight: 8 }}>
                    Cancel
                </Button>
                <LoadBlocker blocking={submitting} ContainerProps={{ style: { width: 'fit-content' } }}>
                    <Button
                        variant={'primary'}
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onClick={() =>
                            address &&
                            doctor &&
                            submit({
                                sku: config.sku,
                                organization_id: partnerId,
                                data: {
                                    address_id: address,
                                    doctor_id: doctor,
                                    type: LabsGqlPracticeRequestType.MarketingMaterials,
                                    email_requested: emailRequested,
                                },
                            })
                        }
                        disabled={!address || !doctor}
                    >
                        Submit request
                    </Button>
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

const getAnalyticsMarketingData = (config: ConciergeConfigInterface): CtaAnalyticsEventData => {
    return {
        AssetCTAText: config.marketing?.ctaButtonText ?? 'Unknown',
        AssetLocation: config.assetLocation,
        AssetVersion: 'v1.0',
        AssetType: 'button',
        AssetName: 'Marketing Request',
    };
};

interface ConciergeMarketingMaterialsRootDialogProps {
    config: ConciergeConfigInterface;
    partnerId: string;
    materialsRequested: boolean;
    setMaterialsRequested: (requested: boolean) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    submissionEventAnalyticsName?: string;
}
const ConciergeMarketingMaterialsRootDialog: React.VFC<ConciergeMarketingMaterialsRootDialogProps> = props => {
    const { config, partnerId, materialsRequested, open, setOpen } = props;
    if (!config.marketing || materialsRequested) {
        return null;
    }
    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={
                <Grid container>
                    <Text variant={'h4'}>{config.marketing.dialog.title}</Text>
                    {config.marketing.dialog.subtitle && (
                        <Text variant={'body2'} color={'DARK_GRAY'} style={{ marginTop: 8 }}>
                            {config.marketing.dialog.subtitle}
                        </Text>
                    )}
                </Grid>
            }
            CustomButton={() => null}
            content={
                <ConciergeMarketingMaterialsDialogBody
                    config={config}
                    partnerId={partnerId}
                    setOpen={setOpen}
                    submissionEventAnalyticsName={props.submissionEventAnalyticsName}
                    setMaterialsRequested={props.setMaterialsRequested}
                />
            }
        />
    );
};

type ConciergeMarketingMaterialsCTAProps = Omit<ConciergeMarketingMaterialsRootDialogProps, 'open' | 'setOpen'>;
export const ConciergeMarketingMaterialsCTA: React.FC<ConciergeMarketingMaterialsCTAProps> = props => {
    const { config, materialsRequested } = props;
    const [open, setOpen] = React.useState(false);
    const analyticsMarketingData = React.useMemo(() => getAnalyticsMarketingData(config), [config]);
    useAnalyticsTrackCtaShown(analyticsMarketingData, !materialsRequested);
    if (!config.marketing) {
        return null;
    }
    return (
        <>
            <CheckoutSidebarConciergeCTA
                text={config.marketing.ctaLineText}
                onClick={() => {
                    setOpen(true);
                    AnalyticsClient.track('Button Clicked', analyticsMarketingData);
                }}
                buttonText={config.marketing.ctaButtonText}
                disabled={materialsRequested}
                disabledText={config.marketing.ctaDisabledText}
                blocking={false}
            />
            <ConciergeMarketingMaterialsRootDialog {...props} open={open} setOpen={setOpen} />
        </>
    );
};
