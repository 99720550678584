import { ExtraShadesSummary } from './ExtraShadesSummary';
import { InlinePrefsSection } from './InlinePrefsSection';
import type { ItemSectionProps } from './ItemsSection.types';
import { ShadeOcclusalDetails } from './ShadeOcclusalDetails';
import { ItemsSectionDisplayClasses } from './util/displayClasses';
import { getSingleItemSectionTitle } from './util/getItemDisplay';
import { dedupedItemToItemsArray } from './util/mergeOrderItems';
import { useOrderSummaryComponents } from './util/useOrderSummaryComponents';
import { CartItemV2Utils } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

// Section intended to render a single item, perhaps with additional tooth numbers provided as duplicates
export const SingleItemSection: React.FC<ItemSectionProps> = props => {
    const { item, index, inlinePrefs } = props;
    const { ContainerComponent, fontComponents, Action } = useOrderSummaryComponents();
    const { BoldText, Text, MediumText } = fontComponents;

    const material = CartItemV2Utils.getItemDisplayMaterial(item);
    const showShades = !!item.shades?.length;
    return (
        <ContainerComponent
            style={{
                borderTop: index !== 0 ? '1pt solid lightgray' : undefined,
                minHeight: '35pt',
                flexDirection: 'column',
            }}
        >
            <ContainerComponent style={{ paddingLeft: '0', marginBottom: '2pt' }}>
                <ContainerComponent
                    className={ItemsSectionDisplayClasses.ITEM_HEADER}
                    style={{ width: '100%', paddingTop: '5pt', flexDirection: 'row', alignItems: 'flex-end' }}
                >
                    <BoldText className={ItemsSectionDisplayClasses.ITEM_HEADER_TITLE} style={{ flex: 70 }}>
                        {getSingleItemSectionTitle(item)}
                    </BoldText>
                    <ShadeOcclusalDetails item={item} />
                    {Action ? (
                        <Grid style={{ flex: 20, textAlign: 'right' }}>
                            <Action items={dedupedItemToItemsArray(item)} />
                        </Grid>
                    ) : null}
                </ContainerComponent>

                {/* Indented details section */}
                {(!!material || showShades) && (
                    <ContainerComponent
                        className={ItemsSectionDisplayClasses.DETAIL_ROW_WRAPPER}
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            marginBottom: '5pt',
                            borderLeft: '2pt solid silver',
                            marginTop: '10pt',
                            paddingLeft: '10pt',
                        }}
                    >
                        {/* Row 0 - Material row */}
                        {!!material && (
                            <ContainerComponent
                                className={ItemsSectionDisplayClasses.DETAIL_ROW}
                                style={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <MediumText style={{ flex: 23 }}>Material</MediumText>
                                <Text style={{ flex: 75, fontSize: '12pt' }}>{material}</Text>
                                {Action ? <Grid style={{ flex: 20 }} /> : null}
                            </ContainerComponent>
                        )}
                        {/* Row 1 - Extra Shades row */}
                        {!!showShades && (
                            <ContainerComponent
                                className={ItemsSectionDisplayClasses.DETAIL_ROW}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginTop: '10pt',
                                }}
                            >
                                <MediumText style={{ flex: 23 }}>Shades</MediumText>
                                <ContainerComponent style={{ flex: 75, flexDirection: 'row', fontSize: '12pt' }}>
                                    <ExtraShadesSummary item={item} />
                                </ContainerComponent>
                                {Action ? <Grid style={{ flex: 20 }} /> : null}
                            </ContainerComponent>
                        )}
                    </ContainerComponent>
                )}
                <InlinePrefsSection inlinePrefs={inlinePrefs} item_notes={item.item_notes ?? null} />
            </ContainerComponent>
        </ContainerComponent>
    );
};
