import {
    CheckoutContentSection,
    CheckoutContentSectionTitle,
} from '../../components/CheckoutLayout/CheckoutContentSection';
import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, makeStyles, Grid, Typography } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        splitTitleWrap: {
            flexWrap: 'nowrap',
            [theme.breakpoints.down('md')]: {
                paddingRight: 0,
            },
        },
    }),
);

export const CheckoutSummaryScreenTitle: React.FC<{ title: string; titleRight?: string }> = props => {
    const classes = useStyles();
    return (
        <CheckoutContentSection
            title={
                !props.titleRight ? (
                    props.title
                ) : (
                    <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent={'space-between'}
                        className={classes.splitTitleWrap}
                    >
                        <CheckoutContentSectionTitle>{props.title}</CheckoutContentSectionTitle>
                        <Typography variant={'body1'} color={'textSecondary'}>
                            {props.titleRight}
                        </Typography>
                    </Grid>
                )
            }
            style={{
                borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
                background: '#fff',
                zIndex: 1,
            }}
        />
    );
};
