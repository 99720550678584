import { StackY, TrashIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    imageDisplayImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        borderRadius: 8,
    },
    orderImageDisplayImage: {
        height: '100%',
        width: '100%',
        objectFit: 'cover',
        borderRadius: 8,
    },
    imageDisplayRemove: {
        position: 'absolute',
        alignSelf: 'center',
        bottom: 8,
        padding: 4,
        height: 32,
        width: 32,
        backgroundColor: FlossPalette.WHITE,
        borderRadius: '50%',
        color: FlossPalette.PRIMARY_FOREGROUND,
        cursor: 'pointer',
    },
}));

export const ImageDisplay: React.VFC<{ file: File | undefined; onRemove: () => void; forOrder?: boolean }> = ({
    file,
    onRemove,
    forOrder,
}) => {
    const classes = useStyles();
    const [src, setSrc] = React.useState<string | undefined>(undefined);
    React.useEffect(() => {
        if (file) {
            const src = window.URL.createObjectURL(file);
            setSrc(src);
            return () => window.URL.revokeObjectURL(src);
        }
    }, [file]);
    if (!file) {
        return null;
    }
    return (
        <StackY style={{ position: 'relative' }}>
            <img
                src={src}
                alt={file.name}
                className={forOrder ? classes.orderImageDisplayImage : classes.imageDisplayImage}
            />
            {forOrder ? (
                <Button variant={'ghost'} onClick={onRemove} style={{ marginTop: 8 }}>
                    <TrashIcon style={{ marginRight: 8 }} />
                    Delete
                </Button>
            ) : (
                <TrashIcon onClick={onRemove} className={classes.imageDisplayRemove} />
            )}
        </StackY>
    );
};
