import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const CAROUSEL_STYLE = {
    position: `absolute`,
    inset: 0,
    transition: `200ms`,
    zIndex: 1,
    opacity: 0.7,
} as const;

/**
 *
 * @param children each child will become a slide in the carousel
 * @param index an index in children for the current slide
 *
 * the way the key props are calculated is the critical thing that makes the animation here work
 *
 * when the user goes forward, e.g. index++
 *
 * the value for `key={index}` becomes the new value for `key={index - 1}` and
 * the value for `key={index + 1}` becomes the new value for `key={index}`
 *
 * in this way
 * 1. the keys stay consistent for the two cards that moved over and react retains their existing DOM elements while updating their style and
 * 2. the keys change between the new first and last cards and react removes and recreates their DOM elements
 *
 * an animation results from the components where 1 applies with no animation where 2 does
 *
 * viola!
 */
export const SimpleCarousel: React.VFC<{ children: React.ReactElement[]; index: number }> = ({ children, index }) => (
    <Grid item style={{ position: `relative`, width: `100%`, height: `100%` }}>
        {index > 0 && (
            <div key={index - 1} style={{ ...CAROUSEL_STYLE, transform: `translateX(-200px) scale(0.7)` }}>
                {children[index - 1]}
            </div>
        )}
        <div key={index} style={{ ...CAROUSEL_STYLE, zIndex: 2, opacity: 1, transform: `none` }}>
            {children[index]}
        </div>
        {index < children.length - 1 && (
            <div key={index + 1} style={{ ...CAROUSEL_STYLE, transform: `translateX(200px) scale(0.7)` }}>
                {children[index + 1]}
            </div>
        )}
    </Grid>
);
