import { SimpleAutocomplete } from '../../SimpleForm/SimpleAutocomplete';
import type { FieldDefAutoComplete, FieldLayout } from '../QuickForm.types';
import { FormikRootField } from '../QuickForm.types';
import { QFErrorText } from './QFHelperText';
import { Grid } from '@orthly/ui-primitives';
import type { FieldProps } from 'formik';
import { getIn } from 'formik';
import _ from 'lodash';
import React from 'react';

interface QFAutoCompleteFieldProps {
    field: FieldDefAutoComplete & { name: string };
}

function cleanAndSetValue(
    formikProps: FieldProps<any>,
    field: FieldDefAutoComplete & { name: string },
    value: string | null,
) {
    if (field.cleanValue) {
        formikProps.form.setFieldValue(field.name, field.cleanValue(value || null));
    } else {
        formikProps.form.setFieldValue(field.name, value || null);
    }
}

export const QFAutoCompleteField: React.FC<QFAutoCompleteFieldProps> = props => {
    const { field } = props;
    const layout: FieldLayout = Object.assign({ xs: 12 }, field.layout || {});
    const label = field.label ? field.label : _.startCase(field.name);
    const { TextFieldProps, AutocompleteProps, ...autocompleteProps } = field.fieldProps || {};
    return (
        <Grid item {...layout} style={{ width: '100%', display: field.hidden ? 'none' : undefined }}>
            <FormikRootField
                name={field.name}
                render={formikProps => {
                    const error = getIn(formikProps.form.error, field.name);
                    const touched = getIn(formikProps.form.touched, field.name);
                    return (
                        <>
                            <SimpleAutocomplete
                                label={label}
                                options={field.options}
                                disabled={field.hidden}
                                variant={field.fieldProps?.variant}
                                {...autocompleteProps}
                                onChange={value => cleanAndSetValue(formikProps, field, value)}
                                onInputChange={value => {
                                    if (field.freeSolo) {
                                        cleanAndSetValue(formikProps, field, value);
                                    }
                                }}
                                error={touched && error ? true : undefined}
                                AutocompleteProps={{
                                    ...AutocompleteProps,
                                    onBlur: () => formikProps.form.setFieldTouched(field.name),
                                }}
                                TextFieldProps={{ 'data-test': `quick-form-field-${field.name}`, ...TextFieldProps }}
                                freeSolo={field.freeSolo}
                                initialInputValue={formikProps.form.initialValues[field.name]}
                            />
                            {touched && <QFErrorText fieldName={field.name} />}
                        </>
                    );
                }}
            />
        </Grid>
    );
};
