import { QueryString, OrthlyBrowserConfig, OrthlyFrontendApps } from '@orthly/ui';
import isElectron from 'is-electron';

export class PracticeUtils {
    static getStripeKey(): string {
        if (new OrthlyBrowserConfig(OrthlyFrontendApps.practice).isProduction) {
            return 'pk_live_b2rgZhBiFJx0AYqlEGVinies';
        }
        return 'pk_test_HOreirz6eDG7LdMPsXpWRfIh';
    }

    // pass in location if you want to a different one, such as the result of `useLocation` from react-router.
    static getParsedQueryProperty(propertyName: string, search: string = window.location.search): string | undefined {
        const parsed = QueryString.parse(search);
        const value = parsed[propertyName];
        return typeof value === 'string' ? value : undefined;
    }

    static async closeIfElectronWindow() {
        console.warn('Running closeIfElectronWindow');
        try {
            if (isElectron()) {
                alert('Order submitted. This window will now close.');
                console.warn('IN ELECTRON, ATTEMPTING TO EXIT');
                window.close();
                return new Promise(res => setTimeout(res, 5000));
            }
            console.warn('NOT ELECTRON, NOT EXITING');
        } catch (e: any) {
            console.error(e);
        }
    }
}

// Function returning another function to easily check if a multivariant flag is enabled. We have used this to
// determine which upsell banners to show when they're controlled by multivariant feature flags for example.
// example:
// const checkShowA = getFlagVariantValueChecker('show_a');
// checkShowA('show_a') => true, checkShowA('show_b') => false
export const getFlagVariantValueChecker = (requiredFlagValue: string) => (actualFlagValue: boolean | string) =>
    actualFlagValue === requiredFlagValue;

// Util function to get portion of day for customer messaging purposes
export const getPartOfDay = (): 'morning' | 'afternoon' | 'evening' => {
    const hour = new Date().getHours();
    if (hour < 12) {
        return 'morning';
    }
    if (hour < 18) {
        return 'afternoon';
    }
    return 'evening';
};
