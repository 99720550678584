export const SESSION_STORAGE_IS_SCANNER_SESSION = 'SESSION_STORAGE_IS_SCANNER_SESSION';

export const setIsScannerSessionFromSessionStorage = (isScannerSession: boolean) => {
    sessionStorage.setItem(SESSION_STORAGE_IS_SCANNER_SESSION, JSON.stringify(isScannerSession));
};

export const SESSION_STORAGE_IS_CARESTREAM_SESSION = 'SESSION_STORAGE_IS_CARESTREAM_SESSION';

export const setIsCarestreamSessionFromSessionStorage = (isCarestreamSession: boolean) => {
    sessionStorage.setItem(SESSION_STORAGE_IS_CARESTREAM_SESSION, JSON.stringify(isCarestreamSession));
};
