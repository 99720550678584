import type { TryInFeedbackOptionComponentProps, TryInFeedbackOptionDefinition } from '../TryInFeedbackOption.types';
import { TryInFeedbackOptionWrapper } from '../TryInFeedbackOptionWrapper';
import { CartItemSimpleShadePicker, getHiddenShadeProps } from '@orthly/dentin';
import { LabOrderItemSKUType, OrderItemPartialDentureProductionType } from '@orthly/items';
import React from 'react';

const TryInFeedbackShadeOption: React.VFC<TryInFeedbackOptionComponentProps<LabOrderItemSKUType.Partial>> = ({
    orderItem,
    newItem,
    updateNewItem,
    isSelected,
    description,
    onSelected,
    onUnselected,
    onDescriptionChanged,
    showError,
}) => {
    const unselect = () => {
        // on unselect, reset item shade  changes
        updateNewItem({ name: 'shades', payload: orderItem.shades });
        onUnselected();
    };

    const itemBaseShade = newItem.shades.find(shade => shade.name === 'base')?.value;

    // auto-set description to shade change if already set
    React.useEffect(() => {
        const shadeChangeString = itemBaseShade ? `Change shade to ${itemBaseShade}` : undefined;
        if (isSelected && shadeChangeString !== description) {
            onDescriptionChanged(shadeChangeString);
        }
    }, [isSelected, itemBaseShade, description, onDescriptionChanged]);

    const { hiddenShades, hiddenShadeOpts } = getHiddenShadeProps(newItem.sku, false);

    return (
        <TryInFeedbackOptionWrapper
            title={'Teeth shade'}
            subtitle={'Teeth shade needs adjustment'}
            isSelected={isSelected}
            onSelected={onSelected}
            onUnselected={unselect}
            hasError={isSelected && showError && !description}
        >
            <CartItemSimpleShadePicker
                unns={newItem.unit.unns}
                item={newItem}
                updateItem={updateNewItem}
                hiddenShades={hiddenShades}
                hiddenShadeOpts={hiddenShadeOpts}
            />
        </TryInFeedbackOptionWrapper>
    );
};

export const tryInFeedbackShadeOption: TryInFeedbackOptionDefinition<LabOrderItemSKUType.Partial> = {
    sku: LabOrderItemSKUType.Partial,
    isArchSpecific: false,
    category: 'Teeth shade',
    isEnabled: item => item.partial_production_type !== OrderItemPartialDentureProductionType.WaxRim,
    Component: TryInFeedbackShadeOption,
};
