import { useShowMobileLayout } from '../../utils/LayoutUtils';
import { OrdersToolbarItem } from './OrdersToolbarItem';
import { TOOLBAR_TABLET_DOWN_HEIGHT, ToolbarContainer } from '@orthly/ui';
import { FlossPalette, stylesFactory, useScreenIsMd } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    searchContainer: {
        backgroundColor: FlossPalette.WHITE,
        [theme.breakpoints.down('md')]: { paddingLeft: 16 },
    },
}));

interface OrdersOverviewToolbarRootProps {
    searchComponent: React.ReactElement;
    MobileFilters: React.ComponentType;
    DesktopFilters: React.ComponentType;
}

export const OrdersToolbarRoot: React.FC<OrdersOverviewToolbarRootProps> = props => {
    const { MobileFilters, DesktopFilters } = props;
    const classes = useStyles();
    const isMobile = useScreenIsMd();
    const isMobileLayout = useShowMobileLayout();

    return (
        <ToolbarContainer
            sticky
            style={{ padding: 0, top: isMobile && !isMobileLayout ? TOOLBAR_TABLET_DOWN_HEIGHT : 0 }}
        >
            <OrdersToolbarItem size={6} innerClassName={classes.searchContainer}>
                {props.searchComponent}
            </OrdersToolbarItem>
            {isMobile ? <MobileFilters /> : <DesktopFilters />}
        </ToolbarContainer>
    );
};
