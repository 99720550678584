import { usePresetStatus } from '../../../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../../../state/GuidedWaxupContext';
import type { PresetInfo } from '../../../state/GuidedWaxupState';
import { useGuidedWaxupAction } from '../../../state/GuidedWaxupState';
import { LabsGqlGuidedWaxupPresetStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { SimpleInput } from '@orthly/ui';
import { Box, Checkbox, FormControlLabel } from '@orthly/ui-primitives';
import React from 'react';

export const OtherNotesControl: React.VFC<{ preset: PresetInfo }> = ({ preset }) => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();
    const presetStatus = usePresetStatus({ presetType: LabsGqlGuidedWaxupPresetType.GeneralView });
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');
    const isRejected = presetStatus === LabsGqlGuidedWaxupPresetStatus.Rejected;
    const setPresetRejectionNotes = useGuidedWaxupAction('SET_PRESET_REJECTION_NOTES');

    return (
        <Box sx={{ marginTop: '12px', gridColumn: '1 / -1' }}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={isRejected}
                        disabled={selectedDesignRevisionAlreadyReviewed}
                        onChange={event => {
                            setPresetStatus({
                                presetName: LabsGqlGuidedWaxupPresetType.GeneralView,
                                status: event.target.checked ? LabsGqlGuidedWaxupPresetStatus.Rejected : undefined,
                            });
                        }}
                    />
                }
                label={'Other notes'}
                slotProps={{ typography: { sx: { '&&': { margin: 0 } } } }}
            />
            {isRejected && (
                <SimpleInput
                    disabled={selectedDesignRevisionAlreadyReviewed}
                    value={preset.notes}
                    onChange={rejectionNotes =>
                        setPresetRejectionNotes({
                            presetName: LabsGqlGuidedWaxupPresetType.GeneralView,
                            rejectionNotes,
                        })
                    }
                    TextFieldProps={{ multiline: true, rows: 5 }}
                    label={'Description of issue not covered by existing inputs'}
                    fullWidth
                />
            )}
        </Box>
    );
};
