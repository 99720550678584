import { Form, Panel, PanelHeader, Title } from './PanelContent.styles';
import { Icon, Text } from '@orthly/ui-primitives';

type PanelContentProps = {
    panelTitle: React.ReactNode;
    panelDescription?: React.ReactNode;
    onBack?: () => void;
    onFormSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const PanelContent: React.FC<PanelContentProps> = ({
    children,
    onBack,
    panelTitle,
    panelDescription,
    onFormSubmit,
}) => {
    return (
        <Panel>
            <PanelHeader>
                <Title bold>
                    {onBack && (
                        <Icon data-testid={'back_icon'} icon={'ArrowBack'} onClick={onBack} cursor={'pointer'} />
                    )}
                    {panelTitle}
                </Title>
                {panelDescription && (
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        {panelDescription}
                    </Text>
                )}
            </PanelHeader>
            {onFormSubmit ? <Form onSubmit={onFormSubmit}>{children}</Form> : children}
        </Panel>
    );
};
