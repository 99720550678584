import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderWaxupReviewStatus } from '@orthly/graphql-schema';
import { PauseOrderActionReason } from '@orthly/shared-types';
import type { SimpleSelectOption } from '@orthly/ui';

export const getPauseOrderActionOptions = (order: LabsGqlLabOrderFragment): SimpleSelectOption[] => {
    const canReviewDesign = order.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview;
    const canRescanPatient = order.can_attach_scans;
    const canUpdateRx = order.can_edit;

    // We want all the reasons, preserving order, without those reasons which are not applicable to the order.
    // This logic is hard to follow for the sake of brevity, but it's just a filter.
    return Object.values(PauseOrderActionReason).reduce<SimpleSelectOption[]>((acc, reason) => {
        if (
            (reason !== PauseOrderActionReason.ReviewDesign || canReviewDesign) &&
            (reason !== PauseOrderActionReason.RescanPatient || canRescanPatient) &&
            (reason !== PauseOrderActionReason.UpdateRx || canUpdateRx) &&
            reason !== PauseOrderActionReason.ScheduledLiveDdpReview
        ) {
            acc.push({ label: reason, value: reason });
        }
        return acc;
    }, []);
};
