import type { QueryResult } from '@apollo/client';
import type { LabsGqlOrganizationDtoFragment } from '@orthly/graphql-operations';
import { useGetOrgQuery } from '@orthly/graphql-react';
import React from 'react';

interface CurrentPartnerContextVal {
    error?: QueryResult<any>['error'];
    loading: boolean;
    data?: LabsGqlOrganizationDtoFragment;
    refetch: () => Promise<void>;
}

export const CurrentPartnerContext = React.createContext<CurrentPartnerContextVal>({
    loading: false,
    refetch: async () => {},
});

export const CurrentPartnerCtxProvider: React.FC<{ partnerId?: string }> = props => {
    const { data, error, loading, refetch } = useGetOrgQuery({
        variables: { id: props.partnerId || '' },
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onError: console.error,
    });
    return (
        <CurrentPartnerContext.Provider
            value={{
                loading,
                error,
                data: data?.getOrganization ?? undefined,
                refetch: async () => {
                    await refetch().catch(console.error);
                },
            }}
        >
            {props.children}
        </CurrentPartnerContext.Provider>
    );
};

export function useCurrentPartner() {
    return React.useContext<CurrentPartnerContextVal>(CurrentPartnerContext);
}
