import type { OrderTimelineV2Item } from '../../../../../../PracticeOrderChatV2';
import { OrderTimelineDownload } from './components/OrderTimelineDownload';
import { OrderTimelineImage } from './components/OrderTimelineImage';
import { stylesFactory } from '@orthly/ui-primitives';
import { isImagePath, type FirebasePreviewFileMulti } from '@orthly/veneer';
import classNames from 'classnames';
import React from 'react';

const useStyles = stylesFactory(() => ({
    attachmentsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    imageAttachmentsContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(80px, 1fr))',
        gap: 16,
        alignItems: 'start',
    },
}));

const groupAttachments = (attachments: FirebasePreviewFileMulti[]) => {
    const attachmentTypes: { images: typeof attachments; downloads: typeof attachments } = {
        images: [],
        downloads: [],
    };

    for (const attachment of attachments) {
        if (isImagePath(attachment.source)) {
            attachmentTypes.images.push(attachment);
        } else {
            attachmentTypes.downloads.push(attachment);
        }
    }

    return attachmentTypes;
};

export const OrderTimelineAttachments: React.FC<{
    attachments: OrderTimelineV2Item['attachments'];
    className?: string;
}> = ({ attachments, className }) => {
    const classes = useStyles();

    if (!attachments || attachments.length === 0) {
        return null;
    }

    const { downloads, images } = groupAttachments(attachments);

    return (
        <div className={classNames(classes.attachmentsContainer, className)}>
            {downloads.map(attachment => (
                <OrderTimelineDownload key={attachment.source} {...attachment} />
            ))}
            {images.length > 0 && (
                <div className={classes.imageAttachmentsContainer}>
                    {images.map(attachment => (
                        <OrderTimelineImage key={attachment.source} {...attachment} />
                    ))}
                </div>
            )}
        </div>
    );
};
