import { PrintableSlipSectionDivider } from '../../../PrintableSlip/components/shared/PrintableSlipSectionDivider';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { Text, Grid, makeStyles } from '@orthly/ui-primitives';
import format from 'date-fns/format';
import React from 'react';

const useStyles = makeStyles({
    orderHeader: {
        fontSize: '1rem !important',
        justifyContent: 'flex-end',
    },
    fadedText: {
        fontSize: '0.75rem !important',
    },
});

interface IUnboxingSlipOrderInfoProps {
    order: LabsGqlLabOrderForLabSlipFragment;
}

/**
 * Renders the Dandy Id and order date
 * to be displayed on the Order Unboxing Slip
 * @param props the order for which the slip is being rendered
 * @returns Order Unboxing Slip Section with information about the order
 */
export const UnboxingSlipOrderInfo: React.VFC<IUnboxingSlipOrderInfoProps> = props => {
    const { order } = props;
    const classes = useStyles();
    const startDate = format(new Date(order.created_at), 'MM/dd');
    return (
        <Grid item xs={6}>
            <Grid container>
                <Grid container justifyContent={'flex-end'}>
                    <Text variant={'h6'} className={classes.orderHeader}>{`Dandy (#${order.order_number})`}</Text>
                </Grid>
                <Grid container justifyContent={'flex-end'}>
                    <Text variant={'body2'} className={classes.fadedText}>
                        {`Order Date: ${startDate}`}
                    </Text>
                </Grid>
            </Grid>
            <PrintableSlipSectionDivider />
        </Grid>
    );
};
