import type { PrimitiveAtom } from 'jotai';
import { atom, useAtom, useAtomValue } from 'jotai';
import { splitAtom } from 'jotai/utils';
import { z } from 'zod';

type Trainee = {
    name: string | undefined;
    role: string | undefined;
    experience: string | undefined;
    scanner: string | undefined;
    material: string | undefined;
    retractions: string[] | undefined;
    prepStyles: string[] | undefined;
    willAdapt: string | undefined;
    showInvalidFields: boolean;
};

export const traineeSchema = z.object({
    name: z.string(),
    role: z.string(),
    experience: z.string().optional(),
    scanner: z.string().optional(),
});

const dentistSchema = traineeSchema.extend({
    material: z.string(),
    retractions: z.array(z.string()).min(1),
    prepStyles: z.array(z.string()).min(1),
    willAdapt: z.string().optional(),
});

const INITIAL_TRAINEE: Trainee = {
    name: undefined,
    role: undefined,
    experience: undefined,
    scanner: undefined,
    material: undefined,
    retractions: undefined,
    prepStyles: undefined,
    willAdapt: undefined,
    showInvalidFields: false,
};

export type TraineeAtom = PrimitiveAtom<Trainee>;

const traineesAtom = atom<Trainee[]>([INITIAL_TRAINEE]);
const traineesAtomsAtom = splitAtom(traineesAtom);
const dentistsAtom = atom(get => get(traineesAtom).filter(trainee => trainee.role === 'Dentist'));

/**
 * Custom hook to manage the state of the trainees.
 * @returns {Object} - Object containing the trainees, dentists, trainee atoms, and functions to add and remove trainees
 */
export const useTrainees = () => {
    const [traineeAtoms, dispatch] = useAtom(traineesAtomsAtom);
    const [trainees, setTrainees] = useAtom(traineesAtom);
    const dentists = useAtomValue(dentistsAtom);

    const traineesAreValid = trainees.every(trainee => {
        const parsed = traineeSchema.safeParse(trainee);
        return parsed.success;
    });

    const dentistsAreValid = dentists.every(dentist => {
        const parsed = dentistSchema.safeParse(dentist);

        if (!parsed.success) {
            return false;
        }

        if (dentist.prepStyles?.includes('Knife edge')) {
            return (dentist.willAdapt?.length ?? 0) > 0;
        }

        return true;
    });

    const addTrainee = () => {
        dispatch({ type: 'insert', value: INITIAL_TRAINEE });
    };

    const removeTrainee = (atom: TraineeAtom) => {
        dispatch({ type: 'remove', atom });
    };

    const clearTrainees = () => {
        setTrainees([INITIAL_TRAINEE]);
    };

    return {
        addTrainee,
        removeTrainee,
        clearTrainees,
        setTrainees,
        trainees,
        dentists,
        traineesAreValid,
        dentistsAreValid,
        traineeAtoms,
    };
};
