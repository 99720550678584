import { FlossPalette, stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    icon: { paddingTop: 44, color: FlossPalette.GREEN },
    title: { padding: 8 },
}));

interface EmptyStateTextProps {
    title: string;
    subTitle: string;
    icon: React.ReactNode;
}

export const EmptyStateText: React.FC<EmptyStateTextProps> = props => {
    const { title, subTitle, icon } = props;
    const classes = useStyles();

    return (
        <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            style={{ padding: '0 40px' }}
        >
            <Grid item className={classes.icon}>
                {icon}
            </Grid>
            <Grid item className={classes.title}>
                <Text variant={'body1'} medium align={'center'}>
                    {title}
                </Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'} color={'GRAY'} align={'center'}>
                    {subTitle}
                </Text>
            </Grid>
        </Grid>
    );
};
