import { useGetStarRating } from './Feedback.util';
import { FeedbackActions } from './state/feedback.actions';
import type { FeedbackItemNotesScreen } from './state/feedback.reducer';
import { useFeedbackSelector } from './state/feedback.selectors';
import { useSubmitFeedbackV3 } from './state/useSubmitFeedback';
import { FeedbackAndRefabBodyContainer } from '@orthly/dentin';
import { isArrayMin2 } from '@orthly/runtime-utils';
import { SimpleInput } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import React from 'react';
import { useDispatch } from 'react-redux';

export const FeedbackItemNotesScreenComponent: React.VFC<{ screen: FeedbackItemNotesScreen }> = ({ screen }) => {
    const { items, item } = screen;
    const dispatch = useDispatch();
    const notes = useFeedbackSelector(state => state.items[item.id]?.notes || undefined);
    const starRating = useGetStarRating(item);
    const { submit } = useSubmitFeedbackV3();

    React.useEffect(() => {
        dispatch(FeedbackActions.SET_ITEM_STAR_RATING({ itemId: item.id, starRating: starRating }));
    }, [item, starRating, dispatch]);

    const onNext = () => {
        if (isArrayMin2(items)) {
            dispatch(
                FeedbackActions.GO_TO({
                    items,
                    id: 'item_select',
                    previous_screen: screen,
                }),
            );
        } else {
            submit();
        }
    };

    return (
        <FeedbackAndRefabBodyContainer
            title={
                <>
                    Anything we should know?{' '}
                    <Text variant={'body2'} color={'DARK_GRAY'} component={'span'}>
                        Optional
                    </Text>
                </>
            }
            onBack={() => dispatch(FeedbackActions.GO_BACK(screen))}
            onNext={onNext}
            nextTitle={items[1] ? 'Next' : 'Submit feedback'}
        >
            <Grid item xs={12}>
                <SimpleInput
                    onChange={val => {
                        dispatch(FeedbackActions.SET_ITEM_NOTES({ itemId: item.id, notes: val || `` }));
                    }}
                    value={notes}
                    label={'Additional notes'}
                    fullWidth
                    TextFieldProps={{ multiline: true, rows: 4, style: { maxWidth: 576 } }}
                />
            </Grid>
        </FeedbackAndRefabBodyContainer>
    );
};
