import type { CreateChatMessageMutationVariables } from './AddOrderNotesDialog';
import { AddOrderNotesDialog } from './AddOrderNotesDialog';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { getChatMessageVisibilityForWrite } from '@orthly/chat';
import { useCreateChatMessageMutation } from '@orthly/graphql-react';
import { LabsGqlChatEntityTypes, LabsGqlControlPanelActionType, LabsGqlStaffRoleWithAny } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

export const AddOrderNotes = (props: {
    addPhotoVariant?: boolean;
    orderId: string;
    refetchMessages: () => void;
    CustomButton?: React.FC;
}) => {
    const { orderId, addPhotoVariant, CustomButton } = props;
    const organizationType = useSession()?.organization_type ?? 'practice';
    const [attachmentUrls, setAttachmentUrls] = React.useState<string[]>([]);
    const [toastOpen, setToastOpen] = React.useState(false);
    const [submitMtn] = useCreateChatMessageMutation();
    const mtnSubmitter = (data: CreateChatMessageMutationVariables) =>
        submitMtn({
            variables: {
                data: {
                    ...data,
                    attachment_urls: attachmentUrls,
                    entity_type: LabsGqlChatEntityTypes.Order,
                    entity_id: orderId,
                    visible_to_roles: getChatMessageVisibilityForWrite(organizationType, [
                        LabsGqlStaffRoleWithAny.LabAny,
                    ]),
                    action_type: addPhotoVariant
                        ? LabsGqlControlPanelActionType.AddPhoto
                        : LabsGqlControlPanelActionType.LeaveNote,
                },
            },
        });
    const { open, setOpen, submit, submitting } = useChangeSubmissionFn<any, [CreateChatMessageMutationVariables]>(
        mtnSubmitter,
        {
            closeOnComplete: true,
            successMessage: () => [undefined, {}],
            onSuccess: () => {
                BrowserAnalyticsClientFactory.Instance?.track(
                    addPhotoVariant
                        ? 'Practice - Control Panel - Add Photo Action Submitted'
                        : 'Practice - Control Panel - Leave Note Action Submitted',
                    { $groups: { order: orderId } },
                );
                setToastOpen(true);
                setAttachmentUrls([]);
                props.refetchMessages();
            },
        },
    );

    return (
        <>
            <AddOrderNotesDialog
                addPhotoVariant={addPhotoVariant}
                orderId={orderId}
                open={open}
                setOpen={setOpen}
                CustomButton={CustomButton}
                submit={submit}
                submitting={submitting}
                setAttachmentUrls={setAttachmentUrls}
                toastOpen={toastOpen}
                setToastOpen={setToastOpen}
            />
        </>
    );
};
