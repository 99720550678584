import { persistStaffProfileReducerToLocalStorage } from '../select-staff.util';
import { SelectStaffActions } from './select-staff.actions';
import { CreateStaffStep } from './select-staff.progression';
import { selectCreateStaffProgression } from './select-staff.selectors';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import type { LabsGqlDoctorProfilePhotoDto, LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { LabsGqlDoctorProfilePhotoColor, LabsGqlDoctorProfilePhotoType } from '@orthly/graphql-schema';
import * as _ from 'lodash';
import { handleActions } from 'redux-actions';

export enum ResponsibilityOption {
    CreateNewOrders = 'CreateNewOrders',
    ManageNotifications = 'ManageNotifications',
    ManageBilling = 'ManageBilling',
    TrackOrders = 'TrackOrders',
    ChatWithDandy = 'ChatWithDandy',
}

export type StaffProfileInfo = Pick<
    LabsGqlDoctorPreferencesFragment,
    '__typename' | 'id' | 'contact_email' | 'name' | 'roles' | 'staff_member_id'
>;

interface StaffCreationState {
    occupation?: LabsGqlStaffRolePractice | null;
    responsibilities: ResponsibilityOption[];
    responsibility_notes?: string;
    profile_photo: LabsGqlDoctorProfilePhotoDto;
    name?: string;
    contact_email?: string;
    contact_phone?: string;
    created_profile?: StaffProfileInfo;
}

export interface SelectStaffState {
    step: CreateStaffStep;
    history: CreateStaffStep[];
    skipped: boolean;
    profile?: StaffProfileInfo;
    profileCreationState: StaffCreationState;
    createWithoutAutoLogin?: boolean;
}

const initialStaffCreationState: StaffCreationState = {
    responsibilities: [],
    profile_photo: {
        type: LabsGqlDoctorProfilePhotoType.None,
        color: LabsGqlDoctorProfilePhotoColor.LightGreen,
    },
};

const initialState: SelectStaffState = {
    step: CreateStaffStep.Landing,
    history: [],
    skipped: false,
    profileCreationState: initialStaffCreationState,
};

const createNewWithoutLogoutState: SelectStaffState = {
    step: CreateStaffStep.Occupation,
    history: [CreateStaffStep.Landing],
    skipped: false,
    profileCreationState: initialStaffCreationState,
    createWithoutAutoLogin: true,
};

const progressStep = (state: SelectStaffState, step: CreateStaffStep | null): SelectStaffState =>
    step && step !== state.step
        ? {
              ...state,
              step,
              history: [...state.history, state.step],
          }
        : state;

const staffMemberReducerRaw = handleActions<SelectStaffState, any>(
    {
        ...SelectStaffActions.SELECT_PRACTICE_EMPLOYEE.reducer((_state, action) => ({
            ...initialState,
            profile: action.payload,
        })),
        ...SelectStaffActions.SKIP_SELECT_STAFF.reducer(state => ({
            ...state,
            skipped: true,
        })),
        ...SelectStaffActions.CONTINUE_SELECT_STAFF.reducer(state => ({
            ...state,
            skipped: false,
        })),
        ...SelectStaffActions.LOGOUT_STAFF.reducer(() => initialState),
        ...SelectStaffActions.SET_SELECT_STAFF_STEP.reducer((state, action) => progressStep(state, action.payload)),
        ...SelectStaffActions.NEXT_SELECT_STAFF_STEP.reducer(state =>
            progressStep(state, selectCreateStaffProgression(state).nextStep),
        ),
        ...SelectStaffActions.PREV_SELECT_STAFF_STEP.reducer(state => ({
            ...state,
            step: _.last(state.history) ?? state.step,
            history: state.history.slice(0, -1),
        })),
        ...SelectStaffActions.START_STAFF_CREATION.reducer(state =>
            progressStep(
                {
                    ...state,
                    profileCreationState: initialStaffCreationState,
                },
                CreateStaffStep.Occupation,
            ),
        ),
        ...SelectStaffActions.ADD_STAFF_CREATION_FIELD.reducer((state, action) => {
            if (!state.profileCreationState) {
                return state;
            }
            const [draft, options] = action.payload;
            const nextState: SelectStaffState = {
                ...state,
                profileCreationState: {
                    ...state.profileCreationState,
                    ...draft,
                },
            };
            if (options?.autoNextStep) {
                return progressStep(nextState, selectCreateStaffProgression(nextState).nextStep);
            }
            return nextState;
        }),
        ...SelectStaffActions.CREATE_NEW_STAFF_WITHOUT_LOGOUT.reducer(state => ({
            ...createNewWithoutLogoutState,
            profile: state.profile,
        })),
    },
    initialState,
);

export const staffMemberReducer = persistStaffProfileReducerToLocalStorage(staffMemberReducerRaw);
