import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const ReactivateDoctorPreferences_Mutation = graphql(`
    mutation ReactivateDoctorPreferences($data: ReactivateDoctorPreferencesCommand!) {
        reactivateDoctorPreferences(data: $data) {
            id
        }
    }
`);

export const useReactivateDoctorPreferences = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => unknown;
    onError?: (errorMessage: string) => unknown;
} = {}) => {
    const [submitMtn, { error }] = useMutation(ReactivateDoctorPreferences_Mutation);
    const { submit: reactivateDoctorPreferences, submitting } = useChangeSubmissionFn(submitMtn, {
        disableDefaultErrorMessage: true,
        onSuccess: () => {
            onSuccess?.();
        },
        onError: e => {
            onError?.(e.message);
        },
    });

    const submit = React.useCallback(
        async (preferences_id: string) => {
            return reactivateDoctorPreferences({
                variables: { data: { preferences_id } },
            });
        },
        [reactivateDoctorPreferences],
    );

    return { submit, submitting, error: error?.message };
};
