import { LoginWrapper } from '../pre-auth/screens/login';
import { RecoverPassword } from '../pre-auth/screens/recover-password/RecoverPassword';
import { SetNewPassword } from '../pre-auth/screens/set-new-password/SetNewPassword';
import { LoginRoutes } from './LoginPaths';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

interface PracticeLoginRootProps {
    originalLocation: LocationDescriptorObject;
}

export const LoggedOutRoot: React.FC<PracticeLoginRootProps> = props => (
    <Switch>
        <Route path={LoginRoutes.recover_pass}>
            <RecoverPassword />
        </Route>
        <Route path={LoginRoutes.new_pass}>
            <SetNewPassword />
        </Route>
        <Route path={Object.values(LoginRoutes)}>
            <LoginWrapper originalLocation={props.originalLocation} />
        </Route>
    </Switch>
);
