import { createStyles, makeStyles, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            padding: '32px 0 0',
            position: 'relative',
        },
        titleWrap: {
            position: 'relative',
            paddingBottom: 12,
            flexDirection: 'column',
        },
    }),
);

interface CheckoutContentSectionProps {
    // If you pass a string it will be wrapped in Typography
    title?: React.ReactNode | string;
    // If you pass a string it will be wrapped in Typography
    subtitle?: React.ReactNode | string;
    style?: React.CSSProperties;
}

export const CheckoutContentSectionTitle: React.FC = props => (
    <Text variant={'h5'} style={{ width: '100%', position: 'relative' }}>
        {props.children}
    </Text>
);

export const CheckoutContentSection: React.FC<CheckoutContentSectionProps> = props => {
    const classes = useStyles();
    return (
        <Grid item container style={props.style} className={classes.root}>
            {props.title && (
                <Grid container className={classes.titleWrap}>
                    {typeof props.title === 'string' ? (
                        <CheckoutContentSectionTitle>{props.title}</CheckoutContentSectionTitle>
                    ) : (
                        props.title
                    )}
                    {typeof props.subtitle === 'string' ? (
                        <Text variant={'body1'} style={{ width: '100%' }}>
                            {props.subtitle}
                        </Text>
                    ) : (
                        props.subtitle ?? null
                    )}
                </Grid>
            )}
            {props.children}
        </Grid>
    );
};
