import type { PartnerAppState } from '../../../redux/types';
import type { TryInFeedbackState } from './TryInFeedback.types';
import { generateUseStatePropSelector, generateUseSubstateSelector } from '@orthly/redux-async-actions';

const useTryInFeedbackSelector = generateUseSubstateSelector<PartnerAppState, 'tryInFeedback'>('tryInFeedback');

export const useTryInFeedbackPropSelector = generateUseStatePropSelector<TryInFeedbackState>(
    useTryInFeedbackSelector,
    'tryInFeedback',
);
