import { NotificationNames } from '../Notifications.types';
import { LabsGqlNotificationGroupNames } from '@orthly/graphql-schema';

export function useGetNotificationGroup(notificationName: NotificationNames) {
    switch (notificationName) {
        case NotificationNames.status_change:
            return LabsGqlNotificationGroupNames.StatusChange;
        case NotificationNames.review_required:
            return LabsGqlNotificationGroupNames.ReviewRequired;
        case NotificationNames.order_tracking:
            return LabsGqlNotificationGroupNames.OrderTracking;
        case NotificationNames.communication:
            return LabsGqlNotificationGroupNames.Communication;
    }
}
