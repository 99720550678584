import { LoginRoutes } from '../../../../../logged-out/LoginPaths';
import { PageLayout } from '../../../../components/page-layout/PageLayout';
import { CenteredFooterText, FooterLink } from '../../../../components/page-layout/components/PanelFooterLink';
import { Button } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

const pageMessage = (() => {
    const hour = dayjs().hour();
    if (hour < 12) {
        return 'Good morning';
    } else if (hour < 18) {
        return 'Good afternoon';
    } else {
        return 'Good evening';
    }
})();

export const LoginForm: React.FC<{ onSubmit: React.FormEventHandler<HTMLFormElement>; shouldContinue: boolean }> = ({
    children,
    shouldContinue,
    onSubmit,
}) => {
    const history = useHistory();

    return (
        <PageLayout pageMessage={pageMessage} panelTitle={'Log in to your Dandy Portal'} onFormSubmit={onSubmit}>
            {children}
            <Button disabled={!shouldContinue} variant={'primary'} fullWidth type={'submit'}>
                Continue
            </Button>
            <CenteredFooterText>
                <FooterLink onClick={() => history.push(LoginRoutes.recover_pass)}>Forgot your password?</FooterLink>
            </CenteredFooterText>
        </PageLayout>
    );
};
