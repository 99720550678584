import type { PartnerAppState } from '../../../redux/types';
import { FeedbackActions } from './feedback.actions';
import type { FeedbackItemReasonCode, FeedbackState } from './feedback.reducer';
import type { LabsGqlReasonCodeOptionFragment } from '@orthly/graphql-operations';
import { LabsGqlReasonCodeGroup } from '@orthly/graphql-schema';
import _ from 'lodash';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

export const useFeedbackSelector: TypedUseSelectorHook<FeedbackState> = <TSelected>(
    selector: (state: FeedbackState) => TSelected,
    equalityFn?: (left: TSelected, right: TSelected) => boolean,
): TSelected => {
    return useSelector<PartnerAppState, TSelected>(s => selector(s.feedback), equalityFn);
};

// -- Custom Selectors

const useSelectedReasonCodesForCurrentItem = (itemId: string) => {
    return useFeedbackSelector(state => state.items[itemId]?.tags) ?? {};
};
export const useSelectedReasonCodeIdsForCurrentItem = (itemId: string) => {
    return Object.keys(useSelectedReasonCodesForCurrentItem(itemId));
};

export const useSelectOtherNotesByReasonCodeIdForCurrentItem = (itemId: string, reasonCodeId?: string) => {
    return useFeedbackSelector(s => s.items[itemId]?.tags[reasonCodeId ?? '']?.otherNotes ?? '');
};

export const useFilterSelectedReasonCodeIdsByCategoryForCurrentItem = (
    itemId: string,
    reasonCodes?: LabsGqlReasonCodeOptionFragment[],
    category?: string | null,
) => {
    const selectedReasonCodeIds = useSelectedReasonCodeIdsForCurrentItem(itemId);
    const reasonCodesIdsByCategory = reasonCodes?.filter(rc => rc.category === category).map(rc => rc.id) ?? [];
    return _.intersection(selectedReasonCodeIds, reasonCodesIdsByCategory);
};

// -- Custom Actions

export const useUnsetReasonCodesForCurrentItem = (itemId: string) => {
    const dispatch = useDispatch();
    return (reasonCodeIds: string[]) => {
        dispatch(FeedbackActions.UNSET_REASON_CODES({ itemId, reasonCodeIds }));
    };
};

const convertReasonCodeToFeedbackItemShape = (
    reasonCode: LabsGqlReasonCodeOptionFragment,
    itemId: string,
): FeedbackItemReasonCode => ({
    itemId,
    group: reasonCode.group ?? LabsGqlReasonCodeGroup.Other,
    category: reasonCode.category ?? 'Unknown',
    title: reasonCode.title,
    codeId: reasonCode.id,
    otherNotes: '',
});

export const useSetReasonCodeForCurrentItem = (itemId: string) => {
    const dispatch = useDispatch();
    return (reasonCode: LabsGqlReasonCodeOptionFragment) =>
        dispatch(
            FeedbackActions.SET_SINGLE_REASON_CODE({
                itemId,
                reasonCode: convertReasonCodeToFeedbackItemShape(reasonCode, itemId),
            }),
        );
};

export const useSetOtherNotesByReasonCodeIdForCurrentItem = (itemId: string, codeId: string) => {
    const dispatch = useDispatch();
    const reasonCode = useFeedbackSelector(s => s.items[itemId]?.tags[codeId]);
    return (otherNotes: string) => {
        if (reasonCode) {
            dispatch(FeedbackActions.SET_OTHER_NOTES({ itemId, reasonCode, otherNotes }));
        }
    };
};
