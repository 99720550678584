import type { CheckoutSidebarTextStepProps } from './CheckoutSidebarTextStep';
import { CheckoutSidebarTextVariant, TextStepEntryVariantProps } from './CheckoutSidebarTextStep';
import { CheckoutSidebarStepVariant } from './checkoutSidebarTypes';
import { Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    listItem: {
        '&::before': {
            content: '"○"',
            marginRight: 8,
        },
    },
    listItemCurrent: {
        '&::before': {
            content: '"●"',
        },
    },
}));

export interface CheckoutSidebarImageStepProps extends Partial<CheckoutSidebarTextStepProps> {
    image: React.ReactNode;
    imageCaption?: React.ReactNode;
}

export const CheckoutSidebarImageStep: React.FC<CheckoutSidebarImageStepProps> = props => {
    const { texts, contentPlaceholder, variant: stepVariant } = props;
    const classes = useStyles();
    const textVariantDefault =
        stepVariant === CheckoutSidebarStepVariant.Header
            ? CheckoutSidebarTextVariant.Header
            : CheckoutSidebarTextVariant.Normal;
    return (
        <>
            {props.image}
            {props.imageCaption}
            {texts?.map(({ caption, content = contentPlaceholder, variant = textVariantDefault, onClick }, index) => (
                <Grid container alignItems={'center'} justifyContent={'space-between'} wrap={'nowrap'} key={index}>
                    <Text
                        {...TextStepEntryVariantProps[variant].caption}
                        onClick={onClick}
                        className={clsx(
                            classes.listItem,
                            variant === CheckoutSidebarTextVariant.Current && classes.listItemCurrent,
                        )}
                    >
                        {caption}
                    </Text>
                    {content && <Text {...TextStepEntryVariantProps[variant].content}>{content}</Text>}
                </Grid>
            ))}
        </>
    );
};
