import { CheckoutItemSelectField } from '../CheckoutItemSelectField';
import { getFieldLabel, useAutoSetItemDataFieldDefaultValue } from './CheckoutItemDataField.utils';
import type { CheckoutItemDataFieldBaseProps } from './CheckoutItemDataFieldBaseProps.types';
import type { EnumSelectItemDataField, ItemDataFieldId, LabOrderItemSKUType } from '@orthly/items';
import { ItemDataFieldUtils } from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';
import React from 'react';

interface CheckoutEnumSelectItemDataFieldProps<
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
> extends CheckoutItemDataFieldBaseProps<SKU> {
    field: EnumSelectItemDataField<ID, SKU, Enum>;
}

export const CheckoutEnumSelectItemDataField = <
    ID extends ItemDataFieldId,
    SKU extends LabOrderItemSKUType,
    Enum extends string,
>({
    field,
    item,
    updateItem,
}: CheckoutEnumSelectItemDataFieldProps<ID, SKU, Enum>): React.ReactElement => {
    const value = field.getValue(item) ?? undefined;

    React.useEffect(() => {
        const options = field.getOptions(item);

        if (value && !options.some(option => option.value === value)) {
            updateItem(field.getUpdatePayload(null, item));
        }
    }, [item, value, field, updateItem]);

    const applyUpdate = (val: string | undefined) => {
        // belongs to enum should always be true, but we check for type reasons
        if (val === undefined || belongsToEnum(val, field.enum)) {
            updateItem(field.getUpdatePayload(val ?? null, item));
        }
    };

    useAutoSetItemDataFieldDefaultValue(field, item, updateItem);

    return (
        <CheckoutItemSelectField
            label={getFieldLabel(field, item)}
            value={value}
            options={[...field.getOptions(item)]}
            onChange={val => applyUpdate(val)}
            required={ItemDataFieldUtils.isItemDataFieldRequired(field, item)}
        />
    );
};
