import { useCheckoutSelector } from '../../../redux';
import { useCheckoutAction } from './checkout.actions';
import type { LabsGqlScanFileCheckoutFragment } from '@orthly/graphql-operations';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import { useScanFilesCheckoutQuery } from '@orthly/graphql-react';
import { QueryString, OrthlyBrowserConfig } from '@orthly/ui';
import type { History } from 'history';
import _ from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function useScanIdFromQueryString() {
    const location = useLocation();
    return React.useMemo(() => {
        const query = QueryString.parse(location.search);
        return query.scanId;
    }, [location.search]);
}

function useScanReusedFromQueryString() {
    const location = useLocation();
    return React.useMemo(() => new URLSearchParams(location.search).get('scanReused'), [location.search]);
}

export function useScanAlreadySubmittedQueryParam(): boolean {
    const location = useLocation();
    return React.useMemo(() => {
        const query = QueryString.parse(location.search);
        return query.already_submitted === 'true';
    }, [location.search]);
}

function findMatchingScanForId(
    scanIdQuery: string,
    scans: LabsGqlScanFileCheckoutFragment[],
): LabsGqlScanFileCheckoutFragment | undefined {
    // we use threeshape_order_id or id here to support old uploader code
    const exactMatch = scans.find(s => s.id === scanIdQuery);
    if (exactMatch) {
        return exactMatch;
    }
    const threeshapeIdMatches = scans.filter(s => s.threeshape_order_id === scanIdQuery);
    return _.maxBy(threeshapeIdMatches, s => new Date(s.created_at).valueOf());
}

// Removes the scanId query param from location if not submitting from scanner
function resetScanIdQueryParamNonScannerSubmit(history: History) {
    if (!history.location.pathname.includes('scanner')) {
        // use replace instead of push so the user doesnt have to press back twice go back to portal
        history.replace({
            ...history.location,
            search: QueryString.stringify(_.omit(QueryString.parse(history.location.search), ['scanId', 'scanReused'])),
        });
    }
}

// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function useCheckoutScansQuery(params?: { skip?: boolean }): ScanFilesCheckoutQueryHookResult {
    const currentScan = useCheckoutSelector(s => s.scan);
    const setScan = useCheckoutAction('SET_SCAN');
    const clearScan = useCheckoutAction('CLEAR_SCAN');
    const scanIdFromQuery = useScanIdFromQueryString();
    const scanReusedFromQueryString = useScanReusedFromQueryString();
    const history = useHistory();

    const [reusedScanIds] = React.useState<string[] | null>(
        scanReusedFromQueryString && scanIdFromQuery ? [scanIdFromQuery] : null,
    );
    const queryResult = useScanFilesCheckoutQuery({
        // important, dont remove
        fetchPolicy: 'no-cache',
        skip: params?.skip,
        displayName: 'UseScanFilesCheckoutQuery',
        variables: { reused_scan_ids: reusedScanIds },
        onCompleted: data => {
            if (!data) {
                return;
            }
            const scans = data.scan_files;
            // we are only handling logic if there was a scan id passed in the query string
            if (scanIdFromQuery) {
                resetScanIdQueryParamNonScannerSubmit(history);
                // if we have a scanId from the query, we want to set the scan to the found scan or undefined
                const scanFromQuery = findMatchingScanForId(scanIdFromQuery, scans);
                return scanFromQuery ? setScan(scanFromQuery) : clearScan();
            }
            // if there is only 1 scan, set it to that
            const firstScan = scans[0];
            if (scans.length === 1 && firstScan && (!currentScan || currentScan.id !== firstScan.id)) {
                return setScan(firstScan);
            }
        },
        onError: console.error,
    });

    // clear the scan if its not in the current available scans
    React.useEffect(() => {
        const scans = queryResult.data?.scan_files || [];
        if (scanIdFromQuery) {
            const scanFromQuery = findMatchingScanForId(scanIdFromQuery, scans);
            if (currentScan?.id !== scanFromQuery?.id) {
                scanFromQuery ? setScan(scanFromQuery) : clearScan();
            }
            return;
        }
        // clear the scan if its not in the current available scans. Skip this step in dev b/c it kills hot reload
        if (currentScan && !scans.find(s => s.id === currentScan.id) && !OrthlyBrowserConfig.isDevelopment) {
            clearScan();
        }
    }, [queryResult.data, currentScan, setScan, scanIdFromQuery, clearScan]);
    return queryResult;
}
