import type { ButtonProps } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, createStyles, Button } from '@orthly/ui-primitives';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import type { RequireExactlyOne } from 'type-fest';

const useStyles = stylesFactory(() =>
    createStyles({
        root: {
            textTransform: 'unset',
            '&&': {
                color: FlossPalette.BLACK,
                lineHeight: 'normal',
            },
            '&.Mui-disabled': {
                opacity: 0.4,
            },
            '&:focus,&:hover': {
                '&&': {
                    color: FlossPalette.GRAY,
                },
            },
        },
    }),
);

interface PropsBase {
    link: string;
    onClick: () => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
    classes?: ButtonProps['classes'];
    disabled?: boolean;
}

export type CheckoutTextButtonProps = RequireExactlyOne<PropsBase, 'link' | 'onClick'>;

export const CheckoutTextButtonBase: React.FC<Omit<ButtonProps, 'variant'>> = props => {
    const classes = useStyles();
    return (
        <Button {...props} className={classes.root} variant={'text'} style={props.style}>
            {props.children}
        </Button>
    );
};

export const CheckoutTextButton: React.FC<CheckoutTextButtonProps> = props => {
    const classes = useStyles();
    const buttonProps = React.useMemo<ButtonProps>(() => {
        return {
            className: classes.root,
            color: 'secondary',
            variant: 'text',
            style: props.style,
            classes: props.classes,
        };
    }, [classes.root, props.classes, props.style]);
    if (props.link && !props.disabled) {
        return (
            <RouterLink to={props.link} style={{ textDecoration: 'none' }}>
                <Button {...buttonProps}>{props.children}</Button>
            </RouterLink>
        );
    }
    return (
        <Button disabled={props.disabled} {...buttonProps} onClick={() => props.onClick?.()}>
            {props.children}
        </Button>
    );
};
