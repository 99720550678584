import { PracticeUtils } from './utils';
import { OrthlyApolloProvider } from '@orthly/graphql-react';
import { OrthlyEnvironment } from '@orthly/shared-types';
import { OrthlyBrowserConfig, OrthlyFrontendApps } from '@orthly/ui';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

const config = new OrthlyBrowserConfig(OrthlyFrontendApps.practice);

function useScannerTokenAuthHeader(): string | undefined {
    const location = useLocation();
    const scannerTokenFromQueryParam = PracticeUtils.getParsedQueryProperty('scannerToken', location.search);
    if (scannerTokenFromQueryParam) {
        Sentry.captureException(new Error('Unexpected scanner token found in page URL'));
    }

    const scannerToken = sessionStorage.getItem('scannerToken') ?? scannerTokenFromQueryParam;
    return scannerToken ? `Bearer ${scannerToken}` : undefined;
}

export const PracticeGqlProvider: React.FC = props => {
    const scannerTokenHeader = useScannerTokenAuthHeader();
    return (
        <OrthlyApolloProvider
            authorizationHeader={scannerTokenHeader}
            clientName={process.env.REACT_APP_LOCAL_PROD ? `${config.appName}-LOCAL_PROD` : config.appName}
            version={config.version}
            env={process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : config.env}
            routerEndpoint={process.env.REACT_APP_GATEWAY_URL ?? '/graphql'}
            loadingFallback={null}
            disablePersistedCache={true}
        >
            {props.children}
        </OrthlyApolloProvider>
    );
};
