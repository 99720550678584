import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import { PrintableInvoice } from '../PrintableInvoice';
import { PracticeScreen } from '@orthly/dentin';
import { downloadAsCsv } from '@orthly/mui-table';
import { Format } from '@orthly/runtime-utils';
import { Button, styled } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';

const StyledContainer = styled('div')({
    padding: '20px 32px',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    '@media print': {
        display: 'none',
    },
    '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
    },
});

const DownloadContainer = styled('div')({
    display: 'flex',
    '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignContent: 'center',
    },
});

export const InvoiceDetailActionContainer: React.FC = () => {
    const { rows, doctorNameFilter, invoice, printableSlipIsOpen, openPrintableSlip } = useInvoiceDetailContext();
    const history = useHistory();

    const headerOnBack = () => {
        history.push(`/${PracticeScreen.billing}`);
    };

    const onClickDownload = () => {
        const downloadRows = rows.map(r => ({
            transaction_id: r.id,
            amount: Format.currency(r.amount_cents),
            category: r.category,
            effective_date: moment(r.effective_date).format('MM/DD/YYYY'),
            details: r.description,
            doctor: r.doctor_name,
        }));
        downloadAsCsv(downloadRows, `Dandy Statement #${invoice.invoice_number}`, { eol: '\n' });
    };

    const onPrint = () => {
        const dateTitle = moment(invoice.created_at).format('MMM-YYYY');
        const filterTitle = doctorNameFilter ? ` - ${doctorNameFilter}` : '';

        document.title = `Dandy Statement #${invoice.invoice_number}${filterTitle} - ${dateTitle}`;
        openPrintableSlip();
    };

    return (
        <StyledContainer>
            <Button onClick={headerOnBack} variant={'ghost'} startIcon={'ChevronLeft'}>
                Back to all invoices
            </Button>
            <DownloadContainer>
                <Button
                    variant={'ghost'}
                    onClick={onClickDownload}
                    style={{ marginLeft: 'auto' }}
                    endIcon={'DownloadIcon'}
                >
                    Export (CSV)
                </Button>
                <Button variant={'ghost'} onClick={onPrint} endIcon={'PrintIcon'}>
                    Download PDF
                </Button>
            </DownloadContainer>
            {printableSlipIsOpen && <PrintableInvoice />}
        </StyledContainer>
    );
};
