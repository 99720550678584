import type { TraineeAtom } from './useTrainees';
import { traineeSchema } from './useTrainees';
import { useAtom } from 'jotai';

/**
 * Custom hook to manage the state of the trainee.
 * @param {TraineeAtom} traineeAtom - Atom containing the trainee state
 * @param field - Optional field to check for errors
 * @returns {Object} - Object containing the trainee, setTrainee, and a function to check if a field has an error
 */
export const useTrainee = (traineeAtom: TraineeAtom, field?: keyof ReturnType<typeof traineeSchema.parse>) => {
    const [trainee, setTrainee] = useAtom(traineeAtom);
    let hasError = false;

    if (field) {
        const parsed = traineeSchema.safeParse(trainee);

        if (!parsed.success) {
            hasError = parsed.error.issues.flatMap(issue => issue.path).includes(field);
        }
    }

    return {
        trainee,
        setTrainee,
        hasError,
    };
};
