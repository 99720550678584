import type { CheckoutItemV2, CheckoutState } from '../checkout.state';

export const bulkItemsSelector =
    (bulkEditId: string) =>
    (s: CheckoutState): CheckoutItemV2[] => {
        if (!s.bulkEditIds.includes(bulkEditId)) {
            return [];
        }
        return s.items.filter(i => i.bulk_edit_id === bulkEditId);
    };
