import { hasFullStructuredNotesSelection } from '../../GuidedWaxup.util';
import { useStructuredNotesOptions } from '../../hooks/useStructuredNotesOptions';
import { useGuidedWaxupContext } from '../../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../../state/GuidedWaxupState';
import { RejectionNotesContainer } from '../RejectionNotesContainer';
import { StructuredRejectionNotesAddendum } from './StructuredRejectionNotesAddendum';
import { nestedCheckboxListUtils, NestedCheckboxList } from '@orthly/dentin';
import type { LabsGqlDesignOrderNoteCategory } from '@orthly/graphql-schema';
import React from 'react';

export const StructuredRejectionNotesContent: React.VFC = () => {
    const { selectedDesignRevisionAlreadyReviewed, selectedTab } = useGuidedWaxupContext();
    const preset = useGuidedWaxupSelector(s => s.presets[selectedTab]);
    const options = useStructuredNotesOptions(selectedTab);
    const structuredNotes = React.useMemo(
        () => nestedCheckboxListUtils.unflattenSelections(preset?.structured_notes ?? [], options),
        [options, preset],
    );

    const showNotesAddendum = React.useMemo(
        () => hasFullStructuredNotesSelection(selectedTab, preset?.structured_notes),
        [selectedTab, preset],
    );

    const setPresetStructuredRejectionNotes = useGuidedWaxupAction('SET_PRESET_STRUCTURED_REJECTION_NOTES');

    return (
        <RejectionNotesContainer title={'Sorry about that, what should we change?'}>
            <NestedCheckboxList
                disabled={selectedDesignRevisionAlreadyReviewed}
                options={options}
                selections={structuredNotes}
                onChange={selections =>
                    setPresetStructuredRejectionNotes({
                        presetName: selectedTab,
                        structuredRejectionNotes: nestedCheckboxListUtils.flattenSelections(
                            selections,
                        ) as LabsGqlDesignOrderNoteCategory[],
                    })
                }
            />
            {showNotesAddendum && (
                <StructuredRejectionNotesAddendum
                    disabled={selectedDesignRevisionAlreadyReviewed}
                    presetType={selectedTab}
                    hasBorder
                />
            )}
        </RejectionNotesContainer>
    );
};
