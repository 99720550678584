import { ShadeDisplaySVG } from './ShadeDisplaySVG';
import type { TeethShade } from '@orthly/items';
import { teethHexValues } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

interface ToothShadeDisplayProps {
    base?: TeethShade;
    gingival?: TeethShade;
    incisal?: TeethShade;
    rotate?: boolean;
    svgStyle?: React.CSSProperties;
}

export const ToothShadeDisplay: React.FC<ToothShadeDisplayProps> = props => {
    const { base, gingival, incisal, rotate, svgStyle } = props;
    return (
        <Grid style={rotate ? { transform: 'rotate(180deg)' } : undefined}>
            <ShadeDisplaySVG
                baseHex={!!base ? teethHexValues[base] : undefined}
                gingivalHex={!!gingival ? teethHexValues[gingival] : undefined}
                incisalHex={!!incisal ? teethHexValues[incisal] : undefined}
                style={svgStyle}
            />
        </Grid>
    );
};
