import type { LabsGqlPlacedOrderFragment } from '@orthly/graphql-operations';
import type { ApolloError } from '@orthly/ui';

export const placeOrderMutationOptions = (): {
    onCompleted?: (data: any) => void;
    onError?: (error: ApolloError) => void;
} => ({
    onCompleted: () => {},
    onError: error => {
        throw error; // rethrow error or promise.catch will eat it and cause a success snackbar
    },
});

export type SubmittedOrder = LabsGqlPlacedOrderFragment;
export type UseSubmitOrderResult = {
    isSubmitDisabled: boolean;
    submitting: boolean;
    submit: () => Promise<unknown>;
    submittedOrder?: SubmittedOrder;
};
