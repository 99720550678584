import type { MergeOrderItemOptions } from './mergeOrderItems';
import type { LabsGqlManufacturerProfileFragment } from '@orthly/graphql-operations';
import type { IOrderItemV2DTO, ICustomFieldSubmission } from '@orthly/items';
import { AllItemMetafields, CartItemV2Utils } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

// Finds if the given preference is redundant per the manufacturer's default prefs
function isPreferenceHidden(
    pref: ICustomFieldSubmission,
    manufacturerProfile?: LabsGqlManufacturerProfileFragment,
): boolean {
    return (
        manufacturerProfile?.custom_field_preferences?.find(
            manPref => manPref.field_id === pref.field_id && manPref.value === pref.value,
        ) !== undefined
    );
}

// Checks if the indicated preference is a majority
function isMajorityPreference(
    pref: ICustomFieldSubmission,
    majorityPrefValues: {
        unitType: string;
        prefs: Record<string, string>;
    }[],
): boolean {
    return (
        majorityPrefValues.find(unit => {
            return Object.keys(unit.prefs).includes(pref.field_id);
        }) !== undefined
    );
}

function prefGroupingKey(item: IOrderItemV2DTO): string {
    const primaryUnitType = CartItemV2Utils.getPrimaryUnitType(item);
    return primaryUnitType === 'Bridge' ? 'Crown' : primaryUnitType;
}

export type InlinePrefItem = IOrderItemV2DTO & {
    inlinePreferences: ICustomFieldSubmission[];
};

const metafieldsById = _.keyBy(AllItemMetafields, f => f.id);

function cleanedPrefValue(pref: ICustomFieldSubmission): string {
    function cleanPrefValue(value: string | number | boolean): string {
        return value.toString().trim().toLowerCase();
    }
    const cleanedValue = cleanPrefValue(pref.value);
    const field = metafieldsById[pref.field_id];
    if (!field || !field.options) {
        return cleanedValue;
    }
    const selectedOption = field.options.find(
        opt =>
            [opt.value, opt.label].includes(pref.value.toString()) ||
            [cleanPrefValue(opt.value), cleanPrefValue(opt.label)].includes(cleanedValue),
    );
    return selectedOption?.value ?? cleanedValue;
}

export function toInlinePrefsItem(item: IOrderItemV2DTO, opts: MergeOrderItemOptions): InlinePrefItem {
    const { majorityPrefs, hiddenPrefMap, respectMetafieldInlineValues } = opts;
    const majorityPrefsForUnit = majorityPrefs?.find(({ unitType }) => unitType === prefGroupingKey(item))?.prefs ?? {};
    // if we're respecting inline values, populate the inline value lookup. otherwise, set it to an empty record
    const inlineValuesByFieldId = respectMetafieldInlineValues
        ? _.fromPairs(AllItemMetafields.map(f => [f.id, f.order_form_inline_values ?? []]))
        : {};
    const inlinePreferences = item.preference_fields.filter(pref => {
        // if this pref is hidden, hide it!
        if (hiddenPrefMap?.[item.id]?.includes(pref.field_id)) {
            return false;
        }
        const submissionValue = cleanedPrefValue(pref);
        // if the pref differs from the majority, show it
        if (majorityPrefsForUnit[pref.field_id] !== submissionValue) {
            return true;
        }
        // if the pref should always be shown inline, show it!
        const isInline = inlineValuesByFieldId[pref.field_id]?.includes(submissionValue);
        return isInline;
    });
    return { ...item, inlinePreferences };
}

/**
 * Processes per-item preferences and returns data for grouping majority preferences, filtering them based on
 * the manufacturer profile, and doing layout.
 */
export const useGroupPreferences = (
    orderItems?: IOrderItemV2DTO[],
    respectMetafieldInlineValues?: boolean,
    manufacturerProfile?: LabsGqlManufacturerProfileFragment,
): {
    // The number of unique (unit type, pref name, pref value) triples in the order.
    uniquePreferenceValues: number;
    // The majority preference values for each unit. A preference value is in the majority
    // when most units, and at least two, have that value.
    majorityPrefValues: { unitType: string; prefs: Record<string, string> }[];
    // The total number of minority preference values.
    overridePrefCount: number;
    // Maps from item ID to preference field IDs for preferences that should be hidden because
    // their value is the default for the manufacturer.
    hiddenPrefMap: {
        [itemId: string]: string[];
    };
} =>
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useMemo(() => {
        let uniquePreferenceValues = 0;
        // prefCounts maps from (unit type, pref name, pref value) triple to the count for that triple.
        // It is structured as a 3-layer map, rather than mapping directly from the triple to the count.
        // Object of shape { unit_type: { pref_name: { value: count } } }
        const prefCounts = (orderItems ?? []).reduce(
            (prefCounts, li) => {
                const unitType = prefGroupingKey(li);
                const unitPrefCounts = li.preference_fields.reduce(
                    (unitPrefCounts, pref) => {
                        const existingCounts = unitPrefCounts[pref.field_id] || {};
                        const valueKey = cleanedPrefValue(pref);
                        if (existingCounts[valueKey] === undefined) {
                            uniquePreferenceValues += 1;
                        }
                        return {
                            ...unitPrefCounts,
                            [pref.field_id]: { ...existingCounts, [valueKey]: (existingCounts[valueKey] || 0) + 1 },
                        };
                    },
                    { ...prefCounts[unitType] },
                );
                return {
                    ...prefCounts,
                    [unitType]: unitPrefCounts,
                };
            },
            {} as Record<string, Record<string, Record<string, number>>>,
        );

        // majorityPrefValues collects the most common value for each preference. It omits values that
        // are the default for the manufacturer, or should appear inline based on order_form_inline_values.
        // Object of shape [{ unitType: string; prefs: { pref_name: value }}]
        const majorityPrefValues: { unitType: string; prefs: Record<string, string> }[] = Object.entries(prefCounts)
            .map(([unitType, unitTypePrefs]) => ({
                unitType,
                prefs: Object.entries(unitTypePrefs).reduce(
                    (maxPrefs, [pref_id, valueCounts]) => {
                        const maxPair = _.maxBy(Object.entries(valueCounts), ([_, count]) => count);
                        if (!maxPair || maxPair[1] < 2) {
                            // A majority of 1 is not worth pulling out.
                            return {
                                ...maxPrefs,
                            };
                        }
                        const customField = respectMetafieldInlineValues
                            ? AllItemMetafields.find(cf => cf.label === pref_id)
                            : undefined;
                        const isMaxDefault =
                            manufacturerProfile?.custom_field_preferences?.find(
                                pref => cleanedPrefValue(pref) === maxPair[0] && pref.field_id === pref_id,
                            ) !== undefined;
                        // If the most common pref value for this id is always set to appear inline, omit it from the grouped display
                        if (
                            isMaxDefault ||
                            (!!maxPair && customField?.order_form_inline_values?.includes(maxPair[0]))
                        ) {
                            return {
                                ...maxPrefs,
                            };
                        }
                        return {
                            ...maxPrefs,
                            [pref_id]: maxPair[0],
                        };
                    },
                    {} as Record<string, string>,
                ),
            }))
            .filter(({ prefs }) => Object.keys(prefs).length > 0);

        // overridePrefCount is the total number of minority preference values.
        const overridePrefCount = _.sumBy(Object.values(prefCounts), unitTypePrefs =>
            _.sumBy(
                Object.values(unitTypePrefs),
                valueCounts => _.sum(Object.values(valueCounts)) - Math.max(...Object.values(valueCounts)),
            ),
        );

        // Some prefs are hidden from the view simply because they are redundant for manufacturers
        // Each pref in a manufacturer profile which has the same id/val as the pref in the order form
        // is excluded from pref counts.
        // We generate a mapping of item id to the prefs that are hidden
        const hiddenPrefMap = (orderItems ?? []).reduce<{ [itemId: string]: string[] }>((prefs, item) => {
            const hiddenPrefs = item.preference_fields.filter(
                pref =>
                    isPreferenceHidden(pref, manufacturerProfile) && !isMajorityPreference(pref, majorityPrefValues),
            );
            return Object.assign(prefs, { [item.id]: hiddenPrefs.map(pref => pref.field_id) });
        }, {});

        return {
            uniquePreferenceValues,
            majorityPrefValues,
            overridePrefCount,
            hiddenPrefMap,
        };
    }, [respectMetafieldInlineValues, orderItems, manufacturerProfile]);
