import { useCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { CheckoutDisplayUtils, checkoutRefabOrderSummaryText } from '../../state/CheckoutDisplayUtils';
import { CheckoutItemV2Manager } from '../../state/CheckoutItemV2Manager';
import { useCheckoutAction } from '../../state/checkout.actions';
import { getActiveItemIndex, getLastVisitedItemIndex } from '../../state/checkout.screens';
import { useScanIsAligner } from '../../state/checkout.selectors';
import type { CheckoutItemV2, CheckoutStep } from '../../state/checkout.state';
import { bulkItemsSelector } from '../../state/selectors/bulk-items.selectors';
import { CheckoutMouthContainer } from './CheckoutMouth.container';
import { CartItemV2Utils } from '@orthly/items';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    createStyles,
    makeStyles,
    Collapse,
    Grid,
    IconButton,
    Tooltip,
    Text,
    Typography,
    UndoIcon,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            background: FlossPalette.TAN,
            paddingTop: 16,
            position: 'relative',
            height: 'calc(100vh - 64px)',
            overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
                height: '100%',
                paddingTop: 0,
                flexDirection: 'column',
                flexWrap: 'nowrap',
                paddingBottom: 60,
            },
        },
        rootColumn: {
            position: 'relative',
        },
        row: {
            position: 'relative',
            padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(48)}`,
            background: FlossPalette.TAN,
            overflowY: 'hidden',
            [theme.breakpoints.down('md')]: {
                padding: `${theme.typography.pxToRem(16)} ${theme.typography.pxToRem(24)}`,
            },
        },
        halfText: { maxWidth: '50%' },
        activeOverlay: {
            transition: theme.transitions.create(['top', 'height'], {
                duration: 200,
                easing: theme.transitions.easing.sharp,
            }),
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            background: '#000',
            opacity: 0.05,
            pointerEvents: 'none',
        },
        subtitle: {
            display: 'flex',
            alignItems: 'center',
        },
        subtitleClickable: {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        subtitleDeleted: { color: theme.palette.error.main },
        mouthWrapper: {
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            paddingBottom: 16,
            maxHeight: '50vh',
        },
        itemBullet: {
            marginRight: 4,
            color: CheckoutDisplayUtils.DEFAULT_STROKE,
        },
    }),
);

const CheckoutSidebarRootSummary: React.FC = () => {
    const classes = useStyles();
    const doctorName = useCheckoutSelector(s => (s.doctor ? s.doctor.formatted_name : '-'));
    const deliveryAddress = useCheckoutSelector(s =>
        s.address ? _.startCase(`${s.address.street_one}`.toLowerCase()) : '-',
    );
    const patient = useCheckoutSelector(s =>
        s.patient_first_name && s.patient_last_name ? `${s.patient_first_name} ${s.patient_last_name}` : '-',
    );
    const rows = React.useMemo(() => {
        return [
            { name: 'Doctor', value: doctorName },
            { name: 'Delivery address', value: deliveryAddress },
            { name: 'Patient', value: patient },
        ];
    }, [deliveryAddress, doctorName, patient]);
    return (
        <>
            {rows.map(row => (
                <Grid
                    key={row.name}
                    container
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    style={{ paddingBottom: 2 }}
                >
                    <Typography color={'textSecondary'} className={classes.halfText}>
                        {row.name}
                    </Typography>
                    <Typography
                        color={'textPrimary'}
                        className={classes.halfText}
                        style={{ fontWeight: 500, textAlign: 'right' }}
                    >
                        {row.value}
                    </Typography>
                </Grid>
            ))}
        </>
    );
};

interface CheckoutSidebarItemTitleProps {
    title: string;
    itemState: 'deleted' | 'active' | 'complete' | 'incomplete';
    onClick?: () => void;
}

const CheckoutSidebarItemTitle: React.FC<CheckoutSidebarItemTitleProps> = props => {
    const { onClick, title, itemState } = props;
    const deleted = itemState === 'deleted';
    const classes = useStyles();
    return (
        <Typography
            className={cx(classes.subtitle, deleted && classes.subtitleDeleted)}
            color={itemState !== 'complete' ? 'inherit' : 'textPrimary'}
            style={{ maxWidth: '70%' }}
            onClick={onClick}
        >
            <span
                className={classes.itemBullet}
                style={itemState === 'active' || deleted ? { color: 'inherit' } : undefined}
            >
                {itemState === 'active' || deleted ? <>&#x25cf;</> : <>&#9675;</>}
            </span>
            {itemState !== 'complete' ? title : <span className={classes.subtitleClickable}>{title}</span>}
        </Typography>
    );
};

interface CheckoutSidebarRootItemProps {
    itemActive: boolean;
    itemComplete: boolean;
    title: string;
    completeTitle?: string;
    onTitleClick?: () => void;
    deleted?: boolean;
    onUndeleteClick?: () => void;
}

const CheckoutSidebarRootItem: React.VFC<CheckoutSidebarRootItemProps> = props => {
    const classes = useStyles();
    const { completeTitle, itemActive, itemComplete, title, onTitleClick, onUndeleteClick, deleted } = props;
    const content = React.useMemo(() => {
        if (deleted) {
            return 'Deleted';
        }

        if (itemActive) {
            return 'Reviewing';
        }

        if (!itemComplete) {
            return 'To Review';
        }

        return completeTitle ?? null;
    }, [completeTitle, deleted, itemActive, itemComplete]);

    return (
        <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ paddingBottom: 2, color: itemActive ? FlossPalette.HIGHLIGHT_BLUE : undefined }}
        >
            <CheckoutSidebarItemTitle
                title={title}
                // eslint-disable-next-line no-nested-ternary
                itemState={deleted ? 'deleted' : itemActive ? 'active' : itemComplete ? 'complete' : 'incomplete'}
                onClick={onTitleClick}
            />
            <Typography
                className={cx(classes.subtitle, deleted && classes.subtitleDeleted)}
                color={itemActive ? 'inherit' : 'textSecondary'}
                style={{ maxWidth: '30%' }}
            >
                {deleted && (
                    <IconButton size={'small'} style={{ padding: 0, marginRight: 4 }} onClick={onUndeleteClick}>
                        <Tooltip title={'Add item back to order'} arrow placement={'left'}>
                            <UndoIcon color={'error'} style={{ width: 20, height: 20 }} />
                        </Tooltip>
                    </IconButton>
                )}
                {content}
            </Typography>
        </Grid>
    );
};

function useSidebarItemTitle(item: CheckoutItemV2) {
    const bulkItems = useCheckoutSelector(bulkItemsSelector(item.bulk_edit_id));
    return React.useMemo<string>(() => {
        const teeth =
            bulkItems.length > 1
                ? _.sortBy(_.uniq(bulkItems.flatMap(i => CartItemV2Utils.getUniqueUNNs(i))))
                : CartItemV2Utils.getUniqueUNNs(item);
        const teethFormatted = teeth.length > 1 ? ` for teeth ${teeth.join(', ')}` : '';
        const unit = `${CartItemV2Utils.getDisplayName(item)}${bulkItems.length > 1 ? 's' : ''}`;
        return `${bulkItems.length > 1 ? bulkItems.length : 1}x ${unit}${teethFormatted}`;
    }, [bulkItems, item]);
}

interface CheckoutSidebarRootItemRowProps {
    item: CheckoutItemV2;
    bulkCount?: number;
    active: boolean;
    deleted?: boolean;
}

const CheckoutSidebarRootItemRow: React.FC<CheckoutSidebarRootItemRowProps> = props => {
    const { item, deleted } = props;
    const undeleteItem = useCheckoutAction('ADD_SCAN_ITEM_BACK');
    const { step } = useCheckoutPropSelector(['step']);
    const lastVisitedItemIndex = useCheckoutSelector(getLastVisitedItemIndex);
    const itemActive = props.active && step === 1;
    const itemComplete = (step === 1 && item.item_index <= lastVisitedItemIndex && !itemActive) || step > 1;
    const goToItem = useCheckoutAction('SET_ACTIVE_ITEM_IDX');
    const title = useSidebarItemTitle(props.item);
    return (
        <CheckoutSidebarRootItem
            itemActive={itemActive}
            itemComplete={itemComplete}
            title={title}
            completeTitle={'Complete'}
            deleted={deleted}
            onTitleClick={() => itemComplete && goToItem(item.item_index)}
            onUndeleteClick={() => undeleteItem(item.item_index)}
        />
    );
};

export const CheckoutSidebarItems: React.FC = () => {
    const { bulkEditIds, items, removed_scan_items } = useCheckoutPropSelector([
        'bulkEditIds',
        'items',
        'removed_scan_items',
    ]);
    const activeItemIndex = useCheckoutSelector(getActiveItemIndex);

    const deletedItemIds = React.useMemo(() => _.map(removed_scan_items, i => i.item_index), [removed_scan_items]);
    const itemsByBulkId = React.useMemo(
        () => CheckoutItemV2Manager.getBulkGroups(CheckoutItemV2Manager.sortItems([...items, ...removed_scan_items])),
        [removed_scan_items, items],
    );

    // Collect the representative item from each bulk edit group.
    const representativeItems = Object.entries(itemsByBulkId).flatMap(([bulkId, matchingItems]) => {
        const firstItem = matchingItems[0];
        const isBulkMode = bulkEditIds.includes(bulkId);
        if (firstItem && isBulkMode) {
            return firstItem;
        }
        return matchingItems;
    });

    const waxupSelected = useCheckoutSelector(s => s.waxupState.selected);
    return (
        <Grid container style={{ paddingTop: 16 }}>
            {waxupSelected && (
                <CheckoutSidebarRootItem
                    itemActive={false}
                    itemComplete={true}
                    title={'1x Design Preview'}
                    completeTitle={'Complimentary'}
                />
            )}
            {CheckoutItemV2Manager.sortItems(representativeItems).map(item => {
                const active = activeItemIndex === item.item_index;
                const bulkId = item.bulk_edit_id;
                const bulkCount = bulkEditIds.includes(bulkId) ? itemsByBulkId[bulkId]?.length ?? 1 : 1;
                return (
                    <CheckoutSidebarRootItemRow
                        key={item.item_index}
                        item={item}
                        bulkCount={bulkCount}
                        active={active}
                        deleted={deletedItemIds.includes(item.item_index)}
                    />
                );
            })}
        </Grid>
    );
};

const RefabricationAlert: React.FC = () => {
    const classes = useStyles();
    const refabOrder = useCheckoutSelector(s => s.refab?.original_order);
    const summary = checkoutRefabOrderSummaryText(refabOrder);
    return (
        <Collapse in={!!refabOrder} style={{ width: '100%', position: 'relative' }}>
            <Grid container className={classes.row} style={{ backgroundColor: FlossPalette.ATTENTION }}>
                <Text style={{ color: '#fff' }}>{summary}</Text>
            </Grid>
        </Collapse>
    );
};

type Measures = { [K in CheckoutStep]?: { top: number; height: number } };

/**
 * Keeps track of the active sidebar section for positioning the overlay which shows the active section,
 * along with the sizes of each section (updated via the returned `setMeasureFromRef` callback)
 */
function useSectionMeasurements() {
    const activeStep = useCheckoutSelector(s => s.step);
    const [measurements, setMeasurements] = React.useState<Measures>({});
    const setMeasureFromRef = (refStep: CheckoutStep) => (instance: HTMLElement | null) => {
        if (!instance || instance.offsetHeight === 0 || instance.offsetTop === 0) {
            return;
        }
        const activeMeasure = measurements[refStep];
        const newMeasure = {
            top: instance.offsetTop,
            height: instance.offsetHeight,
        };
        if (activeMeasure?.top !== newMeasure.top || activeMeasure?.height !== newMeasure.height) {
            setMeasurements({ ...measurements, [refStep]: newMeasure });
        }
    };
    const activeMeasurement = React.useMemo(() => {
        return activeStep === 0 ? measurements[0] : measurements[1];
    }, [activeStep, measurements]);
    return { setMeasureFromRef, activeMeasurement };
}

export const CheckoutSidebarRoot: React.FC = () => {
    const classes = useStyles();
    const { setMeasureFromRef, activeMeasurement } = useSectionMeasurements();
    const isAlignerOrder = useScanIsAligner();
    return (
        <Grid container className={classes.root}>
            <Grid container direction={'column'} className={classes.rootColumn} wrap={'nowrap'}>
                <Grid container className={classes.row}>
                    <Grid container style={{ borderBottom: `1px solid ${FlossPalette.DARK_TAN}`, paddingBottom: 12 }}>
                        <Text variant={'h5'}>Summary</Text>
                    </Grid>
                </Grid>
                <Grid container className={classes.row} ref={setMeasureFromRef(0)}>
                    <CheckoutSidebarRootSummary />
                </Grid>
                <RefabricationAlert />
                <Grid container className={classes.row} ref={setMeasureFromRef(1)}>
                    <Grid container className={classes.mouthWrapper}>
                        <CheckoutMouthContainer layout={'horizontal'} />
                    </Grid>
                    <CheckoutSidebarItems />
                </Grid>
                {!isAlignerOrder && (
                    // render the dark overlay for the active section of the checkout flow
                    // aligner checkout is only one page right now so not used
                    <Grid
                        container
                        className={classes.activeOverlay}
                        style={{ top: activeMeasurement?.top ?? 0, height: activeMeasurement?.height ?? 0 }}
                    />
                )}
            </Grid>
        </Grid>
    );
};
