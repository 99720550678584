import { OrderAction, OrderActionTrackerIdMap } from '../../OrderActionsUtils';
import { NewBadge } from '../NewBadge';
import type { PauseOrResumeOrderActionProps } from './PauseOrResumeOrderAction.types';
import { PauseOrderActionContent } from './PauseOrderActionContent';
import { SplitOrderBanner } from './SplitOrderBanner';
import { useCreateOrderHold } from './hooks/useCreateOrderHold';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { RootActionDialog } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import React from 'react';

type PauseOrderActionProps = PauseOrResumeOrderActionProps;

export const PauseOrderAction: React.VFC<PauseOrderActionProps> = ({
    order,
    refetch,
    leftAlign,
    buttonVariant,
    preselectedOption,
}) => {
    const [open, setOpen] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState<string | undefined>(preselectedOption);

    const orderHasSplitOrders = (order.split_order_count ?? 0) > 0;

    const openPauseOrderModal = React.useCallback(() => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Modal Opened', {
            $groups: { order: order.id },
        });

        setOpen(true);
    }, [setOpen, order.id]);

    const closePauseOrderModal = React.useCallback(() => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Pause Order - Modal Abandoned', {
            $groups: { order: order.id },
        });

        setOpen(false);
        setSelectedOption(undefined);
    }, [setOpen, setSelectedOption, order.id]);

    const { submit: submitCreateHold, submitting: submittingCreateHold } = useCreateOrderHold(
        order.id,
        () => {
            refetch();
            setOpen(false);
        },
        () => {
            setOpen(false);
        },
    );

    const onPauseAction = () => {
        void submitCreateHold({
            hold_details: {
                hold_category: 'Doctor requested hold',
                hold_subcategory: selectedOption ?? '',
                hold_is_practice_managed_pause: true,
            },
            orderId: order.id,
            show_hold_status_to_practice: true,
        });
    };

    return (
        <RootActionDialog
            showCloseButton
            disableActionsBorder
            loading={submittingCreateHold}
            open={open}
            setOpen={setOpen}
            onClose={closePauseOrderModal}
            maxWidth={'md'}
            title={'Pause this order'}
            content={
                <PauseOrderActionContent
                    order={order}
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    orderHasSplitOrders={orderHasSplitOrders}
                    onCloseAction={closePauseOrderModal}
                    onPauseAction={onPauseAction}
                />
            }
            actions={orderHasSplitOrders && <SplitOrderBanner />}
            CustomButton={() => (
                <Button
                    data-tracker-id={OrderActionTrackerIdMap[OrderAction.PauseOrder]}
                    data-test={'open-pause-order-modal-button'}
                    leftAlign={leftAlign}
                    variant={buttonVariant}
                    startIcon={'PauseIcon'}
                    onClick={openPauseOrderModal}
                >
                    Pause order <NewBadge>New</NewBadge>
                </Button>
            )}
        />
    );
};
