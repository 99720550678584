import { useDandyBillingAddress } from '../../../../utils/useDandyBillingAddress';
import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import type { SummaryRow } from './InvoiceDetailBody.utils';
import { isPaymentOrCreditRefundCategory, useSummaryRows } from './InvoiceDetailBody.utils';
import { InvoiceFilters } from './InvoiceFilters';
import { InvoiceTransactionsTable } from './InvoiceTransactionsTable';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker, Table } from '@orthly/ui';
import { Box, FlossPalette, TableBody, TableCell, TableHead, TableRow, Text, styled } from '@orthly/ui-primitives';

const AddressDetails: React.FC = () => {
    const { billingContactInfoLoading, organizationName, billingEmail } = useInvoiceDetailContext();
    const dandyBillingAddress = useDandyBillingAddress();

    return (
        <LoadBlocker blocking={billingContactInfoLoading}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Text variant={'body1'} medium style={{ marginBottom: '8px' }}>
                    From
                </Text>
                <Text variant={'body2'} color={'GRAY'}>
                    {dandyBillingAddress.name}
                    <br />
                    {dandyBillingAddress.street_one}
                    <br />
                    {dandyBillingAddress.city}, {dandyBillingAddress.state} {dandyBillingAddress.postal_code}
                    <br />
                    cxbilling@meetdandy.com
                </Text>

                {organizationName && (
                    <>
                        <Text variant={'body1'} medium style={{ marginTop: '24px', marginBottom: '8px' }}>
                            To
                        </Text>
                        <Text variant={'body2'} color={'GRAY'}>
                            {organizationName}
                            <br />
                            {billingEmail}
                        </Text>
                    </>
                )}
            </div>
        </LoadBlocker>
    );
};

const Container = styled('div')({
    display: 'flex',
    width: '100%',
    marginTop: '24px',
    '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
    },
});

const SummaryContainer = styled('div')({
    width: '67%',
});

const AddressContainer = styled('div')({
    width: '33%',
    marginLeft: '24px',
    '@media screen and (max-width: 768px)': {
        marginTop: '16px',
        width: '100%',
        marginLeft: '0px',
    },
});

const TitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    '@media screen and (max-width: 768px)': {
        flexDirection: 'column',
    },
});

const StyledTitle = styled(Text)({
    marginRight: 'auto',
});

const StyledTable = styled(Table)({
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: '4px !important',
    borderCollapse: 'separate',
});

const StyledTableHead = styled(TableHead)({
    height: '56px',
    borderTopLeftRadius: '4px !important',
    borderTopRightRadius: '4px !important',
});

const StyledTableCell = styled(TableCell)({
    border: 'none',
});

export const TextWithIconContainer = styled('div')({
    display: 'inline-flex',
});

const StyledTableHeadCell = styled(TableCell)({
    backgroundColor: FlossPalette.TAN,
    '&:first-of-type': {
        // subtract 1px to account for the border on the table
        borderTopLeftRadius: '3px !important',
    },
    '&:last-of-type': {
        // subtract 1px to account for the border on the table
        borderTopRightRadius: '3px !important',
    },
});

const StyledTableBody = styled(TableBody)({
    '& tr': {
        height: '72px',
    },
});

const SummaryTable: React.FC<{ summaryRows: SummaryRow[] }> = ({ summaryRows }) => {
    return (
        <StyledTable>
            <StyledTableHead>
                <TableRow>
                    <StyledTableHeadCell variant={'head'}>Type</StyledTableHeadCell>
                    <StyledTableHeadCell variant={'head'}>Quantity</StyledTableHeadCell>
                    <StyledTableHeadCell variant={'head'}>Amount</StyledTableHeadCell>
                </TableRow>
            </StyledTableHead>
            <StyledTableBody>
                {summaryRows.map(row => {
                    const rowTextColor = isPaymentOrCreditRefundCategory(row) ? 'GREEN' : 'BLACK';
                    return (
                        <TableRow key={row.id}>
                            <StyledTableCell>
                                <TextWithIconContainer>
                                    <Text variant={'body2'} color={rowTextColor}>
                                        {row.id}
                                    </Text>
                                    {row.tooltip}
                                </TextWithIconContainer>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant={'body2'} color={rowTextColor}>
                                    {row.quantity ?? ' '}
                                </Text>
                            </StyledTableCell>
                            <StyledTableCell>
                                <Text variant={'body2'} color={rowTextColor}>
                                    {Format.currency(row.amountCents)}
                                </Text>
                            </StyledTableCell>
                        </TableRow>
                    );
                })}
            </StyledTableBody>
        </StyledTable>
    );
};

export const InvoiceDetailBody: React.FC = () => {
    const { invoice, rows, rowsLoading } = useInvoiceDetailContext();
    const summaryRows = useSummaryRows(invoice, rows);

    return (
        <LoadBlocker blocking={rowsLoading}>
            <Box sx={{ width: '100%' }}>
                <Box component={'section'} sx={{ padding: '32px' }}>
                    <TitleContainer>
                        <StyledTitle variant={'h5'}>Invoice #{invoice.invoice_number}</StyledTitle>
                        <InvoiceFilters />
                    </TitleContainer>

                    <Container>
                        <SummaryContainer>
                            <SummaryTable summaryRows={summaryRows} />
                        </SummaryContainer>
                        <AddressContainer>
                            <AddressDetails />
                        </AddressContainer>
                    </Container>
                </Box>
                <InvoiceTransactionsTable />
            </Box>
        </LoadBlocker>
    );
};
