import { CheckoutLargeRadioRow } from '../LegacyCheckoutFormComponents/CheckoutLargeRadioRow';
import type { LabsGqlPendingAlignerCaseBiteConcernsInput } from '@orthly/graphql-schema';
import { LabsGqlBiteConcernOptions } from '@orthly/graphql-schema';
import { Text, Grid, TextField } from '@orthly/ui-primitives';
import React from 'react';

interface AlignerBiteConcernsSelectionProps {
    biteConcerns: LabsGqlPendingAlignerCaseBiteConcernsInput;
    updateBiteConcerns: (val: LabsGqlPendingAlignerCaseBiteConcernsInput) => void;
    notes?: string;
    updateNotes: (val: string) => void;
    small?: boolean;
}

export const AlignerBiteConcernsSelection: React.VFC<AlignerBiteConcernsSelectionProps> = ({
    biteConcerns,
    updateBiteConcerns,
    notes,
    updateNotes,
    small,
}) => (
    <>
        <Grid container direction={'column'} wrap={'nowrap'} style={{ marginTop: 16 }}>
            <CheckoutLargeRadioRow<LabsGqlBiteConcernOptions>
                small={small}
                value={biteConcerns.improve_overjet}
                options={[
                    {
                        value: LabsGqlBiteConcernOptions.ShowOutcome,
                        label: 'Show outcome after alignment',
                    },
                    {
                        value: LabsGqlBiteConcernOptions.Improve,
                        label: 'Improve overjet (may require IPR)',
                    },
                ]}
                onChange={improve_overjet => updateBiteConcerns({ ...biteConcerns, improve_overjet })}
                label={'Overjet'}
                description={'Horizontal overlap of anterior teeth.'}
            />
            <CheckoutLargeRadioRow<LabsGqlBiteConcernOptions>
                small={small}
                value={biteConcerns.improve_overbite}
                options={[
                    {
                        value: LabsGqlBiteConcernOptions.ShowOutcome,
                        label: 'Show outcome after alignment',
                    },
                    {
                        value: LabsGqlBiteConcernOptions.Improve,
                        label: 'Improve overbite',
                    },
                ]}
                onChange={improve_overbite => updateBiteConcerns({ ...biteConcerns, improve_overbite })}
                label={'Overbite'}
                description={'Vertical overlap of anterior teeth.'}
            />
            <CheckoutLargeRadioRow<LabsGqlBiteConcernOptions>
                small={small}
                value={biteConcerns.improve_midline}
                options={[
                    {
                        value: LabsGqlBiteConcernOptions.ShowOutcome,
                        label: 'Show outcome after alignment',
                    },
                    {
                        value: LabsGqlBiteConcernOptions.Improve,
                        label: 'Improve midline (may require IPR)',
                    },
                ]}
                onChange={improve_midline => updateBiteConcerns({ ...biteConcerns, improve_midline })}
                label={'Midline'}
                description={'The contact points between upper and lower central incisors.'}
            />
        </Grid>
        <Grid container direction={'column'} wrap={'nowrap'} style={{ marginTop: 16 }}>
            <Text variant={'h6'}>Anything else you'd like us to know?</Text>
            <Text variant={'body2'}>
                Any context around your treatment goals will help us produce the perfect treatment.
            </Text>
            <TextField
                variant={'standard'}
                style={{ marginTop: 16 }}
                InputProps={{ style: { paddingTop: 0 } }}
                defaultValue={notes}
                fullWidth
                multiline
                onBlur={({ target: { value } }) => updateNotes(value)}
                onFocus={({ target: { value } }) => updateNotes(value)}
                placeholder={'Notes...'}
                minRows={small ? 2 : 4}
            />
        </Grid>
    </>
);
