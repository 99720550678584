import { openZDChat, closeZDChat } from '../useZendeskChat';
import { ChatActions } from './chat.actions';
import { isCsAvailable } from '@orthly/date-fns-business-days';
import { handleActions } from 'redux-actions';

export interface ChatState {
    zendeskOpen: boolean;
    zendeskUnread: number;
    outOfOfficeOpen: boolean;
    zendeskLoaded: boolean;
}

const initialState: ChatState = {
    zendeskOpen: false,
    zendeskUnread: 0,
    outOfOfficeOpen: false,
    zendeskLoaded: false,
};

export const chatReducer = handleActions<ChatState, any>(
    {
        ...ChatActions.OPEN_ZD_CHAT.reducer((state, action) => {
            // If we're out of chat hours, don't allow the user to
            // open the chat widget unless they have an ongoing chat with
            // unread messages.  This check can be overridden in
            // either direction by the chat availability flag (passed in
            // as the action payload)
            let available = isCsAvailable(new Date());
            // Even turning the flag to offline shouldn't stop an
            // in-progress chat
            if (action.payload === 'offline' && state.zendeskUnread === 0) {
                available = false;
            }
            if (action.payload === 'online') {
                available = true;
            }

            if (!available && state.zendeskUnread === 0) {
                return { ...state, outOfOfficeOpen: true };
            }
            void openZDChat();
            return { ...state, zendeskOpen: true };
        }),
        ...ChatActions.CLOSE_ZD_CHAT.reducer(state => {
            closeZDChat();
            return { ...state, zendeskOpen: false, outOfOfficeOpen: false };
        }),
        ...ChatActions.SET_ZD_CHAT_OPEN.reducer((state, action) => ({
            ...state,
            zendeskOpen: action.payload,
        })),
        ...ChatActions.SET_ZD_CHAT_UNREAD.reducer((state, action) => ({
            ...state,
            zendeskUnread: action.payload,
        })),
        ...ChatActions.CLOSE_OUT_OF_OFFICE.reducer(state => ({
            ...state,
            outOfOfficeOpen: false,
        })),
        ...ChatActions.SET_ZD_CHAT_LOADED.reducer(state => ({
            ...state,
            zendeskLoaded: true,
        })),
    },
    initialState,
);
