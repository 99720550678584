import { FormHelperText } from '@orthly/ui-primitives';
import { ErrorMessage } from 'formik';
import React from 'react';

interface QFHelperTextProps {
    text?: string | React.ReactNode;
    style?: React.CSSProperties;
}

export const QFHelperText: React.FC<QFHelperTextProps> = ({ text, style }) => {
    if (!text) {
        return null;
    }
    return typeof text === 'string' ? (
        <FormHelperText style={{ whiteSpace: 'normal', ...style }}>{text}</FormHelperText>
    ) : (
        <>{text}</>
    );
};

interface QFErrorTextProps {
    fieldName: string;
    style?: React.CSSProperties;
}

export const QFErrorText: React.FC<QFErrorTextProps> = props => {
    return (
        <ErrorMessage
            name={props.fieldName}
            render={errorMessage => (
                <FormHelperText
                    style={{ ...props.style, display: errorMessage.length === 1 ? 'none' : undefined }}
                    error={true}
                >
                    {errorMessage}
                </FormHelperText>
            )}
        />
    );
};
