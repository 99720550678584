import { useSelectStaffAction } from '../../state/select-staff.actions';
import { useSelectStaffSelector } from '../../state/select-staff.selectors';
import { StaffProfilePhotoSelection } from './SelectStaffAddPhoto.types';
import { LabsGqlDoctorProfilePhotoColor, LabsGqlDoctorProfilePhotoType } from '@orthly/graphql-schema';
import { WebcamIcon } from '@orthly/ui';
import { stylesFactory, Card, Grid, Button, Text } from '@orthly/ui-primitives';
import { DoctorProfilePhotoColorPicker, DoctorProfilePhotoContent, PROFILE_PHOTO_AVATAR_OPTIONS } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    flex: {
        flexWrap: 'nowrap',
        rowGap: '32px',
        columnGap: '16px',
        flexDirection: 'row',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    },
    card: {
        width: 328,
        borderRadius: 16,
        padding: 24,
    },
}));

const WebcamCallToAction: React.VFC<
    React.ComponentProps<typeof Card> & { setSelection: (variant: StaffProfilePhotoSelection) => void }
> = ({ setSelection, ...props }) => {
    const classes = useStyles();
    const onClick = React.useCallback(() => setSelection(StaffProfilePhotoSelection.Webcam), [setSelection]);
    return (
        <Card className={classes.card} {...props}>
            <WebcamIcon />
            <Text variant={'h6'} medium>
                Make it personal with a photo
            </Text>
            <Text variant={'body2'}>Use the webcam on your Dandy laptop to take a photo</Text>
            <Grid container direction={'row'} wrap={'nowrap'} style={{ gap: 8, marginTop: 8 }}>
                <Button variant={'secondary'} onClick={onClick} style={{ backgroundColor: 'unset' }}>
                    Take a photo
                </Button>
                <Button variant={'secondary'} onClick={onClick} style={{ backgroundColor: 'unset' }}>
                    Upload
                </Button>
            </Grid>
        </Card>
    );
};

export const SelectStaffAddPhotoSelectAvatar: React.VFC<{
    setSelection: (variant: StaffProfilePhotoSelection) => void;
}> = ({ setSelection }) => {
    const classes = useStyles();
    const addCreationField = useSelectStaffAction('ADD_STAFF_CREATION_FIELD');
    const nextStep = useSelectStaffAction('NEXT_SELECT_STAFF_STEP');
    const profile_photo = useSelectStaffSelector(({ profileCreationState }) => profileCreationState.profile_photo);
    return (
        <Grid container direction={'column'} wrap={'nowrap'}>
            <Grid container className={classes.flex} style={{ marginTop: 32 }}>
                <Grid container direction={'column'} wrap={'nowrap'} style={{ flexBasis: 1, flexGrow: 1 }}>
                    <Text variant={'h6'}>Choose an avatar</Text>
                    <Grid container direction={'row'} wrap={'wrap'} style={{ gap: 20, marginTop: 16 }}>
                        {PROFILE_PHOTO_AVATAR_OPTIONS.map(source => (
                            <DoctorProfilePhotoContent
                                key={source}
                                type={LabsGqlDoctorProfilePhotoType.Avatar}
                                source={source}
                                color={profile_photo.color}
                                selected={profile_photo.source === source}
                                onClick={() =>
                                    addCreationField({
                                        profile_photo: {
                                            ...profile_photo,
                                            source,
                                            type: LabsGqlDoctorProfilePhotoType.Avatar,
                                        },
                                    })
                                }
                            />
                        ))}
                        <DoctorProfilePhotoContent
                            type={LabsGqlDoctorProfilePhotoType.None}
                            source={'T'}
                            color={profile_photo.color}
                            selected={profile_photo.type === LabsGqlDoctorProfilePhotoType.None}
                            onClick={() =>
                                addCreationField({
                                    profile_photo: {
                                        ...profile_photo,
                                        type: LabsGqlDoctorProfilePhotoType.None,
                                        source: undefined,
                                    },
                                })
                            }
                        />
                    </Grid>
                    <Text variant={'h6'} style={{ marginTop: 32 }}>
                        Pick a background color
                    </Text>
                    <Grid container direction={'row'} wrap={'wrap'} style={{ gap: 8, marginTop: 8 }}>
                        {Object.values(LabsGqlDoctorProfilePhotoColor).map(color => (
                            <DoctorProfilePhotoColorPicker
                                key={color}
                                color={color}
                                selected={profile_photo?.color === color}
                                onClick={color => addCreationField({ profile_photo: { ...profile_photo, color } })}
                            />
                        ))}
                    </Grid>
                </Grid>
                <WebcamCallToAction style={{ alignSelf: 'flex-start' }} setSelection={setSelection} />
            </Grid>
            <Button variant={'primary'} onClick={nextStep} style={{ marginTop: 40, alignSelf: 'flex-start' }}>
                Continue
            </Button>
        </Grid>
    );
};
