import { EstimateProgressRow } from './EstimateProgressRow';
import { AlignerEstimateStep, orderedAlignerEstimateSteps } from '@orthly/shared-types';
import { useInterval } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const ANIMATION_DURATION = 2 * 60 * 1000; // 2 minutes

function useAutoProgress(activeStep: AlignerEstimateStep, setActiveStep: (step: AlignerEstimateStep) => void) {
    const updateEstimateProgress = React.useCallback(() => {
        const activeIndex = orderedAlignerEstimateSteps.indexOf(activeStep);
        // don't allow the new index to go out of bounds and also don't allow it to ever be the final step
        const newIndex = Math.min(activeIndex + 1, orderedAlignerEstimateSteps.length - 2);

        setActiveStep(orderedAlignerEstimateSteps[newIndex] ?? AlignerEstimateStep.CalculatingResults);
    }, [activeStep, setActiveStep]);

    // progress from one step to the next at an equal rate across the entire animation duration
    // we use the total number of steps - 1 since we don't want to hit the last step within the duration provided
    useInterval(updateEstimateProgress, ANIMATION_DURATION / (orderedAlignerEstimateSteps.length - 1 || 1));
}

export interface EstimateProgressProps {
    activeStep: AlignerEstimateStep;
    setActiveStep: (step: AlignerEstimateStep) => void;
}

export const EstimateProgress: React.FC<EstimateProgressProps> = ({ activeStep, setActiveStep }) => {
    useAutoProgress(activeStep, setActiveStep);

    return (
        <Grid container direction={'column'} alignContent={'center'}>
            {orderedAlignerEstimateSteps.map(step => {
                if (step === AlignerEstimateStep.Complete) {
                    return null;
                }
                return <EstimateProgressRow key={step} step={step} activeStep={activeStep} />;
            })}
        </Grid>
    );
};
