import React from 'react';
import { z } from 'zod';

const requiredSchema = z.object({
    required: z.string().trim().nonempty('This is a required field'),
});

export const useRequiredValidation = (required: string) => {
    const validation = React.useMemo(() => requiredSchema.safeParse({ required }), [required]);

    if (validation.success) {
        return { isFormValid: true };
    }

    const { fieldErrors } = validation.error.formErrors;

    return {
        isFormValid: false,
        requiredError: fieldErrors.required?.[0],
    };
};
