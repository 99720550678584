import { useChatAction } from '../chat/chat-state/chat.actions';
import {
    setIsCarestreamSessionFromSessionStorage,
    SESSION_STORAGE_IS_SCANNER_SESSION,
    setIsScannerSessionFromSessionStorage,
} from './ScannerStorage.util';
import { CheckoutLayoutRoot } from './components/CheckoutLayout/CheckoutLayoutRoot';
import { PRACTICE_CHECKOUT_PATHNAME } from './state/CheckoutPaths';
import { useMutation } from '@apollo/client';
import { PracticeScreen } from '@orthly/dentin';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlScannerPartnerSessionFragment } from '@orthly/graphql-operations';
import { usePartnerSessionForScannerQuery } from '@orthly/graphql-react';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import { LoadBlocker, OrthlyErrorBoundary, QueryString } from '@orthly/ui';
import { Button, Grid, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import * as Sentry from '@sentry/react';
import _ from 'lodash';
import React from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';

const ScannerSubmitErrorFallback: React.FC<{ error: Error; message?: string }> = props => {
    const openZDAction = useChatAction('OPEN_ZD_CHAT');
    const { value: chatAvailability } = useFeatureFlag('chatAvailability');

    return (
        <CheckoutLayoutRoot loading={false} onSubmit={() => {}} hideBottom>
            <Grid
                container
                justifyContent={'center'}
                style={{ height: '100vh', paddingTop: 100 }}
                alignItems={'flex-start'}
            >
                <Grid container item xs={12} sm={6}>
                    <Grid container style={{ padding: '24px 0' }} justifyContent={'center'}>
                        <Text variant={'h5'}>{props.message || "Uh oh, looks like we've run into an error"}</Text>
                    </Grid>
                    <Grid container justifyContent={'center'}>
                        <Grid container justifyContent={'space-between'} style={{ maxWidth: 400 }}>
                            <Button
                                onClick={() => {
                                    openZDAction(chatAvailability);
                                }}
                                variant={'contained'}
                            >
                                Contact Support
                            </Button>
                            {!props.message && (
                                <Button onClick={() => window.location.reload()} variant={'contained'}>
                                    Refresh Page
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    {/* renders the error props but hidden (useful if tests fail) */}
                    <Grid container style={{ whiteSpace: 'pre', visibility: 'hidden' }} justifyContent={'center'}>
                        {JSON.stringify(props, null, 2)}
                    </Grid>
                </Grid>
            </Grid>
        </CheckoutLayoutRoot>
    );
};

function useOnSelectPartnerSession() {
    const history = useHistory();
    const location = useLocation();
    const loggedInPath = React.useMemo(() => {
        let search = location.search;
        const queryParams = QueryString.parse(search);
        if (queryParams['scannerToken']) {
            Sentry.captureException(new Error('Unexpected scanner token found in page URL'));
            // remove the scanner token from the query string, maintain the other properties
            search = `?${QueryString.stringify(_.omit(QueryString.parse(location.search), 'scannerToken'))}`;
        }
        return `/${PracticeScreen.orders}/${PRACTICE_CHECKOUT_PATHNAME}${search}`;
    }, [location.search]);
    const { loadSessionFromLegacyToken } = useStaffMemberLoginProps();
    return React.useCallback(
        (partnerSession: LabsGqlScannerPartnerSessionFragment) => {
            loadSessionFromLegacyToken(partnerSession.native_id_token)
                .then(() => {
                    sessionStorage.removeItem('scannerToken');
                    setIsScannerSessionFromSessionStorage(true);
                    history.push(loggedInPath);
                })
                .catch(() => {});
        },
        [history, loggedInPath, loadSessionFromLegacyToken],
    );
}

const PracticeCarestreamPreUpload_Mutation = graphql(`
    mutation PracticeCarestreamPreUpload_Mutation($caseId: String!) {
        carestreamPreUpload(caseId: $caseId)
    }
`);

function useDoPreupload(caseId: string) {
    const preuploadMutation = useMutation(PracticeCarestreamPreUpload_Mutation);
    return React.useCallback(async () => {
        if (!caseId) {
            return;
        }
        await preuploadMutation[0]({ variables: { caseId } });
    }, [caseId, preuploadMutation]);
}

export const loadIsScannerSessionFromSessionStorage = (): boolean | undefined => {
    try {
        const string = sessionStorage.getItem(SESSION_STORAGE_IS_SCANNER_SESSION);
        if (!string) {
            return undefined;
        }
        const value = JSON.parse(string);
        return !!value;
    } catch {
        return undefined;
    }
};

export const ScannerSubmit: React.VFC = () => {
    const location = useLocation();
    const onSelectPartner = useOnSelectPartnerSession();
    const queryParams = QueryString.parse(location.search);
    const doPreupload = useDoPreupload(queryParams['caseId'] ?? '');
    const { error: exchangeTokenError } = usePartnerSessionForScannerQuery({
        variables: { staff_member_id: null },
        fetchPolicy: 'no-cache',
        onCompleted: async data => {
            const partnerSession = data?.partnerSessionForScanner;
            if (partnerSession && !queryParams['invalidProduct'] && !queryParams['missingMeshes']) {
                if (queryParams['source'] && queryParams['source'] === 'carestream') {
                    setIsCarestreamSessionFromSessionStorage(true);
                    await doPreupload();
                }
                onSelectPartner(partnerSession);
            }
        },
    });

    if (queryParams['invalidProduct']) {
        return (
            <ScannerSubmitErrorFallback
                error={new Error('Unsupported product')}
                message={"Uh oh, it looks like you're trying to order an unsupported product"}
            />
        );
    }
    if (queryParams['missingMeshes']) {
        const missingMeshSuffixes = queryParams['missingMeshes'].split(',').map(s => `_${s}.stl`);
        return (
            <ScannerSubmitErrorFallback
                error={new Error('Missing meshes')}
                message={`Uh oh, it looks like you're missing the following meshes: ${missingMeshSuffixes} , please try again.`}
            />
        );
    }
    if (exchangeTokenError) {
        if (queryParams['source'] && queryParams['source'] === 'carestream') {
            return <Redirect to={`/login`} />;
        }
        return <ScannerSubmitErrorFallback error={exchangeTokenError} />;
    }
    return (
        <OrthlyErrorBoundary componentName={'ScannerSubmit'} FallbackComponent={ScannerSubmitErrorFallback}>
            <LoadBlocker blocking={true} loader={'dandy'}>
                <Grid container style={{ height: '100vh', width: '100vw' }} />
            </LoadBlocker>
        </OrthlyErrorBoundary>
    );
};
