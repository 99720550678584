import { CheckoutLargeButton } from '../../components/LegacyCheckoutFormComponents/CheckoutLargeButton';
import { DandyMouthInterproximalSpaceSelector } from '../DandyMouth/DandyMouthInterproximalSpaceSelector';
import type { InterproximalSpaceSize } from '@orthly/items';
import { OrderItemArch } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { Text, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface AlignerSpacingSelectionProps {
    arch?: OrderItemArch | null;
    value: InterproximalSpaceSize[] | null;
    onChange: (val: InterproximalSpaceSize[] | null) => void;
    small?: boolean;
}

export const AlignerSpacingSelection: React.VFC<AlignerSpacingSelectionProps> = ({ arch, value, onChange, small }) => {
    const isVertical = useScreenIsMobileOrVerticalTablet();

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: small ? 4 : 8 }}>
                <Grid item sm={6} xs={12}>
                    <CheckoutLargeButton
                        radio
                        small={small}
                        selected={value === null}
                        onClick={() => onChange(null)}
                        label={'Close all spaces'}
                    />
                </Grid>
                <Grid item sm={6} xs={12}>
                    <CheckoutLargeButton
                        radio
                        small={small}
                        selected={!!value}
                        onClick={() => onChange([])}
                        label={'Leave spaces'}
                    />
                </Grid>
            </Grid>
            <LoadBlocker
                blocking={!value}
                loader={<React.Fragment />}
                LoaderContainerProps={{ style: { cursor: 'inherit', zIndex: 1000 } }}
                OverlayProps={{ style: { cursor: 'inherit', zIndex: 999 } }}
            >
                <Grid container spacing={2} style={{ marginTop: small ? 8 : 16 }}>
                    <Grid item xs={12}>
                        <Text variant={small ? 'body1' : 'h5'} medium={small}>
                            Select where and how much space you'd like to leave
                        </Text>
                        <Text variant={small ? 'body2' : 'body1'}>
                            Please provide us with the amount of spacing you want between teeth in millimeters (mm).
                        </Text>
                    </Grid>
                    <Grid item container style={{ marginTop: 16, marginBottom: 16 }}>
                        <Grid
                            item
                            xl={small ? 8 : 10}
                            lg={small ? 8 : 11}
                            md={12}
                            xs={12}
                            style={{ paddingBottom: isVertical ? 660 : undefined }}
                        >
                            <DandyMouthInterproximalSpaceSelector
                                verticalLayout={isVertical}
                                value={value ?? null}
                                onChange={onChange}
                                upperEnabled={arch === OrderItemArch.Dual || arch === OrderItemArch.Upper}
                                lowerEnabled={arch === OrderItemArch.Dual || arch === OrderItemArch.Lower}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </LoadBlocker>
        </>
    );
};
