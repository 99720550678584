import { TryInFeedbackNavigationButtons } from './TryInFeedbackNavigationButtons';
import { TryInFeedbackSidebar, TRY_IN_FEEDBACK_SIDEBAR_WIDTH } from './TryInFeedbackSidebar';
import { TryInFeedbackArchFeedback } from './screens/TryInFeedbackArchFeedback';
import {
    TryInFeedbackAlreadyCompletedError,
    TryInFeedbackIneligibleError,
    TryInFeedbackNotDeliveredError,
    TryInFeedbackNoTryInError,
} from './screens/TryInFeedbackError';
import { TryInFeedbackNextSteps } from './screens/TryInFeedbackNextSteps';
import { TryInFeedbackReturnInstructions } from './screens/TryInFeedbackReturnInstructions';
import { TryInFeedbackReviewAndSubmit } from './screens/TryInFeedbackReviewAndSubmit';
import { TryInFeedbackSubmissionCompleted } from './screens/TryInFeedbackSubmissionCompleted';
import { useTryInFeedbackAction } from './state/TryInFeedback.action';
import { useTryInFeedbackPropSelector } from './state/TryInFeedback.selectors';
import type { TryInFeedbackNavigation } from './state/TryInFeedback.types';
import { TryInFeedbackUtils } from './utils/TryInFeedback.util';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlLabOrderForTryInFeedbackFragment } from '@orthly/graphql-operations';
import { Assert } from '@orthly/runtime-utils';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';
import { Redirect } from 'react-router-dom';

interface TryInFeedbackScreenRouterProps {
    navigation?: TryInFeedbackNavigation;
}

const TryInFeedbackScreenRouter: React.VFC<TryInFeedbackScreenRouterProps> = ({ navigation }) => {
    // things must be loading if we're here without a navigation
    if (!navigation) {
        return null;
    }
    switch (navigation.step) {
        case 'group':
            return navigation.subStep === 'next_steps' ? (
                <TryInFeedbackNextSteps />
            ) : (
                <TryInFeedbackArchFeedback arch={navigation.subStep} />
            );
        case 'return_instructions':
            return <TryInFeedbackReturnInstructions />;
        case 'submit':
            return <TryInFeedbackReviewAndSubmit />;
    }
};

function useSetUpTryInFeedback(order: LabsGqlLabOrderForTryInFeedbackFragment): void {
    const initialize = useTryInFeedbackAction('INITIALIZE');
    const { orderId } = useTryInFeedbackPropSelector(['orderId', 'itemGroups']);

    React.useEffect(() => {
        if (order.id !== orderId && order.can_submit_tryin_feedback) {
            initialize({ order });
        }
    }, [orderId, initialize, order]);
}

interface TryInFeedbackContentProps {
    order: LabsGqlLabOrderForTryInFeedbackFragment;
}

export const TryInFeedbackContent: React.VFC<TryInFeedbackContentProps> = ({ order }) => {
    const { navigation, submissionData } = useTryInFeedbackPropSelector(['navigation', 'submissionData']);

    useSetUpTryInFeedback(order);

    const disabledReason = TryInFeedbackUtils.getTryInFeedbackDisabledReason(order);
    if (disabledReason) {
        if (disabledReason === 'use_refab') {
            return <Redirect to={`/${PracticeScreen.refab_flow}/${order.id}`} />;
        }
        if (disabledReason === 'already_completed') {
            return <TryInFeedbackAlreadyCompletedError orderId={order.id} />;
        }
        if (disabledReason === 'ineligible') {
            return <TryInFeedbackIneligibleError orderId={order.id} />;
        }
        if (disabledReason === 'no_try_in') {
            return <TryInFeedbackNoTryInError orderId={order.id} />;
        }
        if (disabledReason === 'not_delivered') {
            return <TryInFeedbackNotDeliveredError orderId={order.id} />;
        }
        Assert.unreachable(disabledReason);
    }

    if (submissionData) {
        return <TryInFeedbackSubmissionCompleted />;
    }

    return (
        <Grid container wrap={'nowrap'} style={{ height: '100%' }}>
            <Grid item style={{ position: 'fixed', height: '100%' }}>
                <TryInFeedbackSidebar />
            </Grid>
            <Grid
                item
                style={{
                    marginLeft: TRY_IN_FEEDBACK_SIDEBAR_WIDTH,
                    flexGrow: 1,
                    display: 'flex',
                }}
            >
                <TryInFeedbackScreenRouter key={JSON.stringify(navigation)} navigation={navigation} />
            </Grid>
            <TryInFeedbackNavigationButtons />
        </Grid>
    );
};
