import { PROFILE_PHOTO_COLOR_TO_HEX } from './DoctorProfilePhotoColors';
import type { LabsGqlDoctorProfilePhotoColor } from '@orthly/graphql-schema';
import { CheckIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    doctorProfilePhotoColorPicker: {
        width: 24,
        aspectRatio: '1',
        borderRadius: 12,
        color: FlossPalette.WHITE,
        cursor: 'pointer',
    },
}));

interface DoctorProfilePhotoColorPickerProps {
    color: LabsGqlDoctorProfilePhotoColor;
    onClick: (color: LabsGqlDoctorProfilePhotoColor) => void;
    selected?: boolean;
}

export const DoctorProfilePhotoColorPicker: React.VFC<DoctorProfilePhotoColorPickerProps> = props => {
    const { color, onClick, selected } = props;
    const classes = useStyles();
    return (
        <Grid
            container
            justifyContent={'center'}
            alignContent={'center'}
            className={classes.doctorProfilePhotoColorPicker}
            style={{
                backgroundColor: PROFILE_PHOTO_COLOR_TO_HEX[color],
                boxShadow: selected
                    ? `0 0 0 2px ${FlossPalette.WHITE}, 0 0 0 4px ${PROFILE_PHOTO_COLOR_TO_HEX[color]}66`
                    : undefined,
            }}
            onClick={() => onClick(color)}
        >
            {selected && <CheckIcon />}
        </Grid>
    );
};
