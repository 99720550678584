import { useCheckoutSelector } from '../../../../redux';
import { useEditNotificationsDialog } from './EditNotificationsDialog';
import {
    NotificationPreferencesSummaryTable,
    doctorHasMissingNotificationPreferences,
} from './NotificationPreferencesSummaryTable';
import type { NotificationPreferencesSectionProps } from './utils';
import { useGetDoctorPreferencesByIdQuery } from '@orthly/graphql-react';
import { WarningIcon } from '@orthly/ui';
import { FlossPalette, Dialog, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const NotificationPreferencesNotSetWarning: React.FC<{ openEditModal: () => void }> = props => {
    return (
        <Grid container style={{ flexDirection: 'column' }}>
            <Text variant={'h6'} style={{ color: FlossPalette.ATTENTION, paddingBottom: 3 }}>
                <WarningIcon
                    style={{
                        position: 'relative',
                        top: '0.28rem',
                        marginRight: '0.5rem',
                    }}
                />
                Action recommended
            </Text>
            <Text variant={'body2'} color={'DARK_GRAY'} style={{ paddingBottom: 16 }}>
                You do not have any notification preferences set up. We recommend setting these up as soon as possible,
                as this can lead to delays.
            </Text>
            <Button variant={'primary'} fullWidth={true} onClick={props.openEditModal}>
                Configure notification preferences
            </Button>
        </Grid>
    );
};

const NotificationPreferencesSectionInternal: React.FC<NotificationPreferencesSectionProps> = props => {
    const { doctor } = props;
    const hasMissingPreferences = doctorHasMissingNotificationPreferences(doctor);
    const [hideNotificationsPopup, setHideNotificationsPopup] = React.useState<boolean>(false);
    const [setEditing, editDialog] = useEditNotificationsDialog(props);

    return (
        <>
            <Grid
                container
                style={{
                    flexDirection: 'column',
                    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
                    marginBottom: 16,
                }}
            >
                <Grid
                    container
                    style={{ flexDirection: 'row', alignItems: 'baseline', justifyContent: 'space-between' }}
                >
                    <Text variant={'h5'}>Order Notifications</Text>
                    {!hasMissingPreferences && (
                        <Button variant={'ghost'} onClick={() => setEditing(true)}>
                            Edit
                        </Button>
                    )}
                </Grid>
                <Text variant={'body2'} color={'DARK_GRAY'} style={{ paddingTop: 8, paddingBottom: 16 }}>
                    Below you can find when you are notified about updates on this order
                </Text>
            </Grid>
            {hasMissingPreferences ? (
                <NotificationPreferencesNotSetWarning openEditModal={() => setEditing(true)} />
            ) : (
                <NotificationPreferencesSummaryTable doctor={doctor} />
            )}
            <Dialog
                open={hasMissingPreferences && !hideNotificationsPopup}
                onClose={() => setHideNotificationsPopup(true)}
                maxWidth={'sm'}
                fullWidth
                PaperProps={{ style: { minWidth: 663, padding: 40 } }}
            >
                <Text variant={'h4'} style={{ textAlign: 'center', marginBottom: 16 }}>
                    Personalize Your Order Updates!
                </Text>
                <Text variant={'body1'} color={'DARK_GRAY'} style={{ textAlign: 'center', marginBottom: 40 }}>
                    Let us know your order update notification preferences. This can help prevent order delays and takes
                    just a few seconds!
                </Text>
                <Button
                    variant={'primary'}
                    fullWidth
                    onClick={() => {
                        setHideNotificationsPopup(true);
                        setEditing(true);
                    }}
                    style={{ marginBottom: 8 }}
                >
                    Personalize Order Notifications
                </Button>
                <Button variant={'ghost'} fullWidth onClick={() => setHideNotificationsPopup(true)}>
                    Remind me later
                </Button>
            </Dialog>
            {editDialog}
        </>
    );
};

export const NotificationPreferencesSection: React.VFC = () => {
    const doctor_id = useCheckoutSelector(s => s.doctor?.id);
    // re-fetch doctor data so Apollo cache will invalidate it when we edit it without needing to
    // mutate manually
    const { data } = useGetDoctorPreferencesByIdQuery({
        skip: !doctor_id,
        variables: { doctor_id: doctor_id ?? '' },
    });
    if (!data) {
        return null;
    }
    return <NotificationPreferencesSectionInternal doctor={data.preferences} />;
};
