import { ScanbodyOverview } from './stages/ScanbodyOverview';
import { ScanbodyRequestDetail } from './stages/ScanbodyRequestDetail';
import React from 'react';

export const ScanbodiesContentRoot: React.FC = () => {
    return (
        <>
            <ScanbodyOverview />
            <ScanbodyRequestDetail />
        </>
    );
};
