import { useCalculatePaddingTop } from '../../utils/LayoutUtils';
import { useIsChairside } from '../../utils/chairside.hooks';
import { useRetainerTokenAutoRefresh } from '@orthly/graphql-react';
import { useRetainerToken } from '@orthly/session-client';
import { OrthlyBrowserConfig, TOOLBAR_TABLET_DOWN_HEIGHT } from '@orthly/ui';
import { useScreenIsMd } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

export const SetupRoot: React.FC = () => {
    useRetainerTokenAutoRefresh();
    const ref = React.useRef<HTMLIFrameElement | null>(null);
    const isMobile = useScreenIsMd();

    const isProduction = OrthlyBrowserConfig.isProduction;
    const url = isProduction
        ? 'static-scheduling-admin.dandyserv.net'
        : 'staging-static-scheduling-admin.dandyserv.net';
    const { retainerToken } = useRetainerToken();
    const [token, setToken] = React.useState(retainerToken);
    React.useEffect(() => {
        if (!token) {
            setToken(retainerToken);
        }
    }, [token, retainerToken, setToken]);

    React.useEffect(() => {
        if (!ref?.current) {
            return;
        }
        ref.current?.contentWindow?.postMessage(
            JSON.stringify({
                data: {
                    token: retainerToken,
                },
                event: 'retainer:refresh',
            }),
            '*',
        );
    }, [retainerToken, ref, url]);
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');
    const chairside = useIsChairside();

    return (
        <iframe
            title={'Setup'}
            ref={ref}
            src={`https://${url}?retainerToken=${token}`}
            frameBorder={'0'}
            allowFullScreen
            style={{
                position: 'absolute',
                paddingTop:
                    useCalculatePaddingTop({
                        chairside,
                        maintenanceBannerPractice: !!maintenanceBannerPractice?.length,
                    }) + (isMobile ? TOOLBAR_TABLET_DOWN_HEIGHT : 0),
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
            }}
        />
    );
};
