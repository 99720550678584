import { useAddNewScans } from '../useAddNewScans';
import { AttachScansUtils } from './utils/AttachScans.util';
import { useSelectScanAction } from './utils/Reloader.util';
import { MultiProductImageWrapper } from '@orthly/dentin';
import type { ScanEntry } from '@orthly/forceps';
import type { LabsGqlLabOrderFragment, LabsGqlPatientScanFileFragment } from '@orthly/graphql-operations';
import { useScanFilesForPatientQuery } from '@orthly/graphql-react';
import { Icon } from '@orthly/ui';
import { FlossPalette, styled, useScreenIsMobile, RadioPrimitive as Radio, Button, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

const SCAN_FILE_LAYOUT_WIDTH = 696;

const Layout = styled('div')(({ isMobile }: { isMobile: boolean }) => ({
    display: 'grid',
    marginTop: isMobile ? 0 : 32,
    gap: isMobile ? 16 : 8,
}));

const NoScansLayout = styled('div')({
    width: '100%',
    padding: 16,
    background: FlossPalette.TAN,
    borderRadius: 16,
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    display: 'flex',
    justifyContent: 'center',
});

const ScanFileLayout = styled('div')({
    width: '100%',
    maxWidth: SCAN_FILE_LAYOUT_WIDTH,
    padding: 16,
    background: FlossPalette.TAN,
    borderRadius: 16,
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    position: 'relative',
    '&:hover': {
        border: `1px solid ${FlossPalette.STROKE_DARK}`,
        cursor: 'pointer',
    },
});

const OutdatedScanLabelLayout = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const ScanFileTitleLayout = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: 'auto',
    marginBottom: 4,
});

const ScanFileProductsLayout = styled('div')({
    width: 'auto',
    marginRight: 8,
});

const PromptLayout = styled('div')({
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
});

const ScanFileSubtitle = styled('div')({
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
});

interface AttachScansItemProps {
    order: LabsGqlLabOrderFragment;
    scan: LabsGqlPatientScanFileFragment;
    setScans: (scans: ScanEntry[]) => void;
    setLoading: (loading: boolean) => void;
    setSelectedScan: (scan: LabsGqlPatientScanFileFragment) => void;
    onSelectScanAction?: () => void;
}

export const AttachScansItem: React.VFC<AttachScansItemProps> = props => {
    const { order, scan, setScans, setLoading, setSelectedScan, onSelectScanAction } = props;

    const selectScanAction = useSelectScanAction(order, setSelectedScan, setLoading, setScans, onSelectScanAction);
    const scanIsOutdated = dayjs(scan.created_at).isBefore(dayjs().subtract(45, 'days'));

    return (
        <ScanFileLayout key={scan.id} onClick={() => selectScanAction(scan)}>
            <ScanFileTitleLayout>
                <Radio color={'secondary'} style={{ margin: '0 8px 0 0' }} onClick={() => selectScanAction(scan)} />
                <ScanFileProductsLayout>
                    <MultiProductImageWrapper
                        products={AttachScansUtils.getScanSKUs(scan)}
                        direction={'row'}
                        maxNumOrbs={3}
                        backgroundColor={FlossPalette.WHITE}
                        size={24}
                    />
                </ScanFileProductsLayout>
                <Text variant={'body2'} medium>
                    {AttachScansUtils.getScanTitle(scan)}
                </Text>
            </ScanFileTitleLayout>
            <ScanFileSubtitle>
                <Text variant={'caption'} color={'GRAY'}>
                    Created {AttachScansUtils.formatScanDate(scan.created_at)}
                </Text>
                {scanIsOutdated && (
                    <OutdatedScanLabelLayout>
                        <Icon
                            icon={'ClockAlertIcon'}
                            style={{ color: FlossPalette.SECONDARY_FOREGROUND, marginRight: 4 }}
                        />
                        <Text variant={'caption'} color={'SECONDARY_FOREGROUND'}>
                            These scans are more than 45 days old, we recommend you use a newer scan
                        </Text>
                    </OutdatedScanLabelLayout>
                )}
            </ScanFileSubtitle>
        </ScanFileLayout>
    );
};

interface AttachScansActionProps {
    order: LabsGqlLabOrderFragment;
    setScans: (scans: ScanEntry[]) => void;
    setLoading: (loading: boolean) => void;
    setSelectedScan: (scan: LabsGqlPatientScanFileFragment) => void;
    onSelectScanAction?: () => void;
}

export const AttachScansAction: React.VFC<AttachScansActionProps> = props => {
    const { order, setScans, setLoading, setSelectedScan, onSelectScanAction } = props;
    const isMobile = useScreenIsMobile();

    const { data: scanFilesData, loading: scanFilesLoading } = useScanFilesForPatientQuery({
        variables: { patient_id: order.patient.id },
    });
    const scans = scanFilesData?.scan_files_for_patient ?? [];
    const displayScans = scans.filter(scan => !!scan.file_url);

    React.useEffect(() => {
        setLoading(scanFilesLoading);
    }, [setLoading, scanFilesLoading]);

    const { invokeAttachScans, canAddNewScans } = useAddNewScans(order);

    return (
        <Layout isMobile={isMobile}>
            {!scanFilesLoading && displayScans.length === 0 && (
                <NoScansLayout>
                    <Text variant={'body2'} color={'GRAY'}>
                        You do not currently have any Chairside scans to select from
                    </Text>
                </NoScansLayout>
            )}

            {displayScans.map(scan => (
                <AttachScansItem
                    key={scan.id}
                    scan={scan}
                    order={order}
                    onSelectScanAction={onSelectScanAction}
                    setScans={setScans}
                    setLoading={setLoading}
                    setSelectedScan={setSelectedScan}
                />
            ))}

            {canAddNewScans && (
                <PromptLayout>
                    <Text variant={'body2'} medium color={'GRAY'}>
                        Select a scan from above or
                    </Text>

                    <Button
                        variant={'ghost'}
                        endIcon={'ScanIcon'}
                        style={{ padding: '0px 8px 0px 4px' }}
                        onClick={() => invokeAttachScans({ id: order.id })}
                    >
                        Capture new scan
                    </Button>
                </PromptLayout>
            )}
        </Layout>
    );
};
