import { OrderAction, OrderActionTrackerIdMap } from '../OrderActionsUtils';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { Theme } from '@orthly/ui-primitives';
import { makeStyles, createStyles, Button } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        feedbackButton: {
            padding: '0 4px',
            [theme.breakpoints.down('sm')]: {
                height: 'unset',
                marginTop: 12,
            },
        },
    }),
);

export const ProvideProductFeedbackAction: React.FC<{
    order: LabsGqlOrder;
    hideButtonIcon?: boolean;
    buttonStyle?: React.CSSProperties;
    analyticsLocation: 'tracking' | 'quick order actions';
}> = props => {
    const { order, buttonStyle, analyticsLocation } = props;

    const classes = useStyles();
    const history = useHistory();

    const navigateToProductFeedbackScreen = () => {
        BrowserAnalyticsClientFactory.Instance?.track('Practice - Feedback - Opened', {
            $groups: { order: order.id },
            location: `order details - ${analyticsLocation}`,
        });
        history.push(`/${PracticeScreen.feedback}/${order.id}`);
    };

    if (!order.can_submit_feedback) {
        return null;
    }

    return (
        <Button
            variant={'ghost'}
            onClick={navigateToProductFeedbackScreen}
            className={classes.feedbackButton}
            style={{ ...buttonStyle }}
            endIcon={props.hideButtonIcon ? undefined : 'ChevronRight'}
            data-test={'provide-product-feedback-from-order'}
            data-tracker-id={OrderActionTrackerIdMap[OrderAction.ProvideProductFeedback]}
        >
            {'Provide product feedback'}
        </Button>
    );
};
