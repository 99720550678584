import { usePortalToChairsideBridgeCommand } from '../../../../utils/chairside.hooks';
import type { LabsGqlLabOrderForTryInFeedbackFragment } from '@orthly/graphql-operations';
import type { LabsGqlLabOrderWithEvents } from '@orthly/graphql-schema';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { DenturesProductionType, LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { ActionCardIcon } from '@orthly/ui';
import {
    stylesFactory,
    FlossPalette,
    Grid,
    Link,
    HelpOutlineIcon,
    ReportProblemOutlinedIcon,
    Button,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    banner: {
        borderRadius: 16,
        padding: 24,
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
    },
    numberedBullet: {
        backgroundColor: FlossPalette.ATTENTION_FOREGROUND,
        display: 'inline-block',
        textAlign: 'center',
        width: 26,
        height: 26,
        marginRight: 16,
        borderRadius: '50%',
    },
    link: {
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
}));

interface DenturesWaxRimDeliveredAlertProps {
    orderId: string;
}

const DenturesWaxRimDeliveredAlert: React.VFC<DenturesWaxRimDeliveredAlertProps> = ({ orderId }) => {
    const classes = useStyles();
    const startChairsideCase = usePortalToChairsideBridgeCommand('startDenturesWaxRimContinuation');

    const bullets: string[] = [
        `Adjust and guide the wax bite rim into your patient's mouth to register their bite and mark mid-lines, canine lines, and smile lines with the wax rim in the patient's mouth.`,
        `Add a light body wash to the wax rim and perform a 360 extraoral scan of it`,
    ];

    return (
        <Grid container className={classes.banner} wrap={'nowrap'}>
            <Grid item>
                <ActionCardIcon variant={'alert'} IconComponent={ReportProblemOutlinedIcon} />
            </Grid>
            <Grid item style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                <Text variant={'body1'} medium>
                    Scans are required to continue this denture order
                </Text>
                {!startChairsideCase && (
                    <>
                        {bullets.map((bulletText, index) => (
                            <Grid
                                container
                                key={index}
                                wrap={'nowrap'}
                                alignItems={'flex-start'}
                                style={{ marginTop: 16 }}
                            >
                                <Grid item>
                                    <Text variant={'body2'} color={'WHITE'} medium className={classes.numberedBullet}>
                                        {index + 1}
                                    </Text>
                                </Grid>
                                <Grid item>
                                    <Text variant={'body2'} color={'DARK_GRAY'} style={{ maxWidth: 650 }}>
                                        {bulletText}
                                    </Text>
                                </Grid>
                            </Grid>
                        ))}
                        <Text variant={'body2'} medium style={{ marginTop: 16 }}>
                            <Link
                                className={classes.link}
                                href={'https://dandy.dental/bite-rim-appointment-2'}
                                target={'_blank'}
                            >
                                <HelpOutlineIcon style={{ marginBottom: -6, marginRight: 18 }} />
                                Learn more in our Help Center
                            </Link>
                        </Text>
                    </>
                )}
            </Grid>
            {!!startChairsideCase && (
                <Grid item style={{ flexGrow: 1, display: 'flex' }}>
                    <Button
                        variant={'primary'}
                        onClick={() => startChairsideCase({ order_id: orderId })}
                        endIcon={'ChevronRight'}
                        style={{ margin: 'auto 0 auto auto' }}
                        analytics={{
                            AssetName: 'Order Details - Start Wax Rim Continuation',
                            AssetCTAText: 'Start Scanning',
                        }}
                    >
                        Start Scanning
                    </Button>
                </Grid>
            )}
        </Grid>
    );
};

interface OrderDetailDenturesWaxRimDeliveredAlertProps {
    order: LabsGqlLabOrderForTryInFeedbackFragment & Pick<LabsGqlLabOrderWithEvents, 'next_fulfillment_order_ids'>;
}

export const OrderDetailDenturesWaxRimDeliveredAlert: React.VFC<OrderDetailDenturesWaxRimDeliveredAlertProps> = ({
    order,
}) => {
    // do not show if not delivered, order has been refabbed, or order has been continued
    if (
        order.status !== LabsGqlLabOrderStatus.Delivered ||
        order.refabrication_order_id ||
        order.next_fulfillment_order_ids?.length
    ) {
        return null;
    }
    const waxRimItems = OrderItemV2Utils.parseItems(order.items_v2).filter(
        item =>
            OrderItemV2Utils.itemIsType(item, LabOrderItemSKUType.Denture) &&
            item.denture_production_type === DenturesProductionType.WaxRim,
    );
    if (!waxRimItems.length) {
        return null;
    }
    // if we can use the try in feedback flow then we use that instead of wax rim continuation
    // if we've already submitted try in feedback then we know that we're not using the continuation flow either
    const usesTryInFeedback = order.can_submit_tryin_feedback || !!order.try_in_feedback;
    if (usesTryInFeedback) {
        return null;
    }
    return <DenturesWaxRimDeliveredAlert orderId={order.id} />;
};
