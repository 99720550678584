import { AnalyticsClient } from '../../../../analytics/analyticsClient';
import { usePortalToChairsideBridgeCommand } from '../../../../utils/chairside.hooks';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import { useFeatureFlag } from '@orthly/veneer';
import { useHistory } from 'react-router-dom';

/**
 * This hook will return a `triggerRefabAction` property, which when called will send the user to the expected Refab flow.
 * If a user is browsing the practice portal inside Chairside, and if the FF is enabled, we expect to send the user to
 * the Chairside refab flow, which is a better UX as it lets the doctor re-scan the patient easily.
 *
 * If the user is not in Chairside (eg: on web or phone) OR if the FF is disabled, they will continue to use the regular practice portal refab flow
 */
export const useRefabAction = (orderId: string) => {
    const history = useHistory();
    const practicePortalRefabUrl = `/${PracticeScreen.refab_flow}/${orderId}`;

    const { value: isRefabFromPortalToChairsideEnabled } = useFeatureFlag('isRefabFromPortalToChairsideEnabledMay2024');
    const startRefabInChairside = usePortalToChairsideBridgeCommand('startRefabrication');

    // startRefabInChairside will be falsy if we are not embedded in Chairside, OR if we are using an old version of Chairside that does not include this function
    const shouldUseChairside = startRefabInChairside && isRefabFromPortalToChairsideEnabled;

    return {
        triggerRefabAction: (
            analyticsLocation: DandyAnalyticsEventSchemaType['Practice - Order - Refabricate Order Opened']['location'],
        ) => {
            AnalyticsClient.track('Practice - Order - Refabricate Order Opened', {
                $groups: { order: orderId },
                location: analyticsLocation,
                isSentToChairside: shouldUseChairside,
            });

            if (shouldUseChairside) {
                startRefabInChairside({ order_id: orderId });
            } else {
                history.push(practicePortalRefabUrl);
            }
        },
    };
};
