import { FlossPalette, CircularProgress, Icon, Zoom } from '@orthly/ui-primitives';
import React from 'react';

export type StarRatingType = 0 | 1 | 2 | 3 | 4 | 5;
export const StarRatingInput: React.VFC<{
    onChange?: (newRating: StarRatingType) => void;
    rating: StarRatingType;
    loading?: boolean;
    readonly?: boolean;
    disabled?: boolean;
}> = ({ onChange, rating, loading, readonly, disabled }) => {
    const options = [1, 2, 3, 4, 5] as const;
    const [liveRating, setLiveRating] = React.useState(rating);
    const [animate, setAnimate] = React.useState(false);
    // eslint-disable-next-line no-nested-ternary
    const color = FlossPalette[disabled ? 'LIGHT_GRAY' : !!liveRating ? 'WARNING' : 'LIGHT_GRAY'];
    const [hoverTo, setHoverTo] = React.useState(0);
    return (
        <span>
            {loading && <CircularProgress size={20} />}{' '}
            {options.map(optValue => {
                const filled = optValue <= (liveRating ?? 0);

                const isHoveredTo = !disabled && !readonly && !liveRating && hoverTo >= optValue;
                const animationDelay = 100 + 80 * optValue;
                const content = (
                    <Icon
                        icon={filled ? 'Star' : 'StarBorder'}
                        onMouseOver={() => setHoverTo(optValue)}
                        onMouseOut={() => setHoverTo(0)}
                        style={{
                            color: isHoveredTo ? FlossPalette.WARNING : color,
                            cursor: disabled || readonly ? 'not-allowed' : 'pointer',
                            fontSize: `1.8rem`,
                            marginBottom: `-0.5rem`,
                        }}
                        data-test={`feedback-star-rating-${optValue}`}
                        onClick={ev => {
                            ev.stopPropagation();
                            if (disabled || readonly) {
                                return;
                            }
                            setLiveRating(optValue);
                            setAnimate(true);
                            setTimeout(() => {
                                setAnimate(false);
                                onChange?.(optValue);
                            }, animationDelay);
                        }}
                    />
                );
                return animate && filled ? (
                    <Zoom key={optValue} in={true} timeout={animationDelay} style={{ display: 'inline' }}>
                        {content}
                    </Zoom>
                ) : (
                    <React.Fragment>{content}</React.Fragment>
                );
            })}
        </span>
    );
};
