import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { ATTACH_SURGICAL_REPORT_PATH } from '../../scanbodies/ScanbodiesPaths';
import { useSelectStaffPropSelector } from '../../select-staff/state/select-staff.selectors';
import { useImplantReadinessAction } from '../state/ImplantReadiness.actions';
import { useImplantReadinessPropSelector } from '../state/ImplantReadiness.selectors';
import type { Stage } from './Stage';
import { PracticeScreen } from '@orthly/dentin';
import {
    usePartnerNotificationPrefsQuery,
    useSendScanbodySurgicalReportUploadLinkMutation,
} from '@orthly/graphql-react';
import { CartItemV2Utils } from '@orthly/items';
import { useSession } from '@orthly/session-client';
import { TrashIcon, useChangeSubmissionFn } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Button, Text } from '@orthly/ui-primitives';
import type { FileUploadAnalyticsMetadata, UploadedFile } from '@orthly/veneer';
import {
    FileListPreview,
    FileUpload,
    useGetAttachmentsPath,
    useImpersonatedQrCodeLink,
    useUploadFiles,
} from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        backgroundColor: FlossPalette.TAN,
        borderColor: FlossPalette.DARK_TAN,
        borderStyle: 'solid',
        borderRadius: 8,
        borderWidth: 2,
        overflow: 'hidden',
        padding: 24,
        flexDirection: 'column',
        width: 320,
    },
}));

function useMobileURL(itemId: string) {
    const { items } = useImplantReadinessPropSelector(['items']);
    const activeItem = items.find(i => i.id === itemId);
    const implantMetadata = (activeItem && CartItemV2Utils.getImplantMetadata(activeItem)) ?? {};
    const mobileURL = React.useMemo(() => {
        const mobileURL = new URL(
            `/${PracticeScreen.scanbody_inventory}/${ATTACH_SURGICAL_REPORT_PATH}/${itemId}`,
            window.location.origin,
        );
        if (implantMetadata.manufacturer) {
            mobileURL.searchParams.set('manufacturer', implantMetadata.manufacturer);
        }
        if (implantMetadata.system) {
            mobileURL.searchParams.set('system', implantMetadata.system);
        }
        if (implantMetadata.connection_size) {
            mobileURL.searchParams.set('connection', implantMetadata.connection_size);
        }
        return mobileURL.toString();
    }, [itemId, implantMetadata.manufacturer, implantMetadata.system, implantMetadata.connection_size]);
    return mobileURL;
}

function useTextUploadLink(uploadLink: string) {
    const session = useSession();
    const practiceId = session?.organization_id ?? '';

    // autofill phoneNumber
    const [placeholderPhoneNumber, setPlaceholderPhoneNumber] = React.useState<string | undefined>();
    usePartnerNotificationPrefsQuery({
        onCompleted: ({ getPartnerPreferences }) =>
            setPlaceholderPhoneNumber(getPartnerPreferences?.phone_number ?? undefined),
    });

    const { profile } = useSelectStaffPropSelector(['profile']);

    const [sendTextMtn] = useSendScanbodySurgicalReportUploadLinkMutation();
    const textSubmitter = async (phoneNumber: string) => {
        if (!phoneNumber) {
            // Button.disabled={!isNumber} so we should never need this
            throw new Error(`Please enter a phone number`);
        }
        if (!practiceId) {
            // if !practiceId no parent will render this component so we should never need this
            throw new Error(`Practice info was not found`);
        }
        await sendTextMtn({
            variables: {
                data: {
                    upload_link: uploadLink,
                    doctor_name: profile?.name,
                    practice_id: practiceId,
                    phone_number: phoneNumber,
                },
            },
        });
    };
    const { submit: sendText, submitting } = useChangeSubmissionFn<void, [string]>(textSubmitter, {
        successMessage: () => ['Check your phone for the upload link!', {}],
    });

    return {
        sendText,
        submitting,
        placeholderPhoneNumber,
    };
}

function sendAnalytics(implantReadinessId: string, uploaded: boolean) {
    AnalyticsClient.track('Practice - Implant Readiness - Upload Stage Completed', {
        implantReadinessId,
        uploaded,
    });
}

const Upload: React.VFC = () => {
    const classes = useStyles();
    const { activeItemId, implantReadinessId } = useImplantReadinessPropSelector([
        'activeItemId',
        'implantReadinessId',
    ]);
    const setSurgicalReport = useImplantReadinessAction('SET_SURGICAL_REPORT');
    const mobileURL = useMobileURL(activeItemId);
    const qrCode = useImpersonatedQrCodeLink(mobileURL);
    const textUploadLink = useTextUploadLink(mobileURL);
    const storagePathConfig = useGetAttachmentsPath(activeItemId);
    const { uploadFiles, deleteFile, useListFiles } = useUploadFiles({
        caseId: activeItemId,
        storagePathConfig,
        analyticsEventName: 'Practice - Checkout - File Uploaded',
    });

    const [files, setFiles] = React.useState<UploadedFile[]>([]);
    const analyticsMetadata: FileUploadAnalyticsMetadata = {
        productName: 'scanbody ordering surgical report',
        device: 'web',
    };

    async function onDropAccepted(filesToUpload: File[]) {
        await uploadFiles(filesToUpload, analyticsMetadata, newFiles => {
            if (files.length > 0) {
                files.forEach(file => onRemoveFile(file.uploadName));
            }
            setFiles(newFiles);
        });
    }

    async function onRemoveFile(filename: string) {
        await deleteFile(filename);
        setFiles(files => files.filter(f => f.uploadName !== filename));
    }

    const uploadedFiles = useListFiles();
    React.useEffect(() => {
        setFiles(files => {
            const deletedFiles = files.filter(f => f.filepath && !uploadedFiles.some(uf => uf.filepath === f.filepath));
            return uploadedFiles
                .concat(files.filter(file => !uploadedFiles.some(f => f.uploadName === file.uploadName)))
                .filter(uf => !deletedFiles.some(df => df.filepath === uf.filepath));
        });
    }, [uploadedFiles, setFiles]);
    const file = files.length > 0 && files[0];
    const thumbnailSize = 320 - 48 - 4; // containerWidth - containerPadding - containerBorder

    return (
        <>
            <Grid container wrap={'nowrap'}>
                <Grid container className={classes.root} alignItems={'center'}>
                    {!file ? (
                        <FileUpload
                            title={'Upload a surgical report here'}
                            onDropAccepted={onDropAccepted}
                            qrCode={qrCode}
                            textUploadLink={textUploadLink}
                            allowMultipleFiles={false}
                        />
                    ) : (
                        <>
                            <Grid container justifyContent={'space-between'} style={{ marginBottom: 16 }}>
                                <Text variant={'body2'} bold>
                                    Your file
                                </Text>
                                <TrashIcon
                                    style={{ cursor: 'pointer', color: FlossPalette.ATTENTION_FOREGROUND }}
                                    onClick={() => onRemoveFile(file.uploadName)}
                                />
                            </Grid>
                            <FileListPreview
                                fileUpload={file}
                                onRemove={onRemoveFile}
                                maxThumbnailSize={thumbnailSize}
                            />
                        </>
                    )}
                </Grid>
            </Grid>
            <Button
                endIcon={'ChevronRight'}
                variant={file ? 'primary' : 'secondary'}
                onClick={() => {
                    setSurgicalReport(files[0]?.filepath ?? '');
                    sendAnalytics(implantReadinessId, file ? true : false);
                }}
                style={{ width: 240, marginTop: 24 }}
            >
                {file ? 'Next' : 'Skip'}
            </Button>
        </>
    );
};

export const UploadStage: Stage = {
    path: `/${PracticeScreen.scanbodies}/upload`,
    component: Upload,
    pageTitle: ({ name }) => ({
        title: `Preparing for ${name}'s implant case`,
        header: 'Recommended: Upload the surgical report',
        subtitle: 'Our clinical team will confirm manufacturer, system, and platform size.',
    }),
};
