import type {
    LabsGqlDenturesSingleFulfillmentFragment,
    LabsGqlDenturesFulfillmentFixupFragment,
    LabsGqlDenturesFulfillmentWaxRimFragment,
    LabsGqlDenturesFulfillmentCoreFragment,
    LabsGqlDenturesFulfillmentTryInFollowupFragment,
} from '@orthly/graphql-operations';
import type { LabsGqlDenturesSingleFulfillmentType } from '@orthly/graphql-schema';

type withType<TIndiscriminate, TType> = Omit<TIndiscriminate, 'type'> & { type: TType };

type DenturesSingleFulfillmentNarrowable =
    | withType<LabsGqlDenturesFulfillmentFixupFragment, LabsGqlDenturesSingleFulfillmentType.Fixup>
    | withType<LabsGqlDenturesFulfillmentWaxRimFragment, LabsGqlDenturesSingleFulfillmentType.WaxRim>
    | withType<LabsGqlDenturesFulfillmentCoreFragment, LabsGqlDenturesSingleFulfillmentType.Core>
    | withType<LabsGqlDenturesFulfillmentTryInFollowupFragment, LabsGqlDenturesSingleFulfillmentType.TryInFollowup>;

export function isDenturesFulfillmentType<TType extends LabsGqlDenturesSingleFulfillmentType>(
    dsf: { type?: LabsGqlDenturesSingleFulfillmentType },
    type: TType,
): dsf is DenturesSingleFulfillmentNarrowable & { type: TType } {
    return dsf.type === type;
}

export const getDenturesSingleFulfillmentDisplayName = (
    singleFulfillment: LabsGqlDenturesSingleFulfillmentFragment,
): string => {
    switch (singleFulfillment.__typename) {
        case 'DenturesFulfillmentCoreDTO':
            if (singleFulfillment.is_try_in) {
                return `Monolithic Try In (Full Dentures)`;
            }
            return singleFulfillment.implant_support
                ? `Full Overdentures (Process to final)`
                : `Full Dentures (Process to final)`;
        case 'DenturesFulfillmentWaxRimDTO':
            return singleFulfillment.implant_support
                ? `Wax Bite Rim (Full Overdentures)`
                : `Wax Bite Rim (Full Dentures)`;
        case 'DenturesFulfillmentFixupDTO':
            return singleFulfillment.is_try_in
                ? 'Fix Up - Monolithic Try In (Full Dentures)'
                : 'Fix Up - Full Dentures (Process to final)';
        case 'DenturesFulfillmentTryInFollowupDTO':
            return singleFulfillment.is_try_in
                ? 'Followup - Monolithic Try In (Full Dentures)'
                : 'Followup - Full Dentures (Process to final)';
    }
};
