import { PracticeScreen } from '@orthly/dentin';
import { HomeIcon, MoreIcon, OrderPaperIcon, SearchIcon, SidebarChatIcon } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    useScreenIsMobile,
    createStyles,
    makeStyles,
    BottomNavigation,
    BottomNavigationAction,
} from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const TAB_BAR_HEIGHT = 80;

const buttonFontSize = 12;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            minWidth: 'auto !important',
            maxWidth: 'unset',
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            paddingTop: '8px',
            '& .MuiBottomNavigationAction-label': {
                fontSize: buttonFontSize,
            },
        },
        root: {
            position: 'absolute',
            alignItems: 'flex-start',
            height: TAB_BAR_HEIGHT,
            bottom: 0,
            backgroundColor: FlossPalette.TAN,
            justifyContent: 'space-between',
            width: '100%',
            zIndex: theme.zIndex.appBar,
            '@media print': {
                display: 'none',
            },
        },
        selectedButton: {
            color: `${FlossPalette.STAR_GRASS} !important`,
            minWidth: 'auto !important',
            maxWidth: 'unset',
            borderTop: `1px solid ${FlossPalette.STAR_GRASS}`,
            paddingTop: '8px',
            '& .MuiBottomNavigationAction-label': {
                fontSize: buttonFontSize,
            },
        },
    }),
);

export const MobileTabs: React.FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const isMobile = useScreenIsMobile();

    const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
        history.push(newValue);
    };

    const isActive = (value: string) => history.location.pathname.includes(value);
    const buttonClass = (value: string) => (isActive(value) ? classes.selectedButton : classes.button);

    return (
        <BottomNavigation
            data-test={'mobile-tabs'}
            showLabels
            value={history.location.pathname}
            onChange={handleChange}
            className={classes.root}
        >
            <BottomNavigationAction
                data-test={'mobile-tab-home'}
                className={buttonClass(`/${PracticeScreen.inbox}`)}
                label={'Home'}
                value={`/${PracticeScreen.inbox}`}
                icon={<HomeIcon style={{ height: 22 }} />}
            />
            <BottomNavigationAction
                data-test={'mobile-tab-orders'}
                className={buttonClass(`/${PracticeScreen.orders}`)}
                label={'Orders'}
                value={`/${PracticeScreen.orders}`}
                icon={<OrderPaperIcon style={{ height: 22 }} />}
            />
            {isMobile && (
                <BottomNavigationAction
                    data-test={'mobile-tab-search'}
                    className={buttonClass(`/${PracticeScreen.search}`)}
                    label={'Search'}
                    value={`/${PracticeScreen.search}`}
                    icon={<SearchIcon style={{ height: 22 }} />}
                />
            )}
            {isMobile && (
                <BottomNavigationAction
                    data-test={'mobile-tab-support'}
                    className={buttonClass(`/${PracticeScreen.chat}`)}
                    label={'Chat'}
                    value={`/${PracticeScreen.chat}`}
                    icon={<SidebarChatIcon style={{ height: 22 }} />}
                />
            )}
            <BottomNavigationAction
                data-test={'mobile-tab-more'}
                className={buttonClass(`/${PracticeScreen.more}`)}
                label={'More'}
                value={`/${PracticeScreen.more}`}
                icon={<MoreIcon style={{ height: 22 }} />}
            />
        </BottomNavigation>
    );
};
