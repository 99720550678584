import { useStyles } from './css.util';
import { Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';

const relativeFormat = (date: Date) => {
    if (dayjs().isSame(date, 'day')) {
        return 'Today';
    }

    if (dayjs().subtract(1, 'day').isSame(date, 'day')) {
        return 'Yesterday';
    }

    if (dayjs().subtract(6, 'months').isBefore(date, 'day')) {
        return date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric' });
    }

    return date.toLocaleDateString(undefined, { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
};

export const OrderTimelineDateHeader: React.FC<{ date: Date }> = ({ date }) => {
    const classes = useStyles();

    return (
        <Text className={classes.header} color={'WHITE'} variant={'caption'} medium>
            {relativeFormat(date)}
        </Text>
    );
};
