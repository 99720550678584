import type { CalculateOnboardingStateArgs } from './onboarding.actions';
import { OnboardingActions } from './onboarding.actions';
import { handleActions } from 'redux-actions';

export const ONBOARDING_SPLASH = 'Splash';
export const ONBOARDING_DOCTOR_PREFERENCES = 'Doctor Preferences';
export const ONBOARDING_DELIVERY_ADDRESS = 'Delivery Address';
export const ONBOARDING_NOTIFICATION_SETTINGS = 'Notification Settings';
export const ONBOARDING_PAYMENT = 'Payment';
export const ONBOARDING_COMPLETE = 'Complete';

export const ONBOARDING_STAGES = [
    ONBOARDING_SPLASH,
    ONBOARDING_DOCTOR_PREFERENCES,
    ONBOARDING_DELIVERY_ADDRESS,
    ONBOARDING_NOTIFICATION_SETTINGS,
    ONBOARDING_COMPLETE,
    ONBOARDING_PAYMENT,
] as const;

export type ONBOARDING_STAGE = (typeof ONBOARDING_STAGES)[number];

export interface OnboardingState {
    currentStage: ONBOARDING_STAGE;
    loading: boolean;
    isComplete: boolean;
    initial: boolean;
}

const initialState: OnboardingState = {
    currentStage: ONBOARDING_SPLASH,
    loading: true,
    isComplete: false,
    initial: true,
};

const initializeState = (args: CalculateOnboardingStateArgs): OnboardingState => {
    const { addresses, addressesLoading, preferences, preferencesLoading, notifications, notificationsLoading } = args;

    // In the future we could determine separately if a user has never seen onboarding before vs just doesn't
    // have any preferences set. But showing them the onboarding popover each time if they have no prefs set isn't a bad thing
    // For onboarding purposes, preferences are considered "complete" for a practice if there exists at least one custom field preference set for at least one doctor
    const preferencesComplete =
        preferences.find(pref => pref.custom_field_preferences && pref.custom_field_preferences.length > 0) !==
        undefined;

    const loading = addressesLoading || preferencesLoading || notificationsLoading;
    if (loading) {
        return initialState;
    }
    if (!preferencesComplete) {
        return { ...initialState, loading, initial: false };
    }
    if (addresses.filter(addr => addr.deleted_at === null).length === 0) {
        return { ...initialState, loading, initial: false, currentStage: ONBOARDING_DELIVERY_ADDRESS };
    }
    if (!notifications) {
        return { ...initialState, loading, initial: false, currentStage: ONBOARDING_NOTIFICATION_SETTINGS };
    }
    return { ...initialState, loading, initial: false, currentStage: ONBOARDING_COMPLETE };
};

const stateWithIsComplete = (state: OnboardingState) => ({
    ...state,
    isComplete: [ONBOARDING_COMPLETE, ONBOARDING_PAYMENT].includes(state.currentStage),
});

export const onboardingReducer = handleActions<OnboardingState, any>(
    {
        ...OnboardingActions.INITIALIZE_STATE.reducer<OnboardingState>((state, action) =>
            stateWithIsComplete(state.initial ? initializeState(action.payload) : state),
        ),
        ...OnboardingActions.BACK.reducer<OnboardingState>(state => {
            const currentIndex = ONBOARDING_STAGES.indexOf(state.currentStage);
            const previousStage = currentIndex > 0 ? ONBOARDING_STAGES[currentIndex - 1] : undefined;
            return stateWithIsComplete({ ...state, currentStage: previousStage ?? state.currentStage });
        }),
        ...OnboardingActions.NEXT.reducer<OnboardingState>(state => {
            const currentIndex = ONBOARDING_STAGES.indexOf(state.currentStage);
            const nextStage = ONBOARDING_STAGES[currentIndex + 1] ?? undefined;
            return stateWithIsComplete({ ...state, currentStage: nextStage ?? state.currentStage });
        }),
    },
    initialState,
);
