import { usePricingGuide } from '../../PricingGuide.context';
import type { KnownPrice } from '../../PricingGuide.types';
import { PricingGuideTable } from '../../table/PricingGuideTable';
import React from 'react';

export const ImplantSolutionsMaterialsTable: React.FC = () => {
    const { getPrice, formatPrice } = usePricingGuide();

    const implantSolutionMaterials = React.useMemo(() => {
        const titaniumAbutmentPrice = getPrice('Titanium Abutment') ?? 0;
        const zirconiaAbutmentPrice = getPrice('Zirconia Abutment') ?? 0;
        const baseAbutmentPrice = getPrice('Screw-Retained TI Base') ?? 0;
        const customAbutmentPrice = Math.max(titaniumAbutmentPrice, zirconiaAbutmentPrice);

        const getBasePrice = (name: KnownPrice) => {
            const materialPrice = getPrice(name);

            if (materialPrice && baseAbutmentPrice) {
                return materialPrice + baseAbutmentPrice;
            }
        };

        const getCustomPrice = (name: KnownPrice) => {
            const materialPrice = getPrice(name);

            if (materialPrice && customAbutmentPrice) {
                return materialPrice + customAbutmentPrice;
            }
        };

        return [
            {
                id: 'fullContourZirconiaPrice',
                material: 'Full Contour Zirconia',
                customPrice: getCustomPrice('Full Contour Zirconia'),
                basePrice: getBasePrice('Full Contour Zirconia'),
                isGold: false,
            },
            {
                id: 'zirconiaTranslucentPrice',
                material: 'Zirconia Translucent Aesthetic',
                customPrice: getCustomPrice('Zirconia Transluscent Aesthetic'),
                basePrice: getBasePrice('Zirconia Transluscent Aesthetic'),
                isGold: false,
            },
            {
                id: 'emaxPrice',
                material: 'E.max',
                customPrice: getCustomPrice('Emax'),
                basePrice: 'N/A',
                isGold: false,
            },
            {
                id: 'porcelainFusedZirconiaPrice',
                material: 'Porcelain Fused Zirconia (PFZ)',
                customPrice: getCustomPrice('Porcelain Fused Zirconia - PFZ'),
                basePrice: getBasePrice('Porcelain Fused Zirconia - PFZ'),
                isGold: false,
            },
            {
                id: 'pfmSemiPreciousPrice',
                material: 'PFM - Noble - White',
                customPrice: getCustomPrice('PFM - Noble - White'),
                basePrice: 'N/A',
                isGold: true,
            },
        ];
    }, [getPrice]);

    return (
        <PricingGuideTable
            id={'implant-solutions-materials'}
            title={'Final Implant Restorations*'}
            columns={[
                {
                    id: 'material',
                    title: 'Material',
                },
                {
                    id: 'customPrice',
                    title: 'Custom Titanium or Zirconia Abutment',
                    render: ({ customPrice, isGold }) =>
                        formatPrice(customPrice, formattedPrice =>
                            isGold ? `${formattedPrice} + Alloy` : formattedPrice,
                        ),
                },
                {
                    id: 'basePrice',
                    title: 'Screw-retained Ti Base',
                    render: ({ basePrice }) => (typeof basePrice === 'string' ? basePrice : formatPrice(basePrice)),
                    sortBy: ({ basePrice }) => (typeof basePrice === 'string' ? 0 : basePrice),
                },
            ]}
            rows={implantSolutionMaterials}
            footnotes={[
                '* Screw-retained, Cement-retained or Screwmentable.',
                '* Listed price does not include the alloy price of gold. Final price depends on the weight and market value of gold, which will be added to the listed price.',
                'OEM component surcharge: $75 per abutment',
            ]}
        />
    );
};
