import { useCalculatePaddingTop } from '../../../utils/LayoutUtils';
import { useIsChairside } from '../../../utils/chairside.hooks';
import { ChatWindowBase } from './ChatWindowBase';
import { useScreenIsMobile, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const HorizontalNavCSTAnchor = styled('div')(({ theme }) => ({
    position: 'fixed',
    right: 5,
    zIndex: 1500,
    height: 688,
    '& > *': {
        position: 'absolute',
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: 0,
        height: '100vh',
    },
}));

export const ChatWindowRoot: React.VFC = () => {
    const isChairside = useIsChairside();
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');
    const isMobile = useScreenIsMobile();
    const paddingTop = useCalculatePaddingTop({
        chairside: isChairside,
        maintenanceBannerPractice: !!maintenanceBannerPractice?.length,
    });

    if (isMobile) {
        return null;
    }

    return (
        <HorizontalNavCSTAnchor style={{ top: paddingTop }}>
            <ChatWindowBase />
        </HorizontalNavCSTAnchor>
    );
};
