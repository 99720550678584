import { AnalyticsClient } from '../../analytics/analyticsClient';
import { OnboardingStep } from './CreateAccount';
import { OnboardingStepCard } from './OnboardingStepCard';
import { OnboardingStepLayout } from './OnboardingStepLayout';
import type { LabsGqlUserDtoFragment } from '@orthly/graphql-schema';
import { Button, styled } from '@orthly/ui-primitives';
import React from 'react';

interface WelcomeScreenProps {
    setStep(step: OnboardingStep): void;
    preferencesId: string;
    user?: LabsGqlUserDtoFragment | undefined;
}

const ImageStyled = styled('img')(({ theme }) => ({
    maxWidth: '447px',
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
        height: 'fit-content',
    },
}));
const ButtonStyled = styled(Button)(({ theme }) => ({
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

export const WelcomeScreen: React.VFC<WelcomeScreenProps> = ({ setStep, preferencesId, user }) => {
    return (
        <OnboardingStepLayout id={'welcome-step'} title={'Welcome to Dandy'}>
            <OnboardingStepCard
                mediaRight
                media={<ImageStyled src={'/onboarding/HomeActivaton.png'} alt={'Home Activation'} />}
                height={'319px'}
                title={'First, let’s set up your profile'}
                bodyText={[
                    'Setting up your profile lets you manage orders, staff members, handle invoices, set up notifications, and so much more. This process will take roughly 5 minutes.',
                ]}
                actions={[
                    <ButtonStyled
                        key={'set-up-profile'}
                        data-test={'set-up-profile'}
                        onClick={() => {
                            setStep(OnboardingStep.ProfileSetUp);
                            AnalyticsClient.track('Practice - Guided Onboarding - Start', {
                                preferencesId,
                                email: user?.email,
                            });
                        }}
                        variant={'primary'}
                        endIcon={'ChevronRight'}
                    >
                        Set Up Profile
                    </ButtonStyled>,
                ]}
            />
        </OnboardingStepLayout>
    );
};
