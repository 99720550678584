import type { FlossColor } from '@orthly/ui';
import type { IconName, Theme } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Grid, Button, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory((theme: Theme) => ({
    root: {
        borderRadius: 16,
        padding: '40px 16px 16px 16px',
        marginBottom: 24,
        position: 'relative',
    },
    removeBelowSmall: {
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
}));

interface InboxUpsellItemButtonProps {
    text: React.ReactNode;
    onClick?: () => void;
    endIcon?: IconName;
    ctaButtonColor?: FlossColor;
}

interface InboxUpsellItemDisplayProps {
    title: React.ReactNode;
    subtitle?: React.ReactNode;
    primaryAction?: InboxUpsellItemButtonProps;
    image?: React.ReactNode;
    backgroundColor?: FlossColor;
}

/**
 * This is the pure component that actually display the upsell item
 */
export const InboxUpsellItemDisplay: React.VFC<InboxUpsellItemDisplayProps> = props => {
    const classes = useStyles();
    const { image, primaryAction, subtitle, title } = props;
    const backgroundColor: FlossColor = props.backgroundColor ?? 'STAR_GRASS_HOVER';

    return (
        <Grid
            container
            className={classes.root}
            direction={'row'}
            wrap={'nowrap'}
            style={{ backgroundColor: FlossPalette[backgroundColor], position: 'relative' }}
        >
            <Grid item container direction={'column'} wrap={'nowrap'}>
                <div style={{ position: 'absolute', top: -36 }}>{image}</div>
                <div>
                    <Text variant={'body1'} style={{ color: FlossPalette.PRIMARY_FOREGROUND }} medium>
                        {title}
                    </Text>
                    {subtitle && (
                        <Text variant={'body2'} style={{ marginTop: 8, color: FlossPalette.GRAY }}>
                            {subtitle}
                        </Text>
                    )}
                </div>
                <Grid item style={{ marginTop: 8 }}>
                    {primaryAction && (
                        <Button
                            variant={'ghost'}
                            onClick={primaryAction.onClick}
                            endIcon={primaryAction.endIcon}
                            style={{ margin: 0, padding: 0, height: 'unset' }}
                        >
                            {primaryAction.text}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};
